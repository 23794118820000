<div>
  <div class="row">
    <div class="col-12 font-size-11">
      <a class="float-right mt-1" (click)="addPhone()" href="javascript:;" id="phoneAddNumber">
        <fa-icon [icon]="['fas', 'plus-circle']" class="mr-1 text-success"></fa-icon>
        <span>{{ 'add_number' | translate }}</span>
      </a>
      <label class="labels" translate>
        {{ label }}
      </label>
    </div>
  </div>
</div>

<div *ngFor="let phone of value; let i = index" class="row mb-1 pl-2 pr-2">
  <div class="col-3 pl-1 pr-1">
    <ng-select class="phoneType" [items]="types" [clearable]="false" [(ngModel)]="phone.type" (ngModelChange)="updateFormControlOnModelChange()" [ngModelOptions]="{standalone: true}">
      <ng-template ng-option-tmp let-item="item">
        {{ item | translate }}
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{ item | translate }}
      </ng-template>
    </ng-select>
  </div>
  <div class="col-3 pl-1 pr-1">
    <ng-select
      class="phoneCode"
      [items]="codes"
      [clearable]="false"
      (ngModelChange)="updateFormControlOnModelChange()"
      [(ngModel)]="phone.code"
      [ngModelOptions]="{standalone: true}"
      [ngClass]="{'is-invalid': !phone.code && isTouched}"
      bindLabel="name"
      bindValue="dial_code">
      <ng-template ng-label-tmp let-item="item">
        {{ item.dial_code }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item.name + ' (' + item.dial_code + ')' }}
      </ng-template>
    </ng-select>
  </div>
  <div class="col-5 pl-1 pr-1">
    <input
      required
      [(ngModel)]="phone.number"
      [ngModelOptions]="{standalone: true}"
      (keypress)="validateInput($event)"
      (ngModelChange)="updateFormControlOnModelChange()"
      class="font-size-11 form-control input-height-40 phoneNumber"
      [ngClass]="{'is-invalid': !phone.number && isTouched}"
      (paste)="onPaste($event, i)"
    />
  </div>
  <div class="col-1 pl-1 pr-1 text-center">
    <fa-icon [icon]="['fal', 'minus-circle']" class="font-size-12 pointer text-danger mt-4 phoneDeleteNumber" (click)="deletePhone(phone)"></fa-icon>
  </div>
</div>
<div class="row mt-2">
  <div class="col-12">
    <p *ngIf="value.length < 1" class="no-content-text" translate>no_content</p>
  </div>
</div>
