export class Common {

  id?: number | string;
  value?: number | string;
  type?: string;
  
  constructor(id?: number | string, value?: number | string, type?: string){
    this.id = id;
    this.value = value;
    this.type = type;
  }

}