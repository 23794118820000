import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss']
})
export class ReasonDialogComponent implements OnInit {

  public arReasonOptions: any = [];
  public strChosenReason: string = null;

  constructor(
    public dialog: MatDialogRef<ReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.arReasonOptions = data["options"];
      this.strChosenReason = data["default_value"];
    }

  ngOnInit() {
  }

  /**
   * Close the dialog.
   */
  public cancelDialog(strReason = '') {
    this.dialog.close(strReason);
  }

}
