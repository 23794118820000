import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputSanitizerService {

  constructor() { }

  /**
   * Converts the given subject to a valid float.
   * 
   * @param subject 
   * 
   * @returns {number}
   */
  toFloat(subject: any): number {
    var _subject = parseFloat(subject);

    return isNaN(_subject) ? 0.00 : _subject;
  }
}
