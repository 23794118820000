import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSiteComponent } from '../../shared/components/forms/input/select-site/select-site.component';
import { SelectCustomerComponent } from '../../shared/components/forms/input/select-customer/select-customer.component';
import { SharedModule } from './shared.module';
import { CurrencyInputComponent } from '../components/forms/input/currency/currency.component';
import { TextInputComponent } from '../components/forms/input/text/text.component';
import { GeolocationComponent } from '../components/forms/input/geolocation/geolocation.component';
import { GoogleMapComponent } from '../components/forms/input/geolocation/google-map/google-map.component';
import { AgmCoreModule } from '@agm/core';
import { EmailAddressInputComponent } from '../components/forms/input/email-address/email-address.component';
import { RelateInputComponent } from '../components/forms/input/relate/relate-input.component';
import { MultitextInputComponent } from '../components/forms/input/multitext/multitext-input.component';
import { DecimalInputComponent } from '../components/forms/input/decimal/decimal.component';
import { CheckboxInputComponent } from '../components/forms/input/checkbox/checkbox.component';
import { TextareaInputComponent } from '../components/forms/input/textarea/textarea.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    AgmCoreModule,
  ],
  declarations: [
    SelectSiteComponent,
    SelectCustomerComponent,
    CurrencyInputComponent,
    TextInputComponent,
    GeolocationComponent,
    GoogleMapComponent,
    EmailAddressInputComponent,
    RelateInputComponent,
    MultitextInputComponent,
    DecimalInputComponent,
    CheckboxInputComponent,
    TextareaInputComponent,
  ],
  exports: [
    SelectSiteComponent,
    SelectCustomerComponent,
    CurrencyInputComponent,
    TextInputComponent,
    GeolocationComponent,
    GoogleMapComponent,
    EmailAddressInputComponent,
    RelateInputComponent,
    MultitextInputComponent,
    DecimalInputComponent,
    CheckboxInputComponent,
    TextareaInputComponent,
  ],
  entryComponents: [
    GoogleMapComponent,
  ],
})
export class SharedInputModule { }
