import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { ClientStoreService } from '../../services/client-store.service';
import { Client } from '../../objects/client';
import { filter } from 'rxjs/operators';
import { RoleService } from '../../services/role.service';
import { WidgetTab } from '../../objects/widget-tab';
import { isPlural, plural } from 'pluralize';

export type WidgetProp = Pick<WidgetTab, 'singular' | 'for_module'>;
export type HasWidgetPermissionDirectiveProp = WidgetProp | string;

@Directive({
  selector: '[fcHasWidgetPermission]',
})
export class HasWidgetPermissionDirective implements OnInit {
  /**
   * @type {BehaviorSubject<string>}
   * @readonly
   */
  protected readonly moduleSubject = new BehaviorSubject<string>(undefined);

  /**
   * @type {Obseravable<[Client, string]}
   * @readonly
   */
  protected readonly state: Observable<[Client, string]> = combineLatest([
    this.clients.getWhenClientIsSetEvent(),
    this.moduleSubject.pipe()
  ]);

  /**
   * Handles directive checking
   *
   * @param {HasWidgetPermissionDirectiveProp} objWidget
   */
  @Input('fcHasWidgetPermission')
  set hasPermission(objWidget: HasWidgetPermissionDirectiveProp) {
    this.moduleSubject.next(
      (typeof objWidget !== 'string') ? this.pluralizeModule(objWidget) : objWidget
    );
  }

  /**
   * A template reference that will be used as fallback
   *
   * @type {TemplateRef<unknown>}
   */
  @Input('fcHasWidgetPermissionElse') else?: TemplateRef<unknown>;

  /**
   * Create instance of the directive
   *
   * @param {ViewContainerRef} view
   * @param {TemplateRef<unknown>} template
   * @param {ClientStoreService} clients
   * @param {RoleService} roles
   */
  constructor(
    protected view: ViewContainerRef,
    protected template: TemplateRef<unknown>,
    protected clients: ClientStoreService,
    protected roles: RoleService
  ) {}

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.state.pipe(
      filter(([ objClient ]) => !! objClient),
    ).subscribe(([objClient, strModule]) => {
      this.view.clear();

      if (this.roles.hasPermission(`${strModule}:list`, { client: objClient })) {
        this.show();
      } else {
        this.hide();
      }
    });
  }

  /**
   * Shows the current template
   *
   * @returns {void}
   */
  protected show(): void {
    this.view.createEmbeddedView(this.template);
  }

  /**
   * Clears the current view context
   *
   * @returns {void}
   */
  protected hide(): void {
    if (this.else !== undefined) {
      this.view.createEmbeddedView(this.else);
    }
  }

  /**
   * Pluralize the module value
   *
   * @param   {WidgetProp} objWidget
   *
   * @returns {string}
   */
  protected pluralizeModule(objWidget: WidgetProp): string {
    // replace - with _ for normalization
    const strModule = (objWidget.for_module || objWidget.singular).replace('-', '_');

    return (! isPlural(strModule)) ? plural(strModule) : strModule;
  }
}