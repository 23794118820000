
export class DocumentLibrary {
    metadata = {
        "version":"V 1.0",
        "author":"Christine Joyce",
        "title":"Sample Proposal for CRM ONLINE",
        "date":"6th of April, 2019",
        "customer": "CRM ONLINE",
        "customer_address": "1/F Unit 1101, 88 Corporate Centre, Valero St Corner Sedeno St, Makati"
  }
};