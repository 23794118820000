
    <div class="input-group input-group-sm">
        <span class="input-group-prepend">
            <div class="btn-group">
                <span class="btn btn-sm btn-outline-primary notClickable">{{ advancedSearchBox.translateLabel(viewModel) }}</span>
                <div ngbDropdown container="body" class="btn-group" appDropdownNavigation>
                    <button class="btn btn-sm btn-outline-primary noRadius" type="button" ngbDropdownToggle #buttonToggle
                    (keydown)="advancedSearchBox.keydown($event, viewModel, {id:'buttonDropDown'})">{{viewModel.value.op ? operatorsEnum[viewModel.value.op] : '..'}}</button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" (focus)="viewModel.value.op = operator" *ngFor="let operator of operatorsList"
                        (click)="onChangeOperators($event, operator)" (keydown)="onChangeOperators($event, operator)" href="javascript:void(0)">
                            {{operatorsEnum[operator]}}
                        </a>
                    </div>
                </div>
            </div>
        </span>
        <ng-container [ngSwitch]="inputType">
            <as-simple-input-with-operators *ngSwitchCase="'SIMPLE'" #inputComponent ></as-simple-input-with-operators>
            <as-simple-input-with-operators-mask *ngSwitchCase="'SIMPLE_MASK'" #inputComponent ></as-simple-input-with-operators-mask>
            <as-suggestions-input-with-operators *ngSwitchCase="'SUGGESTIONS'" #inputComponent ></as-suggestions-input-with-operators>
            <as-domains-input-with-operators *ngSwitchCase="'DOMAINS'" #inputComponent (change)="onChange()"></as-domains-input-with-operators>
        </ng-container>
        <span class="input-group-append" *ngIf="viewModel.date">
          <input type="text" (dateTimeChange)="parseDateToLocal($event)" [owlDateTime]="asDP" style="
            position: absolute;
            width: 0px;
            height: 0px;
            border: 0;
          " />
          <button class="btn btn-primary" [owlDateTimeTrigger]="asDP">
              <fa-icon [icon]="['fal', 'calendar']"></fa-icon>
          </button>
          <owl-date-time
            #asDP
            [pickerType]="'calendar'"
            (afterPickerOpen)="toggleDatepickershow($event)"
            (afterPickerClosed)="toggleDatepickershow($event)"
          ></owl-date-time>
        </span>
        <span class="input-group-append">
            <button class="btn btn-outline-primary" type="button" (click)="remove()">X</button>
        </span>
    </div>
    