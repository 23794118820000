import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpEvent } from '@angular/common/http';
import * as moment from 'moment';

import { RelateIds } from '../../../../../lists/relate-ids';
import { Notification } from '../../../../../objects/notification';
import { UploadConfig } from "../../../../../lists/upload-config";

import { NotificationService } from '../../../../../services/notification.service';
import { FileService } from '../../../../../services/file/file.service';

@Component({
  selector: 'app-file-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public arFileDetails: any = [];
  public arFiles: any = [];
  public arRecordDetails: any = [];
  public arRequestData: any = [];
  public strRelatedId: any = RelateIds;
  public strVersion: string = '';
  public arVersionList: any = [];

  public isFileUploaded: boolean = false;

  public uploadConfig = new UploadConfig();

  public fileUploadForm: FormGroup = this.formBuilder.group({
    file: new FormControl('', [Validators.required]),
  });

  public saveConfig = {
    label: "upload",
    icon: "file-upload",
    loading: false,
    disable: true
  };

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifService: NotificationService,
    private dialogRef: MatDialogRef<EditComponent>,
    private formBuilder: FormBuilder,
    private fileService: FileService
  ) {
    // Store record details
    this.arFileDetails = data.file;
    this.arRecordDetails = data;
    this.fileUploadForm.valueChanges.subscribe( data => {
      if (data.file) {
        this.validateFile(data.file);
      }
    });

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  ngOnInit() {
    this.fileService.getObjectHistory(this.arFileDetails.upload_name).subscribe(
      data => {
        this.arVersionList = data;
      }
    );
  }

  /**
   * get file icon
   *
   * @param strType
   * @param strFileName
   */
  getFileType(strType, strFileName): string {
    // Get the file type by cutting the first word
    var strFileType = strType.substr(0, strType.indexOf("/"))
    // Get file extensions (Exclusively made for file type application)
    var strFileExtension = strFileName.split('.')[1];
    if (strFileType !== 'application') {

      // Check if file has corresponding icon
      return this.uploadConfig['mimetype'][strFileType.toLowerCase()] != undefined ?
        this.uploadConfig['mimetype'][strFileType.toLowerCase()] : 'file';
    } else {

      // Check if application used is spreadsheet, powerpoint, docs, zip, pdf etc.
      if (this.uploadConfig['spreadsheetList'].includes(strFileExtension.toLowerCase())) {
        return 'file-spreadsheet';
      } else if (this.uploadConfig['powerpointList'].includes(strFileExtension.toLowerCase())) {
        return 'file-powerpoint';
      } else if (this.uploadConfig['documentList'].includes(strFileExtension.toLowerCase())) {
        return 'file-word';
      } else if (this.uploadConfig['archiveList'].includes(strFileExtension.toLowerCase())) {
        return 'file-archive';
      } else if (strFileExtension.toLowerCase() === 'pdf') {
        return 'file-pdf';
      } else {
        return 'file';
      }
    }
  }

  /**
   * get file icon color
   *
   * @param strType
   * @param strFileName
   */
  getColorsPerType(strType, strFileName): string {
    // Get the file type by cutting the first word
    var strFileType = strType.substr(0, strType.indexOf("/"))
    // Get file extensions (Exclusively made for file type application)
    var strFileExtension = strFileName.split('.')[1];
    let strApplicationType = 'default';
    if (strFileType !== 'application') {

      // Check if file has corresponding icon color, if not return default color
      return this.uploadConfig['iconColors'][strFileType.toLowerCase()] != undefined ?
      this.uploadConfig['iconColors'][strFileType.toLowerCase()] : this.uploadConfig['iconColors']['default'] ;
    } else {

      // Check if application used is spreadsheet, powerpoint, docs, zip, pdf etc.
      if (this.uploadConfig['spreadsheetList'].includes(strFileExtension.toLowerCase())) {
        strApplicationType = 'spreadsheet';
      } else if (this.uploadConfig['powerpointList'].includes(strFileExtension.toLowerCase())) {
        strApplicationType = 'powerpoint';
      } else if (this.uploadConfig['documentList'].includes(strFileExtension.toLowerCase())) {
        strApplicationType = 'document';
      } else if (this.uploadConfig['archiveList'].includes(strFileExtension.toLowerCase())) {
        strApplicationType = 'archive';
      } else if (strFileExtension.toLowerCase() === 'pdf') {
        strApplicationType = 'pdf';
      } else {
        strApplicationType = 'default';
      }
      return this.uploadConfig['iconColors'][strApplicationType];
    }
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate) {
    return moment(strDate).utcOffset(new Date().getTimezoneOffset()).format('lll');
  }

  objFile: object = null;
  /**
   * validate the file that is being upload
   *
   * @param file
   */
  validateFile(uploaded_file): void {
    if (uploaded_file.name && uploaded_file.size && uploaded_file.type) {
      if (this.validateFileSize(uploaded_file.size)) {
        if (this.validateFileFormat(uploaded_file.type)) {
          this.uploadFile(uploaded_file);
        } else {
          this.warningMessage("upload_failed", "invalid_file_format");
        }
      } else {
        this.warningMessage("upload_failed", "invalid_file_size");
      }
    }
  }

  /**
   * close the dialog
   */
  cancelDialog(): void {
    if (this.fileUploadForm.dirty) {
      this.notifService.sendConfirmation('confirm_cancel')
      .filter(confirmation => (confirmation.answer))
      .subscribe( () => {
        this.dialogRef.close('cancel');
      });
    } else {
      this.dialogRef.close('cancel');
    }
  }

  /**
   * format file size. If more than 1000kb show in MB
   *
   * @param strFileSize
   */
  formatFileSize(strFileSize): string {
    if (strFileSize > 1000) {
      return (strFileSize / 1024).toFixed(2) + ' mb';
    }
    return strFileSize.toFixed(2) + ' kb';
  }

  /**
   * get the icon color for header
   *
   * @param arFileDetails
   */
  formHeaderIconStyle(arFileDetails): object {
    let formHeaderStyle = {
      color: this.getColorsPerType(arFileDetails.file_type, arFileDetails.file_name)
    }
    return formHeaderStyle;
  }

  /**
   * recieve the response from header action buttons
   *
   * @param event
   */
  actionEvent(event) {
    if (event.action === 'cancel') {
      this.cancelDialog();
    }
    if (event.action === 'save') {
      this.onSubmit();
    }
  }

  /**
   * update current file record
   */
  onSubmit(): void {
    if (!this.fileUploadForm.valid) {
      this.warningMessage('required', 'please_select_file');
    } else if (!this.isFileUploaded) {
      this.warningMessage('required', 'please_wait_uploading_file');
    } else {
      this.dialogRef.close({
        module_id: this.arRecordDetails.record_id,
        module_field: this.strRelatedId[this.arRecordDetails.module],
        version: this.arFileDetails.version + 1,
        file: this.objFile,
        module: this.arRecordDetails.module,
        id: this.arFileDetails.id
      });
    }
  }

  /**
   * We need to make sure the file is less than 30mb
   *
   * @param file_size
   */
  validateFileSize(file_size: number): boolean {
    return (file_size/1024/1024 < 30) ? true : false
  }

  /**
   * We need to make sure that current file type is same as the new file
   *
   * @param file_type
   */
  validateFileFormat(file_type: string): boolean {
    var strNewFileType = file_type != undefined ? file_type.substr(0, file_type.indexOf("/")) : null;
    var strCurrentFileType = this.arFileDetails.file_type != undefined ? this.arFileDetails.file_type.substr(0, this.arFileDetails.file_type.indexOf("/")) : null;
    return (strNewFileType !== strCurrentFileType) ? false : true;
  }

  /**
   * create activity record and upload temporary file
   *
   * @param file
   */
  uploadFile(file): void {

    this.isFileUploaded = false
    this.saveConfig.loading = true;

    this.fileService.upload(file).subscribe(result => {
      this.isFileUploaded = true;
      this.saveConfig.loading = false;
      this.saveConfig.disable = false;
      let objFile = this.fileService.objFile;
      this.objFile = {
        name: objFile.name,
        size: objFile.size / 1024,
        type: objFile.type,
        upload_name: result['filename']
      };
    });
  }

  /**
   * prompt warning message
   *
   * @param message
   */
  warningMessage(header: string, message: string): void {
    this.notifService.sendNotification(header, message, "warning");
    this.fileUploadForm.patchValue({ file: null });
  }
}
