import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() {}

  /**
   * This will convert the given period to a date
   *
   * @param strPeriod
   * @param bHasTime
   * @returns string
   */
  convertPeriodToDate(strPeriod: string, bHasTime: boolean = false): string {
    let objDate = new Date();
    let strDateFormat = bHasTime ? 'YYYY-MM-DD H:m:s' : 'YYYY-MM-DD';

    // Add here if there's any period that need to be convert.
    if (strPeriod.includes('tomorrow')) {
      objDate.setDate(objDate.getDate() + 1);
    } else if (strPeriod.includes('days_7')) {
      objDate.setDate(objDate.getDate() + 7);
    } else if (strPeriod.includes('days_14')) {
      objDate.setDate(objDate.getDate() + 14);
    } else if (strPeriod.includes('days_30')) {
      objDate.setDate(objDate.getDate() + 30);
    } else if (strPeriod.includes('last_day_of_this_month')) {
      objDate = new Date(objDate.getFullYear(), objDate.getMonth() + 1, 0);
    } else if (strPeriod.includes('first_day_of_next_month')) {
      objDate = new Date(objDate.getFullYear(), objDate.getMonth() + 1, 1);
    } else if (strPeriod.includes('last_day_of_next_month')) {
      objDate = new Date(objDate.getFullYear(), objDate.getMonth() + 2, 0);
    }

    return moment(objDate).format(strDateFormat);
  }

  /**
   * Convert UTC moment to local.
   *
   * @param {moment.Moment} strUtcTime
   *
   * @returns {moment.Moment}
   */
  convertUtcToLocal(strUtcTime: moment.Moment): moment.Moment {
    return strUtcTime.local();
  }
}
