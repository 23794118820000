import { FormGroup } from '@angular/forms';

export class Form<T>{
    default_value: T;
    key: string;
    label: string;
    controlType: string;
    space: number;
    required: boolean = false;
    readonly: boolean = false;
    validator: boolean;
    max_length: string;
    min_length: string;
    is_hidden: boolean;
    precision: number[]|null;
    is_admin: boolean = false;
    has_primary: boolean;
    note: string;
    decimal_places?: number;

    /**
     * Indicates that the field is a multiple value field
     */
    readonly multiple: boolean;

    /**
     * Contains the options to be used when creating a proper control instance for this field
     */
    readonly custom_form_options: CustomFormOptions;

    /**
     * Dictates if the given form field should hide its label. This is useful
     * when you have a custom field component and that component is responsible for displaying the
     * appropriate label for the field
     */
    readonly hide_label: boolean;

    /// contains the availability condition of the current field
    readonly depends_on?: string;

    constructor(options: {
        default_value?: T,
        key?: string,
        label?: string,
        controlType?: string,
        space?: number,
        required?: boolean,
        readonly?: boolean,
        validator?: boolean,
        is_hidden?: boolean,
        max_length?: string,
        min_length?: string,
        precision?: number[],
        is_admin?: boolean,
        has_primary?: boolean,
        note?: string,
        multiple?: boolean,
        custom_form_options?: CustomFormOptions,
        hide_label?: boolean,
        decimal_places?: number,
        depends_on?: string,
      } = {}) {
        this.default_value = options.default_value,
        this.key = options.key,
        this.label = options.label,
        this.controlType = options.controlType,
        this.space = (options.space == 2) ? 12 : 6,
        this.required = options.required || this.required,
        this.readonly = options.readonly || this.readonly,
        this.validator = options.validator
        this.is_hidden = options.is_hidden || false
        this.max_length = options.max_length
        this.min_length = options.min_length
        this.precision = options.precision
        this.is_admin = options.is_admin || this.is_admin
        this.has_primary = options.has_primary || false
        this.note = options.note;
        this.multiple = options.multiple;
        this.custom_form_options = options.custom_form_options;
        this.hide_label = options.hide_label || false;
        this.decimal_places = options.decimal_places;
        this.depends_on = options.depends_on;
    }

    toggleFieldAvailability(available: boolean, opts: {
      action: AvailabilityAction,
    }): void {
      if (opts.action === 'readonly') {
        this.readonly = ! available;
      } else if (opts.action === 'hide') {
        this.is_hidden = available;
      }
  }
}

export type AvailabilityAction = 'readonly' | 'hide';

export type CustomFormOptions = {
  /**
   * The selector of the field component to be used as a custom field for the current form
   */
  component_selector: string;
  /**
   * Dictates the control for this custom form field is an object.
   */
  is_object?: boolean;
};

export type FormMode = 'edit' | 'add';

export interface CustomEditFieldComponent<T = any> {
  /**
   * Set the current mode of the form
   */
  setFormMode(formMode: FormMode): void;
  /**
   * Sets the instance of the parent form
   */
  setParentForm(parent: FormGroup): void;
  /**
   * Sets the current field which represents by the custom field component
   */
  setField(field: Form<T>): void;
}

export interface CustomFieldViewComponent<T = any> {
  /**
   * Sets the current field which represents by the custom field component
   */
  setField(field: Form<T>): void;
}
