import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { TranslateService } from '@ngx-translate/core';
import { Select } from "../../../../../../objects/select";
import { ContactRoles } from "../../../../../../objects/contact-roles";
import { ContactRolesService } from '../../../../../../services/contact-roles.service';

@Component({
  selector: "app-role-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"]
})
export class ContactsComponent implements OnInit {

  @Input() strModule: string;
  @Input() arContactList: ContactRoles[];
  @Input() arOption: Select[] = [];
  @Output() objAction: EventEmitter<{
    action: string,
    data: object
  }> = new EventEmitter();

  get roleRecords(): ContactRoles[] {
    if (this.strModule === 'jobs') {
      return this.arContactList.map( objRecord => {
        return this.transformJobRoles(objRecord)
      })
    }
    return this.arContactList;
  }

  constructor(
    private translateService: TranslateService,
    private contactRolesService: ContactRolesService
  ) { }

  ngOnInit() {
  }

  /**
   * set"s email dialog data
   *
   * @param email
   * @param contact_id
   */
  setEmailDialogData(email: object = {}, contact_id: string = ""): void {
    this.emitAction("email", {
        email: email["email"],
        contact_id: contact_id
    });
  }

  /**
   * emit objAction data
   */
  emitAction(action: string, record: object): void {
    this.objAction.emit({
        action: action,
        data: record
    })
  }

  /**
   * format and translate text
   *
   * @param data
   */
  formatText(data: string): string {
    try {
      var arCompiledText = [];
      JSON.parse(data).forEach( text => {
        arCompiledText.push(this.translateService.instant(text));
      });
      return arCompiledText.join(", ");
    } catch (e) {
      return this.translateService.instant(data);
    }
  }

  /**
   * check for primary contact role
   *
   * @param contact_id
   * @param primary_contact_id
   */
  isPrimaryContactRole(contact_id: string, primary_contact_id: string): boolean {
    try {
        return JSON.parse(primary_contact_id).map( data => {
            return data["customers"] == contact_id || data["sites"] == contact_id;
        }).includes(true);
    } catch (e) {
      return contact_id == primary_contact_id;
    }
  }

  /**
   * translates the selected roles
   * unshift the primary role so will be visible in the display
   *
   * @param arRoles
   */
  translateRole(arRoles: Select[]): string {
    return this.contactRolesService.transformRole(arRoles);
  }

  /**
   * separate compile and separate each roles
   *
   * @param objContactRoles
   */
  transformJobRoles(objContactRoles: ContactRoles): ContactRoles {
    let arRoles = typeof objContactRoles.role === 'string' ? JSON.parse(objContactRoles.role) : objContactRoles.role;
    objContactRoles.customer_roles = [];
    objContactRoles.site_roles = [];
    arRoles.forEach( objRole => {
      Object.keys(objRole).forEach( strModule => {

        let strRecordKey = strModule === 'customers' ? 'customer_roles' : 'site_roles';
        let role = this.safeJSONParse(objRole[strModule]);
        if (typeof role === 'string') {
          role = [
            { id: role, primary: false, text: role }
          ];
        }
        objContactRoles[strRecordKey] = [ ...objContactRoles[strRecordKey], ...role]
      });
    });

    return objContactRoles;
  }

  /**
   * parse json data
   *
   * @param data
   */
  safeJSONParse(data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  }
}
