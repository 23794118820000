<div class="row col-12">
  <div class="col-6 text-right">
    <p>{{ 'schedule_import_customers_and_suppliers' | translate }}</p>
  </div>
  <div class="col-6">
    <button class="btn btn-primary" (click)="onStartImport()" *ngIf="! isInProgress && ! isSuccessful && ! error" id="btn-schedule-import">{{ "schedule" | translate }}</button>
    <fa-icon [icon]="['fas','spinner']" class="custom-size text-secondary" [spin]="true" *ngIf="isInProgress"></fa-icon>
    <fa-icon id="import-customers-and-suppliers-success" [icon]="['fas', 'check']" class="text-success" *ngIf="isSuccessful && ! isInProgress"></fa-icon>
    <fa-icon [icon]="['fas', 'sync-alt']" (click)="retryHandler()" class="pointer" id="import-customers-and-suppliers-retry-action" *ngIf="error && ! isInProgress"></fa-icon>
    <small class="text-danger text-muted" id="import-customers-and-suppliers-error-message" *ngIf="! isSuccessful">{{ error | translate }}</small>
  </div>
</div>
