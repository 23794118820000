import { NgModule } from '@angular/core';
import { AccountingSystemService } from './services/accounting_system.service';
import { CommonModule } from '@angular/common';
import { ConnectComponent } from './components/connect/connect.component';
import { DriversComponent } from './components/drivers/drivers.components';
import { SharedModule as AppSharedModule } from '../../../shared/modules/shared.module';
import { MatStepperModule } from '@angular/material';
import { AuthorizationComponent } from './components/connect/steps/authorization/authorization.component';
import { SetupMetadataFieldsComponent } from './components/connect/steps/authorization/components/setup-metadata-fields.component';
import { ImportAccountCodesComponent } from './components/connect/steps/imports/account-codes/account-codes.component';
import { ImportTaxCodesComponent } from './components/connect/steps/imports/tax-codes/tax-codes.component';
import { ImportItemsComponent } from './components/connect/steps/imports/items/items.component';
import { ScheduleImportCustomersAndSuppliersComponent } from './components/connect/steps/imports/customers-and-suppliers/customers-and-suppliers.component';
import { ScheduleImportContacts } from './components/connect/steps/imports/contacts/contacts.component';
import { SuccessWizardConnectionComponent } from './components/connect/steps/success-connection/success-connection.component';
import { ImportMultipleModulesComponent } from './components/connect/steps/imports/multiple-modules/multiple-modules.component';
import { ImportTaxCodesComponent as ButtonImportTaxCodesComponent } from './components/buttons/tax-codes/import-tax-codes.component';
import { ImportAccountCodesComponent as ButtonImportAccountCodesComponent } from './components/buttons/account-codes/import-account-codes.component';
import { ImportItemsComponent as ButtonImportItemsComponent } from './components/buttons/items/import-items.component';
import { ImportCustomersAndSuppliersComponent as ButtonImportCustomersAndSuppliersComponent } from './components/buttons/customers-and-suppliers/import-customers-and-suppliers.component';
import { ImportContactsComponent as ButtonImportContactsComponent } from './components/buttons/contacts/import-contacts.component';
import { AccountingSyncButtonComponent } from './components/buttons/sync/sync.component';
import { FailedSyncComponent as NotificationFailedSyncComponent } from './components/notifications/failed-sync/failed-sync.component';
import { NoInstalledDriverComponent as NotificationNoInstalledDriverComponent } from './components/notifications/no-installed-driver/no-installed-driver.component';
import { RouterModule } from '@angular/router';
import { ViewService } from '../../../services/view.service';
import { CustomMetadataComponent } from './components/render-form/custom-metadata/custom-metadata.component';
import { ModifiedSinceComponent } from './components/common/forms/dialog/modified-since/modified-since.component';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { ConfigureComponent as StepConfigureMetadataComponent } from './components/connect/steps/configuration/metadata/configure.component';
import { ConfigureComponent as StepConfigureSystemDefaultsComponent } from './components/connect/steps/configuration/system-defaults/configure.component';
import { AccountingSetDefaultConfiguration as SetupSystemDefaultsComponent } from './components/common/forms/tax-account-codes/tax-account-codes.component';
import { ConfigurationService as AdminService } from '../../../services/configuration.service';
import { EnquiryComponent } from './components/drivers/enquiry/enquiry.component';
import { ComposeComponent as ComposeEnquiryComponent } from './components/drivers/enquiry/compose/compose.component';
import { ImportProgressComponent } from './components/common/import-progress/import-progress.component';
import { RecordService } from '../../../services/record.service';
import { EditModule } from '../../../shared/modules/edit.module';
import { AccountingSyncRequestLogComponent } from './components/buttons/sync/request-log-dialog/request-log-dialog.component';

@NgModule({
  declarations: [
    ConnectComponent,
    DriversComponent,
    AuthorizationComponent,
    SetupMetadataFieldsComponent,
    ImportAccountCodesComponent,
    ImportTaxCodesComponent,
    ImportItemsComponent,
    ScheduleImportCustomersAndSuppliersComponent,
    ScheduleImportContacts,
    SuccessWizardConnectionComponent,
    ImportMultipleModulesComponent,
    ButtonImportTaxCodesComponent,
    ButtonImportAccountCodesComponent,
    ButtonImportItemsComponent,
    ButtonImportCustomersAndSuppliersComponent,
    ButtonImportContactsComponent,
    AccountingSyncButtonComponent,
    NotificationFailedSyncComponent,
    NotificationNoInstalledDriverComponent,
    CustomMetadataComponent,
    ModifiedSinceComponent,
    StepConfigureMetadataComponent,
    StepConfigureSystemDefaultsComponent,
    SetupSystemDefaultsComponent,
    EnquiryComponent,
    ComposeEnquiryComponent,
    ImportProgressComponent,
    AccountingSyncRequestLogComponent,
  ],
  entryComponents: [
    ConnectComponent,
    AuthorizationComponent,
    SetupMetadataFieldsComponent,
    ImportAccountCodesComponent,
    ImportTaxCodesComponent,
    ImportItemsComponent,
    ScheduleImportCustomersAndSuppliersComponent,
    ScheduleImportContacts,
    SuccessWizardConnectionComponent,
    ImportMultipleModulesComponent,
    CustomMetadataComponent,
    ModifiedSinceComponent,
    StepConfigureMetadataComponent,
    StepConfigureSystemDefaultsComponent,
    SetupSystemDefaultsComponent,
    EnquiryComponent,
    ComposeEnquiryComponent,
    ImportProgressComponent,
    AccountingSyncRequestLogComponent,
  ],
  imports: [
    CommonModule,
    AppSharedModule.forRoot(),
    MatStepperModule,
    RouterModule,
    OwlDateTimeModule,
    EditModule,
  ],
  exports: [
    DriversComponent,
    ButtonImportTaxCodesComponent,
    ButtonImportAccountCodesComponent,
    ButtonImportItemsComponent,
    ButtonImportCustomersAndSuppliersComponent,
    ButtonImportContactsComponent,
    AccountingSyncButtonComponent,
    NotificationFailedSyncComponent,
    NotificationNoInstalledDriverComponent,
    CustomMetadataComponent,
    SetupSystemDefaultsComponent,
    EnquiryComponent,
  ],
  providers: [
    AccountingSystemService,
    ViewService,
    AdminService,
    RecordService,
  ]
})
export class SharedModule {
  // ..
}
