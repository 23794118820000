

<div class="container-fluid h-100">
  <div class="row h-100">

    <div class="col-3 mt-1 h-100">
      <div class="row h-100">

        <div class="col-12 mb-1">
          <div class="d-flex">

            <div class="flex-grow-1 pr-2">
              <div class="position-relative">
                <fa-icon *ngIf="bUserSearchLoader" [icon]="['fas','spinner']" [spin]="true" class="position-absolute search-spinner"></fa-icon>
                <input type="text" class="form-control" placeholder="{{ 'search_'+ strSubcontractor | translate }}" [formControl]="objSearchUser"/>
              </div>
            </div>

            <div *ngIf="bPaginationReady" class="btn-group float-right" role="group">
              <button type="button" (click)="movePage('prev')" class="btn btn-primary p-2" [disabled]="!list.hasPrevPage"><fa-icon [icon]="['far', 'chevron-left']"></fa-icon></button>
              <button type="button" (click)="movePage('next')"class="btn btn-primary p-2" [disabled]="!list.hasNextPage"><fa-icon [icon]="['far', 'chevron-right']"></fa-icon></button>
            </div>

          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="d-flex">
            <ng-select
              [clearable]="false"
              appendTo="body"
              style="width: 100%;"
              [items]="['users', 'subcontractors']"
              [(ngModel)]="strSubcontractor"
              (ngModelChange)="onSubcontractorToggle()">

              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                {{ item | translate }}
              </ng-template>

              <ng-template  ng-option-tmp let-item="item">
                {{ item | translate }}
              </ng-template>

            </ng-select>
          </div>
        </div>

        <div class="col-12" style="overflow-x: auto; height: 85%;">
          <ul class="list-group">
            <li *ngIf="arUsers.length == 0" class="list-group-item p-2">{{ 'no_results' | translate }}</li>
            <ng-container *ngFor="let user of arUsers" >
              <li *ngIf="user.status == 'inactive'" class="list-group-item p-2">
                <small class="text-secondary">{{ user.text }} ({{ 'inactive' | translate }})</small>
              </li>
              <li *ngIf="user.status == 'active'" class="list-group-item p-2" [ngClass]="{'active-user': objSelectUser && objSelectUser.id == user.id}" (click)="selectUser(user);" >

                {{ user.text }}

                <span class="float-right">
                  <ng-container *ngIf="user.has_availability">
                    <fa-icon *ngIf="!user.show_availablity" [icon]="['far', 'caret-circle-down']" class="mr-1 pointer text-primary" (click)="user.show_availablity = !user.show_availablity; $event.stopPropagation()"></fa-icon>
                    <fa-icon *ngIf="user.show_availablity" [icon]="['far', 'caret-circle-up']" class="mr-1 pointer text-primary" (click)="user.show_availablity = !user.show_availablity; $event.stopPropagation()"></fa-icon>
                  </ng-container>
                  <fa-icon [icon]="['far', 'calendar']" class="mr-1 pointer text-primary"></fa-icon>
                </span>

                <div class="row">
                  <div class="col-md-12">
                    <ng-container *ngFor="let schedule of user.availability | keyvalue : ignoreSort">
                      <small class="mr-1">
                        <span class="text-secondary">{{ 'days_label.initial.' + schedule.key | translate}}:</span>
                        <span class="text-success">{{ schedule.value.duration }}</span>
                      </small>
                    </ng-container>
                  </div>
                </div>

                <div *ngIf="user.show_availablity" class="row">
                  <ng-container *ngFor="let schedule of user.availability | keyvalue : ignoreSort">
                    <div *ngIf="schedule.value['start_time'] && schedule.value['start_time']" class="col-4">
                      <small class="text-secondary">{{ 'days_label.short.' + schedule.key | translate}}</small> <br>
                      <span class="font-size-11">{{ schedule.value['display_start'] }}</span> <br>
                      <span class="font-size-11">{{ schedule.value['display_end'] }}</span>
                    </div>
                  </ng-container>
                </div>

              </li>
            </ng-container>

          </ul>
        </div>

      </div>
    </div>

    <div class="col h-100">
      <div class="row h-100">

        <div *ngIf="objSelectUser" class="col-12 mt-1">
          <div class="row">
            <div class="col-6">
              <h5 >{{ 'set_scheduled_pre' | translate }} {{ objSelectUser.text }}</h5>
            </div>
            <div class="col-6 d-flex justify-content-end d-flex-gap align-items-center">
              <ng-container *ngIf="!objSelectUser.is_subcontractor">
                <span
                  *ngIf="notifyPush.notifyViaPush"
                  class="btn btn-link"
                  (click)="notifyPush.onEditNotificationMessage()">
                  {{ 'edit' | translate }}
                </span>
                <fieldmagic-checkbox-input
                  [(ngModel)]="notifyPush.notifyViaPush"
                >
                  <span class="pr-3">
                    {{ 'notify' | translate }}
                    <fa-icon
                      [icon]="['fas', 'info-circle']"
                      class="pointer fs-12"
                      matTooltip="{{ 'notify_tip' | translate }}">
                    </fa-icon>
                  </span>
                </fieldmagic-checkbox-input>
              </ng-container>
              <button class="btn btn-primary" (click)="save()">
                <fa-icon *ngIf="!bSave" [icon]="['fas', 'save']" class="mr-1"></fa-icon>
                <fa-icon *ngIf="bSave" [icon]="['fas','spinner']" [spin]="true" class="mr-1"></fa-icon>
                {{ 'Save and Close' | translate }}
              </button>
              <button class="btn btn-danger" (click)="onClose()">
                <fa-icon [icon]="['fas', 'times']" class="mr-1"></fa-icon>
                {{ 'cancel' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 pt-2" style="overflow-x: auto; height: 100%;">
          <div *ngIf="!objSelectUser" class="overlay-note">
            <h5>{{ 'schedule_user_tip' | translate }}</h5>
          </div>
          <full-calendar #fullcalendar [options]="options" [ngStyle]="{'opacity': objSelectUser == null ? 0 : 1}"></full-calendar>
        </div>

      </div>
    </div>

  </div>
</div>
