import { Component, Output, EventEmitter, OnDestroy, Input } from "@angular/core";
import { WizardStepComponent, StepEventData, PROGRESS_NEXT, PROGRESS_FAILED, PROGRESS_INPROGRESS } from '../../../connect.component';
import { Subscription } from "rxjs";
import { AccountingSystemService } from "../../../../../services/accounting_system.service";
import { finalize } from "rxjs/operators";
import { DriverInterface } from "../../../../../entities/driver";

@Component({
  selector: 'wizard-step-schedule-import-customers-and-suppliers',
  templateUrl: './customers-and-suppliers.component.html'
})
export class ScheduleImportCustomersAndSuppliersComponent implements WizardStepComponent, OnDestroy {
  /**
   * Selected driver information
   *
   * @var {DriverInterface}
   */
  @Input('driver') driver: DriverInterface;

  /**
   * {@inheritDoc}
   */
  @Output('event-progress') eventProgress: EventEmitter<StepEventData> = new EventEmitter;

  /**
   * this tells if the current step is in progress
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * indicates if an action was successful
   *
   * @var {boolean}
   */
  isSuccessful: boolean = false;

  /**
   * contains the error message
   *
   * @var {string}
   */
  error: string;

  /**
   * INTERNAL: contains all the subscriptions for rxjs that should be cleaned up once
   * this component is unmounted
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * @param {AccountingSystemService} service
   */
  constructor(
    private service: AccountingSystemService
  ) { }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /// handles on start import user action
  onStartImport(): void {
    this.scheduleImport();
  }

  /**
   * handles the retry action of the schedule import process
   *
   * @returns {void}
   */
  retryHandler(): void {
    this.scheduleImport();
  }

  /**
   * schedules an import for customers and suppliers
   *
   * @returns {void}
   */
  private scheduleImport(): void {
    this.isInProgress = true;
    this.eventProgress.emit({ progress: PROGRESS_INPROGRESS });

    this.subscriptions.push(
      this.service.scheduleImportCustomersAndSuppliers$(false)
        .pipe(
          finalize(() => this.isInProgress = false)
        )
        .subscribe((result) => {
          if (result.isScheduledSuccessfully) {
            this.isSuccessful = true;
            this.eventProgress.emit({ progress: PROGRESS_NEXT });
          } else {
            this.error = result.errors.shift();
            this.eventProgress.emit({ progress: PROGRESS_FAILED });
            this.isSuccessful = false;
          }
        })
    );
  }
}
