<app-error-handler onlyFor="assets_jobs:list" alignment="center" displayAs="widget">
<div class="d-flex justify-content-around tabs-content">
    <span id="widgetAssetsJobsCreateBtn" (click)="openCreateJobWizard()" class="span-links ml-2 mr-2">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ 'create_job' | translate }}
    </span>
</div>
<div class="widget-container">
    <div class="container">
        <div class="col-lg-12 pt-4 pb-4">
            <div class="row">
                <div class="col-lg-12 no-padding">
                    <div class="form-inline">
                        <div class="form-group flex-nowrap w-100">
                            <div class="col-lg-4">
                                <label class="jc-l">{{ 'status' | translate }}</label>
                            </div>
                            <div class="col-lg-8">
                                <ng-select
                                    [items]="objStatus"
                                    [clearable]="true"
                                    class="value-text"
                                    (change)="setRecordFilter($event, 'status')"
                                    id="widgetAssetsJobsStatusFilter">
                                        <ng-template ng-option-tmp let-item="item">{{ item.text | translate }}</ng-template>
                                        <ng-template ng-label-tmp let-item="item"><small>{{ item.text | translate }}</small></ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row border-top pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'wrench']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'linked_jobs' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetAssetsJobsRefreshButton" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
                        <li [ngClass]="(listingService.strFirstValue != listingService.strCurrentValue && listingService.strCurrentValue != '') ? 'active pointer' : 'disabled'" class="page-item">
                            <a id="widgetAssetsJobsPreviousButton" class="page-link" aria-label="prev" (click)="getRecordData('prev')">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="(listingService.strNextValue != '') ? 'active pointer' : 'disabled'" class="page-item">
                            <a id="widgetAssetsJobsNextButton" class="page-link"  aria-label="next" (click)="getRecordData('next')">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div *ngIf="arRecordData.length < 1" align="center">
            <div class="row border-top" *ngIf="!bLoading">
                <span class="no-content">
                    {{ 'no_linked_jobs' | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="bLoading">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </div>

        <div *ngFor="let record of arRecordData" class="row border-top pt-2 pb-2 pr-2" [id]="'widgetAssetsJobsRecord' + record.id">
            <div class="col-sm-1 circle-size">
                <fa-icon class="fa-2x" matTooltip="{{ record.status | translate }}" [icon]="['fas', 'circle']" [ngClass]="[(objStatusClass[record.status]) ? objStatusClass[record.status] : '']"></fa-icon>
            </div>
            <div class="col-sm-2">
                <span class="word-wrap" matTooltip="{{ 'job_number' | translate }}: {{ '#' + record.job_text }}"><a href="/#/jobs/{{ record.job_id }}">#{{ record.job_text }}</a></span> <br/>
                <span class="word-wrap" matTooltip="{{ 'job_type' | translate }}: {{ record.job_type | translate }}">{{ record.job_type | translate }}</span> <br/>
            </div>
            <div class="col-sm-4 truncate">
                <span class="word-wrap" matTooltip="{{ 'job_summary' | translate }}: {{ record.job_summary | placeholdWithString }}">{{ record.job_summary | placeholdWithString }}</span> <br/>
            </div>
            <div class="col-sm-4 truncate">
                <span class="word-wrap" matTooltip="{{ 'due_date' | translate }}: {{ record.job_due_date | humanReadableDate | placeholdWithString }}">{{ "due" | translate }}: {{ record.job_due_date | humanReadableDate | placeholdWithString }}</span> <br/>
                <span class="word-wrap" matTooltip="{{ 'date_completed' | translate }}: {{ record.job_date_completed | humanReadableDate | placeholdWithString }}">{{ "completed" | translate }}: {{ record.job_date_completed | humanReadableDate | placeholdWithString }}</span> <br/>
            </div>
            <div class="col-sm-1">
              <div class="row">
                <div class="col-12">
                  <fa-icon id="widgetAssetsJobsEditButton" [icon]="['fas', 'pencil']" class="text-success pointer float-right" (click)="editJobRecord(record.job_id)"></fa-icon>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
</app-error-handler>