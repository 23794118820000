import { BehaviorSubject, Subject } from 'rxjs';
import { PaginatedResult } from '../view-models';
import _ from 'lodash';

export { PaginatedResult, Pagination } from '../view-models';

export class PaginationStore<R extends Object, P = string> {
  readonly onNextPage$ = new BehaviorSubject<P | null>(null);

  readonly list$ = new Subject<R[] | null>();

  readonly hasNextPage$ = new BehaviorSubject<boolean>(false);

  readonly hasPreviousPage$ = new BehaviorSubject<boolean>(false);

  readonly isFirstPage$ = new BehaviorSubject<boolean>(true);

  private _pointer: number = 0;

  get hasNextPage(): boolean {
    if (_.isNull(this._current)) {
      return false;
    }

    return this._current.pagination.hasNextPage;
  }

  private _current: PaginatedResult<R, P> | null = null;

  private _cache: PaginatedResult<R, P>[] = [];

  setCurrent(paginated: PaginatedResult<R, P>) {
    this._setCurrent(paginated);
  }

  goToFirstPage(): void {
    if (this._cache.length < 1) {
      return;
    }

    this.hasPreviousPage$.next(false);
    this.isFirstPage$.next(true);

    this._setCurrent(
      _.first(this._cache), {
        is_history: true,
      }
    );

    this._pointer = 0;
  }

  goToPreviousPage(): void {
    if (this._cache.length < 1) {
      return;
    }

    let pointer = this._pointer - 1;

    /// make sure that we are not out of range
    if (pointer < 1) {
      pointer = 0;
    }

    const previous = this._cache[pointer];

    if (_.isEqual(previous, _.first(this._cache))) {
      this.isFirstPage$.next(true);
      this.hasPreviousPage$.next(false);
    }

    this._setCurrent(previous, {
      is_history: true,
    });

    this._pointer -= 1;
  }

  goToNextPage(): void {
    const { pagination } = this._current;

    let pointer = this._pointer + 1;

    /// this guarantees that a pointer cannot be higher
    /// that the currently cached list
    if (this._cache.length < 1 || pointer > this._cache.length) {
      this.list$.next(null);
      this._cache.push(this._current);
      this.onNextPage$.next(pagination.nextPage);
      this.hasPreviousPage$.next(true);
      this.isFirstPage$.next(false);
      this._pointer += 1;

      return;
    }

    this._setCurrent(this._cache[pointer - 1], {
      is_history: true,
    });

    this.hasPreviousPage$.next(true);
    this.isFirstPage$.next(false);
    this._pointer += 1;
  }

  refresh(): void {
    this.reset();
    this.list$.next(null);
    this.onNextPage$.next(null);
  }

  reset(): void {
    this._cache = [];
    this._pointer = 0;
    this.hasNextPage$.next(false);
    this.hasPreviousPage$.next(false);
    this.isFirstPage$.next(true);
  }

  loading(): void {
    this.list$.next(null);
  }

  _setCurrent(paginated: PaginatedResult<R, P>, options: SetCurrentOptions = {}): void {
    options = _.merge(options, {
      is_history: false,
    });

    this._current = paginated;

    /// notify observables
    this.list$.next(paginated.items);
    this.hasNextPage$.next(paginated.pagination.hasNextPage);
  }
}

type SetCurrentOptions = {
  is_history?: boolean;
}
