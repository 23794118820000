<div *ngIf="!bLoading" class="d-flex justify-content-around contacts-option tabs-content">
    <span id="widgetQuotesCreateButton" (click)="createQuote()" class="span-links">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ 'create_quote' | translate }}
    </span>
</div>

<div class="contact-roles-body">
    <div class="container">
        <div class="row pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'clipboard-list']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'quotes' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetQuotesRefreshButton" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
                        <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetQuotesPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetQuotesNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div *ngIf="arQuotes.length < 1">
            <div class="row border-top" *ngIf="!bLoading">
                <span class="no-content">
                    {{ 'no_quotes' | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="bLoading">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </div>

        <div class="row border-top pb-2 pt-2 pr-2"  *ngFor="let quote of arQuotes" [id]="'widgetQuotesRecord' + quote.id">
            <div class="col-sm-5">
                <div class="text-activity-type truncate">
                    <fa-icon *ngIf="quote.is_primary" matTooltip="{{ 'primary_quote' | translate }}" class="text-warning mr-1" [icon]="['fas', 'crown']"></fa-icon>
                    <span matTooltip="{{ quote.quote_number }}">{{ quote.quote_number }}</span>
                </div>

                <div matTooltip="{{ quote.quote_summary | translate }}" *ngIf="quote.quote_summary" class="text-activity-content truncate">
                    <span>
                        {{ quote.quote_summary | translate }}
                    </span>
                    <span matTooltip="--" *ngIf="quote.quote_summary == ''" class="text-activity-content">
                        --
                    </span>
                </div>
            </div>
            <div class="col-sm-3 truncate">
                <span
                    matTooltip="{{'total_profit' | translate}}: {{ computeProfit(quote.line_items) | currency  }} ({{ computeProfitPercentage(quote.line_items) }}%)"
                    matTooltipClass="profit-tooltip"
                    [ngClass]="computeProfit(quote.line_items) < 0 ? 'text-danger' : 'text-success'"
                    class="text-activity-date text-nowrap">
                  {{ computeProfit(quote.line_items) | currency: {decimal_places: 2}  }} <br /> ({{ computeProfitPercentage(quote.line_items) }}%)

                </span>
            </div>
            <div class="col-sm-3 truncate">
                <span matTooltip="{{ quote.expiry_date | format: 'll' | placeholdWithString }}" class="text-activity-date text-nowrap">{{ quote.expiry_date | format: 'll' | placeholdWithString }} </span><br>
        <span matTooltip="{{ addTax(quote.amount_tax_ex, quote.amount_tax) | currency  }}" class="text-activity-date text-nowrap">{{ addTax(quote.amount_tax_ex, quote.amount_tax) | currency: {decimal_places: 2}  }}</span>
            </div>
            <div class="col-sm-1 d-table text-role less-padding">
                <div class="row">
                    <div class="col-12">
                        <form-templates-preview-default-template-btn
                            [as-span]="true"
                            icon-class="text-danger"
                            btn-class=""
                            [hide-text]="true"
                            class="pointer float-right ml-2"
                            [module-id]="quote.id"
                            document-type="quotation"
                            module-name="quotes"
                            id="widgetQuotesPreviewButton">
                        </form-templates-preview-default-template-btn>
                        <fa-icon id="widgetQuotesEditButton" matTooltip="{{ 'edit' | translate }}" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="openQuote(quote)"></fa-icon>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <fa-icon id="widgetQuotesDuplicateButton" matTooltip="{{ 'duplicate' | translate }}" class="text-primary pointer float-right ml-2" [icon]="['fas', 'copy']" (click)="duplicateQuote(quote)"></fa-icon>
                        <fa-icon id="widgetQuotesDeleteButton" matTooltip="{{ 'delete' | translate }}" class="text-warning pointer float-right ml-1" [icon]="['fas', 'trash-alt']" (click)="deleteQuote(quote)"></fa-icon>
                        <fa-icon id="widgetQuotesSetPrimaryButton"*ngIf="!quote.is_primary" matTooltip="{{ 'set_primary' | translate }}" class="text-warning pointer float-right"
                            [icon]="['fas', 'crown']" (click)="setAsPrimary(quote)"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
