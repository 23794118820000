import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RecordService } from '../../../services/record.service';
import { ViewService } from '../../../services/view.service';

@Component({
  selector: 'app-view-supplier-inventory',
  templateUrl: './view-supplier-inventory.component.html',
  styleUrls: ['./view-supplier-inventory.component.scss']
})
export class ViewSupplierInventoryComponent implements OnInit {

  isLoaded: boolean = false;
  supplierPricingRecord: SupplierPricing;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data,
    protected currentDialog: MatDialogRef<ViewSupplierInventoryComponent>,
    protected recordService: RecordService,
    protected viewService: ViewService,
  ) {
    this.recordService.getRecord('items', this.data['record'].id).subscribe(response => {
      this.viewService.setViewResult(response);
      this.isLoaded = true;
      this.supplierPricingRecord = response['record_details']['additional_codes'];
    });
  }

  ngOnInit() {
  }

  /**
   * Close the current dialog
   *
   * @returns {void}
   */
  closeDialog(): void {
    this.currentDialog.close();
  }
}

export type SupplierPricing = {
  code: string;
  customer_text: string;
  unit_cost: number;
  notes: string;
};
