import { Type } from '@angular/core';

import { Tab } from './tab';
import { Widgetable } from './widgetable';

/**
 * A class that contains metatada for the component widget
 * viewer to read.
 *
 * @author CRM Online
 * @version 1.0.0
 */
export class Viewable {
  /**
   * @constructor
   * @param component The component being marked for widget viewing
   * @param name      A name that represents this viewable component. Can be anything you want :)
   * @param tab       Tab metadata that will be used to construct the tab that the user will click to show this component in the component viewing widget.
   */
  constructor(public component: Type<Widgetable>, public name: string, public tab: Tab) { }
}
