// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { environment } from './environments/environment';

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": environment.aws_appsync_graphqlEndpoint,
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "OPENID_CONNECT"
};


export default awsmobile;
