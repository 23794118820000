import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, ElementRef, forwardRef, HostListener, ViewChild } from '@angular/core';

var noop = () => { };

@Component({
  selector: 'file-upload-input',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

  /**
   * The file upload input that we're going to work with.
   *
   * @type {ElementRef<HTMLInputElement>}
   */
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef<HTMLInputElement>;

  /**
   * The content type allowed for downloading.
   *
   * @example
   * <file-upload-input [accept]="application/pdf"></file-upload-input>
   * @type string
   */
  @Input('accept') accept: string = '*';

  /**
   * Accept multiple files? Defaults to false.
   *
   * @type boolean
   */
  @Input('multiple') multiple: boolean = false;

  protected onChangeCallback: Function;

  @HostListener('blur')
  protected onTouchedCallback: Function;

  /**
   * A fork of Ben Nadel's FormControl-compatible input type file
   *
   * @link {https://www.bennadel.com/blog/3597-using-ngmodel-with-input-type-file-and-a-custom-controlvalueaccessor-in-angular-7-2-12.html}
   */
  constructor() {
    // CAUTION: These will be called by Angular when rendering the View.
    this.onChangeCallback = noop;
    this.onTouchedCallback = noop;
  }

  ngOnInit() { }

  /**
   * Gets called when the user selects, removes or updates the selected
   * files in the input.
   *
   * @todo Update spec.ts to test the passing of single/multiple files
   * to the change callback depending on nativeElement.multiple. As of
   * writing, im not sure yet if it's possible due to the nature of
   * FileList's readonly state. -Peter
   *
   * @param files {FileList}
   *
   * @returns {void}
   */
  @HostListener('change', ['$event.target.files']) handleChange(files: FileList): void {
    // If the input is set to allow MULTIPLE files, then always push an ARRAY of
    // files through to the calling context (even if it is empty).
    // --
    // NOTE: We are using Array.from() in order to create a proper Array from the
    // Array-like FileList collection.
    if (this.fileUploadInput.nativeElement.multiple) {
      this.onChangeCallback(Array.from(files));
    } else {
      // If the input is set to allow only a SINGLE file, then let's only push the
      // first file in the collection (or NULL if no file is available).
      this.onChangeCallback(files.length ? files[0] : null);
    }
  }

  /**
   * Registers the callback that gets called when the file in
   * the inputs gets changed.
   *
   * @param onChangeCallBack
   *
   * @returns {void}
   */
  registerOnChange(onChangeCallBack: Function): void {
    this.onChangeCallback = onChangeCallBack;
  }

  /**
   * Registers the callback that gets called when the input is
   * touched. We're not putting anything here since file inputs
   * don't necessarily have any validation required for when it
   * is touched.
   *
   * @param onTouchedCallBack
   *
   * @returns {void}
   */
  registerOnTouched(onTouchedCallBack: Function): void {
    this.onTouchedCallback = onTouchedCallBack;
  }

  /**
   * Gets called when the "disabled" attribute of this component
   * gets set.
   *
   * @param {boolean} isDisabled
   *
   * @returns {void}
   */
  setDisabledState(isDisabled: boolean): void {
    this.fileUploadInput.nativeElement.disabled = isDisabled;
  }

  /**
   * Gets called when the component hosting this input component
   * supplies a value manually. We don't want that, since our value
   * should only come from the file input that we have, nowhere else.
   * So when called, let's just clear the file input's value.
   *
   * @param file
   */
  writeValue(value: any): void {
    if (value instanceof FileList) {
      this.fileUploadInput.nativeElement.files = value;
    } else if (Array.isArray(value) && !value.length) {
      this.fileUploadInput.nativeElement.files = null;
    } else if (value === null) {
      this.fileUploadInput.nativeElement.files = null;
    } else {
      // Since we cannot manually construct a FileList instance, we have to ignore
      // any attempt to push a non-FileList instance into the input.
      if (console && console.warn && console.log) {
        console.warn(`An input[type=file] element can only have a FileList as its value. Ignoring: ${value}`);
      }
    }
  }
}
