<div class="containers">
  <div class="row">
    <div class="col-md-12">
      <span class="labels">{{ 'available_checklists' | translate }}</span>
      <ng-select
        [(ngModel)]="checklistModel"
        id="linkChecklistChecklistList"
        [items]="objChecklistRelate['source'] | async"
        [typeahead]="objChecklistRelate['typehead']"
        [loading]="objChecklistRelate['loader']"
        [hideSelected]="true"
        [clearable]="false"
        [ngModelOptions]="{standalone: true}"
        bindLabel="name"
        bindValue="id"
        (change)="onChangeChecklist($event)"
        (open)="triggerSubject(objChecklistRelate['typehead'])"
        appendTo="body">
      </ng-select>
    </div>
  </div>

  <div *ngIf="objChecklist && objChecklist.type === 'asset'" class="row">
    <div class="col-md-12">
      <span class="labels">{{ 'choose_period' | translate }}</span>
      <required-tag></required-tag>
      <ng-select
        [(ngModel)]="checklistPeriodModel"
        id="linkChecklistPeriod"
        [items]="objChecklist.available_periods"
        [clearable]="false"
        [ngModelOptions]="{standalone: true}"
        [multiple]= true
        appendTo="body">
          <ng-template ng-option-tmp let-item="item" class="value-text">{{ item | translate }}</ng-template>
          <ng-template ng-label-tmp let-item="item" let-clear="clear" class="value-text">{{ item | translate }}</ng-template>
      </ng-select>
    </div>
  </div>

  <div class="row  pt-3">
    <div class="col-xs-6 col-md-6">
      <button id="linkChecklistCancel" type="button" class="btn btn-warning btn-block btn-lg" (click)="onCancel()" [disabled]="bSubmit">
        <strong>{{ 'cancel' | translate }}</strong>
      </button>
    </div>
    <div class="col-xs-6 col-md-6">
      <button id="linkChecklistSave" type="button" class="btn btn-primary btn-block btn-lg" (click)="onSave()" [disabled]="bSubmit">
        <strong><fa-icon *ngIf="bSubmit" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon> {{ 'save' | translate }}</strong>
      </button>
    </div>
  </div>
</div>