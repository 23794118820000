<aside class="aside" [ngClass]="{
  'aside-minimize': layoutDashboardService.minimizedAside,
  'aside-show': layoutDashboardService.showedAside
}">

  <header class="header align-items-center justify-content-between px-3 d-none d-lg-flex">
    <ul class="nav align-items-center">
      <li class="nav-item m-auto">
        <a class="nav-link px-1 text-secondary" href="javascript:;" (click)="layoutDashboardService.minimizeAside()">
          <fa-icon [icon]="['fas', 'bars']" size="lg"></fa-icon>
        </a>
      </li>
    </ul>

    <a class="navbar-brand" href="javascript:;">
      <img *ngIf="!bIsInternetExplorer6to11" src="./assets/img/fieldmagic-logo.svg" class="fm-logo">
      <img *ngIf="bIsInternetExplorer6to11" src="./assets/img/fmlogo.png" class="fm-logo">
    </a>
  </header>

  <ul class="nav">
    <ng-container>
      <li *ngFor="let link of arLinks" class="nav-item">
        <dashboard-navigation [metadata]="link"></dashboard-navigation>
      </li>
    </ng-container>
  </ul>

</aside>