import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ImportRecordComponent } from './import-record/import-record.component';
import { ImportRecordsListComponent } from './import-records-list/import-records-list.component';
const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: ImportRecordsListComponent },
      { path: ':id', component: ImportRecordComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ImportRoutingModule { }
