import { ViewItemCodesComponent } from '../../../../admin/items/view/components/item-codes.component';
import { ViewRelatedProductsComponent } from '../../../../admin/items/view/components/related-products.component';
import { ViewReorderConfigComponent } from '../../../../admin/items/view/components/reorder-config.component';
import { ViewItemInventoryLevelsComponent } from '../../../../admin/items/view/components/item-inventory-levels.component';
import { ViewItemUsageHistoryComponent } from '../../../../admin/items/view/components/item-usage-history';

/**
 * Contains the appropriate component instance for each custom selector that represents the custom field.
 * Each component should be registered in client/src/app/shared/modules/view.module.ts
 *
 * You should register the component in the following keys
 * - entryComponents - this is used to dynamically resolve components using the ComponentResolverFactory class
 * - declarations - the component should be part of the edit module
 */
export const CUSTOM_FIELD_VIEW_SELECTORS = {
  item_codes: ViewItemCodesComponent,
  reorder_config: ViewReorderConfigComponent,
  related_products: ViewRelatedProductsComponent,
  item_inventory_levels: ViewItemInventoryLevelsComponent,
  item_usage_history: ViewItemUsageHistoryComponent,
};
