import { Injectable } from '@angular/core';

import { Client } from '../../objects/client';
import { ClientStoreService } from '../../services/client-store.service';
import { SubscriptionPlanTypes as SPT, ENTERPRISE_PLAN, ADVANCED_PLAN, STARTER_PLAN, SubscriptionId } from '../../objects/subscription-plans';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionRestrictionService {

  constructor(public clientStoreService: ClientStoreService) { }

  /**
   * Determines whether the currently active client's subscription is superior
   * to the other.
   *
   * @param {SubscriptionId} comparatorSubscriptionId
   *
   * @returns {Boolean}
   */
  subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(comparatorSubscriptionId: SubscriptionId): boolean {
    return this.subscriptionPlanIsGreaterThanOrEqualTo(this.clientStoreService.getActiveClient(), comparatorSubscriptionId);
  }

  /**
   * Determines whether a user's subscription is superior to the other.
   *
   * The access hierarchy look like this:
   * Enterprise
   * |___\
   *     Advanced
   *     |___\
   *         Starter
   *
   * 1. Enterprise users CAN access advanced areas
   * 2. Enterprise users CAN access starter areas
   * 3. Advanced users CANNOT access enterprise areas
   * 4. Advanced users CAN access starter areas
   * 5. Starter users CANNOT access enterprise areas
   * 6. Starter users CANNOT access advanced areas
   *
   * @param {Client} activeClient
   * @param {SubscriptionId} comparatorSubscriptionId
   *
   * @returns {Boolean}
   */
  subscriptionPlanIsGreaterThanOrEqualTo(client: Client, comparatorSubscriptionId: SubscriptionId): boolean {
    let clientSubscriptionIsSuperior;
    let clientSubscriptionPlanId = client.subscription_plan_id;
    let clientSubscriptionPlan = (clientSubscriptionPlanId) ? clientSubscriptionPlanId.split('-') : clientSubscriptionPlanId;
    let clientSubscriptionPlanPrefix = (clientSubscriptionPlan) ? `${clientSubscriptionPlan[0]}-${clientSubscriptionPlan[1]}` : clientSubscriptionPlan;

    switch (clientSubscriptionPlanPrefix) {
      case ENTERPRISE_PLAN:
        // The enterprise plan will always be superior because they're special :)
        clientSubscriptionIsSuperior = true;
        break;
      case ADVANCED_PLAN:
        if (comparatorSubscriptionId === ENTERPRISE_PLAN) {
          // Advanced users cannot and will not be able to access enterprise functionalities and
          // based on the hierarchy we've discussed above, enterprise is much superior.
          clientSubscriptionIsSuperior = false
        } else {
          clientSubscriptionIsSuperior = true;
        }
        break;
      case STARTER_PLAN:
      default:
        if (comparatorSubscriptionId === ENTERPRISE_PLAN || comparatorSubscriptionId === ADVANCED_PLAN) {
          // This is just sad. The starter plan is the weakest of them all!
          clientSubscriptionIsSuperior = false
        } else {
          clientSubscriptionIsSuperior = true;
        }
        break;
    }

    return clientSubscriptionIsSuperior;
  }
}
