<div class="d-flex justify-content-around contacts-option tabs-content">
    <span *ngIf="strModule == 'jobs'; else commonHeader">
        <fa-icon [icon]="['fal', 'users']" class="pr-1"></fa-icon>{{ objTemplateLabel.header | translate }}
    </span>
    <ng-template #commonHeader>
        <span id="widgetRolesManageButton_{{ strRelatedModule }}" (click)="manageRecord()" class="span-links pointer">
            <fa-icon [icon]="['fas', 'cogs']" class="text-success pr-1"></fa-icon>{{ objTemplateLabel.header | translate }}
        </span>
    </ng-template>
</div>
<div class="contact-roles-body">
    <div class="container">
        <div *ngIf="strModule == 'jobs' && !bFilterLoading" class="row border-bottom">
            <div class="col-lg-12 pt-4 pb-4">
                <div class="form-inline">
                    <div class="form-group flex-nowrap w-100">
                        <div class="col-lg-4">
                            <label class="float-left">{{ 'filter_by_contact_customers' | translate }}</label>
                        </div>
                        <div class="col-lg-8">
                            <ng-select
                                [(ngModel)]="strCustomerRole"
                                [items]="objFilterOption['customer_roles']"
                                [clearable]="true"
                                (change)="setFilter()"
                                [ngModelOptions]="{standalone: true}"
                                bindLabel="text"
                                bindValue="id"
                                id="widgetRolesCustomerRoleFilter">
                                <ng-template ng-option-tmp let-item="item" class="fs-11">
                                  <span id="widgetRolesCustomerRoleFilter{{ item.id }}">{{ item.text | translate }}</span>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" class="fs-11">
                                  {{ item.text | translate }}
                                </ng-template>
                          </ng-select>
                        </div>
                    </div>
                </div>
                <div class="form-inline mt-12">
                    <div class="form-group flex-nowrap w-100">
                        <div class="col-lg-4">
                            <label class="float-left">{{ 'filter_by_contact_sites' | translate }}</label>
                        </div>
                        <div class="col-lg-8">
                            <ng-select
                                [(ngModel)]="strSiteRole"
                                [items]="objFilterOption['site_roles']"
                                [clearable]="true"
                                (change)="setFilter()"
                                [ngModelOptions]="{standalone: true}"
                                bindLabel="text"
                                bindValue="id"
                                id="widgetRolesSiteRoleFilter">
                                <ng-template ng-option-tmp let-item="item" class="fs-11">
                                  <span id="widgetRolesSiteRoleFilter{{ item.id }}">{{ item.text | translate }}</span>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" class="fs-11">
                                    {{ item.text | translate }}
                                </ng-template>
                          </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', objTemplateLabel.title_icon]" aria-hidden="true"></fa-icon> &nbsp; {{ objTemplateLabel.title | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetRolesRefreshButton_{{ strRelatedModule }}" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
                        <li [ngClass]="previousButton" class="page-item">
                            <a id="widgetRolesPreviousButton_{{ strRelatedModule }}" class="page-link" aria-label="first" (click)="getRecord('prev', objFilter)">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="nextButton" class="page-item">
                            <a id="widgetRolesNextButton_{{ strRelatedModule }}" class="page-link" aria-label="first" (click)="getRecord('next', objFilter)">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div *ngIf="arRoleList.length < 1">
            <div class="row border-top" *ngIf="!bLoading">
                <span class="no-content no-record-found">
                    {{ objTemplateLabel.no_record | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="bLoading">
                <span class="no-content text-center">
                    <inline-view-loader [isLoading]="true"></inline-view-loader>
                </span>
            </div>
        </div>
        <div *ngIf="strRelatedModule == 'contacts'">
            <app-role-contacts [strModule]="strModule" [arContactList]="arRoleList" [arOption]="objFilterOption" (objAction)="onChangeRecord($event)"></app-role-contacts>
        </div>
        <div *ngIf="strRelatedModule == 'customers'">
            <app-role-customers [arCustomerList]="arRoleList" (objAction)="onChangeRecord($event)"></app-role-customers>
        </div>
        <div *ngIf="strRelatedModule == 'sites'">
            <app-role-sites [arSiteList]="arRoleList" (objAction)="onChangeRecord($event)"></app-role-sites>
        </div>
        <div *ngIf="strRelatedModule == 'opportunities'">
            <app-role-opportunities [arOpportunityList]="arRoleList" (objAction)="onChangeRecord($event)"></app-role-opportunities>
        </div>
    </div>
</div>
