<header>
  <div class="d-flex header-container header-label">
    <span class="header-title">
      <h4 class="page-title"><span>{{ 'stock-transfers' | translate }}</span></h4>
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button class="btn ml-1 btn-primary" (click)="cancelDialog()"><fa-icon [icon]="['fas', 'times']" transform="shrink-3"class="pointer"></fa-icon> {{ 'cancel' | translate }}</button>
        </li>
        <li class="list-inline-item">
          <button class="btn ml-1 btn-primary" (click)="addStockTransferQuantity()" translate><fa-icon [icon]="['fas', isUpdate ? 'pencil' : 'plus']" transform="shrink-3"class="pointer"></fa-icon> {{ isUpdate ? 'update_product' : 'add_product' }}</button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>
<mat-dialog-content class="mat-dialog-content {{ matDialogContentClass }}">
  <table *ngIf="!loading" class="table table-striped w100p">
    <thead>
      <tr>
        <th scope="col" class="w75p">{{ 'product' | translate }}</th>
        <th scope="col" class="w20p">{{ 'quantity' | translate }}</th>
        <th scope="col" class="w05p">
          <fa-icon class="text-success fs16 d-inline pointer float-right" (click)="addProduct()" [icon]="['fas', 'plus-circle']"></fa-icon>
        </th>
      </tr>
    </thead>
  </table>
  <div *ngIf="!loading" >
  <div *ngFor="let config of stockTransferQuantityForm; let i = index;">
    <form [formGroup]="config.form">
    <table class="table w100p mb-0">
      <tbody>
        <tr>
          <td class="w50p">
            <ng-select
              [items]="config.item_obv | async"
              [loading]="config.item_loading"
              [typeahead]="config.item_typeahead"
              class="w-100 item-{{ config.id }}"
              bindLabel="text"
              bindValue="id"
              formControlName="item_id"
              (change)="onChangeItem(config.id, config.form, $event)"
              [ngClass]="{ 'is-invalid': isFormInvalid(config.form, 'item_id') }"
            >
              <ng-template ng-option-tmp let-item="item">
                <span>{{ item.name | translate }} <span style="font-size: 9px;">( {{ item.code }} )</span></span>
              </ng-template>

              <ng-template ng-label-tmp let-item="item">
                <span class="font-size-12">{{ item.name }}</span>
              </ng-template>
            </ng-select>
          </td>
          <td class="w50p">
            <input
              type="number"
              formControlName="quantity"
              class="form-control font-size-11 input-height-40 quantity-{{ config.id }}"
              [ngClass]="{ 'is-invalid': isFormInvalid(config.form, 'quantity') }"
            />
          </td>
          <td class="w50p">
            <fa-icon class="text-danger pointer fs16 float-right" (click)="removeStockTransferQuantity(config)" [icon]="['fal', 'minus-circle']"></fa-icon>
          </td>
        </tr>
      </tbody>
    </table>
    </form>
  </div>
  </div>
</mat-dialog-content>