export interface TaxCodeInterface {

    /**
     * Code of the tax.
     */
    code?: string

    /**
     * Id of the tax code in Fieldmagic.
     */
    id?: string

    /**
     * Flag if the tax code is purchase.
     */
    is_purchase?: boolean

    /**
     * Flag if the tax code is sales.
     */
    is_sales?: boolean

    /**
     * Flag if the tax code is demo data.
     */
    is_demo_data?: boolean

    /**
     * Name of the tax code.
     */
    name?: string

    /**
     * Rate of the tax code.
     */
    rate?: number

    /**
     * For tax that have multiple rates.
     */
    tax_rates?: string

    /**
     * Combined object to identify if the tax is both
     * sales and purchase.
     */
    tax_type?: any

    /**
     * Main label of the tax code for relate
     * fields.
     */
    text?: string
}

export class TaxCode implements TaxCodeInterface {

    code: string;
    id: string;
    is_purchase: boolean;
    is_sales: boolean;
    is_demo_data: boolean;
    name: string;
    rate: number;
    tax_rates: string;
    tax_type: any;
    text: string;

    constructor(properties: TaxCodeInterface) {
        this.code = properties.code;
        this.id = properties.id;
        this.is_purchase = properties.is_purchase;
        this.is_sales = properties.is_sales;
        this.is_demo_data = properties.is_demo_data;
        this.name = properties.name;
        this.rate = properties.rate;
        this.tax_rates = properties.tax_rates;
        this.tax_type = properties.tax_type;
        this.text = properties.text;
    }
}

