<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <fa-icon [icon]="['far', 'arrow-alt-circle-right']" transform="shrink-3" class="dialog-title"></fa-icon>
      {{ 'convert_' +strModule | translate }} {{ strRecordTitle }}
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="closeConvertDialog()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>
<mat-dialog-content class="mobile-view-dialog">

  <ng-container *ngIf="bShowAdditionalDataSection">
    <div>
      <ng-container *ngIf="bDepartmentTracking">
        <label class="ml-1 align-self-center">{{ 'department' | translate }}</label>
        <required-tag [hasSpace]="false"></required-tag>
        <ng-select class="mt-2"
          [items]="openSelectionObservables.department_id.obv | async"
          [loading]="openSelectionObservables.department_id.loader"
          [typeahead]="openSelectionObservables.department_id.typeahead"
          bindValue="id"
          bindLabel="text"
          [required]=true
          [formControl]="objAdditionalData.department_id"
          [ngClass]="{'is-invalid': !objAdditionalData.department_id.valid}"
          (open)="initSelectionData('department_id')">
        </ng-select>
        <br>
      </ng-container>

      <label class="ml-1 align-self-center">{{ 'notes' | translate }}</label>
      <required-tag [hasSpace]="false"></required-tag>
      <textarea class="form-control mt-2" rows="5" [formControl]="objAdditionalData.summary" [ngClass]="{'is-invalid': !objAdditionalData.summary.valid && objAdditionalData.summary.touched}"></textarea>

      <div class="float-right mt-3">
        <button class="btn btn-primary" (click)="hideAdditionalDataSection()" [disabled]="bButtonSubmit"><fa-icon [icon]="['fas', 'arrow-left']" class="mr-1"></fa-icon>{{ 'back_to_conversion' | translate }} </button>
        &nbsp;

        <button (click)="sendTheRequest(strModuleToBeConverted)" class="btn btn-success" [disabled]="bButtonSubmit">
          <fa-icon *ngIf="!bShowLoader[strModuleToBeConverted]" [icon]="['fas', 'plus']"></fa-icon>
          <fa-icon *ngIf="bShowLoader[strModuleToBeConverted]" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
          <span class="pl-1">{{ 'continue_converting_to' | translate }} {{ 'single.' + strModuleToBeConverted  | translate }}</span>
        </button>
      </div>
      <br><br>
    </div>
  </ng-container>

  <ng-container *ngIf="bLoaded && !bShowAdditionalDataSection">
    <mat-accordion *ngIf="arModel">
      <div *ngFor="let data of arConvertList[strModule]" >
        <mat-expansion-panel *ngIf="arModel[data.module]" [disabled]="true" hideToggle="true" [expanded]="arModel[data.module]['expand']">
        <mat-expansion-panel-header
          *ngIf="data.display && arModuleRecords[data.module]"
          class="border-top col-md-12 panel-header bg-success"
          [ngClass]="{'bg-warning': arModuleRecords[data.module].length < 1 && arModel[data.module]['type'] == '', 'bg-danger': arModel[data.module]['type'] == 'empty_value' || arModel[data.module]['type'] == 'selected' || arModel[data.module]['type'] == 'multiple_duplicate'}"
          (click)="(arModel[data.module]['type'] != 'empty_value') ? onChangePanel(data.module, arModel[data.module]['expand'], $event) : ''">
          <mat-panel-title class="col-md-3 align-middle panel-content">
            <span class="w025" align="center" class="align-self-center">
              <fa-icon [icon]="['far', data.icon]" class="pt01"></fa-icon>
            </span>
            <span class="ml-1 align-self-center">{{ data.label | translate }}</span>
          </mat-panel-title>
          <mat-panel-description *ngIf="arModuleRecords[data.module]" class="col-md-9"  class="fs010 panel-content">
            <div [ngSwitch]="arModel[data.module]['type']" class="limit-text-height">
              <div *ngSwitchCase="'create'">
                  <span *ngIf="arModuleRecords[data.module].length == 0">
                    {{ 'convert_' +data.module+ '_' +arModel[data.module]['type']+ '_wo_duplicate' | translate }}
                  </span>
                  <span *ngIf="arModuleRecords[data.module].length != 0">
                    {{ 'convert_' +data.module+ '_' +arModel[data.module]['type']+ '_w_duplicate' | translate }}
                  </span>
              </div>
              <span *ngSwitchCase="'relate'">
                  {{ 'convert_' +data.module+ '_' +arModel[data.module]['type'] | translate }}
              </span>
              <span *ngSwitchCase="'empty_value'">
                  {{ 'convert_' +data.module+ '_' +arModel[data.module]['type'] | translate }}
              </span>
              <span *ngSwitchCase="'multiple_duplicate'">
                  {{ 'found' | translate }} {{ arModuleRecords[data.module].length }} {{ 'matches' | translate }} {{ 'convert_click_to_review' | translate }}
              </span>
              <span *ngSwitchCase="'selected'">
                <span *ngIf="arModuleRecords[data.module].length == 1">
                  {{ 'found' | translate }} {{ arModuleRecords[data.module].length }} {{ 'convert_match' | translate }}.
                  <a class="duplicate_link" [routerLink]="data.module+ '/' +arModel[data.module]['data']"
                    target="_blank">
                    {{ 'convert_click_to_review' | translate }}
                  </a>
                </span>
                <span *ngIf="arModuleRecords[data.module].length != 1">
                    {{ 'selected' | translate }}: {{ getRecordData(data.module, arModel[data.module].data, true) }}
                  </span>
              </span>
              <span *ngSwitchDefault>
                {{ 'convert_' +data.module+ '_empty' | translate }}
              </span>
            </div>
          </mat-panel-description>
          <span class="panel-content align-self-center">
            <fa-icon [icon]="['far', (arModel[data.module]['expand']) ? 'angle-up' : 'angle-down']" class="pointer" aria-hidden="true"></fa-icon>
          </span>
        </mat-expansion-panel-header>
        <div *ngIf="arModuleRecords[data.module]">
          <table class="mt-3 w-100">
            <tr>
              <td width="60%">
                <ng-container *ngIf="data.module === 'customers'">
                  <span *ngIf="arRecord['company']; else Individual"> <b>{{ 'customer_entered' | translate }}:</b> {{ arRecord['company']}} </span>
                  <ng-template #Individual>
                    <span> <b>{{ 'customer_entered' | translate }}:</b> {{ arRecord['first_name'] }} {{ arRecord['last_name']}}</span>
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="data.module === 'sites'">
                  <span> <b>{{ 'address_entered' | translate }}:</b> {{ arRecord['address_text'] }}</span>
                </ng-container>

                <ng-container *ngIf="data.module === 'contacts'">
                  <span> <b>{{ 'contact_entered' | translate }}:</b> {{ arRecord['first_name'] }} {{ arRecord['last_name']}}</span>
                </ng-container>
              </td>
              <ng-container *ngIf="data.module === 'sites'">
                <td width="40%" class="row w-80" align="center">
                  <div class="row col-12">
                    <small>
                      {{ 'customer_link_info' | translate }}
                   </small>
                  </div>
                  <div class="row">
                    <mat-slide-toggle
                      color="primary"
                      class="value-text"
                      id="isActive-input-chkbx"
                      [disabled]="!arModel[data.module]['data']"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="isCustomerLink"
                    >
                    </mat-slide-toggle>
                  </div>
                </td>
              </ng-container>
            </tr>
          </table>
          <table class="table w-100 mb-0">
            <tr>
              <td>

                <form [formGroup]="arRelateFields[data.module]['form']" >
                  <!-- <app-edit-field
                    [item]="arRelateFields[data.module]['item']"
                    [form]="arRelateFields[data.module]['form']"
                    [strMode]="data.module"
                    (click)="onChange(data.module)"
                  >
                  </app-edit-field> -->
                  <ng-select *ngIf="bIsGlobalSearch"
                    [items]="arNgSelectFields[data.module]['obv'] | async"
                    [typeahead]="arNgSelectFields[data.module]['typehead']"
                    [loading]="arNgSelectFields[data.module]['loader']"
                    [clearable]="true"
                    [ngModelOptions]="{standalone: true}"
                    bindLabel="name"
                    bindValue="id"
                    [(ngModel)]="arNgSelectFields[data.module]['value']"
                    groupBy="match_percentage"
                    (change)="onChange(data.module)"
                    [id]="arNgSelectFields[data.module]['name']"
                    [name]="arNgSelectFields[data.module]['name']">

                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ (item.match_percentage / 100) | percent }} {{ 'matches' | translate }}
                    </ng-template>
                  </ng-select>

                  <ng-select *ngIf="!bIsGlobalSearch"
                    [items]="arNgSelectFields[data.module]['obv'] | async"
                    [typeahead]="arNgSelectFields[data.module]['typehead']"
                    [loading]="arNgSelectFields[data.module]['loader']"
                    [clearable]="true"
                    [ngModelOptions]="{standalone: true}"
                    bindLabel="text"
                    bindValue="id"
                    [(ngModel)]="arNgSelectFields[data.module]['value']"
                    (change)="onChange(data.module)"
                    [id]="arNgSelectFields[data.module]['name']"
                    [name]="arNgSelectFields[data.module]['name']">
                  </ng-select>
                </form>
              </td>
              <td class="w-25" align="center">
                  <input
                    type="radio"
                    name="{{ data.module }}"
                    value="relate"
                    [required]="true"
                    [disabled]="arModel[data.module]['disable']"
                    [(ngModel)]="arModel[data.module]['type']"
                    (click)="storeRecord(data.module, 'relate')" />
              </td>
            </tr>
            <tr *ngFor="let arRecord of arModuleRecords[data.module]">
              <td class="w-75">
                <a class="duplicate-record" [routerLink]="data.module+ '/' +arRecord.id"
                  target="_blank">
                  {{ formatRecord(data.filter_id, arRecord) }}
                </a>
              </td>
              <td class="w-25" align="center">
                  <input
                    type="radio"
                    name="{{ data.module }}"
                    [value]="arRecord.id"
                    [required]="true"
                    [(ngModel)]="arModel[data.module]['data']"
                    (click)="storeRecord(data.module, 'selected', arRecord.id)"/>
              </td>
            </tr>
            <tr class="border-bottom">
              <td class="w-75" align="center">
                {{ 'create_a_new_' +data.label | translate }}
              </td>
              <td class="w-25" align="center">
                <input
                  type="radio"
                  name="{{ data.module }}"
                  value="create"
                  [required]="true"
                  [(ngModel)]="arModel[data.module]['type']"
                  (click)="storeRecord(data.module, 'create')" />
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="!arModuleRecords[data.module]" align="center">
          <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="loading-spinner" aria-hidden="true"></fa-icon>
        </div>
      </mat-expansion-panel>
      </div>
    </mat-accordion>
    <div class="ml-auto d-flex" align="center" class="mt-3 w-100">
      <ul class="list-inline" >
        <li class="list-inline-item" *ngFor="let data of arConvertOptional[strModule]">
          <button (click)="showAdditionalDataSection(data)" class="btn btn-primary" [ngClass]="(bShowLoader[data])? 'btn-spinner' : ''" [disabled]="bButtonSubmit">
            <fa-icon *ngIf="!bShowLoader[data]" [icon]="['fas', 'plus']"></fa-icon>
            <fa-icon *ngIf="bShowLoader[data]" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            <span class="pl-1">{{ 'convert_and_create_' +data | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button (click)="sendTheRequest('convert_only')" class="btn btn-primary" [ngClass]="(bShowLoader['convert_only'])? 'btn-spinner' : ''" [disabled]="bButtonSubmit">
            <fa-icon *ngIf="!bShowLoader['convert_only']" [icon]="['fas', 'plus']"></fa-icon>
            <fa-icon *ngIf="bShowLoader['convert_only']" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            <span class="pl-1">{{ 'convert_only' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container *ngIf="!bLoaded">
    <div align="center">
      <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
    </div>
  </ng-container>
  <hr />
</mat-dialog-content>