import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import awsmobile from '../../aws-exports';
import * as APIService from '../API.service';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { } from'apollo-client';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import AWSAppSyncClient, { AUTH_TYPE, AWSAppSyncClientOptions } from 'aws-appsync';
import { map, filter as rxjsFilter, timeout, catchError, tap } from 'rxjs/operators';

import { has } from 'lodash';
import { AuthService } from '../auth/auth.service';
import { LooseObject } from '../objects/loose-object';
import { NotificationService } from './notification.service';
import { LocalStorageService } from './local-storage.service';
import { ErrorEventType, ErrorPublishingService } from './error-publishing.service';

interface LooseObjectWithData extends LooseObject {
  /**
   * All GraphQL response data is contained within a response's `data` attribute.
   *
   * @type {LooseObject}
   */
  data: LooseObject;
}

@Injectable({
  providedIn: 'root'
})
/**
 * A note regarding the GraphQL methods in this class:
 * These methods are just replicated from the methods generated
 * by Amplify in API.service.ts because I still haven't
 * figured out how to properly use their service.
 *
 * I hit an authentication issue blocking me from using it so
 * if you figure out how to do it, kindly replace all usages of
 * the said methods to use the one in API.service.ts
 */
export class AWSAppSyncService {

  /**
   * Instance of our AWSAppSyncClient. Should only
   * be set once; on this service's constructor.
   *
   * @type {any}
   */
  private _client: AWSAppSyncClient<NormalizedCacheObject>;

  /**
   * AppSyncClient Options
   */
  private _clientOptions: AWSAppSyncClientOptions;

  /**
   * How long should we wait for requests in milliseconds before we throw a
   * timeout error.
   *
   * @type {Number}
   */
  private readonly GRAPHQL_REQUEST_TIMEOUT: number = 30000;

  /**
   * Our service's constructor. We only make property
   * initializations and configurations here.
   *
   * @todo Move AppSync client options to an external config file.
   */
  constructor(
    protected localStorageService: LocalStorageService,
    protected authService: AuthService,
    protected router: Router,
    protected notificationService: NotificationService,
    protected errors: ErrorPublishingService
  ) {
    this._initClient();
  }

  /**
   * This will return an observable that listens to all task
   * information updates. Use this to update the data on-screen
   *
   * @returns Observable<APIService.UpdatedTaskSubscription>
   */
  UpdatedTaskListener(client_id: string): Observable<APIService.UpdatedTaskSubscription> {
    return from(this._client.subscribe({
      query: gql(
        `subscription UpdatedTask($client_id: String!) {
          updatedTask(client_id: $client_id) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id }
    }))
    .pipe(
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({ data: { updatedTask: [] } }, response)),
      map(response => response.data.updatedTask),
      rxjsFilter(response => response !== undefined && response !== null)
    );
  }

/**
 * Returns an observable that listens for newly created tasks.
 *
 * @returns Observable<APIService.NewTaskSubscription>
 */
  NewTaskListener(client_id: string): Observable<APIService.NewTaskSubscription> {
    return from(this._client.subscribe({
      query: gql(
        `subscription NewTask($client_id: String!) {
          newTask(client_id: $client_id) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id }
    }))
    .pipe(
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({ data: { newTask: [] } }, response)),
      map(response => response.data.newTask),
      rxjsFilter(response => response !== undefined && response !== null)
    );
  }

  /**
   * Returns an observable that listens for newly created tasks using create bulk task.
   *
   * @returns Observable<APIService.NewBulkTaskSubscription[]>
   */
  NewBulkTaskListener(client_id: string): Observable<APIService.NewBulkTaskSubscription[]> {
      return from(this._client.subscribe({
        query: gql(
          `subscription NewBulkTask {
            newBulkTask {
              __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
            }
          }`
        )
      }))
      .pipe(
        catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
        map(response => this.replaceBrokenResponseWith({ data: { newBulkTask: [] } }, response)),
        map(response => response.data.newBulkTask),
        rxjsFilter(response => response !== undefined && response !== null),
        map(response => response.filter(task => task.client_id === client_id))
      );
    }

  /**
   * Waits for newly scheduled tasks, then returns it back
   * to the subscriber
   *
   * @returns {Observable<APIService.ScheduledTaskSubscription>}
   */
  ScheduledTaskListener(client_id: string): Observable<APIService.ScheduledTaskSubscription> {
    return from(this._client.subscribe({
      query: gql(
        `subscription ScheduledTask($client_id: String!) {
          scheduledTask(client_id: $client_id) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id }
    }))
    .pipe(
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({ data: { scheduledTask: [] } }, response)),
      map(response => response.data.scheduledTask),
      rxjsFilter(response => response !== undefined && response !== null)
    );
  }

  /**
   * Waits for newly scheduled tasks, then returns it back
   * to the subscriber
   *
   * @returns Observable<APIService.UnscheduledTaskSubscription>
   */
  UnScheduledTaskListener(client_id: string): Observable<APIService.UnscheduledTaskSubscription> {
    return from(this._client.subscribe({
      query: gql(
        `subscription UnscheduledTask($client_id: String!) {
          unscheduledTask(client_id: $client_id) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id }
    }))
    .pipe(
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({ data: { unscheduledTask: [] } }, response)),
      map(response => response.data.unscheduledTask),
      rxjsFilter(response => response !== undefined && response !== null)
    );
  }

  /**
   * Creates a new task. Tasks are just activity records (stored
   * in the "activities" table) with an `activity_type` of 'task'
   *
   * @param user_id
   * @param auto_id
   * @param job_id
   * @param activity_name
   * @param activity_time
   * @param client_id
   * @param due_date
   * @param activity_date
   * @param created_by
   * @param priority
   * @param notes
   *
   * @returns {Observable<APIService.CreateTaskMutation>}
   */
  CreateTask(
    client_id: string,
    job_id: string,
    activity_name: string,
    estimated_duration: number,
    due_date: string,
    activity_date: string,
    priority: string,
    notes: string,
    user_id: string,
    site_id: string,
    department_id: string
  ): Observable<APIService.CreateTaskMutation> {
    return from(this._client.mutate({
      mutation: gql(
        `mutation CreateTask($client_id: ID!, $job_id: ID!, $activity_name: String!, $estimated_duration: Float!, $due_date: String, $activity_date: String!, $priority: String!, $notes: String, $user_id: ID, $site_id: ID, $department_id: ID) {
          createTask(client_id: $client_id, job_id: $job_id, activity_name: $activity_name, estimated_duration: $estimated_duration, due_date: $due_date, activity_date: $activity_date, priority: $priority, notes: $notes, user_id: $user_id, site_id: $site_id, department_id: $department_id) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id, job_id, activity_name, estimated_duration, due_date, activity_date, priority, notes, user_id, site_id, department_id }
    }))
    .pipe(
      timeout(this.GRAPHQL_REQUEST_TIMEOUT),
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({ data: { createTask: [] } }, response)),
      map(response => response.data.createTask),
      rxjsFilter(response => response !== undefined && response !== null)
    );
  }

  /**
   * Creates multiple new task in one request
   *
   * @param client_id
   * @param job_id
   * @param task
   *
   * @returns {Observable<APIService.CreateTaskMutation>}
   */
  CreateBulkTask(
    client_id: string,
    job_id: string,
    task: string
  ): Observable<APIService.CreateTaskMutation> {
    return from(this._client.mutate({
      mutation: gql(
        `mutation CreateBulkTask($client_id: ID!, $job_id: ID!, $task: String!) {
          createBulkTask(client_id: $client_id, job_id: $job_id, task: $task) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id, job_id, task }
    }))
    .pipe(
      timeout(this.GRAPHQL_REQUEST_TIMEOUT),
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => response instanceof Error ? { data: { createBulkTask: [] } } : response),
      map(response => response.data.createBulkTask),
      rxjsFilter(response => response !== undefined && response !== null)
    );
  }

  /**
   * Returns all tasks whose `due_date` lies between
   * "due_date_start" and "due_date_end"
   *
   * @param client_id
   * @param due_date_start
   * @param due_date_end
   * @param pagination
   *
   * @returns {Observable<APIService.GetScheduledTasksDueQuery[]>}
   */
  GetScheduledTasksDue(client_id: string, due_date_start: string, due_date_end: string, pagination?: APIService.Pagination): Observable<APIService.GetScheduledTasksDueQuery[]> {
    return from(this._client.query({
      query: gql(
        `query GetScheduledTasksDue($client_id: String!, $due_date_start: String!, $due_date_end: String!, $pagination: Pagination) {
          getScheduledTasksDue(client_id: $client_id, due_date_start: $due_date_start, due_date_end: $due_date_end, pagination: $pagination) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id, due_date_start, due_date_end, ...(pagination !== undefined && { pagination: pagination }) },
      errorPolicy: 'none',
    }))
    .pipe(
      timeout(this.GRAPHQL_REQUEST_TIMEOUT),
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({ data: { getScheduledTasksDue: [] } }, response)),
      map(response => response.data.getScheduledTasksDue),
      map(response => response == undefined || response == null ? [] : response)
    );
  }

  /**
   * Returns all users under the given `client_id`
   *
   * @param {String} client_id
   * @param {String} filter
   * @param {APIService.Pagination} pagination
   *
   * @returns {Observable<APIService.GetUsersQuery[]>}
   */
  GetUsers(client_id: string, filter?: APIService.UsersFilter, pagination?: APIService.Pagination, sorting?: APIService.Sorting): Observable<APIService.GetUsersQuery[]> {
    return from(this._client.query({
      query: gql(
        `query GetUsers($client_id: ID!, $filter: UsersFilter, $pagination: Pagination, $sorting: Sorting) {
          getUsers(client_id: $client_id, filter: $filter, pagination: $pagination, sorting: $sorting) {
            __typename id email_address nickname first_name last_name locale mobile_config phone department_ids created_at updated_at job_title name
          }
        }`
      ),
      variables: { client_id, filter, pagination, ...(sorting !== undefined && { sorting: sorting }) }
    }))
    .pipe(
      timeout(this.GRAPHQL_REQUEST_TIMEOUT),
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({ data: { getUsers: [] } }, response)),
      map(response => response.data.getUsers),
      map(response => response == undefined || response == null ? [] : response)
    );
  }

  /**
   * Returns all tasks under the given `client_id` that can be scheduled.
   * A task is said to be schedulable if its `due_date` is null.
   *
   * @param client_id
   * @param filter
   * @param _page_starting_at
   * @param _page_ending_at
   *
   * @returns {Observable<APIService.GetCalendarSchedulableTasksQuery[]>}
   */
  GetCalendarSchedulableTasks(
    client_id: string,
    filter?: APIService.TasksFilter,
    pagination?: APIService.Pagination,
    sorting?: APIService.Sorting
  ): Observable<APIService.GetCalendarSchedulableTasksQuery[]> {
    return from(this._client.query({
      query: gql(
        `query GetCalendarSchedulableTasks($client_id: ID!, $filter: TasksFilter, $pagination: Pagination, $sorting: Sorting) {
          getCalendarSchedulableTasks(client_id: $client_id, filter: $filter, pagination: $pagination, sorting: $sorting) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: {
        client_id,
        ...(filter !== undefined && { filter: filter }),
        ...(pagination !== undefined && { pagination: pagination }),
        ...(sorting !== undefined && { sorting: sorting }),
      },
      errorPolicy: 'none',
    })).pipe(
      timeout(this.GRAPHQL_REQUEST_TIMEOUT),
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({data: {getCalendarSchedulableTasks: []}}, response)),
      map(response => response.data.getCalendarSchedulableTasks),
      map(response => response == undefined || response == null ? [] : response)
    );
  }

  /**
   * Returns all departments under the given client
   *
   * @param client_id
   * @param pagination
   * @param sorting
   *
   * @returns {Observable<APIService.GetDepartmentsQuery[]>}
   */
  GetDepartments(client_id: string, pagination?: APIService.Pagination, sorting?: APIService.Sorting): Observable<APIService.GetDepartmentsQuery[]> {
    return from(this._client.query({
      query: gql(
        `query GetDepartments($client_id: ID!, $pagination: Pagination, $sorting: Sorting) {
          getDepartments(client_id: $client_id, pagination: $pagination, sorting: $sorting) {
            __typename id department_name
          }
        }`
      ),
      variables: { client_id, ...(pagination !== undefined && { pagination: pagination }), ...(sorting !== undefined && { sorting: sorting }) }
    })).pipe(
      timeout(this.GRAPHQL_REQUEST_TIMEOUT),
      catchError(error => of(error).pipe(tap(error => this._catchError(this, error)))),
      map(response => this.replaceBrokenResponseWith({ data: { getDepartments: [] } }, response)),
      map(response => response.data.getDepartments),
      map(response => response == undefined || response == null ? [] : response)
    );
  }

  /**
   * Mutation for updating a task's information.
   *
   * @param client_id
   * @param id
   * @param activity_name
   * @param estimated_duration
   * @param technician_id
   * @param due_date
   * @param job_task_eta
   * @param created_by
   * @param priority
   * @param notes
   * @param task_progress
   * @param activity_date
   *
   * @returns Promise<APIService.UpdateTaskMutation>
   */
  UpdateTask(
    client_id: string,
    id: string,
    activity_name?: string,
    estimated_duration?: number,
    technician_id?: string,
    due_date?: string,
    job_task_eta?: string,
    created_by?: string,
    priority?: string,
    notes?: string,
    task_progress?: string,
    activity_date?: string
  ): Promise<APIService.UpdateTaskMutation> {
    return this._client.mutate({
      mutation: gql(
        `mutation UpdateCalendarTask($client_id: ID!, $id: ID!, $activity_name: String, $activity_due_date: String, $estimated_duration: Float, $technician_id: ID, $due_date: String, $activity_date: String, $created_by: ID, $priority: String, $notes: String, $task_progress: String, $job_task_eta: String) {
          updateCalendarTask(client_id: $client_id, id: $id, activity_name: $activity_name, activity_due_date: $activity_due_date, estimated_duration: $estimated_duration, technician_id: $technician_id, due_date: $due_date, activity_date: $activity_date, created_by: $created_by, priority: $priority, notes: $notes, task_progress: $task_progress, job_task_eta: $job_task_eta) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: {
        client_id,
        id,
        ...(activity_name !== undefined && { activity_name: activity_name }),
        ...(estimated_duration !== undefined && { estimated_duration: estimated_duration }),
        ...(technician_id !== undefined && { technician_id: technician_id }),
        ...(due_date !== undefined && { due_date: due_date }),
        ...(job_task_eta !== undefined && { job_task_eta: job_task_eta }),
        ...(activity_date !== undefined && { activity_date: activity_date }),
        ...(created_by !== undefined && { created_by: created_by }),
        ...(priority !== undefined && { priority: priority }),
        ...(notes !== undefined && { notes: notes }),
        ...(task_progress !== undefined && { task_progress: task_progress })
      }
    }).catch((error) => { this._catchError(this, error); });
  }

  /**
   * Schedules a task by setting its `task_progress`
   *
   * @param client_id
   * @param id
   * @param technician_id
   * @param due_date
   *
   * @returns {Promise<APIService.ScheduleTaskMutation>}
   */
  ScheduleTask(client_id: string, id: string, technician_id: string, due_date: string): Promise<APIService.ScheduleTaskMutation> {
    return this._client.mutate({
      mutation: gql(
        `mutation ScheduleTask($client_id: ID!, $id: ID!, $technician_id: ID!, $due_date: String!) {
          scheduleTask(client_id: $client_id, id: $id, technician_id: $technician_id, due_date: $due_date) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id, id, technician_id, due_date }
    }).catch((error) => { this._catchError(this, error); });
  }

  /**
   * Sets the task's `task_progress` field to "awaiting_scheduling"
   *
   * @param client_id
   * @param id
   *
   * @returns {Promise<APIService.UnscheduleTaskMutation>}
   */
  UnscheduleTask(client_id: string, id: string, user_id: string): Promise<APIService.UnscheduleTaskMutation> {
    return this._client.mutate({
      mutation: gql(
        `mutation UnscheduleTask($client_id: ID!, $id: ID!, $user_id: ID!) {
          unscheduleTask(client_id: $client_id, id: $id, user_id: $user_id) {
            __typename id client_id task_id customer_id activity_name activity_date task_priority activity_due_date activity_duration notes job_id user_id created_at task_progress department_id date_completed user_first_name user_last_name job_number job_address job_type job_priority job_summary job_internal_notes job_customer_name job_custom_attributes job_department_id job_department_name job_amount_to_invoice job_due_date job_estimated_timeframe job_amount_actually_invoiced job_po_number job_work_order_number job_date_completed site_address site_email_address site_id job_task_eta site_address_lng site_address_lat is_custom_location site_notes activity_date_completed updated_at jobs_address_geolocation safety { __typename has_signature }
          }
        }`
      ),
      variables: { client_id, id, user_id }
    }).catch((error) => { this._catchError(this, error); });
  }

  /**
   * Returns the replacement value when the given subject is an instance of Error or
   * if it doesn't have a `data` attribute.
   *
   * @param {LooseObjectWithData}               replacement
   * @param {LooseObjectWithData | LooseObject} response
   *
   * @returns {LooseObjectWithData}
   */
  protected replaceBrokenResponseWith(
    replacement: LooseObjectWithData,
    response: LooseObjectWithData | LooseObject
  ): LooseObjectWithData {
    if (response instanceof Error === false && has(response, ['data'])) {
      return <LooseObjectWithData>response;
    }

    return replacement;
  }

  /**
   * Returns a configured instance of AWSAppSyncClient,
   * ready to be used for querying data from the GraphQL
   * server.
   *
   * @returns {any}
   */
  private _initClient(): any {
    // Default config for our AppSync client. These values
    // should be stored in a config file.
    this._clientOptions = {
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: AUTH_TYPE[awsmobile.aws_appsync_authenticationType],
        jwtToken: this.localStorageService.getItem('access_token'),
      },
      disableOffline: true
    };

    this._client = new AWSAppSyncClient(this._clientOptions, {
      defaultOptions: {
        query: { fetchPolicy: 'network-only', errorPolicy: 'all' }
      }
    });

    return this._client;
  }

  /**
   * Error handler for all AWS AppSync request for unified
   * error handling.
   *
   * @param error
   *
   * @returns {void}
   */
  private _catchError(context, error): void {
    if (error.message == 'Network error: Response not successful: Received status code 401') {
      console.warn('Access token used by GraphQL has expired. Renewing...');
      context.authService.renewToken().then(
        () => {
          // Just after renewal, we need to reinitialize our AppSync client
          // so that the access token it is currently using will get updated.
          context._initClient();
          context.router.navigate(['/']);
        },
        (err) => { context.notificationService.sendNotification('header_error', 'invalid_authentication', 'danger'); },
      );
    } else {
      console.error('An error has occurred with your GraphQL request', error);
    }

    this.handleGraphQLErrors(error.graphQLErrors || []);
  }

  /**
   * Handles all the graphql errors
   *
   * @param {GraphQLError[]} arErrors
   *
   * @returns {void}
   */
  private handleGraphQLErrors(arErrors: GraphQLError[]): void {
    for (let objError of arErrors) {
      if (objError.errorInfo.errorCode === 403) {
        this.errors.publish({
          type: ErrorEventType.HTTP_ERROR,
          status: 403,
          for: 'calendar',
        });
      }
    }
  }
}

type GraphQLErrorInfo = {
  /**
   * Error code specified for the request possibly the status code
   *
   * @type {number}
   */
  errorCode: number;
}

type GraphQLError = {
  /**
   * Contains the current error information like code
   *
   * @type {GraphQLErrorInfo}
   */
  errorInfo: GraphQLErrorInfo;
};