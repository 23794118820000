import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Sms } from '../../../lists/sms-included-modules';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SmsService } from '../../../services/sms.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { isEmpty } from 'lodash';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { StatusCode } from '../../../lists/status-code';
import { SmsTemplate } from '../../../objects/sms-template';
@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.scss']
})
export class SaveComponent implements OnInit {

  /**
   * Determines if the component is in create or edit mode
   *
   * @type {boolean}
   */
  isCreate: boolean = true;

  /**
   *
   * @type {any}
   */
   metadata: any;

  /**
   * Contains the message to be sent in sms
   *
   * @type {string}
   */
  strMessage: string = '';

  /**
   * Determines on what module the template will show
   *
   * @type {string}
   */
  strModule: string = null;


  /**
   * If the mode is edit, this will be the storage of the record id
   *
   * @type {string}
   */
  strRecordId: string = null;

  /**
   * This is just for the displaying of template
   *
   * @type {string}
   */
  strName: string = null;

  /**
   * This is just for the displaying of template
   *
   * @type {string}
   */
  strField: string = null;

  /**
   * Storage of the modules and their fields
   *
   * @type {any}
   */
  objAvailableFields: any;

  /**
   * List of objects needed in creating/updating sms template
   *
   * @type {object}
   */
  objSms = new Sms;

  constructor(
    public dialogRef: MatDialogRef<SaveComponent>,
    public smsService: SmsService,
    public recordService: RecordService,
    public notificationService: NotificationService,
    public localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public record: SmsTemplate)
  {
    this.isCreate = isEmpty(this.record);

    if (! this.isCreate) {
      this.strMessage = record.message;
      this.strModule = record.module;
      this.strName = record.name;
      this.strRecordId = this.record.id;
    }
  }

  ngOnInit() {
    this.initializeSmsFields();
  }

  /**
   * Called when save button is triggered
   * Adds or Updates the current record
   *
   * @returns {void}
   */
  onSubmit(): void {
    if (! isEmpty(this.strModule) && ! isEmpty(this.strMessage) && ! isEmpty(this.strName)) {

      let objRequest = {
        name: this.strName,
        message: this.strMessage,
        module: this.strModule
      };

      this.recordService.saveRecord('sms_templates', objRequest, this.strRecordId).subscribe( result => {
        if (result.status === StatusCode.kResponseSuccess || result.status === StatusCode.kResponseCreated) {
          this.notificationService.notifySuccess('record_view_update_success');
          this.closeDialog('save');
        } else {
          this.notificationService.notifyError('unable_to_save');
        }
      });

    } else {
      this.notificationService.notifyError('sms_fields_required');
    }
  }

  /**
   * Checks if metadata is in local storage. If not, request for the metadata of all
   * modules that will have the sms widget and store it in the local storage.
   * Also, check the metadata of each fields if they have in_sms_template property
   *
   * @returns {void}
   */
  initializeSmsFields(): void {
    const ALLOWED_CUSTOM_FIELD_TYPE = ['number', 'text', 'currency', 'textarea', 'date', 'datetime'];
    this.objAvailableFields = this.localStorageService.getJsonItem('sms_related_modules');

    if (this.objAvailableFields === null) {

      this.smsService.getRelatedMetadata().subscribe(metadataList => {

        if (metadataList) {
          let smsRelatedFields = {};

          Object.keys(metadataList).forEach((module) => {
            smsRelatedFields[module] = [];
            Object.keys(metadataList[module]).forEach((field) => {
              if (metadataList[module][field].hasOwnProperty('in_sms_template') ||
                ALLOWED_CUSTOM_FIELD_TYPE.some(allowedfield => field.includes(allowedfield))) {
                smsRelatedFields[module].push({ field: field, label : metadataList[module][field]['label']});
              }
            });
          });

          this.objAvailableFields = smsRelatedFields;
          this.localStorageService.setJsonItem('sms_related_modules', smsRelatedFields);
        }
      });

    }
  }

  /**
   * Appends the field variable to the message value
   *
   * @returns {void}
   */
  appendField(): void {
    if (this.strModule !== null && this.strField !== null) {
      this.strMessage += ' {{ ' + this.strField + ' }}';
    }
  }

  /**
   * When back button is clicked, redirect back to sms templates listing page
   *
   * @params {string} strTrigger
   * @returns {void}
   */
  closeDialog(strTrigger: string = 'close'): void {
    this.dialogRef.close(strTrigger);
  }

}
