import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';

const kBaseUrl: string = environment.url + "/assets_job/";


@Injectable()
export class AssetService {

  constructor (private http: HttpClient) {}

	// Link multiple assets to a job using ids of assets
	linkAssets(arAssetIds, strJobId) {
		let body = new URLSearchParams();
		body.append('asset_ids', JSON.stringify(arAssetIds));
		body.append('job_id', strJobId);

		return this.http.post<Response>(kBaseUrl + "link_assets", body.toString());
	}

	// Link multiple assets to a job
	linkAllRelatedAssets(arFilter, strJobId) {
		let body = new URLSearchParams();
		body.append('filters', JSON.stringify(arFilter));
		body.append('job_id', strJobId);

		return this.http.post<Response>(kBaseUrl + "link_related_assets", body.toString(), { observe: 'response' });
	}

	// Get linked assets against the current module
	getAllLinkedAssets(strRecordId, strModule) {
		let body = new URLSearchParams();
		body.append('module', strModule);
		body.append('record_id', strRecordId);

		return this.http.post<Response>(kBaseUrl + "get_linked_assets", body.toString());
	}

	// Get list of asset jobs that have faults
	getAllFaultyAssets(arJobRecord) {
		let body = new URLSearchParams();
		body.append('job_record', arJobRecord);

		return this.http.post<Response>(kBaseUrl + "get_faulty_assets", body.toString());
	}

	// Get all asset jobs record with realationship with the given opportunity id
	// Re-create those asset jobs in the new job
	getOpportunityAssets(strNewJobId, strOldJobId, strOpportunityId) {
		let body = new URLSearchParams();
		body.append('new_job_id', strNewJobId);
		body.append('old_job_id', strOldJobId);
		body.append('opportunity_id', strOpportunityId);

		return this.http.post<Response>(kBaseUrl + "convert_to_quote", body.toString());
	}
}