import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { LooseObject } from '../../../objects/loose-object';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { finalize, map } from 'rxjs/operators';
import { ItemFoldersService } from '../_services/item-folders.service';
import { LocalStorageService } from '../../../services/local-storage.service';

export class DynamicFlatNode {
  constructor(
    public is_folder = false,
    public is_loading = false,
    public is_expandable = false,
    public metadata: LooseObject,
    public level = 1,
    public id: string,
    public name: string,
    public include: boolean = false,
    public quantity: number = 1
  ){}
}

export class FolderTreeSource implements DataSource<DynamicFlatNode> {

  /**
   * Current page.
   *
   * @var {string}
   */
  numPage: string = null;

  /**
   * If we have another page of folders.
   *
   * @var {boolean}
   */
  bHasNextPage: boolean = false;

  /**
   * Indicator if were still loading data.
   *
   * @var {boolean}
   */
  bLoading: boolean = false;

  /**
   * Monitors data changes and applies those changes
   * to the tree.
   *
   * @var BehaviorSubject<DynamicFlatNode[]>
   */
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  /**
   * Special unallocated folder where we store
   * products that have null folder_id.
   *
   * @var {DynamicFlatNode}
   */
  objUnallocated = new DynamicFlatNode(true, false, true, {
    id: "unallocated",
    name: "unallocated"
  }, 0, "unallocated", "unallocated");

  /**
   * Special folder item types.
   *
   * @var {string[]}
   */
  arSpecialFolders = ['unallocated', 'next_page'];

  /**
   * A flag to show or hide unallocated.
   *
   * @var {boolean}
   */
  bHideUnallocated: boolean = false;

  /**
   * When the data is retrieved.
   *
   * @returns {DynamicFlatNode[]}
   */
  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }

  /**
   * When the data is set.
   *
   * @param {DynamicFlatNode[]}
   *
   * @returns void
   */
  set data(arValue: DynamicFlatNode[]) {
    this.treeControl.dataNodes = arValue;
    this.dataChange.next(arValue);
  }

  constructor(
    public treeControl: FlatTreeControl<DynamicFlatNode>,
    private folder: ItemFoldersService,
    private local: LocalStorageService,
    public labor: boolean | null
  ) {
  }

  /**
   * @inheritdoc
   */
  connect(objCollectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {

    this.treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(objCollectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));

  }

  /**
   * @inheritdoc
   */
  disconnect(objCollectionViewer: CollectionViewer): void {}

  /**
   * @inheritdoc
   */
  handleTreeControl(objChange: SelectionChange<DynamicFlatNode>): void {

    if (objChange.added) {
      objChange.added.forEach(node => this.toggleNode(node, true));
    }

    if (objChange.removed) {
      objChange.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }

  }

  /**
   * When the nodes are toggled.
   *
   * @param {DynamicFlatNode} objNode
   * @param {boolean} bExpand
   *
   * @returns {void}
   */
  toggleNode(objNode: DynamicFlatNode, bExpand: boolean): void {

    let index = this.data.indexOf(objNode);

    if (bExpand) {

      objNode.is_loading = true;

      let strId = objNode['id'] != 'unallocated' ? objNode['id'] : null;

      this.folder.getChildrenList(strId, this.labor)
      .pipe(
        map(response => {

          let arData: DynamicFlatNode[] = [];

          if (response['data']) {

            let arResponseData = response['data'];
            if (strId != null && arResponseData['folders']) {
              arData = [...arData, ...arResponseData['folders'].map(
                name => new DynamicFlatNode(true, false, true, name, objNode.level + 1, name['id'], name['next'])
              ) || []];
            }
            if (arResponseData['items']) {
              arData = [...arData, ...arResponseData['items'].map(
                name => new DynamicFlatNode(false, false, false, name, objNode.level + 1, name['id'], name['text']),
              ) || []];

              if (arResponseData['items_pagination']) {
                arData.push(new DynamicFlatNode(true, false, true, {
                  id: 'next_page',
                  parent_id: strId,
                  name: 'Load Next Page',
                  page: arResponseData['items_pagination'],
                }, objNode.level + 1, 'next_page', 'Load Next Page'))
              }

            }

          }

          return arData;

        })
      )
      .subscribe(response => {
        index = this.getEndOfNode(objNode);
        if (response.length > 0) {
          this.data.splice(index + 1, 0, ...response);
          objNode.is_loading = false;
          this.data = this.data.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
          });
          this.dataChange.next(this.data);
        } else {
          this.treeControl.collapse(objNode);
          objNode.is_loading = false;
        }
      });

    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > objNode.level;
        i++, count++
      ) {}
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
    }
  }

  /**
   * Loading the folders by pages.
   *
   * @returns {void}
   */
  loadPages(arFolderIds: string[] = [], arItemIds: string[] = []): void {

    this.bLoading = true;

    this.folder.getList(this.numPage, null, arFolderIds, arItemIds, this.labor).pipe(
      map(response => {
        return {
          data: response['data'].map(item => {
            return new DynamicFlatNode(true, false, true, item, 0, item['id'], item['next'])
          }),
          next: response['next'],
          items: response['items']
        }
      }),
      finalize(() => {
        this.bLoading = false;
      })
    ).subscribe(response => {
      let numUnallocatedIndex = this.setUnallocated();
      response.data.forEach(item => {
        this.data.splice(numUnallocatedIndex, 0, item)
        numUnallocatedIndex++;
      });
      this.dataChange.next(this.data);
      this.bHasNextPage = (response.next) ;
      this.numPage = response.next;

      if (this.numPage == null) {
        if (response.data.length > 0) {
          this.local.setItem('product_folders_view', 'folder');
        } else {
          this.local.setItem('product_folders_view', 'list');
        }
      }

      if (response.items) {

        response.items.forEach(item => {
          let numParentIndex = this.data.findIndex(node => { return node.id == item['item_folder_id'] });

          if (numParentIndex == -1) {
            numParentIndex = this.data.findIndex(node => { return node.id == 'unallocated' });
            this.bHideUnallocated = false;
          }

          let objNewNode = new DynamicFlatNode(false, false, false, item, this.data[numParentIndex].level + 1, item['id'], item['text']);
          this.data.splice(numParentIndex + 1, 0, objNewNode);
          this.data = this.data.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
          });
          this.dataChange.next(this.data);

        });

      }

    });

  }

  /**
   * Get the parent node of this child node.
   *
   * @param {DynamicFlatNode} objNode
   * @returns {DynamicFlatNode}
   */
  getParent(objNode: DynamicFlatNode): DynamicFlatNode {

    const currentLevel = this.treeControl.getLevel(objNode);

    if (currentLevel < 1) {
      return null;
    }

    const index = this.treeControl.dataNodes.indexOf(objNode) - 1;

    for (let i = index; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }

  }

  /**
   * Retrieves the index of the end of a node.
   *
   * @param objNode
   */
  getEndOfNode(objNode: DynamicFlatNode) {

    if (this.treeControl.dataNodes) {
      let arChildren = this.treeControl.getDescendants(objNode);

      if (arChildren.length > 0) {
        return this.data.indexOf(arChildren[arChildren.length - 1]);
      }
    }

    return this.data.indexOf(objNode);
  }

  /**
   * Sets the special unallocated folder and returns the index.
   * This will show all products without a folder.
   *
   * @returns {number}
   */
  public setUnallocated(): number {

    let numUnallocated =  this.data.findIndex(folder => (folder.id == this.objUnallocated.id));

    if (numUnallocated < 0) {
      this.data.push(this.objUnallocated);
      this.dataChange.next(this.data);
      return 0;
    }

    return numUnallocated;
  }

  /**
   * Load the pages inside the node.
   *
   * @param objNode
   *
   * @returns {void}
   */
  loadNodePages(objNode: DynamicFlatNode): void {

    let numIndex = this.data.indexOf(objNode);
    let objPage = objNode['metadata']['page'];
    objNode = this.getParent(objNode);

    let strId = objNode['id'] != 'unallocated' ? objNode['id'] : null;

    this.folder.getChildrenList(strId, this.labor, objPage)
    .pipe(
      map(response => {

        this.data.splice(numIndex, 1);
        this.dataChange.next(this.data);

        let arData: DynamicFlatNode[] = [];

        if (response['data']) {

          let arResponseData = response['data'];

          if (arResponseData['items']) {
            arData = [...arData, ...arResponseData['items'].map(
              name => new DynamicFlatNode(false, false, false, name, objNode.level + 1, name['id'], name['text']),
            ) || []];

            if (arResponseData['items_pagination']) {
              arData.push(new DynamicFlatNode(true, false, true, {
                id: 'next_page',
                parent_id: strId,
                name: 'Load Next Page',
                page: arResponseData['items_pagination'],
              }, objNode.level + 1, 'next_page', 'Load Next Page'))
            }

          }

        }

        return arData;

      })
    )
    .subscribe(response => {
      if (response.length > 0) {
        this.data.splice(numIndex, 0, ...response);
        this.data = this.data.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
        });
        this.dataChange.next(this.data);
      }
    });

  }
}