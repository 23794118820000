<ng-container>
  <ng-container *ngTemplateOutlet="links"></ng-container>

  <div class="w-100 mt-2">
    <ng-content></ng-content>
  </div>

  <ng-container *ngTemplateOutlet="links"></ng-container>
</ng-container>

<ng-template #links>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-xs-12 d-flex justify-content-end">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" (click)="onRefresh()">
            <fa-icon [icon]="['far', 'sync-alt']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': isFirstPage$ | async}">
          <a class="page-link" (click)="onFirstPage()">
            <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': ! (hasPreviousPage$ | async)}">
          <a class="page-link" (click)="onPreviousPage()">
            <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': ! (hasNextPage$ | async)}">
          <a class="page-link" (click)="onNextPage()">
            <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>