export class EmailAddressOption {
  text: string;
  email_address: object;

  constructor(name: string, emailAddress: string, isPrimary: boolean = false) {
    this.text = name;
    this.setEmailAddress(emailAddress, isPrimary);
  }

  get emailAddress(): object {
    return this['email_address'] || {};
  }

  get isPrimary(): boolean {
    return this.emailAddress['primary'] || false;
  }

  protected setEmailAddress(emailAddress: string, isPrimary: boolean = false) {
    this['email_address'] = {
      email: emailAddress,
      primary: isPrimary
    };
  }
}
