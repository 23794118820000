import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, concat, Subject, of } from 'rxjs';
import { Select } from '../../../objects/select';
import { Guid } from "guid-typescript";
import { RecordService } from '../../../services/record.service';
import { StatusCode } from '../../../lists/status-code';
import { NotificationService } from '../../../services/notification.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ChecklistsService } from '../../../services/checklist.service';
import { ChecklistConstants, ChecklistTypeDisplayToEnum, ChecklistTypeEnumToDisplay } from '../../../lists/checklist'
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PromptColumnClasses } from './shared/classes';
import { isEmpty, isEqual, get } from 'lodash';
import { ChecklistResponsePrompt } from '../../../objects/checklis-response';
import { ChecklistPromptGroup } from '../../../objects/checklist';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-checklist',
  templateUrl: './update-checklist.component.html',
  styleUrls: ['./update-checklist.component.scss']
})
export class UpdateChecklistComponent implements OnInit {

    @ViewChild('checklistName') nameInput: ElementRef;
    @ViewChild("inputChip") chipInput: ElementRef;

    public objChecklistConstants = new ChecklistConstants;

    // Static Lists for Checklist
    public arPromptTypes : any = [];
    public arAvailablePeriod : any = [];
    public arExcludedFields : any = [];
    public arExcludedDataTypes : any = [];
    public arChecklistTypeDisplayToEnum : any = ChecklistTypeDisplayToEnum;
    public arChecklistTypeEnumToDisplay : any = ChecklistTypeEnumToDisplay;

    public arPrompts = [
        { name: 'main_prompts', prompts: [] },
        { name: 'per_asset_prompts', prompts: [] }
    ];

    public bSubmitted: boolean = false;
    public bShowLoader: boolean = false;
    public editText = false;
    public checklistName;
    public arChecklistRecord = [];
    public objFieldEntries;
    public arFieldEntryJobsKeys;
    public arFieldEntrySitesKeys;
    public arFieldEntryOpportunitiesKeys;
    public arFieldEntryAssetTypes = [];
    public arFieldEntryAssetKeys;
    public arFieldEntryTypes = [];
    public arFieldEntry = [];
    public arCustomFielEntry = [];

    // Checklist's main inputs
    public strChecklistName = '';
    public strChecklistType;
    public strRecordId: string = '';
    public strAssetType = '';
    public arPeriod = [];
    public isChecklistEnabled = true;
    public objRelatedAssetType = {'id' : '', 'text': ''};
    public arPeriodList = [];

    // For chips
    public arChipType = [];
    visible: boolean = true;
    selectable: boolean = true;
    removable: boolean = true;
    addOnBlur: boolean = true;
    separatorKeysCodes = [ENTER, COMMA];

    public arPaginationPrevious: any = {};
    public arPagination = {
        current_value: '',
        current_page: '',
        next_page: '',
        prev_page: '',
        initial_value: ''
    };

    public defaultValue = [];

    public objAssetType = {
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_asset_type',
        name: 'asset_type_id',
        module: 'asset_types',
        value: 'assetType',
    }
    public bEdited: boolean = false;

    /**
     * Contains the class names used for the width of the prompt columns.
     *
     * @type {PromptColumnClasses} objPromptColumnClasses
    */
    public objPromptColumnClasses: PromptColumnClasses = {
      prompt_name_class: 'header-prompt',
      prompt_type_class: 'header-type',
      prompt_value_class: 'header-value',
      prompt_sched_class: 'header-schedule',
      prompt_req_class: 'header-required',
      prompt_icons_class: 'header-icons',
      prompt_drag_class: 'indent-and-drag'
    };

    /**
     * Gets the classes of all the repeating group blocks.
     *
     * @returns {string[]}
    */
    get repeatingGroups(): string[] {
        let arClasses: string[] = [];

        this.arPrompts.forEach(item => {
            item.prompts.forEach((prompt, index) => {
                if (prompt['is_group']) {
                    arClasses.push(`repeating-group-${index}`);
                    arClasses.push(`repeating-group-${index}-header`);
                }
            });
        });

        return arClasses;
    }

    constructor(
      public router: Router,
      private route: ActivatedRoute,
      public recordService: RecordService,
      private notifService: NotificationService,
      private checklistService : ChecklistsService,
      private translateService: TranslateService,
    ) {

          // Gets the record id from url parameter
          this.route.params.subscribe( params => {
              this.strRecordId = params['id'];
          });

          // Get the current checklist's data
          this.recordService.getRecord('checklists', this.strRecordId).subscribe( result => {

              // Do we have data?
              if (result['record_details'].length != 0) {
                  this.arChecklistRecord = result['record_details'];
                  this.strChecklistName = this.arChecklistRecord['name']
                  this.strChecklistType =  this.arChecklistTypeEnumToDisplay[this.arChecklistRecord['type']]
                  this.isChecklistEnabled = this.arChecklistRecord['is_checklist_enabled'];

                    // If checklist type is asset type, have both main checklist and per asset prompts, otherwise just display main checklist prompts.
                    // Set asset type id and get its related value from asset types table
                    if (this.arChecklistRecord['type'] === "asset") {
                        this.strAssetType = this.arChecklistRecord['asset_type_id'];
                        this.arPrompts = this.arChecklistRecord['questions'] == '' ? this.arPrompts : JSON.parse(this.arChecklistRecord['questions']);
                        this.arPeriod = this.arChecklistRecord['available_periods']  == '' ? [] : JSON.parse(this.arChecklistRecord['available_periods']);
                        this.arPeriodList = this.arPeriod;
                        // Update prompts column classes by adding '-asset' suffix to the classes if checklist is asset.
                        Object.keys(this.objPromptColumnClasses).forEach(key => this.objPromptColumnClasses[key] = this.objPromptColumnClasses[key] + '-asset');

                        // Get Record Relate of asset types
                        this.recordService.getRecordRelate('asset_types', '', this.strAssetType, false, false, 10, false).subscribe(response => {

                            this.objRelatedAssetType =  {
                                'id' : response[0]['id'],
                                'text' : response[0]['text']
                            }

                            // Add Custom field entry for asset type
                            this.arCustomFielEntry = response[0]['attributes'];

                            // Place an initial value to the asset type field ng-select.
                            this.objAssetType['obv'] = new Observable<Select[]>();
                            this.objAssetType['obv'] = concat(
                                of([this.objRelatedAssetType])
                            );

                            this.getFieldEntry();
                        });

                    } else {
                        this.getFieldEntry();
                        this.arPrompts = this.arChecklistRecord['questions'] == '' ? [ {name: 'main_prompts', prompts: []} ] : JSON.parse(this.arChecklistRecord['questions']);
                    }
                }
          });
      }

    ngOnInit() {
        this.arPromptTypes = this.objChecklistConstants['PromptTypes'];
        this.arAvailablePeriod = this.objChecklistConstants['AvailableInspectionPeriod'];
        this.arExcludedFields = this.objChecklistConstants['FieldEntryExcludedFields'];
        this.arExcludedDataTypes = this.objChecklistConstants['FieldEntryExcludedDataType'];
    }

    /**
     * Triggers whether name will be plain text or input box.
     * Clicking the name will convert it to an input box.
     *
     * @param isShowing - boolean
     */
    focusInput(isShowing) {
        //If isShowing is true, make title an input box.
        //If its false, just plain text.
        this.editText = isShowing;

        if (isShowing && this.nameInput) {
          setTimeout(function() {
            // Focus if an input box.
            this.nameInput.nativeElement.focus();
          }.bind(this), 1);
        }
    }

    /**
     * Closes the dialog and redirects to list of checklists
     */
    cancelDialog() {
        if (this.bEdited) {
          // Pop-up modal for confirmation
          this.notifService.sendConfirmation('confirm_cancel')
              .filter(confirmation => confirmation.answer === true)
              .subscribe(() => {
                  this.router.navigate(['/admin/checklists']);
              });
        } else {
            this.router.navigate(['/admin/checklists']);
        }
    }

    /*
    *  Gets and returns available table fields of jobs and sites tables from their metadata
    */
    getFieldEntry() {

        let bIsAssetType: boolean = this.strChecklistType === 'assets_checklist' ? true : false;
        let bIsQuoteType: boolean = this.strChecklistType === 'quote_checklist';

        // Gets the metadata for job, site and asset type
        this.checklistService.getFieldEntries(bIsAssetType).subscribe( result => {

            this.objFieldEntries = result;

            // If Checklist is not an asset type
            if (!bIsAssetType) {
                // Extract keys (field names) from metadata
                this.arFieldEntryJobsKeys = Object.keys(this.objFieldEntries['jobs']);
                this.arFieldEntrySitesKeys = Object.keys(this.objFieldEntries['sites']);
                this.arFieldEntryOpportunitiesKeys = Object.keys(this.objFieldEntries['opportunities']);

                // Set an id with "sites-" or "jobs-" or "custom_asset_types-" or "asset-" prefix for identification
                Object.keys(this.arFieldEntrySitesKeys).forEach(
                    item => {
                        if (! this.arExcludedDataTypes.includes(this.objFieldEntries['sites'][this.arFieldEntrySitesKeys[item]]['type']) &&
                            ! this.arExcludedFields.includes(this.objFieldEntries['sites'][this.arFieldEntrySitesKeys[item]]['label'])) {
                            this.arFieldEntry.push (
                                {
                                    'id' : 'sites-'+ this.arFieldEntrySitesKeys[item],
                                    'field_type' : 'site_fields',
                                    'field' : this.arFieldEntrySitesKeys[item],
                                    'display' : this.objFieldEntries['sites'][this.arFieldEntrySitesKeys[item]]['label']
                                }
                            )
                        }
                    }
                );

                if (bIsQuoteType) {
                    Object.keys(this.arFieldEntryOpportunitiesKeys).forEach(
                        item => {
                        if (! this.arExcludedDataTypes.includes(this.objFieldEntries['opportunities'][this.arFieldEntryOpportunitiesKeys[item]]['type']) &&
                                ! this.arExcludedFields.includes(this.objFieldEntries['opportunities'][this.arFieldEntryOpportunitiesKeys[item]]['label'])) {
                                this.arFieldEntry.push (
                                    {
                                        'id' : 'opportunities-'+ this.arFieldEntryOpportunitiesKeys[item],
                                        'field_type' : 'opportunity_fields',
                                        'field' : this.arFieldEntryOpportunitiesKeys[item],
                                        'display' : this.objFieldEntries['opportunities'][this.arFieldEntryOpportunitiesKeys[item]]['label']
                                    }
                                )
                            }
                        }
                    );
                }

                if (!bIsQuoteType) {
                    Object.keys(this.arFieldEntryJobsKeys).forEach(
                        item => {
                        if (! this.arExcludedDataTypes.includes(this.objFieldEntries['jobs'][this.arFieldEntryJobsKeys[item]]['type']) &&
                                ! this.arExcludedFields.includes(this.objFieldEntries['jobs'][this.arFieldEntryJobsKeys[item]]['label'])) {
                                this.arFieldEntry.push (
                                    {
                                        'id' : 'jobs-'+ this.arFieldEntryJobsKeys[item],
                                        'field_type' : 'job_fields',
                                        'field' : this.arFieldEntryJobsKeys[item],
                                        'display' : this.objFieldEntries['jobs'][this.arFieldEntryJobsKeys[item]]['label']
                                    }
                                )
                            }
                        }
                    );
                }
            } else {

                this.arFieldEntryAssetKeys = Object.keys(this.objFieldEntries['assets']);

                Object.keys(this.arFieldEntryAssetKeys).forEach(
                    item => {
                        if (! this.arExcludedDataTypes.includes(this.objFieldEntries['assets'][this.arFieldEntryAssetKeys[item]]['type']) &&
                            ! this.arExcludedFields.includes(this.objFieldEntries['assets'][this.arFieldEntryAssetKeys[item]]['label'])) {
                            this.arFieldEntryTypes.push (
                                {
                                    'id' : 'assets-'+ this.arFieldEntryAssetKeys[item],
                                    'field_type' : 'asset_fields',
                                    'field' : this.arFieldEntryAssetKeys[item],
                                    'display': this.arFieldEntryAssetKeys[item]
                                }
                            )
                        }
                    }
                );

                if (this.arCustomFielEntry) {
                    this.arCustomFielEntry.forEach(
                        item => {
                            if (! this.arExcludedDataTypes.includes(item['type'])) {
                                this.arFieldEntryTypes.push(
                                    {
                                        'id' : 'custom_asset_types-'+ item['key'],
                                        'field_type' : 'custom_asset_fields',
                                        'field' : item['key'],
                                        'display': item['label']
                                    }
                                )
                            }
                        }
                    );
                }
            }
        });
    }

    /*
    *  Gets data gathered and saves in checklist table
    */
    onSubmit() {
        // Check if period length is empty in asset type checklist
        if ((this.strChecklistType != "assets_checklist" && this.arPeriod.length == 0) || this.arPeriod.length > 0) {

            let intInvalidPromptNameCounter: number = 0;
            let intPassOrFailCounter: number = 0;
            let hasAssetType: boolean = false;
            let intEmptyValueCounter: number = 0; // For pass and fail, field entry, and instructions
            let numInvalidGroupNameCounter: number = 0;
            let numEmptyRepeatingGroupsCtr: number = 0;
            let numEmptyPromptTypeCtr: number = 0;

            // Validate prompts before saving
            // Check 2 type of prompts (Main and Assets)
            for (let promptTypeCounter = 0; promptTypeCounter < this.arPrompts.length; promptTypeCounter++ ) {

                // Check first if the prompt is not empty
                if (this.arPrompts[promptTypeCounter]['prompts'].length > 0) {
                    // If there's any per_asset_prompts available
                    if (this.arPrompts[promptTypeCounter]['name'] === 'per_asset_prompts') {
                        hasAssetType = true;
                    }

                    // Get all prompts of current type (asset prompts or main prompts)
                    for (let promptsCounter = 0; promptsCounter < this.arPrompts[promptTypeCounter]['prompts'].length; promptsCounter++ ) {
                        // If prompt type is empty, should display error
                        if (isEmpty(this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['type'])) {
                            numEmptyPromptTypeCtr++;
                            break;
                        }

                        // Check if prompt name is empty or undefined
                        if (
                            (this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['prompt'] === '' ||
                            this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['prompt'] === undefined) &&
                            !this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['is_group']
                        ) {
                            intInvalidPromptNameCounter++;
                            break;
                        }

                        if (this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['is_group'] && isEmpty(this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['group_name'])) {
                            numInvalidGroupNameCounter++;
                            break;
                        }

                        if (this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['is_group']) {
                            for (let i=0; i<this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['prompts'].length; i++) {
                                if (isEmpty(this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['prompts'][i]['prompt'])) {
                                    intInvalidPromptNameCounter++;
                                    break;
                                }
                            }

                            // if repeating group is empty
                            if (this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['prompts'].length === 0) {
                                numEmptyRepeatingGroupsCtr++;
                            }
                        }

                        // Check if per_asset_prompts has available pass or fail prompt type
                        if (hasAssetType && this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['type'] == "pass/fail") {
                            intPassOrFailCounter++;
                        }

                        // Check if per_asset_prompts group prompts has available pass or fail prompt type
                        if (hasAssetType && this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['is_group']) {
                            this.arPrompts[promptTypeCounter]['prompts'][promptsCounter].prompts.forEach((objPrompt: ChecklistResponsePrompt) => {
                                if (objPrompt.type === "pass/fail") {
                                    intPassOrFailCounter++;
                                }
                            });
                        }

                        let arCurrentValue = this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['value'];
                        let strCurrentPromptType = this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['type'];

                        // Remove all not needed values and attach the current value only in request
                        if (!this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['is_group']) {
                            this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['value'] = {
                                [strCurrentPromptType] : arCurrentValue[strCurrentPromptType]
                            }
                        }

                        // If pass / fail, or instructions text, attach their values
                        // Also check if value is empty for pass / fail, instructions text, field entry and dropdown prompt types
                        switch (strCurrentPromptType) {

                            case 'pass/fail' :
                                this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['value'] = {
                                    'pass' : arCurrentValue['pass'],
                                    'fail' : arCurrentValue['fail'],
                                };

                                // Check if there's no any pass or fail values
                                if (arCurrentValue['pass'].length == 0 || arCurrentValue['fail'].length == 0) {
                                    intEmptyValueCounter++;
                                    break;
                                }

                            break;
                            case 'instructions' :
                                this.arPrompts[promptTypeCounter]['prompts'][promptsCounter]['value']['instructions_text'] = arCurrentValue['instructions_text'];

                                // Check if instructions text value is empty
                                if (arCurrentValue['instructions_text'] === undefined || arCurrentValue['instructions_text'] === "") {
                                    intEmptyValueCounter++;
                                    break;
                                }
                            break;

                        }

                        const arRequiredValues = [
                            "field_entry", "dropdown"
                        ];

                        if (arRequiredValues.includes(strCurrentPromptType)) {
                            if (this.isFieldEntryOrDropdownEmpty(this.arPrompts[promptTypeCounter]['prompts'][promptsCounter], strCurrentPromptType)) {
                                intEmptyValueCounter++;
                                break;
                            }
                        }

                        if (strCurrentPromptType === 'group') {
                            const objPromptGroup: ChecklistPromptGroup = this.arPrompts[promptTypeCounter]['prompts'][promptsCounter];

                            // check if there are empty pass/fail, dropdown, instructions, or field entry prompts inside group
                            if (this.hasEmptyRequiredFieldInGroup(objPromptGroup)) {
                                intEmptyValueCounter++;
                                break;
                            }

                            // checks if there are empty prompt type inside group
                            if (this.hasEmptyPromptTypeInGroup(objPromptGroup)) {
                                numEmptyPromptTypeCtr++;
                                break;
                            }
                        }
                    }
                }
            }

            // If there's any prompt name that's invalid
            if (intInvalidPromptNameCounter > 0) {
                this.notifService.notifyWarning('invalid_prompt_name');
            // if per_asset_prompts is available, but has no pass/fail type prompt in it
            } else if (hasAssetType && intPassOrFailCounter == 0)  {
                this.notifService.notifyWarning('asset_prompt_type_missing');
            // If values of certain types are empty ("field_entry", "dropdown", "pass/fail", "instructions")
            } else if (intEmptyValueCounter > 0) {
                this.notifService.notifyWarning('prompt_values_empty');
            // If there are any invalid group names
            } else if (numInvalidGroupNameCounter > 0) {
                this.notifService.notifyWarning('invalid_prompt_group_name');
            } else if (numEmptyRepeatingGroupsCtr > 0) {
                this.notifService.notifyWarning('prompt_group_minimum_prompts');
            } else if (numEmptyPromptTypeCtr > 0) {
                this.notifService.notifyWarning('has_prompt_with_blank_type');
            } else {
                let arData = {
                    'name': this.strChecklistName,
                    'type': this.arChecklistTypeDisplayToEnum[this.strChecklistType],
                    'id': this.strRecordId,
                    'asset_type_id': this.strAssetType,
                    'available_periods': JSON.stringify(this.arPeriod),
                    'is_checklist_enabled': this.isChecklistEnabled,
                    'questions' : JSON.stringify(this.arPrompts)
                };

                // Show loading icon in save button.
                this.bShowLoader = true;

                this.recordService.saveRecord('checklists', arData, this.strRecordId).subscribe( result => {
                    if (result.status === StatusCode.kResponseSuccess) {

                        this.notifService.notifySuccess('status_update_success')

                    } else {

                        this.notifService.notifyWarning('failed_to_save');
                    }

                    // Hide loading icon in save button.
                    this.bShowLoader = false;
                    this.bEdited = false;
                });
            }
        } else {
            this.notifService.notifyWarning('invalid_inspection_period');
        }
    }

    /**
     * Checks if the field entry or dropdown prompt is empty.
     *
     * @param {ChecklistPromptGroup} objPromptGroup
     * @param {string} strPromptType
     *
     * @returns {boolean}
     */
    isFieldEntryOrDropdownEmpty(objChecklistPrompt: ChecklistResponsePrompt, strPromptType: string): boolean {
        return isEmpty(objChecklistPrompt.value[strPromptType]) || objChecklistPrompt.value[strPromptType].length === 0;
    }

    /**
     * Checks if there are empty prompt types inside group.
     *
     * @param {ChecklistPromptGroup} objPromptGroup
     *
     * @returns {boolean}
     */
    hasEmptyPromptTypeInGroup(objPromptGroup: ChecklistPromptGroup): boolean {
        return !objPromptGroup.prompts.every(prompt => !isEmpty(prompt.type));
    }

    /**
     * Checks if there are empty pass/fail, dropdown, instructions, or field entry prompts inside group.
     *
     * @param {ChecklistPromptGroup} objPromptGroup
     *
     * @returns {boolean}
     */
    hasEmptyRequiredFieldInGroup(objPromptGroup: ChecklistPromptGroup): boolean {
        return !objPromptGroup.prompts.every(prompt => {
            switch (prompt.type) {
                case 'field_entry':
                case 'dropdown':
                    return !this.isFieldEntryOrDropdownEmpty(prompt, prompt.type);
                case 'pass/fail' :
                    return !(prompt.value['pass'].length === 0 || prompt.value['fail'].length === 0);
                case 'instructions':
                    return !isEmpty(prompt.value['instructions_text']);
                default:
                    return true;
            }
        });
    }

    /*
    *  Function that adds new set of prompt
    *
    *  @param indexPromptTypes = type of prompt (Main Checklist prompts & Per Asset Prompts)
    *
    *  @returns void
    */
    addPrompt(indexPromptTypes, objPrompts = null, objPromptGroup?: ChecklistPromptGroup): void {
        if ((this.arPeriodList.length != 0 && this.strChecklistType === 'assets_checklist') || this.strChecklistType !== 'assets_checklist') {

            // Initialize default prompt values.
            // FC-3781: default blank prompt to 'pass/fail' type
            const arPromptsHolder: ChecklistResponsePrompt = {
                id : Guid.create().toString(),
                is_required : true,
                is_visible_in_reports : true,
                prompt : '',
                type: 'pass/fail',
                value : {
                    pass: [this.translateService.instant('pass')],
                    fail: [this.translateService.instant('fail')]
                }
            };

            if (this.strChecklistType === 'assets_checklist') {
                let arDefaultPeriod = {};

                if (objPrompts.length == 0) {
                    // When adding first prompt, get all the selected period and default them to true
                    arPromptsHolder['schedule_type'] = {};
                    this.arPeriodList.forEach(selectedPeriod => {
                        arPromptsHolder['schedule_type'][selectedPeriod] = true;
                    });

                } else {
                    // If the first prompt is already added copy its period values and set as default
                    arDefaultPeriod = (() => {
                        if (!objPrompts[0]['is_group']) {
                            return objPrompts[0]['schedule_type'];
                        }

                        if (get(objPrompts[0], 'prompts', []).length > 0) {
                            return objPrompts[0].prompts[0]['schedule_type'];
                        }

                        let objDefaultScheduleTypes = {};
                        this.arPeriodList.forEach(selectedPeriod => objDefaultScheduleTypes[selectedPeriod] = true);

                        return objDefaultScheduleTypes;
                    })();

                    arPromptsHolder['schedule_type'] = {...arDefaultPeriod};
                }

            }

            if (objPromptGroup) {
                objPromptGroup.prompts.push(arPromptsHolder);
            } else {
              this.arPrompts[indexPromptTypes]['prompts'].push(arPromptsHolder);
            }

            this.markAsEdited();

        } else {
            this.notifService.notifyWarning('select_prompt_period');
        }
    }

    /*
    *  Function that adds and gets period when they are selected in "Available Periods Section"
    *
    *  @param arPeriod = selected period (Annual, Bi-Annual, Quarterly, Monthly)
    */
    getPeriods(arPeriod) {
        this.arPeriodList = arPeriod
    }

    /**
     *  Function that removes a prompt when trash icon is triggered
     *
     * @param {number} indexPromptTypes = index of prompt types (Main Checklist Prompt or Per Asset Prompts)
     * @param {number} indexPrompts = index of each prompts added
     * @param {ChecklistPromptGroup|undefined} objPromptGroup? = optional, if exists, removes the prompt from the prompt group
     *
     * @returns {void}
     */
    removePrompt(indexPromptTypes, indexPrompts, objPromptGroup?: ChecklistPromptGroup): void {
        if (objPromptGroup) {
            objPromptGroup['prompts'].splice(indexPrompts, 1)
        } else {
            this.arPrompts[indexPromptTypes]['prompts'].splice(indexPrompts, 1);
        }

        this.markAsEdited();
    }

    /**
     * Function which rearranges elements in an array
     *
     * @param {CdkDragDrop<string[]>} event - the item being dragged
     * @param {number} indexPromptTypes - index of the current prompt type (main_prompts / per_asset_prompts)
     * @param {ChecklistPromptGroup|undefined} objPromptGroup? - optional, if exists, arranges the prompts within the group
     *
     * @returns {void}
     */
    arrangePrompts(event: CdkDragDrop<string[]>, indexPromptTypes, objPromptGroup?: ChecklistPromptGroup): void {
        let objDraggedData = event.item.data;
        let objGroupContainingDraggedPrompt: ChecklistPromptGroup = this.arPrompts[indexPromptTypes].prompts.find(prompt => {
            return prompt['is_group'] && prompt['prompts'].includes(objDraggedData);
        });

        // Prompt not under group -> dragged to group
        if (!objGroupContainingDraggedPrompt && objPromptGroup && !objPromptGroup.prompts.includes(objDraggedData) && !objDraggedData['is_group']) {
            transferArrayItem(
                this.arPrompts[indexPromptTypes].prompts,
                objPromptGroup.prompts,
                event.previousIndex,
                event.currentIndex
            );

            this.markAsEdited();
            return;
        }

        let objTargetPrompt = this.arPrompts[indexPromptTypes].prompts[event.currentIndex];

        // Prompt under group -> dragged outside of group (but not dragged to another group)
        if (objGroupContainingDraggedPrompt && !objPromptGroup &&
            (
                this.arPrompts[indexPromptTypes].prompts.includes(objTargetPrompt) ||
                this.arPrompts[indexPromptTypes].prompts.length === event.currentIndex
            )
        ) {
            transferArrayItem(
                objGroupContainingDraggedPrompt.prompts,
                this.arPrompts[indexPromptTypes].prompts,
                event.previousIndex,
                event.currentIndex
            );
            this.markAsEdited();

            return;
        }

        // Prompt under group -> dragged outside of group and into another group
        if (objGroupContainingDraggedPrompt && objPromptGroup && !isEqual(objGroupContainingDraggedPrompt, objPromptGroup)) {
            transferArrayItem(
                objGroupContainingDraggedPrompt.prompts,
                objPromptGroup.prompts,
                event.previousIndex,
                event.currentIndex
            );
            this.markAsEdited();

            return;
        }

        // If last row is group and you want to drag a prompt from under the group to outside
        if (
            !event.isPointerOverContainer &&
            objDraggedData && objGroupContainingDraggedPrompt &&
            // check if group containing dragged prompt is the last row
            isEqual(objGroupContainingDraggedPrompt, this.arPrompts[indexPromptTypes].prompts[this.arPrompts[indexPromptTypes].prompts.length - 1])
        ) {
            transferArrayItem(
                objGroupContainingDraggedPrompt.prompts,
                this.arPrompts[indexPromptTypes].prompts,
                event.previousIndex,
                this.arPrompts[indexPromptTypes].prompts.length
            );

            this.markAsEdited();
            return;
        }

        // Dragging prompt not under group -> another prompt not under group OR
        // Dragging prompt under group -> another prompt in the same group OR
        // Dragging group -> another group (switching places)
        if (objPromptGroup) {
            moveItemInArray(objPromptGroup['prompts'], event.previousIndex, event.currentIndex);
        } else {
            moveItemInArray(this.arPrompts[indexPromptTypes]['prompts'], event.previousIndex, event.currentIndex);
        }

        this.markAsEdited();
    }

    /**
     * Mark as edited
     *
     * @param {boolean} bEditedStatus // defaults to true
     *
     * @returns {void}
     */
    markAsEdited(bEditedStatus: boolean = true): void {
        this.bEdited = bEditedStatus;
    }

  /**
   * delete checklist
   */
  deleteRecord(): void {
    if (this.strRecordId) {
      this.notifService.sendConfirmation('confirm_delete')
      .filter(confirmation => confirmation.answer == true)
      .subscribe(() => {
        this.recordService.deleteRecord('checklists', this.strRecordId).first().subscribe(
          data => {
            let arResponse = data.body;
            if (data.status == 200) {
              this.notifService.sendNotification('deleted', arResponse.toString(), 'success');
              this.router.navigate(['/admin/checklists']);
            } else {
              var warningMessage = (typeof arResponse === 'object' && arResponse['message']) ? arResponse['message'] : arResponse.toString();
              this.notifService.sendNotification('warning', warningMessage, 'warning');
            }
          },
          error => {
            if (error.error.id) {
              this.notifService.sendNotification('not_allowed', error.error.id[0], 'warning');
            }
          }
        );
      });
    } else {
      this.notifService.sendNotification('not_allowed', 'content_notification.fail_delete_resource', 'warning');
    }
  }

  /**
   * Adds a group which can contain prompts under it.
   *
   * @returns {void}
  */
  addGroup(numIndex: number): void {
    let objPromptGroup: ChecklistPromptGroup = {
      id : Guid.create().toString(),
      is_group: true,
      group_name: '',
      prompts: [],
      type: 'group',
    };

    if (this.strChecklistType === 'assets_checklist') {
      let objPerAssetPrompts = this.arPrompts.find(item => item.name === 'per_asset_prompts');
      let arPrompts = objPerAssetPrompts ? objPerAssetPrompts['prompts'] : [];

      this.addPrompt(numIndex, arPrompts, objPromptGroup);
    } else {
      this.addPrompt(numIndex, null, objPromptGroup);
    }

    this.arPrompts[numIndex]['prompts'].push(objPromptGroup);
    this.markAsEdited();
  }

  /**
   * Adds a prompt under the prompt group.
   *
   * @param {number} numIndex
   * @param {ChecklistPromptGroup} objPromptGroup
   *
   * @returns {void}
  */
  addPromptUnderGroup(numIndex: number, objPromptGroup: ChecklistPromptGroup): void {
    if (this.strChecklistType === 'assets_checklist') {
        let objPerAssetPrompts = this.arPrompts.find(item => item.name === 'per_asset_prompts');
        let arPrompts = objPerAssetPrompts ? objPerAssetPrompts['prompts'] : [];

        this.addPrompt(numIndex, arPrompts, objPromptGroup);
    } else {
        this.addPrompt(numIndex, null, objPromptGroup);
    }
  }

  /**
   * Actions to be performed before removing the prompt under a group.
   *
   * @param {number} numIndex
   * @param {ChecklistPromptGroup} objPromptGroup
   *
   * @returns {void}
  */
  onRemovePromptUnderGroup(numIndex: number, objPromptGroup: ChecklistPromptGroup): void {
    this.removePrompt(null, numIndex, objPromptGroup)
  }

  /**
   * Returns the connected drop lists (other groups) of the prompt group
   *
   * @param {string} strClass
   *
   * @returns {string[]}
  */
  getConnectedDropLists(strClass: string): string[] {
    return ['outer'].concat(this.repeatingGroups.filter(strGroupClass => strGroupClass !== strClass));
  }

  /**
   * Prevents the prompt animation from becoming too shaky
   *
   * @returns {boolean}
  */
  dragAnimationFunc(): boolean {
    return bDragAnimationFlag;
  }

  /**
   * Sets the drag animation flag value.
   *
   * @param {boolean} bFlag
   *
   * @returns {void}
  */
  setDragAnimationFlag(bFlag: boolean): void {
    bDragAnimationFlag = bFlag;
  }
}

/**
 * Prevents the prompt animation from becoming too shaky when dragging inside group
 *
 * @type {boolean}
 */
let bDragAnimationFlag: boolean = false;