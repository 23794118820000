import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ListingService } from '../../../../../services/listing.service';
import { SearchService } from '../../../../../services/search.service';
import { NotificationService } from '../../../../../services/notification.service';
import { StockManagementService } from '../../../../../services/stock-management.service';

import { StocktakeLine } from './stocktake-line';
import { Stocktake, StocktakeItemInterface } from '../../../../../objects/stock-management/stocktake';
import { Warehouse } from '../../../../../objects/stock-management/warehouse';
import { Relate } from '../../../../../objects/relate';
import { Pagination } from '../../../../../shared/components/pagination/pagination';
import { StockLevel } from '../../../../../objects/stock-management/stock-level';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { RecordService } from '../../../../../services/record.service';

@Component({
  selector: 'stocktake-list',
  templateUrl: './stocktake-list.component.html',
  styleUrls: ['./stocktake-list.component.scss'],
  providers: [
    ListingService,
    StockManagementService
  ]
})
export class StocktakeListComponent implements OnInit {

  /**
   * The holder for the warehouse where the stocktake
   * records will reflect.
   *
   * @var {Relate<Warehouse[], Warehouse>}
   */
  objWarehouse: Relate<Warehouse>;

  /**
   * If a stocktake was saved as a draft
   * and is currently being updated, this
   * will be its holder.
   *
   * @var {Stocktake}
   */
  objStockTake: Stocktake | null = null;

  /**
   * Simple loader while the warehouses
   * are being retrieved from the api.
   *
   * @var {boolean}
   */
  bLoader: boolean = false;

  /**
   * Simple flag to identify if anything from
   * the stocklevel list has been touched.
   *
   * @var {boolean}
   */
  bSaveDirty: boolean = false;

  /**
   * Simple flag to identify if the
   * save button has been clicked.
   *
   * @var {boolean}
   */
  bSaveClicked: boolean = false;

  /**
   * Simple flag to check if the
   * stock stake is being saved.
   *
   * @var {boolean}
   */
  bSaveLoading: boolean = false;

  /**
   * The pagination object for the stocktake.
   *
   * @var {Pagination}
   */
  objPagination: Pagination<Relate<StocktakeLine>> = new Pagination;

  /**
   * Simple flag to indicate if the forms
   * should be editable.
   *
   * @var {boolean}
   */
  bDisabledForms: boolean = false;

  /**
   * List of stocktake history that
   * were fetched based on the
   * date given.
   *
   * @var {Stocktake}
   */
  arHistory: Stocktake[] = [];

  /**
   * Holder for the selected date
   * of the stocktake history.
   *
   * @var {string}
   */
  strDateHistory: string;

  /**
   * Placeholder for the stocktake history.
   * This is separated from the objStocktake
   * because that is used for stocktake that
   * you are just about to create. This is only
   * for completed stocktake.
   *
   * @var {Stocktake}
   */
  objStocktakeHistory: Stocktake;

  /**
   * Simple getters to check if the current stocktake
   * is a completed one.
   *
   * @var {boolean}
   */
  get isStocktakeComplete(): boolean {
    return !isEmpty(this.objStockTake) && !isEmpty(this.objStockTake.id) && !isEmpty(this.objStockTake.stocktake_date);
  }

  constructor(
    private list: ListingService,
    private notification: NotificationService,
    private search: SearchService,
    private stock: StockManagementService,
    private recordService: RecordService,
  ) {}

  ngOnInit() {
    this.objWarehouse = new Relate<Warehouse>().buildRelates(
      switchMap(term => this.getWarehouses(term)),
      this.getWarehouses()
    );
  }

  /**
   * Adds another stock level field to the list of
   * stocktake items. This also creates the relate field objects
   * that are necessary for ng-selec fields.
   *
   * @param {number} numPlace
   * @param {StocktakeLine | null} objStockLevel
   *
   * @returns {void}
   */
  addStockLevelField(numPlace: number = 0, objStockLevel: StocktakeLine = null): void {

    let objNewRelate = new Relate<StocktakeLine>()
      .buildRelates(switchMap(term => {
        return this.stock.getStockLevelsRelate(term, this.objWarehouse.value.id)
          .map(response => {
            return response.map(item => {
              if (item.active) {
                return new StocktakeLine({
                  item_id: item['item_id'] || item['id'],
                  preferred_supplier: item['preferred_supplier'],
                  product_code: item['code'],
                  product_name: item['name'],
                  location: item['location'],
                  quantity: 0
                });
              } else {
                return new StocktakeLine(item)
              }
            }).filter(item => {
              return this.filterExistingStock(item);
            });
          });
      }),
    );

    if (objNewRelate) {
      if (this.objStockTake && objStockLevel) {
        let objStockTakeItem = this.objStockTake.items.find(item => item.item_id == objStockLevel.item_id);
        objStockLevel.quantity = objStockTakeItem.quantity;
      }
      objNewRelate.model = objStockLevel;
    }

    this.objPagination.list.splice(numPlace + 1, 0, objNewRelate);
  }

  /**
   * Remove the stock level from the list while also
   * marking the stocktake form as dirty since an
   * item has been deducted.
   *
   * @param {number} numIndex
   *
   * @returns {void}
   */
  removeStockLevelField(numIndex: number): void {
    this.bSaveDirty = true;
    this.objPagination.list.splice(numIndex , 1);

    this.checkEmptyLineItems()
  }

  /**
   * Clears the form of all items
   * and marks the form as dirty while also
   * adding stock level fields with no
   * preselected item yet.
   *
   * @returns {void}
   */
  clearAll(): void {
    this.bDisabledForms = false;
    this.bSaveDirty = true;
    this.objPagination.list = [];
    this.addMultipleStockLevels();
  }

  /**
   * Check if all lines are already empty.
   *
   * @returns {void}
   */
  checkEmptyLineItems(): void {
    let numItemIndex = this.objPagination.list.findIndex(item => item.value != null);

    if (numItemIndex < 0) {
      this.clearAll();
    }
  }

  /**
   * Save the stocktake, could be either saved
   * as draft or final.
   *
   * @param {'final' | 'draft'} strStatus
   *
   * @returns {void}
   */
  save(strStatus: 'final' | 'draft'): void {

    this.bSaveClicked = true;
    let arItems: StocktakeItemInterface[] = this.objPagination.list.filter(item => item.value).map(item => {
      return {
        name: item.value.product_name,
        item_id: item.value.item_id || item.value.id,
        quantity: item.value.quantity,
        code: item.value.product_code,
        location: item.value.location
      }
    });

    if (! isEmpty(arItems)) {
      if (arItems.findIndex(item => item.quantity < 0) > -1) {
        this.notification.notifyWarning('cannot_save_with_negative_adjustments');
      } else {
        this.bSaveLoading = true;
        this.stock.saveStocktake(strStatus, this.objWarehouse.value.id, arItems, this.objStockTake ? this.objStockTake.id : null)
        .subscribe(response => {
          this.bSaveClicked = false;
          this.bSaveDirty = false;
          this.bSaveLoading = false;

          if (strStatus == 'final') {
            this.bDisabledForms = true;
            this.objStockTake = null;
          } else {
            this.objStockTake = response.data;
          }

          this.notification.notifySuccess('success');
        });
      }
    } else {
      this.notification.notifyError('items_required');
    }
  }

  /**
   * When a stock level relate field is touched,
   * we mark form as dirty.
   *
   * @return {void}
   */
  onStockLevelChanged(): void {
    this.bSaveDirty = true;
  }

  /**
   * When a warehouse is selected, we show
   * a confirmation if user really does want to
   * change warehous when form is dirty.
   *
   * @returns {void}
   */
  onWarehouseSelected(): void {
    if (this.bSaveDirty) {
      this.notification.sendConfirmation(
        'stocktake_switch_warehouse_confirmation',
        'stocktake_switch_warehouse_header'
      ).subscribe(response => {
          if (response.answer == true) {
            this.clearAndFetchStockLevels();
          } else {
            this.objWarehouse.value = this.objWarehouse.previous_value;
          }
        });
    } else {
      this.clearAndFetchStockLevels();
    }
  }

  /**
   * When the stocktake history date input field changes.
   *
   * @param {field_value: {default_date: string}} objEvent
   */
  onDateChanged(objEvent: {field_value: {default_date: string}}): void {
    this.arHistory = [];
    this.objStocktakeHistory = null;
    if(objEvent.field_value && objEvent.field_value.default_date) {
      this.stock.listStocktake(moment(objEvent.field_value.default_date).utc().format('YYYY-MM-DD HH:mm:ss')).subscribe(response => {
        if (response.length < 1) {
          this.notification.notifyInfo('stocktakes_not_found_for_set_date');
        } else {
          this.notification.notifySuccess('stocktakes_found_for_set_date');
        }
        this.arHistory = response;
      });
    }
  }

  /**
   * When a stocktake history is selected.
   *
   * @param {Stocktake} objStockTake
   */
  onHistorySelected(objStockTake: Stocktake): void {
    this.bDisabledForms = true;
    this.objPagination.list = [];
    this.objStockTake = objStockTake;
    this.objStockTake.items.forEach((item, index) =>{
      this.addStockLevelField(index, new StocktakeLine({
        item_id: item.item_id,
        product_code: item.code,
        product_name: item.name,
        location: item.location,
        quantity: item.quantity,
        preferred_supplier: item.code
      }));
    });
  }

  /**
   * Adds multiple stock level fields
   * to the stocktake form.
   *
   * @param {number} numQuantity
   *
   * @returns {void}
   */
  private addMultipleStockLevels(numQuantity: number = 5): void {
    for (var i = 0; i < numQuantity; i++) {
      this.addStockLevelField();
    }
  }

  /**
   * Simply filters the existing stock so that
   * the user won't select an items
   * that is already in the stocktake form to
   * avoid duplicate records.
   *
   * @param {StocktakeLine} objStockLevel
   *
   * @returns {boolean}
   */
  private filterExistingStock(objStockLevel: StocktakeLine): boolean {

    let numRemoveExisting = this.objPagination.list.findIndex(objExistingStockLevel =>
      (objExistingStockLevel.value && objExistingStockLevel.value.id == objStockLevel.id)
    );

    return !(numRemoveExisting > -1);
  }

  /**
   * Clear the current stocktake form and then
   * check if there is an existing draft for the selected
   * warehouse and apply it on the form if there is.
   *
   * @returns {void}
   */
  private clearAndFetchStockLevels(): void {

    this.bDisabledForms = false;
    this.objStocktakeHistory = null;
    this.objStockTake = null;
    this.objPagination.initializeData();
    this.bLoader = true;

    if (this.objWarehouse) {
      this.stock.getStocktake(null, this.objWarehouse.value.id).pipe(
        switchMap((objResponse: Stocktake) => {
          if (objResponse && isEmpty(objResponse.stocktake_date)) {
            this.objStockTake = objResponse;
            return this.stock.getStockLevelsByItem(objResponse.items.map(item => {return item.item_id}), objResponse.warehouse_id);
          } else {
            return Observable.of(null);
          }
        })
      ).subscribe((objResponse : StockLevel[] | null) => {
        if (objResponse == null) {
          this.addMultipleStockLevels();
        } else {
          this.notification.notifyInfo('existing_stocktake_draft_found');
          objResponse.forEach((item, index) =>{
            this.addStockLevelField(index, item);
          });
        }
        this.bLoader = false;
      });
    } else {
      this.addMultipleStockLevels();
    }
  }

  /**
   * get warehouses remote
   *
   * @param term {string}
   * @returns
   */
  private getWarehouses(term: string = ''): Observable<Warehouse[]> {
    return this.search.getRemoteData({
      model: 'warehouses',
      label: null,
      placeholder: null,
      type: "INPUT",
      remoteModule: 'warehouses'
    }, term).map(objResults => {
      return objResults.response.map(objRecord => {
        return new Warehouse(objRecord as any);
      });
    });
  }
}
