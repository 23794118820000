<div class="container">
  <div class="row border-top pt-2 pb-2">
    <div class="col-sm-6 pt-1">
      <span class="module-title">
        <fa-icon [icon]="['far', 'file-alt']" aria-hidden="true"></fa-icon>
        &nbsp;
        {{ 'files' | translate }}
      </span>
    </div>
  </div>
  <ng-container *ngIf="(importRecord$ | async) as record">
    <div class="row border-top" *ngIf="record.is_finished === false">
      <span class="no-content">
        {{ 'resultset_cannot_be_downloaded_until_import_finishes' | translate }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="(whenImportRecordCompletes$ | async) as record">
    <div class="row border-top" *ngIf="record.erroneous_rows > 0 || record.progress_status === 'failed'">
      <div class="col-sm-5 file-texts">
        errors.csv
      </div>
      <div class="col-sm-3 file-texts">
        <fa-icon [icon]="['far', 'file-csv']"></fa-icon>&nbsp;
        text/csv
      </div>
      <div class="col-sm-3 text-center">
        <fa-icon [icon]="['fas', 'download']" class="file-icon-download" matTooltip="{{ 'download_file' | translate }}" (click)="downloadResultSet(record.id, 'errors')"></fa-icon>
      </div>
    </div>
    <div class="row border-top" *ngIf="record.imported_rows > 0">
      <div class="col-sm-5 file-texts">
        imported.csv
      </div>
      <div class="col-sm-3 file-texts">
        <fa-icon [icon]="['far', 'file-csv']"></fa-icon>&nbsp;
        text/csv
      </div>
      <div class="col-sm-3 text-center">
        <fa-icon [icon]="['fas', 'download']" class="file-icon-download" matTooltip="{{ 'download_file' | translate }}" (click)="downloadResultSet(record.id, 'success')"></fa-icon>
      </div>
    </div>
  </ng-container>
</div>
