<dialog-header [icon]="['fas', 'file-pdf']" label="annual_condition_report" [buttons]="arButtons" (onChange)="ngOnHeaderChange($event)"></dialog-header>
<hr />
<mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <form *ngIf="objForm" [formGroup]="objForm">
        <div class="row">
          <div class="col-12">
            <app-edit-field [item]="objFormMetadata['start_date']" [form]="objForm" (strCurrentValue)="updateDate('start_date', $event)" [strMode]="'add'" [strModule]="'sites_annual_condition_report'" [strUpdateValue]="objForm.controls['start_date'].value"></app-edit-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>