<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'hashtag']" ></fa-icon>
        <span> {{ arAssetData.serial_number }} - {{ arAssetData.asset_type_text }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="closeDialog()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button type="button" (click)="saveStatus()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
     
    </div>
  </div>
</header>
<hr class="separator"/>
<div class="form-container">
  <div class="row">
    <div class="col-2 label">
      {{ 'status' | translate }}
    </div>
    <div class="col-10">
        <ng-select
          class="input-select"
          [items]="arStatus"
          [clearable]="true" 
          [ngModelOptions]="{standalone: true}"
          [(ngModel)]="strStatus">
        <!-- DISPLAY IN SELECTED -->
        <ng-template ng-label-tmp let-item="item">
          <span class="ng-value-label">{{ item | translate }}</span>
        </ng-template>
        <!-- DISPLAY IN OPTION -->
        <ng-template  ng-option-tmp let-item="item">
          <span class="value-text"> {{ item | translate }} </span>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>