import { Address } from "./address";
import { Select } from "./select";

export class Opportunity {

  /**
   * Tax amount for the quote.
   */
  public address: Address;

  /**
   * Total amount of the opportunity.
   */
  public amount: number;

  /**
   * TODO: This should be casted as a Contact object.
   * but let's use any for now.
   */
  public contact: any;

  /**
   * TODO: We should have another object
   * that holds the common properties
   * of a record. Let's use any for now.
   */
  public record_properties: any;

  /**
   * Currency used by the opportunity.
   */
  public curency: string;

  /**
   * TODO: This should be casted as an object which
   * values are CustomField objects with index as
   * a string key but for now, let's use any.
   */
  public custom_attributes: any;

  /**
   * TODO: This should be casted as a Customer object
   * but for now let's use any.
   */
  public customer: any;

  /**
   * Date of when the opportunity should be
   * followed up.
   */
  public date_follow_up: Date;

  /**
   * The department id of the opportunity.
   *
   * If we're going to need some properties of the
   * department and not just the ID, we should cast this as
   * a Department object. But since we only need ID for now,
   * let's just cast this as a string.
   */
  public department_id: string;

  /**
   * When will the opportunity
   * be expected to close.
   */
  public forecast_close_date: Date;

  /**
   * (Unsure what's the purpose of this.
   * Just replace this if you know.)
   */
  public from_job_id: Date;

  /**
   * Flag to know if this opportunity
   * would use custom location.
   */
  public is_custom_location: boolean;

  /**
   * (Unsure what's the purpose of this.
   * Just replace this if you know.)
   */
  public is_include_forcasting: boolean;

  /**
   * Any other additional things to indicate
   * in the opportunity.
   */
  public notes: string;

  /**
   * The number of the opportunity but is
   * actually a string because users can add
   * their prefix in it.
   */
  public opportunity_number: string;

  /**
   * The primary contact id of the opportunity.
   *
   * This should also be typed as a contact
   * but we only need id for now so...string!
   */
  public primary_contact_id: string;

  /**
   * Since opportunities are a cluster of quotes
   * it might be a project so we store that name here.
   */
  public project_name: string;

  /**
   * The opportunity might be cancelled so here
   * we indicate the reason why.
   */
  public reason_for_lost: string;

  /**
   * TODO: Should be casted as a Site object
   * but let's do with any for now.
   */
  public site: any;

  /**
   * What stage is the opportunity in?
   */
  public stage: string;

  /**
   * What status is the opportunity in?
   */
  public status: string;

  /**
   * Summary of the opportunity, this is usually being
   * inherited by the quote.
   */
  public summary: string;

  constructor(properties: {
    address?: Address,
    amount?: number,
    contact?: any,
    record_properties?: any,
    curency?: string,
    custom_attributes?: any,
    customer?: any,
    date_follow_up?: Date,
    department_id?: string,
    forecast_close_date?: Date,
    from_job_id?: Date,
    is_custom_location?: boolean,
    is_include_forcasting?: boolean,
    notes?: string,
    opportunity_number?: string,
    primary_contact_id?: string,
    project_name?: string,
    reason_for_lost?: string,
    site?: any,
    stage?: string,
    status?: string,
    summary?: string
  } = {}){
    this.address = new Address().setProperties(properties.address);
    this.amount = properties.amount;
    this.contact = {
      'contact_id': properties['contact_id'],
      'contact_text': properties['contact_text'],
    };
    this.record_properties = {
      'created_at': properties['created_at'],
      'created_by': properties['created_by'],
      'created_type': properties['created_type'],
      'deleted_at': properties['deleted_at'],
      'id': properties['id'],
      'text': properties['text'],
      'external_id': properties['external_id'],
      'modified_by': properties['modified_by'],
      'modified_type': properties['modified_type'],
      'updated_at': properties['updated_at'],
      'user_id': properties['user_id'],
      'user_text': properties['user_text'],
    };
    this.curency = properties.curency;
    this.custom_attributes = properties.custom_attributes;
    this.customer = {
      'customer_id': properties['customer_id'],
      'customer_text': properties['customer_text'],
    };
    this.date_follow_up = properties.date_follow_up;
    this.department_id = properties.department_id;
    this.forecast_close_date = properties.forecast_close_date;
    this.from_job_id = properties.from_job_id;
    this.is_custom_location = properties.is_custom_location;
    this.is_include_forcasting = properties.is_include_forcasting;
    this.notes = properties.notes;
    this.opportunity_number = properties.opportunity_number;
    this.primary_contact_id = properties.primary_contact_id;
    this.project_name = properties.project_name;
    this.reason_for_lost = properties.reason_for_lost;
    this.site = {
      'site_id': properties['site_id'],
      'site_text': properties['site_text'],
    };
    this.stage = properties.stage;
    this.status = properties.status;
    this.summary = properties.summary;
  }

  /**
   * This should be inside a "Customer" object but for now let's
   * put it here.
   *
   * @return string
   */
  public getCustomerId(): string {
    if (this.customer['customer_id'] != undefined) {
      return this.customer['customer_id'];
    }
    return '';
  }

  /**
   * This should be inside a "Contact" object but for now let's
   * put it here.
   *
   * @return string
   */
  public getContactId(): string {
    if (this.contact['contact_id'] != undefined) {
      return this.contact['contact_id'];
    }
    return '';
  }

  /**
   * This should be inside a "Contact" object but for now let's
   * put it here.
   *
   * @return string
   */
  public getContactText(): string {
    if (this.contact['contact_text'] != undefined) {
      return this.contact['contact_text'];
    }
    return '';
  }

  /**
   * This should be inside a "Site" object but for now let's
   * put it here.
   *
   * @return string
   */
  public getSiteId(): string {
    if (this.site['site_id'] != undefined) {
      return this.site['site_id'];
    }
    return null;
  }

  /**
   * Return the current oppotunity id
   *
   * @return string
   */
  public getId(): string {
    if (this.record_properties['id'] != undefined) {
      return this.record_properties['id'];
    }
    return null;
  }
}