import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNull, isUndefined, toNumber } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { StrService } from '../../../../../services/helpers/str.service';

type OnChangeHandler = (value?: string) => void;
type OnTouchedHandler = () => void;

@Component({
  selector: 'fc-text-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    }
  ],
  template: `
    <input
      type="text"
      class="{{ customClass }}"
      maxlength="{{ maxlength }}"
      placeholder="{{ placeholder | translate }}"
      [(ngModel)]="value"
      [ngClass]="{'is-invalid': isValid}"
      (change)="onChange($event.target.value)"
      (keypress)="onKeyPressInput($event)"
    />
  `,
  styleUrls: ['./text.component.scss']
})
export class TextInputComponent implements ControlValueAccessor {

  constructor(
    private strService: StrService
  ) { }

  /**
   * Dictates if the field should be disabled
   */
  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Contains the value inputted
   */
  value: string;

  /**
   * apply custom class from parent component
   */
  @Input() customClass: string = 'form-control value-text';

  /**
   * determine if field is readonly
   */
  @Input() isReadonly: boolean = false;

  /**
   * determine if field is required, so we can add red border
   */
  @Input() isValid: boolean = false;

  /**
   * determine the max length
   */
  @Input() maxlength: string = null;

  /**
   * placeholder for input
   */
  @Input() placeholder: string = null;

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => {};

  /**
   * Callback when input value was changed
   */
  onChange: OnChangeHandler = () => {};

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (value?: string) => {
      fn(
        this.value = value
      );
    };
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: string): void {
    this.value = value
  }

  /**
   * prevent from typing non-alphanumeric characters from the text inputs
   */
  public onKeyPressInput(event: KeyboardEvent) {
    if (this.strService.hasInvalidCharacters(event.key)) {
      event.preventDefault();
    }
  }
}