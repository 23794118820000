import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';

const kBaseUrl: string = environment.url + "/job_skills/";


@Injectable()
export class JobSkillsService {

  constructor (private http: HttpClient) {}

	// Get all job skills in library
	getAllRequiredSkills() {
		return this.http.post<Response>(kBaseUrl + "get_all", '');
	}
}
