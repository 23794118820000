<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <div>
          <fa-icon [icon]="['far', 'arrow-alt-circle-right']" transform="shrink-3" class="dialog-title"></fa-icon>
        {{ 'converted_data' | translate }}
      </div>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<mat-dialog-content>
  <div *ngIf="!bLoading">
    <div class="row mb-3">
      <ng-container *ngFor="let convertedKey of arConvertedDataKey">
        <table *ngIf="convertedKey != 'quotes'" width="100%" class="table table-striped">
          <thead>
            <th>{{ convertedKey | translate }}</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row" *ngIf="arConvertedData[convertedKey] != undefined">
                  <div class="col-xs-12 col-md-12">
                      <a *ngIf="arConvertedData[convertedKey].length > 0" [routerLink]="['/'+convertedKey+'/'+arConvertedData[convertedKey][0]['id']]" (click)="cancelDialog()">
                        <span *ngIf="convertedKey == 'jobs' || convertedKey == 'opportunities'">#</span>
                        <span> {{ arConvertedData[convertedKey][0]['text'] }} </span>
                      </a>
                      <span *ngIf="arConvertedData[convertedKey].length < 1"> -- </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="arConvertedDataKey.length < 1">
        <div class="col-xs-12 col-md-12 text-center">
            {{ 'no_available_user' | translate }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <fa-icon *ngIf="bLoading" [icon]="['fas','spinner']" [spin]="true" aria-hidden="true" class="pr-1"></fa-icon>
    </div>
  </div>
</mat-dialog-content>