export class Select {

  id?: string;
  text?: string;
  primary?: string
  extras?: any
  selected?: boolean
  disabled?: boolean

  constructor(id?: any, text?: string, primary?: string, extras?: any, selected?: boolean, disabled?: boolean){
    this.id = id;
    this.text = text;
    this.primary = primary;
    this.extras = extras;
    this.selected = selected;
    this.disabled = disabled || false;
  }

}