import { Form } from './form';
import {Select} from '../objects/select';

export class Dropdown extends Form<string> {
  controlType = 'dropdown';
  options: Select[] = [];
  tag: boolean = false;
  hideSelected: boolean = true;
  closeOnSelect: boolean = false;
  maxSelectedItems: number = 100;
  clearable: boolean = false;
  is_hidden: boolean = false;
  list: string;

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.tag = options['tag'] || this.tag;
    this.hideSelected = options['hideSelected'] || this.hideSelected;
    this.closeOnSelect = options['closeOnSelect'] || this.closeOnSelect;
    this.maxSelectedItems = options['maxSelectedItems'] || this.maxSelectedItems;
    this.list = options['list'] || '';
    this.clearable = options['clearable'] || this.clearable;
    this.is_hidden = options['is_hidden'] || this.is_hidden;
  }
}