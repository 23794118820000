<header>
  <div class="d-flex dialog-header">
    <h3 class="title">
       <span>{{'client_info' | translate}}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="dialogClose()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr>
<div>
  <mat-dialog-content>
    <div>
      <div class="row">
        <div class="col-md-12 form-group text-font-size">
          <b>{{ 'client' | translate }}: </b> <span id="clientName">{{ clientData['name'] }}</span>
        </div>

        <div class="col-md-12 form-group text-font-size">
          <b>{{ 'client_id' | translate }}: </b> <span id="clientId">{{ clientData['client_id'] }}</span>
        </div>

        <div class="col-md-12 form-group text-font-size">
          <b>{{ 'alias' | translate }}: </b> <span id="clientAlias">{{ clientData['alias'] }}</span>
        </div>

        <div class="col-md-12 form-group text-font-size">
          <b>{{ 'country' | translate }}: </b> <span id="clientCountry">{{ clientData['country'] | translate }}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>