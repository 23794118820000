
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * This pipe is used for sanitizing urls that is potentially placed in an iframe.
 *
 * To use this pipe, simply add the 'safe' word just like using a normal pipe.
 *
 * Example:
 * <iframe [src]="strUrl | safe"></iframe>
 */

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
