import { isEqual } from 'lodash';

import { LooseObject } from '../../../../../objects/loose-object';

/**
 * A class that handles the storage of events that have been already rendered into
 * the calendar.
 *
 * @author CRM Online
 * @version 1.0.0
 */
export class RenderedEvents {

  /**
   * An array of rendered events.
   *
   * @type {LooseObject[]}
   */
  protected renderedEvents: LooseObject[] = [];

  /**
   * Adds an event to the array of rendered events.
   *
   * @param {LooseObject} event
   *
   * @returns {void}
   */
  push(event: LooseObject): void {
    if (this.hasId(event) === false) {
      throw new Error('An ID is required for an event to be rendered.');
    }

    this.renderedEvents.push(event);
  }

  /**
   * Returns an event from the array of rendered events
   *
   * @param id
   *
   * @returns {LooseObject}
   */
  get(id: string): LooseObject {
    let event = this.renderedEvents.find((event) => {
      return event.id === id;
    });

    return event === undefined ? null : event;
  }

  /**
   * Determines whether an event with given id already exists in the array of
   * rendered events.
   *
   * @param {string} id
   *
   * @returns {boolean}
   */
  has(id: string): boolean {
    return this.get(id) !== null;
  }

  /**
   * Compares the given event, looks for it in the array of rendered events then
   * determines whether its value has been changed.
   *
   * Gets the keys of the comparand, then compares each key's value to the comparator
   * to determine if atleast one (1) has not been changed. Inequality is immediately
   * assumed when keys of both are different or not equal in number
   *
   * @param {LooseObject} comparand
   * @param {LooseObject} comparator
   *
   * @returns {boolean}
   */
  isEqual(comparand: LooseObject, comparator: LooseObject): boolean {
    return isEqual(comparand, comparator);
  }

  /**
   * Looks for the given id in the list of rendered events then replaces its value
   * with the replacement value.
   *
   * @param {string} id
   * @param {LooseObject} replacement
   *
   * @returns {LooseObject}
   */
  replace(id: string, replacement: LooseObject): LooseObject {
    if (this.hasId(replacement) === false) {
      throw new Error('An ID is required for the replacement event.');
    } else if (replacement.id !== id) {
      throw new Error('The replacement event\'s id must be similar to original.');
    }

    let rendered_event_index = this.renderedEvents.findIndex((event) => {
      return event.id === id;
    });

    if (rendered_event_index > -1) {
      this.renderedEvents[rendered_event_index] = replacement;
    } else {
      this.push(replacement);
    }

    return replacement;
  }

  /**
   * Determines whether the given event has an id and is not null.
   *
   * @param {LooseObject} event
   */
  protected hasId(event: LooseObject): boolean {
    return event.hasOwnProperty('id') && event.id !== null;
  }
}
