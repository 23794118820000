import { Injectable } from '@angular/core';
import { NEVER, Observable, Subject, throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
// Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import { LooseObject } from '../objects/loose-object';
import { AdvanceSearchboxTemplate, StaticEnum } from '../objects/advance-searchbox';
import { TranslateService } from '@ngx-translate/core';
import { AdvanceSearchboxService } from './advance-searchbox.service';
import { map } from 'rxjs/operators/map';
import * as moment from 'moment'
import { tap } from 'rxjs/operators';
import { ErrorEventType, ErrorPublishingService } from './error-publishing.service';
import { SearchAfter } from '../objects/elasticsearch';
import { filled } from '../shared/utils/common';
import { LocalStorageService } from './local-storage.service';

const kBaseUrl: string = environment.url + '/list/';

@Injectable()
export class ListingService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private advanceSearchboxService: AdvanceSearchboxService,
    private router: Router,
    private errors: ErrorPublishingService,
    private localStorageService: LocalStorageService,
  ) {}

  public objTableHeaderFields: any;

  public arDisplayFields: string[][]
  public arDefaultFilterFileds: string[] = [
    "default_filter.7days", "default_filter.1month", "default_filter.recently_updated"
  ];
  public arModuleDefaultFilter: string[] = [];
  public arUSerList: string[] = [
    "admin", "desktop", "mobile-premium", "mobile-lite"
  ];
  public arFilterableFields: string[];

  public objPreviousPages = {};

  public strModule: string;
  public strFilter: string = '';
  public strDefaultFilter: string = '';

  public intCurrentPageNumber: number;
  public intNumberOfPages: number;
  public intNumberOfData: number;
  public intNumberOfItemPerPage: number;

  public strNextValue = '';
  public strPrevValue = '';
  public strFirstValue = '';
  public strCurrentValue = '';
  public objOrderBy: object = {};
  public intCurrentPage: number = 1;
  public intUpdatedRecord: number = 0;
  public objRecordList: object = {};

  get hasPrevPage() {
    return this.strFirstValue != this.strCurrentValue && this.strCurrentValue != '';
  }

  get hasNextPage() {
    return this.strNextValue != '';
  }

  public bLoading = false;
  public bNoResult = false;

  private strSearchItem = new Subject<any>();
  obvSearchItem$ = this.strSearchItem.asObservable();

  private arInformationFilter = new Subject<any>();
  obvInformationFilter$ = this.arInformationFilter.asObservable();

  public numInstanceId = Math.floor(Math.random() * 100000) + 1;
  public defaultOrderBy: object;

  public paginationConfig: LooseObject = {};

  public tokenFilter: LooseObject = {};

  /**
   * Bootstrap template data by updating some of its value such as translation of labelss
   *
   * @return  {void}
   */
   getAdvancedSearchboxTemplate(strModule: string = null): Observable<AdvanceSearchboxTemplate[]> {
    // Get metadata from the server
    return this.advanceSearchboxService.getMetadata(strModule || this.strModule).pipe(
      map((template) => {
        // Translate labels
        return template.map((t: AdvanceSearchboxTemplate) => {
          // For date/datetime filter
          const dateFilters = ['date', 'datetime'];
          // Update format based on locale
          if (dateFilters.includes(t.dataType)) {
            // Change locale: chrome://settings/languages
            // Date validation
            t.mask = {
              mask: t.placeholder
                // .replace(/\//g, '-')
                .replace('DD', 'd0')
                .replace('MM', 'M0')
                .replace('YYYY', '0000'),
              clearIfNotMatch: true,
              dropSpecialCharacters: false,
            };
          }
          // FC-3652: Add module origin in relate fields
          if (t.dataType === 'uuid') {
            t.moduleOrigin = this.strModule;
          }
          return t;
        });
      })
    );
  }

  /**
   * Get Module Filters from Server
   *
   * @return  {void}
   */
   getModuleFilters(): Observable<object> {
    return this.advanceSearchboxService.getFilters(this.strModule);
  }

  // Function for wrapping json request to 'filter'
  // if request of list has filter if none just display all
  wrappJsonData(jsonData) {
    if (jsonData != '') {
      let body = new URLSearchParams();
      body.append('filter', jsonData);
      return body.toString();
    }
    return '';
  }

  // Fetching api based on current page and current module
  fetchData(objPage, strModule, jsonFilterData, recordPerPage = null) {

    let body = new URLSearchParams();

    if (jsonFilterData != '') {
      body.append('filter', jsonFilterData);
    }

    if (objPage != '') {
      body.append('page', objPage);
    }

    if (filled(recordPerPage)) {
      body.append('per_page', recordPerPage);
    }

    return this.http.post(kBaseUrl + strModule, body.toString()).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: `${strModule}:list`,
            });
          }
        },
      })
    );
  }

  /**
   * Fetching api based on current page and current module (Advanced Search)
   *
   * @param   {object}  pageParams   Pagination Details
   * @param   {string}  module        Module Data to Fetch
   * @param   {object}  filterParams  Filter Parameters
   * @param   {object}  sortParams    Sort Parameters
   *
   * @return  {Observable<object>[]}  Search Results
   */
  fetchDataAdvanceSearch(
    page: object,
    module: string,
    filterParams: object,
    sortParams?: object,
    predefinedFilter?: string,
    pageSize?: number,
    options: FetchDataAdvanceSearchOptions = {}
  ): Observable<object[]> {
    const body = new URLSearchParams();

    // Prepare Request Body
    if (filterParams !== undefined && filterParams !== {}) {
      body.append('filter', JSON.stringify(filterParams));
    }
    if (sortParams !== undefined && sortParams !== {}) {
      body.append('sort', JSON.stringify(sortParams));
    }
    if (page !== undefined && page !== {}) {
      body.append('page', JSON.stringify(page));
    }
    if (predefinedFilter !== undefined && predefinedFilter !== null && predefinedFilter !== '') {
      body.append('filter_name', predefinedFilter);
    }
    if (pageSize && pageSize > 0) {
      body.append('page_size', pageSize.toString());
    }

    return this.http.post<object[]>(
      kBaseUrl + 'advanced/' + module,
      body.toString(),
      {
        headers: {
          ... (options.on_behalf_of_client && {
            'x-other-client-id': options.on_behalf_of_client,
          }),
        },
      }
    ).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: `${module}:list`,
            });
          }
        },
      })
    );
  }

  // Fetch available filters
  fetchAvailableFilters(strModule) {
    return this.http.post(kBaseUrl + strModule + '/available_filters', []);
  }

  // Save filters
  saveCreatedFilter(strModule, filters) {
    return this.http.post(kBaseUrl + strModule + '/save_filter', this.wrappJsonData(filters));
  }

  searchItem(anyToSearch) {
    this.strSearchItem.next(anyToSearch);
  }

  setModule(strModule: string) {
    this.strModule = strModule;
  }

  setFieldsToDisplay(arDisplayFields: string[][]) {
    this.arDisplayFields = arDisplayFields;
  }

  getFieldsToDisplay(): string[][] {
    return this.arDisplayFields;
  }

  // Set table header fields
  setTableHeaderFields(objData) {
    this.objTableHeaderFields = objData;
  }

  // get table header fields
  getTableHeaderFields() {
    return this.objTableHeaderFields;
  }

  getDefaultFilters(): string[] {
    return this.arDefaultFilterFileds;
  }

  // Set default filter
  setDefaultFilters(strFilter: string) {
    return this.strDefaultFilter = strFilter;
  }

  getModuleDefaultFilter() {
    return this.arModuleDefaultFilter;
  }

  setModuleDefaultFilter(arFilter: string[]) {
    this.arModuleDefaultFilter = arFilter;
  }

  getUserTypeList(): string[] {
    return this.arUSerList;
  }

  setFilter(strFilter: string) {
    this.strFilter = strFilter;
  }

  getFilter(): string {
    return this.strFilter;
  }

  /**
   * Set filter from information
   *
   * @param arFilter
   */
  setInformationFilter(arFilter: any) {
    this.arInformationFilter.next(arFilter);
  }

  setNextPageValue(strDate) {
    this.strNextValue = (strDate) ? strDate : '';
  }

  setPreviousPageValue(strDate) {
    this.strPrevValue = (strDate) ? strDate : '';
  }

  setCurrentPageValue(strDate) {
    this.strCurrentValue = (strDate) ? strDate : '';
  }

  getNextPageValue() {
    return this.strNextValue;
  }

  getPreviousPageValue() {
    return this.strPrevValue;
  }

  getFirstPageValue() {
    return this.strCurrentValue;
  }

  setCurrentPageNum(pageNum: number) {
    this.intCurrentPage = pageNum;
  }

  getCurrentPageNum() {
    return this.intCurrentPage;
  }

  setOrderBy(orderBy: object) {
    this.objOrderBy = orderBy;
  }

  getOrderBy() {
    return this.objOrderBy;
  }

  /**
   * Pass the page to access.
   * @param strPage - values can be next, prev, default, and reload (same page but needs update)
   */
  beforeFetching(strPage: string) {

    // Get filters if there are filters.
    let objFilters = (this.getFilter()) ? this.getFilter() : {};
    let objPage = {};

    // If accessing the first/default page.
    if (strPage == 'default') {
      this.setCurrentPageValue('');
      this.setNextPageValue('');
      this.setPreviousPageValue('');
    }

    this.updateToken();
    // If going to the next page.
    if (strPage == 'next') {
      objPage['direction'] = 'next';
      objPage['page'] = btoa(this.strNextValue);
      this.intCurrentPage++;
      objPage['pageNum'] = this.intCurrentPage;
    }

    // If going to the previous page.
    if (strPage == 'prev') {
      objPage['direction'] = 'prev';
      objPage['page'] = btoa(this.strPrevValue);
      this.intCurrentPage--;
      objPage['pageNum'] = this.intCurrentPage;
    }

    // If going to reload the page
    if (strPage == 'reload') {
      objPage['direction'] = 'prev';
      objPage['page'] = btoa(this.strCurrentValue);
      objPage['pageNum'] = this.intCurrentPage;
    }

    if (strPage === 'default') {
      this.intCurrentPage = 1;
    }

    // Set loading to true.
    this.bLoading = true;

    return { objPage, objFilters };
  }

  /**
   * After loading data from API, arrange the pagination.
   * @param data - response of the fetchData function.
   * @param strPage - the page being accessed.
   */
  afterFetching(data, strPage) {

    // Check if there is records.
    if (data['data'].length != 0) {

      this.bNoResult = false;
      // FC-1828: Store the the current record
      this.objRecordList[this.intCurrentPage] = data['data'];
      // check if order by id or sort is empty
      if (!data['order_by']['id'] || !data['order_by']['sort']) {
        // Set default order by
        data['order_by']['id'] = 'created_at';
        data['order_by']['sort'] = 'desc';
      }
      // FC-1828: Store the current sort
      this.objOrderBy = data['order_by'];
      // On first load of the page
      if (strPage == 'default') {

        let strTokenValue = data['data'][0][data['order_by']['id']];
        // We store the first value.
        this.strFirstValue = strTokenValue;
        // Store the first value of the list to both current and previous as it is the first page.
        this.setCurrentPageValue(strTokenValue);
        this.setPreviousPageValue(strTokenValue);

        // Initialize the object that will hold all the previous accessed page. This will be used when user presses previous.
        this.objPreviousPages = {};
      }

      // If the user choose next.
      if (strPage == 'next') {
        if (this.strCurrentValue != data['data'][0][data['order_by']['id']]) {
          // Store the value of the previous current page.
          this.setPreviousPageValue(this.strCurrentValue);
          // Store the first value from the list as it will be the new current page.
          this.setCurrentPageValue(data['data'][0][data['order_by']['id']]);
        }
      }

      if (strPage == 'prev') {
        // Store the current value.
        this.setCurrentPageValue(data['data'][0][data['order_by']['id']]);
        // Get the previous value from the history of accessed page.
        this.setPreviousPageValue(this.objPreviousPages[this.strCurrentValue.toString()]);
      }

      // If the user want to reload the page
      if (strPage == 'reload') {
        // Store the current value.
        this.setCurrentPageValue(data['data'][0][data['order_by']['id']]);
        // Get the previous value from the history of accessed page.
        this.setPreviousPageValue(this.objPreviousPages[this.strPrevValue.toString()]);
      }

      let strPrev = (this.strPrevValue) ? this.strPrevValue.toString() : '';
      // Store all the previous pages.
      this.objPreviousPages[this.strCurrentValue.toString()] = strPrev;

      // If the list is 10 records, set the last value from the list in the next page variable.
      if (data['hasNextToken']) {
        this.setNextPageValue(data['data'][data['data'].length - 1][data['order_by']['id']]);
      } else {
        // If the list is less than 10 records, there will be no next page.
        this.setNextPageValue('');
      }

    } else {

      this.bNoResult = true;
      // If the list returns none, no values should be stored.
      this.setNextPageValue('');
      this.setCurrentPageValue('');
      this.setPreviousPageValue('');
    }

    this.bLoading = false;
  }

  setDefaultOrderBy(objOrderBy: object) {
    this.defaultOrderBy = objOrderBy;
  }

  /**
   * FC-1828: Trigger this method if the user updates a record in listing view
   * We need to store the updated record to use the latest record as token in listing
   *
   * This should take effect on desc only, because we need to update the values in first page
   *
   * If the user updated a record and its desc, we need to update the first value to make the first page based on the latest updated record
   *
   * @params objRecord updated record
   *
   * @returns {void}
   */
  setUpdatedRecord(objRecord: object): void {
    if (objRecord[this.objOrderBy['id']] && this.objOrderBy['id'] == 'updated_at') {
      let strTokenValue = objRecord[this.objOrderBy['id']];
      if (this.intCurrentPage != 1 && this.objOrderBy['sort'] == 'desc') {
        this.strFirstValue = strTokenValue;
      }
      this.intUpdatedRecord++;
    }
    this.updateRecordList(objRecord);
  }

  /**
   * FC-1828: Splice and unshift the updated record
   *
   * @param objRecord
   *
   * @returns {void}
   */
  updateRecordList(objRecord) {
    var splice_success = false;
    // get the current page
    var record = cloneDeep(this.objRecordList[this.intCurrentPage]);
    // find the record and remove it
    record.forEach((data, index) => {
      if (data['id'] == objRecord['id']) {
        splice_success = true;
        return record.splice(index, 1);
      }
    });
    this.objRecordList[this.intCurrentPage] = record;
    // add the updated item in the top of first page
    if (splice_success) {
      this.objRecordList[1].unshift(objRecord);
    }

    this.rearrangeRecordList();
  }

  /**
   * FC-1828: Re arrange the record list
   *
   * @returns {void}
   */
  rearrangeRecordList(): void {
    var arrangedList: object = {};
    // rearrange the list
    let record_list = cloneDeep(this.objRecordList);
    for (var page = 1; page <= Object.keys(record_list).length; page++) {
      // get the current list record
      var data_set = record_list[page];
      var data_length = data_set.length;
      if (data_length > 10) {
        for (var extra_record = 10; extra_record < data_length; extra_record++) {
          // check if do we have next page
          if (record_list[page + 1]) {
            var extra_data = data_set[extra_record];
            // push the excess record on top of next page
            record_list[page + 1].unshift(extra_data);
            // Remove the extra record in list
            data_set.splice(extra_record, 1);
          }
        }
      }
      arrangedList[page] = data_set;
    }
    // Update the cord list
    this.objRecordList = arrangedList;
  }

  /**
   * update the token before requesting to api
   * create a set of updated pagination list
   *
   * @param strPage
   */
  updateToken() {
    if (this.intUpdatedRecord) {
      this.objPreviousPages = {};
      var orderById = this.objOrderBy['id'];
      for (var page = Object.keys(this.objRecordList).length; page >= 1; page--) {
        var current_page = this.objRecordList[page];
        var previous_page = this.objRecordList[page - 1];
        if (previous_page) {
          // update token list
          this.objPreviousPages[current_page[0][orderById]] = previous_page[0][orderById];
        }
      }
      var token = this.objRecordList[this.intCurrentPage][0][[orderById]];
      this.setPreviousPageValue(this.objPreviousPages[token]);
      this.intUpdatedRecord = 0;
    }
  }

  /**
   * get filter based on token
   *
   * @param token
   */
  getTokenFilter(token: string, page: string): Array<string> {
    return this.tokenFilter[btoa(token + page)];
  }

  /**
   * store filter based on token id
   *
   * @param token
   * @param filter
   */
  storeTokenFilter(token: string, page: string, filter: Array<string>): void {
    if (!this.tokenFilter[btoa(token + page)]) {
      this.tokenFilter[btoa(token + page)] = filter;
    }
  }

  /**
   * delete token filter
   */
  deleteTokenFilter(): void {
    this.tokenFilter = {};
  }

  /**
   * get the value of each field
   *
   * @param data
   * @param field
   */
  getKeyValue(data: Array<object>, field: string): Array<any> {
    return Object.keys(data).map(key => data[key][field]);
  }

  /**
   * store token filter before fetching the record
   *
   * @param page
   */
  setTokenBeforeFetching(page: string, filter: Array<any>): void {
    if (page == 'next') {
      this.storeTokenFilter(btoa(this.strNextValue), 'next', filter);
    }
  }

  /**
   * store token filter after fetching the record
   *
   * @param page
   */
  setTokenAfterFetching(page: string): void {
    if (page == 'next') {
      let tokenFilter = this.getTokenFilter(btoa(this.getPreviousPageValue()), 'next');
      this.storeTokenFilter(btoa(this.getPreviousPageValue()), 'prev', tokenFilter || []);
    }
  }

  /**
   * update pagination config
   *
   * @param paginationKey
   */
  updatePaginationConfig(paginationKey): void {
    if (!this.paginationConfig[paginationKey]) {
      this.paginationConfig[paginationKey] = {}
    }
    this.paginationConfig[paginationKey] = {
      next: this.strNextValue,
      previous: this.strPrevValue,
      first: this.strFirstValue,
      current: this.strCurrentValue
    };
  }

  /**
   * retrieve pagination config
   *
   * @param paginationKey
   */
  getPaginationConfig(paginationKey) {
    return this.paginationConfig[paginationKey] || null;
  }

  /**
   * set the pagination config to listing service
   *
   * @param paginationKey
   */
  setPaginationConfig(paginationKey): void {
    if (this.paginationConfig[paginationKey]) {
      this.strNextValue = this.paginationConfig[paginationKey].next;
      this.strPrevValue = this.paginationConfig[paginationKey].previous;
      this.strFirstValue = this.paginationConfig[paginationKey].first;
      this.strCurrentValue = this.paginationConfig[paginationKey].current;
    }
  }

  /**
   * Fetch all the scheduled invoices
   *
   * @param {string} strPage
   * @param {string} strFilterdata
   *
   * @returns {Observable<Object>}
   */
  fetchScheduledInvoices(strPage: string, strFilterdata: string): Observable<Object> {
    let body = new URLSearchParams();

    if (strFilterdata != '') {
      body.append('filter', strFilterdata);
    }

    if (strPage != '') {
      body.append('page', strPage);
    }

    body.append('per_page', this.getRecordPerPage().toString());

    return this.http.post(`${environment.url}/recurring_invoices/scheduled`, body.toString()).pipe(
      tap({
        error: (objErr: HttpErrorResponse) => {
          if (objErr.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: 'list_scheduled_invoices',
            });
          }
        },
      })
    );
  }

  /**
   * Fetch all the scheduled jobs
   *
   * @param {string} strPage
   * @param {string} strFilterdata
   *
   * @returns {Observable<Object>}
   */
   fetchScheduledJobs(strPage: string, strFilterdata: string): Observable<Object> {
    let body = new URLSearchParams();

    if (strFilterdata != '') {
      body.append('filter', strFilterdata);
    }

    if (strPage != '') {
      body.append('page', strPage);
    }

    body.append('per_page', this.getRecordPerPage().toString());

    return this.http.post(`${environment.url}/recurring_jobs/scheduled`, body.toString()).pipe(
      tap({
        error: (objErr: HttpErrorResponse) => {
          if (objErr.status === 403) {
            this.errors.publish({
              type: ErrorEventType.HTTP_ERROR,
              status: 403,
              for: 'list_scheduled_jobs',
            });
          }
        },
      })
    );
  }

  /**
   * Creates a URL that goes from list view to record view
   *
   * @param   {string}  strListModule
   * @param   {string}  strRecordId
   * @param   {number}  numRecordIndex
   * @param   {number}  numPage
   * @param   {string[]}  arNoViewModules
   * @param   {SearchAfter[]}  arSearchAfters
   * @param   {activatedRoute}  ActivatedRoute
   *
   * @return  {string}
   */
  getListRecordURL(
    strListModule: string,
    strRecordId: string,
    numRecordIndex: number = -1,
    numPage: number = 0,
    arNoViewModules: string[] = [],
    arSearchAfters: SearchAfter[] = [],
    activatedRoute: ActivatedRoute,
    params: LooseObject = {}
  ): string {
    switch (strListModule) {
      case 'pricebooks':
        return `/admin/pricebooks/edit/${strRecordId}`;
      case 'workflows':
        return `/admin/workflow/form/${strRecordId}`;
      case 'checklists':
        return `/admin/checklists/edit/${strRecordId}`;
      case 'stock_levels':
        return `/stock_levels/${strRecordId}?item_id=${params.item_id}`;
    }

    const objState: LooseObject = {};

    if (!arNoViewModules.includes(strListModule)) {
      let objQuery = params;

      if (numRecordIndex < 0) {
        objQuery = {...(strListModule == 'opportunities' && {from: 'create_quote'}) }
      }

      if (numRecordIndex > -1) {
        objState['fromListView'] = true;
        objState['page'] = numPage;
        objState['searchAfter'] = arSearchAfters[numRecordIndex];
      } else {
        objState['fromListView'] = false;
      }

      // If record is non admin, sample path generated is /jobs/7cb6d3ba-e038-4ea0-a7e7-b5febeb57e23
      // If admin, sample path is /admin/users/7cb6d3ba-e038-4ea0-a7e7-b5febeb57e23
      return this.router.serializeUrl(this.router.createUrlTree([strRecordId], {
        state: objState,
        queryParams: objQuery,
        relativeTo: activatedRoute
      }));
    }

    return '';
  }

  getPageFilter(page: any, currentPage: any = '1'): number {
    if (page == 'default' || page == 'reload') {
      page = 1;
    }

    if (page == 'next') {
      page = parseInt(currentPage) + 1;
    }

    if (page == 'prev') {
      page = parseInt(currentPage) - 1;
    }

    return page;
  }

  getRecordPerPage(): number {
    let currentClient = this.localStorageService.getJsonItem('current_client');
    return currentClient.config.listing_records_per_page || 25;
  }
}

type FetchDataAdvanceSearchOptions = {
  on_behalf_of_client?: string;
}
