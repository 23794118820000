<div class="pagination-body">
  <div class="container">
    <div class="row pt-2 pb-2">
      <div class="col-sm-6 pt-1">
        <span class="module-title">
          <fa-icon [icon]="['fa', 'inventory']" aria-hidden="true" class="pr-2"></fa-icon>{{ 'current_stock' | translate }}
        </span>
      </div>
      <div class="col-sm-6 pr-2">
        <nav class="pagination-section">
          <ul class="pagination">
            <refresh-pagination-btn (refresh)="onRefresh()" [isDisabled]="arStockLevels.length < 0" id="refresh-btn"></refresh-pagination-btn>
          </ul>
        </nav>
      </div>
    </div>

    <div class="row border-top p-2" *ngFor="let stockLevel of arStockLevels">
      <div class="col-12 pl-1 pr-1">
        <div class="row">
          <div class="col-4" matTooltip="{{ 'warehouse_name' | translate }}">
            <span class="text-label">{{ stockLevel['warehouse_name'] | translate }}</span>
          </div>
          <div class="col-4 container-padding location-wrap" matTooltip="{{ 'location' | translate }}: {{ stockLevel['location'] || '--'}}">
            <span class="text-label">{{ stockLevel['location'] || '--'}} </span>
          </div>
          <div class="col-2 container-padding" matTooltip="{{ 'stock' | translate }}">
            <span class="text-label">{{ stockLevel['quantity'] }} {{ stockLevel['unit'] | translate }}</span>
          </div>
          <div class="col-2 container-padding">
            <fa-icon [icon]="['fas', 'edit']" matTooltip="{{ 'update_stock_location' | translate }}" class="icon-update" (click)="openUpdateLocationDialog(stockLevel)"></fa-icon>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
