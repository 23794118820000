import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-file-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  objConfig: object = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PreviewComponent>
  ) {
    this.objConfig = data;
  }

  ngOnInit() {
  }

  /**
   * Close this dialog
   *
   * @return {void}
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
