<button type="button" [ngClass]="btnClass" *ngIf="! asSpan" matTooltip="{{ label | translate }}" [disabled]="isDisabled || (isInProgress$ | async)">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>

<span [ngClass]="btnClass" *ngIf="asSpan" matTooltip="{{ label | translate }}" [class.disabled]="isDisabled || (isInProgress$ | async)">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>

<ng-template #content>
  <button-icon-loader
    [isLoading]="isInProgress$ | async"
    [hasMarginClass]="false"
    [defaultIcon]="['fas', icon]"
    [ngClass]="! (isInProgress$ | async) && iconClass"
    [class.disabled]="isDisabled || (isInProgress$ | async)">
  </button-icon-loader>
  <span *ngIf="! hideText" class="ml-1">{{ label | translate }}</span>
</ng-template>
