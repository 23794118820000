import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Common } from '../../../../objects/common';
import { FormGroup, FormControl } from '@angular/forms';
import { Select } from '../../../../objects/select';

@Component({
  selector: 'app-editdialog',
  templateUrl: './editdialog.component.html',
  styleUrls: ['./editdialog.component.scss']
})
export class EditdialogComponent implements OnInit {

  public arAttributes: Common[] = [];
  public intFieldCounter: number = 0;
  public fieldLimit: number = 10;
  public bSubmitted: boolean = false;
  public arKeyDropdown: Select[] = [];
  private strKey: string = 'default';
  public strHeaderLabel: string = '';

  //Input labels for dialogs.
  private objFieldLabel = {
    'default': {'name_label': 'name', 'value_label': "value"},
    'phone': {'name_label': 'phone_name', 'value_label': "phone_number"},
  };
  
  constructor(public dialogRef: MatDialogRef<EditdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      //We get the key of the displayed field.
      if (this.objFieldLabel[data['strKey']] != undefined) {
        //If we have certain labels for certain key, we assign value to strKey.
        this.strKey = data['strKey'];
        this.arKeyDropdown = data['keyValues'];
        this.strHeaderLabel = data['strKey']
      } 
        
      //Gets the data to be shown in the input.
      this.arAttributes = data['dialogValues'];

      //Give a name for the input field (Having a name is necessary for form controls.)
      let intCount = 0;
      this.arAttributes = this.arAttributes.map(
        item => {
          intCount++;
          //Assign the name, adding an incremental value.
          item.type = 'nameField' + intCount;
          return item;
        }
      );

      if (this.arAttributes.length == 0) {
        this.addAttribute();
      }
    }

  ngOnInit() {}

  /**
   * Closes the dialog.
   */
  dialogClose(): void {
    this.dialogRef.close();
  }

  /**
   * Adds a new input field.
   */
  addAttribute() {
    //First checks if attributes fields exceeds the limit (Limit is 10, can be adjusted.)
    if (this.arAttributes.length < this.fieldLimit) {
      //Add a new field to the array of fields.
      this.arAttributes.push(new Common('', '', 'nameField' + this.arAttributes.length + 1));
    }
  }

  /**
   * Removes the input field.
   * @param attr 
   */
  removeAttribute(attr) {
    this.arAttributes.splice(this.arAttributes.indexOf(attr),1);
  }

  /**
   * Closes dialog and passes the data to the parent component.
   * @param formData 
   */
  onSubmit(formData) {

    this.bSubmitted = true;

    if (formData.valid) {
      this.dialogRef.close({'newData': this.arAttributes});
    }

  }

  /**
   * Checks if the input field is valid.
   * @param strFormName - name of the form.
   * @param formData - data of form.
   */
  isInvalid(strFormName, formData){

    if (formData.form.controls[strFormName] != undefined) {
      if (formData.form.controls[strFormName].status == "VALID") {
        return false;
      } 
    }
    
    return true; 
  }
}

