<button
  *ngIf="shouldDisplay"
  class="btn btn-primary dropdown-toggle mr-2"
  data-testid="btn_accounting"
  id="btnAccounting"
  data-toggle="dropdown"
>
  {{ 'accounting' | translate }}
</button>

<div class="dropdown-menu" data-testid="dd_accounting_menus">
  <a
    href="#"
    class="dropdown-item action-menu"
    [ngClass]="{'disabled': isSyncing}"
    data-testid="btn_sync_to_accounting"
    id="btnSyncToAccounting"
    (click)="sync($event)"
  >
    {{ 'sync_to_accounting' | translate }}
    <inline-view-loader
      class="pl-1"
      [isLoading]="isSyncing"
      [hasText]="false"
    ></inline-view-loader>
  </a>
  <a
    *fcAccessLevelRequired="'admin'"
    href="#"
    class="dropdown-item action-menu"
    [ngClass]="{'disabled': debugging}"
    data-testid="btn_sync_to_accounting_with_debug"
    id="btnSyncToAccountingWithDebug"
    (click)="sync($event, true)"
  >
    {{ 'sync_to_accounting_with_debug' | translate }}
    <inline-view-loader
      class="pl-1"
      [isLoading]="debugging"
      [hasText]="false"
    ></inline-view-loader>
  </a>
  <a
    *ngIf="isInvoiceModule"
    href="#"
    class="dropdown-item action-menu"
    [ngClass]="{'disabled': isPullingAnAmountDueUpdate}"
    data-testid="btn_update_amount_due"
    id="btnUpdateAmountDue"
    (click)="updateAmountDue($event)"
  >
    {{ 'update_amount_due' | translate }}
    <inline-view-loader
      class="pl-1"
      [isLoading]="isPullingAnAmountDueUpdate"
      [hasText]="false"
    ></inline-view-loader>
  </a>
</div>