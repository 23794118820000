// angular
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

// fieldmagic services
import { RecurringInvoiceService } from '../../../../../services/recurring-invoice.service';

interface IDialogData {
  /**
   * contains the current viewing recurring invoices id
   *
   * @var {string}
   */
  recurringInvoiceID: string;
}

interface IHistoricalInvoice {
  /**
   * contains the unique identifier for the customer invoice data
   *
   * @var {string}
   */
  id: string;

  /**
   * contains the data representation or display text of the current invoice
   *
   * @var {string}
   */
  invoiceNumber: string;

  /**
   * contains the invoice summary of the customer invoice
   *
   * @var {string}
   */
  invoiceSummary: string;

  /**
   * contains the taxable amount of the customer invoice
   *
   * @var {string}
   */
  amountTax: number;

  /**
   * contains the amount of the customer invoice excluding the tax
   *
   * @var {number}
   */
  amountTaxExcluded: number;

  /**
   * contains the amount of the customer invoice including the tax
   *
   * @var {number}
   */
  amountTaxIncluded: number;

  /**
   * contains the amount of the customer invoice that should be paid
   *
   * @var {number}
   */
  amountDue: number;
}

@Component({
  selector: 'app-historical-invoices',
  templateUrl: './historical-invoices.component.html',
  styleUrls: [ './historical-invoices.component.scss' ]
})
export class HistoricalInvoicesComponent implements OnInit {
  /**
   * contains the list of all the historical invoices from the API
   *
   * @var {Array<IHistoricalInvoice>}
   */
  arHistoricalInvoices: Array<IHistoricalInvoice> = [];

  /**
   * indicatest that the current listing is being loaded from the
   * API service
   *
   * @var {boolean}
   */
  isLoadingList: boolean = false;

  /**
   * @param {IDialogData}                               data
   * @param {RecurringInvoiceService}                   listService
   * @param {MatDialogRef<HistoricalInvoicesComponent>} currentDialog
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: IDialogData,
    protected recurringInvoiceService: RecurringInvoiceService,
    protected currentDialog: MatDialogRef<HistoricalInvoicesComponent>
  ) { }

  /**
   * angular lifecycle callback on template initialization
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.initList();
  }

  /**
   * closes this component dialog
   *
   * @returns {void}
   */
  closeDialog(): void {
    this.currentDialog.close();
  }

  /**
   * fetches and initializes the list from the API into our listing variable
   *
   * @returns {void}
   */
  protected initList(): void {
    // show loader
    this.isLoadingList = true;

    // call the service
    this.recurringInvoiceService.getHistoricalInvoices$(this.data.recurringInvoiceID).subscribe((objResponse) => {
      // only capture successful request
      if (objResponse.status === 200) {
        let arData: Array<any> = objResponse.body['data'] || [];

        // map each data and assign to the our list
        this.arHistoricalInvoices = arData.map<IHistoricalInvoice>((objData) => ({
          id: objData['id'],
          invoiceNumber: objData['invoice_number'],
          invoiceSummary: objData['invoice_summary'],
          amountDue: objData['amount_due'],
          amountTax: objData['amount_tax'],
          amountTaxExcluded: objData['amount_tax_excluded'],
          amountTaxIncluded: objData['amount_tax_included']
        }));
      }

      // hide loader
      this.isLoadingList = false;
    });
  }
}
