import { Component, Output, EventEmitter, Input } from '@angular/core';
import { WizardStepComponent, StepEventData, PROGRESS_NEXT, PROGRESS_FAILED } from '../../../connect.component';
import { DriverInterface } from '../../../../../entities/driver';

@Component({
  templateUrl: './configure.component.html',
  selector: 'wizard-step-configure-system-defaults',
})
export class ConfigureComponent implements WizardStepComponent {
   /**
   * {@inheritdoc}
   */
  @Output('event-progress') eventProgress: EventEmitter<StepEventData> = new EventEmitter<StepEventData>();

  /**
   * Currently selected driver
   *
   * @var {DriverInterface}
   */
  @Input('driver') driver: DriverInterface;

  /**
   * handles the event emitted from the custom metadata field component
   *
   * @param   {boolean} isSuccess
   *
   * @returns {void}
   */
  handleEventEmitted(isSuccess: boolean): void {
    this.eventProgress.emit({
      progress: (isSuccess) ? PROGRESS_NEXT : PROGRESS_FAILED
    });
  }
}
