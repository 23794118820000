import { Observable } from "rxjs/Observable";
import { Select } from "./select";
import { Subject } from "rxjs/Subject";

export class RecurringJobLineItem {

  /**
   * Asset type for the current
   * line item
   */
  asset_type_id: string;

  /**
   * Asset group for the current
   * line item
   */
  asset_group_id: string;

  /**
   * Set of id and name of the selected
   * checklist
   */
  checklist_ids: string[];

  /**
   * Department for the current
   * line item.
   */
  department_id: string;

  /**
   * User for the current
   * line item.
   */
  user_id: string;

  /**
   * Description for the task.
   */
  task_description: string;

  id: string;

  constructor(properties: {
    asset_type_id?: string
    asset_group_id?: string
    department_id?: string
    user_id?: string
    checklist_ids?: string[]
    task_description?: string
  } = {}){
    this.asset_type_id = properties.asset_type_id;
    this.asset_group_id = properties.asset_group_id;
    this.department_id = properties.department_id;
    this.user_id = properties.user_id;
    this.checklist_ids = properties.checklist_ids;
    this.task_description = properties.task_description;
  }
}