import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { ShareButtonComponent } from './components/buttons/share/share.button.component';
import { SelectClientInputComponent } from './components/input/select-client.input.component';
import { FileSharingModalComponent } from './modals/file-sharing.modal.component';
import { ClientAuthService } from './services/client-auth.service';
import { SharingDialogService } from './services/sharing-dialog.service';
import { SharingService } from './services/sharing.service';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    FontAwesomeModule,
    SharedModule,
  ],
  declarations: [
    SelectClientInputComponent,
    ShareButtonComponent,
    FileSharingModalComponent,
  ],
  exports: [
    ShareButtonComponent,
    SelectClientInputComponent,
  ],
  providers: [
    ClientAuthService,
    SharingService,
    SharingDialogService,
  ],
  entryComponents: [
    FileSharingModalComponent,
  ],
})
export class DataSharingModule {}