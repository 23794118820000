import { Validators } from '@angular/forms';
import isFileExtension from './validators/is-valid-file-extension.validator';
import isFileSize from './validators/file-size.validator';
import { CustomTemplate } from './contracts/custom-template';
import { SafetyManagementService } from '../../safety-management/shared/services/safety-management.service';
import { map } from 'rxjs/operators';
import { ADVANCED_PLAN } from '../../../objects/subscription-plans';

const SUPPORTED_MODULES = [
  'leads',
  'customers',
  'sites',
  'quotes',
  'jobs',
  'customer_invoices',
  'purchase_orders',
  'supplier_invoices',
  'opportunities'
];

const TEMPLATE_MAX_FILE_SIZE = '2MB';
const TEMPLATE_EXTENSIONS = ['odt'];

const TEMPLATE_FILE_RULES = [
  Validators.required,
  isFileExtension(TEMPLATE_EXTENSIONS),
  isFileSize(TEMPLATE_MAX_FILE_SIZE)
];

const TEMPLATE_FILE_RULES_UPDATE = [
  isFileExtension(TEMPLATE_EXTENSIONS),
  isFileSize(TEMPLATE_MAX_FILE_SIZE)
];

export {
  SUPPORTED_MODULES,
  TEMPLATE_MAX_FILE_SIZE,
  TEMPLATE_EXTENSIONS,
  TEMPLATE_FILE_RULES,
  TEMPLATE_FILE_RULES_UPDATE,
};


export const CUSTOM_MODULE_TEMPLATES: {[module: string]: CustomTemplate[]} = {
  jobs: [
    {
      label: "work_order",
      module: "jobs"
    },
    {
      label: "job_report",
      module: "jobs",
      filter: { type: 'job_report'}
    },
    {
      label: "swms_pdf",
      module: "job_swms",
      fn: (ctx, id) => ctx.get(SafetyManagementService).getPdf$(id).pipe(
        map(data => ({ body: data }))
      ),
      options: {
        preview_label: 'swms_pdf'
      },
      minimum_subscription_plan: ADVANCED_PLAN,
    }
  ],
  supplier_invoices: [
    {
        label: "supplier_invoice_report",
        module: "supplier_invoices"
    },
  ],
  assets: [
    {
    label: "asset_report",
    module: "assets"
    }
  ],
  customer_invoices: [
    {
        label: "customer_invoice_report",
        module: "customer_invoices"
    }
  ],
  purchase_orders: [
    {
        label: "purchase_order_report",
        module: "purchase_orders"
    },
  ]
};

export const DOCUMENT_TYPE_OPTIONS = {
  sites: [
      {
          id: 'site_asset_summary',
          text: 'asset_summary_report',
      },
      {
          id: 'site_annual_condition',
          text: 'annual_condition_report',
      }
  ],
  jobs: [
      {
          id: 'job_report',
          text: 'job_report',
      },
      {
          id: 'job_swms',
          text: 'swms_pdf',
      },
      {
          id: 'job_work_order',
          text: 'work_order',
      }
  ],
  checklists: [
      {
          id: 'asset_checklist',
          text: 'assets_checklist',
      },
      {
          id: 'job_checklist',
          text: 'job_checklist',
      }
  ],
  supplier_invoices: [
      {
          id: 'supplier_invoice_report',
          text: 'supplier_invoice_report',
      }
  ],
  assets: [
      {
          id: 'asset_report',
          text: 'asset_report',
      }
  ],
  customer_invoices: [
      {
          id: 'customer_invoice_report',
          text: 'customer_invoice_report',
      }
  ],
  purchase_orders: [
      {
          id: 'purchase_order_report',
          text: 'purchase_order_report',
      }
  ]
};