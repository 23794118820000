<ng-container *ngIf="fields !== undefined">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" #formRef="ngForm">
    <div class="form-group">
      <div class="col-xs-12 col-md-12">
        <label for="item_id">{{ 'default_item_id' | translate }}<fa-icon [icon]="['fas', 'info-circle']" class="change-pointer ml-2" matTooltip="{{ 'default_item_id_info' | translate }}"></fa-icon></label>
        <app-edit-field
          id="inputItemId"
          [item]="fields.item_id"
          [form]="form"
          data-testid="input_item_id"></app-edit-field>
      </div>
    </div>
    <div
      class="form-group col-12"
      *ngIf="shouldDisplayCustomItemForm"
      formGroupName="item"
      id="formNewLabor"
      data-testid="form_new_labor"
    >
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <label for="item_name">{{ 'item_name' | translate }}</label>
          <input
            id="inputItemName"
            data-testid="input_item_name"
            type="text"
            formControlName="name"
            maxlength="128"
            class="form-control">
        </div>
        <div class="col-xs-12 col-md-6">
          <label for="item_code">{{ 'item_code' | translate }}</label>
          <input
            id="inputItemCode"
            data-testid="input_item_code"
            type="text"
            formControlName="code"
            maxlength="64"
            class="form-control">
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <label for="purchase_account_code_id">{{ 'default_account_code_purchase' | translate }}</label>
          <app-edit-field
            id="inputPurchaseAccountCodeId"
            [item]="fields.purchase_account_code_id"
            [form]="form"
            data-testid="input_purchase_account_code_id"
          ></app-edit-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <label for="purchase_tax_code_id">{{ 'default_taxcode_purchase' | translate }}</label>
          <app-edit-field
            id="inputPurchaseTaxCodeId"
            [item]="fields.purchase_tax_code_id"
            [form]="form"
            data-testid="input_purchase_tax_code_id"
          ></app-edit-field>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <label for="sales_account_code_id">{{ 'default_account_code_sale' | translate }}</label>
          <app-edit-field
            id="inputSalesAccountCodeId"
            [item]="fields.sales_account_code_id"
            [form]="form"
            data-testid="input_sales_account_code_id"
          ></app-edit-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <label for="sales_tax_code_id">{{ 'default_taxcode_sale' | translate }}</label>
          <app-edit-field
            id="inputSalesTaxCodeId"
            [item]="fields.sales_tax_code_id"
            [form]="form"
            data-testid="input_sales_tax_code_id"
          ></app-edit-field>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <label for="adjustment_purchase_account_code_id">{{ 'account_code_for_purchase_tax_adjustment' | translate }}</label>
          <app-edit-field
            id="inputAdjustmentPurchaseAccountCodeId"
            [item]="fields.adjustment_purchase_account_code_id"
            [form]="form"
            data-testid="input_adjustment_purchase_account_code_id"
          ></app-edit-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <label for="adjustment_purchase_tax_code_id">{{ 'tax_code_for_purchase_tax_adjustment' | translate }}</label>
          <app-edit-field
            id="inputAdjustmentPurchaseTaxCodeId"
            [item]="fields.adjustment_purchase_tax_code_id"
            [form]="form"
            data-testid="input_adjustment_purchase_tax_code_id"
          ></app-edit-field>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <label for="adjustment_sales_account_code_id">{{ 'account_code_for_sales_tax_adjustment' | translate }}</label>
          <app-edit-field
            id="inputAdjustmentSalesAccountCodeId"
            [item]="fields.adjustment_sales_account_code_id"
            [form]="form"
            data-testid="input_adjustment_sales_account_code_id"
          ></app-edit-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <label for="adjustment_sales_tax_code_id">{{ 'tax_code_for_sales_tax_adjustment' | translate }}</label>
          <app-edit-field
            id="inputAdjustmentSalesTaxCodeId"
            [item]="fields.adjustment_sales_tax_code_id"
            [form]="form"
            data-testid="input_adjustment_sales_tax_code_id"
          ></app-edit-field>
        </div>
      </div>
    </div>
  </form>

  <div class="col-xs-12 col-md-12 form-group">
    <div class="d-flex justify-content-end d-flex-gap mt-3">
      <button class="btn btn-primary text-white" (click)="formRef.ngSubmit.emit()" [disabled]="isProcessing || ! form.valid">
        <inline-view-loader [isLoading]="isProcessing" className="mr-1 fa-1x" [hasText]="false"></inline-view-loader>{{ 'save' | translate }}
      </button>
      <button *ngIf="canBeSkipped" class="btn btn-danger" id="btn-skip-config" [disabled]="isProcessing" (click)="onSkip()">{{ 'skip' | translate }}</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="fields === undefined">
  <div class="col-xs-12 col-md-12 text-center">
    <inline-view-loader [isLoading]="true" [hasText]="false"></inline-view-loader>
  </div>
</ng-container>
