import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';

import { NewImportQueueFormComponent } from '../forms/new-import-queue-form/new-import-queue-form.component';
import { FieldMappingsComponent } from '../forms/field-mappings/field-mappings.component';
@Component({
  selector: 'import-records-list',
  templateUrl: './import-records-list.component.html',
  styleUrls: ['./import-records-list.component.scss']
})
export class ImportRecordsListComponent implements OnInit {

  objTableHeaderData: object[];

  constructor(public dialog: MatDialog) {
    this.objTableHeaderData = [
      {
        id: 'progress',
        label: 'progress',
        searchable: true,
        sortable: true,
        option: ''
      },
      {
        id: 'updated_at',
        label: 'date_modified',
        searchable: false,
        sortable: true,
        option: 'history_date'
      },
      {
        id: 'created_at',
        label: 'date_entered',
        searchable: false,
        sortable: true,
        option: 'history_date'
      },
    ];
  }

  ngOnInit() {
  }

  /**
   * Shows the new import dialog box that contains a dropdown
   * letting the users select which module to import. Users
   * can also download an import template from here.
   *
   * @returns {void}
   */
  showNewImportDialog(objParams: {add_form: string, module: string}): void {
    this.dialog.open(NewImportQueueFormComponent, {
      disableClose: true,
      panelClass: ['w-100'],
      data: {
        module: objParams ? objParams.module : null
      }
    });
  }

}
