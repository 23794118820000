<ng-template #popTemplate>
  <table class="table table-hover m-0 notification-box">
    <tbody class="table-full-width">
      <ng-container *ngFor="let notificationList of [arStickyNotifications, arNotifications]">
        <tr *ngFor="let notification of notificationList; index as i">
          <td class="p-2" (click)="notification.has_button === false && markAsRead(notification);">
            <div class="row">
              <div [ngClass]="(notification.has_button === true) ? 'col-8':'col-12'" class="col-8">
                <div class="row">
                  <div class="col-12 notification-font"
                    [ngClass]="(notification.is_read === true) ? 'text-secondary' : 'text-primary'">
                    <b><span class="float-left">{{ notification.header | translate }}</span></b>
                  </div>
                  <div class="col-12 notification-font mt-1"
                    [ngClass]="{'font-weight-bold': notification.is_read === false}">
                    {{ notification.message | translate }}
                  </div>
                </div>
              </div>
              <div *ngIf="notification.has_button === true" class="col-4 flex-box">
                <button (click)="getActionButton(notification);" type="button"
                  class="btn btn-danger p-2 float-right mx-auto my-auto">
                  {{ notification.button_title | translate }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="objNextToken">
        <td class="p-2">
          <div class="row">
            <div class="col-12">
              <div class="col-12 p-1 text-center notification-font">
                <a href="" [routerLink]="" (click)="getPreviousNotifications()"
                  class="notification-font">{{ 'load_previous_notifications' | translate }}</a>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="arNotifications.length > 0" class="row border-top mark-all-box">
    <div class="col-12 p-1 text-center notification-font">
      <a href="" [routerLink]="" (click)="markAllAsRead()" class="notification-font">{{ 'mark_all' | translate }}</a>
    </div>
  </div>
  <div *ngIf="arNotifications.length == 0 && arStickyNotifications.length == 0" class="row">
    <div class="col-12 p-2 text-center text-secondary notification-font">
      {{ 'no_notifications' | translate }}
    </div>
  </div>
</ng-template>

<li class="nav-item d-lg-flex" [popover]="popTemplate" [outsideClick]="true"  container="body"
  popoverTitle="{{ 'notifications' | translate }}" containerClass="notification-header-class" placement="bottom">
  <span class="nav-link">
    <a [routerLink]="" href="">
      <span *ngIf="numUnreadNotification > 0"
        class="badge badge-pill badge-danger notification-badge">{{ numUnreadNotification }}</span>
        <span class="hide-notification">
          <fa-icon [fixedWidth]="true" [icon]="['fas', 'bell']" size="lg" class="icon-style"></fa-icon> <span class="notification-label">{{ 'notification' | translate }}</span>
        </span>
        <!-- If PAGE IS MOBILE -->
        <span class="d-xl-none d-lg-none">
          <fa-icon [fixedWidth]="true" [icon]="['fas', 'bell']" size="lg" class="icon-style"></fa-icon> <span class="notification-label"></span>
        </span>
    </a>
  </span>
</li>
