<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title">
      {{ strHeaderLabel | translate }}
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="dialogClose()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
        <li *ngIf="arAttributes.length < 1" class="list-inline-item">
          <button type="button" (click)="addAttribute()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon><span class="pl-1">{{ 'add' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button (click)="f.ngSubmit.emit()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'save']"></fa-icon><span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
<header>
<mat-dialog-content class="mobile-view-dialog">
  <form #f="ngForm" (ngSubmit)="onSubmit(f)" validate>
    <div class="col-lg-12 col-md-12 col-sx-12">
      <table class="table table-striped">
        <thead>
          <tr *ngIf="arAttributes.length >= 1">
            <td>{{ objFieldLabel[strKey].name_label | translate }}</td>
            <td>{{ objFieldLabel[strKey].value_label | translate }}</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="arAttributes.length < 1">
            <td colspan="3" align="center">
              <span class="text-primary">
                  {{ 'no_content_dialog' | translate }}
              </span>
            </td>
          </tr>
          <tr *ngFor="let attr of arAttributes; let i = index">
            <td>
              <input *ngIf="strKey == 'default'" type="text" class="form-control" required [(ngModel)]="attr.id" [ngClass]="{'is-invalid': isInvalid(attr.type + 'id', f) && bSubmitted}" [name]="attr.type + 'id'"/>
              <ng-select *ngIf="strKey != 'default'" [items]="arKeyDropdown" [clearable]="false" bindLabel="text" bindValue="id" required [(ngModel)]="attr.id" [ngClass]="{'is-invalid': isInvalid(attr.type + 'id', f) && bSubmitted}" [name]="attr.type + 'id'" appendTo="body">
                <ng-template ng-option-tmp let-item="item">
                  {{ item.text | translate }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  {{ item.text | translate }}
                </ng-template>
              </ng-select>
            </td>
            <td>
              <input type="text" class="form-control" required [(ngModel)]="attr.value" [ngClass]="{'is-invalid': isInvalid(attr.type + 'value', f) && bSubmitted}" [name]="attr.type + 'value'"/>
            </td>
            <td align="center">
              <fa-icon [icon]="['fal', 'minus-circle']" class="pointer text-danger" size="1x" (click)="removeAttribute(attr)"></fa-icon>
              <fa-icon *ngIf="i == (arAttributes.length - 1)" [icon]="['fal', 'plus-circle']" size="1x" class="pointer text-success ml-2" (click)="addAttribute()"></fa-icon>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr *ngIf="arAttributes.length >= 1 && arAttributes.length == fieldLimit">
            <td colspan="3" align="center">
              <span class="text-danger">
                  {{ 'reach_maximum_number_of_attributes' | translate }} ({{fieldLimit}})
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </form>
</mat-dialog-content>
