import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNull, isUndefined, toNumber } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { StrService } from '../../../../../services/helpers/str.service';

type OnChangeHandler = (value?: string) => void;
type OnTouchedHandler = () => void;
type OnFocusOutHandler = () => void;

@Component({
  selector: 'fc-email-address-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailAddressInputComponent),
      multi: true,
    }
  ],
  template: `
    <input
      type="email"
      class="{{ customClass }}"
      maxlength="{{ maxlength }}"
      placeholder="{{ placeholder | translate }}"
      [(ngModel)]="value"
      [ngClass]="{'is-invalid': isInvalid}"
      (change)="onChange($event.target.value)"
      (focusout)="onFocusOutInput()"
      [email]="true"
      [readonly]="isReadonly"
    />
  `
})
export class EmailAddressInputComponent implements ControlValueAccessor {

  /**
   * Dictates if the field should be disabled
   */
  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Contains the value inputted
   */
  value: string;

  /**
   * apply custom class from parent component
   */
  @Input() customClass: string = 'form-control';

  /**
   * determine if field is readonly
   */
  @Input() isReadonly: boolean = false;

  /**
   * determine if field is required, so we can add red border
   */
  @Input() isInvalid: boolean = false;

  /**
   * determine the max length
   */
  @Input() maxlength: string = null;

  /**
   * placeholder for input
   */
  @Input() placeholder: string = null;

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => {};

  /**
   * Callback when input value was changed
   */
  onChange: OnChangeHandler = () => {};

  /**
   * Callback when input is touched
   */
  onFocusOut: OnFocusOutHandler = () => {};

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (value?: string) => {
      fn(
        this.value = value
      );
    };
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: string): void {
    this.value = value
  }

  public onFocusOutInput(): void {
    this.hasValidEmailAddress();
  }

  /**
   * validate the entered value
   *
   * @returns {boolean}
   */
  protected hasValidEmailAddress(): void
  {
    let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    this.isInvalid = !emailRegex.test(this.value);
  }
}