import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { ClientStoreService } from '../services/client-store.service';

const kBaseUrl: string = environment.lambda_url + "/pdf/";

@Injectable()
export class LambdaService {

  constructor(private http: HttpClient, private router: Router, private localStorageService: LocalStorageService, private clientStoreService: ClientStoreService) {
  }

  getPreviewUrl(parameter) {
    let options = { headers: this.getHttpHeaders() };
    return this.http.post<Response>(kBaseUrl + "render", parameter, options);
  }

  getDownloadUrl(parameter) {
    parameter['download'] = true;
    let options = { headers: this.getHttpHeaders() };
    return this.http.post<Response>(kBaseUrl + "render", parameter, options);
  }

  getEmailAttachment(parameter) {
    parameter['email'] = true;
    let options = { headers: this.getHttpHeaders() };
    return this.http.post<Response>(kBaseUrl + "render", parameter, options);
  }

  mergeFormFields(parameter) {
    let options = { headers: this.getHttpHeaders() };
    return this.http.post<Response>(kBaseUrl + "check_available_fields", parameter, options);
  }

  getHttpHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.localStorageService.getItem('access_token'),
      'client_id': this.clientStoreService.getActiveClient().client_id,
      'X-Lambda': '1'
    });
  }
}
