<app-error-handler onlyFor="recurring_jobs:list" alignment="center" displayAs="widget">
<div class="d-flex justify-content-around contacts-option tabs-content">
  <ng-container *fcHasPermission="'recurring_jobs:create'; else createRecurringJobDisabled">
    <span id="widgetRecurringJobsCreateButton" (click)="(bPageLoaded && bDialogLoaded) ? createRecurringJob() : ''" class="span-links">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ 'create_recurring_job' | translate }}
    </span>
  </ng-container>
  <ng-template #createRecurringJobDisabled>
    <span id="widgetRecurringJobsCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-default"></fa-icon>
        {{ 'create_recurring_job' | translate }}
    </span>
  </ng-template>
</div>

<div class="contact-roles-body">
  <div class="container">
    <div class="row border-bottom">
        <div class="col-lg-12 pt-4 pb-4">
            <div class="form-inline mt-12">
                <div class="form-group flex-nowrap w-100">
                    <div class="col-lg-4">
                        <label class="jc-l">{{ 'status' | translate }}</label>
                    </div>
                    <div class="col-lg-8">
                        <ng-select
                            [items]="arStatusFilter"
                            [clearable]="true"
                            class="value-text"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="strStatus"
                            (change)="fetchList('default')"
                            id="widgetRecurringJobsStatusFilter"
                            >
                            <ng-template ng-label-tmp let-item="item">{{ item | translate }}</ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              <span id="widgetRecurringJobsStatusFilter{{ item }}">{{ item | translate }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>

            <div class="form-inline mt-12">
                <div class="form-group flex-nowrap w-100">
                    <div class="col-lg-4">
                        <label class="jc-l">{{ 'billable' | translate }}</label>
                    </div>
                    <div class="col-lg-8">
                        <ng-select
                            [items]="arBillableFilter"
                            [clearable]="true"
                            class="value-text"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="strBillable"
                            (change)="fetchList('default')"
                            id="widgetRecurringJobsBillableFilter"
                            bindValue="id"
                            >
                            <ng-template ng-label-tmp let-item="item">{{ item.text | translate }}</ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              <span id="widgetRecurringJobsBillableFilter{{ item.id }}">{{ item.text | translate }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div class="row pt-2 pb-2">
          <div class="col-sm-6 pt-1">
              <span class="module-title">
                  <fa-icon [icon]="['fa', 'tools']" aria-hidden="true"></fa-icon>
                  &nbsp;
                  {{ 'recurring_jobs' | translate }}
              </span>
          </div>
          <div class="col-sm-6 pr-2">
              <nav class="pagination-section">
                  <ul class="pagination">
                    <refresh-pagination-btn id="widgetRecurringJobsRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bPageLoaded"></refresh-pagination-btn>
                    <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                        <a id="widgetRecurringJobsPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                            <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                        </a>
                    </li>
                    <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                        <a id="widgetRecurringJobsNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                            <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                        </a>
                    </li>
                  </ul>
              </nav>
          </div>
      </div>

      <div *ngIf="arRecurringJobs.length < 1">
          <div class="row border-top" *ngIf="bPageLoaded">
              <span class="no-content">
                  {{ 'no_recurring_jobs' | translate }}
              </span>
          </div>
      </div>
      <ng-container *ngIf="bPageLoaded">
          <div class="row border-top pt-2 pb-2 pr-2"  *ngFor="let recurring_job of arRecurringJobs" [id]="'widgetRecurringJobsRecord' + recurring_job.id ">
              <div class="col-sm-1" id="widgetRecurringJobsHistory" (click)="getRecurringJobHistory(recurring_job.id)">
                <div class="row">
                    <div class="col-12 truncate">
                        <fa-icon class="float-left icon-size text-success text-status" *ngIf="recurring_job.status === true" matTooltip="{{ 'active' | translate }}" [icon]="['fas', 'circle']"></fa-icon>
                        <fa-icon class="float-left icon-size text-danger text-status" *ngIf="recurring_job.status === false" matTooltip="{{ 'inactive' | translate }}" [icon]="['fas', 'circle']"></fa-icon>
                    </div>
                </div>
              </div>
              <div class="col-sm-5" (click)="getRecurringJobHistory(recurring_job.id)">
                  <div class="row" matTooltip="{{ 'period' | translate }}: {{ recurring_job.period | translate }} ">
                      <div class="col-12 truncate">
                          <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                          <span *ngIf="recurring_job.period != '' && recurring_job.period != null"> {{ recurring_job.period | translate }} </span>
                          <span *ngIf="recurring_job.period == '' ||recurring_job.period == null"> -- </span>
                      </div>
                  </div>
                  <div class="row" matTooltip="{{ 'next_job_date' | translate }}: {{ formatDate(recurring_job.next_job_date) }} ">
                      <div class="col-12 truncate">
                          <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                          <span *ngIf="recurring_job.next_job_date != '' && recurring_job.next_job_date != null"> {{ formatDate(recurring_job.next_job_date) }} </span>
                          <span *ngIf="recurring_job.next_job_date == '' ||recurring_job.next_job_date == null"> -- </span>
                      </div>
                  </div>
              </div>
               <div class="col-sm-5" (click)="getRecurringJobHistory(recurring_job.id)">
                    <div class="row" matTooltip="{{ 'priority' | translate }}: {{ recurring_job.priority }}">
                        <div class="col-12 truncate"><span>&nbsp;</span>
                            <fa-icon [icon]="['fas', 'exclamation']"></fa-icon>
                            <span *ngIf="recurring_job.priority != '' && recurring_job.priority != null"> {{ recurring_job.priority | translate }} </span>
                            <span *ngIf="recurring_job.priority == '' ||recurring_job.priority == null"> -- </span>
                        </div>
                    </div>
                    <div class="row" matTooltip="{{ 'job_summary' | translate }}: {{ recurring_job.job_summary }}">
                        <div class="col-12 truncate">
                            <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
                            <span *ngIf="recurring_job.job_summary != '' && recurring_job.job_summary != null"> {{ recurring_job.job_summary }} </span>
                            <span *ngIf="recurring_job.job_summary == '' ||recurring_job.job_summary == null"> -- </span>
                        </div>
                    </div>
              </div>
              <div class="col-sm-1 d-table text-role less-padding">
                  <div class="row">
                        <div class="col-12">
                            <ng-container *fcHasPermission="'recurring_jobs:edit'; else disabledEditButton">
                                <fa-icon id="widgetRecurringJobsEditButton" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="(bDialogLoaded) ? openRecurringJob(recurring_job) : ''"></fa-icon>
                            </ng-container>
                            <ng-template #disabledEditButton>
                                <fa-icon id="widgetRecurringJobsEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                            </ng-template>
                        </div>
                  </div>
                  <div class="row">
                        <div class="col-12">
                            <ng-container *fcHasPermission="'recurring_jobs:delete'; else disabledDeleteButton">
                                <fa-icon id="widgetRecurringJobsDeleteButton" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteRecurringJob(recurring_job.id)"></fa-icon>
                            </ng-container>
                            <ng-template #disabledDeleteButton>
                                <fa-icon id="widgetRecurringJobsDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                            </ng-template>
                        </div>
                  </div>
              </div>
          </div>
      </ng-container>
      <ng-container *ngIf="!bPageLoaded">
        <div class="row border-top">
            <span class="no-content">
                <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                {{ 'loading' | translate }}
            </span>
        </div>
      </ng-container>
  </div>
</div>
</app-error-handler>
