import { SubscriptionId } from '../../../../objects/subscription-plans';
import FormTemplateHistory from './form-template-history.entity';

export default class FormTemplate {
  /**
   * contains the unique identifier for the form
   *
   * @var {string}
   */
  id: string;

  /**
   * contains the name of the form
   *
   * @var {string}
   */
  name: string;

  /**
   * contains the module name for this module belongs to
   *
   * @var {string}
   */
  module: string;

  /**
   * tells if the current form is active or not
   *
   * @var {boolean|undefined}
   */
  isActive?: boolean = false;

  /**
   * contains the list of template history that is uploaded
   *
   * @var {FormTemplateHistory[]}
   */
  histories?: FormTemplateHistory[] = [];

  /**
   * contains the current default form template history
   *
   * @var {FormTemplateHistory}
   */
  history?: FormTemplateHistory;

  /**
   * contains the form creation date
   *
   * @var {string}
   */
  createdAt: string;

  /**
   * contains the form updated date
   *
   * @var {string|undefined}
   */
  updatedAt?: string;

  /**
   * contains the form updated date
   *
   * @var {string|null}
   */
  documentType?: string | null;

  /**
   * Contains the template minimum client subscription requirements
   *
   * @var {SubscriptionId|null}
   */
  minSubscription?: SubscriptionId | null;

  constructor(data: {
    id: string;
    name: string;
    moduleName: string;
    isActive?: boolean;
    histories?: FormTemplateHistory[];
    history?: FormTemplateHistory;
    createdAt: string;
    updatedAt?: string;
    documentType?: string;
    minSubscription?: SubscriptionId;
  }) {
    this.id = data.id;
    this.name = data.name;
    this.module = data.moduleName;
    this.isActive = data.isActive || false;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt || undefined;
    this.histories = data.histories || [];
    this.history = data.history || null;
    this.documentType = data.documentType || null;
    this.minSubscription = data.minSubscription || null;
  }
}
