import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-checklists',
  templateUrl: './checklists.component.html',
  styleUrls: ['./checklists.component.scss']
})
export class ChecklistsComponent implements OnInit {

  objTableHeaderData: object[];
  
  constructor() {    
    /**
     * First value should be a translatable label.
     * the following values should be the field's key.
     */
    this.objTableHeaderData = [
      {
        id: 'name',
        label: 'name',
        searchable: true,
        sortable: true,
        option: ''
      }
    ];
  }

  ngOnInit() {
  }
}
