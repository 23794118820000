
<div *ngIf="arFilterList">
  <h4>
      <span translate>{{ (strSelectedFilterName) ? 'update_filter' : 'advanced_filter' }}</span>
      {{ (strSelectedFilterName) ? '"' +strSelectedFilterName+ '"' : '' }}
    <fa-icon [icon]="['fas', 'times']" (click)="dialogClose()" class="float-right pointer"></fa-icon></h4>
  <hr />
  <mat-dialog-content class="mobile-view-dialog pt-3">
    <div class="row">
      <div *ngFor="let filterData of arFilterList" class="col-12">
          <div *ngIf="filterData.option; else optionRelate" class="form-group row">
              <label class="col-3 col-form-label">{{ filterData.label | translate }}: </label>
              <div class="col-9">
                <ng-select [(ngModel)]="arFilterModel[filterData.id]" multiple="{{ filterData.multiple }}" placeholder="{{ filterData.label | translate }}">
                  <ng-option *ngFor="let filterDataOption of filterData.options" [value]="filterDataOption.id">{{ filterDataOption.value | translate }}</ng-option>
                </ng-select>
              </div>
          </div>
          <ng-template #optionRelate>
            <div *ngIf="filterData.relate; else searchInput" class="form-group row">
              <label class="col-3 col-form-label">{{ filterData.label | translate }}: </label>
              <div class="col-9">
                <ng-select
                  [(ngModel)]="arFilterModel[filterData.id]"
                  [items]="arRelateModel[filterData.id] | async"
                  [loading]="arRelateLoading[filterData.id]"
                  [clearable]="true"
                  [typeahead]="arRelateValuesInput[filterData.id]"
                  bindLabel="text" 
                  bindValue="id"
                  multiple="{{ filterData.multiple }}"
                  (open)="triggerSubject(arRelateValuesInput[filterData.id])">
                </ng-select>
              </div>
            </div>
          </ng-template>
          <ng-template #searchInput>
            <div *ngIf="filterData.searchable == true" class="form-group row">
              <label class="col-3 col-form-label">{{ filterData.label | translate }}: </label>
              <div class="col-9">
                <input class="form-control" placeholder="{{ filterData.label | translate }}" [(ngModel)]="arFilterModel[filterData.id]"/>
              </div>
            </div>
          </ng-template>
      </div>
      <div class="col-12">
        <div class="form-group row">
          <label class="col-3 col-form-label">{{ 'share_filter_with_all_users' | translate }}: </label>
          <div class="col-9 col-form-label">
            <mat-slide-toggle [(ngModel)]="arFilterModel['share']"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="form-group">
      <button class="btn btn-danger float-right" (click)="deleteFilter()"><fa-icon [icon]="['fas', 'trash-alt']" class="pr-2"></fa-icon><span translate> {{ 'delete_filter' }}</span></button>
      <button *ngIf="!bUpdate" class="btn btn-primary float-right mr-2" (click)="saveFilter('save')"><fa-icon [icon]="['fas', 'save']" class="pr-2"></fa-icon><span translate>save_filter_as</span></button>
      <button *ngIf="!bUpdate" class="btn btn-primary float-right mr-2" (click)="saveFilter('set')"><span translate>set_filter</span></button>
      <button *ngIf="bUpdate" class="btn btn-primary float-right mr-2" (click)="saveFilter('update')"><fa-icon [icon]="['fas', 'save']" class="pr-2"></fa-icon><span translate>update_filter</span></button>
    </div>
  </mat-dialog-content>
</div>