import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContactComponent } from './components/steps/contact/contact.component';
import { ErrorHandlerModule } from '../../shared/modules/error-handler.module';
import { SharedModule } from '../../shared/modules/shared.module';
import { EditModule } from '../../shared/modules/edit.module';
import { FormService } from '../../services/form.service';
import { CustomerComponent } from './components/steps/customer/customer.component';
import { QuoteComponent } from './components/steps/quote/quote.component';
import { JobComponent } from './components/steps/job/job.component';
import { RouterModule } from '@angular/router';
import { AddressModule } from '../../shared/modules/address.module';
import { ListingService } from '../../services/listing.service';
import { WizardService } from './services/wizard.service';
import { CreateCustomerWizardComponent } from './components/create-customer-wizard/create-customer-wizard.component';
import { CreateJobWizardComponent } from './components/create-job-wizard/create-job-wizard.component';
import { CreateQuoteWizardComponent } from './components/create-quote-wizard/create-quote-wizard.component';
import { JobSummaryComponent } from './components/steps/summary/job-summary/job-summary.component';
import { CommonSummaryComponent } from './components/steps/summary/common-summary/common-summary.component';
import { ChooseInspectionPeriodComponent } from './components/steps/job/choose-inspection-period/choose-inspection-period.component';
import { WizardComponent as FlexibleWizardComponent } from './components/wizard/wizard.component';
import { SummaryComponent } from './components/steps/summary/summary.component';
import { MatStepperModule } from '@angular/material';
import { ViewService } from '../../services/view.service';
import { AutocompleteScrollDirective } from '../../shared/directives/autocomplete-scroll.directive';
import { SharedService } from './services/shared.service';
import { AddressService } from '../../services/address.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    MatStepperModule,
    ErrorHandlerModule,
    SharedModule.forRoot(),
    EditModule,
    RouterModule,
    AddressModule,
  ],
  exports: [
    FlexibleWizardComponent,
    ContactComponent,
    CustomerComponent,
    QuoteComponent,
    JobComponent,
    CreateCustomerWizardComponent,
    CreateJobWizardComponent,
    CreateQuoteWizardComponent,
  ],
  declarations: [
    FlexibleWizardComponent,
    ContactComponent,
    CustomerComponent,
    QuoteComponent,
    JobComponent,
    CreateCustomerWizardComponent,
    CreateJobWizardComponent,
    CreateQuoteWizardComponent,
    SummaryComponent,
    ChooseInspectionPeriodComponent,
    JobSummaryComponent,
    CommonSummaryComponent,
    AutocompleteScrollDirective
  ],
  entryComponents: [
    FlexibleWizardComponent,
    CreateCustomerWizardComponent,
    CreateJobWizardComponent,
    CreateQuoteWizardComponent,
    ContactComponent,
    CustomerComponent,
    QuoteComponent,
    JobComponent,
    ChooseInspectionPeriodComponent,
  ],
  providers: [
    FormService,
    ListingService,
    WizardService,
    ViewService,
    SharedService,
    AddressService,
  ]
})

export class WizardModule { }
