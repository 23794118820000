import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Select } from "../../../../../../objects/select";
import { ContactRolesService } from '../../../../../../services/contact-roles.service';

@Component({
  selector: 'app-role-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {

  @Input() arSiteList: Array<object>;
  @Output() objAction: EventEmitter<{
    action: string,
    data: object
  }> = new EventEmitter();

  constructor(
    private contactRolesService: ContactRolesService
  ) { }

  ngOnInit() {
  }

  /**
   * emit objAction data
   */
  emitAction(action: string, record: object): void {
    this.objAction.emit({
        action: action,
        data: record
    })
  }

  /**
   * translates the selected roles
   * unshift the primary role so will be visible in the display
   *
   * @param arRoles
   */
  translateRole(arRoles: Select[]): string {
    return this.contactRolesService.transformRole(arRoles);
  }
}
