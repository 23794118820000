<div>
	<h6>{{ 'save_quote_template' | translate }}<fa-icon [icon]="['fas','times']" (click)="dialogClose()" class="fw300 float-right pointer"></fa-icon></h6>
	<hr>
	<mat-dialog-content>
		<div>
			<label for="quote_number">
				{{ 'name' | translate }}
			</label>
			<input class="form-control" id="quote_number" name="quote_number" [(ngModel)]="strQuoteName" [ngClass]="{'is-invalid': (isClicked && strQuoteName != '')}"/>
		</div>
		<hr>
		<button (click)="saveName()" class="btn btn-primary float-right mb-1">{{ 'save' | translate }}</button>
	</mat-dialog-content>
</div>