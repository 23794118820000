<nav *ngIf="bHideTotalEntries" class="float-right pagination-section">
    <ul class="pagination">
        <li id="paginationFirstButton"
            class="page-item"
            (click)="objPagination.onStartPage()"
            [ngClass]="previousButton"
            [class.disabled]="objPagination.disableStartButton"
            [class.active]="!objPagination.disableStartButton">
            <a class="page-link" aria-label="first">
                <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
            </a>
        </li>
        <li id="paginationPreviousButton"
            class="page-item"
            (click)="objPagination.onPreviousPage()"
            [ngClass]="previousButton"
            [class.disabled]="objPagination.disablePreviousButton"
            [class.active]="!objPagination.disablePreviousButton">
            <a class="page-link" aria-label="first">
                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
            </a>
        </li>
        <li id="paginationNextButton"
            class="page-item"
            (click)="objPagination.onNextPage()"
            [ngClass]="nextButton"
            [class.disabled]="objPagination.disableNextButton"
            [class.active]="!objPagination.disableNextButton">
            <a class="page-link" aria-label="first">
                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
            </a>
        </li>
    </ul>
</nav>

<div *ngIf="!bHideTotalEntries" class="row">
    <div class="col-md-6 align-bottom">
        <span >
            {{ 'list_view_summary' | translate:  { fromRecords: objPagination.showFrom, toRecords: objPagination.showTo, totalRecords: objPagination.list.length } }}
        </span>
    </div>
    <div class="col-md-6">
        <nav class="float-right pagination-section">
            <ul class="pagination">
                <li id="paginationFirstButton"
                    class="page-item"
                    (click)="objPagination.onStartPage()"
                    [ngClass]="previousButton"
                    [class.disabled]="objPagination.disableStartButton"
                    [class.active]="!objPagination.disableStartButton">
                    <a class="page-link" aria-label="first">
                        <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
                    </a>
                </li>
                <li id="paginationPreviousButton"
                    class="page-item"
                    (click)="objPagination.onPreviousPage()"
                    [ngClass]="previousButton"
                    [class.disabled]="objPagination.disablePreviousButton"
                    [class.active]="!objPagination.disablePreviousButton">
                    <a class="page-link" aria-label="first">
                        <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                    </a>
                </li>
                <li id="paginationNextButton"
                    class="page-item"
                    (click)="objPagination.onNextPage()"
                    [ngClass]="nextButton"
                    [class.disabled]="objPagination.disableNextButton"
                    [class.active]="!objPagination.disableNextButton">
                    <a class="page-link" aria-label="first">
                        <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>