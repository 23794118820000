<div class="card">
  <div class="card-body border-top rounded">
    <div class="row">
      <div class="col-12">
        <div class="text-center mb-2 mt-3 main-text">
          <span class="font-weight-bold">{{ 'accounting_not_in_list' | translate }}</span>
        </div>
      </div>
      <br>
      <div class="col-12">
        <div class="sub-text text-center">
          <span>{{ 'accounting_not_in_list_sub' | translate }}</span>
        </div>
      </div>
      <div class="col-12">
        <ul class="list-group list-group-flush border-top">
          <li class="list-group-item text-center">
            <button type="button" (click)="onClick()" class="btn btn-primary text-white center-items btn-big suggest-btn mt-3" data-testid="compose-btn" [disabled]="isInProgress">{{ 'send_inquiry' | translate }}</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
