import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: 'mat-autocomplete[onScrollEndEvent]',
  exportAs: 'mat-autocomplete[onScrollEndEvent]'
})
export class AutocompleteScrollDirective {

  /**
   * Threshold percentage of the scroll.
   *
   * @var {number}
   */
  @Input() thresholdPercent: number = 0.8;

  /**
   * Informs the parent component when the scroll event occurs.
   *
   * @var {EventEmitter}
   */
  @Output('onScrollEndEvent') scroll = new EventEmitter<ScrollEndEvent>();

  /**
   * Used to inform observables when to stop listining.
   *
   * @var {Subject}
   */
  _onDestroy = new Subject();

  constructor(public autoComplete: MatAutocomplete) {

    this.autoComplete.opened
      .pipe(
        tap(() => {
          setTimeout(() => {
            this.removeScrollEventListener();
            this.autoComplete.panel.nativeElement.addEventListener(
              'scroll',
              this.onScroll.bind(this)
            );
          },0);
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();

    this.autoComplete.closed
      .pipe(
        tap(() => this.removeScrollEventListener()),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.removeScrollEventListener();
  }

  /**
   * Removes the event listener when not needed.
   *
   * @returns {void}
   */
  private removeScrollEventListener(): void {
    if (this.autoComplete && this.autoComplete.panel) {
      this.autoComplete.panel.nativeElement.removeEventListener(
        'scroll',
        this.onScroll
      );
    }
  }

  /**
   * When a scroll happens in mat-autocomplete element,
   * we trigger the scroll event when we hit the bottom.
   *
   * @param {Event} objEvent
   */
  onScroll(objEvent: Event): void {
    if (this.thresholdPercent === undefined) {
      this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: objEvent });
    } else {
      const scrollTop = (objEvent.target as HTMLElement).scrollTop;
      const scrollHeight = (objEvent.target as HTMLElement).scrollHeight;
      const elementHeight = (objEvent.target as HTMLElement).clientHeight;
      const atBottom = scrollHeight === scrollTop + elementHeight;
      if (atBottom) {
        this.scroll.next();
      }
    }
  }
}

export interface ScrollEndEvent {
  autoComplete: MatAutocomplete;
  scrollEvent: Event;
}
