import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StockLevel } from '../../../../../../objects/stock-management/stock-level';
import { NotificationService } from '../../../../../../services/notification.service';
import { RecordService } from '../../../../../../services/record.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-update-stock-level',
  templateUrl: './update-stock-level.component.html',
  styleUrls: ['./update-stock-level.component.scss']
})
export class UpdateStockLevelComponent implements OnInit {

  constructor(
    private record : RecordService,
    private dialogRef: MatDialogRef<UpdateStockLevelComponent>,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public objStockLevel: StockLevel) { }

  ngOnInit() {}

  /**
   * cancel dialog
   *
   * @returns {void}
   */
  closeDialog(isCancelled = true): void {
    this.dialogRef.close(isCancelled);
  }

  /**
   * Call API for updating stock level table
   *
   * @returns {void}
   */
  save(): void {
    if (isEmpty(this.objStockLevel.location)) {
      this.notificationService.notifyWarning('invalid_location_empty')
    } else if (this.objStockLevel.location.length > 32) {
      this.notificationService.notifyWarning('invalid_location_exceed_limit')
    } else {
      this.record.saveRecord('stock_levels', { location: this.objStockLevel.location }, this.objStockLevel.id)
      .subscribe(() => {
        this.closeDialog(false);
      });
    }

  }

}