import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { FileSharingModalComponent } from '../modals/file-sharing.modal.component';
import { FileSharingModalComponentData } from '../objects/modals/file-module-data';
import { SharingService } from './sharing.service';

@Injectable()
export class SharingDialogService {
  /**
   * A service responsable for any data-sharing dialog processes
   */
  constructor(
    protected readonly sharing: SharingService,
    protected readonly dialogFactory: MatDialog,
  ) {}

  /**
   * Attempts to show a file sharing dialog for the provided module when
   * criteria is met.
   */
  attemptToShowShareFilesDialog(moduleIds: string[], moduleName: string): Observable<void> {
    return this.sharing.getShareableModuleFiles({
      moduleIds,
      moduleName,
    }).pipe(
      filter((response) => response.items.length > 0),
      tap((response) => {
        this.dialogFactory.open
          <FileSharingModalComponent, FileSharingModalComponentData>(
            FileSharingModalComponent,
            {
              panelClass: 'w-30',
              data: {
                moduleIds,
                moduleName,
                initialFilesResponse: response,
              },
            },
          )
      }),
      map(() => {}), // maps to void
    )
  }
}