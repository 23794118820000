import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';

const kBaseUrl: string = environment.url + "/project_templates/";


@Injectable()
export class ProjectTemplatesService {
  public arTemplate: any = [];
  public strMode: string;

  constructor (private http: HttpClient, private router: Router) {}

	setProjectTemplate(arTemplate) {
			this.arTemplate = arTemplate;
		}

		getProjectTemplate(): any {
			return this.arTemplate;
	}
  
  	setMode(strMode) {
		this.strMode = strMode;
	}

	getMode(): string {
		return this.strMode;
	}
}
