import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';

import { LocalStorageService } from '../services/local-storage.service';
import { NotificationService } from '../services/notification.service';
import { BrowserService } from '../services/browser/browser.service';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogRef: MatDialog,
    private localstorageService: LocalStorageService,
    public auth: AuthService,
    public notifService: NotificationService,
    private http: HttpClient,
    private browserService: BrowserService,
    private translateService: TranslateService,
  ) {}

  public strErrorCode: string;
  public strErrorType: string;
  public strErrorMessage: string;
  public arNavigateLink = [
    {
      'name': 'error_link_account',
      'source': '/account/selection'
    }
  ];
  public isInternetExplorer6to11: boolean = this.browserService.isInternetExplorer6to11();

  ngOnInit() {

    // Make sure  to not show the admin redirect if user it not admin.
    if (this.localstorageService.getJsonItem('current_client') != null ) {
      let objClient = this.localstorageService.getJsonItem('current_client');
      if (objClient['level'] != undefined && objClient['level'] == 'admin') {
        this.arNavigateLink.push({
          'name': 'error_link_admin',
          'source': '/admin',
        })
      }
    }

    this.dialogRef.closeAll();

    this.route.params.subscribe(
      params => {
        this.strErrorCode = params['code'];
        this.strErrorType = params['type'];
        this.setErrorDisplay();
      }
    );
  }

  /**
   * Simply sets what should be the message
   * being shown to the user on the error page.
   *
   * @returns {void}
   */
  setErrorDisplay() {
    // Check if code is 404
    if (this.strErrorCode === '404') {
      // error type
      switch (this.strErrorType) {
        case 'record':
          // Display message
          this.strErrorMessage = 'error_404_record';
        break;
        default:
          // Display message
          this.strErrorMessage = 'error_404_page';
      }
    } else if (this.strErrorCode === '500') {
      switch (this.strErrorType) {
        case 'record':
          this.strErrorMessage = 'error_500_record';
        break;
        default:
          this.router.navigate(['error/404/page']);
        break;
      }
    } else if (this.strErrorCode === '403') {
      switch (this.strErrorType) {
        case 'unverified':
          this.strErrorMessage = 'resend_email_verification';
          // FC-3692: move setting default language to specific switch case instead of in the constructor
          this.translateService.setDefaultLang('en');
          this.translateService.use('en');
        break;
        default:
            this.strErrorMessage = 'error_403_page';
        break;
      }
    } else {
      this.router.navigate(['error/404/page']);
    }
  }

  /**
   * Refresh the token to see if
   * the user has already authenticated
   * his/her email address.
   *
   * @returns {void}
   */
  refreshToken() {
    this.auth.renewToken().then(() => {
      this.router.navigate(['/']);
    });
  }

  /**
   * Resend the email verification.
   *
   * @returns {void}
   */
  resendEmail() {
    if (this.localstorageService.getItem('auth_id') != undefined) {

      let body = new URLSearchParams();

      body.append('user_id', this.localstorageService.getItem('auth_id'));

      this.notifService.sendNotification('sending...', 'sending_verification', 'info');

      this.http.post(environment.url + '/resend_verification', body.toString(), {
        headers: {
          'X-Captcha': 'resend_email_verification',
        },
      }).subscribe(response => {

        let arResponse: any = response;

        if (arResponse['message'] == 'resend_success') {
          this.notifService.sendNotification('resend_success', 'verification_email_sent', 'success');
        } else {
          this.notifService.sendNotification('resend_error', 'verification_email_not_sent', 'warning');
        }

      });

    }
  }
}
