import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ListingService } from '../../../services/listing.service';
import * as moment from 'moment';
import { NotificationService } from '../../../services/notification.service';
import { Notification } from '../../../objects/notification';
import { EditformComponent } from '../../../shared/components/editform/editform.component';
import { ViewService } from '../../../services/view.service';
import { RecordService } from '../../../services/record.service';
@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
  providers: [ListingService],
})

export class SitesComponent implements OnInit {

  @Input() strRecordId : string;
  @Input() strModule : string;
  public arSites = [];
  public strFirstValue = '';
  public arPreviousPages = {};
  public bLoading = false;
  public bPageLoaded: boolean = false;
  constructor(
    public listService: ListingService,
    public notifService: NotificationService,
    private viewService: ViewService,
    private recordService: RecordService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // Get the list on page initialization.
    this.fetchList('default');
  }

  /**
   * Fetch the list of quote.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage) {

    let objPagination = this.listService.beforeFetching(strPage);
    let arFilter = {'customer_id': this.strRecordId};
    this.bLoading = true;
    // Get the list from API.
    this.listService.fetchDataAdvanceSearch(
      objPagination['objPage'],
      'sites',
      arFilter,
      { 'id': 'created_at', 'sort': 'desc' },
    ).subscribe(response => {

      this.arSites = response['data'];
      this.listService.afterFetching(response, strPage);
      this.bLoading = false;
      this.bPageLoaded = true;
    });
  }

  /**
   * Gets the proper phone icon type.
   *
   * @param {PhoneType} strPhoneType
   */
  getPhoneIcon(strPhoneType: PhoneType): PhoneIconType {
    let strPhoneIconType: PhoneIconType

    switch(strPhoneType) {
      case 'home':
        strPhoneIconType = 'home';
        break;
      case 'work':
        strPhoneIconType = 'phone-office';
        break;
      case 'mobile':
        strPhoneIconType = 'mobile-android-alt';
        break;
      case 'others':
        strPhoneIconType = 'phone-plus';
        break;
    }

    return strPhoneIconType;
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, arCustomConfig = []) {

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('lll');
  }

  notifyCopy() {
    this.notifService.sendNotification('copied', 'copied_to_clipboard', 'primary');
  }

  /**
   * Open dialog to edit record.
   * @param - the id of record if edit.
   */
  recordDialog(strId = '') {
    this.recordService.getRecordBasedOnParent(this.strModule == 'customers').subscribe( response => {
      let arRecordView = response['record_details'];
      let arData = {
        module : this.strModule
      }

      if (this.strModule == 'customers') {
        arData['address'] = arRecordView['address'];
        arData['customer_text'] = arRecordView['customer_text'];
        arData['customer_id'] = arRecordView['id'];
      }

      let popupConfig : {[k: string]: any} = {
          //Here, we pass all the data we need inside the dialog.
          data: {
              "arData": arData,
              "strModule": 'sites',
              "strId": strId,
              "strMode": (strId) ? 'edit' : 'add'
          },
          disableClose: true
        };

      // IF MOBILE
      if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
        // Display the pop up in full screen (WHOLE PAGE)
        popupConfig.width = '100%';
        popupConfig.height = '100%';
        popupConfig.maxHeight = '100vh';
        popupConfig.maxWidth = '100vw';
      } else {
        // display as pop up
        popupConfig.width = '80%';
        popupConfig.height = 'auto';
      }

      // This line initializes and opens dialog.
      let editRecordDialog = this.dialog.open(EditformComponent, popupConfig);

      editRecordDialog.afterClosed().first().subscribe(editRecordData => {
        if (editRecordData !== undefined && editRecordData.status == 'save') {
          // FC-3803: only add delay when creating new record
          let numDelay = editRecordData.data === 'record_update_success' ? 0 : 3500;

          setTimeout(() => {
            this.onRefresh();
          }, numDelay);
        }
      });
    })

  }

  /**
   *
   * @param strId - record id
   */
  deleteSite(strId) {
    //We tell the app component to open the confirmation.
    this.notifService.sendConfirmation().subscribe(
      confirmation => {
        //If the user confirmed, delete the record by field
        if (confirmation.answer) {
          this.recordService.deleteRecord('sites', strId).first().subscribe(
            data => {
              if (data) {
                this.fetchList('default');
                this.notifService.notifySuccess('record_delete_success')
              } else {
                this.notifService.sendNotification('failed', 'record_delete_failed', 'danger');
              }
            });
        }
      }
    );
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arSites = []; // clear list
    this.fetchList('default');
  }
}

type PhoneType = 'home' | 'work' | 'mobile' | 'others';
type PhoneIconType = 'home' | 'phone-office' | 'mobile-android-alt' | 'phone-plus';