export const ENTERPRISE_PLAN = 'fieldmagic-enterprise';
export const ADVANCED_PLAN = 'fieldmagic-advanced';
export const STARTER_PLAN = 'fieldmagic-starter';

export type SubscriptionPlanTypes = {
  ENTERPRISE_PLAN: 'fieldmagic-enterprise';
  ADVANCED_PLAN: 'fieldmagic-advanced';
  STARTER_PLAN: 'fieldmagic-starter';
}

export type SubscriptionId = SubscriptionPlanTypes['ENTERPRISE_PLAN'] | SubscriptionPlanTypes['ADVANCED_PLAN'] | SubscriptionPlanTypes['STARTER_PLAN'];
