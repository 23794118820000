import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Client } from '../../objects/client';
import { LocalStorageService } from '../local-storage.service';
import { ClientStoreService } from '../client-store.service';

/**
 * The clients list stored in local storage has this format:
 * <client id>: <client object>
 * We can use this type when getting the list of clients.
 *
 * @type {{ [key: string]: Client }}
 */
export type ClientListObject = { [key: string]: Client };

@Injectable({
  providedIn: 'root'
})
export class ClientsListStoreService {

  /**
   * A list of clients accessible by the current user.
   *
   * @type {BehaviorSubject<ClientListObject>}
   */
  private clientList: BehaviorSubject<ClientListObject> = new BehaviorSubject<ClientListObject>({});

  /**
   * Publicly accessible subscription for the clients list.
   *
   * @type {Observable<ClientListObject>}
   */
  public readonly clientList$: Observable<ClientListObject> = this.clientList.asObservable();

  /**
   * Similar to clientList$ but converts it to an array so that it
   * can be used easily within HTML templates' async pipe.
   *
   * @type {Observable<Client[]>}
   */
  public readonly clientListAsArray$: Observable<Client[]> = this.clientList.asObservable().pipe(
    map(clientListObject => Object.values(clientListObject))
  );

  constructor(protected localStorageService: LocalStorageService, protected clientStoreService: ClientStoreService) {
    if (this.localStorageService.hasItem('client_list')) {
      this.setClientList(this.localStorageService.getJsonItem('client_list'));
    }

    this.preserveSubscriptionDetailsOfClientsList();
  }

  /**
   * Returns a list of clients accessible to the current user.
   *
   * @returns {ClientListObject}
   */
  getClientList(): ClientListObject {
    return this.clientList.getValue();
  }

  /**
   * Similar to ClientStoreService::getClientList but converts
   * the client list to an array for easy manipulation.
   *
   * @returns {Client[]}
   */
  getClientListAsArray(): Client[] {
    return Object.values(this.getClientList()).map((client: Client) => { return client; });
  }


  /**
   * Stores the client list on local storage for later use.
   *
   * @param {ClientListObject} clients
   *
   * @returns {void}
   */
  setClientList(clients: ClientListObject): void {
    this.localStorageService.replaceJsonItem('client_list', clients);
    this.clientList.next(clients);
  }

  /**
   * Copies the given client onto the client list to make a new client list.
   *
   * @param {Client} client
   * @param {ClientListObject} client
   *
   * @returns {ClientListObject}
   */
  associateClientMetadataToClientList(client: Client, clientList: ClientListObject): ClientListObject {
    Object.values(clientList).forEach(() => {
      clientList[client.client_id].subscription_id = client.subscription_id;
      clientList[client.client_id].subscription_plan_id = client.subscription_plan_id;
      clientList[client.client_id].subscription_status = client.subscription_status;
      clientList[client.client_id].subscription_trial_end = `${client.subscription_trial_end}`;
      clientList[client.client_id].subscription_trial_start = `${client.subscription_trial_start}`;
    });

    return clientList;
  }

  /**
   * Removes the clients list from local storage.
   *
   * @returns {void}
   */
  removeClientList(): void {
    this.localStorageService.removeItem('client_list');
    this.clientList.next({});
  }

  /**
   * In the clients selection page, preventing access is crucial. So
   * everytime subscription information gets changed, we must always be
   * sure to update the client info stored in local storage since this
   * data only gets set AFTER EVERY LOGIN. Doing this will ensure that
   * even after the user gets back to the clients selection page without
   * logging out, we'll have updated subscription data.
   *
   * @returns {void}
   */
  private preserveSubscriptionDetailsOfClientsList(): void {
    this.clientStoreService.whenActiveClientIsSet$
      .subscribe((activeClient) => {
        let updatedClientList = this.associateClientMetadataToClientList(activeClient, this.getClientList());

        this.setClientList(updatedClientList);
      });
  }
}
