<button id="actionButton" class="btn-primary btn dropdown-toggle ml-2" data-toggle="dropdown" [attr.data-testid]="'actions:' + module">{{ 'actions' | translate }}</button>
<div class="dropdown-menu dropdown-content">
  <ng-container *ngFor="let action of actions">
    <div [id]="'actionButton' + action.id"  class="dropdown-item action-item" (click)="onClick(action, $event)" [attr.data-testid]="'action:' + action.id">
      <span>{{ action.label | translate }}</span>
    </div>
  </ng-container>
  <div *ngIf="(actions).length < 1" class="dropdown-item action-item disabled" data-testid="action:empty">
    <span>{{ 'no_actions' | translate }}</span>
  </div>
</div>