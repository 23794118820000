import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor() { }

  /**
   * Is the user browsing from Internet Explorer 6-11?
   *
   * @returns {boolean}
   */
  isInternetExplorer6to11(): boolean {
    return (navigator.userAgent.indexOf('MSIE') > -1 || navigator.userAgent.indexOf('Trident') > -1);
  }

  /**
   * Forces the browser to download the specified file.
   *
   * @see {@link https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api} For an in-depth
   * explanation on how forced downloads are done in Angular.
   * @see {@link https://stackoverflow.com/a/51852908/8449155}
   * @see {@link https://stackoverflow.com/a/50011806/8449155 For an explanation on how to rename File objects.}
   *
   * @param {File | Blob} file
   * @param {string} custom_filename
   *
   * @returns {void}
   */
  forceDownload(file: File | Blob, custom_filename?: string): void {
    // To determine whether a file is a BLOB, we'll have to check
    // if it isn't a File instead. This is because we cannot compare
    // the instance of File against Blob due to the fact that File
    // extends Blob.
    let file_is_blob = file instanceof File === false;

    if (file_is_blob && custom_filename === undefined) {
      throw new Error('Force downloading of BLOBs require a filename.');

    } else if (file instanceof File && custom_filename !== undefined) {
      // File objects cannot be renamed directly because of its readonly
      // state. As a workaround, we're just going to have to create a new
      // file with the same content, but this time, using the custom
      // filename as the filename.
      file = new File([file], custom_filename, { type: file.type });
    }

    let link = document.createElement('a');

    link.href = window.URL.createObjectURL(file);
    link.target = '_blank';
    link.download = file instanceof File ? file.name : custom_filename;

    link.click();
  }

  /**
   * Extracts the filename from the http response's content
   * disposition header.
   *
   * @see {@link https://stackoverflow.com/a/55391182/8449155}
   *
   * @param {HttpResponse<Blob>} response
   *
   * @returns {String}
   */
  getFilenameFromResponseHeaders(response: HttpResponse<Blob>): string {
    return response.headers.get('content-disposition')
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim();
  }
}
