import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LooseObject } from '../../../../../objects/loose-object';
import { MaterialsService } from '../../../../../services/materials.service';
import { filled } from '../../../../../shared/utils/common';

@Component({
  selector: 'app-create-from-work-order',
  templateUrl: './create-from-work-order.component.html',
  styleUrls: ['./create-from-work-order.component.scss']
})
export class CreateFromWorkOrderComponent implements OnInit {

  public selectAll: boolean = false;

  public loading: boolean = true;

  public lineItems: LooseObject[] = [];

  public hasSelected: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialogRef<CreateFromWorkOrderComponent>,
    private materialService: MaterialsService,
  ) { }

  ngOnInit() {
    this.materialService.getWorkOrderLineItems(this.data.job_id).subscribe( response => {
      if (response.data) {
        this.lineItems = response.data.map( lineItem => {
          let disable = this.isDisabled(lineItem);

          lineItem.selected = false;
          lineItem.error = disable.error;
          lineItem.disabled = disable.disabled;
          lineItem.max_quantity = lineItem.available_quantity;
          lineItem.receive_quantity = lineItem.available_quantity;
          return lineItem;
        });
      }

      this.loading = false;
    });
  }

  /**
   * on change checkbox
   *
   * @param event
   * @param lineItem
   * @param selectAll
   */
  onChangeCheckbox(event, lineItem: LooseObject, selectAll: boolean = false): void {
    let isChecked: boolean = event.currentTarget['checked'];
    if (selectAll) {

      this.lineItems.map( lineItem => {
        if (lineItem.disabled  == false) {
          lineItem.selected = isChecked
        }
        return lineItem;
      });
    } else {

      lineItem.selected = isChecked;
      if (lineItem.selected) {

        let unCheckedLineItems = this.lineItems.filter( lineItem =>
          lineItem.disabled == false && lineItem.selected == false
        );
        if (unCheckedLineItems.length == 0) {
          this.selectAll = true;
        }
      } else {

        this.selectAll = false;
      }
    }

    this.hasSelected = this.lineItems.filter( lineItem =>
      lineItem.disabled == false && lineItem.selected == true
    ).length != 0;
  }

  /**
   * when changing the quantity, we should automatically check the line item
   *
   * @param value
   * @param lineItem
   */
  onChangeQuantity(value, lineItem: LooseObject): void {
    this.onChangeCheckbox({
      currentTarget: {
        checked: true
      }
    }, lineItem)
  }

  /**
   * close the current dialog
   *
   * @param {boolean} created
   * @param {string} message
   */
  cancelDialog(action: string = 'close', created: boolean = false, message: string = ''): void {
    this.dialog.close({
      action: action,
      is_created: created,
      message: message
    });
  }

  /**
   * to determine if user is allowed to select the line item
   *
   * @param lineItem
   * @returns
   */
  isDisabled(lineItem: LooseObject): LooseObject
  {
    if (lineItem.labor == true) {
      return {
        error: 'material_labour_true',
        disabled: true
      };
    }

    if (lineItem.available_quantity <= 0) {
      return {
        error: 'material_used',
        disabled: true
      };
    }

    return {
      error: null,
      disabled: false
    };
  }

  /**
   * create materials
   */
  addMaterials(): void {
    let selectedLineItems = this.lineItems.filter( lineItem =>
      lineItem.disabled == false && lineItem.selected == true
    );
    if (selectedLineItems) {
      this.materialService.createFromWorkOrder(this.data.job_id, selectedLineItems).subscribe( response => {
        this.cancelDialog('saved', true, response.data.length ? 'materials_created_success' : response.message);
      });
    }
  }

  /**
   * get display text
   *
   * @param lineItem
   * @returns
   */
  getProductDisplayText(lineItem): string {
    if (filled(lineItem.item_code) && filled(lineItem.item_name)) {
      return `[${lineItem.item_code}] ${lineItem.item_name}`;
    } else {
      return lineItem.description;
    }
  }
}
