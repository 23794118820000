import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {

  public objConfig = {
    zoomFactor: 0.1,
    containerBackgroundColor: 'white',
    wheelZoom: true,
    allowFullscreen: true,
    allowKeyboardNavigation: true,
    btnIcons: {
      zoomIn: 'fa fa-plus',
      zoomOut: 'fa fa-minus',
      rotateClockwise: 'fa fa-repeat',
      rotateCounterClockwise: 'fa fa-undo',
      next: 'fa fa-arrow-right',
      prev: 'fa fa-arrow-left',
      fullscreen: 'fa fa-arrows-alt',
    },
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: true,
      rotateCounterClockwise: true,
      next: true,
      prev: true
    }
  };

  /**
   * List of images to view.
   *
   * @var {string}
   */
  public arImages: string[] = [];

  constructor (@Inject(MAT_DIALOG_DATA) public data: any) {
    if (typeof data['image_url'] === 'string') {
      this.arImages.push(data['image_url']);
    }
  }

  ngOnInit() {}

}
