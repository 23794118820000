<header>
  <div class="d-flex">
    <h3 class="title">
      <fa-icon [icon]="['fas', 'comments']"></fa-icon>&nbsp;
      <span>{{ 'new_message'| translate }} </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="selfDialog.close()" class="btn btn-danger">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
        <li *ngIf="!bIsInternalChat" class="list-inline-item">
          <div class="dropdown">
            <button (click)="loadTemplate()" class="btn btn-primary dropdown-toggle" type="button" id="btnTemplates"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ 'sms_templates' | translate }}
            </button>
            <div class="dropdown-menu" aria-labelledby="btnTemplates">
              <span *ngIf="objTemplates.loaded && objTemplates.templates.length == 0"
                class="dropdown-item font-size-12 text-secondary">
                {{ 'no_template' | translate }}
              </span>
              <span *ngIf="!objTemplates.loaded && objTemplates.templates.length == 0"
                class="dropdown-item font-size-12 text-secondary text-center">
                <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              </span>
              <span *ngFor="let template of objTemplates.templates" class="dropdown-item font-size-12 pointer"
                (click)="useTemplate(template)">
                {{ template.name }}
              </span>
            </div>
          </div>
        </li>
        <li class="list-inline-item">
          <button (click)="onSubmit()" class="btn btn-primary">
            <button-icon-loader [defaultIcon]="['fas', 'paper-plane']" [isLoading]="bSendLoader"></button-icon-loader>
            <span class="pl-1">{{ 'send' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator" />
<div class="row">
  <div class="col-md-12" [formGroup]="chatForm">
    <div class="form-group mt-2">
      <label class="mr-2">{{ 'customer_chat' | translate }}</label>
      <mat-slide-toggle color="primary" formControlName="is_internal" class="sms-font">
      </mat-slide-toggle>
      <label class="ml-2">{{ 'internal_chat' | translate }}</label>
    </div>
    <div *ngIf="!bIsInternalChat" class="form-group mt-2">
      <small class="text-secondary float-left">
        {{ 'note_sms_billing1' | translate }}
        <a href="/#/admin?sms_balance=open">{{ 'here' | translate }}</a>
        {{ 'note_sms_billing2' | translate }}
      </small>
      <br>
    </div>
    <ng-container *ngIf="!bIsInternalChat">
      <div class="form-group mt-2">
        <label>{{ 'to' | translate }}</label>
        <ng-select (open)="objContactsRelate.typehead.next('')" [items]="objContactsRelate.source | async"
          [loading]="objContactsRelate.loader" class="sms-font" [typeahead]="objContactsRelate.typehead" clearable="true"
          bindLabel="text" bindValue="" formControlName="to">
        </ng-select>
      </div>
      <div *ngIf="arContactPhones.length != 0" class="form-group">
        <div class="font-size-12">
          <mat-radio-group formControlName="number" class="row">
            <mat-radio-button *ngFor="let phone of arContactPhones" [value]="phone" [checked]="arContactPhones.length === 1"  class="col-12">
              {{ phone.code }} {{ phone.number | phone }}
              (<fa-icon [icon]="['far', phone.getPhoneIcon]" class="icon-style"></fa-icon> {{ phone.type | translate }})
            </mat-radio-button><br>
          </mat-radio-group>
        </div>
      </div>
      <div class="form-group">
        <label>{{ 'message' | translate }}</label>
        <small *ngIf="objAttachment" class="float-right">
          {{ 'attachment' | translate }}: <a [href]="objAttachment.url" target="_blank"
            rel="noopener noreferrer">{{ objAttachment.file_name }}</a>
        </small>
        <div mwlTextInputHighlightContainer>
          <textarea
            mwlTextInputElement
            #textarea
            rows="7"
            [mention]="mentionsService.objUsersRelate.source | async"
            [mentionConfig]="mentionsService.objMentionSettings"
            (searchTerm)="mentionsService.searchUsers($event, strMessageValue)"
            (itemSelected)="mentionsService.addMention($event)"
            [mentionListTemplate]="mentionsTemplate"
            class="form-control txt-font"
            formControlName="message_sms"
            [class.is-invalid]="bIsMessageValid">
          </textarea>
          <mwl-text-input-highlight
            [tags]="mentionsService.arMentions"
            [tagCssClass]="'chat-mention'"
            [textInputElement]="textarea">
          </mwl-text-input-highlight>
        </div>
        <small class="text-secondary float-left">{{ 'initial_chat_tip' | translate }}</small>
      </div>
    </ng-container>
    <ng-container *ngIf="bIsInternalChat">
      <div class="form-group">
        <label>{{ 'message' | translate }}</label>
        <small *ngIf="objAttachment" class="float-right">
          {{ 'attachment' | translate }}: <a [href]="objAttachment.url" target="_blank"
            rel="noopener noreferrer">{{ objAttachment.file_name }}</a>
        </small>
        <div mwlTextInputHighlightContainer>
          <textarea
            mwlTextInputElement
            #textarea
            rows="7"
            [mention]="mentionsService.objUsersRelate.source | async"
            [mentionConfig]="mentionsService.objMentionSettings"
            (searchTerm)="mentionsService.searchUsers($event, strMessageValue)"
            (itemSelected)="mentionsService.addMention($event)"
            [mentionListTemplate]="mentionsTemplate"
            class="form-control txt-font"
            formControlName="message_chat">
          </textarea>
          <mwl-text-input-highlight
            [tags]="mentionsService.arMentions"
            [tagCssClass]="'chat-mention'"
            [textInputElement]="textarea">
          </mwl-text-input-highlight>
        </div>
        <small class="text-secondary float-left">{{ 'initial_chat_tip' | translate }}</small>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #mentionsTemplate let-item="item">
  <span>{{item.name}}</span>
</ng-template>


