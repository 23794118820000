// Core
import { ActivatedRoute, ActivatedRouteSnapshot, Navigation, NavigationExtras, Params, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
// Third Party
import * as _ from 'lodash';
import { LocalStorageService } from '../../../../services/local-storage.service';

@Component({
  selector: 'back-to-list',
  template: `
    <a id="navigateBackToList" [routerLink]="link" [queryParams]="queryParams" [ngClass]="['btn',cstmClass]">
      <fa-icon *ngIf="icon" [icon]="['fas', icon]" class="mr-1"></fa-icon> {{ label | translate }}
    </a>
  `,
  styleUrls: []
})
export class BackToListComponent implements OnInit {

  @Input() cstmClass: string;
  @Input() icon: string;
  @Input() label: string;
  @Input() link: string;
  @Input() module: string;

  queryParams: Params;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    // Get current page from local storage
    const arListingStorage = (this.localStorageService.getItem('listing')) ? JSON.parse(this.localStorageService.getItem('listing')) : {};
    if (arListingStorage[this.module] && arListingStorage[this.module]['page'] !== undefined) {
      const pageNum = arListingStorage[this.module]['page']['pageNum'];
      this.queryParams = {
        page: pageNum
      }
    }
  }
}
