import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Common } from '../../../../objects/common';
import { FormControl } from '@angular/forms';
import { RecordService } from '../../../../services/record.service';
import { Subject, concat } from '../../../../../../node_modules/rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from '../../../../../../node_modules/rxjs/operators';
import { of } from '../../../../../../node_modules/rxjs/observable/of';
import { ModuleRelate } from '../../../../lists/module-relate';
import { NotificationService } from '../../../../services/notification.service';
import { LocalStorageService } from '../../../../services/local-storage.service';

@Component({
  selector: 'app-filterdialog',
  templateUrl: './filterdialog.component.html',
  styleUrls: ['./filterdialog.component.scss']
})
export class FilterdialogComponent implements OnInit {

  public arFilter: any = {};
  public arLabels: any = {
    'default_filter': 'def_fil'
  };
  public arLabelsDisplay: any;
  public arCurrentLabel: any;
  public arFilterCommon: Common[] = [];
  public strFilterName = new FormControl();

  public arFilterList: any = [];
  public arFilterOption: any = [];

  public arFilterModel: any = [];

  public arRelateModel: any = [];
  public arRelateOption: any = [];
  public arRelateLoading: any = [];
  public arRelateValuesInput: any = [];
  public arRelateText: any = [];
  public strRelateModule: any = ModuleRelate;

  public bUpdate: boolean = false;

  public arSelectedFilter: any;

  public strSelectedFilterName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilterdialogComponent>,
    public recordService: RecordService,
    public notificationService: NotificationService,
    private localStorageService: LocalStorageService
  ) {
    // Store model
    this.arFilterModel = data['arFilterModel'];
    // Check if there is selected Filter
    this.arSelectedFilter = (data['arSelectedFilter']['id']) ? data['arSelectedFilter']['config'] : '';
    this.strSelectedFilterName = (data['arSelectedFilter']['id']) ? data['arSelectedFilter']['name'] : '';
    this.bUpdate = (data['arSelectedFilter']['id']) ? true : false;

    this.arRelateOption = this.localStorageService.getJsonItem('listing_relates') || {};

    // Modify list, remove anything that have no options.
    let arListTemp = data['arFilterList'].filter(tempList => {
      // If no options, dont include in the filters.
      if (tempList['id'] == tempList['option'] && tempList['option'] != '' && data['arFilterOption'][tempList['id']] == undefined) {
        return false;
      }
      return true;
    });

    // Get all dropdown values
    Object.keys(arListTemp).forEach( fieldItem => {
      // Store field id
      let strFieldId = arListTemp[fieldItem]['id'];
      // Check if type is dropdown
      if (arListTemp[fieldItem]['option'] != '') {
        // Set dropdown values
        arListTemp[fieldItem]['options'] = data['arFilterOption'][arListTemp[fieldItem]['option']];
      }
      // Check if type is relate
      if (arListTemp[fieldItem]['relate']) {
        // Create a subject in variable
        this.arRelateValuesInput[strFieldId] = new Subject<string>();
        // We need to exclude additonal filter of this modules when fetching relate record
        let arModulesExcludedAdditionalFilter: Array<string> = ['purchase_order_id', 'asset_type_id'];
        let bIncludeAdditionalData = true;

        if (arModulesExcludedAdditionalFilter.includes(strFieldId)) {
          bIncludeAdditionalData = false;
        }

        this.arRelateModel[strFieldId] = this.arRelateValuesInput[strFieldId].pipe(
          distinctUntilChanged(),
          tap(() => this.arRelateLoading[strFieldId] = true),
          // We search the inserted text in the ng-select, this time by name.
          switchMap(term => this.recordService.getRecordRelate(arListTemp[fieldItem]['relate'], term, '', false, false, 10, bIncludeAdditionalData).pipe(
              catchError(() => of([])), // Empty the list of dropdown on error.
              tap((data) => {
                this.arRelateOption[strFieldId] = data;
                // Removed the loading icon in relate.
                this.arRelateLoading[strFieldId] = false;
              })
          ))
        )
      }

      // If there is a selected filter alter the model values
      this.arFilterModel[strFieldId] = (this.arSelectedFilter) ? this.arSelectedFilter[strFieldId] : this.arFilterModel[strFieldId];
    });

    if (data['arSelectedFilter']['is_shared']) {
      this.arFilterModel['share'] = true;
    }

    // Set data
    this.arFilterList = arListTemp;
    this.arFilterOption = data['arFilterOption'];
  }

  ngOnInit() {
  }

  public deleteFilter() {
    let objFilter = this.data['arSelectedFilter'];
    this.notificationService.sendConfirmation("filter_delete_confirm").subscribe(confirmation => {
      if (confirmation.answer) {
        this.recordService.deleteFilter(objFilter['module'], objFilter['id']).subscribe(response => {
          let strResponse: any = response['body'];
          this.notificationService.notifySuccess(strResponse);
          this.dialogRef.close('refresh_delete');
        });
      }
    });
  }

  public dialogClose(){
    this.dialogRef.close();
  }

  /**
   * When the ng-select dropdown is clicked/opened, we fake a user input
   * a user input with no characters.
   * @param typehead - the ng-select typehead observable.
   */
  public triggerSubject(typehead: Subject<string>) {
    // We trigger the typehead to execute a search.
    typehead.next("");
  }

  public saveFilter(strType) {
    
    let strCount = 0;

    if (Object.keys(this.arRelateOption).length > 0) {
      // Get text of every relate field id
      Object.keys(this.arRelateOption).forEach( (relateId, index) => {
        // Check if relate has value
        if (this.arFilterModel[relateId] != '') {
          let arRelatedSelected = [];
          if (arRelatedSelected = this.arRelateOption[relateId].find(x => x.id == this.arFilterModel[relateId])) {
            this.arRelateText[relateId] = arRelatedSelected['text'];
            strCount++;
            if (Object.keys(this.arRelateOption).length == strCount) {
              this.closeDialogRef(strType);
            }
          } else {
            let strModule = this.strRelateModule[relateId];
            let strId = strModule + '.id';
            let arFilter: any = { [strId]: this.arFilterModel[relateId] };
            this.recordService.getRecordRelate(strModule, false, false, false, arFilter).subscribe( res => {
              this.arRelateText[relateId] = (res[0] != undefined) ? res[0]['text'] : '';
              strCount++;
              if (Object.keys(this.arRelateOption).length == strCount) {
                this.closeDialogRef(strType);
              }
            });
          }
        } else {
          strCount++;
          if (Object.keys(this.arRelateOption).length == strCount) {
            this.closeDialogRef(strType);
          }
        }
      });
    } else {
      this.closeDialogRef(strType);
    }
  }

  closeDialogRef(strType) {
    // Before we close the dialog. We need to
    // set the null value to empty string as
    // we don't search the field as null.
    Object.keys(this.arFilterModel).forEach( field => {
      if (this.arFilterModel[field] === null) {
        this.arFilterModel[field] = '';
      }
    });

    // Return
    this.dialogRef.close({
      'filter': this.arFilterModel,
      'type': strType,
      'relate': this.arRelateText
    });
  }
}
