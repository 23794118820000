import { Component, HostListener, Input } from '@angular/core';
import { GeneratePDFComponent } from '../../generate-pdf/generate-pdf.component';

@Component({
  selector: 'form-templates-preview-default-template-btn',
  templateUrl: './preview-default-template.component.html',
  styleUrls: [
    './preview-default-template.component.scss'
  ],
})
export class PreviewDefaultTemplateComponent extends GeneratePDFComponent {
  /**
   * Flag to determine if the component should display the `Preview` text of this button
   *
   * @type {boolean}
   */
  @Input('hide-text') hideText: boolean = false;

  /**
  * Determines if the button should rendered as a span
  *
  * @type {boolean}
  */
  @Input('as-span') asSpan: boolean = false;

  /**
  * Icon class definition
  *
  * @type {string|string[]|{[key: string]: any}}
  */
  @Input('icon-class') iconClass: string|string[]|{[key: string]: any};

  /**
  * Button class definition
  *
  * @type {string|string[]|{[key: string]: any}}
  */
  @Input('btn-class') btnClass: string|string[]|{[key: string]: any} = ['btn', 'btn-link'];

  /**
  * default displayed label
  *
  * @type {string}
  */
  @Input('label') label: string = 'preview';

  /**
  * default displayed icon
  *
  * @type {string}
  */
  @Input('icon') icon: string = 'file-pdf';

  /**
  * determine if the button is disabled
  *
  * @type {string}
  */
  @Input('is-disabled') isDisabled: boolean = false;

  /**
   * Handle on host click
   */
  @HostListener('click')
  onClick(): void {
    if (! this.isDisabled) {
      this.previewTemplate();
    }
  }
}