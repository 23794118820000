import { MatDialogConfig } from "@angular/material";

export class FormPopup implements MatDialogConfig {

  /**
   * @inheritdoc
   */
  disableClose: boolean;

  /**
   * @inheritdoc
   */
  width: string;

  /**
   * @inheritdoc
   */
  height: string;

  /**
   * @inheritdoc
   */
  maxHeight: string;

  /**
   * @inheritdoc
   */
  maxWidth: string;

  /**
   * Data could be any, but when using the centralized
   * form, these are the advisable value to be passed to the
   * data.
   */
  data: {

    /**
     * Which module the form is.
     * 
     * @var {string}
     */
    strModule: string;

    /**
     * Default valie of the form.
     * 
     * @var {any}
     */
    objDefault: any;
  
    /**
     * Content of the form.
     * 
     * @var {any}
     */
    arData: any;

    /**
     * Id if the record existing/edit, blank if it's
     * an add.
     * 
     * @var {string}
     */
    strId: string;

    /**
     * Add or edit.
     * 
     * @var {'add' | 'edit'}
     */
    strMode: 'add' | 'edit';

  } | any = {};


  constructor(strModule: string, objDefault: any = {}, arData: any = [], strId: string = '', strMode: 'add' | 'edit' = 'add', disableClose: boolean = true) {

    this.data.arData = arData;
    this.data.strModule = strModule;
    this.data.strId = strId;
    this.data.strMode = strMode;
    this.data.objDefault = objDefault;
    this.disableClose = disableClose;

    if (window.innerWidth <= 800 && window.innerHeight <= 1024) {
      this.width = '100%';
      this.height = '100%';
      this.maxHeight = '100vh';
      this.maxWidth = '100vw';
    } else {
      this.width = '80%';
      this.height = 'auto';
    }

  }
}