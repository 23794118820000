import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * checks if a given file extension is in the accepted extensions list
 *
 * @param {string[]} acceptedExtensions contains the list of accepted extension without the `.`
 */
export default function isValidFileExtension(acceptedExtensions: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    let file: File = control.value;

    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      const isAcceptedExtension = acceptedExtensions.findIndex((acceptedExtension) => acceptedExtension.toLowerCase() === extension) !== -1;

      if (! isAcceptedExtension) {
        return { invalidExtension: true };
      }
    }

    return null;
  };
}
