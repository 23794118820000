import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Select } from "../../../../../../objects/select";
import { ContactRolesService } from '../../../../../../services/contact-roles.service';

@Component({
  selector: 'app-role-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  @Input() arCustomerList: Array<object>;
  @Output() objAction: EventEmitter<{
    action: string,
    data: object
  }> = new EventEmitter();

  constructor(
    private translateService: TranslateService,
    private contactRolesService: ContactRolesService
  ) { }

  ngOnInit() {
  }

  /**
   * set's email dialog data
   *
   * @param email
   * @param contact_id
   */
  setEmailDialogData(email: object, record: object): void {
    this.emitAction('email', {
        email: email['email'],
        contact_id: record['contact_id']
    });
  }

  /**
   * emit objAction data
   */
  emitAction(action: string, record: object): void {
    this.objAction.emit({
        action: action,
        data: record
    })
  }

  /**
   * translates the selected roles
   * unshift the primary role so will be visible in the display
   *
   * @param arRoles
   */
  translateRole(arRoles: Array<Select>): string {
    return this.contactRolesService.transformRole(arRoles);
  }
}
