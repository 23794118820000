import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isString } from 'lodash';

// accepted pattern for given size if a string currently supports MB and KB
const pattern = /^(\d+)(KB|MB)/i;

const validator = (size: number | string): ValidatorFn => {
  // validate format if initialized with string
  // if (isString(size) && ! pattern.test(size as string)) throw new Error('Invalid size provided, Expecting a string with suffix from [KB, MB]');
  if (isString(size)) {
    // attempt to check if we have a matching format like 1KB
    const match = (size as string).match(pattern);

    if (! match) throw new Error('Invalid size provided, Expecting a string with suffix from [KB, MB]');

    const type = match[2];
    const captured = parseInt(match[1]);

    // for case of 'KB' what is captured by the group in the pattern is considered as KB
    switch(type.toLowerCase()) {
      case 'mb':
        size = captured * 1000; // 1 MB = 1000KB
        break;
      case 'kb':
      default:
        size = captured;
        break;
    }
  }

  // alter the size based on the type

  return (control: AbstractControl) => {
    let file: File = control.value;

    return (file && (file.size / 1024) >  size) ? {
      invalidFileSize: true,
    } : null;
  }
}

export default validator;