import { LooseObject } from './loose-object';

export class SelectTemplate {

  id?: string;
  module?: string;
  filter?: LooseObject;
  document_type?: string|string[];
  data?: LooseObject;

  constructor(id?: string, module?: string, filter?: LooseObject, document_type?: string|string[], data?: LooseObject){
    this.id = id;
    this.module = module;
    this.filter = filter;
    this.document_type = document_type;
    this.data = data;
  }

}