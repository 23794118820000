import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'save-field-mapping',
  templateUrl: './save-field-mapping.component.html',
  styleUrls: []
})
export class SaveFieldMappingComponent {

  /**
   * The name of the field map that the user wishes to save.
   *
   * @type {String}
   */
  fieldMappingName: string;

  constructor(protected dialogRef: MatDialogRef<SaveFieldMappingComponent, string>) {
  }

  /**
   * Closes the dialog box, and returns the field mapping name along with it.
   *
   * @returns {void}
   */
  save(): void {
    this.dialogRef.close(this.fieldMappingName);
  }

  /**
   * Closes the dialog box, cancelling any changes that the user has made.
   *
   * @returns {void}
   */
  cancel(): void {
    this.dialogRef.close();
  }
}
