<div class="d-flex h-100 flex-column">

  <div class="d-flex">

    <h3 class="title">
      <fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;
      <span>{{ 'change_payment_method' | translate }}</span>
    </h3>

    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="objDialogRef.close()" class="btn btn-danger">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>

  </div>

  <hr class="w-100 mt-0">

  <mat-dialog-content style="height: 100%">

    <ng-container *ngIf="bLoading">
      <div style="width: 100%; text-align: center;">
        <inline-view-loader [isLoading]="bLoading" [hasText]="false"></inline-view-loader>
      </div>
    </ng-container>

    <ng-container *ngIf="!bLoading">
      <iframe
        frameBorder="0"
        [src]="strUrl | safe"
        height="700px"
        width="400px">
      </iframe>
    </ng-container>

  </mat-dialog-content>
</div>