import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Pagination } from '../../../../shared/components/pagination/pagination';
import { ListingService } from '../../../../services/listing.service';
import { RecordService } from '../../../../services/record.service';
import { Material } from '../../../../objects/material';
import { Relate } from '../../../../objects/relate';
import { finalize, map, switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { Select } from '../../../../objects/select';
import { cloneDeep, isArray, isEmpty, toString } from 'lodash';
import { NotificationService } from '../../../../services/notification.service';
import { StockManagementService } from '../../../../services/stock-management.service';
import { ViewService } from '../../../../services/view.service';
import { MaterialsService } from '../../../../services/materials.service';
import { LooseObject } from '../../../../objects/loose-object';
import { UpdateStockAllocation } from '../update-stock-allocation/update-stock-allocation.component';
import { toFormattedNumber } from '../../../../shared/utils/numbers';

@Component({
  selector: 'stock-allocation',
  templateUrl: './stock-allocation.component.html',
  styleUrls: ['./stock-allocation.component.scss']
})
export class StockAllocationComponent implements OnInit {

  /**
   * List of allocation status.
   *
   * @var {string[]}
   */
  public arAllocationStatus: string[] = [
    'pending', 'allocated', 'used'
  ];

  /**
   * The default warehouse, taken from the
   * current user.
   *
   * @var {Select | null}
   */
  public objDefaultWarehouse: Select | null = null;

  /**
   * The pagination object containing
   * the list and other necessary properties
   * for paginating.
   *
   * @var {Pagination<StockAllocationItem>}
   */
  public objPagination: Pagination<StockAllocationItem> = new Pagination();

  /**
   * determine if we need to refresh main listing
   *
   * @val {boolean}
   */
  public bRefreshMainListing: boolean = false;

  /**
   * list of materials that we need to update
   */
  arMaterialsToUpdate: LooseObject = {};

  /**
   * list of material ids needs to pre-select
   */
  arPreSelectMaterials: string[] = [];

  /**
   * determine the if all the current record is selected
   */
  bSelectAll: boolean = false;

  /**
   * Checks if there are changes to the list.
   *
   * @return {boolean}
   */
  get bNoChange(): boolean {
    return this.objPagination.list.findIndex(objItem => (objItem.has_changes)) < 0;
  }

  get checkedMaterials() {
    return this.objPagination.list
      .filter(objItem => (objItem.is_checked))
      .map(objItem => {
        return {...objItem.material, warehouse_id: objItem.warehouse.value ? objItem.warehouse.value.id : ""};
      });
  }

  constructor(
    public objDialog: MatDialogRef<StockAllocationComponent>,
    @Inject(MAT_DIALOG_DATA) public objData: any,
    private record: RecordService,
    private list: ListingService,
    private local: LocalStorageService,
    private notifService: NotificationService,
    private stockManagementService: StockManagementService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.record.getRecord('users', this.local.getItem('user_id'))
      .pipe(
        map(objResponse => {
          return (objResponse['record_details']['warehouse_id']) ? new Select(
            objResponse['record_details']['warehouse_id'],
            objResponse['record_details']['warehouse_text']
          ) : null;
        })
      )
    .subscribe(objWarehouse => {
      this.objDefaultWarehouse = objWarehouse;
      this.arPreSelectMaterials = isArray(this.objData.material_ids) ? this.objData.material_ids : [];
      this.getPaginationData();
    });
  }

  /**
   * When the warehouse has changed, simply mark the form
   * as changed.
   *
   * @param {StockAllocationItem} objItem
   */
  onWarehouseChange(objItem: StockAllocationItem): void {
    objItem.has_changes = true;
  }

  /**
   * When a status has changed, either empty the warehouse
   * or put the default warehouse based from the user.
   *
   * @param {StockAllocationItem} objItem
   */
  onStatusChange(objItem: StockAllocationItem): void {
    objItem.has_changes = true;

    if (objItem.material.status == 'pending') {
      objItem.warehouse.value = null;
    } else if (!objItem.warehouse.value && this.objDefaultWarehouse != null) {

      objItem.warehouse.source.take(1).subscribe(arWarehouse => {
        let objExistingWarehouse = arWarehouse.find(objWarehouse => (objWarehouse.id == this.objDefaultWarehouse.id));
        if (objExistingWarehouse) {
          objItem.warehouse.value = objExistingWarehouse;
        }
      });

      objItem.warehouse.loadDataOnClick();

    }

  }

  /**
   * Simply sends the new materials in the API.
   *
   * @returns {void}
   */
  allocateStock(): void {
    let arMaterialsToSave = this.objPagination.list
      .filter(objItem => (objItem.has_changes))
      .map(objItem => {
        return {...objItem.material, warehouse_id: objItem.warehouse.value ? objItem.warehouse.value.id : ""};
      });

    const objItemsWithInvalidWarehouse = arMaterialsToSave.find( plan => isEmpty(plan.warehouse_id) && plan.status !== 'pending');

    if (isEmpty(objItemsWithInvalidWarehouse) && arMaterialsToSave) {
      this.stockManagementService.allocateStock(arMaterialsToSave).subscribe(() => {
        this.objDialog.close('save');
      }, err => {
        this.notifService.notifyError('negative_stock_error');
      });
    } else {
      this.notifService.notifyError('warehouse_invalid_in_allocation');
    }
  }

  /**
   * Get the default warehouse.
   *
   * @param {Material} objMaterial
   *
   * @returns {Select[] | null}
   */
  private getDefaultWarehouse(objMaterial: Material): Select[] | null {
    if (objMaterial.warehouse_id) {
      return [new Select(objMaterial.warehouse_id, objMaterial.warehouse_name, null, objMaterial)];
    } else {
      return null;
    }
  }

  /**
   * Set the API callback for the pagination
   * to manipulate.
   *
   * @returns {void}
   */
  private getPaginationData(): void {
    this.objPagination.page_request = (objPage: any = null) => {

      return this.list.fetchData(JSON.stringify(objPage), 'materials', JSON.stringify({
        type: 'product_catalog',
        job_id: this.objData.record_id,
        'items.is_inventoried': true
      })).map(objResponse => {
        this.bSelectAll = false;
        return {

          data: objResponse['data'].map((objMaterialResponse: any) => {

            let objMaterial = new Material(objMaterialResponse);

            return new StockAllocationItem(
              objMaterial.status,
              objMaterial,
              new Relate<any>().buildRelates(
                switchMap(strTerm => this.record.getRecordRelate('warehouses', strTerm, false)),
                this.getDefaultWarehouse(objMaterial),
                (objMaterial.warehouse_id ? true : false)
              ),
              objMaterial.quantity,
              this.arPreSelectMaterials.indexOf(objMaterial.id) > -1 && objMaterial.status != 'used'
            );

          }),
          next: objResponse['hasNextToken'],
          raw_data: objResponse['data']
        }
      });

    };

    this.objPagination.triggerPageObservable();
    this.objPagination.pageChanged.subscribe( () => this.updateSelectAllValue());
  }

  /**
   * cloase dialog
   */
  closeDialog(): void {
    this.objDialog.close(this.bRefreshMainListing ? 'reload' : null);
  }

  /**
   * format the quantity value
   */
  formatQuantity(objItem, event): void {
    objItem.has_changes = true;
    objItem.is_checked = true;

    var decimal_value = (event.target.value > 0) ? event.target.value : 1;
    objItem.material.quantity = toString(toFormattedNumber(decimal_value));
  }

  /**
   * to update the selected materials using one status and warehouse
   */
  saveMultiple(): void {
    this.dialog.open(UpdateStockAllocation, {
      maxWidth: '50%',
      width: '50%',
      height: 'auto',
      disableClose: false,
      data: {
        materials: this.objPagination.list.filter(objItem => (objItem.is_checked && objItem.initial_status != 'used')).map(objItem => {
          return {...objItem.material, warehouse_id: objItem.warehouse.value ? objItem.warehouse.value.id : ""};
        })
      }
    }).afterClosed().subscribe( strResponse => {
      if (strResponse === 'saved') {
        this.objDialog.close('save');
      }
    });
  }

  /**
   * on select per record
   *
   * @param event
   * @param material
   */
  onSelectMaterial(event, currentMaterial): void {
    let checked = (event.target.checked) ?  true : false
    if (!checked) {
      this.bSelectAll = false;
    } else {
      this.updateSelectAllValue(currentMaterial);
    }
  }

  /**
   * select all current data in listing
   *
   * @param event
   */
  onSelectAll(event): void {
    let checked = (event.target.checked) ?  true : false;
    this.bSelectAll = checked;
    this.objPagination.list
      .slice(this.objPagination.start_index, this.objPagination.end_index)
      .map( material => {
        if (material.initial_status != 'used') {
          material.is_checked = checked;
        }
      });
  }

  /**
   * check if the current pagination is selected
   *
   * @param currentMaterial
   */
  updateSelectAllValue(currentMaterial = {}): void {
    let currentMaterialId = (currentMaterial && currentMaterial['material']) ? currentMaterial['material']['id'] : '';
    // get all materials with non used status and not checked
    let isCheckedAll =  cloneDeep(this.objPagination.list)
        .slice(this.objPagination.start_index, this.objPagination.end_index)
        .filter( material =>
          material.initial_status != 'used'
          && !material.is_checked
          && material.material.id != currentMaterialId
        );

    this.bSelectAll = isEmpty(isCheckedAll);
  }
}

export class StockAllocationItem {

  initial_status: string;
  material: Material;
  warehouse: Relate<Select>;
  has_changes: boolean;
  is_checked: boolean;
  quantity: number;

  constructor(initial_status, material, warehouse, quantity, isChecked = false) {
    this.initial_status = initial_status;
    this.material = material;
    this.warehouse = warehouse;
    this.has_changes = false;
    this.is_checked = isChecked;
    this.quantity = quantity;
  }

}
