// angular libs
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// protractor
import { Config } from 'protractor';

// contracts
import { Thread, Mail } from './../contracts/thread-message';

// reactivity
import { Observable } from 'rxjs';

// environment variable
import { environment } from '../../environments/environment';

// moment
import * as _moment from 'moment';

// create moment instance
const moment = (_moment as any).default || _moment;

import { trimEnd } from 'lodash';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  /**
   * class constructor
   *
   * @param protected
   */
  constructor(
    protected http: HttpClient
  ) {}

  /**
   * get all the latest messages of the current thread
   *
   * @param {string}      threadID
   * @param {string|null} page
   *
   * @returns {Observable<Thread>}
   */
  getMessages$(threadID: string, page?: string|null): Observable<Thread> {
    return new Observable((observer) => {
      let payload = new URLSearchParams;

      // append page request
      payload.append('page', page || null);

      this.call(`threads/${threadID}/messages`, payload).subscribe((response) => {
          let body = response.body;
          let messages = [];
          let previousToken = null;
          let nextToken = null;

          if (response.status === 200) {
            messages = body.data || [];
            previousToken = body['previous_token'] || null;
            nextToken = body['next_token'] || null;
          }

          let thread: Thread = {
            messages: messages.map((message) => ({
              id: message['id'],
              from: message['from'],
              subject: message['subject'],
              createdAt: moment(message['created_at'] * 1000),
              attachmentsCount: message['attachments_count'] || 0,
              mailSizeText: this.convertSizeToText(message['mail_size'] || 0),
            })),
            previousToken: previousToken,
            nextToken: nextToken
          };

          observer.next(thread);
      });
    });
  }

  /**
   * render a message from the server
   *
   * @param  {string} messageID
   *
   * @returns {Observable<Mail>}
   */
  renderMessage$(messageID: string): Observable<Mail> {
    return new Observable((observer) => {
      this.call(`messages/${messageID}`, new URLSearchParams).subscribe((response) => {
        let body = response.body;

        if (response.status === 200) {
          observer.next({
            to: body['to'],
            from: body['from'],
            cc: body['cc'],
            bcc: body['bcc'],
            subject: body['subject'],
            content: body['content'],
            email_infected_tag: body['email_infected_tag'],
            attachments: body['attachments'].map((attachment) => ({
              filename: attachment['filename'],
              contentType: attachment['content_type'],
              content: attachment['content'],
              contentDisposition: attachment['content_disposition'],
              contentId: attachment['content_id'],
            }))
          });
        } else {
          observer.next(null);
        }

        observer.complete();
      });
    });
  }

  /**
   * Call the api that will send the verification to user email address
   * to allow sharing for bigquery
   *
   * @param email - this will be the account to share data in bigquery
   * @param is_subscribe - key to identify if its for subscribe or unsubscribe
   */
  sendDatawarehouseAuthorization(email: string, is_subscribe: boolean): Observable<HttpResponse<Config>> {
    let body = new URLSearchParams();
    body.append('email_address', email);
    body.append('is_subscribe', (is_subscribe) ? '1' : '0');

    return this.http.post<Response>(environment.url + '/integrations/datawarehouse/send_verification', body.toString(), { observe: 'response' });
  }

  /**
   * call the api endpoint and return an observable response
   *
   * @param   {string}          uri
   * @param   {UrlSearchParams} body
   *
   * @returns {Observable<HttpResponse<Config>>}
   */
  protected call(uri: string, body: URLSearchParams): Observable<HttpResponse<Config>> {
    return this.http.post(environment.url + `/mails/${uri}`, body.toString(), { observe: 'response' });
  }

  /**
   * converts a size expressed in bytes in a human readable representation
   *
   * @param   {number} bytes
   *
   * @retursn {string}
   */
  protected convertSizeToText(bytes: number): string {
    let bytesInKB = bytes / 1000;

    // Megabytes
    if (bytesInKB > 1000) {
      let bytesInMB = bytesInKB / 1000;

      // Gigabytes
      if (bytesInMB > 1000) {
        return `${(bytesInMB / 1000).toFixed(2)}GB`;
      }

      return `${bytesInMB.toFixed(2)}MB`;
    }

    return `${bytesInKB.toFixed(2)}KB`;
  }

  /**
   * Creates an email template
   *
   * @param {EmailTemplateData} data
   *
   * @returns {Observable<EmailTemplate>}
   */
  createEmailTemplate$(data: EmailTemplateData): Observable<EmailTemplate> {
    const payload = new URLSearchParams;

    payload.append('data', JSON.stringify(data));

    return this.tapEmailTemplateResponse(
      this.http.post<EmailTemplate>(`${trimEnd(environment.url, '/')}/create_email_template`, payload.toString(), {
        observe: 'response'
      })
    );
  }

  /**
   * Updates an email template
   *
   * @param   {string} id
   * @param   {EmailTemplateData} data
   *
   * @returns {Observable<EmailTemplate>}
   */
  updateEmailTemplate$(id: string, data: EmailTemplateData): Observable<EmailTemplate> {
    const payload = new URLSearchParams;

    payload.append('data', JSON.stringify({
      ...data,
      id,
    }));

    return this.tapEmailTemplateResponse(
      this.http.post<EmailTemplate>(`${trimEnd(environment.url, '/')}/update_email_template`, payload.toString(), {
        observe: 'response'
      })
    );
  }

  /**
   * Taps the response from the email template action request
   *
   * @param   {Observable<HttpResponse<EmailTemplate>>} response$
   *
   * @returns {Observable<EmailTemplate>}
   */
  protected tapEmailTemplateResponse(response$: Observable<HttpResponse<EmailTemplate>>): Observable<EmailTemplate> {
    return response$.pipe(
      map((response) => response.body)
    );
  }
}

interface EmailTemplate {
  id: string;
  name: string;
  subject?: string;
  body?: string;
  module?: string;
  attachments?: EmailAttachment[];
}

interface EmailTemplateData {
  name: string;
  subject?: string;
  body?: string;
  module?: string;
  attachments?: EmailAttachment[];
}

export interface EmailAttachment {
  name: string;
  size: number;
  upload_name: string;
  new?: boolean;
}