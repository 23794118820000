<button
  type="button"
  (click)="doShare()"
  class="btn btn-link pb-0 pt-0"
  [disabled]="isInProgress$ | async"
>
  <button-icon-loader
    [defaultIcon]="['fas', 'share']"
    [isLoading]="isInProgress$ | async"
  ></button-icon-loader>
</button>