export enum RoleLabelList {
  customers = 'customer_roles',
  sites = 'site_roles',
  contacts = 'contact_role',
  opportunities = 'opportunity_roles',
  jobs = 'contact',
}

export enum RoleEditHeaderLabel {
  customers = 'manage_customer_roles',
  sites = 'manage_site_roles',
  contacts = 'contact_role',
  opportunities = 'manage_opportunity_roles',
  jobs = 'contacts',
}

export enum NoRecordFound {
  customers = 'no_customer_roles',
  sites = 'no_site_roles',
  contacts = 'no_contact_roles',
  opportunities = 'no_opportunity_roles',
  jobs = 'no_contact_roles',
}

export enum RoleTabTitle {
  customers = 'customer_roles',
  sites = 'site_roles',
  contacts = 'contact_roles',
  opportunities = 'opportunity_roles',
  jobs = 'contacts',
}

export enum RoleModuleOptionId {
  customers = 'customer_roles',
  sites = 'site_roles',
  contacts = 'contact_roles',
  opportunities = 'opportunity_roles',
}

export enum ModuleRelateId {
  customers = 'customer_id',
  sites = 'site_id',
  opportunities = 'opportunity_id',
}