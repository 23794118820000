<app-error-handler onlyFor="opportunities:list" alignment="center" displayAs="widget">
<div class="d-flex justify-content-around contacts-option tabs-content">
    <ng-container *fcHasPermission="'opportunities:create'; else createOpportunityDisabled">
        <span id="widgetOpportunitiesCreateButton" (click)="(bPageLoaded) ? openCreateQuoteWizard() : ''" class="span-links">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-success mr-1"></fa-icon>
            {{ 'create_opportunity' | translate }}
        </span>
    </ng-container>
    <ng-template #createOpportunityDisabled>
        <span class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-default mr-1"></fa-icon>
            {{ 'create_opportunity' | translate }}
        </span>
    </ng-template>
</div>
<div class="contact-roles-body">
    <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pb-4">
              <div class="form-inline">
                  <div class="form-group flex-nowrap w-100">
                      <div class="col-lg-4">
                          <label class="jc-l">{{ 'filter_by_status' | translate }}</label>
                      </div>
                      <div class="col-lg-8">
                          <ng-select
                              [items]="arStatusFilter"
                              [clearable]="true"
                              class="value-text"
                              (change)="filter()"
                              [(ngModel)]="strSelectStatus"
                              id="widgetOpportunitiesStatusFilter">
                              <ng-template ng-label-tmp let-item="item">{{ item | translate }}</ng-template>
                              <ng-template ng-option-tmp let-item="item">
                                <span id="widgetOpportunitiesStatusFilter{{ item }}">{{ item | translate }}</span>
                              </ng-template>
                          </ng-select>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <div class="row border-top pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'list-alt']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'opportunities' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section" class="float-right">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetOpportunitiesRefreshButton" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
                        <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetOpportunitiesPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev', (strSelectStatus != null && strSelectStatus != '') ? { status : strSelectStatus} : {})">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetOpportunitiesNextButton" class="page-link" aria-label="first" (click)="fetchList('next', (strSelectStatus != null && strSelectStatus != '') ? { status : strSelectStatus} : {})">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div *ngIf="arOpportunities.length < 1">
            <div class="row border-top" *ngIf="!bLoading">
                <span class="no-content">
                    {{ 'no_opportunities' | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="bLoading">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </div>

        <div class="row border-top pt-2 pb-2 pr-2" *ngFor="let opportunity of arOpportunities" [id]="'widgetOpportunitiesRecord' + opportunity.id">
            <div class="col-sm-1" matTooltip="{{ opportunity.status | translate }}">
                <fa-icon class="icon-size float-left {{objColors['opportunities'][opportunity.status]}}" [icon]="['fas', 'circle']"></fa-icon>
            </div>
            <div class="col-sm-6">
                <div class="text-name">
                    <a id="widgetOpportunitiesLabel" class="pointer" href="/#/opportunities/{{opportunity.id}}">
                        <span>#{{ opportunity.opportunity_number }}</span>
                    </a>
                </div>
                <div class="text-activity-content truncate">
                  <span matTooltip="{{ (opportunity.summary != '') ? opportunity.summary : '--' }}">
                      {{ (opportunity.summary != '') ? opportunity.summary : '--' }}
                  </span>
                </div>
            </div>
            <div class="col-sm-4 overflow-hidden">
                <div class="row">
                    <div class="col-12">
                        <span class="float-right text-nowrap" matTooltip="{{ 'forecast_close_date' | translate }}: {{ formatDate(opportunity.forecast_close_date) | placeholdWithString }}">
                            <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                            {{ formatDate(opportunity.forecast_close_date) | placeholdWithString }}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <span class="float-right text-nowrap" matTooltip="{{ 'amount' | translate }}: {{ opportunity.amount | currency }}">
                          {{ opportunity.amount | currency: {decimal_places: 2} }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 d-table text-role less-padding">
                <div class="row">
                    <div class="col-12">
                        <ng-container *fcHasPermission="'opportunities:edit'; else disabledEditButton">
                            <fa-icon id="widgetOpportunitiesEditButton" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="recordDialog(opportunity.id)"></fa-icon>
                        </ng-container>
                        <ng-template #disabledEditButton>
                            <fa-icon id="widgetOpportunitiesEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                        </ng-template>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <ng-container *fcHasPermission="'opportunities:delete'; else disabledDeleteButton">
                            <fa-icon id="widgetOpportunitiesDeleteButton" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteOpportunity(opportunity.id)"></fa-icon>
                        </ng-container>
                        <ng-template #disabledDeleteButton>
                            <fa-icon id="widgetOpportunitiesDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</app-error-handler>
