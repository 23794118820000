
import { Pipe, PipeTransform } from '@angular/core';
import { spf } from '../../shared/utils/str';
import { get } from 'lodash';

@Pipe({ name: 'itemName' })
export class ItemNamePipe implements PipeTransform {
  /// display the supplier's name if the item is searched by supplier code
  transform(data: any, opts: {
    using?: string;
  } = {}): string {
    opts = Object.assign({
      using: 'name',
    }, opts);

    if (typeof data === 'string') {
      return data;
    }

    if (data.supplier_code) {

      let objSupplierPricing = data.supplier_pricing
        .find(sCode => sCode.code == data.supplier_code);

        data.unit_cost = objSupplierPricing.unit_cost

      return spf('%s (%s)', {
        args: [
          get(data, opts.using),
          get(objSupplierPricing, 'customer_text'),
        ],
      });
    }

    return get(data, opts.using);
  }
}
