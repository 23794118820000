<div class="form-group col-xs-12 col-md-12">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" #metadataForm="ngForm">
    <div class="form-group" *ngFor="let field of fields">
      <label [for]="field.id">
        {{ field.label | translate
        }}<span class="text text-danger ml-1" *ngIf="field.required">*</span>
      </label>
      <ng-container [ngSwitch]="field.type">
        <!-- type: dropdown -->
        <ng-container *ngIf="field.options$ | async as obs">
          <ng-select
            *ngSwitchCase="'dropdown'"
            [items]="obs.options"
            [class.is-invalid]="(errors[field.id] || []).length > 0"
            [formControlName]="field.id"
            [clearable]="field.required ? false : true"
            [id]="field.id"
            [loading]="obs.loading"
            bindValue="id"
            bindLabel="text"
          >
            <ng-template ng-option-tmp let-item="item">{{
              item.text | translate
            }}</ng-template>
            <ng-template ng-label-tmp let-item="item">{{
              item.text | translate
            }}</ng-template>
          </ng-select>
        </ng-container>

        <!-- type: 'text' or default -->
        <input
          *ngSwitchDefault
          [type]="field.type"
          class="form-control"
          [class.is-invalid]="(errors[field.id] || []).length > 0"
          [formControlName]="field.id"
          [id]="field.id"
          [required]="field.required"
        />
      </ng-container>
      <small
        *ngIf="field.help"
        [id]="field.id + '-help'"
        class="form-text text-muted"
        innerHTML="{{ field.help | translate }}"
      ></small>
      <div
        class="invalid-feedback"
        *ngFor="let error of errors[field.id] || []"
      >
        {{ error }}
      </div>
    </div>
  </form>
</div>

<div class="form-group col-xs-12 col-md-12">
  <div class="d-flex justify-content-end d-flex-gap mt-3">
    <button
      id="submit-btn"
      class="btn btn-primary text-white float-right"
      [disabled]="!form.valid || isInProgress"
      (click)="metadataForm.ngSubmit.emit()"
    >
      <fa-icon
        *ngIf="isInProgress"
        [icon]="['fas', 'spinner']"
        [spin]="true"
        class="custom-size text-secondary mr-1"
      ></fa-icon>
      {{ "save" | translate }}
    </button>
    <button
      *ngIf="canBeSkipped"
      class="btn btn-danger"
      id="btn-skip-form"
      (click)="onSkip()"
      [disabled]="isInProgress"
    >
      {{ "skip" | translate }}
    </button>
  </div>
</div>
