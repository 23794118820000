import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewComponent } from './view/view.component';
import { StockLevelComponent } from './list/stock-level.component';
import { ReorderListComponent } from './actions/reorder/list/reorder-list.component';
import { StocktakeListComponent } from './actions/stocktake/list/stocktake-list.component';
import { StocktakeHistoryComponent } from './actions/stocktake/history/stocktake-history.component';
import { ViewComponent as StocktakeViewComponent } from './actions/stocktake/view/view.component';

const routes: Routes = [
  { path: '', redirectTo: 'stock_levels/', pathMatch: 'full' },
  { path: '', children: [
    { path: '', component: StockLevelComponent },
    { path: 'reorder', component: ReorderListComponent },
    { path: 'stocktake', component: StocktakeListComponent },
    { path: ':id', component: ViewComponent },
    { path: 'stocktake/history', component: StocktakeHistoryComponent },
    { path: 'stocktake/history/:id', component: StocktakeViewComponent },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class StockLevelRoutingModule { }