import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize, scan, switchMap, tap } from 'rxjs/operators';
import { AccountingDebugLogContent, AccountingSystemService } from '../../../../services/accounting_system.service';
import {
  concat as lodashConcat
} from 'lodash';

@Component({
  selector: 'accounting-sync-request-log-dialog',
  templateUrl: './request-log-dialog.component.html',
  styleUrls: [
    './request-log-dialog.component.scss',
  ],
})
export class AccountingSyncRequestLogComponent implements OnInit, OnDestroy {
  /**
   * @type {BehaviorSubject<boolean>}
   * @readonly
   */
  readonly loading$ = new BehaviorSubject(true);

  /**
   * @type {BehaviorSubject<AccountingDebugLogContent[]>}
   * @readonly
   */
  readonly contentsSub = new BehaviorSubject<AccountingDebugLogContent[]>([]);

  /**
   * @type {Observable<AccountingDebugLogContent[]>}
   */
  readonly contents$ = this.contentsSub.pipe(
    scan((arOldLogs, arNewLogs) => lodashConcat(arOldLogs, arNewLogs))
  );

  /**
   * @type {stirng}
   */
  nextToken: string;

  /**
   * @type {boolean}
   */
  wasInitialized: boolean = false;

  /**
   * @type {BehaviorSubject<string>}
   * @readonly
   */
  protected readonly loadNext$ = new BehaviorSubject<string>(undefined);

  /**
   * @type {Subscription[]}
   */
  protected readonly subscriptions: Subscription[] = [];

  /**
   * Create instance of the dialog
     *
   * @param {AccountingSyncRequestLogComponentProps} props
   * @param {MatDialogRef} dialogRef
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly props: AccountingSyncRequestLogComponentProps,
    protected readonly dialogRef: MatDialogRef<AccountingSyncRequestLogComponent>,
    protected readonly accounting: AccountingSystemService
  ) {}

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.loadNext$.pipe(
        tap(() => this.loading$.next(true)),
        switchMap(
          (strNextToken) => this.accounting.getRequestLogs$(this.props.logId, strNextToken).pipe(
            finalize(() => this.loading$.next(false)),
          )
        ),
        tap((objResult) => this.nextToken = objResult.nextToken),
        tap((objResult) => this.contentsSub.next(objResult.contents)),
        tap(() => {
          // if the current component was in the midst of initializing and
          // we already received the response from the api call
          // we then flagged that the component was already initialized
          if (! this.wasInitialized) {
            this.wasInitialized = true;
          }
        })
      ).subscribe()
    );
  }

  /**
   * @inheritdoc
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((objSubscription) => objSubscription.unsubscribe());
  }

  /**
   * Handle user interaction when user wants to load next logs
   *
   * @retu
   */
  onNext(): void {
    this.loadNext$.next(this.nextToken);
  }

  /**
   * Handles on user cancel action
   *
   * @returns {void}
   */
  doCancel(): void {
    this.dialogRef.close();
  }
}

export interface AccountingSyncRequestLogComponentProps {
  /**
   * Request log identity
   *
   * @type {string}
   */
  logId: string;
}