import { Subject } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { Select } from '../../../../objects/select';
import { ModuleLogo } from '../../../../lists/module-logo';
import { RecordService } from '../../../../services/record.service';
import { ListingService } from '../../../../services/listing.service';
import { ClientStoreService } from '../../../../services/client-store.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { SearchService } from '../../../../services/search.service';

@Component({
  selector: 'app-convertdialog',
  templateUrl: './convertdialog.component.html',
  styleUrls: ['./convertdialog.component.scss'],
  providers: [ListingService]
})
export class ConvertdialogComponent implements OnInit {

  public bDepartmentTracking: boolean = false;
  public bShowLoader: any = [];
  public strModule: string;
  public strRecordTitle: string;
  public arRecord: any = [];
  public arModel: any = {};
  public arModuleRecords: [] | {} = [];
  public arConvertList = {};
  public arConvertOptional = {
    'leads': ['opportunities', 'jobs']
  }
  public arRelateFields: any = {};
  public bRelateLoading: boolean = false;
  public bLoaded: boolean = false;
  public bButtonSubmit: boolean = false;
  public bIsGlobalSearch: boolean = true;
  public bShowAdditionalDataSection: boolean = false;
  public strModuleToBeConverted: string = '';
  public isCustomerLink: boolean = true;
  public openSelectionObservables: {} = {
    'department_id' : {
      module: 'departments',
      obv: new Observable<Select[]>(),
      typehead: new Subject<string>(),
      loader: false
    }
  };

  public objAdditionalData = {
    'summary' : new FormControl('', Validators.required),
    'department_id' : new FormControl('', Validators.required)
  };

  public selectionData: {} = {
    department_id: null
  }

  // Observable select field
  public arNgSelectFields =
  { customers : {
      obv: new Observable<Select[]>(),
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'customer',
      name: 'customer',
      value: null
    },
    sites : {
      obv: new Observable<Select[]>(),
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'site',
      name: 'site',
      value: null
    },
    contacts : {
      obv: new Observable<Select[]>(),
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'contact',
      name: 'contact',
      value: null
    }
  };

  constructor(
    public clientStoreService: ClientStoreService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogConvert: MatDialogRef<ConvertdialogComponent>,
    public dialog: MatDialog,
    public recordService: RecordService,
    public listingService: ListingService,
    public globalSearch: SearchService,
    public router: Router,
    private notifService: NotificationService,
    protected localStorageService: LocalStorageService
  ) {
    this.bDepartmentTracking = this.clientStoreService.isDepartmentTracking();
    // Store all passed value
    this.strModule = data['module'];
    this.arRecord = data['recordDetails'];
    this.strRecordTitle = data['recordDetails']['first_name']+ ' ' +data['recordDetails']['last_name'];
    this.arConvertList = {
      'leads': [
        {
          'label': 'customer',
          'icon': ModuleLogo['customers'],
          'module': 'customers',
          'display': true,
          'filter_id': 'name',
          'filter_key': (this.arRecord['company'] != '') ? ['company'] : ['first_name', 'last_name']
        },
        {
          'label': 'site',
          'icon': ModuleLogo['sites'],
          'module': 'sites',
          'display': true,
          'filter_id': ['address'],
          'filter_key': 'address_text'
        },
        {
          'label': 'contact',
          'icon': ModuleLogo['contacts'],
          'module': 'contacts',
          'display': true,
          'filter_id': ['first_name', 'last_name'],
          'filter_key': ['first_name', 'last_name']
        }
      ]
    }

    this.initRelateField();
    // Store client config
    var arClientConfig = this.clientStoreService.getActiveClient().config;
    // If Customer-based Job only, Remove sites from in display
    Object.keys(this.arConvertList[this.strModule]).forEach( element => {
      // Do we have Customer-based Job? Alter display value to false
      if (!arClientConfig['sites'] && this.arConvertList[this.strModule][element]['module'] == 'sites')
        this.arConvertList[this.strModule][element]['display'] = false;
    });
    this.generateModel();
    // Create loading array using optional convert
    this.arConvertOptional[this.strModule].forEach( element => {
      this.bShowLoader[element] = false;
    });
  }

  ngOnInit() {
  }

  /**
   * Generate model
   */
  generateModel() {
    let arModules = [];
    let arFilters = {
      'sites' : {},
      'customers': {},
      'contacts': {}
    };
    let arIsCustomFilter = {};

    this.arConvertList[this.strModule].forEach( element => {
      if (element['display']) {
        let strModule: string;
        let arDefaultConfig = {
          'id': '',
          'type': '',
          'data': '',
          'label': '',
          'disable': true
        };
        if (strModule = element['module']) {

          let strFieldId = element['label']+ '_id';
          this.arModel[strModule] = arDefaultConfig;
          this.arModel[strModule]['id'] = strFieldId;
          this.arModel[strModule]['label'] = this.formatRecord(element['filter_key'], this.arRecord);
          this.arModel[strModule]['expand'] = false;
          // this.getModuleRecord(strModule, element['filter_id'], element['filter_key']);
          let strId = this.formatId(element['filter_id'], false);
          let strValue = (strModule == 'sites') ? this.arRecord['address']: this.formatRecord(element['filter_key'], this.arRecord);
          switch(strModule) {
            case 'sites':
              if(this.arRecord['address']['street'] != '') {
                arModules.push(strModule);
                arFilters[strModule] = {'address' : strValue};
                arIsCustomFilter[strModule] = true;
              }
            break;
            case 'contacts':
              arModules.push(strModule);
              arFilters[strModule] = { 'first_name': this.arRecord['first_name'], 'last_name': this.arRecord['last_name'] };
              arIsCustomFilter[strModule] = false;
            break;
            case 'customers':
              arModules.push(strModule);
              arFilters[strModule] = {'name' : strValue};
              arIsCustomFilter[strModule] = false;
            break;
          }

          let arFieldFormat = {
            clearable: true,
            controlType: 'relate',
            key: strFieldId,
            module: strModule,
            multiple: false,
            readonly: false,
            required: false,
            type: 'relate',
            validator: ''
          };

          this.arRelateFields[strModule] = {
            'item': arFieldFormat,
            'form': new FormGroup(
              {
                [strFieldId]: new FormControl('')
              }
            )
          }
        }
      }
    });
    /**
     * Get duplicate related record of lead
      @params arFilter - Given filter to check
     */
    this.recordService.getPossibleLeadDuplicates(this.arRecord['id']).subscribe( response => {
      let arRecordData =
      {
        customers : [],
        contacts: [],
        sites: []
      };
      if (response.body != undefined) {
        this.getModuleRecord(response.body, arFilters);
      } else {
        this.getModuleRecord(arRecordData, arFilters);
      }
    });
  }

  /**
   * Get all the duplicates based on module
   *
   * @param strModule
   * @param strFilterId
   * @param arFilterKey
   */
  getModuleRecord(moduleRecords, arFilters) {
    let strCount = 0;
    Object.keys(moduleRecords).forEach( strRecordModule => {
      // Do we have record in that module?
      if (!this.arModuleRecords[strRecordModule]) {
        // Create module id in the variable
        this.arModuleRecords[strRecordModule] = [];
        if (moduleRecords[strRecordModule].length > 0) {
          this.arModuleRecords[strRecordModule] = moduleRecords[strRecordModule];
          // If we have exactly 1 duplicate. Auto select that record.
          if (this.arModuleRecords[strRecordModule].length == 1) {
            this.storeRecord(strRecordModule, 'selected', this.arModuleRecords[strRecordModule][0]['id'] );
          } else if (this.arModuleRecords[strRecordModule].length > 1) {
            // If multiple duplicate found.
            this.storeRecord(strRecordModule, 'multiple_duplicate');
          }
          strCount++;
          if (Object.keys(moduleRecords).length == strCount) {
            this.bLoaded = true;
          }
        // If module record don't have duplicate in query search. Use elastic search
        } else {
          let strValue = "";
          switch(strRecordModule) {
            case 'sites':
                strValue = (arFilters[strRecordModule]['address'] != undefined) ? arFilters[strRecordModule]['address']['street'] : '';
            break;
            case 'contacts':
              strValue = (arFilters[strRecordModule]['first_name'] != undefined && arFilters[strRecordModule]['last_name'] != undefined) ? arFilters[strRecordModule]['first_name'] + ' ' + arFilters[strRecordModule]['last_name'] : '';
            break;
            case 'customers':
              strValue = (arFilters[strRecordModule]['name'] != undefined) ? arFilters[strRecordModule]['name'] : '';
            break;
          }
          // Check if value is not empty
          if (strValue) {
            // Check it to global search
            this.globalSearch.global(strValue, strRecordModule).subscribe( response => {
              if (response.length > 0) {
                this.arModuleRecords[strRecordModule] = response;
                // If there is 1 duplicate auto select that record.
                if (this.arModuleRecords[strRecordModule].length == 1) {
                  this.storeRecord(strRecordModule, 'selected', this.arModuleRecords[strRecordModule][0]['id'] );
                } else if(this.arModuleRecords[strRecordModule].length > 1) {
                  // If multiple duplicate found.
                  this.storeRecord(strRecordModule, 'multiple_duplicate');
                }
              } else {
                // If no duplicates auto select create.
                this.storeRecord(strRecordModule, 'create');
              }
              strCount++;
              if (Object.keys(moduleRecords).length == strCount) {
                this.bLoaded = true;
              }
            },
            error => {
              // If no duplicates auto select create.
              this.storeRecord(strRecordModule, 'create');
              strCount++;
              if (Object.keys(moduleRecords).length == strCount) {
                this.initRelateField(false);
                this.bLoaded = true;
              }
            });
          } else {
            this.storeRecord(strRecordModule, 'empty_value');
            strCount++;
            if (Object.keys(moduleRecords).length == strCount) {
              this.bLoaded = true;
            }
          }
        }
      }
    });
  }

  /**
   * Close this component dialog
   */
  closeConvertDialog() {
    this.dialogConvert.close();
  }

  /**
   * Set the leads additional data value to
   * the field, to be used by the
   * job or opportunity upon converting.
   *
   * @param strConvertType
   */
  showAdditionalDataSection(strConvertType: string): void {
    this.strModuleToBeConverted = strConvertType;
    this.bShowAdditionalDataSection = true;

    if (this.objAdditionalData.summary.value === '' || this.objAdditionalData.summary.value === null) {
      this.objAdditionalData.summary.setValue((this.arRecord['notes']) ? this.arRecord['notes'] : null);
    }

    if (this.objAdditionalData.department_id.value === '' || this.objAdditionalData.department_id.value === null) {
      let departmentData = [{ id: this.arRecord['department_id'], text: this.arRecord['department_text'] }];
      this.initSelectionField('department_id', departmentData);
      this.objAdditionalData.department_id.setValue((this.arRecord['department_id']) ? this.arRecord['department_id'] : null);
    }
  }

  /**
   * Hides the additional data section from
   * the user view.
   */
  hideAdditionalDataSection(): void {
    this.bShowAdditionalDataSection = false;
  }

  /**
   * Sends the request to the API.
   *
   * @param strConvertType - if convert the convert should also create a job or opp.
   */
  sendTheRequest(strConvertType: string): void {
    let fields = Object.keys(this.objAdditionalData);

    fields.forEach( field => {
      this.objAdditionalData[field].markAsTouched({onlySelf: true});
    });

    this.bShowLoader[strConvertType] = true;
    this.bButtonSubmit = true;

    if (
      strConvertType == 'convert_only' ||
      (strConvertType != 'convert_only' && this.objAdditionalData.summary.valid &&
      ((this.bDepartmentTracking && this.objAdditionalData.department_id.valid) || !this.bDepartmentTracking))
    ) {
      // Set the summary as the lead notes when it is not convert only.
      if (strConvertType !== 'convert_only') {
        this.arRecord.notes = this.objAdditionalData.summary.value;
      }
      this.arRecord.department_id = this.objAdditionalData.department_id.value;

      let arRequestData = {
        'config': [],
        'record': this.arRecord,
        'convert_with': (strConvertType != 'convert_only') ? strConvertType : '',
        'is_customer_link_to_site': (this.arModel['sites']['data']) ? this.isCustomerLink : null
      };

      Object.keys(this.arModel).forEach( item => {
          // Create config
          arRequestData['config'].push({
            'module': item,
            'id': this.arModel[item]['data'],
            'type': this.arModel[item]['type']
          });
      });

      this.recordService.convertRecord(this.strModule, arRequestData).subscribe( data => {
        let arResult = data.body;
        // Do we have convert values?
        if (arResult['convert']) {
          // Get the module and id of the linked leads
          let strModule = (arRequestData['convert_with']) ? (arRequestData['convert_with']) : 'sites' ;
          strModule = (strModule == 'quotes') ? 'opportunities' : strModule ;
          let strModuleConfig = arResult['convert'].find(x=>x.module == strModule);
          // Check if config has value
          if (!strModuleConfig) {
            strModule = 'customers';
            strModuleConfig = arResult['convert'].find(x=>x.module == strModule);
          }
          // Check if module config has id
          if (strModuleConfig !== undefined) {
            let navigationExtras: NavigationExtras = {
              queryParams: { from: 'create_quote' }
            };
            // Redirect, close the dialog and notify the user
            this.router.navigate([strModule +'/'+ strModuleConfig.id], navigationExtras);
            this.notifService.notifySuccess('convert_success');
          }

          this.bShowLoader[strConvertType] = false;
          this.closeConvertDialog();
        }
      });
    } else {
      this.bButtonSubmit = false;
      this.bShowLoader[strConvertType] = false;
      this.notifService.sendNotification('not_allowed', 'convert_fill_out_fields', 'danger')
    }

  }

  /**
   * Format the record id
   *
   * @param recordId
   */
  formatId(recordId, bDisplay = true) {
    // Declare variable
    let strRecord: string = '';
    if (typeof recordId != 'string') {
      if (recordId.length > 1 && bDisplay) {
        strRecord = recordId[0];
        // For special cases, used for custom table header
        switch(recordId[0]) {
          case 'first_name':
            strRecord = 'name';
          break;
        }
      } else {
        strRecord = recordId[0];
      }
    } else {
      strRecord = recordId;
    }

    return strRecord
  }

  /**
   * Format the record value
   *
   * @param recordId
   * @param data
   */
  formatRecord(recordId, data) {
    // Declare variable
    let strRecord: string = '';
    // Do we have record id?
    if (data['match_percentage'] != undefined) {
      strRecord = data['name'];
    } else if (recordId) {
      // Do we have string type of record id?
      if (typeof recordId == 'string') {
        // Alter the strRecord variable using the record id to get the value
        strRecord = data[recordId];
      } else {
        // Loop the current record ids and get the values
        recordId.forEach( key => {
          // Do we have multiple ids?
          if (recordId.length > 1) {
            let arItem: string[] = [];
            recordId.forEach( id => {
              arItem.push(data[id]);
            });
            // Join the created array and add space separator
            strRecord = arItem.join(' ');
          } else {
            // Check the key
            // This is for special cases, so that we dont need to apply another loop
            switch(key) {
              case 'address':
                strRecord = data['address_text'];
              break;
              default:
                strRecord = data[key];
              break;
            }
          }
        });
      }
    }

    // Return the result
    return strRecord;
  }

  /**
   * Get record data
   *
   * @param strModule
   * @param strId
   * @param bDisplay If true return the display data, if false return the whole record
   */
  getRecordData(strModule, strId, bDisplay = true) {
    if (bDisplay) {
      let arModuleRecords: any = this.arModuleRecords[strModule];
      let arModuleConfig = this.arConvertList[this.strModule].find(x=>x.module == strModule);
      return this.formatRecord(arModuleConfig['filter_id'], arModuleRecords.find(x=>x.id == strId));
    } else {
      return this.arModuleRecords[strModule];
    }
  }

  /**
   * Setup the config of data
   *
   * @param strModule
   * @param strType
   * @param recordData
   */
  storeRecord(strModule, strType, recordData = null) {
    // When create a new record is selected
    // we need to change isCustomerLink
    // to false as we required to link
    // the created customer from convert
    // to site record
    if (strType === 'create') {
      this.isCustomerLink = false;
    }

    // Store passed data
    this.arModel[strModule]['data'] = recordData;
    this.arModel[strModule]['type'] = strType;

    // Validate if required modules has value. For enabling the convert button
    let intFailed = 0;
    this.arConvertList[this.strModule].forEach( item => {
      if (item['display']) {
        if (!this.arModel[item['module']]['type'] ||
            this.arModel[item['module']]['type'] == 'empty_value' ||
            this.arModel[item['module']]['type'] == 'multiple_duplicate') {
          intFailed++;
        }
      }
    });
    this.bButtonSubmit = (intFailed) ? this.bButtonSubmit : false;
    this.onChange(strModule, true);
  }

  /**
   * For relate fields
   *
   * @param strModule
   * @param bClear
   */
  onChange(strModule, bClear = false) {
    if (!bClear) {
      let strRelateValue = this.arNgSelectFields[strModule]['value'];
      if (strRelateValue) {
        this.arModel[strModule]['type'] = 'relate';
        this.arModel[strModule]['data'] = strRelateValue;
      }
      if (!strRelateValue && this.arModel[strModule]['type'] == 'relate') {
        this.arModel[strModule]['type'] = 'create';
        this.arModel[strModule]['data'] = '';
      }
    } else {
      this.arNgSelectFields[strModule]['value'] = '';
    }
  }

  onChangePanel(strPanel, bStatus, event) {
    // Reset model values to false
    Object.keys(this.arModel).forEach( strModel => {
      this.arModel[strModel]['expand'] = false;
    });
    // Update the selected model
    this.arModel[strPanel]['expand'] = (bStatus) ? false : true;
  }

  /**
   * Initialize selection field
   *
   * @param field
   * @param initialData
   */
  initSelectionField(field, initialData) {
    this.openSelectionObservables[field]['obv'] = concat(
      of(initialData),
      this.openSelectionObservables[field]['typehead'].pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.openSelectionObservables[field]['loader'] = true),
        switchMap(term => this.recordService.getRecordRelate(this.openSelectionObservables[field]['module'], term, '', false, false).pipe(
          tap(() => {
            this.openSelectionObservables[field]['loader'] = false;
          })
        ))
      )
    )
  }

  /**
   * Initialize selection field with initial data
   *
   * @param field
   */
  initSelectionData(field) {
    if (!this.selectionData[field]) {
      this.openSelectionObservables[field]['loader'] = true;
      this.recordService.getRecordRelate(this.openSelectionObservables[field]['module'], '', '').subscribe( response => {
        this.selectionData[field] = response;
        this.openSelectionObservables[field]['loader'] = false;
        this.initSelectionField(field, response);
      });
    } else {
      this.initSelectionField(field, this.selectionData[field]);
    }
  }

  /**
   * Initialize related field
   */
  initRelateField(globalSearch = true) {
    Object.keys(this.arNgSelectFields).forEach( fieldKey => {
      if (globalSearch) {
        this.bIsGlobalSearch = true;
        // Initialize the list observable.
        this.arNgSelectFields[fieldKey]['obv'] = concat(
          of([]),
          this.arNgSelectFields[fieldKey]['typehead'].pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => this.arNgSelectFields[fieldKey]['loader'] = true),
            switchMap((term: string) => this.globalSearch.global(term, fieldKey).pipe(
              tap(() => {
                this.arNgSelectFields[fieldKey]['loader'] = false;
              })
            ))
          )
        )
      } else {
        this.bIsGlobalSearch = false;
         // Initialize the list observable.
         this.arNgSelectFields[fieldKey]['obv'] = concat(
          of([]),
          this.arNgSelectFields[fieldKey]['typehead'].pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => this.arNgSelectFields[fieldKey]['loader'] = true),
            switchMap(term => this.recordService.getRecordRelate(fieldKey, term, '', false, false).pipe(
              tap(() => {
                this.arNgSelectFields[fieldKey]['loader'] = false;
              })
            ))
          )
        )
      }
    });
  }
}
