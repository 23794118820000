import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LooseModuleData } from '../module/form-templates/shared/contracts/loose-module-data';
import { Select as DropdownOption } from '../objects/select';
@Injectable()
export class ViewService {

  public arRecordConfig: string[] = [];

  public strRecordModule: string;

  public arRecord: any;

  private obvRecordDetails = new Subject<boolean>();
  obvRecordDetails$ = this.obvRecordDetails.asObservable();

  private arRecordView = new Subject<any>();
  obvRecordView$ = this.arRecordView.asObservable();

  private arRecordFields = new Subject<any>();
  obvRecordFields$ = this.arRecordFields.asObservable();

  private obvRecordValues = new Subject<any>();
  obvRecordValues$ = this.obvRecordValues.asObservable();

  private obvViewResult = new Subject<any>();
  obvViewResult$ = this.obvViewResult.asObservable();

  private callModuleRecordView = new Subject<any>();
  moduleRecordViewCalled$ = this.callModuleRecordView.asObservable();

  @Output() reloadRecordDetail = new EventEmitter<any>();
  @Output() arUpdateStatusWidget = new EventEmitter<any>();
  @Output() arStatusWidget = new EventEmitter<any>();
  @Output() strStatusValue = new EventEmitter<any>();

  public objRecord;
  public strRecordNumber = '';

  constructor() { }

  /**
   * Set and get job records
   *
   * @param strRecordId - job id
   */
  setViewRecord (arRecord: any = []){
    this.arRecord = arRecord;
  }

  getViewRecord () {
    return this.arRecord;
  }

  /**
   * Set record name
   *
   * @param strRecordId
   */
  setRecordNumber(strNumber: string){
    this.strRecordNumber = strNumber;
  }

  getRecordNumber() {
    return this.strRecordNumber;
  }

  /**
   * Set record module
   *
   * @param strRecordId
   */
  setViewResult(objResponse: any){
    this.objRecord = objResponse;
    this.obvViewResult.next(objResponse);
  }

  getRecord() {
    return this.objRecord;
  }

  /**
   * Set record module
   *
   * @param strRecordId
   */
  setRecordConfig(arRecordConfig: any){
    this.arRecordConfig = arRecordConfig;
  }


  /**
   * Set object with module, label, icon and button
   * Sample button object values { type: 'button', link: '/admin/users', class: 'btn-link', label: 'cancel' }
   *
   * @param objRecordDetails
   */
  setRecordDetails(objRecordDetails: any) {
    this.obvRecordDetails.next(objRecordDetails);
  }

  /**
   * Set record view
   *
   * @param objRecordView
   */
  setRecordView(objRecordView: any) {
    this.arRecordView.next(objRecordView);
  }

  /**
   * Set fields that are used in record view
   *
   * @param objRecordFields
   */
  setRecordFields(objRecordFields: any) {
    this.arRecordFields.next(objRecordFields);
  }

  /**
   * Set values of each record view details
   *
   * @param objRecordValues
   */
  setRecordValues(objRecordValues: any) {
    this.obvRecordValues.next(objRecordValues);
  }

  /**
   * Set record module
   *
   * @param strRecordModule
   */
  setRecordModule(strRecordModule: string) {
    this.strRecordModule = strRecordModule;
  }

  /**
   * Call module record view
   *
   */
  callRecordView() {
    this.callModuleRecordView.next();
  }
  /**
   * Set status widget value
   *
   * @param arData
   */
  updateStatusWidget(arData: any) {
    this.arUpdateStatusWidget.emit(arData);
  }

  /**
   * Set status widget value
   *
   * @param arData
   */
  reloadRecordView(reloadRecordDetail: any) {
    this.reloadRecordDetail.emit(reloadRecordDetail);
  }

  /**
   * Set status widget value
   *
   * @param arData
   */
  setStatusWidget(arData: any) {
    this.arStatusWidget.emit(arData);
  }

  /**
   * Update the current status widget value
   *
   * @param arData
   */
  updateStatusWidgetValue(strValue: any) {
    this.strStatusValue.emit(strValue);
  }

  /**
   * wraps the property obvViewResult$ this will make the other service/components testable
   * since it's kinda hard to mock/stub a property of a class
   *
   * @returns {Observable<ModuleViewResult>}
   */
  getModuleViewResult$(): Observable<ModuleViewResult> {
    return this.obvViewResult$;
  }

  onModuleRecordView$(): Observable<undefined> {
    return this.moduleRecordViewCalled$;
  }
}

export interface ModuleUsedField {
  type: 'relate' | 'datetime' | 'date' | 'dropdown' | 'currency' | 'text' | 'textarea'
    | 'number' | 'address' | 'checkbox' | 'multiselect' | 'increment' | 'dialog' | 'json'
    | 'array' | 'images' | 'image';
  label: string;
  default_value?: any;
  module?: string;
  options?: DropdownOption[];
  format?: {[ key: string]: ModuleUsedField};
  decimals?: number;
}

export interface ModuleViewResult {
  /**
   * this contains the current module data
   *
   * @var {LooseModuleData}
   */
  record_details: LooseModuleData;

  /**
   * this contains the fields that is used
   *
   * @var {[key: string]: ModuleUsedField}
   */
  used_fields?: {[key: string]: ModuleUsedField},

  /**
   * this contains the fields that can be used in the view and not being rendered
   *
   * @var {[key: string]: ModuleUsedField}
   */
  available_fields?: {[key: string]: ModuleUsedField},

  /**
   * this contains the list of related data that the module has
   */
  related_data?: [{
    [key: string]: any,
  }]
}
