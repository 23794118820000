<header>
  <div class="d-flex dialog-header">
    <h3 class="title">
      <span>
        <fa-icon [icon]="['fal', 'box-open']" class="mr-1 text-primary"></fa-icon>{{ 'add_related_products' | translate }}
      </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="dialogRef.close()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button type="button" (click)="addProducts()" [disabled]="!bHasSelectedItems" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
            <span class="pl-1">{{ 'add_products_popup' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr>
<div>
  <mat-dialog-content>

  <div class="container-fluid">

    <div class="row mb-3">
      <div class="col-2"><small><b>{{ 'code' | translate }}</b></small></div>
      <div class="col-3"><small><b>{{ 'name' | translate }}</b></small></div>
      <div class="col-5"><small><b>{{ 'description' | translate }}</b></small></div>
    </div>

    <div *ngFor="let item of arRelatedProducts" class="row" [ngClass]="{'bg-highlight': item.selected}">
      <div class="col-2"><small>{{ item.item_code }}</small></div>
      <div class="col-3"><small>{{ item.item_name }}</small></div>
      <div class="col-5">
        <p>
          <span *ngIf="!item.expanded">{{ item.description | slice: 0:128 }}<a *ngIf="item.description.length > 128" href="javascript:void(0)" (click)="item.expanded = !item.expanded">...</a></span>
          <span *ngIf="item.expanded" (click)="item.expanded = !item.expanded">{{ item.description }}</span>
        </p>
      </div>
      <div class="col-2 p-3">
        <mat-checkbox [(ngModel)]="item.selected" class="float-right"></mat-checkbox>
      </div>
    </div>
  </div>

  </mat-dialog-content>
</div>
