import { AsideLink } from './aside-link';

export class AsideMeta {

  /**
   * Name of the main navigation.
   *
   * @var {string}
   */
  name: string;

  /**
   * Icon of the main navigation.
   *
   * @var {string[]}
   */
  icon: string[];

  /**
   * Contents of left side of the child navigation.
   *
   * @var {AsideLink[]}
   */
  panel1: AsideLink[];

  /**
   * Contents of right side of the child navigation.
   *
   * @var {AsideLink[]}
   */
  panel2: AsideLink[];

  get links() {
    return [
      ...this.panel1.map(links => {return links.link[0]}),
      ...this.panel2.map(links => {return links.link[0]})
    ];
  }

  constructor(
    name: string,
    icon: string[],
    panel1: AsideLink[] = [],
    panel2: AsideLink[] = []
  ) {
    this.name = name;
    this.icon = icon;
    this.panel1 = panel1;
    this.panel2 = panel2;
  }
}