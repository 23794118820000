<div *ngIf="updateForm">
  {{ 'force_update_' +strModule | translate }}
  <hr />
  <form [formGroup]="updateForm">
    <div *ngFor="let strField of objRecordFields[strModule]" class="col-12 mb-3">
      <app-edit-field [item]="objMetadata[strModule][strField]" [form]="updateForm" strMode="add" [bSubmitted]="bSubmitted"></app-edit-field>
    </div>
  </form>
  <hr />
  <div class="col-12 mb-1">
    <button (click)="onSubmit()" class="btn btn-primary w-100" [ngClass]="(bShowLoader)? 'btn-spinner' : ''">
      <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'save']"></fa-icon>
      <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
      <span class="pl-1">{{ 'save' | translate }}</span>
    </button>
  </div>
</div>