import _ from 'lodash';

export const toFormattedNumber = (value: any, opts: {
  maxDecimalPlaces?: number,
  currency?: boolean,
} = {}): number => {
  if (_.isNaN(value) || value === 'NaN') {
    return 0;
  }

  value = _.toString(value).trim();

  const places = opts.maxDecimalPlaces || ((opts.currency) ? _kMaxDecimalPlacesForCurrency : _kMaxDecimalPlacesForNumbers);
  const softLimit = new RegExp(`^(?:-?(?:\\d*)?(?:\\.\\d{1,${places}})?)$`, 'i');
  const hardLimit = new RegExp(`^(?:-?(?:\\d*\\.)?\\d{${places}})$`, 'i');

  if (softLimit.test(value)) {
    return _.toNumber(value);
  } else if (/^(?:\-?[\d]+)?(?:\.[\d]*)?$/.test(value) && !hardLimit.test(value)) {
    return _.toNumber(_.round(_.toNumber(value), places).toFixed(places));
  }

  return 0;
}

const _kMaxDecimalPlacesForNumbers = 3;
const _kMaxDecimalPlacesForCurrency = 2;

/// computes the markup percentage on a given unit price and unit cost
export const markup = (opts: {
  unit_price?: number,
  unit_cost?: number,
} = {}): number => {
  opts = Object.assign({
    unit_price: 0,
    unit_cost: 0,
  }, opts);

  if (opts.unit_cost === 0) {
    return 0;
  }

  return toFormattedNumber(
    (100 * ((opts.unit_price - opts.unit_cost) / opts.unit_cost))
  );
}

/// computes the tax on a given price
export const tax = (rate: number, opts: {
  price?: number,
} = {}): number => {
  opts = Object.assign({
    price: 0,
  }, opts);

  if (rate === 0 || opts.price === 0) {
    return opts.price;
  }

  return toFormattedNumber((rate / 100) * opts.price, {
    currency: true,
    maxDecimalPlaces: 4,
  });
}