<div class="d-flex h-100 flex-column">
  <div class="d-flex">
    <h3 class="title">
      <fa-icon [icon]="['fas', 'file-invoice']"></fa-icon>&nbsp;
      <span>{{ 'billing_history' | translate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button id="closeButton" type="button" (click)="objDialogRef.close()" class="btn btn-danger">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr class="w-100 mt-0">
  <mat-dialog-content style="height: 100%">
    <ng-container *ngIf="bLoading">
      <div style="width: 100%; text-align: center;">
        <inline-view-loader [isLoading]="bLoading" [hasText]="false"></inline-view-loader>
      </div>
    </ng-container>
    <ng-container *ngIf="!bLoading">
      <div *ngIf="arBillingHistoryList.length; else noHistory" >
        <div class="row" *ngFor="let billingHistory of arBillingHistoryList; let i = index">
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12">{{ billingHistory.description }}</div>
              <div class="col-sm-3">{{ billingHistory.currency }}{{ billingHistory.total }}</div>
              <div class="col-sm-9">{{ billingHistory.paid_at }}</div>
              <div *ngIf="billingHistory.status != 'paid'" class="col-sm-12 text-warning">{{ billingHistory.status | translate }}</div>
            </div>
          </div>
          <div class="col-md-3 align-items-center d-flex align-items-right">
            <fa-icon
              *ngIf="this.objDownload[billingHistory.id]"
              [icon]="['fas','spinner']"
              [spin]="true"
              aria-hidden="true"
              class="justify-content-center text-right pr-1"
            ></fa-icon>
            <a
              role="button"
              id="downloadHistory{{ i }}"
              class="justify-content-center text-right"
              (click)="!this.objDownload[billingHistory.id] ? downloadInvoice(billingHistory.id) : ''"
            >{{ 'download' | translate }}</a>
          </div>
          <div class="col-md-12"><hr /></div>
        </div>
      </div>
      <ng-template #noHistory>
        <div class="row">
          <div style="width: 100%; text-align: center;">
            {{ 'no_billing_history' | translate }}
          </div>
        </div>
      </ng-template>
    </ng-container>
  </mat-dialog-content>
</div>