import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { MatDialog } from '@angular/material';

import { ViewService } from '../../../../../services/view.service';
import { RecordService } from '../../../../../services/record.service';
import { ListingService } from '../../../../../services/listing.service';
import { NotificationService } from '../../../../../services/notification.service';

import { FormComponent } from '../dialog/form/form.component';
import { ViewComponent } from '../dialog/view/view.component';

import { StockLevel } from '../../../../../objects/stock-management/stock-level';
import { StockTransfer } from '../../../../../objects/stock-management/stock-transfer';
import { LooseObject } from '../../../../../objects/loose-object';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stock-transfer-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  /**
   * determine if the record is marked as complete in listing
   * added this flag to determine if we need to add a checkbox
   */
  objMarkAsCompleteList: LooseObject = {};

  /**
   * determine if the listing is still loading
   */
  bLoading = false;

  /**
   * record list
   */
  arRecordList: Array<StockTransfer> = [];

  /**
   * determine the current item on stock level preview
   */
  strItemId: string = null;

  /**
   * to determine if next page is enabled
   */
  get nextButton(): string {
    return (this.listingService.strNextValue != '') ? 'active' : 'disabled';
  }

  /**
   * to determine if previous page is enabled
   */
  get previousButton(): string {
    return (this.listingService.strFirstValue != this.listingService.strCurrentValue &&
      this.listingService.strCurrentValue != '')
      ? "active" : "disabled";
  }

  /**
   * to determine if previous page is enabled
   */
  get hasData(): boolean {
    return this.arRecordList.length !== 0;
  }

  constructor(
    private matDialog: MatDialog,
    private viewService: ViewService,
    private recordService: RecordService,
    private listingService: ListingService,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) {}

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.strItemId = params['item_id'];
        this.getRecord('default', {});
      }
    );
  }

  /**
   * fetch module role listing
   *
   * @param strPage - what page is currently to be viewed.
   * @param strIf - the id of the record.
   * @param objFilter - Filter of data.
   */
  getRecord(strPage: string, objFilter: LooseObject = {}) {
    this.bLoading = true;
    let objPagination = this.listingService.beforeFetching(strPage);

    objFilter = {
      'stock_transfer_quantities.item_id': this.strItemId,
      ...objFilter
    }
    this.listingService.fetchData(JSON.stringify(objPagination['objPage']), 'stock_transfers', JSON.stringify(objFilter)).subscribe( response => {
      this.arRecordList = response['data'] || [];
      this.bLoading = false;
      this.listingService.afterFetching(response, strPage);
    });
  }

  /**
   * open the form dialog
   *
   * @param record
   *
   * @returns {void}
   */
  openFormDialog(record: StockTransfer | {} = {}): void {
    let objFormDialog = this.matDialog.open(FormComponent, {
      width: '800px',
      height: 'auto',
      data: record,
      disableClose: true
    });

    objFormDialog.afterClosed().first().subscribe( response => {
      if (response == 'success') {
        this.getRecord('default');
      }
    })
  }

  /**
   * preview the the stock transfer
   *
   * @param record
   *
   * @returns {void}
   */
  openViewDialog(record: StockTransfer): void {
    this.matDialog.open(ViewComponent, {
      width: '800px',
      height: 'auto',
      data: record,
      disableClose: true
    });
  }

  /**
   * add a condition which dialog needs to be open
   * if the stock transfer status is draft, open the form dialog so the user can edit the products
   * if the stock transfer status is completed, open view dialog
   *
   * @param record
   *
   * @returns {void}
   */
  openStockTranferDialog(record: StockTransfer): void {
    if (record.status === 'complete') {
      this.openViewDialog(record);
    } else {
      this.openFormDialog(record)
    }
  }

  /**
   * mark the stock transfer as complete
   *
   * @param index
   * @param recordId
   *
   * @returns {void}
   */
  markAsComplete(index: number, recordId: string): void {
    this.objMarkAsCompleteList[recordId] = true;
    this.recordService.saveRecord('stock_transfers', {status: 'complete'}, recordId).subscribe( response => {
      if (response.status === 200) {
        this.arRecordList[index]['status'] = 'complete';
        this.getRecord('default');
      } else {
        this.notificationService.notifyWarning(response.body.error[0]);
        this.objMarkAsCompleteList[recordId] = false;
      }
    });
  }

  /**
   * Handle refresh action
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arRecordList = [];
    this.getRecord('default');
  }

  /**
   * format the date to readable date
   *
   * @returns {string}
   */
  formatDate(date): string {
    let convertedUTCDateTime = moment.utc(date).toDate();
    return moment(convertedUTCDateTime).format('lll');
  }
}
