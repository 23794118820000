import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { DocumentService } from '../../../services/document.service';
import { FileService } from '../../../services/file/file.service';
import { ActivitiesService } from '../../../services/activities.service';
import { LambdaService } from '../../../services/lambda.service';
import { DocumentLibrary } from '../../../lists/document-libray';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-update-document-dialog',
  templateUrl: './update-document-dialog.component.html',
  styleUrls: ['./update-document-dialog.component.scss']
})
export class UpdateDocumentDialogComponent implements OnInit {

    public isFileDropped: boolean = false;
    public isFileUploaded: boolean = false;
    public arFiles : any = [];
    public strDocumentName : string;
    public arCurrentDocument;
    public arVersionList : any = [];
    public arFormatList = new DocumentLibrary;
    public bSubmitted: boolean = false;
    public bEdited: boolean = false;

    @HostListener('window:keyup.esc') onKeyUp() {
      this.cancelDialog();
    }

    constructor(
      public dialogRef: MatDialogRef<UpdateDocumentDialogComponent>,
      public activitiesService: ActivitiesService,
      public fileService: FileService,
      public documentService: DocumentService,
      public lambdaService: LambdaService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private notifService: NotificationService
    ) { }

    ngOnInit() {
        this.arCurrentDocument = this.data
        this.strDocumentName = this.arCurrentDocument.document_name

        // Call api for getting all versions of file
        this.fileService.getObjectHistory(this.arCurrentDocument.upload_name).subscribe(
            data => {
                this.arVersionList = data;
            }
        );
    }

    /**
     * Let's format the datetime value.
     * @param date
     */
    formatDate(strDate) {
      return moment(strDate).utcOffset(new Date().getTimezoneOffset()).format('lll');

    }

    /**
     * Closes the dialog
     */
    cancelDialog() {
        if (this.bEdited) {
            // Pop-up modal for confirmation
            this.notifService.sendConfirmation('confirm_cancel')
                .filter(confirmation => confirmation.answer === true)
                .subscribe(() => {
                    this.dialogRef.close({ status: 'cancel' });
                });
        } else {
            this.dialogRef.close({status : 'cancel'});
        }
    }

    /**
     *
     * Triggers when saving a document, passes value of the document to the main component for saving
     *
     * @param objData - the document that is dropped
     */
    onSubmit() {

      // Check if theres document name and if the file is set and is uploaded successfully
      if (this.strDocumentName && this.arFiles.name !== undefined) {

            let arInitialRequest = {
                metadata : this.arFormatList.metadata,
                document_id : this.arFiles.upload_name.split('/').pop(),
                field_checker : true
            };

            this.bSubmitted = true;

            // Check if uploaded file has form fields available
           this.lambdaService.mergeFormFields(JSON.stringify(arInitialRequest))
           .subscribe(
               data => {

                    let arRequest = {
                        document_name : this.strDocumentName,
                        document : this.arFiles,
                        current_data : this.arCurrentDocument,
                        available_fields :  {},
                    }

                   // If there are available form fields
                   if (data['available_fields'] != undefined) {
                       // Get the fields and attach in request
                       arRequest.available_fields = data['available_fields'];
                   }

                    // Call api for adding the document
                    this.documentService.updateDocument(JSON.stringify(arRequest))
                    .subscribe(
                        data => {
                            this.bSubmitted = false;
                            this.notifService.notifySuccess('file_update_success');
                            this.dialogRef.close({ status: 'save' });
                        }
                    );

               }
           );


      }
    }

     /**
     *
     * Triggered when a document is dropped from the file input
     *
     * @param objData - the document that is dropped
     */
    onFileChange(objData) {
        let reader = new FileReader();

        if(objData.target.files && objData.target.files.length) {
            const [file] = objData.target.files;
            reader.readAsDataURL(file);
            let strFileExtension = file.name.split('.').pop();

            if(strFileExtension === 'pdf') {
                if(file.size/1024/1024 < 30) {
                    reader.onload = () => {
                        this.isFileDropped = true;

                        this.fileService.upload(file).subscribe((response) => {
                            let objFile = this.fileService.objFile;
                            this.isFileDropped = false;
                            this.isFileUploaded = true;
                            this.arFiles = {
                                'name': objFile.name,
                                'size': objFile.size / 1024,
                                'type': objFile.type,
                                'upload_name' : response['filename']
                            };

                        });
                    };
                } else {
                    this.notifService.notifyWarning('file_size_limit');
                }
            } else {
                this.notifService.notifyWarning('pdf_only');
            }
        }
    }

    /**
     * Mark as edited
     *
     * @returns {void}
     */
    markAsEdited(): void {
        this.bEdited = true;
    }
}
