import { map } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit, forwardRef } from '@angular/core';

import { FileUploadComponent } from '../file-upload/file-upload.component';

@Component({
  selector: 'drag-and-drop-file-upload-input',
  templateUrl: './drag-and-drop-file-upload.component.html',
  styleUrls: ['./drag-and-drop-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragAndDropFileUploadComponent),
      multi: true
    }
  ]
})
export class DragAndDropFileUploadComponent extends FileUploadComponent implements OnInit {

  /**
   * The files that the user has uploaded.
   *
   * @type {File | File[] | null | ''}
   */
  protected files: BehaviorSubject<File | File[] | null | ''> = new BehaviorSubject(null);

  /**
   * Public observable for the files behavior subject.
   *
   * @type {Observable<File[]>}
   */
  files$: Observable<File[]> = this.files.asObservable()
    .pipe(
      map(files => {
        if (files === '' || files === null) {
          return [];
        } else if (files instanceof File) {
          return [files];
        } else {
          return files;
        }
      }),
    );

  /**
   * Returns the filenames of the files that the user has
   * uploaded.
   *
   * @type {string[]}
   */
  filenames$: Observable<string[]> = this.files$
    .pipe(
      map(files => { return files.map(file => file.name); }),
    );

  constructor() {
    super();
  }

  ngOnInit() { }

  /**
   * @inheritdoc
   */
  registerOnChange(onChangeCallBack: Function) {
    super.registerOnChange((files) => {
      this.setSelectedFiles(files);

      onChangeCallBack(files);
    });
  }

  /**
   * @inheritdoc
   */
  writeValue(value: any): void {
    this.setSelectedFiles(value);
    super.writeValue(value);
  }

  /**
   * Sets the file input's selected files.
   *
   * @param {File | File[] | null | ''} files
   *
   * @returns {void}
   */
  setSelectedFiles(files: File | File[] | null | ''): void {
    this.files.next(files);
  }

  /**
   * Sets the HTML File input element's value to null.
   *
   * @returns {void}
   */
  clearFileInput(): void {
    this.fileUploadInput.nativeElement.value = null;
  }
}
