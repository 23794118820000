import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { SharedModule } from './shared.module';

// Components
import { InformationComponent } from '../components/information/information.component';

// Services
import { InformationService } from '../../services/information.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule.forRoot()
  ],
  declarations: [
    InformationComponent
  ],
  entryComponents: [],
  exports: [
    InformationComponent
  ],
  providers: [
    InformationService
  ]
})
export class InformationModule { }
