<div class="fixed-top mt-5 centered" style="width: 50%; margin: 0 auto;">
  <div *ngFor="let notification of arPopupNotifications" class="d-flex justify-content-center" [@fadeInOut]>
    <div id="notificationPopup" class="bg-{{ notification.theme}} notification-style mb-3 text-white">
      <div class="col-12">

        <fa-icon *ngIf="notification.theme == 'success'" [icon]="['fas', 'check-circle']" aria-hidden="true"></fa-icon>
        <fa-icon *ngIf="notification.theme == 'danger'" [icon]="['fas', 'times-circle']" aria-hidden="true"></fa-icon>
        <fa-icon *ngIf="notification.theme == 'info'" [icon]="['fas', 'info-circle']"  aria-hidden="true"></fa-icon>
        <fa-icon *ngIf="notification.theme == 'primary'" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
        <fa-icon *ngIf="notification.theme == 'warning'" [icon]="['fas', 'exclamation-circle']" aria-hidden="true"></fa-icon>

        <ng-container *ngIf="notification.type === 'default' || notification.type === 'persistent'">
          <strong id="notificationHeader" class="ml-1">{{ notification.header | translate }}</strong>
        </ng-container>

        <ng-container *ngIf="notification.type === 'interceptor'">
          <strong id="notificationHeader" class="ml-1">{{ notification.header | translate }}</strong>
        </ng-container>

        <fa-icon
          id="btnPopupClose"
          [icon]="['fas', 'times']"
          (click)="removeNotification(notification.id)"
          class="fa-lg float-right pointer exit-button">
        </fa-icon>

      </div>

      <div class="col-12">
        <div class="row  mt-2">
          <div *ngIf="notification.type === 'default' || notification.type === 'persistent'" class="col">
            <span id="notificationMessage" class="notification-message N-{{ notification.id }}" translate [innerHtml]="notification.message"></span>
          </div>

          <div *ngIf="notification.type === 'interceptor'" class="col notification-message">
            <span id="notificationMessageFieldName" *ngIf="notification.message.field_name !== ''" class="pr-1">{{ notification.message.field_name | translate }}</span>
            <span id="notificationInterceptorMessage" class="pr-1">{{ notification.message.message | translate }}</span>
            <span id="notificationMessageFieldVal" *ngIf="notification.message.field_value !== ''" class="pr-1">{{ notification.message.field_value }}</span>
          </div>

          <div *ngIf="notification.has_button" class="col-4 flex-box">
            <button id="btnPopupAction"class="btn btn-light btn-block p-2 float-right mx-auto my-auto text-{{ notification.theme}}" (click)="buttonAction(notification)">
              {{ notification.button_title | translate }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

