// angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

// rxjs
import { Observable } from 'rxjs';

// filesaver
import { saveAs } from 'file-saver';

// fieldmagic components
import { EmailComponent } from '../email.component';
import { ParticipantsListComponent } from './../participants-list/participants-list.component';

// fielmagic services
import { EmailService } from './../../../../../services/email.service';
import { ActivitiesService } from './../../../../../services/activities.service';
import { NotificationService } from './../../../../../services/notification.service';
import { RecordService } from './../../../../../services/record.service';

// fieldmagic contracts
import { Thread, ThreadMessage, Attachment } from './../../../../../contracts/thread-message';

@Component({
  selector: 'app-render',
  templateUrl: './render.component.html',
  styleUrls: ['./render.component.scss']
})
export class RenderComponent implements OnInit {
  /**
   * contains the thread information
   *
   * @var {Thread|any}
   */
  thread: Thread|any = {
    subject: null,
    to: [],
    cc: [],
    bcc: [],
    messages: [],
    participants: {
      subscribed: [],
      unsubscribed: []
    },
    previousToken: null,
    nextToken: null,
  };

  /**
   * tells if the current list is being paginated
   *
   * @var {boolean}
   */
  isPaginating: boolean = false;

  /**
   * tells if the is being fetched
   */
  isLoading: boolean = false;

  /**
   * @param {any}          data
   * @param {EmailService} emailService
   */
  constructor(
	  protected dialog: MatDialog,
    @Inject(MatDialogRef) protected currentDialog: any,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    protected emailService: EmailService,
    protected activityService: ActivitiesService,
    protected notificationService: NotificationService,
    protected recordService: RecordService
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.isLoading = true;

    this.loadEmailData();

    this.getMessages$(this.data['activity_id']).subscribe((thread: Thread) => {
      this.thread = Object.assign(this.thread, thread);
      this.isLoading = false;
    });
  }

  /**
   * retrieves all the previous messages
   *
   * @param {string} page
   */
  retrievedPreviousMessages(page: string): void {
    this.isPaginating = true;
    this.getMessages$(this.data['activity_id'], page).subscribe((thread: Thread) => {
      this.thread.nextToken = thread.nextToken;
      this.thread.previousToken = thread.previousToken;
      this.thread.messages.push(...thread.messages);
      this.isPaginating = false;
    });
  }

  /**
   * callback by tracking data changes to messages list
   *
   * @param {number} index
   * @param {ThreadMessage} message
   */
  trackMessage(index: number, message: ThreadMessage): string|null {
    return (! message) ? message.id : null;
  }

  /**
   * render the mail information from the server
   *
   * @param {ThreadMessage|any} message
   */
  renderMail(message: ThreadMessage|any): void {
    if (! message.mail) {
      message.isLoading = true;
      this.emailService.renderMessage$(message.id).subscribe((mail) => {
        message.mail = mail;
        message.isLoading = false;
        message.shouldCollapse = true;
      });
    } else {
      message.shouldCollapse = (! message.shouldCollapse);
    }
  }

  /**
   * cancels/closes the current dialog box
   */
  cancelDialog(): void {
    this.currentDialog.close();
  }

  /**
   * opens the mail dialog for sending an email
   */
  openMailDialog(): void {
    let activity = Object.assign({}, this.data.activity);

    // setting the is_draft will allow editing of the mail information from email form component
    // and lets remove the notes to enable new mail content to be saved
    activity['is_draft'] = true;
    activity['notes'] = '';

    this.dialog.open(EmailComponent, {
      width: '66%',
      height: 'auto',
      data: {
        module: this.data.module,
        record_id: this.data.record_id,
        view_type: 'edit',
        activity: activity,
        record_data: this.data.record_data,
        activity_id: this.data.activity_id,
        is_archived: true,
      }
    }).afterClosed().subscribe((data) => {
      if (typeof data == 'object') {
        this.getMessages$(this.data['activity_id']).subscribe((thread: Thread) => {
          this.thread = Object.assign(this.thread, thread);
          this.isLoading = false;
        });

        this.notificationService.notifySuccess('email_sent');
      }
    })
  }

  /**
   * convert attachment content into a downloadable file
   *
   * @param   {Attachment} attachment
   *
   * @returns {boolean}
   */
  downloadAttachment(attachment: Attachment): boolean {
    if (attachment.content) {
      // reference https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
      const byteCharacters = atob(attachment.content);
      const sliceSize = 512;
      const byteArrays = [];

      // in this part we transform the characters from a decoded base64 string
      // optimal way of dealing with transforming characters into a byte is via chunks
      for (let byteOffset = 0; byteOffset < byteCharacters.length; byteOffset += sliceSize) {
        const bytePart = byteCharacters.slice(byteOffset, byteOffset + sliceSize);
        const byteNumbers = new Array(bytePart.length);

        for (let byteNumbersOffset = 0; byteNumbersOffset < bytePart.length; byteNumbersOffset++) {
          byteNumbers[byteNumbersOffset] = bytePart.charCodeAt(byteNumbersOffset);
        }

        byteArrays.push(new Uint8Array(byteNumbers));
      }

      const blob = new Blob(byteArrays, { type: attachment.contentType });

      // download the file to the user using the filesaver
      saveAs(blob, attachment.filename);
    }

    // stops click execution
    return false;
  }

  openParticipantsList(): void {
    let participantsDialog = this.dialog.open(ParticipantsListComponent, {
      width: '40%',
      height: 'auto',
      data: {
        threadId: this.data['activity_id'],
        participants: this.thread.participants
      }
    });

    let dialogSubscription = participantsDialog.afterClosed().subscribe((isUpdated) => {
      // reload email data when participants has been updated
      if (isUpdated) {
        this.loadEmailData();
      }

      // unsubscribed to observable subscription
      dialogSubscription.unsubscribe();
    });
  }

  /**
   * retrieved the messages from the email service
   *
   * @param {string}      threadID
   * @param {string|null} page
   *
   * @returns {Observable<Thread>}
   */
  protected getMessages$(threadID: string, page?: string|null): Observable<Thread> {
    return this.emailService.getMessages$(threadID, page);
  }

  protected loadEmailData(): void {
    let moduleName = this.data.module;

    this.recordService
      .getMultipleModuleRelateRecord(
        'activities|'+ moduleName,
        false,
        {
          activities: {
            'activities.id': this.data['activity_id']
          },
          [moduleName]: {
            [moduleName +'.id']: this.data['record_id'],
          },
        }
      )
      .subscribe((response) => {

        let activity = response['activities'][0];
        this.data['record_data'] = response[this.data.module][0];

        // set information
        this.thread.subject = activity['activity_name'];

        // participants
        let threadTo = activity['to'];
        let threadCc = activity['cc'];
        let threadBcc = activity['bcc'];

        if (threadTo !== '' && typeof threadTo == 'string') {
          threadTo = JSON.parse(threadTo);
        }

        if (threadCc !== '' && typeof threadCc == 'string') {
          threadCc = JSON.parse(threadCc);
        }

        if (threadBcc !== '' && typeof threadBcc == 'string') {
          threadBcc = JSON.parse(threadBcc);
        }

        this.thread.to = threadTo;
        this.thread.cc = threadCc;
        this.thread.bcc = threadBcc;

        // FC-1261: participants
        let subscribedParticipants = activity['email_participants'] || [];
        let unsubscribedParticipants = activity['email_nonparticipants'] || [];

        this.thread.participants.subscribed = subscribedParticipants;
        this.thread.participants.unsubscribed = unsubscribedParticipants;
      });
  }
}
