import { Form } from './form';

export class Textarea extends Form<string> {
  controlType = 'textarea';
  rows: number;
  is_hidden: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.rows = options['rows'] || 4;
    this.is_hidden = options['is_hidden'] || false;
  }
}