import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'button-icon-loader',
  templateUrl: './button-icon-loader.component.html',
  styleUrls: ['./button-icon-loader.component.scss']
})
export class ButtonIconLoaderComponent implements OnInit {

  /**
   * Show the loading icon.
   *
   * @type {Boolean}
   */
  @Input('isLoading') isLoading: boolean = false;

  /**
   * Default icon.
   *
   * @type {Array}
   */
  @Input('defaultIcon') defaultIcon: string[] = [];

  /**
   * Show the loading text
   *
   * @type {Boolean}
   */
  @Input('hasText') hasText: boolean = false;

  /**
   * FC-4166: fix issue where preview PDF icons became misaligned in widget.
   *
   * @type {boolean}
   */
  @Input('hasMarginClass') hasMarginClass: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
