import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper, MatCheckboxChange } from '@angular/material';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { delay, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Address } from '../../../../../objects/address';
import { ListingService } from '../../../../../services/listing.service';
import { NotificationService } from '../../../../../services/notification.service';
import { WizardCustomerData } from '../../../objects/wizard';
import { Relate } from '../../../../../objects/relate';
import { SearchService } from '../../../../../services/search.service';
import { RecordService } from '../../../../../services/record.service';
import { GlobalRecord } from '../../../../../objects/global-record';
import { PlacesService } from '../../../../../shared/components/address/places.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { Client } from '../../../../../objects/client';
import { AddressComponent } from '../../../../../shared/components/address/address.component';
import { SharedService } from '../../../services/shared.service';
import { LooseObject } from '../../../../../objects/loose-object';
import { get, isEmpty } from 'lodash';
import { AddressService } from '../../../../../services/address.service';

@Component({
  selector: 'customer-step',
  templateUrl: './customer.component.html',
  styleUrls: [
    './customer.component.scss',
    '../../../styles/shared_wizard_styles.scss',
  ],
  providers: [PlacesService]
})
export class CustomerComponent implements OnInit, OnDestroy {

  /**
   * The element of the org name to be used for Google places.
   *
   * @var {ElementRef}
   */
  @ViewChild('orgName') orgNameElementRef: ElementRef;

  /**
   * The stepper this component is under.
   *
   * @var {MatStepper}
   */
  @Input() stepper: MatStepper;

  /**
   * The ID of the preselected customer.
   *
   * @var {string}
   */
  @Input() preselectedCustomer: string;

  /**
   * The ID of the preselected site.
   *
   * @var {string}
   */
  @Input() preselectedSite: string;

  /**
   * The type of the record being created.
   *
   * @var {string}
   */
  @Input() strRecordType: string;

  /**
   * Flag that shows if the wizard was opened from the mega menu.
   *
   * @var {boolean}
   */
  @Input() bOpenedFromMegaMenu: boolean = false;

  /**
   * Flag to identify if we will skip this step when we have a preselected
   * customer and site.
   *
   * @var {boolean}
   */
  @Input() skipWhenPreselected: boolean;

  /**
   * The data of the customer to be sent to the parent.
   *
   * @var {EventEmitter<WizardCustomerData>}
   */
  @Output() objCustomerData = new EventEmitter<WizardCustomerData>();

  /**
   * Only visible in create job wizard. Emits the latest value of whether the job has a customer or not.
   *
   * @var {EventEmitter<boolean>}
   */
  @Output() changeStepToLocation = new EventEmitter<boolean>();

  /**
   * Only visible in create job wizard. Emits the latest value of whether the job location is a custom address.
   *
   * @var {EventEmitter<boolean>}
   */
  @Output() customLocationEmitter = new EventEmitter<boolean>();

  /**
   * Emits true if step is skipped.
   *
   * @var {EventEmitter<boolean>}
   */
  @Output() skipStepEmitter = new EventEmitter<boolean>();

  /**
   * The child address component.
   *
   * @var {AddressComponent}
   */
  @ViewChild(AddressComponent) private addressComponent: AddressComponent;

  /**
   * The form group of the customer.
   *
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({
    'customer_id': new FormControl(null),
    'first_name': new FormControl(null, Validators.required),
    'last_name': new FormControl(null, Validators.required),
    'name': new FormControl({value: null, disabled: true}, Validators.required),
    'type': new FormControl('individual', Validators.required),
    'site_id': new FormControl(null),
    'address': new FormGroup({})
  });

  /**
   * If the form has been submitted.
   *
   * @var {boolean}
   */
  bSubmitted: boolean = false;

  /**
   * If the current customer's address is already
   * an existing site.
   *
   * @var {boolean}
   */
  bHasExistingSite: boolean = false;

  /**
   * If the current customer is an organization.
   *
   * @var {boolean}
   */
  bIsOrganization: boolean = false;

  /**
   * Site search observable.
   *
   * @var {Relate<any>}
   */
  objSiteSearch: Observable<any> = Observable.of([]);

  /**
   * Customer search observable.
   *
   * @var {Relate<any>}
   */
  objCustomerSearch: Relate<any> = new Relate<any>();

  /**
   * List of subscriptions to destroy.
   *
   * @var {Subscription}
   */
  arSubscriptions: Subscription[] = [];

  /**
   * The address object of the preselected site.
   *
   * @type {Address}
   */
  objPreselectedSiteAddress: Address;

  /**
   * Changes the step from customer to location if no customer is selected.
   *
   * @var {boolean}
   */
  bNoCustomer: boolean = false;

  /**
   * Flag that checks if the job location is a custom address.
   *
   * @var {boolean}
   */
  bIsCustomLocation: boolean = false;

  /**
   * Variable to save the previous value, so we only
   * trigger when they are different.
   *
   * @var {Address}
   */
  bSitePrevious: Address = null;

  /**
   * Checks if we already skipped the form once.
   *
   * @var {boolean}
   */
  bSkipped: boolean = false;

  /**
   * Checks if an existing customer was selected through the name autocomplete.
   *
   * @var {boolean}
   */
  bHasSelectedExistingCustomer = false;

  constructor(
    private placesService: PlacesService,
    private notifService: NotificationService,
    private recordService: RecordService,
    private listService: ListingService,
    private searchService: SearchService,
    private localStorage: LocalStorageService,
    private sharedService: SharedService,
    private addressService: AddressService,
  ) {}

  ngOnInit() {
    if (!this.preselectedCustomer && !this.bOpenedFromMegaMenu && this.strRecordType === 'jobs') {
      this.bNoCustomer = true;
      this.toggleCustomerFieldsStatus(false);
    }

    this.setCustomerSearch();
    this.setOnOrganizationChange();
    this.form.patchValue({type: 'organization'})
  }

  ngAfterViewInit() {
    this.initializeAddressForm();

    if (this.preselectedCustomer) {
      this.updateCustomer(new GlobalRecord({
        id: this.preselectedCustomer,
        match_percentage: 100,
        module: 'customers'
      }));
    }

    if (this.preselectedSite) {
      this.updateSite(new GlobalRecord({
        id: this.preselectedSite,
        match_percentage: 100,
        module: 'sites'
      }));
    }
  }

  ngOnDestroy(): void {
    this.placesService.showPlaces();
    this.clear();
    this.bSkipped = false;
    this.arSubscriptions.forEach(subsribes => {
      subsribes.unsubscribe();
    })
  }

  /**
   * When the user clicks next.
   *
   * @param {boolean} bUseExistingSite
   */
  next(bCreateNewSite: boolean): void {

    if (bCreateNewSite) {
      this.form.controls.site_id.setValue(null);
    }

    this.addressComponent.parentHasSubmitted = true;
    this.form.markAsDirty();
    this.form.markAsTouched();
    this.bSubmitted = true;

    if (this.bNoCustomer) {
      this.form.patchValue({ customer_id: null });
    }

    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      let objFormValue: WizardCustomerData = this.form.value;
      this.objCustomerData.emit(objFormValue);
    }

  }

  /**
   * Mark field as dirty
   *
   * @returns {void}
   */
  markFormControlDirty(): void {
    this.form.controls['address'].markAsDirty();
  }

  /**
   * Gets the filter for the sites search
   *
   * @returns {LooseObject}
   */
  private getSitesFilter(): LooseObject {
    if (this.preselectedSite) {
      return { id: this.preselectedSite };
    }

    const strCustomerId = get(this.form, 'value.customer_id');

    if (strCustomerId) {
      return { customer_id: strCustomerId };
    }

    const objAddress = this.form.controls.address.value;

    return {
      "address": {
        "street": this.setFilters(objAddress.street),
        "country": this.setFilters(objAddress.country),
        "state": this.setFilters(objAddress.state),
        "city": this.setFilters(objAddress.city),
        "zip": this.setFilters(objAddress.zip)
      }
    }
  }

  /**
   * Gets the sites list
   *
   * @param {number} intPageNum
   *
   * @returns {void}
   */
  getSites(intPageNum: number = 1): void {
    this.form.controls.site_id.setValue(null);

    this.objSiteSearch = this.getSitesObservable(intPageNum).pipe(
      tap(result => {
        if (
          this.preselectedSite &&
          result['data'].findIndex(item => item.id == this.preselectedSite) > -1
        ) {

          this.form.controls.site_id.setValue(this.preselectedSite);
          if (this.preselectedSite && this.skipWhenPreselected === true && !this.bSkipped) {
            this.bSkipped = true;
            // FC-4005: if form is valid, we should automatically next the form
            if (this.form.valid || this.bNoCustomer) {
              this.next(false);
            }
          };

        } else {
          this.form.controls.site_id.setValue(null);
        }

        this.form.markAsDirty();
        this.bHasExistingSite = result['data'].length > 0;
      }),
      shareReplay()
    )
  }

  /**
   * Gets the sites search observable
   *
   * @param {number} intPageNum
   * @param {LooseObject} objFilter
   *
   * @returns {Observable<any>}
   */
  getSitesObservable(intPageNum: number = 1): Observable<any> {
    return this.listService.fetchDataAdvanceSearch(
      { pageNum: intPageNum },
      'sites',
      this.getSitesFilter(),
      { "id": "updated_at", "sort": "desc" },
      null,
      4
    );
  }

  /**
   * Update the current customer.
   *
   * @param {GlobalRecord} objGlobalRecord
   *
   * @returns {void}
   */
  updateCustomer(objGlobalRecord: GlobalRecord): void {
    this.recordService.getRecord(
      'customers',
      objGlobalRecord.id,
      true
    ).subscribe(data => {
      if (data['record_details']) {

        let strType = data['record_details']['type'];

        this.bHasSelectedExistingCustomer = true;
        this.form.controls.type.setValue(strType);
        this.form.controls.customer_id.setValue(objGlobalRecord.id);

        if (strType == 'individual') {
          this.form.controls.first_name.setValue(data['record_details']['first_name']);
          this.form.controls.last_name.setValue(data['record_details']['last_name']);
        }

        if (strType == 'organization') {
          this.form.controls.name.setValue(data['record_details']['name']);
        }

        // FC-3982: if we have preselected site, don't apply customer's address
        if (!this.preselectedSite) {
          let objAddress = new Address().setPropertiesToNull(data['record_details']['address']);
          let newAddress = this.addressService.setMissingStateAndCountryByClientDefault(objAddress);
          this.form.controls.address.patchValue(newAddress);

          this.addressComponent.setGeolocationComponentLatLng({
            latitude: objAddress.latitude,
            longitude: objAddress.longitude,
          });
        }

        this.form.markAsDirty();
      }
    });
  }

  /**
   * Update the current customer.
   *
   * @param {GlobalRecord} objGlobalRecord
   *
   * @returns {void}
   */
  updateSite(objGlobalRecord: GlobalRecord): void {
    this.recordService.getRecord(
      'sites',
      objGlobalRecord.id,
      true
    ).subscribe(data => {
      if (data['record_details']) {
        let objAddress = new Address().setPropertiesToNull(data['record_details']['address']);
        this.form.controls.address.patchValue(objAddress);

        this.form.markAsDirty();
      }
    });
  }

  /**
   * Clear the form and setting it to the
   * initial values.
   *
   * @returns {void}
   */
  clear(): void {
    this.form.reset();
    this.form.markAsPristine();
    this.form.controls.type.setValue('individual');
    this.preselectedCustomer = null;
    this.preselectedSite = null;
    this.objPreselectedSiteAddress = null;
    this.bNoCustomer = false;
    this.bIsCustomLocation = false;
    this.bHasExistingSite = false;
    this.objCustomerData.emit(null);
  }

  /**
   * Only visible in job wizard. Toggles the show customer flag.
   *
   * @returns {void}
   */
  toggleShowCustomer(change: MatCheckboxChange): void {
    this.bNoCustomer = change.checked;

    if (this.bNoCustomer) {
      this.toggleCustomerFieldsStatus(false);
    } else {
      this.toggleCustomerFieldsStatus(true);
    }

    this.changeStepToLocation.emit(this.bNoCustomer);

    this.form.markAsDirty();
  }

  /**
   * Only visible in job wizard. Toggles the show customer flag.
   *
   * @returns {void}
   */
  toggleCustomLocation(change: MatCheckboxChange): void {
    this.bIsCustomLocation = change.checked;
    this.customLocationEmitter.emit(this.bIsCustomLocation);
  }

  /**
   * Only visible in job wizard. Step is skippable if there is no customer.
   *
   * @returns {void}
   */
  skip(): void {
    this.notifService.sendConfirmation('skip_customer_confirm').subscribe(response => {
      if (response.answer === true) {
        this.bIsCustomLocation = false;
        this.objCustomerData.emit(null);
        this.customLocationEmitter.emit(false);
        this.skipStepEmitter.emit(true);
      }
    })
  }

  /**
   * Sets the site data for prefil on the next component.
   *
   * @param {LooseObject} objSite
   */
  setSiteData(objSite: LooseObject): void {
    this.sharedService.setSite(objSite);
  }

  /**
   * Set the logic when an organization is changed.
   *
   * @returns {void}
   */
  private setOnOrganizationChange(): void {

    this.arSubscriptions.push(
      this.form.controls.type.valueChanges.subscribe(change => {
        this.form.markAsPristine();
        let isOrganization: boolean = this.bIsOrganization = (change === 'organization');

        if (isOrganization) {
          this.form.controls.first_name.reset();
          this.form.controls.last_name.reset();
          this.form.controls.first_name.disable();
          this.form.controls.last_name.disable();
          this.form.controls.name.enable();
        } else {
          this.form.controls.name.reset();
          this.form.controls.name.disable();
          this.form.controls.first_name.enable();
          this.form.controls.last_name.enable();
        }
      }
    ));

    this.form.controls.type.valueChanges.pipe(
      take(1),
      // We have debounce so the dom has enough time to
      // refresh and we can get the now showing orgNameElementRef.
      delay(1),
    ).subscribe(() => {
        let objClient: Client = this.localStorage.getJsonItem('current_client');
        if (objClient && this.orgNameElementRef) {
          // FC-4207: focus on name field when opening wizard
          setTimeout(() => {
            this.orgNameElementRef.nativeElement.focus();
          }, 100);

          this.placesService.initializePlaces(this.orgNameElementRef, objClient.country || 'AU').subscribe(autocomplete => {
            autocomplete.addListener("place_changed", () => {
              this.placesService.ngZone.run(() => {
                let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                if (place === undefined || place.geometry === undefined || place.geometry === null) {
                  return;
                }
                this.form.controls.name.setValue(place.name);
                this.addressComponent.setFromAutoComplete(place);
              })
            });
          });
        }
    })
  }

  private toggleCustomerFieldsStatus(bEnable: boolean): void {
    if (!bEnable) {
      this.form.get('type').disable();
      this.form.get('first_name').disable();
      this.form.get('last_name').disable();
      this.form.get('name').disable();
    } else {
      this.form.get('type').enable();
      this.form.get('first_name').enable();
      this.form.get('last_name').enable();
      this.form.get('name').enable();
    }
  }

  /**
   * Set the customer search observables and loaders.
   *
   * @returns {void}
   */
  private setCustomerSearch(): void {
    this.objCustomerSearch.buildRelates(
      switchMap(term => {
        return this.searchService.global(term, 'customers').pipe(
          tap((results) => {
            if (results.length == 0) {
              this.placesService.showPlaces();
            } else {
              this.placesService.hidePlaces();
            }
          })
        );
      }),
    );

    this.arSubscriptions.push(
      Observable.merge(
        this.form.controls.first_name.valueChanges,
        this.form.controls.name.valueChanges
      ).subscribe(strTerm => {
        this.objCustomerSearch.typehead.next(strTerm);
      })
    );
  }

  /**
   * Initializes the address forms.
   *
   * @returns {void}
   */
  private initializeAddressForm(): void {
    this.buildAddressForm(new Address());
    this.form.controls.address = this.addressComponent.addressForm;
    this.arSubscriptions.push(
      this.form.controls.address.valueChanges.subscribe(() => {
        if ((this.form.controls.address.valid && this.form.controls.address.value != this.bSitePrevious) || this.bHasSelectedExistingCustomer) {
          this.bSitePrevious = this.form.controls.address.value;
          this.bHasExistingSite = false;
          this.getSites();
        }
      })
    );
  }

  /**
   * Builds the address form inside the address
   * component.
   *
   * @param {Address} objAddress
   */
  private buildAddressForm(objAddress: Address): void {
    this.addressComponent.buildForm(objAddress, 'add', 'recordview');
  }

  /**
   * Set the filters for sites ES.
   *
   * @param {any} value
   *
   * @returns {op: "eq", value: any}
   */
  private setFilters(value: any): {op: "eq", value: any} {
    return { op: "eq", value };
  }


}
