import { LooseObject } from "./loose-object";
import { Relate } from "./relate";

export interface ChatActionInterface {

  /**
   * The unique string identifier for a certain
   * action, for example, if we want to send
   * a work order, we use the string 'send_work_order'.
   *
   * @var {string}
   */
  id: string;

  /**
   * The icon we use for this action. For example
   * if were going to send a quote, we use a clipboard-list icon
   * as that is the icon we use for quotes.
   *
   * @var {string}
   */
  icon: string;

  /**
   * The module of the pdf we like to generate.
   * This is required by the pdf-preview lambda
   * function.
   *
   * @deprecated use pdf_options
   *
   * @var {string}
   */
  pdf_module?: string;

  /**
   * A simple flag for showing a loader.
   *
   * @var {boolean}
   */
  loader?: boolean;

  /**
   * The relate field of the action if
   * there should be any. An example of this
   * is the ability to send an invoice inside the
   * job module.
   *
   * @var {Relate<any, any>}
   */
  relate?: Relate<any>

  /**
   * Is the action a selection, meaning will we
   * use the relate property in this object.
   *
   * @var {boolean}
   */
  selection: boolean;

  /**
   * The file name of the pdf we will be generating.
   * Also required by our pdf preview lambda function.
   *
   * @var {string}
   */
  file_name?: string;

  /**
   * Pdf document options
   *
   * @type {PdfOptionsProps|undefined}
   */
  pdf_options?: PdfOptionsProps
}

interface PdfOptionsProps {
  module: string;
  document_type: string;
  filter?: LooseObject;
}

export class ChatAction implements ChatActionInterface {
  id: string;
  icon: string;
  pdf_module?: string;
  loader?: boolean;
  relate?: Relate<any>
  selection: boolean;
  file_name?: string;
  pdf_options?: PdfOptionsProps;

  constructor(properties: ChatActionInterface) {
    this.id = properties.id;
    this.icon = properties.icon;
    this.pdf_module = properties.pdf_module;
    this.loader = properties.loader || false;
    this.relate = properties.relate || null;
    this.selection = properties.selection || false;
    this.file_name = properties.file_name;
    this.pdf_options = properties.pdf_options;
  }
}
