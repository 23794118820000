import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  /**
   * Parses the given JSON string and returns the resulting object. If it isn't a
   * valid JSON string, the _default value will be returned.
   *
   * @param json
   * @param _default
   *
   * @returns {any}
   */
  parse(json: any, _default = null): any {
    try {
      return JSON.parse(json);
    } catch (e) {
      return _default;
    }
  }

  /**
   * Returns a JSON string created from the given object.
   *
   * @param obj
   *
   * @returns {string}
   */
  stringify(obj: any): string {
    return JSON.stringify(obj);
  }
}
