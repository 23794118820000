import { Component, Input } from "@angular/core";
import { ImporterService } from '../../../../services/importer/importer.service';
import { BrowserService } from '../../../../services/browser/browser.service';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'import-record-button',
  templateUrl: './buttons.component.html'
})
export class ButtonsComponent {
  @Input() record;

  constructor(
    private importerService: ImporterService,
    private browserService: BrowserService,
    private notificationService: NotificationService) {
    }

  /**
   * Executes the downloading of a resultset file based on the download type
   *
   * success - retrieves all the successfully imported rows
   * errors - retrieves all the rows which contain errors
   * @param downloadType
   *
   * @returns {void}
   */
  downloadResultSet(downloadType: 'success' | 'errors'): void {
    this.notificationService.notifySuccess('download_inprogress');
    this.importerService.downloadResultSet(this.record.id, downloadType).subscribe((response) => {
      this.browserService.forceDownload(response.body, `${downloadType}.csv`);
    });
  }
}
