import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  templateUrl: './modified-since.component.html',
  selector: 'accounting-common-forms-modified-since',
  styleUrls: ['./modified-since.component.scss'],
})
export class ModifiedSinceComponent {
  /**
   * The form group instance
   *
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({
    modifiedSince: new FormControl(null, [Validators.required])
  });

  constructor(
    protected dialog: MatDialogRef<ModifiedSinceComponent>
  ) {}

  /**
   * Handle user clicked on schedule button
   *
   * @returns {void}
   */
  onSchedule(): void {
    if (this.form.valid) {
      this.onCloseDialog(this.form.value);
    }
  }

  /**
   *
   */
  onCloseDialog(values: any|undefined = undefined): void {
    this.dialog.close(values);
  }
}

