
    <mat-slide-toggle
      [id]="id"
      [disabled]="isDisabled$ | async"
      class="font-size-11"
      (change)="onChange($event)"
      (blur)="onTouched()"
      [checked]="value"
    >
      <ng-content></ng-content>
    </mat-slide-toggle>
  