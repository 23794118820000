import { Directive, OnInit, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';

import { Client } from '../../objects/client';
import { ClientStoreService } from '../../services/client-store.service';

@Directive({
  selector: '[fcAccessLevelRequired]'
})
export class AccessLevelRequiredDirective implements OnInit {

  /**
   * The least allowed access level to access the content
   *
   * @type {String}
   */
  private accessLevelRequired: BehaviorSubject<Array<String>> = new BehaviorSubject<Array<String>>(undefined);

  /**
   * An observable that we'll use to detect changes to the given access level.
   *
   * @type {Observable<Array<String>>
   */
  protected accessLevelRequired$: Observable<Array<String>> = this.accessLevelRequired.asObservable();

  /**
   * This element's view state! The toggling of display for the element with this
   * directive shall be determined by the active client's access level.
   *
   * @type {Observable<[Client, Array<String>]>}
   */
  protected viewDisplayState: Observable<[Client, Array<String>]> = combineLatest([
    this.clientStoreService.getWhenClientIsSetEvent(),
    this.accessLevelRequired$
  ]);

  constructor(
    protected clientStoreService: ClientStoreService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  /**
   * Initialize a listener to our client object. This will let us "know"
   * whenever the current client changes. When that happens, we then
   * determine whether a given element should be displayed or not
   * based on their access level.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.viewDisplayState.subscribe(([activeClient, accessLevelRequired]) => {
      this.toggleViewBasedOnAccessLevelRequired(activeClient, accessLevelRequired)
    });
  }

  @Input()
  set fcAccessLevelRequired(access_level: Array<String>) {
    this.accessLevelRequired.next(access_level);
  }

  /**
   * Displays or hides a given element based on the level of the current client
   * that they are using.
   *
   * @param {Client} activeClient
   * @param {Array<String>} accessLevelRequired
   *
   * @returns {void}
   */
  private toggleViewBasedOnAccessLevelRequired(activeClient: Client, accessLevelRequired: Array<String>): void {
    accessLevelRequired.includes(activeClient.level) ? this.showView() : this.hideView();
  }

  /**
   * Shows the element to the user but only if isn't in the DOM yet.
   *
   * @returns {void}
   */
  private showView(): void {
    if (this.viewContainer.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  /**
   * Hides the element from the user.
   *
   * @returns {void}
   */
  private hideView(): void {
    this.viewContainer.clear();
  }
}
