import { dial_codes } from '../../assets/api/dial_codes.json';
export interface PhoneInterface {
  /**
   * What type of number is this.
   *
   * Can only be the given set of values.
   */
  type?: "home" | "mobile" | "work" | "others",

  /**
   * The phone prefix of the number
   * usually based on country.
   */
  code?: string,

  /**
   * The actual phone number.
   */
  number?: number,

  /**
   * This is just to cater the old structure.
   * But we can remove once all phone data
   * adheres to the new structure.
   *
   * Old structure: {"home": 09991112234}
   */
  home?: string,
  mobile?: string,
  work?: string,
  others?: string,
}

export class Phone {

  public type: string;
  public code: string;
  public number: number;

  constructor(
    properties: PhoneInterface = {}
  ){
    this.setPhoneObject(properties);
  }

  /**
   * If we want the Phone object we initialize
   * to have the country of the currently logged
   * in user.
   *
   * @param string
   * @return Phone
   */
  public setCurrentCountry(strCountryCode: string = null) {
    if (strCountryCode != null) {
      this.code = this.getCountryCodeList.find(item => (strCountryCode == item.code)).dial_code;
    } else {
      // Just in case they don't pass a country code.
      this.code = "+61";
    }
    return this;
  };

  /**
   * Manipulates incoming data and
   * sets the object properly.
   *
   * This should also cater the old values we have.
   * @param Phone
   */
  private setPhoneObject(objPhone: PhoneInterface) {

    let arObjectKeys = Object.keys(objPhone);

    if (arObjectKeys.length > 0) {
      Object.keys(objPhone).forEach(element => {
        if (this.getPhoneList.indexOf(element) > -1 && objPhone[element] != undefined) {
          this.type = element;
          // Since the old phones structure do not have country codes, we'll set it to +61 by default.
          this.code = "+61"
          // Let's strip any non-numeric values.
          let number = parseInt(objPhone[element]);
          // Make sure it's not a NaN value.
          if (!isNaN(number)) {
            this.number = number;
          }
        } else {
          this[element] = objPhone[element];
        }
      });
    } else {
      // Default values when creating a new Phone.
      this.type = "mobile";
      this.code = "+61";
    }
  }

  /**
   * Returns the icon name depending
   * on the phone type.
   *
   * @return string
   */
  get getPhoneIcon() {
    return {
      'home': 'home',
      'mobile': 'mobile-android-alt',
      'work': 'phone-office',
      'others': 'phone-plus',
    }[this.type];
  }

  /**
   * Returns the list of available types
   * we have for Phone.
   *
   * @return array
   */
  get getPhoneList() {
    return ['home', 'mobile', 'work', 'others'];
  }

  /**
   * Returns the list of phone codes
   * along with the country name
   * and country code.
   *
   * @return array
   */
  get getCountryCodeList() {
    return dial_codes;
  }
}
