import { Component, OnInit, ElementRef, Input, OnChanges, SimpleChanges, HostListener, ChangeDetectorRef } from '@angular/core';
import { pick, isEmpty, isNull, isUndefined } from 'lodash';
import moment from 'moment';
import { map, shareReplay, switchMap, take, filter } from 'rxjs/operators';
import { LooseObject } from '../../../../objects/loose-object';
import { Relate } from '../../../../objects/relate';
import { AdvanceSearchboxService } from '../../../../services/advance-searchbox.service';
import { SearchService } from '../../../../services/search.service';
import { CalendarService } from '../../services/calendar.service';
import { AdvanceSearchboxTemplate, StaticEnum } from '../../../../objects/advance-searchbox';
import { bool } from 'aws-sdk/clients/signer';
import { MatDialog } from '@angular/material';
import { SavefilterdialogComponent } from '../../../../shared/components/listing/savefilterdialog/savefilterdialog.component';
import { ListingService } from '../../../../services/listing.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../../../../services/notification.service';
import { Observable } from 'rxjs';
import { RecordService } from '../../../../services/record.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss'],
})
export class TaskFilterComponent implements OnInit, OnChanges {

  /**
   * Simple holder of the filters, only used
   * to manipulate model.
   *
   * @type {any}
   */
  public objAlwaysClear: any = null;

  /**
   * List of selected filters.
   *
   * @type {FilterField[]}
   */
  public arSelectedFilter: FilterField[] = [];

  /**
   * List of filters available.
   *
   * @type {FilterField[]}
   */
  @Input()
  public arFilters: FilterField[] = [];

  /**
   * List of available operators used for dates.
   *
   * @type {string[]}
   */
  public arOperators: {label: string, value: string}[] = Object.keys(this.arConstOperator).map(item => {
    return {
      label: this.arConstOperator[item],
      value: item
    }
  });

  /**
   * If the user is viewing the calendar coming from a job, this means
   * that we should only be viewing tasks under that job. This job id
   * is received via query string.
   *
   * @type {string|undefined}
   */
  public strViewingFromJobId: string|undefined;

  /**
   * If the user is viewing the calendar coming from an opportunity, this means
   * that we should only be viewing tasks under that opportunity. This opportunity id
   * is received via query string.
   *
   * @type {string|undefined}
   */
  public strViewingFromOpportunityId: string|undefined;

  /**
   * List of operators from the contstants.
   *
   * @return {LooseObject}
   */
  get arConstOperator(): LooseObject {
    return OPERATORS;
  }

  arSavedFilters: Observable<Object[]>;
  arSelectedSavedFilter: LooseObject = {};
  arModels: any;

  /**
   * Listens to any events supplied by the parent component.
   *
   * @var {Observable<boolean>}
   */
  @Input('objResetFilter') objResetFilter: Observable<boolean>;

  constructor(
    public elementRef: ElementRef,
    public advanceSearchboxService: AdvanceSearchboxService,
    protected searchService: SearchService,
    public calendarService: CalendarService,
    public dialog: MatDialog,
    public listService: ListingService,
    public notifService: NotificationService,
    public recordService: RecordService,
    private changeDetectorRef: ChangeDetectorRef,
    protected route: ActivatedRoute,
  ) {
    this.strViewingFromJobId = this.route.snapshot.queryParamMap.get('job_id');
    this.strViewingFromOpportunityId = this.route.snapshot.queryParamMap.get('opportunity_id');
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['arFilters']) {
      this.arModels = {
        filter_name: null
      };
      this.arSelectedFilter = [];
      this.arSelectedSavedFilter = {};

      // FC-3476: display only tasks from the job/quote when viewing calendar from job/opportunity
      if (this.strViewingFromJobId || this.strViewingFromOpportunityId) {
        let strModule: string = this.strViewingFromJobId ? 'jobs' : 'opportunities';
        let strModuleId: string = this.strViewingFromJobId ? 'job_id' : 'opportunity_id';
        let strModuleNumberKey: string = this.strViewingFromJobId ? 'job_number' : 'opportunity_number';
        let strViewingFromId: string = this.strViewingFromJobId ? this.strViewingFromJobId : this.strViewingFromOpportunityId;
        let objFilter: FilterField = this.arFilters.find(objFilter => objFilter.module === 'activities' && objFilter.model === strModuleId);

        if (objFilter) {
          this.recordService.getRecord(strModule, strViewingFromId).pipe(
            filter(res => !isEmpty(res['record_details'])),
            map(res => Number(res['record_details'][strModuleNumberKey]))
          ).subscribe(numJobNum => {
            objFilter.value = {
              operator: "eq",
              value: [{
                id: strViewingFromId,
                name: numJobNum.toString()
              }]
            }
            this.addToFilter(objFilter);
            this.setItem();
          });
        }
      }
    }
  }

  ngOnInit(): void {
    this.arSavedFilters = this.advanceSearchboxService.getFilters('calendar');
    this.arModels = {
      filter_name: null
    };

    this.objResetFilter.subscribe(() => {
      this.strViewingFromJobId = undefined;
      this.strViewingFromOpportunityId = undefined;
      this.clearAllFilters();
    });
  }

  triggerBlur(event) {
    event.target.blur();
  }

  selectFromFilterList(filterName: string, type: 'saved' | 'default'): void {
    this.clearAllFilters(false);
    this.arModels['filter_name'] = filterName;
    this.arSavedFilters.pipe(take(1), shareReplay()).subscribe(filters => {
      this.arSelectedFilter = [];
      this.arSelectedSavedFilter = filters[type][filterName];
      const fields = filters[type][filterName].config.filter_structure;
      if (fields.length > 0) {
        fields.forEach(field => {
          let objNewFilter: FilterField = new FilterField(field.type, field.model, field.label, field.module, field.placeholder, field.source);
          objNewFilter.value = field.value;
          this.addToFilter(objNewFilter);
        });
        this.setItem();

        this.showTaskFilterContainer(false);
      }
    });
  }

  /**
   * Expand or collapse if value is set to true or false respectively
   *
   * @param   {bool}  shown
   */
  showTaskFilterContainer(shown: bool) {
    let taskFilterContainer = document.getElementById('taskFilterBody');
    if (taskFilterContainer) {
      if (shown) taskFilterContainer.classList.add('show');
      else taskFilterContainer.classList.remove('show');
    }
  }

   /**
   * Remove item.
   */
  deleteSavedFilter(filter: LooseObject) {
    // Pop-up modal for confirmation
    this.notifService.sendConfirmation('filter_delete_confirm').subscribe(confirmation => {
      // If the user confirm to delete
      if (confirmation.answer) {
        this.recordService.deleteFilter('calendar', filter['id']).subscribe(response => {
          const strResponse: any = response['body'];
          this.notifService.notifySuccess(strResponse);
          this.arSavedFilters = this.advanceSearchboxService.getFilters('calendar');
          if (this.arModels['filter_name'] === filter['name']) {
            this.clearAllFilters();
          }
        });
      }
    });
  }

  /**
   * Set the filter item and then inform the
   * task list to reload the list with the
   * selected filter.
   *
   * @param {FilterField} objFilter
   * @param {RelateData} objFilterValue
   *
   * @returns {void}
   */
  public setItem(): void {
    this.calendarService.reloadSchedulableTasks.next(
      this.toAdvanceSearchFilter(this.arSelectedFilter)
    );
  }

  /**
   * Set operator.
   *
   * @param {string} strOperator
   * @param {FilterField} objFilter
   *
   * @returns {void}
   */
  setOperator(strOperator: string, objFilter: FilterField): void {
    (objFilter.value as WithOperatorValue).operator = strOperator;
    this.setItem();
  }

  /**
   * Delete the filter item.
   *
   * @param {number} numFilterIndex
   *
   * @returns {void}
   */
  deleteItem(numFilterSelectedIndex: number): void {
    let objFilter = this.arSelectedFilter.splice(numFilterSelectedIndex, 1)[0];
    this.setItem();
    this.toggleOptionDisable(objFilter, false);
  }

  /**
   * Add the chosed filter to the
   * selcted filter list.
   *
   * @param {FilterField} objFilter
   *
   * @returns {void}
   */
  addToFilter(objFilter: FilterField): void {

    this.toggleOptionDisable(objFilter, true);

    if (objFilter.elem_type == 'relate') {
      objFilter.relate = this.getRelate(objFilter.asTemplate);
    }

    this.arSelectedFilter.push(objFilter);
    this.changeDetectorRef.detectChanges();
    this.showTaskFilterContainer(true);
  }

  /**
   * This method will disable options that are already
   * included in the selected filters.
   *
   * @param {FilterField} objFilter
   * @param {boolean} bToggle
   *
   * @returns {void}
   */
  toggleOptionDisable(objFilter: FilterField, bToggle: boolean): void{
    let numFilterIndex = this.arFilters.findIndex(filter => filter.module == objFilter.module && filter.label == objFilter.label);
    if (numFilterIndex > -1) {
      this.arFilters[numFilterIndex].disabled = bToggle;
      if (bToggle === false) {
        // Reset the value to initial value if remove this filter is removed
        this.arFilters[numFilterIndex].value = typeof(this.arFilters[numFilterIndex].initialValue) === 'object' ?
          Object.assign({}, this.arFilters[numFilterIndex].initialValue) :
          this.arFilters[numFilterIndex].initialValue;
      }
      this.arFilters = [...this.arFilters];
    }
  }

  /**
   * Simple clears the ng-select filter.
   *
   * @returns {void}
   */
  clearHolder(): void {
    this.objAlwaysClear = [];
  }

  /**
   * Clear specified filters and deselect current filter
   *
   * @return  {void}    Clear all selected filters
   */
  clearAllFilters(triggerFilter: bool = true): void {
    this.arModels = {
      filter_name: null
    };
    this.arSelectedFilter = [];
    this.arSelectedSavedFilter = {};
    this.arFilters.forEach((objFilter) => {
      this.toggleOptionDisable(objFilter, false);
    });

    if (triggerFilter) {
      this.setItem();
    }
  }

  /**
   * Retrieves the relate observables.
   *
   * @param {AdvanceSearchboxTemplate} asTemplate
   *
   * @returns {Relate<any>}
   */
  private getRelate(asTemplate: AdvanceSearchboxTemplate): Relate<any> {
    return new Relate<any>().buildRelates(
      switchMap(strTerm => this.searchService.getRemoteData(asTemplate, strTerm).pipe(
        map(item => {
          return item.response;
        })
      )
    ));
  }

  /**
   * Convert this modules filter to Advance Search Filter
   *
   * @param   {FilterField[]}  filters
   *
   * @return  {object}
   */
  private toAdvanceSearchFilter(filters: FilterField[]): object
  {
    let asFilter = {
      'activities': {},
      'customers': {},
      'sites': {},
      'jobs': {},
      'opportunities': {}
    };
    filters.forEach(filter => {
      switch(filter.elem_type) {
        case 'numeric':
          if (isUndefined((filter.value as WithOperatorValue).value)) {
            break;
          }
          asFilter[filter.module][filter.model] = {
            op: (filter.value as WithOperatorValue).operator,
            value: (filter.value as WithOperatorValue).value,
          };
          break;
        case 'dropdown':
          // Multiselect dropdown
          if (filter.has_operator) {
            asFilter[filter.module][filter.model] = [{
              op: (filter.value as WithOperatorValue).operator,
              value: ((filter.value as WithOperatorValue).value as string[]).map(x => {
                return {
                  label: x,
                  value: x,
                };
              }),
            }];
          } else {
            // Single select dropdown (Use as substitute to checkbox)
            asFilter[filter.module][filter.model] = [{
              label: filter.value,
              value: filter.value,
            }];
          }

          break;
        case 'relate':
          asFilter[filter.module][filter.model] = [{
            op: (filter.value as WithOperatorValue).operator,
            value: ((filter.value as WithOperatorValue).value as string[]),
          }];
          break;
        case 'date':
          if (isEmpty(filter.value) || isNull(filter.value)) {
            break;
          }
          const greaterThanOrEqualTo = {
            op: "ge",
            value: typeof filter.value[0] === 'string' ? filter.value[0] : (filter.value[0] as moment.Moment).format(filter.placeholder),
          };
          const lessThanOrEqualTo = {
            op: "le",
            value: typeof filter.value[1] === 'string' ? filter.value[1] : (filter.value[1] as moment.Moment).format(filter.placeholder),
          }
          // Filter as Date Range
          asFilter[filter.module][filter.model] = [
            greaterThanOrEqualTo,
            lessThanOrEqualTo
          ];
          break;
        case 'input':
          if (isEmpty((filter.value as WithOperatorValue).value)) {
            break;
          }
          asFilter[filter.module][filter.model] = {
            op: (filter.value as WithOperatorValue).operator,
            value: (filter.value as WithOperatorValue).value,
          };
          break;
      }
    });

    // Fix for date filters not properly initialize when filter comes from saved filter
    this.arSelectedFilter = filters.map(filter => {
      if (filter.elem_type === 'date' && !isEmpty(filter.value) && !isNull(filter.value)) {
        filter.value[0] = typeof filter.value[0] !== 'string' ? filter.value[0] : moment(filter.value[0]);
        filter.value[1] = typeof filter.value[1] !== 'string' ? filter.value[1] : moment(filter.value[1]);
      }
      return filter;
    });
    return asFilter;
  }

  saveCurrentFilter() {
    let tabDialogRef = this.dialog.open(SavefilterdialogComponent, {
      width: '500px',
      height: 'auto',
      data: {
        arLabels: [],
        arRelate: [],
        arFilterOption: [],
        arSelectedFilter: this.arSelectedSavedFilter,
        objFilterBody: {
          default_filter: '',
          hasFilter: this.arSelectedSavedFilter ? true : false,
          share: this.arSelectedSavedFilter.is_shared !== undefined ? this.arSelectedSavedFilter.is_shared : false,
          order_by: [], // Ignore as order is hardcoded on backend
          filter_name: this.arSelectedSavedFilter.name !== undefined ? this.arSelectedSavedFilter.name : '',
        }
      }
    });

    tabDialogRef.afterClosed().subscribe(
      saveFilterResult => {
        // Check if it is not empty or null
        if (saveFilterResult) {
          if (saveFilterResult && saveFilterResult['filter_body']['filter_name'] != undefined) {
            saveFilterResult['filter_body']['filter_name'] = saveFilterResult['filter_name'];
          }
          // Check if user has current selected filter
          if (this.arSelectedSavedFilter) {
            // Include the id on the request
            saveFilterResult['id'] = this.arSelectedSavedFilter['id'];
          }
          // Save current filter specified on the advance searchbox
          saveFilterResult['filter_body']['filter_structure'] = this.arSelectedFilter.map(x => {
            if (x.elem_type === 'date') {
              x.value = (x.value as moment.Moment[]).map(date => {
                return date.format(x.placeholder);
              });
            }
            return pick(x, [
              'type',
              'model',
              'label',
              'module',
              'placeholder',
              'source',
              'value'
            ])
          });

          // filter name and filter body is in saveFilterResult.
          this.listService.saveCreatedFilter('calendar', JSON.stringify(saveFilterResult)).subscribe(
            data => {
              this.arSavedFilters = this.advanceSearchboxService.getFilters('calendar');
              this.selectFromFilterList(data['filter_name'], 'saved');
              this.notifService.notifySuccess("filter_successfully_saved");
          }, (err: HttpErrorResponse) => {
            this.notifService.notifyError(err.error.message);
          });
        }
      }
    );
  }

}

interface OwlDateChangeEventDateRangePayload {
  soruce: any;
  value: Array<moment.Moment>;
  input: HTMLInputElement;
}


const OPERATORS = {
  "eq": "=",
  "neq": "!=",
  "gt": ">",
  "lt": "<",
  "gte": "≥",
  "lte": "≤"
};

export type FilterTypes = 'checkbox' | 'dropdown' | 'multiselect' | 'text' | 'textarea' | 'url' | 'uuid' | 'date' | 'datetime' | 'currency' | 'number' | 'relate' | 'relate_multiple';

export interface DropdownSource {
  id: number | string | boolean;
  name: string;
}

export interface WithOperatorValue {
  operator: string;
  value: string | number | string[] | number[] | object[];
}

export class FilterField {
  type: FilterTypes;
  model: string;
  label: string;
  module: string;
  asTemplate: AdvanceSearchboxTemplate;
  placeholder: string;
  source: StaticEnum[] | string;

  relate: Relate<any> | null;
  value: bool | string | string[] | Array<moment.Moment> | WithOperatorValue;
  initialValue: bool | string | string[] | Array<moment.Moment> | WithOperatorValue;

  disabled: boolean = false;

  elem_type: 'input' | 'dropdown' | 'date' | 'relate' | 'numeric' = 'input';

  multiple: boolean = false;
  dropdown_source: DropdownSource[];
  has_operator: boolean = false;
  allowed_operators = [];

  format: string = null;
  decimals: number = 0;

  constructor(strType: FilterTypes, strModel: string, strLabel: string, strModule: string, asTemplate: AdvanceSearchboxTemplate, strPlaceholder?: string, source?: StaticEnum[] | string) {
    this.type = strType;
    this.model = strModel;
    this.label = strLabel;
    this.module = strModule;
    this.asTemplate = asTemplate;
    this.placeholder = strPlaceholder == undefined ? '' : strPlaceholder;
    this.source = source == undefined ? null : source;

    this.value = null;
    this.initialValue = null;
    this.relate = null;

    if (source && Array.isArray(source)) {
      this.dropdown_source = (source as StaticEnum[]).map(x => {
        return {
          id: x.value,
          name: x.label
        };
      });
    }

    switch (strType) {
      case 'checkbox':
        this.elem_type = 'dropdown';
        break;
      case 'dropdown':
      case 'multiselect':
        this.elem_type = 'dropdown';
        this.has_operator = true;
        this.allowed_operators = ['=', '!='];
        this.multiple = true;

        this.value = {
          operator: 'eq',
          value: null,
        };
        this.initialValue = {
          operator: 'eq',
          value: null,
        };
        break;
      case 'text':
      case 'textarea':
      case 'url':
        this.has_operator = true;
        this.allowed_operators = ['=', '!='];

        this.value = {
          operator: 'eq',
          value: null,
        };
        this.initialValue = {
          operator: 'eq',
          value: null,
        };
        break;

      case 'date':
      case 'datetime':
        this.elem_type = 'date';
        break;

      case 'uuid':
        this.elem_type = 'relate';
        this.has_operator = true;
        this.multiple = true;
        this.allowed_operators = ['=', '!='];
        this.source = source;

        this.value = {
          operator: 'eq',
          value: null,
        };
        this.initialValue = {
          operator: 'eq',
          value: null,
        };
        break;

      case 'currency':
        this.elem_type = 'numeric';
        this.has_operator = true;
        this.allowed_operators = ['=', '!=', '<', '>', '<=', '>='];

        this.value = {
          operator: 'eq',
          value: null,
        };
        this.initialValue = {
          operator: 'eq',
          value: null,
        };

        this.format = '0,0.000';
        this.decimals = 3;
        break;

      case 'number':
        this.elem_type = 'numeric';
        this.has_operator = true;
        this.allowed_operators = ['=', '!=', '<', '>', '<=', '>='];

        this.value = {
          operator: 'eq',
          value: null,
        };
        this.initialValue = {
          operator: 'eq',
          value: null,
        };

        this.format = '0,0';
        this.decimals = 0;
        break;
    }
  }

  /**
   * Only show the allowed filter operator for the specific field
   *
   * @param   {Array<label>}              allOperators
   * @param   {string}                    label
   * @param   {string<allowedOperators>}  value
   * @param   {string[]<label>}           allowedOperators
   *
   * @return  {string}
   */
  filterOperators(allOperators: Array<{label: string, value: string}>, allowedOperators: string[]): Array<{label: string, value: string}> {
    return allOperators.filter(x => {
      return allowedOperators.includes(x.label);
    });
  }

  /**
   * Update filterfields model value
   * On input fields. On blur events happens before the ngModelChanges. Always setting the filter value to null
   * This fixes the issue by updating the model value even before the on blur events occured
   *
   * @param   {any}          $event
   *
   * @return  {void}
   */
  updateValues($event: any): void {
    if (this.has_operator) {
      (this.value as WithOperatorValue).value = $event;
    } else {
      this.value = $event;
    }
  }
}
