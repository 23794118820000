
<header>
  <div class="d-flex">
    <h3 class="title">
      <fa-icon
        [icon]="['far', 'file-alt']"
        class="mr-1">
      </fa-icon>{{ 'template_name'| translate }}
    </h3>

    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button
            data-testid="cancel_btn"
            class="btn btn-primary mr-1"
            type="button"
            (click)="onCancel()"
            [disabled]="inProgress">
            <fa-icon
              [icon]="['fas', 'times']"
              class="mr-1">
            </fa-icon>{{ 'cancel' | translate }}
          </button>
          <button
            data-testid="save_btn"
            class="btn btn-primary"
            type="button"
            (click)="formRef.ngSubmit.emit()"
            [disabled]="inProgress">
            <button-icon-loader
              [defaultIcon]="['fas', 'save']"
              [isLoading]="inProgress"
              class="mr-1">
            </button-icon-loader>{{ 'save' | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>

<mat-dialog-content>
  <form
    [formGroup]="form"
    #formRef="ngForm"
    (ngSubmit)="onSave()">
    <div class="row">
      <div class="col-12">
        <input
          data-testid="template_name"
          formControlName="name"
          type="text"
          class="form-control"
          maxlength="128"
          data-testid="email_template_name"/>
      </div>
    </div>
  </form>
</mat-dialog-content>