import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogButtonAction } from './../../../../objects/dialog-button-action';
import { DialogButtonConfig } from './../../../../objects/dialog-button-config';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {

  /**
   * Displayed icon beside the title
   */
  @Input('icon') icon: IconProp;

  /**
   * Displayed title
   */
  @Input('label') title: string;

  /**
   * list of button config
   */
  @Input('buttons') buttons: DialogButtonConfig[];

  /**
   * to emit the data in parent component
   */
  @Output() onChange = new EventEmitter<DialogButtonAction>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * this will emit the data
   * when there is changes made
   *
   * @param event
   */
  ngOnChangeButton(event): void {
    this.onChange.next(event);
  }
}
