import { Component, Input } from '@angular/core';
import { ProfitCalculation } from '../../../../module/jobs/profitability-analysis/profit';

@Component({
  selector: 'app-list-view-profit-analysis',
  templateUrl: './profit-analysis.component.html',
})
export class ListViewProfitAnalysisComponent {
  /**
   * @type {RecordWithProfitCalculation}
   */
  @Input('record') readonly record: RecordWithProfitCalculation;
}

export interface RecordWithProfitCalculation {
  actual_profit: ProfitCalculation;
  estimated_profit: ProfitCalculation;
}