import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { STARTER_PLAN } from '../../../objects/subscription-plans';
import { Viewable } from '../../../objects/component-viewing-widget/viewable';
import { ResultSetListComponent } from './widget-components/result-set-list/result-set-list.component';
import { ImportedRecordsComponent } from './widget-components/imported-records/imported-records.component';

@Component({
  selector: 'app-import-record',
  templateUrl: './import-record.component.html',
  styleUrls: ['./import-record.component.scss']
})
export class ImportRecordComponent implements OnInit {

  @ViewChild('appViewForm') appViewForm: ElementRef;

  /**
   * The widget tabs we're going to display in our widget section.
   *
   * @type {Viewable[]}
   */
  widgetTabs: Viewable[] = [
    new Viewable(ImportedRecordsComponent, 'imported-records', { icon: ['far', 'cabinet-filing'], isActive: true, toolTipText: 'imported_records', minSubscriptionPlanRequired: STARTER_PLAN }),
    new Viewable(ResultSetListComponent, 'files', { icon: ['far', 'file-alt'], isActive: false, toolTipText: 'files', minSubscriptionPlanRequired: STARTER_PLAN }),
  ];

  /**
   * The data that will be passed onto ComponentViewingWidgetComponent
   *
   * @type {any}
   */
  trickleDownData: any;

  public objRecordDetails = {};

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.objRecordDetails = {
        id: params.id,
        module: 'imports',
        label_id: 'module',
        button: [
          { type: 'back', link: '/admin/import', class: 'btn-link mr-2', label: 'back_to_list', icon: 'arrow-left' },
        ]
      };
    });
  }

  ngOnInit() { }

  /**
   * Gets triggered when the view service completes the fetching
   * of the actual record that's being displayed.
   *
   * @param event
   *
   * @returns {void}
   */
  onRecordFetchCompletion(event): void {
    this.trickleDownData = event;
  }

}
