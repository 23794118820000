import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormService } from '../../../../../services/form.service';
import { RecordService } from '../../../../../services/record.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Notification } from '../../../../../objects/notification';
import { CustomTranslateService } from '../../../../../services/custom-translate.service';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-recurring-jobs-history',
  templateUrl: './recurring-jobs-history.component.html',
  styleUrls: ['./recurring-jobs-history.component.scss']
})
export class RecurringJobsHistoryComponent implements OnInit {
	public strRecurringJobId: string = (this.data.recurring_job_id != undefined && this.data.recurring_job_id != null && this.data.recurring_job_id != '') ? this.data.recurring_job_id : null;
	public arJobs: any = [];
	public bIsLoaded: boolean = false;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<RecurringJobsHistoryComponent>,
		private dialog: MatDialog,
		public formService: FormService,
		public recordService: RecordService,
		public notifService: NotificationService,
		public customTranslate: CustomTranslateService
	) { }

	ngOnInit() {
		let objFilter = {
			'jobs' : { recurring_job_id: this.strRecurringJobId}
		}

		let arOrderClause = { id : 'date_completed', sort: 'desc'};
		/**
		 * Get multiple module relate record
		 * @param strModule - request module to get config
		 * @param strRecordIds - request record id of main table
		 * @param arFilter - request filter
		 * @param arOrderClause - request order clause   Format: ['id' => 'column', 'sort' => 'desc']
		 * @param intLimit - request limit
		 * @param arWhereIn - request to find in array   
		 * @param arWhereNotIn - request to find in array
		 */
		this.recordService.getMultipleModuleRelateRecord('jobs', false, objFilter, arOrderClause, 20).subscribe( response => {
			this.arJobs = response['jobs'];
			this.bIsLoaded = true;
		});
	}

  	/**
	 * Close the current dialog.
	 */
	cancelDialog() {
    	this.dialogRef.close();
	  }
	  
	/**
	 * add leading zero for number
	 * @param num 
	 * @param size 
	 */
	pad(num:number, size:number): string {
		let s = num+"";
		while (s.length < size) s = "0" + s;
		return s;
	}

	/**
	 * Let's format the datetime value.
	 * @param date 
	 */
	formatDate(strDate) {

		// Convert datetime to utc
		let utcTime = moment.utc(strDate).toDate();
		// Convert to local time zone and display
		return moment(utcTime).local().format('lll');
	}

}
