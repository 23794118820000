import { ValidatorFn, AbstractControl } from '@angular/forms';

/**
 * validates an amount based on a given precision configuration [0 => total, 1 => scale]
 *
 * @param {number[]} precision
 */
export function amountValidator(precision: number[]): ValidatorFn {
  return (control: AbstractControl): {[ key: string ]: any } | null => {
    let [ total, scale ] = precision;
    let charLength = total - scale;

    // if we have a char length of 0 or less we only accept value that is less than zero
    if (charLength <= 0) {
      var amountRegex: RegExp = new RegExp(`^[0]{1}(\\.[0-9]{0,${scale}})?$`);
    } else {
      var amountRegex: RegExp = new RegExp(`^[0-9]{1,${charLength}}(\\.[0-9]{0,${scale}})?$`);
    }

    return (amountRegex.test(control.value)) ? null : { 'invalidAmount': { value: control.value } };
  }
}
