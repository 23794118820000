
<!--This component is growing, so let's move the dashboard side navigation to it's own component.-->
<dashboard-aside></dashboard-aside>

<!-- START: Main -->
<main class="main" role="main">

  <header class="header align-items-center justify-content-between px-3 white-space-nowrap hide-menu">
    <ul class="nav align-items-center d-lg-none">
      <li class="nav-item">
        <a class="nav-link px-1 text-secondary" href="javascript:;" (click)="layoutDashboardService.toggleAside()">
          <fa-icon [icon]="['fas', 'bars']" size="lg"></fa-icon>
        </a>
      </li>
    </ul>

    <a class="navbar-brand mx-3 d-lg-none" href="#">
      <img *ngIf="!isInternetExplorer6to11" src="./assets/img/fieldmagic-logo.svg" class="fm-logo">
      <img *ngIf="isInternetExplorer6to11" src="./assets/img/fmlogo.png" class="fm-logo">
    </a>

    <form class="form-search" autocomplete="off"
      [formGroup]="searchForm"
      [ngClass]="{'form-search-show': layoutDashboardService.showHeaderSearchForm}">
      <div class="module-selection-container">
        <ng-select
          id="selectModule"
          appendTo="body"
          bindLabel="text"
          bindValue="id"
          class="module-selection select-site-theme"
          formControlName="module"
          [items]="arSearchableModules"
          [searchable]="false"
          [clearable]="false">
          <ng-template ng-option-tmp let-item="item">
            {{ item | translate }}
          </ng-template>

          <ng-template ng-label-tmp let-item="item">
              {{ item | translate }}
          </ng-template>
        </ng-select>
      </div>
      <div class="custom-input-icon w-100">
        <span class="form-icon">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </span>
        <span class="form-loading-delete" *ngIf="searchForm.get('search').value != ''">
          <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="bLoadingIcon"></fa-icon>
          <fa-icon [icon]="['fas', 'times']" *ngIf="!bLoadingIcon" (click)="emptySearch()"></fa-icon>
        </span>
        <input
          autocomplete="off"
          formControlName="search"
          class="form-control search-text"
          id="search"
          name="search"
          placeholder="{{ 'search' | translate }}"
          matInput [matAutocomplete]="auto">
          <fa-icon [icon]="['fas','times']" (click)="layoutDashboardService.showedHeaderSearchForm()" class="form-close-button d-lg-none"></fa-icon>
      </div>
      <fa-icon [icon]="['fas','times']" (click)="layoutDashboardService.showedHeaderSearchForm()" class="close-button d-lg-none"></fa-icon>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let results of arSearch | async" class="search-class" [value]="results.name" (onSelectionChange)="onSubmit(results)">
          <div class="row">
            <div class="col-12 result-name" matTooltip="{{ formatName(results.name, results.module) }}">{{ formatName(results.name, results.module) }}</div>
            <div class="col-6 result-module">{{ results.module | translate }}</div>
            <div class="col-6 result-module"><span class="float-right">{{ 'match_score' | translate }}: {{ results.match_percentage | number:'1.0-0' }}%</span></div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </form>

    <ul class="nav align-items-center push-right main-menu">
      <li *ngIf="loading" class="nav-item d-none d-lg-flex">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true" class="mr-1"></fa-icon>
        <span class="font-weight-light">{{ 'loading' | translate }}</span>
      </li>

      <!-- Live Support Menu -->
      <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'">
        <li class="nav-item d-none d-lg-flex" *ngIf="(this.layoutDashboardService.acsStatus | async)">
          <span class="nav-link">
            <a id="topMenuOmnichannel" href="javascript:;" (click)="toggleSupportWindow()">
              <fa-icon [icon]="['fas', 'phone']" size="lg" rotate="90" [ngClass]="{
                'active-live-support': bAmazonConnectStreamInitialized
              }"></fa-icon> {{ 'omnichannel' | translate }}
            </a>
          </span>
        </li>
      </ng-container>
      <!-- /end Live Support Menu -->

      <li class="nav-item menu-items">
        <ng-container *fcHasPermission="'job_scheduler'">
          <a id="topMenuCalendar" [routerLink]="['/calendar']" routerLinkActive="active">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'calendar-alt']" size="lg"></fa-icon> {{ 'job_scheduler' | translate }}
          </a>
        </ng-container>
      </li>

      <notification-header></notification-header>

      <li class="nav-item menu-items">
        <a id="topMenuActivities" [routerLink]="['/activities']" routerLinkActive="active">
          <fa-icon id='navActivities' [fixedWidth]="true" [icon]="['fas', 'calendar-week']" size="lg"></fa-icon> {{ 'activities' | translate }}
        </a>
      </li>

      <div class="dropdown">
        <li class="nav-item dropdown menu-items">
          <span class="nav-link">
            <a id="topMenuLanguage" class="pointer" (mouseover)="mouseover()" class="dropdown-toggle">
              <fa-icon id='navLanguage' [icon]="['fas', 'language']" size="lg"></fa-icon> {{ strCurrentLocale | uppercase }}
            </a>
          </span>
        </li>
        <div class="dropdown-content" *ngIf="boolClosed">
            <a id="topMenuLanguageEnglish" (click)="switchLanguage('en')" class="pointer prompt-text">{{ 'language.en' | translate }}</a>
            <hr class="separator">
            <a id="topMenuLanguageSpanish" (click)="switchLanguage('es')" class="pointer prompt-text">{{ 'language.es' | translate }}</a>
            <hr class="separator">
            <a id="topMenuLanguageTagalog" (click)="switchLanguage('fil')" class="pointer prompt-text">{{ 'language.fil' | translate }}</a>
        </div>
      </div>

      <li class="nav-item d-lg-none search-button">
        <span href="javascript:;" (click)="layoutDashboardService.showedHeaderSearchForm()">
          <fa-icon [icon]="['fas', 'search']" size="lg"></fa-icon>
        </span>
      </li>

      <li class="nav-item dropdown account-head">
        <a class="nav-link dropdown-toggle" href="#" id="navbarProfile" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img *ngIf="strUserImage" [src]="strUserImage" class="img-fluid img-custom online">
          <span *ngIf="!strUserImage" style="font-size: 1.4rem">
            <fa-icon [icon]="['fas', 'user']" size="lg"></fa-icon>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarProfile">
          <a href="#" id="topMenuClientSelection" (click)="switchClient()" class="dropdown-item">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'exchange']"></fa-icon> {{ 'account_selection' | translate }}
          </a>
          <a id="topMenuHelp" href="http://help.fieldmagic.co" target="_blank" routerLinkActive="active" class="dropdown-item">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'question']"></fa-icon> {{ 'help' | translate }}
          </a>
          <a href="#" id="topMenuAdmin" [routerLink]="['/admin']" routerLinkActive="active" class="dropdown-item" *fcAccessLevelRequired="['admin']">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'cog']"></fa-icon> {{ 'settings' | translate }}
          </a>
          <a id="topMenuClientInfo" class="dropdown-item pointer" (click)="openClientInfo()">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'info-circle']"></fa-icon> {{ 'client_info' | translate }}
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" id="topMenuLogout" [routerLink]="['/account/dologout']" routerLinkActive="active" class="dropdown-item">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'sign-out-alt']"></fa-icon> {{ 'logout' | translate }}
          </a>
        </div>
      </li>
    </ul>
  </header>

  <!-- Alternate Header -->
  <header class="header align-items-center justify-content-between px-3 white-space-nowrap hide-menu-small">
    <ul class="nav align-items-center d-lg-none justify-content-between">
      <li class="nav-item">
        <a class="nav-link px-1 text-secondary" href="javascript:;" (click)="layoutDashboardService.toggleAside()">
          <fa-icon [icon]="['fas', 'bars']" size="lg" class="burger-icon"></fa-icon>
        </a>
      </li>
    </ul>
    <a class="navbar-brand mx-3 d-lg-none" href="#">
      <img *ngIf="!isInternetExplorer6to11" src="./assets/img/fieldmagic-logo.svg" class="fm-logo">
      <img *ngIf="isInternetExplorer6to11" src="./assets/img/fmlogo.png" class="fm-logo">
    </a>
    <form class="form-search form-min-width" autocomplete="off"
      [formGroup]="searchForm"
      [ngClass]="{'form-search-show': layoutDashboardService.showHeaderSearchForm}">
      <div class="module-selection-container">
        <ng-select
          id="selectModule"
          appendTo="body"
          bindLabel="text"
          bindValue="id"
          class="module-selection select-site-theme"
          formControlName="module"
          [items]="arSearchableModules"
          [searchable]="false"
          [clearable]="false">
          <ng-template ng-option-tmp let-item="item">
            {{ item | translate }}
          </ng-template>

          <ng-template ng-label-tmp let-item="item">
              {{ item | translate }}
          </ng-template>
        </ng-select>
      </div>
      <div class="custom-input-icon w-100">
        <span class="form-icon">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </span>
        <span class="form-loading-delete" *ngIf="searchForm.get('search').value != ''">
          <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="bLoadingIcon"></fa-icon>
          <fa-icon [icon]="['fas', 'times']" *ngIf="!bLoadingIcon" (click)="emptySearch()"></fa-icon>
        </span>
        <input
          autocomplete="off"
          formControlName="search"
          class="form-control search-text"
          id="search"
          name="search"
          placeholder="{{ 'search' | translate }}"
          matInput [matAutocomplete]="auto">
          <fa-icon [icon]="['fas','times']" (click)="layoutDashboardService.showedHeaderSearchForm()" class="form-close-button d-lg-none"></fa-icon>
      </div>
      <fa-icon [icon]="['fas','times']" (click)="layoutDashboardService.showedHeaderSearchForm()" class="close-button d-lg-none"></fa-icon>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let results of arSearch | async" class="search-class" [value]="results.name" (onSelectionChange)="onSubmit(results)">
          <div class="row">
            <div class="col-12 result-name" matTooltip="{{ formatName(results.name, results.module) }}">{{ formatName(results.name, results.module, results) }}</div>
            <div class="col-6 result-module">{{ results.module | translate }}</div>
            <div class="col-6 result-module"><span class="float-right">{{ 'match_score' | translate }}: {{ results.match_percentage | number:'1.0-0' }}%</span></div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </form>
    <ul class="nav align-items-center main-menu push-right">
      <li *ngIf="loading" class="nav-item d-none d-lg-flex">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true" class="mr-1"></fa-icon>
        <span class="font-weight-light">{{ 'loading' | translate }}</span>
      </li>
      <!-- Live Support Menu -->
      <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'">
        <li class="nav-item d-none d-lg-flex" *ngIf="(this.layoutDashboardService.acsStatus | async)">
          <span class="nav-link">
            <a id="topMenuOmnichannel" href="javascript:;" (click)="toggleSupportWindow()">
              <fa-icon [icon]="['fas', 'phone']" size="lg" rotate="90" [ngClass]="{
                'active-live-support': bAmazonConnectStreamInitialized
              }"></fa-icon> {{ 'omnichannel' | translate }}
            </a>
          </span>
        </li>
        <li class="nav-item d-lg-flex" *ngIf="(this.layoutDashboardService.acsStatus | async)">
          <span class="nav-link d-xl-none d-lg-none">
            <a id="topMenuOmnichannelNoLabel" href="javascript:;" (click)="toggleSupportWindow()">
              <fa-icon [icon]="['fas', 'phone']" size="lg" rotate="90" [ngClass]="{
                'active-live-support': bAmazonConnectStreamInitialized
              }"></fa-icon>
            </a>
          </span>
        </li>
      </ng-container>
      <!-- /end Live Support Menu -->
      <notification-header></notification-header>

      <div class="dropdown">
        <li class="nav-item dropdown d-lg-flex menu-items">
          <span class="nav-link">
            <a id="topMenuLanguage" class="pointer" (mouseover)="mouseover()" class="dropdown-toggle">
              <fa-icon id='navLanguage' [icon]="['fas', 'language']" size="lg"></fa-icon> {{ strCurrentLocale | uppercase }}
            </a>
          </span>
        </li>
        <div class="dropdown-content" *ngIf="boolClosed">
            <a id="topMenuLanguageEnglish" (click)="switchLanguage('en')" class="pointer prompt-text">{{ 'language.en' | translate }}</a>
            <hr class="separator">
            <a id="topMenuLanguageSpanish" (click)="switchLanguage('es')" class="pointer prompt-text">{{ 'language.es' | translate }}</a>
            <hr class="separator">
            <a id="topMenuLanguageTagalog" (click)="switchLanguage('fil')" class="pointer prompt-text">{{ 'language.fil' | translate }}</a>
        </div>
      </div>

      <li class="nav-item dropdown account-head">
        <a class="nav-link" href="#" id="dropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <fa-icon id='menuDropdown' [icon]="['fas', 'ellipsis-v']" size="lg"></fa-icon>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarProfile">
          <ng-container *fcHasPermission="'job_scheduler'">
            <a id="topMenuCalendar" [routerLink]="['/calendar']" routerLinkActive="active" class="dropdown-item">
              <fa-icon [fixedWidth]="true" [icon]="['fas', 'calendar-alt']" size="lg"></fa-icon> {{ 'job_scheduler' | translate }}
            </a>
          </ng-container>
          <a id="topMenuActivities" [routerLink]="['/activities']" routerLinkActive="active" class="dropdown-item">
            <fa-icon id='navActivities' [fixedWidth]="true" [icon]="['fas', 'calendar-week']" size="lg"></fa-icon> {{ 'activities' | translate }}
          </a>
        </div>
      </li>

      <li class="nav-item d-lg-none search-button">
        <span href="javascript:;" (click)="layoutDashboardService.showedHeaderSearchForm()">
          <fa-icon [icon]="['fas', 'search']" size="lg"></fa-icon>
        </span>
      </li>

      <li class="nav-item dropdown account-head">
        <a class="nav-link dropdown-toggle" href="#" id="navbarProfile" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img *ngIf="strUserImage" [src]="strUserImage" class="img-fluid img-custom online">
          <span *ngIf="!strUserImage" style="font-size: 1.4rem">
            <fa-icon [icon]="['fas', 'user']" size="lg"></fa-icon>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarProfile">
          <a href="#" id="topMenuClientSelection" (click)="switchClient()" class="dropdown-item">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'exchange']"></fa-icon> {{ 'account_selection' | translate }}
          </a>
          <a id="topMenuHelp" href="http://help.fieldmagic.co" target="_blank" routerLinkActive="active" class="dropdown-item">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'question']"></fa-icon> {{ 'help' | translate }}
          </a>
          <a href="#" id="topMenuAdmin" [routerLink]="['/admin']" routerLinkActive="active" class="dropdown-item" *fcAccessLevelRequired="['admin']">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'cog']"></fa-icon> {{ 'settings' | translate }}
          </a>
          <a href="#" id="topMenuClientInfo" [routerLink]="" class="dropdown-item" (click)="openClientInfo()">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'info-circle']"></fa-icon> {{ 'client_info' | translate }}
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" id="topMenuLogout" [routerLink]="['/account/dologout']" routerLinkActive="active" class="dropdown-item">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'sign-out-alt']"></fa-icon> {{ 'logout' | translate }}
          </a>
        </div>
      </li>
    </ul>
  </header>

  <ng-container *ngIf="(sub$ | async) as sub">
    <small
      *ngIf="sub.show_global_sub_update_button === true
        && sub.subscription_info
        && sub.subscription_info.status === 'in_trial'
        && sub.customer_info.card_status === 'no_card'"

        class="d-flex justify-content-between align-items-center subscription-selection-container p-1">
      <span class="ml-2">
        <fa-icon [icon]="['fas', 'info-circle']" size="lg" class="mr-2"></fa-icon>
      <strong>{{ 'days_left_until_trial_expires' | translate:{ duration: clientStoreService.getActiveClient().subscription_trial_end | local | cbHumanizedTrialDuration } }}</strong>
        &nbsp;
        <a href="javascript:void(0)" (click)="openSubscriptionSelectionPage()">{{ 'click_here_to_update_your_subscription' | translate }}.</a>
      </span>
      <fa-icon [icon]="['fas', 'times']" class="mr-2 hide-global-subscription-update-link" (click)="hideGlobalSubscriptionUpdateLink()"></fa-icon>
    </small>
  </ng-container>

  <integrations-accounting-systems-notification-no-installed-driver></integrations-accounting-systems-notification-no-installed-driver>

  <div class="content">
    <router-outlet></router-outlet>
  </div>

</main>
<!--// END: Main -->

<!-- START: AWS Connect -->
<div class="live-support-container" *fcMinSubscriptionPlanRequired="'fieldmagic-enterprise'" [ngClass]="{
  'live-support-container-hide': !bAmazonConnectStreamInitialized
}">
  <div class="acs-topbar">
    <span class="acs-topbar-text">{{ 'omnichannel' | translate }}</span>
    <a href="javascript:;" class="goto-support-page mr-3" (click)="gotoSupportPage()" [ngClass]="{
      'hide': layoutDashboardService.bSupportWindowMinimize || (!layoutDashboardService.bCallInit && !layoutDashboardService.bAfterCall) || !layoutDashboardService.objSelectedRecord
    }">
      <fa-icon class="text-white" [icon]="['fas', 'user']"></fa-icon>
    </a>
    <a href="javascript:;" (click)="layoutDashboardService.toggleSupportWindowMinimize()">
      <fa-icon class="text-white" [icon]="layoutDashboardService.bSupportWindowMinimize ? ['fas', 'arrow-alt-up'] : ['fas', 'arrow-alt-down']"></fa-icon>
    </a>
  </div>
  <div class="support-summary" [ngClass]="{
    'hide': layoutDashboardService.bSupportWindowMinimize || (!layoutDashboardService.bCallInit && !layoutDashboardService.bAfterCall) || !layoutDashboardService.objSelectedRecord
  }">
    <p style="text-align: center;">{{ 'call_summary' | translate }}</p>
    <div>
      <label class="labels" for="call-summary">{{ 'call_notes' | translate }}</label>
      <textarea #strCallNotes name="call-summary" class="form-control font-size-11" rows="7" style="max-height: 11vh;"></textarea>
    </div>
    <button [disabled]="!layoutDashboardService.bAfterCall" type="button" class="btn btn-success btn-lg mt-2 w-100" (click)="layoutDashboardService.logCallEvent(strCallNotes.value)">
      <span class="px-4 py-2 h5">{{ 'save' | translate }}</span>
    </button>
  </div>
  <div #acsContainer class="acs-container" [ngClass]="{
    'hide': layoutDashboardService.bSupportWindowMinimize
  }"></div>
</div>
<!--// END: AWS Connect -->

<!-- START: Backdrop -->
<div class="backdrop" (click)="layoutDashboardService.toggleAside()" [ngClass]="{
  'backdrop-show': layoutDashboardService.showedAside
}"></div>
<!--// END: Backdrop -->
