import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatStepper } from '@angular/material';
import { WizardService } from '../../services/wizard.service';
import { QuoteWizardData, WizardContactData, WizardCustomerData, WizardJobData, WizardQuoteData } from '../../objects/wizard';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../services/notification.service';
import { LayoutDashboardService } from '../../../../shared/layouts/layout-dashboard/layout-dashboard.service';
import { finalize, switchMap } from 'rxjs/operators';
import { forkJoin, of, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WizardStep, WizardStepper } from '../../objects/wizard-stepper';
import { CustomerComponent } from '../steps/customer/customer.component';
import { get, isEmpty } from 'lodash';

@Component({
  selector: 'app-create-quote-wizard',
  templateUrl: './create-quote-wizard.component.html',
  styleUrls: ['./create-quote-wizard.component.scss']
})
export class CreateQuoteWizardComponent implements OnInit {

  /**
   * The customer step.
   *
   * @var {CustomerComponent}
   */
  @ViewChild(CustomerComponent) customer: CustomerComponent;

  /**
   * The stepper initial settings. You can also update the
   * stepper through here but do note that it will have a delay as
   * change detection of child components take a while to reflect.
   *
   * @var {WizardStepper}
   */
   public wizardStepper: WizardStepper = new WizardStepper(
    new WizardStep({title: 'customer', icon: 'users', optional: false, editable: true, completed: false}),
    new WizardStep({title: 'contact', icon: 'address-book', optional: true, editable: true, completed: false}),
    new WizardStep({title: 'opportunity', icon: 'list-ul', optional: false, editable: true, completed: false}),
    new WizardStep({title: 'summary', icon: 'book', optional: false, editable: false, completed: false})
  )

  /**
   * The wizards data.
   *
   * @var {QuoteWizardData}
   */
  public objWizard: QuoteWizardData = {
    contact: null,
    customer: null,
    quote: null
  };

  /**
   * The id of the customer if it already exists.
   *
   * @var {string}
   */
  public strCustomerId?: string;

  /**
   * The id of the site if it already exists.
   *
   * @var {string}
   */
  public strSiteId?: string;

  /**
   * If the saving of the forms is still loading.
   *
   * @var {boolean}
   */
  public bLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreateQuoteWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private notifService: NotificationService,
    private layoutDashboardService: LayoutDashboardService,
    public wizardService: WizardService,
    private router: Router
  ) {
    this.dialogData = this.wizardService.checkPreselectedData(layoutDashboardService, dialogData);
  }

  ngOnInit() {}

  /**
   * Get the updates from the contact form
   * and save it to this component.
   *
   * @param {WizardContactData} objContactData
   *
   * @returns {void}
   */
  captureContactData(objContactData: WizardContactData, stepper: MatStepper): void {

    this.objWizard.contact = objContactData;

    if (objContactData != null) {
      stepper.steps.find(item => item.state == 'step2').completed = true;
      stepper.next();
    }

  }

  /**
   * Get updates from the customer form, and inform this
   * component of the data.
   *
   * @param {WizardCustomerData} objCustomerData
   * @param {MatStepper} objStepper
   *
   * @returns {void}
   */
  captureCustomerData(objCustomerData: WizardCustomerData, objStepper: MatStepper): void {
    this.objWizard.customer = objCustomerData;
    this.strCustomerId = null;
    this.strSiteId = null;

    if (objCustomerData != null) {

      if (objCustomerData['customer_id']) {
        this.strCustomerId = objCustomerData['customer_id'];
      }

      if (objCustomerData['site_id']) {
        this.strSiteId = objCustomerData['site_id'];
      }

      objStepper.steps.first.completed = true;
      objStepper.next();
    }

  }

  /**
   * Capture the quote data from the quote step component.
   *
   * @param {WizardQuoteData} objQuoteData
   * @param {MatStepper} objStepper
   *
   * @returns {void}
   */
  captureQuoteData(objQuoteData: WizardQuoteData, objStepper: MatStepper): void {
    this.objWizard.quote = objQuoteData;

    if (objQuoteData != null) {
      objStepper.steps.find(item => item.state == 'step3').completed = true;
      objStepper.next();
    }

  }

  /**
   * Actions to perform if the customer step is skipped.
   *
   * @param {boolean} bFlag
   * @param {MatStepper} stepper
   *
   * @returns {void}
   */
  skipCustomerStep(bFlag: boolean, stepper: MatStepper): void {
    if (bFlag) {
      stepper.steps.first.completed = true;
      stepper.next();
    }
  }

  /**
   * Actions to perform if the contact step is skipped.
   *
   * @param {boolean} bFlag
   * @param {MatStepper} stepper
   *
   * @returns {void}
   */
  skipContactStep(bFlag: boolean, stepper: MatStepper): void {
    if (bFlag) {
      stepper.steps.find(item => item.state == 'step2').completed = true;
      stepper.next();
    }
  }

  /**
   * Create the quote along with the customer and contact details.
   *
   * @returns {void}
   */
  create(): void{
    this.bLoading = true;
    this.wizardService.saveQuoteDetails(this.objWizard).pipe(
      switchMap((quoteDetailsRes) => {
        let obs: Observable<{ answer: boolean }> = get(this.dialogData, 'creating_from_widget')
          ? this.notifService.sendConfirmation('redirect_to_new_quote')
          : of({ answer: true });

        return forkJoin([
          obs,
          of(quoteDetailsRes)
        ]);
      })
    ).subscribe(([dialogRes, quoteDetailsRes]) => {
      this.bLoading = false;
      this.notifService.notifySuccess('save_record_success');
      this.dialogRef.close('save');
      if (get(dialogRes, 'answer') === true) {
        this.layoutDashboardService.strActiveParent = 'sales';
        this.router.navigate([`/opportunities/${quoteDetailsRes.body.record_id}`], {queryParams: {from: 'create_quote'}});
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 400 || error.status == 422) {
        this.bLoading = false;
        let errorIds = Object.keys(error.error);
        if (errorIds.length && errorIds[0]) {
          this.notifService.notifyWarning(error.error[errorIds[0]][0]);
        } else {
          this.notifService.notifyWarning('record_invalid_parameters');
        }
      }
    });
  }

  /**
   * Test if the first step, which is the customer, is dirty.
   *
   * @returns {boolean}
   */
  isDirty(): boolean {
    return this.customer.form.dirty
  }

}
