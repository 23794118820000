import { Component, OnInit, HostListener } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';

import { DocumentService } from '../../../services/document.service';
import { FileService } from '../../../services/file/file.service';
import { LambdaService } from '../../../services/lambda.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-upload-document-dialog',
  templateUrl: './upload-document-dialog.component.html',
  styleUrls: ['./upload-document-dialog.component.scss']
})
export class UploadDocumentDialogComponent implements OnInit {

    public isFileDropped: boolean = false;
    public isFileUploaded: boolean = false;
    public isSaveDisabled = false;
    public arFiles : any = [];
    public strDocumentName : string;
    public bEdited: boolean = false;

    @HostListener('window:keyup.esc') onKeyUp() {
      this.cancelDialog();
    }

    constructor(
      public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
      public fileService: FileService,
      public documentService: DocumentService,
      public lambdaService: LambdaService,
      private notifService: NotificationService
    ) { }

    ngOnInit() {
        this.dialogRef.backdropClick().subscribe(_ => {
            this.cancelDialog();
        });
    }

    /**
     * Closes the dialog
     */
    cancelDialog() {
        if (this.bEdited) {
            // Pop-up modal for confirmation
            this.notifService.sendConfirmation('confirm_cancel')
                .filter(confirmation => confirmation.answer === true)
                .subscribe(() => {
                    this.dialogRef.close();
                });
        } else {
            this.dialogRef.close();
        }
    }

    /**
     *
     * Triggers when saving a document, passes value of the document to the main component for saving
     *
     * @param objData - the document that is dropped
     */
    onSubmit() {

        // Check if theres document name and if the file is set and is uploaded successfully
        if (this.strDocumentName && this.arFiles.name !== undefined) {

            // Disabled save button.
            this.isSaveDisabled = true;

            // Prepare initial Lambda request
            let arInitialRequest = {
                document_id : this.arFiles.upload_name.split('/').pop(),
                field_checker : true
            };

            // Check if uploaded file has form fields available
            this.lambdaService.mergeFormFields(JSON.stringify(arInitialRequest))
            .subscribe(
                data => {

                    // Prepare API request
                    let arRequest = {
                        document_name : this.strDocumentName,
                        document : this.arFiles,
                        is_library_document : true,
                        available_fields :  {}
                    }

                    // If there are no available fields
                    if (data['available_fields'] != undefined) {
                        // If there are merge-able fields attach it
                        arRequest.available_fields = data['available_fields']
                    }

                    // Add the document
                    this.documentService.addDocument(JSON.stringify([arRequest]))
                        .subscribe(
                        data => {
                            // Enable save button.
                            this.isSaveDisabled = false;
                            this.notifService.notifySuccess('file_create_success');
                            this.dialogRef.close({data : data});
                        }
                    );

                }
            );


        }
    }

     /**
     *
     * Triggered when a document is dropped from the file input
     *
     * @param objData - the document that is dropped
     */
    onFileChange(objData) {

        let reader = new FileReader();
        // If file is valid
        if(objData.target.files && objData.target.files.length) {

            const [file] = objData.target.files;
            reader.readAsDataURL(file);

            let strFileExtension = file.name.split('.').pop();

            // Check if file is pdf
            if(strFileExtension === "pdf") {
                // if file size is less than 30mb
                if(file.size/1024/1024 < 30) {

                    reader.onload = () => {
                        this.isFileDropped = true;

                        this.fileService.upload(file).subscribe((response) => {
                            let objFile = this.fileService.objFile;
                            this.isFileDropped = false;
                            this.isFileUploaded = true;
                            this.arFiles = {
                                'name': objFile.name,
                                'size': objFile.size / 1024,
                                'type': objFile.type,
                                'upload_name' : response['filename']
                            };
                        });
                    };
                } else {
                    this.notifService.notifyWarning('file_size_limit');
                }
            } else {

                this.notifService.notifyWarning('pdf_only');
            }
        }
    }

    /**
     * Mark as edited
     *
     * @returns {void}
     */
    markAsEdited(): void {
        this.bEdited = true;
    }
}
