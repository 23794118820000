import { Component, Inject, OnInit } from '@angular/core';
import { LooseObject } from '../../../../../objects/loose-object';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RecordService } from '../../../../../services/record.service';
import { ListingService } from '../../../../../services/listing.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { NotificationService } from '../../../../../services/notification.service';
import { QuillModules } from 'ngx-quill';

@Component({
  selector: 'manage-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.scss']
})
export class SignaturesComponent implements OnInit {

  /**
   * The configuration for the modules to show in the
   * quill editor we use.
   *
   * @var {QuillModules}
   */
  quillConfig: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['code-block'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean'],
      ['link']
    ]
  }

  /**
   * The formats we use for the quill.
   *
   * @var {string[]}
   */
  public arFormats: string[] = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'
  ];

  /**
   * To identify if the selected signature is for editing
   * or already is existing.
   *
   * @var {boolean}
   */
  bIsEdit: boolean = false;

  /**
   * The currently selected signature's id.
   *
   * @var {string}
   */
  strSelectedId: string = null;

  /**
   * The user id that have these signatures.
   *
   * @var {string}
   */
  strUserId: string = null;

  /**
   * The list of signatures to manage.
   *
   * @var {LooseObject}
   */
  arSignatures: LooseObject = [];

  /**
   * The default signature.
   *
   * @var {string}
   */
  strDefaultSignature: string = null;

  /**
   * The form that holds the elements of the signature.
   *
   * @var {FormGroup}
   */
  objFormSignature = new FormGroup({
    'name': new FormControl(null, Validators.required),
    'signature': new FormControl(null),
    'user_id': new FormControl(null)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SignaturesComponent>,
    private recordService: RecordService,
    private listService: ListingService,
    private localStorageService: LocalStorageService,
    private configService: ConfigurationService,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.strUserId = this.localStorageService.getItem('user_id');
    this.strDefaultSignature = this.localStorageService.getJsonItem('current_client')['default_signature'];
    this.objFormSignature.patchValue({'user_id': this.strUserId});
    this.getList();
  }

  /**
   * Get the list of signatures.
   *
   * @returns {void}
   */
  getList() {
    this.listService.fetchData(null, 'email_signatures', JSON.stringify({user_id: this.strUserId})).subscribe(response => {
      this.arSignatures = response['data'];
    });
  }

  /**
   * When a signature is being saved or created.
   *
   * @param {boolean} bSaveAsNew
   */
  save(bSaveAsNew: boolean = false) {

    this.objFormSignature.controls.name.markAsDirty();

    let strSelectedId = (!bSaveAsNew && this.strSelectedId != null) ? this.strSelectedId : null;

    if (this.objFormSignature.valid) {
      this.recordService.saveRecord(
        'email_signatures',
        this.objFormSignature.value,
        strSelectedId
      ).subscribe(objSaveRecordResponse => {
        this.notify.notifySuccess('success');

        if (this.arSignatures.length < 1) {
          this.notify.sendConfirmation('first_signature_info').subscribe(response => {
            if (response.answer) {
              this.selectSignature(objSaveRecordResponse['body'], true);
              this.setAsDefault(true, true);
            } else {
              this.selectSignature(objSaveRecordResponse['body'], true);
              this.getList();
            }
          })
        } else {

          if (bSaveAsNew) {
            this.selectSignature(objSaveRecordResponse['body'], true);
          }

          this.getList();
        }

      });
    }


  }


  /**
   * Selects one of the signature being displayed on the list.
   *
   * @param {LooseObject} objItem
   */
  selectSignature(objItem: LooseObject, bDontSetValue: boolean = false) {
    this.bIsEdit = true;
    this.strSelectedId = objItem['id'];

    if (!bDontSetValue) {
      this.objFormSignature.setValue({
        name: objItem['name'],
        signature: objItem['signature'],
        user_id: objItem['user_id']
      });
    }

  }

  /**
   * Cancels the dialog.
   *
   * @returns {void}
   */
  cancelDialog() {
    this.dialogRef.close('cancel');
  }

  /**
   * Sets the selected signature as the default signature for this
   * access level.
   *
   * @param {boolean} bSetDefault
   */
  setAsDefault(bSetDefault: boolean, bGetListAfter: boolean = false) {

    let strSignature = bSetDefault ? this.strSelectedId : null;

    this.recordService.saveRecord('users', {
      default_signature: strSignature
    }, this.strUserId).subscribe(response => {
      this.notify.notifySuccess('success');

      if (bSetDefault) {
        this.strDefaultSignature = strSignature;
        this.localStorageService.saveValueInCurrentClient(['default_signature'], [strSignature]);
      } else {
        this.strDefaultSignature = null;
        this.localStorageService.saveValueInCurrentClient([], [], ['default_signature']);
      }

      if (bGetListAfter) {
        this.getList();
      }
    })
  }

  /**
   * Send this signature to the email component.
   *
   * @returns {void}
   */
  use() {
    this.dialogRef.close(this.objFormSignature.controls['signature'].value);
  }

  /**
   * Deletes the selected signature.
   *
   * @returns {void}
   */
  trash() {
    this.recordService.deleteRecord(
      'email_signatures',
      this.strSelectedId
    ).subscribe(() => {
      this.notify.notifySuccess('success');
      this.objFormSignature.reset();
      this.objFormSignature.patchValue({'user_id': this.strUserId});
      this.strSelectedId = null;
      this.bIsEdit = false;
      this.getList();
    });
  }

}
