import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InformationService {

  private obvRecordDetails = new Subject<boolean>();
  obvRecordDetails$ = this.obvRecordDetails.asObservable();

  constructor() { }

  /**
   * Set object with template of
   * {
   *     header: 'information',                     // header name, accept html code, no tanslate
   *     header_class: 'bg-primary',                // header css
   *     content: '<h1>Hello User!<h1>',            // content, accept html codes, no tanslate
   *     content_class: 'text-success text-center'  // content css
   * }
   * 
   * @param arInfoData
   */
  setRecordDetails(arInfoData: any) {
    this.obvRecordDetails.next(arInfoData);
  }
}
