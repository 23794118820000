import { Observable, Subscription } from "rxjs";
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GeneratePDFComponent } from "../../generate-pdf/generate-pdf.component";
import { SelectTemplate } from '../../../../../../objects/select-template';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'form-real-time-pdf-preview',
  templateUrl: './preview.component.html',
})
export class PreviewComponent extends GeneratePDFComponent {

  @Input('disabled') isDisabled: boolean = false;
  @Input('trigger-event') triggerEvent: Observable<SelectTemplate>;

  @Output('onComplete') $onComplete = new EventEmitter<boolean>();

  private subsciptions: Subscription[] = [];

  ngOnInit(): void {
    super.ngOnInit();

    this.setTriggerEvent();

    this.subsciptions.push(
      this.isInProgress$.pipe(filter((isInprogress) => !isInprogress)).subscribe(() => this.$onComplete.emit(true)),
    );
  }

  protected setTriggerEvent(): void {
    if (this.triggerEvent) {
      this.subsciptions.push(
        this.triggerEvent.pipe(
          filter((data) => !!data['document_type'] && !!data['data'])
        ).subscribe((data) => {
          this.moduleId = data['id'];
          this.filter = data['filter'];
          this.moduleName = data['module'];
          this.documentType = data['document_type'];

          this.setCustomModuleData(data['data']);
          this.previewTemplate();
        }),
      );
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subsciptions) {
      subscription.unsubscribe();
    }
  }
}
