
import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is used for adding -- if the value is empty.
 *
 * To use this pipe, simply add the 'placeholdWithString' word just like using a normal pipe.
 *
 * Example:
 * <span> {{ strSomeString | placeholdWithString }}</span>
 */

@Pipe({ name: 'placeholdWithString' })
export class PlaceholdWithStringPipe implements PipeTransform {

  /**
   * Replaces the target string with the supplied placeholder
   * string if the string is null, undefined or blank.
   *
   * @param {String} target
   * @param {String} placeholder
   *
   * @returns {String}
   */
  transform(target: string | null | undefined,  placeholder: string = '--'): string {
    return target == undefined || target == null || (typeof target == 'string' && target.trim() == '') ? placeholder : target;
  }
}
