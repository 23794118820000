<ng-container *fcHasPermission="'stock_management'; else notPermitted">
  <section>
    <div class="row">
      <div class="col-xl-12">
        <app-information strType="listing" strModule="leads"
          [isPanel1Visible]= "false"
          [isPanel2Visible]= "false"
          [isPanel3Visible]= "false"
          [reloadRecordMetric]="reloadRecordMetric">
        </app-information>
      </div>
      <div class="col-xl-12">
        <app-listing (evtListingDialog)="evtListingDialogHandler($event)" strModule="stocktakes" [objTableHeader]="objTableHeaderData"></app-listing>
      </div>
    </div>
  </section>
</ng-container>

<ng-template #notPermitted>
  <span>{{ 'forbidden_error' | translate }}</span>
</ng-template>