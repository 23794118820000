<header>
  <div class="d-flex">
    <!-- title -->
    <h3 class="title" data-testid="dialog_title">
      <fa-icon [icon]="['fas', 'file-alt']" class="mr-3"></fa-icon>
      {{ 'logs' | translate }}
    </h3>

    <!-- actions -->
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <!-- cancel -->
        <li class="list-inline-item">
          <button
            data-testid="btn_cancel"
            type="button"
            class="btn ml-1 btn-primary"
            (click)="doCancel()"
            [disabled]="loading$ | async"
            id="btnCancel"
          >
            <fa-icon [icon]="['fas', 'times']" class="pr-1"></fa-icon>
            {{ 'cancel' | translate }}
          </button>
        </li>
        <!-- /cancel -->
      </ul>
    </div>
  </div>
</header>

<hr class="separator"/>

<mat-dialog-content class="mobile-view-dialog">
  <div class="row">
    <div class="col-12 text-center" *ngIf="! wasInitialized; else content">
      <inline-view-loader [isLoading]="true" [hasText]="true" class="text-center" role="progressbar"></inline-view-loader>
    </div>
    <ng-template #content>
      <div class="col-12" id="requestLogs">
        <ng-container *ngFor="let objContent of (contents$ | async); let intIndex = index">
          <div class="mb-2 card" id="requestLog_{{ intIndex }}">
            <div class="card-header">
              <a
                data-toggle="collapse"
                data-parent="#requestLogs"
                href="#requestLog_{{ intIndex }}_content"
                role="text"
                id="logTitle"
              >
                [{{ objContent.timestamp | format: "YYYY-MM-DD\\THH:mm:ss.SSSZ" }}] {{ objContent.method }} {{ objContent.url }} ({{ objContent.status }})
              </a>
            </div>
            <div id="requestLog_{{ intIndex }}_content" class="card-body collapse in">
              <div class="panel-body">
                <h6 id="requestTitle" role="heading">{{ 'request' | translate }}</h6>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="request_method">{{ 'request_method' | translate }}</label>
                    <input [value]="objContent.method" type="text" class="form-control" disabled>
                  </div>
                  <div class="form-group col-6">
                    <label for="request_url">{{ 'request_url' | translate }}</label>
                    <input [value]="objContent.url" type="text" class="form-control" disabled>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="request_headers">{{ 'request_headers' | translate }}</label>
                    <textarea class="form-control" disabled rows="5">{{ objContent.requestHeaders }}</textarea>
                  </div>
                  <div class="form-group col-6">
                    <label for="request_body">{{ 'request_body' | translate }}</label>
                    <textarea class="form-control" disabled rows="5">{{ objContent.requestBody }}</textarea>
                  </div>
                </div>
                <h6 id="responseTitle" role="heading">{{ 'response' | translate }}</h6>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="response_status">{{ 'response_status' | translate }}</label>
                    <input [value]="objContent.status" type="text" class="form-control" disabled>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="response_headers">{{ 'response_headers' | translate }}</label>
                    <textarea class="form-control" disabled rows="5">{{ objContent.responseHeaders }}</textarea>
                  </div>
                  <div class="form-group col-6">
                    <label for="response_body">{{ 'response_body' | translate }}</label>
                    <textarea class="form-control" disabled rows="5">{{ objContent.responseBody }}</textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-12 text-center" *ngIf="!! nextToken">
        <button
          role="button"
          id="loadMore"
          class="btn btn-link"
          (click)="onNext()"
          [disabled]="loading$ | async"
        >
          <inline-view-loader [isLoading]="(loading$ | async)" [hasText]="false"></inline-view-loader>
          {{ 'load_more' | translate }}
        </button>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>