import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RecordDetails } from '../../../../../objects/record-details';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  public test: RecordDetails
  public objRecordDetails: RecordDetails;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.objRecordDetails = {
        id: params['id'],
        module: 'stocktakes',
        label_id: 'warehouse_text',
        button: [
          { type: 'back', link: '/stock_levels/stocktake/history', class: 'btn-link mr-2', label: 'back_to_list', icon: 'arrow-left' },
        ]
      };
    });
  }

  ngOnInit() {
  }
}
