import { Component, Input } from '@angular/core';

@Component({
  selector: 'required-tag',
  template: `
    <ng-content></ng-content>
    <span
      class="text-danger"
      [ngClass]="{'pl-1': hasSpace}"
      data-testid="lbl_required_tag"
      id="lblRequiredTag"
      [innerText]="asterisk"
    ></span>
  `,
})

export class RequiredTagComponent  {
  /**
   * By default, this component has a space, so just
   * use the following if you need space:
   *
   * <required-tag></required-tag>
   *
   * and this one if no space:
   *
   * <required-tag [hasSpace]="false"></required-tag>
   */
  @Input('hasSpace') hasSpace: boolean = true;

  /**
   * Astersik content
   *
   * @type {string}
   * @readonly
   */
  readonly asterisk: string = '*';
}