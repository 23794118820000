import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ListingService } from '../../../../../services/listing.service';
import { get } from 'lodash';

@Component({
  selector: 'fc-common-pagination',
  templateUrl: './common-pagination.component.html',
  styleUrls: ['./common-pagination.component.scss']
})
export class CommonPaginationComponent implements OnInit {

  @Input() objPagination = new EventEmitter<ListingService>();

  @Output() objNavigate = new EventEmitter<string>();

  get hasNextPage(): boolean {
    return get(this.objPagination, 'hasNextPage', false);
  }

  get hasPrevPage(): boolean {
    return get(this.objPagination, 'hasPrevPage', false);
  }

  get hasNavigation(): boolean {
    return get(this.objPagination, 'hasNavigation', 0);
  }

  get navigation(): number[] {
    return this.getNavigationConfig();
  }

  get currentPage(): number {
    return get(this.objPagination, 'currentPage', 0);
  }

  ngOnInit(): void {
  }

  onChangePage(page: string) {
    this.objNavigate.next(page)
  }

  getNavigationConfig(): number[] {
    let pageList: number[] = [];
    let numberOfPage = 5
    let totalPage = get(this.objPagination, 'totalPage', 1);
    let currentPage = get(this.objPagination, 'currentPage', 1);
    for (let startPage = 1; startPage <= totalPage; startPage++) {
      pageList.push(startPage);
    }

    let pageStart: number = (currentPage - 3 > 0 && totalPage > 5) ? currentPage - 3 : 0;
    let pageEnd: number =  (currentPage + 2 > numberOfPage) ? currentPage + 2 : numberOfPage;
    if (totalPage - pageEnd < 0  && totalPage > numberOfPage) {
      pageStart = pageStart + totalPage - pageEnd;
    }

    return pageList.slice(pageStart, pageEnd);
  }
}

export default class CommonPaginationConfig {

  hasNextPage: boolean = false;

  hasPrevPage: boolean = false;

  currentPage?: string;

  totalPage?: string;

  hasNavigation: boolean = false;

  constructor(data: {
    hasNextPage: boolean,
    hasPrevPage: boolean,
    currentPage?: string,
    totalPage?: string
    hasNavigation?: boolean
  }) {
    this.hasNextPage = data.hasNextPage;
    this.hasPrevPage = data.hasPrevPage;
    this.currentPage = data.currentPage;
    this.totalPage = data.totalPage;
    this.hasNavigation = data.hasNavigation || false;
  }
}