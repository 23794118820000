
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * This pipe is used for converting json address to readable address.
 *
 * Example:
 * <span> {{ strSomeString | readableAddress: 'city' }}</span>
 */

@Pipe({ name: 'readableAddress' })
export class ReadableAddressPipe implements PipeTransform {

  /**
   * An address object will have properties. These keys are supposed to be "plucked"
   * out and return by the transform() method
   *
   * @type {String[]}
   */
  protected readableAddressSegments = [
    'street',
    'city',
    'state',
    'zip',
    'unit',
    'level',
    'building_name',
    'lot_number',
  ];

  /**
   * Return only a certain value in address config if address_key is not empty
   * The format on this address is copied from convertAddress() in FieldmagicModel
   *
   * @param {string|Address} value
   * @param {string|string[]} key
   *
   * @returns {string|undefined|null}
   */
  transform(value: string|Address, key: string|string[] = null): string|undefined|null|Address {
    if (! value || (! _.isString(value) && ! _.isObject(value))) return value;

    let address = this.parse(value);

    // if we don't have a valid address we should return null
    if (! address) return null;
    // if we have a given key attempt to pull from the address data
    if (! Array.isArray(key) && key !== null) return _.get(address, key);

    // should contain the formatted addresses parts to be glued later on
    let parts = [];

    _.keys(
      _.pick(
        address,
        Array.isArray(key)
          ? _.intersectionWith(this.readableAddressSegments, key)
          : this.readableAddressSegments
      )
    )
      .forEach((part) => {
        let value = _.get(address, part);

        // exclude from processing if we don't have the value
        if (! value) return;

        switch(part) {
          case 'unit':
            // unit and zip separator should be separated  with `-`
            // if zip is missing in the address part therefor we use `,` as it separator
            let separator = _.get(address, 'zip') ? ' -' : ',';
            value = `${separator} U${value}`;
            break;
          case 'level':
            value = `, L${value}`;
            break;
          case 'lot_number':
            value = `, LT${value}`;
            break;
          default:
            value = `, ${value}`;
            break;
        }

        parts.push(value);
      });

    return parts.length > 0
      ? _.trim(_.join(parts, ''), ', ')
      : null;
  }

  /**
   * Parses a value from a json string to an object representation
   *
   * @param {string|object} value
   *
   * @returns {object|null}
   */
  protected parse(value: string|object): object|null {
    if (_.isObject(value)) return value as object;

    try {
       return JSON.parse(value as string);
    } catch (err) {
      return null;
    }
  }
}

interface Address {
  street: string;
  city: string;
  state: string;
  unit: string|number;
  level: string|number;
  building_name: string;
  lot_number: string|number;
  zip: string|number;
}
