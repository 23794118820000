<ng-container *ngIf="isInProgress">
  <p class="text-center mt-3 mb-3">
    <fa-icon [icon]="['fas','spinner']" class="custom-size text-secondary" [spin]="true"></fa-icon>
  </p>
  <p class="text-center">
    {{ 'importing_items' | translate }}
    <accounting-systems-common-import-progress *ngIf="isInProgress" (event-progress)="onProgressEvent($event)" progress-id="connect_import_items" class="d-block"></accounting-systems-common-import-progress>
  </p> 
</ng-container>
<ng-container *ngIf="! isInProgress && ! shouldRetry">
  <div class="d-flex justify-content-center">
    {{ 'confirm_accounting_import_items' | translate }}
  </div>
  <div class="d-flex justify-content-center mt-2 d-flex-gap">
    <button class="btn btn-primary" (click)="onStartImport()" id="btn-confirm-import">{{ "yes" | translate }}</button>
    <button class="btn btn-danger" (click)="onSkipImport()" id="btn-skip-import">{{ "no" | translate }}</button> 
  </div>
</ng-container>
<ng-container *ngIf="! isInProgress">
  <p class="text-center" id="import-items-message">
    {{ message | translate }} 
  </p>
  <div class="d-flex justify-content-center d-flex-gap">
    <fa-icon [icon]="['fas', 'sync-alt']" class="pointer" id="import-items-retry" *ngIf="shouldRetry" (click)="retryHandler()"></fa-icon>
    <fa-icon [icon]="['fas', 'fast-forward']" class="pointer" id="import-items-skip" *ngIf="shouldRetry" (click)="onSkipImport()"></fa-icon>
  </div>
</ng-container>
