import { MapsAPILoader } from '@agm/core';
import { ElementRef, Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { blank, filled } from '../../utils/common';
import { ClientStoreService } from '../../../services/client-store.service';
import { Country } from '../../../lists/country';
import { get, isNil, lowerCase } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  /**
   * Where we will put the places
   * autocomplete once it is loaded.
   *
   * @var {google.maps.places.Autocomplete | null}
   */
  public places: google.maps.places.Autocomplete | null = null;

  /**
   * Flag if places is currently being shown.
   *
   * @var {boolean}
   */
  public bIsPlaceShown: boolean = true;

  private readonly _countries = new Country();

  constructor(
    private mapsAPILoader: MapsAPILoader,
    // Let's publicize this so we don't have to redeclare this
    // to the components that will use this service.
    public ngZone: NgZone,
    private readonly client: ClientStoreService,
  ) {}

  /**
   * Method show to the google places autocomplete.
   *
   * @returns {void}
   */
  showPlaces(): void {
    document.querySelectorAll('.pac-container').forEach(item => {
      item['hidden'] = false;
    });
  }

  /**
   * Method hide to the google places autocomplete.
   *
   * @returns {void}
   */
  hidePlaces(): void {
    document.querySelectorAll('.pac-container').forEach(item => {
      item['hidden'] = true;
    });
  }

  /**
   * Unbind the listeners we added to the autocomplete
   * to remove overhead.
   *
   * @param {ElementRef} objInputElement
   *
   * @returns {void}
   */
  removePlaces(objInputElement: ElementRef): void {
    if (this.places != null) {
      this.places.unbindAll();
      google.maps.event.clearInstanceListeners(objInputElement);
    }
  }

  /**
   * Initialize the place in google autocomplete.
   *
   * @param {ElementRef} objInputElement
   * @param {string} strCountryCode
   *
   * @returns {Observable<google.maps.places.Autocomplete>}
   */
  initializePlaces(objInputElement: ElementRef, strCountryCode: string): Observable<google.maps.places.Autocomplete>{
    return Observable.fromPromise(this.mapsAPILoader.load())
      .pipe(
        map(() => {
          let opts = {};

          /// consider checking the countryCode
          if (
            blank(strCountryCode)
            || isNil(this._countries.list.find((country) => lowerCase(country.code) == lowerCase(strCountryCode)))) {
            strCountryCode = get(this.client.getActiveClient(), 'country');
          }

          if (filled(strCountryCode)) {
            opts = Object.assign({
              componentRestrictions: {
                country: strCountryCode
              }
            }, opts);
          }

          let objAutoComplete = new google.maps.places.Autocomplete(
            objInputElement.nativeElement, opts
          );

          objAutoComplete.setFields([
            'address_component',
            'adr_address',
            'formatted_address',
            'geometry',
            'icon',
            'name',
            'permanently_closed',
            'photo',
            'place_id',
            'plus_code',
            'type',
            'url',
            'utc_offset_minutes',
            'vicinity'
          ]);

          return objAutoComplete;
        }),
        tap((autocomplete) => {
          this.places = autocomplete;
        })
      );
    }

}
