
<div class="form-group">
  <form [formGroup]="addressForm">
    <div class="row mt-2">
      <div class="col-xs-12 col-sm-12">
          <form autocomplete="off" [formGroup]="addressForm">
            <input autocomplete="off"
              formControlName="street"
              [ngClass]="{'is-invalid': isInvalid('street')}"
              name="street"
              id="addressStreet"
              type="text"
              class="street form-control value-text"
              (focus)="placeService.showPlaces()"
              (focusout)="placeService.hidePlaces()"
              required
              placeholder="{{ 'placeholder_line' | translate }}"  #searchInput />
          </form>
      </div>
    </div>
    <div class="row mt-2">
      <div [ngClass]="{'col-sm-3': strCurrentUrl.indexOf('register') < 0, 'col-sm-6': strCurrentUrl.indexOf('register') > -1}">
        <input formControlName="unit" name="unit" id="addressUnit" type="text" class="unit form-control value-text"
        placeholder="{{ 'placeholder_unit' | translate }}">
      </div>
      <div [ngClass]="{'col-sm-3': strCurrentUrl.indexOf('register') < 0, 'col-sm-6': strCurrentUrl.indexOf('register') > -1}">
        <input formControlName="level" name="level" id="addressLevel" type="text" class="level form-control value-text"
        placeholder="{{ 'placeholder_level' | translate }}">
      </div>
      <div *ngIf="strCurrentUrl.indexOf('register') < 0" class="col-xs-6 col-sm-6">
        <label class="labels">
          {{ 'geolocation' | translate }}
        </label>
      </div>
    </div>
    <div class="row mt-2">
      <div [ngClass]="{'col-sm-3': strCurrentUrl.indexOf('register') < 0, 'col-sm-6': strCurrentUrl.indexOf('register') > -1}">
        <input formControlName="building_name" name="building_name" id="addressBuildingName" type="text" class="building_name form-control value-text"
        placeholder="{{ 'placeholder_location_name' | translate }}">
      </div>
      <div [ngClass]="{'col-sm-3': strCurrentUrl.indexOf('register') < 0, 'col-sm-6': strCurrentUrl.indexOf('register') > -1}">
        <input formControlName="lot_number" name="lot_number" id="addressLotNumber" type="text" class="lot_number form-control value-text"
        placeholder="{{ 'placeholder_lot_number' | translate }}">
      </div>
      <div class="col-sm-6" [ngClass]="{'d-none': strCurrentUrl.indexOf('register') > -1}">
        <fc-geolocation-input
          (childToParentEvent)="geolocationComponentEmitter($event)"
          [geolocationData]="geolocationData"
          [withShowMap]="true">
        </fc-geolocation-input>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xs-12 col-sm-6">
        <input formControlName="city" [ngClass]="{'is-invalid': isInvalid('city')}" name="city" id="addressCity" type="text"
          class="city form-control value-text" required placeholder="{{ 'placeholder_city' | translate }}" >
      </div>
      <div class="col-xs-12 col-sm-6">
        <input formControlName="zip" [ngClass]="{'is-invalid': isInvalid('zip')}" name="zip" id="addressZip" type="text"
          class="zip form-control value-text" required placeholder="{{ 'placeholder_zip' | translate }}" >
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xs-12 col-sm-6">
        <ng-select class="state" placeholder="{{ 'placeholder_state' | translate }}" [items]="arrStateList" bindLabel="name" bindValue="code" [clearable]="false"
          formControlName="state" [ngClass]="{'is-invalid': isInvalid('state')}"
          name="state" id="addressState" required >
        </ng-select>
      </div>
      <div class="col-xs-12 col-sm-6">
        <ng-select class="country" placeholder="{{ 'placeholder_country' | translate }}" [items]="arrCountryList" bindLabel="name"
          bindValue="code" [clearable]="false" [ngClass]="{'is-invalid': isInvalid('country')}"
          formControlName="country" name="country" id="addressCountry" required>
        </ng-select>
      </div>
    </div>
  </form>
</div>

