<mat-dialog-content class="mobile-view-dialog">
  <mat-horizontal-stepper class="creation-wizard-style" (selectionChange)="stepperChanges($event)" [linear]="true" #stepper>

    <ng-template *ngIf="stepperSettings.step1" matStepperIcon="step1">
      <fa-icon [icon]="['fas', stepperSettings.step1.icon]"></fa-icon>
    </ng-template>

    <ng-template *ngIf="stepperSettings.step2" matStepperIcon="step2">
      <fa-icon [icon]="['fas', stepperSettings.step2.icon]"></fa-icon>
    </ng-template>

    <ng-template *ngIf="stepperSettings.step3" matStepperIcon="step3">
      <fa-icon [icon]="['fas', stepperSettings.step3.icon]"></fa-icon>
    </ng-template>

    <ng-template *ngIf="stepperSettings.step4" matStepperIcon="step4">
      <fa-icon [icon]="['fas', stepperSettings.step4.icon]"></fa-icon>
    </ng-template>

    <ng-template *ngIf="stepperSettings.step5" matStepperIcon="step5">
      <fa-icon [icon]="['fas', stepperSettings.step5.icon]"></fa-icon>
    </ng-template>

    <mat-step *ngIf="stepperSettings.step1" state="step1" #matStep1
      [completed]="stepperSettings.step1.completed"
      [editable]="stepperSettings.step1.editable"
      [optional]="stepperSettings.step1.optional">

      <ng-template matStepLabel id="step1Title">{{ stepperSettings.step1.title | translate }}</ng-template>
      <br>
      <ng-container
        [ngTemplateOutlet]="step1"
        [ngTemplateOutletContext]="{ stepper: stepper, step: matStep1}">
      </ng-container>
    </mat-step>

    <mat-step *ngIf="stepperSettings.step2" state="step2" #matStep2
      [completed]="stepperSettings.step2.completed"
      [editable]="stepperSettings.step2.editable"
      [optional]="stepperSettings.step2.optional">
      <ng-template matStepLabel id="step2Title">{{ stepperSettings.step2.title | translate }}</ng-template>
      <br>
      <ng-container
        [ngTemplateOutlet]="step2"
        [ngTemplateOutletContext]="{ stepper: stepper, step: matStep2}">
      </ng-container>
    </mat-step>

    <mat-step *ngIf="stepperSettings.step3" state="step3" #matStep3
      [completed]="stepperSettings.step3.completed"
      [editable]="stepperSettings.step3.editable"
      [optional]="stepperSettings.step3.optional">
      <ng-template matStepLabel id="step3Title">{{ stepperSettings.step3.title | translate }}</ng-template>
      <br>
      <ng-container
        [ngTemplateOutlet]="step3"
        [ngTemplateOutletContext]="{ stepper: stepper, step: matStep3 }">
      </ng-container>
    </mat-step>

    <mat-step *ngIf="stepperSettings.step4" state="step4" #matStep4
      [completed]="stepperSettings.step4.completed"
      [editable]="stepperSettings.step4.editable"
      [optional]="stepperSettings.step4.optional">
      <ng-template matStepLabel id="step4Title">{{ stepperSettings.step4.title | translate }}</ng-template>
      <br>
      <ng-container
        [ngTemplateOutlet]="step4"
        [ngTemplateOutletContext]="{ stepper: stepper, step: matStep4 }">
      </ng-container>
    </mat-step>

    <mat-step *ngIf="stepperSettings.step5" state="step5" #matStep5
      [completed]="stepperSettings.step5.completed"
      [editable]="stepperSettings.step5.editable"
      [optional]="stepperSettings.step5.optional">
      <ng-template matStepLabel id="step5Title">{{ stepperSettings.step5.title | translate }}</ng-template>
      <br>
      <ng-container
        [ngTemplateOutlet]="step5"
        [ngTemplateOutletContext]="{ stepper: stepper, step: matStep5 }">
      </ng-container>
    </mat-step>

  </mat-horizontal-stepper>

</mat-dialog-content>