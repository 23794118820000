/**
 * We currently have 2 types which is the following:
 *
 * 'default' = User has 2 choice here, "Yes" and "No".
 * 'ok' = User has only one choice, which is "OK".
 */
export type ConfirmationType = 'default' | 'ok';

/**
 * Allows for custom confirmation values.
 * The default is 'Yes' for affirmative and 'No' negative.
 *
 */
export type ConfirmationPair = {
  trueVal: string,
  falseVal: string
};

export class ConfirmationInterface {

  /**
   * The header of the confirmation dialog.
   *
   * @var {string}
   */
  public header?: string;

  /**
   * The main message of the confirmation.
   *
   * @var {string}
   */
  public message?: string;

  /**
   * The type of confirmation, refer to documentation
   * in ConfirmationType for available types.
   *
   * @var {ConfirmationType}
   */
  public type?: ConfirmationType;

  /**
   * Alternative confirmation values. The default is 'Yes' or 'No'.
   *
   * @var {ConfirmationPair}
   */
  public options?: ConfirmationPair;
}

export class Confirmation {
  public header?: string;
  public message: string;
  public type: ConfirmationType;
  public options: ConfirmationPair;

  constructor(properties: ConfirmationInterface) {
    this.header = properties.header || 'confirmation';
    this.message = properties.message || 'continue_confirm';
    this.type = properties.type || 'default';
    this.options = properties.options || { trueVal: 'yes', falseVal: 'no' };
  }
}

export class ConfirmationAnswer {
  public answer: boolean;
  public type: ConfirmationType;

  constructor(answer: boolean, type: ConfirmationType) {
    this.answer = answer;
    this.type = type || 'default';
  }
}
