import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DriverInterface } from "../../../../../entities/driver";
import { WizardStepComponent, StepEventData, PROGRESS_NEXT, PROGRESS_FAILED } from '../../../connect.component';

@Component({
  templateUrl: './configure.component.html',
  selector: 'wizard-step-configure-driver',
})
export class ConfigureComponent implements WizardStepComponent {
  /**
   * The current driver selected
   *
   * @var {DriverInterface}
   */
  @Input('driver') driver: DriverInterface;

   /**
   * {@inheritdoc}
   */
  @Output('event-progress') eventProgress: EventEmitter<StepEventData> = new EventEmitter<StepEventData>();

  /**
   * handles the event emitted from the custom metadata field component
   *
   * @param   {boolean} isSuccess
   *
   * @returns {void}
   */
  handleEventEmitted(isSuccess: boolean): void {
    this.eventProgress.emit({
      progress: (isSuccess) ? PROGRESS_NEXT : PROGRESS_FAILED
    });
  }
}
