import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { delay, finalize } from 'rxjs/operators';
import { LooseObject } from '../../../../../../objects/loose-object';
import { FormService } from '../../../../../../services/form.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { RecordService } from '../../../../../../services/record.service';
import { ClientStoreService } from '../../../../../../services/client-store.service';

@Component({
  selector: 'task-details-job-edit',
  templateUrl: './job-edit.component.html',
  styleUrls: ['./job-edit.component.scss']
})
export class JobEditComponent implements OnInit {
  @Input() jobDetails: LooseObject;
  @Output() jobFormSave = new EventEmitter<boolean>();

  form: FormGroup;
  bSubmitted = false;
  bSaving = false;
  strMode = 'edit';
  strModule = 'jobs';
  fields: any = [
    // status priority job type due date department job summary
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "date_completed",
      "label": "date_completed",
      "controlType": "date",
      "space": 12,
      "has_primary": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "customer_id",
      "label": "customer",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "customers",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "422b8bf7-f14d-49c0-bba2-0ac573371a4a",
      "key": "site_id",
      "label": "site",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "sites",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "awaiting_scheduling",
      "key": "status",
      "label": "status",
      "controlType": "dropdown",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "options": [
        {
          "id": "awaiting_scheduling",
          "text": "awaiting_scheduling"
        },
        {
          "id": "scheduled",
          "text": "scheduled"
        },
        {
          "id": "pending",
          "text": "pending"
        },
        {
          "id": "awaiting_invoicing",
          "text": "awaiting_invoicing"
        },
        {
          "id": "completed",
          "text": "completed"
        },
        {
          "id": "cancelled",
          "text": "cancelled"
        }
      ],
      "tag": false,
      "hideSelected": true,
      "closeOnSelect": false,
      "maxSelectedItems": 100,
      "clearable": false,
      "list": ""
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "normal",
      "key": "priority",
      "label": "priority",
      "controlType": "dropdown",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "options": [
        {
          "id": "low",
          "text": "low"
        },
        {
          "id": "normal",
          "text": "normal"
        },
        {
          "id": "high",
          "text": "high"
        },
        {
          "id": "urgent",
          "text": "urgent"
        }
      ],
      "tag": false,
      "hideSelected": true,
      "closeOnSelect": false,
      "maxSelectedItems": 100,
      "clearable": false,
      "list": ""
    },
    // Show project template when value is project.
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "type",
      "label": "job_type",
      "controlType": "dropdown",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "options": [
        {
          "id": "simple_job",
          "text": "simple_job"
        },
        {
          "id": "project",
          "text": "project"
        }
      ],
      "tag": false,
      "hideSelected": true,
      "closeOnSelect": false,
      "maxSelectedItems": 100,
      "clearable": false,
      "list": ""
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "project_template_id",
      "label": "project_template",
      "controlType": "relate",
      "space": 6,
      "is_hidden": true,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "project_templates",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "due_date",
      "label": "due_date",
      "controlType": "date",
      "space": 6,
      "has_primary": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": null,
      "key": "department_id",
      "label": "department",
      "controlType": "relate",
      "space": 6,
      "is_hidden": false,
      "has_primary": false,
      "note": "",
      "maxSelectedItems": 100,
      "option_count": 0,
      "text": "text",
      "default_text": "",
      "module": "departments",
      "multiple": false,
      "options": [],
      "filter": {},
      "add_tag": false
    },
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "job_summary",
      "label": "job_summary",
      "controlType": "textarea",
      "space": 12,
      "is_hidden": false,
      "max_length": 2056,
      "has_primary": false,
      "rows": 4
    },
  ];

  constructor(
    private notifService: NotificationService,
    private formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    private recordService: RecordService,
    private client: ClientStoreService
  ) {
    this.form = this.formService.toFormGroup(this.fields);

    const projectTemplateId = this.fields.findIndex(x => x.key === 'project_template_id');

    this.form.controls.type.valueChanges.subscribe((change) => {
      if (change === 'project') {
        this.fields[projectTemplateId].is_hidden = false;
      } else {
        this.fields[projectTemplateId].is_hidden = true;
        this.form.controls.project_template_id.setValue(null);
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (!this.client.isDepartmentTracking()) {
      this.fields = this.fields.filter(item => { return item.key !== 'department_id' });
    }

    if ('jobDetails' in changes && this.form !== undefined) {
      this.form.patchValue(this.jobDetails);

      // Prefill values on some fields where the patchValue doesn;t work
      const customerIdIndex = this.fields.findIndex(field => field.key === 'customer_id');
      this.fields[customerIdIndex].default_text = this.jobDetails.customer_text;
      this.fields[customerIdIndex].options = [
        {
          "id": this.jobDetails.customer_id,
          "text": this.jobDetails.customer_text,
          "disabled": false
        }
      ];
      const siteIdIndex = this.fields.findIndex(field => field.key === 'site_id');
      this.fields[siteIdIndex].default_text = this.jobDetails.site_text;
      this.fields[siteIdIndex].options = [
        {
          "id": this.jobDetails.site_id,
          "text": this.jobDetails.site_text,
          "disabled": false
        }
      ];
      const departmentIdIndex = this.fields.findIndex(field => field.key === 'department_id');

      if (departmentIdIndex > -1) {
        this.fields[departmentIdIndex].default_text = this.jobDetails.department_text;
        this.fields[departmentIdIndex].options = [
          {
            "id": this.jobDetails.department_id,
            "text": this.jobDetails.department_text,
            "disabled": false
          }
        ];
      }

      const dueDateIndex = this.fields.findIndex(field => field.key === 'due_date');
      this.fields[dueDateIndex].default_value = this.jobDetails.due_date;
    }
  }

  save() {
    this.form.markAsDirty();
    this.form.markAsTouched();
    this.bSubmitted = true;
    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      this.bSaving = true;
      this.recordService.saveRecord(this.strModule, this.form.value, this.jobDetails.id)
        .pipe(
          finalize(() => this.bSaving = false)
        )
        .subscribe(arResponse => {
          this.notifService.notifySuccess("record_update_success");
          this.jobFormSave.emit(true);
        }, (error: HttpErrorResponse) => {
          if (error.status == 400 || error.status == 422) {
            this.notifService.notifyWarning('record_invalid_parameters');
          }
        });
    }
  }

  cancel() {
    this.jobFormSave.emit(false);
  }
}