export class StockLevel {
    current_stock_level: number = 0;
    customer_first_name: string;
    customer_last_name: string;
    customer_name: string;
    description: string;
    id: string;
    image: string;
    item_id: string;
    item_name: string;
    preferred_supplier: string;
    product_code: string;
    product_name: string;
    location: string;
    original_quantity: number = 0;
    quantity: number;
    unit: string;
    unit_price: number;
    warehouse_id: string;
    warehouse_name: string;
    warehouse_type: 'fixed_location' | 'vehicle';
    allocated_quantity: number = 0;
    checked: boolean = false;
    active: boolean = false;

    constructor(properties?: StockLevelInterface) {
        if (properties) {
            this.current_stock_level = properties.current_stock_level;
            this.customer_first_name = properties.customer_first_name;
            this.customer_last_name = properties.customer_last_name;
            this.customer_name = properties.customer_name;
            this.description = properties.description;
            this.id = properties.id;
            this.image = properties.image;
            this.item_id = properties.item_id;
            this.item_name = properties.item_name;
            this.preferred_supplier = properties.preferred_supplier;
            this.product_code = properties.product_code;
            this.product_name = properties.product_name;
            this.location = properties.location;
            this.original_quantity = parseFloat(properties.quantity as any);
            this.quantity = parseFloat(properties.quantity as any);
            this.unit = properties.unit;
            this.unit_price = properties.unit_price;
            this.warehouse_id = properties.warehouse_id;
            this.warehouse_name = properties.warehouse_name;
            this.warehouse_type = properties.warehouse_type;
            this.active = properties.active || false;
        }
    }
}
export interface StockLevelInterface {
    allocated_quantity: number;
    checked: boolean;
    current_stock_level: number;
    customer_first_name: string;
    customer_last_name: string;
    customer_name: string;
    description: string;
    id: string;
    image: string;
    item_id: string;
    item_name: string;
    preferred_supplier: string;
    product_code: string;
    product_name: string;
    location: string;
    quantity: number;
    unit: string;
    unit_price: number;
    warehouse_id: string;
    warehouse_name: string;
    warehouse_type: 'fixed_location' | 'vehicle';
    active?: boolean;
}