<div *ngFor="let role of roleRecords" class="row border-top p-2" [id]="'widgetContactRolesRecord' + role.id">
    <div class="col-sm-3 pl-1 pr-1">
        <div class="text-name contact-text truncate">
            <a *ngIf="role.is_owned_by_current_client; else noLinkContactLabel"
              id="widgetContactRolesLabel"
              matTooltip="{{ 'name' | translate }}: {{ role.contact_text }}"
              class="pointer"
              href="/#/contacts/{{role.contact_id}}"
            >
                <span>{{ role.contact_text }}</span>
            </a>
            <fa-icon *ngIf="isPrimaryContactRole(role.contact_id, role.primary_contact_id)" class="text-success pl-1 primary-role" matTooltip="{{ 'primary' | translate }}" [icon]="['fas', 'check-circle']"></fa-icon>
            <ng-template #noLinkContactLabel>
              <span id="widgetContactRolesLabel" matTooltip="{{ 'name' | translate }}: {{ role.contact_text }}">{{ role.contact_text }}</span>
            </ng-template>
        </div>
        <div class="text-job-title">
          <ng-container *ngIf="strModule === 'jobs'; else isContact">
            <div class="text-job-title">
              <span matTooltip="{{ 'customer' | translate }}: {{ translateRole(role.customer_roles) | translate }}" class="fs-12 d-inherit word-break truncate">
                {{ 'customer' | translate }}: {{ translateRole(role.customer_roles) | translate }}
              </span>
            </div>
            <div class="text-job-title">
              <span matTooltip="{{ 'site' | translate }}: {{ translateRole(role.site_roles) | translate }}" class="fs-12 d-inherit word-break truncate">
                {{ 'site' | translate }}: {{ translateRole(role.site_roles) | translate }}
              </span>
            </div>
          </ng-container>
          <ng-template #isContact>
            <span *ngIf="role.role" matTooltip="{{ 'role' | translate }}: {{ translateRole(role.role) | translate }}" class="fs-12 d-inherit word-break truncate">
              {{ translateRole(role.role) }}
            </span>
            <span *ngIf="!role.role" matTooltip="{{ 'no_role_assigned' | translate }}" class="fs-12 d-inherit word-break truncate">
              {{ 'no_role_assigned' | translate }}
            </span>
          </ng-template>
        </div>
    </div>
    <div id="widgetContactRolesEmail" class="col-sm-4 pl-1 pr-1">
        <span *ngFor="let email of role.email_address" class="fs-12 d-inherit word-break pointer truncate email-record" matTooltip="{{ email.email }}" (click)="setEmailDialogData(email, role.contact_id)">
            {{ email.email }}
        </span>
        <span *ngIf="(role.email_address || '').length < 1" matTooltip="{{ 'email' | translate }}: --"> -- </span>
    </div>
    <div id="widgetContactRolesPhone" class="col-sm-4 pl-1 pr-1">
        <table style="width: 100%; table-layout: fixed;">
            <tr *ngFor="let phone of role.phone" matTooltip="{{ phone.type | translate }}: {{ phone.code }} {{ phone.number }}">
                <td width="20%" class="customer-icon-phone" *ngIf="phone.code">
                    <fa-icon *ngIf="phone.type == 'home'" [icon]="['far', 'home']"></fa-icon>
                    <fa-icon *ngIf="phone.type == 'work'" [icon]="['far', 'phone-office']"></fa-icon>
                    <fa-icon *ngIf="phone.type == 'mobile'" [icon]="['far', 'mobile-android-alt']"></fa-icon>
                    <fa-icon *ngIf="phone.type == 'others'" [icon]="['far', 'phone-plus']"></fa-icon>
                </td>
                <td width="80%" class="truncate customer-icon-text" *ngIf="phone.code">{{ phone.code }} {{ phone.number }}</td>
            </tr>
        </table>
        <span *ngIf="(role.phone || '').length < 1" matTooltip="{{ 'phone' | translate }}: -- "> -- </span>
    </div>
    <div *ngIf="strModule != 'jobs'" class="col-sm-1 d-table fs-12 d-inherit word-break pl-1 pr-1">
        <div class="row" *ngIf="role.editable">
            <div class="col-12">
                <fa-icon id="widgetContactRolesEditButton" class="text-success pointer float-right edit-record" [icon]="['fas', 'pencil']" (click)="emitAction('edit', role)"></fa-icon>
            </div>
        </div>
        <div class="row" *ngIf="role.deletable">
            <div class="col-12">
                <fa-icon id="widgetContactRolesDeleteButton" class="text-warning pointer float-right delete-record" [icon]="['fas', 'trash-alt']" (click)="emitAction('delete', role)"></fa-icon>
            </div>
        </div>
    </div>
</div>