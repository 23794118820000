<small *ngIf="isBatch" class="text-muted" id="import-progress" data-testid="import-progress">

  <ng-container *ngIf="hasTotal">
    {{ 'importing_progress' | translate: getImportingTranslateParameters() }}
  </ng-container>

  <ng-container *ngIf="!hasTotal">
    {{ 'importing_progress_no_total' | translate: getImportingTranslateParameters() }}
  </ng-container>
  
</small>
