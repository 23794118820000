import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { MetadataFieldInterface, FIELD_TYPE_DROPDOWN } from "../../../entities/driver";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Observable, Subscription, of } from 'rxjs';
import { AccountingSystemService, FieldValidationError } from "../../../services/accounting_system.service";
import { finalize, startWith, map } from "rxjs/operators";
import { Select } from "../../../../../../objects/select";
import { set } from "lodash";

@Component({
  selector: 'render-custom-metadata',
  templateUrl: './custom-metadata.component.html',
  styleUrls: ['./custom-metadata.component.scss']
})
export class CustomMetadataComponent implements OnInit, OnDestroy {
  /**
   * event emitted by this component if the current form process is success or failed
   *
   * @var {EventEmitter<boolean>}
   */
  @Output('event-status') event: EventEmitter<boolean> = new EventEmitter;

  /**
   * contains the list of fields that should be rendered by the component
   *
   * @var {MetadataFieldInterface[]}
   */
  @Input('fields') fields: MetadataFieldInterface[] = [];

  /**
   * The driver unique identification
   *
   * @var {string}
   */
  @Input('driverId') driverId: string;

  /**
   * contains the what of type fields that is currently being rendered or which fields are extracted in the drivers metadata
   *
   * @var {string}
   */
  @Input('type') type: string;

  /// a flag to use to determine if this form can be skipped
  /// useful for scenarios where this form is used in wizard
  @Input() canBeSkipped: boolean = false;

  /**
   * determines if the current form is in progress for processing
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * contains the list of error message for each field that is from the API service
   *
   * @var {FieldValidationError}
   */
  errors: FieldValidationError = {};

  /**
   * contains the form group of the current metadata fields
   *
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({});

  /**
   * INTERNAL: contains the list of the subscriptions from the observable action that should be destroyed
   * or cleaned up after this component has been unmounted
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = []

  /**
   * @param {AccountingSystemService} service
   */
  constructor(
    private service: AccountingSystemService
  ) { }

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.fields = this.fields.map((field: MetadataFieldInterface) => {
      this.form.addControl(field.id, new FormControl(field.value, {
        validators: [
          ...(field.required && [Validators.required] || [])
        ]
      }));

      if (field.type === FIELD_TYPE_DROPDOWN) {
        field = set(field, 'options$', this.getOptions$(field));
      }

      return field;
    });
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * handle form submission
   *
   * @returns {void}
   */
  onSubmit(): void {
    if (this.form.valid) {
      this.isInProgress = true;

      this.subscriptions.push(
        this.service.updateMetadata$(this.form.value, { type: this.type, driverId: this.driverId })
          .pipe(
            finalize(() => this.isInProgress = false)
          )
          .subscribe(({ isSuccessful, errors }) => {
            if (isSuccessful) return this.event.emit(true);

            this.errors = errors;
            this.event.emit(false);
          })
      );
    }
  }

  /**
   * get the options for the current field from the server or using the option field instead
   *
   * @param   {MetadataFieldInterface} field
   *
   * @returns {Observable<Select[]>}
   */
  getOptions$(field: MetadataFieldInterface): Observable<{ options: Select[], loading: boolean }> {
    if (field.options.length > 0) return of({ options: field.options, loading: false });
    return this.service.getMetadataFieldOptions$(field.id, this.driverId).pipe(
      map((options) => {
        if (options.length === 1 && this.type === 'authentication') {
          this.form.get(field.id).setValue(options[0].id);
        }

        return {
          options,
          loading: false
        };
      }),
      startWith({ loading: true, options: [] })
    );
  }

  /// handles skip user action
  onSkip(): void {
    this.event.emit(true);
  }
}
