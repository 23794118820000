export class UserTypeModule {
    // Module list based on client level
    moduleList = {
        'admin': [
            'users',
            'departments',
            'resources',
            'leads',
            'customers',
            'sites',
            'contacts',
            'opportunities',
            'jobs',
            'assets',
            'customer_invoices',
            'purchase_orders',
            'supplier_invoices',
            'desktop',
            'quotes',
            'admin/dashboard',
            'schedule_recurring_jobs',
            'shared_location',
            'stock_levels',
            'subcontractors'
        ],
        'desktop': [
            'users',
            'departments',
            'resources',
            'leads',
            'customers',
            'sites',
            'contacts',
            'opportunities',
            'jobs',
            'assets',
            'customer_invoices',
            'purchase_orders',
            'supplier_invoices',
            'desktop',
            'quotes',
            'admin/dashboard',
            'schedule_recurring_jobs',
            'shared_location',
            'stock_levels',
            'subcontractors'
        ],
        'mobile-premium': [ 'sites', 'contacts', 'jobs', 'assets', 'quotes' ],
        'mobile-lite': [ 'jobs' ]
    };
};
