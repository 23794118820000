import { ValidatorFn, AbstractControl } from '@angular/forms';
import { Phone } from '../../objects/phone';
import { LooseObject } from '../../objects/loose-object';

/**
 * Custom validator for phone.
 * 
 * This makes sure that the number property in the Phone is filled up.
 */
export function phoneValidator(): ValidatorFn {
    return (control: AbstractControl): LooseObject | null => {
        
        let bFlagError = false;

        // Changing forEach to every so once we flag the error as true
        // we can stop the loop.
        control.value.every((element: Phone) => {
          if (element.number == undefined) {
            bFlagError = true;
            return false;
          }
          return true;
        });

        if (bFlagError) {
          return {'phone': true}
        } else {
          return null;
        }

      }
  }
