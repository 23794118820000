<div
    class="tab-component"
    *ngIf="widgets$ | async as widgets;"
    [ngClass]="{
        'text-center p-2': widgets.length < 1
    }"
>
    <ng-container *ngIf="widgets.length > 0; else noWidgets">
        <ul class="nav" id="myTab" role="tablist">
            <ng-container *ngFor="let widgetTab of widgets">
                <li
                    [ngClass]="{'hidden': widgetTab['type'] === 'tasks'}"
                    class="nav-item"
                    matTooltip="{{ widgetTab.type | translate }}"
                >
                    <a  class="nav-link {{widgetTab['default_active']}}"
                        data-toggle="tab"
                        href="#{{widgetTab['singular']}}"
                        role="tab"
                        aria-selected="true"
                        [id]="widgetTab['singular']+'-tab'"
                        [attr.aria-controls]="widgetTab['singular']"
                        *fcMinSubscriptionPlanRequired="widgetTab.minimum_subscription_required"
                        (click)="prepareWidgetDataForDisplay(widgetTab['type'])">
                        <fa-icon [icon]="['fa', widgetTab['icon']]"></fa-icon>
                    </a>
                </li>
            </ng-container>
        </ul>

        <div class="tab-content">
            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('activities')}" id="activity" role="tabpanel" aria-labelledby="activity-tab" *ngIf="shouldWidgetBeDisplayed('activity')">
                <app-activities [strRecordId]="recordId" [strModule]="strModule" *ngIf="objTriggerFetch['activities'] || isTabActiveByDefault('activities')"></app-activities>
            </div>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('quotes')}" id="quote" role="tabpanel" aria-labelledby="quote-tab" *ngIf="shouldWidgetBeDisplayed('quote')">
                <app-quotes *ngIf="objTriggerFetch['quotes'] || isTabActiveByDefault('quotes')" [strRecordId]="recordId" [strModule]="strModule"></app-quotes>
            </div>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('customer-invoices')}" id="customer-invoice" role="tabpanel" aria-labelledby="customer-invoice-tab" *ngIf="shouldWidgetBeDisplayed('customer-invoice')">
                <app-invoices *ngIf="objTriggerFetch['customer-invoices']" [strModule]="strModule" (refreshRelatePanel)="refreshWidget($event)" [strRecordId]="recordId"></app-invoices>
            </div>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('files')}" id="file" role="tabpanel" aria-labelledby="file-tab" *ngIf="shouldWidgetBeDisplayed('file')">
                <app-file *ngIf="objTriggerFetch['files'] || isTabActiveByDefault('files')" [strRecordId]="recordId"  [strModule]="strModule"></app-file>
            </div>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('time-entries')}" id="time-entry" role="tabpanel" aria-labelledby="time-entry-tab" *ngIf="shouldWidgetBeDisplayed('time-entry')">
                <app-time-entry *ngIf="objTriggerFetch['time-entries']" [strRecordId]="recordId"></app-time-entry>
            </div>

            <ng-container *fcHasWidgetPermission="'jobs'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('jobs')}" id="job" role="tabpanel" aria-labelledby="job-tab" *ngIf="shouldWidgetBeDisplayed('job')">
                    <app-jobs *ngIf="objTriggerFetch['jobs'] || isTabActiveByDefault('jobs')" [strModule]="strModule" [strRecordId]="recordId"></app-jobs>
                </div>
            </ng-container>

            <ng-container *fcHasWidgetPermission="'customer_invoices'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('customer-invoices')}" id="customer-invoice" role="tabpanel" aria-labelledby="customer-invoice-tab" *ngIf="shouldWidgetBeDisplayed('customer-invoice')">
                    <app-invoices *ngIf="objTriggerFetch['customer-invoices']" [strModule]="strModule" (refreshRelatePanel)="refreshWidget($event)" [strRecordId]="recordId"></app-invoices>
                </div>
            </ng-container>

            <ng-container *fcHasWidgetPermission="'purchase_orders'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('purchase-orders')}" id="purchase-order" role="tabpanel" aria-labelledby="purchase-order-tab" *ngIf="shouldWidgetBeDisplayed('purchase-order')">
                    <app-purchase-orders *ngIf="objTriggerFetch['purchase-orders']" [strModule]="strModule" [strRecordId]="recordId"></app-purchase-orders>
                </div>
            </ng-container>

            <ng-container *fcHasWidgetPermission="'recurring_jobs'">
                <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-advanced'">
                    <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('recurring-jobs')}" id="recurring-job" role="tabpanel" aria-labelledby="recurring-job-tab" *ngIf="shouldWidgetBeDisplayed('recurring-job')">
                        <app-recurring-jobs *ngIf="objTriggerFetch['recurring-jobs']" [strModule]="strModule" (refreshRelatePanel)="refreshWidget($event)" [strRecordId]="recordId"></app-recurring-jobs>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *fcHasWidgetPermission="'supplier_invoices'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('supplier-invoices')}" id="supplier-invoice" role="tabpanel" aria-labelledby="supplier-invoice-tab" *ngIf="shouldWidgetBeDisplayed('supplier-invoice')">
                    <app-supplier-invoices *ngIf="objTriggerFetch['supplier-invoices']" [strModule]="strModule" (refreshRelatePanel)="refreshWidget($event)" [strRecordId]="recordId"></app-supplier-invoices>
                </div>
            </ng-container>

            <ng-container *fcHasWidgetPermission="'sites'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('customer-sites')}" id="customer-site" role="tabpanel" aria-labelledby="customer-site-tab" *ngIf="shouldWidgetBeDisplayed('customer-site')">
                    <app-sites *ngIf="objTriggerFetch['customer-sites']" [strModule]="strModule" [strRecordId]="recordId"></app-sites>
                </div>
            </ng-container>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('contacts')}" id="contact" role="tabpanel" aria-labelledby="contact-tab" *ngIf="shouldWidgetBeDisplayed('contact')">
                <app-role-list *ngIf="objTriggerFetch['contacts'] || isTabActiveByDefault('contacts')" [strModule]="strModule" [strModuleId]="recordId" [strRelatedModule]="'contacts'"></app-role-list>
            </div>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('customers')}" id="customer" role="tabpanel" aria-labelledby="customer-tab" *ngIf="shouldWidgetBeDisplayed('customer')">
                <app-role-list *ngIf="objTriggerFetch['customers'] || isTabActiveByDefault('customers')" [strModule]="strModule" [strModuleId]="recordId" [strRelatedModule]="'customers'"></app-role-list>
            </div>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('sites')}" id="site" role="tabpanel" aria-labelledby="site-tab" *ngIf="shouldWidgetBeDisplayed('site')">
                <app-role-list *ngIf="objTriggerFetch['sites'] || isTabActiveByDefault('sites')" [strModule]="strModule" [strModuleId]="recordId" [strRelatedModule]="'sites'"></app-role-list>
            </div>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('opportunities')}" id="opportunity" role="tabpanel" aria-labelledby="opportunity-tab" *ngIf="shouldWidgetBeDisplayed('opportunity')">
                <app-role-list *ngIf="objTriggerFetch['opportunities'] || isTabActiveByDefault('opportunities')" [strModule]="strModule" [strModuleId]="recordId" [strRelatedModule]="'opportunities'"></app-role-list>
            </div>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('checklists')}" id="checklist" role="tabpanel" aria-labelledby="checklist-tab" *ngIf="shouldWidgetBeDisplayed('checklist')">
                <app-checklists *ngIf="objTriggerFetch['checklists']" [strRecordId]="recordId" [strModule]="strModule" (refreshJobAssets)="refreshWidget($event)"></app-checklists>
            </div>

            <ng-container *fcHasWidgetPermission="'assets'">
                <ng-container *fcMinSubscriptionPlanRequired="'fieldmagic-advanced'">
                    <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('assets')}" id="asset" role="tabpanel" aria-labelledby="asset-tab" *ngIf="shouldWidgetBeDisplayed('asset')">
                        <app-asset *ngIf="objTriggerFetch['assets']" [strRecordId]="recordId" [strModule]="strModule" (refreshChecklist)="refreshWidget($event)"></app-asset>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *fcHasWidgetPermission="'opportunities'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('opportunities-module')}" id="opportunity-module" role="tabpanel" aria-labelledby="opportunity-module-tab" *ngIf="shouldWidgetBeDisplayed('opportunity-module')">
                    <app-opportunities *ngIf="objTriggerFetch['opportunities-module']" [strRecordId]="recordId"  [strModule]="strModule"></app-opportunities>
                </div>
            </ng-container>

            <!--div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('tasks')}" id="task" role="tabpanel"
                aria-labelledby="task-tab" *ngIf="shouldWidgetBeDisplayed('task')">
                <app-calendar-tasks *ngIf="objTriggerFetch['tasks'] || shouldWidgetBeDisplayed('tasks')" (fcUpdateResources)="onEventEmit($event)" ></app-calendar-tasks>
            </div-->

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('pricebook_items')}" id="pricebook_items" role="tabpanel" aria-labelledby="pricebook-items-tab" *ngIf="shouldWidgetBeDisplayed('pricebook_items')">
                <!--
                <div class="tab-content">
                    <div class="d-flex justify-content-around activities-option tabs-content">
                        <span (click)="addPricebookItems()">
                            <fa-icon [icon]="['fal', 'file-upload']"></fa-icon>
                            {{ 'upload_file' | translate }}
                        </span>
                    </div>
                </div>
                -->
            </div>

            <!-- widget: recurring_invoices -->
            <ng-container *fcHasWidgetPermission="'recurring_invoices'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('recurring_invoices')}" id="recurring_invoice" role="tabpanel" aria-labelledby="recurring_invoices-tab" *ngIf="shouldWidgetBeDisplayed('recurring_invoice')">
                    <app-recurring-invoices *ngIf="objTriggerFetch['recurring_invoices'] || isTabActiveByDefault('recurring_invoices')" [strRecordID]="recordId" [strModule]="strModule"></app-recurring-invoices>
                </div>
            </ng-container>

            <ng-container *fcHasWidgetPermission="'jobs'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('jobs-asset-jobs')}" id="jobs-asset-job" role="tabpanel"
                    aria-labelledby="jobs_asset-jobs-tab" *ngIf="shouldWidgetBeDisplayed('jobs-asset-job')">
                    <app-jobs-asset-jobs *ngIf="objTriggerFetch['jobs-asset-jobs'] || shouldWidgetBeDisplayed('jobs-asset-jobs')" [strRecordId]="recordId" (fcUpdateResources)="onEventEmit($event)" ></app-jobs-asset-jobs>
                </div>
            </ng-container>

            <ng-container *fcHasWidgetPermission="'customer_invoices'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('customer-invoices-from-recurring-invoices')}" id="customer-invoices-from-recurring-invoice" role="tabpanel" *ngIf="shouldWidgetBeDisplayed('customer-invoices-from-recurring-invoice')">
                    <customer-invoices-from-recurring-invoices *ngIf="objTriggerFetch['customer-invoices-from-recurring-invoices'] || shouldWidgetBeDisplayed('customer-invoices-from-recurring-invoice')" (fcUpdateResources)="onEventEmit($event)" ></customer-invoices-from-recurring-invoices>
                </div>
            </ng-container>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('asset-photos')}" id="asset-photo" role="tabpanel" aria-labelledby="file-tab" *ngIf="shouldWidgetBeDisplayed('asset-photo')">
                <app-asset-photos *ngIf="objTriggerFetch['asset-photos'] || isTabActiveByDefault('asset-photos')" [strRecordId]="recordId"  [strModule]="strModule" [objFetchedRecordDetails]="objFetchedRecordDetails"></app-asset-photos>
            </div>

            <ng-container *fcHasWidgetPermission="'jobs'">
                <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('jobs-from-recurring-jobs')}" id="jobs-from-recurring-job" role="tabpanel" *ngIf="shouldWidgetBeDisplayed('jobs-from-recurring-job')">
                    <jobs-from-recurring-jobs *ngIf="objTriggerFetch['jobs-from-recurring-jobs'] || shouldWidgetBeDisplayed('jobs-from-recurring-job')" (fcUpdateResources)="onEventEmit($event)" ></jobs-from-recurring-jobs>
                </div>
            </ng-container>

            <div class="tab-pane" [ngClass]="{active: isTabActiveByDefault('asset-location-history')}" id="asset-location-history" role="tabpanel" aria-labelledby="file-tab" *ngIf="shouldWidgetBeDisplayed('asset-location-history')">
                <app-asset-location-history *ngIf="objTriggerFetch['asset-location-history'] || isTabActiveByDefault('asset-location-history')" [strRecordId]="recordId"  [strModule]="strModule" [objFetchedRecordDetails]="objFetchedRecordDetails"></app-asset-location-history>
            </div>

            <!--
                For any modules that have widgets only they
                use, please add them here.
            -->
            <ng-container *ngIf="custom_widgets"
                [ngTemplateOutlet]="custom_widgets"
                [ngTemplateOutletContext]="{
                    objTriggerFetch: objTriggerFetch,
                    isTabActiveByDefault: isTabActiveByDefault,
                    shouldWidgetBeDisplayed: shouldWidgetBeDisplayed
                }">
            </ng-container>
            <!-- /end widget -->

        </div>
    </ng-container>



    <ng-template #noWidgets>
        <span>{{ 'no_widgets_avaiable' | translate }}</span>
    </ng-template>
</div>
