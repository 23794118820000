<div class="d-flex h-100 flex-column">

  <div class="d-flex">
    <h3 class="title">
      <fa-icon [icon]="['fas', 'comments-dollar']"></fa-icon>&nbsp;
      <span>{{ 'sms_credits_balance' | translate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="selfDialog.close()" class="btn btn-danger">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr class="w-100 mt-0">
  <mat-dialog-content>
    <div class="row mb-3">
      <div class="col-md-12">
        <div class="text-center">
          {{ 'sms_info' | translate }}
          <br>
          <h1 *ngIf="numCredits != null">{{ numCredits }}</h1>
          <fa-icon *ngIf="numCredits == null" [icon]="['fas','spinner']" class="mt-2 fa-2x" [spin]="true"></fa-icon>
        </div>
      </div>
    </div>
    <div class="form-group row no-margin">
      <div class="col-sm-1">
        <mat-slide-toggle color="primary" [(ngModel)]="bNotifyLowCredits" class="value-text mt-2" name="isChecklistEnabled" (ngModelChange)="updateSmsSettings()">
        </mat-slide-toggle>
      </div>
      <label
        class="col col-form-label m-0"
        [ngClass]="{'text-secondary': !bNotifyLowCredits}">
        {{ 'autocharge_sms' | translate }}
      </label>
      <div class="col-sm-4 m-0">
        <div class="form-row">
          <div class="col-8">
            <input class="form-control m-0" [(ngModel)]="numCreditThreshold" type="number" [disabled]="!bNotifyLowCredits"/>
          </div>
          <div class="col">
            <button type="submit" (click)="updateSmsSettings(false)" class="btn btn-primary mb-2 w35 h100" [disabled]="!bNotifyLowCredits">
              <button-icon-loader [defaultIcon]="['fas', 'save']" [isLoading]="bAutochargeLoader"></button-icon-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr class="w-100 mt-0">
    <div class="form-group row no-margin">
      <div class="col m-0">
        <label class="col-form-label m-0">
          {{ 'purchase_sms_package_info' | translate }}
        </label>
      </div>
      <div class="col-2 m-0">
        <button type="submit" (click)="purchaseAddOn()" class="btn btn-primary btn-block h100 mb-2">
          <button-icon-loader [defaultIcon]="['fas', 'shopping-cart']" [isLoading]="bPurchaseLoader"></button-icon-loader>
          {{ 'purchase' | translate }}
        </button>
      </div>
    </div>

  </mat-dialog-content>
</div>
