export class SmsTemplate {
  id: string;
  name: string;
  message: string;
  module: string;

  constructor(properties: {
    id: string;
    name: string;
    message: string;
    module: string;}) {

    this.name = properties.name;
    this.id = properties.id;
    this.name = properties.name;
    this.message = properties.message;

  }
}
