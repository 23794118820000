<app-form-header
    [label]="arFileDetails.file_name"
    [icon]="getFileType(arFileDetails.file_type, arFileDetails.file_name)"
    [style]="formHeaderIconStyle(arFileDetails)"
    [cancel]="true"
    [save]="true"
    [saveConfig]="saveConfig"
    (actionResponse)="actionEvent($event)">
</app-form-header>
<div class="body" *ngIf="arVersionList != undefined">
    <div class="row label">
        <div class="col-sm-3">
            {{ 'version' | translate }}
        </div>
        <div class="col-sm-3">
            {{ 'file_size' | translate }}
        </div>
        <div class="col-sm-6">
            {{ 'updated_at' | translate }}
        </div>
    </div>
    <div *ngFor="let strVersion of arVersionList; let i = index">
        <div class="row data">
            <div class="col-sm-3">
                {{ i + 1 }}
            </div>
            <div class="col-sm-3">
                {{ formatFileSize(strVersion.size) }}
            </div>
            <div class="col-sm-6">
                {{ formatDate(strVersion.date_updated) }}
            </div>
        </div>
    </div>
</div>
<hr class="separator"/>
<div class="col-sm-12">
    <form [formGroup]="fileUploadForm" (ngSubmit)="onSubmit()">
        <drag-and-drop-file-upload-input [multiple]="false" formControlName="file"></drag-and-drop-file-upload-input>
        <!-- <div class="row">
            <button type="submit" class="btn btn-primary ml-auto mr-0 mt-1" [disabled]="!fileUploadForm.valid || !isFileUploaded">
                <fa-icon [icon]="['fas', isFileUploaded ? 'file-upload' : 'spinner']" [spin]="!isFileUploaded"></fa-icon>
                <span class="pl-1" translate>{{ (isFileUploaded) ? 'upload' : 'uploading' }}</span>
            </button>
        </div> -->
    </form>
</div>
