<header>
  <div class="d-flex">
    <!-- title -->
    <h3 class="title"><fa-icon [icon]="['fas', 'file-alt']" class="mr-3"></fa-icon>{{ 'schedule_import' | translate }}</h3>

    <!-- actions -->
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <!-- cancel -->
        <li class="list-inline-item">
          <button type="button" class="btn ml-1 btn-primary" (click)="onCloseDialog()" id="close-btn">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
              <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <!-- /cancel -->

        <!-- submit -->
        <li class="list-inline-item">
          <button type="button" class="btn ml-1 btn-primary" [disabled]="form.invalid" (click)="onSchedule()" id="close-btn">
              <fa-icon [icon]="['fas', 'clock']"></fa-icon>
              <span class="pl-1">{{ 'schedule' | translate }}</span>
          </button>
        </li>
        <!-- /submit -->
      </ul>
    </div>
  </div>
</header>

<!-- seperator -->
<hr class="seperator"/>
<!-- /seperator -->

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="form-group">
      <label for="modifiedSince" class="control-label">{{ 'modified_since' | translate }}</label>
      <div class="input-group">
        <input
          id="modifiedSince"
          type="text"
          class="form-control date-input"
          formControlName="modifiedSince"
          [owlDateTime]="dateTimeField">
          <button class="calendar-button" [owlDateTimeTrigger]="dateTimeField">
            <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
          </button>
          <owl-date-time id="datetime-modifiedSince" #dateTimeField></owl-date-time>
      </div>
    </div>
  </form>
</mat-dialog-content>
