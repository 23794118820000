import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';

import { PipesModule } from './pipes.module';
import { MaterialModule } from './material.module';
import { DirectivesModule } from './directives.module';
import { FormsModule as FMCFormsModule } from '../modules/forms.module';
import { RequiredTagComponent } from '../components/simple/required-tag.component';
import { InlineViewLoaderComponent } from '../../shared/components/inline-view-loader/inline-view-loader.component';
import { ButtonIconLoaderComponent } from '../../shared/components/button-icon-loader/button-icon-loader.component';
import { NoRecordFoundComponent } from '../../shared/components/no-record-found/no-record-found.component';
import { DialogHeaderComponent } from '../components/dialog/dialog-header/dialog-header.component';
import { DialogButtonComponent } from '../components/dialog/dialog-button/dialog-button.component';
import { RefreshPaginationButtonComponent } from '../components/common/buttons/pagination/refresh-pagination.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { AlertComponent } from '../alert/alert.component';
import { PaginationLinksComponent } from '../components/pagination/pagination-links.component';
import { IconComponent } from '../components/icons/icon.component';
import { PrimaryButtonComponent } from '../components/common/buttons/primary-button.component';
import { DialogTitleComponent } from '../components/dialog/dialog-title.component';
import { ErrorsAlertComponent } from '../alert/errors-alert.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    MaterialModule,
    DirectivesModule,
    PipesModule,
  ],
  declarations: [
    InlineViewLoaderComponent,
    RequiredTagComponent,
    ButtonIconLoaderComponent,
    NoRecordFoundComponent,
    DialogHeaderComponent,
    DialogButtonComponent,
    RefreshPaginationButtonComponent,
    DialogComponent,
    AlertComponent,
    PaginationLinksComponent,
    IconComponent,
    PrimaryButtonComponent,
    DialogTitleComponent,
    ErrorsAlertComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    MaterialModule,
    NgSelectModule,
    PipesModule,
    InlineViewLoaderComponent,
    RequiredTagComponent,
    DirectivesModule,
    FMCFormsModule,
    ButtonIconLoaderComponent,
    NoRecordFoundComponent,
    RefreshPaginationButtonComponent,
    DialogHeaderComponent,
    DialogButtonComponent,
    DialogComponent,
    AlertComponent,
    PaginationLinksComponent,
    IconComponent,
    PrimaryButtonComponent,
    DialogTitleComponent,
    ErrorsAlertComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [] // TranslateService
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}
