
<header *ngIf="! asChild">
    <div class="d-flex dialog-header">
        <h3 class="dialog-title" translate>
            <fa-icon [icon]="['fas', 'line-columns']" transform="shrink-3" class="dialog-title mr-2"></fa-icon>{{ 'work_order_items' | translate }}
        </h3>
        <div class="ml-auto d-flex">
            <ul class="list-inline pull-right">
                <li class="list-inline-item">
                    <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
                        <fa-icon [icon]="['fas', 'times']"></fa-icon><span class="pl-1">{{ 'cancel' | translate }}</span>
                    </button>
                </li>
                <li class="list-inline-item">
                    <button (click)="onSubmit()" [disabled]="bSubmit" class="btn ml-1 btn-primary">
                        <button-icon-loader [defaultIcon]="['fas', 'save']" [isLoading]="bSubmit"></button-icon-loader>
                        <span class="pl-1">{{ 'save' | translate }}</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <hr />
</header>
<mat-dialog-content class="mobile-view-dialog" *ngIf="! asChild">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-dialog-content>

<ng-container *ngIf="asChild">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content>
    <div class="col-12">
        <div class="row">
            <div class="col-4">
                <span class="pricebook-label">{{ 'select_pricebook' | translate }}</span>
                <app-edit-field [item]="objPricebookRelateField" [form]="objFormGroup" #selectPricebook></app-edit-field>
            </div>
        </div>
    </div>
    <div class="col-12 mt-3 mb-2">
        <div class="row">
            <div class="col-12">
                <browse-products
                    (objProductSelected)="addItem($event)"
                    [strPricebookId]="objFormGroup.controls['pricebook_id'].value"
                    [overrideUnitPriceWithDiscountPrice]="false"
                >
                </browse-products>
            </div>
        </div>
    </div>

    <div class="col-lg-12">
        <table class="table border-left border-right border-bottom" style="table-layout: fixed;">
            <thead>
                <tr>
                    <th scope="col" width="8%"></th>
                    <th scope="col" [colSpan]="bDepartmentTracking ? 1 : 2" style="font-size: 12px;">{{ 'description' | translate }}</th>
                    <th *ngIf="bDepartmentTracking" scope="col" width="12%" style="font-size: 12px;">{{ 'department' | translate }}<required-tag></required-tag></th>
                    <th scope="col" width="10%" style="font-size: 12px;">{{ 'quantity' | translate }}</th>
                    <th scope="col" width="10%" style="font-size: 12px;">{{ 'unit_price' | translate }}</th>
                    <th scope="col" width="10%" style="font-size: 12px;">{{ 'discounted_price' | translate }}</th>
                    <th scope="col" width="14%" style="font-size: 12px;">{{ 'tax_code' | translate }}<required-tag></required-tag></th>
                    <th scope="col" width="14%" style="font-size: 12px;">{{ 'line_total' | translate }}</th>
                </tr>
            </thead>
            <tbody *ngIf="bLoading">
                <tr><td colspan="9" align="center"><fa-icon [icon]="['fas','spinner']" [spin]="true" aria-hidden="true"></fa-icon></td></tr>
            </tbody>
            <tbody *ngIf="!bLoading" cdkDropList (cdkDropListDropped)="onLineDrop($event)">
                <tr *ngIf="arLineItems.length === 0">
                    <td [attr.colspan]="bDepartmentTracking ? '9' : '8'" align="center">
                        <div class="row p-2">
                        <div class="col-12 text-center">{{ 'empty_work_order_label' | translate }}</div>
                        </div>
                    </td>
                </tr>
                <ng-container *ngFor="let attr of arLineItems; index as numLineItemIndex">
                    <tr cdkDrag>
                        <td scope="col" style="padding: 0px;">
                            <div class="d-flex flex-row">
                                <fieldmagic-icon
                                    cdkDragHandle
                                    class="p-2"
                                    icon="grip-vertical"
                                >
                                </fieldmagic-icon>
                                <fieldmagic-icon
                                    *ngIf="arLineItems.length > 0"
                                    variant="light"
                                    icon="minus-circle"
                                    class="pl-1 pr-1 pb-2 pt-2 text-danger pointer"
                                    (click)="onLineRemove(numLineItemIndex, attr)"
                                >
                                </fieldmagic-icon>
                                <a *ngIf="attr.item_id"
                                    href="/#/items/{{ attr.item_id }}"
                                    class="pl-1 pr-1 pb-2 pt-2"
                                    target="_blank"
                                >
                                    <fieldmagic-icon
                                        class="text-primary"
                                        icon="info-circle"
                                        matTooltipClass="tooltip-space"
                                        matTooltip="
                                        {{ 'click_to_view' | translate }}&#13;&#13;
                                        {{ 'product' | translate }}: {{ attr['item_name'] }}&#13;
                                        {{ 'code' | translate }}: {{ attr['item_code']  | placeholdWithString }}&#13;
                                        {{ 'current_stock_level' | translate }}: {{ attr['current_stock_level'] }}"
                                    >
                                    </fieldmagic-icon>
                                </a>
                              <fa-icon
                                *ngIf="attr.related_products.length > 0"
                                matTooltip="{{ 'add_related_products' | translate }}"
                                class="pl-1 pr-1 pb-2 pt-2 pointer text-success"
                                [icon]="['fas', 'plus-circle']"
                                (click)="addRelated(attr)">
                            </fa-icon>
                            </div>
                          </td>
                        <td [colSpan]="bDepartmentTracking ? 1 : 2">
                            <textarea
                                class="form-control"
                                style="font-size: 12px;"
                                placeholder="{{ 'description' | translate }}"
                                [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="attr['description']"
                                (ngModelChange)="onLineDescriptionChange(numLineItemIndex, attr)"
                                (focus)="expandInput($event.target)"
                                (focusout)="shrinkInput($event.target)"
                                [attr.data-testid]="'input_line_description:' + numLineItemIndex"
                                id="inputLineDescription_{{ numLineItemIndex }}">
                            </textarea>
                        </td>
                        <td *ngIf="bDepartmentTracking">
                            <ng-select
                                [items]="arDepartmentList"
                                [hideSelected]="true"
                                [clearable]="true"
                                (ngModelChange)="onLineDepartmentChange(numLineItemIndex, $event, attr)"
                                [ngModelOptions]="{standalone: true}"
                                bindLabel="text"
                                bindValue="id"
                                placeholder="{{ 'department' | translate }}"
                                [(ngModel)]="attr.department_id"
                                [id]="attr['id'] + 'department'"
                                [name]="attr['id'] + 'department'"
                                [ngClass]="{'is-invalid': (!attr['department_id']) && bErrors}">
                            </ng-select>
                        </td>
                        <td>
                            <fieldmagic-decimal-input
                                customClass="form-control input-height-40 font-size-12"
                                placeholder="{{ 'quantity' | translate }}"
                                [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="attr['quantity']"
                                (change)="onLineQuantityChange(numLineItemIndex, attr)"
                                max="{{attr['current_stock_level']}}"
                                [invalid]="attr.quantity < 0 && bErrors"
                            ></fieldmagic-decimal-input>
                        </td>
                        <td>
                            <fc-currency-input
                                [places]="4"
                                id="unit_price_{{ i }}"
                                [(ngModel)]="attr['unit_price']"
                                [ngModelOptions]="{standalone: true}"
                                [nonNegative]="true"
                                [customClass]="'form-control font-size-12 input-height-40'"
                                (change)="onLineUnitPriceChange(numLineItemIndex, attr)"
                              >
                            </fc-currency-input>
                        </td>
                        <td>
                            <fc-currency-input
                                [places]="4"
                                id="discounted_price_{{ i }}"
                                [(ngModel)]="attr['discounted_price']"
                                [ngModelOptions]="{standalone: true}"
                                [nonNegative]="true"
                                [customClass]="'form-control font-size-12 input-height-40'"
                                (change)="onLineDiscountedPriceChange(numLineItemIndex, attr)"
                              >
                            </fc-currency-input>
                        </td>
                        <td>
                            <ng-select
                                *ngIf="objTaxCodeObv[attr.id]"
                                [items]="objTaxCodeObv[attr.id].source | async"
                                [addTag]="addTag"
                                [typeahead]="objTaxCodeObv[attr.id].typehead"
                                [loading]="objTaxCodeObv[attr.id].loader"
                                [hideSelected]="true"
                                [clearable]="true"
                                [ngModelOptions]="{standalone: true}"
                                bindLabel="text"
                                bindValue="id"
                                placeholder="{{ 'tax_code' | translate }}"
                                [(ngModel)]="attr.tax_code_id"
                                [id]="attr['id'] + 'tax'"
                                [name]="attr['id'] + 'tax'"
                                (change)="onLineTaxChange(numLineItemIndex, attr, { selected: $event })"
                                [class.is-invalid]="(attr['tax_code_id'] | blank) && (bErrors || parentWasValidated)">
                            </ng-select>
                        </td>
                        <td>
                            <fc-currency-input
                                id="total_price_{{ i }}"
                                [(ngModel)]="attr.computeLineItem"
                                [ngModelOptions]="{standalone: true}"
                                [nonNegative]="true"
                                [customClass]="'form-control font-size-12 input-height-40'"
                                (change)="onLineTotalChange(numLineItemIndex, attr)"
                              >
                            </fc-currency-input>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div *ngIf="!bLoading" class="col-lg-12 mt-1">
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <th scope="row" width="70%" class="invisible"></th>
                    <td scope="row" width="14%" ><span class="p-2 float-right">{{ 'total_ex_tax' | translate }}</span></td>
                    <td scope="row" width="16%" class="text-center">
                      <span class="p-2" matTooltip="{{ intAmountWithoutTax | currency: {decimal_places: 4} }}">{{ intAmountWithoutTax | currency }}</span>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="invisible"></th>
                    <td scope="row"><span class="p-2  float-right">{{ 'tax' | translate }}</span> </td>
                    <td scope="row" class="text-center">
                      <span class="p-2" matTooltip="{{ intTax | currency: {decimal_places: 4} }}">{{ intTax | currency }}</span>
                    </td>
                </tr>
                <tr >
                    <th scope="row" class="invisible"></th>
                    <td scope="row"><span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span> </td>
                    <td scope="row" class="text-center">
                      <span class="p-2" matTooltip="{{ intAmountWithTax | currency: {decimal_places: 4} }}">{{ intAmountWithTax | currency }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>