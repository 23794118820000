import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CustomEditFieldComponent, Form, FormMode } from '../../../base/form';
import { LooseObject } from '../../../objects/loose-object';
import { NotificationService } from '../../../services/notification.service';
import { RelatedProductDisplay } from '../view/components/related-products.component';
import { RecordService } from '../../../services/record.service';
import { ViewService } from '../../../services/view.service';
import { get } from 'lodash';

@Component({
  selector: 'fc-editform-related-products',
  template: `
    <div class="row">
      <div class="col-12 mb-2 text-right">
      <browse-products
        [bSearchable]="false"
        [bAllowCustom]="false"
        [bHideQuantity]="true"
        (objProductSelected)="addItem($event)">
      </browse-products>
      </div>
      <div class="col-12">
        <table
          class="table table-bordered">
          <thead>
            <tr>
              <th colspan="5" class="text-center fmc-input-label">{{ 'related_products' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-template #emptyItemCodesMessage>
              <tr>
                <td class="text-center text-muted" colspan="4">{{ 'no_related_products' | translate }}</td>
              </tr>
            </ng-template>
            <ng-container *ngIf="arItems.length > 0; else emptyItemCodesMessage">
              <tr *ngFor="let item of arItems; let i = index;">
                <td class="form-group" width="20%">
                  <div><b>{{ 'item_code' | translate }}</b></div>
                  <div>{{ item['item_code'] | translate }}</div>
                </td>
                <td class="form-group" width="20%">
                  <div><b>{{ 'item_name' | translate }}</b></div>
                  <div>{{ item['item_name'] | translate }}</div>
                </td>
                <td class="form-group">
                  <div><b>{{ 'description' | translate }}</b></div>
                  <div>{{ item['description'] | translate | slice:0:128 }}</div>
                </td>
                <td class="form-group">
                  <button
                    id="remove_additional_code_{{ i }}"
                    type="button"
                    class="btn btn-link text-danger"
                    (click)="removeItem(i)"
                    matTooltip="{{ 'remove' | translate }}">
                    <fa-icon [icon]="['fal', 'minus-circle']"></fa-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  `
})
export class RelatedProductsComponent implements CustomEditFieldComponent<RelatedProductDisplay> {

  /**
   * The parent form group where the field belongs to
   */
  parentForm: FormGroup;

  /**
   * The current field which represents by this custom field component
   */
  field: Form<RelatedProduct[]>;

  /**
   * The related products to display.
   */
  arItems: RelatedProductDisplay[] = [];

  /**
   * Holds the parent item it.
   *
   * @var {string}
   */
  strParentId: string;

  /**
   * Contains the item codes
   */
  get items(): FormArray {
    return this.parentForm.get(this.field.key) as FormArray;
  }

  constructor(
    private notif: NotificationService,
    private view: ViewService
  ) {
  }

  /**
   * @inheritdoc
   */
  setFormMode(formMode: FormMode): void {}

  /**
   * @inheritdoc
   */
  setParentForm(parentForm: FormGroup): void {
    this.parentForm = parentForm;

  }

  /**
   * @inheritdoc
   */
  setField(field: Form<any>): void {

    this.field = field;
    this.arItems = field.default_value;
    field.default_value.forEach(item => {
      this.items.push(new FormGroup({
        child_item_id: new FormControl(item['child_item_id'])
      }));
    });

    let objRecord = this.view.getViewRecord();
    if (get(objRecord, 'id')) {
      this.strParentId = objRecord['id'];
    }

  }

  /**
   * Removes item from the list and form.
   *
   * @param {number} numIndex
   */
  removeItem(numIndex: number): void {

    const numItemIndex = this.items.value.findIndex(image => image.child_item_id === this.arItems[numIndex].child_item_id)

    if (numItemIndex !== -1) {
      this.items.removeAt(numItemIndex);
    }

    this.arItems.splice(numIndex, 1);
  }

  /**
   * Add item to form and list.
   *
   * @param {LooseObject} objData
   */
  addItem(objData: LooseObject): void {

    let numIndex = this.arItems.findIndex(item => {
      return item['child_item_id'] == objData['id']
    });

    if (numIndex > -1) {
      this.notif.notifyWarning('item_already_exists');
    } else if (objData['id'] == this.strParentId) {
      this.notif.notifyWarning('cannot_set_self');
    } else {

      this.arItems.push({
        child_item_id: objData['id'],
        item_name: objData['name'],
        item_code: objData['code'],
        description: objData['description']
      });

      this.items.push(new FormGroup({
        child_item_id: new FormControl(objData['id'])
      }));
    }

  }
}

export type RelatedProduct = {
  child_item_id: string;
}