import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { get, has, isUndefined, keys, omitBy, reduce, set } from 'lodash';
import { BehaviorSubject, combineLatest, defer, Subject, Subscription } from 'rxjs';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { SimplePaginationMetadataResponseBody } from '../../../objects/http/api-response-body';
import { NotificationService } from '../../../services/notification.service';
import { ShareableModuleFile } from '../objects/api-response-body';
import { FileSharingModalComponentData } from '../objects/modals/file-module-data';
import { SharingService } from '../services/sharing.service';

@Component({
  selector: 'ds-files-sharing-modal',
  templateUrl: './file-sharing.modal.component.html',
  styleUrls: [
    './file-sharing.modal.component.scss',
  ]
})
export class FileSharingModalComponent implements OnInit, OnDestroy {
  /**
   * Form instance
   *
   * @type {FormGroup}
   */
  readonly form = new FormGroup(
    reduce(
      get(this.data, ['initialFilesResponse', 'items'], []),
      (prev, curr) => set(prev, curr.id, new FormControl(false)),
      {}
    ),
  );

  /**
   * A flag to indicate that the list is being loaded
   *
   * @type {BehaviorSubject}
   */
  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * A flag to indicate that the list is being saved
   *
   * @type {BehaviorSubject}
   */
  readonly isSaving$ = new BehaviorSubject<boolean>(false);

  /**
   * A subject that contains the list of files
   *
   * @type {Subject}
   */
  readonly files$ = new BehaviorSubject<ShareableModuleFile[]>(
    get(this.data, ['initialFilesResponse', 'items'], [])
  );

  /**
   * A subject that contains the  pagination metadata
   *
   * @type {Subject}
   */
  readonly pagination$ = new BehaviorSubject<SimplePaginationMetadataResponseBody>(
    get(this.data, ['initialFilesResponse', 'pagination'])
  );

  /**
   * A subject that emits to which indicates the current page
   *
   * @type {Subject}
   */
  readonly page$ = new Subject<string|number>();

  /**
   * Contains subscriptions for the component
   *
   * @type {Subscription[]}
   */
  protected subscriptions: Subscription[] = [];

  /**
   * Create instance of the file sharing modal component
   *
   * @param {FileSharingModalComponentData} data
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly data: FileSharingModalComponentData,
    protected readonly dialog: MatDialogRef<FileSharingModalComponent>,
    protected readonly sharing: SharingService,
    protected readonly notifications: NotificationService,
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.listenToPageChanges()
    );

    // if we don't have initial response we would then fetch it from the
    // server by triggering a page change.
    if (isUndefined(this.data.initialFilesResponse)) {
      this.page$.next(undefined);
    }
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Handle skip action
   *
   * @returns {void}
   */
  onSkip(): void {
    this.dialog.close();
  }

  /**
   * Handles paginate action
   *
   * @param {number | string} page
   */
  onPaginate(page: number | string): void {
    this.page$.next(page);
  }

  /**
   * Handle share action
   *
   * @returns {void}
   */
  onShare(): void {
    const ids = keys(
      omitBy(this.form.value, (isChecked) => ! isChecked)
    );

    if (ids.length < 1) {
      this.notifications.notifyWarning('no_files_to_be_shared_selected');
      return;
    }

    defer(() => {
      this.isSaving$.next(true);

      return this.sharing.shareRecord(ids, 'files');
    }).pipe(
      take(1),
      finalize(() => this.isSaving$.next(false)),
      tap(() => this.notifications.notifySuccess('record_successfully_shared_message')),
    ).subscribe(() => {
      this.dialog.close();
    });
  }

  /**
   * Handles select all action
   *
   * @param {HTMLInputElement} target
   * @param {ShareableModuleFile[]} files
   *
   * @returns {void}
   */
  onSelectAll(target: HTMLInputElement, files: ShareableModuleFile[]): void {
    const values = reduce(files, (prev, curr) => {
      return set(prev, curr.id, target.checked);
    }, {});

    this.form.patchValue(values);
  }

  /**
   * Load files from the server
   *
   * @returns {Subscription}
   */
  protected listenToPageChanges(): Subscription {
    return combineLatest([
      this.page$
    ]).pipe(
      tap(() => this.isLoading$.next(true)),
      switchMap(([page]) => {
        return this.sharing.getShareableModuleFiles({
          moduleIds: this.data.moduleIds,
          moduleName: this.data.moduleName,
          page,
        }).pipe(
          finalize(() => this.isLoading$.next(false)),
        );
      }),
      tap((response) => {
        this.files$.next(response.items);
        this.pagination$.next(response.pagination);
      }),
      tap((response) => {
        response.items.forEach((file) => {
           if (! has(this.form.controls, file.id)) {
            this.form.addControl(file.id, new FormControl);
           }
        })
      })
    ).subscribe()
  }
}

