
    <div class="row">
      <div class="col-12">
        <table
          class="table table-bordered">
          <thead>
            <tr>
              <th colspan="5" class="text-center fmc-input-label">{{ 'supplier_pricing_table' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-template #emptyItemCodesMessage>
              <tr>
                <td
                  id="no_additional_codes"
                  class="text-center text-muted"
                  colspan="3">{{ 'no_item_codes_defined' | translate }}</td>
              </tr>
            </ng-template>

            <ng-container *ngIf="items.length > 0; else emptyItemCodesMessage">
              <tr *ngFor="let item of items; let i = index; ">
                <td class="form-group w-20">
                  <label class="font-weight-bold" for="code">
                    {{ 'supplier_code' | translate }}
                  </label>
                  <p
                    id="additional_code_{{ i }}"
                    class="mb-0">
                    {{ item.code | placeholdWithString }}
                  </p>
                </td>
                <td class="form-group w-35">
                  <label class="font-weight-bold" for="supplier">{{ 'supplier_name' | translate }}</label>
                  <p
                    id="additional_code_supplier_{{ i }}"
                    class="mb-0">
                    {{ item.customer_text | placeholdWithString }}
                  </p>
                </td>
                <td class="form-group w-20">
                  <label class="font-weight-bold" for="unit_cost">{{ 'default_unit_cost' | translate }}</label>
                  <p
                    id="additional_code_unit_cost_{{ i }}"
                    class="mb-0">
                    {{ item.unit_cost | currency: {decimal_places: 4} | placeholdWithString }}
                  </p>
                </td>
                <td class="form-group w-20">
                  <label class="font-weight-bold" for="lead_time">{{ 'lead_time' | translate }} ({{ 'days' | translate }})</label>
                  <p
                    id="additional_code_lead_time_{{ i }}"
                    class="mb-0">
                    {{ item.lead_time | placeholdWithString }}
                  </p>
                </td>
                <td class="form-group w-20">
                  <label class="font-weight-bold" for="notes">{{ 'notes' | translate }}</label>
                  <p
                    id="additional_code_notes_{{ i }}"
                    class="mb-0"
                    style="white-space: pre-line; text-align: start;">
                    {{ item.notes | placeholdWithString }}
                  </p>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  