import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ChecklistsComponent } from './checklists.component';
import { CreateChecklistComponent } from './create-checklist/create-checklist.component';
import { UpdateChecklistComponent } from './update-checklist/update-checklist.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', component: ChecklistsComponent
  },
  {
    path: '', 
    children: [
      { path: 'create', component: CreateChecklistComponent }
    ]
  },
  {
    path: '', 
    children: [
      { path: 'edit/:id', component: UpdateChecklistComponent }
    ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ChecklistsRoutingModule { }