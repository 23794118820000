import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Client } from '../objects/client';
import { LocalStorageService } from './local-storage.service';
import { StrService } from './helpers/str.service'
import * as initHelpHero from 'helphero';
import { environment } from '../../../src/environments/environment';

/**
 * The clients list stored in local storage has this format:
 * <client id>: <client object>
 * We can use this type when getting the list of clients.
 *
 * @type {{ [key: string]: Client }}
 */
export type ClientListObject = { [key: string]: Client };

@Injectable({
  providedIn: 'root'
})
export class ClientStoreService {

  /**
   * The active client's data. We'll use this to publish client changes
   * to the subscribers.
   *
   * @type {BehaviorSubject<Client>}
   */
  private activeClient: BehaviorSubject<Client> = new BehaviorSubject<Client>(null);

  /**
   * Public observable for accessing the activeClient BehaviorSubject.
   *
   * @type {Observable<Client>}
   */
  public activeClient$: Observable<Client> = this.activeClient.asObservable();

  /**
   * An observable for any changes to the active client. We will
   * monitor "watch" any changes to the active client so that we may
   * be able to emit events for when it happens.
   *
   * @type Observable<Client>
   */
  public readonly whenActiveClientIsSet$: Observable<Client> = this.activeClient$
    .pipe(
      filter(client => client !== null)
    );

  /**
   * This is the exact opposite of whenActiveClientIsSet$. This will only
   * emit values if there is no active client.
   *
   * @type Observable<Client>
   */
  public readonly whenActiveClientIsRemoved$: Observable<Client> = this.activeClient.asObservable()
    .pipe(
      filter(activeClient => activeClient === null)
    );

  constructor(protected localStorageService: LocalStorageService, protected strService: StrService) {
    if (this.localStorageService.hasItem('current_client')) {
      // If we have a client in local storage, let's broadcast it to the entire app.
      this.setActiveClient(this.localStorageService.getJsonItem('current_client'));
    }
  }

  /**
   * Returns the current client's data.
   *
   * @returns {Client}
   */
  getActiveClient(): Client {
    let currentClient = this.activeClient.getValue();

    if (!! currentClient) {
      // Note: Used type any due to client Address type accepting
      // json string address. It cant be parse if we dont use any.
      let clientAddress: any = currentClient.address;
      currentClient.address = this.strService.isValidJsonString(clientAddress) ? JSON.parse(clientAddress) : clientAddress;
    }

    return currentClient;
  }

  /**
   * Stores the active client in local storage for later use.
   *
   * @param {Client} activeClient
   *
   * @returns {void}
   */
  setActiveClient(activeClient: Client){
    // Make sure we remove the last accessed dashboard when changing clients.
    this.localStorageService.removeItem('last_dashboard_accessed');
    this.localStorageService.replaceJsonItem('current_client', activeClient);
    this.setHelpHeroUser();
    this.activeClient.next(this.localStorageService.getJsonItem('current_client'));
  }

  /**
   * Stores the previous selected client in local storage for later use.
   *
   * @param {Client} activeClient
   *
   * @returns {void}
   */
  setPreviousClient(activeClient: Client){
    // Make sure we remove the last accessed dashboard when changing clients.
    this.localStorageService.replaceJsonItem('previous_client', activeClient);
  }

  /**
   * Function that set the user/role/name for HelpHero to identify.
   */
  setHelpHeroUser() {
    let objData = this.localStorageService.getJsonItem('current_client');
    let strId = this.localStorageService.getItem('user_id');
    let strName = this.localStorageService.getItem('user_name');

    initHelpHero.default('rZzjEVSgKoC').identify(strId, {
      name: strName,
      role: objData['level']
    });
  }

  /**
   * Removes the current client from local storage and emits
   * an event to all activeClient subscribers.
   *
   * @returns {void}
   */
  removeActiveClient() {
    this.localStorageService.removeItem('current_client');
    this.activeClient.next(null);
  }

  /**
   * Removes the previous client from local storage and emits
   * an event to all activeClient subscribers.
   *
   * @returns {void}
   */
  removePreviousClient() {
    this.localStorageService.removeItem('previous_client');
    this.activeClient.next(null);
  }

  /**
   * check if current admin is client
   *
   * @returns {boolean}
   */
  isAdmin(): boolean {
    return this.activeClient.getValue().level === UserAccessLevel.admin;
  }

  /**
   * Retrieved the event observable that will be triggered when client is set/selected
   *
   * NOTE: this will allow testability of the components/directives/etc that relies on this event which would allows
   * us to mock the property using the jasmine.createSpyObj helper.
   *
   * @returns {Observable<Client>}
   */
  getWhenClientIsSetEvent(): Observable<Client> {
    return this.whenActiveClientIsSet$;
  }

  /**
   * Check if department tracking enabled.
   *
   * @return {boolean}
   */
  isDepartmentTracking(): boolean {
    return this.activeClient.getValue().config.department_tracking || false;
  }
}

export enum UserAccessLevel {
  admin = 'admin',
  desktop = 'desktop',
  mobileFull = 'mobile-premium',
  mobileLite = 'mobile-lite',
}