import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountingSystemService } from '../../../services/accounting_system.service';
import { LocalStorageService } from '../../../../../../services/local-storage.service';
import * as moment from 'moment';
import { Client } from '../../../../../../objects/client';

@Component({
  selector: 'integrations-accounting-systems-notification-no-installed-driver',
  templateUrl: './no-installed-driver.component.html',
  styleUrls: [
    './no-installed-driver.component.scss'
  ]
})
export class NoInstalledDriverComponent {
  /**
   * determines if the current notification should be displayed
   *
   * @var {boolean}
   */
  shouldDisplay: boolean = false;

  /**
   * INTERNAL USE: contains the list of subscrtions that will be cleaned up on destroy lifecycle
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * @param {AccountingSystemService} service
   */
  constructor(
    private service: AccountingSystemService,
    private storage: LocalStorageService
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    if (! this.storage.hasItem('no_installed_driver_notif_expires_in')
      && (this.storage.getItem('no_installed_driver_notif_expires_in') || 0) < moment().unix()
      && this.isAdminUser()
    ) {
      this.subscriptions.push(
        this.service.hasIntegratedDriver$()
          .subscribe((result: boolean) => this.shouldDisplay = ! result)
      );
    }
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * handles when user clicks on the close button
   *
   * @returns {void}
   */
  onClose(): void {
    this.shouldDisplay = false;
    this.storage.setItem('no_installed_driver_notif_expires_in', moment().add(1, 'day').unix());
  }

  /**
   * determines if the client is an admin user
   *
   * @returns {boolean}
   */
  protected isAdminUser(): boolean {
    let client: Client = this.storage.getJsonItem('current_client') || {};
    return client.level === 'admin';
  }
}
