import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class PusherService {

  /**
   * The current user's private pusher channel
   * where we subscribe to notifications event.
   *
   * @var {any}
   */
  private privatePusher: PusherTypes.Channel;

  constructor(private localStorage: LocalStorageService) {

  }

  /**
   * Retrieves the pusher instance of this service.
   *
   * @returns {any}
   */
  getPrivatePusherInstance(): PusherTypes.Channel {
    return this.privatePusher;
  }

  /**
   * Create a pusher subscription to listen
   * for events.
   *
   * @param {string} strThreadId
   *
   * @return {any}
   *  - Can't typecast it cause Pusher's subscription is
   *    a tad bit different compared to RxJS's subscription.
   */
  getPusherPublic(strThreadId: string): PusherTypes.Channel {
    let pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster
    });
    return pusher.subscribe(strThreadId + '-channel');
  }

  /**
   * Create a pusher subscription to listen
   * for events.
   *
   * @param {string} strThreadId
   *
   * @return {PusherTypes.PresenceChannel}
   *  - Can't typecast it cause Pusher's subscription is
   *    a tad bit different compared to RxJS's subscription.
   */
  getPusherPresence(strThreadId: string): PusherTypes.Channel {
    let strUserId = this.localStorage.getItem('user_id');
    let strUserName = this.localStorage.getItem('user_name');
    let strAccessToken = this.localStorage.getItem('access_token');

    let pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      authEndpoint: environment.pusher.auth_endpoint + 'presence',
      auth: {
        params: {
          token: strAccessToken,
          user_id: strUserId,
          display_name: strUserName
        },
      }
    });
    return pusher.subscribe('presence-' + strThreadId + '-channel');
  }

  /**
   * Create a pusher subscription to listen
   * for events.
   *
   * @param {string} strThreadId
   *
   * @return {PusherTypes.Channel}
   */
  getPusherPrivate(strUserId: string): PusherTypes.Channel {
    let strAccessToken = this.localStorage.getItem('access_token');

    let pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      authEndpoint: environment.pusher.auth_endpoint + 'private',
      auth: {
        params: {
          token: strAccessToken
        }
      }
    });
    return pusher.subscribe('private-' + strUserId + '-channel');
  }
}
