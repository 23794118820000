import * as _ from 'lodash';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { get } from 'lodash';
import { Observable, } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { RecordService } from '../../../../services/record.service';
import { LambdaService } from '../../../../services/lambda.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { AccountService } from '../../../../services/account.service';
import { ViewService } from '../../../../services/view.service';
import { ChecklistsService } from '../../../../services/checklist.service';

import { EmailComponent } from '../../../../shared/components/widget/email/email.component';
import { ChecklistAttachmentsComponent } from '../../widget/email/checklist-attachments/checklist-attachments.component';

import { currency_codes } from "../../../../../assets/api/currency_codes.json";
import { LooseObject } from '../../../../objects/loose-object';
import { cloneDeep, isObject } from 'lodash';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { parse as parseJSON } from "../../../../shared/utils/json";

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {

  @ViewChild('content') content: ElementRef;
  @ViewChild('iframePDF') iframePDF: ElementRef;

  public strHTML: any;
  public objRecord: any;
  public objUrl: object = {};
  public strLabel: string = '';
  public strModule: string = '';
  public bError: boolean = false;
  public bLoading: boolean = true;
  public bReady: boolean = false;
  public objViewRecord = {};

  public bDownload: boolean = false;
  public bSendEmail: boolean = false;

  public objAttachment: LooseObject[] = [];
  public strDownloadLink: string = '';

  /**
   * Identifier so the user knows that
   * chat sending is currently in process.
   *
   * @var {boolean}
   */
  public bSendChat: boolean = false;

  /**
   * Contains the module data
   *
   * @var {LooseObject}
   */
    documentModuleData: LooseObject = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(DOCUMENT) private document: any,
    private dialogRef: MatDialogRef<PdfComponent>,
    private lambdaService: LambdaService,
    private sanitizer: DomSanitizer,
    private notifService: NotificationService,
    private renderer: Renderer2,
    private recordService: RecordService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private viewService: ViewService,
    private accountService: AccountService,
    private route: Router,
    private checklistsService: ChecklistsService
  ) {
    this.strModule = data.module;
    this.strLabel = data.label;
    // Get client config, We need to make sure that we have updated pdf config
    this.accountService.getClientConfig().subscribe( response => {
      let objPDFConfig = (response && response['pdf']) ? response['pdf'] : {};
      let strCurrency = (response && response['currency']) ? response['currency'] : '';
      // Append these values for realtime view
      data.response.currency = currency_codes.find(currencies => (currencies.code == strCurrency)) || currency_codes[0];
      data.response.language = this.localStorageService.getItem('user_locale');
      // Set client config
      data.response.config = objPDFConfig;
      // Format Request
      this.objRecord = this.formatRequest(data.response);
      // Get html url
      this.getHTMLUrl();
    });

    this.objViewRecord = this.viewService.getViewRecord();
  }

  ngOnInit() {
  }

  /**
   * Get HTML url that is uploaded on s3
   *
   * @returns {void}
   */
  getHTMLUrl(): void {
    this.lambdaService.getPreviewUrl(this.objRecord).subscribe( response => {
      if (response && response['success']) {
        this.strHTML = this.sanitizer.bypassSecurityTrustHtml(response['success']['html']);
        this.bReady = true;
      } else {
        this.strHTML = null;
        this.bError = true;
      }
      this.bLoading = false;
    }, error => {
      this.strHTML = null;
      this.bError = true;
      this.bLoading = false;
    });
  }

  /**
   * Prepares the data (email address, attachment generation)
   * then calls the email sending dialog with the prepared data
   *
   * @returns {void}
   */
  prepareEmailData(): void {
    if (this.bReady) {

      this.bSendEmail = true;
      let arEmail = [];
      let strContactId = this.objRecord.record.contact_id;
      // Do we have contact id?
      if (!_.isEmpty(strContactId)) {
        // Get contact record
        this.recordService.getRecord('contacts', strContactId).subscribe( response => {
          if (!_.isEmpty(response['record_details']['email_address'])) {
            // Loop through available emails.
            response['record_details']['email_address'].forEach( strEmail => {
              // Create email dropdown
              arEmail.push({ text: response['record_details']['text'], 'email_address': strEmail });
            });
          }
        });
      }

      this.objRecord.request_type = 'pdf';
      this.lambdaService.getPreviewUrl(this.objRecord).subscribe( response => {
        let url = get(response, ['success', 'url'], null);
        let metadata = get(response, ['success', 'metadata'], null);

        if (url && metadata) {
          this.strDownloadLink = url;
          this.objAttachment = this.objAttachment.concat(metadata);
          this.emailDialog(arEmail);
        } else {
          this.notifService.sendNotification('file_not_found', 'failed_to_attach', 'danger', 5000);
        }
      });
    }
  }

  /**
   * If it came from customer invoice widget or record and has atleast one checklist,
   * ask if user wants to attach checklist. If user selects, add it to email attachment
   * otherwise, just open the email dialog with default attachment
   * 
   * @returns {LooseObject[]}
   */
   sendEmail(): void {
    if (this.objRecord['module'] === 'tax_invoices') {
      let jobId = this.viewService.arRecordConfig['module'] === 'customer_invoices' ?
        get(this.viewService.getRecord(), ['record_details', 'job_id'], null) :
        this.viewService.arRecord['id'];

      if (jobId) {
        this.checklistsService.getJobRelatedRecords(jobId).subscribe(data => {
          let arAssetChecklist = get(data, ['body', 'asset_type_checklist'], []);
          let arJobChecklist = get(data, ['body', 'job_checklist'], []);

          if (data.status === 200 && (arAssetChecklist.length + arJobChecklist.length !== 0)) {
            this.notifService.sendConfirmation('attach_checklist_confirmation', 'attach_checklist_confirmation_header')
            .subscribe( confirmStream => {
              if (confirmStream.answer) {
                this.dialog.open(ChecklistAttachmentsComponent, {
                  width: '70%',
                  height: 'auto',
                  data: { checklists: get(data, ['body']) },
                  disableClose: true
                }).afterClosed().subscribe(response => {
                  if (response['is_cancelled']) {
                    this.prepareEmailData();
                  } else {
                    let observables = [];
                    let combinedResponses = response['job_checklists'].concat(response['asset_type_checklists']);

                    combinedResponses.forEach(checklist => {
                      let objWhereClause =  {
                        job_id: jobId,
                        checklist_id: checklist['checklist_id']
                      };

                      observables.push(this.recordService.getPDFRecordData('checklist_responses', null, objWhereClause).pipe(
                        switchMap(data => {
                          let arRecord = data.body;
                          if (arRecord) {
                            arRecord.request_type = 'pdf';
                            return this.lambdaService.getPreviewUrl(arRecord).map(response => {
                              return response['success']['metadata'];
                            });
                          }
                        })
                      ));
                    });
                    
                    Observable.forkJoin(observables).subscribe(response => {
                      this.objAttachment = this.objAttachment.concat(response);
                      this.prepareEmailData();
                    })
                  }
                });
              } else {
                this.prepareEmailData();
              }
            });
          } else {
            this.prepareEmailData();
          }
        });
      }
    } else {
      this.prepareEmailData();
    }
  }

  /**
   * Show the email component dialog
   *
   * @param {array} arEmail
   * @returns {void}
   */
  emailDialog(arEmail = []) : void {
    if (this.objAttachment) {
      let strModule = this.viewService.arRecordConfig['module'];

      // Set data for email
      let objEmailData = {
        width: '80%',
        height: 'auto',
        data: {
          module: strModule,
          record_id: this.objViewRecord['id'],
          is_draft: false,
          quote_data: {
            subject: this.objRecord['label'],
            attachment: this.objAttachment,
            send_to: arEmail
          },
          email_type: 'pdf'
        },
        disableClose: true
      };
      // Close all previous opened dialogs.
      this.dialog.closeAll();

      // Open the email dialog.
      this.dialog.open(EmailComponent, objEmailData);
    } else {

      this.notifService.sendNotification('file_not_found', 'failed_to_attach', 'danger', 5000);
    }

    this.bSendEmail = false;
  }

  /**
   * Download the current pdf
   *
   * @returns {void}
   */
  downloadPDF(): void {
    if (this.bReady) {

      let bRequest: boolean = true;
      this.bDownload = true;
      this.objRecord.request_type = 'pdf';
      // Do we have existing download link?
      if (!_.isEmpty(this.strDownloadLink)) {
        // Create obj url using  the download link
        let objUrl = new URL(this.strDownloadLink);
        // Get url expiration
        let intExpire = parseInt(objUrl.searchParams.get('Expires'));
        // Get current timestamp in seconds
        let currentSeconds = Math.floor(new Date().getTime()/1000.0);
        // Compare seconds and divide it to 60 to make it minutes
        if ((currentSeconds - intExpire)/60 < 15) {
          bRequest = false;
          this.bDownload = false;
          this.document.location.href = this.strDownloadLink;
        }
      }

      // Do we need to request a new pdf config?
      if (bRequest) {
        // Get pdf config
        this.lambdaService.getPreviewUrl(this.objRecord).subscribe( response => {
          if (response['success']) {
            if (response['success']['url'] && response['success']['metadata']) {
              this.document.location.href = response['success']['url'];
              this.strDownloadLink = response['success']['url'];
              this.objAttachment = response['success']['metadata'];
            } else {
              this.notifService.sendNotification('file_not_found', 'failed_to_download', 'danger', 5000);
            }
          } else {
            this.notifService.sendNotification('file_not_found', 'failed_to_download', 'danger', 5000);
          }
          this.bDownload = false;
        });
      }
    }
  }

  /**
   * Format the request and check if we have the required request key
   *
   * @returns {object}
   */
  formatRequest(objResponse): object {
    if (objResponse) {
      // Add request type
      objResponse.request_type = 'html';
      // Add timezone in request
      objResponse.timezone = moment().format('Z');
      // Add generated_date
      objResponse.generated_date = moment().local().format('YYYY-MM-DD HH:mm:ss');
      // If generated_by does not exist
      if (!objResponse.generated_by) {
        // Get current user
        objResponse.generated_by = this.localStorageService.getItem('user_name');
      }

      return objResponse;
    } else {

      this.notifService.sendNotification('warning', 'failed_to_preview_html', 'danger', 5000);
      this.closeDialog();
    }
  }

  /**
   * Close the preview component dialog
   *
   * @returns {void}
   */
  closeDialog() : void {
    this.dialogRef.close();
  }

  /**
   * Create the file as pdf and send that to
   * the chat dialog.
   *
   * @returns {void}
   */
  sendViaChat(): void {

    this.bSendChat = true;
    let objRequest: LooseObject = cloneDeep(this.objRecord);
    objRequest.request_type = 'pdf';

    this.lambdaService.getPreviewUrl(objRequest)
      .subscribe((response: LooseObject) => {

        this.bSendChat = false;
        this.dialogRef.close();

        let queryParamsString = new HttpParams({ fromObject: {
          chat_type: 'file',
          upload_name: response.success.metadata.upload_name,
          file_name: objRequest.file_name + '.' + objRequest.request_type,
          url: btoa(response.success.url)
        } }).toString();

        this.route.navigateByUrl(this.route.url + '?' + queryParamsString);
    });
  }
}
