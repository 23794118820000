import { Observable, of } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { map, tap, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router, UrlTree } from '@angular/router';

import { Client } from '../../objects/client';
import { Notification } from '../../objects/notification';
import { ChargebeeService } from '../../services/chargebee/chargebee.service';
import { ClientStoreService } from '../../services/client-store.service';
import { NotificationService } from '../../services/notification.service';
import { LayoutDashboardService } from '../../shared/layouts/layout-dashboard/layout-dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionIsLiveGuard implements CanActivateChild {

  /**
   * When administrators login to their client, they get to be able to
   * call the /subscription_billing/details API and get their latest
   * subscription data. Now before proceeding to the app, we must wait
   * until that request completes, which in turn updates their client
   * metadata in the app - which is used by this guard.
   *
   * For non-admin users, however, they get to settle with what they
   * got in the WhoAmI API call because the aforementioned API endpoint
   * is exclusive only to administrators.
   *
   * @type {Observable<Client>}
   */
  protected activeClientWithLatestSubscriptionInfo$: Observable<Client> = this.chargebeeService
    .activeClientSubscriptionInfo$
    .pipe(
      mergeMap(subInfo => of(this.clientStoreService.getActiveClient()))
    );

  /**
   * A subscription is considered "live" if its status falls under
   * the following values:
   * 1. in_trial
   * 2. active
   * 3. non_renewing
   *
   * This means that if the client's subscription is "live, they have
   * access to Fieldmagic Cloud.
   *
   * @see https://angular.io/api/router/CanActivate#canactivate
   * @see https://juristr.com/blog/2018/11/better-route-guard-redirects/
   * @see {@link https://github.com/angular/angular/issues/25837#issuecomment-434049467} as to why
   * we're wrapping our call to the setActiveClient function. Basically, when a client is set,
   * our subscription-is-live guard will "hear" the changes to the client and determine if
   * the current page the user is currently on is accessible with its current subscription
   * plan. If not, the user will be thrown back out to the client selection page. Now here's
   * the kicker - this logic can be triggered from anywhere - even outside of the angular zone
   * (e.g., a Chargebee callback function), which Angular doesn't know about so it sets out a
   * warning. And since Angular does not know who the redirect command came from, the page crashes.
   *
   * @returns {Observable<boolean | UrlTree>}
   */
  protected whenSubscriptionIsLive$: Observable<boolean | UrlTree> = this.clientStoreService
    .whenActiveClientIsSet$
    .pipe(
      mergeMap(activeClient => activeClient.level === 'admin' ? this.activeClientWithLatestSubscriptionInfo$ : of(activeClient)),
      map(activeClient => this.chargebeeService.subscriptionIsLive(activeClient)),
      map(subscriptionIsLive => subscriptionIsLive === true || this.router.parseUrl('/account/selection')),
      tap((subscriptionIsLive) => {
        if (subscriptionIsLive !== true) {
          // The client's subscription isn't live so the user will be redirected back to the
          // client selection page. To keep them in the loop, we need to let them know
          // what just happened.
          this.notifyUserAboutSubscriptionError();


          this.ngZone.run(() => {
            // As per Angular's documentation (@see), we can return a URL Tree here so that
            // instead of manually redirecting to a different route (after determining that the
            // user cannot access a route), we let Angular do it. Why? Because using multiple
            // route guards that all redirect to a different page might cause the
            // user to be thrown around different pages. If we return a URLTree however,
            // Angular will intelligently decide where to redirect the user.
            // But here's the catch: Returning a boolean (which in this case, "true") AND THEN
            // returning a URLTree does not redirect the user and i'm not sure why. As a workaround,
            // we just *sigh* fallback to manually redirecting the user. Which defeats the purpose
            // of returning a URLTree. But let's leave it here for the meantime, just in case
            // a fix comes out.  -Peter
            // this.router.navigateByUrl('/account/selection');
            this.layoutDashboardService.switchClient();
          });

          this.clientStoreService.removePreviousClient();
        }
      }),
      tap(subscriptionIsLive => window['loading_screen'].finish()),
    );

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private chargebeeService: ChargebeeService,
    private translateService: TranslateService,
    private clientStoreService: ClientStoreService,
    private notificationService: NotificationService,
    private layoutDashboardService: LayoutDashboardService
  ) { }

  /**
   * Child routes whose [canActivate] attribute contains this guard will only
   * be accessible if the client's subscription is considered "live".
   *
   * @see {@link https://stackoverflow.com/questions/44697159/angular-4-canactivate-observable-not-invoked} for why
   * we're wrapping our observable inside another observable.
   *
   * @param childRoute
   * @param state
   *
   * @returns {Observable<true | UrlTree>}
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.whenSubscriptionIsLive$;
  }

  /**
   * Notify the user that there is an error with this client's subscription
   * so that they can take action.
   *
   * @returns {void}
   */
  private notifyUserAboutSubscriptionError(): void {
    let subscriptionStatusErrorMessage = this.chargebeeService.getSubscriptionNotLiveErrorMessage(
      this.clientStoreService.getActiveClient().subscription_status
    );

    subscriptionStatusErrorMessage = `${subscriptionStatusErrorMessage} ${this.translateService.instant('please_contact_admin_for_subscription_assistance')}`;

    this.notificationService.sendNotification(
      'subscription_error', subscriptionStatusErrorMessage, 'danger', 5000
    );
  }
}
