<header>
  <div class="d-flex">
    <h3 class="dialog-title" translate>
      <div>
        {{ 'select_files_to_link' | translate }}
      </div>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="cancelDialog()" class="btn btn-primary mr-2">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
          <button class="btn btn-primary float-right mr-2" (click)="linkFilesToNewlyConvertedJobRecord()" [disabled]="bDisabled">
            <fa-icon [icon]="['fas', 'link']"></fa-icon>
            <span class="pl-1">{{ 'link_files' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<mat-dialog-content>
    <div class="row">
      <div class="col-md-12 text-center">
        <table class="table table-striped w100p">
          <thead>
            <tr>
              <th class="table-header">{{ 'file_name' | translate }}</th>
              <th class="table-header">{{ 'description' | translate }}</th>
              <th class="table-header"><input type="checkbox" (click)="selectAllFiles($event)"/></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of objOpportunityFiles, index as fileIndex">
              <td class="td-description text-center">{{ file['file_name']}}</td>
              <td class="td-description text-center">{{ file['description']}}</td>
              <td class="td-description text-center">
                <input type="checkbox" [checked]="file['checked']" (click)="selectAFile($event, file['id'], fileIndex)"/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</mat-dialog-content>