<div class="widget-container">
  <div class="container">
    <div class="row pt-2 pb-2">
      <div class="col-sm-6 pt-1">
        <span class="module-title">
          <fa-icon [icon]="['fas', 'sync-alt']" aria-hidden="true"></fa-icon>
          &nbsp;
          {{ "asset_location_history" | translate }}
        </span>
      </div>
      <div class="col-sm-6 pr-2">
        <nav class="pagination-section">
          <ul class="pagination">
            <refresh-pagination-btn
              id="widgetAssetsJobsRefreshButton"
              (refresh)="onRefresh()"
              [isDisabled]="bLoading"
            ></refresh-pagination-btn>
            <li
              [ngClass]="
                listingService.strFirstValue !=
                  listingService.strCurrentValue &&
                listingService.strCurrentValue != ''
                  ? 'active pointer'
                  : 'disabled'
              "
              class="page-item"
            >
              <a
                id="widgetAssetsJobsPreviousButton"
                class="page-link"
                aria-label="prev"
                (click)="getRecordData('prev')"
              >
                <fa-icon
                  [icon]="['far', 'chevron-left']"
                  aria-hidden="true"
                ></fa-icon>
              </a>
            </li>
            <li
              [ngClass]="
                listingService.strNextValue != ''
                  ? 'active pointer'
                  : 'disabled'
              "
              class="page-item"
            >
              <a
                id="widgetAssetsJobsNextButton"
                class="page-link"
                aria-label="next"
                (click)="getRecordData('next')"
              >
                <fa-icon
                  [icon]="['far', 'chevron-right']"
                  aria-hidden="true"
                ></fa-icon>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div *ngIf="arRecordData.length < 1" align="center">
      <div class="row border-top" *ngIf="!bLoading">
        <span class="no-content">
          {{ "no_asset_location_history" | translate }}
        </span>
      </div>
      <div class="row border-top" *ngIf="bLoading">
        <span class="no-content">
          <fa-icon
            [icon]="['fas', 'spinner']"
            [spin]="true"
            aria-hidden="true"
          ></fa-icon>
          {{ "loading" | translate }}
        </span>
      </div>
    </div>

    <div
      *ngFor="let record of arRecordData; let i = index"
      class="row border-top pt-2 pb-2 pr-2 pr-0"
      [id]="'widgetAssetsJobsRecord' + record.id"
    >
      <div class="col-sm-4">
        <div class="row p-0">
          <div
            class="col-12"
            matTooltip="{{ 'transferred_date' | translate }}: {{
              record.created_at | local | format : 'lll'
            }}"
          >
            <span> {{ record.created_at | local | format : "lll" }} </span>
          </div>
        </div>

        <div class="row p-0">
          <div
            class="col-12 truncate"
            matTooltip="{{ 'type_of_move' | translate }}: {{
              record.type_of_move | translate
            }}"
          >
            <span class="word-wrap">{{ record.type_of_move | translate }}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row p-0">
          <div
            class="col-12 truncate"
            matTooltip="{{ 'customer' | translate }}: {{
              record.customer_text
            }}"
          >
            <span class="word-wrap">
              {{ checkValue(record.customer_text) }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="row p-0">
          <div
            class="col-12 truncate"
            matTooltip="{{ 'location' | translate }}: {{ record.site_text }}"
          >
            <span class="word-wrap"> {{ checkValue(record.site_text) }} </span>
          </div>
        </div>

        <div class="row p-0">
          <div
            class="col-12 truncate"
            matTooltip="{{ 'expected_date_return' | translate }}: {{
              record.expected_date_return | local | format : 'lll'
            }}"
          >
            <span *ngIf="record.expected_date_return" class="word-wrap">{{
              checkValue(record.expected_date_return) | local | format : "lll"
            }}</span>
            <span *ngIf="!record.expected_date_return" class="word-wrap">{{
              "--"
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
