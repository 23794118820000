import { Component, ContentChild, Input } from '@angular/core';
import { MatStepper } from '@angular/material';
import { AssetData } from '../../../objects/asset-data';
import { Summary } from '../../../objects/wizard';

@Component({
  selector: 'summary-step',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent {

  /**
   * The html template for the next button.
   * Design depends on the parents.
   */
  @ContentChild('next') next;

  /**
   * The main stepper.
   *
   * @var {MatStepper}
   */
  @Input() stepper: MatStepper;

  /**
   * Values of the forms to make
   * a summarization.
   *
   * @var {Summary}
   */
  @Input() summary: Summary;

  /**
   * The module the wizard is working on.
   *
   * @var {string}
   */
  @Input() strRecordType: string;

  /**
   * Contains asset data when creating job from asset.
   *
   * @var {AssetData}
   */
  @Input() objAssetData: AssetData;

  /**
   * Flag that checks if the job has a customer or not.
   *
   * @var {boolean}
   */
  @Input() bHasNoCustomer: boolean = false;
}
