import { OnInit, Component, OnDestroy } from "@angular/core";
import { ViewService } from '../../../../../../services/view.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'integrations-accounting-systems-notification-failed-sync',
  templateUrl: './failed-sync.component.html'
})
export class FailedSyncComponent implements OnInit, OnDestroy {
  /**
   * determines if the current notification should be displayed
   *
   * @var {boolean}
   */
  shouldDisplay: boolean = false;

  /**
   * INTERNAL USE: contains the list of subscrtions that will be cleaned up on destroy lifecycle
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * @param {ViewService} view
   */
  constructor(
    private view: ViewService
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.view.onModuleRecordView$()
        .subscribe(() => {
          let viewDetails = this.view.getViewRecord();
          let errorDetail = (viewDetails.accounting_sync_detail || '').trim();

          this.shouldDisplay = (viewDetails.accounting_sync_error == true && errorDetail !== '');
        })
    );
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
