<div>
    <div class="d-flex justify-content-around activities-option tabs-content">
        <span id="widgetFilesUpload" (click)="openFileForm()" class="span-links">
            <fa-icon [icon]="['fas', 'upload']" class="text-success"></fa-icon>
            {{ 'upload_file' | translate }}
        </span>
    </div>

    <div class="files-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 pt-4 pb-4">
                    <div class="form-inline">
                        <div class="form-group flex-nowrap w-100">
                            <div class="col-lg-4">
                                <label class="jc-l">{{ 'filter_by_name' | translate }}</label>
                            </div>
                            <div class="input-group col-lg-8">
                                <input id="widgetFilesNameFilter"type="text" class="form-control input-search" [(ngModel)]="strFilterFile">
                                <button id="widgetFilesNameFilterButton" (click)="setFilters()" class="btn-search">
                                    <fa-icon [icon]="['fa', 'search']"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form-inline mt-12">
                        <div class="form-group flex-nowrap w-100">
                            <div class="col-lg-4">
                                <label class="jc-l">{{ 'sort_file' | translate }}</label>
                            </div>
                            <div class="col-lg-8">
                                <ng-select
                                    [items]="arSortFilter"
                                    [clearable]="false"
                                    class="value-text"
                                    (change)="setFilters()"
                                    [(ngModel)]="selectSortFile"
                                    id="widgetFilesSortFilter">
                                    <ng-template ng-label-tmp let-item="item">{{ item | translate }}</ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                      <span id="widgetFilesSortFilter{{ item }}">{{ item | translate }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row border-top pt-2 pb-2">
                <div class="col-sm-6 pt-1">
                    <span class="module-title">
                        <fa-icon [icon]="['fa', 'file-alt']" aria-hidden="true"></fa-icon>
                        &nbsp;
                        {{ 'uploaded_files' | translate }}
                    </span>
                </div>
                <div class="col-sm-6 pr-2">
                    <nav class="pagination-section">
                        <ul class="pagination">
                            <refresh-pagination-btn id="widgetFilesRefreshButton" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
                            <li [ngClass]="(widgetService.objToken['files']['prev']) ? 'active' : 'disabled'" class="page-item">
                                <a id="widgetFilesPreviousButton" class="page-link" aria-label="prev" (click)="setPage('prev')">
                                    <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                                </a>
                            </li>
                            <li [ngClass]="(widgetService.objToken['files']['next']) ? 'active' : 'disabled'" class="page-item">
                                <a id="widgetFilesNextButton" class="page-link"  aria-label="next" (click)="setPage('next')">
                                    <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div *ngIf="arFilesList.length < 1">
                <div class="row border-top" *ngIf="!bLoading">
                    <span class="no-content">
                        {{ 'no_files' | translate }}
                    </span>
                </div>
                <div class="row border-top" *ngIf="bLoading">
                    <span class="no-content">
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                        {{ 'loading' | translate }}
                    </span>
                </div>
            </div>

            <div class="row border-top"  *ngFor="let file of arFilesList" [id]="'widgetFilesRecord' + file.id">
                <div
                    *ngIf="strModule !== 'jobs' || strRecordId === file.job_id"
                    class="col-sm-2 file-icons"
                >
                    <mat-slide-toggle matTooltip="{{ 'set_file_to_public' | translate }}" color="primary" [(ngModel)]="file.public" (ngModelChange)="markAsPublic(file)" class="value-text mt-2" ></mat-slide-toggle>
                </div>
                <div class="col-sm-1 file-icons">
                    <fa-icon [icon]="['fal', getFileType(file.file_type, file.file_name)]" [ngStyle]="{'color': getColorsPerType(file.file_type, file.file_name) }"></fa-icon>
                </div>
                <div class="col-sm-4 file-texts" matTooltip="{{ 'description' | translate }}: {{ file.description | placeholdWithString }}">
                    <span>{{ file.file_name  }}</span>
                </div>
                <div class="col-sm-4 file-texts">
                    <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>&nbsp;
                    {{ file.updated_at| humanReadableDate | placeholdWithString }}
                </div>
                <div class="col-sm-1">
                    <div class="row pt-0 pb-0 justify-content-center" *ngIf="file.editable">
                        <fa-icon id="widgetFilesEditButton" [icon]="['fas', 'pencil']" class="file-icon-edit" matTooltip="{{ 'edit' | translate }}" (click)="editFile(file)"></fa-icon>
                    </div>
                    <div class="row pt-0 pb-0 justify-content-center">
                        <fa-icon id="widgetFilesPreviewButton" *ngIf="file.file_type == 'application/pdf'" [icon]="['fas', 'eye']" class="file-icon-preview" matTooltip="{{ 'preview' | translate }}" (click)="previewFile(file.upload_name, file.file_name)" ></fa-icon>
                    </div>
                    <div class="row pt-0 pb-0 justify-content-center">
                        <fa-icon id="widgetFilesPreviewButton" *ngIf="file.file_type.startsWith('image/')" [icon]="['fas', 'eye']" class="image-icon-preview"  matTooltip="{{ 'preview' | translate }}" (click)="previewImage(file.id)" ></fa-icon>
                    </div>
                    <div class="row pt-0 pb-0 justify-content-center">
                        <fa-icon id="widgetFilesDownloadButton" *ngIf="file.file_type.startsWith('image/') === false"  [icon]="['fas', 'download']" class="file-icon-download" matTooltip="{{ 'download_file' | translate }}" (click)="downloadFile(file.upload_name, file.file_type, file.file_name)" ></fa-icon>
                    </div>
                    <div class="row pt-0 pb-0 justify-content-center">
                        <fa-icon id="widgetFilesDownloadButton" *ngIf="file.file_type.startsWith('image/') === true"  [icon]="['fas', 'download']" class="file-icon-download" matTooltip="{{ 'download_file' | translate }}" (click)="downloadImage(file.id)" ></fa-icon>
                    </div>
                    <div class="row pt-0 pb-0 justify-content-center" *ngIf="file.deletable">
                        <fa-icon id="widgetFilesDeleteButton" [icon]="['fas', 'trash-alt']" class="file-icon-trash" matTooltip="{{ 'delete_file' | translate }}" (click)="delete(file.id)" ></fa-icon>
                    </div>

                    <div class="row pt-0 pb-0 justify-content-center" *ngIf="! file.is_shared && strModule === 'jobs'">
                        <ds-share-btn
                            module-name="files"
                            [module-id]="file.id"
                            (on-shared)="onRefresh()"
                        >
                        </ds-share-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
