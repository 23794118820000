import { Component, OnInit, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { ExportService } from './../../../services/export.service';
import { NotificationService } from './../../../services/notification.service';
import { Select } from './../../../objects/select';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  /**
   * selected module
   *
   * @var
   */
  public moduleSelected: string = null;

  /**
   * list of modules
   *
   * @var
   */
  public moduleList = [
    'leads', 'customers', 'sites', 'contacts', 'opportunities',
    'jobs', 'assets', 'items', 'pricebooks', 'pricebook_items',
    'recurring_jobs', 'contact_roles', 'users'
  ];

  /**
   * determine if the request is still loading
   *
   * @var
   */
  public loading: boolean = false;

  /**
   * determine if export button is click
   *
   * @var
   */
  public isExport: boolean = false;

  /**
   * determine if export all button is click
   *
   * @var
   */
  public isExportAll: boolean = false;

  /**
   * determine if the module is required
   */
  public isModuleRequired: boolean = false;

  /**
   * return the list of modules
   *
   * @get
   */
  public get getModules(): Array<Select> {
    return this.moduleList.map( module => new Select(module, module));
  }

  constructor(
    protected matDialogRef: MatDialogRef<FormsComponent>,
    protected exportService: ExportService,
    protected notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) private record
  ) {
  }

  ngOnInit() {
  }

  /**
   * export record
   */
  export(isAll: boolean = false): void {
    this.isModuleRequired = false
    if (!isAll) {

      this.isModuleRequired = true;
    }
    if (this.moduleSelected && this.isModuleRequired || isAll) {
      this.isModuleRequired = false;
      this.notificationService.sendConfirmation((isAll) ? 'export_confirm_message_all' : 'export_confirm_message', 'exports')
      .filter(confirmation => confirmation.answer == true)
      .subscribe(() => {

        this.loading = true;
        this.isExport = !isAll;
        this.isExportAll = isAll;
        let module = (isAll) ? this.moduleList : [this.moduleSelected];
        this.exportService.exportRecord(JSON.stringify(module)).subscribe( response => {
          if (response.status === 200) {
            this.notificationService.notifySuccess('export_successfully');
            this.cancelDialog();
          }
          this.loading = false;
          this.isExport = false;
          this.isExportAll = false;
        }, error => {
          if (error.status == 400 || error.status == 422) {
            if (error.error.request_limit) {
              this.notificationService.notifyWarning('has_reached_maximum_export_per_day');
            }
          }
          this.loading = false;
          this.isExport = false;
          this.isExportAll = false;
        });
      });
    }
  }

  /**
   * cancel the dialog
   */
  cancelDialog(): void {
    this.matDialogRef.close(null);
  }
}
