import { Observable, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

export class WebpackTranslateLoader implements TranslateLoader {
  /**
   * When the translation is being updated, this function triggers.
   * @param lang
   */
  public getTranslation(lang: string): Observable<any> {
    return of(require(`assets/i18n/${lang}.json`));
  }

  /**
   * This is a fallback when trying to get a translation that does not exist.
   * The original value will be returned instead of a blank or null.
   * @param object
   */
  private process(object: object) {
    return Object.keys(object)
      .filter(key => object.hasOwnProperty(key) && object[key] !== '')
      .reduce((result, key) => (result[key] = typeof object[key] === 'object' ? this.process(object[key]) : object[key], result), {});
  }
}
