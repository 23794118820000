import { Injectable, NgZone } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Observable, of } from 'rxjs';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PushNotificationsService} from 'ng-push';
import { Params, Router } from '@angular/router';
import { Notification } from '../objects/notification';
import { CustomTranslateService } from './custom-translate.service';
import { ClientStoreService } from './client-store.service';
import { Client } from '../objects/client';
import { ClientsListStoreService } from './clients-list-store/clients-list-store.service';
import { LocalStorageService } from './local-storage.service';
import { MatDialog } from '@angular/material';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import _ from 'lodash';

@Injectable()
export class MessagingService {
  public currentNotification: Notification;

  constructor(
    public router: Router,
    protected ngZone: NgZone,
    protected dialog: MatDialog,
    private angularFireMessaging: AngularFireMessaging,
    private notificationService: NotificationService,
    private http: HttpClient,
    private pushNotifications: PushNotificationsService,
    private translator: CustomTranslateService,
    private clientStoreService: ClientStoreService,
    private clientsListStoreService: ClientsListStoreService,
    private localStorageService: LocalStorageService,
  ) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )

    this.pushNotifications.requestPermission();
  }

  /**
   * This will request a permission to allowed us
   * to notify the user
   * @param userId
   */
  requestPermissionToken$(): Observable<string|null> {
    return this.angularFireMessaging.requestToken.pipe(
      take(1),
      map((token) => {
        return token;
      }),
      catchError((err) => {
        // Just for logging the error
        console.log(err);

        return of(null);
      })
    );
  }

  /**
   * This will subscribe the user to a topic
   * @param strUserId
   * @returns
   */
  subscribeTopic$(strUserId: string): Observable<boolean> {
    return this.requestPermissionToken$().pipe(
      switchMap((token) => {
        if (token) {
          return this.subscribeTokenToTopic(token, strUserId);
        } else {
          return of(false);
        }
      })
    )
  }

  /**
   * This will unsubscribe the user to a topic
   * @param strUserId
   * @returns
   */
  unsubscribeTopic$(strUserId: string): Observable<boolean> {
    return this.requestPermissionToken$().pipe(
      switchMap((token) => {
        if (token) {
            return this.unsubscribeTokenToTopic(token, strUserId);
        } else {
          return of(false);
        }
      })
    )
  }

  /**
   * Receiver message of notification
   */
  receiveMessage() {
      this.angularFireMessaging.messages.subscribe(
        (payload) => {
          let activeClient: Client = this.clientStoreService.getActiveClient();
          let link: Links = JSON.parse(payload['data']['link']);
          let activityId: string = payload['data']['activity_id'];
          let senderClientId: string = payload['data']['client_id'];
          let currentClientId: string = (activeClient) ? activeClient.client_id : null;
          let messageBody: string = (payload['notification']) ? payload['notification']['body'] : payload['data']['body'];

          const senderId = _.get(payload, ['data', 'sender_id']);

          // avoid sending notification if the received notification came from the current user
          if (senderId == this.localStorageService.getItem('user_id')) {
            return;
          }

          // This is will handle foreground notif
          if (payload['notification']) {

            let options: NotificationOptions = {
              body: messageBody,
              sticky: true,
            }
            // This will create a notification to notification center (Browser Notif)
            this.pushNotifications.create(payload['notification']['title'], options).subscribe( //creates a notification
              res => {
                this.ngZone.run(() => {
                  // FYI, first notification will trigger the show type
                  // then when you click the notification this will be called again
                  // but with the type of click.
                  if (res['event']['type'] === 'click') {
                    this.notificationService.sendConfirmation('notif_redirect_info', 'new_message')
                    .filter(confirmation => confirmation.answer == true)
                    .subscribe(() => {
                      // After we confirmed to redirect in message
                      // we should close all dialog opened.
                      this.dialog.closeAll();
                      // If the client sender is different from active client
                      // We should switch to that client so we can view the message
                      if (senderClientId !== currentClientId) {
                        let client_list: ClientListObject = this.clientsListStoreService.getClientList();
                        let client: Client = client_list[senderClientId] ? client_list[senderClientId] : null;

                        if (client) {
                          this.clientStoreService.setActiveClient(client);
                          this.bellNotification(messageBody, activityId, link);
                          this.notificationService.notificationMarkAsRead(this.currentNotification);
                        }
                      } else {
                        // When notification is clicked. We need to mark as read the created
                        // bell notification below.
                        this.notificationService.notificationMarkAsRead(this.currentNotification);
                      }
                    });

                  } else if(res['event']['type'] === 'show' && senderClientId === currentClientId) {
                    // For the show type, it will only create the bell notification if the
                    // sender client id is same with receiver client id
                    this.bellNotification(messageBody, activityId, link);
                  }
                });
              },
              err => console.log(err)
            );
          // This will handle background notif
          } else {
            if (senderClientId !== currentClientId) {
              let client_list: ClientListObject = this.clientsListStoreService.getClientList();
              let client: Client = client_list[senderClientId] ? client_list[senderClientId] : null;

              if (client) {
                this.clientStoreService.setActiveClient(client);
              }
            }

            this.bellNotification(messageBody, activityId, link);
            this.notificationService.notificationMarkAsRead(this.currentNotification);
          }
      });
  }

  /**
   * This will create a topic name by (user id)
   * so we can notify the specific user
   * @param token
   * @param topic
   */
  subscribeTokenToTopic(strToken: string, strTopic: string) {
    let body = new URLSearchParams();
    body.append('token', strToken);
    body.append('topic_name', strTopic);

    return this.http.post<Response>(environment.url + "/notifications_subscription/subscribe_topic", body.toString()).pipe(
      map(response => {
        return response['code'] === 200;
      })
    );
  }

  /**
   * This will unsubscribe the token from given topic
   * user will not be able to receive the message
   * @param token
   * @param topic
   */
   unsubscribeTokenToTopic(strToken: string, strTopic: string) {
    let body = new URLSearchParams();
    body.append('token', strToken);
    body.append('topic_name', strTopic);

    return this.http.post<Response>(environment.url + "/notifications_subscription/unsubscribe_topic", body.toString()).pipe(
      map(response => {
        return response['code'] === 200;
      })
    );
  }

  /**
   * This will initialize our push notification
   */
  initializePushNotification() {
    let strUserId: string = this.localStorageService.getItem('user_id');

    if (strUserId) {
      this.subscribeTopic$(strUserId).subscribe();
      this.receiveMessage();
    }
  }

  /**
   * translates a given value
   *
   * @param {string|undefined} value
   *
   * @returns {string|undefined}
   */
   protected translate(value?: string): string|undefined {
    if (! value) return value;

    this.translator.initializeTransalateables([value]);

    return this.translator.getTranslation(value);
  }

  /**
   * This will create a bell notification
   * @param messageBody
   * @param activityId
   * @param link
   */
  protected bellNotification(messageBody: string, activityId: string, link: Links) {
    this.currentNotification = this.notificationService.customNotification({
      id: activityId,
      header: 'new_chat',
      message: messageBody,
      link: link['path'] + '?activity_id=' + activityId,
      theme: 'info',
      type: 'persistent',
      duration: 1,
      is_push_notif: true,
    });
  }

}

export interface NotificationOptions {
  body: string;
  sticky: boolean;
}

export interface Links {
  path: string;
  params?: Params;
}

export type ClientListObject = { [key: string]: Client };