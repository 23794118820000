<a *ngIf="field.key == 'monday'" (click)="copyAll()" href="javascript:void(0)" class="float-right">
  <fa-icon [icon]="['fas', 'copy']" matTooltip="{{ 'copy_availability_all' | translate }}"></fa-icon>
</a>
<div class="row">
  <div class="col-md-6 pr-1">
    <ng-select
      [(ngModel)]="form.value[field.key]['start_time']"
      [ngModelOptions]="{standalone: true}"
      [items]="timeSelection['from']"
      (change)="disableFields($event)"
      placeholder="{{ 'start_time' | translate }}"
      bindLabel="text"
      bindValue="id">
    </ng-select>
  </div>
  <div class="col-md-6 pl-1">
    <ng-select
      [(ngModel)]="form.value[field.key]['end_time']"
      [ngModelOptions]="{standalone: true}"
      [items]="timeSelection['to']"
      placeholder="{{ 'end_time' | translate }}"
      bindLabel="text"
      bindValue="id">
    </ng-select>
  </div>
</div>
