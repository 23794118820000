import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SmsTemplate } from '../objects/sms-template';

@Injectable()
export class SmsService {

  /**
  * The base url for all requests in this service.
  *
  * @returns {string}s
  */
  get strBaseUrl() {
    return environment.url + '/sms_templates/';
  }

  constructor (private http: HttpClient) {}

  /**
   * Get metadata of all modules which have activities widget
   *
   * @type {Observable<Response>}
   */
  getRelatedMetadata(): Observable<Response> {
    return this.http.post<Response>(this.strBaseUrl+ 'get_related_metadata', '');
  }

  /**
   * Get metadata of all modules which have activities widget
   *
   * @type {Observable<SmsTemplate[]>}
   */
  getSmsTemplates(strModule: string): Observable<SmsTemplate[]> {
    return this.http.post<Response>(this.strBaseUrl + 'module/' +strModule, '').map(
      (templates: any) => {
        if (Array.isArray(templates)) {
          return templates.map(template => new SmsTemplate(template));
        } else {
          return [];
        }
      }
    );
  }

}
