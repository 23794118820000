<header>
  <div class="d-flex">
    <h3 class="title">
      <fa-icon [icon]="['fas', 'envelope']" class="mr-2"></fa-icon>
      {{ 'suggestion_box' | translate }}
    </h3>

    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" class="btn btn-primary ml-1" [disabled]="isInProgress" data-testid="cancel-btn" (click)="onCancel()">{{ 'cancel' | translate }}</button>
        </li>
        <li class="list-inline-item">
          <button type="button" class="btn btn-primary ml-1" data-testid="send-btn" (click)="formRef.ngSubmit.emit()" [disabled]="isInProgress || form.invalid">
            <inline-view-loader [isLoading]="isInProgress" [hasText]="false"></inline-view-loader>
            <fa-icon [icon]="['far', 'envelope']" *ngIf="! isInProgress"></fa-icon>
            <span class="ml-1">{{ 'send_inquiry' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>

<!-- seperator -->
<hr class="seperator"/>
<!-- /seperator -->

<mat-dialog-content>
  <form [formGroup]="form" #formRef="ngForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="form-group col-12">
        <textarea
          formControlName="message"
          rows="5"
          class="form-control"
          id="message"
          data-testid="message-input"
          placeholder="{{ 'suggest' | translate }}"
          [class.is-invalid]="form.controls.message.touched && form.controls.message.invalid"
          maxlength="255"
        ></textarea>
      </div>
    </div>
  </form>
</mat-dialog-content>
