export class UploadConfig {
    type = [
        'text',
        'image',
        'application',
    ];

    spreadsheetList = [
        'xlsx',
        'xls',
        'xlr',
        'ods'
    ];

    powerpointList = [
        'odp',
        'key',
        'ppt',
        'pps',
        'pptx'
    ];

    documentList = [
        'doc',
        'docx',
        'odt'
    ];

    archiveList = [
        '7z',
        'deb',
        'pkg',
        'rar',
        'rpm',
        'z',
        'zip'
    ];

    mimetype = 
    {
        'image': 'file-image',
        'video': 'file-video',
        'audio': 'file-audio',
        'text' : 'file-alt'
    }

    iconColors = {
        'image': '#fdd835',
        'video': '#009688',
        'audio': '#00e676',
        'text' : '#b1bfca',
        'pdf'  : '#b71c1c',
        'spreadsheet': '#2e7d32',
        'document': '#1565c0',
        'powerpoint': '#ef5350',
        'archive' : '#4a148c',
        'default'  : '#00363a'
    }
    
};