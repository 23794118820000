import { Component } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FormComponent } from "./form/form.component";

@Component({
  selector: 'safety-management-shared-manage-swms-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  /**
   * @param {MatDialog}
   */
  constructor(
    protected dialogFactory: MatDialog
  ) {}

  /**
   * Opens dialog that contains the form for managing swms
   *
   * @returns {void}
   */
  openDialog(): void {
    this.dialogFactory.open(FormComponent, {
      maxWidth: '98%',
      width: '98%',
      height: 'auto',
      disableClose: false,
    });
  }
}
