
<ng-container *ngIf="shouldDisplay">
    <small id="no-installed-driver-notification" class="d-flex justify-content-between align-items-center p-1">
        <span class="ml-2">
            <fa-icon [icon]="['fas', 'info-circle']" size="lg" class="mr-2"></fa-icon>
            <strong>{{ 'no_installed_accounting_system' | translate }}</strong>
            &nbsp;<a [routerLink]="['/admin/integrations/accounting_systems']" style="color: white;">{{ 'goto_admin_accounting_systems' | translate }}</a>
        </span>
        <fa-icon [icon]="['fas', 'times']" class="mr-2 pointer" id="close-notification-btn" (click)="onClose()"></fa-icon>
    </small>
</ng-container>
