<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'users']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType == 'edit'" >{{ 'edit_task'| translate }} </span>
        <span *ngIf="strViewType == 'add'" >{{ 'create_task'| translate }} </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li *ngIf="strViewType == 'edit' && !bCompleted" class="list-inline-item">
          <button type="button" (click)="markTaskAsCancelled()" class="btn ml-1 btn-danger" [disabled]="! taskForm">
            <fa-icon [icon]="['fas', 'ban']"></fa-icon>
            <span class="pl-1">{{ 'cancel_task' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item" *ngIf="(strViewType !== 'edit' || (strViewType === 'edit' && objEditDetails.editable))">
          <button (click)="onSubmit()" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader)? 'btn-spinner' : ''" [disabled]="! taskForm">
            <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'save']"></fa-icon>
            <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<div *ngIf="objTaskLayout && objTaskField && taskForm && bPageLoaded" class="col-sm-12">
  <form [formGroup]="taskForm">
    <div *ngFor="let objTaskColumn of objTaskLayout" class="row">
      <div *ngFor="let strFieldId of objTaskColumn" class="form-group" [ngClass]="(objTaskColumn.length == 1) ? 'col-sm-12' : 'col-sm-6'">
        <app-edit-field strModule="activities" [strParentModule]="strModule" [item]="objTaskField[strFieldId]" [form]="taskForm" strMode="add" [bSubmitted]="bSubmitted" ></app-edit-field>
      </div>
    </div>
  </form>
</div>
<ng-container *ngIf="!bPageLoaded">
  <div class="col-sm-12" align="center">
    <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
  </div>
</ng-container>