
<app-error-handler onlyFor="stock_levels:view">
  <ng-container *ngIf="objStockLevel">
    <div class="row">
      <div class="header-container col-12">
          <span class="record-title" id="viewHeaderTitle">
            <span class="icon-figure-primary">
              <fa-icon [icon]="['far', 'box-open']"></fa-icon>
            </span> &nbsp;
            <span *ngIf="!bLoading">{{ objStockLevel.product_name | translate}}</span>
          </span>

          <div class="buttons-mobile-view">
            <ng-container>
              <ul class="list-inline float-right">
                <li class="list-inline-item">
                  <a id="navigateBackToList" [routerLink]="['/stock_levels']" class="btn btn-link mr-1">
                    <fa-icon [icon]="['fas', 'arrow-left']" class="mr-1"></fa-icon> {{ 'back_to_list' | translate }}
                  </a>
                </li>
              </ul>
            </ng-container>
          </div>
      </div>
    </div>

    <hr class="hr-margin" />

    <div class="row">
      <div *ngIf="!bLoading" class="col-sm-7">
          <div class="row record-container mb-16" style="clear:both;">
            <div class="col-md-6">
              <label class="record-labels">{{ 'product_code' | translate }}</label>
              <div>
                <span class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                  <a id="viewFieldproduct_code" [routerLink]="['/items/' + objStockLevel.item_id]">
                    {{ objStockLevel.product_code | placeholdWithString }}
                  </a>
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <label class="record-labels">{{ 'stock_level' | translate }}</label>
              <div>
                <span id="viewFieldstock_level" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                  {{ objStockLevel.current_stock_level | placeholdWithString }} {{ objStockLevel.unit }}
                </span>
              </div>
            </div>
          </div>
          <div class="row record-container mb-16" style="clear:both;">
            <div class="col-md-6">
              <label class="record-labels">{{ 'preferred_supplier' | translate }}</label>
              <div>
                <span id="viewFieldpreferred_supplier" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                  {{ objStockLevel.preferred_supplier }}
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <label class="record-labels">{{ 'unit_price' | translate }}</label>
              <div>
                <span id="viewFieldunit_price" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                  {{ objStockLevel.unit_price | placeholdWithString }}
                </span>
              </div>
            </div>
          </div>
          <div class="row record-container mb-16" style="clear:both;">
            <div class="col-md-12">
              <label class="record-labels">{{ 'description' | translate }}</label>
              <div>
                <span id="viewFielddescription" class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text">
                  {{ objStockLevel.description | placeholdWithString }}
                </span>
              </div>
            </div>
          </div>
          <div class="row record-container mb-16" style="clear:both;">
            <div class="col-md-12">
              <label class="record-labels">{{ 'image' | translate }}</label>
              <div>
                <ng-container *ngIf="objStockLevel.image">
                  <img id="viewFieldimage" class="img-fluid stock-image mx-auto d-block" src="{{ strImageUrl }}" />
                </ng-container>
                <ng-container *ngIf="!objStockLevel.image">
                  <img id="viewFieldimage" width="250" height="250" class="img-fluid rounded mx-auto d-block" src="../assets/img/noimage.png" />
                </ng-container>
              </div>
            </div>
          </div>
      </div>

      <div class="col-sm-5">
        <div *ngIf="bLoading" class="border align-center p-3">
          <inline-view-loader [isLoading]="bLoading"></inline-view-loader>
        </div>

        <app-widget *ngIf="!bLoading" strModule="stock_levels" [arModuleTabs]="arAvailableTabs">
          <ng-template #custom_widgets
            let-objTriggerFetch="objTriggerFetch"
            let-isTabActiveByDefault="isTabActiveByDefault"
            let-shouldWidgetBeDisplayed="shouldWidgetBeDisplayed">

            <div
              *ngIf="shouldWidgetBeDisplayed('stock-transfer', arAvailableTabs)"
              id="stock-transfer"
              role="tabpanel"
              class="tab-pane"
              aria-labelledby="stock-transfers-tab"
              [ngClass]="{active: isTabActiveByDefault('stock-transfers', arAvailableTabs)}">
                <app-stock-transfer-list
                  *ngIf="objTriggerFetch['stock-transfers']">
                </app-stock-transfer-list>
            </div>

            <div
              *ngIf="shouldWidgetBeDisplayed('warehouse', arAvailableTabs)"
              id="warehouse"
              role="tabpanel"
              class="tab-pane"
              aria-labelledby="warehouses-tab"
              [ngClass]="{active: isTabActiveByDefault('warehouses', arAvailableTabs)}">
                <warehouse-list></warehouse-list>
            </div>

            <div
              *ngIf="shouldWidgetBeDisplayed('stock-adjustment', arAvailableTabs)"
              id="stock-adjustment"
              role="tabpanel"
              class="tab-pane"
              aria-labelledby="stock-adjustments-tab"
              [ngClass]="{active: isTabActiveByDefault('stock-adjustments', arAvailableTabs)}">
                <stock-adjustments *ngIf="objTriggerFetch['stock-adjustments']"></stock-adjustments>
            </div>
          </ng-template>
        </app-widget>
      </div>
    </div>
  </ng-container>
</app-error-handler>


