import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import PubSub from '@aws-amplify/pubsub';
import API from '@aws-amplify/api';
import awsmobile from './aws-exports';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

PubSub.configure(awsmobile);
API.configure(awsmobile);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

/**
 * Workaround fix for Array.reduce bug in Chrome version 80
 *
 * @link https://github.com/angular/angular/issues/35219
 * @link https://bugs.chromium.org/p/chromium/issues/detail?id=1049982
 */
(function() {

	const chromeVersion = getChromeVersion();
  	if (chromeVersion && chromeVersion >= 80) {

		let callback;
		const arrayReduce = Array.prototype.reduce;
		Object.defineProperty(Array.prototype, 'reduce', {
			value: function(cb, ...args) {
				callback = cb;
				return arrayReduce.call(this, callback, ...args);
			}
		});
	}


	/**
	 * Return the chrome version
	 *
	 * @return int
	 */
	function getChromeVersion() {
		const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    	return raw ? parseInt(raw[2], 10) : false;
	}
})();