export class QuoteFormat{
    list = [
        {id: 'Summary Only', text: 'summary_only'},
        {id: 'Show Detail', text: 'show_detail'},
        {id: 'Show Detail Pricing', text: 'show_detail_pricing'},
        {id: 'Hide Pricing', text: 'hide_pricing'},
        {id: 'Group Totals', text: 'group_totals'},
    ];

    preview = {
      "site_url":"https:\/\/www.fieldmagic.co",
      "company_name":"Fieldmagic",
      "company_logo_url":"https:\/\/static.fieldmagic.co\/logo.jpg",
      "company_theme_color":"#ffffff",
      "company_font_color":"#000000",
      "quote_group_color":"#ffffff",
      "quote_font_color":"#000000",
      "company_website_url":"https:\/\/www.fieldmagic.co",
      "company_specialising_in_summary":"",
      "company_po_box_number":"2326 Malaga WA 6944",
      "company_address_city":"Malaga",
      "company_address_state":"WA",
      "company_address_postalcode":"6090",
      "company_phone_office":"08 9248 4824",
      "company_address_street":"Unit 1, 7 Montgomery Way   ",
      "company_country":"Australia",
      "company_fax":"08 9248 4825",
      "company_email":"inquiries@westside.com.au",
      "company_abn":"12 009 382 870",
      "company_acn":"009 382 870",
      "portal_url":"",
      "disable_sites":null,
      "exclude_specialization":false,
      "portal_active":false,
      "invoice_terms_and_conditions":"1. Costs to recover outstanding accounts to be paid by debtor.<br \/>\n2. Payment is due strictly 30 days from date of invoice.<br \/>\n3. EFT to BSB 016-353 Acc# 4922 65004 ANZ Malaga<br \/>\n4. All items on this invoice remain the property of Westside Fire Services until full payment has been received",
      "preview":1,
      "full_billing_address":"Unit 1, 7 Montgomery Way   , Malaga, WA, Australia",
      "full_postal_address":"2326 Malaga WA 6944, WA, Australia",
      "quote_terms_and_conditions":"",
      "created":"11-12-2018",
      "id":"f52095d0-fcf1-11e8-9246-22000a07008c",
      "name":"44351",
      "date_entered":"11-12-2018 09:00am",
      "date_modified":"25-01-2019 03:22pm",
      "modified_user_id":"744dd3c0-21d0-11e8-a4a9-22000a07008c",
      "modified_by_name":"Mark Potter",
      "created_by":"744dd3c0-21d0-11e8-a4a9-22000a07008c",
      "created_by_name":"Mark Potter",
      "description":"",
      "deleted":"",
      "opportunity_type_raw":"Quote",
      "opportunity_type":"Quote",
      "account_name":"Worth Testamentary Trust C\/- CNI Commercial",
      "account_name_id":"a5f44a16-1b78-11e8-8665-22000a07008c",
      "account_name_name":"Worth Testamentary Trust C\/- CNI Commercial",
      "account_name_date_entered":"27-02-2018 12:42pm",
      "account_name_date_modified":"01-01-2019 11:00pm",
      "account_name_modified_user_id":"1",
      "account_name_modified_by_name":"Administrator",
      "account_name_created_by":"bbcb4d50-dce7-28c4-1dc8-57aaa92141e0",
      "account_name_created_by_name":"Natasha Koroskoska",
      "account_name_description":"",
      "account_name_deleted":"",
      "account_name_facebook":"",
      "account_name_twitter":"",
      "account_name_googleplus":"",
      "account_name_account_type_raw":"Blank",
      "account_name_account_type":"",
      "account_name_industry_raw":"Other",
      "account_name_industry":"Other",
      "account_name_annual_revenue":"",
      "account_name_phone_fax":"",
      "account_name_billing_address_street":"PO Box 961",
      "account_name_billing_address_street_2":"",
      "account_name_billing_address_street_3":"",
      "account_name_billing_address_street_4":"",
      "account_name_billing_address_city":"West Perth",
      "account_name_billing_address_state":"WA",
      "account_name_billing_address_postalcode":"6872",
      "account_name_billing_address_country":"",
      "account_name_rating":"",
      "account_name_phone_office":"9226 3419",
      "account_name_phone_alternate":"",
      "account_name_website":"",
      "account_name_ownership":"",
      "account_name_employees":"",
      "account_name_ticker_symbol":"",
      "account_name_shipping_address_street":"",
      "account_name_shipping_address_street_2":"",
      "account_name_shipping_address_street_3":"",
      "account_name_shipping_address_street_4":"",
      "account_name_shipping_address_city":"",
      "account_name_shipping_address_state":"",
      "account_name_shipping_address_postalcode":"",
      "account_name_shipping_address_country":"",
      "account_name_parent_id":"",
      "account_name_sic_code":"",
      "account_name_duns_num":"",
      "account_name_parent_name":"",
      "account_name_campaign_id":"",
      "account_name_campaign_name":"",
      "account_name_following":"",
      "account_name_my_favorite":"",
      "account_name_tag":"",
      "account_name_locked_fields":"",
      "account_name_assigned_user_id":"9bd1be32-af9c-db02-4ce4-547bbae8a05c",
      "account_name_assigned_user_name":"Anita Gibbons",
      "account_name_team_id":"1",
      "account_name_team_set_id":"1",
      "account_name_acl_team_set_id":"",
      "account_name_team_count":"",
      "account_name_team_name":"Global",
      "account_name_acl_team_names":"",
      "account_name_email":"",
      "account_name_email1":"tworth@cnicommercial.com.au",
      "account_name_email2":"",
      "account_name_invalid_email":"",
      "account_name_email_opt_out":"",
      "account_name_email_addresses_non_primary":"",
      "account_name_invoice_manually_c_raw":"",
      "account_name_invoice_manually_c":"",
      "account_name_password_c":"",
      "account_name_customer_portal_c":"",
      "account_name_username_c":"",
      "account_name_class_pricebooks_accounts_name":"",
      "account_name_class_pricebooks_accountsclass_pricebooks_ida":"",
      "account_name_invoice_terms":"",
      "account_name_quote_terms":"",
      "account_name_sending_preference_raw":"Pdf",
      "account_name_sending_preference":"Pdf",
      "account_name_financial_status_raw":"Active",
      "account_name_financial_status":"Active",
      "account_name_class_pricebooks_id_c":"",
      "account_name_default_pricebook":"",
      "account_name_annual_maintenance_value_c":"1900.67",
      "account_name_abn_c":"",
      "account_name_accounting_id_c":"202a930f-3067-44bb-906e-1f186b65807b",
      "account_name_accounting_last_sync_result_c":"",
      "account_name_accounting_last_update_id_c":"",
      "account_name_account_balance_c":"0.00",
      "account_name_amount_inc_variations_c":"",
      "account_name_approvedto15k_c_raw":"No",
      "account_name_approvedto15k_c":"No",
      "account_name_approvedto5k_c_raw":"",
      "account_name_approvedto5k_c":"",
      "account_name_changed_c":"",
      "account_name_company_abn_c":"",
      "account_name_contact_email_c":"",
      "account_name_contact_first_name_c":"",
      "account_name_contact_it_c_raw":"",
      "account_name_contact_it_c":"",
      "account_name_contact_last_name_c":"",
      "account_name_contact_mobile_c":"",
      "account_name_copy_parent_company_c":"",
      "account_name_creatematchingsite_c":"",
      "account_name_create_new_site_c":"",
      "account_name_credit_approved_c_raw":"",
      "account_name_credit_approved_c":"",
      "account_name_credit_limit_approaching_c_raw":"",
      "account_name_credit_limit_approaching_c":"",
      "account_name_credit_limit_c":"",
      "account_name_credit_limit_nci_c":"",
      "account_name_customerid_c":"",
      "account_name_customertype_c":"",
      "account_name_customer_type_c":"",
      "account_name_debtor_date_1_c":"",
      "account_name_debtor_date_2_c":"",
      "account_name_debtor_date_3_c":"",
      "account_name_debtor_date_4_c":"",
      "account_name_debtor_date_5_c":"",
      "account_name_debtor_notes_2_c":"",
      "account_name_debtor_notes_3_c":"",
      "account_name_debtor_notes_4_c":"",
      "account_name_debtor_notes_5_c":"",
      "account_name_debtor_notes_c":"",
      "account_name_disable_all_schedules_c_raw":"2",
      "account_name_disable_all_schedules_c":"",
      "account_name_entity_type_c_raw":"",
      "account_name_entity_type_c":"",
      "account_name_first_name_billing_c":"",
      "account_name_invoices_over_ninety_days_c_raw":"",
      "account_name_invoices_over_ninety_days_c":"",
      "account_name_it_dept_contact_c":"",
      "account_name_jobs_permitted_c":"",
      "account_name_job_notes_c":"",
      "account_name_last_contact_date_c":"",
      "account_name_last_job_date_c":"",
      "account_name_last_name_billing_c":"",
      "account_name_last_service_date_c":"",
      "account_name_most_recent_comment_c":"",
      "account_name_open_jobs_value_c":"",
      "account_name_over_ride_onhold_c":"",
      "account_name_parent_next_followup_date_c":"27-02-2018 12:42pm",
      "account_name_payment_terms_c_raw":"14DAYS",
      "account_name_payment_terms_c":"14 Days",
      "account_name_phone_mobile_c":"",
      "account_name_processed_c":"",
      "account_name_reason_for_onhold_c":"",
      "account_name_status_c_raw":"Prospect",
      "account_name_status_c":"",
      "account_name_sub_available_for_prospectin_c_raw":"Yes",
      "account_name_sub_available_for_prospectin_c":"Yes",
      "account_name_supplier_email_c":"",
      "account_name_supplier_insurance_expiry_c":"",
      "account_name_tax_code_c_raw":"",
      "account_name_tax_code_c":"",
      "account_name_total_over_five_k_c_raw":"",
      "account_name_total_over_five_k_c":"",
      "account_name_total_over_twenty_k_c_raw":"",
      "account_name_total_over_twenty_k_c":"",
      "account_name_total_unpaid_invoice_value_c":"",
      "account_name_tsm_modified_date_c":"",
      "account_name_type_c_raw":"Blank",
      "account_name_type_c":"",
      "account_name_type_c_fix_c":"",
      "account_name_unpaid_and_open_jobs_c":"",
      "account_name_user_id_c":"",
      "account_id":"a5f44a16-1b78-11e8-8665-22000a07008c",
      "campaign_id":"",
      "campaign_name":"",
      "lead_source_raw":"",
      "lead_source":"",
      "amount":"5210.000000",
      "amount_usdollar":"5210.000000",
      "date_closed":"",
      "date_closed_timestamp":"10",
      "next_step":"",
      "sales_stage_raw":"Sent to Client",
      "sales_stage":"Sent to Client",
      "sales_status_raw":"In Progress",
      "sales_status":"In Progress",
      "probability":"",
      "best_case":"0.000000",
      "worst_case":"0.000000",
      "commit_stage_raw":"",
      "commit_stage":"",
      "total_revenue_line_items":"",
      "closed_revenue_line_items":"",
      "included_revenue_line_items":"",
      "contact_role_raw":"",
      "contact_role":"",
      "mkto_sync":"",
      "mkto_id":"",
      "following":"",
      "my_favorite":"",
      "tag":"",
      "locked_fields":"",
      "assigned_user_id":"744dd3c0-21d0-11e8-a4a9-22000a07008c",
      "assigned_user_name":"Mark Potter",
      "team_id":"1",
      "team_set_id":"1",
      "acl_team_set_id":"",
      "team_count":"",
      "team_name":"Global",
      "acl_team_names":"",
      "currency_id":"-99",
      "base_rate":"1.000000",
      "currency_name":"",
      "currency_symbol":"",
      "quote_term":"",
      "estimated_timeframe_c":"0.00",
      "plant_c":"0.00",
      "total_estimated_materials_c":"0.00",
      "first_call_c_raw":"Blank",
      "first_call_c":"",
      "parent_next_followup_date_c":"11-12-2018 11:07am",
      "reason_for_lost_c_raw":"",
      "reason_for_lost_c":"",
      "quote_format_raw":"Show Detail",
      "quote_format":"Show Detail",
      "reason_for_rejection":"",
      "total_loop_hours_c":"0.00",
      "loop_hours_c":"",
      "number_of_loops_c":"",
      "number_of_zones_c":"",
      "total_loop_zone_hours_c":"1",
      "total_zone_and_loop_hours_c":"95.00",
      "total_zone_hours_c":"0.00",
      "rounded_loop_and_zone_hours_c":"",
      "rounded_sell_price_c":"",
      "quote_queries_c":"",
      "zone_hours_c":"",
      "department_c_raw":"Electrical",
      "department_c":"Electrical",
      "discount_percentage":"0.00",
      "quote_number":"24251",
      "priority_raw":"Recommendation",
      "priority":"Recommendation",
      "most_recent_comment_c":"",
      "last_contact_date_c":"",
      "quote_date":"11-12-2018",
      "class_pricebooks_id_c":"",
      "pricebook":"",
      "created_from_fault_job_c_raw":"No",
      "created_from_fault_job_c":"No",
      "po_number_c":"",
      "job_quote_templates_id_c":"6cc6b2f4-ff30-54ea-ae9c-500889a94202",
      "quote_template_c":"Fire Detection Quote",
      "quote_body_c":"",
      "job_terms_conditions_id_c":"",
      "job_summary":"Additional Detectors Recommended in Some Areas",
      "job_jobs_id_c":"dbc48ce0-37ad-11e8-910c-22000a07008c",
      "job_c":"211751",
      "job_c_id":"dbc48ce0-37ad-11e8-910c-22000a07008c",
      "job_c_name":"211751",
      "job_c_date_entered":"04-04-2018 10:14am",
      "job_c_date_modified":"11-12-2018 01:40pm",
      "job_c_modified_user_id":"744dd3c0-21d0-11e8-a4a9-22000a07008c",
      "job_c_modified_by_name":"Mark Potter",
      "job_c_created_by":"25f213e6-27e6-11e8-8c19-22000a07008c",
      "job_c_created_by_name":"Natasha Koroskoska",
      "job_c_description":"Created from Fire Indicator Panel Schedules",
      "job_c_deleted":"",
      "job_c_latitude":"-31.9452",
      "job_c_longitude":"115.8430",
      "job_c_job_address_level":"",
      "job_c_job_address_unit":"",
      "job_c_job_address_city":"West Perth",
      "job_c_job_address_state":"WA",
      "job_c_job_address_postalcode":"6005",
      "job_c_job_address_country_raw":"AU",
      "job_c_job_address_country":"Australia",
      "job_c_job_address_street":"143 Colin Street",
      "job_c_class_pricebooks_id_c":"",
      "job_c_pricebook":"",
      "job_c_review_complete":"1",
      "job_c_scheduled_maintenance":"1",
      "job_c_job_maint_templates_id_c":"700c660e-c340-11e6-b83d-22000a07008c",
      "job_c_disable_materials":"1",
      "job_c_total_invoiced":"",
      "job_c_percent_invoiced_ex_vars":"",
      "job_c_percent_invoiced_inc_vars":"",
      "job_c_total_estimated_materials":"",
      "job_c_total_estimated_hours":"",
      "job_c_total_estimated_job_cost":"",
      "job_c_estimated_gross_margin":"",
      "job_c_estimated_gross_margin_percentage":"",
      "job_c_total_job_cost":"0.00",
      "job_c_total_cost_variance":"0.00",
      "job_c_financial_analysis_notes":"",
      "job_c_asset_type":"^700c660e-c340-11e6-b83d-22000a07008c^",
      "job_c_status_raw":"Invoiced",
      "job_c_status":"Invoiced",
      "job_c_review_type_raw":"Sounders",
      "job_c_review_type":"Sounders",
      "job_c_is_invoiced_raw":"Yes",
      "job_c_is_invoiced":"Yes",
      "job_c_automatic_report_sent":"1",
      "job_c_internal_notes":"",
      "job_c_department_raw":"I&T Electrical",
      "job_c_department":"I&T Electrical",
      "job_c_priority_raw":"Normal",
      "job_c_priority":"",
      "job_c_po_number":"",
      "job_c_work_order_number":"",
      "job_c_job_summary":"Fire Indicator Panel : Annual",
      "job_c_estimated_timeframe":"4.00",
      "job_c_due_date":"31-05-2018",
      "job_c_job_sites_id_c":"bd39af48-057c-11e8-9212-22000a07008c",
      "job_c_site":"143 Colin Street",
      "job_c_account_id_c":"a5f44a16-1b78-11e8-8665-22000a07008c",
      "job_c_billing_company":"Worth Testamentary Trust C\/- CNI Commercial",
      "job_c_amount_to_invoice":"0.00",
      "job_c_variation_amount":"0.00",
      "job_c_user_id_c":"",
      "job_c_sales_person":"",
      "job_c_billable_raw":"No",
      "job_c_billable":"No",
      "job_c_job_number":"211751",
      "job_c_total_revenue":"0.00",
      "job_c_total_expenses":"0.00",
      "job_c_total_labour":"0.00",
      "job_c_gross_margin":"0.00",
      "job_c_gross_margin_percentage":"0.00",
      "job_c_job_type_raw":"Recurring",
      "job_c_job_type":"I&T",
      "job_c_date_completed":"29-06-2018",
      "job_c_time_booked_raw":"2",
      "job_c_time_booked":"",
      "job_c_opportunity_id_c":"",
      "job_c_quote":"",
      "job_c_acase_id_c":"",
      "job_c_related_case":"",
      "job_c_schedule_type":"^years_1^",
      "job_c_remaining_amount":"0.00",
      "job_c_start_date":"",
      "job_c_requires_review":"1",
      "job_c_created_from_asset_config":"",
      "job_c_address_type_raw":"",
      "job_c_address_type":"",
      "job_c_created_from_portal":"",
      "job_c_checklists_has_faults":"",
      "job_c_following":"",
      "job_c_my_favorite":"",
      "job_c_tag":"",
      "job_c_locked_fields":"",
      "job_c_team_id":"1",
      "job_c_team_set_id":"1",
      "job_c_acl_team_set_id":"",
      "job_c_team_count":"",
      "job_c_team_name":"Global",
      "job_c_acl_team_names":"",
      "job_c_assigned_user_id":"3985c06c-893d-11e7-9095-22000a07008c",
      "job_c_assigned_user_name":"Craig Knowles",
      "job_c_tuffin_quoted_c_raw":"No",
      "job_c_tuffin_quoted_c":"No",
      "job_c_reason_no_invoice_c":"",
      "job_c_dba_no_c":"",
      "job_c_building_name_c":"",
      "job_c_dfes_application_c_raw":"Blank",
      "job_c_dfes_application_c":"N\/A",
      "job_c_app_no_c":"",
      "job_c_fip_location_c":"",
      "job_c_fip_make_model_c":"",
      "job_c_mdf_location_c":"",
      "job_c_practical_completion_c":"",
      "job_c_dfes_submission_date_c":"",
      "job_c_dfes_scope_of_works_c":"",
      "job_c_alarm_type_c_raw":"blank",
      "job_c_alarm_type_c":"",
      "job_c_system_type_c_raw":"Blank",
      "job_c_system_type_c":"",
      "job_c_building_classification_c_raw":"Blank",
      "job_c_building_classification_c":"",
      "job_c_dfes_completed_c_raw":"No",
      "job_c_dfes_completed_c":"No",
      "job_c_dfes_documentation_required_c":"",
      "job_c_building_structure_c":"",
      "job_c_alarm_ia_c":"",
      "job_c_quiescent_c":"",
      "job_c_installaedcapacity_c":"",
      "job_c_quiescent1_c":"",
      "job_c_recomend_batt_c":"",
      "job_c_requiredcapacity_c":"",
      "job_c_plant_c":"",
      "job_c_pre_plan_complete_c":"",
      "job_c_scheduler_notes_c":"",
      "job_c_choose_completion_date_c_raw":"Blank",
      "job_c_choose_completion_date_c":"",
      "job_c_cost_to_complete_c":"",
      "job_c_labour_c":"",
      "job_c_materials_c":"",
      "job_c_total_cost_to_complete_c":"",
      "job_c_analysis_completed_c":"",
      "job_c_job_analysis_comments_c":"",
      "job_c_percentage_completed_c":"",
      "job_c_job_jobs_job_sites_3_name":"",
      "job_c_job_jobs_job_sites_3job_sites_idb":"",
      "job_c_checklist_sent_c_raw":"Yes",
      "job_c_checklist_sent_c":"Yes",
      "job_c_cancelled_reason_c_raw":"Blank",
      "job_c_cancelled_reason_c":"",
      "job_c_specify_reason_c":"",
      "job_c_provisional_booking_date_c":"",
      "job_c_provisionally_booked_c_raw":"",
      "job_c_provisionally_booked_c":"",
      "job_c_reference_documentation_c":"",
      "job_c_system_man_and_type_c":"",
      "job_c_system_designed_by_c":"",
      "job_c_system_design_date_c":"",
      "job_c_alternate_solution_by_c":"",
      "job_c_alternate_solution_date_c":"",
      "job_c_type_of_work_c_raw":"Blank",
      "job_c_type_of_work_c":"",
      "job_c_monitored_alarms_c":"",
      "job_c_customer_portal_entered_c_raw":"",
      "job_c_customer_portal_entered_c":"",
      "job_c_lighting_job_c_raw":"",
      "job_c_lighting_job_c":"",
      "job_c_job_standards_responses_line_job_jobs_1_name":"",
      "job_c_job_standa33abes_line_ida":"",
      "job_c_job_standards_checklists_job_jobs_1_name":"",
      "job_c_job_standards_checklists_job_jobs_1job_standards_checklists_ida":"",
      "job_c_schedules_notes_c":"",
      "job_c_job_queries_c":"",
      "job_c_non_billable_reason_c_raw":"",
      "job_c_non_billable_reason_c":"",
      "job_c_estimated_materials_no_stage_c":"",
      "job_c_documents_g_drive_location_c":"",
      "job_c_cases_job_jobs_1_name":"",
      "job_c_job_time_c":"",
      "job_sites_id_c":"bd39af48-057c-11e8-9212-22000a07008c",
      "related_site":"143 Colin Street",
      "related_site_id":"bd39af48-057c-11e8-9212-22000a07008c",
      "related_site_name":"143 Colin Street",
      "related_site_date_entered":"30-01-2018 01:16pm",
      "related_site_date_modified":"14-01-2019 07:15am",
      "related_site_modified_user_id":"3985c06c-893d-11e7-9095-22000a07008c",
      "related_site_modified_by_name":"Craig Knowles",
      "related_site_created_by":"57aaa7ae-6a6b-a6a6-d53a-4fe6b440c9f0",
      "related_site_created_by_name":"Tony Williams",
      "related_site_description":"Ampac - AB1000\nSerial no: 2635\nManufactured 1990\nConventional 3 zones\nDBA: NA\nBatteries 12v12Ah, installed 04\/06\/15\n\n\n NKO 27.2.18: site access is from 8am - 5pm. \nPlease contact Strata to schedule any testing as the top floor of site do surgeries.\nStrata has advised no access codes, however if keys are needed for any reason, please call Strata (Laura Phipps) 9226 3419.\n\nAttendance book left in HR cabinet near front door \nExt , FB  , HR, FIP book also.\n\nFIP is not monitored by Code red or Security.\n\n*MUST TEST AT 0745 - FIRST WEDNESDAY OF EVERY MONTH*",
      "related_site_deleted":"",
      "related_site_multiple_asset_type_jobs":"",
      "related_site_automatically_email_report":"",
      "related_site_disable_maintenance":"",
      "related_site_contact_id_c":"",
      "related_site_primary_contact":"",
      "related_site_site_directory":"C143 Colin Street West Perth",
      "related_site_site_address_city":"West Perth",
      "related_site_annual_maintenance_value":"1900.67",
      "related_site_site_address_state":"WA",
      "related_site_site_address_postalcode":"6005",
      "related_site_site_address_country_raw":"AU",
      "related_site_site_address_country":"Australia",
      "related_site_site_address_street":"143 Colin Street",
      "related_site_account_id_c":"a5f44a16-1b78-11e8-8665-22000a07008c",
      "related_site_billing_company":"Worth Testamentary Trust C\/- CNI Commercial",
      "related_site_instructions":"",
      "related_site_disable_all_schedules_raw":"No",
      "related_site_disable_all_schedules":"No",
      "related_site_create_new_account_raw":"No",
      "related_site_create_new_account":"No",
      "related_site_mobile_phone":"",
      "related_site_phone_fax":"",
      "related_site_office_phone":"",
      "related_site_other_phone":"",
      "related_site_company_name":"",
      "related_site_email":"commercial.accounts@acton.com.au",
      "related_site_site_address_level":"",
      "related_site_site_address_unit":"",
      "related_site_access_notes":"NKO 27.2.18: site access is from 8am - 5pm. \nPlease contact Strata to schedule any testing as the top floor of site do surgeries.\nStrata has advised no access codes, however if keys are needed for any reason, please call Strata (Laura Phipps) 9226 3419.",
      "related_site_latitude":"-31.9452",
      "related_site_longitude":"115.8430",
      "related_site_allow_scheduled_jobs_without_recurring_invoice":"",
      "related_site_has_recurring_invoice":"1",
      "related_site_override_next_job_date":"",
      "related_site_next_job_date_week_raw":"",
      "related_site_next_job_date_week":"",
      "related_site_next_job_date_day_raw":"",
      "related_site_next_job_date_day":"",
      "related_site_enable_auto_scheduling":"",
      "related_site_auto_scheduling_time_raw":"",
      "related_site_auto_scheduling_time":"",
      "related_site_following":"",
      "related_site_my_favorite":"",
      "related_site_tag":"",
      "related_site_locked_fields":"",
      "related_site_team_id":"1",
      "related_site_team_set_id":"1",
      "related_site_acl_team_set_id":"",
      "related_site_team_count":"",
      "related_site_team_name":"Global",
      "related_site_acl_team_names":"",
      "related_site_assigned_user_id":"57aaa7ae-6a6b-a6a6-d53a-4fe6b440c9f0",
      "related_site_assigned_user_name":"Tony Williams",
      "related_site_emergency_box_c_raw":"",
      "related_site_emergency_box_c":"",
      "related_site_status_c_raw":"Active",
      "related_site_status_c":"Active",
      "related_site_z_amount_of_check_pumps_c":"",
      "related_site_z_amount_check_valves_c":"",
      "related_site_z_amount_of_stop_valves_c":"",
      "related_site_z_amount_position_indicators_c":"",
      "related_site_z_amount_valve_monitors_cie_c":"",
      "related_site_z_amount_alarm_signal_c":"",
      "related_site_z_test_auto_pump_devices_c":"",
      "related_site_z_test_auto_man_devices_c":"",
      "related_site_z_stop_valve_loc_1_c":"",
      "related_site_z_stop_valve_loc_2_c":"",
      "related_site_z_stop_valve_loc_3_c":"",
      "related_site_z_tank_pressure_gauge_c":"",
      "related_site_z_cutin_pressure_c":"",
      "related_site_z_cutout_pressure_c":"",
      "related_site_z_low_tank_pressure_c":"",
      "related_site_z_water_supply_valves_c":"",
      "related_site_z_starting_pressure_kpa_c":"",
      "related_site_z_runtime_c":"",
      "related_site_z_pump_suction_c":"",
      "related_site_z_float_voltage_c":"",
      "related_site_z_pump_discharge_c":"",
      "related_site_z_starting_device_hour_c":"",
      "related_site_z_charge_voltage_c":"",
      "related_site_z_oil_pressure_c":"",
      "related_site_z_engine_temp_c":"",
      "related_site_z_operating_pressure_c":"",
      "related_site_z_pump_cutin_pressure_c":"",
      "related_site_z_pump_cutout_poressure_c":"",
      "related_site_z_water_supply_1_location_1_c":"",
      "related_site_z_water_supply_1_location_2_c":"",
      "related_site_z_water_supply_1_location_3_c":"",
      "related_site_z_water_supply_2_location_1_c":"",
      "related_site_z_water_supply_2_location_2_c":"",
      "related_site_z_water_supply_2_location_3_c":"",
      "related_site_z_fuel_tank_level_c":"",
      "related_site_z_amount_valves_electric_c":"",
      "related_site_z_gauge_suction_diesel_c":"",
      "related_site_z_pressure_gauge_suction_c":"",
      "related_site_z_starting_pressure_electric_c":"",
      "related_site_z_runtime_electric_c":"",
      "related_site_z_pump_suction_electric_c":"",
      "related_site_z_pump_discharge_electric_c":"",
      "related_site_z_float_voltage_electric_c":"",
      "related_site_appendix_f_c":"",
      "related_site_buildingname_c":"",
      "related_site_building_owner_c":"",
      "related_site_building_owner_contact_name_c":"",
      "related_site_building_owner_number_c":"",
      "related_site_building_owner_mobile_c":"",
      "related_site_building_owner_email_c":"",
      "related_site_dba_no_c":"",
      "related_site_quiescent_c":"",
      "related_site_alarm_ia_c":"",
      "related_site_requiredcapacity_c":"",
      "related_site_quiescent1_c":"",
      "related_site_pre_planning_reqd_c":"",
      "related_site_job_jobs_job_sites_3_name":"",
      "related_site_job_jobs_job_sites_3job_jobs_ida":"",
      "related_site_monthly_reports_required_c":"",
      "related_site_system_drain_down_c":"",
      "related_site_drain_down_date_c":"",
      "related_site_drain_down_actions_c":"",
      "related_site_five_yearly_panel_quoted_c_raw":"No",
      "related_site_five_yearly_panel_quoted_c":"No",
      "related_site_customer_portal_entry_rqd_c_raw":"",
      "related_site_customer_portal_entry_rqd_c":"",
      "related_site_amount_main_stop_valves_c":"",
      "related_site_amount_water_stop_checked_c":"",
      "related_site_reason_to_disable_c":"",
      "related_site_reason_disable_maintenance_c_raw":"",
      "related_site_reason_disable_maintenance_c":"",
      "related_site_commissioning_completed_c":"",
      "related_site_contracts_job_number_c":"",
      "related_site_connected_to_brigade_c_raw":"",
      "related_site_connected_to_brigade_c":"",
      "related_site_firepanel_connections_c":"",
      "related_site_type_of_premises_c":"",
      "related_site_gdrive_documents_c":"",
      "related_site_commissioning_date_c":"",
      "related_site_notes_job_sites_1_name":"",
      "related_site_notes_job_sites_1notes_ida":"",
      "related_site_inductions_required_c_raw":"",
      "related_site_inductions_required_c":"",
      "related_site_site_address_for_induction_c":"",
      "related_site_job_jobs_id_c":"",
      "contact_id1_c":"57ffe2fc-1b78-11e8-8875-22000a07008c",
      "recipient_contact":"Laura Phipps",
      "recipient_contact_id":"57ffe2fc-1b78-11e8-8875-22000a07008c",
      "recipient_contact_name":"Laura Phipps",
      "recipient_contact_date_entered":"27-02-2018 12:40pm",
      "recipient_contact_date_modified":"31-08-2018 08:09am",
      "recipient_contact_modified_user_id":"bbcb4d50-dce7-28c4-1dc8-57aaa92141e0",
      "recipient_contact_modified_by_name":"Natasha Koroskoska",
      "recipient_contact_created_by":"bbcb4d50-dce7-28c4-1dc8-57aaa92141e0",
      "recipient_contact_created_by_name":"Natasha Koroskoska",
      "recipient_contact_description":"",
      "recipient_contact_deleted":"",
      "recipient_contact_salutation_raw":"",
      "recipient_contact_salutation":"",
      "recipient_contact_first_name":"Laura",
      "recipient_contact_last_name":"Phipps",
      "recipient_contact_full_name":"Laura Phipps",
      "recipient_contact_title":"Commercial Property Manager",
      "recipient_contact_facebook":"",
      "recipient_contact_twitter":"",
      "recipient_contact_googleplus":"",
      "recipient_contact_department":"",
      "recipient_contact_do_not_call":"",
      "recipient_contact_phone_home":"",
      "recipient_contact_phone_mobile":"",
      "recipient_contact_phone_work":"9226 3419",
      "recipient_contact_phone_other":"",
      "recipient_contact_phone_fax":"9226 5419",
      "recipient_contact_primary_address_street":"143 Colin Street",
      "recipient_contact_primary_address_street_2":"",
      "recipient_contact_primary_address_street_3":"",
      "recipient_contact_primary_address_city":"West Perth",
      "recipient_contact_primary_address_state":"WA",
      "recipient_contact_primary_address_postalcode":"6005",
      "recipient_contact_primary_address_country":"AU",
      "recipient_contact_alt_address_street":"143 Colin Street",
      "recipient_contact_alt_address_street_2":"",
      "recipient_contact_alt_address_street_3":"",
      "recipient_contact_alt_address_city":"West Perth",
      "recipient_contact_alt_address_state":"WA",
      "recipient_contact_alt_address_postalcode":"6005",
      "recipient_contact_alt_address_country":"AU",
      "recipient_contact_assistant":"",
      "recipient_contact_assistant_phone":"",
      "recipient_contact_picture":"",
      "recipient_contact_email_and_name1":"",
      "recipient_contact_lead_source_raw":"",
      "recipient_contact_lead_source":"",
      "recipient_contact_account_name":"CNI Commercial",
      "recipient_contact_account_id":"b4cfb6f9-cb4d-ee0b-b23e-506ce3e55f0b",
      "recipient_contact_dnb_principal_id":"",
      "recipient_contact_opportunity_role_fields":"",
      "recipient_contact_opportunity_role_id":"",
      "recipient_contact_opportunity_role_raw":"",
      "recipient_contact_opportunity_role":"",
      "recipient_contact_reports_to_id":"",
      "recipient_contact_report_to_name":"",
      "recipient_contact_birthdate":"",
      "recipient_contact_portal_name":"",
      "recipient_contact_portal_active":"",
      "recipient_contact_portal_password":"",
      "recipient_contact_portal_password1":"",
      "recipient_contact_portal_app":"",
      "recipient_contact_preferred_language_raw":"",
      "recipient_contact_preferred_language":"",
      "recipient_contact_campaign_id":"",
      "recipient_contact_campaign_name":"",
      "recipient_contact_c_accept_status_fields":"",
      "recipient_contact_m_accept_status_fields":"",
      "recipient_contact_accept_status_id":"",
      "recipient_contact_accept_status_name_raw":"",
      "recipient_contact_accept_status_name":"",
      "recipient_contact_accept_status_calls_raw":"",
      "recipient_contact_accept_status_calls":"",
      "recipient_contact_accept_status_meetings_raw":"",
      "recipient_contact_accept_status_meetings":"",
      "recipient_contact_sync_contact":"",
      "recipient_contact_mkto_sync":"",
      "recipient_contact_mkto_id":"",
      "recipient_contact_mkto_lead_score":"",
      "recipient_contact_following":"",
      "recipient_contact_my_favorite":"",
      "recipient_contact_tag":"",
      "recipient_contact_locked_fields":"",
      "recipient_contact_assigned_user_id":"744dd3c0-21d0-11e8-a4a9-22000a07008c",
      "recipient_contact_assigned_user_name":"Mark Potter",
      "recipient_contact_team_id":"1",
      "recipient_contact_team_set_id":"1",
      "recipient_contact_acl_team_set_id":"",
      "recipient_contact_team_count":"",
      "recipient_contact_team_name":"Global",
      "recipient_contact_acl_team_names":"",
      "recipient_contact_email":"",
      "recipient_contact_email1":"lphipps@cnicommercial.com.au",
      "recipient_contact_email2":"",
      "recipient_contact_invalid_email":"",
      "recipient_contact_email_opt_out":"",
      "recipient_contact_email_addresses_non_primary":"",
      "recipient_contact_portal_view_jobs":"1",
      "recipient_contact_portal_view_invoices":"1",
      "recipient_contact_portal_view_quotes":"1",
      "recipient_contact_portal_view_assets":"1",
      "recipient_contact_portal_view_maintenance":"1",
      "recipient_contact_portal_create_jobs":"1",
      "recipient_contact_portal_approve_quotes":"1",
      "recipient_contact_portal_manage_contacts":"",
      "recipient_contact_inactive_c":"",
      "recipient_contact_documentation_c":"",
      "recipient_contact_reason_for_inactive_raw":"",
      "recipient_contact_reason_for_inactive":"",
      "recipient_contact_force_google_c":"",
      "recipient_contact_last_contact_date_c":"",
      "recipient_contact_most_recent_comment_c":"",
      "recipient_contact_parent_next_followup_date_c":"27-02-2018 08:00am",
      "recipient_contact_status_c_raw":"Prospect",
      "recipient_contact_status_c":"",
      "acc_config_id_c":"e96e12cc-3582-11e7-9ff3-22000a07008c",
      "accounting_file":"Westside Fire Services",
      "tax_code":"OUTPUT",
      "tax_amount":"521.00",
      "amount_including_tax":"5731.00",
      "email_read":"",
      "quote_address_level":"",
      "quote_address_unit":"",
      "quote_address_city":"West Perth",
      "quote_address_state":"WA",
      "quote_address_postalcode":"6005",
      "quote_address_country_raw":"AU",
      "quote_address_country":"Australia",
      "quote_address_street":"143 Colin Street",
      "latitude":"-31.9452",
      "longitude":"115.8430",
      "address_type_raw":"",
      "address_type":"",
      "work_order_number":"",
      "assigned_user_user_name":"mpotter",
      "assigned_user_picture":"",
      "assigned_user_first_name":"Mark",
      "assigned_user_last_name":"Potter",
      "assigned_user_full_name":"Mark Potter",
      "assigned_user_is_admin":"",
      "assigned_user_external_auth_only":"",
      "assigned_user_receive_notifications":"1",
      "assigned_user_description":"",
      "assigned_user_date_entered":"07-03-2018 02:26pm",
      "assigned_user_date_modified":"22-01-2019 11:54am",
      "assigned_user_last_login":"24-01-2019 03:33pm",
      "assigned_user_modified_user_id":"744dd3c0-21d0-11e8-a4a9-22000a07008c",
      "assigned_user_modified_by_name":"Mark Potter",
      "assigned_user_created_by":"e41e087e-d94e-de90-15ae-4fe6b552ea9b",
      "assigned_user_created_by_name":"Rob Walker",
      "assigned_user_title":"",
      "assigned_user_department":"",
      "assigned_user_phone_home":"",
      "assigned_user_phone_mobile":"0400 223 025",
      "assigned_user_phone_work":"6240 2909",
      "assigned_user_phone_other":"909",
      "assigned_user_phone_fax":"",
      "assigned_user_status":"Active",
      "assigned_user_address_street":"105 Sundowner Meander",
      "assigned_user_address_city":"Banksia Grove",
      "assigned_user_address_state":"Western Australia",
      "assigned_user_address_country":"Australia",
      "assigned_user_address_postalcode":"6031",
      "assigned_user_UserType":"",
      "assigned_user_default_team":"1",
      "assigned_user_team_id":"1",
      "assigned_user_team_set_id":"1",
      "assigned_user_acl_team_set_id":"",
      "assigned_user_team_count":"",
      "assigned_user_team_name":"Global",
      "assigned_user_deleted":"",
      "assigned_user_portal_only":"",
      "assigned_user_show_on_employees":"1",
      "assigned_user_employee_status":"Active",
      "assigned_user_messenger_id":"",
      "assigned_user_messenger_type":"",
      "assigned_user_reports_to_id":"",
      "assigned_user_reports_to_name":"",
      "assigned_user_email1":"mpotter@westside.com.au",
      "assigned_user_email":"",
      "assigned_user_email_link_type":"",
      "assigned_user_is_group":"",
      "assigned_user_c_accept_status_fields":"",
      "assigned_user_m_accept_status_fields":"",
      "assigned_user_accept_status_id":"",
      "assigned_user_accept_status_name":"",
      "assigned_user_accept_status_calls":"",
      "assigned_user_accept_status_meetings":"",
      "assigned_user_preferred_language":"",
      "assigned_user_acl_role_set_id":"9ddeaf5c-54c0-11e8-87dd-22000a07008c",
      "assigned_user_disable_jobs_on_mobile_c":"",
      "assigned_user_disable_assets_on_mobile_c":"",
      "assigned_user_disable_quotes_on_mobile_c":"",
      "assigned_user_start_date_c":"03-07-2019",
      "assigned_user_end_date_c":"",
      "assigned_user_office_extension_c":"",
      "assigned_user_vehicle_reg_c":"",
      "assigned_user_vehicle_type_c":"",
      "assigned_user_ipad_mobile_number_c":"",
      "assigned_user_ipad_sim_no_c":"",
      "assigned_user_complete_profile_c":"Yes",
      "assigned_user_emergency_mobile_no_c":"",
      "assigned_user_emergency_address_c":"42 Mornington Drive",
      "assigned_user_vehicle_allowance_c":"No",
      "assigned_user_allowance_commenced_c":"",
      "assigned_user_allowance_renewal_c":"",
      "assigned_user_fuel_consumption_c":"",
      "assigned_user_ave_kms_c":"",
      "assigned_user_fbt_recovery_c":"",
      "assigned_user_fuel_rate_c":"",
      "assigned_user_litres_used_c":"",
      "assigned_user_private_use_c":"",
      "assigned_user_mobile_c":"",
      "assigned_user_daily_break_c":"0.00",
      "assigned_user_date_of_birth_c":"22-02-1977",
      "assigned_user_personal_mobile_c":"0403 584 454",
      "assigned_user_personal_email_c":"markpotter@hotmail.com",
      "assigned_user_emergency_contact_c":"Justine",
      "assigned_user_relationship_to_c":"Wife",
      "assigned_user_emergency_mobile_c":"0402 693 502",
      "assigned_user_dl_number_c":"3951311",
      "assigned_user_dl_expiry_c":"03-06-2019",
      "assigned_user_bank_name_c":"Commonwealth Bank",
      "assigned_user_bsb_c":"066153",
      "assigned_user_super_fund_c":"Hostplus",
      "assigned_user_super_no_c":"830525595",
      "assigned_user_tax_file_no_c":"210092994",
      "assigned_user_lsl_no_c":"7069378",
      "assigned_user_account_no_c":"10322866",
      "assigned_user_member_no_c":"38302872",
      "assigned_user_portable_lsl_c":"NA",
      "assigned_user_hourly_cost_c":"0.00",
      "assigned_user_user_signature_image":"231a5f86-2320-11e8-bf87-22000a07008c",
      "assigned_user_disable_manual_time":"",
      "assigned_user_job_billing_categories_id1_c":"TRAVEL",
      "assigned_user_travel_billing_category":"Travel",
      "assigned_user_template_user_c":"",
      "assigned_user_location_date_modified_c":"",
      "assigned_user_gps_location_c":"",
      "assigned_user_mobile_token_expiry_c":"",
      "assigned_user_longitude_c":"",
      "assigned_user_latitude_c":"",
      "assigned_user_default_department_c":"Electrical",
      "assigned_user_mobile_token_c":"",
      "assigned_user_mobile_only_c":"",
      "assigned_user_job_billing_categories_id_c":"dd866fed-dcc6-a7eb-370d-543cd2764749",
      "assigned_user_billing_category":"Administration",
      "assigned_user_enable_scheduling_c":"",
      "assigned_user_calendar_icon_color_c":"#77f9f5",
      "assigned_user_user_team_id_c":"",
      "assigned_user_team":"",
      "assigned_user_user_type":"",
      "current_user_id":"2",
      "current_user_user_name":"crmonline",
      "current_user_picture":"",
      "current_user_first_name":"Glenn",
      "current_user_last_name":"Richmond",
      "current_user_full_name":"Glenn Richmond",
      "current_user_name":"Glenn Richmond",
      "current_user_is_admin":"1",
      "current_user_external_auth_only":"",
      "current_user_receive_notifications":"",
      "current_user_description":"",
      "current_user_date_entered":"17-06-2012 05:02pm",
      "current_user_date_modified":"10-04-2018 02:26pm",
      "current_user_last_login":"26-01-2019 11:30pm",
      "current_user_modified_user_id":"2",
      "current_user_modified_by_name":"Glenn Richmond",
      "current_user_created_by":"",
      "current_user_created_by_name":"",
      "current_user_title":"Sales Consultant",
      "current_user_department":"Admin",
      "current_user_phone_home":"",
      "current_user_phone_mobile":"0433 425 820",
      "current_user_phone_work":"08 9415 1366",
      "current_user_phone_other":"",
      "current_user_phone_fax":"",
      "current_user_status":"Active",
      "current_user_address_street":"",
      "current_user_address_city":"Belmont",
      "current_user_address_state":"WA",
      "current_user_address_country":"Australia",
      "current_user_address_postalcode":"6104",
      "current_user_UserType":"",
      "current_user_default_team":"1",
      "current_user_team_id":"1",
      "current_user_team_set_id":"1",
      "current_user_acl_team_set_id":"",
      "current_user_team_count":"",
      "current_user_team_name":"Global",
      "current_user_deleted":"",
      "current_user_portal_only":"",
      "current_user_show_on_employees":"",
      "current_user_employee_status":"Active",
      "current_user_messenger_id":"",
      "current_user_messenger_type":"",
      "current_user_reports_to_id":"",
      "current_user_reports_to_name":"",
      "current_user_email1":"grichmond@crmonline.com.au",
      "current_user_email":"",
      "current_user_email_link_type":"",
      "current_user_is_group":"",
      "current_user_c_accept_status_fields":"",
      "current_user_m_accept_status_fields":"",
      "current_user_accept_status_id":"",
      "current_user_accept_status_name":"",
      "current_user_accept_status_calls":"",
      "current_user_accept_status_meetings":"",
      "current_user_preferred_language":"",
      "current_user_acl_role_set_id":"",
      "current_user_disable_jobs_on_mobile_c":"",
      "current_user_disable_assets_on_mobile_c":"",
      "current_user_disable_quotes_on_mobile_c":"1",
      "current_user_start_date_c":"",
      "current_user_end_date_c":"",
      "current_user_office_extension_c":"",
      "current_user_vehicle_reg_c":"",
      "current_user_vehicle_type_c":"",
      "current_user_ipad_mobile_number_c":"",
      "current_user_ipad_sim_no_c":"",
      "current_user_complete_profile_c":"",
      "current_user_emergency_mobile_no_c":"",
      "current_user_emergency_address_c":"",
      "current_user_vehicle_allowance_c":"",
      "current_user_allowance_commenced_c":"",
      "current_user_allowance_renewal_c":"",
      "current_user_fuel_consumption_c":"",
      "current_user_ave_kms_c":"",
      "current_user_fbt_recovery_c":"",
      "current_user_fuel_rate_c":"",
      "current_user_litres_used_c":"",
      "current_user_private_use_c":"",
      "current_user_mobile_c":"",
      "current_user_daily_break_c":"0.50",
      "current_user_date_of_birth_c":"",
      "current_user_personal_mobile_c":"",
      "current_user_personal_email_c":"",
      "current_user_emergency_contact_c":"",
      "current_user_relationship_to_c":"",
      "current_user_emergency_mobile_c":"",
      "current_user_dl_number_c":"",
      "current_user_dl_expiry_c":"",
      "current_user_bank_name_c":"",
      "current_user_bsb_c":"",
      "current_user_super_fund_c":"",
      "current_user_super_no_c":"",
      "current_user_tax_file_no_c":"",
      "current_user_lsl_no_c":"",
      "current_user_account_no_c":"",
      "current_user_member_no_c":"",
      "current_user_portable_lsl_c":"",
      "current_user_hourly_cost_c":"35.00",
      "current_user_user_signature_image":"",
      "current_user_disable_manual_time":"",
      "current_user_job_billing_categories_id1_c":"TRAVEL",
      "current_user_travel_billing_category":"Travel",
      "current_user_template_user_c":"",
      "current_user_location_date_modified_c":"14-08-2018 09:19am",
      "current_user_gps_location_c":"142 Valero, Makati, 1227 Kalakhang Maynila, Philippines",
      "current_user_mobile_token_expiry_c":"10-11-2018 08:41am",
      "current_user_longitude_c":"121.0227",
      "current_user_latitude_c":"14.5585",
      "current_user_default_department_c":"Admin",
      "current_user_mobile_token_c":"e8c9c688-e3b7-11e8-9a07-22000a07008c",
      "current_user_mobile_only_c":"",
      "current_user_job_billing_categories_id_c":"SUPERVISOR",
      "current_user_billing_category":"Supervisor",
      "current_user_enable_scheduling_c":"",
      "current_user_calendar_icon_color_c":"#a0e1ef",
      "current_user_user_team_id_c":"",
      "current_user_team":"",
      "current_user_user_type":"",
      "merge_time":"26-01-2019 11:41pm",
      "today":"26-01-2019",
      "now":"26-01-2019 11:41pm",
      "custom_pdf_filename":"Quote#44351.pdf",
      "total_without_discount_and_tax":5210,
      "priority_color":"#00ff00",
    }
};