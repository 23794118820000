export class Material implements MaterialInterface {
    id: string;
    item_id: string = null;
    job_id: string = null;
    notes?: string = null;
    quantity: number = null;
    status: string = null;
    type?: string = null;
    unit_price: number = null;
    warehouse_id?: string = null;
    warehouse_name?: string = null;
    name: string;
    code: string;
    stock_quantity?: number;

    constructor(properties?: MaterialInterface) {
        this.id = properties.id;
        this.item_id = properties.item_id;
        this.job_id = properties.job_id;
        this.notes = properties.notes;
        this.quantity = properties.quantity;
        this.status = properties.status;
        this.type = properties.type;
        this.unit_price = properties.unit_price;
        this.warehouse_id = properties.warehouse_id;
        this.warehouse_name = properties.warehouse_name;
        this.name = properties.name;
        this.code = properties.code;
        this.stock_quantity = properties.stock_quantity || 0;
    }
}

export interface MaterialInterface {
    id: string;
    item_id: string;
    job_id: string;
    notes?: string;
    quantity: number;
    status: string;
    type?: string;
    unit_price: number;
    warehouse_id?: string;
    warehouse_name?: string;
    name: string;
    code: string;
    stock_quantity?: number;
}