// angular libs
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';

// protractor
import { Config } from 'protractor';

// reactivity
import { Observable } from 'rxjs';

// environment variable
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';

interface IScheduledInvoiceFilter {
  /**
   * Optional: contains the current filter for the next_invoice_date
   * for scheduled invoices filter
   *
   * @var {string|null}
   */
  nextInvoiceDate?: string;

  /**
   * Optional: contains the current filter for the customer_id for
   * scheduled invoices filter
   *
   * @var {string|null}
   */
  customerID?: string;

  /**
   * Optional: contains the current filter for the scheduled invoices filter
   *
   * @var {string|null}
   */
  siteID?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RecurringInvoiceService {

  protected kBaseUrl: string = environment.url + "/recurring_invoices/";

  /**
   * class constructor
   *
   * @param protected
   */
  constructor(
    protected http: HttpClient,
    protected notifications: NotificationService
  ) {}

  /**
   * retrieves the historical invoices from the current recurring invoice
   *
   * @param   {string} strRecurringInvoiceID
   * @param   {number} intLimit
   *
   * @returns {Observable<HttpResponse<Config>>}
   */
  getHistoricalInvoices$(strRecurringInvoiceID: string, intLimit: number = 20):  Observable<HttpResponse<Config>> {
    var objRequestBody = this.createRequestBody();

    // append the request data
    objRequestBody.append('id', strRecurringInvoiceID);
    objRequestBody.append('limit', String(intLimit).toString());

    // call the api
    return this.http.post<Response>(this.kBaseUrl + 'historical_invoices', objRequestBody.toString(), { observe: 'response' });
  }

  /**
   * process selected scheduled invoices into the API
   *
   * @param   {Array<string>} arScheduledInvoices
   *
   * @returns {Observable<HttpResponse<Config>>}
   */
  processScheduledInvoices(arScheduledInvoices: Array<string>): Observable<HttpResponse<Config>> {
    var objRequestBody = this.createRequestBody();

    // append id to the request for each selected one
    arScheduledInvoices.forEach((strScheduledInvoiceID) => {
      objRequestBody.append('id[]', strScheduledInvoiceID);
    });

    return this.http.post<Response>(this.kBaseUrl + 'generate_invoice', objRequestBody.toString(), { observe: 'response' })
      .pipe(
        tap({
          error: (objError: HttpErrorResponse) => {
            if (objError.status === 403) {
              this.notifications.notifyNotAllowed();
            }
          }
        })
      );
  }

  /**
   * builds the filter data for the current scheduled invoice request
   *
   * @param {IScheduledInvoiceFilter} objFilters
   *
   * @returns {object}
   */
  protected buildScheduledInvoiceFilters(objFilters: IScheduledInvoiceFilter): object {
    let arFinalFilters: object = {};

    // set customer_id filter
    if (objFilters.customerID) {
      arFinalFilters['customer_id'] = objFilters.customerID;
    }

    // set site id filter
    if (objFilters.siteID) {
      arFinalFilters['site_id'] = objFilters.siteID;
    }

    // set next_invoice_filter
    if (objFilters.nextInvoiceDate) {
      arFinalFilters['next_invoice_date'] = objFilters.nextInvoiceDate;
    }

    return arFinalFilters;
  }

  /**
   * creates a request body
   *
   * @returns {URLSearchParams}
   */
  protected createRequestBody(): URLSearchParams {
    return new URLSearchParams;
  }

  /**
   * converts the data into a json string representation
   *
   * @param   {object|Array<any>} arData
   *
   * @returns {string}
   */
  protected toJSON(arData: object|Array<any>): string {
    return JSON.stringify(arData);
  }
}
