import { Component, Input } from "@angular/core";
import { Pagination } from "./pagination";

@Component({
    selector: 'pagination-buttons',
    templateUrl: './pagination-buttons.component.html',
    styleUrls: ['./pagination-buttons.component.scss'],
})
export class PaginationButtons {

    /**
     * Receives the pagination object to display
     * proper caret pages.
     * 
     * @var {Pagination<any>}
     */
    @Input('objPagination') objPagination: Pagination<any>;

    /**
     * Toggle if the page totals should be shown or not.
     * 
     * @var {boolean}
     */
    @Input('bHideTotalEntries') bHideTotalEntries: boolean = false;

}