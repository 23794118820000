import { Activity } from "../../../../objects/activity";
import { Item } from "../../../../objects/item";
import { Relate } from "../../../../objects/relate";
import { toString } from 'lodash';
import { markup, toFormattedNumber } from '../../../../shared/utils/numbers';
import { blank } from '../../../../shared/utils/common';

export class MaterialLineItem {
    id: string;
    quantity: string;
    markup: string;
    unit_cost: string;
    unit_price: string;
    type: string;
    product: Relate<Item>;
    task: Relate<Activity>;
    exclude_from_invoice: boolean ;
    notes: string;
    sort_seq: number;
    work_order_reference?: string;
    from_work_order?: boolean;
    customer_invoice_id?: string;
    customer_invoice_number?: string;

    get total_cost() {
      return toString(
        toFormattedNumber(this._quantity * this._unit_cost, {
          currency: true,
        })
      );
    }

    get total_price() {
      return toString(
        toFormattedNumber(this._quantity * this._unit_price, {
          currency: true,
        })
      );
    }

    get _total_cost() {
      return parseFloat(this.total_cost);
    }

    get _total_price() {
      return parseFloat(this.total_price);
    }

    get _quantity() {
      return parseFloat(this.quantity);
    }

    set _quantity(value: number) {
      this.quantity = toString(toFormattedNumber(value));
    }

    get _markup() {
      return parseFloat(this.markup);
    }

    set _markup(value: number) {
      this.markup = toString(toFormattedNumber(value));
    }

    get _unit_cost() {
      return parseFloat(this.unit_cost);
    }

    set _unit_cost(value: number) {
      this.unit_cost = toString(toFormattedNumber(value, {
        currency: true,
      }));
    }

    get _unit_price() {
      return parseFloat(this.unit_price);
    }

    set _unit_price(value: number) {
      this.unit_price = toString(toFormattedNumber(value, {
        currency: true,
      }));
    }

    get is_deletable(): boolean {
      return blank(this.customer_invoice_id);
    }

    get is_editable(): boolean {
      return blank(this.customer_invoice_id);
    }

    get is_disabled(): boolean {
      return ! this.is_editable || ! this.is_deletable;
    }

    markUpComputation(): number {
      return markup({
        unit_price: this._unit_price,
        unit_cost: this._unit_cost,
      });
    }

    /**
     * Checks if customer has pricebook items, if yes,
     * finds selected material from items list, applies
     * the discount, and computes for the final price
     *
     * @param arPricebookItems - pricebook items with discounts
     *
     * @returns {number}
     */
    computeUnitPrice(arPricebookItems: Array<any>): number {
      let fltFinalUnitPrice = this.product.value.unit_price;

      if (arPricebookItems) {
        const objMaterialPricebookItem = arPricebookItems.find(objPricebookItem => {
          return objPricebookItem.item_id === this.product.value.id;
        });

        if (objMaterialPricebookItem) {
          fltFinalUnitPrice = objMaterialPricebookItem.pricebook_unit_price;
        }
      }

      return fltFinalUnitPrice;
    }

    recalculateUnitPrice(): void {
      this._unit_price = toFormattedNumber(
        this._unit_cost + (this._unit_cost * (this._markup / 100))
      );
    }

    getMaterialData(strJobId: string = '') {
      return {
        "id": this.id,
        "type": this.type,
        "quantity": this._quantity,
        "unit_cost": this._unit_cost,
        "unit_price": this._unit_price,
        "total_price": this._total_price,
        "total_cost": this._total_cost,
        "markup": this._markup,
        "product": (this.type == 'once_off_purchase') ? this.product.value : "",
        "item_id": this.product.value.id ? this.product.value.id : "",
        "item_name": this.product.value.text ? this.product.value.text : "",
        "activity_id": this.task.value ? this.task.value.id : "",
        "job_id": strJobId,
        "notes": (this.type == 'once_off_purchase') ? (this.notes || this.product.value) : this.notes,
        "sort_seq": this.sort_seq,
        "work_order_reference": this.work_order_reference,
        "from_work_order": this.from_work_order,
      };
    }

    constructor(properties: any = {}) {
      this.id = properties.id || '';
      this.type = properties.type || 'product_catalog';
      this.product = properties.product;
      this.task = properties.task;
      this.markup =  properties.markup || '0.000';
      this.quantity = properties.quantity || '0.000';
      this.unit_cost = properties.unit_cost || '0.0000';
      this.unit_price = properties.unit_price || '0.0000';
      this.sort_seq = properties.sort_seq || 0;
      this.notes = properties.notes;
      this.from_work_order = properties.from_work_order;
      this.work_order_reference = properties.work_order_reference;
      this.customer_invoice_id = properties.customer_invoice_id;
      this.customer_invoice_number = properties.customer_invoice_number;
    }
}
