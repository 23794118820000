import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Component({
  selector: 'refresh-pagination-btn',
  template: `<li class="page-item" [class.disabled]="isDisabled">
    <a class="page-link" [class.active]="! isDisabled" matTooltip="{{ 'refresh' | translate }}"><fa-icon [icon]="['fas', 'sync-alt']"></fa-icon></a>
  </li>`,
  styleUrls: [
    './refresh-pagination.component.scss'
  ]
})
export class RefreshPaginationButtonComponent {
  /**
   * Event emmited when action is refresh
   *
   * @var {EventEmitter<Event>}
   */
  @Output('refresh') refresh: EventEmitter<Event> = new EventEmitter;

  /**
   * Flag if the current button should be disabled
   *
   * @var {boolean}
   */
  @Input('isDisabled') isDisabled: boolean = false;

  /**
   * Handle host click event
   *
   * @param {Event} $event
   */
  @HostListener('click') onClick($event: Event): void {
    if (! this.isDisabled) this.refresh.emit($event);
  }
}