export enum ModuleLogo {
    'users' = 'users',
    'departments' = 'sitemap',
    'resources' = 'road',
    'leads' = 'id-card',
    'customers' = 'users',
    'sites' = 'map-marker-alt',
    'contacts' = 'address-book',
    'opportunities' = 'list-ul',
    'jobs' = 'wrench',
    'assets' = 'calendar',
    'customer_invoices' = 'dollar-sign',
    'purchase_orders' = 'shopping-bag',
    'supplier_invoices' = 'file-alt',
    'desktop' = 'desktop',
    'quotes' = 'paste',
    'items' = 'box-open',
    'teams' = 'users',
    'pricebooks' = 'money-check',
    'tax_codes' = 'box-open',
    'document_library' = 'file-pdf',
    'asset_types' = 'wrench',
    'project_templates' = 'tasks',
    'job_skills' = 'user-hard-hat',
    'account_codes' = 'box-open',
    'checklists' = 'clipboard-list-check',
    'activities' = 'calendar',
    'imports' = 'file-import',
    'email_templates' = 'file-alt',
    'sms_templates' = 'sms',
    'job_safety_sub_task_templates' = 'hard-hat',
    'recurring_jobs' = 'briefcase',
    'recurring_invoices' = 'dollar-sign',
    'exports' = 'file-export',
    'stock_levels' = 'inventory',
    'stock-receipts' = 'receipt',
    'warehouses' = 'warehouse-alt',
    'stocktakes' = 'people-carry',
    'materials' = 'box-full',
  };
