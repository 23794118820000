import { Injectable } from '@angular/core';
import { bool } from 'aws-sdk/clients/signer';

@Injectable({
  providedIn: 'root'
})

export class StrService {

  protected acceptableCharactersPattern = /[^\w\. !(){}\[\]\\\"\/:;,?@#\$%\^&\*-=_\+|.<>\`~\']/mi;

  constructor() { }

  /**
   * Check if the given key is set and not empty
   *
   * @param objData
   * @param strKey
   * @param fallBack
   */
  fallsBackTo(strValue: string, fallBack = null): string | null {
    return (strValue !== undefined && strValue !== null && strValue !== '') ? strValue : fallBack;
  }

  /**
   * This will validate if the string
   * given is a valid json string
   *
   * @param jsonString
   */
  isValidJsonString(jsonString: string): boolean {
    try {
      JSON.parse(jsonString);
    } catch (e) {
        return false;
    }

    return true;
  }

  /**
   * check if the inputed string has invalid characters
   *
   * @param {string} strInput
   * @returns {bool}
   */
  hasInvalidCharacters(strInput: string): bool {
    return strInput.match(this.acceptableCharactersPattern) !== null;
  }
}
