<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'shopping-bag']" ></fa-icon>&nbsp;
        <span>{{ 'create_purchase_orders' | translate }} </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button (click)="savePurchaseOrder()" class="btn ml-1 btn-primary" [disabled]="arItems.length === 0 || bSaving">
            <fa-icon *ngIf="!bSaving" [icon]="['fas', 'save']"></fa-icon>
            <fa-icon *ngIf="bSaving" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<mat-dialog-content class="mobile-view-dialog">
  <ng-container *ngIf="!bLoading; else isLoading">
    <div class="row">
      <div class="col-md-6">
        <label>{{ 'delivery_to'  | translate }}</label>
        <ng-select
          id="wPODeliverTo"
          [(ngModel)]="strPODeliverTo"
          [items]="['main_warehouse', 'pickup', 'site', 'refer_to_delivery_notes']"
          (change)="onDeliverToChange($event)"
          class="w-170">
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span>{{ item | translate }}</span>
          </ng-template>
          <ng-template  ng-option-tmp let-item="item">
            <span> {{ item | translate }} </span>
          </ng-template>
        </ng-select>
      </div>
      <div *ngIf="strPODeliverTo == 'main_warehouse' || strPODeliverTo == 'site'" class="col-md-6">
        <label>{{ objDeliveryConfig[strPODeliverTo].label | translate }}</label>
        <ng-select #ngSelectModule
          id="wPOModuleSelect"
          [(ngModel)]="strModuleId"
          [items]="objModuleRelate.source | async"
          [typeahead]="objModuleRelate.typehead"
          [loading]="objModuleRelate.loader"
          [(ngModel)]="objModuleRelate.value"
          (open)="objModuleRelate.loadDataOnClick()"
          (change)="onModuleChange($event)"
          bindValue="id"
          bindLabel="text"
          class="w-170">
        </ng-select>
      </div>
      <div *ngIf="strPODeliverTo == 'refer_to_delivery_notes' || strPODeliverTo == 'main_warehouse'" class="col-md-12 mt-2">
        <label>{{ 'delivery_notes'  | translate }}</label>
        <textarea
          class="form-control textarea-style"
          [(ngModel)]="strDeliveryNotes">
        </textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="text-right h-auto">
          <button
            id="add_additional_code"
            type="button"
            class="btn btn-link align-bottom"
            (click)="addItem()">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
            <span class="pl-1">{{ 'add_item' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="text-center font-weight-bold">
          <td colspan="6">
            {{ 'items_to_order' | translate }}
          </td>
        </tr>
        <tr class="text-center font-weight-bold">
          <td width="25%">
            {{ 'item' | translate }}
          </td>
          <td width="25%">
            {{ 'supplier_name' | translate }}
          </td>
          <td width="15%">
            {{ 'quantity' | translate }}
          </td>
          <td width="15%">
            {{ 'unit_cost' | translate }}
          </td>
          <td width="10%">
            {{ 'update_pricing' | translate }}
          </td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="arItems.length; else noItem;">
          <ng-container *ngFor="let item of arItems; let i = index;">
            <tr>
              <td class="border-top-0" width="25%">
                <div class="d-flex justify-content-start">
                  <ng-select
                    style="width:100%;"
                    id="wizardItem{{ i }}"
                    [(ngModel)]="item.item_relate.value"
                    placeholder="{{ 'select_item' | translate }}"
                    matTooltip="{{ item.item_relate.value?.text }}"
                    [items]="item.item_relate.source | async"
                    [typeahead]="item.item_relate.typehead"
                    [loading]="item.item_relate.loader"
                    [clearable]="false"
                    bindLabel="name"
                    (change)="onChangeItem(item)"
                    appendTo="body"
                    [ngClass]="{'is-invalid': bSubmitted && !item.item_relate.value}"
                    (open)="item.item_relate.loadDataOnClick()">
                  </ng-select>
                  <fa-icon
                    *ngIf="item.related_products.length > 0"
                    matTooltip="{{ 'add_related_products' | translate }}"
                    class="pl-2 pb-2 pt-2 pointer text-success"
                    [icon]="['fas', 'plus-circle']"
                    (click)="addRelated(item)">
                  </fa-icon>
                </div>
              </td>
              <td class="border-top-0" width="25%">
                <ng-select
                  id="wizardSupplier{{ i }}"
                  [(ngModel)]="item.customer_relate.value"
                  placeholder="{{ 'select_supplier' | translate }}"
                  matTooltip="{{ item.customer_relate.value?.text }}"
                  [items]="item.customer_relate.source | async"
                  [typeahead]="item.customer_relate.typehead"
                  [loading]="item.customer_relate.loader"
                  [clearable]="true"
                  bindLabel="text"
                  (change)="onChangeCustomer(item)"
                  [ngClass]="{'is-invalid': bSubmitted && !item.customer_relate.value}"
                  appendTo="body">
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <span *ngIf="item.primary" class="pr-1 text-muted">[{{ 'preferred_supplier' | translate }}]</span>
                    <span *ngIf="item.extras" class="pr-1 text-muted">[{{ item.extras?.code }}]</span>
                    <span>{{ item.text }}</span>
                    <span *ngIf="item.extras" class="pl-1 text-muted">( {{ item.extras?.unit_cost | number : '1.2-2' }} )</span>
                    <ng-template #noteInfo>
                      <div class="d-flex flex-column" style="white-space: pre-line; text-align: start;">
                        <span>{{ item.extras?.notes | translate }}</span>
                      </div>
                  </ng-template>
                    <span *ngIf="item.extras && item.extras?.notes" container="body" placement="right" [ngbTooltip]="noteInfo" style="float: inline-end;"><fa-icon class="text-primary" [icon]="['fas', 'info-circle']"></fa-icon></span>
                  </ng-template>
                </ng-select>
              </td>
              <td class="border-top-0" width="15%">
                <fieldmagic-decimal-input
                  id="wizardQuantity{{ i }}"
                  customClass="form-control font-size-12"
                  [(ngModel)]="item.quantity"
                  [ngClass]="{'is-invalid': bSubmitted && !item.quantity}"
                  ngDefaultControl
                ></fieldmagic-decimal-input>
              </td>
              <td class="border-top-0" width="15%">
                <fc-currency-input
                  [places]="4"
                  id="wizardUnitCost{{ i }}"
                  [(ngModel)]="item.unit_cost"
                  [ngModelOptions]="{ standalone: true }"
                  [nonNegative]="true"
                  [customClass]="bSubmitted && !item.unit_cost
                    ? 'is-invalid form-control font-size-12 input-height-40'
                    : 'form-control font-size-12 input-height-40'"
                  (change)="onChangeUnitCost(item)"
                >
                </fc-currency-input>
              </td>
              <td class="border-top-0 text-center align-middle" width="10%">
                <mat-slide-toggle
                  [(ngModel)]="item.is_supplier_new_pricing"
                  color="primary"
                  class="font-size-11 mt-2"
                  (change)="onChangeUpdatePricing(item, $event)"
                  matTooltip="{{ 'save_pricing_to_supplier' | translate }}"
                  [disabled]="!item.item_relate.value || !item.customer_relate.value"
                ></mat-slide-toggle>
              </td>
              <td class="border-top-0" width="10%">
                <div class="col-md-12 p-0 m-0 text-center d-inline-block align-middle">
                  <fa-icon
                    class="text-danger remove-button"
                    matTooltip="{{ 'remove' | translate }}"
                    (click)="removeItem(i)"
                    [icon]="['fal', 'minus-circle']">
                  </fa-icon>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-template #noItem>
          <tr>
            <td class="text-center text-muted" colspan="6">
              {{ 'please_add_item' | translate }}
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </ng-container>
  <ng-template #isLoading>
    <div class="row d-flex justify-content-center">
      <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true" size="lg"></fa-icon>
    </div>
  </ng-template>
</mat-dialog-content>

