import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { trimEnd } from 'lodash';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Differential } from '../difference';

@Injectable({
  providedIn: 'root'
})
export class ProfitabilityAnalystService {
  /**
   * @param {HttpClient} http
   */
  constructor(
    protected readonly http: HttpClient
  ) {}

  /**
   * Analyzes the data of the given job for profitability
   *
   * @param   {string} jobId
   *
   * @returns {Observable<BaseProfitabilityAnalysis>}
   */
  analyze(jobId: string): Observable<BaseProfitabilityAnalysis> {
    const params = new URLSearchParams;

    params.append('job_id', jobId);

    return this.http.post<BaseProfitabilityAnalysis>(`${trimEnd(environment.url, '/')}/profitability_analysis`, params.toString(), {
      observe: 'response'
    }).pipe(
      map(({ body }) => body)
    );
  }

  /**
   * Adjust current analysis variables
   *
   * @param {string} jobId
   * @param {AnalysisAdjustments} adjustments
   *
   * @returns {Observable<boolean>}
   */
  adjust(jobId: string, adjustments: AnalysisAdjustments): Observable<boolean> {
    const params = new URLSearchParams;

    params.append('job_id', jobId);
    params.append('adjustments', JSON.stringify(adjustments));

    return this.http.post<boolean>(`${trimEnd(environment.url, '/')}/profitability_analysis/save_adjustments`, params.toString(), {
      observe: 'response',
    }).pipe(
      map((r) => r.status === 200)
    );
  }
}
export interface BaseLabour {
  id: string;
  name: string;
  billing_category: string;
}

export interface BaseMaterial {
  id: string;
  name: string;
  quantity: number;
}

export enum ProfitabilityAnalysisType {
  time_and_materials = 'time_and_materials',
  fixed_price = 'fixed_price',
}

export interface BaseProfitabilityAnalysis {
  type: keyof typeof ProfitabilityAnalysisType;
  labour_estimated_cost: number;
  labour_estimated_revenue: number;
  labour_actual_cost: number;
  labour_actual_revenue: number;
  labour_cost_difference: Differential;
  labour_revenue_difference: Differential;
  labour_estimated_duration: number;
  labour_actual_duration: number;
  labour_estimated_margin: number;
  labour_estimated_markup: number;
  labour_actual_margin: number;
  labour_actual_markup: number;
  materials_estimated_cost: number;
  materials_estimated_revenue: number;
  materials_actual_cost: number;
  materials_actual_revenue: number;
  materials_cost_difference: Differential;
  materials_revenue_difference: Differential;
  materials_estimated_margin: number;
  materials_estimated_markup: number;
  materials_actual_margin: number;
  materials_actual_markup: number;
  total_estimated_cost: number;
  total_actual_cost: number;
  total_cost_difference: Differential;
  total_estimated_profit_margin: number;
  total_estimated_revenue: number;
  total_actual_revenue: number;
  total_revenue_difference: Differential;
  total_actual_profit: number;
  total_actual_profit_margin: number;
  total_estimated_markup: number;
  total_actual_markup: number;
  has_invoice: boolean;
}

export interface BaseAdjustment {
  id: string;
}

export interface LabourAdjustment extends BaseAdjustment {
  estimated_duration: number;
  estimated_hourly_cost: number;
  actual_cost: number;
}

export interface MaterialAdjustment extends BaseAdjustment {
  actual_unit_cost: number;
}

export interface AnalysisAdjustments {
  labours: LabourAdjustment[];
  materials: MaterialAdjustment[];
  total_labour_estimated_cost: number;
  total_labour_actual_cost: number;
  total_materials_estimated_cost: number;
  total_materials_actual_cost: number;
  total_estimated_cost: number;
  total_actual_cost: number;
  total_estimated_revenue: number;
  total_actual_revenue: number;
  total_actual_profit: number;
  total_estimated_profit: number;
  total_actual_profit_margin: number;
}