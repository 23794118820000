<header>
  <div class="d-flex dialog-header">
    <h3 class="title mr-2">
        <fa-icon
          class="pr-2"
          [icon]="['fas', 'clock']">
        </fa-icon>
       <span>{{ 'reassign_future_tasks' | translate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline">
        <li class="ml-2 mb-1 pull-right">
          <button id="btnOnUnAssign" type="button" (click)="onUnassign()" class="btn btn-primary responsive-button">
            <fa-icon [icon]="['fas', 'unlink']"></fa-icon>
            <span class="pl-1">{{ 'unassign' | translate }}</span>
          </button>
        </li>
        <li class="ml-2 mb-1 pull-right">
          <button id="btnOnReassign" type="button" (click)="onReassign()" class="btn btn-primary responsive-button" [disabled]="!canReAssign">
            <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
            <span class="pl-1">{{ 'assign' | translate }}</span>
          </button>
        </li>
        <li class="ml-2 mb-1 pull-right">
          <button id="btnOnCancel" type="button" (click)="onCancel()" class="btn btn-primary responsive-button">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>

<hr/>

<mat-dialog-content>
  <div class="alert alert-warning">
    <span>{{ 'reassign_future_tasks_info' | translate }}</span>
  </div>
  <div class="form-group" [formGroup]="form">
    <label for="user_id">{{ 'assign_to' | translate }}</label>
    <ng-select
      id="selectAssignTo"
      appendTo="body"
      [items]="users.source | async"
      [loading]="users.loader"
      [typeahead]="users.typehead"
      bindLabel="text"
      bindValue="id"
      formControlName="user_id">
      <ng-template ng-option-tmp let-item="item" class="value-text optionsValue">
        {{ (item.text) ? item.text : item.email_address }}
      </ng-template>

      <ng-template ng-label-tmp let-item="item" let-clear="clear">
          {{ (item.text) ? item.text : item.email_address }}
      </ng-template>
    </ng-select>
  </div>
</mat-dialog-content>