<app-error-handler onlyFor="stock_management">
  <section>
    <header>
      <div class="recurring-title">
        <div class="row">
          <div class="col-xs-6 col-md-6 d-flex">
            <h5 class="font-weight-light pull-left text-primary">
              <span>
                <fa-icon [icon]="['fas', 'box-open']" class="pr-1"></fa-icon>{{ 'reorder_items' | translate }}
              </span>
            </h5>
          </div>
          <div class="col-xs-6 col-md-6">

            <button class="btn ml-2 btn-primary float-right" [disabled]="bDisabledReorder" (click)="reorderProducts()">
              <button-icon-loader [defaultIcon]="['fas', 'box-open']" [isLoading]="bIsSaving"></button-icon-loader>
              <span class="pl-1">{{ 'reorder_products' | translate }}</span>
            </button>

            <div class="float-right ml-2 email-suppliers">
              <mat-slide-toggle
                color="primary"
                [(ngModel)]="bEmailToSuppliers">
              </mat-slide-toggle>
              <span class="font-size-12"> {{ 'email_po_to_suppliers' | translate }} </span>
            </div>

            <button [routerLink]="['/stock_levels']"
              type="button"
              class="btn btn-link float-right">
                <fa-icon [icon]="['fas', 'arrow-left']" class="pr-1"></fa-icon>{{ 'back_to_list' | translate }}
            </button>

          </div>
        </div>
      </div>
    </header>

    <div class="row pt-2">

      <div class="col-xs-12 col-md-12 mb-3">
        <app-information
          strType="listing"
          strModule="stock_reorders"
          [isPanel1Visible]= "true"
          [isPanel2Visible]= "true"
          [isPanel3Visible]= "true" >
        </app-information>
      </div>


      <div class="col-md-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <td colspan="11">
                <div class="text-right h-auto">
                  <button
                    id="add_additional_code"
                    type="button"
                    class="btn btn-link align-bottom"
                    (click)="addItem()">
                    <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
                    <span class="pl-1">{{ 'add_item' | translate }}</span>
                  </button>
                </div>
              </td>
            </tr>
            <tr class="text-center font-weight-bold">
              <td width="12%">{{ 'warehouse' | translate }}</td>
              <td width="12%">{{ 'product_name' | translate }}</td>
              <td width="18%">{{ 'preferred_supplier' | translate }}</td>
              <td width="8%">{{ 'buy_price' | translate }}</td>
              <td width="8%">{{ 'minimum_quantity' | translate }}</td>
              <td width="8%">{{ 'total_current_stock' | translate }}</td>
              <td width="8%">{{ 'reorder_quantity' | translate }}</td>
              <td width="8%">{{ 'lead_time' | translate }} ({{ 'days' | translate }})</td>
              <td width="8%">{{ 'stock_on_order' | translate }}</td>
              <td width="5%">{{ 'update_pricing' | translate }}</td>
              <td
                width="5%"
                class="text-center">
                <input [(ngModel)]="bIsAllChecked" type="checkbox" (change)="checkAll()">
              </td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of arReorderList; let i = index;">
              <tr [class.table-success]="item.is_included">
                <td>
                  <ng-template [ngIf]="item.is_additional" [ngIfElse]="warehouseDetails">
                    <ng-select
                      placeholder="{{ 'select_warehouse' | translate }}"
                      matTooltip="{{ item.warehouse_relate.value?.text }}"
                      [(ngModel)]="item.warehouse_relate.value"
                      [items]="item.warehouse_relate.source | async"
                      [typeahead]="item.warehouse_relate.typehead"
                      [loading]="item.warehouse_relate.loader"
                      (open)="item.warehouse_relate.loadDataOnClick()"
                      [clearable]="false"
                      bindLabel="name"
                      (change)="onChangeWarehouse(item)"
                      [ngClass]="{'is-invalid': bSubmitted && item.is_included && !item.warehouse_relate.value}">
                    </ng-select>
                  </ng-template>
                  <ng-template #warehouseDetails>
                    <div class="truncate" matTooltip="{{ 'warehouse' | translate }}: {{ item.warehouse_text }}">{{ item.warehouse_text }}</div>
                  </ng-template>
                </td>
                <td>
                  <ng-template [ngIf]="item.is_additional" [ngIfElse]="itemDetails">
                    <ng-select
                      placeholder="{{ 'select_item' | translate }}"
                      matTooltip="{{ item.item_relate.value?.text }} {{ (item.item_relate.value?.code) ? '(' +item.item_relate.value?.code+ ')' : '' }}"
                      [(ngModel)]="item.item_relate.value"
                      [items]="item.item_relate.source | async"
                      [typeahead]="item.item_relate.typehead"
                      [loading]="item.item_relate.loader"
                      (open)="item.item_relate.loadDataOnClick()"
                      [clearable]="false"
                      bindLabel="name"
                      (change)="onChangeItem(item)"
                      [ngClass]="{'is-invalid': bSubmitted && item.is_included && !item.item_relate.value}">
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span>{{ item.text }} ({{ item.code }})</span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" class="font-size-11">
                        <span title="{{ item.text }} ({{ item.code }})">{{ item.text }} ({{ item.code }})</span>
                      </ng-template>
                    </ng-select>
                  </ng-template>
                  <ng-template #itemDetails>
                    <div class="truncate" matTooltip="{{ 'product_name' | translate }}: {{ item.item_text }}">{{ item.item_text }}</div>
                  </ng-template>
                </td>
                <td>
                  <ng-select
                    #supplierRelate
                    id="preferred_supplier_{i}"
                    placeholder="{{ 'select_preferred_supplier' | translate }}"
                    matTooltip="{{ item.preferred_supplier.value?.text }}"
                    [(ngModel)]="item.preferred_supplier.value"
                    [items]="item.preferred_supplier.source | async"
                    [typeahead]="item.preferred_supplier.typehead"
                    [loading]="item.preferred_supplier.loader"
                    [addTag]="addTagPromise.bind(this, supplierRelate)"
                    [clearable]="true"
                    (change)="onChangeSupplier(item)"
                    bindLabel="name"
                    [ngClass]="{'is-invalid': bSubmitted && item.is_included && !item.preferred_supplier.value}">
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span *ngIf="item.primary" class="mr-1">
                          <fa-icon [icon]="['fas', 'star']" class="text-primary"></fa-icon>
                        </span>
                        <span *ngIf="item.unit_cost != null" class="text-muted pr-1">( {{ item?.unit_cost | currency | placeholdWithString: '0.00' }} )</span>
                        <span>{{ item.name }}</span>
                      </ng-template>
                      <ng-template ng-tag-tmp let-search="searchTerm">
                        {{ 'create_new_supplier' | translate }}
                      </ng-template>
                  </ng-select>
                </td>
                <td>
                  <fc-currency-input
                    [(ngModel)]="item.unit_cost"
                    (change)="onChangeUnitCost(item)"
                    [customClass]="bSubmitted && item.is_included && item.unit_cost <= 0 ? 'form-control font-size-11 is-invalid' : 'form-control font-size-11'"
                    decimalPipe
                    ngDefaultControl
                    nonNegative="true"
                  ></fc-currency-input>
                </td>
                <td class="border-top-0 text-center align-middle">{{ item.min_stock_level }}</td>
                <td class="border-top-0 text-center align-middle">{{ item.stock_level_quantity }}</td>
                <td>
                  <input
                    [(ngModel)]="item.min_reorder_level"
                    type="number"
                    min=1
                    step="0.1"
                    [ngClass]="{'is-invalid': bSubmitted && item.is_included && !item.min_reorder_level}"
                    class="form-control font-size-11"
                    (keypress)="negateNegative($event)"
                  />
                </td>
                <td>
                  <input
                    [(ngModel)]="item.lead_time"
                    type="number"
                    min=0
                    step="0.1"
                    class="form-control font-size-11"
                    (keypress)="negateNegative($event)"
                  />
                </td>
                <td class="border-top-0 text-center align-middle">
                  <ng-template [ngIf]="item.purchase_orders && item.purchase_orders.length" [ngIfElse]="hasNoPendingOrder">
                    <a
                      id="show_purchase_order{{i}}"
                      role="button"
                      aria-expanded="false"
                      data-toggle="collapse"
                      href="#collapseWarehouse{{i}}"
                      class="text-reset text-decoration-none"
                      matTooltip="{{ 'view_on_ordered_purchase_order' | translate }}">
                      {{ computeOnOrdered(item.purchase_orders) }}
                    </a>
                  </ng-template>
                  <ng-template #hasNoPendingOrder>0</ng-template>
                </td>
                <td class="border-top-0 text-center align-middle">
                  <mat-slide-toggle
                    [(ngModel)]="item.is_supplier_new_pricing"
                    color="primary"
                    class="font-size-11 mt-2"
                    matTooltip="{{ 'save_pricing_to_supplier' | translate }}"
                    [disabled]="!item.preferred_supplier.value"
                  ></mat-slide-toggle>
                </td>
                <td class="text-center">
                  <div class="col-md-12 p-0 m-0 text-center">
                    <input
                      [(ngModel)]="item.is_included"
                      type="checkbox"
                      matTooltip="{{ 'add' | translate }}"
                      [disabled]="item.is_additional">
                  </div>
                  <div *ngIf="item.is_additional" class="col-md-12 p-0 m-0 text-center">
                    <fa-icon
                      matTooltip="{{ 'remove' | translate }}"
                      [icon]="['fas', 'minus-circle']"
                      class="text-danger pointer"
                      (click)="removeItemReorder(i)">
                    </fa-icon>
                  </div>
                </td>
              </tr>
              <ng-container *ngIf="item.purchase_orders && item.purchase_orders.length">
                <tr class="collapse" id="collapseWarehouse{{i}}">
                  <td colspan="11">
                    <table class="table mb-0 purchase-order-table">
                      <thead>
                        <tr class="text-center font-weight-bold">
                          <th scope="col">{{ 'po_number' | translate }}</th>
                          <th scope="col">{{ 'quantity_received' | translate }}</th>
                          <th scope="col">{{ 'quantity_remaining' | translate }}</th>
                        </tr>
                      </thead>
                      <ng-container *ngFor="let purchase_order of item.purchase_orders;">
                        <tr class="text-center font-weight-bold">
                          <td>
                            <span
                              class="word-wrap"
                              matTooltip="{{ 'po_number' | translate }}: {{ '#' + purchase_order.purchase_order_text }}"
                              >
                              <a href="/#/purchase_orders/{{ purchase_order.purchase_order_id }}">#{{ purchase_order.purchase_order_text }}</a>
                            </span>
                          </td>
                          <td>{{ purchase_order.quantity_received }}</td>
                          <td>{{ purchase_order.quantity_remaining }}</td>
                        </tr>
                      </ng-container>
                    </table>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</app-error-handler>
