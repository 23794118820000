export default class FormTemplateHistory {
  /**
   * contains the unique identifier for the template
   *
   * @var {string}
   */
  id: string;

  /**
   * contains the path of the odt file
   *
   * @var {string|string[]}
   */
  path: string | string[];

  /**
   * this contains our form template history version
   *
   * @var {string}
   */
  version: string;

  /**
   * contains the current form template metadata
   *
   * @var {FormTemplateHistoryMetadata}
   */
  metadata: FormTemplateHistoryMetadata;

  /**
   * tells if the current form template is the default for the form
   *
   * @var {boolean}
   */
  isDefault?: boolean = false;

  constructor(data: {
    id: string,
    path: string | string[],
    version: string,
    metadata: FormTemplateHistoryMetadata,
    isDefault?: boolean,
  }) {
    this.id = data.id;
    this.version = parseFloat(data.version).toFixed(1);
    this.path = data.path;
    this.metadata = data.metadata;
    this.isDefault = data.isDefault || false;
  }
}

interface FieldOption {
  /**
   * contains the default value of the field
   *
   * @var {string}
   */
  defaultValue: string;

  /**
   * format of the date to be used if any
   *
   * @var {string}
   */
  format?: string;
}

export const FIELD_TEXT = 'text';
export const FIELD_DATE = 'date';
export const FIELD_DATETIME = 'datetime';
export const FIELD_BOOLEAN = 'boolean';
export const FIELD_NUMBER = 'number';

export interface FormTemplateCustomField {
  /**
   * contains the name of the custom field
   *
   * @var {string}
   */
  name: string;

  /**
   * contains the label of the custom field
   *
   * @var {string}
   */
  text: string;

  /**
   * contains what type of the current custom field is needed
   *
   * @var {string}
   */
  type: string;

  /**
   * contains the options of the field
   *
   * @var {FieldOption}
   */
  options?: FieldOption
}

export interface FormTemplateHistoryMetadata {
  /**
   * contains the custom fields that are present in the form template
   *
   * @var {FormTemplateCustomField[]}
   */
  fields: FormTemplateCustomField[];
}
