import { FileService } from './../../../../services/file/file.service';
import { AssetPhotoDialogComponent } from "./asset-photo-dialog/asset-photo-dialog.component";
import { NotificationService } from "./../../../../services/notification.service";
import { MatDialog } from "@angular/material";
import { Component, OnInit, Inject, Input, TemplateRef } from "@angular/core";
import { UploadConfig } from "./../../../../lists/upload-config";
import { PreviewComponent } from './../file/preview/preview.component';
import { ViewService } from "./../../../../services/view.service";
import { DOCUMENT } from '@angular/platform-browser';
import { WidgetService } from "./../../../../services/widget.service";
import { RecordService } from './../../../../services/record.service';
import { ImageViewComponent } from './../../image-view/image-view.component';
import { Notification } from '../../../../objects/notification';
import { environment } from '../../../../../environments/environment';
import * as moment from 'moment';
import { clone } from "lodash";
import { LooseObject } from '../../../../objects/loose-object';
import { Router } from '@angular/router';

@Component({
  selector: "app-asset-photos",
  templateUrl: "./asset-photos.component.html",
  styleUrls: ["./asset-photos.component.scss"],
})
export class AssetPhotosComponent implements OnInit {
  @Input() strRecordId: string;
  @Input() strModule: string;
  @Input() arRecordDetails: object;
  @Input() objFetchedRecordDetails: LooseObject;

  public selectDate: string;
  public selectType: string;
  public selectSortFile: string;
  public strFilterFile: string;
  public arPhotoList = [];
  public uploadConfig = new UploadConfig();
  public bLoading = false;
  public objAssetRecord: LooseObject = {};

  constructor(
    private dialog: MatDialog,
    private notifService: NotificationService,
    private widgetService: WidgetService,
    private viewService: ViewService,
    private recordService: RecordService,
    private fileService: FileService,
    @Inject(DOCUMENT) private document: any,
    private router: Router
  ) {}

  ngOnInit() {
    this.getAssetRecord();
    this.arPhotoList = this.objFetchedRecordDetails.photos || [];
  }

  /**
   * Update asset record
   *
   * @param data
   * @param message
   */
  updateAssetRecord(data,message){
    this.recordService
    .saveRecord("assets", data, this.strRecordId)
    .subscribe((asset) => {
      if (typeof asset === "string") {
        this.notifService.sendNotification(message.message_header_fail, message.message_header_fail_message, message.message_header_fail_theme);
      } else {
        this.notifService.sendNotification(message.message_header_success, message.message_header_success_message, message.message_header_success_theme);
        // get the latest record
        this.recordService.getRecord('assets', this.viewService.getRecord().record_details.id).subscribe(data => {
          this.arPhotoList = data['record_details'].photos;
        })
      }
    });
  }

  /**
   * Opens file upload form
   * @param date
   */
  openFileForm() {
    var clonePhotoList = clone(this.arPhotoList);

    let objFileData = {
      width: "700px",
      height: "auto",
      // Data to be passed on
      data: {
        module: this.strModule,
        record_id: this.strRecordId,
        photos: clonePhotoList,
        assets: this.objAssetRecord,
      },
      disableClose: true,
    };

    let tabDialogRef;

    tabDialogRef = this.dialog.open(AssetPhotoDialogComponent, objFileData);

    // Get response after closing or submitting files dialog
    tabDialogRef.afterClosed().subscribe((paramsAssetPhotos) => {
      // Check if button clicked is not cancel button
      if (paramsAssetPhotos != "cancel" && paramsAssetPhotos) {
        this.updateAssetRecord(paramsAssetPhotos['data'],{
          message_header_success: "success",
          message_header_success_message: "asset_photos_successfully_saved",
          message_header_success_theme: "success",
          message_header_fail: "header_notification.generic_fail",
          message_header_fail_message: "content_notification.fail_s3_upload",
          message_header_fail_theme: "danger"
        });
      }
    });
  }

  // Download a file from s3 bucket
  downloadFile(upload_name, file_type,file_name) {
    this.fileService.download(upload_name, file_type,file_name)
      .subscribe((data) => {
        if (data) {
          // Redirect to that value
          this.document.location.href = data;
        }
      });
  }

  // View file history
  previewPhoto(image) {
    // Get presigned url for object viewing ( 1 hr expiration )
    this.fileService
      .getObjectSignedUrl(image["upload_name"],environment.client_bucket)
      .subscribe((object) => {
        let objChecklist = {
          width: "1200px",
          height: "auto",
          data: {
            image_url: object["url"],
          },
        };
        this.dialog.open(ImageViewComponent, objChecklist);
      });
  }


  /**
   * Remove photo in photo field
   *
   * @param imageIndex
   */
  removePhoto(imageIndex) {
    // Pop-up modal for confirmation
    let photoToBeRemoved = clone(this.arPhotoList);

    this.notifService.sendConfirmation('confirm_delete')
      .filter(confirmation => confirmation.answer === true)
      .subscribe(() => {
        photoToBeRemoved.splice(imageIndex, 1);
        this.updateAssetRecord({photos: photoToBeRemoved},{
          message_header_success: "header_notification.success_delete",
          message_header_success_message: "asset_photos_successfully_deleted",
          message_header_success_theme: "success",
          message_header_fail: "header_notification.generic_fail",
          message_header_fail_message: "content_notification.fail_delete_resource",
          message_header_fail_theme: "danger"
        });

      });

  }

  ngOnChanges(): void {
    this.arPhotoList == this.objFetchedRecordDetails.photos || [];
  }

  /**
   * get current url asset id
   *
   * @return {string}
   */
  getAssetId(): string {
    let arUrl = this.router.url.split('/')
    arUrl.splice(0, 2);

    return arUrl[0];
  }

  /**
   * retrieve job record using the curring url job id
   *
   * @return {void}
   */
  getAssetRecord(): void {
    this.bLoading = true;
    this.recordService.getRecord('assets', this.getAssetId()).subscribe( response => {
      this.bLoading = false;
      this.objAssetRecord = response['record_details'];
    });
  }
}
