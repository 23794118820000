<div class="col-6 prompt-rows pt-9px pb-9px" [ngClass]="{
	'prompt-rows-passed' : objPrompt['value']['is_passed'] == true,
	'prompt-rows-failed' : objPrompt['value']['is_passed'] == false,
	'prompt-rows' : objPrompt['value']['is_passed'] == 'default' || objPrompt['value']['is_passed'] == undefined
}">
	<div class="d-flex align-items-center gap-10">
		<div *ngIf="bIsUnderGroup" class="bg-indent"></div>

		<div>
			<span class="prompt-questions">
				{{ (bIsUnderGroup ? '' : numIndex + 1 + '.') }} {{ objPrompt['prompt'] | translate }}
			</span>

			<span *ngIf="objPrompt['is_required']" class="is-required">
					<fa-icon [icon]="['fas', 'asterisk']" aria-hidden="true" matTooltip="{{ 'required_to_complete' | translate }}"></fa-icon>
			</span>
		</div>
	</div>
</div>

<div class="col-6 prompt-rows pt-9px pb-9px" [ngClass]="{
	'prompt-rows-passed' : objPrompt['value']['is_passed'] == true,
	'prompt-rows-failed' : objPrompt['value']['is_passed'] == false,
	'prompt-rows' : objPrompt['value']['is_passed'] == 'default'  || objPrompt['value']['is_passed'] == undefined
}">
	<!-- FOR IMAGE TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'image'">
		<div>
			<div class="row ml-0 mr-0">
				<div class="file-drop-container">
					<input id="inputImageFile{{ numIndex + 1 }}" class="file-input" type="file" accept="image/*" (change)="onFileChange($event)" multiple="multiple"/>

					<div *ngIf="!bIsFileDropped" class="file-input-overlay">
						{{ 'drop_image_here' | translate }}
					</div>

					<div *ngIf="bIsFileDropped" class="file-input-overlay-uploading">
						<fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon> {{ 'uploading' | translate }}
					</div>
				</div>
			</div>

			<div *ngIf="objPrompt['value'] != undefined && objPrompt['value']['image'] && objPrompt['value']['image'].length > 0">
				<div class="row image-row">
					<div *ngFor="let item of objPrompt['value']['image'], index as indexImagePrompt">
						<div class="col-md-6">
							<fa-icon
								[icon]="['fas', 'trash-alt']"
								class="bullet"
								(click)="removeAttachement(objPrompt['value']['image'], indexImagePrompt)">
							</fa-icon>
							<img class="rounded thumbnails" (click)="viewImage(objPrompt['value']['image'][indexImagePrompt])" src="{{ item.image_path }}"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<!-- FOR SIGNATURE TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'signature'">
		<div *ngIf="objPrompt['value']['signature'] == ''" class="empty-value"> -- </div>
		<div class="row image-row" *ngIf="objPrompt['value']['signature'] !== ''">
			<div class="col-md-6">
				<img class="rounded thumbnails" (click)="viewImage(objPrompt['value'])" src="{{ objPrompt['value']['image_path'] }}"/>
			</div>
		</div>
	</ng-container>

	<!-- FOR TEXT TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'text'">
		<textarea
			id="inputTypeText{{ numIndex + 1 }}" class="form-control default-input textFieldType"
			[ngClass]="{'overflow-hidden': !bFocusingOnTextarea}"
			[(ngModel)]="objPrompt['value']['text']"
			maxlength="{{ numDefaultTextMaxLength }}"
			rows="1"
			(focus)="enlargeTextareaHeight($event, 2)"
			(focusout)="decreaseTextareaHeight($event, 1)"
			(ngModelChange)="onChangeTexts(numDefaultTextMaxLength)">
		</textarea>

		<small
			[ngClass]="{'error-text': countFieldValue(objPrompt['value']['text']) === numDefaultTextMaxLength}"
			class="nopadding clrlbl">
				{{ countFieldValue(objPrompt['value']['text']) }}/{{ numDefaultTextMaxLength }}
		</small>
	</ng-container>

	<!-- FOR NUMBER TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'number'">
		<fieldmagic-decimal-input
      id="inputTypeNumber{{ numIndex + 1 }}"
			customClass="form-control default-input font-size-12"
			placeholder="0.000"
			[(ngModel)]="objPrompt['value']['number']"
			(ngModelChange)="formDirtyEmitter.emit(true)"
		></fieldmagic-decimal-input>
	</ng-container>

	<!-- FOR INSTRUCTION TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'instructions'">
		<div matTooltip="{{ objPrompt['value']['instructions_text'] }}" >
			<input
				id="inputTypeInstructionsText{{ numIndex + 1 }}"
				class="form-control prompt-input"
				[(ngModel)]="objPrompt['value']['instructions_text']" disabled/>
		</div>
	</ng-container>

	<!-- FOR DATE TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'date'">
		<div class="input-group">
			<input
				[(ngModel)] = "objPrompt['value']['date']"
				[owlDateTime]="dateTypeInput"
				id="dateTypeInput"
				type="text"
				class="form-control date-input prompt-input"
				(dateTimeChange)="dateChange($event)"
				(ngModelChange)="formDirtyEmitter.emit(true)">

			<button class="calendar-button" [owlDateTimeTrigger]="dateTypeInput">
				<fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
			</button>
		</div>
		<div>
			<owl-date-time  #dateTypeInput [pickerType]="'calendar'"></owl-date-time>
		</div>
	</ng-container>

	<!-- FOR FIELD ENTRY TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'field_entry'">
		<ng-container *ngIf="objPrompt['value']['module'] != 'custom_asset_types'">
			<ng-container *ngIf="objMetadata[objPrompt['value']['module']] && objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]; then fieldExist; else fieldNotExist"></ng-container>
				<ng-template #fieldExist>
					<!-- Field Entries that are not CUSTOM, DATETIME, DATE & DROPDOWN -->
					<div *ngIf="objMetadata[objPrompt['value']['module']] && objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] != 'datetime' &&
											objMetadata[objPrompt['value']['module']] && objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] != 'date'  &&
											objMetadata[objPrompt['value']['module']] && objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] != 'dropdown' &&
											objMetadata[objPrompt['value']['module']] && objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] != 'multiselect' &&
											objMetadata[objPrompt['value']['module']] && objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] != 'textarea' &&
											objMetadata[objPrompt['value']['module']] && objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] != 'checkbox'" >
						<div>
							<textarea
								id="inputFieldEntry{{ numIndex + 1 }}"
								[ngClass]="{'overflow-hidden': !bFocusingOnTextarea}"
								class="form-control default-input fieldEntryCustomTextField"
								placeholder="{{ objPrompt['value']['default_value'] }}"
								[(ngModel)]="objPrompt['value']['field_entry_value']"
								maxlength="{{getFieldMaxLength(objMetadata[objPrompt['value']['module']][objPrompt['value']['field']])}}"
								[disabled]="objPrompt['value']['read_only'] == true"
								(ngModelChange)="onChangeTexts(getFieldMaxLength(objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]))"
								rows="1"
								(focus)="enlargeTextareaHeight($event, 2)"
								(focusout)="decreaseTextareaHeight($event, 1)">
							</textarea>
							<small class="nopadding clrlbl fieldEntryCustomTextLabel" [ngClass]="{'error-text': countFieldValue(objPrompt['value']['field_entry_value']) === getFieldMaxLength(objMetadata[objPrompt['value']['module']][objPrompt['value']['field']])}">
								{{ countFieldValue(objPrompt['value']['field_entry_value']) }}/{{ getFieldMaxLength(objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]) }}
							</small>
						</div>
					</div>

					<!-- FIELD ENTRY TEXTAREA TYPE -->
					<div *ngIf="objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] === 'textarea'" >
						<div>
							<textarea
								id="textareaFieldEntry{{ numIndex + 1 }}"
								[ngClass]="{'overflow-hidden': !bFocusingOnTextarea}"
								[disabled]="objPrompt['value']['read_only'] == true"
								class="form-control default-input fieldEntryCustomTextAreaField"
								placeholder="{{ objPrompt['value']['default_value'] }}"
								[(ngModel)]="objPrompt['value']['field_entry_value']"
								maxlength="{{getFieldMaxLength(objMetadata[objPrompt['value']['module']][objPrompt['value']['field']])}}"
								(ngModelChange)="onChangeTexts(getFieldMaxLength(objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]))"
								rows="1"
								(focus)="enlargeTextareaHeight($event, 4)"
								(focusout)="decreaseTextareaHeight($event, 1)">
							</textarea>
							<small class="nopadding clrlbl fieldEntryCustomTextAreaLabel" [ngClass]="{'error-text': countFieldValue(objPrompt['value']['field_entry_value']) === getFieldMaxLength(objMetadata[objPrompt['value']['module']][objPrompt['value']['field']])}">
								{{ countFieldValue(objPrompt['value']['field_entry_value']) }}/{{ getFieldMaxLength(objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]) }}
							</small>
						</div>
					</div>

					<!-- FIELD ENTRY MULTISELECT TYPE -->
					<div *ngIf="objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] == 'multiselect'" >
						<div *ngFor="let option of objPrompt['value']['options'];">
							<span>
								<input
									type="checkbox"
									id="option['id']"
									name = "option['id']"
									[checked] = "option['checked']"
									(click)="changeSelection(option['id'], option['checked'], objPrompt['value']['options'])">
								{{ option.text | translate }}
							</span>
						</div>
					</div>

					<!-- FIELD ENTRY DATE and TIME TYPE -->
					<div *ngIf="objMetadata[objPrompt['value']['module']] && objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] == 'datetime'" >
						<div class="input-group">
							<input
								[(ngModel)] = "objPrompt['value']['field_entry_value']"
								[owlDateTime]="fieldEntryDateTime"
								id="fieldEntryDateTime"
								type="text"
								class="form-control date-input prompt-input"
								(dateTimeChange)="dateChange($event)"
								placeholder="{{ objPrompt['value']['default_value'] }}"
								[disabled]="objPrompt['value']['read_only'] == true">

							<button class="calendar-button" [owlDateTimeTrigger]="fieldEntryDateTime" [disabled]="objPrompt['value']['read_only'] == true">
								<fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
							</button>
						</div>
						<div>
							<owl-date-time  #fieldEntryDateTime ></owl-date-time>
						</div>
					</div>

					<!-- FIELD ENTRY DATE ONLY TYPE -->
					<div *ngIf="objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] == 'date'" >
						<div class="input-group">
							<input
								[(ngModel)] = "objPrompt['value']['field_entry_value']"
								[owlDateTime]="fieldEntryDate"
								id="fieldEntryDate"
								type="text"
								class="form-control date-input prompt-input"
								(dateTimeChange)="dateChange($event)"
								placeholder="{{ objPrompt['value']['default_value'] }}"
								[disabled]="objPrompt['value']['read_only'] == true">

							<button class="calendar-button" [owlDateTimeTrigger]="fieldEntryDate" [disabled]="objPrompt['value']['read_only'] == true">
								<fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
							</button>
						</div>
						<div>
							<owl-date-time [pickerType]="'calendar'" #fieldEntryDate></owl-date-time>
						</div>
					</div>

					<!-- FIELD ENTRY DROPDOWN TYPE -->
					<div *ngIf="objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] == 'dropdown'">
						<ng-container *ngIf="objEnumFields[objPrompt['value']['module']] !== undefined && objEnumFields[objPrompt['value']['module']][objPrompt['value']['field']] !== undefined" >
							<ng-select
								id="ngSelectFieldEntryDefinedValueModule{{ numIndex + 1 }}"
								[items]="objEnumFields[objPrompt['value']['module']][objPrompt['value']['field']]"
								[(ngModel)]="objPrompt['value']['field_entry_value']"
								[bindValue]
								bindLabel="text"
								placeholder="{{ objPrompt['value']['default_value'] | translate }}"
								[disabled]="objPrompt['value']['read_only'] == true"
								(ngModelChange)="markAsDirty()">

								<!-- DISPLAY IN SELECTED -->
								<ng-template ng-label-tmp let-item="item">
									<span id="selectedFieldEntryDefinedValueModule" class="ng-value-label">{{ item | translate }}</span>
								</ng-template>
								<!-- DISPLAY IN OPTION -->
								<ng-template  ng-option-tmp let-item="item" let-index="index">
									<span id="fieldEntryDefinedValueModuleOptions{{index}}" class="value-text"> {{ item | translate }} </span>
								</ng-template>
							</ng-select>
						</ng-container>

						<ng-container *ngIf="objEnumFields[objPrompt['value']['module']] == undefined || objEnumFields[objPrompt['value']['module']][objPrompt['value']['field']] == undefined" >
							<ng-select
								id="ngSelectFieldEntryUndefinedValueModule{{ numIndex + 1 }}"
								[items]="objPrompt['value']['dropdown']"
								[(ngModel)]="objPrompt['value']['field_entry_value']"
								[bindValue]
								placeholder="{{ objPrompt['value']['field_entry_value'] != undefined ? objPrompt['value']['field_entry_value'] : objPrompt['value']['default_value'] }}"
								bindValue="id"
								bindLabel="text"
								(ngModelChange)="formDirtyEmitter.emit(true)">

								<!-- DISPLAY IN SELECTED -->
								<ng-template ng-label-tmp let-item="item">
									<span id="selectedFieldEntryUndefinedValueModule" class="ng-value-label">{{ item.text | translate }}</span>
								</ng-template>
								<!-- DISPLAY IN OPTION -->
								<ng-template  ng-option-tmp let-item="item" let-index="index">
									<span id="fieldEntryUndefinedValueModuleOptions{{index}}" class="value-text"> {{ item.text | translate }} </span>
								</ng-template>
							</ng-select>
						</ng-container>
					</div>

					<!-- FIELD ENTRY BOOLEAN TYPE -->
					<div *ngIf="objMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['type'] == 'checkbox'"  >
						<mat-slide-toggle color="primary" [(ngModel)]="objPrompt['value']['field_entry_value']"></mat-slide-toggle>
					</div>
				</ng-template>

				<ng-template #fieldNotExist>
					<div>
						<input
							id="inputFieldEntryNotExist{{ indexPrompts + 1 }}"
							class="form-control default-input"
							placeholder="{{ objPrompt['value']['default_value'] }}"
							[(ngModel)]="objPrompt['value']['field_entry_value']"
							(ngModelChange)="onChangeTexts()">
					</div>
				</ng-template>
		</ng-container>

		<!-- FIELD ENTRY CUSTOM ASSETS -->
		<ng-container *ngIf="objPrompt['value']['module'] == 'custom_asset_types'">
			<!-- CUSTOM ASSETS TYPE TEXT -->
			<div *ngIf="objPrompt['value']['key'] != undefined &&
									objPrompt['value']['type'] == 'text'" >
				<textarea
					id="inputCustomAssetsTypeText{{ numIndex + 1 }}"
					[ngClass]="{'overflow-hidden': !bFocusingOnTextarea}"
					class="form-control default-input textFieldCustomAssetType"
					placeholder="{{ objPrompt['value']['default_value'] }}"
					[(ngModel)]="objPrompt['value']['default_value']"
					(ngModelChange)="formDirtyEmitter.emit(true)"
					maxlength="{{numDefaultTextMaxLength}}"
					rows="1"
					(focus)="enlargeTextareaHeight($event, 2)"
					(focusout)="decreaseTextareaHeight($event, 1)"
					(ngModelChange)="onChangeTexts()">
				</textarea>
				<small [ngClass]="{'error-text': countFieldValue(objPrompt['value']['default_value']) === numDefaultTextMaxLength}" class="nopadding clrlbl">
					{{ countFieldValue(objPrompt['value']['default_value']) }}/{{ numDefaultTextMaxLength }}
				</small>
			</div>

			<!-- CUSTOM ASSETS TYPE NUMBER -->
			<div *ngIf="objPrompt['value']['key'] != undefined &&
									objPrompt['value']['type'] == 'number'" >
				<fieldmagic-decimal-input
					id="inputCustomAssetsTypeNumber{{ numIndex + 1 }}"
					customClass="form-control default-input font-size-12"
					placeholder="{{ objPrompt['value']['default_value'] }}"
					[(ngModel)]="objPrompt['value']['field_entry_value']"
					(ngModelChange)="formDirtyEmitter.emit(true)"
				>
				</fieldmagic-decimal-input>
			</div>

			<!-- CUSTOM ASSETS TYPE DROPDOWN -->
			<div *ngIf="objPrompt['value']['key'] != undefined &&
									objPrompt['value']['type'] == 'dropdown'" >
				<ng-select
					id="ngSelectCustomAssetsTypeDropdown{{ numIndex + 1 }}"
					placeholder="{{ objPrompt['value']['default_value'] }}"
					[items]="objPrompt['value']['option']"
					[(ngModel)]="objPrompt['value']['default_value']"
					bindValue="id"
					bindLabel="text"
					(ngModelChange)="formDirtyEmitter.emit(true)">

					<!-- DISPLAY IN SELECTED -->
					<ng-template ng-label-tmp let-item="item">
						<span id="selectedCustomAssetsDropdownOption" class="ng-value-label">{{ item.text | translate }}</span>
					</ng-template>
					<!-- DISPLAY IN OPTION -->
					<ng-template  ng-option-tmp let-item="item" let-index="index">
						<span id="customAssetsDropdownOption{{index}}" class="value-text"> {{ item.text | translate }} </span>
					</ng-template>

				</ng-select>
			</div>

				<!-- CUSTOM ASSETS TYPE DATE -->
				<div *ngIf="objPrompt['value']['key'] != undefined &&
										objPrompt['value']['type'] == 'date'" >

					<div class="input-group">
						<input
							[(ngModel)] = "objPrompt['value']['default_value']"
							[owlDateTime]="CustomFieldEntryDate"
							id="CustomFieldEntryDate"
							type="text"
							class="form-control date-input prompt-input"
							(dateTimeChange)="dateChange($event)"
							placeholder="{{ objPrompt['value']['default_value'] | date:'MMMM d, y' }}"
							(ngModelChange)="formDirtyEmitter.emit(true)">

						<button class="calendar-button" [owlDateTimeTrigger]="CustomFieldEntryDate" >
								<fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
						</button>
					</div>
					<div>
						<owl-date-time #CustomFieldEntryDate [pickerType]="'calendar'"></owl-date-time>
					</div>
				</div>

		</ng-container>
	</ng-container>

	<!-- FOR PASS OR FAIL TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'pass/fail'" >
		<ng-select
				id="ngSelectPassOrFail{{ numIndex + 1 }}"
				[items]="objPrompt['value']['pass_and_fail']"
				[(ngModel)]="objPrompt['value']['pass_fail_value']"
				(change)="onPassFailChange($event, objPrompt['value']['pass'])">
		</ng-select>
	</ng-container>

	<!-- FOR DROPDOWN TYPE -->
	<ng-container *ngIf="objPrompt['type'] === 'dropdown'">
		<ng-select
			id="ngSelectTypeDropdown{{ indexPrompts + 1 }}"
			[items]="objPrompt['value']['dropdown']"
			[(ngModel)]="objPrompt['value']['dropdown_value']"
			(ngModelChange)="formDirtyEmitter.emit(true)"
			[bindValue]>
		</ng-select>
	</ng-container>
</div>
