import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Form } from '../../../../base/form';

@Component({
  selector: 'fc-item-list-pagination',
  template: `
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end pb-3">
      <ul class="pagination">
        <li class="page-item" [ngClass]="(hasPreviousPage) ? 'text-primary pointer' : 'disabled'">
          <a class="page-link" (click)="onChangePage('default')">
            <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="(hasPreviousPage) ? 'text-primary pointer' : 'disabled'">
          <a class="page-link" (click)="onChangePage('prev')">
            <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="(hasNextPage) ? 'text-primary pointer' : 'disabled'">
          <a class="page-link" (click)="onChangePage('next')">
            <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
  `,
  styleUrls: [],
})

export class ItemListPaginationComponent implements OnInit {

  @Input() objPagination = new EventEmitter<PaginationConfig>();

  @Output() objNavigate = new EventEmitter<string>();

  get hasPreviousPage(): boolean {
    return this.objPagination['previous_page'];
  }

  get hasNextPage(): boolean {
    return this.objPagination['next_page'] && this.objPagination['last_page'] > this.objPagination['current_page'];
  }

  constructor() {

  }

  ngOnInit(): void {
  }

  onChangePage(page: string) {
    this.objNavigate.next(page)
  }

  /**
   * @inheritdoc
   */
  setField(field: Form<any>): void {
  }
}

export class PaginationConfig {

  next_page: boolean = false;
  previous_page: boolean = false;
  from_records: number = 0;
  to_records: number = 0;
  total_records: number = 0;
  last_page: number = 0;
  current_page: number = 0;

  constructor(properties?: PaginationConfig) {
    if (properties) {
      this.next_page = properties.next_page;
      this.previous_page = properties.previous_page;
      this.from_records = properties.from_records;
      this.to_records = properties.to_records;
      this.total_records = properties.total_records;
      this.last_page = properties.last_page;
      this.current_page = properties.current_page;
    }
  }
}
