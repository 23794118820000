export interface ChatInterface {
  /**
   * In the database, this is the activity_message
   * id which is a unique UUID.
   *
   * @var {string}
   */
  id?: string;
  /**
   * Name of the person who sent the chat.
   *
   * @var {string}
   */
  name?: string;
  /**
   * The type of chat, could be text, image, or file.
   *
   * @var {string}
   */
  type: string;
  /**
   * The main content of the chat.
   *
   * @var {string}
   */
  message: string;
  /**
   * The UUID of the user who created this chat/activity message.
   *
   * @var {string}
   */
  created_by?: string;
  /**
   * The date this chat was created.
   *
   * @var {string}
   */
  created_at?: string;
  /**
   * Name of the file if this chat does
   * contain a file.
   *
   * @var {string}
   */
  file_name?: string;
}
export class Chat implements ChatInterface{
  name?: string;
  file_name?: string;
  type: string;
  message: string;
  id?: string;
  created_by?: string;
  created_at?: string;
  constructor(properties: ChatInterface) {
    this.name = properties.name || null;
    this.file_name = properties.file_name || null;
    this.type = properties.type;
    this.message = properties.message;
    this.id = properties.id || null;
    this.created_by = properties.created_by;
    this.created_at = properties.created_at;
  }
  /**
   * Sets the name of the person who made this chat.
   *
   * @param {string} strFirstName
   * @param {string} strLastName
   *
   * @returns {void}
   */
  setName(strFirstName: string, strLastName: string): void {
    if (strFirstName && strLastName) {
      this.name = strFirstName + ' ' + strLastName;
    }
  }
  /**
   * Just adds the full name.
   *
   * @param {string} strFullName
   *
   * @returns {void}
   */
  setFullName(strFullName?: string): void {
    this.name = strFullName || null;
  }
}
