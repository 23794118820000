
<header>
  <div class="d-flex">
    <h3 class="title"><fa-icon [icon]="['fas', 'file-alt']" class="mr-3"></fa-icon>{{ 'manage_swms' | translate }}</h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" class="btn btn-primary mr-1" [disabled]="isProcessing || isInitializingForm" (click)="onCancel()" data-testid="cancel-btn">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <form-templates-save-and-preview-btn
            [disabled]="isProcessing || isInitializingForm"
            (click)="onSaveAndPreview()"
            [trigger-event]="preview.asObservable()">
          </form-templates-save-and-preview-btn>
        </li>
        <li class="list-inline-item">
          <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="isProcessing || isInitializingForm">
            <button-icon-loader [defaultIcon]="['fas', 'save']" [isLoading]="isProcessing"></button-icon-loader>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>

<hr class="seperator"/>

<mat-dialog-content class="mobile-view-dialog">
  <div class="row">
    <inline-view-loader class="col-12 text-center" [isLoading]="isInitializingForm" [hasText]="false"></inline-view-loader>

    <form *ngIf="! isInitializingForm" [formGroup]="form" class="col-12">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_work_activity" class="col-form-label">{{ 'swms_work_activity' | translate }}</label>
            <textarea id="swms_work_activity" class="form-control" data-testid="smws-work-activity-input" disabled>{{ objJob.job_summary }}</textarea>
          </div>
        </div>

        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_work_location" class="col-form-label">{{ 'swms_work_location' | translate }}</label>
            <textarea id="swms_work_location" class="form-control" data-testid="smws-work-location-input" disabled>{{ objJob.site_text }}</textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_responsible_person" class="col-form-label">{{ 'swms_responsible_person' | translate }}<required-tag [hasSpace]="false"></required-tag></label>
            <ng-select [items]="options.users" data-testid="smws-responsible-person-input" id="swms_responsible_person" formControlName="compliance_officer" bindLabel="text"></ng-select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_relevant_legislation" class="col-form-label">{{ 'swms_relevant_legislation' | translate }}<required-tag [hasSpace]="false"></required-tag></label>
            <textarea type="text" formControlName="relevant_legislation" class="form-control"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_staff_compentency" class="col-form-label">{{ 'swms_staff_compentency' | translate }}</label>
            <input type="text" formControlName="staff_competencies" class="form-control" maxlength="255">
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_management_competency" class="col-form-label">{{ 'swms_management_competency' | translate }}<required-tag [hasSpace]="false"></required-tag></label>
            <input type="text" formControlName="management_competency" class="form-control" maxlength="255">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_hazardous_substance" class="col-form-label">{{ 'swms_hazardous_substance' | translate }}</label>
            <textarea type="text" formControlName="hazardous_substances" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_plant_and_equipment" class="col-form-label">{{ 'swms_plant_and_equipment' | translate }}</label>
            <textarea type="text" formControlName="plant_and_equipment" class="form-control"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_specific_ppe_required" class="col-form-label">{{ 'swms_specific_ppe_required' | translate }}</label>
            <textarea type="text" formControlName="specific_ppe_required" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="swms_emergency_planning" class="col-form-label">{{ 'swms_emergency_planning' | translate }}</label>
            <textarea type="text" formControlName="emergency_planning" class="form-control"></textarea>
          </div>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col-12 text-left mb-1">
          <span class="col-form-label">{{ 'smws_risks_and_controls' | translate }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="col-form-label align-top">
                  {{ 'swms_sub_task' | translate }}
                  <fa-icon [icon]="['fas', 'info-circle']" size="xs" matTooltip="{{ 'swms_sub_task_help' | translate}}"></fa-icon>
                </th>
                <th class="col-form-label align-top">
                  {{ 'swms_risk' | translate }}
                  <fa-icon [icon]="['fas', 'info-circle']" size="xs" matTooltip="{{ 'swms_risk_help' | translate}}"></fa-icon>
                  <required-tag [hasSpace]="false"></required-tag>
                </th>
                <th class="col-form-label align-top" colspan="2">
                  {{ 'risk_rating' | translate }}
                  <fa-icon [icon]="['fas', 'info-circle']" size="xs" matTooltip="{{ 'swms_risk_rating_help' | translate}}"></fa-icon>
                  <required-tag [hasSpace]="false"></required-tag>
                  <p class="text-help text-muted mb-0">{{ 'risk_ratings_sub' | translate }}</p>
                </th>
                <th class="col-form-label align-top">
                  {{ 'swms_control_measure' | translate }}
                  <fa-icon [icon]="['fas', 'info-circle']" size="xs" matTooltip="{{ 'swms_control_measure_help' | translate}}"></fa-icon>
                  <required-tag [hasSpace]="false"></required-tag>
                </th>
                <th class="col-form-label align-top" colspan="2">
                  {{ 'residual_risk_rating' | translate }}
                  <fa-icon [icon]="['fas', 'info-circle']" size="xs" matTooltip="{{ 'residual_risk_rating_help' | translate}}"></fa-icon>
                  <required-tag [hasSpace]="false"></required-tag>
                  <p class="text-help text-muted mb-0">{{ 'risk_ratings_sub' | translate }}</p>
                </th>
                <th class="col-form-label align-top">
                  {{ 'task_review_frequency' | translate }}
                  <fa-icon [icon]="['fas', 'info-circle']" size="xs" matTooltip="{{ 'swms_task_review_frequency_help' | translate}}"></fa-icon>
                  <required-tag [hasSpace]="false"></required-tag>
                </th>
                <th class="col-form-label align-top">
                  {{ 'responsible_user' | translate }}
                  <fa-icon [icon]="['fas', 'info-circle']" size="xs" matTooltip="{{ 'responsible_user_help' | translate}}"></fa-icon>
                  <required-tag [hasSpace]="false"></required-tag>
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <ng-container *ngFor="let task of tasks.controls; let parent = index;" formArrayName="tasks">
                <ng-container [formGroupName]="parent">
                  <tr data-testid="tasks" class="table-active" (click)="toggleSubTasks(task)">
                    <td colspan="9">{{ getTaskName(task) }}</td>
                    <td class="text-center">
                      <button type="button" class="btn btn-link">{{ 'show_hide' | translate }}</button>
                    </td>
                  </tr>
                  <tr
                    *ngFor="let sub_task of getSubTasks(task).controls; let children = index;"
                    formArrayName="sub_tasks"
                    data-testid="sub-tasks"
                    [attr.data-task]="parent"
                    [class.risks-and-controls-hidden]="isSubTasksHidden(task)"
                  >
                    <ng-container [formGroupName]="children">
                      <td>
                        <div class="d-flex">
                          <fa-icon
                            [icon]="['fas', 'circle']"
                            class="risk-score-status"
                            [class.risk-catastrophic]="isRiskCatastrophic(sub_task)"
                            [class.risk-high]="isRiskHigh(sub_task)"
                            [class.risk-moderate]="isRiskModerate(sub_task)"
                            [class.risk-low]="isRiskLow(sub_task)"
                            matTooltip="{{ 'risk_score'| translate }}: {{ 'risk_score_' + getRiskScoreTranslation(sub_task) | translate }}">
                          </fa-icon>
                          <input
                            type="text"
                            class="form-control col-10"
                            formControlName="name"
                            id="sub_task"
                            [ngbTypeahead]="search(task)"
                            (selectItem)="onSearchSelect($event, sub_task)"
                            [resultTemplate]="searchTemplate"
                            maxlength="255">
                        </div>
                      </td>
                      <td>
                        <textarea formControlName="risk" class="form-control expandable-textarea" id="risk"></textarea>
                      </td>
                      <!-- Risk Rating -->
                      <ng-container formArrayName="risk_rating">
                        <td *ngFor="let _ of sub_task.get('risk_rating').controls; let _index = index" class="ratings-column">
                          <ng-select [items]="options.ratings[_index]" [clearable]="false" bindValue="id" bindLabel="text" [formControlName]="_index">
                            <ng-template ng-option-tmp let-item="item">
                              {{ item.text | translate }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              {{ item.text | translate }}
                            </ng-template>
                          </ng-select>
                        </td>
                      </ng-container>
                      <!-- end -->
                      <td>
                        <textarea formControlName="control_measure" class="form-control expandable-textarea"></textarea>
                      </td>
                      <!-- Residual Risk Rating -->
                      <ng-container formArrayName="residual_risk_rating">
                        <td *ngFor="let _ of sub_task.get('residual_risk_rating').controls; let _index = index" class="ratings-column">
                          <ng-select [items]="options.ratings[_index]" [clearable]="false" bindValue="id" bindLabel="text" [formControlName]="_index">
                            <ng-template ng-option-tmp let-item="item">
                              {{ item.text | translate }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              {{ item.text | translate }}
                            </ng-template>
                          </ng-select>
                        </td>
                      </ng-container>
                      <!-- end -->
                      <td>
                        <ng-select [items]="options.task_review_frequencies" [clearable]="false" bindValue="id" bindLabel="text" formControlName="task_review_frequency">
                          <ng-template ng-option-tmp let-item="item">
                            {{ item.text | translate }}
                          </ng-template>
                          <ng-template ng-label-tmp let-item="item">
                            {{ item.text | translate }}
                          </ng-template>
                        </ng-select>
                      </td>
                      <td style="width: 10%;">
                        <ng-select formControlName="responsible_person" id="user_id" [items]="options.users" bindLabel="text"></ng-select>
                      </td>
                      <td class="text-center">
                        <button type="button" class="btn btn-link" (click)="removeSubTask(task, children)" [disabled]="hasSingleSubTask(task)" matTooltip="{{ 'delete' | translate }}" data-testid="delete-sub-tasks-btn" [attr.data-task]="parent">
                          <fa-icon [icon]="['fas', 'trash-alt']" class="text-warning" [class.text-muted]="hasSingleSubTask(task)"></fa-icon>
                        </button>
                        <button class="btn btn-link" type="button" (click)="addSubTask(task)" matTooltip="{{ 'add_risk_and_control' | translate }}" data-testid="add-sub-tasks-btn" [attr.data-task]="parent">
                          <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
                        </button>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </form>

  </div>

</mat-dialog-content>

<ng-template #searchTemplate let-r="result" let-t="term">
  <ngb-highlight result="{{ r.name | truncate:70 }}" [term]="t" [matTooltip]="r.name"></ngb-highlight>
</ng-template>
