<button
    *ngIf="isUserProfitabilityEnabled"
    id="txtActualProfit"
    class="btn mr-2 btn-success"
    (click)="showProfitabilityAnalysis()"
    [ngClass]="{
      'btn-success': objProfitMargin.margin > 0,
      'btn-danger': objProfitMargin.margin <= 0
    }"
    matTooltip="{{'profitability_analysis' | translate}}" >
     {{  ( objProfitMargin.profit | currency: {decimal_places: 2} ) }}
     ({{ objProfitMargin.margin }}%)
  </button>