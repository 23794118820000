import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Client } from '../../objects/client';
import { LocalStorageService } from '../local-storage.service';

/**
 * The clients list stored in local storage has this format:
 * <client id>: <client object>
 * We can use this type when getting the list of client invitations.
 *
 * @type {{ [key: string]: Client }}
 */
export type ClientInvitationsObject = { [key: string]: Client };

@Injectable({
  providedIn: 'root'
})
export class ClientInvitationsStoreService {

  /**
   * Client invites received by the current user. When approved,
   * this becomes a client.
   *
   * @type {BehaviorSubject<Client[]>}
   */
  private clientInvitations: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);

  /**
   * Public observable for accessing the clientInvitations BehaviorSubject.
   *
   * @type {Observable<Client[]>}
   */
  public readonly clientInvitations$: Observable<Client[]> = this.clientInvitations.asObservable();

  /**
   * Returns an observable that emits a value when a user has atleast
   * one (1) client invite.
   *
   * @type {Observable<boolean>}
   */
  public readonly whenUserHasClientInvite$: Observable<boolean> = this.clientInvitations$.pipe(
    map(invitations => invitations.length > 0 === true)
  );

  constructor(protected localStorageService: LocalStorageService) { }

  /**
   * Returns a list of clients that has invited this user to be
   * a part of their team.
   *
   * @returns {Client[]}
   */
  getClientInvitations(): Client[] {
    return this.clientInvitations.getValue();
  }

  /**
   * Set the list of client invites that this user has received.
   *
   * @param {Client[]} clients
   *
   * @returns {void}
   */
  setClientInvitations(clients: Client[]): void {
    this.localStorageService.replaceJsonItem('invitation_list', clients);
    this.clientInvitations.next(clients);
  }

  /**
   * Removes the clients invitations from local storage.
   *
   * @returns {void}
   */
  removeClientInvitations(): void {
    this.localStorageService.removeItem('invitation_list');
    this.clientInvitations.next([]);
  }
}
