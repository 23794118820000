import { Component, Input, OnInit } from '@angular/core';
import { Select } from '../../../../objects/select';
import { FormControl } from '@angular/forms';
import { LooseObject } from '../../../../objects/loose-object';

@Component({
  selector: 'fc-user-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {

  /**
   * The form passed from parent.
   *
   * @var {FormControl}
   */
  @Input('form') form: FormControl;

  /**
   * The field, usually the value of the form control
   * but with modification passed from parent.
   *
   * @var {object}
   */
  @Input('field') field: {
    key: string,
    value: {
      start_time: string,
      end_time: string
    }
  };

  /**
   * The current time selections available.
   *
   * @var {object}
   */
  timeSelection: {
    to: Select[],
    from: Select[]
  } = {
    to: [],
    from: []
  };

  constructor() { }

  ngOnInit() {
    for (let index = 0; index < 24; index++) {
      ['00', '30'].forEach(item => {
        this.timeSelection.to.push(
          this.createTime(index, item)
        );
        this.timeSelection.from.push(
          this.createTime(index, item)
        );
      });
    }
  }

  /**
   * Creates a list of available time.
   *
   * @param {number} numTime
   * @param {string} numMins
   *
   * @returns {Select}
   */
  createTime(numTime: number, numMins: string): Select {
    return new Select(
      numTime.toString().padStart(2, '0') + `:${(numMins)}:00`,
      ((numTime > 11) ? ((numTime - 12) || 12).toString().padStart(2, '0') : (numTime || 12).toString().padStart(2, '0')) + `:${(numMins)} ${(numTime > 11) ? 'PM' : 'AM'}`,
      null, (numMins == '00') ? numTime : numTime + .5
    );
  }

  /**
   * Copy all the time from Monday to the rest of the
   * days of the week.
   *
   * @returns {void}
   */
  copyAll(): void {
    let objNewFields = {};
    Object.keys(this.form.value).forEach(test => {
      objNewFields[test] = {...this.form.value[this.field.key]};
    });
    this.form.setValue(objNewFields);
  }

  /**
   * Disabled the dropdown values
   * to make sure we dont have end times
   * that are lesser than the start times.
   *
   * @param {LooseObject} objEvent
   *
   * @returns {void}
   */
  disableFields(objEvent: LooseObject): void {
    if (objEvent) {
      this.timeSelection['to'] = this.timeSelection['to'].map(response => {
        if (objEvent['extras'] >= response['extras']) {
          response['disabled'] = true;
        } else {
          response['disabled'] = false;
        }
        return response;
      });
    }
  }

}
