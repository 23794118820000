import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  /**
   * A pipe wrapper for MomentJS's Format.
   *
   * @see {@link https://momentjs.com/docs/#/displaying/format/}
   *
   * @param input
   * @param format
   *
   * @returns {String|null}
   */
  transform(input: moment.MomentInput, format?: string): string|null {
    let _input = moment(input);

    return _input.isValid() ? _input.format(...[format !== undefined && format]) : null;
  }

}
