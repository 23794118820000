import { Config } from 'protractor';
import { Injectable } from '@angular/core';
import { of, Observable, from, throwError } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';
import { catchError } from 'rxjs/operators/catchError';
import FormTemplate from '../entities/form-template.entity';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import FormTemplates from '../collections/form-templates.collection';
import { environment } from '../../../../../environments/environment';
import FormTemplateHistory from '../entities/form-template-history.entity';
import { LooseModuleData } from '../contracts/loose-module-data';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { Client } from '../../../../objects/client';
import { defaultIfEmpty } from 'rxjs/operators/defaultIfEmpty';
import * as FileManager from 'file-saver';
import { switchMap, tap } from 'rxjs/operators';
import { LooseCustomField } from '../contracts/loose-custom-field';
import * as _ from 'lodash';
import { LooseObject } from '../../../../objects/loose-object';
import { FileService } from '../../../../services/file/file.service';
import { SubscriptionId } from '../../../../objects/subscription-plans';
import { ClientStoreService } from '../../../../services/client-store.service';
import * as moment from 'moment';
import FormTemplatesList from '../collections/form-templates-list.collection';

@Injectable({
  providedIn: 'root'
})
export class FormTemplateService {
  /**
   * @param {HttpClient} http
   * @param {LocalStorageService} storage
   * @param {FormTemplateDataGeneratorService} generator
   * @param {FileService} files
   */
  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
    private files: FileService,
    private clients: ClientStoreService
  ) { }

  /**
   * creates a new form template record
   *
   * @param  {CreateFormTemplateData} data
   *
   * @return {Observable<Form | undefined>}
   */
  createTemplate$(data: CreateFormTemplateData): Observable<FormTemplate | undefined> {
    let payload = new URLSearchParams;

    payload.append('name', data.name);
    payload.append('module', data.module);
    payload.append('isActive', this.transformBooleanValueToStringRepresentation(data.isActive));

    if (data.documentType) {
      payload.append('documentType', data.documentType);
    }

    return this.files.upload(data.file)
      .pipe(
        tap((result) => payload.append('template', result.filename)),
        switchMap(() => this.callApi('/create_template', payload).pipe(
          filter((response) => response.status === 201),
          map((response) => this.transformFormTemplate(response.body as FormTemplateResponseBody)),
          catchError((err: HttpErrorResponse) => {
            const templateFileUploadError = this.makeTemplateFileUploadError(err);

            if (!_.isEmpty(templateFileUploadError)) return throwError(templateFileUploadError);

            return of(undefined);
          })
        ))
      );
  }

  /**
   * retrieves the list of form templates based on given filter
   *
   * @param  {any}         filterParams
   * @param  {string|null} page
   *
   * @return {Observable<FormTemplates>}
   */
  list$(filterParams: any, page?: string, per_page?: number): Observable<FormTemplatesList> {
    let body = new URLSearchParams;

    // @todo filterParams
    body.append('page', (page) ? page : '1');

    if (per_page) {
      body.append('per_page', per_page.toString());
    }

    let response$ = this.callApi('/', body.toString());

    return response$.pipe(
      filter((response) => response.status === 200),
      map((response) => {
        return this.makeFormTemplatesCollection(response.body);
      }),
      catchError(() => of(this.makeFormTemplatesCollection({ data: [] })))
    );
  }

  /**
   * returns the list of templates that is available or uploaded for the current form
   *
   * @param  {string} formTemplateId
   *
   * @return {Observable<FormTemplateHistory[]>}
   */
  fetchHistories$(formTemplateId: string): Observable<FormTemplateHistory[]> {
    let body = new URLSearchParams;

    body.set('form_template_id', formTemplateId);

    let response$ = this.callApi('/history', body.toString());

    return response$.pipe(
      filter((response) => response.status === 200),
      map((response) => {
        return this.transformHistories(response.body as FormTemplateHistoryResponseBody[]);
      }),
      catchError(() => of([]))
    );
  }

  /**
   * adds a template to the form templates history
   *
   * @param  {string} formTemplateId
   * @param  {File}   templateFile
   *
   * @return {Observable<boolean>}
   */
  addHistory$(formTemplateId: string, templateFile: File): Observable<boolean> {
    let payload = new URLSearchParams;

    payload.append('form_template_id', formTemplateId);

    return this.files.upload(templateFile)
      .pipe(
        tap((result) => payload.append('template', result.filename)),
        switchMap(() => this.callApi('/history/add', payload).pipe(
          map((response) => response.status === 201),
          catchError((err: HttpErrorResponse) => {
            const templateFileUploadError = this.makeTemplateFileUploadError(err);

            if (!_.isEmpty(templateFileUploadError)) return throwError(templateFileUploadError);

            return of(false);
          })
        ))
      )
  }

  /**
   * sets the given history as default
   *
   * @param  {string} historyId
   *
   * @return {Observable<FormTemplateHistory>}
   */
  setHistoryAsDefault$(historyId: string): Observable<FormTemplateHistory[]> {
    let body = new URLSearchParams;

    body.set('history_id', historyId);

    let response$ = this.callApi('/history/set_as_default', body.toString());

    return response$.pipe(
      filter((response) => response.status === 200),
      map((response) => {
        return this.transformHistories(response.body as FormTemplateHistoryResponseBody[]);
      }),
      catchError(() => of([]))
    );
  }

  /**
   * deletes a given template to our records
   *
   * @param   {string} templateId
   *
   * @returns {Observable<boolean>}
   */
  deleteTemplate$(templateId: string): Observable<boolean> {
    let body = new URLSearchParams;

    body.set('form_template_id', templateId);

    let response$ = this.callApi('/delete', body.toString());

    return response$.pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  /**
   * retrieves all module active templates
   *
   * @param  {string}  module
   * @param  {boolean} excludeDefaults
   *
   * @return {Observable<FormTemplate[]>}
   */
  getModuleTemplates$(module: string, excludeDefaults: boolean = false): Observable<FormTemplate[]> {
    let body = new URLSearchParams;

    body.set('module', module);

    if (excludeDefaults) {
      body.set('exclude_defaults', '1');
    }

    let response$ = this.callApi('/module/templates', body.toString());

    return response$.pipe(
      filter((response) => response.status === 200),
      map((response) => {
        return (response.body || []).map((response) => {
          return this.transformFormTemplate(response);
        });
      }),
      catchError(() => of([]))
    );
  }

  /**
   * Generates a pdf URL from our form template parser and emits the generated URL which can be
   * used for previewing or downloading the pdf
   *
   * @param {{
   *  module: string
   *  data: LooseModuleData,
   *  template: FormTemplateHistory,
   *  custom: LooseCustomField
   * }} options
   *
   * @returns {Observable<string|null>}
   */
  generatePDFUrl$(options: {
    module: string,
    data: LooseModuleData,
    template: FormTemplateHistory,
    custom?: LooseCustomField
    template_id?: string,
    additional_document? : string[]
  }): Observable<string | null> {
    let client = this.getAuthenticatedClient();
    let payload = {
      module: options.module,
      template: options.template.path,
      data: options.data,
      clientId: (client !== null) ? client['client_id'] : null,
      custom: options.custom,
      additional_document: options.additional_document,
    };

    if (options.template_id) {
      payload['template_id'] = options.template_id;
    }

    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "X-Lambda": "1",
      "X-Authorization": `Bearer ${this.storage.getItem('access_token')}`,
    });

    let response$ = this.http.post<Config>(environment['forms_generator_base_url'], payload, {
      headers,
      observe: 'response',
    });

    return response$.pipe(
      map((response) => {
        return response.body.link;
      }),
      catchError(() => {
        return of(null);
      }),
      defaultIfEmpty()
    );
  }

  /**
   * download the current generated PDF using its url
   *
   * @param   {string}           url
   * @param   {string|undefined} outputFilename
   *
   * @returns {Observable<boolean>}
   *
   * @deprecated please use the FileService.downloadFileFromUrl$
   */
  downloadPdf$(url: string, outputFilename?: string): Observable<boolean> {
    return from(fetch(url))
      .pipe(
        filter((response: Response) => response.status === 200),
        switchMap((response: Response) => {
          return from(response.blob());
        }),
        map((blob: Blob) => {
          FileManager.saveAs(blob, outputFilename);
          return true;
        }),
        catchError(() => of(false))
      );
  }

  /**
   * updates a given form template to its new template data
   *
   * @param   {string}           id
   * @param   {FormTemplateData} updatedData
   *
   * @returns {Observable<FormTemplate|undefined>}
   */
  updateTemplate$(id: string, updatedData: FormTemplateData): Observable<FormTemplate | undefined> {
    let payload = new URLSearchParams;

    payload.set('form_template_id', id);
    payload.set('module', updatedData.module);
    payload.set('isActive', this.transformBooleanValueToStringRepresentation(updatedData.isActive));
    payload.set('name', updatedData.name);
    if (updatedData.documentType) {
      payload.set('documentType', updatedData.documentType);
    }

    let response$ = this.callApi('/update_template', payload.toString());

    return response$.pipe(
      filter((response) => response.status === 200),
      map((response) => {
        return this.transformFormTemplate(response.body as FormTemplateResponseBody);
      }),
      catchError(() => of(undefined))
    );
  }

  /**
   * download template file from our storage and allows to be saved
   * by the user
   *
   * @param   {string} templateFilePath
   * @param   {string} outputFilename
   *
   * @returns {Observable<boolean>}
   */
  downloadTemplate$(templateFilePath: string, outputFilename: string): Observable<boolean> {
    let payload = new URLSearchParams;

    payload.set('template_file_path', templateFilePath);

    return this.callApi('/generate_download_url', payload.toString()).pipe(
      filter((response) => response.status === 200),
      map((response) => response.body.url),
      switchMap((url: string) => {
        return from(fetch(url));
      }),
      switchMap((response) => {
        return from(response.blob());
      }),
      map((content: Blob) => {
        FileManager.saveAs(content, outputFilename);
        return true;
      }),
      catchError(() => of(false))
    );
  }

  /**
   * Transform a url into blob
   *
   * @param {string} url
   *
   * @returns {Observable<Blobl>}
   *
   * @deprecated please use the FileService.toBlob$
   */
  toBlob$(url: string): Observable<Blob> {
    return from(fetch(url))
      .pipe(
        switchMap((response: Response) => from(response.blob()))
      );
  }

  /**
   * retrieves the current authenticated client information from the storage
   *
   * @return {Client|null}
   */
  protected getAuthenticatedClient(): Client | null {
    return (this.storage.hasItem('current_client'))
      ? this.storage.getJsonItem('current_client')
      : null;
  }

  /**
   * wraps and calls the API endpoint
   *
   * @param  {string} uri
   * @param  {any}    body
   * @param  {{
   *  method?: string,
   *  headers?: HttpHeaders,
   *  baseUrl?: string
   * }} options
   *
   * @return {Observable<HttpResponse<Config>>}
   */
  protected callApi(
    uri: string,
    body: any,
    options?: {
      method?: string,
      headers?: HttpHeaders,
      baseUrl?: string
    }
  ): Observable<HttpResponse<Config>> {
    options = Object.assign({
      baseUrl: environment.url,
      method: 'POST'
    }, options)
    uri = (uri !== '/') ? uri : '';
    let baseUrl = _.trimEnd(options.baseUrl, '/')

    return this.http[options.method.toLowerCase()](`${baseUrl}/form_templates${uri}`, body, { observe: 'response', headers: options.headers });
  }

  /**
   * transforms the form template data into an object of FormTemplate instance
   *
   * @param  {FormTemplateResponseBody} data
   *
   * @return {FormTemplate}
   */
  protected transformFormTemplate(data: FormTemplateResponseBody): FormTemplate {
    return new FormTemplate({
      id: data.id.toString(),
      name: data.name,
      moduleName: data.module,
      isActive: data.isActive || false,
      histories: this.transformHistories(data.histories || []),
      history: (data.history) ? this.transformHistory(data.history) : undefined,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt || null,
      documentType: data.documentType,
      minSubscription: data.minSubscription,
    });
  }

  /**
   * transforms each form templates data into instance of FormTemplate
   *
   * @param  {FormTemplateHistoryResponseBody[]} templates
   *
   * @return {FormTemplateHistory[]}
   */
  protected transformHistories(templates: FormTemplateHistoryResponseBody[]): FormTemplateHistory[] {
    return templates.map((history: FormTemplateHistoryResponseBody) => {
      return this.transformHistory(history);
    });
  }

  /**
   * transform a given template history data into a template history object
   *
   * @param  {FormTemplateHistoryResponseBody} history
   *
   * @return {FormTemplateHistory}
   */
  protected transformHistory(history: FormTemplateHistoryResponseBody): FormTemplateHistory {
    let metadata: FormTemplateMetadataResponseBody = history.metadata;

    return new FormTemplateHistory({
      id: history.id,
      path: history.path,
      version: history.version,
      isDefault: history.isDefault,
      metadata: {
        fields: (metadata.fields || []).map((field: FieldResponseBody) => {
          let options = field.options;
          return {
            name: field.name,
            text: field.text,
            type: field.type,
            options: {
              defaultValue: options.defaultValue,
              format: options.format || undefined
            }
          }
        }),
      },
    });
  }

  /**
   * creates a form collection instance from a given response body
   *
   * @param  {FormTemplatesCollectionResponseBody} body
   *
   * @return {FormTemplatesList}
   */
  protected makeFormTemplatesCollection(body): FormTemplatesList {
    let templates: FormTemplate[] = body.data.map((data: any) => {
      return this.transformFormTemplate(data);
    });

    return new FormTemplatesList({
      templates,
      current_page: body.current_page,
      total_page: body.total_page,
      has_previous_page: body.has_previous_page,
      has_next_page: body.has_next_page,
    });
  }

  /**
   * transforms a boolean value to a string representation that will be treated by the
   * API as boolean value
   *
   * '0' - represents false value
   * '1' - represents true value
   *
   * @param {boolean} value
   *
   * @returns {'1'|'0'}
   */
  protected transformBooleanValueToStringRepresentation(value: boolean): '1' | '0' {
    return (value === true) ? '1' : '0';
  }

  protected makeTemplateFileUploadError(err: HttpErrorResponse): UploadTemplateFileError | null {
    if (err.status === 422) {
      const fields = err.error;

      if (_.has(fields, 'template')) {
        return new UploadTemplateFileError(fields.template[0]); // capture the first error
      }
    }

    return null;
  }

  /**
   * Download the system default template
   *
   * @param {string} module
   * @param {string} documentType
   *
   * @returns {Observable<boolean>}
   */
  downloadSystemTemplate$(module: string, documentType: string): Observable<boolean> {
    let payload = new URLSearchParams;

    payload.set('module', module);
    payload.set('document_type', documentType);

    return this.callApi('/generate_system_template_download_url', payload.toString()).pipe(
      filter((response) => response.status === 200),
      map((response) => response.body),
      switchMap((metadata: SystemTemplateDownloadMetadata) => from(
        fetch(metadata.url)
          .then(response => response.blob())
          .then(content => ({ content, metadata }))
      )),
      map(({ content, metadata }) => {
        FileManager.saveAs(content, metadata.filename);
        return true;
      }),
      catchError(() => of(false))
    );
  }

  /**
   * Request a restore a speciifc document to system generated template
   *
   * @param   {FormTemplate} template
   *
   * @returns {Observable<boolean>}
   */
  restoreToSystemDefaultTemplate$(template: FormTemplate): Observable<boolean> {
    let payload = new URLSearchParams;

    payload.set('template_id', template.id);

    return this.callApi('/restore_system_default_template', payload.toString())
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  /**
   * Retrieved current module document data from the server
   *
   * @param {string} id
   * @param {string} module
   * @param {GetModuleDataOptions} options
   *
   * @returns {Observable<GetModuleDataResult>}
   */
  getModuleDocumentData$(id: string, module: string, options: GetModuleDataOptions = {}): Observable<GetModuleDataResult> {
    let payload = new URLSearchParams;

    // set required payload
    payload.set('id', id);
    payload.set('module', module);
    payload.set('timezone', moment().format('Z'));

    if (options.dontIncludeTemplate) {
      payload.set('dont_include_template', '1');
    }

    if (options.document_type) {
      // if received type are multiple types parse it to json
      let documentType = (_.isArray(options.document_type))
        ? JSON.stringify(options.document_type)
        : options.document_type as string;

      payload.set('document_type', documentType);
    }

    if (options.filter) {
      payload.set('filter', JSON.stringify(options.filter));
    }

    const currency = _.get(this.clients.getActiveClient().config, 'currency', 'USD');
    const locale = this.storage.getItem('user_locale') || 'en';

    if (currency) {
      payload.set('currency', currency);
    }

    if (locale) {
      payload.set('locale', locale);
    }

    if (!_.isEmpty(options.data)) {
      payload.set('data', JSON.stringify(options.data));
    }

    return this.callApi('/module/get_data', payload.toString(), { method: 'POST' }).pipe(
      map((response) => {
        response.body.template = (response.body.template) ? this.transformFormTemplate(response.body.template as FormTemplateResponseBody) : [];
        return response;
      }),
      map((response) => ({
        data: response.body.data,
        config: response.body.config,
        template: response.body.template,
        additional_template_paths: response.body.additional_template_paths || [],
        additional_document: response.body.additional_document || [],
      }))
    );
  }

  /**
   * retrieves module default templates
   *
   * @param  {string} module
   * @param  {string|string[]} documentType
   *
   * @return {Observable<FormTemplate|undefined>}
   */
  getSystemTemplate$(module: string, documentType: string | string[]): Observable<FormTemplate | undefined> {
    let body = new URLSearchParams;

    body.set('module', module);
    body.set('document_type', (_.isArray(documentType)) ? JSON.stringify(documentType) : documentType as string);

    let response$ = this.callApi('/module/get_system_template', body.toString());

    return response$.pipe(
      filter((response) => response.status === 200),
      map((response) => this.transformFormTemplate(response.body['template'] as FormTemplateResponseBody)),
      catchError(() => of(undefined)),
    );
  }

  sendDocumentToEmail$(module: string, start_date: string, end_date: string, filter: LooseObject, documentType: string): Observable<GetModuleDataResult> {
    let payload = new URLSearchParams;

    // set required payload
    payload.set('module', module);
    payload.set('start_date', start_date);
    payload.set('end_date', end_date);
    payload.set('filter', JSON.stringify(filter));
    payload.set('timezone', moment().format('Z'));

    return this.callApi(`/send/${documentType}`, payload.toString(), { method: 'POST' }).pipe(
      map((response) => {
        response.body.template = (response.body.template) ? this.transformFormTemplate(response.body.template as FormTemplateResponseBody) : [];
        return response;
      }),
      map((response) => ({
        data: response.body.data,
        config: response.body.config,
        template: response.body.template,
        additional_template_paths: response.body.additional_template_paths || [],
        additional_document: response.body.additional_document || [],
      }))
    );
  }

  /**
   * Retrieved current module document data from the server
   *
   * @param {string} module
   * @param {GetModuleDataOptions} options
   *
   * @returns {Observable<GetModuleDataResult>}
   */
  getModuleDocumentTemplate$(module: string, options: GetModuleDataOptions = {}): Observable<GetModuleDataResult> {
    let payload = new URLSearchParams;

    payload.set('module', module);
    payload.set('timezone', moment().format('Z'));

    if (options.dontIncludeTemplate) {
      payload.set('dont_include_template', '1');
    }

    if (options.document_type) {
      // if received type are multiple types parse it to json
      let documentType = (_.isArray(options.document_type))
        ? JSON.stringify(options.document_type)
        : options.document_type as string;

      payload.set('document_type', documentType);
    }

    if (options.filter) {
      payload.set('filter', JSON.stringify(options.filter));
    }

    const currency = _.get(this.clients.getActiveClient().config, 'currency', 'USD');
    const locale = this.storage.getItem('user_locale') || 'en';

    if (currency) {
      payload.set('currency', currency);
    }

    if (locale) {
      payload.set('locale', locale);
    }

    if (!_.isEmpty(options.data)) {
      payload.set('data', JSON.stringify(options.data));
    }

    return this.callApi('/module/get_template', payload.toString(), { method: 'POST' }).pipe(
      map((response) => {
        response.body.template = (response.body.template) ? this.transformFormTemplate(response.body.template as FormTemplateResponseBody) : [];
        return response;
      }),
      map((response) => ({
        data: response.body.data,
        config: response.body.config,
        template: response.body.template,
        additional_template_paths: response.body.additional_template_paths || [],
        additional_document: response.body.additional_document || [],
      }))
    );
  }
}

interface FormTemplateHistoryResponseBody {
  /**
   * contains the unique identifier for the form template uploade
   *
   * @var {string}
   */
  id: string,

  /**
   * contains the path of the file template
   *
   * @var {string}
   */
  path: string;

  /**
   * determines if the current form template is set as default
   *
   * @var {boolean}
   */
  isDefault: boolean;

  /**
   * this contains our form template history version
   *
   * @var {string}
   */
  version: string;

  /**
   * contains the form creation date
   *
   * @var {string}
   */
  createdAt: string;

  /**
   * template metadata
   *
   * @var {FormTemplateMetadataResponseBody}
   */
  metadata: FormTemplateMetadataResponseBody;

  /**
   * contains the form updated date
   *
   * @var {string|undefined}
   */
  updatedAt?: string;
}

interface FormTemplateMetadataResponseBody {
  /**
   * contains the fields of the template history metadata
   *
   * @var {FieldResponseBody[]}
   */
  fields: FieldResponseBody[]
}

interface FieldResponseBody {
  /**
   * contains the field label
   *
   * @var {string}
   */
  text: string;

  /**
   * contains the name of the field
   *
   * @var {string}
   */
  name: string;

  /**
   * contains the type of field
   *
   * @var {string}
   */
  type: string;

  /**
   * contains the option information of the field
   *
   * @var {FieldOptionsResponseBody}
   */
  options: FieldOptionsResponseBody,
}

interface FieldOptionsResponseBody {
  /**
   * contains the default value of the field
   *
   * @var {string}
   */
  defaultValue: string;

  /**
   * contains the format of the value that field will accept
   *
   * @var {string}
   */
  format?: string;
}

interface FormTemplateResponseBody {
  /**
   * contains the unique identifier for the current form
   *
   * @var {string|number}
   */
  id: string | number;

  /**
   * contains the name of the form
   *
   * @var {string|number}
   */
  name: string;

  /**
   * contains the module name of the form
   *
   * @var {string|number}
   */
  module: string;

  /**
   * determines if the current for is active
   *
   * @var {boolean}
   */
  isActive: boolean;

  /**
   * contains the form creation date
   *
   * @var {string}
   */
  createdAt: string;

  /**
   * contains the current templates assigned to this form
   *
   * @var {FormTemplateHistoryResponseBody[]}
   */
  histories?: FormTemplateHistoryResponseBody[];

  /**
   * contains the currente default template history
   *
   * @var {FormTemplateHistoryResponseBody}
   */
  history?: FormTemplateHistoryResponseBody;

  /**
   * contains the form updated date
   *
   * @var {string|undefined}
   */
  updatedAt?: string;

  /**
   * contains the form updated date
   *
   * @var {string|null}
   */
  documentType: string | null;

  /**
   * contains the template minimum subscription
   *
   * @var {SubscriptionId|null}
   */
  minSubscription?: SubscriptionId | null;
}

interface FormTemplatesCollectionResponseBody {
  /**
   * contains the associative data for each form
   *
   * @var {FormTemplateResponseBody[]}
   */
  data: FormTemplateResponseBody[];

  /**
   * Optional: contains the next token value for the pagination
   *
   * @var {string | null}
   */
  nextToken?: string | null;

  /**
   * Optional: contains the previous token value for pagination
   *
   * @var {string | null}
   */
  prevToken?: string | null;

  /**
   * Optional: contains the current token requested for pagination
   *
   * @var {string | null}
   */
  currentToken?: string | null;
}

interface FormTemplateData {
  /**
   * contains the module name for this form template data to be created/updated
   *
   * @var {string}
   */
  module: string;

  /**
   * contains the name of the form template
   *
   * @var {string}
   */
  name: string;

  /**
   * indicates if the current form template should be active/inactive
   *
   * @var {boolean}
   */
  isActive: boolean;

  /**
   * contains the document type of module
   *
   * @var {string | null}
   */
  documentType: string | null;
}

interface CreateFormTemplateData extends FormTemplateData {
  /**
   * contains the template file object
   *
   * @var {File}
   */
  file: File;
}

class UploadTemplateFileError extends Error {
  name: string = 'UploadTemplateFileError';

  constructor(message: string) {
    super(message);
  }
}

interface SystemTemplateDownloadMetadata {
  url: string;
  filename: string;
}

export interface GetModuleDataOptions {
  filter?: { [key: string]: any };
  document_type?: string | string[];
  dontIncludeTemplate?: boolean;
  data?: Record<string, any>;
}

interface GetModuleDataResult {
  data: LooseModuleData;
  config: {
    filename: string
  };
  template: FormTemplate;
  additional_template_paths: string[];
}
