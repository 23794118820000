import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddressComponent } from '../components/address/address.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedInputModule } from './shared-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    NgSelectModule,
    SharedInputModule,
  ],
  exports: [
    AddressComponent
  ],
  declarations: [
    AddressComponent
  ],
})

export class AddressModule { }
