import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isUndefined, toString } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ListShareableModuleFilesResponseBody, SharedModuleResponseBody } from '../objects/api-response-body';
import { ListShareableModuleFileData } from '../objects/services/sharing-data';

@Injectable()
export class SharingService {
  /**
   * @param {HttpClient} http
   */
  constructor(
    protected readonly http: HttpClient,
  ) {}

  /**
   * Perform share module record request
   *
   * @param {string[]} id
   * @param {string} moduleName
   *
   * @returns {Observable<boolean>}
   */
  shareRecord(ids: string[], moduleName: string): Observable<boolean> {
    const params = new URLSearchParams;

    params.set('ids', JSON.stringify(ids));

    return this.http.patch<SharedModuleResponseBody>(
      `${environment.url}/data-sharing/share/${moduleName}`,
      params.toString(),
      {
        observe: 'body',
      }
    ).pipe(
      map(() => true)
    );
  }

  /**
   * Retrieve the list of shareable files for the given module
   *
   * @param {ListShareableModuleFileData} data
   *
   * @returns {Observable<ListShareableModuleFilesResponseBody> }
   */
  getShareableModuleFiles(data: ListShareableModuleFileData): Observable<ListShareableModuleFilesResponseBody> {
    let params = new URLSearchParams;

    if (! isUndefined(data.page)) {
      params.set('page', toString(data.page));
    }

    params.set('ids', JSON.stringify(data.moduleIds));

    return this.http.post<ListShareableModuleFilesResponseBody>(
      `${environment.url}/data-sharing/share/${data.moduleName}/files`,
      params.toString(),
      {
        observe: 'response',
      }
    ).pipe(
      map((response) => response.body)
    );
  }
}