import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-archive-confirmation',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit, OnDestroy {

  /**
   * model for module selection
   *
   * @var {string[]}
   */
  public arSelectedModule = [];

  /**
   * list of related module for current module
   *
   * @var {LooseObject[]}
   */
  public arModuleSelection = [];

  /**
   * Contains the list of subscriptions that should cleaned up after
   *
   * @var {Subscription[]}
   */
  private arSubscriptions: Subscription[] = [];

  private objRelatedModule = {
    customers: [
      {
        module: 'sites',
        selected: false
      },
      {
        module: 'recurring_jobs',
        selected: false
      },
      {
        module: 'recurring_invoices',
        selected: false
      },
    ]
  }

  constructor(
    public dialogRef: MatDialogRef<ArchiveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.arModuleSelection = this.objRelatedModule[data.module];
  }

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.arSubscriptions.push(
      this.dialogRef.backdropClick().subscribe(() => this.closeDialog(false))
    );

    this.arSubscriptions.push(
      this.dialogRef.keydownEvents()
        .pipe(
          filter(($event: KeyboardEvent) => $event.key === 'Escape')
        )
        .subscribe(($event: KeyboardEvent) => this.closeDialog(false))
    )
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.arSubscriptions.forEach((objSub: Subscription) => objSub.unsubscribe());
  }

  /**
   * Method to close the dialog, along with
   * the response.
   *
   * @param {boolean} bConfirm
   */
  closeDialog(bConfirm: boolean): void {
    this.dialogRef.close({
      confirmation: bConfirm,
      module: this.arSelectedModule
    });
  }

}
