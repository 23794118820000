import { Component, OnInit, Input} from '@angular/core';
import { ListingService } from '../../../../services/listing.service';
import * as moment from 'moment';
import { StatusColors } from '../../../../lists/listing-fields';
import { ViewService } from '../../../../services/view.service';
import { MatDialog } from '@angular/material';
import { NotificationService } from '../../../../services/notification.service';
import { Notification } from '../../../../objects/notification';
import { RecordService } from '../../../../services/record.service';
import { EditformComponent } from '../../../../shared/components/editform/editform.component';
import { AddQuoteComponent } from '../../../../module/opportunities/quotes/add-quote/add-quote.component';
import { CreateQuoteWizardComponent } from '../../../../features/wizard/components/create-quote-wizard/create-quote-wizard.component';
import { PopupConfig } from '../../../../objects/dialogs/popup-config';
import * as _ from "lodash";

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss'],
  providers: [ListingService],
})
export class OpportunitiesComponent implements OnInit {

  @Input() strRecordId: string;
  @Input() strModule: string;

  public arOpportunities = [];
  public bLoading = true;
  public objColors = new StatusColors;
  public strSearchSummary = '';
  public strSelectStatus = null;
  public bPageLoaded: boolean = false;

  /**
   * list of status filter
   */
  public arStatusFilter: string[] = [
    'open', 'closed_won', 'closed_lost'
  ];

  constructor(
    public listService: ListingService,
    public viewService: ViewService,
    private dialog: MatDialog,
    private notifService: NotificationService,
    private recordService: RecordService
    ) { }

  ngOnInit() {
    this.fetchList('default');
  }

  filter() {
    let objFilter = {};

    if (this.strSelectStatus != '' && this.strSelectStatus != null) {
      objFilter['status'] = this.strSelectStatus;
    }
    this.arOpportunities = [];
    this.fetchList('default', objFilter);
  }

  /**
	 * Fetch the list of quote.
	 * @param strPage - what page is currently to be viewed.
	 */
	fetchList(strPage, filter = {}) {
    // Show loading icon.
    this.bLoading = true;
    // Initiate pagination.
    let objPagination = this.listService.beforeFetching(strPage);
    // Rename module to singular.
    let strName = this.strModule.slice(0, -1);


    if (this.strModule === 'jobs') {
      // This will be the field to be filter
      // when it from jobs record view
      strName = 'from_job';
    }

    filter[strName + '_id'] = this.strRecordId;


		// Get the list from API.
		this.listService.fetchDataAdvanceSearch(
      objPagination['objPage'],
      'opportunities',
      filter,
      { 'id': 'created_at', 'sort': 'desc' },
    ).subscribe(response => {

      this.arOpportunities = response['data'];
      // Hide loading icon.
      this.bLoading = false;
      this.listService.afterFetching(response, strPage);
      this.bPageLoaded = true;
		});
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, arCustomConfig = []) {
    if (_.isEmpty(strDate)) return;

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }

  /**
   * Open dialog to edit record.
   * @param - the id of record if edit.
   */
  recordDialog(strId = '') {
    let arRecordView = this.viewService.arRecord;
    let arData = {
      module : this.strModule
    }

    if (this.strModule == 'sites') {
      arData['address'] = arRecordView['address'];
      arData['site_text'] = arRecordView['site_text'];
      arData['site_id'] = arRecordView['id'];
      arData['customer_text'] = arRecordView['customer_text'];
      arData['customer_id'] = arRecordView['customer_id'];
    } else if (this.strModule == 'customers') {
      arData['address'] = arRecordView['address'];
      arData['customer_text'] = arRecordView['customer_text'];
      arData['customer_id'] = arRecordView['id'];
    } else if (this.strModule === 'jobs') {
      arData['from_job_id'] = arRecordView['id'];
      arData['from_job_text'] = arRecordView['job_number'];
      arData['customer_id'] = arRecordView['customer_id'];
      arData['customer_text'] = arRecordView['customer_text'];
      arData['site_text'] = arRecordView['site_text'];
      arData['site_id'] = arRecordView['site_id'];
    }

    let popupConfig : {[k: string]: any} = {
        //Here, we pass all the data we need inside the dialog.
        data: {
            "arData": arData,
            "strModule": 'opportunities',
            "strId": strId,
            "strMode": (strId) ? 'edit' : 'add'
        },
        disableClose: true
      };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      popupConfig.width = '100%';
      popupConfig.height = '100%';
      popupConfig.maxHeight = '100vh';
      popupConfig.maxWidth = '100vw';
    } else {
      // display as pop up
      popupConfig.width = '80%';
      popupConfig.height = 'auto';
    }

    // This line initializes and opens dialog.
    let editRecordDialog = this.dialog.open(EditformComponent, popupConfig);

    editRecordDialog.afterClosed().first().subscribe(editRecordData => {
      if (editRecordData !== undefined && editRecordData.status == 'save') {
        if (this.strModule === 'jobs' && strId === '') {
          this.recordService.getDropdownRecord('quotes').subscribe( result => {
            // We need to set the view record as the result of created opp
            this.viewService.setViewRecord(editRecordData.data);
            // Create the object to be passed inside the dialog.
            let objData = {
              maxWidth: '100%',
              width: '100%',
              height: 'auto',
              padding: '1%',
              // The id of the opporunity, the quote's "parent".
              data: {
                record_id: editRecordData.data.id,
                default_config: [],
                options: result.body,
                view_type: 'add',
                case: ''
              },
              disableClose: true
            };

            // Initialize the dialog.
            let tabDialogRef = this.dialog.open(AddQuoteComponent, objData);

            // When the dialog closes, reload the list.
            tabDialogRef.afterClosed().subscribe(item => {
              // After the add quote component is close.
              // We need to set the view record on its
              // original record
              this.viewService.setViewRecord(arRecordView);
              this.filter();
            })
          });
        } else {
          this.filter()
        }
      }
    });
  }

  /**
   * Opens the quote creation wizard
   *
   * @returns {void}
   */
  openCreateQuoteWizard(): void {
    let arRecordView = this.viewService.arRecord;

    let quoteWizardConfig = {
      record_type: 'opportunities',
      creating_from_widget: true,
    };

    if (this.strModule === 'jobs') {
      quoteWizardConfig['site_id'] = arRecordView['site_id'];
      quoteWizardConfig['customer_id'] = arRecordView['customer_id'];
      quoteWizardConfig['job_id'] = arRecordView['id'];
    } else if (this.strModule === 'customers') {
      quoteWizardConfig['customer_id'] = arRecordView['id'];
    } else if (this.strModule === 'sites') {
      quoteWizardConfig['customer_id'] = arRecordView['customer_id'];
      quoteWizardConfig['site_id'] = arRecordView['id'];
    }

    let otherConfig = {
      panelClass: ['mat-dialog-container-overflow-auto'],
      data: quoteWizardConfig
    };

    let popup = new PopupConfig(
      CreateQuoteWizardComponent,
      { width: '60%', height: 'auto' },
      { width: '70%', height: 'auto' },
      otherConfig
    );

    this.dialog
      .open(popup.component, popup.config)
      .afterClosed()
      .first()
      .subscribe(_ => {
        setTimeout(() => {
          this.onRefresh();
        }, 3500);
      });
  }

  /**
   *
   * @param strId - record id
   */
  deleteOpportunity(strId) {
    this.notifService.sendConfirmation('continue_confirm')
      .subscribe(confirmation => {
        if (confirmation.answer) {
          this.recordService.deleteRecord('opportunities', strId).first().subscribe(
            data => {
              if (data) {
                this.fetchList('default');
                this.notifService.notifySuccess('record_delete_success');
              } else {
                this.notifService.notifyError('record_delete_failed');
              }
            });
        }
      })
    }

  /**
   * Refreshes current list
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.filter();
  }

  ngOnChanges(): void {
    this.fetchList('default');
  }
}
