import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CustomerWizardData, WizardContactData, WizardCustomerData, WizardJobData, WizardQuoteData } from '../../objects/wizard';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatStep, MatStepper } from '@angular/material';
import { NotificationService } from '../../../../services/notification.service';
import { WizardService } from '../../services/wizard.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Config } from 'protractor';
import { WizardStep, WizardStepper } from '../../objects/wizard-stepper';
import { CustomerComponent } from '../steps/customer/customer.component';
import { ViewService } from '../../../../services/view.service';
import { LayoutDashboardService } from '../../../../shared/layouts/layout-dashboard/layout-dashboard.service';

@Component({
  selector: 'app-create-customer-wizard',
  templateUrl: './create-customer-wizard.component.html',
  styleUrls: ['./create-customer-wizard.component.scss']
})
export class CreateCustomerWizardComponent implements OnInit {

  /**
   * View the contact component step.
   *
   * @var {CustomerComponent}
   */
  @ViewChild(CustomerComponent) customer: CustomerComponent;

  /**
   * The stepper initial settings. You can also update the
   * stepper through here but do note that it will have a delay as
   * change detection of child components take a while to reflect.
   *
   * @var {WizardStepper}
   */
  public wizardStepper: WizardStepper = new WizardStepper(
    new WizardStep({title: 'customer', icon: 'users', optional: false, editable: true, completed: false}),
    new WizardStep({title: 'contact', icon: 'address-book', optional: false, editable: true, completed: false}),
    new WizardStep({title: 'create', icon: 'plus-circle', optional: true, editable: false, completed: false})
  )

  /**
   * Holds the data for this wizard.
   *
   * @var {CustomerWizardData}
   */
  public objWizard: CustomerWizardData = {
    contact: null,
    customer: null
  };

  /**
   * The loading flag when hitting save on the last step.
   *
   * @var {boolean}
   */
  public bLoading: boolean = false;

  /**
   * The id of the customer if it already exists.
   *
   * @var {string}
   */
  public strCustomerId?: string;

  /**
   * The id of the site if it already exists.
   *
   * @var {string}
   */
  public strSiteId?: string;

  /**
   * The customer record response of the API.
   *
   * @var {CustomerResponse}
   */
  public objCustomerRecord?: CustomerResponse;

  /**
   * List of actions after saving customer record.
   *
   * @var {string[][]}
   */
  public arActions: string[][] = [
    ['jobs', 'wrench'],
    ['opportunities', 'list-ul'],
    ['customer_invoices', 'dollar-sign'],
    ['purchase_orders', 'shopping-bag'],
    ['supplier_invoices', 'file-alt'],
    ['recurring_jobs', 'tools'],
    ['recurring_invoices', 'file-invoice-dollar']
  ];

  constructor(
    public dialogRef: MatDialogRef<CreateCustomerWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private notifService: NotificationService,
    public wizardService: WizardService,
    private router: Router,
    private layout: LayoutDashboardService
  ) {
    this.dialogData = this.wizardService.checkPreselectedData(layout, dialogData);
  }

  ngOnInit() {}

  /**
   * Get the updates from the contact form
   * and save it to this component.
   *
   * @param {WizardContactData} objContactData
   * @param {MatStepper} objStepper
   *
   * @returns {void}
   */
  captureContactData(objContactData: WizardContactData, objStepper: MatStepper): void {

    this.objWizard.contact = objContactData;

    this.wizardStepper.step2.completed = true;

    this.save().subscribe(response => {
      this.wizardStepper.step1.completed = true;
      this.wizardStepper.step1.editable = false;
      this.wizardStepper.step2.completed = true;
      this.wizardStepper.step2.editable = false;
      this.bLoading = false;
      this.customer.form.markAsPristine();
      this.notifService.notifySuccess('save_record_success');
      this.objCustomerRecord = response.body['record'];
      this.strCustomerId = this.objCustomerRecord.customer_id;
      objStepper.next();

    }, (error: HttpErrorResponse) => {
      let errorIds = Object.keys(error.error);
      if (errorIds.length && errorIds[0]) {
        this.notifService.notifyWarning(error.error[errorIds[0]][0]);
      } else {
        this.notifService.notifyWarning('record_invalid_parameters');
        this.wizardStepper.step2.completed = false;
      }
    });

  }

  /**
   * Captures the customer data
   *
   * @param {WizardCustomerData} objCustomerData
   * @param {MatStepper} objStepper
   *
   * @returns {void}
   */
  captureCustomerData(objCustomerData: WizardCustomerData, objStepper: MatStepper): void {
    this.objWizard.customer = objCustomerData;
    this.strCustomerId = null;
    this.strSiteId = null;

    if (objCustomerData != null) {

      objStepper.steps.first.completed = true;
      objStepper.next();

      if (objCustomerData['customer_id']) {
        this.strCustomerId = objCustomerData['customer_id'];
      }

      if (objCustomerData['site_id']) {
        this.strSiteId = objCustomerData['site_id'];
      }
    }
  }

  /**
   * Checks if this stepper is dirty.
   *
   * @returns {boolean}
   */
  isDirty(): boolean {
    if (this.customer) {
      return this.customer.form.dirty
    }

    return false;
  }

  /**
   * When hitting the last step, call the API to save
   * the collected data.
   *
   * @param {string}
   *
   * @returns {void}
   */
  redirect(strModule: string): void {
    this.dialogRef.close();

    if (strModule != 'no_action') {
      this.router.navigate([`/${strModule}`], {queryParams: {...this.objCustomerRecord, ...{add_form: 'open'}}});
    } else {
      this.router.navigate([`/customers/${this.strCustomerId}`]);
    }
  }

  /**
   * Saves the collected data to API.
   *
   * @returns {Observable<HttpResponse<Config>>}
   */
  private save(): Observable<HttpResponse<Config>> {
    this.bLoading = true;
    return this.wizardService.saveCustomerDetails(this.objWizard);
  }

}

export interface CustomerResponse {
  customer_id: string;
  customer_text: string;
}
