import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { isEmpty } from 'lodash';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { Relate } from '../../../../objects/relate';
import { Select } from '../../../../objects/select';
import { Users } from '../../../../objects/users';
import { ListingService } from '../../../../services/listing.service';
import { NotificationService } from '../../../../services/notification.service';
import { RecordService } from '../../../../services/record.service';

@Component({
  selector: 'users-reassign-future-tasks-dialog',
  templateUrl: './reassign-future-tasks.dialog.html',
  styleUrls: ['./reassign-future-tasks.dialog.scss']
})
export class ReassignFutureTasksDialog {
  public users = new Relate<Users>();
  readonly form = new FormGroup({
    user_id: new FormControl,
  });

  get canReAssign(): boolean {
    return  !isEmpty(this.form.controls.user_id.value);
  }

  constructor(
    protected readonly dialog: MatDialogRef<ReassignFutureTasksDialog, ReassignFutureTasksResult>,
    @Inject(MAT_DIALOG_DATA) protected readonly data: ReassignFutureTasksDialogData,
    public listingService: ListingService,
    public notifService: NotificationService,
    public recordService: RecordService,
  ) {}

  ngOnInit() {
    this.recordService.getRecordRelate('users',
      '',
      false,
      false,
      {
        excluded_id: this.data.exclude_user_id,
      }
    )
    .pipe(
      debounceTime(100)
    ).subscribe(users => {
      this.users = new Relate<Users>().buildRelates(
        switchMap(keyword => this.recordService.getRecordRelate('users',
            keyword,
            false,
            false,
            {
              excluded_id: this.data.exclude_user_id,
            }
          ).pipe(
            map(users => {
            return users;
          })
        )),
        users
      );
    });
  }

  onCancel(): void {
    this.dialog.close({
      action: Action.CANCELLED,
    });
  }

  onReassign(): void {
    if (this.canReAssign) {
      this.dialog.close({
        action: Action.REASSIGN,
        user_id: this.form.controls.user_id.value,
      });
    } else {
      this.notifService.notifyWarning('select_user_to_assign');
    }
  }

  onUnassign(): void {
    this.dialog.close({
      action: Action.UNASSIGN,
    });
  }

  /**
   * create search filter for relate field
   *
   * @param field
   * @param value
   */
  createRelateFilter(field: string, value: string) {
    return {
      ...(value && {
          [field] : {
            op: "eq",
            value: value
          }
        }),
        "id": [{
          "op": "ne",
          "value": this.data.exclude_user_id,
        }],
    }
  }
}

export type ReassignFutureTasksDialogData = {
  exclude_user_id: string;
}

export enum Action {
  UNASSIGN,
  REASSIGN,
  CANCELLED,
};

export type ReassignFutureTasksResult = {
  action: Action.REASSIGN,
  user_id: string;
} | {
  action: Action.UNASSIGN | Action.CANCELLED,
};