<div class="input-group">
  <div class="input-group-prepend">
    <button (click)="openProductFolders()" class="btn btn-primary" type="button">Browse Products</button>
  </div>

  <ng-select
    *ngIf="bAllowCustom"
    #productSearch
    [items]="objProductRelate.source | async"
    [addTag]="addTag"
    [typeahead]="objProductRelate.typehead"
    [loading]="objProductRelate.loader"
    [clearable]="false"
    (change)="addAttribute(productSearch, $event)"
    [ngModelOptions]="{standalone: true}"
    bindValue="id"
    appendTo="body"
    placeholder="{{ 'search_product_quote' | translate }}"
    [(ngModel)]="objProductRelate.value"
    (open)="objProductRelate.loadDataOnClick()"
    class="pill-inputs-end h-40 hide-arrow">

    <ng-template ng-option-tmp let-item="item" class="value-text">
      [{{ item.code | translate }}] {{ item.text }}&nbsp;
      <a href="/#/items/{{ item.id }}" target="_blank" (click)="$event.stopPropagation()" ><fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon></a>
    </ng-template>

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      [{{ item.code | translate }}] {{ item.text }}
    </ng-template>

  </ng-select>

  <ng-select
    *ngIf="!bAllowCustom"
    #productSearch
    [items]="objProductRelate.source | async"
    [typeahead]="objProductRelate.typehead"
    [loading]="objProductRelate.loader"
    [clearable]="false"
    (change)="addAttribute(productSearch, $event)"
    [ngModelOptions]="{standalone: true}"
    bindValue="id"
    appendTo="body"
    placeholder="{{ 'search_product_quote_no_custom' | translate }}"
    [(ngModel)]="objProductRelate.value"
    (open)="objProductRelate.loadDataOnClick()"
    class="pill-inputs-end h-40 hide-arrow">

    <ng-template ng-option-tmp let-item="item" class="value-text">
      [{{ item.code | translate }}] {{ item.text }}&nbsp;
      <a href="/#/items/{{ item.id }}" target="_blank" (click)="$event.stopPropagation()" ><fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon></a>
    </ng-template>

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      [{{ item.code | translate }}] {{ item.text }}
    </ng-template>

  </ng-select>
</div>
