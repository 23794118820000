import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MetadataFieldInterface } from "../../../../../../shared/entities/driver";
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'authorization-setup-metadata',
  templateUrl: './setup-metadata-fields.component.html'
})
export class SetupMetadataFieldsComponent implements OnInit {
  /**
   * contains the list of fields that should be rendered by the component
   *
   * @var {MetadataField[]}
   */
  fields: MetadataFieldInterface[] = [];

  /**
   * The driver unique identification
   *
   * @var {string}
   */
  driverId: string;

  /**
   * contains the list of error message for each field that is from the API service
   *
   * @var {[key: string]: string[]}
   */
  errors: {[key: string]: string[]} = {};

  /**
   * contains the form group of the current metadata fields
   *
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({});

  /**
   * determines if the current form is in progress of submission
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * @param {MatDialogRef<SetupMetadataFieldsComponent>} dialog
   * @param {{fields: MetadataFieldInterface[], driverId: string}} data
   */
  constructor(
    private dialog: MatDialogRef<SetupMetadataFieldsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {fields: MetadataFieldInterface[], driverId: string}
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit() {
    this.fields = this.data.fields;
    this.driverId = this.data.driverId;
  }

  /**
   * handles event emitted of render custom metadata component that determines if the current metadata
   * was updated/saved successful in our server
   *
   * @param   {boolean} isSuccessful
   *
   * @returns {void}
   */
  onMetadataUpdate(isSuccessful: boolean): void {
    if (isSuccessful) this.dialog.close(true);
  }
}
