export class ChecklistConstants {

    PromptTypes = [
        'pass/fail',
        'field_entry',
        'signature',
        'instructions',
        'dropdown',
        'text',
        'number',
        'date'
    ];

    // Fields that are not included in the field entry value selection
    // ( Just probabbly in the first release )
    FieldEntryExcludedFields = [
        "phone",
        "address_geolocation",
        "attributes",
        "custom_attributes",
        "custom_field",
        "job_number",
        "status",
        "email_address",
        "invoicing_type",
        "priority",
        "job_type",
        "created_at",
        "created_by",
        "updated_at",
        "modified_by",
        "last_inspection_date",
        'is_custom_location',
        'is_invoiced',
        'amount_to_invoice',
        'amount_actually_invoiced',
        'external_id',
    ];

    FieldEntryExcludedDataType = [
        "relate",
        "address"
    ];

    AvailableInspectionPeriod = [
        'annual',
        'bi_annual',
        'every_2_years',
        'every_3_years',
        'every_4_years',
        'every_5_years',
        'monthly',
        'quarterly',
        'weekly',
        'one_day',
        'non_recurring',
        'every_10_years',
    ];

}

export enum ChecklistTypeDisplayToEnum {
    'job_checklist' = 'job',
    'quote_checklist' = 'opportunity',
    'assets_checklist' = 'asset'
}

export enum ChecklistTypeEnumToDisplay {
    'job' = 'job_checklist',
    'opportunity' = 'quote_checklist',
    'asset' = 'assets_checklist'
}