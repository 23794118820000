import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationButtons } from './pagination-buttons.component';
import { FontAwesomeModule } from '../../modules/font-awesome.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule
  ],
  declarations: [
    PaginationButtons
  ],
  exports: [
    PaginationButtons
  ],
})
export class PaginationButtonsModule { }
