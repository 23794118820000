import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { Client } from '../../../../objects/client';
import { ChargebeeService } from '../../../../services/chargebee/chargebee.service';

@Component({
  selector: 'change-payment',
  templateUrl: './change-payment.component.html',
  styleUrls: ['./change-payment.component.scss']
})
export class ChangePaymentComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public client: Client,
    public objDialogRef: MatDialogRef<ChangePaymentComponent>,
    protected chargebeeService: ChargebeeService
  ) { }

  /**
   * A simple flag for a loading indicator.
   *
   * @var {boolean}
   */
  public bLoading: boolean = true;

  /**
   * Holder for the url for the payment method.
   *
   * @var {string}
   */
  public strUrl: string;

  ngOnInit() {
    this.chargebeeService.changePayment(this.client.client_id).pipe(
      finalize(() => this.bLoading = false)
    ).subscribe(strUrl => {
      this.strUrl = strUrl;
    });
  }

}
