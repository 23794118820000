import { Form } from './form';
import { Select } from '../objects/select';

export class Multiselect extends Form<string> {
  controlType = 'multiselect';
  options: Select[] = [];
  tag: boolean = false;
  multiple: boolean = true;
  hideSelected: boolean = true;
  closeOnSelect: boolean = false;
  clearable: boolean = false;
  maxSelectedItems: number = 100;
  module: string;

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.tag = (typeof options['tag'] == 'boolean') ? options['tag'] : this.tag;
    this.multiple = (typeof options['multiple'] == 'boolean') ? options['multiple'] : this.multiple;
    this.hideSelected = (typeof options['hideSelected'] == 'boolean') ? options['hideSelected'] : this.hideSelected;
    this.closeOnSelect = (options['tag'] == true) ? true : false || this.closeOnSelect;
    this.maxSelectedItems = options['maxSelectedItems'] || this.maxSelectedItems;
    this.module = options['module'] || '';

  }
}