import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class CustomTranslateService {

  /**
   * SETTING UP:
   * To use this custom translate, please make sure you have
   * an existing instance of the actual TranslateService.
   * After that, add this to the providers portion of your component.
   *
   * HOW TO USE:
   * On your component's constructor or ngOnit, call the
   * initializeTransalateables function and supply an array of strings
   * containing the KEYS of your desired translations.
   *
   * Example:
   * this.customTranslateables.initializeTransalateables(['name', 'email', 'address']);
   *
   * Afterwards, simply use the getTranslation function to retrieve the translation
   * of your key. Translation will be updated when switching to different languages.
   */

  public objTranslatedValues = {};
  public arTranslatedValues = [];
  public strCurrentLang = '';

  // Get the actual translate service.
  constructor(public translateService: TranslateService) {

    // Gets the current language selected.
    this.strCurrentLang = this.translateService.getDefaultLang();

    // Checks if the default language is changed. If yes, it updates
    // the current key/value pair to it's new translation values.
    this.translateService.onLangChange.subscribe(objTranslationConfig => {
      if (objTranslationConfig['lang'] != undefined && objTranslationConfig['lang'] != this.strCurrentLang) {
        // Save the current language.
        this.strCurrentLang = objTranslationConfig['lang'];
        // Get the new set of translation.
        this.getAllTranslation();
      }
    });
  }

  /**
   * Initialize the translations we need.
   * @param arToBeTranslatedValues
   */
  initializeTransalateables(arToBeTranslatedValues: string[]) {
    // Save the current set of translateables.
    this.arTranslatedValues = arToBeTranslatedValues;
    // Get the new set of translation.
    this.getAllTranslation();
  }

  /**
   * Get the translation value from the key.
   * @param strTranslationKey
   */
  getTranslation(strTranslationKey: string) {

    if (this.objTranslatedValues[strTranslationKey] != undefined) {
      return this.objTranslatedValues[strTranslationKey];
    }

    return strTranslationKey;
  }

  /**
   * Get all translation from the local assets.
   */
  getAllTranslation() {
    if (this.arTranslatedValues.length !== 0) {
      this.translateService.get(this.arTranslatedValues).subscribe(translations => {
        // Save the current set of key/pair translateables.
        this.objTranslatedValues = translations;
      });
    }
  }

  /**
   * Get all translation from the local assets.
   */
  getAllTranslationSubscription() {
    return this.translateService.get(this.arTranslatedValues);
  }

  setAllTranslation(arTranslations){
    this.arTranslatedValues = arTranslations;
  }

  /**
   * Adds a key to be translated.
   *
   * @param {string} strKey
   */
  addTranslationKey(strKey: string): void {
    this.arTranslatedValues.push(strKey);
  }

}
