import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material'
import FormTemplate from '../../../../entities/form-template.entity';
import { LooseModuleData } from '../../../../contracts/loose-module-data';
import { FormTemplateService } from '../../../../services/form-template.service';
import { Observable, Subscription, of, iif, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators/finalize';
import { NotificationService } from '../../../../../../../services/notification.service';
import { LooseCustomField } from '../../../../contracts/loose-custom-field';
import { switchMap } from 'rxjs/operators/switchMap';
import { map } from 'rxjs/operators/map';
import { ViewService } from '../../../../../../../services/view.service';
import { EmailComponent } from '../../../../../../../shared/components/widget/email/email.component';
import { RecordService } from '../../../../../../../services/record.service';
import { get, isObject, concat } from 'lodash';
import { FileService } from '../../../../../../../services/file/file.service';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivitiesService } from '../../../../../../../services/activities.service';
import { SmsComponent } from '../../../../../../../shared/components/widget/activities/messages/sms/sms.component';
import { catchError, tap, filter } from 'rxjs/operators';
import { ChecklistsService } from '../../../../../../../services/checklist.service';
import { ChecklistAttachmentsComponent } from '../../../../../../../shared/components/widget/email/checklist-attachments/checklist-attachments.component';

const MODULE_PREVIEW_HEADER = {
  'customer_invoices': 'invoice_number',
  'assets': 'serial_number',
  'supplier_invoices': 'invoice_number',
  'purchase_orders': 'po_number',
  'quotes': 'quote_number',
};

@Component({
  selector: 'form-templates-preview',
  templateUrl: './preview.component.html',
  styleUrls: [ './preview.component.scss' ]
})
export class PreviewComponent implements OnInit, OnDestroy {
  /**
   * indicates that the template is being generated for preview
   *
   * @var {boolean}
   */
  isGeneratingPreview: boolean = false;

  /**
   * Indicates that the current user is composing an email
   *
   * @var {boolean}
   */
  isComposingEmail: boolean = false;

  /**
   * Indicates that the user currently downloading the generated document
   *
   * @var {boolean}
   */
  isDownloading: boolean = false;

  /**
   * Indicates that the user currently sharing the document to portal
   *
   * @var {boolean}
   */
   isSharingToPortal: boolean = false;

  /**
   * indicates if the current preview has been loaded after successful process
   *
   * @var {boolean}
   */
  isLoaded: boolean = false;

  /**
   * contains the absolute link to the generated PDF
   *
   * @var {string}
   */
  previewURL: string;

  /**
   * Indicates that the user currently generating a document to attach in chat
   *
   * @var {boolean}
   */
  isComposingChat: boolean = false;

  /**
   * Indicates that the current module the user is in
   *
   * @var {string}
   */
  strModule: string;

  /**
   * PRIVATE: contains the list of observable subscription that will be cleaned when
   * this component is destroyed
   */
  private subscriptions: Subscription[] = [];

  /**
   * @param {PreviewPdfData}                  data
   * @param {MatDialogRef<PreviewComponent>}  currentDialog
   * @param {FormTemplateService}             forms
   * @param {NotificationService}             notifications
   * @param {ViewService}                     view
   * @param {MatDialog}                       dialogFactory
   * @param {RecordService}                   records
   * @param {FileService}                     files
   * @param {Router}                          route
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: PreviewPdfData,
    protected currentDialog: MatDialogRef<PreviewComponent>,
    protected forms: FormTemplateService,
    protected notifications: NotificationService,
    protected view: ViewService,
    protected dialogFactory: MatDialog,
    protected records: RecordService,
    protected files: FileService,
    protected route: Router,
    protected activities: ActivitiesService,
    protected checklists: ChecklistsService,
  ) {
    this.strModule = this.data.module;
  }

  /**
   * {@inheritdoc}
   */
  ngOnInit() {
    this.isGeneratingPreview = true;

    this.subscriptions.push(
      this.forms.generatePDFUrl$({
        module: this.data.module,
        template: this.data.template.history,
        custom: this.data.custom,
        data: this.data.moduleData,
      }).pipe(
        finalize(() => this.isGeneratingPreview = false),
      )
      .subscribe((url: string | null) => {
        this.previewURL = url;
      })
    );
  }

  /**
   * callback when cancel button is clicked
   *
   * @returns {void}
   */
  closeDialog(): void {
    this.currentDialog.close();
  }

  /**
   * Callback when 'Send Email' button is clicked
   *
   * If module is jobs, opens a 'share in portal' confirmation modal first
   * The email form will open regardless of the confirmation response
   *
   * Else, proceed directly to opening compose email form
   *
   * @returns {void}
   */
  sendEmailInit(): void {
    this.isComposingEmail = true;
    let sendEmailSubscription: Subscription;

    if (this.strModule === 'jobs') {
      sendEmailSubscription = this.notifications.sendConfirmation('share_in_portal_confirm').pipe(
        switchMap((result) => {
          if (result.answer) {
            return this.shareInPortal();
          } else {
            return of({});
          }
        }),
        switchMap(() => this.openEmailDialog()),
      )
      .subscribe(() => {
        this.currentDialog.close();
      });
    } else {
      sendEmailSubscription = this.openEmailDialog().subscribe(() => {
        this.currentDialog.close();
      });
    }

    this.subscriptions.push(sendEmailSubscription);
  }

  /**
   * Callback when download button is clicked and user wants
   * to download the current pdf being previewed
   *
   * If module is jobs, opens a 'share in portal' confirmation modal first
   * Download will occur regardless of the confirmation response
   *
   * Else, proceed directly to downloading the pdf file
   *
   * @returns {void}
   */
  downloadPdfInit(): void {
    this.isDownloading = true;
    let downloadPdfSubscription: Subscription;

    if (this.strModule === 'jobs') {
      downloadPdfSubscription = this.notifications.sendConfirmation('share_in_portal_confirm').pipe(
        switchMap((result) => {
          if (result.answer) {
            return this.shareInPortal();
          } else {
            return of({});
          }
        }),
        switchMap(() => this.downloadPdf()),
      )
      .subscribe();
    } else {
      downloadPdfSubscription = this.downloadPdf().subscribe();
    }

    this.subscriptions.push(downloadPdfSubscription);
  }

  /**
   * Callback when 'Share in Portal' button is clicked, applicable to jobs module only
   * Initializes the share in portal subscription and pushes it to the subscriptions array for proper disposal
   *
   * @returns {void}
   */
  shareInPortalInit(): void {
    const shareInPortalSubscription = this.shareInPortal().subscribe();
    this.subscriptions.push(shareInPortalSubscription);
  }

  /**
   * Downloads the current pdf being previewed
   *
   * @returns {Observable<boolean>}
   */
  downloadPdf(): Observable<boolean> {
    return this.files.downloadFileFromUrl$(this.previewURL, this.pdfFileName).pipe(
      finalize(() => this.isDownloading = false),
      catchError((err) => {
        this.notifications.notifyError('download_failed_due_to_error');
        return throwError(err);
      })
    );
  }

  /**
   * Shares document in portal, also uploads it the files widget
   * Applicable to jobs module only
   *
   * @returns {Observable<Object>}
   */
  shareInPortal(): Observable<Object> {
    this.isSharingToPortal = true;

    return this.files.webToFile$(this.previewURL, { name: this.pdfFileName, type: 'application/pdf' }).pipe(
      switchMap((file) => this.files.upload(file)),
      switchMap((response) => {
        let objFile = this.files.objFile;
        const objFileDetails ={
            name: objFile.name,
            size: objFile.size / 1024,
            type: objFile.type,
            upload_name : response['filename'],
            public: true
        };

        const objRequestData = {
          module_id: this.data.moduleData.id,
          module_field: 'job_id',
          description: '',
          file: objFileDetails,
          module: this.strModule
        };

        const uploadName = objRequestData.file.upload_name;
        objRequestData['upload_name'] = uploadName;
        objRequestData['id'] = uploadName.split('/')[1];

        return this.files.save(objRequestData)
      }),
      tap(data => {
        this.isSharingToPortal = false
        if (typeof data === 'string') {
          this.notifications.sendNotification('header_notification.generic_fail', 'content_notification.fail_s3_upload', 'danger');
        } else {
          this.files.refreshFilesListSource.next(true);
          this.notifications.notifySuccess('share_in_portal_success');
        }
      })
    )
  }

  /**
   * Prepares attachment (if there's any and open the email dialog)
   *
   * @returns {Observable<{}>}
   */
  openEmailDialog(): Observable<{}> {
    const viewRecord = this.view.getRecord();
    const jobId = get(this.data.moduleData, ['job', 'id'], null);

    // if we don't have a parent view we pull the parent record based on the current module data
    return iif(
      () => ! viewRecord,
      this.records.getRecord(this.data.module, this.data.moduleData.id).pipe(
        tap((response) => this.view.setViewResult(response))
      ),
      of(viewRecord)
    )
      .pipe(
        map((parent) => get(parent, 'record_details')),
        switchMap((parent) => {
          // @todo improve me
          if (this.moduleNameForEmail === 'customer_invoices' && jobId) {
            return this.checklists.getJobRelatedRecords(jobId).pipe(
              filter(({ status }) => status === 200),
              map(({ body }) => ({
                parent,
                checklists: [
                  ...get(body, 'asset_type_checklist', []),
                  ...get(body, 'job_checklist', [])
                ],
                response_body: body,
              }))
            );
          }

          return of({
            parent,
            checklists: [],
            response_body: undefined,
          });
        }),
        switchMap(({ parent, checklists, response_body }) => {
          // @todo improve me
          if (checklists.length > 0) {
            return this.notifications.sendConfirmation('attach_checklist_confirmation', 'attach_checklist_confirmation_header')
              .pipe(
                map((confirmation) => ({
                  confirmed: confirmation.answer,
                  parent,
                  response_body,
                }))
              );
          }

          return of({
            confirmed: false,
            parent,
            response_body,
          });
        }),
        switchMap(({ confirmed, parent, response_body }) => {
          // @todo improve me
          if (!! confirmed) {
            return this.dialogFactory.open(ChecklistAttachmentsComponent, {
              width: '70%',
              height: 'auto',
              data: {
                checklists: response_body,
                job_id: jobId,
              }
            }).afterClosed().pipe(
              switchMap((output) => of({
                parent,
                attachments: (! output.is_cancelled) ? output.attachments : [],
              }))
            );
          }

          return of({
            parent,
            attachments: []
          });
        }),
        switchMap(
          ({ parent, attachments }) => this.files.webAsAnAttachment$(this.previewURL, {
            filename: this.pdfFileName,
            filetype: 'application/pdf',
            generatesUniqueFilenameUponUpload: true,
          }).pipe(
            map((attachment) => ({
              attachments: concat(attachments, {
                name: this.pdfFileName,
                size: attachment.size / 1024,
                type: attachment.type,
                upload_name: attachment.filename
              }),
              parent,
            }))
          )
        ),
        switchMap(({ attachments, parent }) =>
          iif(
            () => !! parent.contact_id,
            this.records.getRecord('contacts', parent.contact_id),
            of(undefined) // still needs to call subscribers EMPTY only completes but never emits
          ).pipe(
            map((response) => ({
              parent,
              attachments,
              emails: get(response, ['record_details', 'email_address'], [])
                .filter((email) => email.primary === '1') // only filter primary email
                .map((email) => ({
                  text: parent.contact_text,
                  email_address: email
                }))
            }))
          ),
        ),
        switchMap(({ attachments, emails, parent }) => {

          if (
            this.data.module === 'quotes' &&
            this.data.moduleData['contact'] &&
            this.data.moduleData['contact']['id']
          ) {
            emails = [{
              id: this.data.moduleData['contact']['id'],
              text: this.data.moduleData['contact']['name'],
              email_address: {
                email: this.data.moduleData['contact']['email_addresses'][0],
                primary: "1"
              }
            }];
          }

          let objData = {
            module: this.moduleNameForEmail,
            record_id: (this.data.module === 'quotes') ? parent.id : this.data.moduleData.id,
            is_draft: false,
            email_type: 'pdf',
            record_data: this.data.moduleData,
            quote_data: {
              subject: (this.data.module === 'quotes') ? this.data.moduleData.quote_summary : parent.text,
              attachment: attachments,
              send_to: emails
            },
          };

          return this.dialogFactory.open(EmailComponent, {
            width: '66%',
            height: 'auto',
            data: objData
          }).afterClosed()
        }),
        finalize(() => this.isComposingEmail = false),
        filter((result) => result !== 'cancel' && isObject(result)),
        tap((result) => !! result.isDraft && this.notifications.notifySuccess('draft_saved')),
        tap((result) => ! result.isDraft && this.notifications.notifySuccess('email_sent')),
      );
  }

  /**
   * handles preview complete
   *
   * @returns {void}
   */
  onPreviewComplete(): void {
    this.isLoaded = true;
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * Create the file as pdf and send that to
   * the chat dialog.
   *
   * @returns {void}
   */
  sendViaChat(): void {
    this.isComposingChat = true;

    let subscription = this.files.webAsAnAttachment$(this.previewURL, {
      filename: this.pdfFileName,
      filetype: 'application/pdf'
    })
      .pipe(
        finalize(() => this.isComposingChat = false)
      )
      .subscribe((attachment) => {
        this.currentDialog.close();

        let messageParams = {
          chat_type: 'file',
          upload_name: attachment.filename,
          file_name: this.pdfFileName,
          url: btoa(attachment.url)
        };

        if (this.route.url === '/site_service_summary_report') {
          this.openMessageDialog(messageParams)
        } else {
          let queryParamsString = new HttpParams({ fromObject: messageParams}).toString();

          this.route.navigateByUrl(this.route.url + '?' + queryParamsString);
        }
      });

    this.subscriptions.push(subscription);
  }

  /**
   * Open sms/message dialog
   *
   * @param {MessageParams} messageParams
   */
  protected openMessageDialog(params: MessageParams): void {
    let dialog = this.dialogFactory.open(SmsComponent, {
      data: {
        module: 'sites',
        view_type: 'add',
        record_config: {},
        additional_data: params,
        record_data: this.data.moduleData,
        record_id: this.data.moduleData['id'],
      },
      width: '700px',
      height: 'auto',
      disableClose: true,
      panelClass: ['custom-dialog-container']
    });

    dialog
      .afterClosed()
      .pipe(
        filter( data => data !== undefined && data.mode === 'create' ),
        switchMap( data => this.activities.createActivity(JSON.stringify(data.data))),
        catchError( err => {

          let errorKeys = Object.keys(err.error);
          if (err.error && errorKeys.length) {

            let erroKey = errorKeys[0];
            this.notifications.sendNotification('not_allowed', err['error'][erroKey][0], 'warning');
          }

          return throwError(err);
        })
      )
      .subscribe( () => {
          this.notifications.notifySuccess('success');
      });
  }

  /**
   * @type {string}
   */
  protected get moduleNameForEmail(): string {
    if (this.data.module === 'checklists') {
      return this.view.strRecordModule;
    }
    if (this.data.module === 'quotes') return 'opportunities';

    return this.data.module;
  }

  /**
   * Format pdf filename
   *
   * @returns {string}
   */
  protected get pdfFileName(): string {
    let filename = this.data.filename;

    const objModuleData = this.data.moduleData;

    // document type of a custom template is always null
    const isCustomPdfTemplate = get(this.data, ['template', 'documentType'], null) === null;

    let strSiteName = get(objModuleData, ['site', 'name'], null);
    strSiteName = strSiteName ? `_${strSiteName}` : '';

    switch (this.data.module) {
      case 'customer_invoices':
        if (isCustomPdfTemplate) {
          filename = `${objModuleData.invoice_number}_${this.data.template.name}${strSiteName}`;
        } else {
          let strCustomerName = get(objModuleData, ['customer', 'name'], get(objModuleData, ['customer', 'first_name'], null));
          strCustomerName = strCustomerName ? `${strCustomerName}_` : '';

          filename = `${strCustomerName}${objModuleData.invoice_number}${strSiteName}`;
        }
      break;
      case 'quotes':
        filename = `${objModuleData.quote_number}${strSiteName}`;
      break;
      case 'jobs':
        if (isCustomPdfTemplate) {
          filename = `${objModuleData.job_number}_${this.data.template.name}${strSiteName}`;
        } else {
          filename = `${filename}${strSiteName}`;
        }
      break;
      case 'assets':
        if (isCustomPdfTemplate) {
          filename = `${objModuleData.serial_number}_${this.data.template.name}${strSiteName}`;
        } else {
          filename = `${filename}${strSiteName}`;
        }
        break;
      case 'opportunities':
        if (isCustomPdfTemplate) {
          filename = `${objModuleData.opportunity_number}_${this.data.template.name}${strSiteName}`;
        } else {
          filename = `${filename}_${this.data.template.name}`;
        }
      break;
      default:
        filename = `${filename}_${this.data.template.name}`;
      break;
    }

    return `${filename.substr(0, 85)}.pdf`;
  }

  /**
   * @type {string}
   */
  get previewHeader(): string {
    const key = get(MODULE_PREVIEW_HEADER, this.module);
    const defaultHeader = this.data.previewHeaderLabel || 'preview_form_template';

    // if key was found for the current module we would replace the default
    // preview header value with the value from the data
    if (key) {
      return get(this.data.moduleData, key, defaultHeader);
    }

    return defaultHeader;
  }

  /**
   * @type {boolean}
   */
  get allowGenerateCustomTemplate(): boolean {
    return this.data.allowCustomTemplateGeneration;
  }

  /**
   * @type {string}
   */
  get module(): string {
    return this.data.module;
  }

  /**
   * @type {PreviewPdfData}
   */
  get currentPreviewData(): PreviewPdfData {
    return this.data;
  }

  /**
   * @type {boolean}
   */
  get isProcessing(): boolean {
    return this.isDownloading
      || this.isGeneratingPreview
      || this.isComposingEmail
      || this.isComposingChat
      || this.isSharingToPortal;
  }

  /**
   * @type {boolean}
   */
  get failedToGeneratePreview(): boolean {
    return ! this.previewURL;
  }
}

export interface PreviewPdfData {
  /**
   * contains the current module which requires the pdf to be generated
   *
   * @var {string}
   */
  module: string;

  /**
   * contains the current form template to be parsed to PDF
   *
   * @var {FormTemplate}
   */
  template: FormTemplate;

  /**
   * Current module data
   *
   * @var {LooseModuleData}
   */
   moduleData: LooseModuleData;

   /**
    * File name to be used when downloading a pdf
    *
    * @var {string}
    */
  filename: string;

  /**
   * contains the custom fields that will be rendered in the component
   *
   * @var {LooseCustomField}
   */
  custom?: LooseCustomField;

  /**
   * Allows the current preview to generate a new preview for a custom template
   * (The selectable templates should not show the default templates)
   *
   * @type {boolean|undefined}
   */
  allowCustomTemplateGeneration?: boolean

  /**
   * Sets the dialog preview header title/label
   *
   * @type {string}
   */
  previewHeaderLabel?: string;

  /**
   * additional document to be merged when user downloaded the document
   *
   * @type {string[]}
   */
  additional_document?: string[];
}

interface MessageParams {
  chat_type: string,
  upload_name: string,
  file_name: string,
  url: string
}
