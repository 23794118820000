export class CalendarPagination {

  current_page: number = 0;

  next_page: number | null = null;

  prev_page: number | null = null;

  per_page_size: number = 0;

  total: number = 0;

  constructor(properties?: CalendarPagination) {

    if (properties) {
      this.current_page = properties.current_page || 0;

      this.next_page = properties.next_page || null;

      this.prev_page = properties.prev_page || null;

      this.per_page_size = properties.per_page_size || 10;

      this.total = properties.total || 0;
    }

  }
}

export type CalendarPageDirection = 'first' | 'next' | 'prev' | 'max';
