<ng-container *ngIf="shouldDisplay">
  <button id="documentTemplatesButton" data-testid="display_document_templates" type="button" data-toggle="dropdown" (click)="getTemplates()" class="btn-primary btn dropdown-toggle" [disabled]="isInProgress$ | async">
    <button-icon-loader [isLoading]="isInProgress$ | async" [defaultIcon]="['fas', 'file-pdf']" class="mr-1"></button-icon-loader>{{ 'documents' | translate }}
  </button>

  <!-- list -->
  <div class="dropdown-menu dropdown-content">
    <ng-container>
      <div *ngFor="let template of templates; index as i;">
        <h6 class="dropdown-header pl-3" *ngIf="i === 0 && totalDefaultTemplates > 0">{{ 'default_document_template' | translate }}</h6>
        <h6 class="dropdown-header pl-3" *ngIf="i === 0 && totalDefaultTemplates < 1">{{ 'custom_document_template' | translate }}</h6>
        <a [id]="'documentTemplates' + template.id"
          (click)="previewTemplate(template)"
          [attr.data-testid]="'document_template_' + template.id"
          class="dropdown-item module-template-option pointer">
            {{ template.name | translate }}
        </a>
        <div class="dropdown-divider" *ngIf="(i + 1 === totalDefaultTemplates) && totalCustomTemplates > 0"></div>
        <h6 class="dropdown-header pl-3" *ngIf="(i + 1 === totalDefaultTemplates) && totalCustomTemplates > 0">{{ 'custom_document_template' | translate }}</h6>

      </div>
      <div class="dropdown-item text-center text-muted" id="empty-templates" *ngIf="templates.length < 1 && ! isLoadingTemplates">
        <p class="mb-0">{{ 'no_templates_defined' | translate }}</p>
      </div>

      <div class="text-center" *ngIf="isLoadingTemplates">
        <inline-view-loader [isLoading]="isLoadingTemplates" [hasText]="false"></inline-view-loader>
      </div>
    </ng-container>
  </div>
</ng-container>
