export class StocktakeLine {
    id?: string;
    item_id: string;
    preferred_supplier: string;
    product_code: string;
    product_name: string;
    location: string;
    original_quantity: number = 0;
    quantity: number;


    constructor(properties?: StockLevelInterface) {
        if (properties) {
            this.id = properties.id;
            this.item_id = properties.item_id;
            this.preferred_supplier = properties.preferred_supplier;
            this.product_code = properties.product_code;
            this.product_name = properties.product_name;
            this.location = properties.location;
            this.original_quantity = parseFloat(properties.quantity as any);
            this.quantity = parseFloat(properties.quantity as any);
        }
    }
}
export interface StockLevelInterface {
    id?: string;
    item_id: string;
    preferred_supplier: string;
    product_code: string;
    product_name: string;
    location: string;
    quantity: number;
}