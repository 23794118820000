
<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'shopping-bag']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType == 'edit'" >{{ 'edit_supplier_invoice'| translate }} </span>
        <span *ngIf="strViewType == 'add'">{{ 'create_supplier_invoice'| translate }} </span>
    </h3>
    <ng-container *fcHasPermission="'supplier_invoices' + ((strViewType == 'add') ? ':create' : ':edit')">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary" [disabled]="bSubmitted || !bInvoiceLineLoaded || (whenInProgress$ | async)">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
              <span class="pl-1">{{ 'cancel' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <form-templates-save-and-preview-btn
              [disabled]="isPreviewTemplate || !bInvoiceLineLoaded || bSubmitted || (whenInProgress$ | async)"
              (click)="onSubmitAndPreview()"
              [trigger-event]="previewTemplate.asObservable()">
            </form-templates-save-and-preview-btn>
          </li>
          <li class="list-inline-item">
            <form-real-time-pdf-preview
              [disabled]="isPreviewTemplate || !bInvoiceLineLoaded || bSubmitted || (whenInProgress$ | async)"
              (click)="onPreview()"
              [trigger-event]="realTimePreviewTemplate.asObservable()"
              (onComplete)="whenInProgress$.next(false)">
            </form-real-time-pdf-preview>
          </li>
          <li class="list-inline-item">
            <button (click)="saveSupplierInvoice()" class="btn ml-1 btn-primary" [disabled]="bSubmitted || !bInvoiceLineLoaded || (whenInProgress$ | async)">
              <fa-icon *ngIf="!bSubmitted" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bSubmitted" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>

<hr class="separator"/>

<ng-container *fcHasPermission="'supplier_invoices' + ((strViewType == 'add') ? ':create' : ':edit'); else createSIDisabled">
<mat-dialog-content *ngIf="supplierInvoiceForm" class="mobile-view-dialog">
<app-error-handler onlyFor="edit_form" [isAction]="true" alignment="center">
  <div *ngIf="supplierInvoiceForm.length != 0" class="mt-3">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li *ngFor="let item of supplierInvoiceForm" class="nav-item text-size">
        <a class="nav-link" [ngClass]="{'active': supplierInvoiceForm[0]['label'] == item.label}" id="{{item.label}}-tab" data-toggle="tab" href="#{{item.id}}" role="tab" aria-selected="true" translate>{{ item.label }}</a>
      </li>
    </ul>
    <div class="tab-content">
      <div *ngFor="let item of supplierInvoiceForm" [ngClass]="{'active': supplierInvoiceForm[0]['label'] == item.label, 'show': supplierInvoiceForm[0]['label'] == item.label}" class="tab-pane row p-4" [id]="item.id" role="tabpanel">
        <form [formGroup]="item.groups">
          <div class="row">
            <ng-container *ngFor="let data of item.fields">
              <div *ngIf="data.label != 'status'" class="col-{{data.space}} mb-3">
                <ng-container *ngIf="(( data.label != 'created_by' && data.label != 'modified_by' && data.label != 'updated_at' && data.label != 'created_at') && strViewType == 'add') || strViewType == 'edit'">
                  <app-edit-field *ngIf="data.key != 'line_items' else lineItems" [item]="data" [form]="item.groups" [bSubmitted]="bSubmitted" [strMode]="strViewType" strModule="purchase_orders" [strRecordId]="" [autoSelectResult]="true" (strCurrentValue)="getRelateText(data, $event)"></app-edit-field>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div align="center">
    <fa-icon *ngIf="supplierInvoiceForm.length == 0" [icon]="['fas', 'spinner']" [spin]="true" class="loading-spinner" aria-hidden="true"></fa-icon>
  </div>
</app-error-handler>
</mat-dialog-content>
<!-- Flexible and Milestone Invoicing Field -->
<ng-template #lineItems>
<div class="col-12">
  <div class="row">
    <div class="col-12 p-0">
      <label class="labels">
        {{ 'line_items' | translate }}
      </label>
    </div>
    <div class="col-12 p-0 mb-2">
      <browse-products (objProductSelected)="addLineAttribute($event, true, true)" [bLabor]="false"></browse-products>
    </div>

    <div class="col-12 p-0">
      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-row border">
            <div class="daggable-table-box-header" style="width: 8%"></div>
            <div class="draggable-table-box-header" [ngStyle]="{ width: bDepartmentTracking ? '23%' : '36%'}">
              <b>{{ 'description' | translate }}</b>
            </div>
            <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 13%">
              <b>{{ 'department' | translate }}<required-tag></required-tag></b>
            </div>
            <div class="draggable-table-box-header" style="width: 10%">
              <b>{{ 'quantity' | translate }}</b>
            </div>
            <div class="draggable-table-box-header" style="width: 10%">
              <b>{{ 'unit_cost' | translate }}</b>
            </div>
            <div class="draggable-table-box-header" style="width: 13%">
              <b>{{ 'tax_code' | translate }}<required-tag></required-tag></b>
            </div>
            <div class="draggable-table-box-header" style="width: 13%">
              <b>{{ 'account_code' | translate }}<required-tag></required-tag></b>
            </div>
            <div class="draggable-table-box-header" style="width: 10%">
              <b>{{ 'amount' | translate }}</b>
            </div>
          </div>
        </div>

        <div *ngIf="bInvoiceLineLoaded" class="col-12">
          <div cdkDropList (cdkDropListDropped)="drop($event)" class="row">

            <ng-container *ngFor="let attr of arLineAttributes; index as i">
              <div cdkDrag class="col-12">
                <div class="d-flex flex-row border border-top-0 bg-white">

                  <div class="draggable-table-box center-items pr-2 pl-2" style="width: 2%;">
                    <fa-icon
                      cdkDragHandle
                      style="cursor: grab;"
                      [icon]="['fas', 'grip-vertical']">
                    </fa-icon>
                  </div>

                  <div class="draggable-table-box center-items" style="width: 6%;">

                    <fa-icon *ngIf="arLineAttributes.length > 1" (click)="removeAttribute(attr)"
                      class="pl-1 pr-1 pb-2 pt-2 text-danger pointer"
                      [icon]="['fal', 'minus-circle']">
                    </fa-icon>

                    <a *ngIf="attr['supplier_invoice']['item_id']"
                      href="/#/items/{{ attr['supplier_invoice']['item_id'] }}"
                      class="pl-1 pr-1 pb-2 pt-2"
                      target="_blank">
                      <fa-icon
                        matTooltipClass="tooltip-space"
                        matTooltip="
                        {{ 'click_to_view' | translate }}&#13;&#13;
                        {{ 'product' | translate }}: {{ attr['supplier_invoice']['item_name'] }}&#13;
                        {{ 'code' | translate }}: {{ attr['supplier_invoice']['item_code'] | placeholdWithString }}&#13;
                        {{ 'current_stock_level' | translate }}: {{ attr['supplier_invoice']['current_stock_level'] }}"
                        class="text-primary"
                        [icon]="['fas', 'info-circle']">
                      </fa-icon>
                    </a>
                  </div>
                  <div class="draggable-table-box" [ngStyle]="{ width: bDepartmentTracking ? '23%' : '36%'}">
                    <textarea
                      class="form-control invoice-desc-{{i}}" style="font-size: 12px;"
                      placeholder="{{ 'description' | translate }}"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="attr['supplier_invoice']['description']"
                      [ngClass]="{'is-invalid': (validateField(attr['supplier_invoice']['description'])) && isTouched}"
                      (focus)="increaseRow(i)"
                      (focusout)="decreaseRow(i)"
                      (ngModelChange)="markAsDirty()"
                      #invoiceLineDesc>
                  </textarea>
                  </div>
                  <div *ngIf="bDepartmentTracking" class="draggable-table-box" style="width: 13%">
                    <ng-select
                      class="h-40"
                      [items]="attr['department_obv'] | async"
                      [addTag]="addTag"
                      [typeahead]="attr['department_typehead']"
                      [loading]="attr['department_loader']"
                      [clearable]="true"
                      (change)="selectDepartment($event, attr)"
                      [ngModelOptions]="{standalone: true}"
                      bindLabel="text"
                      [bindValue]
                      placeholder="{{ 'select_department' | translate }}"
                      [(ngModel)]="attr['department']"
                      [id]="attr['type'] + 'department'"
                      [name]="attr['type'] + 'department'"
                      (open)="initRelateRecords('arLineAttributes', 'departments', 'department', '', objCacheData['departments'], i)"
                      [ngClass]="{'is-invalid': validateField(attr['supplier_invoice']['department_id']) && isTouched}">
                    </ng-select>
                  </div>
                  <div class="draggable-table-box" style="width: 10%">
                    <fieldmagic-decimal-input
                      (change)="quantityChange($event, attr)"
                      customClass="form-control font-size-12 input-height-40"
                      placeholder="{{ 'quantity' | translate }}"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="attr['supplier_invoice']['quantity']">
                    </fieldmagic-decimal-input>
                  </div>
                  <div class="draggable-table-box" style="width: 10%">
                    <fc-currency-input
                      [places]="4"
                      id="unit_cost_{{ i }}"
                      [(ngModel)]="attr['supplier_invoice']['unit_cost']"
                      [ngModelOptions]="{standalone: true}"
                      [customClass]="'form-control font-size-12 input-height-40'"
                      (change)="unitCostChange($event, attr)"
                      decimalPipe>
                    </fc-currency-input>
                  </div>
                  <div class="draggable-table-box" style="width: 13%">
                    <ng-select
                      class="h-40"
                      [items]="attr['taxcode_obv'] | async"
                      [typeahead]="attr['taxcode_typehead']"
                      [loading]="attr['taxcode_loader']"
                      [clearable]="true"
                      (change)="selectATaxcode($event, attr)"
                      [ngModelOptions]="{standalone: true}"
                      bindLabel="text"
                      [bindValue]
                      placeholder="{{ 'tax_code' | translate }}"
                      [(ngModel)]="attr['tax']"
                      [id]="attr['type'] + 'tax_code'"
                      [name]="attr['type'] + 'tax_code'"
                      (open)="initRelateRecords('arLineAttributes', 'tax_codes', 'taxcode', arTaxCodeFilter, objCacheData['tax_codes'], i)"
                      [ngClass]="{'is-invalid': (validateField(attr['supplier_invoice']['tax_code_id']) || attr['tax'] == null) && isTouched}">
                    </ng-select>
                  </div>
                  <div class="draggable-table-box" style="width: 13%">
                    <ng-select
                      class="h-40"
                      [items]="attr['account_code_obv'] | async"
                      [typeahead]="attr['account_code_typehead']"
                      [loading]="attr['account_code_loader']"
                      [clearable]="true"
                      (change)="selectAccountCode($event, attr)"
                      [ngModelOptions]="{standalone: true}"
                      bindLabel="name"
                      [bindValue]
                      placeholder="{{ 'account_code' | translate }}"
                      [(ngModel)]="attr['account_code']"
                      [id]="attr['type'] + 'account_code'"
                      [name]="attr['type'] + 'account_code'"
                      (open)="initRelateRecords('arLineAttributes', 'account_codes', 'account_code', arAccountCodeFilter, objCacheData['account_codes'], i)"
                      [ngClass]="{'is-invalid': (validateField(attr['supplier_invoice']['account_code_id']) || attr['account_code'] == null) && isTouched}">
                    </ng-select>
                  </div>
                  <div class="draggable-table-box" style="width: 10%">
                    <fc-currency-input
                      customClass="form-control font-size-12 input-height-40"
                      [(ngModel)]="attr['amount']"
                      (change)="onLineTotalChange($event.target, {attr: attr, index: i})"
                    ></fc-currency-input>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="col-12 text-center" *ngIf="arLineAttributes.length < 1">
              <div class="draggable-table-box border border-top-0 p-3">
                <span>{{ 'no_invoice_line' | translate }}</span>
              </div>
            </div>

            <div *ngIf="!bInvoiceLineLoaded" class="col-12 text-center" style="font-size: 16px;">
              <div class="draggable-table-box border border-top-0">
                <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div *ngIf="bInvoiceLineLoaded" class="row">
        <div class="col-12">
          <div class="d-flex flex-row border border-top-0">
            <div class="draggable-table-box right-items" style="width: 90%;">
              <span class="p-2">{{ 'total_ex_tax' | translate }}</span>
            </div>
            <div class="draggable-table-box left-items" style="width: 10%;">
              <span class="ml-2">{{ computeInvoiceLineExcludedTax() | currency: {decimal_places: 2} }}</span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex flex-row border border-top-0">
            <div class="draggable-table-box right-items" style="width: 90%;">
              <span class="p-2  float-right">{{ 'tax' | translate }}</span>
            </div>
            <div class="draggable-table-box left-items" style="width: 10%;">
              <span class="ml-2">{{ computeInvoiceLineTax() | currency: {decimal_places: 2} }}</span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex flex-row border border-top-0">
            <div class="draggable-table-box right-items" style="width: 90%;">
              <span class="p-2 float-right">{{ 'tax_adjustment_amount' | translate }}</span>
            </div>
            <div class="draggable-table-box center-items" style="width: 10%;">
              <fc-currency-input
                step="0.01"
                [withControls]="true"
                customClass="form-control font-size-12 input-height-40"
                [(ngModel)]="totalTaxAdjustment"
                (change)="onTaxAdjustment()">
              </fc-currency-input>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex flex-row border border-top-0">
            <div class="draggable-table-box right-items" style="width: 90%;">
              <span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span>
            </div>
            <div class="draggable-table-box center-items" style="width: 10%;">
              <fc-currency-input
                id="invoiceLineIncludedTaxField"
                [(ngModel)]="invoiceLineIncludedTax"
                customClass="form-control input-height-40 font-size-12">
              </fc-currency-input>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
</ng-template>
</ng-container>

<ng-template #createSIDisabled>
  <span id="btnCreateSIDisabled" class="disabled"
    matTooltip="{{ 'forbidden_action_error' | translate }}">
    {{ 'forbidden_action_error' | translate }}
  </span>
</ng-template>
