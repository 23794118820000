
<div>
  <h4 translate>{{ (bUpdate) ? 'update_filter' : 'save_filter' }}<fa-icon [icon]="['fas', 'times']" (click)="dialogClose()" class="float-right pointer"></fa-icon></h4>
  <hr>
  <mat-dialog-content>
    <div class="form-group mb-3">
      <label for="filter_name">{{ 'filter_name' | translate }}</label>
      <fc-text-input
        id="filter_name"
        [(ngModel)]="strFilterName"
        [maxlength]="'128'"
        required
      ></fc-text-input>
    </div>
    <div class="form-group mb-12">
      <span>{{ 'share_filter_with_all_users' | translate }}: </span><mat-slide-toggle [(ngModel)]="arFilter['share']"></mat-slide-toggle>
    </div>
    <span *ngFor="let filter of arFilterCommon" [ngSwitch]="filter.id">
      <b>{{ filter.id | translate }}</b>:
      <span *ngSwitchCase="'order_by'"> {{ filter.value.id | translate }}, {{ filter.value.sort | translate }} </span>
      <span *ngSwitchDefault>

        <ul *ngIf="checkType(filter.value) == 'array'">
          <li *ngFor="let arItem of filter.value">
            {{ arItem | translate }}
          </li>
        </ul>

        <span *ngIf="checkType(filter.value) == 'string'">
          {{ filter.value | translate }}
        </span>

      </span>
      <br />
    </span>
    <hr>
    <div class="form-group float-right">
      <button class="btn btn-primary" (click)="saveFilter()"><fa-icon [icon]="['fas', 'save']" class="pr-2"></fa-icon><span translate> {{ 'save' }}</span></button>
    </div>
  </mat-dialog-content>
</div>
