<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <fa-icon [icon]="['fas', 'file']" class="pr-2" ></fa-icon>{{ 'select_files_to_share' | translate }}
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="onSkip()" class="btn ml-1 btn-primary" [disabled]="isLoading$ | async">
            <fa-icon [icon]="['fas', 'fast-forward']"></fa-icon>
            <span class="pl-1">{{ 'skip' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button (click)="onShare()" class="btn ml-1 btn-primary" [disabled]="(isLoading$ | async) || (isSaving$ | async)">
            <button-icon-loader [defaultIcon]="['fas', 'share']" [isLoading]="isSaving$ | async">
              <span class="pl-1">{{ 'share' | translate }}</span>
            </button-icon-loader>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>

<hr>

<mat-dialog-content>
  <div class="alert alert-info">
    <small>{{ 'share_file_info' | translate }}</small>
  </div>

  <ng-container *ngIf="(files$ | async) && ! (isLoading$ | async); else loader">
    <ng-container *ngIf="files$ | async as files">
      <table class="table mb-0">
        <colgroup>
          <col class="col-1">
          <col class="col-11">
        </colgroup>
        <tr>
          <th><input type="checkbox" (change)="onSelectAll($event.target, files)"></th>
          <th style="font-size: 12px;">{{ 'file_name' | translate }}</th>
        </tr>
        <ng-container [formGroup]="form">
          <tr *ngFor="let file of files; let pos = index">
            <td><input type="checkbox" [formControlName]="file.id" [value]="file.id"/></td>
            <td>{{ file.name }}</td>
          </tr>
        </ng-container>
        <tr>
          <td colspan="2">
            <div class="row">
              <div class="col-12 pl-0 pr-0">
                <ul *ngIf="pagination$ | async as pagination" class="pagination pull-right">
                  <li class="page-item" [ngClass]="{'disabled': ! pagination.prev_page }">
                    <a (click)="onPaginate(pagination.prev_page)" class="page-link">
                      <fa-icon [icon]="['far', 'chevron-left']"></fa-icon>
                    </a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled': ! pagination.next_page }">
                    <a (click)="onPaginate(pagination.next_page)"  class="page-link">
                      <fa-icon [icon]="['far', 'chevron-right']"></fa-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </ng-container>
  </ng-container>
</mat-dialog-content>

<ng-template #loader>
  <div class="row">
    <div class="col-12 text-center" style="font-size: 14px;">
      <inline-view-loader [isLoading]="true"></inline-view-loader>
    </div>
  </div>
</ng-template>