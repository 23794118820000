import { Component, OnInit, Inject, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Form, FormControl, FormGroup } from '@angular/forms';
import { FormService } from '../../../../../services/form.service';
import { Subject, of, concat, Observable, BehaviorSubject } from 'rxjs';
import { RecordService } from '../../../../../services/record.service';
import { Select } from '../../../../../objects/select';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  finalize
} from 'rxjs/operators';
import { NotificationService } from '../../../../../services/notification.service';
import { CustomTranslateService } from '../../../../../services/custom-translate.service';
import { ArrService } from '../../../../../services/helpers/arr.service';
import { ListingService } from '../../../../../services/listing.service';
import { RelateIds } from '../../../../../lists/relate-ids';
import * as _moment from 'moment';
import { StatusCode } from '../../../../../lists/status-code';
import { RecurringJobLineItem } from '../../../../../objects/recurring-job-line-item';
import { isValidWorkOrderLines, OnWorkOrderLinesUpdate, WorkOrderDefaultPricebook, WorkOrderItemsComponentConfig, WorkOrderLine, WorkOrderLineData } from '../../../view/jobs/work-order-items/work-order-items.component';
import { get, isEmpty, isNil, trim, map as _map, first } from 'lodash';
import { LooseObject } from '../../../../../objects/loose-object';
import { ClientStoreService } from '../../../../../services/client-store.service';
import { Router } from '@angular/router';
import { MaterialLineData, OnMaterialsUpdate } from '../../../../../module/jobs/materials/log-materials/components/material-lines/material-lines.component';
import { blank, filled, isIn, transform, whenFilled } from '../../../../utils/common';
import { MaterialLineItem } from '../../../../../module/jobs/materials/log-materials/material-line-item';
import { TaxCode } from '../../../../../objects/tax-code';

@Component({
  selector: 'app-edit-recurring-jobs',
  templateUrl: './edit-recurring-jobs.component.html',
  styleUrls: ['./edit-recurring-jobs.component.scss']
})
export class EditRecurringJobsComponent implements OnInit {
  public recurringJobForm: any = [];
  public strRecordId: string = (this.data.record_id != undefined && this.data.record_id != null && this.data.record_id != '') ? this.data.record_id : null;
  public strViewType: string = this.data.view_type;
  public strRecordModule: string = this.data.module;
  public arPeriodOptions: any = this.data.period_options;
  public bSubmitted: boolean = false;
  public bShowErrors: boolean = false;
  public hasError: boolean = false;

  public relateAssetGroupData: any = [];
  public relateChecklistData: any = [];
  public relateRequiredSkillData: any = [];
  public bHasAssetType: boolean = false;
  public bHasChanged: boolean = false;
  public arCustomColumn = [];
  public strAssetGroupId: any = [];
  public strChecklistId: any = [];
  public strRequiredSkillId: any = [];
  public arRecurringJobsData: any = [];
  public arRelatedData: [] = [];
  public arReadOnlyId: any = [];
  public arRelateEmptyId: any = [];
  public arRequiredField: any = [];

  public strAssetTypeId: string = null;
  public arLineAttributes: object[] = [];
  public objChecklistFilter: {} = {};
  public objModuleConfig: {} = {};

  public objRecurringJobRecord: object = {};

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  @HostListener('window:resize', ['$event']) sizeChange(event) {
    this.setDialogStyle();
  }

  /**
   * Observable to emit when disabling the submit button
   *
   * @type {BehaviorSubject<boolean>}
   */
  disabledSaveButton$ = new BehaviorSubject(false);

  /**
   * If department tracking is enabled.
   *
   * @var {boolean}
   */
  public bDepartmentTracking: boolean = false;

  readonly workOrderComponentConfig$ = new BehaviorSubject<WorkOrderItemsComponentConfig|null>(null);

  readonly initialMaterials$ = new BehaviorSubject<MaterialLineData[]>([]);

  readonly initialWorkOrder$ = new BehaviorSubject<WorkOrderLineData[]>([]);

  readonly wasValidated$ = new BehaviorSubject<boolean>(false);

  readonly defaultTaxCode$ = new BehaviorSubject<TaxCode|null>(null);

  readonly defaultPricebook$ = new BehaviorSubject<WorkOrderDefaultPricebook|null>(null);

  _workOrderLines: WorkOrderLine[] = [];

  _materialLines: MaterialLineItem[] = [];

  _pricebokId: string;

  constructor(
    public dialogRef: MatDialogRef<EditRecurringJobsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formService: FormService,
    public recordService: RecordService,
    public notifService: NotificationService,
    public customTranslate: CustomTranslateService,
    public arrService: ArrService,
    public elemRef: ElementRef,
    public listingService: ListingService,
    private renderer: Renderer2,
    private client: ClientStoreService,
    private router: Router
  ) { }

  ngOnInit() {

    this.bDepartmentTracking = this.client.isDepartmentTracking();

    if (this.data['recurring_job_id'] != undefined && this.data['recurring_job_id'] != '' && this.data['recurring_job_id'] != null) {
        this.recordService.getRecord('recurring_jobs', this.data['recurring_job_id'], true, {}, 'edit_form').subscribe( response => {
          this.objRecurringJobRecord = response;
          // Get updated record data of currently updating.
          this.data['recurring_job'] = (response['record_details'] != null && Object.keys(response['record_details']).length > 0) ? response['record_details'] : [];
          this.arRelatedData = response['related_data'];
          this.objModuleConfig['record_details'] = response['record_details'];
          this.objModuleConfig['record_view'] = response['record_view'];
          this.objModuleConfig['used_fields'] = response['used_fields'];
          this.defaultTaxCode$.next(get(response, 'related_data.default_tax_code_sale'));
          this.defaultPricebook$.next(transform(get(response, 'record_details.pricebook_id'), {
            transformer: (id) => ({
              id: id,
              text: get(response, 'record_details.pricebook_text'),
            })
          }));
          this.setRecurringJob();
        });
    } else {
      this.recordService.getRecordConfig('recurring_jobs', null, true).first().subscribe( response => {
        this.objRecurringJobRecord = response;
        this.arRelatedData = response['related_data'];
        this.objModuleConfig = response;
        this.defaultTaxCode$.next(get(response, 'related_data.default_tax_code_sale'));
        this.setRecurringJob();
      });
    }
    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
    this.setDialogStyle();
  }

  /**
   * Set recurring job form
   */
  setRecurringJob() {
    if (this.strViewType === 'edit') {
      this.strAssetTypeId = this.data['recurring_job']['asset_type_id'];
      this.objChecklistFilter = this.strAssetTypeId ? {'checklists.asset_type_id': this.strAssetTypeId, 'is_checklist_enabled': true} : null;

      this.initField();

      this.recurringJobForm.map( form => {
        let objField = Object.keys(form['groups']['controls']);
        objField.forEach( strField => {
          let indexField = this.getFieldIndex(strField, form);
          if (indexField > -1) {
            if (form['fields'][indexField]['controlType'] == 'relate') {
              let textField = strField.replace('_id', '_text');
              let strText = this.data['recurring_job'][textField];
              form['fields'][indexField]['default_value'] = this.data['recurring_job'][strField];
              form['fields'][indexField]['default_text'] = strText;
            }
            // If mode is edit. Set job field as readonly. due to unable to change the job when created
            if (strField == 'job_id') {
              form['fields'][indexField]['readonly'] = true;
            }

            if (strField === 'amount' && this.data['recurring_job']['billable'] === false) {
              form['fields'][indexField]['is_hidden'] = true;
            }
          }
          // If field exist. Set field value
          if (this.data['recurring_job'][strField] != undefined) {
            this.fieldPatchValue(strField, this.data['recurring_job'][strField]);
          }
        });
      });

      if (this.arrService.keyFallsBackTo(this.data['recurring_job'], 'line_items', null) !== null && this.data['recurring_job']['line_items'].length > 0) {
        this.data['recurring_job']['line_items'].forEach( line_item => {
          this.addLineAttribute(line_item);
        });
      } else {
        this.addLineAttribute();
      }

      if (filled(get(this.data, 'recurring_job.work_order_items', []))) {
        this.initialWorkOrder$.next(_map(
          get(this.data, 'recurring_job.work_order_items', []),
          (line) => ({
            labor: get(line, 'labor', false),
            unit_cost: get(line, 'unit_cost', 0),
            hourly_cost: get(line, 'hourly_cost', 0),
            work_order_reference: get(line, 'work_order_reference'),
            unit_price: get(line, 'unit_price', 0),
            discounted_price: get(line, 'discounted_price', 0),
            description: get(line, 'description'),
            is_created: get(line, 'is_created', false),
            item_id: get(line, 'item_id'),
            item_name: get(line, 'item_name'),
            item_code: get(line, 'item_code'),
            line_item: get(line, 'line_item', 0),
            markup: get(line, 'markup', 0),
            quantity: get(line, 'quantity', 0),
            tax_code: get(line, 'tax_code'),
            tax_rate: get(line, 'tax_rate', 0),
            tax_code_id: get(line, 'tax_code_id'),
            tax_code_name: get(line, 'tax_code_name'),
            current_stock_level: get(line, 'current_stock_level', 0),
          })
        ));
      }

      if (filled(get(this.data, 'recurring_job.material_line_items', []))) {
        this.initialMaterials$.next(_map(
          get(this.data, 'recurring_job.material_line_items', []),
          (line) => ({
            id: get(line, 'id'),
            quantity: get(line, 'quantity', 0),
            item_id: get(line, 'item_id'),
            item_name: get(first(get(line, 'item')), 'name'),
            item_code: get(first(get(line, 'item')), 'code'),
            work_order_reference: get(line, 'work_order_reference'),
            once_off_product_name: get(line, 'product'),
            unit_price: get(line, 'unit_price'),
            unit_cost: get(line, 'unit_cost'),
            markup: get(line, 'markup'),
          })
        ));
      }
    } else {
      this.recordService.getRecordBasedOnParent(!isNil(this.strRecordId) && this.router.url !== '/recurring_jobs').subscribe(response => {
        // FC-4062: if in list view (app.fieldmagic.co/#/recurring_jobs) and the 'Add' button is clicked, should not preselect a customer
        let arRecordData = response['record_details']
        let strCustomerId = null;
        let strSiteId = null;

        this.initField();

        let customerFormIndex = this.getFormFieldIndex('customer_id');
        let siteFormIndex = this.getFormFieldIndex('site_id');
        let customerIndexField = this.getFieldIndex('customer_id', this.recurringJobForm[customerFormIndex]);
        let siteIndexField = this.getFieldIndex('site_id', this.recurringJobForm[siteFormIndex]);

        if (this.data['customer_id'] && this.data['customer_text']) {
          arRecordData['customer_id'] = this.data['customer_id'];
          arRecordData['customer_text'] = this.data['customer_text'];
        }

        if (arRecordData != undefined) {
          // Set Customer and Site id
          strCustomerId = (this.strRecordModule == 'customers') ? arRecordData['id'] : arRecordData['customer_id'];
          strSiteId = (this.strRecordModule == 'sites') ? arRecordData['id'] : null;
          this.recurringJobForm[customerFormIndex]['fields'][customerIndexField]['default_value'] = strCustomerId;
          this.recurringJobForm[customerFormIndex]['fields'][customerIndexField]['default_text'] = arRecordData['customer_text'];

          this.recurringJobForm[siteFormIndex]['fields'][siteIndexField]['default_value'] = strSiteId;
          this.recurringJobForm[siteFormIndex]['fields'][siteIndexField]['default_text'] = arRecordData['site_text'];
        }

        let amountFormIndex = this.getFormFieldIndex('amount');
        let amountIndexField = this.getFieldIndex('amount', this.recurringJobForm[amountFormIndex]);
        this.recurringJobForm[amountFormIndex]['fields'][amountIndexField]['is_hidden'] = true;

        this.fieldPatchValue('customer_id', strCustomerId);
        this.fieldPatchValue('site_id', strSiteId);
        this.addLineAttribute();
      });
    }

    this._listenToChanges();
  }

  /**
   * On changes of form
   */
  private _listenToChanges(): void {
    let intIndexOfBillable: number = this.getFormFieldIndex('billable');
    let intIndexOfJobTemplateId: number = this.getFormFieldIndex('job_template_id');

    this.recurringJobForm[intIndexOfBillable]['groups'].get('billable').valueChanges.subscribe( value => {
      let amountFormIndex = this.getFormFieldIndex('amount');
      let amountIndexField = this.getFieldIndex('amount', this.recurringJobForm[amountFormIndex]);

      if (value) {
        this.recurringJobForm[amountFormIndex]['fields'][amountIndexField]['is_hidden'] = false;
      } else {
        this.recurringJobForm[amountFormIndex]['fields'][amountIndexField]['is_hidden'] = true;
      }
    });

    this.recurringJobForm[intIndexOfJobTemplateId]['groups'].get('job_template_id').valueChanges.subscribe(strValue => {
      if (strValue) {
        this.recordService.getRecordRelate('job_templates', '', '', false, {'job_templates.id': strValue}, 10, false).subscribe(arResult => {
          let arValue = (arResult[0] != undefined) ? arResult[0] : [];
          this.fieldPatchValue('due_date', arValue['due_date']);
          this.fieldPatchValue('job_summary', arValue['job_summary']);
          this.fieldPatchValue('priority', arValue['priority']);
          this.fieldPatchValue('internal_notes', arValue['internal_notes']);
          this.fieldPatchValue('billable', arValue['billable']);
          this.arLineAttributes = [];

          if (filled(get(arValue, 'material_line_items'))) {
            this.initialMaterials$.next(_map(
              get(arValue, 'material_line_items', []),
              (line) => ({
                quantity: get(line, 'quantity', 0),
                item_id: get(line, 'item_id'),
                item_name: get(first(get(line, 'item')), 'name'),
                item_code: get(first(get(line, 'item')), 'code'),
                work_order_reference: get(line, 'work_order_reference'),
                once_off_product_name: get(line, 'product'),
                unit_price: get(line, 'unit_price'),
                unit_cost: get(line, 'unit_cost'),
                markup: get(line, 'markup'),
              })
            ));
          }

          if (filled(get(arValue, 'work_order_line_items'))) {
            this.initialWorkOrder$.next(_map(
              get(arValue, 'work_order_line_items', []),
              (line) => ({
                labor: get(line, 'labor', false),
                unit_cost: get(line, 'unit_cost', 0),
                hourly_cost: get(line, 'hourly_cost', 0),
                work_order_reference: get(line, 'work_order_reference'),
                unit_price: get(line, 'unit_price', 0),
                discounted_price: get(line, 'discounted_price', 0),
                description: get(line, 'description'),
                is_created: get(line, 'is_created', false),
                item_id: get(line, 'item_id'),
                item_name: get(line, 'item_name'),
                item_code: get(line, 'item_code'),
                line_item: get(line, 'line_item', 0),
                markup: get(line, 'markup', 0),
                quantity: get(line, 'quantity', 0),
                tax_code: get(line, 'tax_code'),
                tax_rate: get(line, 'tax_rate', 0),
                tax_code_id: get(line, 'tax_code_id'),
                tax_code_name: get(line, 'tax_code_name'),
                current_stock_level: get(line, 'current_stock_level', 0),
              })
            ));
          }

          if (filled(get(arValue, 'pricebook_id'))) {
            this.defaultPricebook$.next({
              id: get(arValue, 'pricebook_id'),
              text: get(arValue, 'pricebook_text'),
            });
          }

          if (arValue['task_line_items'].length > 0) {
            arValue['task_line_items'].forEach(arLineItem => {
              this.addLineAttribute(arLineItem);
            });
          }
        });
      }
    });
  }

  /**
   * Initialize form
   */
  initField() {

    // To set data of custom attributes as default value
    if (this.objModuleConfig['record_details']['custom_attributes'] != undefined && this.objModuleConfig['record_details']['custom_attributes'] != null && this.objModuleConfig['record_details']['custom_attributes'] != '') {
      let customAttributeKeys = Object.keys(this.objModuleConfig['record_details']['custom_attributes']);
      customAttributeKeys.forEach( strKey => {
        // Append each custom attributes to record details
        this.objModuleConfig['record_details'][strKey] = this.objModuleConfig['record_details']['custom_attributes'][strKey];
      });
    }

    let arFormData = this.formService.formData(this.objModuleConfig['record_view'], this.objModuleConfig['used_fields'], this.objModuleConfig['record_details']);

    this.recurringJobForm  = arFormData.map(
      formItems => {
        formItems['id'] = formItems['label'].toLowerCase().replace(/ /g,'_');
        formItems['groups'] = this.formService.toFormGroup(formItems['fields'])
        return formItems;
      }
    );
  }

  /**
   * Here we can manipulate the relate filter before requesting it to api
   *
   * @param relate_module
   * @param term
   * @param id
   * @param email
   * @param filter
   *
   * @retuns Observable<Select[]>
   */
  beforeInitRelateData(relateModule, term, id, email, filter): Observable<Select[]> {

    let strCustomerId = this.recurringJobForm.controls['customer_id'].value;
    filter = (filter) ? filter : {};
    if (relateModule === 'sites' && (term === null || term === '' ) && strCustomerId) {
      filter['customer_id'] = strCustomerId;
    }

    return this.recordService.getRecordRelate(relateModule, term, id, email, filter)
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if (this.recurringJobForm.dirty) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Save Recurring job
   */
  saveRecurringJob() {
    this.bShowErrors = true;
    this.hasError = false;

    let objRecurringJobData = [];
    this.arReadOnlyId = [];
    this.arRelateEmptyId = [];
    this.arRequiredField = [];

    this.recurringJobForm.map(
      form => {
        Object.keys(form['groups'].value).forEach(
          item => {
            let strFieldMetadata = form['fields'].find( x => x.key == item);
            if (strFieldMetadata != undefined) {
              if (strFieldMetadata.readonly) this.arReadOnlyId.push(item);
              if (strFieldMetadata.controlType == 'relate') this.arRelateEmptyId.push(item);
              if (strFieldMetadata.required) this.arRequiredField.push(item);
            }
        })
    });

    //Loops the values.
    this.recurringJobForm.forEach(
      item => {
        //Merge all form group values into a single object.
        objRecurringJobData = {...objRecurringJobData, ...item['groups'].getRawValue()};
    });

    objRecurringJobData['billable'] = (objRecurringJobData['billable'] != '' && objRecurringJobData['billable'] != null && objRecurringJobData['billable'] != false) ? true : false;
    objRecurringJobData['status'] = (objRecurringJobData['status'] != '' && objRecurringJobData['status'] != null && objRecurringJobData['status'] != false) ? true : false;
    objRecurringJobData['automatically_create'] = (objRecurringJobData['automatically_create'] != '' && objRecurringJobData['automatically_create'] != null && objRecurringJobData['automatically_create'] != false) ? true : false;
    objRecurringJobData['amount'] = (objRecurringJobData['amount'] != '' && objRecurringJobData['amount'] != null) ? objRecurringJobData['amount'] : '0.00';

    if (this.strRecordId != null) {
      let strModuleId = RelateIds[this.strRecordModule];
      objRecurringJobData[strModuleId] = this.strRecordId;
    }

    if (objRecurringJobData['billable'] == false) {
      objRecurringJobData['amount'] = '0.00';
    }

    objRecurringJobData['expiry_date'] = (objRecurringJobData['expiry_date'] == undefined || objRecurringJobData['expiry_date'] == '' || objRecurringJobData['expiry_date'] == 'Invalid date') ? null : objRecurringJobData['expiry_date'];

    objRecurringJobData['pricebook_id'] = this._pricebokId;
    objRecurringJobData['work_order_items'] = _map(this._workOrderLines, (line) => ({
      ... line.encode(),
      tax_code: get(first(get(line, 'tax_code')), 'code'),
    }));

    this.validateRequest(objRecurringJobData);

    if (!this.hasError && this.isValidChecklistField()) {
      let arLines = [];
      // We need to filter those line items without any value in the field
      let filteredLineAttributes = this.arLineAttributes.filter(arLineItem =>
        this.arrService.keyFallsBackTo(arLineItem['data'], 'asset_type_id', null) !== null ||
        this.arrService.keyFallsBackTo(arLineItem['data'], 'asset_group_id', null) !== null ||
        this.arrService.keyFallsBackTo(arLineItem['data'], 'checklist_ids', null) !== null ||
        this.arrService.keyFallsBackTo(arLineItem['data'], 'department_id', null) !== null ||
        this.arrService.keyFallsBackTo(arLineItem['data'], 'task_description', null) !== null
      );

      let arFilteredMaterialLineAttributes = this._materialLines.filter(objLineItem =>
        this.arrService.keyFallsBackTo(objLineItem.product, 'value', null) !== null,
      );

      filteredLineAttributes.forEach(arLineItem => {
        arLines.push(arLineItem['data']);
      });

      objRecurringJobData['line_items'] = arLines;

      objRecurringJobData['material_line_items'] = arFilteredMaterialLineAttributes.map(item => {
        return item.getMaterialData('');
      });

      this.processSave(objRecurringJobData);
    } else {
      this.notifService.notifyError('required_notice', {
        header: 'not_allowed',
      });
    }
  }

  /**
   * Process to create or update recurring job
   * @param objRecurringJobData
   */
  processSave(objRecurringJobData) {
    if (this.strViewType == 'edit' && this.data['recurring_job'] != undefined) {
      this.compareOldData(objRecurringJobData);

      if (this.bHasChanged) {
        // Save customer invoice
        this.recordService.saveRecord('recurring_jobs', objRecurringJobData, this.data['recurring_job']['id']).pipe(
          finalize(() => this.bSubmitted = false)
        ).subscribe(res => {
          if (res.status == 200) {
            this.dialogRef.close(res.body);
          } else if (res.status === StatusCode.kResponseAccepted) {
            this.notifService.promptError(res.body.error);
          } else {
            this.dialogRef.close('fail');
          }
        });
      } else {
        this.notifService.notifyWarning('no_changes_made', {
          header: 'not_allowed',
        });

        this.bSubmitted = false;
        this.dialogRef.close('fail');
      }

    } else {
      // Save customer invoice
      this.recordService.saveRecord('recurring_jobs', objRecurringJobData, '').pipe(
        finalize(() => this.bSubmitted = false)
      ).subscribe(res => {
        if (res.status === StatusCode.kResponseAccepted) {
          this.notifService.promptError(res.body.error);
        } else if (res.body.id != undefined) {
          this.dialogRef.close(res.body);
        }
      });
    }
  }

  /**
   * Compare the old data to the requested data
   * @param objRequestData - Requested data
   */
  compareOldData(objRequestData) {
    let objRequestFields = Object.keys(objRequestData);
    let arRelateField = ['department_id', 'site_id', 'customer_id', 'asset_type_id', 'asset_group_id'];
    let originalExpiryDate = '';

    objRequestFields.forEach( strField => {
      if (arRelateField.indexOf(strField) > -1) {
        if (this.data['recurring_job'][strField] == '') {
          this.data['recurring_job'][strField] = null;
        }
      }

      if (strField == 'expiry_date') {
        if (this.data['recurring_job'][strField] == '') {
          this.data['recurring_job'][strField] = null;
        } else {
          originalExpiryDate = objRequestData[strField];
        }
      }

      if (typeof this.data['recurring_job'][strField] == 'string' && typeof objRequestData[strField] == 'string') {
        if (this.data['recurring_job'][strField] != objRequestData[strField]) {
          if (strField == 'expiry_date') {
            objRequestData[strField] = originalExpiryDate;
          }
          this.bHasChanged = true;
        }
      } else {
        if (JSON.stringify(this.data['recurring_job'][strField]) != JSON.stringify(objRequestData[strField])) {
          this.bHasChanged = true;
        }
      }
    });
  }

  /**
   * Checks if selected checklists are their prompts are not empty
   *
   * @param {array} arChecklistData - Checklists which we would like to link in the job
   * @return {boolean}
   */
  isValidChecklistField(): boolean {
    // Variable if one of the checklist in line items has an empty prompts
    let hasChecklistError = false;

    this.arLineAttributes.forEach( (line_item, attr_index) => {
      if (this.arrService.keyFallsBackTo(line_item, 'checklist', null) !== null) {
        // This is for identifying which line items
        // has a checklist error
        let hasCurrentLineItemError = false;

        line_item['checklist'].forEach( checklist => {
          let promptIndex;
          let arPrompts;

          try {
            arPrompts = JSON.parse(checklist['questions']);
          } catch (e){
            return;
          }

          if (checklist['type'] === 'asset') {
            promptIndex = arPrompts.findIndex(item => (item.name == 'per_asset_prompts'));
          } else {
            promptIndex = arPrompts.findIndex(item => (item.name == 'main_prompts'));
          }

          if (promptIndex < 0 || arPrompts[promptIndex]['prompts'].length === 0) {
            hasCurrentLineItemError = true;
          }
        });

        if (hasCurrentLineItemError) {
          hasChecklistError = true;
          this.arLineAttributes[attr_index]['has_checklist_error'] = true;
        } else {
          this.arLineAttributes[attr_index]['has_checklist_error'] = false;
        }
      }
    });

    if (hasChecklistError) {
      this.notifService.notifyWarning('empty_checklist_prompt', {
        header: 'not_allowed',
      });

      this.bSubmitted = false;

      return false;
    }

    return true;
  }

  /**
   * Removes the line item.
   * @param attr - the object to be removed.
   */
  removeAttribute(i) {
    this.arLineAttributes.splice(i, 1);
    this.setDialogStyle();
  }

  addLineAttribute(objData: LooseObject = {}, strReferenceWorkOrder: string = null) {
    // Generate a unique name for the lineitem.
    let hasData = Object.keys(objData).length > 0;
    let intExistingWorkOrderIndex = this.arLineAttributes.findIndex(arLineAttr => arLineAttr['work_order_reference'] === strReferenceWorkOrder);
    let bLineItemExist = (strReferenceWorkOrder) ? intExistingWorkOrderIndex !== -1 : false;
    // If the generated name is indeed unique (no existing item of a similar name found.).
    if (!bLineItemExist) {
        if (Object.keys(objData).length > 0) {

          let updateData = {
            'asset_type_id' : objData['asset_type_id'],
            'asset_group_id' : objData['asset_group_id'],
            'department_id' : objData['department_id'],
            'user_id': objData['user_id'],
            'checklist_ids' : objData['checklist_ids'],
            'task_description' : objData['task_description'],
          };

          this.arLineAttributes.push({
            work_order_reference : objData['work_order_reference'] ? objData['work_order_reference'] : strReferenceWorkOrder,
            data: new RecurringJobLineItem(updateData),
            asset_type_obv: new Observable<Select[]>(),
            asset_type_typehead: new Subject<string>(),
            asset_type_loader: false,
            checklist_obv: new Observable<Select[]>(),
            checklist_typehead: new Subject<string>(),
            checklist_loader: false,
            asset_group_obv: new Observable<Select[]>(),
            asset_group_typehead: new Subject<string>(),
            asset_group_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            user_obv: new Observable<Select[]>(),
            user_typehead: new Subject<string>(),
            user_loader: false,
            checklist: objData['checklist'],
            checklist_filter: {'is_checklist_enabled': true, 'checklists.asset_type_id': objData['asset_type_id']},
            has_checklist_error: false,
          });
        } else {
          this.arLineAttributes.push({
            work_order_reference: objData['work_order_reference'] ? objData['work_order_reference'] : strReferenceWorkOrder,
            data: new RecurringJobLineItem(),
            asset_type_obv: new Observable<Select[]>(),
            asset_type_typehead: new Subject<string>(),
            asset_type_loader: false,
            checklist_obv: new Observable<Select[]>(),
            checklist_typehead: new Subject<string>(),
            checklist_loader: false,
            asset_group_obv: new Observable<Select[]>(),
            asset_group_typehead: new Subject<string>(),
            asset_group_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            user_obv: new Observable<Select[]>(),
            user_typehead: new Subject<string>(),
            user_loader: false,
            checklist: null,
            checklist_filter: {},
            has_checklist_error: false,
          });
        }
    }

    // For User Relate
    this.setRelateField('users', 'user', null, (hasData) ? objData['user'] : []);
    // For Asset type Relate
    this.setRelateField('asset_types', 'asset_type', null, (hasData) ? objData['asset_type'] : []);
    // For Checklist Relate
    this.setRelateField('checklists', 'checklist', null, (hasData) ? objData['checklist'] : []);
    // For Asset Group Relate
    this.setRelateField('asset_groups', 'asset_group', null, (hasData) ? objData['asset_group'] : []);
    // For Department Relate
    this.setRelateField('departments', 'department', null, (hasData) ? objData['department'] : []);
    this.setDialogStyle();
  }

  /**
   * Initialize relate fields
   * @param strObservableName - observable that is set
   * @param strModule - module for relate
   * @param objMainFilter - Main filter for record (onChange of the fields)
   * @param arRelateInitialValue - Initial relate value
   */
  setRelateField(strModule, strObservableName, objMainFilter, arRelateInitialValue: object | [] = [], curentIndex: number | string = '') {
    // Get the lastest index.
    let index = (curentIndex === '') ? this.arLineAttributes.length - 1 : curentIndex;
    let strObservable = strObservableName + '_obv';
    let strTypehead = strObservableName + '_typehead';
    let strLoader = strObservableName + '_loader';
    let bHasAdditionalFilter: boolean = strModule !== 'asset_types';
    // Set a default dropdown value for this line item.
    this.arLineAttributes[index][strObservable] = concat(
      of(arRelateInitialValue),
      this.arLineAttributes[index][strTypehead].pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.arLineAttributes[index][strLoader] = true),
        switchMap(term => this.recordService.getRecordRelate(strModule, term, '', false, false, 10, bHasAdditionalFilter).pipe(
          tap(() => {
            this.arLineAttributes[index][strLoader] = false;
          })
        ))
      )
    )
  }

  /**
   * Initialize relate field
   * @param {string} strModule - module to get related data
   * @param strObservableName - Observable name i.e department
   * @param strIndex - Index of attribute
   * @param objFilter - Desired Filter
   */
  getRelatedData(strModule, strObservableName, strIndex, objFilter: any = false) {
    let strLoader = strObservableName + '_loader';
    let bHasAdditionalFilter: boolean = strModule !== 'asset_types';

    this.arLineAttributes[strIndex][strLoader] = true;
    this.recordService.getRecordRelate(strModule, '', false, false, objFilter, 10, bHasAdditionalFilter).subscribe( result => {

      this.setRelateField(strModule, strObservableName, objFilter, result, strIndex);
      this.arLineAttributes[strIndex][strLoader] = false;
    });

    this.setDialogStyle();
  }

  /**
   * When asset type is change
   * Add the value of selected id
   * to the current line item
   * checklist filter
   *
   * @param value
   * @param index
   */
  assetTypeChange(value, index) {
    if (value !== undefined && value.id) {
      this.arLineAttributes[index]['checklist_filter'] = {'is_checklist_enabled': true, 'checklists.asset_type_id': value.id}
    } else {
      this.arLineAttributes[index]['checklist_filter'] = {}
    }
  }

  /**
   * Get index of form
   * @param strField
   */
  getFormFieldIndex(strField) {
    return this.recurringJobForm.findIndex(attr => (attr.groups.get(strField) != undefined && attr.groups.get(strField) != null));
  }

  /**
   * Get index of field
   * @param strField
   * @param form
   */
  getFieldIndex(strField, form) {
    if (form != undefined && form['fields'] != undefined) {
      return form['fields'].findIndex( attr => (attr['key'] == strField));
    } else {
      return -1;
    }
  }

  /**
   * Set value of field
   * @param strField
   * @param strValue
   */
  fieldPatchValue(strField, strValue) {
    let indexOfFormField = this.getFormFieldIndex(strField);
    if (indexOfFormField > -1) {
      this.recurringJobForm[indexOfFormField]['groups'].patchValue({
        [strField] : strValue,
      }, {emitEvent: false, onlySelf: true});
    }
  }

  /**
   * When checklist field is change
   * get all ids and set it to data
   * checklist ids in line items
   *
   * @param value
   * @param attr_index
   */
  checklistChange(value, attr_index) {
    if (value !== undefined) {
      let ids = [];
      value.forEach( data => {
        ids.push(data['id']);
      });

      this.arLineAttributes[attr_index]['data']['checklist_ids'] = ids;
    }

    this.isValidChecklistField();
  }

  /**
   * Validate Request
   * @param objRequest
   */
  validateRequest(objRequest) {
    this.wasValidated$.next(false);

    for (let key in objRequest) {
      if (this.arRequiredField.indexOf(key) > -1 && key != 'line_items') {
        if((objRequest[key] == undefined || objRequest[key] == '' || objRequest[key] == null || objRequest[key].length < 1)) {
          let indexOfFormField = this.getFormFieldIndex(key);

          if (indexOfFormField > -1) {
            this.recurringJobForm[indexOfFormField]['groups']['controls'][key].markAsTouched();
            this.recurringJobForm[indexOfFormField]['groups']['controls'][key].setErrors({'incorrect' : true});
          }

          if (!this.bDepartmentTracking && key != 'department_id') {
            this.hasError = true;
          }

        }
      }
    }

    if (! isValidWorkOrderLines(this._workOrderLines)) {
      this.hasError = true;
    }

    if (this.hasError) {
      this.wasValidated$.next(true);
    }
  }

  /**
   * This is to increase the row
   * when textarea is focus
   */
  increaseRow(i) {
    let currentElem = document.querySelector('.task-desc-'+ i);
    currentElem.setAttribute("style", "height: 100px");
  }

  /**
   * This is to decrease the row
   * when textarea is focus out
   */
  decreaseRow(i) {
    let currentElem = document.querySelector('.task-desc-'+ i);
    currentElem.setAttribute("style", "height: 40px");
  }

  /*
   * Mark field as dirty
   *
   * @param {string} field
   *
   * @returns {void}
   */
  markFormControlDirty(field: string): void {
    this.recurringJobForm.controls[field].markAsDirty();
  }

  /**
   * Set style for dialog to avoid
   * the dropdown panel to be behind
   * in the dialog content
   */
  setDialogStyle(): void {
    let dialogParentElement = document.getElementsByClassName('cdk-global-overlay-wrapper');
    let dialogElement = document.getElementsByClassName('mat-dialog-container');
    let currentWindowHeight = window.innerHeight;

    if (dialogParentElement.length > 0 && dialogElement.length > 0) {
      // We need to set overflow on dialog parent element. So
      // when dialog is more than the dialog parent it will
      // be scrollable
      this.renderer.setStyle(dialogParentElement[0], 'overflow', 'auto');
      let heightOfAdditionalLineItems = 81;
      // Check the dialog height to current window height.
      // to set the correct overflow in the dialog
      if ((dialogElement[0].getBoundingClientRect().height + heightOfAdditionalLineItems) > currentWindowHeight) {
        dialogElement[0].setAttribute("style", "overflow: auto !important");
      } else {
        dialogElement[0].setAttribute("style", "overflow: visible !important");
      }
    }
  }

  /**
   * Listens to form error
   *
   * @returns {void}
   */
  onFormError(): void {
    this.disabledSaveButton$.next(true);
  }

  /**
   * Before we save the recurring job.
   * We need to emit a value to child component
   * to process the work order items.
   */
  preSaveRecurringJob() {
    // FC-4023: check if recurring job's 'Schedule Automatically' field is set to true as there is further logic to be performed if it is true.
    let bScheduleAutomatically: boolean = this.isRecurringJobAutoschedule();
    // If schedule automatically is set to true, and job tasks tab is not empty, display prompt if technician field in job tasks is empty.
    if (bScheduleAutomatically && this.hasJobTasksWithoutTechnician()) {
      this.notifService.sendConfirmation('recurring_job_task_no_technician')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.saveRecurringJob();
        });
    } else {
      this.saveRecurringJob();
    }
  }

  /**
   * Gets the value of the 'Schedule Automatically' field located at the 'System' tab of the form.
   *
   * @return {boolean}
   */
  isRecurringJobAutoschedule(): boolean {
    // FC-4023: If 'Schedule Automatically' field is set to true, and the job tasks tab is not empty,
    // should display a prompt to user if there are job tasks with no selected Technician,
    // and the prompt should say that if the technician field is empty, that job task will
    // be set to Awaiting Scheduling.
    //
    // Get the system form group first as the 'Schedule Automatically' field is located there.
    let objSystemFormGroup: { fields: Form[], groups: FormGroup } = this.recurringJobForm.find(objGroup => get(objGroup, 'id', '') === 'system');
    let objSchedAutomaticallyField: FormControl;

    if (objSystemFormGroup) {
      // get the schedule_automatically field
      objSchedAutomaticallyField = objSystemFormGroup.groups.get('schedule_automatically') as FormControl;
    }

    if (objSchedAutomaticallyField) {
      // return the value of the schedule automatically field
      return get(objSchedAutomaticallyField, 'value', false);
    }

    return false;
  }

  /**
   * Checks the 'Job Tasks' section of the recurring job form if there are any tasks without an assigned technician.
   * Only applies if the 'Job Tasks' section is not blank.
   *
   * @return {boolean}
   */
  hasJobTasksWithoutTechnician(): boolean {
    const isTaskDataEmpty = (objTaskData: RecurringJobLineItem) => {
      return isEmpty(objTaskData.asset_group_id) &&
        isEmpty(objTaskData.asset_type_id) &&
        isEmpty(objTaskData.checklist_ids) &&
        isEmpty(objTaskData.department_id) &&
        isEmpty(objTaskData.user_id) &&
        isEmpty(trim(objTaskData.task_description));
    };

    for (let row of this.arLineAttributes) {
      let objTaskData: RecurringJobLineItem = row['data'];

      // to check if job tasks tab is empty, check first if the length of arLineAttributes is just 1,
      // and then check if the objTaskData attributes are all empty.
      if (this.arLineAttributes.length === 1 && isTaskDataEmpty(objTaskData)) {
        return false;
      }

      // if job tasks tab is not empty, find the first instance of a job task with no technician and return true
      if (isEmpty(get(objTaskData, 'user_id'))) {
        return true;
      }
    }

    return false;
  }

  onMaterialsUpdate(event: OnMaterialsUpdate): void {
    this._materialLines = event.materials;
  }

  onWorkOrderUpdate(event: OnWorkOrderLinesUpdate): void {
    this._workOrderLines = event.work_order_items;
    this._pricebokId = event.pricebook_id;

    if (blank(event.work_order_items) || event.mode != 'add' || event.work_order_item.isGroup()) {
      return;
    }

    const line = event.work_order_item;
    const [isInMaterials, isInTasks] = this._isInTasksOrMaterials(line);

    if (isInMaterials || isInTasks || blank(get(line, 'item_id'))) {
      return;
    }

    if (! get(line, 'labor', true)) {
      const materials: MaterialLineData[] = _map(this._materialLines, (material) => material.getMaterialData());

      materials.push({
        unit_cost: get(line, 'unit_cost'),
        unit_price: whenFilled(get(line, 'discounted_price'), {
          then: () => get(line, 'discounted_price'),
          else: () => get(line, 'unit_price'),
        }),
        quantity: get(line, 'quantity'),
        work_order_reference: get(line, 'work_order_reference'),
        description: get(line, 'description'),
        from_work_order: true,
        item_id: get(line, 'item_id'),
        item_name: get(line, 'item_name'),
        item_code: get(line, 'item_code'),
        markup: get(line, 'markup'),
      });

      this.initialMaterials$.next(materials);
    } else {
      this.addLineAttribute({
        task_description: get(line, 'description'),
        estimated_duration: 1,
        department: whenFilled(get(line, 'department'), {
          then: () => first(get(line, 'deaprtment')),
          else: () => [],
        }),
        department_id: whenFilled(get(line, 'department'), {
          then: () => get(first(get(line, 'department')), 'id'),
        }),
        from_work_order: true,
        work_order_reference: get(line, 'work_order_reference'),
      }, get(line, 'work_order_reference'));
    }
  }

  private _isInTasksOrMaterials(line: WorkOrderLine): [boolean, boolean] {
    const isInMaterials = isIn(this._materialLines, {
      comparator: (material) => material.work_order_reference == get(line, 'work_order_reference'),
    });

    const isInTasks = isIn(this.arLineAttributes, {
      comparator: (task) => get(task, 'work_order_reference') == get(line, 'work_order_reference'),
    });

    return [isInMaterials, isInTasks];
  }
}
