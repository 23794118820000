export class LinkFilter {

  /**
   * Link of the filter. The key could be any but it must have
   * the indicated structure.
   *
   * @todo Feel free to alter the structure if we add more filters.
   */
  [key: string]: {op?: string, label?: string, value: any }[];

  constructor(key: string, value: any, type: 'string' | 'boolean') {

    if (type == 'string') {
      this[key] = [
        {
          op: 'eq',
          value: [
            {
              label: value,
              value: value
            }
          ]
        }
      ];
    } else {
      this[key] = [
        {
          label: value ? 'yes' : 'no',
          value: value
        }
      ];
    }

  }

}