<div class="row col-12">
  <div class="col-6 text-right">
    <p>{{ 'import_tax_codes' | translate }}</p>
  </div>
  <div class="col-6">
    <fa-icon [icon]="['fas','spinner']" class="custom-size text-secondary" [spin]="true" *ngIf="isInProgress"></fa-icon>
    <fa-icon id="import-tax-codes-success" [icon]="['fas', 'check']" class="text-success" *ngIf="isSuccessful && ! isInProgress"></fa-icon>
    <fa-icon [icon]="['fas', 'sync-alt']" (click)="retryHandler()" class="pointer mr-2 text-primary" id="import-tax-codes-retry-action" *ngIf="! isSuccessful && ! isInProgress"></fa-icon>
    <small class="text-muted text-danger" id="import-tax-codes-error-message" *ngIf="! isSuccessful && ! isInProgress">{{ error | translate }}</small>
    <accounting-systems-common-import-progress *ngIf="isInProgress" (event-progress)="onProgressEvent($event)" class="ml-2 d-inline-block" progress-id="connect_import_taxes"></accounting-systems-common-import-progress>
  </div>
</div>
