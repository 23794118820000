<header>
  <div class="d-flex">
    <h3 class="title">
      <fa-icon [icon]="['fas', 'search']"></fa-icon>&nbsp;
      <span>{{ objAction.id + '_title' | translate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="selfDialog.close()" class="btn btn-danger">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>

<hr>

<div class="row" class="action-screen">
  <div class="col-12">
      <ng-select class="value-text" clearable="true" bindLabel="text" bindValue=""
        [(ngModel)]="objSelectedRecord"
        [typeahead]="objAction['relate'].typehead"
        [items]="objAction['relate'].source | async"
        [loading]="objAction['relate'].loader"
        (open)="objAction['relate'].typehead.next('')">
      </ng-select>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12">
    <button class="btn btn-primary float-right" (click)="doAction()">
      {{ objAction.id | translate }}
    </button>
  </div>
</div>

