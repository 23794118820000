import { LooseObject } from "./loose-object";

export class Pagination {

  /**
   * This usually contains the next token.
   *
   * @var {string}
   */
  cursor?: string;

  /**
   * Which direction the pagination is going,
   * can be 'next' or 'previous'.
   */
  direction?: string;

  /**
   * History of travelled pages.
   *
   * @var {LooseObject}
   */
  history?: LooseObject;

  constructor(token: string) {
    let objDecodedToken: any = (token) ? JSON.parse(atob(token)) : {};

    this.cursor = objDecodedToken.cursor;
    this.direction = objDecodedToken.direction;
    this.history = objDecodedToken.history;
  }

  /**
   * Converts this object to a string that
   * is readable by the server.
   *
   * @returns {string}
   */
  toString(): string{
    return btoa(JSON.stringify(this));
  }

}
