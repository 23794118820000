import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError, delay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { Config } from 'protractor';
import { Select } from '../objects/select';

const baseUrl: string = environment.url;

@Injectable()
export class ExportService {

  constructor (private http: HttpClient, private router: Router) {}

  exportRecord(module: string): Observable<HttpResponse<Config>> {
    let body = new URLSearchParams();
    body.append('module', module);
    return this.http.post<Response>(`${baseUrl}/export`, body.toString(), { observe: 'response' });
  }
}
