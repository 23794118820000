<header>
  <div class="d-flex dialog-header">
    <h3 id="viewInspectionPhotos" class="dialog-title">
      <fa-icon [icon]="['fas', 'file-image']"></fa-icon>
      {{ "inspection_photos" | translate }}
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button
            id="dialogCloseButton"
            type="button"
            (click)="dialogClose()"
            class="btn ml-1 btn-primary"
          >
            <fa-icon [icon]="['fas', 'times']"></fa-icon
            ><span class="pl-1">{{ "cancel" | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button
            id="saveButton"
            (click)="onSubmit()"
            class="btn ml-1 btn-primary"
          >
            <fa-icon [icon]="['fas', 'save']"></fa-icon
            ><span class="pl-1">{{ "save" | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="mb-1" />

<mat-dialog-content>
  <div class="containers">
    <div class="row">
      <div class="file-drop-container">
        <input
          class="file-input"
          type="file"
          accept="image/*"
          (change)="onFileChange($event)"
          multiple="multiple"
        />

        <div *ngIf="!isFileDropped" class="file-input-overlay">
          {{ "drop_image_here" | translate }}
        </div>

        <div *ngIf="isFileDropped" class="file-input-overlay-uploading">
          <fa-icon
            [icon]="['fas', 'spinner']"
            [spin]="true"
            aria-hidden="true"
          ></fa-icon>
          {{ "uploading" | translate }}
        </div>
      </div>
    </div>

    <div class="row image-row">
      <div *ngFor="let photo of photos; index as indexPhoto">
        <div class="col-md-11 mb-4">
          <fa-icon
            [icon]="['fas', 'trash-alt']"
            class="pull-right bullet text-danger cursor"
            (click)="removeAttachement(photos, indexPhoto)"
          >
          </fa-icon>
          <img
            height="150"
            width="150"
            class="rounded thumbnails cursor"
            (click)="viewImage(photo)"
            src="{{ photo.url }}"
          />
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
