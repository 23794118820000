import { AfterViewInit, Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationService } from '../../../../services/notification.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Subscription, merge } from 'rxjs';
import { WizardStepper } from '../../objects/wizard-stepper';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'create-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Settings for the stepper.
   *
   * @var {WizardStepper}
   */
  @Input() stepperSettings: WizardStepper;

  /**
   * The dialog data of the parent.
   *
   * @var {MatDialogRef}
   */
  @Input() dialogRef: MatDialogRef<any>;

  /**
   * Inform parents for any stepper events.
   *
   * @var {EventEmitter<StepperSelectionEvent>}
   */
  @Output() stepperEvents = new EventEmitter<StepperSelectionEvent>();

  /**
   * The content of each step, needed for
   * showing in the templates in html.
   *
   * @var {any}
   */
  @ContentChild('step1') step1: any;
  @ContentChild('step2') step2: any;
  @ContentChild('step3') step3: any;
  @ContentChild('step4') step4: any;
  @ContentChild('step5') step5: any;

  /**
   * List of subscriptions so we can unsub when component is gone.
   *
   * @var {Subscription[]}
   */
  private arSubscriptions: Subscription[] = [];

  constructor(
    private notifService: NotificationService,
    private wizardService: WizardService
  ) {}

  ngOnInit() {
    this.arSubscriptions.push(
      merge(
        this.dialogRef.backdropClick(),
        this.wizardService.$onDialogClose
      ).subscribe(() => {
      if (this.step1 && this.step1._parentView.component.isDirty()) {
        this.notifService.sendConfirmation('confirm_cancel')
          .filter(confirmation => confirmation.answer === true)
          .subscribe(() => {
            this.dialogRef.close('cancel');
          });
      } else {
        this.dialogRef.close('cancel');
      }
    }));
  }

  ngAfterViewInit(): void {
    this.dialogRef.disableClose = true;
  }

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  /**
   * Emit the stepper changes.
   *
   * @param {StepperSelectionEvent} objEvent
   *
   * @returns {void}
   */
  stepperChanges(objEvent: StepperSelectionEvent): void{
    this.stepperEvents.emit(objEvent);
  }

}
