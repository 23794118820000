import { StrService } from '../services/helpers/str.service';
import { Select } from './select';

export interface DepartmentObject {
  /**
   * Object department that will
   * be used in Select field
   * @type {object}
   */
  department: {};
  /**
   * Department id that will
   * be used in data or as
   * selected value in field
   * @type {string}
   */
  department_id?: string;
  /**
   * Department text that will
   * be used in data or as
   * selected value in field
   * @type {string}
   */
  department_text?: string;
}

export class Department {

  id?: string;
  department_name?: string;

  constructor(properties: {
      id?: string
      department_name?: string
    } = {}){

    this.id = properties.id,
    this.department_name = properties.department_name
  }

  /**
   * This will get the department of job related
   *
   * @param {object} objJobData
   */
  getJobDepartment(objJobData): DepartmentObject {
    let strService = new StrService;
    let objDepartment = {
      department: {},
      department_id: null,
      department_text: null
    };

    if (strService.fallsBackTo(objJobData['id']) !== null && strService.fallsBackTo(objJobData['department_id']) !== null) {
      let strDepartmentId = objJobData['department_id'];
      let strDepartmentText = objJobData['department_text'];

      objDepartment = {
        department: new Select(strDepartmentId, strDepartmentText),
        department_id: strDepartmentId,
        department_text: strDepartmentText
      };
    }

    return objDepartment;
  }
}