import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/modules/shared.module';
import { ComponentViewingWidgetComponent } from '../../shared/components/component-viewing-widget/component-viewing-widget.component';
import { ResultSetListComponent } from '../../admin/import/import-record/widget-components/result-set-list/result-set-list.component';
import { ImportedRecordsComponent } from '../../admin/import/import-record/widget-components/imported-records/imported-records.component';
import { WidgetableHostDirective } from '../../shared/components/component-viewing-widget/directives/widgetable-host/widgetable-host.directive';

@NgModule({
  declarations: [
    ComponentViewingWidgetComponent,
    WidgetableHostDirective
  ],
  imports: [
    CommonModule,
    SharedModule.forRoot()
  ],
  exports: [
    ComponentViewingWidgetComponent,
    WidgetableHostDirective
  ],
  entryComponents: [
    ImportedRecordsComponent,
    ResultSetListComponent
  ]
})
export class DynamicComponentDisplayModule { }
