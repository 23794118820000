import { Component, Input, OnInit } from '@angular/core';
import { ImporterService } from '../../../../services/importer/importer.service';
import { NotificationService } from '../../../../services/notification.service';
import { BrowserService } from '../../../../services/browser/browser.service';

@Component({
  selector: 'listing-download-button',
  templateUrl: './button-download.component.html',
  styleUrls: ['./button-download.component.scss']
})
export class ButtonDownloadComponent implements OnInit {

  @Input() record;

  constructor(
    private importerService: ImporterService,
    private browserService: BrowserService,
    private notificationService: NotificationService) { }

  ngOnInit() {}

  /**
   * Executes the downloading of a resultset
   *
   * @param downloadType
   *
   * @returns {void}
   */
  download(downloadType: 'success' | 'errors'): void {
    this.importerService.downloadResultSet(this.record.id, downloadType).subscribe((response) => {
      this.browserService.forceDownload(response.body, `${downloadType}.csv`);
      this.notificationService.notifySuccess('download_inprogress');
    });
  }
}
