import { MatDialogConfig } from '@angular/material';

export class PopupConfig {

  /**
   * The config that the popup should use.
   *
   * @var {MatDialogConfig}
   */
  config: MatDialogConfig = {};

  /**
   * The dialog component to open.
   *
   * @var {any}
   */
  component: any;

  /**
   * Redirect to module?
   *
   * @var {boolean}
   */
  direct: boolean = false;

  constructor(
    objComponent: any,
    objMobileDimensions: {width: string, height: string} = null,
    objDesktopDimensions: {width: string, height: string} = null,
    objConfig: MatDialogConfig = {},
    bDirect: boolean = false
  ) {

    this.component = objComponent;
    this.config = objConfig;
    this.direct = bDirect;

    if (window.innerWidth <= 800 && window.innerHeight <= 1024) {
      if (objDesktopDimensions !== null) {
        this.config.width = objDesktopDimensions.width;
        this.config.height = objDesktopDimensions.height;
        this.config.maxHeight = '100vh';
        this.config.maxWidth = '100vw';
      }
    } else {

      if (objMobileDimensions !== null) {
        this.config.width = objMobileDimensions.width;
        this.config.height = objMobileDimensions.height;
      }

    }

  }
}