<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <div>
        <fa-icon [icon]="['far', 'shopping-bag']" transform="shrink-3" class="dialog-title"></fa-icon>
        {{ 'create_purchase_order' | translate }}
      </div>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button id="btnCancel" type="button" (click)="cancelDialog()" class="btn btn-primary mr-2">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
          <button class="btn btn-primary float-right mr-2" (click)="createPurchaseOrders()" [disabled]="bDisabled">
            <fa-icon [icon]="['fas', 'save']"></fa-icon>
            <span class="pl-1">{{ 'create' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>
<mat-dialog-content>
    <div class="row">
      <div class="col-md-12 text-center">

        <span class="items-label"> {{ 'has_preferred_suppliers' | translate }} </span>
        <table class="table table-striped w100p">
          <thead>
            <tr>
              <th class="table-header">{{ 'supplier_name' | translate }}</th>
              <th class="table-header">{{ 'item_name' | translate }}</th>
              <th class="table-header">{{ 'item_code' | translate }}</th>
              <th class="table-header">{{ 'description' | translate }}</th>
              <th class="table-header"><input id="cbxSelectAllHasSupplierItems" type="checkbox" (click)="selectAllItems($event)"/></th>
            </tr>
          </thead>
          <tbody *ngIf="objItemsWithSupplier">
            <tr *ngFor="let itemWithSupplier of objItemsWithSupplier, index as itemIndex">
              <td class="td-description text-center">{{ itemWithSupplier['item_record'].preferred_supplier_text }}</td>
              <td class="td-description text-center">{{ itemWithSupplier['item_record'].name }}</td>
              <td class="td-description text-center">{{ itemWithSupplier['item_record'].code }}</td>
              <td class="td-description text-center">{{ itemWithSupplier['line_item'].description }}</td>
              <td class="td-description text-center">
                <input id="cbxSelectOneHasSupplierItems" type="checkbox" [checked]="itemWithSupplier['checked']" (click)="selectItem($event, itemWithSupplier, itemIndex)"/>
              </td>
            </tr>
          </tbody>
        </table>

        <span  *ngIf="objItemsWithoutSupplier.length !== 0" class="items-label"> {{ 'no_preferred_suppliers' | translate }} </span>
        <table *ngIf="objItemsWithoutSupplier.length !== 0" class="table table-striped w100p">
          <thead>
            <tr>
              <th class="table-header">{{ 'item_name' | translate }}</th>
              <th class="table-header">{{ 'item_code' | translate }}</th>
              <th class="table-header">{{ 'description' | translate }}</th>
              <th class="table-header"><input id="cbxSelectAllNoSupplierItems" type="checkbox" (click)="selectAllItems($event, false)"/></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let itemWithoutSupplier of objItemsWithoutSupplier, index as itemIndex">
              <td class="td-description text-center">{{ itemWithoutSupplier['item_record'].name }}</td>
              <td class="td-description text-center">{{ itemWithoutSupplier['item_record'].code }}</td>
              <td class="td-description text-center">{{ itemWithoutSupplier['line_item'].description }}</td>
              <td class="td-description text-center">
                <input type="checkbox" [checked]="itemWithoutSupplier['checked']" (click)="selectItem($event, itemWithoutSupplier, itemIndex, false)"/>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="objItemsWithoutSupplier.length !== 0">
          <span class="supplier-label">{{ 'select_preferred_supplier' | translate }}</span>
          <ng-select
            class="supplier-dropdown"
            [items]="objPreferredSupplierConfig['obv'] | async"
            [typeahead]="objPreferredSupplierConfig['typehead']"
            [loading]="objPreferredSupplierConfig['loader']"
            [clearable]="true" 
            [ngModelOptions]="{standalone: true}"
            bindLabel="text"
            [bindValue]
            [(ngModel)]="objPreferredSupplierConfig['value']"
            (open)="initRelateSupplier()"
            [id]="objPreferredSupplierConfig['name']"
            [name]="objPreferredSupplierConfig['name']">
          </ng-select>
        </div>

      </div>
  </div>
</mat-dialog-content>