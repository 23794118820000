import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNull, isUndefined, toString } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { toFormattedNumber } from '../../../../utils/numbers';

type OnChangeHandler = (value?: string) => void;
type OnTouchedHandler = () => void;
type OnKeyPressHandler = (event: KeyboardEvent) => void;

@Component({
  selector: 'fieldmagic-decimal-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DecimalInputComponent),
      multi: true,
    },
  ],
  template: `
    <div class="input-icon">
      <input
        type="number"
        min="0"
        [max]="max"
        step="0.1"
        class="{{ customClass }}"
        [ngClass]="{'is-invalid': invalid}"
        [placeholder]="placeholder"
        [disabled]="isDisabled$ | async"
        [(ngModel)]="value"
        (change)="onChange($event.target.value)"
        (focus)="onTouched()"
        (keypress)="onKeyPress($event)"
        [readonly]="isReadonly"
      />
    </div>
  `,
})
export class DecimalInputComponent implements ControlValueAccessor {
  /**
   * Dictates if the field should be disabled
   */
  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Contains the value inputted
   */
  value: string;

  /**
   * determine if field allows negative value
   */
  @Input() nonNegative: boolean = false;

  /**
   * determine if field allows negative value
   */
  @Input() customClass: string = 'form-control font-size-11';

  /**
   * determine if field allows negative value
   */
  @Input() isReadonly: boolean = false;

  /// add placeholder to the input
  @Input() placeholder: string = '';

  /// add invalid flag to trigger styling for input error
  @Input() invalid: boolean = false;

  /// max possible values
  @Input() max: number;

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => { };

  /**
   * Callback when input value was changed
   */
  onChange: OnChangeHandler = () => { };

  /**
   * Callback when on key press
   */
  onKeyPress: OnKeyPressHandler = (event: KeyboardEvent) => this.onKeyPressInput(event);

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (value?: string) => {
      fn(
        this.value = this.formatValue(value)
      );
    };
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: string): void {
    this.value = this.formatValue(value);
  }

  /**
   * Formats the given value
   */
  protected formatValue(value?: string): string | undefined {
    if (isUndefined(value) || isNull(value)) {
      return undefined;
    }

    if (this.max != 0 && Number(value) > this.max) {
      value = toString(this.max);
    }

    return toString(toFormattedNumber(value));
  }

  /**
   * on key press event
   * if non negative is true, we should allow the user to input numeric values only
   */
  protected onKeyPressInput(event: KeyboardEvent) {
    if (!this.nonNegative) {
      return;
    }

    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }
}
