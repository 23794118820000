import { Address } from "../address";

export class Warehouse implements WarehouseInterface {
    address?: Address;
    address_text?: string;
    id: string;
    name: string;
    qr_code?: string;
    status: 'active' | 'inactive';
    text: string;
    type: 'fixed_location' | 'vehicle';

    constructor(properties: WarehouseInterface) {
        this.address = properties.address;
        this.address_text = properties.address_text;
        this.id = properties.id;
        this.name = properties.name;
        this.qr_code = properties.qr_code;
        this.status = properties.status;
        this.text = properties.text;
        this.type = properties.type;
    }

}

export interface WarehouseInterface {
    address?: Address;
    address_text?: string;
    id: string;
    name: string;
    qr_code?: string;
    status: 'active' | 'inactive';
    text: string;
    type: 'fixed_location' | 'vehicle';
}
