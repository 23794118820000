import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../widgets/stock-transfer/dialog/form/form.component';
import { ListingService } from '../../../services/listing.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'stock-level',
  templateUrl: './stock-level.component.html',
  styleUrls: ['./stock-level.component.scss']
})
export class StockLevelComponent implements AfterViewInit, OnDestroy {

  /**
   * Observables that are alive on this
   * component.
   *
   * @var {Subscription[]}
   */
  public arSubscriptions: Subscription[] = [];

  objTableHeaderData: object[];

  constructor(
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public list: ListingService
  ) {
    this.objTableHeaderData = [
      {
        id: 'item_id',
        label: 'product',
        searchable: false,
        sortable: false,
        option: ''
      },
      {
        id: 'warehouse_id',
        label: 'warehouse',
        searchable: false,
        sortable: false,
        option: ''
      },
      {
        id: 'quantity',
        label: 'quantity',
        searchable: false,
        sortable: false,
        option: ''
      },
      {
        id: 'created_at',
        label: 'date_created',
        searchable: false,
        sortable: true,
        option: 'history_date'
      },
      {
        id: 'updated_at',
        label: 'date_modified',
        searchable: false,
        sortable: true,
        option: 'history_date'
      }

    ];
  }

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngAfterViewInit(): void {
    this.arSubscriptions.push(this.route.queryParams.delay(100).subscribe( params => {
      if (params['stock_transfer'] == 'open') {
        this.dialog.open(FormComponent, {
          width: '800px',
          height: 'auto',
          disableClose: true,
          data: {}
        });
      }
    }));
  }

}
