import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { LocalStorageService } from '../../services/local-storage.service';

@Pipe({
  name: 'cbHumanizedTrialDuration',
  pure: false
})
export class ChargebeeHumanizedTrialDuration implements PipeTransform {

  /**
   * Upon instantiating this pipe, we'll have some tweaks to do with MomentJS for
   * it to work just like Chargebee.
   */
  constructor(protected translateService: TranslateService, protected localStorageService: LocalStorageService) {
    this.overrideRelativeTimeThresholds();
  }

  /**
   * Given a date, this will return a human-readable duration text 
   * similar to how Chargebee shows trial duration in its subscription
   * info page.
   * 
   * @param {moment.MomentInput} input
   * 
   * @returns {String}
   */
  transform(input: moment.MomentInput): string {
    this.setMomentLocale(this.localStorageService.getItem('user_locale'));

    let today = moment();
    let past = moment(input);
    let secondsLeft = Math.max(past.diff(today, 'seconds', true), 0);

    return moment.duration(secondsLeft, 'seconds').humanize();
  }

  /**
   * When calling .humanize() MomentJS uses something called "Relative
   * Time Thresholds" that allow it to change how a datetime format is displayed
   * when it reaches a specific threshold. For example, the duration of "10 Months"
   * will instead be returned as "a year" or "26 Days" as "a month".
   *
   * That's beautiful, however, for Chargebee, this work a bit differently so we'll
   * need to modify those thresholds.
   * @see {@link https://momentjs.com/docs/#/customization/relative-time-threshold/} for further information about MomentJS time thresholds.
   * @see {@link https://crmonline.atlassian.net/browse/FC-1235} for further information on how Chargebee shows trial duration text.
   * 
   * @returns {void}
   */
  private overrideRelativeTimeThresholds(): void {
    // If the number of trial seconds exceeds 60 seconds, it will show up as "a minute"
    moment.relativeTimeThreshold('s', 60);

    // If the number of trial minutes exceeds 60 minutes, it will show up as "an hour"
    moment.relativeTimeThreshold('m', 60);

    // If the number of trial hours exceeds 12 hours, it will show up as "a day"
    moment.relativeTimeThreshold('h', 12);

    // If the number of trial days exceeds 31 days, it will show up as "a month"
    moment.relativeTimeThreshold('d', 31);

    // If the number of trial months exceeds 11 months, it will show up as "a year"
    moment.relativeTimeThreshold('M', 11);
  }

  /**
   * Sets the locale for MomentJS so that human readable strings are
   * automatically translated.
   * 
   * @param {String} locale 
   * 
   * @returns {void}
   */
  private setMomentLocale(locale: string): void {
    // MomentJS named the "fil" locale under "tl-ph" so as a precaution,
    // we must use the latter just in case the user tries to use "fil".
    moment.locale(locale === 'fil' ? 'tl-ph' : locale);
  }

}
