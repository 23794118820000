
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="4" class="text-center fmc-input-label">{{ 'reorder_levels_table' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="getReorderLevels.length > 0; else emptyItemCodesMessage">
              <tr *ngFor="let item of getReorderLevels; let i = index; ">
                <td class="form-group w-40">
                  <label class="font-weight-bold mb-0">{{ 'warehouse' | translate }}</label>
                  <p id="no_reorder_levels_warehouse_{{ i }}" class="mb-0">{{ item.warehouse_text }}</p>
                </td>
                <td class="form-group w-30">
                  <label class="font-weight-bold mb-0">{{ 'reorder_quantity' | translate }}</label>
                  <p id="no_reorder_levels_reorder_level_{{ i }}" class="mb-0">{{ item.min_reorder_level | placeholdWithString: 0 }}</p>
                </td>
                <td class="form-group w-30">
                  <label class="font-weight-bold mb-0">{{ 'min_stock_level' | translate }}</label>
                  <p id="no_reorder_levels_stock_level_{{ i }}" class="mb-0">{{ item.min_stock_level | placeholdWithString: 0 }}</p>
                </td>
              </tr>
            </ng-container>
            <ng-template #emptyItemCodesMessage>
              <tr>
                <td
                  id="no_reorder_levels"
                  class="text-center text-muted"
                  colspan="3">{{ 'no_reorder_levels' | translate }}</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  