export class WizardStep {
  title: string;
  icon?: string;
  completed?: boolean;
  editable?: boolean;
  optional?: boolean;

  constructor(proerties: WizardStep) {
    this.title = proerties.title;
    this.icon = proerties.icon || 'circle';
    this.completed = proerties.completed;
    this.editable = proerties.editable;
    this.optional = proerties.optional;
  }
}

export class WizardStepper {
  selected: number = 0;
  step1: WizardStep;
  step2: WizardStep;
  step3?: WizardStep;
  step4?: WizardStep;
  step5?: WizardStep;

  constructor(
    step1: WizardStep,
    step2: WizardStep,
    step3?: WizardStep,
    step4?: WizardStep,
    step5?: WizardStep
  ) {
    this.step1 = step1;
    this.step2 = step2;
    this.step3 = step3;
    this.step4 = step4;
    this.step5 = step5;
  }
}