<header>
  <div class="d-flex header-container header-label">
    <span class="header-title">
      <h4 class="title"><span>{{ objStockLevel.warehouse_name }}</span></h4>
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button class="btn ml-1 btn-primary" (click)="closeDialog()">
            <fa-icon [icon]="['fas', 'times']" transform="shrink-3" class="pointer"></fa-icon> {{ 'cancel' | translate }}
          </button>
        </li>
        <li class="list-inline-item">
          <button class="btn ml-1 btn-primary" (click)="save()" translate>
            <fa-icon [icon]="['fas', 'save']" transform="shrink-3" class="pointer"></fa-icon>
            {{ 'update_stock_location' | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>
<mat-dialog-content class="mat-dialog-content">
  <div class="flex-nowrap w-100 d-flex align-items-start justify-content-center mb-3">
    <div class="d-flex col-lg-2 justify-content-start labels">
      {{ 'location' | translate }}
    </div>
    <div class="col-lg-10">
      <input
        [(ngModel)]="objStockLevel.location"
        maxlength="32"
        class="form-control font-size-12"
      />
    </div>
  </div>
</mat-dialog-content>