import * as _moment from 'moment';
import { iif, Observable, of } from 'rxjs';
import { cloneDeep, get, isEmpty, isNil, isNull, merge, uniq } from 'lodash';
import { switchMap, map } from 'rxjs/operators';

import { MatDialog,  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';

import { Select } from '../../../../objects/select';
import { Relate } from '../../../../objects/relate';
import { LooseObject } from '../../../../objects/loose-object';
import { ViewService } from '../../../../services/view.service';
import { RecordService } from '../../../../services/record.service';
import { NotificationService } from '../../../../services/notification.service';
import { WizardService } from '../../../../features/wizard/services/wizard.service';
import { CustomTranslateService } from '../../../../services/custom-translate.service';
import { RelatedProductsComponent } from '../../../../features/product-folders/static-folders/related-products/related-products.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Router } from '@angular/router';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-wizard-purchase-order',
  templateUrl: './wizard-purchase-order.component.html',
  styleUrls: ['./wizard-purchase-order.component.scss'],
  providers: [ CustomTranslateService ]
})
export class WizardPurchaseOrderComponent implements OnInit {

  @ViewChild('ngSelectModule') ngSelectComponent: NgSelectComponent;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  public arItems: LineItem[] = [];

  public bLoading: boolean = true;

  public bSubmitted: boolean = false;

  public bSaving: boolean = false;

  private strRecordModule: string = null;

  private objJobRecord: LooseObject = {};

  private strPODeliverTo: string = null;
  private strDeliveryNotes: string = null;

  public objDeliveryConfig = {
    main_warehouse: {
      label: 'warehouse',
      module: 'warehouses',
      module_id: 'warehouse_id'
    },
    site: {
      label: 'site',
      module: 'sites',
      module_id: 'site_id'
    }
  };
  private objModuleRelate: Relate<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private viewService: ViewService,
    private wizardService: WizardService,
    private recordService: RecordService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<WizardPurchaseOrderComponent>,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.strRecordModule = !isNil(this.data.module) ? this.data.module : null;
  }

  ngOnInit() {
    iif(
      () => this.strRecordModule == 'jobs',
      this.recordService.getRecord('jobs', this.getJobId()),
      of({ record_details: {} })
    )
    .subscribe(response => {
      this.objJobRecord = response['record_details']
      this.getPurchaseOrderConfig();
    })
  }

  /**
   * retrieve purchase order config
   */
  getPurchaseOrderConfig(): void {
    if (this.objJobRecord.work_order_items) {

      let arItemIds: string[] = this.objJobRecord.work_order_items
        .filter(objLineItem => objLineItem.item_id)
        .map(objLineItem => objLineItem.item_id);
      iif( () => !isEmpty(arItemIds),
        this.recordService.getProductRecordData(null, null, null, false, null, arItemIds),
        of([])
      ).subscribe( response => {
        if (response && response.length > 0) {
          let arProductList = response;
          this.objJobRecord.work_order_items.forEach( objLineItem  => {
            if (objLineItem.item_id) {

              let objProduct = arProductList.find( objItem => objItem.id == objLineItem.item_id);
              objProduct['quantity'] = objLineItem.quantity;
              objProduct['unit_cost'] = objLineItem.unit_cost;
              objProduct['related_products'] = objLineItem.related_products;

              this.addItem(objProduct);
            }
          });
        }
        this.bLoading = false;
      });
    } else {
      this.bLoading = false;
    }
  }
4

  /**
   * add new line item
   */
  addItem(objItem: LooseObject = null): void {
    let objProductFilter = {
      labor: false, active: true, is_searchable: true
    };
    let objLineItem = new LineItem(
      new Relate<any>().buildRelates(
        switchMap(strTerm => this.recordService.getProductRecordData(strTerm, objProductFilter, '', true, null, [], true)),
        (objItem && objItem.id) ? [{ id: objItem.id, name: objItem.name }] : null,
        true
      ),
      new Relate<any>().buildRelates(
        switchMap(strTerm => this.recordService.getRecordRelate('customers', strTerm, false, false, { is_supplier: true }))
      ),
      objItem
    );

    if (objItem) {

      objLineItem.quantity = objItem.quantity || 1;
      objLineItem.unit_cost = objItem.unit_cost || 0;
      objLineItem.related_products = objItem.related_products;
      if (!isEmpty(objItem.preferred_supplier) || !isEmpty(objItem.supplier_pricing)) {

        this.updateCustomerRelate(objLineItem, objItem);
      }
    }

    this.arItems.push(objLineItem);
  }

  /**
   * we should update the amount when updating item
   *
   * @param objLineItem
   */
  onChangeItem(objLineItem: LineItem): void {

    objLineItem.description = null;
    objLineItem.unit_cost = 0.00;
    objLineItem.quantity = 1;
    objLineItem.is_unit_cost_changed = false;
    objLineItem.is_supplier_new_pricing = false;
    objLineItem.supplier_pricing_list = [];
    objLineItem.customer_relate.destroyTypehead();

    let objItem = objLineItem.item_relate.value;

    objLineItem.related_products = objItem.related_products;

    objLineItem.description = objItem.description;
    objLineItem.unit_cost = objItem.unit_cost;

    this.updateCustomerRelate(objLineItem, objItem)
  }

  /**
   * adding default value or default option on customer relate field
   *
   * @param objLineItem
   * @param objItem
   */
  updateCustomerRelate(objLineItem: LineItem, objItem: LooseObject = null): void {
    let objDefaultSupplier = objItem.preferred_supplier && objItem.preferred_supplier_text
      ? [ new Select(objItem.preferred_supplier, objItem.preferred_supplier_text, 'primary') ]
      : [];

    objLineItem.supplier_pricing_list = cloneDeep(objDefaultSupplier);
    objItem.supplier_pricing.forEach( supplierPricing => {
      objLineItem.supplier_pricing_list.push(
        new Select(supplierPricing['customer_id'], supplierPricing['customer_text'], '', supplierPricing)
      )
    });

    if (isEmpty(objDefaultSupplier)) {
      if (objItem.supplier_pricing && objItem.supplier_pricing.length == 1) {
        let objSupplierPricing = objItem.supplier_pricing[0];
        objDefaultSupplier = [
          new Select(
            objSupplierPricing['customer_id'],
            objSupplierPricing['customer_text'],
            '',
            objSupplierPricing
          )
        ]
      }
    }

    objLineItem.customer_relate.destroyTypehead();
    objLineItem.customer_relate = new Relate<any>().buildRelates(
      switchMap(strTerm => this.customerBuildRelate(objLineItem, strTerm)),
      objLineItem.supplier_pricing_list,
      !isEmpty(objDefaultSupplier)
    );

    if (objDefaultSupplier) {
      this.onChangeCustomer(objLineItem);
    }
  }

  /**
   * initialize relate request for customer relate field
   *
   * @param objLineItem
   * @param strTerm
   */
  customerBuildRelate(objLineItem: LineItem, strTerm: string): Observable<Select[]>{
    return this.recordService.getRecordRelate('customers', strTerm, false, false, { is_supplier: true })
      .pipe(
        map( response => {
          if (isEmpty(strTerm) && !isEmpty(objLineItem.supplier_pricing_list)) {
            return objLineItem.supplier_pricing_list;
          }
          return response;
        })
      );
  }

  /**
   * trigger's when customer relate is changed
   *
   * @param objLineItem
   */
  onChangeCustomer(objLineItem: LineItem): void {
    if (objLineItem.customer_relate.value && objLineItem.customer_relate.value.extras) {

      // when extras has value in customer relate, this means that it is from supplier pricing
      let objSupplierPricing = objLineItem.customer_relate.value.extras;
      objLineItem.unit_cost = parseFloat(objSupplierPricing.unit_cost);
    }
  }

  /**
   * trigger's changing from supplier reorder cost to default item's cost
   */
  onChangeUpdatePricing(objLineItem: LineItem, event): void {
    objLineItem.is_supplier_new_pricing = event.checked;
  }

  /**
   * trigger's when unit cost is changed
   *
   * @param objLineItem
   */
  onChangeUnitCost(objLineItem: LineItem): void {
    objLineItem.is_unit_cost_changed = true;
  }

  /**
   * remove and reindex Items
   *
   * @param index
   */
  removeItem(index: number): void {
    this.arItems.splice(index, 1);
    this.arItems.filter( objItems => objItems);
  }

  /**
   * Save Purchase Order as draft
   */
  savePurchaseOrder(): void {
    this.bSubmitted = true;
    this.bSaving = true;
    if (this.hasValidLineItems()) {
      let objPayload = this.getPayload();
      let customerId = uniq(objPayload.line_items.map( lineItem => lineItem.customer_id ));
      this.wizardService.savePurchaseOrderDetails(objPayload).subscribe( response => {
        this.notificationService.notifySuccess('multiple_po_created');
        this.dialogRef.close({
          status: 'success',
          redirect: customerId.length == 1
        });
      })
    } else {

      this.bSaving = false;
      this.notificationService.notifyWarning('fill_out_fields');
    }
  }

  /**
   * create a payload for saving purchase order
   */
  getPayload(): LooseObject {

    let arItems = this.arItems.map( objItems => {
      return {
        customer_id: objItems.customer_relate.value.id,
        item_id: (objItems.item_relate.value) ? objItems.item_relate.value.id : null,
        is_supplier_new_pricing: objItems.is_supplier_new_pricing,
        quantity: objItems.quantity,
        unit_cost: objItems.unit_cost,
        description: objItems.description,
        tax_code_id: objItems.tax_code_id,
        tax_code_name: objItems.tax_code_name,
      }
    });

    return {
      delivery_location: this.strPODeliverTo,
      delivery_notes: this.strDeliveryNotes,
      site_id: this.strPODeliverTo == 'site' ? this.objModuleRelate.value : null,
      warehouse_id: this.strPODeliverTo == 'main_warehouse' ? this.objModuleRelate.value : null,
      job_id: this.objJobRecord.id,
      line_items: arItems
    }
  }

  /**
   * validate all the form line items
   *
   * @returns boolean
   */
  hasValidLineItems(): boolean {
    return isEmpty(this.arItems.filter( objLineItem => {
      let objLineItemForm = new FormGroup({
        customer_relate: new FormControl(null, [Validators.required]),
        item_relate: new FormControl(null, [Validators.required]),
        quantity: new FormControl(null, [Validators.required]),
        unit_cost: new FormControl(null, [Validators.required]),
      });

      objLineItemForm.patchValue({
        customer_relate: objLineItem.customer_relate.value,
        item_relate: objLineItem.item_relate.value,
        quantity: objLineItem.quantity,
        unit_cost: objLineItem.unit_cost,
      });

      return !objLineItemForm.valid;
    }));
  }

  /**
   * Close the current dialog.
   */
  cancelDialog(): void {
    if (this.arItems.length) {
      // Pop-up modal for confirmation
      this.notificationService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close({
            status: 'cancel'
          });
        });
    } else {
      this.dialogRef.close({
        status: 'cancel'
      });
    }
  }

  /**
   * Add the related product to the list of line items.
   *
   * @param {LooseObject} objRecord
   */
  public addRelated(objRecord: LooseObject) {

    this.dialog.open(RelatedProductsComponent, {
      width: '70%',
      data: objRecord
    }).afterClosed().subscribe(response => {
      if (response && response.length > 0) {

        let strIds: string[] = [];

        response.forEach(item => {
          strIds.push(item['child_item_id'])
        });

        this.recordService.getProductRecordData(null, null, null, false, null, strIds).subscribe(response => {
          if (response && response.length > 0) {
            response.forEach(item => {
              this.addItem(item);
            });
          }
        });
      }
    });

  }

  /**
   * When the deliver to is changed.
   *
   * @param {string} strDeliverTo
   */
  public onDeliverToChange(strDeliverTo: string) {

    this.strDeliveryNotes = null;

    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }

    if (this.objModuleRelate) {
      this.objModuleRelate.$source.unsubscribe();
      this.objModuleRelate = null;
    }

    if (strDeliverTo == 'site') {
      this.objModuleRelate = new Relate<any>().buildRelates(
        switchMap(strTerm => this.recordService.getRecordRelate(this.objDeliveryConfig[strDeliverTo].module, strTerm, '', false)),
        this.objJobRecord.site_id ? [{id: this.objJobRecord.site_id, text: this.objJobRecord.site_text}] : null,
        true
      );
    }

    if (strDeliverTo == 'main_warehouse') {
      this.objModuleRelate = new Relate<string>().buildRelates(
        switchMap(strTerm => this.recordService.getRecordRelate(this.objDeliveryConfig[strDeliverTo].module, strTerm, '', false))
      );
    }
  }

  /**
   * get current url job id
   *
   * @return {string}
   */
  getJobId(): string {
    let arUrl = this.router.url.split('/')
    arUrl.splice(0, 2);

    return arUrl[0];
  }

  /**
   * When the module relate field changes.
   *
   * @param {LooseObject} objSelectedItem
   */
  public onModuleChange(objSelectedItem: LooseObject) {
    if (this.strPODeliverTo == 'main_warehouse' && objSelectedItem) {
      this.strDeliveryNotes = objSelectedItem['delivery_instructions'];
    }
  }
}

export class LineItem {

  customer_relate: Relate<any>;
  item_relate: Relate<any>;
  description?: string;
  quantity: number;
  unit_cost: number;
  department_relate: Relate<any>;
  tax_code_relate: Relate<any>;
  account_code_relate: Relate<any>;
  amount: number;
  supplier_pricing_list: LooseObject[];
  is_unit_cost_changed: boolean;
  is_supplier_new_pricing: boolean;
  use_supplier_pricing: boolean;
  related_products: LooseObject[];
  tax_code_id?: string;
  tax_code_name?: string;

  constructor(
    itemRelate: Relate<any>,
    customerRelate: Relate<any>,
    lineItem: LooseObject = {}
  ) {
    this.customer_relate = customerRelate;
    this.item_relate = itemRelate;
    this.description = get(lineItem, 'description', null);
    this.quantity = 1;
    this.unit_cost = 0.00;
    this.amount = 0.00;
    this.is_unit_cost_changed = false;
    this.is_supplier_new_pricing = false;
    this.use_supplier_pricing = false;
    this.supplier_pricing_list = [];
    this.related_products = [];
    this.tax_code_id = get(lineItem, 'tax_code_id', null);
    this.tax_code_name = get(lineItem, 'tax_code_name', null);
  }

}