import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LooseObject } from '../../../../../../objects/loose-object';
import { CalendarService } from '../../../../services/calendar.service';

@Component({
  selector: 'quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit {
  /**
   * Contains the quote details
   *
   * @type {LooseObject}
   */
  @Input() quoteDetails: LooseObject;

  /**
   * A flag that indicates that the current user is viewing this task
   * in behalf of the child client and this will only be valid if
   * the trigger came from the scheduled task (via calendar click)
   *
   * @type {boolean}
   */
  @Input() bIsBehalfOfChildClient: boolean;

  /**
   * Flags that checks if the edit button is clicked.
   *
   * @type {LooseObject}
   */
  @Output() quoteFormEdit = new EventEmitter<boolean>();


  constructor(
    protected calendarService: CalendarService,
  ) {}

  ngOnInit() { }

  /**
   * Creates the URL for links to other modules
   *
   * @param {string} moduleName
   * @param {string|undefined} moduleId
   *
   * @returns {string}
   */
  createUrl(moduleName: string, moduleId: string | undefined = undefined): string {
    return this.calendarService.createUrl(moduleName, moduleId, this.bIsBehalfOfChildClient);
  }

  /**
   * Send flag to parent to initialize editing quote details.
   *
   * @returns {void}
   */
  emitEditModeStatus(): void {
    this.quoteFormEdit.emit(true);
  }
}