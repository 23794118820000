<span id="confirmationHeader" class="confirmation-header">{{ objConfirmation.header | translate }}</span>
<hr>
<mat-dialog-content>
    <div id="confirmationMessage" class="confirmation-body text-center">{{ objConfirmation.message | translate  }}</div>
    <hr>
    <div *ngIf="objConfirmation.type == 'default'" class="form-group text-center mb-0">
        <button id="btnConfirmationYes" tabindex="-1" mat-button (click)="closeDialog(true)" >
            {{ objConfirmation.options.trueVal | translate }}
        </button>
        <button id="btnConfirmationNo" tabindex="-1" mat-button (click)="closeDialog(false)">
            {{ objConfirmation.options.falseVal | translate }}
        </button>
    </div>
    <div id="btnConfirmationOk" *ngIf="objConfirmation.type == 'ok'" class="text-center">
        <button tabindex="-1" mat-button (click)="closeDialog(true)" >Ok</button>
    </div>
</mat-dialog-content>
