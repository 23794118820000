import { Injectable } from '@angular/core';
import { HighlightTag } from 'angular-text-input-highlight';
import { Relate } from '../objects/relate';
import { GlobalRecord } from '../objects/global-record';
import { MentionConfig } from 'angular-mentions';
import { cloneDeep } from 'lodash';

@Injectable()
export class MentionsService {

  /**
   * The settings for the Angular Mentions library.
   *
   * @var {MentionConfig}
   */
  public objMentionSettings: MentionConfig = {
    maxItems: 10,
    labelKey: 'name',
    mentionSelect: (item: GlobalRecord, triggerChar: string) => {
      return triggerChar + item.other_properties.first_name;
    }
  };

  /**
 * List of users mentioned in the message.
 *
 * @var {HighlightTag[]}
 */
  public arMentions: HighlightTag[] = [];

  /**
   * The current start point of a mention.
   *
   * @var {number}
   */
  public numCurrent = 0;

  /**
   * Relate object containing the typeheads and other
   * necessary properties to use the Ng-Select field
   * properly.
   *
   * @var {Relate}
   */
  public objUsersRelate: Relate<GlobalRecord> = new Relate<GlobalRecord>();

  constructor() { }

  /**
   * Add selected user to the mention list.
   *
   * @param {GlobalRecord} event
   *
   * @returns {void}
   */
  addMention(objGlobalRecord: GlobalRecord): void {

    let objMentionData = {
      id: objGlobalRecord.id,
      name: objGlobalRecord.other_properties.first_name,
      full_name: objGlobalRecord.name
    };

    this.arMentions.push({
      indices: {
        start: this.numCurrent,
        end: this.numCurrent + objMentionData.name.length + 1
      },
      data: objMentionData
    });

  }

  /**
   * Triggers when typing on the mention textarea library.
   *
   * @param {string} strSearch
   *
   * @returns {void}
   */
  searchUsers(strSearch: string, strMessageValue: string): void {
    this.numCurrent = strMessageValue.length - strSearch.length;
    this.objUsersRelate.typehead.next(strSearch);
  }

  /**
   * Checks the tags everytime there is a change in the message_chat or message_sms form.
   *
   * @returns {{textarea_value: string, textarea_cursor: number} | null}
   *
   */
  checkTags(strMessageValue: string): {textarea_value: string, textarea_cursor: number} | null {


    let arMentionsCopy: HighlightTag[] = cloneDeep(this.arMentions);
    this.arMentions = [];

    arMentionsCopy.forEach((objMentionCopy: HighlightTag) => {
      let numMentionedNameIndex: number = (strMessageValue) ? strMessageValue.indexOf('@' + objMentionCopy.data.name) : -1;

      this.arMentions.forEach(mention => {
        if (mention.indices.start == numMentionedNameIndex) {
          let numIndiceEnd: number = mention.indices.end + 1;
          let strRemainingText: string = strMessageValue.slice(numIndiceEnd);
          numMentionedNameIndex = strRemainingText.indexOf('@' + objMentionCopy.data.name) + numIndiceEnd;
        }
      });

      if (strMessageValue &&
        strMessageValue.substr(objMentionCopy.indices.start, 1) == '@' &&
        strMessageValue.substr(objMentionCopy.indices.start + 1, objMentionCopy.data.name.length) != objMentionCopy.data.name
      ) {

        let strNewFormValue: string = strMessageValue;
        let numMentionedIndexStart: number = objMentionCopy.indices.start;
        let numCount: number = 0;

        while (strMessageValue.substr(numMentionedIndexStart, 1) == ('@' + objMentionCopy.data.name).charAt(numCount)) {
          numMentionedIndexStart++;
          numCount++;
        }

        strNewFormValue = strNewFormValue.slice(0, numMentionedIndexStart - numCount) + strNewFormValue.slice(numMentionedIndexStart);

        return { textarea_value: strNewFormValue, textarea_cursor: objMentionCopy.indices.start }

      } else if (numMentionedNameIndex != objMentionCopy.indices.start) {

        objMentionCopy.indices.start = numMentionedNameIndex;
        objMentionCopy.indices.end = numMentionedNameIndex + objMentionCopy.data.name.length + 1;
        this.arMentions.push(objMentionCopy);

      } else {
        this.arMentions.push(objMentionCopy);
      }
    });

    return null;
  }

  /**
   * Retrieve all the UUID of the users
   * in the list of mentions.
   *
   * @returns {string[]}
   */
  getAllMentionedPeopleId(): string[] {
    return this.arMentions.map(mentioned => {
      return mentioned.data.id;
    });
  }

}
