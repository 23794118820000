<h1 mat-dialog-title><strong>{{ 'you_are_about_to_unschedule_this_task' | translate }}</strong></h1>
<div mat-dialog-content>
  <p>{{ 'do_you_wish_to_proceed' | translate }}?</p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button cdkFocusInitial [disabled]="bShowLoader" class="btn btn-primary btn-lg mr-2" (click)="cancelTaskUnscheduling()">
    <fa-icon [icon]="['fas', 'times']" class="mr-1"></fa-icon>
    <span class="pl-1">{{ 'close' | translate }}</span>
  </button>
  <button class="btn btn-danger btn-lg" [disabled]="bShowLoader" (click)="unscheduleTask()">
    <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
    <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'save']" class="mr-1"></fa-icon>
    <span class="pl-1">OK</span>
  </button>
</div>
