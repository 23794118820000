import { Component, Injector, Input, OnInit } from '@angular/core';
import { ClientStoreService } from '../../../../services/client-store.service';
import { ViewRecord } from '../view.component';
import { get } from 'lodash';
import { SubscriptionRestrictionService } from '../../../../services/subscription-restriction/subscription-restriction.service';
import { SubscriptionId } from '../../../../objects/subscription-plans';

@Component({
  selector: 'app-view-actions-button',
  templateUrl: './actions-button.component.html',
  styleUrls: [
    './actions-button.component.scss'
  ],
})
export class ViewActionsButtonComponent implements OnInit {
  /**
   * @type {string}
   *
   * @readonly
   */
  @Input('module') readonly module: string;

  /**
   * @type {Action[]}
   */
  @Input('actions') actions: Action[] = [];

  /**
   * @type {ViewRecord}
   *
   * @readonly
   */
  @Input('record') readonly record: ViewRecord;

  /**
   * Create instance of the component
   *
   * @param {ClientStoreService} clients
   * @param {Injector} ctx
   */
  constructor(
    protected readonly clients: ClientStoreService,
    protected readonly ctx: Injector,
    protected readonly subscription: SubscriptionRestrictionService
  ) {}

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    const client = this.clients.getActiveClient();

    // filters all the actions if current active user has allowed a specific permission
    this.actions = this.actions.filter(
      (action) => {
        let viewable = true;

        if (action.allows !== undefined) {
          viewable =  get(client, 'enable_' + action.allows, false);
        }

        if (action.min_subscription_required !== undefined) {
          viewable = this.subscription.subscriptionPlanIsGreaterThanOrEqualTo(client, action.min_subscription_required);
        }

        return viewable
      }
    );
  }

  /**
   * Handles action click
   *
   * @param {Action} action
   * @param {Event}  ev
   *
   * @returns {void}
   */
  onClick(action: Action, ev: Event): void {
    return action.handle(this.record, this.ctx, ev);
  }
}

export interface Action {
  id: string;
  label: string;
  handle: (record: ViewRecord, ctx: Injector, ev: Event) => void;
  allows?: string;
  min_subscription_required?: SubscriptionId;
}