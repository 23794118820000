import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorPublishingService {
  /**
   * @type {Subject<ErrorEvent>}
   */
  protected readonly subject$ = new Subject<ErrorEvent>();

  /**
   * Returns the observable which consumers will listens to
   *
   * @returns {Observable<ErrorEvent>}
   */
  onError(): Observable<ErrorEvent> {
    return this.subject$.asObservable();
  }

  /**
   * Publishes the error to the listeners
   *
   * @param {ErrorEvent} objError
   *
   * @returns {void}
   */
  publish(objError: ErrorEvent): void {
    this.subject$.next(objError);
  }
}

export enum ErrorEventType {
  HTTP_ERROR, // the type for http errors received
};

type HttpErrorEvent = {
  /**
   * The type of error
   *
   * @type {ErrorEventType.HTTP_ERROR}
   */
  type: ErrorEventType.HTTP_ERROR;
  /**
   * Status code recevied from the http request 4xx, 5xx
   *
   * @type {number}
   */
  status: number;
};

export type ErrorEvent = {
  for?: string;
} & HttpErrorEvent;