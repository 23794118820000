import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from './components/generate-pdf/components/preview/preview.component';
import { GeneratePDFComponent } from './components/generate-pdf/generate-pdf.component';
import { MatDialogModule } from '@angular/material';
import { FontAwesomeModule } from '../../../shared/modules/font-awesome.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CustomFieldsComponent } from './components/generate-pdf/components/custom-fields/custom-fields.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule as AppSharedModule } from '../../../shared/modules/shared.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FormTemplateService } from './services/form-template.service';
import { FormatPipe as DateFormatPipe } from '../../../pipes/moment/format/format.pipe';
import { CustomCurrencyPipe as CurrencyPipe } from '../../../pipes/custom-currency-pipe';
import { ActivitiesService } from '../../../services/activities.service';
import { AnnualConditionReportComponent } from './components/generate-pdf/dialog/annual-condition-report/annual-condition-report.component';
import { AnnualAssetReportComponent } from './components/generate-pdf/dialog/annual-asset-report/annual-asset-report.component';
import { EditModule } from '../../../shared/modules/edit.module';
import { SaveAndPreviewDefaultTemplatecomponent } from './components/buttons/save-and-preview/save-and-preview.component';
import { PreviewDefaultTemplateComponent } from './components/buttons/preview-default-template/preview-default-template.component';
import { ButtonsComponent as RecordButtonComponent } from '../../../admin/import/import-record/buttons/buttons.component';
import { ProfitabilityAnalysisButtonComponent } from '../../jobs/profitability-analysis/button/button.component';
import { PreviewComponent as PreviewButtonComponent } from './components/buttons/preview/preview.component';

@NgModule({
  entryComponents: [
    PreviewComponent,
    CustomFieldsComponent,
    AnnualAssetReportComponent,
    AnnualConditionReportComponent,
  ],
  declarations: [
    GeneratePDFComponent,
    PreviewComponent,
    CustomFieldsComponent,
    AnnualAssetReportComponent,
    AnnualConditionReportComponent,
    SaveAndPreviewDefaultTemplatecomponent,
    PreviewDefaultTemplateComponent,
    RecordButtonComponent,
    ProfitabilityAnalysisButtonComponent,
    PreviewButtonComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FontAwesomeModule,
    PdfViewerModule,
    ReactiveFormsModule,
    AppSharedModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    EditModule
  ],
  exports: [
    GeneratePDFComponent,
    SaveAndPreviewDefaultTemplatecomponent,
    PreviewDefaultTemplateComponent,
    RecordButtonComponent,
    ProfitabilityAnalysisButtonComponent,
    PreviewButtonComponent,
  ],
  providers: [
    CurrencyPipe,
    DateFormatPipe,
    FormTemplateService,
    ActivitiesService,
  ]
})
export class SharedModule { }
