import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '../../shared/modules/font-awesome.module';
import { FileUploadComponent } from '../components/forms/input/file-upload/file-upload.component';
import { DragAndDropFileUploadComponent } from '../components/forms/input/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { HeaderComponent } from '../components/forms/header/header.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    DragAndDropFileUploadComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule
  ],
  exports: [
    FileUploadComponent,
    DragAndDropFileUploadComponent,
    FontAwesomeModule,
    HeaderComponent
  ]
})
export class FormsModule { }
