import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { get } from 'lodash';
import { CustomFieldViewComponent, Form } from '../../../../base/form';
import { CUSTOM_FIELD_VIEW_SELECTORS } from './config';

@Component({
  selector: 'fc-custom-view-field',
  template: `
    <ng-container #container></ng-container>
  `
})
export class ViewCustomFieldComponent implements OnInit {
  /**
   * The field data
   */
  @Input('field') field: Form<unknown>

  /**
   * The template reference that will be replaced by the custom component to be display
   */
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  /**
   * Create instance of the component that is responsible for rendering the custom template component
   * for the current custom field
   */
  constructor(
    private readonly cfr: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    const ref = this.container.createComponent<CustomFieldViewComponent>(this.cfr.resolveComponentFactory(
      get(CUSTOM_FIELD_VIEW_SELECTORS, this.field.custom_form_options.component_selector)
    ));

    ref.instance.setField(this.field);
  }
}