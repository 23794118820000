import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-activity-log-details-dialog',
  templateUrl: './activity-log-details-dialog.component.html',
  styleUrls: ['./activity-log-details-dialog.component.scss']
})
export class ActivityLogDetailsDialogComponent implements OnInit {

  /**
   * store's the activity type name
   */
  strActivityTypeName: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData,
    protected notificationService: NotificationService,
    protected dialogRef: MatDialogRef<ActivityLogDetailsDialogComponent>,
  ) {
    this.strActivityTypeName = this.dialogData.name;
  }

  ngOnInit() {

  }

  /**
   * close activity log dialog
   *
   * @returns {void}
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * send the confirmation to calendar components that we have delete
   * action for the selected activty log
   *
   * @returns {void}
   */
  deleteActivityLog(): void {
    this.notificationService.sendConfirmation('confirm_delete')
    .filter(confirmation => confirmation.answer == true)
    .subscribe(() => {
      this.dialogRef.close({
        action: 'delete'
      });
    });
  }
}
