import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-name-quote',
  templateUrl: './name-quote.component.html',
  styleUrls: ['./name-quote.component.scss']
})
export class NameQuoteComponent implements OnInit {

  public strQuoteName: string = '';
  public isClicked = false;

  constructor(
    public dialogRef: MatDialogRef<NameQuoteComponent>
  ) { }

  ngOnInit() {}

  dialogClose() {
    this.dialogRef.close();
  }

  saveName() {
    this.isClicked = true;
    if (this.strQuoteName != '') {
      this.dialogRef.close(this.strQuoteName);
    }
  }

}
