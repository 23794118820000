import * as _ from 'lodash';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { LinkChecklistComponent } from './link-checklist/link-checklist.component';
import { ListingService } from '../../../../services/listing.service';
import { ChecklistResponseComponent } from './checklist-response/checklist-response.component';
import { StatusColors } from '../../../../lists/listing-fields';
import { RecordService } from '../../../../services/record.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { NotificationService } from '../../../../services/notification.service';
import { ViewService } from '../../../../services/view.service';
import { PdfComponent } from '../../view/pdf/pdf.component';
import { ClientStoreService } from '../../../../services/client-store.service';

@Component({
  selector: 'app-checklists',
  templateUrl: './checklists.component.html',
  styleUrls: ['./checklists.component.scss'],
  providers: [ListingService]
})
export class ChecklistsComponent implements OnInit {
  @Input() strRecordId: string;

  /**
   * Where will this module be placed? Data fetched for the
   * widget will be related to the given record (module + record id)
   * so this should always be set correctly.
   */
  @Input() strModule: string;

  @Output() refreshJobAssets = new EventEmitter<any>();

  public arLinkedChecklist = [];
  public objColors = new StatusColors;
  public bLoader = false;
  public isAdminUser = false;
  public bProcessed: boolean = true;

  constructor (
    public clientStoreService: ClientStoreService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public recordService: RecordService,
    public translateService: TranslateService,
    public listingService: ListingService,
    public viewService: ViewService,
    private notifService: NotificationService,
    protected localStorageService: LocalStorageService
  ) {
    this.isAdminUser = this.clientStoreService.getActiveClient().level === 'admin';
  }


  ngOnInit() {
    this.fetchData('default');
  }

  // Get all checklists for linking
  linkChecklists() {

      let objChecklist = {
          width: '600px',
          height: 'auto',
          // Data to be passed on
          data: {
            record_id : this.strRecordId,
            module: this.strModule
          },
          disableClose: true
      };



      let tabDialogRef;

      tabDialogRef = this.dialog.open(LinkChecklistComponent, objChecklist);

      // Get response after closing or submitting files dialog
      tabDialogRef.afterClosed().subscribe(
          data => {
              this.fetchData('default');
          }
      );
  }

    convertChecklistType(type) {
        switch(type) {
          case 'job' :
            return "job_checklist"
          case 'opportunity' :
            return "quote_checklist";
          case 'asset' :
            return "asset_checklist"
        }
    }

    /**
     * Fetch the list of linked checklist responses.
     * @param strPage - what page is currently to be viewed.
     */
    fetchData(strPage) {
      let objPagination = this.listingService.beforeFetching(strPage);
      this.bLoader = false;

      if (this.strRecordId) {
        let strFilterKey: string = this.strModule === 'opportunities' ? 'opportunity_id' : 'job_id';
        let objFilter = {
          [strFilterKey]: this.strRecordId,
          'order_by': {id: 'created_at', sort: 'desc'},
        };

        this.listingService.fetchData(JSON.stringify(objPagination['objPage']), 'checklist_responses', JSON.stringify(objFilter)).subscribe(response => {
            this.bLoader = true;
            this.arLinkedChecklist = response['data'];
            this.listingService.afterFetching(response, strPage);
        });
      } else {
        this.notifService.notifyWarning('error_in_fetching_checklist');
        this.arLinkedChecklist = [];
        this.bLoader = true;
      }
    }

    /**
     * Calls Checklist Response dialog to edit prompts
     *
     * @param checklistResponse
     */
    addResponseDialog(checklistResponse) {
      this.recordService.getRecordBasedOnParent(this.strModule === 'opportunities' || this.strModule === 'jobs')
      .subscribe( response => {
        let objParentRecord = response['record_details'];
        let objChecklist = {
          width: '1200px',
          height: 'auto',
          // Data to be passed on
          data: {
            checklist_response : checklistResponse,
            response_type : 'main_prompts',
            job_number :  objParentRecord['job_number'],
            opportunity_number :  objParentRecord['opportunity_number'],
            module: this.strModule,
            fault : {
              'details' : checklistResponse['fault_details'],
              'products' : checklistResponse['product_id']
            }
          },
          disableClose: true
        };

        let tabDialogRef;

        tabDialogRef = this.dialog.open(ChecklistResponseComponent, objChecklist);

        // Get response after closing or submitting files dialog
        tabDialogRef.afterClosed().subscribe(
            data => {
                this.fetchData('default');
            }
        );
      });
    }

  /**
   * Removes checklist response record and the asset type prompts associated to it (if there's any)
   *
	 * @param string record id
   *
   * @return void
   */
    deleteRecord(strRecordId): void{
      // Confirmation for deleting the checklist response
      this.notifService.sendConfirmation('confirm_delete_checklist_response', 'warning')
        .subscribe(confirmation => {
          if (confirmation.answer) {
            this.recordService.deleteRecord('checklist_responses', strRecordId).subscribe(result => {

              // Refresh checklist response record
              this.fetchData('reload');

              // Refresh job record
              this.refreshJobAssets.emit({ widget: 'assets' });
            });
          }
        });
    }

    /**
     * Explode array of period to be separated by comma
     * Translate it before returning
     */
    convertPeriod(period) {

      let arTranslatedPeriod = [];

      if (JSON.parse(period).length > 0) {
        JSON.parse(period).forEach(element => {
            this.translateService.get([element]).subscribe( translateValues => {

              arTranslatedPeriod.push(translateValues[element]);
            });
        });
        return arTranslatedPeriod.join(', ');
      }
    }

    /**
     * Preview pdf
     *
     * @param objCheckListRecord
     */
    previewCheckLists(objCheckListRecord: object = {}): void {

        let strPdfHeader = 'checklist';
        let objWhereClause =  {
            job_id: this.strRecordId
        }

        if (objCheckListRecord['checklist_id']) {

            strPdfHeader = objCheckListRecord['name'];
            objWhereClause['checklist_id'] = objCheckListRecord['checklist_id'];
        }
        this.bProcessed = false;
        this.recordService.getPDFRecordData('checklist_responses', null, objWhereClause).subscribe( response => {

            let arRecord = response.body;
            if (arRecord) {

                // Compile dialog request
                let dialogConfig : {[k: string]: any} = {
                    data: {
                        module: 'checklist',
                        label: strPdfHeader,
                        response: arRecord
                    },
                    disableClose:true
                };

                // If mobile
                if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
                    // Display the pop up in full screen (WHOLE PAGE)
                    dialogConfig.width = '100%';
                    dialogConfig.height = '100%';
                    dialogConfig.maxHeight = '100vh';
                    dialogConfig.maxWidth = '100vw';
                } else {
                    // display as pop up if not mobile
                    dialogConfig.width = '80%';
                    dialogConfig.height = '80%';
                }
                this.dialog.open(PdfComponent, dialogConfig);
            }
            this.bProcessed = true;
        });
    }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arLinkedChecklist = []; // clear list
    this.fetchData('default');
  }

  ngOnChanges(): void {
    this.onRefresh();
  }
}
