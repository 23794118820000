import { Component } from '@angular/core';
import { CustomFieldViewComponent, Form } from '../../../../base/form';
import { Item } from '../../../../objects/item';

@Component({
  selector: 'fc-view-related-products',
  template: `
    <div class="row">
      <div class="col-12">
        <table
          class="table table-bordered">
          <thead>
            <tr>
              <th colspan="5" class="text-center fmc-input-label">{{ 'related_products' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-template #emptyRelatedItemMessage>
              <tr>
                <td class="text-center text-muted" colspan="4">{{ 'no_related_products' | translate }}</td>
              </tr>
            </ng-template>
            <ng-container *ngIf="arItems.length > 0; else emptyRelatedItemMessage">
              <tr *ngFor="let item of arItems; let i = index;">
                <td class="form-group" width="20%">
                  <div><b>{{ 'item_code' | translate }}</b></div>
                  <div>{{ item['item_code'] | translate }}</div>
                </td>
                <td class="form-group" width="20%">
                  <div><b>{{ 'item_name' | translate }}</b></div>
                  <div>{{ item['item_name'] | translate }}</div>
                </td>
                <td class="form-group">
                  <div><b>{{ 'description' | translate }}</b></div>
                  <div>{{ item['description'] | translate | slice:0:128 }}</div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  `
})
export class ViewRelatedProductsComponent implements CustomFieldViewComponent<RelatedProductDisplay[]> {

  /**
   * List of items to display.
   *
   * @var {RelatedProductDisplay[]}
   */
  arItems: RelatedProductDisplay[] = [];

  /**
   * @inheritdoc
   */
  setField(field: Form<RelatedProductDisplay[]>): void {
    this.arItems = field.default_value;
  }

}

export interface RelatedProductDisplay {
  item_code: string;
  item_name: string;
  description: string;
  child_item_id: string;
}
