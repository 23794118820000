<header>
  <div class="d-flex dialog-header">
    <h3 class="title">
      <span>
        <fa-icon [icon]="['fas', 'folder']" class="mr-1 text-primary"></fa-icon>{{ 'Browse Products' }}
      </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="dialogRef.close()" class="btn ml-1 btn-danger">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr>
<div>
  <mat-dialog-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0 mb-2">
        <div class="input-group pt-2" >
          <div class="input-group-prepend">
            <span class="input-group-text" style="background-color: transparent;">
              <fa-icon [icon]="['fas', 'search']" aria-hidden="true"></fa-icon>
            </span>
          </div>
          <input type="text" [formControl]="objSearchFolder" class="form-control search-folder-input" placeholder="{{ 'search_item_or_folder' | translate }}">
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="m-1">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

            <mat-tree-node
              *matTreeNodeDef="let node; when: hasChild"
              [hidden]="node['metadata']['name'] == 'unallocated' && dataSource.bHideUnallocated"
              [matTreeNodePadding]="node['level']"
              [ngClass]="{'bg-highlight': node['include']}">

              <ng-container *ngIf="node.id != 'next_page'">
                <span matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                  <fa-icon *ngIf="treeControl.isExpanded(node)" [icon]="['fas', 'minus-square']" class="mr-2 text-primary"></fa-icon>
                  <fa-icon *ngIf="!treeControl.isExpanded(node)" [icon]="['fas', 'plus-square']" class="mr-2 text-primary"></fa-icon>
                </span>

                <span
                  class="mr-2"
                  matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.name">
                  <a href="javascript:;">
                    <span *ngIf="!dataSource.bHideUnallocated">{{ node['metadata']['name'] | translate }}</span>
                    <span *ngIf="dataSource.bHideUnallocated">{{ node['metadata']['full_path'] | translate }}</span>
                  </a>
                </span>
              </ng-container>

              <ng-container *ngIf="node.id == 'next_page'">
                <span>
                  <fa-icon [icon]="['far', 'chevron-double-right']" class="mr-2 text-primary"></fa-icon>
                </span>
                <span
                  matTooltip="{{ 'load_more_items' | translate }}"
                  matTooltipPosition="above"
                  class="mr-2"
                  [attr.aria-label]="'toggle ' + node.name">
                  <a href="javascript:;" (click)="dataSource.loadNodePages(node)">{{ node['metadata']['name'] }}</a>
                </span>
              </ng-container>

              <ng-container *ngIf="node.is_loading">
                <fa-icon [icon]="['fas','spinner']" [spin]="true"></fa-icon>
              </ng-container>

              <span *ngIf="node.id != 'next_page' && node.id != 'unallocated'" class="d-flex flex-row ml-auto p-1 align-items-center" (click)="addProducts(node, 'folder')">
                <fa-icon [icon]="['fas', 'plus-circle']" class="text-success ml-1 plus-margin pointer" size="lg"></fa-icon>
              </span>

            </mat-tree-node>


            <mat-tree-node
              *matTreeNodeDef="let node"
              [matTreeNodePadding]="node['level']"
              [ngClass]="{'bg-highlight': node['include']}">

              <span class="pointer pl-2">
                <fa-icon [icon]="['fal', 'box-open']" class="mr-2"></fa-icon>
              </span>

              <span class="mr-2">
                [{{ node['metadata']['code'] }}] {{ node['metadata']['name'] }}
                <fa-icon (click)="loadStockLevels(node)" [icon]="['fal', 'info-circle']"
                  [popover]="popTemplate"
                  container="body"
                  [outsideClick]="true"
                  class="mr-2 text-primary"
                  size="xs">
                </fa-icon>
              </span>

              <span class="d-flex flex-row ml-auto p-1 align-items-center">
                <ng-container *ngIf="node['metadata']">
                  <span *ngIf="node['metadata']['labor'] === false && node['metadata']['unit']" matTooltip="{{ 'unit' | translate }}" class="mr-2"> {{ node['metadata']['unit'] }}</span>
                  <span matTooltip="{{ 'sell_price' | translate }}" class="mr-2">{{ node['metadata']['unit_price'] | currency }}</span>
                  <span *ngIf="node['metadata']['labor'] === false" matTooltip="{{ 'total_stock_level' | translate }}" class="mr-2">({{ node['metadata']['current_stock_level'] || '0.000' }})</span>

                </ng-container>
                <input *ngIf="!hideQuantity" class="form-control quantity-input" type="text" [(ngModel)]="node['quantity']"/>
                <fa-icon [icon]="['fas', 'plus-circle']" class="text-success ml-1 plus-margin pointer" size="lg" (click)="addProducts(node, 'item', node['quantity'] || 1)"></fa-icon>
              </span>

              <ng-template #popTemplate>
                <div class="row">
                  <div class="col-12">{{ 'cost_price' | translate }}:
                    <span *ngIf="node['metadata']['labor'] === false">{{ node['metadata']['unit_cost'] | currency }}</span>
                    <span *ngIf="node['metadata']['labor'] === true">{{ node['metadata']['hourly_cost'] | currency }}</span>
                  </div>
                  <ng-container *ngIf="node['metadata']['labor'] === false">
                    <div *ngIf="!node['metadata']['stock_levels']" class="col-12 mt-2"><small>{{ 'loading_stock_breakdown' | translate }}...</small></div>
                    <div *ngIf="node['metadata']['stock_levels']" class="col-12 mt-2">
                      <small *ngIf="node['metadata']['stock_levels'].length == 0">{{ 'no_record_found' | translate }}</small>
                      <div *ngFor="let stocks of node['metadata']['stock_levels']" class="row">
                        <div class="col-12">{{ stocks['warehouse_name'] }}: {{ stocks['current_stock_level'] }}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-template>

            </mat-tree-node>




          </mat-tree>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!dataSource.bLoading && dataSource.data.length == 1 && dataSource.bHideUnallocated" class="text-center">
    <span class="no-results-text text-secondary">
      <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>{{ 'no_results_found' | translate }}
    </span>
  </div>

  <div *ngIf="dataSource.bLoading" class="text-center">
    <span>
      <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="fa-2x text-secondary"></fa-icon>
    </span>
  </div>

  <div *ngIf="!dataSource.bLoading && dataSource.bHasNextPage">
    <div class="text-center pb-3">
      <span class="span-link" (click)="refreshList()" style="font-size: 13px;">{{ 'load_more_items' | translate }}</span>
    </div>
  </div>
  </mat-dialog-content>
</div>
