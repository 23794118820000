import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
const kBaseUrl: string = environment.url + "/document_library/";

@Injectable()
export class DocumentService {

  constructor (private http: HttpClient, private router: Router) {}

  // Upload a new document in s3 and save data in table
  addDocument(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "create", body.toString());
  }

  // Reupload a new version of pdf file
  updateDocument(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "update", body.toString());
  }

  // Get all documents in library
  getDocuments(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "get", body.toString());
  }

}
