import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from '../../../../services/notification.service';

import { ViewService } from '../../../../services/view.service';
import { RecordService } from '../../../../services/record.service';

@Component({
  selector: 'app-statuswidget',
  templateUrl: './statuswidget.component.html',
  styleUrls: ['./statuswidget.component.scss']
})
export class StatuswidgetComponent implements OnInit {

  @Input() objRecordDetails: object;
  
  public strStatusSelected: any = [];
  public arStatusList: any = [];
  public arStatusCustomCSS: any = [];
  public numInstanceId = Math.floor(Math.random() * 100000) + 1;
  public strField = 'status';
  public objPointOfNonConvertible = {
    'leads': 4
  };

  constructor(
    private viewService: ViewService,
    private notifService: NotificationService,
    private recordService: RecordService
  ) {
    // Waiting to pass status list
    this.viewService.arStatusWidget.distinctUntilChanged().subscribe( objData => {

      if (objData) {

        // Set the field name.
        if (objData['field'] != undefined) {
          this.strField = objData['field'];
        }
        // Make sure this is is always empty before creating
        this.arStatusList = [];
        // Do we have option and id key?
        if (objData['id'] && objData['option']) {
          let intPosition = 1;
          // Create status list
          objData['option'].forEach( data => {
            // Do we have these values?
            if (data['id'] && data['text']) {
              this.arStatusList.push({
                id: data['id'],
                label: data['text'],
                position: intPosition
              });
              intPosition++;
            }
          });
          // Do we have list?
          if (this.arStatusList) {
            // Sort status by position
            this.arStatusList.sort((a, b) => {
              if (a.position < b.position) return -1;
              else if (a.position > b.position) return 1;
              else return 0;
            });

            // Find the index.
            let numStatusIndex = this.arStatusList.findIndex(x=>x['id'] == objData['id']);

            // If it's not there, set as the first.
            if (numStatusIndex == -1) {
              this.strStatusSelected = this.arStatusList[0];
            } else {
              // Store selected status
              this.strStatusSelected = this.arStatusList[numStatusIndex];
            }

            // Count and divide it to 100 so that every status button has same width
            this.arStatusCustomCSS = {
              'width': (100/this.arStatusList.length).toString()+ '%'
            }
          }
        }
      }
    });

    // Waiting to pass the new status
    this.viewService.strStatusValue.distinctUntilChanged().subscribe( strValue => {
      if (strValue && typeof (strValue) == 'string') {
        Object.keys(this.arStatusList).forEach( key => {
          if (this.arStatusList[key].id == 'sent_to_client') {
            this.strStatusSelected = this.arStatusList[key];
          }
        });
      }
    })
  }

  ngOnInit() {
    this.viewService.updateStatusWidget({
      instance_id: this.numInstanceId
    })
  }

  /**
   * Begin status update process. If update requires confirmation, wait for user to confirm first.
   * Otherwise, proceed directly to status update. 
   * @param objStatus
   */   
  updateStatus(arStatus) {
    // Do we have different status?
    if (this.strStatusSelected['id'] != arStatus['id']) {

      if ((this.objPointOfNonConvertible[this.objRecordDetails['module']] < this.strStatusSelected['position'] && arStatus['position'] < this.strStatusSelected['position']) || this.strStatusSelected['id'] == 'converted') {
        this.notifService.sendNotification('not_allowed', 'cannot_convert', 'warning', 5000);
      } else if (this.objRecordDetails['module'] == 'opportunities' && arStatus['id'] == 'closed_lost') {
        // Pass the status widget value, so we can preview the convert dialog.
        this.viewService.updateStatusWidget({
          type: 'reason_for_lost',
          id: this.numInstanceId,
          data: { module: this.objRecordDetails['module'], id: this.objRecordDetails['id'], status: arStatus['id']},
          instance_id: this.numInstanceId
        });
      } else if (this.objRecordDetails['module'] === 'jobs' && arStatus['id'] === 'cancelled') {
        this.notifService.sendConfirmation("confirm_cancel_job", "confirmation", "default")
        .filter(confirmation => confirmation.answer === true)
        .subscribe( () => {
          this.saveRecordStatus(arStatus);
        });
      } else {
        // Check if do we have converted status
        if (arStatus['id'] == 'converted' || arStatus['id'] == 'closed_won') {
          // Pass the status widget value, so we can preview the convert dialog.
          this.viewService.updateStatusWidget({
            type: 'converted',
            id: this.numInstanceId,
            data: { module: this.objRecordDetails['module'], id: this.objRecordDetails['id']},
            instance_id: this.numInstanceId
          });
        } else {
          this.saveRecordStatus(arStatus);
        }
      }
    }
  }

  /**
   * Update the record status
   *
   * @param status current selected status
   * @returns {void}
   */
  saveRecordStatus(status): void {
    // Update the current record.
    this.recordService.saveRecord(this.objRecordDetails["module"], {[this.strField]: status["id"]}, this.objRecordDetails["id"])
    .subscribe( data => {
      this.strStatusSelected = status;
      // Pass the status widget value, so we can update the record.
      this.viewService.updateStatusWidget({
        type: "update",
        data: { module: this.objRecordDetails["module"], id: this.objRecordDetails["id"]},
        instance_id: this.numInstanceId
      });

      this.notifService.notifySuccess('status_update_success');
    });
  }  
}
