import { ModuleWithProviders, NgModule } from '@angular/core';
import { MaterialLinesComponent } from './log-materials/components/material-lines/material-lines.component';
import { SharedModule } from '../../../shared/modules/shared.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedInputModule } from '../../../shared/modules/shared-input.module';
import { EditModule } from '../../../shared/modules/edit.module';
import { ProductFoldersModule } from '../../../features/product-folders/product-folders.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedInputModule,
    SharedModule.forChild(),
    EditModule,
    ProductFoldersModule,
    DragDropModule,
  ],
  declarations: [
    MaterialLinesComponent,
  ],
  exports: [
    MaterialLinesComponent,
  ],
})
export class MaterialsModule {
  static forChild(): ModuleWithProviders {
    return {
      ngModule: MaterialsModule,
    };
  }
}