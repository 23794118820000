import { Component } from '@angular/core';
import _ from 'lodash';
import { PaginationStore } from '../../store/pagination.store';

@Component({
  selector: 'fieldmagic-pagination-links',
  templateUrl: './pagination-links.component.html',
})
export class PaginationLinksComponent {
  get hasNextPage$() {
    return this.pagination.hasNextPage$;
  }

  get hasPreviousPage$() {
    return this.pagination.hasPreviousPage$;
  }

  get isFirstPage$() {
    return this.pagination.isFirstPage$;
  }

  constructor(
    private readonly pagination: PaginationStore<any, any>,
  ) { }

  onRefresh(): void {
    this.pagination.refresh();
  }

  onNextPage(): void {
    this.pagination.goToNextPage();
  }

  onPreviousPage(): void {
    this.pagination.goToPreviousPage();
  }

  onFirstPage(): void {
    this.pagination.goToFirstPage();
  }
}
