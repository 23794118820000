import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from '../../../../../../services/notification.service';

@Component({
  selector: 'app-choose-inspection-period',
  templateUrl: './choose-inspection-period.component.html',
  styleUrls: ['./choose-inspection-period.component.scss']
})
export class ChooseInspectionPeriodComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ChooseInspectionPeriodComponent>,
    private notifService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      checklist_name: new FormControl(this.dialogData.text),
      choose_period: new FormControl([], Validators.required),
    });
  }

  /**
   * Closes the dialog
   *
   * @returns {void}
   */
  dialogClose(): void {
    this.dialogRef.close({
      success: false,
      data: {
        id: this.dialogData.id
      }
    });
  }

  submit(): void {
    this.form.get('choose_period').markAsDirty();
    this.form.get('choose_period').markAsTouched();

    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      this.notifService.notifySuccess('inspection_period_chosen');
      this.dialogRef.close({
        success: true,
        data: {
          id: this.dialogData.id,
          periods: this.form.get('choose_period').value
        }
      });
    }
  }
}
