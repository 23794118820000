import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { DlDateTimePickerDateModule } from 'angular-bootstrap-datetimepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule, MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment';
// Modules
import { AddressModule } from './address.module';
import { SharedModule } from './shared.module';
import { ClipboardModule } from 'ngx-clipboard';

// Components
import { EditComponent } from '../components/editform/edit/edit.component';
import { EditformComponent } from '../components/editform/editform.component';
import { EditdialogComponent } from '../components/editform/editdialog/editdialog.component';
import { EditjsonComponent } from '../components/editform/editjson/editjson.component';
import { PhoneComponent } from '../components/editform/phone/phone.component';
import { ErrorHandlerModule } from './error-handler.module';
import { DateModule } from '../components/forms/input/date/date.module';
import { ReassignFutureTasksDialog } from '../../module/user/dialog/reassign-future-tasks/reassign-future-tasks.dialog';
import { SharedInputModule } from './shared-input.module';
import { EditItemCodesComponent } from '../../admin/items/edit/item-codes.component';
import { CustomEditFormFieldComponent } from '../components/editform/custom-field/custom-field.component';
import { EditReorderConfigComponent } from '../../admin/items/edit/reorder-config.component';
import { RelatedProductsComponent } from '../../admin/items/edit/related-products.component';
import { ProductFoldersModule } from '../../features/product-folders/product-folders.module';
import { AvailabilityComponent } from '../components/editform/availability/availability.component';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_MOMENT_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    ColorPickerModule,
    DlDateTimePickerDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    AddressModule,
    SharedModule.forRoot(),
    ErrorHandlerModule,
    DateModule,
    SharedInputModule,
    ClipboardModule,
    ProductFoldersModule
  ],
  exports: [
    EditComponent,
    EditformComponent,
    EditdialogComponent,
    EditjsonComponent,
    PhoneComponent,
    AvailabilityComponent
  ],
  declarations: [
    EditComponent,
    EditformComponent,
    EditdialogComponent,
    EditjsonComponent,
    PhoneComponent,
    ReassignFutureTasksDialog,
    CustomEditFormFieldComponent,
    EditItemCodesComponent,
    EditReorderConfigComponent,
    RelatedProductsComponent,
    AvailabilityComponent
  ],
  entryComponents: [
    EditdialogComponent,
    EditformComponent,
    ReassignFutureTasksDialog,
    EditItemCodesComponent,
    EditReorderConfigComponent,
    RelatedProductsComponent
  ],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
  ]
})
export class EditModule { }
