import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Guid } from "guid-typescript";
import { cloneDeep, get, isEmpty } from 'lodash';
import * as moment from 'moment';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { EnumeratedTypeFields } from '../../../../../lists/field-type';
import { PromptTypeIcons } from '../../../../../lists/listing-fields';
import { StatusCode } from '../../../../../lists/status-code';
import { ChecklistResponsePrompt } from '../../../../../objects/checklis-response';
import { ChecklistPromptGroup } from '../../../../../objects/checklist';
import { Select } from '../../../../../objects/select';
import { ChecklistsService } from '../../../../../services/checklist.service';
import { FileService } from '../../../../../services/file/file.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import imageCompression from 'browser-image-compression';
import { ChecklistConstants } from '../../../../../lists/checklist';
import { NotificationService } from '../../../../../services/notification.service';
import { RecordService } from '../../../../../services/record.service';

@Component({
  selector: 'app-checklist-response',
  templateUrl: './checklist-response.component.html',
  styleUrls: ['./checklist-response.component.scss'],
  providers: [ChecklistsService]
})
export class ChecklistResponseComponent implements OnInit {

    public arPrompts: any = [];
    public arChecklist: any = [];
    public arRelatedRecords : any = [];
    public arMetadata : any = [];
    public isPassed : any = "default";
    public strResponseType : string = "main_prompts";
    public arAvailablePeriod : any = [];
    public bHasFailedPrompts: boolean = false;
    public arProducts : any = [];
    public strAssetId : string = '';
    public strReviewStatus : string = 'not_yet_reviewed';
    public strFaultDetails : string = '';
    public strJobOrOpportunityNumber : string = '';
    public intMaxCharacter : number = 2056;
    public arNumberOfErroneousText : string[] = [];
    public intDefaultTextAreaMaxLength : number = 2056;
    public intDefaultTextMaxLength : number = 2056;

    public objEnumFields = new EnumeratedTypeFields;
    public arValidModules = [ "jobs", "opportunities", "sites", "assets", "custom_asset_types"];

    public objProducts = {
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_product',
        name: 'name',
        module: 'items',
        value: 'arProducts',
        readonly: false
    }

    // Fields that are ready only thus cannot be edited
    public arExcludedFields = [
        "amount_to_invoice",
        "attributes",
        "billing_type",
        "created_at",
        "created_by",
        "invoicing_type",
        "job_number",
        "modified_by",
        "phone",
        "updated_at",
        "updated_by"
    ];

    public objCompletedBy: object = {
        id: '',
        text: '',
    };
    public bRelateLoading: boolean = false;
    public arRelateValues$: Observable<Select[]>;
    public arRelateValuesInput$ = new Subject<string>();
    public arInitialCompletedRelate: Array<object> = [];

    public bFormDirty: boolean = false;
    public shouldShowDateCompleted: boolean = false;
    public strDateCompleted: string = null;

    @HostListener('window:keyup.esc') onKeyUp() {
      this.cancelDialog();
    }

    constructor (
        public dialogRef: MatDialogRef<ChecklistResponseComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private notifService: NotificationService,
        private fileService: FileService,
        private recordService: RecordService,
        private localStorageService: LocalStorageService,
        private dialog: MatDialog,
        public checklistsService: ChecklistsService
    ) {
        this.arChecklist = data['checklist_response'];
        this.strResponseType = data['response_type']; // Determine if main_prompts or per_asset_prompts
        this.strJobOrOpportunityNumber = this.data['job_number'] || this.data['opportunity_number'];
        this.strAssetId = data['asset_id'] != undefined ? data['asset_id'] : "";

        let objCurrentClient = JSON.parse(this.localStorageService.getItem('current_client'));

        // Only show editable date completed field if admin and if date_completed has value
        if (objCurrentClient.level === 'admin' && ! isEmpty(this.arChecklist['date_completed'])) {
            this.shouldShowDateCompleted = true;
            this.strDateCompleted = this.arChecklist['date_completed'];
        }

        if (data['checklist_response']['completed_by'] && data['checklist_response']['completed_by_name']) {

            this.objCompletedBy = new Select(data['checklist_response']['completed_by'], data['checklist_response']['completed_by_name']);
            this.arInitialCompletedRelate.push(new Select(data['checklist_response']['completed_by'], data['checklist_response']['completed_by_name']));
        }

        if (data['checklist_response']['review_status']) {
            this.strReviewStatus = data['checklist_response']['review_status'];
        }

        // If there's available fault details
        if ( data['fault'] != null) {
            this.arProducts = data['fault']['products'] != "" ? JSON.parse(data['fault']['products']) : [],
            this.strFaultDetails = data['fault']['details']
        }

        // Records where the field value will be coming from
        let arRecords = {
            'job_id'  : this.arChecklist['job_id'],
            'opportunity_id': this.arChecklist['opportunity_id'],
            'asset_id' : this.strAssetId
        };

        // If it is not asset checklist type and displaying main_prompts
        // OR if it is asset checklist type and displaying per asset prompts
        if ((this.strResponseType === 'main_prompts' && this.arChecklist['type'] !== "asset")
            || (this.arChecklist['type'] == "asset" && this.strResponseType === 'per_asset_prompts') ) {
            // Get records related to the linked checklist (JOBS, SITES and ASSET TYPES (if there's any))
            this.checklistsService.getRelatedRecords(JSON.stringify(arRecords), this.data['module']).pipe(
                tap(response => {
                    let objRelatedRecords = cloneDeep(response['records']);
                    if (!isEmpty(objRelatedRecords.assets)) {
                        if (!isEmpty(objRelatedRecords.assets.asset_type_attributes)) {

                            let arAssetTypeAttributes = objRelatedRecords.assets.asset_type_attributes;
                            let objAssetAttributes = objRelatedRecords.assets.attributes;
                            objRelatedRecords.assets.attributes = arAssetTypeAttributes.map( objTypeAttributes => {

                                objTypeAttributes.default_value = get(objAssetAttributes, objTypeAttributes.key, null);

                                return objTypeAttributes;
                            })
                        }
                    }

                    response['records'] = objRelatedRecords;

                    return response;
                })
              ).subscribe( data => {

                this.arRelatedRecords = data['records'];
                this.arMetadata = data['metadata'];

                // If response is empty, get the questions
                this.arPrompts = this.arChecklist['response'] == "" || JSON.parse(this.arChecklist['response']).length === 0 ? JSON.parse(this.arChecklist['questions']) : JSON.parse(this.arChecklist['response']);

                for (let ctrPrompt = 0; ctrPrompt < this.arPrompts.length; ctrPrompt++) {

                    if (this.arPrompts[ctrPrompt]) {

                        this.arPrompts[ctrPrompt]['prompts'].map( promptValue => {
                            if (promptValue['is_group'] === true) {
                                promptValue.prompts.map(objPrompt => this.initPrompt(objPrompt));
                            } else {
                                this.initPrompt(promptValue);
                            }
                        });

                    }
                }
            });
        }
    }

    ngOnInit() {
        // Only trigger initial getting of products if there is no failed prompts and products has content.
        if (this.strResponseType === 'per_asset_prompts' && this.arProducts.length > 0) {
            // get the products
            this.recordService.getRecordRelate('items', '', this.arProducts, false, { 'active' : true }, this.arProducts.length).subscribe(response => {
                let arResponse: any = response;
                // If the response has a list, set it as the default values in the dropdown.
                if (arResponse.length > 0) {
                    this.initializeProducts(arResponse);
                } else {
                    this.initializeProducts();
                }
            })
        } else {
            this.initializeProducts();
        }

        // Only trigger if the checklist is asset type
        if (this.arChecklist['type'] === 'asset') {
            //Set the values of the relate's dropdown.
            this.arRelateValues$ = concat(
                of(this.arInitialCompletedRelate), // We set the initial values to the ng-select.
                this.arRelateValuesInput$.pipe(
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => this.bRelateLoading = true),
                switchMap(term => this.recordService.getRecordRelate('users', term, '', false, false).pipe(
                    tap( (data) => {
                        this.bRelateLoading = false;
                    }),
                ))
                ),
            );
        }

        this.dialogRef.backdropClick().subscribe(_ => {
            this.cancelDialog();
        });
    }

    /**
     * Initialize products.
     * @param arInitialList
     */
    initializeProducts(arInitialList = []) {
        this.objProducts['obv'] = new Observable<Select[]>();

        // Set the input observable to trigger this API call.
        this.objProducts['obv'] = concat(
            of(arInitialList),
            this.objProducts['typehead'].pipe(
                // Trigger only ever 400 milisecond.
                debounceTime(400),
                // Trigger only when the value is different from the previous.
                distinctUntilChanged(),
                // Show the loader.
                tap(() => {
                    this.objProducts['loader'] = true;
                }),
                // Get the response from the API.
                switchMap(term => this.recordService.getRecordRelate('items', term, '', false, { 'active' : true }).pipe(
                    // Hide the loader once done.
                    tap(() => {
                    this.objProducts['loader'] = false;
                    })
                ))
            )
        );
    }

    /*
    *  Gets data gathered and saves in checklists response table
    */
    onSubmit() {
        let isAssetChecklistParent = (this.arChecklist['type'] === 'asset' && this.strResponseType === 'main_prompts');
        let hasChangedCompletedBy = (this.objCompletedBy && this.objCompletedBy['id']);
        let hasAnsweredPrompts = Array.isArray(this.arPrompts) && this.arPrompts.length !== 0;

        if (hasAnsweredPrompts || (isAssetChecklistParent && hasChangedCompletedBy)) {

            // get index of prompt type you're saving
            let intIndexOfCurrentPrompt = this.arPrompts.findIndex(
                item => (
                    item.name == this.strResponseType
                )
            );

            // Check if there's a failed pass or fail prompt and require fault details textarea
            if (this.bHasFailedPrompts && this.strFaultDetails == "") {
                this.notifService.notifyWarning('fault_details_required');
            } else if (this.arNumberOfErroneousText.length > 0) {
                this.notifService.notifyWarning('text_max_limit_error');
            } else {

                // If all pass or fail prompts are passed, reset the fault details and products
                if (!this.bHasFailedPrompts) {
                    this.arProducts = [];
                    this.strFaultDetails = "";
                }

                let arFaults = {
                    'products' : this.arProducts,
                    'details' : this.strFaultDetails
                };

                let arChecklistResponseRecord = {
                    'asset_id' : this.strAssetId,
                    'job_id' : this.arChecklist['job_id'],
                    'opportunity_id': this.arChecklist['opportunity_id'],
                    'id' : this.data['response_type'] == 'per_asset_prompts' ? this.data['id'] : this.arChecklist['id']
                };

                // Only send review status data when it's set to review complete
                if (this.strReviewStatus === 'review_complete') {
                    arChecklistResponseRecord['review_status'] = this.strReviewStatus;
                }

                let arResponseBody = (isAssetChecklistParent) ?
                    { completed_by: hasChangedCompletedBy ? this.objCompletedBy['id'] : null} :
                    [this.arPrompts[intIndexOfCurrentPrompt]];

                // If there's date completed available append to the record
                if (this.strDateCompleted !== null) {
                    arChecklistResponseRecord['date_completed'] = moment(this.strDateCompleted).format('YYYY-MM-DD HH:mm:ss');
                }

                this.checklistsService.saveChecklistResponse (
                    JSON.stringify(arChecklistResponseRecord),
                    JSON.stringify(arResponseBody),
                    this.strResponseType,
                    this.arChecklist['type'],
                    JSON.stringify(arFaults)
                ).subscribe(response => {

                    if (response.status === StatusCode.kResponseSuccess) {

                        this.notifService.sendNotification(response['body']['header'], response['body']['message'], 'success');
                    } else {
                        if (response['error'] !== undefined) {
                            this.notifService.sendNotification(response['error']['header'], response['error']['message'], 'warning');
                        } else {
                            this.notifService.notifyWarning('checklist_create_fail');
                        }
                    }
                    this.dialogRef.close({ status : 'save' });
                });
            }
        } else {
            this.notifService.notifyWarning('no_prompts');
        }
    }

    /**
     * Closes the dialog
     */
    cancelDialog() {
        if (this.checkFormGroupDirty()) {
          // Pop-up modal for confirmation
          this.notifService.sendConfirmation('confirm_cancel')
            .filter(confirmation => confirmation.answer === true)
            .subscribe(() => {
                this.dialogRef.close({ status: 'cancel' });
            });
        } else {
            this.dialogRef.close({status : 'cancel'});
        }
    }

    // Change response type icon
    getTypeIcon(type) {
        return PromptTypeIcons[type];
    }

    // Add leading zeros
    pad(number, size) {
        var strNewNumber = number + "";
        while (strNewNumber.length < size) strNewNumber = "0" + strNewNumber;
        return strNewNumber;
    }

    /**
     * When the ng-select dropdown is clicked/opened, we fake a user input
     * a user input with no characters.
     * @param typehead - the ng-select typehead observable.
     */
    public triggerSubject(typehead: Subject<string>) {
        // We trigger the typehead to execute a search.
        typehead.next("");
    }

    // Returns formatted date from default value of custom field
    public convertCustomDateValues(strDatePeriod) {
        switch(strDatePeriod) {
            case 'today':
                return moment().format('LL');
            case 'seven_days':
                return moment().add(7, 'days').format('LL');
            case 'fourteen_days':
                return moment().add(14, 'days').format('LL');
            case 'thirty_days':
                return moment().add(30, 'days').format('LL');
            case 'last_day_of_this_month':
                return moment().endOf('month').format('LL');
            case 'first_day_of_next_month':
                return moment().add(1, 'month').date(1).format('LL');
            case 'monthly':
                return moment().add(1, 'month').format('LL');
            case 'quarterly':
                return moment().add(3, 'month').format('LL');
            case 'semi_annual':
                return moment().add(6, 'month').format('LL');
            case 'annual':
                return moment().add(1, 'year').format('LL');
            case 'every_2_years':
                return moment().add(2, 'year').format('LL');
            case 'every_3_years':
                return moment().add(3, 'year').format('LL');
            case 'every_4_years':
                return moment().add(4, 'year').format('LL');
            case 'every_5_years':
                return moment().add(5, 'year').format('LL');
            default:
                return moment().format('LL');
        }
    }

    /**
     * Changes an saves review status to review_complete
     *
     * @return void
     */
    changeReviewStatusToComplete() {
        this.strReviewStatus = 'review_complete';
        this.onSubmit();
    }


    /**
     * Check if the form group is changed
     *
     * @returns {boolean}
     */
    checkFormGroupDirty(): boolean {
        return this.bFormDirty;
    }

    /**
     * Mark as dirty
     *
     * @returns {void}
     */
    markAsDirty(bIsDirty: boolean = false): void {
      this.bFormDirty = bIsDirty;
    }


    /**
     * Get the index of the current selected option and negate it's current value
     * (eg. Add checked status if not yet selected)
     *
     * @param {string} selectedId
     * @param {boolean} isChecked
     * @param {number} indexPromptTypes
     * @param {number} indexPrompt
     * @returns {void}
     */
    changeSelection(selectedId: string, isChecked: boolean, options, indexPromptTypes: number, indexPrompt: number): void {

        let selectedIndex = options.findIndex( option => selectedId === option['id']);

        options[selectedIndex]['checked'] = ! isChecked;

        this.arPrompts[indexPromptTypes]['prompts'][indexPrompt]['value']['options'] = options;
    }

    /**
     * Get the set max length of field
     * @param arMetada
     * @returns
     */
    getFieldMaxLength(arMetada: []): number {
        if (arMetada['max_length'] !== undefined) {
            return arMetada['max_length'];
        }

        return this.intMaxCharacter;
    }

    /**
     * Checks if there are any failed 'pass/fail' type prompts, also resets the
     * arProducts and strFaultDetails variables if there are no failed prompts.
     * Prompt changes color if passed (Green) and failed (red).
     *
     * @returns {void}
     */
    checkForFailedPassFailPrompts(): void {
        // Gets all 'pass/fail' type prompts
        let arPassFailPrompts: ChecklistResponsePrompt[] = this.arPrompts.reduce((arFinalRes, promptCategory) => {
            let arCategoryPassFailPrompts = promptCategory['prompts'].reduce((arResult, prompt) => {
                if (prompt['type'] === 'pass/fail') {
                    arResult.push(prompt);
                }

                if (prompt['is_group']) {
                    let arPassFailSubprompts = prompt['prompts'].filter(subprompt => subprompt['type'] === 'pass/fail');
                    arResult = arResult.concat(arPassFailSubprompts);
                }

                return arResult;
            }, []);

            return arFinalRes.concat(arCategoryPassFailPrompts);
        }, []);

        // Checks if at least one 'pass/fail' type prompt has failed
        this.bHasFailedPrompts = arPassFailPrompts.some(prompt => prompt.value['is_passed'] === false);

        // If there's no failed prompt reset values for products and fault details
        if (!this.bHasFailedPrompts) {
            this.arProducts = [];
            this.strFaultDetails = "";
        }
    }

    /**
     * Removes the repeating prompts group.
     *
     * @param {number} numIndex
     *
     * @returns {void}
     */
    removeGroup(numIndex: number): void {
        let numCurrPromptIdx: number = this.arPrompts.findIndex(item => item.name === this.strResponseType);

        this.arPrompts[numCurrPromptIdx]['prompts'].splice(numIndex, 1);
        this.checkForFailedPassFailPrompts();
    }

    /**
     * Checks if there are similar repeating groups.
     *
     * @param {ChecklistPromptGroup} objPromptGroup
     * @param {number} numIndex
     *
     * @returns {boolean}
     */
    hasRepeatingGroup(objPromptGroup: ChecklistPromptGroup, numIndex: number): boolean {
        let numCurrPromptIdx: number = this.arPrompts.findIndex(item => item.name === this.strResponseType);
        let arRepeatingGroups: ChecklistPromptGroup[] = this.arPrompts[numCurrPromptIdx]['prompts']
            .filter((item, idx) => item['is_group'] && item['group_name'] === objPromptGroup.group_name && idx !== numIndex)
            .filter(item => item['prompts'].every((prompt, idx) => get(prompt, 'prompt') === get(objPromptGroup.prompts[idx], 'prompt')));

        return arRepeatingGroups.length > 0;
    }

    /**
     * Repeats the selected prompt group.
     *
     * @param {ChecklistPromptGroup} objGroup
     *
     * @returns {void}
     */
    repeatGroup(objGroup: ChecklistPromptGroup, numIndex: number): void {
        let objBlankGroup: ChecklistPromptGroup = this.getBlankPromptGroup(objGroup);
        let numCurrPromptIdx: number = this.arPrompts.findIndex(item => item.name === this.strResponseType);

        this.arPrompts[numCurrPromptIdx]['prompts'].splice(numIndex + 1, 0, objBlankGroup);
        this.checkForFailedPassFailPrompts();
    }

    /**
     * Creates a blank copy of the selected prompt group.
     *
     * @param {ChecklistPromptGroup} objGroup
     *
     * @returns {ChecklistPromptGroup}
     */
    getBlankPromptGroup(objGroup: ChecklistPromptGroup): ChecklistPromptGroup {
        let objClonedGroup = cloneDeep(objGroup);
        objClonedGroup.id = Guid.create().toString();

        objClonedGroup.prompts = objClonedGroup.prompts.map((objPrompt: ChecklistResponsePrompt) => {
            objPrompt.id = Guid.create().toString();

            switch (objPrompt.type) {
                case "pass/fail":
                    objPrompt.value = {
                        pass: objPrompt.value.pass,
                        fail: objPrompt.value.fail,
                        pass_and_fail: objPrompt.value.pass_and_fail
                    };
                    break;
                case "field_entry":
                    objPrompt.value = {
                        default_value: objPrompt.value.default_value,
                        module: objPrompt.value.module,
                        field: objPrompt.value.field,
                        field_entry: objPrompt.value.field_entry,
                        label: get(objPrompt, 'value.label', ''),
                        key: get(objPrompt, 'value.key', ''),
                        required: get(objPrompt, 'value.required', false),
                        type: get(objPrompt, 'value.type', 'text')
                    };
                    break;
                case "dropdown":
                    objPrompt.value = { dropdown: objPrompt.value.dropdown };
                    break;
                case "signature":
                case "text":
                case "number":
                case "date":
                    objPrompt.value = { [objPrompt.type]: '' };
                    break;
            }

            return objPrompt;
        });

        return objClonedGroup;
    }

    /**
     * Perform actions on the prompt based on the prompt type.
     *
     * @param {ChecklistResponsePrompt} objPrompt
     *
     * @returns {void}
     */
    initPrompt(objPrompt: ChecklistResponsePrompt): void {
        switch(objPrompt['type']) {
            case 'signature' :
                if (objPrompt['value']['signature'] != "") {
                    this.fileService.getObjectSignedUrl(objPrompt['value']['signature'], environment.client_bucket).subscribe(object => {
                        objPrompt['value']['image_path'] = object['url'];
                    });
                }
            break;
            // Combine Pass and Fail Values into an array
            case 'pass/fail' :
                // Check if there are failed values
                if (objPrompt['value']['is_passed'] !== undefined && !objPrompt['value']['is_passed']) {
                    this.bHasFailedPrompts = true;
                }
                objPrompt['value']['pass_and_fail'] = objPrompt['value']['pass'].concat(objPrompt['value']['fail']);
            break;

            // Get the corresponding value of the field based on the module indicated
            case 'field_entry' :

                // Temporarily store field and module (WILL REMOVE IN SAVING)
                let arFieldEntryValue = objPrompt['value']['field_entry'].split("-");

                objPrompt['value']['module'] = arFieldEntryValue[0]; // Get the module
                objPrompt['value']['field'] = arFieldEntryValue[1]; // Get the field

                // Checks if module is supported or valid
                if (this.arValidModules.includes(objPrompt['value']['module'])) {

                    if (objPrompt['value']['module'] != 'custom_asset_types') {
                        let strFieldEntryValue = "";
                        const objCurrentFieldMetadata = get(this.arMetadata[objPrompt['value']['module']], (objPrompt['value']['module'], objPrompt['value']['field']), null);

                        if (this.arRelatedRecords[objPrompt['value']['module']][objPrompt['value']['field']] != undefined) {
                            strFieldEntryValue = this.arRelatedRecords[objPrompt['value']['module']][objPrompt['value']['field']];
                        } else {
                            strFieldEntryValue = objCurrentFieldMetadata && this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['default_value'] != undefined
                                ? this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['default_value'] : "";
                        }

                        // Get the dropdown option of custom fields in metadata
                        if (objPrompt['value']['field'].includes('dropdown')) {
                            objPrompt['value']['dropdown'] = objCurrentFieldMetadata && this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['options'] != undefined
                                ? this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['options'] : [];
                        } else if (objPrompt['value']['field'].includes('date')) {
                            // Formats date from default value of custom field  (eg. today, seven_days etc)
                            strFieldEntryValue = this.convertCustomDateValues(strFieldEntryValue);
                        } else if (objPrompt['value']['field'].includes('multiselect')) {

                            if (objPrompt['value']['options'] === undefined) {

                                let arCurrentMetadata = this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']];

                                let arCurrentOptions = objCurrentFieldMetadata && arCurrentMetadata['options'] !== undefined
                                    ? arCurrentMetadata['options'] : [];

                                let arDefaultValues = arCurrentMetadata['default_value'];

                                arCurrentOptions.some(option => {
                                    option['checked'] = arDefaultValues.includes(option['id']);
                                });

                                objPrompt['value']['options'] = arCurrentOptions;
                            }
                        } else if (objPrompt['value']['field'].includes('textarea') || objPrompt['value']['field'].includes('text')) {
                            const fieldEntryValue = get(objPrompt, ['value', 'field_entry_value'], []);
                            const intMaxLength = this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['max_length'];

                            if (fieldEntryValue.length > intMaxLength) {
                                this.arNumberOfErroneousText.push(objPrompt['id']);
                            }
                        }

                        if (objCurrentFieldMetadata['type'] === 'checkbox') {
                            objPrompt['value']['field_entry_value'] = isEmpty(objPrompt['value']['field_entry_value']) ?
                                strFieldEntryValue : objPrompt['value']['field_entry_value'];
                        }

                        objPrompt['value']['default_value'] = strFieldEntryValue;

                    } else {
                        if (this.arRelatedRecords['assets']['attributes'] != undefined) {
                            let intIndexOfAttribute = this.arRelatedRecords['assets']['attributes'].findIndex(
                                item => (
                                    item.key == objPrompt['value']['field']
                                )
                            );
                            if (this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute] != undefined) {
                                objPrompt['value']['key'] = this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute]['key'];
                                objPrompt['value']['label'] = this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute]['label'];
                                objPrompt['value']['default_value'] = !isEmpty(objPrompt['value']['default_value']) ? objPrompt['value']['default_value'] : this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute]['default_value'];
                                objPrompt['value']['type'] = this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute]['type'];
                                objPrompt['value']['required'] = this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute]['required'];

                                if (isEmpty(objPrompt['value']['default_value']) && !isEmpty(objPrompt['value']['field_entry_value'])) {
                                    objPrompt['value']['default_value'] = objPrompt['value']['field_entry_value'];
                                }

                                if (objPrompt['value']['type'] === 'dropdown') {
                                    objPrompt['value']['option'] = this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute]['option'] != "" ?
                                        this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute]['option'] : [];
                                }
                            }
                        }
                    }

                }
            break;

            // Get the corresponding value of the field based on the module indicated
            case 'image' :
                if (isEmpty(objPrompt['value']['image'])) {
                    objPrompt['value']['image'] = [];
                }

                if (objPrompt['value']['image'].length > 0) {

                    let arTempImages = [];
                    objPrompt['value']['image'].forEach(image => {

                        // If image type is not defined
                        image['type'] = image['type'] == undefined ? 'image/jpeg' : image['type'];

                        // If uploaded name is not undefined
                        if (image['upload_name'] != undefined) {
                            this.fileService.getObjectSignedUrl(image['upload_name'], environment.client_bucket).subscribe(object => {
                                // Override image path to new presigned path
                                image['image_path'] = object['url']
                                arTempImages.push(image);
                            });
                        }
                    });

                    objPrompt['value']['image'] = arTempImages

                }
            break;


            // Get the corresponding value of the field inserted (IF THERE'S ANY) based on the module indicated
            // Then parse the value back to the whole instructions text
            case 'instructions' :
                let arVariableData = [];
                let strFieldInstructionValue = objPrompt['value']['instructions_text'];

                // Find all occurence of possible variables with given format eg. ({$sites-name}, {$jobs-po_number}, {$quotes-po_number})
                let arVariableMatches = strFieldInstructionValue.match(/[{$](.*?)[}]/g);
                // Check if Matches are already loaded (if there's any) and is not null
                if (arVariableMatches != null) {
                    // loop through all found variables and check if a valid variable
                    arVariableMatches.forEach( strVariable => {

                        // Extract the module and field type from the current variable format
                        let intLastIndex = strVariable.length - 1;
                        let arInstructionValue = strVariable.substring(2, intLastIndex).split("-");

                        objPrompt['value']['module'] = arInstructionValue[0]; // Get the module
                        objPrompt['value']['field'] = arInstructionValue[1] === 'address' ? 'address_text' : arInstructionValue[1] ; // Get the field (If address however, get the text value)

                        // Checks if module is supported or valid
                        if (this.arValidModules.includes(objPrompt['value']['module'])) {
                            if (objPrompt['value']['module'] != 'custom_asset_types') {

                                if (this.arRelatedRecords[objPrompt['value']['module']][objPrompt['value']['field']] != undefined) {
                                    arVariableData.push(this.arRelatedRecords[objPrompt['value']['module']][objPrompt['value']['field']]);
                                } else {
                                    if (
                                        this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']] !== undefined &&
                                        this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['default_value']
                                    ) {
                                        arVariableData.push(this.arMetadata[objPrompt['value']['module']][objPrompt['value']['field']]['default_value']);
                                    }
                                }
                            } else {
                                if (this.arRelatedRecords['assets']['attributes'] != undefined) {
                                    // get index of the attribute
                                    let intIndexOfAttribute = this.arRelatedRecords['assets']['attributes'].findIndex(
                                        item => (
                                            item.key == objPrompt['value']['field']
                                        )
                                    );
                                    if (this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute] != undefined) {
                                        arVariableData.push(this.arRelatedRecords['assets']['attributes'][intIndexOfAttribute]['default_value']);
                                    }
                                }
                            }
                        } else {
                            // If module invalid, remove it from the list
                            const index = arVariableMatches.indexOf(strVariable, 0);
                            if (index > -1) {
                                arVariableMatches.splice(index, 1);
                            }
                        }
                    });

                    // Replace all the variable values in instruction with actual values from the record
                    for ( let intVariableNumber = 0; arVariableMatches.length > intVariableNumber; intVariableNumber++ ) {
                        strFieldInstructionValue = strFieldInstructionValue.replace(arVariableMatches[intVariableNumber], arVariableData[intVariableNumber]);
                    }

                    objPrompt['value']['instructions_text'] = strFieldInstructionValue;
                }
            break;
        }
    }
}