
import FormTemplate from '../entities/form-template.entity';

export default class FormTemplatesList {

  items: FormTemplate[] = [];

  current_page?: string;

  total_page?: string;

  has_previous_page?: boolean;

  has_next_page?: boolean;

  constructor(data: {
    templates: FormTemplate[],
    current_page?: string,
    total_page?: string,
    has_previous_page?: boolean
    has_next_page?: boolean
  }) {
    this.items = data.templates;
    this.current_page = data.current_page || null;
    this.total_page = data.total_page || null;
    this.has_previous_page = data.has_previous_page || false;
    this.has_next_page = data.has_next_page || false;
  }
}
