import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { filter, finalize, tap } from 'rxjs/operators';
import { AnalysisAdjustments, BaseProfitabilityAnalysis, ProfitabilityAnalysisType, ProfitabilityAnalystService } from '../services/profitability-analyst.service';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'profitability-analysis-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: [
    './dialog.component.scss',
  ],
})
export class ProfitabilityAnalysisDialogComponent {
  /**
   * @type {BaseProfitabilityAnalysis}
   */
  analysis: BaseProfitabilityAnalysis;

  /**
   * @type {boolean}
   */
  fetching: boolean = true;

  /**
   * @type {boolean}
   */
  saving: boolean = false;

   /**
    * @type {FormGroup}
    */
  readonly form: FormGroup = new FormGroup({
    labours: new FormArray([]),
    materials: new FormArray([]),
    total_labour_estimated_cost: new FormControl(0),
    total_labour_estimated_revenue: new FormControl(0),
    total_labour_actual_cost: new FormControl(0),
    total_labour_actual_revenue: new FormControl(0),
    total_materials_estimated_cost: new FormControl(0),
    total_materials_estimated_revenue: new FormControl(0),
    total_materials_actual_cost: new FormControl(0),
    total_materials_actual_revenue: new FormControl(0),
    total_estimated_cost: new FormControl(0),
    total_actual_cost: new FormControl(0),
    total_estimated_revenue: new FormControl(0),
    total_actual_revenue: new FormControl(0),
    total_actual_profit: new FormControl(0),
    total_estimated_profit: new FormControl(0),
    total_actual_profit_margin: new FormControl(0),
    total_estimated_profit_margin: new FormControl(0),
    total_estimated_markup: new FormControl(0),
    total_actual_markup: new FormControl(0)
  });

  /**
   * Create instance of the component dialog container
   *
   * @param {DialogData} data
   * @param {MatDialogRef<ProfitabilityAnalysisDialogComponent>} dialog
   * @param {ProfitabilityAnalystService} analyst
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly data: ProfitabilityAnalysisDialogData,
    protected readonly dialog: MatDialogRef<ProfitabilityAnalysisDialogComponent>,
    protected readonly analyst: ProfitabilityAnalystService,
    protected readonly notifications: NotificationService
  ) {}

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.analyst.analyze(this.data.jobId)
      .pipe(
        finalize(() => this.fetching = false)
      )
      .subscribe((analysis) => this.analysis = analysis);
  }

  /**
   * Handles action when user closes the dialog
   *
   * @returns {void}
   */
  onClose(): void {
    if (this.form.pristine) {
      this.dialog.close();
    } else {
      this.notifications.sendConfirmation()
        .pipe(
          filter((feedback) => feedback.answer === true),
          tap(() => this.dialog.close())
        ).subscribe();
    }
  }

  /**
   * Handles action when user clicks on the save button
   *
   * @returns {void}
   */
  onSave(): void {
    const adjustments: AnalysisAdjustments = this.form.value;

    this.saving = true;

    this.analyst.adjust(this.data.jobId, adjustments)
      .pipe(
        finalize(() => this.saving = false),
        filter((isSuccessful) => !!isSuccessful),
        tap({
          error: () => this.notifications.notifyError('failed_to_save')
        })
      ).subscribe(() => {
        this.notifications.notifySuccess('adjustment_saved_successfully');
        this.form.markAsPristine();
      });
  }

  /**
   * Checks if actions should be disabled
   *
   * @returns {boolean}
   */
  get disablesActions(): boolean {
    return this.saving || this.fetching;
  }

  /**
   * @type {string}
   */
  get isTimeAndMaterialsAnalysis(): boolean {
    return this.analysis.type === ProfitabilityAnalysisType.time_and_materials;
  }
}

export interface ProfitabilityAnalysisDialogData {
  jobId: string;
}