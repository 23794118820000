import { Component, Input, OnInit } from '@angular/core';
import { LooseObject } from '../../../../objects/loose-object';
import { ListingService } from '../../../../services/listing.service';
import { RecordService } from '../../../../services/record.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-asset-location-history',
  templateUrl: './asset-location-history.component.html',
  styleUrls: ['./asset-location-history.component.scss']
})
export class AssetLocationHistoryComponent implements OnInit {
  @Input() strRecordId: string;
  @Input() strModule: string;
  @Input() arRecordDetails: object;
  @Input() objFetchedRecordDetails: LooseObject;

  public bLoading: boolean = false;
  public arRecordData: Array<LooseObject> = [];

  constructor(
    public listingService : ListingService,
    public recordService : RecordService,
  ) { }

  ngOnInit() {
    this.getRecordData();
  }

  /**
   * Returns the list of records
   *
   * @param {string} strNavigation
   *
   * @returns {void}
   */
  getRecordData(strNavigation: string = 'default'): void {
    this.bLoading = true;
    let objPagination = this.listingService.beforeFetching(strNavigation);
    this.listingService.fetchData(JSON.stringify(objPagination.objPage), 'asset_location_history', JSON.stringify({'asset_id': this.strRecordId})).subscribe( response => {

      if (response['data'] && response['data'].length != 0) {
        this.arRecordData = response['data'];
      }

			this.listingService.afterFetching(response, strNavigation);
      this.bLoading = false;
    });
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arRecordData = []; // clear list
    this.getRecordData();
  }

  checkValue(value: string): string {
    if(!isEmpty(value)) {
      return value;
    }

    return '--';
  }
}
