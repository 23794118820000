<!-- THE WHOLE FORM -->
<header >
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <fa-icon [icon]="['fas', 'pencil']" transform="shrink-3" *ngIf="strMode == 'edit'" class="dialog-title"></fa-icon>
      <fa-icon [icon]="['fas', 'plus-circle']" transform="shrink-3" *ngIf="strMode == 'add'" class="dialog-title"></fa-icon>
      {{ strMode }}
    </h3>
    <ng-container *fcHasPermission="strModule + ((strMode === 'add') ? ':create' : ':edit')">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
              <span class="pl-1">{{ 'cancel' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <button (click)="onSubmit()" [disabled]="bShowLoader || !items[strModule]" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader)? 'btn-spinner' : ''">
              <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>

<hr />

<mat-dialog-content class="mobile-view-dialog">
  <ng-container *fcHasPermission="strModule + ((strMode === 'add') ? ':create' : ':edit'); else createFormDisabled">
    <app-error-handler onlyFor="edit_form" [isAction]="true" alignment="center">
      <div align="center">
        <fa-icon id="editform-spinner" *ngIf="!items[strModule]" [icon]="['fas', 'spinner']" [spin]="true" class="loading-spinner"
          aria-hidden="true"></fa-icon>
      </div>

      <div *ngIf="items[strModule] && items[strModule].length != 0" class="mt-3">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li *ngFor="let item of items[strModule]" class="nav-item text-size">
            <a class="nav-link" [ngClass]="{'active': strActiveTab == item.label}" id="{{item.label}}" data-toggle="tab" role="tab" aria-selected="true" translate (click)="setTab(item['label'])">{{ item.label }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div *ngFor="let item of items[strModule]" [ngClass]="{'active': strActiveTab == item.label, 'show': strActiveTab == item.label}" class="tab-pane row p-4" [id]="'tab_' + item.id" role="tabpanel">
            <form [formGroup]="item.groups">
              <div class="row">
                <div *ngFor="let data of item.fields" class="col-{{data.space}} mb-3">
                  <ng-container *ngIf="(( data.label != 'created_by' && data.label != 'modified_by' && data.label != 'updated_at' && data.label != 'created_at') && strMode == 'add') || strMode == 'edit'">
                    <app-edit-field [item]="data" [form]="item.groups" [bSubmitted]="bSubmitted" [strMode]="strMode" [strModule]="strModule" (parentEvent)="doSomethingInParent($event)" [strRecordId]="strId" [valueFromParent]="parentValue"></app-edit-field>
                  </ng-container>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </app-error-handler>
  </ng-container>

  <ng-template #createFormDisabled>
    <span id="btnCreateFormDisabled" class="disabled"
      matTooltip="{{ 'forbidden_action_error' | translate }}">
      {{ 'forbidden_action_error' | translate }}
    </span>
  </ng-template>
</mat-dialog-content>
