export interface StockTransferInterface {
  id: string;
  status: string;
  warehouse_source_id: string;
  warehouse_source_text: string;
  warehouse_destination_id: string;
  warehouse_destination_text: string;
}

export class StockTransfer implements StockTransferInterface {
  id: string;
  status: string;
  warehouse_source_id: string;
  warehouse_source_text: string;
  warehouse_destination_id: string;
  warehouse_destination_text: string;

  constructor(properties: StockTransferInterface) {
    this.id = properties.id;
    this.status = properties.status;
    this.warehouse_source_id = properties.warehouse_source_id;
    this.warehouse_source_text = properties.warehouse_source_text;
    this.warehouse_destination_id = properties.warehouse_destination_id;
    this.warehouse_destination_text = properties.warehouse_destination_text;
  }
}