import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Confirmation } from '../../../../objects/confirmation';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {

  /**
   * The object which will contain the confirmation
   * details.
   *
   * @var {Confirmation}
   */
  public objConfirmation: Confirmation;

  /**
   * Contains the list of subscriptions that should cleaned up after
   *
   * @var {Subscription[]}
   */
  private arSubscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Confirmation
  ) {
    this.objConfirmation = data;
  }

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.arSubscriptions.push(
      this.dialogRef.backdropClick().subscribe(() => this.closeDialog(false))
    );

    this.arSubscriptions.push(
      this.dialogRef.keydownEvents()
        .pipe(
          filter(($event: KeyboardEvent) => $event.key === 'Escape')
        )
        .subscribe(($event: KeyboardEvent) => this.closeDialog(false))
    )
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.arSubscriptions.forEach((objSub: Subscription) => objSub.unsubscribe());
  }

  /**
   * Method to close the dialog, along with
   * the response.
   *
   * @param {boolean} bConfirm
   */
  closeDialog(bConfirm: boolean): void {
    this.dialogRef.close({
      result: bConfirm,
      confirmation: this.objConfirmation
    });
  }

}
