import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChecklistsRoutingModule } from './checklists-routing.module';
import { ChecklistsComponent } from './checklists.component';
import { ListingModule } from '../../shared/modules/listing.module';
import { InformationModule } from '../../shared/modules/information.module';
import { RecordService } from '../../services/record.service';
import { FormService } from '../../services/form.service';
import { CreateChecklistComponent } from './create-checklist/create-checklist.component';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { SharedInputModule } from '../../shared/modules/shared-input.module';
import { UpdateChecklistComponent } from './update-checklist/update-checklist.component';
import { ChecklistPromptComponent } from './update-checklist/checklist-prompts/checklist-prompt.component';
import { ChecklistsService } from '../../services/checklist.service';
import { NgDragDropModule } from 'ng-drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    ChecklistsComponent,
    CreateChecklistComponent,
    UpdateChecklistComponent,
    ChecklistPromptComponent
  ],
  imports: [
    ChecklistsRoutingModule,
    CommonModule,
    ListingModule,
    InformationModule,
    NgDragDropModule,
    DragDropModule,
    SharedModule.forRoot(),
    SharedInputModule
  ],
  providers: [
    RecordService,
    FormService,
    ChecklistsService,
    TranslateService
  ]
})
export class ChecklistsModule { }
