export interface PromptInterface {

  /**
   * Main message for this error prompt.
   *
   * @var {string}
   */
  message: string,

  /**
   * The field name where there is an error.
   *
   * @var {string}
   */
  field_name?: string,

  /**
   * The error value.
   *
   * @var {string}
   */
  field_value?: string
}

export class Prompt implements PromptInterface {
  public message: string;
  public field_name?: string;
  public field_value?: string;

  constructor(properties: PromptInterface
  ){
    this.message = properties.message;
    this.field_name = properties.field_name;
    this.field_value = properties.field_value;
  }

}
