<div *ngFor="let role of arCustomerList" class="row border-top p-2" [id]="'widgetCustomerRolesRecord' + role.id">
    <div class="col-sm-3 pl-1 pr-1">
        <div class="text-name customer-text truncate">
            <a id="widgetCustomerRolesLabel" matTooltip="{{ 'name' | translate }}: {{ role.module_text }}" class="pointer" href="/#/customers/{{role.module_id}}">
                <span>{{ role.module_text }}</span>
            </a>
            <fa-icon *ngIf="role.primary_contact_id == role.contact_id" class="text-success pl-1 primary-role" matTooltip="{{ 'primary' | translate }}" [icon]="['fas', 'check-circle']"></fa-icon>
        </div>
        <div class="text-job-title">
            <span *ngIf="role.role" matTooltip="{{ translateRole(role.role) }}" class="fs-12 d-inherit word-break truncate">
                <fa-icon [icon]="['fas', 'user']" aria-hidden="true" class="pr-1"></fa-icon>{{ translateRole(role.role) }}
            </span>
            <span *ngIf="!role.role" matTooltip="{{ 'no_role_assigned' | translate }}" class="fs-12 d-inherit word-break truncate">
                {{ 'no_role_assigned' | translate }}
            </span>
        </div>
    </div>
    <div class="col-sm-4 pl-1 pr-1">
        <span *ngFor="let email of role.customer_email" class="fs-12 d-inherit word-break pointer truncate email-record" matTooltip="{{ email.email }}" (click)="setEmailDialogData(email, role)">
            {{ email.email }}
        </span>
        <span *ngIf="(role.customer_email || '').length < 1" matTooltip="{{ 'email' | translate }}: --"> -- </span>
    </div>
    <div class="col-sm-4 pl-1 pr-1">
        <table style="width: 100%; table-layout: fixed;">
            <tr *ngFor="let phone of role.customer_phone" matTooltip="{{ phone.type | translate }}: {{ phone.code }} {{ phone.number }}">
                <td width="20%" class="customer-icon-phone" *ngIf="phone.code">
                    <fa-icon *ngIf="phone.type == 'home'" [icon]="['far', 'home']"></fa-icon>
                    <fa-icon *ngIf="phone.type == 'work'" [icon]="['far', 'phone-office']"></fa-icon>
                    <fa-icon *ngIf="phone.type == 'mobile'" [icon]="['far', 'mobile-android-alt']"></fa-icon>
                    <fa-icon *ngIf="phone.type == 'others'" [icon]="['far', 'phone-plus']"></fa-icon>
                </td>
                <td *ngIf="phone.code" width="80%" class="truncate customer-icon-text">{{ phone.code }} {{ phone.number }}</td>
            </tr>
        </table>
        <span *ngIf="(role.customer_phone || '').length < 1" matTooltip="{{ 'phone' | translate }}: -- "> -- </span>
    </div>
    <div class="col-sm-1 d-table fs-12 d-inherit word-break pl-1 pr-1">
        <div class="row">
            <div class="col-12">
                <fa-icon id="widgetCustomerRolesEditButton" class="text-success pointer float-right edit-record" [icon]="['fas', 'pencil']" (click)="emitAction('edit', role)"></fa-icon>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <fa-icon id="widgetCustomerRolesDeleteButton" class="text-warning pointer float-right delete-record" [icon]="['fas', 'trash-alt']" (click)="emitAction('delete', role)"></fa-icon>
            </div>
        </div>
    </div>
</div>