import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { DialogService } from '../../../../services/dialog.service';
import { CustomNotificationMessageDialog } from '../dialog/custom-notification-message.component';
import { LooseObject } from '../../../../objects/loose-object';
import moment from 'moment';
import { isEmpty, toString } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotifyViaPushService {

  /**
   * Should we notify user when a task is scheduled to them?
   *
   * @var {boolean}
   */
  public notifyViaPush: boolean = false;

  /**
   * The custom message they can indicate in the notify settings.
   *
   * @var {string}
   */
  public _customNotificationMessage: string | undefined | null;

  constructor(
    public dialog: DialogService,
    public translate: TranslateService,
  ) {}

  /**
   * Open dialog when editing the custom message.
   *
   * @returns {void}
   */
  public onEditNotificationMessage(): void {
    this._displayCustomNotificationMessageDialog();
  }

  /**
   * Get the message of the notification body.
   *
   * @param {string} strDueDate
   */
  public getNotificationBodyMessage(strDueDate: string): string {

    const due = moment.utc(strDueDate);

    return this.translate.instant('task_scheduled_push_notification_body', {
      due_date: moment(due).local().format('lll'),
    });

  }

  /**
   * Create the push notification body.
   *
   * @param objToSave
   *
   * @returns {string}
   */
  public makePushNotificationBody(objToSave: LooseObject): string | undefined {
    if (isEmpty(objToSave)) {
      return;
    }

    const note = this._customNotificationMessage;
    const messages: Record<string, string> = {};

    for (const taskId in objToSave) {
      const details = objToSave[taskId];

      if (isEmpty(details)) {
        continue;
      }

      if (toString(note).trim().length < 1) {
        messages[taskId] = this.getNotificationBodyMessage(details['due_date']);
      } else {

        const due = moment.utc(details['due_date']);

        messages[taskId] = this.translate.instant('task_scheduled_push_notification_body_with_note', {
          due_date: moment(due).local().format('lll'),
          note,
        });
      }
    }

    if (isEmpty(messages)) {
      return;
    }

    return JSON.stringify(messages);
  }

  /**
   * If we should notify. This does not apply to everything since in the
   * task scheduler, we still have the flexibility to turn off the notification.
   *
   * @param {string} strTimeDate
   *
   * @returns {boolean}
   */
  public shouldNotify(strDueDate: string): boolean {

    let duedate = moment.utc(strDueDate).local();

    return duedate < moment().add(24, 'hours');
  }

  /**
   * Opens up the dialog for the custom message.
   *
   * @returns {void}
   */
  private _displayCustomNotificationMessageDialog(): void {
    this.dialog.show({
      component: CustomNotificationMessageDialog,
      size: 'md',
      data: {
        message: this._customNotificationMessage,
      },
      afterClosing: (message: string) => {
        this._customNotificationMessage = message;
      }
    });
  }

}