import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ImporterService } from '../../../../../services/importer/importer.service';
import { Widgetable } from '../../../../../objects/component-viewing-widget/widgetable';

@Component({
  selector: 'app-imported-records',
  templateUrl: './imported-records.component.html',
  styleUrls: ['./imported-records.component.scss']
})
export class ImportedRecordsComponent implements OnInit, Widgetable {

  /**
   * The page number we're currently in.
   *
   * @type {number}
   */
  page: BehaviorSubject<number> = new BehaviorSubject(1);

  /**
   * An array of polymorphic records that was imported
   * under a specified import record.
   *
   * @type {any[]}
   */
  importedRecords: any[];

  /**
   * Is the list of imported records being fetched?
   *
   * @type {Boolean}
   */
  isLoading: boolean = false;

  /**
   * Do we have a next page?
   *
   * @type {Boolean}
   */
  hasNextPage: boolean = false;

  constructor(protected activatedRoute: ActivatedRoute, protected importerService: ImporterService) { }

  ngOnInit() {
    this.page.subscribe(page => {
      this.isLoading = true;

      this.importerService.getImportedRecords(
        this.activatedRoute.snapshot.paramMap.get('id'),
        this.getCurrentPageNumber()
      ).subscribe(response => {
        this.hasNextPage = response['next_page_url'] !== null;
        this.isLoading = false;
        this.importedRecords = response['data'];
      });
    });
  }

  /**
   * Gets the imported records, for the previous page.
   *
   * @returns {void}
   */
  gotoPrevPage(): void {
    let p = this.getCurrentPageNumber();

    this.page.next(p -= p === 1 ? 0 : 1);
  }

  /**
   * Gets the importec records, for the next page.
   *
   * @returns {void}
   */
  gotoNextPage(): void {
    this.page.next(this.getCurrentPageNumber() + 1);
  }

  /**
   * @inheritdoc
   */
  setTrickleDownData(data: any): void { }

  /**
   * @inheritdoc
   */
  getTrickleDownData(): any { }

  /**
   * Returns the current page number we're in.
   *
   * @returns {Number}
   */
  protected getCurrentPageNumber(): number {
    return this.page.getValue();
  }

}
