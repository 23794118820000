import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormTemplateCustomField, FIELD_DATE, FIELD_DATETIME } from '../../../../entities/form-template-history.entity';
import { AbstractControl, FormControl } from '@angular/forms';
import { LooseCustomField } from '../../../../contracts/loose-custom-field';
import * as moment from 'moment';
import { FormTemplateService } from '../../../../services/form-template.service';
import { LooseModuleData } from '../../../../contracts/loose-module-data';

@Component({
  selector: 'form-templates-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: [ './custom-fields.component.scss' ]
})
export class CustomFieldsComponent implements OnInit {
  /**
   * contains the list of custom fields that can be seen or found in the template
   *
   * @var {FieldMetada[]}
   */
  fields: FieldMetada[] = [];

  /**
   * @param {CustomFieldsComponentDialogData} template
   * @param {MatDialogRef<CustomFieldsComponent>} dialogFactory
   * @param {FormTemplateService} documents
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: CustomFieldsComponentDialogData,
    protected dialogFactory: MatDialogRef<CustomFieldsComponent>,
    protected documents: FormTemplateService
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.parseFields(this.data.fields, this.data.moduleData);
  }

  /**
   * preview the template requested
   *
   * @return {void}
   */
  previewTemplate(): void {
    let fields: LooseCustomField = {};

    this.fields.forEach((field: FieldMetada) => {
      fields = { ...fields, [field.name]: this.getFieldValue(field) };
    });

    this.closeDialog(fields);
  }

  /**
   * closes the current dialog
   *
   * @param {LooseCustomField|undefined} fields
   */
  closeDialog(fields?: LooseCustomField): void {
    this.dialogFactory.close(fields);
  }

  /**
   * parse the template custom fields into component fields information
   *
   * @param   {FormTemplateCustomField[]} fields
   * @param   {LooseModuleData} data
   *
   * @returns {void}
   */
  protected parseFields(fields: FormTemplateCustomField[], data: LooseModuleData): void {
    fields.forEach((field: FormTemplateCustomField) => {
      this.fields.push({
        label: field.text,
        name: field.name,
        type: field.type,
        control: new FormControl(this.parseDefaultValue(field.options.defaultValue, data)),
        options: {
          ...((field.type === FIELD_DATE || field.type === FIELD_DATETIME) && { format: field.options.format })
        }
      });
    });
  }

  /**
   * parse the given value for field's default value
   *
   * @param   {string} value
   * @param   {LooseModuleData} data
   *
   * @returns {string}
   */
  protected parseDefaultValue(value: string, data: LooseModuleData): string {
    if (value && value.includes('{{') && value.includes('}}')) {
      let fields: string[] = value.replace(/{{[\s]*([\w\.]+)[\s]*}}/ig, '$1').split('.');
      let newValue: any;

      fields.forEach((field: string) => {
        if (newValue === undefined && data[field]) {
          newValue = data[field];
        } else if (newValue && newValue[field]) {
          newValue = newValue[field];
        }
      });

      return typeof newValue === 'string' ? newValue : '';
    }

    return value;
  }

  /**
   * retrieves the value inputted by the user
   *
   * @param   {FieldMetada} field
   *
   * @returns {mixed}
   */
  protected getFieldValue(field: FieldMetada) {
    let value = field.control.value;

    if (! value) return value;

    if (field.type === FIELD_DATE || field.type === FIELD_DATETIME) {
      return this.transformDateValues(value, field.options.format);
    }

    return value;
  }

  /**
   * Transforms the date value into local format
   *
   * @param   {Date}   date
   * @param   {string} format
   *
   * @returns {string}
   */
  protected transformDateValues(date: Date, format: string): string {
    format = format.replace('Y', 'YYYY');
    format = format.replace('y', 'YY');
    format = format.replace('F', 'MMMM');
    format = format.replace('M', 'MMM');
    format = format.replace('m', 'MM');
    format = format.replace('d', 'DD');
    format = format.replace('i', 'mm');
    format = format.replace('s', 'ss');

    return moment.utc(date).local().format(format);
  }
}

interface FieldMetada {
  /**
   * the label representation of the field
   *
   * @var {string}
   */
  label: string;

  /**
   * the name of the field
   *
   * @var {string}
   */
  name: string;

  /**
   * dictates the type of field used
   *
   * @var {string}
   */
  type: string;

  /**
   * the field control instance
   *
   * @var {AbstractControl}
   */
  control: AbstractControl;

  /**
   * Optional: contains the options of the current field like format etc
   *
   * @var {Options}
   */
  options?: Options
}


interface CustomFieldsComponentDialogData {
  id: string;
  module: string;
  fields: FormTemplateCustomField[];
  moduleData: LooseModuleData;
}

type Options = Record<string, any>;