import { Input, Component } from '@angular/core';

@Component({
  selector: 'fieldmagic-dialog-title',
  template: `
    <fieldmagic-icon
      [icon]="icon"
    >
      <span *ngIf="label | filled; else content">{{ label | translate }}</span>
      <ng-template #content><ng-content></ng-content></ng-template>
    </fieldmagic-icon>
  `,
})
export class DialogTitleComponent {
  @Input() icon: string;

  @Input() label: string;
}
