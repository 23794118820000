import { Component, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AccountingSystemService } from "../../../../services/accounting_system.service";
import { finalize } from "rxjs/operators";
import { NotificationService } from "../../../../../../../services/notification.service";
import { Subscription } from "rxjs";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: 'integrations-accounting-systems-enquiry-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.scss']
})
export class ComposeComponent implements OnDestroy {
  /**
   * Instance of the form group
   *
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({
    message: new FormControl(null, [Validators.required])
  });

  /**
   * Flag if the current action is progress
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * List of reactive subscriptions to be cleaned up later on when this componse is unmounted/destroyed
   *
   * @var {Subscription[]}
   */
  protected subscriptions: Subscription[] = [];

  /**
   * Create instance of the component
   *
   * @param {AccountingSystemService} accounting
   * @param {NotificationService} notifications
   */
  constructor(
    protected accounting: AccountingSystemService,
    protected notifications: NotificationService,
    protected currentDialog: MatDialogRef<ComposeComponent>
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Handles on cancel dialog event
   *
   * @param {boolean} value
   *
   * @returns {void}
   */
  onCancel(value: boolean = false): void {
    this.currentDialog.close(value);
  }

  /**
   * Handles form submission
   *
   * @returns {void}
   */
  onSubmit(): void {
    if (this.form.invalid) return;

    this.isInProgress = true;

    this.accounting.sendEnquiry$(this.form.value.message)
      .pipe(
        finalize(() => this.isInProgress = false)
      )
      .subscribe((isSuccessful: boolean) => {
        if (isSuccessful) {
          this.notifications.notifySuccess('enquiry_sent_successfully');
          this.onCancel(true);
        } else {
          this.notifications.notifyError('enquiry_failed_to_send');
        }
      })
  }
}
