<form [formGroup]="form">
  <div class="container-fluid">
    <div class="row" >
      <div class="col-xs-6 col-md-6 form-group">
        <strong id="editQuoteTitle">{{ 'edit' | translate }} {{ 'quote_details' | translate }}</strong>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let data of fields" class="col-{{data.space}} mb-3">
        <ng-container
          *ngIf="(( data.label != 'created_by' && data.label != 'modified_by' && data.label != 'updated_at' && data.label != 'created_at') && strMode == 'add') || strMode == 'edit'">
          <app-edit-field [item]="data" [form]="form" [bSubmitted]="bSubmitted" [strMode]="strMode"
            [strModule]="strModule"></app-edit-field>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6 col-md-6">
        <button id="editQuoteCancel" type="button" class="btn btn-warning btn-block btn-lg" (click)="cancel()" [disabled]="bSaving">
          <strong>{{ 'cancel' | translate }}</strong>
        </button>
      </div>
      <div class="col-xs-6 col-md-6">
        <button id="editQuoteSave" type="button" class="btn btn-primary btn-block btn-lg" (click)="save()" [disabled]="bSaving">
          <strong><fa-icon *ngIf="bSaving" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon> {{ 'save' | translate }}</strong>
        </button>
      </div>
    </div>
  </div>
</form>