import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotificationService } from '../../../../../services/notification.service';
import { isEmpty as _isEmpty } from 'lodash';

@Component({
  selector: 'app-dialog-save-template',
  templateUrl: './dialog-save-template.component.html',
  styleUrls: ['./dialog-save-template.component.scss']
})
export class DialogSaveTemplateComponent implements OnInit {

  public strTemplateName: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<DialogSaveTemplateComponent>,
    private notificationService : NotificationService,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.strTemplateName = this.data.isUpdate ? this.data.selectedTemplateName : null;
    }

  ngOnInit() {}

  /**
   * Closing of dialog
   *
   * @returns {void}
   */
  cancel() : void {
    this.dialogRef.close({ name: null, isCancelled: true });
  }

  /*
   * Pass the template name from the dialog to the main caller
   *
   * @returns {void}
   */
  saveTemplate(): void {
    if (_isEmpty(this.strTemplateName)) {
      this.notificationService.notifyWarning('please_add_template_name');
    } else {
      this.dialogRef.close({name: this.strTemplateName, isCancelled: false});
    }
  }

}
