
    <ng-select
        appendTo="body"
        [bindLabel]="filter.viewModel.bindLabel"
        [bindValue]="filter.viewModel.bindValue"
        (focus)="filter.focusInput$.next()"
        (keydown)="advancedSearchBox.keydown($event,filter.viewModel)"
        #inputRef
        [placeholder]="filter.viewModel.placeholder | translate"
        [typeahead]="domainTypeahead"
        [items]="itemsDomain"
        (change)="onChange($event)"
        (clear)="onClear()"
        [(ngModel)]="filter.viewModel.value"
        [clearable]="false"
        >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            {{ (item.label || item.name) | translate }}
        </ng-template>
        <ng-template  ng-option-tmp let-item="item">
            <span class="ng-value-label as-domains-input-label">{{ (item.label || item.name) | translate }}</span>
        </ng-template>
    </ng-select>
    <input autosize #inputAutosize type="text" [(ngModel)]="filterValue" [hidden]="true" />