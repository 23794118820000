export const environment = {
  production: true,
  base_api_url: 'https://api.fieldmagic.co',
  url: 'https://api.fieldmagic.co/private',
  app_url: 'https://app.fieldmagic.co',
  lambda_url: 'https://sls-production.fieldmagic.co',
  search_url: 'https://api.fieldmagic.co/private/search',
  version_folder: '',
  admin_email: 'support@crmonline.com.au',
  atatus_config: 'b41489dc8e554e7a81aa74b39edd59da',
  website: 'https://www.fieldmagic.co/',
  aws_appsync_graphqlEndpoint: 'https://y6qs3moxajh3fcteqsachlbyvi.appsync-api.eu-west-1.amazonaws.com/graphql',
  chargebee: {
    site: 'fieldmagic',
    publishableKey: 'live_JWpVaJuIiMI8nY47evaHV0KD1b4lvsyf'
  },
  api_public_base_url: 'https://api.fieldmagic.co/public',
  forms_generator_base_url: 'https://7nb4knuvt446hbgyxivdisalxa0wzcmq.lambda-url.eu-west-1.on.aws',
  accounting_system_authorization_callback_origins: [
    'https://api.fieldmagic.co/'
  ],
  // TODO: Replace with actual Production Pusher Key.
  pusher: {
    key: '46f5e45ec2ab7b8d9453',
    cluster: 'ap4',
    auth_endpoint: 'https://api.fieldmagic.co/pusher/auth/'
  },
  public_bucket_url: 'https://public-prod.fieldmagic.co/',
  public_bucket: 'fieldmagic-public-files-prod',
  temp_bucket: 'fieldmagic-prod-tmp',
  client_bucket: 'fieldmagic-prod-files',
  firebase: {
    apiKey: "AIzaSyA1ZdPKD0X9bnyZ6JYmg_dRxVoBU4Cw_aw",
    authDomain: "fieldmagic-cloud-production.firebaseapp.com",
    projectId: "fieldmagic-cloud-production",
    storageBucket: "fieldmagic-cloud-production.appspot.com",
    messagingSenderId: "687891244576",
    appId: "1:687891244576:web:56aa4598367d92291dfe45"
  },
  recaptcha_site_key: '6LdCxeEkAAAAABm2M4rJaDzFHvJ4z3MMWNKtZirh',
  archive_domain_name: 'mail.fieldmagic.co'
};
