import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
  Injector,
  ComponentFactoryResolver,
  ApplicationRef,
  ComponentRef,
  ElementRef,
  AfterViewChecked,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import * as moment from 'moment';
import { VNode } from 'preact';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material';
import { FullCalendarComponent as FullCalendarAngularComponent }  from '@fullcalendar/angular';
import { CalendarOptions, EventApi } from '@fullcalendar/common';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { TranslateService } from '@ngx-translate/core';
import interactionPlugin, { EventReceiveArg } from '@fullcalendar/interaction';
import { map, switchMap, tap } from 'rxjs/operators';
import { DomPortalHost } from '@angular/cdk/portal';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { EventContentArg, EventInput, ViewApi, EventClickArg } from '@fullcalendar/core';

import { CalendarService } from '../services/calendar.service';
import { JsonService } from '../../../services/helpers/json/json.service';
import { AWSAppSyncService } from '../../../services/aws-appsync.service';
import { ReadableAddressPipe } from '../../../pipes/readable-address.pipe';
import { RenderedEvents } from './classes/rendered-events/rendered-events';
import { ClientStoreService } from '../../../services/client-store.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { InputSanitizerService } from '../../../services/input-sanitizer.service';
import { GraphQLFilter } from '../../../shared/components/graphql-filter/graphql-filter.component';
import { PlaceholdWithStringPipe } from '../../../pipes/placehold-with-string/placehold-with-string.pipe';
import { FullCalendarGoToDateComponent } from '../full-calendar-go-to-date/full-calendar-go-to-date.component';
import { ArrService } from '../../../services/helpers/arr.service';
import { has, isEmpty, padStart, truncate, get, toString } from 'lodash';
import { TaskDetailsDialogComponent } from '../dialogs/task-details-dialog/task-details-dialog.component';
import { ActivityLogDetailsDialogComponent } from '../dialogs/activity-log-details-dialog/activity-log-details-dialog.component';
import { Calendar } from "@fullcalendar/core";
import { HttpClient } from '@angular/common/http';
import { SearchService } from '../../../services/search.service';
import { CalendarApiService } from '../services/calendar-api.service';
import { TaskMetadataC } from './classes/metadata/task-metadata';
import { CalendarPageDirection } from './classes/calendar-pagination';
import { GlobalRecord } from '../../../objects/global-record';
import { ActivitiesService } from '../../../services/activities.service';
import { RecordService } from '../../../services/record.service';
import { AuthorizedClient } from '../../../features/data-sharing/objects/authorized-client';
import { Relate } from '../../../objects/relate';
import { NotifyViaPushService } from '../../../features/task-calendar/components/services/notify-via-push-service';

/**
 * These colors will be used as an event's (fullcalendar event)
 * background color depending on its task progress.
 *
 * @type {{ [key: string]: string }}
 */
export const TASK_PROGRESS_COLORS: { [key: string]: string } = {
  in_transit: 'rgba(0, 134, 139, 0.1)',
  in_progress: 'rgba(255, 208, 80, 0.1)',
  complete: 'rgba(52, 197, 99, 0.1)',
  scheduled: 'rgba(14, 165, 233, 0.1)',
  deleted: 'rgba(219, 0, 0, 0.1)',
  busy: 'rgba(33, 37, 41, 0.1)',
};

/**
 * These colors are for matching with the current set for deisng
 * purposes.
 *
 * @type {{ [key: string]: string }}
 */
export const TASK_PROGRESS_COLORS_FONT: { [key: string]: string } = {
  in_transit: '#17a2b8',
  in_progress: '#fcb040',
  complete: '#2bb673',
  scheduled: '#0e76bc',
  deleted: '#dc3545',
  busy: '#495057',
};

export const TASK_TEXT_COLOR_FONT: string = '#ffffff';

export const ACTIVITY_LOG_TYPE_COLOR = 'rgb(255, 255, 255)';

export const ACTIVITY_LOG_TYPE_COLOR_FONT = '#202020';
/**
 * The event metadata supplied to FullCalendar's eventDidMount and eventWillUnmount
 * event rendering hooks.
 *
 * @see {@link https://fullcalendar.io/docs/v5/event-render-hooks}
 */
interface EventMountMeta extends EventContentArg {
  el: HTMLElement;
}

/**
 * @see {@link https://fullcalendar.io/docs/v5/datesSet}
 */
interface DateInfo {
  /**
   * A Date for the beginning of the range the calendar needs events for.
   *
   * @type {Date}
   */
  start: Date,

  /**
   * A Date for the end of the range the calendar needs events for. Note: This value
   * is exclusive.
   *
   * @type {Date}
   */
  end: Date,

  /**
   * An ISO8601 string representation of the start date. Will have a time zone offset
   * according to the calendar’s timeZone like 2018-09-01T12:30:00-05:00.
   *
   * @type {String}
   */
  startStr: string,

  /**
   * Just like startStr, but for the end date.
   *
   * @type {String}
   */
  endStr: string,

  /**
   * The exact value of the calendar’s timeZone setting.
   *
   * @see {@link https://fullcalendar.io/docs/v5/timeZone}
   *
   * @type {String}
   */
  timeZone: string,

  /**
   * The current View Object.
   *
   * @see {@link https://fullcalendar.io/docs/v5/view-object}
   *
   * @type {ViewApi}
   */
  view: ViewApi
}

@Component({
  selector: 'fc-full-calendar',
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    CalendarApiService
  ]
})
export class FullCalendarComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, OnChanges  {

  /**
   * View reference for the FullCalendar component. We'll
   * use this to access its API which contains a wide range
   * of methods that we can use in manipulating it.
   */
  @ViewChild('fullcalendar') calendarComponent: FullCalendarAngularComponent;

  /**
   * View reference for the calendar top bar, which contains the buttons
   * and searchbar.
   *
   * @type {ElementRef}
   */
  @ViewChild('calendarTopBar') calendarTopBar: ElementRef;

  /**
   * View reference for the calendar pagination buttons
   *
   * @type {ElementRef}
   */
  @ViewChild('paginationContainer') paginationContainer: ElementRef;

  /**
   * After an event has been scheduled onto the calendar we
   * emit a signal to the tasks component so that it will
   * reload its list of schedulable tasks, removing the
   * one that we have just scheduled.
   */
  @Output() eventReceive: EventEmitter<any> = new EventEmitter();

  /**
   * This will get emitted once the user has clicked the Enlarge/Shrink button.
   */
  @Output() fullscreenDisplay: EventEmitter<boolean> = new EventEmitter();

  /**
   * Similar to the eventReceive emitter above, with their
   * difference being that we trigger this when a third party
   * has scheduled a task. (e.g., a user scheduled a task from
   * another browser)
   */
  @Output() taskScheduledByThirdParty: EventEmitter<EventApi | EventInput> = new EventEmitter();

  /**
   * Triggers when the user duplicates task but didnt assign technician
   */
  @Output() schedulableTaskRefresh: EventEmitter<any> = new EventEmitter();

  /**
   * Contains the selected authorized client/contractor
   */
  @Input('child-client') childClient?: AuthorizedClient;

  /**
   * Flag that checks if the calendar is displayed as a popup or not.
   *
   * @type {boolean}
   */
  @Input('popped-up') bIsPoppedUp: boolean = false;

  /**
   * Which view is being shown at the moment.
   */
  @Input('team-view') teamView: boolean = false;

  /**
   * This is the maximum number of users allowed to be displayed
   * in the calendar.
   *
   * @type {Number}
   */
  readonly FC_MAX_RESOURCE_COUNT: number = 50;

  /**
   * Set of key-value pairs that we will use in configuring
   * FullCalendar to suit our needs.
   */
  options: CalendarOptions = {

    /**
     * Indicates the format of the date title
     * Eg. "Monday, April 06, 2020"
     *
     * @type {Object}
     */
    titleFormat: {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      weekday: 'long'
    },

    /**
     * CRM Online's license key for FullCalendar. Check out this
     * {@link https://stackoverflow.com/questions/44551976/hide-fullcalendar-schedulerlicensekey-in-page|link} for
     * why I didn't think it's necessary to make an effort to hide this license key
     * in an environment variable, whatsoever.
     *
     * @type {String}
     */
    schedulerLicenseKey: '0258041683-fcs-1609118915',

    lazyFetching: true,

    /**
     * An Array of plugins that we're using for FullCalendar
     *
     * @type {Array}
     */
    plugins: [
      /**
       * Scheduler add-on that provides a new view called "timeline view" with a
       * customizable horizontal time-axis and resources as rows.
       *
       * @type {Object}
       */
      resourceTimelinePlugin,

      /**
       * Required plugin for detecting dateClick actions, selectable actions,
       * and event drag-n-drop & resizing.
       *
       * @type {Object}
       */
      interactionPlugin,

      /**
       * Plugin that offers Bootstrap theming of FullCalendar
       *
       * @type {Object}
       */
      bootstrapPlugin
    ],

    /**
     * Renders the calendar with a given theme system. This is where
     * we "activate" the bootstrap plugin
     *
     * @type {String}
     */
    themeSystem: 'bootstrap4',

    /**
     * Turns the resource area from a plain list of titles into a grid of data.
     *
     * @see {@link https://fullcalendar.io/docs/v5/resourceAreaColumns}
     *
     * @type {Array}
     */
    resourceAreaColumns: [
      {
        headerContent: this.translateService.instant('users'),
        field: 'technician_name'
      }
    ],

    /**
     * Determines the ordering of the resource list.
     * Need to set as null to remove default order
     * of resource list.
     *
     * @see {@link https://fullcalendar.io/docs/resourceOrder}
     */
    resourceOrder: null,

    /**
     * A custom function for programmatically generating raw Resources objects.
     * This allows for any sort of asynchronous means of obtaining the resource list.
     *
     * In our use case, "resources" are the technicians whom we drop tasks onto.
     * They are the "assignee" to tasks.
     *
     * @see {@link https://fullcalendar.io/docs/v5/resources-function}
     *
     * @function
     */
    resources: () => this.getFcResources(this.techniciansFilter),

    events: this.getFcEvents.bind(this),

    /**
     * The initial view when the calendar loads. We set it to a default
     * of 'resourceTimelineDay' which looks like a scheduler.
     *
     * @type {String}
     */
    initialView: this.getFcViewType(),

    /**
     * The initial date displayed when the calendar first loads. If a cached
     * initialDate is set, we use it (done from ngOnInit). Otherwise, we use the date
     * today. The cached  initialDate is the last date that the user was viewing.
     *
     * @type {String | Date}
     */
    initialDate: this.getFcInitialDate(),

    /**
     * This determines if the events can be dragged and resized. Enables/disables both at the same time.
     * The "events" we're talking about here are the tasks that are displayed on the calendar.
     *
     * @type {Boolean}
     */
    editable: true,

    /**
     * Determines if external draggable elements or events from other calendars can
     * be dropped onto the calendar. This allows us to drop external events (the
     * schedulable tasks) onto the calendar.
     *
     * @type {Boolean}
     */
    droppable: true,

    /**
     * Sets the width-to-height aspect ratio of the calendar.
     *
     * @type {Float}
     */
    aspectRatio: 1.8,

    /**
     * Whether or not to display a marker indicating the current time.
     *
     * @type {Boolean}
     */
    nowIndicator: true,

    /**
     * Emphasizes certain time slots on the calendar. By default, Monday-Friday,
     * 9am-5pm.
     *
     * @see {@link https://fullcalendar.io/docs/businessHours}
     */
    businessHours: {
      startTime: '08:30',
      endTime: '17:30',
    },

    /**
    * The frequency for displaying time slots
    *
    * @type {String}
    */
    slotDuration: '00:30:00',

    /**
     * A fallback duration for timed Event Objects without a specified end value. If an event
     * does not have an end specified, it will appear to be this duration when rendered.
     *
     * @type {String}
     */
    defaultTimedEventDuration: '01:00:00',

    /**
     * Defines the buttons and title at the top of the calendar.
     *
     * @type {Object}
     */
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },

    /**
     * Customizes an existing available view
     *
     * @type {Object}
     */
    views: {
      resourceTimelineTwoWeeks: {
        buttonText: this.translateService.instant('two_weeks'),
          type: 'resourceTimeline',
          duration: {
            weeks: 2
          }
      },
    },

    /**
     * Text that will be displayed on buttons of the header/footer. We set
     * translated texts for the buttons here.
     *
     * @type {Object}
     */
    buttonText: {},

    /**
     * Determines which icons are displayed in buttons of the header/footer when
     * Bootstrap 4 theming is on.
     *
     * Note: FC-2009: `icon` was actually intended to be here since full calendar automatically
     * appends `fa-` to the class name since `fc-icon` was the classname used we need to have a proxy
     * for enable use to use are fully qualified class name
     *
     * @type {Object}
     */
    bootstrapFontAwesome: {
      prev: 'icon fc-icon fc-icon-chevron-left',
      next: 'icon fc-icon fc-icon-chevron-right',
      prevYear: 'left-double-arrow',
      nextYear: 'right-double-arrow'
    },

    /**
     * Defines custom buttons that can be used in the header/footer.
     *
     * @type {Object}
     */
    customButtons: {},

    /**
     * Called right after the view has been added to the DOM
     *
     * @see {@link https://fullcalendar.io/docs/v5/view-render-hooks}
     */
    viewDidMount: this.handleViewDidMount.bind(this),

    /**
     * A ClassName Input for adding classNames to the root view element. called
     * whenever the view changes.
     *
     * @see {@link https://fullcalendar.io/docs/v5/view-render-hooks}
     */
    viewClassNames: this.handleViewClassNames.bind(this),

    /**
     * Called after the calendar’s date range has been initially set or changed in
     * some way and the DOM has been updated.
     *
     * @see {@link https://fullcalendar.io/docs/v5/datesSet}
     */
    datesSet: this.handleDatesSet.bind(this),

    /**
     * A Content Injection Input. Generated content is inserted inside the inner-most
     * wrapper of the event element. If supplied as a callback function, it is called
     * every time the associated event data changes.
     *
     * @see {@link https://fullcalendar.io/docs/v5/event-render-hooks}
     */
    eventContent: this.handleEventContent.bind(this),

    /**
     * Called right after the element has been added to the DOM. If the event data
     * changes, this is NOT called again.
     *
     * @see {@link https://fullcalendar.io/docs/v5/event-render-hooks}
     */
    eventDidMount: this.handleEventDidMount.bind(this),

    /**
     * Triggered when resizing stops and the event has changed in duration.
     *
     * @see {@link https://fullcalendar.io/docs/v5/eventResize}
     */
    eventResize: this.handleEventResize.bind(this),

    /**
     * Triggered when dragging stops and the event has moved to a different day/time.
     *
     * @see {@link https://fullcalendar.io/docs/v5/eventDrop}
     */
    eventDrop: this.handleEventDrop.bind(this),

    /**
     * Called when an external draggable element with associated event data was
     * dropped onto the calendar. Or an event from another calendar.
     *
     * @see {@link https://fullcalendar.io/docs/v5/eventReceive}
     */
    eventReceive: this.handleEventReceive.bind(this),

    /**
     * Triggered when the user clicks an event.
     *
     * @see {@link https://fullcalendar.io/docs/v5/eventClick}
     */
    eventClick: this.handleEventClick.bind(this),

    /**
     * @see {@link https://fullcalendar.io/docs/v5/resource-render-hooks}
     */
    resourceLabelContent: this.handleResourceLabelContent.bind(this),

    /**
     * @see {@link https://fullcalendar.io/docs/v5/resourceAreaWidth}
     */
    resourceAreaWidth: '15%'
  };

  /**
   * A filter for technicians. Currently, by name.
   *
   * @type {GraphQLFilter}
   */
  techniciansFilter: GraphQLFilter;

  /**
   * How many users are displayed in the calendar?
   *
   * @type {Number}
   */
  fcResourcesCount: number = 0;

  /**
   * The container for our events whose DOM element has already been rendered in the
   * calendar.
   *
   * @type {RenderedEvents}
   */
  protected renderedEvents = new RenderedEvents();

  /**
   * Defines the time slots defined in the calendar. This allows
   * the user to switch between business hours or 24 hours.
   *
   * @type {string}
   */
  private _calendarTimeView: '24_hours' | 'business_hours' = 'business_hours';

  /**
   * List of departments under the current client. This will be used
   * in displaying each user's (FullCalendar Resources) department.
   *
   * @type {Array}
   */
  private _departments: { [key: string]: string }[] = null;

  /**
   * A portal host that allows the attachment of a DOM Portal.
   * We'll attach this._gotoDatePortal here.
   *
   * @type {DomPortalHost}
   */
  private _gotoDatePortalHost: DomPortalHost;

  /**
   * A FullCalendarGoToDateComponent component instance that we will
   * attach to this._gotoDatePortalHost. This will contain a datepicker
   * that allows the user to select a far-away date in which they want
   * FullCalendar to navigate to.
   *
   * @type {ComponentRef<FullCalendarGoToDateComponent>}
   */
  private _gotoDatePortal: ComponentRef<FullCalendarGoToDateComponent>;

  /**
   * Holder of the calendar api which we can get from the CalendarComponent.
   *
   * @type {Calendar}
   */
  private calendarApi: Calendar;

  /**
   * Which resource view are we currently showing.
   *
   * @type {string}
   */
  currentResourceView: string = this.getFcViewType();

  /**
   * Object that will handle the user search UI.
   *
   * @TODO: Move to separate component.
   *
   * @type {user, value, relate}
   */
  objUserSearch = {
    user: null,
    value: null,
    relate: new Relate<string>().buildRelates(
      switchMap((term) => {
        return forkJoin(this.getSearchSources(term))
          .pipe(
            map((results) => {
              let arSearchResults = [];

              results.forEach(result => {
                let arHits: SearchResult[] = result.map(record => ({
                  id: record.id,
                  name: (this.teamView || record.module === 'departments')
                    ? record.name
                    : `${record.other_properties['first_name']} ${record.other_properties['last_name']}`,
                  module: record.module
                }));

                arSearchResults = arSearchResults.concat(arHits);
              });

              return arSearchResults;
            })
          );
        })
    )
  }

  /**
   * Lists the calendar views with big slots in the UI
   *
   * @type {string[]}
   */
  arViewsWithBigSlots: string[] = [
    'resourceTimelineTwoWeeks',
    'resourceTimelineWeek',
    'resourceTimelineMonth',
  ];

  /**
   * Subscribes to actions in task details dialog
   * (schedule/reschedule/duplicate/unschedule/edit)
   * and updates calendar.
   *
   * @type {Subscription}
   */
  updateCalendarSub: Subscription;

  /**
   * Flag that contains the display status of the calendar, if it is
   * in full screen or not.
   *
   * @type {boolean}
   */
  bCalendarFullScreen: boolean = false;

  /**
   * Internal Use: contains list of subscription that is used throughout the component that would be
   * cleaned up when this component is destroyed.
   *
   * @var {Subscription[]}
   */
  protected arSubscriptions: Subscription[] = [];

  get showingFrom(): number {
    return this.calendarServiceApi.numShowStart
  }

  get showingTo(): number {
    return this.calendarServiceApi.numShowEnd
  }

  get showingTotal(): number {
    return this.calendarServiceApi.objPagination.total;
  }

  get hasNextPage(): boolean {
    return this.calendarServiceApi.objPagination.next_page === null;
  }

  get hasPrevPage(): boolean {
    return this.calendarServiceApi.objPagination.prev_page === null;
  }

  get startDateISOString(): string {
    return this.calendarServiceApi.objStartDate.toISOString();
  }

  get endDateISOString(): string {
    return this.calendarServiceApi.objEndDate.toISOString();
  }

  get calendarTimeView(): '24_hours' | 'business_hours' {
    return this._calendarTimeView;
  }

  /**
   * Convert boolean if team view to string.
   *
   * @return {'teams' | 'users'}
   */
  get view(): 'teams' | 'users' {
    return this.teamView ? 'teams' : 'users';
  }

  /**
   * A flag stating that the current calendar is behalf of a contrator/client
   */
  protected get isOnBehalfOfClient() : boolean {
    return ! isEmpty(this.childClient);
  }

  /**
   * @type {number}
   *
   * For storing selected resources size
   */
  numSelectedResourcesSize: number = this.localStorageService.getItem('setSchedulerUserCount') ? parseInt(this.localStorageService.getItem('setSchedulerUserCount')) : 10;

  arResourcesSize = [
      { value: 10, name: '10' },
      { value: 20, name: '20' },
      { value: 50, name: '50' },
  ];

  constructor(
    public clientStoreService: ClientStoreService,
    public notifyPush: NotifyViaPushService,
    protected appSyncService: AWSAppSyncService,
    protected is: InputSanitizerService,
    protected localStorageService: LocalStorageService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    protected calendarService: CalendarService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected appRef: ApplicationRef,
    protected injector: Injector,
    protected el: ElementRef,
    protected placeholdWithStringPipe: PlaceholdWithStringPipe,
    protected notificationService: NotificationService,
    protected json: JsonService,
    protected arrService: ArrService,
    protected readableAddressPipe: ReadableAddressPipe,
    protected http: HttpClient,
    protected searchService: SearchService,
    protected calendarServiceApi: CalendarApiService,
    protected recordService: RecordService,
    protected activityService: ActivitiesService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {

    let listingCache = this.localStorageService.getJsonItem('listing');

    if (listingCache !== null && listingCache['calendar:users'] !== undefined) {
      // So the user has a previous caching of the users list in the calendar.
      // Let's use that for fetching FullCalendar's resources.
      this.techniciansFilter = listingCache['calendar:users'];
    }

    // Updates calendar after actions in task details dialog (schedule/duplicate/unschedule/edit/show on calendar)
    this.updateCalendarSub = this.calendarService.calendarUpdate$.subscribe((task: object)  => {
      let event: EventInput = this.calendarApi.getEventById(
        task['data']['child_id'] || task['data']['id']
      );

      // If event already exists in the current view, remove it to update calendar
      // (only applicable to reschedule and unschedule actions)
      if (event && task['action'] !== 'show_on_calendar') {
        event.remove();
      }

      switch (task['action']) {
        case 'unschedule':
          break;
        case 'reschedule':
        case 'schedule':
          event = this._fcMakeTaskEvent(task['data'], task['user']);
          this.fcBulkRenderEvents([event]);
          break;
        case 'duplicate':
          let arDuplicatedEvents = [];
          task['data'].forEach(objTask => {
            event = this._fcMakeTaskEvent(objTask, task['user']);
            arDuplicatedEvents.push(event);
          });
          this.fcBulkRenderEvents(arDuplicatedEvents);
          break;
        case 'show_on_calendar':
          if (has(task, 'data') && ! isEmpty(task['data']) && task['data']['user_id'] !== null) {
            let due_date = moment.utc(task['data']['due_date']);

            this.calendarApi.gotoDate(due_date.format());
            this.calendarApi.scrollToTime(due_date.local().format("HH:mm:ss"));
          }
          break;
        default:
          this.calendarApi.refetchEvents();
      }
    });
  }

  /**
   * A lifecycle hook that is called after Angular has initialized all data-bound
   * properties of a directive.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    if (this.localStorageService.getItem('setSchedulerUserCount')) {
      this.calendarServiceApi.setResourcesSizePerPage(parseInt(this.localStorageService.getItem('setSchedulerUserCount')));
    }
  }

  ngOnDestroy(): void {
    this.updateCalendarSub.unsubscribe();
  }

  /**
   * Respond after Angular initializes the component's views and child views / the
   * view that a directive is in. Called once after the first ngAfterContentChecked()
   *
   * This is where we render FullCalendar.
   *
   * @returns {void}
   */
  ngAfterViewInit(): void {
    this.translateService.onLangChange.subscribe(() => {
      this._fcTranslateCoreButtonTexts();
    });

    let calendarViewCache = this.localStorageService.getItem(
      'calendar:calendarTimeView'
    );

    this.setCalendarTimeView(calendarViewCache);
    this._fcToggleBusinessAnd24Hours();
    this.changeDetectorRef.detectChanges();

    this._fcTranslateCoreButtonTexts();

    // Move the pagination buttons to the users column in the calendar
    let resourceAreaColumns = [{
      headerContent: {
        domNodes: Array.from(this.paginationContainer.nativeElement.childNodes)
      },
      field: 'technician_name'
    }]

    this.calendarComponent.getApi().setOption('resourceAreaColumns', resourceAreaColumns);
  }

  /**
   * Angular lifecycle that runs after everything is checked. Only runs once.
   *
   * @returns {void}
   */
  ngAfterViewChecked(): void {
    this.calendarApi = this.calendarComponent.getApi();
  }

  /**
   * @inheritdoc
   */
  ngOnChanges({ childClient, teamView }: SimpleChanges) {
    if (teamView && ! teamView.isFirstChange() && teamView.currentValue !== teamView.previousValue) {
      this.objUserSearch.user = null;
      this.objUserSearch.value = null;
      this.resetCalendar();
    }

    if (childClient && ! childClient.isFirstChange() && childClient.currentValue !== childClient.previousValue) {
      this.resetCalendar();
    }
  }

  /**
   * Gets the observables for the search bar items
   *
   * @param {string} strTerm
   *
   * @returns {Observable<GlobalRecord[]>[]}
   */
  private getSearchSources(strTerm: string): Observable<GlobalRecord[]>[] {
    let arSearchSources: Observable<GlobalRecord[]>[] = [];
    let objSearchOptions = {
      ... (this.childClient && {
        for_child_client_id: this.childClient.client_id,
      })
    };

    let usersOrTeamSource: Observable<GlobalRecord[]> = this.searchService.global(strTerm, this.view, objSearchOptions);
    arSearchSources.push(usersOrTeamSource);

    if (!this.teamView) {
      let departmentsSource: Observable<GlobalRecord[]> = this.searchService.global(strTerm, 'departments', objSearchOptions);
      arSearchSources.push(departmentsSource);
    }

    return arSearchSources;
  }

  /**
   * Sets the user filter.
   *
   * @param {GlobalRecord} objSearchOption
   *
   * @returns {void}
   */
  setUser(objSearchOption: GlobalRecord): void {
    this.objUserSearch.value = { id: objSearchOption.id, module: objSearchOption.module }
    this.calendarApi.refetchResources();
  }

  /**
   * Changes the calendar date to the present date.
   *
   * @returns {void}
   */
  goToToday(): void {
    this.calendarApi.today();
  }

  /**
   * Changes the calendar to the date before the present date.
   *
   * @returns {void}
   */
  goToPrevDate(): void {
    this.calendarApi.prev();
  }

  /**
   * Changes the calendar to the date after the present date.
   *
   * @returns {void}
   */
  goToNextDate(): void {
    this.calendarApi.next();
  }

  /**
   * Sets the calendar time view.
   *
   * @param {string} strView
   *
   * @returns {void}
   */
  setCalendarTimeView(strView: '24_hours' | 'business_hours'): void {
    this._calendarTimeView = strView;
  }

  /**
   * Changes the calendar display by to either full screen or default display
   *
   * @returns {void}
   */
  changeDisplayStatus(): void {
    this.bCalendarFullScreen = !this.bCalendarFullScreen;

    this.fullscreenDisplay.emit(this.bCalendarFullScreen);
  }

  /**
   * Clears the user search bar.
   *
   * @returns {void}
   */
  clearUserSearch(): void {
    this.objUserSearch.user = null;
    this.objUserSearch.value = null;
    this.calendarApi.refetchResources();
  }

  /**
   * Runs everytime the input is being typed on.
   *
   * @param {string} strSearchTerm
   *
   * @returns {void}
   */
  searchUser(strSearchTerm: string): void {
    this.objUserSearch.relate.typehead.next(strSearchTerm);
  }

  /**
   * Toggles the hours view button.
   *
   * @returns {void}
   */
  changeHoursView(): void {
    this._fcToggleBusinessAnd24Hours();
  }

  /**
   * When the date changes.
   *
   * @param {any} objEvent
   *
   * @returns {void}
   */
  onGoToDateChange(objEvent: any): void {
    this.localStorageService.setItem(
      'calendar:options:initialDate',
      moment(objEvent.value._d).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    );
    this.calendarApi.gotoDate(objEvent.value._d);
    this.calendarService.eventsAreLoading.next(false);
  }

  /**
   * When the view type is changed.
   *
   * @param {string} strViewType
   *
   * @returns {void}
   */
  changeViewType(strViewType: string): void {

    if (strViewType == 'resourceTimelineDay') {
      this.localStorageService.setItem(
        'calendar:options:initialDate',
        moment(this.calendarApi.getDate()).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
      );
      this.calendarService.eventsAreLoading.next(false);
    }

    this.currentResourceView = strViewType;
    this.calendarApi.changeView(strViewType);
  }

  /**
   * Called right after the view has been added to the DOM.
   *
   * @param {{view: ViewApi, el: HTMLElement}} arg
   *
   * @returns {void}
   */
  handleViewDidMount(arg: { view: ViewApi, el: HTMLElement }): void {
    // We're storing the portal host's instance because it is possible for
    // FullCalendar to destroy the calendar including the custom buttons. When that
    // happens, we want to make sure that we don't create another portal instance
    // (Which will mean the gotodate component will be rendered more than once).
    this._gotoDatePortalHost = this.makeDomPortalHost(
      arg.el.parentElement.parentElement
    );
  }

  /**
   * A ClassName Input for adding classNames to the root view element. called
   * whenever the view changes.
   *
   * @param {{view: ViewApi, el: HTMLElement}} arg
   *
   * @returns {void}
   */
  handleViewClassNames(arg: { view: ViewApi, el: HTMLElement }): void {
    let view_type = arg.view.type;

    this.localStorageService.setItem('calendar:options:initialView', view_type);
    this._fcChangeSlotDuration(view_type);
  }

  /**
   * Called after the calendar’s date range has been initially set or changed in some
   * way and the DOM has been updated.
   *
   * @see {@link https://fullcalendar.io/docs/v5/datesSet}
   *
   * @param {DateInfo} date_info
   *
   * @returns {void}
   */
  handleDatesSet(date_info: DateInfo): void {
    this.localStorageService.setItem(
      'calendar:options:initialDate',
      date_info.startStr
    );
  }

  /**
   * This is where we'll be attaching customizations to each event's HTML structure.
   * For now, we'll be only adding a tooltip.
   *
   * @see {@link https://preactjs.com/guide/v8/getting-started/#rendering-jsx}
   *
   * @param {EventContentArg} arg
   * @param {Function} h
   *
   * @returns {VNode}
   */
  handleEventContent(arg: EventContentArg, h: Function): VNode {
    let objMetadata: any = this.calendarService.getRecordMetadata(arg.event);
    let strMetadataType: string = this.calendarService.getRecordMetadataType();
    let strTaskModule: 'job' | 'opportunity' = (() => {
      if (objMetadata['metadata_type'] === 'task') {
        return !isEmpty(objMetadata['job']) ? 'job' : 'opportunity';
      }

      return objMetadata['metadata_type'];
    })();
    let tooltip = null;
    // strModuleNumber is either the job number or the opportunity number
    let strModuleNumber: string = objMetadata[strTaskModule] ? get(objMetadata, `${strTaskModule}.${strTaskModule}_number`) : get(objMetadata, `${strTaskModule}_number`);
    let strAddress: string = objMetadata[strTaskModule] ? get(objMetadata, `${strTaskModule}.full_address`) : get(objMetadata, 'full_address');
    let strCustomerId: string = objMetadata[strTaskModule] ? get(objMetadata, `${strTaskModule}.customer.id`, null) : get(objMetadata, 'customer.id', null);
    let strNoCustomerText: string = this.translateService.instant('no_customer');
    let strCustomerName: string = (() => {
      if (!strCustomerId) {
        return strNoCustomerText;
      }

      if (objMetadata['metadata_type'] === 'task') {
        return get(objMetadata, `${strTaskModule}.customer.name`, strNoCustomerText);
      } else {
        return get(objMetadata, 'customer.name', strNoCustomerText);
      }
    })();

    if (arg.isMirror === false) {
      // Mirrors are like the placeholders of events. They're displayed before the
      // events but will not actually be "hoverable". So to improve performance,
      // let's remove the creation of a tooltip for mirror events.
      tooltip = this
        .calendarService
        .formatTooltip(strMetadataType, {
          ...objMetadata,
          ...{
            departments: this._departments
          }
        });
    }

    if (strMetadataType == 'activity_log_type') {
      return h('span', {}, [
        h('span', { class: 'font-weight-bold' }, objMetadata.name)
      ]);
    }

    let numEstimatedDuration: number = Number(objMetadata.estimated_duration);
    let strTaskParentColorClass: string = strTaskModule === 'job' ? 'bg-success' : 'bg-warning';
    let minimizedTaskDisplay: VNode = h('span', { title: tooltip }, [
      h('div', { }, [
        h('b',
          {
            class: `d-flex justify-content-center align-items-center rounded-circle h-18px w-18px float-right task-parent-type ${strTaskParentColorClass}`
          },
          strTaskModule === 'job' ? 'J' : 'Q'
        ),
        h('span', { class: 'font-weight-bold' }, strCustomerName + ': #' + padStart(strModuleNumber, 6, '0')),
      ]),
    ]);

    // If task duration is less than 1 hour, minimize the display.
    if (numEstimatedDuration < 1 && numEstimatedDuration !== 0) {
      return minimizedTaskDisplay;
    } else {
      // If not in day view and task duration is 1 hour, task display is also minimized.
      // note: if task duration is 0, it automatically defaults to 1
      if ((numEstimatedDuration === 1 || numEstimatedDuration === 0) && this.arViewsWithBigSlots.includes(this.getFcViewType())) {
        return minimizedTaskDisplay;
      } else {
        return h('span', { title: tooltip }, [
          h('div', { }, [
            h('b',
              {
                class: `d-flex justify-content-center align-items-center rounded-circle h-18px w-18px float-right task-parent-type ${strTaskParentColorClass}`
              },
              strTaskModule === 'job' ? 'J' : 'Q'
            ),
            h('span', { class: 'font-weight-bold event-font-1' }, strCustomerName + ': #' + padStart(strModuleNumber, 6, '0')),
          ]),
          h(
            'span',
            {
              class: 'font-weight-normal event-font-1',
            },
            strAddress
          ),
          ... (objMetadata.viewable && [
            h('small', { class: 'd-block font-weight-normal event-font-2' }, truncate(objMetadata.name, { length: 30 })),
          ]),
        ]);
      }
    }
  }

  /**
   * This is where we add customizations to each DOM Element of an event in the
   * calendar such as modifying border-radius, adding class names, and attaching
   * domportalhosts for our event action buttons.
   *
   * @param {EventMountMeta} arg
   *
   * @returns {void}
   */
  handleEventDidMount(arg: EventMountMeta): void {
    let el = arg.el;

    el.style['border-radius'] = '3px';
    el.classList.add('d-flex', 'justify-content-between');
  }

  /**
   * Triggered when resizing stops and the event has changed in duration.
   *
   * @param {EventReceiveArg} objEvent
   */
  handleEventResize(eventResizeInfo: EventReceiveArg): void {

    let objMetadata = this.calendarService.getEventData(eventResizeInfo);
    let strTechnicianId = eventResizeInfo.event._def.resourceIds[0];

    if (objMetadata instanceof TaskMetadataC) {

      let objTask: TaskMetadataC = objMetadata;

      let numEstimatedDuration = moment
        .duration(moment(eventResizeInfo.event.end)
        .diff(moment(eventResizeInfo.event.start)))
        .asHours();

      let strDueDate = moment
        .utc(this.checkDSTAndModifyDate(eventResizeInfo.event.start.toISOString()))
        .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

      objTask.estimated_duration = numEstimatedDuration;
      objTask.viewable = true;
      objTask.due_date = strDueDate;

      this.scheduleTaskAndReRenderEvents(
        objTask,
        strDueDate,
        numEstimatedDuration,
        strTechnicianId
      );

    } else {
      this.recordService.saveRecord(
        'activity_logs',
        {
          user_id: strTechnicianId,
          start_datetime: moment.utc(eventResizeInfo.event.start).format("YYYY-MM-DD HH:mm:ss"),
          end_datetime: moment.utc(eventResizeInfo.event.end).format("YYYY-MM-DD HH:mm:ss"),
        },
        objMetadata.id
      ).subscribe();
    }
  }

  /**
   * Triggered when dragging stops and the event has moved to a different day/time.
   *
   * @param {EventReceiveArg} objEvent
   */
  handleEventDrop(objEvent: EventReceiveArg): void {
    let objMetadata = this.calendarService.getEventData(objEvent);
    let strTechnicianId = objEvent.event._def.resourceIds[0];

    if (objMetadata instanceof TaskMetadataC) {

      let objTask: TaskMetadataC = objMetadata;

      let strDueDate: string = moment
      .utc(this.checkDSTAndModifyDate(objEvent.event.start.toISOString()))
      .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

      objTask.due_date = strDueDate;
      objTask.viewable = true;

      this.scheduleTaskAndReRenderEvents(
        objTask, strDueDate, objTask.estimated_duration, strTechnicianId
      );

    } else {
      this.recordService.saveRecord(
        'activity_logs',
        {
          user_id: strTechnicianId,
          start_datetime: moment.utc(objEvent.event.start).format("YYYY-MM-DD HH:mm:ss"),
          end_datetime: moment.utc(objEvent.event.end).format("YYYY-MM-DD HH:mm:ss")
        },
        objMetadata['id']
      ).subscribe();
    }

  }

  /**
   * Called when an external draggable element with associated event
   * data was dropped onto the calendar. Or an event from another calendar.
   *
   * @param {EventReceiveArg} objEvent
   *
   * @todo Add error handling for when the scheduling has failed. We must "put back"
   * the schedulable task that we had removed from the list to let the user know
   * that he must try to schedule it again.
   *
   * @returns {void}
   */
  handleEventReceive(objEvent: EventReceiveArg): void {
    let objMetadata = this.calendarService.getRecordMetadata(objEvent);
    let strMetadataType = this.calendarService.getRecordMetadataType();
    let strTechnicianId: string = objEvent.event._def.resourceIds[0];
    let strTaskModule: 'job' | 'opportunity' = (() => {
      if (objMetadata['metadata_type'] === 'task') {
        return !isEmpty(objMetadata['job']) ? 'job' : 'opportunity';
      }

      return objMetadata['metadata_type'];
    })();

    // ignore scheduling of activity when context is on behalf of other client
    if (strMetadataType === 'activity_log_type' && this.isOnBehalfOfClient) {
      objEvent.event.remove();
      this.notificationService.notifyWarning('cross_client_schedule_activity_log_not_allowed');
      return;
    }

    if (strMetadataType == 'activity_log_type') {
      this.recordService.saveRecord(
        'activity_logs',
        {
          activity_log_type_id: objMetadata['id'],
          user_id: strTechnicianId,
          start_datetime: moment.utc(objEvent.event.start.toISOString()).format("YYYY-MM-DD HH:mm:ss"),
          end_datetime: moment.utc(objEvent.event.end.toISOString()).format("YYYY-MM-DD HH:mm:ss"),
        }
      ).subscribe();
    } else {
      let strDueDate: string = moment
      .utc(this.checkDSTAndModifyDate(objEvent.event.start.toISOString()))
      .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

      let numDuration = !objMetadata['estimated_duration'] || objMetadata['estimated_duration'] == 0 ? 1 : objMetadata['estimated_duration'];

      if (strMetadataType !== 'task' && objMetadata['tasks'].length < 1) {
        this.notificationService.notifyWarning(`no_${strTaskModule}_tasks_to_schedule`);
        if (objEvent) {
          objEvent.event.remove();
        }
        return;
      }

      let taskData: Array<TaskPayload> = [];

      if (strMetadataType !== 'task' && objMetadata['tasks'].length > 0) {
        objMetadata['tasks'].forEach(tasks => {
          taskData.push({
            id: tasks.id,
            parent_id: objMetadata['id'],
          });
        });
      } else {
        taskData.push({
          id: objMetadata.id,
          parent_id: objMetadata[strTaskModule].id,
        });
      }

      this.calendarServiceApi.scheduleTask(
        taskData,
        {
          due_date: strDueDate,
          assigned_id: strTechnicianId,
          assigned_type: this.teamView ? 'team' : 'user',
          ... (this.isOnBehalfOfClient && {
            for_child_client_id: this.childClient.client_id,
          }),
          ... (strMetadataType === 'task' && {
            duration: numDuration,
          }),
          notify_via_push: this.notifyPush.shouldNotify(strDueDate),
          push_notification_body: this.notifyPush.makePushNotificationBody({[objMetadata.id]: {due_date: strDueDate}}),
        }
      ).subscribe((tasks) => {
        this.calendarService.updateForSchedulingList.next({
          data: objMetadata,
          action: 'schedule',
          dragged_from_list: true,
        });

        if (strMetadataType !== 'task' && objMetadata['tasks'].length > 0) {
          tasks = (this.isOnBehalfOfClient) ? tasks : objMetadata['tasks'];

          tasks = tasks.map(task => {
            let objNewTaskData = {
              assigned_user: {id: strTechnicianId},
              description: task.description,
              activity_date: strDueDate,
              due_date: strDueDate,
              estimated_duration: parseFloat(task.estimated_duration) || 1,
              id: task.id,
              job: task.job,
              opportunity: task.opportunity,
              name: task.name,
              priority: task.priority,
              status: 'scheduled',
              viewable: true,
              reschedulable: true,
              department: task.department
            };

            return this._fcMakeTaskEvent(objNewTaskData, objNewTaskData.assigned_user)
          });
        } else {

          tasks = (this.isOnBehalfOfClient) ? tasks : [{
            ... objMetadata,
            status: 'scheduled',
            estimated_duration: numDuration,
            viewable: true,
          }];

          tasks = tasks.map((task) => this._fcMakeTaskEvent(task, {
            id: strTechnicianId
          }));
        }

        this.fcBulkRenderEvents(tasks);

        if (strMetadataType !== 'task') {
          let objEvent = this.calendarApi.getEventById(objMetadata.id);
          objEvent.remove();
        }

        // this is necessary as the task scheduled to other client
        // from the current client is being copied a new task is being created
        // in the other client hence the current task scheduled is completely useless
        if (this.isOnBehalfOfClient) {
          objEvent.event.remove();
        }

      }, error => {
        if (error) {
          this.calendarApi.refetchEvents();
          this.notificationService.notifyError('could_not_schedule');
        }
      });
    }
  }

  /**
   * Calls the schedule API and then call rerender events
   * once an event is scheduled.
   *
   * @param {TaskMetadataC} objTask
   * @param {string} strDueDate
   * @param {number} numDuration
   * @param {string} strUserId
   */
  scheduleTaskAndReRenderEvents(objTask: TaskMetadataC, strDueDate: string, numDuration: number, strUserId: string) {

    this.calendarServiceApi.scheduleTask(
      [{
        id: objTask.id,
        parent_id: isEmpty(objTask.job) ? get(objTask, 'job.id', '') : get(objTask, 'opportunity.id', ''),
      }],
      {
        due_date: strDueDate,
        duration: numDuration,
        assigned_id: strUserId,
        assigned_type: this.teamView ? 'team' : 'user',
        rescheduled: true,
        notify_via_push: this.notifyPush.shouldNotify(strDueDate),
        ... (this.isOnBehalfOfClient && {
          for_child_client_id: this.childClient.client_id,
        }),
        push_notification_body: this.notifyPush.makePushNotificationBody({[objTask.id]: {due_date: strDueDate}}),
      }
    ).subscribe(() => {
        this.fcBulkRenderEvents([
          this._fcMakeTaskEvent(objTask, {id: strUserId})
        ]);
      }, error => {
        if (error) {
          this.calendarApi.refetchEvents();
          this.notificationService.notifyError('could_not_schedule');
        }
      });
  }

  /**
   * Triggered when the user clicks an event. When an event
   * is clicked, we'll show the following task information
   * in a popup fashion:
   * 1.) Site details
   * 2.) Job details
   * 3.) Task details
   *
   * @param {EventClickArg} eventClickInfo
   */
  handleEventClick(eventClickInfo: EventClickArg): void {

    let objMetadata = this.calendarService.getRecordMetadata(eventClickInfo);
    let strTaskModule: 'job' | 'opportunity' = get(objMetadata, 'job') ? 'job' : 'opportunity';
    let strMetadataType = this.calendarService.getRecordMetadataType();

    if (strMetadataType == 'activity_log_type' && ! this.isOnBehalfOfClient) {
      let dialog = this.dialog.open(ActivityLogDetailsDialogComponent, {
        minHeight: 'auto',
        width: '500px',
        data: objMetadata
      });
      dialog
        .afterClosed()
        .subscribe( objDialogResult => {
          if (objDialogResult && objDialogResult['action'] == 'delete') {
            this.recordService.deleteRecord('activity_logs', objMetadata['id']).subscribe( objResponse => {
              let objEvent = this.calendarApi.getEventById(objMetadata.id);
              objEvent.remove();
              this.notificationService.notifySuccess('deleted');
            });
          }

          if (objDialogResult && objDialogResult['action'] != 'delete') {
            this.calendarService.updateForSchedulingList.next(objDialogResult);
            this.calendarService.updateCalendar.next(objDialogResult);
          }
        });
    } else {
      if (! objMetadata['viewable']) {
        return;
      }

      if (get(objMetadata, `${strTaskModule}.${strTaskModule}_number`)) {
        let dialog = this.dialog.open(TaskDetailsDialogComponent, {
          maxWidth: '84vw',
          width: '1280px',
          data: {
            ...this.calendarService.formatTaskForDialogDisplay(objMetadata),
            ... (this.isOnBehalfOfClient && {
              for_child_client_id: this.childClient.client_id,
            }),
            scheduled_task: true,
            view: this.view
          }
        });

        dialog
          .afterClosed()
          .filter(objDialogResult => !!objDialogResult)
          .subscribe(objDialogResult => {
            if (!objDialogResult.action && !objDialogResult.editedTask) {
              return;
            }

            this.calendarService.updateForSchedulingList.next({
              ... objDialogResult,
              action: objDialogResult.action,
            });
            this.calendarService.updateCalendar.next(objDialogResult);
          });
      }
    }
  }

  /**
   * Allows us to manipulate how the resources in the calendar are being rendered.
   *
   * @see {@link https://preactjs.com/guide/v8/getting-started/#rendering-jsx}
   *
   * @param {object} arg
   * @param {Function} h
   *
   * @returns {VNode}
   */
  handleResourceLabelContent(arg, h: Function): VNode {
    // Make each cell content fall down to the next line so that it look like this:
    // ___________________________________
    // | Sample Client Name               |
    // | <Job Title>                      |
    // | <Department>                     |
    // -----------------------------------
    let tech = arg.resource.extendedProps;

    return h('div', { class: 'username-label' }, [
      h('span', null, tech.technician_name),
      //h('div', { class: 'text-muted technician-job-title' }, tech.job_title),
      //h('small', { class: 'text-muted technician-job-title' }, tech.department_text),
    ]);
  }

  /**
   * Adds the given events onto the calendar. Only real-time events should are being
   * sent to FullCalendar because it doesn't know about the given events yet. Events
   * that are added by the current user right now should not be re-added. (Because
   * they're already there.)
   *
   * @todo Investigate bug where receiving tasks in real-time increases DOM nodes (by
   * about 5, forgot the exact number but kinda negligible for now) when the event is
   * added by the current user. Scenario: current user adds event on calendar, real-
   * time update gets fired, adding another event on the calendar. DOM nodes should
   * not increase since the event is already in the calendar.
   *
   * @see {@link https://crmonline.atlassian.net/browse/FC-2385} For performance,
   * events that were not changed will not be re-added or added to the calendar again
   *
   * @param  {Object[]} event
   *
   * @returns {void}
   */
  fcBulkRenderEvents(events: object[]): void {
    let calendarApi = this.calendarComponent.getApi();

    if (calendarApi === undefined || calendarApi === null) {
      return;
    }

    let events_to_add = [];
    let events_to_update = [];

    events.forEach((event) => {
      let rendered_calendar_event_cache = this.renderedEvents.get(event['id']);

      if (rendered_calendar_event_cache !== null
        && this.renderedEvents.isEqual(rendered_calendar_event_cache, event)) {
        // This ensures that the memory-intensive rendering process of an event are
        // only done once for each event. (unless the task info has been modified in
        // which case, the task should be rendered updated.)
        return;
      }

      let rendered_calendar_event = calendarApi.getEventById(event['id']);

      if (rendered_calendar_event === null) {
        // We've determined that this event isn't in the calendar yet. That means
        // we're just going to add it to the calendar, as usual. No special tricks.
        this.renderedEvents.push(event);
        events_to_add.push(event);
      } else {
        this.renderedEvents.replace(event['id'], event);
        events_to_update.push([rendered_calendar_event, event]);
      }
    });

    events_to_add.forEach(e => {
      let event = calendarApi.addEvent(e, true);

      let meta = event.extendedProps['recordMetadata'];

      let color = TASK_PROGRESS_COLORS_FONT[meta.task_progress || 'scheduled'];

      event.setProp('color', color);
      event.setProp('textColor', TASK_TEXT_COLOR_FONT);
      event.setProp('borderColor', TASK_TEXT_COLOR_FONT);
    });

    events_to_update.forEach(([rendered_calendar_event, event]) => {

      let meta = event['recordMetadata'];
      let color = TASK_PROGRESS_COLORS_FONT[meta.task_progress || 'scheduled'];

      rendered_calendar_event.setExtendedProp('recordMetadata', meta);
      rendered_calendar_event.setStart(event['start']);
      rendered_calendar_event.setEnd(event['end']);
      rendered_calendar_event.setResources([event['resourceId']]);
      rendered_calendar_event.setProp('color', color);
      rendered_calendar_event.setProp('textColor', TASK_TEXT_COLOR_FONT);
      rendered_calendar_event.setProp('borderColor', TASK_TEXT_COLOR_FONT);
    });
  }

  /**
   * Looks for the calendar event with the given id and if found,
   * will proceed to remove it.
   *
   * @param event_id
   *
   * @returns {void}
   */
  fcRemoveEvent(event_id): void {
    let calendarApi = this.calendarComponent.getApi();

    if (calendarApi !== undefined
        && calendarApi !== null
        && calendarApi.getEventById(event_id)) {
      calendarApi.getEventById(event_id).remove();
    }
  }

  /**
   * Gets called whenever the date on the calendar changes, allows us to retrieve a
   * new set of data for the given dates.
   *
   * @see {@link https://fullcalendar.io/docs/event-source-object}
   *
   * @todo Add tests for the toggling of loaders.
   *
   * @param info
   * @param successCallback
   * @param failureCallback
   *
   * @returns {void}
   */
  getFcEvents(info, successCallback, failureCallback): void {

    //this.calendarService.eventsAreLoading.next(true);

    this.calendarServiceApi.objStartDate = moment(info.startStr);
    this.calendarServiceApi.objEndDate = moment(info.endStr);

    this
      .getScheduledTasks()
      .pipe(
        tap(() => this.calendarService.eventsAreLoading.next(false)),
        tap(events => {

          this.calendarComponent.getApi().removeAllEvents();
          // To make sure that we do not have any duplicates on the calendar, we take
          // each event, check if it is already rendered in the calendar, then update
          // its details.
          this
            .fcBulkRenderEvents(
              events.filter(({ id }) => this.renderedEvents.has(id))
            );
        })
      )
      .subscribe(events => successCallback(events));
  }

  /**
   * Navigate to new pages.
   *
   * @param {CalendarPageDirection} strDirection
   */
  accessPage(strDirection: CalendarPageDirection) {
    this.calendarServiceApi.setPage(strDirection);
    this.calendarApi.refetchResources();
  }

  /**
   * Gets a list of technicians from an external source
   * then sets it in our FullCalendar configuration
   * object.
   *
   * @param {GraphQLFilter} filter
   *
   * @returns {Promise<Select[]>}
   */
  async getFcResources(filter?: GraphQLFilter): Promise<any[]> {
    this.calendarService.resourcesAreLoading.next(true);

    const options = {
      view: this.view,
      ... (this.isOnBehalfOfClient && {
        for_child_client_id: this.childClient.client_id,
      }),
    };

    try {
      let arTechnicians = await this.calendarServiceApi.getResources(this.objUserSearch.value, options).pipe(
        map((response: any) => {
          return response.items.map(user => {
            return {
              id: user.id,
              technician_name: user.display_name
            };
          });
        }),
      ).toPromise();

      this.calendarService.resourcesAreLoading.next(false);

      this.calendarComponent.getApi().refetchEvents();

      return arTechnicians;

    } catch (err) {
      console.log(`There was an error with your request: ${err}`);
      this.calendarService.resourcesAreLoading.next(false);
    }
  }

  /**
   * Returns the current view type set in local storage. When not found however, will
   * fall back to 'resourceTimelineDay'
   *
   * @returns {string}
   */
  protected getFcViewType(): string {
    return this.localStorageService.getItem('calendar:options:initialView')
      || 'resourceTimelineDay';
  }

  /**
   * Switches the calendar's time view between
   * 24 hours and business hours.
   *
   * @returns {void}
   */
  protected _fcToggleBusinessAnd24Hours(): void {
    let calendarWas24Hours = this.calendarTimeView === '24_hours';

    this._fcTranslateCoreButtonTexts();

    let calendarApi = this.calendarComponent.getApi();
    let strSlotMinTime: string = calendarWas24Hours ? '00:00:00' : '08:30:00';
    // Adjust timeslot a bit to make time display readable
    let strSlotMaxTime: string = calendarWas24Hours ? '23:59:59' : '18:10:00';

    calendarApi.setOption('businessHours', {
      startTime: strSlotMinTime,
      endTime: strSlotMaxTime
    });
    calendarApi.setOption('slotMinTime', strSlotMinTime);
    calendarApi.setOption('slotMaxTime', strSlotMaxTime);

    this.localStorageService.setItem('calendar:calendarTimeView', this.calendarTimeView);
    this.setCalendarTimeView(calendarWas24Hours ? 'business_hours' : '24_hours');

    // Change slot duration of the calendar when calendar time view is adjusted
    this._fcChangeSlotDuration(this.getFcViewType());
  }

  /**
   * Returns a date that will be used as the calendar's initialDate option value.
   * When there's a cached initialdate, it will be returned as a date instance.
   * Otherwise, the current date will be returned.
   *
   * @returns {Date}
   */
  protected getFcInitialDate(): Date {
    return new Date();
  }

  /**
   * Changes the calendar's slot duration based on view
   *
   * If in week, 2 weeks, or month view, set 3hrs as interval
   * If in day view, have 30mins interval between time
   *
   * @param {string} selected_type
   *
   * @returns {void}
   */
  private _fcChangeSlotDuration(selected_type: string) {
    if (this.arViewsWithBigSlots.includes(selected_type)) {
      this.options.slotDuration = '03:00:00';
    } else {
      this.options.slotDuration = '00:30:00';
    }
  }

  /**
   * Creates a FullCalendar event object out of the data (assumed to be a task
   * record) given. A `taskMetadata` attribute will be attached to the resulting
   * object which contains the given task.
   *
   * @see {@link https://crmonline.atlassian.net/browse/FC-2385} This function takes
   * up precious ram because it creates a DOM element for each event then attaches
   * it later in the renderer to act as the event's title. For performance, we'll
   * cache the generated task so we can use it later
   *
   * @param data
   *
   * @returns {EventInput}
   */
  protected _fcMakeTaskEvent(task, user?): EventInput {
    let duration = this.is.toFloat(task.estimated_duration) || 1;

    // The timezone we have set for FullCalendar is 'local'. Meaning,
    // dates passed onto it should be UTC/ISO Format which it then
    // will convert to the browser's current timezone.
    task.job_task_eta = task.job_task_eta !== null ? moment.utc(task.job_task_eta).format() : null;
    task.due_date = this.checkDSTAndModifyDate(task.due_date, true);
    task.metadata_type = 'task';
    task.task_progress = task.status;

    task.date_completed = task.date_completed !== null
      ? moment.utc(task.date_completed).format()
      : null;

    let start = moment(task.due_date).format();
    let end = moment(task.due_date).add(duration, 'hours').format();

    const getTaskNumber = () => {
      if (task.job) {
        return task.job.job_number;
      }

      if (task.opportunity) {
        return task.opportunity.opportunity_number;
      }

      return null;
    }

    let backgroundColor = (getTaskNumber() === null) ? 'deleted' : task.status;

    // override task color when task is not viewable from the user
    if (! task.viewable && task.status !== 'deleted') {
      backgroundColor = 'busy';
    }

    const isReschedulable = task.reschedulable && task.status !== 'deleted';

    return {
      textColor: TASK_TEXT_COLOR_FONT,
      borderColor: TASK_TEXT_COLOR_FONT,
      id: task.id,
      recordMetadata: { ...task, ...{user} },
      resourceId: user.id,
      start: start,
      end: end,
      color: TASK_PROGRESS_COLORS_FONT[(backgroundColor !== '') ? backgroundColor : 'scheduled'],
      ...(user.id !== null && { resourceId: user.id }),
      startEditable: isReschedulable,
      durationEditable: isReschedulable,
      editable: isReschedulable,
      resourceEditable: isReschedulable,
    };
  }

  /**
   * Returns all tasks whose `activity_due_date` lies between "due_date_start" and
   * "due_date_end". The tasks displayed on the calendar are limited to one-thousand
   * (1000) on all view types.
   *
   * @see {@link https://crmonline.atlassian.net/browse/FC-2385}
   *
   * @param {moment.Moment} due_date_start
   * @param {moment.Moment} due_date_end
   *
   * @todo Ensure that events fetched are only those that doesn't exist in the
   * calendar yet (for network optimization and to remove the excess logic below that
   * removes duplicate tasks)
   *
   * @returns {Observable<EventInput[]>}
   */
  protected getScheduledTasks(): Observable<EventInput[]> {

    const options = {
      view: this.view,
      ... (this.isOnBehalfOfClient && {
        for_child_client_id: this.childClient.client_id,
      }),
    };

    return this.calendarServiceApi.getEvents([], options)
    .pipe(
      map(items => {
        return this.makeCalendarViewEvent(items);
      })
    );
  }

  /**
   * Modifies the given html element so that it can be used as a dom portal outlet,
   * allowing us to attach another component to it.
   *
   * @param el
   */
  protected makeDomPortalHost(el: HTMLElement): DomPortalHost {
    return new DomPortalHost(
      el,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
  }

  /**
   * Sets the text in FullCalendar's buttons depending on
   * the currently defined language.
   *
   * @returns {void}
   */
  private _fcTranslateCoreButtonTexts(): void {
    this.options.buttonText = {
      today: this.translateService.instant('today'),
      month: this.translateService.instant('month'),
      day: this.translateService.instant('day'),
      list: this.translateService.instant('list'),
      twoWeeks: this.translateService.instant('two_weeks'),
      week: this.translateService.instant('week'),
    };
  }

  /**
   * Simply checks if it's currently DST and subtracts
   * an hour from the date.
   *
   * @param {string} strDate
   *
   * @returns {string}
   */
   private checkDSTAndModifyDate(strDate: string, bToUtc: boolean = false): string {
    if (bToUtc) {
      return moment.utc(strDate).format();
    } else {
      return moment(strDate).format();
    }
  }

  /**
   * merge calendar event items
   *
   * @param objItemsView
   *
   * @returns {EventInput[]}
   */
  protected makeCalendarViewEvent(objItemsView): EventInput[] {
    let arEvents = [];
    objItemsView.tasks.forEach( objTask => {
      arEvents.push(this._fcMakeTaskEvent(objTask, objTask.assigned_user));
    })
    objItemsView.activity_logs.forEach( objActivityLogs => {
      arEvents.push(this._fcMakeActivityLogsEvent(objActivityLogs, objActivityLogs.assigned_user));
    })
    return arEvents;
  }

  /**
   * @returns {EventInput}
   */
  protected _fcMakeActivityLogsEvent(objActivityLog, user?): EventInput {

    objActivityLog.name = objActivityLog.log_type.name;
    objActivityLog.metadata_type = 'activity_log_type';
    objActivityLog.start = this.checkDSTAndModifyDate(objActivityLog.start, true);
    objActivityLog.end = this.checkDSTAndModifyDate(objActivityLog.end, true);

    let strStartDate = moment(objActivityLog.start).format();
    let strEndDate = moment(objActivityLog.end).format();

    // activity cannot be worked on by the master client
    const workable = ! this.isOnBehalfOfClient;

    return {
      textColor: TASK_TEXT_COLOR_FONT,
      borderColor: TASK_TEXT_COLOR_FONT,
      id: objActivityLog.id,
      recordMetadata: { ...objActivityLog, ...{user} },
      resourceId: user.id,
      start: strStartDate,
      end: strEndDate,
      title: objActivityLog.log_type.name,
      color: 'rgb(106, 13, 173)',
      ...(user.id !== null && { resourceId: user.id }),
      editable: workable,
      startEditable: workable,
      durationEditable: workable,
      resourceEditable: workable,
    };
  }

  /**
   * Call reset and refetch of calendar data.
   *
   * @returns {void}
   */
  private resetCalendar(): void {
    this.calendarServiceApi.resetPage();
    this.calendarComponent.getApi().refetchResources();
    this.calendarComponent.getApi().refetchEvents();
  }

  /**
   * Change Users Display Size
   *
   * @param objEvent
   *
   */
  changeResourcesSize(objEvent: any){
    this.calendarServiceApi.setResourcesSizePerPage(objEvent.value);
    this.localStorageService.setItem('setSchedulerUserCount', objEvent.value);
    this.calendarApi.refetchResources();
  }
}

export interface TaskPayload {
  id: string,
  parent_id: string,
}

type SearchResult = {
  /**
   * ID of the result
   */
  id: string;
  /**
   * The name of the result
   */
  name: string;
  /**
   * The module of the result
   */
  module: string;
}
