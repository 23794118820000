import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutAccountComponent } from './shared/layouts/layout-account/layout-account.component';
import { LayoutDashboardComponent } from './shared/layouts/layout-dashboard/layout-dashboard.component';
import { UserLevelModule } from './guards/user-level-module';
import { ErrorComponent } from './error/error.component';
import { MinimumSubscriptionRequiredGuard } from './guards/minimum-subscription-required/minimum-subscription-required.guard';
import { SubscriptionPlanTypes as SPT, ENTERPRISE_PLAN, ADVANCED_PLAN, STARTER_PLAN } from './objects/subscription-plans';
import { ClientIsSelectedMain } from './guards/client-is-selected-main';
import { SubscriptionIsLiveGuard } from './guards/subscription-is-live/subscription-is-live.guard';

const routes: Routes = [
  //NOTE: Did this to avoid access_token error on console.log every login. Should be removed someday.
  { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'account', component: LayoutAccountComponent, loadChildren: './account/account.module#AccountModule' },
  // Moved error component outside of a guard as the error page should be accessible whether they've selected a client or not.
  { path: 'error/:code/:type', component: ErrorComponent },
  {
    path: '',
    canActivateChild: [SubscriptionIsLiveGuard],
    // https://medium.com/ngconf/how-to-nest-lazy-loaded-modules-2d73a7881be
    component: LayoutDashboardComponent,
    children: [
      { path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
      { path: 'leads', canActivate: [UserLevelModule], loadChildren: './module/leads/leads.module#LeadsModule' },
      { path: 'customers', canActivate: [UserLevelModule], loadChildren: './module/customers/customers.module#CustomersModule' },
      { path: 'sites', canActivate: [UserLevelModule], loadChildren: './module/sites/sites.module#SitesModule' },
      { path: 'contacts', canActivate: [UserLevelModule], loadChildren: './module/contacts/contacts.module#ContactsModule' },
      { path: 'jobs', canActivate: [ClientIsSelectedMain], loadChildren: './module/jobs/jobs.module#JobsModule' },
      { path: 'recurring_jobs', canActivate: [ClientIsSelectedMain], loadChildren: './module/recurring-jobs/recurring-jobs.module#RecurringJobsModule' },
      { path: 'assets', canActivate: [UserLevelModule, MinimumSubscriptionRequiredGuard], data: { minimumSubscriptionRequired: ADVANCED_PLAN }, loadChildren: './module/assets/assets.module#AssetsModule' },
      { path: 'opportunities', canActivate: [UserLevelModule], loadChildren: './module/opportunities/opportunities.module#OpportunitiesModule' },
      { path: 'customer_invoices', canActivate: [UserLevelModule], loadChildren: './module/customer-invoices/customer-invoices.module#CustomerInvoicesModule' },
      { path: 'purchase_orders', canActivate: [UserLevelModule], loadChildren: './module/purchase-orders/purchase-orders.module#PurchaseOrdersModule' },
      { path: 'supplier_invoices', canActivate: [UserLevelModule], loadChildren: './module/supplier-invoices/supplier-invoices.module#SupplierInvoicesModule' },
      { path: 'dashboard', canActivate: [ClientIsSelectedMain], loadChildren: './dashboard/dashboard.module#DashboardModule' },
      { path: 'calendar', canActivate: [ClientIsSelectedMain], loadChildren: './module/calendar/calendar.module#CalendarModule' },
      { path: 'shared_location', canActivate: [ClientIsSelectedMain], loadChildren: './module/user/shared-location/shared-location.module#SharedLocationModule' },
      { path: 'schedule_recurring_jobs', canActivate: [ClientIsSelectedMain], loadChildren: './module/schedule-recurring-jobs/schedule-recurring-jobs.module#ScheduleRecurringJobsModule' },
      { path: 'recurring_invoices', canActivate: [ClientIsSelectedMain], loadChildren: './module/recurring-invoices/recurring-invoices.module#RecurringInvoicesModule' },
      { path: 'activities', canActivate: [ClientIsSelectedMain], loadChildren: './module/activities/activities.module#ActivitiesModule' },
      { path: 'support', canActivate: [ClientIsSelectedMain, MinimumSubscriptionRequiredGuard], data: { minimumSubscriptionRequired: ENTERPRISE_PLAN }, loadChildren: './module/acs-support/acs-support.module#AcsSupportModule' },
      { path: 'site_service_summary_report', canActivate: [ClientIsSelectedMain], loadChildren: './module/reports/summary-report/summary-report.module#SummaryReportModule' },
      { path: 'stock_levels', canActivate: [ClientIsSelectedMain, MinimumSubscriptionRequiredGuard], data: { minimumSubscriptionRequired: ENTERPRISE_PLAN }, loadChildren: './module/stock-level/stock-level.module#StockLevelModule' },
      { path: 'subcontractors', loadChildren: './module/subcontractor/subcontractor.module#SubcontractorModule' },
      { path: 'items', loadChildren: './admin/items/items.module#ItemsModule' },
      { path: 'warehouses', loadChildren: './admin/warehouse/warehouse.module#WarehouseModule' },
      { path: 'timesheets', canActivate: [ClientIsSelectedMain], loadChildren: './module/timesheets/timesheets.module#TimesheetsModule' },
      { path: 'error/:code/:type', component: ErrorComponent }
    ]
  },
  { path: '**', redirectTo: 'error/404/page', },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: [UserLevelModule, ClientIsSelectedMain]
})

export class AppRoutingModule { }
