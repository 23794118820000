import { Injectable } from '@angular/core';
import { concatMap, map, tap } from 'rxjs/operators';
import { Subject, Observable, throwError, of, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, catchError } from 'rxjs/operators';

import { LooseObject } from '../../objects/loose-object';
import { Notification } from '../../objects/notification';
import { PresignedUrl } from '../../objects/presigned-url';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../notification.service';
import { ClientStoreService } from '../../services/client-store.service';
import { FormattedErrorObject, BasicValidationErrorObject } from '../../objects/request-errors';
import * as FileManager from 'file-saver';
import _, { defaults, pick } from 'lodash';
import imageCompression from 'browser-image-compression';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  public objFile: File;
  public refreshFilesList$: Observable<boolean>;
  public refreshFilesListSource: Subject<boolean>;

  /**
   * list of accetable file types
   */
  private objAcceptableFileTypes = {
    'text/plain': ['txt'],
    'text/csv': ['csv'],
    'image/png': ['png'],
    'image/jpeg': ['jpg', 'jpeg'],
    'image/bmp': ['bmp'],
    'image/gif': ['gif'],
    'image/heic': ['heic'],
    'application/pdf': ['pdf'],
    'application/msword': ['doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['docx'],
    'application/vnd.ms-powerpoint': ['ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['pptx'],
    'application/vnd.ms-excel': ['xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xlsx'],
    'application/zip': ['zip'],
    'application/x-zip-compressed': ['zip'], // this one is depricated but some windows still has this type when uploading zip https://mimetype.io/application/x-zip-compressed
    'application/vnd.rar': ['rar'],
    'application/vnd.oasis.opendocument.text': ['odt'],
    'application/vnd.oasis.opendocument.spreadsheet': ['ods'],
    'application/vnd.oasis.opendocument.presentation': ['odp'],
    'video/mp4': ['mp4'],
  };

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
    protected clientStoreService: ClientStoreService,
  ) {
    this.refreshFilesListSource = new Subject<boolean>();
    this.refreshFilesList$ = this.refreshFilesListSource.asObservable();
  }

  /**
   * Uploads the file to the given bucket and returns its pre-signed URL along with a filename
   *
   * @param {File} objFile
   * @param {string} strBucket
   * @param {string|undefined} strFilename
   *
   * @returns {Observable<PresignedUrl>}
   */
  upload(objFile: File, strBucket: string = environment.temp_bucket, strFilename?: string, options: {
    hasErrorHandler?: boolean,
  } = {}): Observable<PresignedUrl> {
    options = Object.assign({
      hasErrorHandler: false,
    }, options);

    if (objFile.type && objFile.type.includes('image')) {
      let objCurrentClient = this.clientStoreService.getActiveClient();
      let intImageQuality: number = objCurrentClient.config.image_quality || 0.5;

      const objOptions = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        initialQuality: intImageQuality,
        useWebWorker: true
      }

      let imageCompressed = from(imageCompression(objFile, objOptions));

      return imageCompressed.pipe(
        switchMap(objFileCompressed => {
          this.objFile = objFileCompressed;

          return this.uploadSignedUrl(objFileCompressed, strBucket, strFilename, options);
        })
      );
    } else {
      this.objFile = objFile;
      return this.uploadSignedUrl(objFile, strBucket, strFilename);
    }
  }

  /**
   * Generate a pre-signed URL along with a file name
   * @param objFile
   * @param strBucket
   * @param strFilename
   * @returns
   */
  uploadSignedUrl(objFile: File, strBucket: string, strFilename?: string, options: {
    hasErrorHandler?: boolean,
  } = {}): Observable<PresignedUrl> {
    options = Object.assign({
      hasErrorHandler: false,
    }, options);

    return this.getUploadSignedUrl(objFile, strBucket, strFilename).pipe(
      concatMap(objSignature => {
        let objHeaders = { headers: { 'Content-Type': objFile.type } };

        return this.http.put(objSignature.url, objFile, objHeaders).pipe(
          concatMap(response => of(objSignature)),
          catchError(err => throwError(err))
        );
      }),
      catchError((error) => {
        if (options.hasErrorHandler) {
          return throwError(error);
        }

        this.presentArrayAsErrorMessages(
          'tmp_file_upload_error',
          this.getMessagesFromErrorResponse(error)
        );

        return throwError(
          'An error has occurred while uploading the temporary file. Please try again'
        );
      }),
    );
  }

  /**
   * Generates an upload pre-signed url for the given file
   *
   * @param {File} file
   * @param {string} bucket
   * @param {string|undefined} filename
   *
   * @returns {Observable<PresignedUrl>}
   */
  getUploadSignedUrl(file: File, bucket: string, filename?: string): Observable<PresignedUrl> {
    let requestData = this.urlSearchParams({
      bucket: bucket,
      content_type: `${file.type}`,
      filename,
    });

    return this.http.post<PresignedUrl>(
      this.url('get_upload_url'), requestData.toString()
    );
  }

  /**
   * Generates a pre-signed url which contains the viewable object
   *
   * @param {string} fileName
   * @param {string} contentType
   * @param {string} bucket
   * @param {number} expiry - defaults to 2hrs / 120 minutes
   *
   * @returns {Observable<PresignedUrl>}
   */
  getObjectSignedUrl(fileName: string, bucket: string = environment.temp_bucket, expiry: number = 120): Observable<PresignedUrl> {
    let requestData = this.urlSearchParams({
      object_key: fileName,
      bucket: bucket,
      expiry: expiry
    });

    return this.http.post<PresignedUrl>(
      this.url('get_object_url'), requestData.toString()
    );
  }

  /**
   * Calls the api for getting all the versions of the current object
   *
   * @param {string} fileName
   */
  getObjectHistory(fileName: string) {
    let requestData = this.urlSearchParams({
      object_key: fileName
    });

    return this.http.post(
      this.url('get_object_versions'), requestData.toString()
    );
  }


  /**
   * Gets the object url for downloading
   *
   * @param uploadName
   * @param contentType
   * @param fileName
   */
  download(uploadName: string, contentType: string, fileName: string) {
    let requestData = this.urlSearchParams({
      object_key: uploadName,
      content_type: contentType,
      file_name: fileName
    });

    return this.http.post(
      this.url('download'), requestData.toString()
    );
  }

  /**
   * Upload images file to s3
   *
   *
   * @param file
   * @param file_type
   * @param file_size
   * @returns {Observable<PresignedUrl>}
   */
  uploadImage(file: File, file_type: string, file_size: number): Observable<PresignedUrl> {
    // if file size is less than 30mb
    if (file_size / 1024 / 1024 < 30) {
      if (file_type == 'image/png' || file_type == 'image/jpeg') {
        return this.upload(file);
      } else {
        return throwError(this.notificationService.sendNotification("upload_failed", "photos_invalid", "warning"));
      }
    } else {
      return throwError(this.notificationService.sendNotification('not_allowed', 'invalid_file_size', 'warning'));
    }
  }

  /**
   * Copies the file to client bucket and saves it to activities record
   *
   * @param data
   */
  save(data) {
    let requestData = this.urlSearchParams(data);

    return this.http.post(
      this.url('save'), requestData.toString()
    );
  }

  /**
   * Creates a new url search params object, uses the given loose object as its
   * `data` attribute then returns it.
   *
   * @param {LooseObject} data
   *
   * @returns {URLSearchParams}
   */
  protected urlSearchParams(data: LooseObject): URLSearchParams {
    let body = new URLSearchParams();
    body.append('data', JSON.stringify(data));

    return body;
  }

  /**
   * Returns a url to the records api and appends the given path.
   *
   * @param {String} path
   *
   * @returns {String}
   */
  protected url(path: string): string {
    return `${environment.url}/file/${path}`;
  }

  /**
   * Extracts the error messages from the HttpErrorResponse object.
   *
   * @param {string|FormattedErrorObject|BasicValidationErrorObject|Error} error
   *
   * @returns {string[]}
   */
  protected getMessagesFromErrorResponse(response: string | FormattedErrorObject | BasicValidationErrorObject | Error): string[] {
    if (typeof response === 'string') {
      return [response];

    } else if (response instanceof Error) {
      return [response.message];

    } else if (response.errors === undefined) {
      let errors = <BasicValidationErrorObject>response;

      return Object.values(errors).flat(2);

    } else {
      let errors = <FormattedErrorObject>response;

      return errors.errors.map(_error => _error.detail);
    }
  }

  /**
   * Opens up a notification box containing the supplied header and error messages.
   *
   * @param header
   * @param messages
   *
   * @returns {void}
   */
  protected presentArrayAsErrorMessages(header: string, messages: string[]): void {
    let error_messages = messages.join('\n');

    this.notificationService.sendNotification(header, error_messages, 'danger');
  }

  /**
   * Transform a url into blob
   *
   * @param {string} url
   *
   * @returns {Observable<Blobl>}
   */
  toBlob$(url: string): Observable<Blob> {
    return from(fetch(url))
      .pipe(
        switchMap((response: Response) => from(response.blob()))
      );
  }

  /**
   * Downloads the content from given url into a file instance
   *
   * @param {string} url
   * @param {FileOptions} options
   *
   * @returns {Observable<File>}
   */
  webToFile$(url: string, options: FileOptions): Observable<File> {
    return this.toBlob$(url)
      .pipe(
        map((blob) => new File([blob], options.name, {
          type: options.type
        })),
      );
  }

  /**
   * Download the file and save as a file to the user
   *
   * @param {string} url
   * @param {string} filename
   *
   * @returns {Observable<boolean>}
   */
  downloadFileFromUrl$(url: string, filename: string): Observable<boolean> {
    return this.toBlob$(url).pipe(
      tap((blob) => {
        FileManager.saveAs(blob, filename);
      }),
      map(() => true)
    );
  }

  /**
   * Downloads the given web content and upload it to the specified bucket (tmp by default)
   * and make it as an attachment to certain process
   *
   * @param {string} url
   * @param {WebAsAnAttachmentOptions} options
   *
   * @returns {Observable<WebAttachment>}
   */
  webAsAnAttachment$(url: string, options: WebAsAnAttachmentOptions): Observable<WebAttachment> {
    options = defaults(options, {
      generatesUniqueFilenameUponUpload: false,
    });

    return this.webToFile$(url, {
      name: options.filename,
      type: options.filetype
    })
      .pipe(
        switchMap((file) => this.upload(
          file, options.bucket, options.generatesUniqueFilenameUponUpload ? undefined : options.filename
        ).pipe(
          map((presigned) => ({
            ...presigned,
            ...pick(file, ['size', 'type'])
          }))
        ))
      );
  }

  /**
   * validate file type and file extension, it should exist on file acceptable file type list
   *
   * @param objFile
   * @returns
   */
  hasValidUploadFile(objFile: File): boolean {
    let arFileExtension = this.objAcceptableFileTypes[objFile.type] || [];
    if (arFileExtension) {

      let arExtension = objFile.name.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
      return arFileExtension.includes(arExtension[1]);
    }

    return false;
  }
}

interface FileOptions {
  name: string;
  type: string;
}

interface WebAsAnAttachmentOptions {
  filename: string;
  filetype: string;
  bucket?: string;
  generatesUniqueFilenameUponUpload?: boolean;
}

interface WebAttachment extends PresignedUrl {
  size: number;
  type: string;
}