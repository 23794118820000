<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <fa-icon [icon]="['fas', modeIcon]" class="pr-2" ></fa-icon>{{ modeLabel | translate }}
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary" [disabled]="inProgress">
            <fa-icon [icon]="['fas', 'times']"></fa-icon><span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button (click)="onSubmit()" class="btn ml-1 btn-primary" [disabled]="inProgress">
            <fa-icon [icon]="['fas', (submitted) ? 'spinner' : 'save']" [spin]="submitted"></fa-icon><span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr />
<mat-dialog-content class="mobile-view-dialog">
  <ng-container *ngIf="!loading" >
    <form [formGroup]="emailTemplateForm">
      <div class="mb-3">
        <app-edit-field [item]="emailTemplateField['name']" [form]="emailTemplateForm"></app-edit-field>
      </div>
      <div class="mb-3">
        <app-edit-field [item]="emailTemplateField['subject']" [form]="emailTemplateForm"></app-edit-field>
      </div>
      <div *ngIf="objAvailableModules" class="mb-3">
        <app-edit-field
          [item]="emailTemplateField['module']"
          [form]="emailTemplateForm"
          [strModule]="'email-templates'"
          (parentEvent)="generateModuleFields($event)">
        </app-edit-field>
      </div>
      <div *ngIf="objAvailableModules" class="mb-3 row">
        <div class="col-sm-11">
          <app-edit-field
            [item]="emailTemplateField['field']"
            [form]="emailTemplateForm"
            [strModule]="'email-templates'"
            (parentEvent)="setField($event)">
          </app-edit-field>
        </div>
        <div class="col-sm-1 d-flex align-items-end justify-content-start pl-0 pr-0">
          <fa-icon
            matTooltip="{{ 'append_field' | translate }}"
            class="add-button text-success pointer"
            (click)="appendField()"
            [icon]="['fas', 'plus-circle']">
          </fa-icon>
        </div>
      </div>
      <div class="form-group">
        <label for="attachment" class="fs-14">{{ 'attachment' | translate }}</label>
        <div>
          <div class="attachment-container">
            <div *ngFor="let item of attachmentList" class="attachment-list">
              <fa-icon [icon]="['fas', 'times-circle']" class="attachment-item" (click)="removeAttachement(item.name)" *ngIf="item.upload_name"></fa-icon>
              <inline-view-loader [isLoading]="! item.upload_name" [hasText]="false"></inline-view-loader>
              {{ item.name }} - {{ item.size | number:'.2' }} kb
            </div>
          </div>
          <div ng2FileDrop
            id="attachment"
            [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
            (fileOver)="fileOverBase($event)"
            [uploader]="fileUploader"
            (onFileDrop)="dropAttachment($event)"
            class="well my-drop-zone"
            #attachment>
            {{ 'drop_files_here' | translate }}
          </div>
        </div>
      </div>
      <quill-editor
        #quill
        formControlName="body"
        placeholder="Enter Text"
        [modules]="quillConfig"
        [style]="{'height':'220px'}">
      </quill-editor>
    </form>
  </ng-container>
</mat-dialog-content>