import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Relate } from '../../../../objects/relate';
import { RecordService } from '../../../../services/record.service';
import { map, switchMap } from 'rxjs/operators';
import { SearchService } from '../../../../services/search.service';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {

  /**
   * The relate that holds the product search.
   *
   * @var {Relate<any>}
   */
  public objProducts = new Relate<any>();

  constructor(
    public dialogRef: MatDialogRef<AddItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public record: RecordService,
    public es: SearchService
  ) {
    this.objProducts.buildRelates(
      switchMap(term => this.es.global(term, 'items'))
    );
  }

  ngOnInit() {}

  /**
   * Compiles the ids of the selected product.
   *
   * @returns {void}
   */
  addProducts(): void {
    this.dialogRef.close(this.objProducts.value);
  }

}
