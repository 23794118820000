import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { LooseObject } from '../../../../../../objects/loose-object';
import { Chat } from '../../../../../../objects/chat';
import { ChatAction } from '../../../../../../objects/chat-action';
import { PusherService } from '../../../../../../services/pusher.service';
import { LocalStorageService } from '../../../../../../services/local-storage.service';
import { Pagination } from '../../../../../../objects/pagination';
import { RecordService } from '../../../../../../services/record.service';
import { ChatService } from '../../../../../../services/chat.service';
import { ActionComponent } from '../action/action.component';
import { AccountService } from '../../../../../../services/account.service';
import { LambdaService } from '../../../../../../services/lambda.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { of, Subject, Subscription } from 'rxjs';
import { MentionsService } from '../../../../../../services/mentions.service';
import { concatMap, filter, finalize, map, switchMap } from 'rxjs/operators';
import { SearchService } from '../../../../../../services/search.service';
import { FormControl, Validators } from '@angular/forms';
import { ImageViewComponent } from '../../../../../../shared/components/image-view/image-view.component';
import { FormTemplateService } from '../../../../../../module/form-templates/shared/services/form-template.service';
import { FileService } from '../../../../../../services/file/file.service';

@Component({
  selector: 'activity-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [
    MentionsService
  ]
})

export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {

  /**
   * The scrollable div where the messages are contained
   *
   * @var {ElementRef}
   */
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  /**
   * The native element for either the textarea with the
   * formControl of message_chat or message_sms. See FormGroup below.
   *
   * @var {ElementRef}
   */
  @ViewChild('textarea', { read: ElementRef }) objMessageTextarea: ElementRef;

  /**
   * The variable that will hold the pusher
   * stream.
   *
   * @var {any}
   */
  private objChatChannel: any;

  /**
   * This list of chats to display.
   *
   * @var {Chat[]}
   */
  public arChats: Chat[] = [];

  /**
   * Flag to identify if the chats
   * hace been loaded.
   *
   * @var {boolean}
   */
  public bChatLoaded: boolean = false;

  /**
   * The ID of this user, usually taken from
   * the local storage.
   *
   * @var {string}
   */
  public strUserId: string;

  /**
   * Flag to identify if the message
   * is still on sending status.
   *
   * @var {boolean}
   */
  public bSending: boolean = false;

  /**
   * Flag to tell ig the previous messages'
   * loading icon should show.
   *
   * @var {boolean}
   */
  public bPreviousLoading: boolean = false;

  /**
   * The next page for the message list.
   *
   * @var {Pagination}
   */
  public objNextPage: Pagination = null;

  /**
   * The formcontrol for the message textarea.
   *
   * @var {FormControl}
   */
  public strMessage: FormControl = new FormControl(null, [Validators.required]);

  /**
   * Informs any thing that subscribes to this that
   * the dom changes are done and ready.
   * This is trigger by the ngAfterViewChanged lifecyclye.
   *
   * @var {Subject<boolean>}
   */
  private isViewReady = new Subject<boolean>();

  /**
   * List of actions for this chat.
   * This usually depends on the module.
   *
   * @var {ChatAction[]}
   */
  public arActions: ChatAction[] = [];

  /**
   * Where the value changes subscription
   * of the message is so we can destroy it
   * later.
   *
   * @var {Subscription}
   */
  private objMessageSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public objActivity: any,
    public selfDialog: MatDialogRef<ChatComponent>,
    public pusherService: PusherService,
    private chatService: ChatService,
    private localStorage: LocalStorageService,
    private recordService: RecordService,
    private accountService: AccountService,
    private lambdaService: LambdaService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    public mentionsService: MentionsService,
    private searchService: SearchService,
    private documents: FormTemplateService,
    private files: FileService
  ) {}

  ngOnInit(): void {
    this.chatService.addDefaultAction(this.objActivity['module'], this.objActivity['record_id']);

    this.arActions = this.chatService.getActions();
    this.strUserId = this.localStorage.getItem('user_id');

    this.initializeChatMessages();
    this.initializeSubscriptions();
    this.buildRelates();
  }

  ngAfterViewChecked(): void {
    this.isViewReady.next(true);
  }

  ngOnDestroy(): void {
    if (this.objChatChannel !== undefined) {
      this.objChatChannel.unsubscribe();
    }
    this.objMessageSubscription.unsubscribe();
  }

  /**
   * Creates a new activity message record
   * in our database.
   *
   * @returns {void}
   */
  onSubmit(): void {
    if (this.strMessage.valid && this.strMessage.value.trim()) {
      this.strMessage.disable()
      this.chatService.sendMessage(
        this.objActivity.id,
        this.strMessage.value,
        null, null,
        this.mentionsService.getAllMentionedPeopleId()
      ).subscribe(() => {
        this.strMessage.setValue(null);
        this.strMessage.enable()
      });
    }
  }

  /**
   * Loads previous messages by passing the current
   * token to the api and receiving the next 10 messages.
   *
   * @returns {void}
   */
  loadPreviousMessages(): void {
    this.bPreviousLoading = true;
    this.chatService.getActivityMessages(this.objActivity.id, this.objNextPage.toString())
      .subscribe(activity => {
        activity.data.forEach(chat => {
          this.bPreviousLoading = false;
          this.arChats.unshift(chat);
          this.objNextPage = activity.token;
        });
      });
  }

  /**
   * Checks by id if the user is one of the
   * members that are currently present in the chat.
   *
   * @param {string} strUserId
   *
   * @returns {boolean}
   */
  isOnline(strUserId: string): boolean {
    if (this.objChatChannel !== undefined) {
      return this.objChatChannel.members.get(strUserId);
    } else {
      return false;
    }
  }

  /**
   * Triggers a chat action, usually sending a file
   * or invoice, or work order, depending on the
   * actions available for certain modules.
   *
   * @param {ChatAction} objAction
   *
   * @returns {void}
   */
  triggerAction(objAction: ChatAction): void {
    if (objAction.selection) {
      let objActionDialog = this.dialog.open(ActionComponent, {
        width: '900px',
        height: 'auto',
        panelClass: ['custom-dialog-container'],
        data: objAction
      });
      objActionDialog.afterClosed().take(1).subscribe((result: { record: LooseObject, action: ChatAction }) => {
        if (result) {
          objAction.loader = true;
          if (result.action['id'] == 'send_files') {
            // We do not allow sending of SVG file as message
            if (! result.record.file_type.includes('svg')) {
              this.getFile(result);
            } else {
              this.notificationService.sendNotification('not_allowed', 'invalid_file_type', 'warning');
            }
          } else {
            // As of initial release quotation is not yet supported in the document builder
            if (objAction['pdf_module'] === 'quotation') {
              this.getLink(result);
            } else if (objAction.pdf_options !== undefined) {
              // if we have a job_work_order use the current work order related job id
              const strModuleId = (objAction.pdf_options.document_type === 'job_work_order')
                ? result.record.job_id
                : result.record.id;

              this.generateDocument(result.action, strModuleId);
            }
          }
        }
      });
    } else {
      objAction.loader = true;
      // As of initial release quotation is not yet supported in the document builder
      if (objAction['pdf_module'] === 'quotation') {
        this.recordService.getPDFRecordData(this.objActivity['module'], this.objActivity['record_id']).subscribe(pdf => {
          let objData: { action: ChatAction, record: LooseObject } = { action: objAction, record: pdf.body['record'] };
          this.getLink(objData, (objAction.file_name) ? objAction.file_name : null);
        });
      } else if (objAction.pdf_options !== undefined) {
        this.generateDocument(objAction);
      }
    }
  }

  /**
   * Initializes the chat by retrieving the last 10 messages
   * and then calling the pusher channels
   * to listen to further events.
   *
   * @returns {void}
   */
  private initializeChatMessages(): void {
    this.chatService.getActivityMessages(this.objActivity.id, null)
      .subscribe(activity => {
        activity.data.forEach(chat => {
          this.arChats.unshift(chat);
        });
        this.objNextPage = activity.token;
        this.bChatLoaded = true;
        this.startListening();
        this.scrollToBottom();
      });
  }

  /**
   * Dictates what to do when receiving certain presence
   * events such as when a member opened a chat box
   * or a member closed the chat box.
   *
   * @returns {void}
   */
  private initializePresenceEvents(): void {
    this.objChatChannel.bind('pusher:member_added', (member: ChatMember) => {
      this.removeActiveAlert(member);
      this.arChats.push(new Chat({
        id: member.id,
        type: 'notification',
        message: 'joined_chat',
        name: member.info.name
      }));
      this.scrollToBottom();
    });
    this.objChatChannel.bind('pusher:member_removed', (member: ChatMember) => {
      this.removeActiveAlert(member);
    });
  }

  /**
   * Dictates what to do when receving chat events
   * or that the chat stream encountered an error.
   *
   * @returns {void}
   */
  private initializeChatEvents(): void {
    this.objChatChannel.bind('message-event', event => {
      let objChat = new Chat((event.data != undefined) ? event.data : event);
      objChat.setFullName(event.name || null);
      this.arChats.push(objChat);
      this.scrollToBottom();
    });
    this.objChatChannel.bind('pusher:subscription_error', (event) => {
      this.notificationService.sendNotification('error', 'chat_subscribe_error', 'danger', 5000);
      this.selfDialog.close();
    });
  }

  /**
   * Join the channel and start listening for events
   * related to this activity.
   *
   * @returns {void}
   */
  private startListening(): void {
    let strId: string = null;

    if (this.objActivity.is_internal == false) {
      strId = this.objActivity.thread_id;
    } else {
      strId = this.objActivity.id;
    }

    this.objChatChannel = this.pusherService.getPusherPresence(strId);
    this.initializePresenceEvents();
    this.initializeChatEvents();
  }

  /**
   * Simply scrolls to the bottom of the chats.
   *
   * @returns {void}
   */
  private scrollToBottom(): void {
    this.isViewReady.take(1).subscribe(scroll => {
      if (scroll === true) {
        try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch { }
      }
    });
  }

  /**
   * Retrieves the link of the pdf by getting
   * all the necessary data to build the pdf,
   * then retrieving the temp url to use it
   * to copy the object from the temp bucket to the
   * permanent bucket and then get the presigned
   * url from there.
   *
   * @param {record: LooseObject, action: ChatAction} objData
   * @param {string} strFileName
   *
   * @returns {void}
   */
  private getLink(objData: {record: LooseObject, action: ChatAction}, strFileName: string = null): void{
    this.accountService.getClientConfigForPdfPreviews().take(1).subscribe(objPdfConfig => {
      objPdfConfig.record = objData['record'];
      objPdfConfig.file_name = (strFileName) ? strFileName + objData['record']['text'] : objData['record']['text'];
      objPdfConfig.module = objData['action']['pdf_module'];

      this.lambdaService.getPreviewUrl(objPdfConfig).subscribe(objResponse => {
        this.chatService.copyMessageAttachment(objResponse['success']['metadata']['upload_name'])
          .subscribe(() => {
            this.sendChatMessage(objResponse['success']['metadata']['upload_name'], objPdfConfig.file_name + '.pdf', 'file', objData['action'])
          });
      });
    })
  }

  /**
   * Retrieve the presigned url for a specific file
   * from the files widget.
   *
   * @param {record: LooseObject, action: ChatAction} objData
   *
   * @returns {void}
   */
  private getFile(objData: { record: LooseObject, action: ChatAction }): void {
    let bIsImage: boolean = objData.record.file_type.includes('image');

    this.sendChatMessage(
      objData.record.upload_name,
      objData.record.file_name,
      bIsImage ? 'image' : 'file',
      objData['action']
    );
  }

  /**
   * Sends SMS chat to API but checks first if there are
   * SMS credits left.
   *
   * @param {string} strMessage
   * @param {string} strName
   * @param {string} strType
   * @param {ChatAction} objAction
   *
   * @returns {void}
   */
  private sendChatMessage(strMessage: string, strName: string, strType: string , objAction: ChatAction = null): void {
    if (this.objActivity.is_internal === false) {
      this.accountService.getSmsBalance().pipe(
        concatMap(response => {
          if (response.credits > 0) {
            return of(true)
          } else {
            return this.notificationService.sendConfirmation(
              'no_sms_credits_message',
              'no_sms_credits',
              'ok'
            ).pipe(
              filter(confirmation => confirmation.answer === false),
              finalize(() => {
                objAction.loader = false;
              })
            );
          }
        })
      ).subscribe(() => {
        this.triggerSendMessageApi(strMessage, strName, strType, objAction);
      });
    } else {
      this.triggerSendMessageApi(strMessage, strName, strType, objAction);
    }

  }

  /**
   * The actual sending of the message to
   * the API.
   *
   * @param {string} strMessage
   * @param {string} strName
   * @param {string} strType
   * @param {ChatAction} objAction
   *
   * @returns {void}
   */
  triggerSendMessageApi(strMessage: string, strName: string, strType: string, objAction: ChatAction = null): void {
    this.chatService.sendMessage(this.objActivity.id, strMessage, strType, strName).subscribe(() => {
      if (objAction) {
        objAction.loader = false;
      }
    });
  }

  /**
   * Removes the active alert within the chat.
   *
   * @param {ChatMember} objMemberInfo
   *
   * @returns {void}
   */
  private removeActiveAlert(objMemberInfo: ChatMember): void {
    let numIndex = this.arChats.findIndex(chats =>
      chats.id == objMemberInfo.id &&
      chats.type == 'notification' &&
      chats.message == 'joined_chat'
    );
    if (numIndex > -1) {
      this.arChats.splice(numIndex, 1);
    }
  }

  /**
   * Check if tags are still mapping correctly.
   *
   * @returns {void}
   */
  private checkTags(): void {

    let objTextareaUpdates: {
      textarea_value: string,
      textarea_cursor: number
    } | null = this.mentionsService.checkTags(this.strMessage.value);

    if (objTextareaUpdates != null) {
      this.strMessage.patchValue({ message_chat: objTextareaUpdates.textarea_value });
      this.objMessageTextarea.nativeElement.selectionEnd = objTextareaUpdates.textarea_cursor;
    }

  }

  /**
   * Initializes the users list from the ES.
   *
   * @returns {void}
   */
  private buildRelates(): void {
    this.mentionsService.objUsersRelate.buildRelates(
      switchMap(term => this.searchService.global(term, 'users')
        .map(user => {
          return user.filter(user => user.id != this.localStorage.getItem('user_id'))
        })
      )
    );
  }

  /**
  * Get all subscriptions to destroy
  * later.
  *
  * @returns {void}
  */
  private initializeSubscriptions(): void {
    this.objMessageSubscription = this.strMessage.valueChanges.subscribe(() => {
      this.checkTags();
    })
  };

  /**
   * Simply opens a new dialog to the full size of the image.
   *
   * @param {string}
   *
   * @returns {void}
   */
  private previewImage(strUrl: string): void {
    this.dialog.open(ImageViewComponent, {
      width: '1200px',
      height: '80%',
      data: {
        image_url: strUrl
      }
    });
  }

  /**
   * Generates a document based on the current action
   *
   * @param {string} objAction
   * @param {string} strModuleId
   *
   * @returns {void}
   */
  private generateDocument(objAction: ChatAction, strModuleId: string = this.objActivity['record_id']): void {
    const { pdf_options } = objAction;

    this.documents.getModuleDocumentData$(strModuleId, pdf_options.module, {
      document_type: pdf_options.document_type,
      filter: pdf_options.filter,
    }).pipe(
      switchMap((result) => this.documents.generatePDFUrl$({
        module: pdf_options.module,
        data: result.data,
        template: result.template.history,
      }).pipe(
        switchMap((url) => this.documents.toBlob$(url)),
        map((rawFile) => ({
          rawFile,
          result,
        }))
      )),
      switchMap((value) => {
        const filename = this.appendPdfExtension(value.result.config.filename);
        const file = new File([value.rawFile], filename, {
          type: 'application/pdf'
        });

        return this.files.upload(file, undefined, filename)
        .pipe(
          map((presigned) => ({
            presigned,
            result: value.result,
          })),
          switchMap((value) => this.chatService.copyMessageAttachment(value.presigned.filename).pipe(
            map(() => value)
          ))
        )
      })
    ).subscribe((value) => {
      const message = value.presigned.filename;
      this.sendChatMessage(message, this.appendPdfExtension(value.result.config.filename), 'file', objAction);
    })
  }

  /**
   * Appends file pdf extension to the current filename
   *
   * @param {string} filename
   * @returns {string}
   */
  private appendPdfExtension(filename: string): string {
    return `${filename.substr(0, 85)}.pdf`;
  }
}

export interface ChatMember {
  id: string;
  info: {name: string}
}
