import { CanActivate } from "@angular/router";
import { Injector } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";

export class ClientIsSelectedMain implements CanActivate {

    /**
     * Same as the ClientIsSelected, but instead of the guard applying only to
     * the child of the routes, this one applies to the parent.
     */
    static localStorageService: LocalStorageService;

    canActivate() {
        // Inject our LocalStorageService into this class. We can't do it via 
        // constructor since this is just a normal class and Angular cannot
        // manipulate it.
        if (!ClientIsSelectedMain.localStorageService) {
            const injector: any = Injector.create([{ provide: LocalStorageService, useClass: LocalStorageService, deps: [] }]);
            ClientIsSelectedMain.localStorageService = injector.get(LocalStorageService);
        }

        // Check first if there is a selected client
        if (ClientIsSelectedMain.localStorageService.getItem("current_client") != null) {
            return true;
        }
        return false;
    }

}
