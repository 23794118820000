import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fieldmagic-icon',
  template: `
    <div
      class="d-flex d-flex-gap align-items-center"
      [class.justify-content-center]="position == 'center'"
      [class.justify-content-start]="position == 'left'"
      [class.justify-content-end]="position == 'right'"
      [class.flex-row-reverse]="position == 'right'"
    >
      <fa-icon
        [matTooltip]="tooltip"
        [icon]="(spin) ? ['fas', 'spinner'] : (icon$ | async)"
        [spin]="spin"
      >
      </fa-icon>
      <ng-content></ng-content>
    </div>
  `
})
export class IconComponent implements OnInit {
  @Input() icon: string;

  @Input() variant: 'solid' | 'light' = 'solid';

  @Input() spin: boolean = false;

  @Input() position: 'left' | 'right' | 'center' = 'left';

  @Input() tooltip: string;

  readonly icon$ = new BehaviorSubject<string[]>([]);

  ngOnInit(): void {
    const icon = [];

    if (this.variant == 'solid') {
      icon.push('fas');
    } else if (this.variant == 'light') {
      icon.push('fal');
    }

    icon.push(this.icon);

    this.icon$.next(icon);
  }
}
