<table class="table table-borderless table-sm">
  <tr>
    <td
      class="p-0"
      width="10%"
      matTooltip="{{ 'total_actual_profit' | translate }}: {{ record.actual_profit.value | currency }}">
      <span class="mr-1 text-muted">{{ 'act' | translate }}:</span>
      <span
        data-testid="profit_analysis:actual_profit"
        [ngClass]="{
          'text-success': record.actual_profit.stats === 'high',
          'text-danger': record.actual_profit.stats === 'low',
          'text-muted': record.actual_profit.stats === 'zero'
        }">
        {{ record.actual_profit.value | currency }}&nbsp;({{ record.actual_profit.margin }}%)
      </span>
    </td>
    <td
      *ngIf="record.actual_profit.stats != 'zero'"
      rowspan="2"
      class="p-0"
      [ngClass]="{
        'text-success': record.actual_profit.stats === 'high',
        'text-danger': record.actual_profit.stats === 'low',
        'text-muted': record.actual_profit.stats === 'zero'
      }">
      <fa-icon [icon]="['fas', 'arrow-alt-' + (record.actual_profit.stats == 'high' ? 'up' : 'down')]" class="fa-2x ml-2"></fa-icon>
    </td>
  </tr>
  <tr>
    <td
      class="p-0"
      width="10%"
      matTooltip="{{ 'total_estimated_profit' | translate }}: {{ record.estimated_profit.value | currency }}">
        <span class="mr-1 text-muted">{{ 'est' | translate }}:</span>
        <span
          data-testid="profit_analysis:estimated_profit"
          [ngClass]="{
            'text-success': record.estimated_profit.stats === 'high',
            'text-danger': record.estimated_profit.stats === 'low',
            'text-muted': record.estimated_profit.stats === 'zero'
          }">
          {{ record.estimated_profit.value | currency }}
        </span>
    </td>
  </tr>
</table>
