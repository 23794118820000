import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs/Subject';
import { ClientsListStoreService } from './clients-list-store/clients-list-store.service';
import { ClientStoreService } from './client-store.service';
import { PusherService } from './pusher.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Pagination } from '../objects/pagination';
import {
  Notification,
  NotificationThemes,
  NotificationInterface,
  NotificationAction,
  NotificationTheme,
  NotificationTypes
} from '../objects/notification';
import { Confirmation, ConfirmationType, ConfirmationAnswer, ConfirmationInterface, ConfirmationPair } from '../objects/confirmation';
import { Prompt, PromptInterface } from '../objects/prompt';
import * as PusherTypes from 'pusher-js';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  /**
   * This will be the stream where all notifications
   * will go through.
   *
   * @var {Subject<Notification|NotificationAction>}
   */
  private notifSource = new Subject<Notification|NotificationAction>();

  /**
   * Stream where both the popup and the answer
   * confirmation will pass through.
   *
   * @var {Subject<Notification|NotificationAction>}
   */
  private confirmSource = new Subject<Confirmation|ConfirmationAnswer>();

  /**
   * This will be the stream where notification will mark as read
   */
  private notifMarkAsReadSource = new Subject<Notification>();

  /**
   * Listen to all notification that will be mark as read
   */
  notifMarkAsRead$ = this.notifMarkAsReadSource.asObservable();

  /**
   * Listen to all notifications events
   * going through the source stream.
   *
   * NOTE: Make sure to filter the stream based on what you need!
   *
   * @var {Observable<Notification|NotificationAction>}
   */
  notif$ = this.notifSource.asObservable();

  /**
  * Listen to all confirmation events
  * going through the source stream.
  *
  * NOTE: Make sure to filter the stream based on what you need!
  *
  * @var {Observable<Confirmation|ConfirmationAnswer>}
  */
  confirm$ = this.confirmSource.asObservable();

  /**
   * The Pusher channel where we will listen
   * for any events pushed through Pusher.
   *
   * @var {any}
   */
  public obvNotificationChannel: any;

  /**
   * The base url for all requests in this service.
   *
   * @returns {string}
   */
  get strBaseUrl() {
    return environment.url + '/notifications/';
  }

  constructor(
    public clientsListStoreService: ClientsListStoreService,
    public localStorageService: LocalStorageService,
    public clientStoreService: ClientStoreService,
    private pusherService: PusherService,
    private http: HttpClient,
  ) {

  }

  /**
   * Send a notification to your user.
   *
   * @param {string} strHeader - The header of the notification.
   * @param {string} strMessage - The notification main message.
   * @param {string} strTheme - The theme of the notification
   * @param {string} numDuration - How long will the notification appear.
   *
   * @returns {void}
   *
   * @deprecated use notifyError for error notification
   * @deprecated use notifySuccess for success notification
   * @deprecated use notifyWarning for warning notification
   * @deprecated use notify internally within the service
   */
  sendNotification(strHeader: string, strMessage: string, strTheme: NotificationThemes, numDuration?: number): void {
    this.notifSource.next(new Notification({
      header: strHeader,
      message: strMessage,
      theme: strTheme,
      duration: numDuration
    }));
  }

  /**
   * Open up a confirmation dialog to your user.
   *
   * @param {string} strConfirmMessage
   *  The message of the confirmation dialog.
   * @param {string} strConfirmHeader
   *  The header of the confirmation dialog, by default, the value is "confirmation".
   * @param {string} strConfirmButton
   *  The type of confirmation this is. By default, value is 'default' but can also be 'ok'.
   * @param {ConfirmationPair} objConfirmOptions
   *  Type options to choose from in the confirmation dialog. Default options are 'Yes' and 'No'.
   *
   * Returns the stream of confirmation answers only.
   *
   * @returns {Observable<ConfirmationAnswer>}
   */
  sendConfirmation(
    strConfirmMessage?: string,
    strConfirmHeader?: string,
    strConfirmButton?: ConfirmationType,
    objConfirmOptions?: ConfirmationPair
  ): Observable<ConfirmationAnswer> {

    this.confirmSource.next(new Confirmation({
      header: strConfirmHeader,
      message: strConfirmMessage,
      type: strConfirmButton,
      options: objConfirmOptions
    }));

    return this.confirm$.pipe(
      filter(confirmation => confirmation instanceof ConfirmationAnswer),
      map((confirmation: ConfirmationAnswer) => { return confirmation }),
      take(1)
    );
  }

  /**
   * Sends the confirmation answer.
   *
   * @param {boolean} answer
   * @param {ConfirmationType} type
   *
   * @returns {void}
   */
  sendConfirmationAnswer(answer: boolean, type?: ConfirmationType): void {
    this.confirmSource.next(new ConfirmationAnswer(answer, type));
  }

  /**
   * Sends signal to listener that a button from
   * the notification has been triggered.
   *
   * @param {boolean} answer
   *
   * @returns {void}
   */
  sendNotificationAction(id: number|string, answer: boolean): void {
    this.notifSource.next(new NotificationAction(id, answer));
  }

  /**
   * Customize your own  notification by passing an object
   * with Notification type.
   *
   * @param {Notification} objNotification
   *
   * @returns {void}
   */
  customNotification(objNotification: NotificationInterface): Notification {
    let objNewNotification: Notification = new Notification(objNotification);
    this.notifSource.next(objNewNotification);
    return objNewNotification;
   }

 /**
  * Customize your own confirmation by passing an object
  * with Confirmation type.
  *
  * @param {Confirmation} objConfirmation
  *
  * @returns {void}
  */
  customConfirmation(objConfirmation: ConfirmationInterface): void {
    this.confirmSource.next(new Confirmation(objConfirmation));
  }

  /**
   * sends a notification type of error
   *
   * @param {string} strMessage
   * @param {NotificationOptions|undefined} objOptions
   *
   * @returns {void}
   */
  notifyError(strMessage: string, objOptions: NotificationOptions = {}): void {
    this.notify({
      message: strMessage,
      theme: NotificationTheme.THEME_ERROR,
      header: objOptions.header || 'error',
      type: objOptions.type,
      id: objOptions.id,
      duration: objOptions.duration,
    });
  }

  /**
   * sends a notification type of success
   *
   * @param {string} strMessage
   * @param {NotificationOptions|undefined} objOptions
   *
   * @returns {void}
   */
  notifySuccess(strMessage: string, objOptions: NotificationOptions = {}): void {
    this.notify({
      message: strMessage,
      theme: NotificationTheme.THEME_SUCCESS,
      header: 'success',
      type: objOptions.type,
      id: objOptions.id,
      duration: objOptions.duration,
    });
  }

  /**
   * sends a notification type of warning
   *
   * @param {string} strMessage
   * @param {NotificationOptions|undefined} objOptions
   *
   * @returns {void}
   */
  notifyWarning(strMessage: string, objOptions: NotificationOptions = {}): void {
    this.notify({
      message: strMessage,
      theme: NotificationTheme.THEME_WARNING,
      header: 'warning',
      type: objOptions.type,
      id: objOptions.id,
      duration: objOptions.duration,
    });
  }

  /**
   * Sends a notification type of info which
   * is a lighter blue color than the primary.
   *
   * @param {string} strMessage
   *
   * @returns {void}
   */
  notifyInfo(strMessage: string): void {
    this.sendNotification('info', strMessage, 'info');
  }

  /**
   * Shows a prompt error. This is when the user needs to know
   * the exact field where they have an error when submitting
   * a form.
   *
   * @param {Prompt} objPrompt
   *
   * @returns {void}
   */
  promptError(objPrompt: PromptInterface): void {
    this.customNotification({
      header: 'invalid_parameter',
      message: new Prompt(objPrompt),
      theme: 'warning',
      type: 'interceptor',
      duration: 5000
    });
  }

  /**
   * Set the Pusher notifications.
   *
   * @param {string} strUserId
   *
   * @returns {PusherTypes.Channel}
   */
  getPusherNotificationListener(): PusherTypes.Channel {
    return this.pusherService.getPrivatePusherInstance();
  }

  /**
   * Marks a notification as read.
   *
   * @param {string[]} strNotificationId
   *
   * @returns {Observable<Response>}
   */
  markNotificationAsRead(strNotificationId: string[]|string): Observable<Response> {
    let body = new URLSearchParams();

    if (typeof strNotificationId !== 'string') {
      strNotificationId = JSON.stringify(strNotificationId);
    }

    body.append('id', strNotificationId);
    return this.http.post<Response>(this.strBaseUrl + "mark", body.toString())
  }

  /**
   * Retrieves all notifications from server.
   *
   * @param {string} strToken
   *
   * @returns {Observable<{ data: Notification[], token: Pagination }>}
   */
  getNotifications(strToken: string = null): Observable<{ data: Notification[], token: Pagination, unread: number }> {
    let body = new URLSearchParams();
    body.append('token', strToken);
    return this.http.post<Response>(this.strBaseUrl + "get", body.toString())
      .map(response => {
        return {
          unread: response['data']['unreads'] as number || 0,
          data: response['data']['notifications'].map(notification => {
            return new Notification({
              id: notification.id,
              message: notification.contents.data,
              link: notification.contents.link,
              is_read: notification.is_read,
              header: notification.type,
              type: 'persistent',
            })
          }) as Notification[],
          token: (response['next']) ? new Pagination(response['next']) : null
        }
      });
  }

  /**
   * Removes a notification from the clients table.
   *
   * @param {string} strTitle
   *
   * @returns {void}
   */
  removeNotification(strTitle: string): void {

    let body = new URLSearchParams();
    body.append('data', JSON.stringify({ "title": strTitle }));

    this.http.post<Response>(environment.url + "/account/remove_notification", body.toString())
      .subscribe(
        data => {
          if (data) {
            let objCurrentClient = this.clientStoreService.getActiveClient();
            if (objCurrentClient.notifications) {
              delete objCurrentClient.notifications[strTitle];
              this.clientStoreService.setActiveClient(objCurrentClient);
              this.clientStoreService.setPreviousClient(objCurrentClient);

              let arAllClients = this.clientsListStoreService.getClientList();

              if (arAllClients[objCurrentClient['client_id']]['notifications']) {
                delete arAllClients[objCurrentClient['client_id']]['notifications'][strTitle];
                this.clientsListStoreService.setClientList(arAllClients);
              }
            }
          }
        }
      );
  }

  /**
   * Mark as read of the given notification
   * @param notification
   */
  notificationMarkAsRead(notification: Notification): void {
    this.notifMarkAsReadSource.next(notification);
  }

  /**
   * Emit a new notification
   *
   * @param   {NotificationInterface} objProps
   *
   * @returns {void}
   */
  protected notify(objProps: NotificationInterface): void {
    this.notifSource.next(new Notification(objProps));
  }

  /**
   * Emit notification that provided action is not allowed or forbidden
   *
   * @param strAction
   *
   * @returns {void}
   */
  notifyNotAllowed(strAction: string = 'forbidden_action_error'): void {
    this.notifyError(strAction, {
      header: 'not_allowed'
    });
  }
}

type NotificationOptions = {
  type?: NotificationTypes;
  id?: string | number;
  header?: string;
  duration?: number;
}