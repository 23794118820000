<div>
  <div class="row">
    <div class="col-12 mb-3">
      <div *ngIf="strRecordType === 'jobs'; else commonSummary">
        <app-job-summary [bHasNoCustomer]="bHasNoCustomer" [summary]="summary" [objAssetData]="objAssetData"></app-job-summary>
      </div>

      <ng-template #commonSummary>
        <app-common-summary [summary]="summary"></app-common-summary>
      </ng-template>
    </div>
  </div>
  <!-- Stepper Next -->
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <ng-container [ngTemplateOutlet]="next"></ng-container>
    </div>
  </div>
</div>