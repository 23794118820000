<header>
  <div class="d-flex dialog-header">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'dollar-sign']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType === 'edit'" >{{ 'edit_job_invoice'| translate }} </span>
        <span *ngIf="strViewType === 'add'">{{ 'create_job_invoice'| translate }}</span>
    </h3>
    <ng-container *fcHasPermission="'customer_invoices:create'">
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary" [disabled]="bSubmitted || (whenInProgress$ | async)">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
              <span class="pl-1">{{ 'cancel' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <form-templates-save-and-preview-btn
              [disabled]="isPreviewTemplate || (whenFailed$ | async) || (whenInProgress$ | async)"
              (click)="onSubmitAndPreview()"
              [trigger-event]="previewTemplate.asObservable()">
            </form-templates-save-and-preview-btn>
          </li>
          <li class="list-inline-item">
            <form-real-time-pdf-preview
              [disabled]="isPreviewTemplate || (whenFailed$ | async) || (whenInProgress$ | async)"
              (click)="onPreview()"
              [trigger-event]="realTimePreviewTemplate.asObservable()"
              (onComplete)="whenInProgress$.next(false)">
            </form-real-time-pdf-preview>
          </li>
          <li class="list-inline-item">
            <button (click)="saveInvoice()" class="btn ml-1 btn-primary" [disabled]="bSubmitted || (whenFailed$ | async) || (whenInProgress$ | async)">
              <fa-icon *ngIf="!bSubmitted" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bSubmitted" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</header>

<hr />

<ng-container *fcHasPermission="'customer_invoices:create'; else createCustomerInvoiceDisabled">

<mat-dialog-content *ngIf="jobInvoiceForm" class="mobile-view-dialog">
<app-error-handler
  onlyFor="edit_form"
  [isAction]="true"
  alignment="center"
  (onError)="onError()"
>
<div *ngIf="jobInvoiceForm.length != 0">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li *ngFor="let item of jobInvoiceForm" class="nav-item text-size">
      <a class="nav-link" [ngClass]="{'active': jobInvoiceForm[0]['label'] === item.label}" id="{{item.label}}-tab" data-toggle="tab" href="#{{item.id}}" role="tab" aria-selected="true" translate>{{ item.label }}</a>
    </li>
  </ul>
  <div class="tab-content">
    <div *ngFor="let item of jobInvoiceForm" [ngClass]="{'active': jobInvoiceForm[0]['label'] === item.label, 'show': jobInvoiceForm[0]['label'] === item.label}" class="tab-pane row p-4" [id]="item.id" role="tabpanel">
      <form [formGroup]="item.groups">
        <div class="row">
          <ng-container *ngFor="let data of item.fields">
            <div *ngIf="data.label != 'status'" [ngClass]="
            {'col-2': arCustomColumn.indexOf(data.label) > -1,
            'col-6': arCustomColumn.indexOf(data.label) < 0 && data.space === 6,
            'col-12': arCustomColumn.indexOf(data.label) < 0 && data.space === 12
            }"
            class="mb-3">
              <ng-container *ngIf="(( data.label != 'created_by' && data.label != 'modified_by' && data.label != 'updated_at' && data.label != 'created_at') && strViewType === 'add') || strViewType === 'edit'">
                <app-edit-field *ngIf="arCustomField.indexOf(data.key) < 0" [item]="data" [form]="item.groups" [bSubmitted]="bSubmitted" [strMode]="strViewType" [strModule]="strMyModule" (parentEvent)="doSomethingInParent($event)" [strRecordId]=""></app-edit-field>
                <ng-container *ngIf="data.key === 'site_id'">
                  <label> {{ 'site' | translate }}</label>
                  <ng-select
                    [items]="arRelateSiteValues$ | async"
                    [loading]="bRelateLoadingSite"
                    [typeahead]="arRelateSiteValuesInput$"
                    [clearable]="true"
                    class="value-text"
                    name="site_id"
                    bindLabel="text"
                    [bindValue]
                    [disabled]="(strRecordModule === 'sites') ? true : false"
                    [ngModelOptions]="{standalone: true}"
                    (open)="(objSiteData['id'] === undefined) ? initRelateData('sites') : ''"
                    [(ngModel)]="objSiteField"
                    (change)="getRelateText(data, $event)"
                    >
                    <!-- DISPLAY IN SELECTED -->
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item.text | translate }}</span>
                    </ng-template>
                    <!-- DISPLAY IN OPTION -->
                    <ng-template  ng-option-tmp let-item="item">
                      <span class="value-text"> {{ item.text | translate }}</span>
                    </ng-template>
                  </ng-select>
                </ng-container>
                <ng-container *ngIf="data.key === 'customer_id'">
                    <label> {{ 'customer' | translate }}</label>
                    <required-tag></required-tag>
                    <ng-select
                      [items]="arRelateCustomerValues$ | async"
                      [loading]="bRelateLoadingCustomer"
                      [typeahead]="arRelateCustomerValuesInput$"
                      [clearable]="true"
                      class="value-text"
                      name="customer_id"
                      bindLabel="text"
                      [bindValue]
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="objCustomerField"
                      [disabled]="(strRecordModule === 'customers') ? true : false"
                      (open)="(objCustomerData['id'] === undefined) ? initRelateData('customers') : ''"
                      [ngClass]="{'is-invalid': validateField(objCustomerField) && isTouched}"
                      (change)="getRelateText(data, $event)">
                      <!-- DISPLAY IN SELECTED -->
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{ item.text | translate }}</span>
                      </ng-template>
                      <!-- DISPLAY IN OPTION -->
                      <ng-template  ng-option-tmp let-item="item">
                        <span class="value-text"> {{ item.text | translate }}</span>
                      </ng-template>
                    </ng-select>
                </ng-container>
                <ng-container *ngIf="data.key === 'contact_id'">
                    <label> {{ 'contact' | translate }}</label>
                    <ng-select
                      [items]="arRelateValues$ | async"
                      [loading]="bRelateLoading"
                      [typeahead]="arRelateValuesInput$"
                      [clearable]="true"
                      class="value-text"
                      name="contact_id"
                      bindLabel="text"
                      [bindValue]
                      (open)="initRelateData('contacts')"
                      groupBy="groupname"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="strContactId"
                      (change)="getRelateText(data, $event)"
                      >
                      <!-- DISPLAY IN SELECTED -->
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{ item.text | translate }}</span>
                      </ng-template>
                      <!-- DISPLAY GROUP NAME -->
                      <ng-template ng-optgroup-tmp let-item="item">
                        {{ (item.groupname | translate) }}
                      </ng-template>
                      <!-- DISPLAY IN OPTION -->
                      <ng-template  ng-option-tmp let-item="item">
                        <span *ngIf="!item.role" class="value-text"> {{ item.text | translate }}</span>
                        <span *ngIf="item.role" class="value-text"> {{ item.text | translate }} - {{ item.role | translate }}</span>
                      </ng-template>
                    </ng-select>
                </ng-container>
                <ng-container *ngIf="data.key === 'date_invoice'">
                    <label> {{ 'date_invoice' | translate }}</label>
                    <div class="input-group">
                      <input
                        [(ngModel)]="dateInvoiceField"
                        [owlDateTime]="dateFieldInvoice"
                        (dateTimeChange)="dateInvoiceFieldChange($event)"
                        [ngModelOptions]="{standalone: true}"
                        [id]="date-invoice"
                        type="text"
                        class="form-control date-input"
                        (ngModelChange)="markAsDirty()"
                        >
                      <button class="calendar-button" [owlDateTimeTrigger]="dateFieldInvoice">
                        <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                      </button>
                    </div>

                    <div>
                      <owl-date-time  #dateFieldInvoice [pickerType]="'calendar'"></owl-date-time>
                    </div>
                </ng-container>
                <ng-container *ngIf="data.key === 'date_due'">
                    <label> {{ 'date_due' | translate }}</label>
                    <div class="input-group">
                      <input
                        [(ngModel)]="dateInvoiceDueField"
                        [owlDateTime]="dateFieldInvoiceDue"
                        [ngModelOptions]="{standalone: true}"
                        [id]="date-invoice"
                        type="text"
                        class="form-control date-input"
                        (ngModelChange)="markAsDirty()"
                        >
                      <button class="calendar-button" [owlDateTimeTrigger]="dateFieldInvoiceDue">
                        <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                      </button>
                    </div>
                    <div>
                      <owl-date-time  #dateFieldInvoiceDue [pickerType]="'calendar'"></owl-date-time>
                    </div>
                </ng-container>
                <ng-template *ngIf="data.key === 'line_items' then lineItems"></ng-template>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
<div align="center">
  <fa-icon *ngIf="jobInvoiceForm.length === 0" [icon]="['fas', 'spinner']" [spin]="true" class="loading-spinner" aria-hidden="true"></fa-icon>
</div>
</app-error-handler>
</mat-dialog-content>

<ng-template #lineItems>
<!-- Time Entry Group Field -->
<ng-container *ngIf="strInvoicingType === 'time_and_materials' && enableGroup === true">
  <div class="row">
    <div class="col-6">
      <label class="labels">
        {{ 'time_entries' | translate }}
      </label>
    </div>
    <div class="col-6">
      <a class="float-right action_content" [ngClass]="{'text-secondary': !bTimeEntryLoaded }" (click)="(bTimeEntryLoaded) ? copyActualHours() : ''" [routerLink]="" href="">
        <fa-icon [icon]="['fal', 'copy']"></fa-icon>&nbsp;{{ 'copy_actual_hours' | translate }} &nbsp;
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2">
      <browse-products
        (objProductSelected)="addTimeEntryAttribute($event, data, true)"
        [bLabor]="true"
        [bAllowCustom]="false"
        [customerId]="getCurrentCustomerId()"
      ></browse-products>
    </div>
  </div>
  <div class="row">

    <div *ngFor="let grouped of arGroupedTimeEntries" class="col-12">
      <div *ngIf="bTimeEntryLoaded && grouped.time_entries.length > 0" class="row">

        <div class="col-12">
          <div class="w-100 border border-top-0 border-primary rounded-2">
            <div class="col-12 border-bottom text-center bg-primary text-white">
              <div class="d-flex flex-row">
                <div class="draggable-table-box center-items" style="width: 8%;">
                  <b>{{ 'technician' | translate }}</b>
                </div>
                <div class="draggable-table-box left-items" style="width: 34%;">
                  {{ grouped.user_text | translate }}
                </div>
                <div class="draggable-table-box center-items" style="width: 8%;">
                  <b>{{ 'summary' | translate }}</b>
                </div>
                <div class="draggable-table-box left-items text-left" style="width: 40%;">
                  <div *ngIf="grouped.summary.length <= 256"
                    style="height: 50px; overflow: auto;"
                    class="left-items">
                    {{ grouped.summary }}
                  </div>
                  <div *ngIf="grouped.summary.length > 256"
                    style="height: 50px; overflow: auto;"
                    class="pl-2">
                    {{ grouped.summary }}
                  </div>
                </div>
                <div class="draggable-table-box center-items" style="width: 10%;" (click)="grouped.collapsed = !grouped.collapsed">
                  <span class="pointer">
                    <fa-icon *ngIf="grouped.collapsed" [icon]="['fas', 'chevron-up']" size="lg"></fa-icon>
                    <fa-icon *ngIf="!grouped.collapsed" [icon]="['fas', 'chevron-down']" size="lg"></fa-icon>
                  </span>
                </div>
              </div>
            </div>

            <ng-container *ngIf="grouped.collapsed">
              <div class="col-12 p-0">
                <div class="d-flex flex-row border">
                  <div class="daggable-table-box-header" style="width: 8%"></div>
                  <div class="draggable-table-box-header" [ngStyle]="{ width: bDepartmentTracking ? '22%' : '34%'}">
                    <b>{{ 'description' | translate }}</b>
                  </div>
                  <div class="draggable-table-box-header" style="width: 8%">
                    <b>{{ 'unit_price' | translate }}</b>
                  </div>
                  <div class="draggable-table-box-header" style="width: 8%">
                    <b>{{ 'billable' | translate }}</b>
                  </div>
                  <div class="draggable-table-box-header center-items" style="width: 8%">
                    <b>{{ 'actual_hours' | translate }}</b>
                  </div>
                  <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 12%">
                    <b>{{ 'department' | translate }}<required-tag></required-tag></b>
                  </div>
                  <div class="draggable-table-box-header" style="width: 12%">
                    <b>{{ 'tax_code' | translate }}<required-tag></required-tag></b>
                  </div>
                  <div class="draggable-table-box-header" style="width: 12%">
                    <b>{{ 'account_code' | translate }}<required-tag></required-tag></b>
                  </div>
                  <div class="draggable-table-box-header" style="width: 10%">
                    <b>{{ 'total_price' | translate }}</b>
                  </div>
                </div>
              </div>

              <div *ngFor="let attr of grouped.time_entries; index as i" class="row">
                <div class="col-12">
                  <div class="d-flex flex-row bg-white">

                    <div class="draggable-table-box center-items" style="width: 8%;">
                      <fa-icon
                        class="text-danger pointer"
                        (click)="removeAttribute(attr, 'time_entry' , true)"
                        [icon]="['fal', 'minus-circle']">
                      </fa-icon>
                      <a *ngIf="attr['time_entry']['item_id']"
                        class="pl-1"
                        href="/#/items/{{ attr['time_entry']['item_id'] }}"
                        target="_blank">
                        <fa-icon
                          matTooltipClass="tooltip-space"
                          matTooltip="
                          {{ 'click_to_view' | translate }}&#13;&#13;
                          {{ 'product' | translate }}: {{ attr['item']['text'] }}&#13;
                          {{ 'code' | translate }}: {{ attr['customer_invoice']['item_code'] | placeholdWithString }}&#13;
                          "
                          class="text-primary"
                          [icon]="['fas', 'info-circle']">
                        </fa-icon>
                      </a>
                      <input
                        class="ml-1"
                        type="checkbox"
                        matTooltip="{{ 'exclude_from_invoice' | translate }}"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="attr['customer_invoice']['exclude_from_invoice']"
                        (change)="excludeInvoiceChange($event, attr, 'time_entry')" >
                    </div>

                    <div class="draggable-table-box" [ngStyle]="{ width: bDepartmentTracking ? '22%' : '34%'}">
                      <textarea
                        class="form-control time-entry-desc-{{i}}" style="font-size: 12px;"
                        placeholder="{{ 'description' | translate }}"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="attr['time_entry']['description']"
                        (focus)="increaseRow('time-entry', i)"
                        (focusout)="decreaseRow('time-entry', i)"
                        (ngModelChange)="markAsDirty()">
                      </textarea>
                    </div>
                    <div class="draggable-table-box" style="width: 8%">
                      <fc-currency-input
                        id="time_entries_unit_price_{{ i }}"
                        [(ngModel)]="attr['customer_invoice']['unit_price']"
                        [ngModelOptions]="{standalone: true}"
                        [nonNegative]="true"
                        [customClass]="'form-control font-size-12 input-height-40'"
                        (change)="unitPriceChange($event, attr, 'time_entry')">
                      </fc-currency-input>
                    </div>
                    <div class="draggable-table-box" style="width: 8%">
                      <fieldmagic-decimal-input
                        (change)="billableChange($event, attr)"
                        step="0.01"
                        customClass="form-control font-size-12 input-height-40"
                        placeholder="{{ 'billable_hours' | translate }}"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="attr['time_entry']['billed_duration']">
                      </fieldmagic-decimal-input>
                    </div>
                    <div class="draggable-table-box center-items" style="width: 8%">
                      <span>{{ attr['time_entry']['actual_duration'] }}</span>
                    </div>
                    <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 12%">
                      <ng-select
                        class="h-40"
                        [items]="attr['department_obv'] | async"
                        [typeahead]="attr['department_typehead']"
                        [loading]="attr['department_loader']"
                        [clearable]="true"
                        (change)="selectDepartment($event, attr, 'time_entries')"
                        [ngModelOptions]="{standalone: true}"
                        bindLabel="text"
                        [bindValue]
                        placeholder="{{ 'select_department' | translate }}"
                        [(ngModel)]="attr['department']"
                        [id]="attr['type'] + 'department'"
                        [name]="attr['type'] + 'department'"
                        (open)="initRelateRecords('arTimeEntryAttributes', 'departments', 'department', '', objCacheData['departments'], i, attr)"
                        [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['department_id']) && isTouched}">
                      </ng-select>
                    </div>
                    <div class="draggable-table-box" style="width: 12%">
                      <ng-select
                        class="h-40"
                        [items]="attr['taxcode_obv'] | async"
                        [typeahead]="attr['taxcode_typehead']"
                        [loading]="attr['taxcode_loader']"
                        [clearable]="true"
                        (change)="selectATaxcode($event, attr, 'time_entry')"
                        [ngModelOptions]="{standalone: true}"
                        bindLabel="text"
                        [bindValue]
                        placeholder="{{ 'tax_code' | translate }}"
                        [(ngModel)]="attr['tax']"
                        [id]="attr['type'] + 'tax_code'"
                        [name]="attr['type'] + 'tax_code'"
                        (open)="initRelateRecords('arTimeEntryAttributes', 'tax_codes', 'taxcode', arTaxCodeFilter, objCacheData['tax_codes'], i, attr)"
                        [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['tax_code_id']) && isTouched && strInvoicingType != 'fixed_price_invoices'}">
                      </ng-select>
                    </div>
                    <div class="draggable-table-box" style="width: 12%">
                      <ng-select
                        class="h-40"
                        [items]="attr['account_code_obv'] | async"
                        [typeahead]="attr['account_code_typehead']"
                        [loading]="attr['account_code_loader']"
                        [clearable]="true"
                        (change)="selectAccountCode($event, attr, 'time_entry')"
                        [ngModelOptions]="{standalone: true}"
                        bindLabel="name"
                        [bindValue]
                        placeholder="{{ 'account_code' | translate }}"
                        [(ngModel)]="attr['account_code']"
                        [id]="attr['type'] + 'account_code'"
                        [name]="attr['type'] + 'account_code'"
                        (open)="initRelateRecords('arTimeEntryAttributes', 'account_codes', 'account_code', arAccountCodeFilter, objCacheData['account_codes'], i, attr)"
                        [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['account_code_id']) && isTouched && strInvoicingType != 'fixed_price_invoices'}">
                      </ng-select>
                    </div>
                    <div class="draggable-table-box" style="width: 10%">
                      <fc-currency-input
                        customClass="form-control font-size-12 input-height-40"
                        [(ngModel)]="attr['amount']"
                        (change)="onLineTotalChange($event.target, {attr: attr, index: i, module: 'time_entry'})">
                      </fc-currency-input>
                    </div>
                  </div>
                  <div class="d-flex flex-row border-bottom bg-white">
                    <div class="draggable-table-box center-items p-1" style="width: 8%;">
                      <b>{{ 'technician' | translate }}</b>
                    </div>
                    <div class="draggable-table-box center-items" style="width: 34%;">
                      <ng-select
                        class="h-40 w-100"
                        [items]="attr['user_obv'] | async"
                        [typeahead]="attr['user_typehead']"
                        [loading]="attr['user_loader']"
                        [clearable]="true"
                        [ngModelOptions]="{standalone: true}"
                        bindLabel="text"
                        [bindValue]
                        (change)="selectUser($event, attr)"
                        placeholder="{{ 'user' | translate }}"
                        [(ngModel)]="attr['user']"
                        [id]="attr['type'] + 'user'"
                        [name]="attr['type'] + 'user'"
                        (open)="openDropdown(attr, 'user_typehead')"
                        [ngClass]="{'is-invalid': validateField(attr['time_entry']['user_id']) && isTouched && strInvoicingType != 'fixed_price_invoices' }">
                      </ng-select>
                    </div>
                    <div class="draggable-table-box center-items p-1" style="width: 8%;">
                      <b>{{ 'work_date' | translate }}</b>
                    </div>
                    <div class="draggable-table-box" style="width: 40%;">
                      <div class="input-group">
                        <input
                          [owlDateTime]="dateTimeRangeField"
                          [selectMode]="'range'"
                          [(ngModel)]="attr['work_date']"
                          [ngModelOptions]="{standalone: true}"
                          (dateTimeChange)="selectWorkDate($event, attr)"
                          type="text"
                          class="form-control date-input"
                          [readonly]="attr['has_time_entries']"
                          [ngClass]="{'is-invalid': validateDateTime(attr['work_date']) && isTouched && strInvoicingType != 'fixed_price_invoices'}"
                          (ngModelChange)="markAsDirty()"
                          >

                        <span><fa-icon *ngIf="dateTimeRangeField && !attr['has_time_entries']" [icon]="['fas', 'times']" class="date-clear" (click)="clearField(attr)"></fa-icon></span>

                        <button
                          matTooltip="{{ 'work_date' | translate }}"
                          [disabled]="attr['has_time_entries']"
                          class="calendar-button"
                          [owlDateTimeTrigger]="(attr['has_time_entries']) ? '' : dateTimeRangeField">
                          <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                        </button>
                      </div>

                      <div>
                        <owl-date-time #dateTimeRangeField></owl-date-time>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

      </div>
    </div>

    <div class="col-12" *ngIf="arTimeEntryAttributes.length < 1">
      <div class="d-flex flex-row border">
        <div class="daggable-table-box-header" style="width: 8%"></div>
        <div class="draggable-table-box-header" [ngStyle]="{ width: bDepartmentTracking ? '22%' : '34%'}">
          <b>{{ 'description' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 8%">
          <b>{{ 'unit_price' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 8%">
          <b>{{ 'billable' | translate }}</b>
        </div>
        <div class="draggable-table-box-header center-items" style="width: 8%">
          <b>{{ 'actual_hours' | translate }}</b>
        </div>
        <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'department' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'tax_code' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'account_code' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 10%">
          <b>{{ 'total_price' | translate }}</b>
        </div>
      </div>
      <div class="d-flex flex-row border border-top-0 justify-content-center p-3">
        <small>{{ 'empty_time_entry_label' | translate }}</small>
      </div>
    </div>

    <div *ngIf="!bTimeEntryLoaded" class="col-12 text-center" style="font-size: 16px;">
      <div class="d-flex flex-row border border-top-0 justify-content-center p-3">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
      </div>
    </div>

  </div>
</ng-container>

<!-- Time Entry Field -->
<ng-container *ngIf="strInvoicingType === 'time_and_materials' && enableGroup === false">
  <div class="row">
    <div class="col-6">
      <label class="labels">
        {{ 'time_entries' | translate }}
      </label>
    </div>
    <div class="col-6">
      <a class="float-right action_content" [ngClass]="{'text-secondary': !bTimeEntryLoaded }" (click)="(bTimeEntryLoaded) ? copyActualHours() : ''" [routerLink]="" href="">
        <fa-icon [icon]="['fal', 'copy']"></fa-icon>&nbsp;{{ 'copy_actual_hours' | translate }} &nbsp;
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2">
      <browse-products
        (objProductSelected)="addTimeEntryAttribute($event, data, true)"
        [bLabor]="true"
        [bAllowCustom]="false"
        [customerId]="getCurrentCustomerId()"
      ></browse-products>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-row border">
        <div class="daggable-table-box-header" style="width: 8%"></div>
        <div class="draggable-table-box-header" [ngStyle]="{ width: bDepartmentTracking ? '22%' : '34%'}">
          <b>{{ 'description' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 8%">
          <b>{{ 'unit_price' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 8%">
          <b>{{ 'billable' | translate }}</b>
        </div>
        <div class="draggable-table-box-header center-items" style="width: 8%">
          <b>{{ 'actual_hours' | translate }}</b>
        </div>
        <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'department' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'tax_code' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'account_code' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 10%">
          <b>{{ 'total_price' | translate }}</b>
        </div>
      </div>
    </div>

    <div *ngIf="bTimeEntryLoaded" class="col-12">
      <div class="row">

        <ng-container *ngFor="let attr of arTimeEntryAttributes; index as i">
          <div class="col-12">
            <div class="d-flex flex-row border-right border-left bg-white">

              <div class="draggable-table-box center-items" style="width: 8%;">
                <fa-icon
                  class="text-danger pointer"
                  (click)="removeAttribute(attr, 'time_entry')"
                  [icon]="['fal', 'minus-circle']">
                </fa-icon>
                <a *ngIf="attr['time_entry']['item_id']"
                  class="pl-1"
                  href="/#/items/{{ attr['time_entry']['item_id'] }}"
                  target="_blank">
                  <fa-icon
                    matTooltipClass="tooltip-space"
                    matTooltip="
                    {{ 'click_to_view' | translate }}&#13;&#13;
                    {{ 'product' | translate }}: {{ attr['item']['text'] }}&#13;
                    {{ 'code' | translate }}: {{ attr['customer_invoice']['item_code'] | placeholdWithString }}&#13;
                    "
                    class="text-primary"
                    [icon]="['fas', 'info-circle']">
                  </fa-icon>
                </a>
                <input
                  class="ml-1"
                  type="checkbox"
                  matTooltip="{{ 'exclude_from_invoice' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['customer_invoice']['exclude_from_invoice']"
                  (change)="excludeInvoiceChange($event, attr, 'time_entry')" >
              </div>

              <div class="draggable-table-box" [ngStyle]="{ width: bDepartmentTracking ? '22%' : '34%'}">
                <textarea
                  class="form-control time-entry-desc-{{i}}" style="font-size: 12px;"
                  placeholder="{{ 'description' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['time_entry']['description']"
                  (focus)="increaseRow('time-entry', i)"
                  (focusout)="decreaseRow('time-entry', i)"
                  (ngModelChange)="markAsDirty()">
                </textarea>
              </div>
              <div class="draggable-table-box" style="width: 8%">
                <fc-currency-input
                  id="time_entries_unit_price_{{ i }}"
                  [places]="4"
                  [(ngModel)]="attr['customer_invoice']['unit_price']"
                  [ngModelOptions]="{standalone: true}"
                  [customClass]="'form-control font-size-12 input-height-40'"
                  (change)="unitPriceChange($event, attr, 'time_entry')">
                </fc-currency-input>
              </div>
              <div class="draggable-table-box" style="width: 8%">
                <fieldmagic-decimal-input
                  (change)="billableChange($event, attr)"
                  step="0.01"
                  customClass="form-control font-size-12 input-height-40"
                  placeholder="{{ 'billable_hours' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['time_entry']['billed_duration']">
                </fieldmagic-decimal-input>
              </div>
              <div class="draggable-table-box center-items" style="width: 8%">
                <span>{{ attr['time_entry']['actual_duration'] }}</span>
              </div>
              <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 12%">
                <ng-select
                  class="h-40"
                  [items]="attr['department_obv'] | async"
                  [typeahead]="attr['department_typehead']"
                  [loading]="attr['department_loader']"
                  [clearable]="true"
                  (change)="selectDepartment($event, attr, 'time_entries')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="text"
                  [bindValue]
                  placeholder="{{ 'select_department' | translate }}"
                  [(ngModel)]="attr['department']"
                  [id]="attr['type'] + 'department'"
                  [name]="attr['type'] + 'department'"
                  (open)="initRelateRecords('arTimeEntryAttributes', 'departments', 'department', '', objCacheData['departments'], i)"
                  [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['department_id']) && isTouched}">
                </ng-select>
              </div>
              <div class="draggable-table-box" style="width: 12%">
                <ng-select
                  class="h-40"
                  [items]="attr['taxcode_obv'] | async"
                  [typeahead]="attr['taxcode_typehead']"
                  [loading]="attr['taxcode_loader']"
                  [clearable]="true"
                  (change)="selectATaxcode($event, attr, 'time_entry')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="text"
                  [bindValue]
                  placeholder="{{ 'tax_code' | translate }}"
                  [(ngModel)]="attr['tax']"
                  [id]="attr['type'] + 'tax_code'"
                  [name]="attr['type'] + 'tax_code'"
                  (open)="initRelateRecords('arTimeEntryAttributes', 'tax_codes', 'taxcode', arTaxCodeFilter, objCacheData['tax_codes'], i)"
                  [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['tax_code_id']) && isTouched && strInvoicingType != 'fixed_price_invoices'}">
                </ng-select>
              </div>
              <div class="draggable-table-box" style="width: 12%">
                <ng-select
                  class="h-40"
                  [items]="attr['account_code_obv'] | async"
                  [typeahead]="attr['account_code_typehead']"
                  [loading]="attr['account_code_loader']"
                  [clearable]="true"
                  (change)="selectAccountCode($event, attr, 'time_entry')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="name"
                  [bindValue]
                  placeholder="{{ 'account_code' | translate }}"
                  [(ngModel)]="attr['account_code']"
                  [id]="attr['type'] + 'account_code'"
                  [name]="attr['type'] + 'account_code'"
                  (open)="initRelateRecords('arTimeEntryAttributes', 'account_codes', 'account_code', arAccountCodeFilter, objCacheData['account_codes'], i)"
                  [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['account_code_id']) && isTouched && strInvoicingType != 'fixed_price_invoices'}">
                </ng-select>
              </div>
              <div class="draggable-table-box" style="width: 10%">
                <fc-currency-input
                  customClass="form-control font-size-12 input-height-40"
                  [(ngModel)]="attr['amount']"
                  (change)="onLineTotalChange($event.target, {attr: attr, index: i, module: 'time_entry'})"
                >
                </fc-currency-input>
              </div>
            </div>
            <div class="d-flex flex-row border border-top-0 bg-white">
              <div class="draggable-table-box center-items p-1" style="width: 8%;">
                <b>{{ 'technician' | translate }}</b>
              </div>
              <div class="draggable-table-box center-items" style="width: 34%;">
                <ng-select
                  class="h-40 w-100"
                  [items]="attr['user_obv'] | async"
                  [typeahead]="attr['user_typehead']"
                  [loading]="attr['user_loader']"
                  [clearable]="true"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="text"
                  [bindValue]
                  (change)="selectUser($event, attr)"
                  placeholder="{{ 'user' | translate }}"
                  [(ngModel)]="attr['user']"
                  [id]="attr['type'] + 'user'"
                  [name]="attr['type'] + 'user'"
                  (open)="initRelateData('users', 'arTimeEntryAttributes', 'user', i)"
                  [ngClass]="{'is-invalid': validateField(attr['time_entry']['user_id']) && isTouched && strInvoicingType != 'fixed_price_invoices' }">
                </ng-select>
              </div>
              <div class="draggable-table-box center-items p-1" style="width: 8%;">
                <b>{{ 'work_date' | translate }}</b>
              </div>
              <div class="draggable-table-box" style="width: 40%;">
                <div class="input-group">
                  <input
                    [owlDateTime]="dateTimeRangeField"
                    [selectMode]="'range'"
                    [(ngModel)]="attr['work_date']"
                    [ngModelOptions]="{standalone: true}"
                    (dateTimeChange)="selectWorkDate($event, attr)"
                    type="text"
                    class="form-control date-input"
                    [readonly]="attr['has_time_entries']"
                    [ngClass]="{'is-invalid': validateDateTime(attr['work_date']) && isTouched && strInvoicingType != 'fixed_price_invoices'}"
                    (ngModelChange)="markAsDirty()"
                    >

                  <span><fa-icon *ngIf="dateTimeRangeField && !attr['has_time_entries']" [icon]="['fas', 'times']" class="date-clear" (click)="clearField(attr)"></fa-icon></span>

                  <button
                    matTooltip="{{ 'work_date' | translate }}"
                    [disabled]="attr['has_time_entries']"
                    class="calendar-button"
                    [owlDateTimeTrigger]="(attr['has_time_entries']) ? '' : dateTimeRangeField">
                    <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                  </button>
                </div>

                <div>
                  <owl-date-time #dateTimeRangeField></owl-date-time>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-12 text-center" *ngIf="arTimeEntryAttributes.length < 1">
          <div class="d-flex flex-row border border-top-0 justify-content-center p-3">
            <small>{{ 'empty_time_entry_label' | translate }}</small>
          </div>
        </div>

        <div *ngIf="!bTimeEntryLoaded" class="col-12 text-center" style="font-size: 16px;">
          <div class="d-flex flex-row border border-top-0 justify-content-center p-3">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
          </div>
        </div>

      </div>
    </div>

  </div>
</ng-container>

<!-- Materials Field -->
<ng-container *ngIf="strInvoicingType === 'time_and_materials'">
  <div class="row mt-3">
    <div class="col-12">
      <label class="labels">
        {{ 'materials' | translate }}
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2">
      <browse-products
        (objProductSelected)="addMaterialEntryAttribute($event, data, true)"
        [bLabor]="false"
        [customerId]="getCurrentCustomerId()"
      ></browse-products>
    </div>
  </div>
  <div class="row">

    <div class="col-12">
      <div class="d-flex flex-row border">
        <div class="daggable-table-box-header" style="width: 8%"></div>
        <div class="draggable-table-box-header" [ngStyle]="{ width: bDepartmentTracking ? '14%' : '26%'}">
          <b>{{ 'notes' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 8%">
          <b>{{ 'quantity' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 8%">
          <b>{{ 'unit_cost' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 8%">
          <b>{{ 'unit_price' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 8%">
          <b>{{ 'markup' | translate }}</b>
        </div>
        <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'department' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'tax_code' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 12%">
          <b>{{ 'account_code' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 10%">
          <b>{{ 'total_price' | translate }}</b>
        </div>
      </div>
    </div>

    <div *ngIf="bMaterialLoaded" class="col-12">
      <div cdkDropList (cdkDropListDropped)="dropMaterial($event)" class="row">

        <ng-container *ngFor="let attr of arMaterialEntryAttributes; index as i">
          <div cdkDrag class="col-12">
            <div class="d-flex flex-row border border-top-0 bg-white">

              <div class="draggable-table-box center-items pr-2 pl-2" style="width: 2%;">
                <fa-icon
                  cdkDragHandle
                  style="cursor: grab;"
                  [icon]="['fas', 'grip-vertical']">
                </fa-icon>
              </div>

              <div class="draggable-table-box center-items" style="width: 6%;">
                <fa-icon
                  class="text-danger pointer"
                  (click)="removeAttribute(attr, 'material')"
                  [icon]="['fal', 'minus-circle']">
                </fa-icon>
                <a *ngIf="attr['material_entry']['type'] == 'product_catalog'"
                  href="/#/items/{{ attr['material_entry']['item_id'] }}"
                  class="pl-1 "
                  target="_blank">
                  <fa-icon
                    matTooltipClass="tooltip-space"
                    matTooltip="
                    {{ 'click_to_view' | translate }}&#13;&#13;
                    {{ 'product' | translate }}: {{ attr['item']['text'] }}&#13;
                    {{ 'code' | translate }}: {{ attr['customer_invoice']['item_code'] | placeholdWithString }}&#13;
                    {{ 'current_stock_level' | translate }}: {{ attr['current_stock_level'] }}"
                    class="text-primary"
                    [icon]="['fas', 'info-circle']">
                  </fa-icon>
                </a>
                <input
                  class="ml-1"
                  type="checkbox"
                  matTooltip="{{ 'exclude_from_invoice' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['material_entry']['exclude_from_invoice']"
                  (change)="excludeInvoiceChange($event, attr, 'material')"/>
              </div>

              <div class="draggable-table-box" [ngStyle]="{ width: bDepartmentTracking ? '14%' : '26%'}">
                <textarea
                  class="form-control material-desc-{{i}}" style="font-size: 12px;"
                  placeholder="{{ 'notes' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['material_entry']['notes']"
                  (focus)="increaseRow('material', i)"
                  (focusout)="decreaseRow('material', i)"
                  [ngClass]="{'is-invalid': validateField(attr['material_entry']['notes']) && isTouched}">
                </textarea>
              </div>
              <div *ngIf="attr['label'] === undefined" class="draggable-table-box" style="width: 8%">
                <fieldmagic-decimal-input
                  (change)="quantityChange($event, attr, 'material')"
                  customClass="form-control font-size-12 input-height-40"
                  placeholder="{{ 'quantity' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['material_entry']['quantity']">
                </fieldmagic-decimal-input>
              </div>
              <div *ngIf="attr['label'] === undefined" class="draggable-table-box" style="width: 8%">
                <fc-currency-input
                  [places]="4"
                  id="materials_unit_cost_{{ i }}"
                  [(ngModel)]="attr['material_entry']['unit_cost']"
                  [ngModelOptions]="{standalone: true}"
                  [customClass]="'form-control font-size-12 input-height-40'"
                  (change)="unitCostChange($event, attr)">
                </fc-currency-input>
              </div>
              <div *ngIf="attr['label'] === undefined" class="draggable-table-box" style="width: 8%">
                <fc-currency-input
                  [places]="4"
                  id="materials_unit_price_{{ i }}"
                  [(ngModel)]="attr['material_entry']['unit_price']"
                  [ngModelOptions]="{standalone: true}"
                  [customClass]="'form-control font-size-12 input-height-40'"
                  (change)="unitPriceChange($event, attr, 'material')">
                </fc-currency-input>
              </div>
              <div *ngIf="attr['label'] === undefined" class="draggable-table-box" style="width: 8%">
                <fieldmagic-decimal-input
                  (change)="markupChange($event, attr)"
                  step="0.01"
                  customClass="form-control font-size-12 input-height-40"
                  placeholder="{{ 'markup' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['material_entry']['markup']">
                </fieldmagic-decimal-input>
              </div>
              <div *ngIf="bDepartmentTracking" class="draggable-table-box" style="width: 12%">
                <ng-select
                  class="h-40"
                  [items]="attr['department_obv'] | async"
                  [typeahead]="attr['department_typehead']"
                  [loading]="attr['department_loader']"
                  [clearable]="true"
                  (change)="selectDepartment($event, attr, 'materials')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="text"
                  [bindValue]
                  placeholder="{{ 'select_department' | translate }}"
                  [(ngModel)]="attr['department']"
                  [id]="attr['type'] + 'department'"
                  [name]="attr['type'] + 'department'"
                  (open)="initRelateRecords('arMaterialEntryAttributes', 'departments', 'department', '', objCacheData['departments'], i)"
                  [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['department_id']) && isTouched}">
                </ng-select>
              </div>
              <div class="draggable-table-box" style="width: 12%">
                <ng-select
                  class="h-40"
                  [items]="attr['taxcode_obv'] | async"
                  [typeahead]="attr['taxcode_typehead']"
                  [loading]="attr['taxcode_loader']"
                  [clearable]="true"
                  (change)="selectATaxcode($event, attr, 'materials')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="text"
                  [bindValue]
                  placeholder="{{ 'tax_code' | translate }}"
                  [(ngModel)]="attr['tax']"
                  [id]="attr['type'] + 'tax_code'"
                  [name]="attr['type'] + 'tax_code'"
                  (open)="initRelateRecords('arMaterialEntryAttributes', 'tax_codes', 'taxcode', arTaxCodeFilter, objCacheData['tax_codes'], i)"
                  [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['tax_code_id']) && isTouched && strInvoicingType != 'fixed_price_invoices'}">
                </ng-select>
              </div>
              <div class="draggable-table-box" style="width: 12%">
                <ng-select
                  class="h-40"
                  [items]="attr['account_code_obv'] | async"
                  [typeahead]="attr['account_code_typehead']"
                  [loading]="attr['account_code_loader']"
                  [clearable]="true"
                  (change)="selectAccountCode($event, attr, 'materials')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="name"
                  [bindValue]
                  placeholder="{{ 'account_code' | translate }}"
                  [(ngModel)]="attr['account_code']"
                  [id]="attr['type'] + 'account_code'"
                  [name]="attr['type'] + 'account_code'"
                  (open)="initRelateRecords('arMaterialEntryAttributes', 'account_codes', 'account_code', arAccountCodeFilter, objCacheData['account_codes'], i)"
                  [ngClass]="{'is-invalid': validateField(attr['customer_invoice']['account_code_id']) && isTouched && strInvoicingType != 'fixed_price_invoices'}">
                </ng-select>
              </div>
              <div class="draggable-table-box" style="width: 10%">
                <fc-currency-input
                  customClass="form-control font-size-12 input-height-40"
                  [(ngModel)]="attr['total_price']"
                  (change)="onLineTotalChange($event.target, {attr: attr, index: i, module: 'material'})">
                </fc-currency-input>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-12 text-center" *ngIf="arMaterialEntryAttributes.length < 1">
          <div class="d-flex flex-row border border-top-0 justify-content-center p-3">
            <small>{{ 'empty_materials_label' | translate }}</small>
          </div>
        </div>

        <div *ngIf="!bMaterialLoaded" class="col-12 text-center" style="font-size: 16px;">
          <div class="d-flex flex-row border border-top-0 justify-content-center p-3">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
          </div>
        </div>

      </div>
    </div>

  </div>
</ng-container>

<!-- Flexible Invoice Field -->
<ng-container *ngIf="strInvoicingType != 'time_and_materials'">
  <div class="row">
    <div class="col-12">
      <label class="labels">
        {{ 'line_items' | translate }}
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2">
      <browse-products
        (objProductSelected)="addInvoiceLineAttribute($event, true)"
        [customerId]="getCurrentCustomerId()"
      ></browse-products>
    </div>
  </div>
  <div class="row">

    <div class="col-12">
      <div class="d-flex flex-row border">
        <div class="daggable-table-box-header" style="width: 8%"></div>
        <div class="draggable-table-box-header" [ngStyle]="{ width: bDepartmentTracking ? '23%' : '36%'}">
          <b>{{ 'description' | translate }}</b>
        </div>
        <div *ngIf="bDepartmentTracking" class="draggable-table-box-header" style="width: 13%">
          <b>{{ 'department' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 10%">
          <b>{{ 'quantity' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 10%">
          <b>{{ 'unit_price' | translate }}</b>
        </div>
        <div class="draggable-table-box-header" style="width: 13%">
          <b>{{ 'tax_code' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 13%">
          <b>{{ 'account_code' | translate }}<required-tag></required-tag></b>
        </div>
        <div class="draggable-table-box-header" style="width: 10%">
          <b>{{ 'amount' | translate }}</b>
        </div>
      </div>
    </div>

    <div *ngIf="bInvoiceLineLoaded" class="col-12">
      <div cdkDropList (cdkDropListDropped)="dropInvoice($event)" class="row">

        <ng-container *ngFor="let attr of arInvoiceLineAttributes; index as i">
          <div cdkDrag class="col-12">
            <div class="d-flex flex-row border border-top-0 bg-white">

              <div class="draggable-table-box center-items pr-2 pl-2" style="width: 2%;">
                <fa-icon
                  cdkDragHandle
                  style="cursor: grab;"
                  [icon]="['fas', 'grip-vertical']">
                </fa-icon>
              </div>

              <div class="draggable-table-box center-items" style="width: 6%;">

                <fa-icon *ngIf="arInvoiceLineAttributes.length > 1" (click)="removeAttribute(attr, 'invoice_line')"
                  class="pl-1 pr-1 pb-2 pt-2 text-danger pointer"
                  [icon]="['fal', 'minus-circle']">
                </fa-icon>

                <a *ngIf="attr['invoice_line']['item_id']"
                  href="/#/items/{{ attr['invoice_line']['item_id'] }}"
                  class="pl-1 pr-1 pb-2 pt-2"
                  target="_blank">
                  <fa-icon
                    matTooltipClass="tooltip-space"
                    matTooltip="
                    {{ 'click_to_view' | translate }}&#13;&#13;
                    {{ 'product' | translate }}: {{ attr['item']['text'] }}&#13;
                    {{ 'code' | translate }}: {{ attr['invoice_line']['item_code'] | placeholdWithString }}&#13;
                    {{ 'current_stock_level' | translate }}: {{ attr['current_stock_level'] }}"
                    class="text-primary"
                    [icon]="['fas', 'info-circle']">
                  </fa-icon>
                </a>

              </div>
              <div class="draggable-table-box" [ngStyle]="{ width: bDepartmentTracking ? '23%' : '36%'}">
                <textarea
                  class="form-control invoice-desc-{{i}}" style="font-size: 12px;"
                  placeholder="{{ 'description' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['invoice_line']['description']"
                  (focus)="increaseRow('invoice', i)"
                  (focusout)="decreaseRow('invoice', i); setLastIndex(i)"
                  (ngModelChange)="markAsDirty()">
                </textarea>
              </div>
              <div *ngIf="attr['label'] === undefined && bDepartmentTracking" class="draggable-table-box" style="width: 13%">
                <ng-select
                  class="h-40"
                  [items]="attr['department_obv'] | async"
                  [addTag]="addTag"
                  [typeahead]="attr['department_typehead']"
                  [loading]="attr['department_loader']"
                  [clearable]="true"
                  (change)="selectDepartment($event, attr, 'invoice')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="text"
                  [bindValue]
                  placeholder="{{ 'select_department' | translate }}"
                  [(ngModel)]="attr['department']"
                  [id]="attr['type'] + 'department'"
                  [name]="attr['type'] + 'department'"
                  (open)="initRelateRecords('arInvoiceLineAttributes', 'departments', 'department', '', objCacheData['departments'], i)"
                  (focusout)="setLastIndex(i)"
                  [ngClass]="{'is-invalid': validateField(attr['invoice_line']['department_id']) && isTouched}">
                </ng-select>
              </div>
              <div *ngIf="attr['label'] === undefined" class="draggable-table-box" style="width: 10%">
                <fieldmagic-decimal-input
                  (change)="quantityChange($event, attr, 'invoice_line')"
                  customClass="form-control font-size-12 input-height-40"
                  placeholder="{{ 'quantity' | translate }}"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="attr['invoice_line']['quantity']"
                  [disabled]="(strInvoicingType === 'fixed_price_invoices') ? true : false"
                  (focusout)="setLastIndex(i)"
                ></fieldmagic-decimal-input>
              </div>
              <div *ngIf="attr['label'] === undefined" class="draggable-table-box" style="width: 10%">
                <fc-currency-input
                  [places]="4"
                  id="invoice_unit_price_{{ i }}"
                  [(ngModel)]="attr['invoice_line']['unit_price']"
                  [ngModelOptions]="{standalone: true}"
                  [customClass]="'form-control font-size-12 input-height-40'"
                  (change)="unitPriceChange($event, attr, 'invoice_line')"
                  [isReadonly]="isFixedPriceType()"
                  (focusout)="setLastIndex(i)">
                </fc-currency-input>
              </div>
              <div class="draggable-table-box" style="width: 13%">
                <ng-select
                  class="h-40"
                  [items]="attr['taxcode_obv'] | async"
                  [typeahead]="attr['taxcode_typehead']"
                  [loading]="attr['taxcode_loader']"
                  [clearable]="true"
                  (change)="selectATaxcode($event, attr, 'invoice_line')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="text"
                  [bindValue]
                  placeholder="{{ 'tax_code' | translate }}"
                  [(ngModel)]="attr['tax']"
                  [id]="attr['type'] + 'tax_code'"
                  [name]="attr['type'] + 'tax_code'"
                  (open)="initRelateRecords('arInvoiceLineAttributes', 'tax_codes', 'taxcode', arTaxCodeFilter, objCacheData['tax_codes'], i)"
                  [ngClass]="{'is-invalid': validateField(attr['invoice_line']['tax_code_id']) && isTouched}"
                  (focusout)="setLastIndex(i)">
                </ng-select>
              </div>
              <div *ngIf="attr['label'] === undefined" class="draggable-table-box" style="width: 13%">
                <ng-select
                  class="h-40"
                  [items]="attr['account_code_obv'] | async"
                  [typeahead]="attr['account_code_typehead']"
                  [loading]="attr['account_code_loader']"
                  [clearable]="true"
                  (change)="selectAccountCode($event, attr, 'invoice_line')"
                  [ngModelOptions]="{standalone: true}"
                  bindLabel="name"
                  [bindValue]
                  placeholder="{{ 'account_code' | translate }}"
                  [(ngModel)]="attr['account_code']"
                  [id]="attr['type'] + 'account_code'"
                  [name]="attr['type'] + 'account_code'"
                  (open)="initRelateRecords('arInvoiceLineAttributes', 'account_codes', 'account_code', arAccountCodeFilter, objCacheData['account_codes'], i)"
                  [ngClass]="{'is-invalid': validateField(attr['invoice_line']['account_code_id']) && isTouched}"
                  (focusout)="setLastIndex(i)">
                </ng-select>
              </div>
              <div *ngIf="attr['label'] === undefined" class="draggable-table-box" style="width: 10%">
                <fc-currency-input
                  customClass="form-control font-size-12 input-height-40"
                  [(ngModel)]="attr['amount']"
                  (change)="onLineTotalChange($event.target, {attr: attr, index: i, module: 'invoice_line'})"
                  (focusout)="setLastIndex(i)"
                ></fc-currency-input>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-12 text-center" *ngIf="arInvoiceLineAttributes.length < 1">
          <div class="d-flex flex-row border border-top-0 justify-content-center p-3">
            <small>{{ 'empty_ci_label' | translate }}</small>
          </div>
        </div>

        <div *ngIf="!bInvoiceLineLoaded" class="col-12 text-center" style="font-size: 16px;">
          <div class="d-flex flex-row border border-top-0 justify-content-center p-3">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
          </div>
        </div>

      </div>
    </div>

  </div>
</ng-container>

<!-- Invoice Total Computations -->
<div  *ngIf="(bTimeEntryLoaded && bMaterialLoaded) || bInvoiceLineLoaded"  class="row mt-2">
  <div class="col-12">
    <div class="d-flex flex-row border">
      <div class="draggable-table-box right-items" style="width: 90%;">
        <span class="p-2">{{ 'total_ex_tax' | translate }}</span>
      </div>
      <div class="draggable-table-box left-items pl-2" style="width: 10%;">
        <span *ngIf="strInvoicingType === 'time_and_materials'" matTooltip="{{ computeExcludedTax() | currency }}">{{ computeExcludedTax() | currency: {decimal_places: 2} }}</span>
        <span *ngIf="strInvoicingType != 'time_and_materials'" matTooltip="{{ computeInvoiceLineExcludedTax() | currency }}">{{ computeInvoiceLineExcludedTax() | currency: {decimal_places: 2} }}</span>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="d-flex flex-row border border-top-0">
      <div class="draggable-table-box right-items" style="width: 90%;">
        <span class="p-2  float-right">{{ 'tax' | translate }}</span>
      </div>
      <div class="draggable-table-box left-items pl-2" style="width: 10%;">
        <span *ngIf="strInvoicingType === 'time_and_materials'" matTooltip="{{ computeTax() | currency }}">{{ computeTax() | currency: {decimal_places: 2} }}</span>
        <span *ngIf="strInvoicingType != 'time_and_materials'" matTooltip="{{ computeInvoiceLineTax() | currency }}">{{ computeInvoiceLineTax() | currency: {decimal_places: 2} }}</span>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="d-flex flex-row border border-top-0">
      <div class="draggable-table-box right-items" style="width: 90%;">
        <span class="p-2 float-right">{{ 'tax_adjustment_amount' | translate }}</span>
      </div>
      <div class="draggable-table-box center-items" style="width: 10%;">
        <fc-currency-input
          step="0.01"
          [withControls]="true"
          customClass="form-control font-size-12 input-height-40"
          [(ngModel)]="totalTaxAdjustment"
          (change)="onTaxAdjustment()"
        ></fc-currency-input>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="d-flex flex-row border border-top-0">
      <div class="draggable-table-box right-items" style="width: 90%;">
        <span class="p-2  float-right">{{ 'total_inc_tax' | translate }}</span>
      </div>
      <div class="draggable-table-box center-items" style="width: 10%;">
        <span *ngIf="strInvoicingType === 'time_and_materials'">
          <fc-currency-input
            id="includedTaxField"
            [(ngModel)]="includedTax"
            customClass="form-control input-height-40 font-size-12">
          </fc-currency-input>
        </span>
        <span *ngIf="strInvoicingType != 'time_and_materials'">
          <fc-currency-input
            id="invoiceLineIncludedTaxField"
            [(ngModel)]="invoiceLineIncludedTax"
            customClass="form-control input-height-40 font-size-12">
          </fc-currency-input>
        </span>
      </div>
    </div>
  </div>
</div>

</ng-template>

</ng-container>

<ng-template #createCustomerInvoiceDisabled>
  <span id="btnCreateCustomerInvoiceDisabled" class="disabled"
    matTooltip="{{ 'forbidden_action_error' | translate }}">
    {{ 'forbidden_action_error' | translate }}
  </span>
</ng-template>
