
    <div class="input-icon">
      <input
        type="number"
        min="0"
        [max]="max"
        step="0.1"
        class="{{ customClass }}"
        [ngClass]="{'is-invalid': invalid}"
        [placeholder]="placeholder"
        [disabled]="isDisabled$ | async"
        [(ngModel)]="value"
        (change)="onChange($event.target.value)"
        (focus)="onTouched()"
        (keypress)="onKeyPress($event)"
        [readonly]="isReadonly"
      />
    </div>
  