import { Observable } from 'rxjs';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

import { Notification } from '../../../objects/notification';
import { UserTypeModule } from '../../../lists/user-type-module';
import { RecordService } from '../../../services/record.service';
import { ListingService } from '../../../services/listing.service';
import { environment } from '../../../../environments/environment';
import { AccountService } from '../../../services/account.service';
import { LayoutDashboardService } from './layout-dashboard.service';
import { ChargebeeService } from '../../../services/chargebee/chargebee.service';
import { BrowserService } from '../../../services/browser/browser.service';
import { ClientStoreService } from '../../../services/client-store.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { GlobalSubscriptionUpdateButtonState } from '../../../services/chargebee/chargebee.service';
import { ForceupdateComponent } from './../../../shared/components/forceupdate/forceupdate.component';
import { ClientsListStoreService } from '../../../services/clients-list-store/clients-list-store.service';
import { SearchService } from '../../../services/search.service';
import { get, isEmpty, padStart } from 'lodash';
import { ManageSubscriptionComponent } from '../../../admin/chargebee/dialogs/manage-subscription/manage-subscription.component';

import "amazon-connect-streams";
import { AcsSupportService } from '../../../module/acs-support/acs-support.service';
import { take } from 'rxjs/operators';
import { DialogComponent } from '../../../account/client-info/dialog/dialog.component';
import { LooseObject } from '../../../objects/loose-object';

@Component({
  selector: 'app-layout-dashboard',
  templateUrl: './layout-dashboard.component.html',
  styleUrls: ['./layout-dashboard.component.scss'],
  providers: [
    ListingService,
    RecordService,
    TranslateService,
  ]
})
export class LayoutDashboardComponent {

  @ViewChild("acsContainer") acsContainer: ElementRef;

  public bAmazonConnectStreamInitialized = false;
  public loading: boolean =  false;
  public anySearch: any;
  public strPlaceHolder: string = "";
  public arSearchablePages = [
    "leads", "customers", "sites",
    "contacts", "opportunities", "jobs",
    "assets","customer_invoices", "purchase_orders",
    "supplier_invoices"
  ];
  public arSearchablePagesAdmin = [
    "admin/teams", "admin/resources", "admin/users", "admin/departments"
  ];

  public arRelatesTo = [
    {
      id: 'sites',
      text: 'sites',
    },
    {
      id: 'customers',
      text: 'customers',
    },
    {
      id: 'contacts',
      text: 'contacts',
    },
  ]

  public userTypeList = new UserTypeModule();
  public arModules: any = [];
  public arNavigation: any = [];
  public objCurrentNotification: { [key: string]: Notification } = {};
  public arSearch: Observable<any[]>;
  public searchForm: FormGroup;
  public strUserImage = null;
  public bLoadingIcon = false;
  public isInternetExplorer6to11: boolean = this.browserService.isInternetExplorer6to11();
  public strCurrentLocale: string;

  public arSearchableModules: string[] = [
    'all',
    'leads',
    'sites',
    'customers',
    'jobs',
    'contacts',
    'purchase_orders',
    'opportunities',
    'assets',
    'supplier_invoices',
    'customer_invoices',
  ];

  public arAdminModules: string[] = [
    'users',
  ];

  arCurrentSupportRoute: string[];
  objCurrentAgent: connect.Agent;

  /**
   * Shortened property name for the otherwise longer
   * chargebeeService::globalSubscriptionUpdateButtonState$
   *
   * @type {Observable<GlobalSubscriptionUpdateButtonState>}
   */
  public sub$: Observable<GlobalSubscriptionUpdateButtonState>;

  constructor(
    public layoutDashboardService: LayoutDashboardService,
    public translate: TranslateService,
    public listingService: ListingService,
    public clientStoreService: ClientStoreService,
    public clientsListStoreService: ClientsListStoreService,
    private notifService: NotificationService,
    private recordService: RecordService,
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    public chargebeeService: ChargebeeService,
    private browserService: BrowserService,
    private searchService: SearchService,
    private acsSupportService: AcsSupportService
  ) {
      router.events.subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event)
        this.checkPlaceHolderChange();
      });

      var arActiveClient = this.clientStoreService.getActiveClient()

      // Make sure we don't proceed if we dont have level.
      if (arActiveClient['level'] == undefined || arActiveClient['level'] == null || arActiveClient['level'] == '') {
        this.router.navigate(['/account/selection']);
      }

      // Get client level
      var strClientLevel = arActiveClient['level'];
      var arUsersModules: Array<any> = this.userTypeList.moduleList[strClientLevel] || [];

      // Store module list
      if (arUsersModules.length > 0) {
        arUsersModules.forEach(element => {
          this.arModules[element] = true;
        });
      }

      // Get current client
      this.arNavigation = arActiveClient['config'];
      // Addition validation if config has empty value
      if (!this.arNavigation) this.arNavigation = {'sites': false, 'assets': false};

      // Do we have name?
      let strUserName = this.localStorageService.getItem('user_name');
      if (strUserName == undefined || strUserName == '' || strUserName == null || strUserName.length === 0) {
        this.openUpdateDialog({ module: 'users'})
      };

      // Initialize the search form.
      this.searchForm = new FormGroup({
        'search': new FormControl(''),
        'module': new FormControl('all')
      })

      this.arSearch = this.searchForm.get('search').valueChanges
        .filter(term => term)
        //Wait 400ms before in between searches.
        .debounceTime(400)
        //Execute search only when the current value is different than the last.
        .distinctUntilChanged()
        //Bind the values fetch from api to a proper object.
        .switchMap(term => {
          this.bLoadingIcon = true;
          return this.searchService.global(term, this.searchForm.get('module').value);
        })
        ._do( _ => {
          this.bLoadingIcon = false;
        })
        .catch((error) => {
          this.bLoadingIcon = false;
          return Observable.throw(error);
        })

      if (this.localStorageService.getItem('temp_image') != null) {
        this.saveImage(this.localStorageService.getItem('temp_image')).subscribe(response => {
          if (response) {
            this.localStorageService.setItem("image", this.localStorageService.getItem("temp_image"));
            this.localStorageService.removeItem("temp_image");
            this.strUserImage = this.localStorageService.getItem('image');
          }
        });
      } else {
        if (this.localStorageService.getItem('image') != null || this.localStorageService.getItem('image') != '') {
          this.strUserImage = this.localStorageService.getItem('image');
        }
      }

      this.sub$ = this.chargebeeService.globalSubscriptionUpdateButtonState$;
      this.strCurrentLocale = this.localStorageService.getItem("user_locale");
    }

  ngOnInit() {

    this.acsSupportService.getConfig().pipe(take(1)).subscribe((objConfig) => {
      this.layoutDashboardService.acsConfig = objConfig;
    });

    this.layoutDashboardService.acsStatus.subscribe((enable) => {
      if (!enable && this.bAmazonConnectStreamInitialized) {
        connect.core.terminate();
        this.acsContainer.nativeElement.removeChild(this.acsContainer.nativeElement.firstChild);
        this.bAmazonConnectStreamInitialized = false;
      }
    });

    this.initializeHubspotApi();
  }

  /**
   * Save image.
   * @param strImageUrl
   */
  public saveImage(strImageUrl) {
    let body = new URLSearchParams();
    body.append('id', this.localStorageService.getItem('user_id'));
    body.append('data', JSON.stringify({'image': strImageUrl}));
    body.append('module', 'users');

    return this.http.post<Response>(environment.url + '/account/update_profile', body.toString());
  }

  public boolClosed : boolean = true;
  // Switches language based on user preference
  // @param | strSelectedLocale - string code of the locale
  switchLanguage(strSelectedLocale: string) {
    // Gets supported languages that was set in the app component
    let arAvailableLanguage = this.translate.getLangs();
    // Check if language is supported
    if (arAvailableLanguage.includes(strSelectedLocale)) {
      let strUserId = this.localStorageService.getItem('user_id');
      // Change current language used
      this.translate.use(strSelectedLocale);
      // Set default app language to use as a fallback
      this.translate.setDefaultLang(strSelectedLocale);
      // Update Locale Label
      this.strCurrentLocale = strSelectedLocale;
      // User record service to save user locale
      this.recordService.saveRecord('users', { locale : strSelectedLocale }, strUserId).subscribe();
      // Set new user locale to local storage
      this.localStorageService.setItem("user_locale", strSelectedLocale);
      this.boolClosed = false;

      this.setAgentLanguage(strSelectedLocale);
    }
  }

  setAgentLanguage(strSelectedLocale: string): void {
    const supportedLocales = {
      es: 'es_ES',
      en: 'en_US',
      // For locales not supported by the ACS
      default: 'en_US',
    };

    if (this.objCurrentAgent === null || this.objCurrentAgent === undefined) {
      return;
    }

    const config = {
      ...this.objCurrentAgent.getConfiguration(),
      agentPreferences: {
        locale: supportedLocales[strSelectedLocale] !== undefined ? supportedLocales[strSelectedLocale] : supportedLocales.default,
      }
    };

    this.objCurrentAgent.setConfiguration(config, {
      success: () => {
        console.log(`Agent locale updated to ${strSelectedLocale}`);
      },
      failure: (err) => {
        console.warn('failed to set config', err);
      }
    });
  }

  checkPlaceHolderChange(){

    let strLink = this.router.url.slice(1);

    if (this.arSearchablePages.includes(strLink)) {
      this.strPlaceHolder = strLink;
    } else if (this.arSearchablePagesAdmin.includes(strLink)) {
      this.strPlaceHolder = strLink.substring(6);
    }
    else {
      this.strPlaceHolder = 'here';
    }
  }

  /**
   * If the client is still on trial, they can begin choosing for
   * the plan of their choice of if already on a plan, they can
   * decide for an upgrade or downgrade.
   *
   * @returns {void}
   */
  openSubscriptionSelectionPage(): void {
    this.dialog.open(ManageSubscriptionComponent, {
      data: this.clientStoreService.getActiveClient()
    });
  }

  /**
   * Hides the global subscription update link from the user's view.
   * However, a page reload will cause it to appear again.
   *
   * @returns {void}
   */
  hideGlobalSubscriptionUpdateLink(): void {
    this.chargebeeService.toggleGlobalSubscriptionUpdateDisplay$.next(false);
  }

  /**
   * When the user clicks/hits enter to a search result.
   * @param objResult
   */
  onSubmit(objResult) {
    // Redirects them to the proper page.
    const navigateTo = [objResult['module'], objResult['id']];
    if (this.arAdminModules.includes(objResult['module'])) {
      navigateTo.unshift('admin');
    }
    this.router.navigate(navigateTo);
  }

  /**
   * Properly formats the search results.
   * @param strName
   * @param strModule
   */
  formatName(strName: string, strModule: string, results: LooseObject = {}) {
    // Modules that should have a hashtag at the front.
    let arNumberSign = ['assets', 'purchase_orders'];
    // Modules that should have a hashtag and leadings zeroes at the front.
    let arLeadingZeroes = ['opportunities', 'jobs', 'customer_invoices', 'supplier_invoices'];

    // If the result should have a hashtag at the front.
    if (arNumberSign.indexOf(strModule) != -1) {
      // Add the hashtag to the string.
      return '#' + strName;
    }
    // If the result should have a hashtag and leadiing zero.
    else if (arLeadingZeroes.indexOf(strModule) != -1) {
      let isNumber = /^\d+$/.test(strName);

      // Return the string with the hashtags and leading zeroes.
      return (isNumber) ? '#' + padStart(strName, 6, '0') : strName;
    } else {
      // If the name is on the other properties
      let otherName = get(results, ['other_properties', 'names'], null);
      if (isEmpty(strName) && otherName !== null) {
        strName = otherName;
      }
      // If there's no special format needed, just return it.
      return strName;
    }
  }

  mouseover() {
    this.boolClosed = true;
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }

  /*
  * Function to get button display text (If has_button == true)
  *
  * @param strTitle - title of the notification
  */
  getNotifButtonTextDisplay(strTitle) {
    let strButtonTextDisplay = '';

    // Switch what button your notification needs
    switch (strTitle) {
      case 'demo_mode' :
        strButtonTextDisplay = 'Remove'
        break;
    }
    return strButtonTextDisplay;
  }

  // Force update
  openUpdateDialog(data) {

    let forceUpdateDialog = this.dialog.open( ForceupdateComponent, {
      width: '500px',
      height: 'auto',
      data: data,
      disableClose: true
    });

    forceUpdateDialog.afterClosed().subscribe(
      results => {
        this.notifService.sendConfirmationAnswer(results);
      }
    )
  }

  public emptySearch() {
      this.searchForm.patchValue({
        'search': ""
      });
  }

  /**
   * Redirects the user back to the client selection page.
   *
   * @returns {void}
   */
  switchClient(): void {
    this.layoutDashboardService.switchClient();
  }

  /**
   * Toggle the Amazon Connect Stream window
   *
   * @return  {void}    Hides/Show the window
   */
  toggleSupportWindow(): void {
    this.acsSupportService.getConfig().pipe(take(1)).subscribe((objConfig) => {
      this.layoutDashboardService.acsConfig = objConfig;
      if (!objConfig.ccp_url) {
        this.notifService.notifyWarning('ccp_url_not_configured_error.message');
        return;
      } else if (objConfig.enable === false) {
        this.notifService.notifyWarning('omnichannel_not_enabled.message');
        return;
      }

      if (this.bAmazonConnectStreamInitialized === false) {
        connect.core.initCCP(this.acsContainer.nativeElement, {
          ccpUrl: objConfig.ccp_url,
          loginPopup: true,
          loginPopupAutoClose: true,
          region: "eu-west-1",
          pageOptions: {
            enableAudioDeviceSettings: true,
          },
          softphone: {
            allowFramedSoftphone: true,
          },
        });

        this.bAmazonConnectStreamInitialized = true;
        this.layoutDashboardService.bSupportWindowMinimize = false;

        connect.agent(currentAgent => {
          this.objCurrentAgent = currentAgent;
          this.setAgentLanguage(this.translate.getDefaultLang());
          currentAgent.onRefresh(agent => {
            console.log({'TestFM Agent Event: onRefresh': agent});
          });
          currentAgent.onStateChange(agent => {
            console.log({'TestFM Agent Event: onStateChange': agent});
          });
          currentAgent.onRoutable(agent => {
            console.log({'TestFM Agent Event: onRoutable': agent});
          });
          currentAgent.onNotRoutable(agent => {
            console.log({'TestFM Agent Event: onNotRoutable': agent});
          });
          currentAgent.onOffline(agent => {
            console.log({'TestFM Agent Event: onOffline': agent});
          });
          currentAgent.onError(agent => {
            console.log({'TestFM Agent Event: onError': agent});
          });
          currentAgent.onSoftphoneError(agent => {
            console.log({'TestFM Agent Event: onSoftphoneError': agent});
          });
          currentAgent.onAfterCallWork(agent => {
            console.log({'TestFM Agent Event: onAfterCallWork': agent});
          });
        });

        connect.contact(currentContact => {
          currentContact.onRefresh(contact => {
            console.log({'TestFM Contact Event: onRefresh': contact});
          });
          currentContact.onIncoming(contact => {
            console.log({'TestFM Contact Event: onIncoming': contact});
          });
          currentContact.onPending(contact => {
            console.log({'TestFM Contact Event: onPending': contact});
          });
          currentContact.onConnecting(contact => {
            console.log({'TestFM Contact Event: onConnecting': contact});
          });
          currentContact.onAccepted(contact => {
            console.log({'TestFM Contact Event: onAccepted': contact});
          });
          currentContact.onMissed(contact => {
            console.log({'TestFM Contact Event: onMissed': contact});
          });
          currentContact.onEnded(contact => {
            // Called after finishing the call but not closing contact yet
            this.layoutDashboardService.bAfterCall = true;
          });
          currentContact.onDestroy(contact => {
            // Called after finishing the call and closing the contact
            this.layoutDashboardService.bCallInit = false;
            this.layoutDashboardService.bAfterCall = false;
            this.layoutDashboardService.objSelectedRecord = null;
          });
          currentContact.onACW(contact => {
            console.log({'TestFM Contact Event: onACW': contact});
          });
          currentContact.onConnected(contact => {
            console.log({'TestFM Contact Event: onConnected': contact});
            // Called when connected to incoming call/chat.
            this.layoutDashboardService.bCallInit = true;
            this.layoutDashboardService.bAfterCall = false;
            const chatAttributes = contact.getAttributes();
            if (chatAttributes['customerName'] !== undefined) {
              this.arCurrentSupportRoute = ['support', chatAttributes['customerName'].value];
              this.gotoSupportPage();
            } else {
              const callAttribute = contact.getConnections()[1].getEndpoint();
              this.arCurrentSupportRoute = ['support', callAttribute['phoneNumber']];
              this.gotoSupportPage();
            }
          });
          currentContact.onError(contact => {
            console.log({'TestFM Contact Event: onError': contact});
          });
        });
      } else {
        // Terminate ACS
        connect.core.terminate();
        this.acsContainer.nativeElement.removeChild(this.acsContainer.nativeElement.firstChild);
        this.bAmazonConnectStreamInitialized = false;
        this.objCurrentAgent = null;
      }
    });
  }

  gotoSupportPage(): void {
    this.router.navigate([...this.arCurrentSupportRoute, 't', Date.now().toString()]);
  }

  openClientInfo() {
    this.dialog.open(DialogComponent, {
      width: '30%',
      height: 'auto',
      data: {client: this.clientStoreService.getActiveClient()},
      disableClose: true
    });
  }

  /**
   * Only set the token when the conversation widget is ready.
   *
   * @return {void}
   */
  onConversationReady(): void {
    if (this.localStorageService.getItem('hubspot_token')) {
      window['hsConversationsSettings'] = {
        loadImmediately: environment.production,
        identificationEmail: this.localStorageService.getItem('user_email'),
        identificationToken: this.localStorageService.getItem('hubspot_token')
      };
     if (window['HubSpotConversations']) {
      window['HubSpotConversations'].widget.load();
     }
    }
  }

  /**
   * Initialize the hubspot widget along with its API.
   *
   * @return {void}
   */
  private initializeHubspotApi(): void {
    if (window['HubSpotConversations']) {
      this.onConversationReady();
    } else {
      window['hsConversationsOnReady'] = [
        this.onConversationReady()
      ];
    }
  }
}
