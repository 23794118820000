import { Address } from './address';

export class Users {

  id?: string;
  email_address?: string;
  nickname?: string;
  first_name?: string;
  last_name?: string;
  locale?: string;
  department?: string[];
  mobile_config?: string[];
  phone?: any;
  security?: string[];
  address?: Address;
  level?: string;
  name?: string;

  constructor(properties: {
    id?: string,
    email_address?: string,
    nickname?: string,
    first_name?: string,
    last_name?: string,
    locale?: string,
    department?: any,
    mobile_config?: string[],
    phone?: any,
    security?: string[],
    address?: Address,
    level?: string,
    name?: string
  }) {
    this.id = properties.id;
    this.email_address = properties.email_address;
    this.nickname = properties.nickname;
    this.first_name = properties.first_name;
    this.last_name = properties.last_name;
    this.locale = properties.locale;
    this.department = properties.department;
    this.mobile_config = properties.mobile_config;
    this.phone = properties.phone;
    this.security = properties.security;
    this.address = properties.address;
    this.level = properties.level;
    this.name = properties.name;
  }
}