<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <div>
          <fa-icon [icon]="['far', 'arrow-alt-circle-right']" transform="shrink-3" class="dialog-title"></fa-icon>
        {{ 'convert_opportunity' | translate }}
      </div>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button *ngIf="data['reference'] == 'widget'" (click)="skipConversion()" class="btn btn-warning mr-2 skip-button" [disabled]="bDisabled">
            <fa-icon [icon]="['far', 'fast-forward']"></fa-icon>
            <span class="pl-1">{{ 'skip_conversion' | translate }}</span>
          </button>
          <button type="button" (click)="cancelDialog()" class="btn btn-primary mr-2">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
          <button class="btn btn-primary float-right mr-2" (click)="convertJob()" [disabled]="bDisabled">
            <fa-icon [icon]="['far', 'arrow-alt-circle-right']"></fa-icon>
            <span class="pl-1">{{ 'convert_job' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>
<mat-dialog-content>
  <div *ngIf="!bLoading">
    <div class="row mb-3">
      <ng-container *ngFor="let quote of arFields; index as i">
          <ng-container *ngIf="objQuotesField[quote] != undefined && objQuotesField[quote]['custom'] == undefined">

            <div *ngIf="
              !(quote == 'project_template' && quoteForm.controls['type'].value != 'project') &&
              !(quote == 'po_number' && (quoteForm.controls['configure_inclusion'].value != 'po_only' && quoteForm.controls['configure_inclusion'].value != 'wo_and_po')) &&
              !(quote == 'work_order_number' && (quoteForm.controls['configure_inclusion'].value != 'wo_only' && quoteForm.controls['configure_inclusion'].value != 'wo_and_po'))" 
              class="col-6 mt-2">
                <app-edit-field
                  [item]="objQuotesField[quote]" 
                  [form]="quoteForm" 
                  [bSubmitted]="bSubmitted" 
                  strMode="add" 
                  [autoSelectResult]="true"
                  [strModule]=""
                  (parentEvent)="checkQuoteIfEmpty($event)">
                </app-edit-field>
            </div>

            <ng-container *ngIf="quote == 'configure_inclusion'" >
              <div class="col-6 mt-2" ></div>
              <div class="col-12 mt-4" >
                <mat-divider></mat-divider>
              </div>
            </ng-container>

        </ng-container>
      </ng-container>
    </div>
    <hr />
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <fa-icon *ngIf="bLoading" [icon]="['fas','spinner']" [spin]="true" aria-hidden="true" class="pr-1"></fa-icon>
    </div>
  </div>
</mat-dialog-content>