<header>
  <div class="d-flex">
    <!-- title -->
    <h3 class="title"><fa-icon [icon]="['fas', 'file-alt']" class="mr-3"></fa-icon>{{ 'form_template_custom_field_instruction' | translate }}</h3>

    <!-- actions -->
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <!-- cancel -->
        <li class="list-inline-item">
          <button type="button" class="btn ml-1 btn-primary" (click)="closeDialog()" id="close-btn">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
              <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <!-- /cancel -->

        <!-- preview -->
        <li class="list-inline-item">
          <button type="button"
            class="btn ml-1 btn-primary" (click)="previewTemplate()" id="preview-btn">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
            <span class="pl-1">{{ 'preview_template' | translate }}</span>
          </button>
        </li>
        <!-- /preview -->
      </ul>
    </div>
  </div>
</header>

<!-- seperator -->
<hr class="seperator"/>
<!-- /seperator -->

<!-- dialog content -->
<mat-dialog-content class="mobile-view-dialog">
  <ng-container *ngFor="let field of fields">
    <div class="form-group">
      <label for="{{ field.name }}" class="control-label">{{ field.label }}</label>
      <ng-container [ngSwitch]="field.type">
        <input [id]="field.name" type="text" class="form-control" [formControl]="field.control" *ngSwitchCase="'text'"/>
        <input [id]="field.name" type="number" class="form-control" [formControl]="field.control" *ngSwitchCase="'number'"/>
        <div class="input-group" *ngSwitchCase="'date'">
          <input
            [id]="field.name"
            type="text"
            class="form-control date-input"
            [formControl]="field.control"
            [owlDateTime]="dateField"
          >
          <button class="calendar-button" [owlDateTimeTrigger]="dateField">
            <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
          </button>
          <owl-date-time id="calendar-{{ field.name }}" #dateField [pickerType]="'calendar'"></owl-date-time>
        </div>
        <div class="input-group" *ngSwitchCase="'datetime'">
          <input
            [id]="field.name"
            type="text"
            class="form-control date-input"
            [formControl]="field.control"
            [owlDateTime]="dateTimeField"
          >
          <button class="calendar-button" [owlDateTimeTrigger]="dateTimeField">
            <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
          </button>
          <owl-date-time id="datetime-{{ field.name }}" #dateTimeField></owl-date-time>
        </div>
      </ng-container>
    </div>
  </ng-container>
</mat-dialog-content>
<!-- /dialog content -->
