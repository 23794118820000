<div class="row">
  <div class="col-sm-12">
    <span class="title" translate>
      <fa-icon [icon]="['fas', 'users']" ></fa-icon>
      &nbsp; {{ 'manage_email_thread_participants'| translate }}
    </span>

    <span class="buttons">
      <button type="button" (click)="save()" [disabled]="(! form.dirty || ! form.valid) || isProcessing" class="btn ml-1 btn-primary">
        <fa-icon *ngIf="! isProcessing" [icon]="['fas', 'save']"></fa-icon>
        <fa-icon *ngIf="isProcessing" [icon]="['fas','spinner']" [spin]="true" aria-hidden="true" class="mr-1"></fa-icon>
        <span class="pl-1">{{ 'save' | translate }}</span>
      </button>
      <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
        <span class="pl-1">{{ 'close' | translate }}</span>
      </button>
    </span>
  </div>
</div>

<hr class="separator"/>

<form [formGroup]="form">
  <div class="row col-12 mb-20">
    <span class="col-md-9 text-center">{{ 'email_address' | translate }}</span>
    <span class="col-md-3 text-center">{{ 'subscribed' | translate }}</span>
  </div>

  <div class="col-12">
    <ng-container formArrayName="participants" *ngFor="let control of participantsControls.controls; let i = index;">
      <div class="row col-12 mb-10" [formGroupName]="i">
        <span class="col-md-9">
          <ng-container *ngIf="participants[i].emailAddress.name">
            {{ participants[i].emailAddress.name }} <{{ participants[i].emailAddress.email }}>
          </ng-container>

          <ng-container *ngIf="! participants[i].emailAddress.name">
            {{ participants[i].emailAddress.email }}
          </ng-container>
        </span>
        <span class="col-md-3 text-center">
          <input type="checkbox" formControlName="isSubscribed">
          <input type="hidden" formControlName="emailAddress">
        </span>
      </div>
    </ng-container>
  </div>
</form>
