import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';

import { Select } from '../../../../objects/select';

import { isEmpty, cloneDeep } from 'lodash';
import { FormService } from '../../../../services/form.service';

@Component({
  selector: 'app-editjson',
  templateUrl: './editjson.component.html',
  styleUrls: ['./editjson.component.scss']
})
export class EditjsonComponent implements OnInit {

  @Input() strModule: any = '';
  @Input() objConfig: any = {};
  @Input() strMode: any = {};
  @Output() jsonChange = new EventEmitter<any>();

  public objJsonForm: FormGroup;
  public objJsonFormGroup: any = null;

  public objJsonFormat: any = {};
  public objJsonFormatGroup: any = {};
  public objJsonFormatView = {
    asset_types: [
      ['type', ''],
      ['type'],
      ['type']
    ]
  };
  public arJsonAttributes: any = [];
  public strCustomClass: any = '';
  public intId : any = 1;

  public objOptions: Object = {
    asset_types: {
      option: ['number', 'dropdown', 'text', 'date']
    }
  };

  public arReadonlyFields = [];

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService
  ) { }

  /**
   * First thing we do is generate the form group
   *
   * @return void
   */
  ngOnInit(): void {

    this.generateGroup();
    this.objJsonForm = this.formBuilder.group({
      objAttributes: this.formBuilder.array([])
    });
    if (this.strMode == 'edit' && !isEmpty(this.objConfig.default_value)) {

      let arValues = this.objConfig.default_value;
      arValues.forEach( element => {
        this.addAttributes(element);
      });
    }

    // Trigger when there a changes on form
    this.objJsonForm.statusChanges.subscribe( result => {

      let objErrors = {};
      let objFormArray = this.objJsonForm.controls['objAttributes'];
      // Loop each form group
      objFormArray['controls'].forEach( objFormGroup => {

        let intError = 0;
        // Store a single form grouop
        let objRecordFromGroup = objFormGroup.controls;
        // Loop each form group field
        Object.keys(objRecordFromGroup).forEach( strFieldId => {

          if (strFieldId && objRecordFromGroup[strFieldId].errors) { intError++; }
        });

        // Do we have errors? Store the form group id
        if (intError) { objErrors[objRecordFromGroup.id.value] = true; }
      });

      let arJsonEmit: any = [];
      let arJsonValues = this.objJsonForm.getRawValue().objAttributes;
      arJsonValues.forEach( element => {

        let objData = element;
        if (objData.id) {

          let strRecordKey = objData.id;
          let arNewData = {};
          Object.keys(objData).forEach( strDataKey => {

            let arKey = strDataKey.split('.');
            // We make sure that we have length of two, combination of field name and id.
            // We make sure that the field is not readonly
            if (arKey.length == 2 && arKey[1] == strRecordKey && this.arReadonlyFields[arKey[0]] === undefined) {
              arNewData[arKey[0]] = element[strDataKey];
            }
          });

          // If record key is does not exist on error list
          if (typeof objErrors[strRecordKey] === 'undefined') { arJsonEmit.push(arNewData) }
        }
      });

      this.jsonChange.emit(arJsonEmit);
    });
  }

  /**
   * Generate the group using the format from the field config
   * Store the created group so that we can reuse it in adding a new field.
   *
   * @return void
   */
  generateGroup(): void {

    // Do we have format in field config?
    if (!isEmpty(this.objConfig.format)) {

      // let objGroup = {};
      Object.keys(this.objConfig.format).forEach( element => {

        let objField = this.objConfig.format[element];
        objField.label = '';
        objField.default_value = '';
        objField.key = element;
        objField.validator = (objField.required) ? Validators.required : '';
        // If the field is readonly, put it in the readonly variable
        if (objField.readonly == true) { this.arReadonlyFields[element] = true; }
        this.objJsonFormat[element] = this.formService.tranformFieldObject(objField);
      });
    }
  }

  /**
   * Return the created form group with cloneDeep, so that the every group has different instance
   *
   * @param object default value for form group
   *
   * @return object
   */
  generateAttribute(objValues = {}) {

    let objGroup = {};
    let intRandom = Math.random().toString(36).substr(2, 9);;
    let objJsonFormat = cloneDeep(this.objJsonFormat);
    this.objJsonFormatGroup[intRandom] = {}
    Object.keys(objJsonFormat).forEach( element => {

      let strDefaultValue = (typeof objValues[element] !== 'undefined') ? objValues[element] : '';
      let objField = objJsonFormat[element];
      let strUniqueKey = objField.key+ '.' +intRandom;

      objField.key = strUniqueKey;
      this.objJsonFormatGroup[intRandom][strUniqueKey] = objField;
      objGroup[strUniqueKey] = [
        (strDefaultValue) ? strDefaultValue : objField.default_value,
        (objField.validator) ? [objField.validator] : []
      ];
    });

    objGroup['id'] = [intRandom];
    let objJsonFormGroup = this.formBuilder.group(objGroup);

    return objJsonFormGroup;
  }

  /**
   * Add attribute
   *
   * @param objValues Generate attributes with default values
   *
   * @return void
   */
  addAttributes(objValues = {}): void {

    let objControl = <FormArray>this.objJsonForm.controls['objAttributes'];
    objControl.push(this.generateAttribute(objValues));
  }

  /**
   * Remove item based on submitted object key
   *
   * @param strFormKey key of the selected form
   * @param strAttributeKey key of the selected attribute
   *
   * @return void
   */
  deleteAttributes(strFormKey, strAttributeKey): void {

    const arrayControl = <FormArray>this.objJsonForm.controls['objAttributes'];
    arrayControl.removeAt(strFormKey);

    delete this.objJsonFormatGroup[strAttributeKey];
  }

  /**
   * Auto fill a field based on another field
   *
   * @param strValue value
   * @param strField field name
   * @param intForm current form
   *
   * @return void
   */
  autofill(strValue, strField, intForm): void {

    if (!isEmpty(strValue)) {

      const arrayControl = <FormArray>this.objJsonForm.controls['objAttributes'];
      if (!isEmpty(arrayControl.controls[intForm])) {

        arrayControl.controls[intForm].patchValue({
          [strField]: strValue.replace(/ /g, '_').toLowerCase()
        });
      }
    }
  }
}