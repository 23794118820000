<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title">
      <fa-icon [icon]="['fal', 'tasks']"></fa-icon>&nbsp;
      <span>{{ strCustomerName }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" class="btn ml-1 btn-outline-primary" (click)="cancelDialog()">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>
<mat-dialog-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- Tab buttons -->
        <div class="row">
          <div class="col-12">
            <nav class="pb-3">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a *ngIf="dialogData.job"
                  [ngClass]="{'active': activeTab == 'job_info'}"
                  class="nav-item nav-link noradius text-primary pointer record-labels" id="jobInfoTab"
                  (click)="switchTab('job_info')">
                  <fa-icon [icon]="['fas', 'briefcase']"></fa-icon> {{ 'job_details' | translate }}
                </a>
                <a *ngIf="dialogData.opportunity"
                  [ngClass]="{'active': activeTab == 'quote_info'}"
                  class="nav-item nav-link noradius text-primary pointer record-labels" id="quoteInfoTab"
                  (click)="switchTab('quote_info')">
                  <fa-icon [icon]="['fas', 'briefcase']"></fa-icon> {{ 'quote_details' | translate }}
                </a>
                <a [ngClass]="{'active': activeTab == 'task_list'}"
                  class="nav-item nav-link noradius text-primary pointer record-labels" id="taskListTab"
                  (click)="switchTab('task_list')">
                  <fa-icon [icon]="['fal', 'tasks']"></fa-icon> {{ 'tasks' | translate }}
                </a>
                <a [ngClass]="{'active': activeTab == 'checklist'}"
                  class="nav-item nav-link noradius text-primary pointer record-labels" id="checklistListTab"
                  (click)="switchTab('checklist')">
                  <fa-icon [icon]="['fal', 'tasks']"></fa-icon> {{ 'checklist' | translate }}
                </a>
              </div>
            </nav>
          </div>
        </div>
        <!-- Job Details Tab -->
        <div class="row record-container" *ngIf="activeTab == 'job_info' && !bEditMode && !bUpsertTaskMode">
          <div class="col-12" *ngIf="(jobDetails$ | async)?.record_details as details; else loading">
            <ng-container *ngIf="(taskDetails$ | async)?.record_details as details">
              <div class="row">
                <div class="col-12">
                  <h5>{{ 'current_task' | translate }}</h5>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-12 border ml-1 mb-4 mt-2 p-3" style="font-size: 0.75rem;">
                  <div class="pointer">
                    <b>{{ (!dialogData.job.customer?.id ? 'no_customer' : strCustomerName) | translate  }}</b>: {{ details.activity_name | truncate }}
                  </div>
                  <div><b>{{ 'job_due_date' | translate }}</b>: {{ details.due_date ? formatDate(details.due_date, details.activity_type) : '' }}</div>
                  <div><b>{{ 'address' | translate }}</b>: {{ dialogData.job.full_address }}</div>
                  <div><b>{{ 'assigned_user' | translate }}</b>: {{ details.user_text }}</div>
                  <span class="pull-right">
                    <span class="pointer ml-4 font-weight-bold" *ngIf="details.reschedulable" (click)="scheduleTask(details)">{{ (details.task_progress === 'awaiting_scheduling' ? 'schedule' : 'reschedule') | translate }}</span>
                    <span class="pointer ml-4 font-weight-bold" *ngIf="shouldShowDuplicate(details)" (click)="duplicateEvent(details)">{{ 'duplicate' | translate }}</span>
                    <span class="pointer ml-4 font-weight-bold" *ngIf="details.editable" (click)="openUpsertTaskView(details)">{{ 'edit' | translate }}</span>
                    <span class="pointer ml-4 font-weight-bold text-danger" *ngIf="details.cancelable_schedule" (click)="unscheduleEvent(details)">{{ 'unschedule' | translate }}</span>
                    <span class="text-success ml-4 font-weight-bold" *ngIf="details.task_progress === 'complete'">{{ 'complete' | translate }}</span>
                  </span>
                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="col-9">
                <h5>{{ 'job_details' | translate }}</h5>
              </div>
              <div class="col-3 text-right p-0">
                <button *ngIf="details.editable"  id="editJobBtn" class="btn btn-outline-primary btn-block" (click)="setEditMode(true)">
                  {{ 'edit' | translate }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'job_number' | translate }}</label>
                  <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                    <ng-container
                      *ngTemplateOutlet="objRecordDetails.id ? jobLink : noLinkRelate; context: {
                        id: objRecordDetails.id,
                        text: details.job_number
                      }">
                    </ng-container>
                    <ng-template #jobLink let-id="id" let-text="text">
                      <a class="pointer" id="jobNumber" href="{{ createUrl('jobs', id) }}">{{ text | placeholdWithString }}</a>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'opportunity' | translate }}</label>
                  <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                    <ng-container
                      *ngTemplateOutlet="objRecordDetails.opportunity_id ? quoteLink : noLinkRelate; context: {
                        id: objRecordDetails.opportunity_id,
                        text: objRecordDetails.opportunity_text
                      }">
                    </ng-container>
                    <ng-template #quoteLink let-id="id" let-text="text">
                      <a class="pointer" id="jobQuote" href="{{ createUrl('opportunities', id) }}">{{ text | placeholdWithString }}</a>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'customer' | translate }}</label>
                  <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts h-100">
                    <ng-container
                      *ngTemplateOutlet="details.customer_is_viewable ? customerLink : noLinkRelate; context: {
                        id: details.viewable_customer_id,
                        text: details.customer_text
                      }"
                    ></ng-container>
                    <ng-template #customerLink let-id="id" let-text="text">
                      <a class="pointer" id="jobCustomer" href="{{ createUrl('customers', id) }}">{{ text | placeholdWithString }}</a>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'site_address' | translate }}</label>
                  <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text h-100">
                    <ng-container
                      *ngTemplateOutlet="details.site_is_viewable ? siteLink : noLinkRelate; context: {
                        id: details.site_id,
                        text: details.site_text
                      }"
                    ></ng-container>
                    <ng-template #siteLink let-id="id" let-text="text">
                      <a class="pointer" id="jobSite" href=" {{ createUrl('sites', id) }}">{{ text | placeholdWithString }}</a>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'date_completed' | translate }}</label>
                  <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                    <span>
                      {{ details.date_completed | date:'mediumDate' | placeholdWithString }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'status' | translate }}</label>
                  <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                    <span>
                      {{ details.status | translate | placeholdWithString }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'priority' | translate }}</label>
                  <span class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                    {{ details.priority | translate | placeholdWithString }}
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'job_summary' | translate }}</label>
                  <span class="form-control-plaintext form-control-sm pl-2 record-texts border-bottom long-text"
                    [innerHTML]="details.job_summary | placeholdWithString">
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'job_type' | translate }}</label>
                  <span class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                    {{ details.type | translate | placeholdWithString }}
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="record-labels">{{ 'invoicing_type' | translate }}</label>
                  <span class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
                    {{ details.invoicing_type | translate | placeholdWithString }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Edit Job -->
        <div class="row record-container"
          *ngIf="activeTab == 'job_info' && bEditMode && (jobDetails$ | async)?.record_details as details">
          <task-details-job-edit (jobFormSave)="onDetailsEditClose($event)" [jobDetails]="details"></task-details-job-edit>
        </div>
        <!-- Edit Quote -->
        <div class="row record-container"
          *ngIf="activeTab == 'quote_info' && bEditMode && (opportunityDetails$ | async)?.record_details as details">
          <task-details-quote-edit (quoteFormSave)="onDetailsEditClose($event)" [quoteDetails]="details"></task-details-quote-edit>
        </div>
        <!-- Quote Details Tab -->
        <div class="row record-container" *ngIf="activeTab == 'quote_info' && !bEditMode && !bUpsertTaskMode">
          <div class="col-12" *ngIf="(opportunityDetails$ | async)?.record_details as details; else loading">
            <ng-container *ngIf="(taskDetails$ | async)?.record_details as details">
              <div class="row">
                <div class="col-12">
                  <h5>{{ 'current_task' | translate }}</h5>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-12 border ml-1 mb-4 mt-2 p-3" style="font-size: 0.75rem;">
                  <div class="pointer">
                    <b>{{ dialogData.opportunity.customer.name }}</b>: {{ details.activity_name | truncate }}
                  </div>
                  <div><b>{{ 'due_date' | translate }}</b>: {{ details.due_date ? formatDate(details.due_date, details.activity_type) : '' }}</div>
                  <div><b>{{ 'address' | translate }}</b>: {{ dialogData.opportunity.full_address }}</div>
                  <div><b>{{ 'assigned_user' | translate }}</b>: {{ details.user_text }}</div>
                  <span class="pull-right">
                    <span class="pointer ml-4 font-weight-bold" *ngIf="details.reschedulable" (click)="scheduleTask(details)">{{ (details.task_progress === 'awaiting_scheduling' ? 'schedule' : 'reschedule') | translate }}</span>
                    <span class="pointer ml-4 font-weight-bold" *ngIf="shouldShowDuplicate(details)" (click)="duplicateEvent(details)">{{ 'duplicate' | translate }}</span>
                    <span class="pointer ml-4 font-weight-bold" *ngIf="details.editable" (click)="openUpsertTaskView(details)">{{ 'edit' | translate }}</span>
                    <span class="pointer ml-4 font-weight-bold text-danger" *ngIf="details.cancelable_schedule" (click)="unscheduleEvent(details)">{{ 'unschedule' | translate }}</span>
                    <span class="text-success ml-4 font-weight-bold" *ngIf="details.task_progress === 'complete'">{{ 'complete' | translate }}</span>
                  </span>
                </div>
              </div>
            </ng-container>
            <quote-details [quoteDetails]="details" [bIsBehalfOfChildClient]="isBehalfOfChildClient" (quoteFormEdit)="setEditMode($event)"></quote-details>
          </div>
        </div>
        <!-- Task List -->
        <div class="row record-container" *ngIf="activeTab == 'task_list' && !bUpsertTaskMode">
          <div class="col-12" *ngIf="(tasks$ | async) as tasks; else loading">
            <div class="row mb-3">
              <div class="col-12">
                <span class="pull-right">
                  <button class="btn btn-primary" *ngIf="! isBehalfOfChildClient" (click)="openUpsertTaskView()">
                    {{ 'create_task' | translate }}
                  </button>
                </span>
                <h5>{{ 'tasks' | translate }}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <span>
                  <small *ngIf="tasks.total_records">
                    {{ 'list_view_summary' | translate: {fromRecords: tasks.from_records, toRecords: tasks.to_records, totalRecords: tasks.total_records} }}
                  </small>
                  <small *ngIf="!tasks.total_records">{{ 'no_record_found' | translate }}</small>
                </span>
              </div>
              <div class="col-6">
                <div *ngIf="tasks.total_records" class="pagination-section">
                  <ul class="pagination float-right">
                    <li class="page-item" [ngClass]="tasks.current_page === 1 ? 'disabled' : ''">
                      <a id="jobSchedulerTaskListsPageOne" class="page-link" aria-label="first" (click)="initTasks(1)">
                        <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
                      </a>
                    </li>
                    <li class="page-item" [ngClass]="tasks.current_page === 1 ? 'disabled' : ''">
                      <a id="jobSchedulerTaskListsPreviousPage" class="page-link" aria-label="previous" (click)="initTasks(tasks.current_page - 1)">
                        <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                      </a>
                    </li>
                    <li class="page-item" [ngClass]="tasks.current_page === tasks.last_page ? 'disabled' : ''">
                      <a id="jobSchedulerTaskListsNextPage" class="page-link" aria-label="next" (click)="initTasks(tasks.current_page + 1)">
                        <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                      </a>
                    </li>
                    <li class="page-item" [ngClass]="tasks.current_page === tasks.last_page ? 'disabled' : ''">
                      <a id="jobSchedulerTaskListsLastPage" class="page-link" aria-label="last" (click)="initTasks(tasks.last_page)">
                        <fa-icon [icon]="['far', 'chevron-double-right']" aria-hidden="true"></fa-icon>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row" *ngFor="let task of tasks?.data">
              <div class="col-12 border ml-1 mb-2 mt-2 p-3" style="font-size: 0.75rem;">
                <div class="pointer"><b>{{ task.activity_type | translate}}</b>: {{ task.activity_name | truncate }}</div>
                <div>
                  <b>{{ 'due_date' | translate }}</b>:
                  {{ task.due_date ? formatDate(task.due_date, task.activity_type) : '' }}
                </div>
                <div><b>{{ 'address' | translate }}</b>: {{ strModule === 'jobs' ? dialogData.job.full_address : dialogData.opportunity.full_address }}</div>
                <div><b>{{ 'assigned_user' | translate }}</b>: {{ task.user_text }}</div>
                <span class="pull-right">
                  <span class="pointer ml-4 font-weight-bold" *ngIf="task.reschedulable" (click)="scheduleTask(task)">{{ (task.task_progress === 'awaiting_scheduling' ? 'schedule' : 'reschedule') | translate }}</span>
                  <span class="pointer ml-4 font-weight-bold" *ngIf="shouldShowDuplicate(task)" (click)="duplicateEvent(task)">{{ 'duplicate' | translate }}</span>
                  <span class="pointer ml-4 font-weight-bold" *ngIf="task.editable" (click)="openUpsertTaskView(task)">{{ 'edit' | translate }}</span>
                  <span class="pointer ml-4 font-weight-bold text-danger" *ngIf="task.cancelable_schedule" (click)="unscheduleEvent(task)">{{ 'unschedule' | translate }}</span>
                  <span class="text-success ml-4 font-weight-bold" *ngIf="task.task_progress === 'complete'">{{ 'complete' | translate }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row record-container" *ngIf="bUpsertTaskMode">
          <div class="col-12">
            <task-upsert [taskDetails]="selectedTask" [strTaskParentModule]="strModule" (taskFormSave)="onUpsertTaskClose($event)"></task-upsert>
          </div>
        </div>
        <!-- Checklist List -->
        <div class="row record-container" *ngIf="activeTab == 'checklist' && !bLinkChecklist">
          <div class="col-12" *ngIf="(checklistList$ | async) as checklistList ; else loading">
            <div class="row">
              <div class="col-6">
                <span class="pointer" (click)="openLinkChecklist()">
                  <small>
                    <fa-icon [icon]="['fas', 'link']" class="text-success pr-1"></fa-icon>{{ 'link_checklist' | translate }}
                  </small>
                </span>
              </div>
              <div class="col-6">
                  <nav class="pagination-section">
                    <ul class="pagination">
                      <refresh-pagination-btn id="widgetChecklistsRefreshButton" (refresh)="initChecklistList()"></refresh-pagination-btn>
                      <li [ngClass]="(objChecklistPagination.first != objChecklistPagination.current && objChecklistPagination.current != '') ? 'active' : 'disabled'" class="page-item">
                        <a id="widgetChecklistsPreviousButton" class="page-link" aria-label="first" (click)="initChecklistList('prev')">
                          <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                        </a>
                      </li>
                      <li [ngClass]="(objChecklistPagination.next != '') ? 'active' : 'disabled'" class="page-item">
                        <a id="widgetChecklistsNextButton" class="page-link" aria-label="first" (click)="initChecklistList('next')">
                          <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                        </a>
                      </li>
                    </ul>
                  </nav>
              </div>
            </div>
            <div class="row" *ngFor="let linkedChecklist of checklistList?.data">
              <div class="col-12 border ml-1 mb-2 mt-2 p-3" style="font-size: 0.75rem;">
                <div class="pointer">
                  <b>{{ linkedChecklist.name }}</b>
                </div>
                <div><b>{{ 'type' | translate }}</b>: <span translate >{{ getChecklistType(linkedChecklist.type) | translate }}</span></div>
                <div *ngIf="linkedChecklist.period"><b>{{ 'inspection_period' | translate }}</b>: {{ linkedChecklist.period }}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="bLinkChecklist">
          <calendar-link-checklist
            [strJobId]="dialogData.job?.id"
            [strOpportunityId]="dialogData.opportunity?.id"
            [strModule]="strModule"
            (parentEventEmitter)="closeLinkChecklist($event)">
          </calendar-link-checklist>
        </div>
        <!-- Others -->
      </div>
    </div>
  </div>
</mat-dialog-content>

<ng-template #loading>
  <span class="no-content text-center mt-3">
    <inline-view-loader [isLoading]="true"></inline-view-loader>
  </span>
</ng-template>

<ng-template #noActivities>
  <span id="noActivitiesFound" class="no-content no-record-found">
    {{ 'no_record_found' | translate }}
  </span>
</ng-template>

<ng-template #noLinkRelate let-text="text">
  <span>{{ text | placeholdWithString }}</span>
</ng-template>