import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Response } from '@angular/http';
import { Observable, Subject } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

import 'rxjs/add/operator/map';
import { TranslateService } from '@ngx-translate/core';

import { Field } from '../objects/field';
import { Config } from 'protractor';

const kBaseUrl: string = environment.url + "/admin_configure/";

@Injectable()
export class ConfigurationService {

  constructor (private http: HttpClient, private router: Router, public translate: TranslateService) {}

  /**
   * Saves the increment fields.
   *
   * @param objSaveRecord - See objects/layout.ts
   */
  saveAdminConfig(objSaveRecord): Observable<HttpResponse<Config>> {
    let arFormEncode = new URLSearchParams();
    arFormEncode.append('data', JSON.stringify(objSaveRecord));
    return this.http.post<Response>(kBaseUrl+ 'create_record', arFormEncode, { observe: 'response' });
  }
  /**
   * Get Max Value of the field
   * for a given module.
   */
  getAdminConfig(): Observable<Response>{
    return this.http.post<Response>(kBaseUrl + "get_admin_config", {});
  }
}
