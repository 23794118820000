import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Response } from '@angular/http';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Get the base url from the environment file.
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Checklist, ChecklistPromptGroup } from '../objects/checklist';
import { get, isEmpty } from 'lodash';
import { Guid } from 'guid-typescript';
import { ChecklistResponse, ChecklistResponsePrompt } from '../objects/checklis-response';
import { RecordService } from './record.service';
import { ListingService } from './listing.service';

const kBaseUrl: string = environment.url + "/checklists/";

@Injectable()
export class ChecklistsService {

  protected checklistResponseData: ChecklistResponse = {};
  protected checklistResponseError: string = null;

  constructor (
    private http: HttpClient,
    private router: Router,
    private recordService: RecordService,
    private listingService: ListingService,
  ) {}

  /**
   * Fetch all fields from metadata
   */
  getFieldEntries(isAssetType = false) {
      let body = new URLSearchParams();
      body.append('is_asset_type', JSON.stringify(isAssetType));

      return this.http.post<Response>(kBaseUrl + "field_entries", body.toString());
  }

  /**
   * Fetch all Record of Related tables in checklist (jobs, opportunities, sites, asset types)
   */
  getRelatedRecords(arRecordIds, strModule: string = 'jobs') {

      let body = new URLSearchParams();
      body.append('record_ids', arRecordIds);
      body.append('module', strModule);

      return this.http.post<Response>(kBaseUrl + "related_records", body.toString());
  }

  /**
   * Saves answered response of checklist
   */
  saveChecklistResponse(arRecord, arResponse, strSaveType, strType, arFaults = null) {

    let body = new URLSearchParams();
    body.append('record', arRecord);
    body.append('response', arResponse);
    body.append('save_type', strSaveType);
    body.append('faults', arFaults);
    body.append('checklist_type', strType);

    return this.http.post<Response>(kBaseUrl + "save_response", body.toString(), { observe: 'response' });
  }

  /**
   * Gets all checklist responses (job checklist and asset checklist) given the Job Id
   *
   * @param {string} strJobId
   */
  getJobRelatedRecords(strJobId: string) {
    let body = new URLSearchParams();
    body.append('job_id', strJobId);

    return this.http.post<Response>(kBaseUrl + "get_job_related_records", body.toString(), { observe: 'response' });
  }

  /**
   * Gets all quote checklist responses given the Opportunity Id
   *
   * @param {string} strOpportunityId
   */
  getOpportunityRelatedRecords(strOpportunityId: string) {
    let body = new URLSearchParams();
    body.append('oportunity_id', strOpportunityId);

    return this.http.post<Response>(kBaseUrl + "get_opportunity_related_records", body.toString(), { observe: 'response' });
  }

  /**
   * generate checklist response payload
   *
   * @param {Checklist} checklist
   * @param {string[]} checklistPeriod
   * @param {string} strRecordId
   * @param {string} strModule
   *
   * @returns {Promise<boolean>}
   */
  generateChecklistResponse(checklist: Checklist, checklistPeriod: string[] = [], strRecordId: string, strModule: string): Promise<boolean> {
    return new Promise( (resolve, reject) => {
        let strChecklistType: string = get(checklist, 'type', 'job');
        let strFilterKey: string = strChecklistType === 'opportunity' ? 'opportunity_id' : 'job_id';

        if (strChecklistType === 'asset' && strModule === 'jobs') {
          strFilterKey = 'job_id';
        }

        let objModuleFilter: object = { [strFilterKey]: strRecordId };

        // Check if there's already linked checklist response with same asset type or if that checklist response itself is already linked
        let objAdditionFilter = (checklist.type === 'asset') ? { asset_type_id: checklist.asset_type_id } : { checklist_id: checklist.id };
        this.listingService.fetchData('', 'checklist_responses', JSON.stringify({ ...objModuleFilter, ...objAdditionFilter })).subscribe( response => {

        if (!isEmpty(response['data'])) {
          this.checklistResponseError = (response['data'][0].checklist_id == checklist.id) ? 'checklist_already_linked' : 'checklist_of_same_asset_already_linked';
          reject(false);
        }

        let isValidPeriod = true;
        let intEmptyPromptCounter = 0;
        let objChecklistResponse = {
          checklist_id: checklist.id,
          [strFilterKey]: strRecordId,
          status: 'awaiting_completion',
          name: get(checklist, 'name', ''),
          questions: !isEmpty(checklist['questions']) ? checklist['questions'] : '[]',
          response: '[]',
          type: strChecklistType
        };

        let arPrompt = objChecklistResponse.questions.length > 0 ? JSON.parse(objChecklistResponse.questions) : objChecklistResponse.questions;

        // Get number of empty prompts
        // Checks if asset type checklist has no empty per_asset prompts
        // Also check for jobs checklists to restrict empty main prompts
        arPrompt.forEach( prompt => {
          if ((prompt['name'] === 'main_prompts' && objChecklistResponse['type'] !== 'asset') ||
            (prompt['name'] !== 'main_prompts' && objChecklistResponse['type'] == 'asset')) {
            if (prompt['prompts'].length === 0) {

                intEmptyPromptCounter++;
            }
          }
        });

        // If either per asset prompt and main prompt is empty, prompt an error
        if (intEmptyPromptCounter === 0) {

          let arDefaultImagePrompt = {
            id : Guid.create().toString(),
            is_required : false,
            is_visible_in_reports : true,
            prompt : 'Add Image/s',
            type : 'image',
            value : {
              image : []
            },
            schedule_type : {
              monthly : true,
              quarterly : true,
              bi_annual : true,
              annual : true,
              every_2_years : true,
              every_3_years : true,
              every_4_years : true,
              every_5_years : true,
              every_10_years: true,
              weekly : true,
              one_day: true,
              non_recurring: true
            }
          };

          // Checks if asset type checklist has no empty per_asset prompts
          // Also check for jobs checklists to restrict empty main prompts
          // Have the image prompt in all checklist type
          for (let intCounter = 0; intCounter < arPrompt.length; intCounter++) {
              if ((arPrompt[intCounter]['name'] === 'main_prompts' && objChecklistResponse['type'] !== 'asset') ||
                  (arPrompt[intCounter]['name'] !== 'main_prompts' && objChecklistResponse['type'] == 'asset')) {
                  let intPromptsLength = arPrompt[intCounter]['prompts'].length;

                  // Check if image is the last prompt type
                  if (arPrompt[intCounter]['prompts'][intPromptsLength - 1]['type'] != 'image') {
                      arPrompt[intCounter]['prompts'].push(arDefaultImagePrompt)
                  }
              }
          }

          // If asset type, append the asset type id
          if (objChecklistResponse.type === 'asset') {
            // If period is not valid, return false and dont go to processing of questions per period
            if (checklistPeriod.length === 0) {

              isValidPeriod = false;
            } else {
              // Loop through all prompts and extract all prompts with same period chosen
              for (let intPromptType = 0; arPrompt.length > intPromptType; intPromptType++) {

                let arPromptsTemp = [];
                let arPromptTempId = [];

                for (let intPromptCtr = 0; arPrompt[intPromptType]['prompts'].length > intPromptCtr; intPromptCtr++) {
                  checklistPeriod.forEach( period => {
                    let bIsGroup: boolean = arPrompt[intPromptType]['prompts'][intPromptCtr]['is_group'] || false;

                    if (bIsGroup) {
                      let arGroupPromptsTemp = [];
                      let objPromptGroup: ChecklistPromptGroup = arPrompt[intPromptType]['prompts'][intPromptCtr];

                      objPromptGroup.prompts.forEach(prompt => {
                        this.initTempAssetPrompts(arGroupPromptsTemp, arPromptTempId, prompt, period);
                      });

                      if (!arPromptTempId.includes(objPromptGroup['id'])) {
                        objPromptGroup.prompts = arGroupPromptsTemp;
                        arPromptsTemp.push(objPromptGroup);
                      }

                      arPromptTempId.push(objPromptGroup['id']);
                    } else {
                      this.initTempAssetPrompts(arPromptsTemp, arPromptTempId, arPrompt[intPromptType]['prompts'][intPromptCtr], period);
                    }
                  });
                }

                // Replace values of current prompts to array with prompts of same period
                arPrompt[intPromptType]['prompts'] = arPromptsTemp;
              }

              // Assign additional values for fields in asset type checklist
              objChecklistResponse['period'] = JSON.stringify(checklistPeriod);
              objChecklistResponse['asset_type_id'] = checklist.asset_type_id;
            }
          }

          // If checklist has name question and if period is valid
          if (objChecklistResponse.name !== "" && isValidPeriod) {
            if (isValidPeriod) {

              // Append the prompts in the checklist
              objChecklistResponse.questions = JSON.stringify(arPrompt);
              this.checklistResponseData = objChecklistResponse;
              resolve(true);
            } else {
              this.checklistResponseError = 'invalid_period';
            }
          } else {
            this.checklistResponseError = 'invalid_checklist';
          }
        } else {
          this.checklistResponseError = 'empty_prompt';
        }

        resolve(false);
      });
    });
  }

  /**
   * Replace values of current prompts to array with prompts of same period.
   *
   * @param {ChecklistResponsePrompt[]} arAssetPrompts
   * @param {string[]} arPromptIds
   * @param {ChecklistResponsePrompt} objAssetPrompt
   * @param {string} strScheduleType
   *
   * @returns {void}
   */
  initTempAssetPrompts(
    arAssetPrompts: ChecklistResponsePrompt[],
    arPromptIds: string[],
    objAssetPrompt: ChecklistResponsePrompt,
    strScheduleType: string,
  ): void {
    // If it belongs to periods that are selected
    if (objAssetPrompt['schedule_type'][strScheduleType]) {
      // Check if prompt is already added in new list by checking it's GUID
      if (!arPromptIds.includes(objAssetPrompt['id'])) {
        // Push it in a temporary array
        arAssetPrompts.push(objAssetPrompt);
        arPromptIds.push(objAssetPrompt['id']);
      }
    }
  }

  /**
   * get checklist response error message when generating payload
   */
  getChecklistResponseError(): string {
    return this.checklistResponseError;
  }

  /**
   * get checklist response payload
   */
  getChecklistResponseData() {
    return this.checklistResponseData;
  }
}
