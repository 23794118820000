import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "computeAmount"
})
export class LineItemsAmountPipe implements PipeTransform {

  constructor() {}

  /**
   * Returns the total line amount (without tax).
   * 
   * @param objLineItem 
   */
  transform(objLineItem: { 
    quantity: number, 
    unit_price?: number | undefined, 
    unit_cost?: number | undefined 
  }) {

    let quantity = objLineItem.quantity || 0;
    let price = objLineItem.unit_price || objLineItem.unit_cost || 0;

    return quantity * price;

  }
}