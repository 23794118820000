import { Checklist } from './checklist';
import { Department } from './department';
import { Relate } from './relate';
import { Users } from './users';

export class TaskLineItem {
  /**
   * Estimated Duration for the current
   * line item
   */
  estimated_duration: number;

  /**
   * Set of id and name of the selected
   * checklist
   */
  checklist: Relate<Checklist>;

  /**
   * Department for the current
   * line item.
   */
  department: Relate<Department>;

  /**
   * User for the current
   * line item.
   */
  user: Relate<Users>;

  /**
   * Description for the task.
   */
  task_description: string;

  /**
   * Reference if the line item
   * is from work order
   */
  work_order_reference: string;

  /**
   * Identifier if from work order
   */
  from_work_order: boolean;

  getTaskData() {
    let arChecklistIds = this.getChecklistIds();

    return {
      'estimated_duration': this.estimated_duration,
      'checklist_ids': arChecklistIds,
      'department_id': this.department.value ? this.department.value.id : '',
      'user_id': this.user.value ? this.user.value.id : '',
      'task_description': this.task_description,
      'from_work_order': this.from_work_order,
      'work_order_reference': this.work_order_reference,
    }
  }

  getChecklistIds() {
    let arChecklistIds: Array<string> = [];

    if (this.checklist.value) {
      arChecklistIds = Object.values(this.checklist.value).map(objChecklist => {
        return objChecklist.id;
      });
    }

    return arChecklistIds;
  }

  constructor(properties: {
    estimated_duration?: number
    department?: Relate<Department>
    user?: Relate<Users>
    checklist?: Relate<Checklist>
    task_description?: string
    from_work_order?: boolean
    work_order_reference?: string
  } = {}){
    this.estimated_duration = properties.estimated_duration;
    this.department = properties.department;
    this.user = properties.user;
    this.checklist = properties.checklist;
    this.task_description = properties.task_description;
    this.from_work_order = properties.from_work_order;
    this.work_order_reference = properties.work_order_reference;
  }
}