import { Observable } from "rxjs";
import { EventEmitter } from '@angular/core';

export class Pagination<T> {

    /**
     * Default page value for the stock items.
     *
     * @var {number}
     */
    page_number = 10;

    /**
     * Loading indicator when the list to paginate
     * is being loaded.
     *
     * @var {boolean}
     */
    loader: boolean = true;

    /**
     * Contains the list to p[aginate.
     *
     * @var {T}
     */
    list: T[] = [];

    /**
     * If we are using an API to fill the list,
     * this flag identifies whether there is still
     * a next page.
     *
     * @var {boolean}
     */
    has_next?: boolean = null;

    /**
     * This property should be a function that returns the API
     * results with already mapped  returns.
     *
     * @var {(page: any) => Observable<{raw_data: any, data: T[], next: boolean}>}
     */
    page_request: (page: any) => Observable<{raw_data: any, data: T[], next: boolean}>;

    /**
     * Simple holder for the next and previous page for the
     * pagination.
     *
     * @var {next_page: string, previous_page: string}
     */
    page_data?: {next_page: string, previous_page: string} = null;

    /**
     * Which page is currently being shown to
     * the user.
     *
     * @var {number}
     */
    current_page: number = 1;

    /**
     * Holder to indicate which place the
     * items being shown begins with.
     *
     * @var {number}
     */
    start_index: number = 0;

    /**
     * Holder to indicate the end of the item
     * placement that is currently being shown.
     *
     * @var {number}
     */
    end_index: number = this.page_number;

    pageChanged: EventEmitter<boolean> = new EventEmitter();

    /**
     * A modified version of numShowStartList
     * but more presentable to the user.
     *
     * @return {number}
     */
    get showFrom(): number {
        return (this.list.length > 0) ? this.start_index + 1 : 0;
    }

    /**
     * A modified version of numShowEndList
     * but more presentable to the user.
     *
     * @return {number}
     */
    get showTo(): number {
        return (this.list.length < this.page_number) ? this.list.length : this.end_index;
    }

    /**
     * A method to identify if the previous
     * button should be disabled.
     *
     * @return {boolean}
     */
    get disablePreviousButton(): boolean {
        return this.start_index === 0;
    }

    /**
     * A method to identify if the start
     * button should be disabled.
     *
     * @return {boolean}
     */
    get disableStartButton(): boolean {
        return this.current_page === 1;
    }

    /**
     * Same as above but for the the
     * next button.
     *
     * @return {boolean}
     */
    get disableNextButton(): boolean {
        if (this.has_next === true) {
            return false;
        } else {
            if (this.list.length < this.page_number || this.list.length <= this.end_index) {
                return true;
            } else {
                return false;
            }
        }
    }

    constructor(numItemPerPage: number = 10) {
        this.page_number = numItemPerPage;
    }

    /**
     * When previous page icon is clicked,
     * deduct 1 to the current page and change the
     * end index to the start page index and then deduct
     * the start page to the proper index.
     *
     * @returns {void}
     */
    onPreviousPage(): void {
        if (!this.disablePreviousButton) {
            this.current_page = this.current_page - 1;
            this.end_index = this.start_index;
            this.start_index = this.start_index - this.page_number;
            this.pageChanged.next(true);
        }
    }

    /**
     * When next page is hit, add a plus ten to
     * start and end indexes, and if the end index
     * exceeds the length of the array, revert it
     * to its proper index limit.
     *
     * @returns {void}
     */
    onNextPage(): void {
        if (!this.disableNextButton) {
            if (this.has_next === true && this.end_index === this.list.length) {
                this.triggerPageObservable('next');
            } else {
                this.nextPageComputation();
            }
            this.pageChanged.next(true);
        }
    }

    /**
     * When start page is clicked, should redirect
     * to initial page.
     *
     * @returns {void}
     */
     onStartPage(): void {
        if (!this.disableStartButton) {
            if (this.page_request) {
                this.current_page = 1;
                this.start_index = 0;
                this.end_index = 10;
            } else {
                this.initializeData(false);
            }
        }
    }

    /**
     * Computes the value of the indexes whenever the
     * next page is accessed.
     *
     * @returns {void}
     */
    nextPageComputation(): void {
        this.start_index = this.start_index + this.page_number;
        this.end_index = this.end_index + this.page_number;

        if (this.end_index > this.list.length) {
            this.end_index = this.list.length;
        }

        this.current_page = this.current_page + 1;
    }

    /**
     * Get the tokens for the next page
     * and previous page.
     *
     * @param {any[]} arData
     * @param {string} strColum
     *
     * @returns {void}
     */
    setNextPage(arData: any[] = [], strColumn: string = 'created_at'): void {
        if (arData.length > 0) {
            this.page_data = {
                next_page: arData[arData.length - 1][strColumn],
                previous_page: arData[0][strColumn]
            }
        }
    }

    /**
     * Trigger the page to get next set of data.
     *
     * @param {'next' | 'prev' | null} strDirection
     *
     * @returns {void}
     */
    triggerPageObservable(strDirection: 'next' | 'prev' | null = null): void {

        this.loader = true;

        this.page_request(this.getPageData(strDirection)).subscribe((objResponse: {data: any, next: boolean, raw_data: any}) => {

            this.setNextPage(objResponse.raw_data);

            objResponse.data.forEach(item => {
                this.list.push(item);
            });

            this.has_next = objResponse.next;

            if (strDirection != null) {
                this.nextPageComputation();
            }

            this.loader = false;
        });
    }

    /**
     * Get the page data to use for the
     * next API request.
     *
     * @param {string} strDirection
     *
     * @returns {string | null}
     */
    getPageData(strDirection: 'next' | 'prev'): {pageNum: number, page: string, direction: 'next' | 'prev'} | null {
        if (this.page_data != null) {
            return {
                pageNum: (strDirection == 'next') ? this.current_page + 1 : this.current_page - 1,
                page: btoa((strDirection == 'next') ? this.page_data.next_page : this.page_data.previous_page),
                direction: strDirection
            };
        }
        return null;
    }

    /**
     * Initialize the properties needed for
     * pagination logic.
     *
     * @return {void}
     */
    initializeData(bEmptyList: boolean = true): void {
        if (bEmptyList) {
            this.list = [];
        }
        this.current_page = 1;
        this.start_index = 0;
        this.end_index = 10;
        this.page_data = null;
    }

}