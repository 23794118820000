import { Component, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { WizardStepComponent, StepEventData, PROGRESS_NEXT, PROGRESS_FAILED, PROGRESS_SKIP } from '../../../../connect/connect.component';
import { AccountingSystemService, ProgressEvent } from '../../../../../services/accounting_system.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DriverInterface } from '../../../../../entities/driver';
import * as _ from 'lodash';

@Component({
  selector: 'wizard-step-import-items',
  templateUrl: './items.component.html'
})
export class ImportItemsComponent implements WizardStepComponent, OnDestroy {
  /**
   * Selected driver information
   *
   * @var {DriverInterface}
   */
  @Input('driver') driver: DriverInterface;

  /**
   * {@inheritDoc}
   */
  @Output('event-progress') eventProgress: EventEmitter<StepEventData> = new EventEmitter;

  /**
   * INTERNAL: contains all the subscriptions for rxjs that should be cleaned up once
   * this component is unmounted
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * this tells if the current step is in progress
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * indicates if an action should be retried
   *
   * @var {boolean}
   */
  shouldRetry: boolean = false;

  /**
   * contains the message of the current progress
   *
   * @var {string}
   */
  message: string;

  /**
   * Current batch cursor
   *
   * @var {number}
   */
  cursor: number = 0;

  /**
   * @param {AccountingSystemService} service
   */
  constructor(
    private service: AccountingSystemService
  ) { }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /// handles on start import user action
  onStartImport(): void {
    this.importItems();
  }

  /// handles on skip user action
  onSkipImport(): void {
    this.eventProgress.emit({
      progress: PROGRESS_SKIP,
    });

    this.service.flagNoImportedItems();
  }

  /**
   * handles the retry request of the user
   *
   * @returns {void}
   */
  retryHandler(): void {
    this.importItems();
  }

  /**
   * import items proceedure
   *
   * @returns {void}
   */
  private importItems(): void {
    this.isInProgress = true;
    this.shouldRetry = false;

    this.subscriptions.push(
      this.service.importItems$({
        cursor: this.cursor,
        progressId: 'connect_import_items',
      }).pipe(
        finalize(() => this.isInProgress = false)
      )
        .subscribe((result) => {
          if (result.isSuccessful === true && !result.shouldSkip) {
            this.message = 'imported_items_successfully';
            this.cursor = 0;
            this.eventProgress.emit({ progress: PROGRESS_NEXT });
          } else if ((result.isSuccessful === false || result.shouldSkip === true) && result.errors.length === 0) {
            this.message = 'no_imported_items';
            this.shouldRetry = !result.shouldSkip;
            this.eventProgress.emit({ progress: (result.shouldSkip) ? PROGRESS_SKIP : PROGRESS_NEXT });
            this.service.flagNoImportedItems();
          } else if (result.errors.length > 0) {
            this.message = result.errors[0];
            this.shouldRetry = true;
            this.eventProgress.emit({ progress: PROGRESS_FAILED });
          }
        })
    );
  }

  /**
   * Handles progress event emitted
   *
   * @param {ProgressEvent} event
   *
   * @returns {void}
   */
  onProgressEvent(event: ProgressEvent): void {
    this.cursor = event.progress;
  }
}
