
    <div
      class="d-flex d-flex-gap align-items-center"
      [class.justify-content-center]="position == 'center'"
      [class.justify-content-start]="position == 'left'"
      [class.justify-content-end]="position == 'right'"
      [class.flex-row-reverse]="position == 'right'"
    >
      <fa-icon
        [matTooltip]="tooltip"
        [icon]="(spin) ? ['fas', 'spinner'] : (icon$ | async)"
        [spin]="spin"
      >
      </fa-icon>
      <ng-content></ng-content>
    </div>
  