import { Observable, Subscription } from "rxjs";
import { Component, Input} from '@angular/core';
import { GeneratePDFComponent } from "../../generate-pdf/generate-pdf.component";
import { SelectTemplate } from '../../../../../../objects/select-template';

@Component({
  selector: 'form-templates-save-and-preview-btn',
  templateUrl: './save-and-preview.component.html',
})
export class SaveAndPreviewDefaultTemplatecomponent extends GeneratePDFComponent {
  /**
   * @type {boolean}
   */
  @Input('disabled') isDisabled: boolean = false;

  /**
   * to determine which document will be used in request
   *
   * @type {string}
   */
  @Input('trigger-event') triggerEvent: Observable<SelectTemplate>;

  /**
  * Event subscription
  *
  * @type {Subscription}
  */
  private triggerSubscription: Subscription;

  /**
  * @inheritdoc
  */
  ngOnInit(): void {
    super.ngOnInit();

    this.setTriggerEvent();
  }

  /**
  * Setup trigger event
  *
  * @returns {void}
  */
  protected setTriggerEvent(): void {
    if (this.triggerEvent) {
      this.triggerSubscription = this.triggerEvent.subscribe((data) => {
        if (data['id'] && data['document_type']) {
          this.moduleId = data['id'];
          this.filter = data['filter'];
          this.moduleName = data['module'];
          this.documentType = data['document_type'];

          this.previewTemplate();
        }
      });
    }
  }

  /**
  * {@inheritDoc}
  */
  ngOnDestroy() {
    if (this.triggerSubscription) {
      this.triggerSubscription.unsubscribe();
    }
  }
}