<header class="dialog-header container mb-1">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-xs-12 dialog-title">
      <div class="row">
        <ng-content select=".dialog-header-content > *"></ng-content>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-xs-12 pt-1">
      <div class="row">
        <ng-content select=".dialog-header-buttons"></ng-content>
      </div>
    </div>
  </div>
</header>

<hr />

<mat-dialog-content>
  <div class="container">
    <ng-content select=".dialog-content"></ng-content>
  </div>
</mat-dialog-content>
