
    <input
      type="text"
      class="{{ customClass }}"
      maxlength="{{ maxlength }}"
      placeholder="{{ placeholder | translate }}"
      [(ngModel)]="value"
      [ngClass]="{'is-invalid': isValid}"
      (change)="onChange($event.target.value)"
      (keypress)="onKeyPressInput($event)"
    />
  