<div class="row">
    <div class="col-md-12 float-left">
        <button class="btn btn-success" (click)="addAttributes()">
            <fa-icon [icon]="['fas','plus']" class="text-white"></fa-icon> {{ 'add_' +objConfig.key | translate }}
        </button>
    </div>
</div>
<form *ngIf="strModule == 'asset_types' && objConfig.key == 'attributes'" [formGroup]="objJsonForm" novalidate class="mt-3">
    <div formArrayName="objAttributes">
        <table class="table table-bordered w-100">
            <thead>
                <tr>
                    <td class="w-10">{{ 'type' | translate }}</td>
                    <td class="w-20">{{ 'label' | translate }}</td>
                    <td class="w-20">{{ 'key' | translate }}</td>
                    <td class="w-20">{{ 'default_value' | translate }}</td>
                    <td class="w-10">{{ 'required' | translate }}</td>
                    <td class="w-15">{{ 'options' | translate }}</td>
                    <td class="w-05"></td>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let formGroup of objJsonForm.controls['objAttributes'].controls; let intId = index;">
                    <tr *ngIf="formGroup.controls.id.value">
                        <td>
                            <app-edit-field
                                [item]="objJsonFormatGroup[formGroup.controls.id.value]['type.' +formGroup.controls.id.value]"
                                [form]="objJsonForm.controls.objAttributes.controls[intId]"
                                [strMode]="strMode"
                                [bSubmitted]="false"
                            ></app-edit-field>
                        </td>
                        <td align="center">
                            <app-edit-field
                                [item]="objJsonFormatGroup[formGroup.controls.id.value]['label.' +formGroup.controls.id.value]"
                                [form]="objJsonForm.controls.objAttributes.controls[intId]"
                                [strMode]="strMode"
                                [bSubmitted]="false"
                                (strCurrentValue)="autofill($event, 'key.' +formGroup.controls.id.value, intId)"
                            ></app-edit-field>
                        </td>
                        <td align="center">
                            <app-edit-field
                                [item]="objJsonFormatGroup[formGroup.controls.id.value]['key.' +formGroup.controls.id.value]"
                                [form]="objJsonForm.controls.objAttributes.controls[intId]"
                                [strMode]="strMode"
                                [bSubmitted]="false"
                            ></app-edit-field>
                        </td>
                        <td align="center">
                            <app-edit-field
                                [item]="objJsonFormatGroup[formGroup.controls.id.value]['default_value.' +formGroup.controls.id.value]"
                                [form]="objJsonForm.controls.objAttributes.controls[intId]"
                                [strMode]="strMode"
                                [bSubmitted]="false"
                            ></app-edit-field>
                        </td>
                        <td align="center">
                            <app-edit-field
                                [item]="objJsonFormatGroup[formGroup.controls.id.value]['required.' +formGroup.controls.id.value]"
                                [form]="objJsonForm.controls.objAttributes.controls[intId]"
                                [strMode]="strMode"
                                [bSubmitted]="false"
                            ></app-edit-field>
                        </td>
                        <td align="center">
                            <app-edit-field
                                *ngIf="objJsonForm.controls.objAttributes.controls[intId].controls['type.' +formGroup.controls.id.value].value == 'dropdown'"
                                [item]="objJsonFormatGroup[formGroup.controls.id.value]['option.' +formGroup.controls.id.value]"
                                [form]="objJsonForm.controls.objAttributes.controls[intId]"
                                [strMode]="strMode"
                                [bSubmitted]="false"
                            ></app-edit-field>
                            <span *ngIf="objJsonForm.controls.objAttributes.controls[intId].controls['type.' +formGroup.controls.id.value].value != 'dropdown'">
                                --
                            </span>
                        </td>
                        <td align="center">
                            <fa-icon
                                [icon]="['fas','trash-alt']"
                                class="text-danger pointer"
                                (click)="deleteAttributes(intId, formGroup.controls.id.value)"
                            ></fa-icon>
                        </td>
                    </tr>
                </ng-container> 
            </tbody>
        </table>
    </div>
</form>