import { Component, OnInit, Inject, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { get, isEmpty, isNil, map as _map, first } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { switchMap, finalize, map } from 'rxjs/operators';
import { RelateIds } from '../../../lists/relate-ids';
import { StatusCode } from '../../../lists/status-code';
import { TaskLineItem } from '../../../objects/task-line-item';
import { LooseObject } from '../../../objects/loose-object';
import { Relate } from '../../../objects/relate';
import { Department } from '../../../objects/department';
import { Users } from '../../../objects/users';
import { Checklist } from '../../../objects/checklist';
import { FormService } from '../../../services/form.service';
import { RecordService } from '../../../services/record.service';
import { ArrService } from '../../../services/helpers/arr.service';
import { ListingService } from '../../../services/listing.service';
import { NotificationService } from '../../../services/notification.service';
import { TaxCode } from '../../../objects/tax-code';
import { ClientStoreService } from '../../../services/client-store.service';
import { blank, filled, isIn, whenFilled } from '../../../shared/utils/common';
import { MaterialLineData, OnMaterialsUpdate } from '../../../module/jobs/materials/log-materials/components/material-lines/material-lines.component';
import { MaterialLineItem } from '../../../module/jobs/materials/log-materials/material-line-item';
import { isValidWorkOrderLines, OnWorkOrderLinesUpdate, WorkOrderDefaultPricebook, WorkOrderLine, WorkOrderLineData } from '../../../shared/components/view/jobs/work-order-items/work-order-items.component';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  /**
   * What product type is the material?
   *
   * @var {string[]}
   */
  public arProductType: string[] = [
    'product_catalog',
    'once_off_purchase'
  ];

  public objJobTemplateForm: LooseObject;
  public objModuleConfig: LooseObject = [];
  public strRecordId: string = (!isEmpty(this.objDialogData.record_id)) ? this.objDialogData.record_id : null;
  public strViewType: string = this.objDialogData.view_type;
  public strRecordModule: string = this.objDialogData.module;
  public strAssetTypeId: string = null;
  public bSubmitted: boolean = false;
  public bShowErrors: boolean = false;
  public bHasError: boolean = false;
  public bHasChanged: boolean = false;
  public arReadOnlyId: Array<string> = [];
  public arRequiredField: Array<string> = [];
  public arTaskLineAttributes: TaskLineItem[] = [];
  public arMaterialLineAttributes: MaterialLineItem[] = [];
  public arWorkOrderLineAttributes: WorkOrderLine[] = [];

  /**
   * If Job Template form was opened from the mega menu
   *
   * @var {boolean}
   */
  public bOpenedFromMegaMenu: boolean = false;

  /**
   * If department tracking enabled.
   *
   * @var {boolean}
   */
  bDepartmentTracking: boolean = false;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  @HostListener('window:resize', ['$event']) sizeChange(event) {
    this.setDialogStyle();
  }

  /**
   * If the material list is invalid, meaning
   * the user has not selected a product or inputted
   * any name if its a once off.
   *
   * @return {boolean}
   */
   get bIsMaterialsInValid(): boolean {
    return this.arMaterialLineAttributes.findIndex(item => (item.product.value == null)) > -1;
  }

  /**
   * Observable to emit when disabling the submit button
   *
   * @type {BehaviorSubject<boolean>}
   */
  disabledSaveButton$ = new BehaviorSubject(false);

  readonly materialsInitialValue$ = new BehaviorSubject<MaterialLineData[]>([]);

  readonly workOrderInitialValue$ = new BehaviorSubject<WorkOrderLineData[]>([]);

  readonly defaultTaxCode$ = new BehaviorSubject<TaxCode|null>(null);

  readonly defaultPricebook$ = new BehaviorSubject<WorkOrderDefaultPricebook|null>(null);

  readonly wasValidated$ = new BehaviorSubject<boolean>(false);

  private _strPricebookId: string;

  constructor(
    public objDialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public objDialogData: any,
    public formService: FormService,
    public recordService: RecordService,
    public notifService: NotificationService,
    public listingService: ListingService,
    public arrService: ArrService,
    public elemRef: ElementRef,
    private renderer: Renderer2,
    private client: ClientStoreService
  ) { }

  ngOnInit() {
    this.bDepartmentTracking = this.client.isDepartmentTracking();
    this.bOpenedFromMegaMenu = get(this.objDialogData, 'opened_from_mega_menu', false);

    if (!isEmpty(this.objDialogData['job_template_id'])) {
        this.recordService.getRecord('job_templates', this.objDialogData['job_template_id'], true, {}, 'edit_form').subscribe(objResponse => {
          // Get updated record data of currently updating.
          this.objDialogData['job_template'] = (objResponse['record_details'] != null && Object.keys(objResponse['record_details']).length > 0) ? objResponse['record_details'] : [];
          this.objModuleConfig['record_details'] = objResponse['record_details'];
          this.objModuleConfig['record_view'] = objResponse['record_view'];
          this.objModuleConfig['used_fields'] = objResponse['used_fields'];
          this.defaultTaxCode$.next(get(objResponse, 'related_data.default_tax_code_sale', null));

          this.setJobTemplate();
        });
    } else {
      this.recordService.getRecordConfig('job_templates', null, true).first().subscribe(objResponse => {
        this.objDialogData = objResponse;
        this.objModuleConfig = objResponse;
        this.defaultTaxCode$.next(get(objResponse, 'related_data.default_tax_code_sale', null));
        this.setJobTemplate();
      });
    }
    this.objDialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
    this.setDialogStyle();
  }

  /**
   * Set job template form
   */
  setJobTemplate() {
    if (this.strViewType === 'edit') {
      this.initField();

      this.objJobTemplateForm.map(objForm => {
        let objField = Object.keys(objForm['groups']['controls']);

        objField.forEach( strField => {
          // If field exist. Set field value
          if (this.objDialogData['job_template'][strField] !== undefined) {
            this.fieldPatchValue(strField, this.objDialogData['job_template'][strField]);
          }
        });
      });

      if (this.arrService.keyFallsBackTo(this.objDialogData['job_template'], 'task_line_items', null) !== null && this.objDialogData['job_template']['task_line_items'].length > 0) {
        this.objDialogData['job_template']['task_line_items'].forEach((objLineItem, intIndex) => {
          this.addTaskLineItem(intIndex, objLineItem);
        });
      } else {
        this.addTaskLineItem();
      }

      if (filled(get(this.objDialogData, 'job_template.material_line_items', []))) {
        this.materialsInitialValue$.next(_map(get(this.objDialogData, 'job_template.material_line_items', []), (line: Record<string, any>) => ({
          id: get(line, 'id'),
          quantity: get(line, 'quantity'),
          item_id: get(line, 'item_id'),
          item_name: get(first(get(line, 'item', [])), 'name'),
          item_code: get(first(get(line, 'item', [])), 'code'),
          work_order_reference: get(line, 'work_order_reference'),
          once_off_product_name: get(line, 'product'),
          unit_price: get(line, 'unit_price'),
          unit_cost: get(line, 'unit_cost'),
          markup: get(line, 'markup'),
        })));
      }

      if (filled(get(this.objDialogData, 'job_template.pricebook_id'))) {
        this.defaultPricebook$.next({
          id: get(this.objDialogData, 'job_template.pricebook_id'),
          text: get(this.objDialogData, 'job_template.pricebook_text'),
        });
      }

      if (filled(get(this.objDialogData, 'job_template.work_order_line_items', []))) {
        this.workOrderInitialValue$.next(_map(
          get(this.objDialogData, 'job_template.work_order_line_items', []),
          (line) => ({
            description: get(line, 'description'),
            work_order_reference: get(line, 'work_order_reference'),
            unit_price: get(line, 'unit_price'),
            labor: get(line, 'labor', false),
            unit_cost: get(line, 'unit_cost'),
            hourly_cost: get(line, 'hourly_cost'),
            item_id: get(line, 'item_id'),
            item_code: get(line, 'item_code'),
            item_name: get(line, 'item_name'),
            markup: get(line, 'markup'),
            quantity: get(line, 'quantity'),
            tax_code: get(first(get(line, 'tax_code', [])), 'code'),
            tax_code_id: get(line, 'tax_code_id'),
            tax_code_name: get(line, 'tax_code_name'),
            tax_rate: get(line, 'tax_rate'),
            current_stock_level: get(line, 'current_stock_level'),
            discounted_price: get(line, 'discounted_price'),
            is_created: get(line, 'is_created', false),
          }),
        ));
      }

    } else {
      this.initField();
      this.addTaskLineItem();
    }
  }

  /**
   * Initialize form
   */
  initField() {
    let arFormData = this.formService.formData(this.objModuleConfig['record_view'], this.objModuleConfig['used_fields'], this.objModuleConfig['record_details']);

    this.objJobTemplateForm  = arFormData.map(
      objFormItems => {
        objFormItems['id'] = objFormItems['label'].toLowerCase().replace(/ /g,'_');
        objFormItems['groups'] = this.formService.toFormGroup(objFormItems['fields'])
        return objFormItems;
      }
    );
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if (this.objJobTemplateForm.dirty) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(objConfirmation => objConfirmation.answer === true)
        .subscribe(() => {
          this.objDialogRef.close();
        });
    } else {
      this.objDialogRef.close();
    }
  }

  /// called when user hits the save button
  onSubmit(): void {
    this.saveJobTemplate();
  }

  /**
   * Save job template
   */
  saveJobTemplate() {
    this.bSubmitted = true;
    this.bShowErrors = true;
    this.bHasError = false;

    let objJobTemplateData = [];
    this.arReadOnlyId = [];
    this.arRequiredField = [];

    this.objJobTemplateForm.map(
      objForm => {
        Object.keys(objForm['groups'].value).forEach(
          item => {
            let strFieldMetadata = objForm['fields'].find( x => x.key == item);
            if (strFieldMetadata != undefined) {
              if (strFieldMetadata.readonly) this.arReadOnlyId.push(item);
              if (strFieldMetadata.required) this.arRequiredField.push(item);
            }
        })
    });

    //Loops the values.
    this.objJobTemplateForm.forEach(
      objItem => {
        //Merge all form group values into a single object.
        objJobTemplateData = {...objJobTemplateData, ...objItem['groups'].getRawValue()};
    });

    objJobTemplateData['billable'] = objJobTemplateData['billable'];

    if (this.strRecordId != null) {
      let strModuleId = RelateIds[this.strRecordModule];
      objJobTemplateData[strModuleId] = this.strRecordId;
    }

    this.validateRequest(objJobTemplateData);

    if (!this.bHasError) {
        // We need to filter those line items without any value in the field
        let arFilteredTaskLineAttributes = this.arTaskLineAttributes.filter(objLineItem =>
          this.arrService.keyFallsBackTo(objLineItem.checklist, 'value', null) !== null ||
          this.arrService.keyFallsBackTo(objLineItem.department, 'value', null) !== null ||
          this.arrService.keyFallsBackTo(objLineItem, 'task_description', null) !== null
        );

        let arFilteredMaterialLineAttributes = this.arMaterialLineAttributes.filter(
          (line) => filled(get(line, 'product.value')),
        );

        let arFilteredWorkOrderLineAttributes = this.arWorkOrderLineAttributes.filter(
          (line) => filled(get(line, 'tax_code_id')),
        );

        objJobTemplateData['task_line_items'] = arFilteredTaskLineAttributes.map(item => {
          let arData = item.getTaskData();
          arData.from_work_order = false;
          return arData;
        });

        objJobTemplateData['material_line_items'] = arFilteredMaterialLineAttributes.map(item => {
          let arData = item.getMaterialData('');
          arData.from_work_order = false;
          return arData;
        });

        objJobTemplateData['work_order_line_items'] = _map(this.arWorkOrderLineAttributes, (line) => ({
          ... line.encode(),
          is_created: true,
        }))

        objJobTemplateData['pricebook_id'] = this._strPricebookId;

        this.processSave(objJobTemplateData);
    } else {
      this.notifService.notifyError('required_notice', {
        header: 'not_allowed',
      });
      this.bSubmitted = false;
    }
  }

  /**
   * Process to create or update job template
   * @param objJobTemplateData
   */
  processSave(objJobTemplateData: LooseObject) {
    if (this.strViewType == 'edit') {

      this.compareOldData(objJobTemplateData);

      if (this.bHasChanged) {
        this.recordService.saveRecord('job_templates', objJobTemplateData, this.objDialogData['job_template']['id']).pipe(
          finalize(() => this.bSubmitted = false)
        ).subscribe(res => {
          if (res.status == 200) {
            this.objDialogRef.close('save');
          } else if (res.status === StatusCode.kResponseAccepted) {
            this.notifService.promptError(res.body.error);
          } else {
            this.objDialogRef.close('fail');
          }
        });
      } else {
        this.notifService.sendNotification('not_allowed', 'no_changes_made', 'warning');
        this.bSubmitted = false;
        this.objDialogRef.close('fail');
      }

    } else {
      this.recordService.saveRecord('job_templates', objJobTemplateData, '').pipe(
        finalize(() => this.bSubmitted = false)
      ).subscribe(res => {
        if (res.status === StatusCode.kResponseAccepted) {
          this.notifService.promptError(res.body.error);
        } else if (!isNil(res.body.id)) {

          // FC-4383: fix issue where no notficiation appears when a job template is created from the mega menu (instead of in the job templates listing page)
          if (this.bOpenedFromMegaMenu) {
            this.notifService.notifySuccess('header_notification.success_added');
          }

          this.objDialogRef.close('save');
        }
      });
    }
  }

  /**
   * Compare the old data to the requested data
   * @param objRequestData - Requested data
   */
  compareOldData(objRequestData: LooseObject) {
    let objRequestFields = Object.keys(objRequestData);

    objRequestFields.forEach(strField => {
      if (
          (typeof this.objDialogData['job_template'][strField] == 'string' &&
           typeof objRequestData[strField] == 'string' &&
           this.objDialogData['job_template'][strField] != objRequestData[strField]
          ) ||
          (JSON.stringify(this.objDialogData['job_template'][strField]) != JSON.stringify(objRequestData[strField]))
        ) {
        this.bHasChanged = true;
      }
    });
  }

  /**
   * Add a new material to log.
   *
   * @param {number} intNumIndex
   *
   * @returns {void}
   */
   addTaskLineItem(intNumIndex: number = 0, objUpdateData: LooseObject = [], strReferenceWorkOrder: string = null): void {
    let intExistingWorkOrderIndex = this.arTaskLineAttributes.findIndex(arTask => arTask['work_order_reference'] === strReferenceWorkOrder);
    let bLineItemExist = (strReferenceWorkOrder) ? intExistingWorkOrderIndex !== -1 : false;

    if (bLineItemExist) {
      intNumIndex = intExistingWorkOrderIndex;
    }

    if (!bLineItemExist) {
      this.arTaskLineAttributes.splice(intNumIndex + 1, 0, new TaskLineItem({
        department: new Relate<Department>().buildRelates(
          switchMap(strTerm => this.listingService.fetchDataAdvanceSearch({}, 'departments', {
            department_name:{
              op: "eq",
              value: strTerm
            },
          }, {}, null)
            .pipe(map(arDepartments => {
              return arDepartments['data'].map(objDepartment => {
                return new Department(objDepartment as any)
              })
            })
          )),
          objUpdateData['department'] ? objUpdateData['department'] : []
        ),
        user: new Relate<Users>().buildRelates(
          switchMap(strTerm => this.recordService.getRecordRelate('users', strTerm, false, false, {}).pipe(
            map(arUsers => {
              return arUsers.map(objUser => new Users({
                  ...objUser,
                  name: `${get(objUser, 'first_name', '')}  ${get(objUser, 'last_name', '')}`
                }))
              })
            )
          ),
          objUpdateData['user'] ? objUpdateData['user'] : []
        ),
        checklist: new Relate<Checklist>().buildRelates(
          switchMap(strTerm => this.listingService.fetchDataAdvanceSearch({}, 'checklists', {
            name:{
              op: "eq",
              value: strTerm
            },
          }, {}, null)
            .pipe(map(arChecklists => {
              return arChecklists['data'].map(objChecklist => {
                return new Checklist(objChecklist as any)
              })
            })
          )),
          objUpdateData['checklist'] ? objUpdateData['checklist'] : []
        ),
        work_order_reference: objUpdateData['work_order_reference'] ? objUpdateData['work_order_reference'] : strReferenceWorkOrder,
      }));
    }

    // When it is update form. We should set value in line item
    if (Object.values(objUpdateData).length > 0) {
      this.arTaskLineAttributes[intNumIndex].department.value = !isEmpty(objUpdateData['department']) ? objUpdateData['department'][0] : [];
      this.arTaskLineAttributes[intNumIndex].checklist.value = objUpdateData['checklist'];
      this.arTaskLineAttributes[intNumIndex].user.value = !isEmpty(objUpdateData['user']) ? objUpdateData['user'][0] : [];
      this.arTaskLineAttributes[intNumIndex].task_description = objUpdateData['task_description'];
      this.arTaskLineAttributes[intNumIndex].estimated_duration = objUpdateData['estimated_duration'];
      this.arTaskLineAttributes[intNumIndex].from_work_order = objUpdateData['from_work_order'];
    }

    this.setDialogStyle();
  }

  /**
   * Removes the line item.
   * @param attr - the object to be removed.
   */
  removeTaskLineItem(intIndex: number) {
    if (this.arTaskLineAttributes.length > 1) {
      this.arTaskLineAttributes.splice(intIndex, 1);
    }

    this.setDialogStyle();
  }

  /**
   * Get index of form
   * @param strField
   */
  getFormFieldIndex(strField: string) {
    return this.objJobTemplateForm.findIndex(objAttr => (objAttr.groups.get(strField) != undefined && objAttr.groups.get(strField) != null));
  }

  /**
   * Get index of field
   * @param strField
   * @param objForm
   */
  getFieldIndex(strField: string, objForm: LooseObject) {
    if (objForm != undefined && objForm['fields'] != undefined) {
      return objForm['fields'].findIndex(objAttr => (objAttr['key'] == strField));
    } else {
      return -1;
    }
  }

  /**
   * Set value of field
   * @param strField
   * @param strValue
   */
  fieldPatchValue(strField: string, strValue: string) {
    let intIndexOfFormField = this.getFormFieldIndex(strField);
    if (intIndexOfFormField > -1) {
      this.objJobTemplateForm[intIndexOfFormField]['groups'].patchValue({
        [strField] : strValue,
      }, {emitEvent: false, onlySelf: true});
    }
  }

  /**
   * Validate Request
   * @param objRequest
   */
  validateRequest(objRequest: LooseObject) {
    this.wasValidated$.next(false);

    for (let strKey in objRequest) {
      if (this.arRequiredField.indexOf(strKey) > -1 && strKey != 'line_items') {
        if((objRequest[strKey] == undefined || objRequest[strKey] == '' || objRequest[strKey] == null || objRequest[strKey].length < 1)) {
          let intIndexOfFormField = this.getFormFieldIndex(strKey);

          if (intIndexOfFormField > -1) {
            this.objJobTemplateForm[intIndexOfFormField]['groups']['controls'][strKey].markAsTouched();
            this.objJobTemplateForm[intIndexOfFormField]['groups']['controls'][strKey].setErrors({'incorrect' : true});
          }

          this.bHasError = true;
        }
      }
    }

    if (! isValidWorkOrderLines(this.arWorkOrderLineAttributes)) {
      this.bHasError = true;
    }

    if (this.bHasError) {
      this.wasValidated$.next(true);
    }
  }

  /**
   * Expands the target element
   *
   * @param {HTMLTextAreaElement} target
   *
   * @returns {void}
   */
  expandInput(target: HTMLTextAreaElement): void {
    target.classList.add('expanded-input');
  }

  /**
   * Shrinks the target element
   *
   * @param {HTMLTextAreaElement} target
   *
   * @returns {void}
   */
  shrinkInput(target: HTMLTextAreaElement): void {
    target.classList.remove('expanded-input');
  }

  /*
   * Mark field as dirty
   *
   * @param {string} strField
   *
   * @returns {void}
   */
  markFormControlDirty(strField: string): void {
    this.objJobTemplateForm.controls[strField].markAsDirty();
  }

  /**
   * Set style for dialog to avoid
   * the dropdown panel to be behind
   * in the dialog content
   */
  setDialogStyle(): void {
    let arDialogParentElement = document.getElementsByClassName('cdk-global-overlay-wrapper');
    let arDialogElement = document.getElementsByClassName('mat-dialog-container');
    let intCurrentWindowHeight = window.innerHeight;

    if (arDialogParentElement.length > 0 && arDialogElement.length > 0) {
      // We need to set overflow on dialog parent element. So
      // when dialog is more than the dialog parent it will
      // be scrollable
      this.renderer.setStyle(arDialogParentElement[0], 'overflow', 'auto');
      let intHeightOfAdditionalLineItems = 79;
      // Check the dialog height to current window height.
      // to set the correct overflow in the dialog
      if ((arDialogElement[0].getBoundingClientRect().height + intHeightOfAdditionalLineItems) > intCurrentWindowHeight) {
        arDialogElement[0].setAttribute("style", "overflow: auto !important");
      } else {
        arDialogElement[0].setAttribute("style", "overflow: visible !important");
      }
    }
  }

  /**
   * Listens to form error
   *
   * @returns {void}
   */
  onFormError(): void {
    this.disabledSaveButton$.next(true);
  }

  onMaterialsUpdate(event: OnMaterialsUpdate): void {
    this.arMaterialLineAttributes = event.materials;
  }

  onWorkOrdersUpdate(event: OnWorkOrderLinesUpdate): void {
    this.arWorkOrderLineAttributes = event.work_order_items;
    this._strPricebookId = event.pricebook_id;

    if (event.mode == 'init') {
      return;
    }

    const line = event.work_order_item;
    const [isInMaterials, isInTasks] = this._isInTasksOrMaterials(line);

    if (isInMaterials || isInTasks || blank(get(line, 'item_id'))) {
      return;
    }

    if (! get(line, 'labor', true)) {
      const materials: MaterialLineData[] = _map(this.arMaterialLineAttributes, (material) => material.getMaterialData());

      materials.push({
        unit_cost: get(line, 'unit_cost'),
        unit_price: whenFilled(get(line, 'discounted_price'), {
          then: () => get(line, 'discounted_price'),
          else: () => get(line, 'unit_price'),
        }),
        quantity: get(line, 'quantity'),
        work_order_reference: get(line, 'work_order_reference'),
        description: get(line, 'description'),
        from_work_order: true,
        item_id: get(line, 'item_id'),
        item_name: get(line, 'item_name'),
        item_code: get(line, 'item_code'),
        markup: get(line, 'markup'),
      });

      this.materialsInitialValue$.next(materials);
    } else {
      this.addTaskLineItem(this.arTaskLineAttributes.length - 1, {
        task_description: get(line, 'description'),
        estimated_duration: 1,
        department: whenFilled(get(line, 'department'), {
          then: () => first(get(line, 'deaprtment')),
          else: () => [],
        }),
        department_id: whenFilled(get(line, 'department'), {
          then: () => get(first(get(line, 'department')), 'id'),
        }),
        from_work_order: true,
        work_order_reference: get(line, 'work_order_reference'),
      }, get(line, 'work_order_reference'));
    }
  }

  private _isInTasksOrMaterials(line: WorkOrderLine): [boolean, boolean] {
    const isInMaterials = isIn(this.arMaterialLineAttributes, {
      comparator: (material) => material.work_order_reference == get(line, 'work_order_reference'),
    });

    const isInTasks = isIn(this.arTaskLineAttributes, {
      comparator: (task) => get(task, 'work_order_reference') == get(line, 'work_order_reference'),
    });

    return [isInMaterials, isInTasks];
  }
}
