<app-error-handler onlyFor="purchase_orders:list" alignment="center" displayAs="widget">
<div class="d-flex justify-content-around contacts-option tabs-content">
    <ng-container *fcHasPermission="'purchase_orders:create'; else createPurchaseOrderDisabled">
        <span id="widgetPurchaseOrdersCreateButton" (click)="(bPageLoaded && bDialogLoaded) ? createPurchaseOrder() : ''" class="span-links">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
            {{ 'create_purchase_order' | translate }}
        </span>
        <span id="widgetPurchaseOrdersWizardButton" (click)="(bPageLoaded && bDialogLoaded) ? openPurchaseOrderWizard() : ''" class="span-links">
            <fa-icon [icon]="['fas', 'magic']" class="text-primary"></fa-icon>
            {{ 'purchase_order_wizard' | translate }}
        </span>
    </ng-container>
    <ng-template #createPurchaseOrderDisabled>
        <span id="widgetPurchaseOrdersCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-default mr-1"></fa-icon>
            {{ 'create_purchase_order' | translate }}
        </span>
        <span id="widgetPurchaseOrdersWizardButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'magic']" class="text-default"></fa-icon>
            {{ 'purchase_order_wizard' | translate }}
        </span>
    </ng-template>
</div>

<div class="contact-roles-body">
    <div class="container">
        <div class="row pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'shopping-bag']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'purchase_orders' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetPurchaseOrdersRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bPageLoaded"></refresh-pagination-btn>
                        <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetPurchaseOrdersPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetPurchaseOrdersNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div *ngIf="arPurchaseOrders.length < 1">
            <div class="row border-top" *ngIf="bPageLoaded">
                <span class="no-content">
                    {{ 'no_purchase_order' | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="!bPageLoaded">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </div>

        <div class="row border-top pt-2 pb-2 pr-2"  *ngFor="let purchase_order of arPurchaseOrders" [id]="'widgetPurchaseOrdersRecord_' + purchase_order.id">
            <div class="col-sm-5">
                <div class="row" matTooltip="{{ 'po_number' | translate }}: {{ purchase_order.po_number }}">
                    <div class="col-12">
                        <a id="widgetPurchaseOrdersLabel"[routerLink]="['/purchase_orders/'+purchase_order.id]">
                            <span> #{{ purchase_order.po_number }}</span>
                        </a>
                    </div>
                </div>
                <div class="row" matTooltip="{{ 'po_date' | translate }}: {{ formatDate(purchase_order.po_date) }}">
                    <div class="col-12">
                        <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                        {{ formatDate(purchase_order.po_date) }}
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="row" matTooltip="{{ 'amount_tax_ex' | translate }}: {{ purchase_order.amount | currency }}">
                    <div class="col-12">
                      {{ purchase_order.amount | currency: {decimal_places: 2} }}
                    </div>
                </div>
                <div class="row" matTooltip="{{ 'amount_tax_inc' | translate }}: {{ purchase_order.amount_inc_tax | currency }}">
                    <div class="col-12">
                      {{ purchase_order.amount_inc_tax | currency: {decimal_places: 2} }}
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="row" matTooltip="{{ 'amount_due' | translate }}: {{ purchase_order.amount_due | currency }}">
                    <div class="col-12">
                      {{ purchase_order.amount_due | currency: {decimal_places: 2} }}
                    </div>
                </div>
            </div>
            <div class="col-sm-1 d-table text-role less-padding">
                <div class="row">
                    <div class="col-12">
                        <form-templates-preview-default-template-btn
                            [as-span]="true"
                            icon-class="text-danger"
                            btn-class=""
                            [hide-text]="true"
                            class="pointer float-right ml-2"
                            [module-id]="purchase_order.id"
                            document-type="purchase_order_report"
                            module-name="purchase_orders"
                            id="widgetPurchaseOrdersPreviewButton">
                        </form-templates-preview-default-template-btn>
                        <ng-container *fcHasPermission="'purchase_orders:edit'; else disabledEditButton">
                            <fa-icon id="widgetPurchaseOrdersEditButton" matTooltip="{{ 'edit' | translate }}" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="(bDialogLoaded) ? openPurchaseOrder(purchase_order) : ''"></fa-icon>
                        </ng-container>
                        <ng-template #disabledEditButton>
                            <fa-icon id="widgetPurchaseOrdersEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                        </ng-template>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <ng-container *fcHasPermission="'purchase_orders:delete'; else disabledDeleteButton">
                            <ng-container *ngIf="purchase_order.related_record == undefined || purchase_order.related_record.length < 1">
                                <fa-icon id="widgetPurchaseOrdersDeleteButton" matTooltip="{{ 'delete' | translate }}" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deletePurchaseOrder(purchase_order.id)"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="purchase_order.related_record != undefined && purchase_order.related_record.length > 0">
                                <ng-container *ngIf="compiledRelatedData(purchase_order.related_record); let relatedData">
                                    <span matTooltip="{{ 'purchase_orders_warning_disabled' | translate }} {{ relatedData }} {{ 'purchase_orders_warning_info' | translate }}">
                                        <fa-icon id="widgetPurchaseOrdersDeleteButton" class="text-secondary pointer float-right" [icon]="['fas', 'trash-alt']"></fa-icon>
                                    </span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-template #disabledDeleteButton>
                            <fa-icon id="widgetPurchaseOrdersDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</app-error-handler>
