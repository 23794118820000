<div class="container">
  <div class="row border-top pt-2 pb-2">
    <div class="col-sm-6 pt-1">
      <span class="module-title">
        <fa-icon [icon]="['far', 'cabinet-filing']" aria-hidden="true"></fa-icon>
        &nbsp;
        {{ 'imported_records' | translate }}
      </span>
    </div>
    <div class="col-sm-6 pr-2">
      <nav class="pagination-section">
        <ul *ngIf="(page | async) as _page" class="pagination">
          <li [ngClass]="{active: isLoading === false, disabled: isLoading === true || _page === 1}" class="page-item">
            <a class="page-link" aria-label="prev" (click)="gotoPrevPage()">
              <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
            </a>
          </li>
          <li [ngClass]="{active: isLoading === false, disabled: isLoading === true || hasNextPage === false}" class="page-item">
            <a class="page-link" aria-label="next" (click)="gotoNextPage()">
              <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <ng-container>
    <div class="row border-top" *ngIf="isLoading === false && importedRecords.length == 0">
      <span class="no-content">
        {{ 'no_records_have_been_imported_yet' | translate }}
      </span>
    </div>
    <div class="row border-top" *ngIf="isLoading === true">
      <span class="no-content">
        <inline-view-loader [isLoading]="true"></inline-view-loader>
      </span>
    </div>

    <div *ngIf="isLoading === false">
      <div class="row border-top" *ngFor="let record of importedRecords">

        <div class="col-sm-12">
          <div class="text-activity-type">
            <span>{{ record.import_metadata.description | translate }}</span>
          </div>
          <div class="text-activity-content">
            <small>
              <strong>{{ 'id' | translate }}: </strong>
              <span>{{ record.import_metadata.imported_record_id }}</span>
            </small>
          </div>
          <div class="text-activity-content">
            <small>
              <strong>{{ 'operation' | translate }}: </strong>
              <span>{{ record.import_metadata.operation | translate | uppercase }}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
