import { Component, OnInit, Input } from '@angular/core';

import { MatDialog } from '@angular/material';

import { EditComponent } from '../edit/edit.component';
import { EmailComponent } from '../../email/email.component';

import { RecordService } from '../../../../../services/record.service';
import { ListingService } from '../../../../../services/listing.service';
import { ActivitiesService } from '../../../../../services/activities.service';
import { NotificationService } from '../../../../../services/notification.service';

import { ModuleLogo } from  '../../../../../lists/module-logo';
import { Notification } from '../../../../../objects/notification';
import { RoleEditHeaderLabel, NoRecordFound, RoleTabTitle, RoleModuleOptionId } from  '../../../../../lists/role';

@Component({
selector: 'app-role-list',
templateUrl: './list.component.html',
styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

    @Input() strModule: string;
    @Input() strModuleId: string;
    @Input() strRelatedModule: string;

    public objTemplateLabel: {
        header: string,
        title: string,
        title_icon: string,
        no_record: string,
    };
    public bLoading = false;
    public arRoleList : Array<object> = [];

    public strCustomerRole: string = null;
    public strSiteRole: string = null;
    public objCustomerOptions: {} = null;
    public objCustomerRole: object = null;
    public objSiteOptions: {} = null;
    public objSiteRole: object = null;
    public objFilterOption: object;
    public objFilter: object = {};
    public bFilterLoading: boolean = true;

    /**
     * to determine if next page is enabled
     */
    get nextButton(): string {
        let paginationConfig = this.listingService.getPaginationConfig(this.paginationKey);
        return (paginationConfig && paginationConfig.next != "")  ? "active" : "disabled";
    }

    /**
     * to determine if previous page is enabled
     */
    get previousButton(): string {
        let paginationConfig = this.listingService.getPaginationConfig(this.paginationKey);
        return (
            paginationConfig &&
            paginationConfig.first != paginationConfig.current &&
            paginationConfig.current != ""
        )  ? "active" : "disabled";
    }

    /**
     * pagination config key
     */
    get paginationKey(): string {
        return `${this.strModule}_${this.strRelatedModule}`
    }

    constructor(
		private dialog: MatDialog,
        private recordService: RecordService,
        private listingService: ListingService,
        private activitiesService: ActivitiesService,
        private notificationService: NotificationService,
    ) { }

    ngOnInit() {
        this.setTemplateLabel((this.strModule == 'jobs') ? 'jobs' : this.strRelatedModule );
        this.getRecord('default');
        if (this.strModule == 'jobs') {
            this.getDropdownOption();
        }
    }

    ngOnChanges() {
        this.onRefresh();
    }

    /**
     * set's the template label based on the given module
     *
     * @param module
     */
    setTemplateLabel(module: string): void {
        this.objTemplateLabel = {
            header: RoleEditHeaderLabel[module],
            title: RoleTabTitle[module],
            title_icon: (this.strModule == 'jobs') ? ModuleLogo['contacts'] : ModuleLogo[module],
            no_record: NoRecordFound[module],
        }
    }

    /**
     * get the dropdown options for contact role modules
     */
    getDropdownOption(): void {
        this.recordService.getDropdownRecord('contacts'). subscribe( response => {
            this.objFilterOption = {
                site_roles: response.body['site_roles']['config'],
                customer_roles: response.body['customer_roles']['config'],
                opportunity_roles: response.body['opportunity_roles']['config'],
            }
            this.bFilterLoading = false;
        });
    }

    /**
     * fetch module role listing
     *
     * @param strPage - what page is currently to be viewed.
     * @param objFilter - Filter of data.
     */
    getRecord(page: string, filter: object = {}) {
        this.bLoading = true;
        filter = this.getRecordFilter(filter);
        this.listingService.setPaginationConfig(this.paginationKey);
        let pagination = this.listingService.beforeFetching(page);
        this.listingService.fetchData(JSON.stringify(pagination['objPage']), 'contact_roles', JSON.stringify(filter)).subscribe( response => {
            this.bLoading = false;
            this.arRoleList = response['data'];
            this.listingService.afterFetching(response, page);
            this.listingService.updatePaginationConfig(this.paginationKey);
        });
    }

    /**
     * add's a default filter when fetching record
     *
     * @param filter
     */
    getRecordFilter(filter: object): object {
        filter['module'] = this.strModule;
        if (this.strModule == 'contacts') {
            filter['module'] = this.strRelatedModule;
            filter['contact_id'] = this.strModuleId;
        } else {
            filter['module'] = this.strModule;
            filter['module_id'] = this.strModuleId;
        }
        return filter;
    }

    /**
     * set the filter for job contact role list
     */
    setFilter(): void {
        this.bLoading = true;
        this.arRoleList = [];
        let filter: object = {};
        if (this.strCustomerRole && this.strSiteRole) {
            filter['role'] = [this.strCustomerRole, this.strSiteRole];
            filter['relate_module'] = ['customers', 'sites'];
        } else if (this.strCustomerRole) {
            filter['role'] = this.strCustomerRole;
            filter['relate_module'] = 'customers';
        } else if (this.strSiteRole) {
            filter['role'] = this.strSiteRole;
            filter['relate_module'] = 'sites';
        }
        this.objFilter = filter;
        this.getRecord('default', this.objFilter);
    }

    /**
     * manage role record
     */
    manageRecord() {
        this.openRoleEditDialog([], 'manage');
    }

    /**
     * update role record
     *
     * @param roleData
     * @param roleId
     */
	updateRecord(id: string) {
        this.recordService.getRecord('contact_roles', id).subscribe( response => {
            if (response['record_details']) {
                this.openRoleEditDialog(response['record_details'], 'edit')
            }
        });
	}

    /**
     * delete record
     *
     * @param id
     */
    deleteRecord(id: string): void {
        this.notificationService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
            this.recordService.deleteRecord('contact_roles', id).subscribe( response => {
                if (response.status === 200) {
                    this.getRecord('default');
                    this.notificationService.notifySuccess('record_delete_success');
                } else {
                    this.notificationService.notifyError('record_delete_failed');
                }
            });
        });
    }

    /**
     * open email component dialog
     *
     * @param email
     * @param contact_id
     */
    openEmailDialog(email: string = '', contact_id: string = ''): void {
        let activity: object = {};
        if (email && contact_id) {
            activity = {
                is_draft: true,
                to: JSON.stringify([{
                    id: contact_id,
                    email: email
                }]),
                bcc: null,
                subject: null
            };
        }

        let emailDialog = this.dialog.open(EmailComponent, {
            width: '66%',
            height: 'auto',
            // Data to be passed on
            data: {
                module: this.strModule,
                record_id: this.strModuleId,
                activity : activity,
                view_type : 'add'
            },
            disableClose: true
        });

        emailDialog.afterClosed().subscribe( dialogResponse => {
            if (dialogResponse && dialogResponse != 'cancel') {
                if (dialogResponse['isDraft'] != undefined) {
                    this.notificationService.notifySuccess((dialogResponse['isDraft']) ? 'draft_saved' : 'email_sent');
                }
                if (dialogResponse['mode'] == 'create') {
                    this.activitiesService.createActivity(JSON.stringify(dialogResponse['data'])).subscribe( activityResponse => {
                        this.notificationService.notifySuccess('activity_created');
                    });
                }
            }
        });
    }

    /**
     * open edit component dialog
     *
     * @param objRoleRecord
     * @param view_type
     */
    openRoleEditDialog(objRoleRecord: object, view_type: string): void {

        let editFormDialog = this.dialog.open(EditComponent, {
            width: '80%',
            height: 'auto',
            data: {
                module: this.strModule,
                module_id: this.strModuleId,
                record: objRoleRecord,
                relate_module: (this.strModule == 'contacts') ? this.strRelatedModule : 'contacts',
                view_type: view_type
            },
            disableClose: true,
            panelClass: 'custom-dialog-container',
            id: 'roleDialog'
        });

        editFormDialog.afterClosed().subscribe( dialogResponse => {
            if (dialogResponse != 'cancel' && dialogResponse) {
                this.getRecord('default');
            }
        });
    }

    /**
     * update or delete the selected record
     *
     * @param event {
     *   action: string
     *   data: object
     * }
     */
    onChangeRecord(event): void {
        if (event.action === 'edit') {
            this.updateRecord(event.data.id);
        } else if (event.action == 'delete') {
            this.deleteRecord(event.data.id);
        } else if (event.action == 'email') {
            this.openEmailDialog(event.data.email, event.data.contact_id);
        }
    }

    /**
     * Handle refresh action
     *
     * @returns {void}
     */
    onRefresh(): void {
        this.arRoleList = [];
        this.getRecord('default', this.objFilter);
    }
}
