
    <input
      type="email"
      class="{{ customClass }}"
      maxlength="{{ maxlength }}"
      placeholder="{{ placeholder | translate }}"
      [(ngModel)]="value"
      [ngClass]="{'is-invalid': isInvalid}"
      (change)="onChange($event.target.value)"
      (focusout)="onFocusOutInput()"
      [email]="true"
      [readonly]="isReadonly"
    />
  