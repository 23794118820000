import { get, isArray, isBoolean, isEmpty, isNil, isNull, isNumber, isObjectLike, isString, trim } from 'lodash';

/// checks if a value is blank
/// a value is considered blank if it is null, undefined, empty string, empty array, empty object, or NaN
export const blank = <T = any>(value: T): boolean => {
  if (isNil(value) || isNull(value)) {
    return true;
  }

  if (isBoolean(value)) {
    return false;
  }

  if (isString(value)) {
    return trim(value).length < 1;
  }

  if ((isArray(value) || isObjectLike(value)) && isEmpty(value)) {
    return true;
  }

  if (isNumber(value) && isNaN(value)) {
    return true;
  }

  return false;
};

/// check if a value is filled
export const filled = <T = any>(value: T): boolean => !blank<T>(value);

/// retrieve a value from a given source based on the given keys
/// if one of the key has a value and exists in the source, it will return the value
/// otherwise it will return the default value if provided or null
export const either = <R = any>(source: any, keys: string[], opts: {
  default_value?: R,
} = {}): R => {
  opts = Object.assign({
    default_value: null,
  }, opts);

  for (const key of keys) {
    const value = get(source, key);

    if (filled(value)) {
      return value;
    }
  }

  return opts.default_value;
}

// retrieve a result when a condition is met. works like a ternary operator
export const when = <R = any>(condition: boolean, opts: {
  then?: () => R,
  else?: () => R,
} = {}): R => {
  opts = Object.assign({
    then: () => null,
    else: () => null,
  }, opts);

  if (condition) {
    return opts.then();
  }

  return opts.else();
}

/// retrieve a result when a value is blank. works like a ternary operator
export const whenBlank = <R = any, T = any>(value: T, opts: {
  then?: () => R,
  else?: () => R,
} = {}) => when(blank(value), opts);

/// retrieve a result when value is filled. works like a ternary operator
export const whenFilled = <R = any, T = any>(value: T, opts: {
  then?: () => R,
  else?: () => R,
} = {}) => when(filled(value), opts);

// returns the value if it is filled, otherwise it will return the fallback value
export const fallback = <R = any>(value: any, opts: {
  fallback: () => R,
}): R => whenFilled(value, {
  then: () => value,
  else: () => opts.fallback(),
});

/// transform a value based on the given transformation function
export const transform = <T = any, R = any>(value: T, opts: {
  transformer: (value: T) => R,
  default_value?: R,
}): R => {
  if (blank(value)) {
    return opts.default_value;
  }

  return opts.transformer(value);
}

/// checks if a value is in the list using a comparator function
export const isIn = <T = any>(list: T[], opts: {
  comparator: (item: T, pos: number) => boolean,
}): boolean => {
  for (const index in list) {
    if (opts.comparator(list[index], parseInt(index))) {
      return true;
    }
  }

  return false;
}

/// checks if a value is not in the list. works like the opposite of isIn
export const isNotIn = <T = any>(list: T[], opts: {
  comparator: (item: T, pos: number) => boolean,
}) => !isIn(list, opts);

/// check if a given value is a valid system id. it uses the uuid library to validate the id
export const isId = (value: any): boolean => filled(value) && _validateId(value);

// regex to validate uuid
const _validateId = (value: string): boolean => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(value);