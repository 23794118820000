import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { currency_codes } from "../../assets/api/currency_codes.json";
import {
  join,
  get
} from 'lodash';

@Pipe({
  name: "currency"
})
export class CustomCurrencyPipe implements PipeTransform {
  readonly _base = new CurrencyPipe('en-US');

  /**
   * Create instance of custom currency pipe
   *
   * @param {LocalStorageService} localStorage
   */
  constructor(
    protected localStorage: LocalStorageService
  ) { }

  /**
   * {@inheritdoc}
   */
  transform(
    value: any,
    opts: {
      mode?: 'default' | 'metric';
      currency_code?: string,
      decimal_places?: number;
    } = {}
  ) {
    opts = Object.assign({
      mode: 'default',
      currency_code: this.getCode(),
      decimal_places: 2,
    }, opts);

    // Find the currenct set, if none, default is USD.
    let currency = currency_codes.find(currencies => (currencies.code == opts.currency_code)) || currency_codes.find((currency) => currency.code == 'USD');

    if (opts.mode == 'metric') {
      return this.transformMetric(value, currency);
    }

    const places = opts.decimal_places || 2;

    return this._base.transform(value, currency.code, currency.symbol, `1.2-${places}`);
  }

  /**
   * Retrieved currency code from the selected client
   *
   * @returns {string|undefined}
   */
  protected getCode(): string | undefined {
    let client = this.localStorage.getJsonItem('current_client');
    return get(client, ['config', 'currency']);
  }

  /**
   * Transforms value into a metric form
   *
   * @param {any} value
   * @param {{symbol: string}} currency
   *
   * @returns {string}
   */
  protected transformMetric(value: any, currency: { symbol: string }): string {
    let suffix: string;
    let divisor = 1;
    let rounded = Math.round(value);
    let length = rounded.toString().length;

    if (length > 4 && length < 7) {
      divisor = 1000;
      suffix = 'K';
    } else if (length > 6 && length < 10) {
      divisor = 1000000;
      suffix = 'M';
    } else if (length > 9 && length < 12) {
      divisor = 1000000000;
      suffix = 'B';
    }

    return join([currency.symbol, Math.round(rounded / divisor), suffix], '').trim();
  }
}
