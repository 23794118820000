import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import * as _ from "lodash";
import { environment } from '../../../../../environments/environment';
import { Observable, of } from "rxjs";
import { map, catchError, filter } from "rxjs/operators";
import { Config } from "protractor";
import { Safety, SafetyMetadata } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SafetyManagementService {
  /**
   * Create instance
   *
   * @param {HttpClient} http
   */
  constructor(
    protected http: HttpClient
  ) {}

  /**
   * Save job safety information
   *
   * @param {string} jobId
   * @param {Safety} data
   *
   * @returns {Observable<Safety>}
   */
  saveJobSafety$(jobId: string, data: Safety): Observable<Safety> {
    let body = new URLSearchParams();

    data = _.set(data, ['job', 'id'], jobId);

    body.append('data', JSON.stringify(data));

    return this.callApi$('post', '/save', body)
      .pipe(
        filter(({ status }) => status === 200),
        map((response) => response.body.data),
        catchError(() => of(null))
      );
  }

  /**
   * Retrieved safety information of the current job
   *
   * @param {string} id
   *
   * @returns {Observable<SafetyMetadata>}
   */
  getJobSafety$(id: string): Observable<SafetyMetadata> {
    let body = new URLSearchParams();

    body.append('job_id', id);

    return this.callApi$('post', '/get', body)
      .pipe(
        map(({ body }) => ({
          data: body.data,
          config: body.config,
        })),
        catchError(() => of(null))
      );
  }

  /**
   * Retrieved safety information for pdf service for the current job given
   *
   * @param {string} id
   *
   * @returns {Observable<any>}
   */
  getPdf$(id: string): Observable<any> {
    let body = new URLSearchParams();

    body.append('job_id', id);

    return this.callApi$('post', '/pdf', body)
      .pipe(
        map((response) => response.body.data),
        catchError(() => of(null))
      );
  }

  /**
   * calls a given api endpoint
   *
   * @param {string} method
   * @param {string} uri
   * @param {any}    body
   *
   * @returns {Observable<HttpResponse<Config>>}
   */
  private callApi$(method: string, uri: string, body?: any): Observable<HttpResponse<Config>> {
    var baseUrl = _.trimEnd(environment.url, '/');
    var uri = _.trim(uri, '/');
    var method = method || 'get';

    return this.http[method](_.trim(`${baseUrl}/safety/${uri}`, '/'), body, { observe: 'response' });
  }
}