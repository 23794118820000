import { ChecklistResponsePrompt } from './checklis-response';

export class Checklist {

  id?: string;
  name?: string;
  type?: string;
  asset_type_id?: string;
  is_checklist_enabled?: boolean;
  available_periods?: string;

  constructor(properties: {
      id?: string
      name?: string
      type?: string
      asset_type_id?: string
      is_checklist_enabled?: boolean
      available_periods?: string
    } = {}){

    this.id = properties.id,
    this.name = properties.name,
    this.type = properties.type,
    this.asset_type_id = properties.asset_type_id,
    this.is_checklist_enabled = properties.is_checklist_enabled || false,
    this.available_periods = properties.available_periods || null
  }
}

export interface ChecklistPromptGroup {
  id: string;
  is_group: boolean;
  group_name: string;
  prompts: ChecklistResponsePrompt[];
  type: string;
}