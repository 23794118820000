<input type="text"
            [(ngModel)]="filter.viewModel.value"
            (change)="filter.onChange()"
            (focus)="filter.focusInput$.next()"
            (keydown)="advancedSearchBox.keydown($event, filter.viewModel)"
            (selectItem)="filter.onSelectSuggestions($event)"
            autosize 
            [autosizeProp]="filter.viewModel.bindLabel"
            [placeholder]="filter.viewModel.placeholder | translate"
            [ngbTypeahead]="suggestionsFunc"
            [inputFormatter]="suggestionsFormatter(filter.viewModel)"
            [resultFormatter]="suggestionsFormatter(filter.viewModel)"
            #inputRef
            editable="false"
            class="as-text-input"
            />