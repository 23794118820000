export enum ModuleInitial {
    'leads' = 'l',
    'customers' = 'cu',
    'sites' = 'si',
    'contacts' = 'co',
    'opportunities' = 'o',
    'jobs' = 'j',
    'assets' = 'a',
    'customer_invoices' = 'cui',
    'purchase_orders' = 'p',
    'supplier_invoices' = 'su'
  };