import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { get } from 'lodash';

import { RecordService } from '../../../services/record.service';
import { ListingService } from '../../../services/listing.service';
import {ModuleStatusValueTranslations, StatusColors} from '../../../lists/listing-fields';
import { IRecordMetricEvent } from './../../events/record-metric.event';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit, OnChanges {

  // Gets passed variables from html elements that uses Information panel
  @Input('strModule') strModule: string;
  @Input('showLegend') bShowLegend: boolean = true;
  @Input('strType') strType: string;
  // Are Analytics widgets enabled?
  @Input('isPanel1Visible') isPanel1Visible: boolean;
  @Input('isPanel2Visible') isPanel2Visible: boolean;
  @Input('isPanel3Visible') isPanel3Visible: boolean;

  /**
   * input attribute reloadRecordMetric dictates whether we will reload the current metric
   *
   * @var {boolean}
   */
  @Input('reloadRecordMetric') reloadRecordMetric: boolean = false;

  /**
   * event triggered when record metric data has been loaded
   *
   * @var {EventEmitter}
   */
  @Output('evtRecordMetricDataLoaded') evtRecordMetricDataLoaded: EventEmitter<IRecordMetricEvent> = new EventEmitter<IRecordMetricEvent>();

  public information_content : string;
  public arModuleLegend = [];
  public arPanel1Data:any = [];
  public arPanel2Data:any = [];
  public arPanel3Data:any = [];
  public objStatusColors = new StatusColors;
  protected moduleStatusValueTranslations: ModuleStatusValueTranslations = new ModuleStatusValueTranslations;

  constructor (private recordService: RecordService, private listingService: ListingService) {}

  ngOnInit() {
    // Pass the content from translation to UI
    this.information_content = 'content_information.' + this.strModule;

    // Check if module has status
    if (this.bShowLegend && this.getLegend()) {
      Object.keys(this.getLegend()).forEach( key => {
        let value = get(this.moduleStatusValueTranslations, `${this.strModule}.${key}`, key);
        this.arModuleLegend.push({ value, 'class': this.getLegend()[key]});
      });
    }

    /*if (this.strType == 'listing') {
      this.getMetric();
    }*/
  }

  /**
   * {@inheritdoc}
   *
   * @param {any} changes
   */
  ngOnChanges(changes: any): void {
    // FC-858: trigger this.getMetric when changes to this.releadRecordMetric has occured
    if (changes.reloadRecordMetric
      && changes.reloadRecordMetric.currentValue === true
      && changes.reloadRecordMetric.previousValue === false
    ) {
      this.getMetric();
    }
  }

  getLegend() {

    let arDifferentStatus = ['users', 'customers', 'sites', 'items', 'workflows'];

    // Special case. Data for statuses of these modules are true or false.
    if (arDifferentStatus.includes(this.strModule)) {
      switch(this.strModule) {
        // active, inactive
        case 'users' :
          return { 'active' : "text-success", 'inactive': "text-danger" };
        // on_hold, active
        case  'customers':
          return{ 'active' : "text-success", 'on_hold': "text-danger" };
        case  'sites':
          return{ 'enabled_maintenance' : "text-success", 'disabled_maintenance': "text-danger" };
        case 'workflows':
        case 'items':
          return{ 'active' : "text-success", 'inactive': "text-danger" };
      }

    } else {
      return (typeof (this.objStatusColors[this.strModule]) ? this.objStatusColors[this.strModule] : '')
    }

  }

  getMetric() {
      // Get record metrics
      this.recordService.getRecordMetric(this.strModule).first().subscribe(
        data => {
          this.arPanel1Data = (data['panel_1']) ? data['panel_1'] : [];
          this.arPanel2Data = (data['panel_2']) ? data['panel_2'] : [];
          this.arPanel3Data = (data['panel_3']) ? data['panel_3'] : [];

          this.evtRecordMetricDataLoaded.emit({
            isLoaded: true,
            isReloaded: this.reloadRecordMetric,
            data: data
          });
        }
      );
  }

  setFilter(arFilter) {
    // FC-872: if no filter was given pass an empty object
    if (! arFilter) {
      this.listingService.setInformationFilter([]);
    } else {
      // Send filter to listing
      this.listingService.setInformationFilter(arFilter);
    }
  }
}