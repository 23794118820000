import { Component, Input } from '@angular/core';
import { Summary } from '../../../../objects/wizard';

@Component({
  selector: 'app-common-summary',
  templateUrl: './common-summary.component.html',
})
export class CommonSummaryComponent {
  /**
   * Values of the forms to make
   * a summarization.
   *
   * @var {Summary}
   */
  @Input() summary: Summary;
}