import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  /**
   * Truncates a string a desired length
   *
   * Inspired from: @link http://codebuckets.com/2018/01/23/angular-pipe-to-truncate-text-to-the-nearest-whole-word/
   *
   * @param {string} value
   * @param {number} length
   * @param {biggestWord} biggestWord
   */
  transform(value: string, length: number = 50, replace: string = '...', biggestWord: number = 50): string {
    if (! value) return '--';
    if (value.length <= length) return value;
    if (length < replace.length) return value;

    let truncated = value.slice(0, length + biggestWord);

    while(truncated.length > length - replace.length) {
      let lastSpace = truncated.lastIndexOf(' ');

      if (lastSpace === -1) break;

      truncated = truncated.slice(0, lastSpace).replace(/[!.,?]$/, '');
    }

    return `${truncated}${replace}`;
  }
}
