import { Component, OnInit, Inject } from '@angular/core';
import { RecordService } from '../../../../../services/record.service';
import { ListingService } from '../../../../../services/listing.service';
import { FormGroup } from '@angular/forms';
import { StatusCode } from '../../../../../lists/status-code';
import { FormService } from '../../../../../services/form.service';
import { map } from 'rxjs/operators';
import { Subject, Observable, concat, of } from 'rxjs';
import { Select } from '../../../../../objects/select';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../../../../../services/notification.service';
import { Guid } from 'guid-typescript';
import { ArrService } from '../../../../../services/helpers/arr.service';
import { ChecklistsService } from '../../../../../services/checklist.service';
import { Checklist } from '../../../../../objects/checklist';
import { get } from 'lodash';

@Component({
  selector: 'app-link-checklist',
  templateUrl: './link-checklist.component.html',
  styleUrls: ['./link-checklist.component.scss'],
  providers: [ListingService]
})
export class LinkChecklistComponent implements OnInit {

  public arChecklist = [];
  public checklistForm: FormGroup;
  public strChecklist;
  public arRecord = [];
  public arChosenPeriod = [];

  public objChecklist = {
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'select_checklist',
      name: 'name',
      module: 'checklists',
      value: 'strChecklist',
      readonly: false
  }

  constructor (
    public dialogRef: MatDialogRef<LinkChecklistComponent>,
    public recordService: RecordService,
    public listService: ListingService,
    public formService: FormService,
    private notifService: NotificationService,
    private arrService: ArrService,
    private checklistService: ChecklistsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.arRecord = data;
    }

    ngOnInit() {

        this.objChecklist['obv'] = new Observable<Select[]>();

        // Place an initial value to the asynchronus ng-select.
        this.objChecklist['obv'] = concat(
        // Make sure the initial value is an array.
        of(this.arRecord['checklist_record']),
        // Set the input observable to trigger this API call.
        this.objChecklist['typehead'].pipe(
            // Trigger only ever 400 milisecond.
            debounceTime(400),
            // Trigger only when the value is different from the previous.
            distinctUntilChanged(),
            // Show the loader.
            tap(() => {
                this.objChecklist['loader'] = true;
            }),
            // Get the response from the API.
            switchMap(term => this.recordService.getRecordRelate(
              'checklists',
              term,
              '',
              false,
              {
                is_checklist_enabled : true,
                // FC-3476: If module is opportunities (quote), we set the filter here to only get quote checklists
                ... (this.arRecord['module'] === 'opportunities' && {
                  type: 'opportunity',
                }),
              }
            ).pipe(
              // Hide the loader once done.
              tap(() => {
                this.objChecklist['loader'] = false;
              }),
              // FC-3476: If module is jobs, we need to filter the result here because we need to get both job and asset checklists
              map(result => {
                if (this.arRecord['module'] === 'jobs') {
                  return result.filter(objChecklist => objChecklist['type'] !== 'opportunity');
                }

                return result;
              })
            ))
        ));

    }


    /**
     * Closes the dialog
     */
    cancelDialog() {
        this.dialogRef.close({status : 'cancel'});
    }


    /**
     * Creates and links checklist response in the current job record
     */
    async linkChecklists() {
      if (this.strChecklist !== undefined) {
        try {
          let strChecklistType: string = get(this.arChecklist, 'type', 'job');
          let strFilterKey: string = strChecklistType === 'opportunity' ? 'opportunity_id' : 'job_id';

          const recordId = get(this.arRecord, strFilterKey, this.arRecord['record_id']);

          if (await this.checklistService.generateChecklistResponse(this.arChecklist as Checklist, this.arChosenPeriod, recordId, this.arRecord['module'])) {
            // Link the checklist in the current job record
            this.recordService.saveRecord('checklist_responses', this.checklistService.getChecklistResponseData()).subscribe( result => {
              if (result.status === StatusCode.kResponseCreated) {
                this.notifService.sendNotification('created', 'checklist_created_success', 'success');
                this.dialogRef.close({ status: 'save' });
              } else {
                this.notifService.notifyWarning('failed_to_save');
              }
            });
          }
        } catch (e) {
          this.notifService.notifyWarning(this.checklistService.getChecklistResponseError());
        }
      } else {
        // Checklist is already attached
        this.notifService.notifyWarning('invalid_checklist');
      }
    }

    /**
     * Gets available inspection periods from chosen checklist and attach to available inspection period
     */
    chooseChecklist(event) {

        this.arChecklist = event;

        if (this.arChecklist['type'] === "asset") {
            // Reset Current Period
            this.arChosenPeriod = [];
            this.arChecklist['available_periods'] = this.arrService.keyFallsBackTo(this.arChecklist, 'available_periods', null);

            // Parse the string period to an array to support NgSelect Item
            if (this.arChecklist['available_periods'] !== null) {
                this.arChecklist['available_periods'] = JSON.parse(this.arChecklist['available_periods']);
            } else {
                this.notifService.notifyWarning('no_inspection_period');
            }
        }
    }

    /**
     * When the ng-select dropdown is clicked/opened, we fake a user input
     * a user input with no characters.
     * @param typehead - the ng-select typehead observable.
     */
    public triggerSubject(typehead: Subject<string>) {
        // We trigger the typehead to execute a search.
        typehead.next("");
    }
}
