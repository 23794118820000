import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ListingService } from '../../../../../services/listing.service';
import { StockManagementService } from '../../../../../services/stock-management.service';

import { IDialogEvent } from '../../../../../shared/events/dialog.event';

@Component({
  selector: 'stocktake-history',
  templateUrl: './stocktake-history.component.html',
  styleUrls: ['./stocktake-history.component.scss'],
  providers: [
    ListingService,
    StockManagementService
  ]
})
export class StocktakeHistoryComponent implements OnInit {

  objTableHeaderData: object[];

  /**
   * for listing component reloadRecordMetric attribute
   *
   * @var {boolean}
   */
  reloadRecordMetric: boolean = false;

  constructor(
    public listingService: ListingService
  ) {
    /**
     * First value should be a translatable label.
     * the following values should be the field's key.
     */
    this.objTableHeaderData = [
      {
        id: 'first_name',
        label: 'name',
        searchable: false,
        sortable: false,
        option: ''
      },
      {
        id: 'company',
        label: 'company',
        searchable: true,
        sortable: false,
        option: ''
      },
      {
        id: 'created_at',
        label: 'date_created',
        searchable: false,
        sortable: true,
        option: 'history_date'
      },
      {
        id: 'updated_at',
        label: 'date_modified',
        searchable: false,
        sortable: true,
        option: 'history_date'
      },
      {
        id: 'status',
        label: 'status',
        searchable: false,
        sortable: false,
        option: 'status',
        multiple: true
      },
      {
        id: 'phone',
        label: 'phone',
        searchable: false,
        sortable: false,
        option: ''
      },
      {
        id: 'email_address',
        label: 'email_address',
        searchable: false,
        sortable: false,
        option: ''
      },
      {
        id: 'user_id',
        relate_display: 'user_name',
        label: 'assigned_user',
        searchable: false,
        sortable: false,
        relate: 'users',
        option: ''
      },
      {
        id: 'date_follow_up',
        label: 'next_follow_up_date',
        searchable: false,
        sortable: true,
        option: 'history_date'
      }
    ];
  }

  ngOnInit() {
  }

  /**
   * handles event triggered evtListingDialog
   *
   * @param {IDialogEvent} objEvent
   */
  evtListingDialogHandler(objEvent: IDialogEvent): void {
    if (objEvent.mode === 'form'
      && objEvent.action === 'close'
      && objEvent.dataStatus === 'save'
    ) {
      // reload metric data
      this.reloadRecordMetric = true;
    }
  }
}
