import { Component, OnInit } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';
import { DocumentService } from '../../../../services/document.service';

@Component({
  selector: 'app-select-document',
  templateUrl: './select-document.component.html',
  styleUrls: ['./select-document.component.scss']
})
export class SelectDocumentComponent implements OnInit {

  public arDocumentList : any = [];
  public bLoading: boolean;
  public bEmpty: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SelectDocumentComponent>,
    public documentService: DocumentService) { }

  ngOnInit() {

      this.bLoading = true;

      this.documentService.getDocuments(JSON.stringify({ }))
			.subscribe(
				data => {
          this.bLoading = false;
          if (data['message'] != undefined) {
            this.bEmpty = true;
          } else {
            this.bEmpty = false;
            this.arDocumentList =  data;
          }
      });
  }

  /**
	 * Closes the dialog
	 */
  cancelDialog() {
    this.dialogRef.close();
  }

  /**
	 * 
	 * Triggers when user clicks a document
   *
	 * @param document - the selected document
	 */
  importDocument(document) {
    this.dialogRef.close({'data':  document});
  }

}
