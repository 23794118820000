import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { concat } from 'rxjs/observable/concat';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { FormGroup, FormControl } from '@angular/forms';
import * as _moment from 'moment';

import { Select } from '../../../../objects/select';
import { RelateIds } from '../../../../lists/relate-ids';
import { ActivityType } from '../../../../lists/activity'
import { NotificationService } from '../../../../services/notification.service';
import { RecordService } from '../../../../services/record.service';
import { ActivitiesService } from '../../../../services/activities.service';
import { Notification } from '../../../../objects/notification';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { AWSAppSyncService } from '../../../../services/aws-appsync.service';
import { ClientStoreService } from '../../../../services/client-store.service';
import { LooseObject } from '../../../../objects/loose-object';
import { get, toString, isEmpty } from 'lodash';
import { toFormattedNumber } from '../../../../shared/utils/numbers';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
@Component({
  selector: 'app-add-time-entry',
  templateUrl: './add-time-entry.component.html',
  styleUrls: ['./add-time-entry.component.scss']
})
export class AddTimeEntryComponent implements OnInit {
  public strModule: string;
  public bSubmitted: boolean = false;
  public bShowLoader: boolean = false;
  public isTouched: boolean = false;
  public strRelatedId: any = RelateIds;
  public strViewType: string = this.data.view_type;
  public arRecordDetails: any = [];
  public arNgSelectFieldKey: any = [];
  public arTimeEntryFieldKey: any = [];
  public objEditDetails = {};
  public objInitialRawData = {};
  public arInitial: any = [];
  public TimeEntryForm: FormGroup;
  public getDateDiff: any = [];
  public bDialogLoaded: boolean = false;

  public objTimeEntryFields = {
    'date_range': {
      key: 'date_range',
      label: 'work_date',
      controlType: 'datetimerange',
      default_value: '',
      readonly: false,
      date_range_restriction: get(this.data, 'date_range_filter')
    },
    'actual_duration': {
      key: 'actual_duration',
      label: 'actual_duration',
      controlType: 'decimal',
      default_value: '',
      readonly: true
    },
    'break': {
      key: 'break',
      label: 'break',
      controlType: 'decimal',
      default_value: '',
      readonly: false
    },
    'description': {
      key: 'description',
      label: 'description',
      controlType: 'textarea',
      rows: 5,
      default_value: '',
    }
  };

  /**
   * Custom ng-select fields.
   */
  public arNgSelectFields =
    { items : {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_labour_category',
        name: 'item_id',
        module: 'items',
        value: null,
        filter: {
          labor: true,
          active: true
        },
      },
      assigned_user: {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_user',
        name: 'user_id',
        module: 'users',
        value: null,
        filter: {
          has_subcontractor: true
        }
      },
      activities: {
        obv: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
        placeholder: 'select_task',
        name: 'activity_id',
        module: 'activities',
        value: null,
        filter: this.taskFilter
      }
    };

  public bFormDirty: boolean = false;

  /**
   * Sets the default selected task if there is only one task in the job
   *
   * @type {{ id: string, text: string }}
   *
   */
  public defaultTask: { id: string, text: string } = {
    id: null,
    text: null
  };

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  get defaultAssignedUser(): { id: string, text: string } {
    return {
      id: get(this.data, 'user.id', this.localStorageService.getItem('user_id')),
      text: get(this.data, 'user.name', this.localStorageService.getItem('user_name'))
    };
  }

  get initialDateValue(): LooseObject {
    if (get(this.data, 'module') === 'timesheets') {
      return {
        start_date: get(
          this.data,
          'date_range_filter.from',
          moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
        )
      }
    }

    return {};
  }

  get taskFilter(): LooseObject {
    const objFilter = {
      activity_type: ActivityType['task']
    };

    if (get(this.data, 'module') === 'timesheets') {
      // if module is timesheets, only show job tasks in relate
      objFilter['job_id'] = 'not_null'
    } else {
      objFilter['activities.job_id'] = this.data.record_id;
    }

    return objFilter;
  }

  constructor(
    public clientStoreService: ClientStoreService,
    public dialogRef: MatDialogRef<AddTimeEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifService: NotificationService,
    private recordService: RecordService,
    private activitiesService: ActivitiesService,
    protected localStorageService: LocalStorageService,
    protected appSyncService: AWSAppSyncService
    ) {
      let TimeEntryForm = {
        date_range: new FormControl(this.initialDateValue),
        description: new FormControl(''),
        actual_duration: new FormControl('0.00'),
        break: new FormControl('0.00'),
      };
      // Initialize form control fields.
      this.arNgSelectFieldKey = Object.keys(this.arNgSelectFields);
      this.arTimeEntryFieldKey = Object.keys(this.objTimeEntryFields);
      this.TimeEntryForm = new FormGroup(TimeEntryForm);
      this.arInitial['items'] = [];
      this.arInitial['activities'] = [];

      if (this.data['time_entry_id'] != undefined && this.data['time_entry_id'] != '' && this.data['time_entry_id'] != null) {
        let arFilter = { 'time_entries.id' : this.data['time_entry_id'] };
        this.recordService.getRecordRelateJoined('time_entries', false, arFilter).subscribe( response => {
          // Get updated record data of currently updating.
          this.data['time_entry'] = (response != null && response.length > 0) ? response[0] : [];
          this.setTimeEntry(data);
          this.bDialogLoaded = true;
          this.onChanges();
        });
      } else {
        this.setTimeEntry(data);
        this.bDialogLoaded = true;
        this.onChanges();
      }

      this.dialogRef.backdropClick().subscribe(_ => {
        this.cancelDialog();
      });

      if (get(this.data, 'set_default_task') === true) {
        const taskSearchData: LooseObject = {
          module : 'jobs',
          record_id : get(this.data, 'record_id'),
          filter_clause : {
            activity_type: 'task',
            activity_date: 'all'
          },
          widget: 'activities'
        };

        // FC-4212: When adding a new time entry, and job has only 1 task,
        // default the task selection to that one task.
        this.activitiesService.getActivities(JSON.stringify(taskSearchData)).subscribe(res => {
          const arData = get(res, 'data', []);

          if (arData.length === 1) {
            this.defaultTask.id = arData[0]['id'];
            this.defaultTask.text = arData[0]['text'];
            this.arNgSelectFields['activities']['value'] = this.defaultTask;
          }
        });
      }
    }

  ngOnInit() {
  }

  // onChanges of break to recalculate the actual duration
  onChanges(): void {
    this.TimeEntryForm.get('break').valueChanges.subscribe(val => {

      val = (val != '' && val != null) ? parseFloat(val) : 0.00;
      let actualDuration = 0.00;
      let strStartDate = '';
      let strEndDate = '';
      // Check if start date exists
      if(this.TimeEntryForm.value.date_range.start_date != undefined && this.TimeEntryForm.value.date_range.start_date != 'Invalid Date' && this.TimeEntryForm.value.date_range.start_date != '') {
        strStartDate = this.TimeEntryForm.value.date_range.start_date;
      }
      // Check if end date exists
      if(this.TimeEntryForm.value.date_range.end_date != undefined && this.TimeEntryForm.value.date_range.end_date != 'Invalid Date' && this.TimeEntryForm.value.date_range.end_date != '') {
        strEndDate = this.TimeEntryForm.value.date_range.end_date;
      }
      // Calculate date range
      let calculatedDateTime = this.calculateDateDiff(strStartDate, strEndDate);

      if (calculatedDateTime != undefined) {
        let convertMinutes = parseFloat(calculatedDateTime.minutes) * (1 / 60); // Formula: minutes * (hour / 60 minutes)
        actualDuration = parseFloat(calculatedDateTime.hours) + convertMinutes;
      }

      if (val > actualDuration) {

        this.TimeEntryForm.patchValue({
          break: '0.00'
        });

        this.notifService.notifyWarning('break_exceed');
      } else {
        this.TimeEntryForm.patchValue({
          actual_duration: toString(toFormattedNumber(actualDuration - val)),
        });
      }
    });
  }

  // This is for calcuate the actual duration
  calculateDate(event) {
    this.getDateDiff = event;
    if (event == 'invalid') {
      this.TimeEntryForm.patchValue({
        actual_duration: '0.00'
      });
    } else {
      // let actual_duration = (parseFloat(event.hours +'.'+ event.minutes / 60 * 100) - parseFloat(this.TimeEntryForm.value.break));
      let convertMinutes = parseFloat(event.minutes) * (1 / 60); // Formula: minutes * (hour / 60 minutes)
      let actual_duration = parseFloat(event.hours) + convertMinutes;

      this.TimeEntryForm.patchValue({
        actual_duration: toString(toFormattedNumber(actual_duration))
      });
    }
  }
  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if(this.checkFormGroupDirty()) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close();
        });

    } else {
      this.dialogRef.close();
    }
  }

  /**
   *
   * @param data - Set data of each field
   *
   */
  setTimeEntry(data) {

    this.arNgSelectFields['assigned_user']['value'] = this.defaultAssignedUser;

    if (data.time_entry != undefined) {
      let actualDuration = 0;
      let strBreak = (data['time_entry']['break'] != '' && data['time_entry']['break'] != null) ? parseFloat(data['time_entry']['break']) : 0;
      // Calculate date range
      let calculatedDateTime = this.calculateDateDiff(data['time_entry']['start_time'], data['time_entry']['end_time']);

      if (calculatedDateTime != undefined) {
        let convertMinutes = parseFloat(calculatedDateTime.minutes) * (1 / 60); // Formula: minutes * (hour / 60 minutes)
        actualDuration = parseFloat(calculatedDateTime.hours) + convertMinutes;
      }

      // Set the values of the form control.
      this.TimeEntryForm.patchValue({
        description: data['time_entry']['description'],
        date_range: { start_date: data['time_entry']['start_time'], end_date: data['time_entry']['end_time'] },
        actual_duration: toString(toFormattedNumber(actualDuration - strBreak)),
        break: toString(toFormattedNumber(strBreak))
      });

      // Set the value of the contact id manually.
      this.arNgSelectFields['items']['value'] = (data['time_entry']['item_id'] != undefined && data['time_entry']['item_id'] != null && data['time_entry']['item_id'] != '') ? { id: data['time_entry']['item_id'], text: data['time_entry']['name'] } : {};
      this.arNgSelectFields['activities']['value'] = (data['time_entry']['activity_id'] != undefined && data['time_entry']['activity_id'] != null && data['time_entry']['activity_id'] != '') ? { id: data['time_entry']['activity_id'], text: data['time_entry']['activity_name'] } : {};
      this.arNgSelectFields['assigned_user']['value'] = (data['time_entry']['user_id'] != undefined && data['time_entry']['user_id'] != null && data['time_entry']['user_id'] != '') ? { id: data['time_entry']['user_id'], text: data['time_entry']['user_text'] } : {};
    }
  }

  /**
   * Initialize related field
   * @param strModule - field key of selected field
   */
  initRelateField(fieldKey) {
    this.arNgSelectFields[fieldKey]['loader'] = true
    // Initialize a variable for filters.
    let arFilter: any = false;
    // If the field has filter, place them in the arFilter.
    if (this.arNgSelectFields[fieldKey]['filter'] != undefined) {
      arFilter = this.arNgSelectFields[fieldKey]['filter'];
    }
    this.recordService.getRecordRelate(this.arNgSelectFields[fieldKey]['module'], false, false, false, arFilter).subscribe( response => {
      this.arNgSelectFields[fieldKey]['loader'] = false;
      // Initialize the list observable.
      this.arNgSelectFields[fieldKey]['obv'] = concat(
        of(response),
        this.arNgSelectFields[fieldKey]['typehead'].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this.arNgSelectFields[fieldKey]['loader'] = true),
          switchMap(term => this.recordService.getRecordRelate(this.arNgSelectFields[fieldKey]['module'], term, '', false, arFilter).pipe(
            tap(() => {
              this.arNgSelectFields[fieldKey]['loader'] = false;
            })
          ))
        )
      )
    });
  }

  // Create time entry data
  saveTimeEntry() {
      this.bSubmitted = true;
      this.isTouched = true;
      this.bShowLoader = true;
      let arErrors = [];

      // Validation of relate fields
      this.arNgSelectFieldKey.forEach( key => {
        if(this.arNgSelectFields[key]['value'] === undefined || this.arNgSelectFields[key]['value'] === null) {
          arErrors.push(true);
        }
      });

      // Validation of date range start_date and end_date
      if(this.TimeEntryForm.value.date_range.start_date == undefined || this.TimeEntryForm.value.date_range.start_date == 'Invalid Date' || this.TimeEntryForm.value.date_range.start_date == '') {
        this.TimeEntryForm.controls.date_range.setErrors({'incorrect': true});
        this.TimeEntryForm.controls.date_range.markAsTouched();
        arErrors.push(true);
      }

      if(this.TimeEntryForm.value.date_range.end_date == undefined || this.TimeEntryForm.value.date_range.end_date == 'Invalid Date' || this.TimeEntryForm.value.date_range.end_date == '') {
        this.TimeEntryForm.controls.date_range.setErrors({'incorrect': true});
        this.TimeEntryForm.controls.date_range.markAsTouched();
        arErrors.push(true);
      }

      if (parseFloat(this.TimeEntryForm.value.actual_duration) === 0) {
        arErrors.push(true);
        this.notifService.notifyWarning('actual_duration_cannot_be_zero');
      }

      if (arErrors.length < 1) {
        // Loop through the line items and remove all observables.
        // Note: If we dont remove observables, a circular object error will be thrown.
        // Remove also the loader since we just use if for viewing purposes.
        this.arNgSelectFieldKey.forEach(key => {
          delete this.arNgSelectFields[key]['obv'];
          delete this.arNgSelectFields[key]['typehead'];
          delete this.arNgSelectFields[key]['loader'];
        });

        // Get the raw value of the form.
        let objTimeEntry = this.TimeEntryForm.getRawValue();
        this.arNgSelectFieldKey.forEach(key => {
          let fieldId = this.arNgSelectFields[key]['name'];
          objTimeEntry[fieldId] = (this.arNgSelectFields[key]['value'] != null && this.arNgSelectFields[key]['value'] != '') ? this.arNgSelectFields[key]['value']['id'] : null;
        });

        objTimeEntry['job_id'] = this.data.record_id;
        objTimeEntry['start_time'] = objTimeEntry.date_range.start_date;
        objTimeEntry['end_time'] = objTimeEntry.date_range.end_date;
        objTimeEntry['shift_id'] = get(this.data, 'shift_id');

        if (objTimeEntry['break'] == '') {
          objTimeEntry['break'] = '0.00'
        }

        // Delete not needed field in request
        delete objTimeEntry['date_range'];

        let is_time_entry_update = this.data['time_entry'] !== undefined;
        let id = is_time_entry_update ? this.data['time_entry']['id'] : '';
        // The only difference in API calls between a time entry's creation and update
        // is that for updates, we pass the time entry's id.
        this.recordService
          .saveRecord('time_entries', objTimeEntry, id)
          .subscribe(() => {
            this.dialogRef.close('save');
          });
      } else {
        this.bSubmitted = false;
        this.bShowLoader = false;
        this.notifService.sendNotification('not_allowed', 'required_notice', 'danger');
      }
  }

  // Add leading zeros
  pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  // Calculate Datetime difference
  calculateDateDiff(startdate, enddate) {
    //define moments for the startdate and enddate
    var startdateMoment = moment(startdate);
    var enddateMoment = moment(enddate);

    if (startdateMoment.isValid() === true && enddateMoment.isValid() === true) {
      //getting the difference in years
      var years = enddateMoment.diff(startdateMoment, 'years');

      //to calculate the months, first get the previous year and then subtract it
      startdateMoment.add(years, 'years')
      var months = enddateMoment.diff(startdateMoment, 'months')

      //to calculate the days, first get the previous month and then subtract it
      startdateMoment.add(months, 'months');
      var days = enddateMoment.diff(startdateMoment, 'days')

      //Similar to days go the previous day
      // startdateMoment.add(days, 'days')
      var hours = enddateMoment.diff(startdateMoment, 'hours')

      //Getting minutes
      startdateMoment.add(hours, 'hours')
      var minutes = enddateMoment.diff(startdateMoment, 'minutes')

      //Similar to days go the previous day
      startdateMoment.add(minutes, 'minutes')
      var seconds = enddateMoment.diff(startdateMoment, 'seconds')

      return {
        years: years,
        months: months,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };

    }
    else {
      return undefined;
    }
  }

  /**
   * Check if the form is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    return this.TimeEntryForm.dirty || this.bFormDirty;
  }

  /**
   * Mark as dirty
   *
   * @returns {void}
   */
  markAsDirty(): void {
    this.bFormDirty = true;
  }

  /**
   * Triggers whenever a relate field is changed in the form
   *
   * @param {any} event
   * @param {string} strFieldKey
   *
   * @returns {void}
   */
  changeRelateField(event: any, strFieldKey: string): void {
    if (get(this.data, 'module') === 'timesheets' && strFieldKey === 'activities') {
      // set job id (record_id) to activity job id
      this.data.record_id = get(event, 'job_id');
    }
  }
}
