import { Subject, of, concat, Observable, BehaviorSubject } from 'rxjs';
import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap, switchMap, finalize } from 'rxjs/operators';
import * as _moment from 'moment';

import { cloneDeep as _cloneDeep, merge as _merge, isEmpty, get, toNumber, isNaN, toString, isNil, cloneDeep, isArray } from 'lodash';
import { FormService } from '../../../../services/form.service';
import { RecordService } from '../../../../services/record.service';
import { Select } from '../../../../objects/select';
import { Department } from '../../../../objects/department';
import { NotificationService } from '../../../../services/notification.service';
import { CustomTranslateService } from '../../../../services/custom-translate.service';
import { RelateIds } from '../../../../lists/relate-ids';
import { ViewService } from '../../../../services/view.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { ClientStoreService } from '../../../../services/client-store.service';
import { NumberService } from '../../../../services/helpers/number.service';
import { ArrService } from '../../../../services/helpers/arr.service';
import { StrService } from '../../../../services/helpers/str.service';
import { InputSanitizerService } from '../../../../services/input-sanitizer.service';
import { PdfComponent } from './../../../../shared/components/view/pdf/pdf.component';
import { SelectTemplate } from '../../../../objects/select-template';
import { LooseObject } from '../../../../objects/loose-object';
import { toFormattedNumber } from '../../../../shared/utils/numbers';
import { ReadableAddressPipe } from '../../../../pipes/readable-address.pipe';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-edit-supplier-invoice',
  templateUrl: './edit-supplier-invoice.component.html',
  styleUrls: ['./edit-supplier-invoice.component.scss'],
  providers: [CustomTranslateService]
})
export class EditSupplierInvoiceComponent implements OnInit {
  @ViewChild("invoiceLineDesc") textAreaInvoiceLineElement: ElementRef;

  public supplierInvoiceForm: any = [];
  public strRecordId: string = (this.data.record_id != undefined && this.data.record_id != null && this.data.record_id != '') ? this.data.record_id : null;
  public strViewType: string = this.data.view_type;
  public strRecordModule: string = (this.data.module != undefined) ? this.data.module : '';
  public objJobData: {} = {};
  public supplierInvoiceRecord: {} = {};
  public objCustomerData: {} = {};
  public objModuleConfig: {} = {};
  public arSupplierInvoiceFieldKey: any = [];
  public objSupplierInvoiceField = {};
  public bSubmitted: boolean = false;
  public bHasChanged: boolean = false;
  public bInvoiceLineLoaded: boolean = false;
  public hasError: boolean = false;
  public isTouched: boolean = false;
  public itemType: any = '';
  public arLineAttributes: any = [];
  public arTaxCodeFilter: any = { is_purchase: true };
  public arAccountCodeFilter: any = { is_purchase: true };
  public arItemFilter: any = { active: true, labor: false };
  public arReadOnlyId: any = [];
  public arRelateEmptyId: any = [];
  public arRequiredField: any = [];

  public initialRelateAccountCodeData: any = [];
  public initialRelateTaxCodeData: any = [];
  public initialRelateItemData: any = [];
  public initialRelateDepartmentData: any = [];
  public objRelateText: any = {};

  public arTranslateables = [
    'exceed_supplier_invoice_amount',
    'total_purchase_order_amount',
    'total_supplier_invoice',
    'total_remaining_amount',
  ];

  public objDefaultTaxCode = {};
  public objDefaultAccountCode = {};
  public objCacheData: {} = {};
  public objClientRecord: {} = {};
  public pdfPreview: boolean = false;
  public objPurchaseOrderOnchange: {} = {};
  public bFormDirty: boolean = false;
  public previewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();
  public isPreviewTemplate: boolean = false;
  public realTimePreviewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();

  /**
   * If customer field is readonly
   *
   * @var {boolean}
   */
  public bIsCustomerFieldReadOnly: boolean = false;

  /**
   * If department tracking is enabled.
   *
   * @var {boolean}
   */
  public bDepartmentTracking: boolean = false;

  /**
   * When the supplier invoice is loading.
   *
   * @var {boolean}
   */
  public bLoading: boolean = false;

  /// this contains the tax adjustment amount for the whole invoice
  totalTaxAdjustment: number = 0;

  whenInProgress$ = new BehaviorSubject<boolean>(false);

  get invoiceLineIncludedTax(): number {
    return this.computeInvoiceLineIncludedTax();
  }

  set invoiceLineIncludedTax(value: number) {
    const adjustment = toFormattedNumber(value, {
      currency: true,
    });

    const totalPrice = this.computeInvoiceLineExcludedTax();
    const totalTax = this.computeInvoiceLineTax();

    this.totalTaxAdjustment = toFormattedNumber(adjustment - (totalPrice + totalTax), {
      currency: true,
    });

    this.setAmountDue();
  }

  /**
   * FC-4265: Used when we update the SI data and we changed the Purchase Order id.
   *
   * @var {{ id: string, si_amount_to_add_to_po_remaining_amount: number }}
   */
  private objOriginalPurchaseOrderData: { id: string, si_amount_to_add_to_po_remaining_amount: number } = {
    id: '',
    si_amount_to_add_to_po_remaining_amount: 0
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    public clientStoreService: ClientStoreService,
    public dialogRef: MatDialogRef<EditSupplierInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public formService: FormService,
    public recordService: RecordService,
    public notifService: NotificationService,
    public customTranslate: CustomTranslateService,
    public viewService: ViewService,
    public numberService: NumberService,
    public arrService: ArrService,
    public strService: StrService,
    protected is: InputSanitizerService,
    protected localStorageService: LocalStorageService,
    private readableAddressPipe: ReadableAddressPipe,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.objClientRecord = this.clientStoreService.getActiveClient();
    this.bDepartmentTracking = this.clientStoreService.isDepartmentTracking();
    // Declare tax code and account code variable
    let defaultTaxCodePurchase = [];
    let defaultAccountCodePurchase = [];

    if (this.strViewType == 'edit') {
      this.recordService.getRecordAndParentRecord(
        'supplier_invoices', this.data['supplier_invoice_id'], !isEmpty(this.data.module) && !isEmpty(this.data.record_id)
      )
      .subscribe(([supplierInvoice, moduleRecord]) => {
        const numOriginalSIAmount: number = toNumber(get(supplierInvoice, 'record_details.amount', 0));
        this.objOriginalPurchaseOrderData = {
          id: get(supplierInvoice, 'record_details.purchase_order_id'),
          si_amount_to_add_to_po_remaining_amount: isNaN(numOriginalSIAmount) ? 0 : numOriginalSIAmount
        };
        // Set Job Data if record view is job get the record in view service
        this.objJobData = (this.strRecordModule == 'jobs') ? moduleRecord['record_details'] : [];
        // Set Customer Data if record view is customer get the record in view service
        this.objCustomerData = (this.strRecordModule == 'customers') ? moduleRecord['record_details'] : [];
        // Set supplier invoice record
        this.supplierInvoiceRecord = supplierInvoice;
        // Set supplier record details
        this.data['supplier_invoice'] = supplierInvoice['record_details'];
        // Set config of supplier invoice
        this.objModuleConfig['record_details'] = this.supplierInvoiceRecord['record_details'];
        this.objModuleConfig['record_view'] = this.supplierInvoiceRecord['record_view'];
        this.objModuleConfig['used_fields'] = this.supplierInvoiceRecord['used_fields'];
        this.totalTaxAdjustment = toFormattedNumber(get(this.supplierInvoiceRecord, 'record_details.tax_adjustment_amount'), {
          currency: true,
        });
        // Check if has related data
        if (this.supplierInvoiceRecord['related_data'] != undefined) {
          // Set all related data to objCacheData
          this.objCacheData = this.supplierInvoiceRecord['related_data'];
          // Check if default tax code exist
          if (this.supplierInvoiceRecord['related_data']['default_tax_code_purchase'] != undefined) {
            // Set default tax code
            defaultTaxCodePurchase = this.supplierInvoiceRecord['related_data']['default_tax_code_purchase'];
          }

          // Check if default account code exist
          if (this.supplierInvoiceRecord['related_data']['default_account_code_purchase'] != undefined) {
            // Set default account code
            defaultAccountCodePurchase = this.supplierInvoiceRecord['related_data']['default_account_code_purchase'];
          }

          // If job data is empty. Check the related job data in supplier invoice record. What does this mean the dialog is not from widget.
          if (this.objJobData['id'] == undefined && this.supplierInvoiceRecord['record_details']['job_id'] !== '' && this.supplierInvoiceRecord['record_details']['job_id'] !== null) {
            this.objJobData = {
              id: this.supplierInvoiceRecord['record_details']['job_id'],
              text: this.supplierInvoiceRecord['record_details']['job_text']
            }
          }
        }
        // If the record view is customers. Set customer record id
        if (this.strRecordModule == 'customers') {
          this.strRecordId = (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '';
        // If the record view is jobs. Set job record id
        } else if (this.strRecordModule == 'jobs') {
          this.strRecordId = (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '';
        }

        // Set default tax code
        this.objDefaultTaxCode = defaultTaxCodePurchase;
        // Set default account code
        this.objDefaultAccountCode = defaultAccountCodePurchase;
        this.initializeComponent();
        setTimeout( () => {
          this.onChanges();
        }, 2000);
      });

    } else {
      this.recordService.getConfigAndParentRecord(
        'supplier_invoices', !isEmpty(this.data.module) && !isEmpty(this.data.record_id)
      )
      .subscribe(([supplierInvoice, moduleRecord]) => {
        // Set Job Data if record view is job get the record in view service
        this.objJobData = (this.strRecordModule == 'jobs') ? moduleRecord['record_details'] : [];
        // Set Customer Data if record view is customer get the record in view service
        this.objCustomerData = (this.strRecordModule == 'customers') ? moduleRecord['record_details'] : [];
        // Set config of supplier invoice
        this.objModuleConfig = supplierInvoice;
        // Check if has related data
        if (this.objModuleConfig['related_data'] != undefined) {
          // Set all related data to objCacheData
          this.objCacheData = this.objModuleConfig['related_data'];
          // Check if default tax code exist
          if (this.objModuleConfig['related_data']['default_tax_code_purchase'] != undefined) {
            // Set default tax code
            defaultTaxCodePurchase = this.objModuleConfig['related_data']['default_tax_code_purchase'];
          }

          // Check if account code exist
          if (this.objModuleConfig['related_data']['default_account_code_purchase'] != undefined) {
            // Set account code
            defaultAccountCodePurchase = this.objModuleConfig['related_data']['default_account_code_purchase'];
          }
        }
        // If the record view is customers. Set customer record id
        if (this.strRecordModule == 'customers') {
          this.strRecordId = (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '';
        // If the record view is jobs. Set job record id
        } else if (this.strRecordModule == 'jobs') {
          this.strRecordId = (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '';
        }

        // Set default tax code
        this.objDefaultTaxCode = defaultTaxCodePurchase;
        // Set default account code
        this.objDefaultAccountCode = defaultAccountCodePurchase;
        this.initializeComponent();
        this.onChanges();

        // We need to set related purchase order to
        // supplier invoice when it from the convert PO
        let strPurchaseOrderId = this.arrService.keyFallsBackTo(this.data, 'purchase_order_id');
        if (this.data['from_convert_po'] !== undefined && this.data['from_convert_po'] === true && strPurchaseOrderId) {
          this.bInvoiceLineLoaded = false;
          let indexPoForm = this.getFormFieldIndex('purchase_order_id');
          let indexPoField = this.getFieldIndex('purchase_order_id', this.supplierInvoiceForm[indexPoForm]);

          if (indexPoForm > -1 && indexPoField > -1) {
            this.supplierInvoiceForm[indexPoForm]['fields'][indexPoField]['default_value'] = this.data['purchase_order_id'];
            this.supplierInvoiceForm[indexPoForm]['fields'][indexPoField]['default_text'] = this.data['purchase_order_text'];
          }

          this.fieldPatchValue('purchase_order_id', strPurchaseOrderId);
          this.setLineItemFromPurchaseOrder(strPurchaseOrderId);
        }

        const numSyncToAccountingIndex: number = this.getFormFieldIndex('sync_to_accounting');

        if (numSyncToAccountingIndex > -1) {
          this.supplierInvoiceForm[numSyncToAccountingIndex]['groups'].patchValue({
            // FC-4359: when creating supplier invoices, the default value of sync_to_accounting flag will follow the value of 'sync_supplier_invoice_on_creation'.
            sync_to_accounting: get(this.objClientRecord, 'sync_supplier_invoice_on_creation', true)
          });
        }
      });
    }

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  initializeComponent() {
    this.customTranslate.initializeTransalateables(this.arTranslateables);

      this.initField();

      if (this.strViewType == 'edit' && this.data.supplier_invoice != undefined) {
        this.supplierInvoiceForm.map( form => {
          let objField = Object.keys(form['groups']['controls']);
          objField.forEach( strField => {
            let indexField = this.getFieldIndex(strField, form);
            if (indexField > -1) {
              if (form['fields'][indexField]['controlType'] == 'relate') {
                let textField = strField.replace('_id', '_text');
                if (textField == 'customer_text') textField = 'supplier_text';
                let strText = this.data.supplier_invoice[textField];
                form['fields'][indexField]['default_value'] = this.data.supplier_invoice[strField];
                form['fields'][indexField]['default_text'] = strText;
              }

              if (strField == 'job_id') {
                form['fields'][indexField]['readonly'] = true;
              }
              if (strField == 'customer_id' && this.strRecordModule == 'customers') {
                form['fields'][indexField]['readonly'] = true;
              }
              // Set default value for date and datetime
              if (form['fields'][indexField]['controlType'] == 'date' || form['fields'][indexField]['controlType'] == 'datetime') {
                form['fields'][indexField]['default_value'] = this.data.supplier_invoice[strField];
              }

              if (this.data.supplier_invoice[strField] != undefined) {
                this.fieldPatchValue(strField, this.data.supplier_invoice[strField]);
              }
            }
          });
        });
        // Get index of accounting sync error and accounting sync detail
        let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
        let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
        let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.supplierInvoiceForm[indexOfAccountingSyncDetail]);
        // If field are exist
        if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
          // Get the value of sync error
          let bAccountingSyncError = this.data.supplier_invoice['accounting_sync_error'];
          // If true show accounting sync detail field else hide it.
          if (bAccountingSyncError) {
            this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = false;
          } else {
            this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
          }
        }
      } else {
        let indexJobForm = this.getFormFieldIndex('job_id');
        let indexJobField = this.getFieldIndex('job_id', this.supplierInvoiceForm[indexJobForm]);

        let indexCustomerForm = this.getFormFieldIndex('customer_id');
        let indexCustomerField = this.getFieldIndex('customer_id', this.supplierInvoiceForm[indexCustomerForm]);
        // Set default customer data if exist
        if (this.objCustomerData['id'] != undefined) {
          if (indexCustomerForm > -1 && indexCustomerField > -1) {
            this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_value'] = this.objCustomerData['id'];
            this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_text'] = this.objCustomerData['customer_text'];
            this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['readonly'] = true;
            this.bIsCustomerFieldReadOnly = true;
          }
          this.fieldPatchValue('customer_id', (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '');
        }
        // Set default job data if exist
        if (this.objJobData['id'] != undefined) {
          if (indexJobForm > -1 && indexJobField > -1) {
            this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['default_value'] = this.objJobData['id'];
            this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['default_text'] = this.objJobData['job_number'];
            this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['readonly'] = true;
          }
          this.fieldPatchValue('job_id', (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '');
        }
        // Get index of accounting sync error and accounting sync detail
        let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
        let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
        // If mode is create. Hide accounting sync detail as default
        let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.supplierInvoiceForm[indexOfAccountingSyncDetail]);
        if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
          this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
      }

      this.setLineItems();
  }

  /**
   * Initialize relate field
   * @param {string} strModule - module to get related data
   * @param strAttributeName  - attribute name i.e. arTimeEntryAttributes
   * @param strObservableName - Observable name i.e department
   * @param strIndex - Index of attribute
   * @param objFilter - Desired Filter
   */
  initRelateData(strModule, strAttributeName, strObservableName, strIndex, objFilter: any = false) {
    let strLoader = strObservableName + '_loader';
    if (strAttributeName != undefined && strAttributeName != '') this[strAttributeName][strIndex][strLoader] = true;
    if (strModule == 'items') {
      this.recordService.getProductRecordData('', objFilter, null, false).subscribe(result => {
        if (strAttributeName != undefined && strAttributeName != '') {
          this.initRelateRecords(strAttributeName, strModule, strObservableName, objFilter, result, strIndex);
          this[strAttributeName][strIndex][strLoader] = false;
        }
      });
    } else {
      this.recordService.getRecordRelate(strModule, '', false, false, objFilter).subscribe( result => {
        if (strAttributeName != undefined && strAttributeName != '') {
          this.initRelateRecords(strAttributeName, strModule, strObservableName, objFilter, result, strIndex);
          this[strAttributeName][strIndex][strLoader] = false;
        }
      });
    }
  }

  /**
   * Initialize Main Field
   */
  initField() {
    // To set data of custom attributes as default value
    if (this.objModuleConfig['record_details']['custom_attributes'] != undefined && this.objModuleConfig['record_details']['custom_attributes'] != null && this.objModuleConfig['record_details']['custom_attributes'] != '') {
      let customAttributeKeys = Object.keys(this.objModuleConfig['record_details']['custom_attributes']);
      customAttributeKeys.forEach( strKey => {
        // Append each custom attributes to record details
        this.objModuleConfig['record_details'][strKey] = this.objModuleConfig['record_details']['custom_attributes'][strKey];
      });
    }

    // Add filter to customer relate
    if (this.objModuleConfig['used_fields']['customer_id'] !== undefined) {
      this.objModuleConfig['used_fields']['customer_id']['filter'] = {'is_supplier': true};
    }

    let arFormData = this.formService.formData(this.objModuleConfig['record_view'], this.objModuleConfig['used_fields'], this.objModuleConfig['record_details']);
    this.supplierInvoiceForm  = arFormData.map(
      formItems => {
        formItems['id'] = formItems['label'].toLowerCase().replace(/ /g,'_');
        formItems['groups'] = this.formService.toFormGroup(formItems['fields'])
        return formItems;
      }
    );
  }

  /**
   * On changes of form
   */
  onChanges(): void {
    // Get index of this field
    let indexOfPurchaseOrderId = this.getFormFieldIndex('purchase_order_id');
    let indexOfJob = this.getFormFieldIndex('job_id');
    let indexOfSyncToAccounting = this.getFormFieldIndex('sync_to_accounting');
    let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
    let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
    let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.supplierInvoiceForm[indexOfAccountingSyncDetail]);
    let indexOfAmountPaid = this.getFormFieldIndex('amount_paid');
    let indexOfAmountDue = this.getFormFieldIndex('amount_due');

    // When purchase_order_id field is exist. Trigger when value change
    if (indexOfPurchaseOrderId > -1) {
        this.supplierInvoiceForm[indexOfPurchaseOrderId]['groups'].get('purchase_order_id').valueChanges.subscribe(val => {
          // Check if val has value
          let strPurchaserOrderId = (val != null && val != undefined && val != '') ? val : '';
          if (strPurchaserOrderId) {
            this.bInvoiceLineLoaded = false;
            // If has purchase order id
            this.setLineItemFromPurchaseOrder(strPurchaserOrderId);
          }
        });
    }
    // When job id field is exist. Trigger when value change
    if (indexOfJob > -1) {
      this.supplierInvoiceForm[indexOfJob]['groups'].get('job_id').valueChanges.subscribe(val => {
        this.objJobData = [];
        // Check if val has value then assigned it to strRecordId(job_id)
        this.strRecordId = this.strService.fallsBackTo(val);
        if (this.strRecordId !== '') {
          // If has job id get the data
          this.recordService.getRecordRelateJoined('jobs', this.strRecordId).subscribe( result => {
            // Set data
            this.objJobData = (!isEmpty(result[0])) ? result[0] : [];
          });
        }
      });
    }
    // Onchange of accounting sync error. If true show accounting sync detail else hide it.
    if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
      this.supplierInvoiceForm[indexOfAccountingSyncError]['groups'].get('accounting_sync_error').valueChanges.subscribe(val => {
        if (val) {
          this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = false;
        } else {
          this.supplierInvoiceForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
      });
    }
    // Onchange of sync to accounting. Clear the value of accounting sync detail
    if (indexOfSyncToAccounting > -1) {
      this.supplierInvoiceForm[indexOfSyncToAccounting]['groups'].get('sync_to_accounting').valueChanges.subscribe(val => {
        this.supplierInvoiceForm[indexOfSyncToAccounting]['groups'].patchValue({
          accounting_sync_detail: ''
        });
      });
    }

    // If amount paid field exist
    if (indexOfAmountPaid > -1) {
      this.supplierInvoiceForm[indexOfAmountPaid]['groups'].get('amount_paid').valueChanges.subscribe(val => {
        let totalIncludedTax: number = this.computeInvoiceLineIncludedTax();
        // Clear accounting sync detail when sync to accounting is change
        let strAmountDue: number = totalIncludedTax - parseFloat(val);
        this.supplierInvoiceForm[indexOfAmountDue]['groups'].patchValue({
          amount_due: !isNaN(strAmountDue) ? toFormattedNumber(strAmountDue, {
            currency: true,
          }) : 0
        });
      });
    }
  }

  /**
   * Removes the line item.
   * @param attr - the object to be removed.
   */
  removeAttribute(attr) {
    this.arLineAttributes.splice(this.arLineAttributes.indexOf(attr), 1);
    this.setAmountDue();
  }

  addLineAttribute(objData: any = [], bRecompute: boolean = true, bFromUi: boolean = false) {

    // Generate a unique name for the lineitem.
    let newId = 'nameField' + this.arLineAttributes.length + Math.floor((Math.random() * 10) + 1);

    // If the generated name is indeed unique (no existing item of a similar name found.).
    if (this.arLineAttributes.findIndex(attr => (attr['type'] == newId)) < 0) {

      if (Object.keys(objData).length > 0) {
        let strTaxCodeId = '';
        let strTaxCode = '';
        let strTaxRate: number = 0;
        let objTaxCode = {};

        let strAccountCodeId = '';
        let strAccountCode = '';
        let objAccountCode = {};

        if (objData['tax_code_id'] != undefined && objData['tax_code_id'] != null && objData['tax_code_id'] != '') {
          strTaxCodeId = objData['tax_code_id'];
          strTaxCode = objData['tax_code'];
          strTaxRate = parseFloat(objData['tax_rate']) * 0.01;
          objTaxCode = new Select(objData['tax_code_id'], objData['tax_code_name']);
        } else if (this.objDefaultTaxCode['id'] != undefined && this.objDefaultTaxCode['id'] != null && this.objDefaultTaxCode['id'] != '') {
          strTaxCodeId = this.objDefaultTaxCode['id'];
          strTaxCode = this.objDefaultTaxCode['code'];
          strTaxRate = parseFloat(this.objDefaultTaxCode['rate']) * 0.01;
          objTaxCode = new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']);
        }

        if (objData['account_code_id'] != undefined && objData['account_code_id'] != null && objData['account_code_id'] != '') {
          strAccountCodeId = objData['account_code_id'];
          strAccountCode = objData['account_code'];
          objAccountCode = { id: objData['account_code_id'], name: objData['account_code_name'] };
        } else if (this.objDefaultAccountCode['id'] != undefined && this.objDefaultAccountCode['id'] != null && this.objDefaultAccountCode['id'] != '') {
          strAccountCodeId = this.objDefaultAccountCode['id'];
          strAccountCode = this.objDefaultAccountCode['code'];
          objAccountCode = { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] };
        }

        // Set value of relate field
        let strItemId = bFromUi ? objData['id'] : objData['item_id']
        let strItemName = objData.name || objData.item_name;
        let strItemCode = objData.code || objData.item_code;
        let strDescription = objData.description || objData.name;
        let strQuantity: number = objData.quantity || 1;
        let strUnitCost: number = toFormattedNumber(objData.unit_cost, {
          currency: true,
          maxDecimalPlaces: 4,
        });
        let strDepartmentName = objData['department_name'] || null;
        let strDepartmentId = objData['department_id'] || null;
        let strItemAccountingId = objData['item_accounting_id'];
        let objDepartment = (strDepartmentId) ? new Select(strDepartmentId, strDepartmentName) : {};
        let objItem = (strItemId) ? new Select(strItemId, strItemName) : {};

        const invoicedQuantity = toFormattedNumber(get(objData, 'invoiced_quantity', 0));

        strQuantity = toFormattedNumber(strQuantity) - invoicedQuantity;

        let indexOfSupplierField = this.getFormFieldIndex('customer_id');

        if (indexOfSupplierField > -1 && get(objData, 'supplier_pricing')) {
          let customerId = this.supplierInvoiceForm[indexOfSupplierField]['groups'].controls.customer_id.value;
          let itemSupplierIndex = objData['supplier_pricing'].findIndex(supplier => (supplier.customer_id == customerId));

          if (itemSupplierIndex > -1) {
            strUnitCost = objData['supplier_pricing'][itemSupplierIndex]['unit_cost'];
          }
        }

        this.arLineAttributes.push({
          id : "",
          supplier_invoice: {
            item_id: strItemId,
            item_name: strItemName,
            item_code: strItemCode,
            tax_code: strTaxCode,
            account_code: strAccountCode,
            description: strDescription,
            quantity: strQuantity,
            unit_cost: strUnitCost,
            tax_code_id: strTaxCodeId,
            account_code_id: strAccountCodeId,
            department_id: strDepartmentId,
            department_name: strDepartmentName,
            item_accounting_id: strItemAccountingId,
            current_stock_level: objData['current_stock_level'] || 0,
            purchase_order_line_item_id: get(objData, 'id'),
          },
          type: newId,
          item: objItem,
          tax: objTaxCode,
          account_code: objAccountCode,
          department: objDepartment,
          item_obv: new Observable<Select[]>(),
          item_typehead: new Subject<string>(),
          item_loader: false,
          taxcode_obv: new Observable<Select[]>(),
          taxcode_typehead: new Subject<string>(),
          taxcode_loader: false,
          account_code_obv: new Observable<Select[]>(),
          account_code_typehead: new Subject<string>(),
          account_code_loader: false,
          department_obv: new Observable<Select[]>(),
          department_typehead: new Subject<string>(),
          department_loader: false,
          amount: toFormattedNumber(strUnitCost * strQuantity, {
            currency: true,
          }),
          tax_rate: strTaxRate,
        });
      } else {
        let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
        let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';
        let objDepartmentJob = new Department().getJobDepartment(this.objJobData);

        this.arLineAttributes.push({
            id : "",
            supplier_invoice: {
              item_id: null,
              item_code: null,
              item_name: null,
              account_code: (this.objDefaultAccountCode['code'] != undefined) ? this.objDefaultAccountCode['code'] : null,
              tax_code: (this.objDefaultTaxCode['code'] != undefined) ? this.objDefaultTaxCode['code'] : null,
              department_id: this.strService.fallsBackTo(objDepartmentJob.department_id),
              department_name: this.strService.fallsBackTo(objDepartmentJob.department_text),
              description: null,
              quantity: 1,
              unit_cost: '0.00',
              tax_code_id: (this.objDefaultTaxCode['id'] != undefined) ? this.objDefaultTaxCode['id'] : null,
              account_code_id: (this.objDefaultAccountCode['id'] != undefined) ? this.objDefaultTaxCode['id'] : null,
              item_accounting_id: strDefaultItemAccountingId
            },
            type: newId,
            item: null,
            tax: (this.objDefaultTaxCode['id'] != undefined) ? new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']) : {},
            account_code: (this.objDefaultAccountCode['id'] != undefined) ? {id : this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name']} : {},
            department: !isEmpty(objDepartmentJob.department) ? objDepartmentJob.department : {},
            item_obv: new Observable<Select[]>(),
            item_typehead: new Subject<string>(),
            item_loader: false,
            taxcode_obv: new Observable<Select[]>(),
            taxcode_typehead: new Subject<string>(),
            taxcode_loader: false,
            account_code_obv: new Observable<Select[]>(),
            account_code_typehead: new Subject<string>(),
            account_code_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            amount: 0,
            tax_rate: (this.objDefaultTaxCode['id'] != undefined) ? parseFloat(this.objDefaultTaxCode['rate']) * 0.01 : 0
        });
      }

      // For Tax Code Relate
      this.initRelateRecords('arLineAttributes', 'tax_codes', 'taxcode', this.arTaxCodeFilter, this.initialRelateTaxCodeData);
      // For Items Relate
      this.initRelateRecords('arLineAttributes', 'items', 'item', this.arItemFilter, this.initialRelateItemData);
      // For Account Code Relate
      this.initRelateRecords('arLineAttributes', 'account_codes', 'account_code', this.arAccountCodeFilter, this.initialRelateAccountCodeData);
      // For Department Relate
      this.initRelateRecords('arLineAttributes', 'departments', 'department', false, this.initialRelateDepartmentData);
      this.bInvoiceLineLoaded = true;
      if (bRecompute) {
        this.setAmountDue();
      }
    }
  }

  /**
   * Compute total cost, price and amount when quantity is change
   * @param event
   * @param attr
   * @param strModule
   */
  quantityChange(event, attr) {
    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));
    if (numLineItem > -1) {
      let quantity: number = (event.target.value != '' && event.target.value > 0) ? event.target.value : 1;
      let totalCost: number = parseFloat(this.arLineAttributes[numLineItem]['supplier_invoice']['unit_cost']) * quantity;
      this.arLineAttributes[numLineItem]['supplier_invoice']['quantity'] = quantity;
      this.arLineAttributes[numLineItem]['amount'] = toFormattedNumber(totalCost, {
        currency: true,
      })
      this.setAmountDue();
    }
    this.markAsDirty()
  }

  /**
   * Compute data when unit price is change
   * @param event
   * @param attr
   * @param strModule
   */
  unitCostChange(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));
    if (numLineItem > -1) {
      let unitCost: number = (event.target.value != '') ? event.target.value : 0;
      let totalAmount: number = (unitCost * this.arLineAttributes[numLineItem]['supplier_invoice']['quantity']);
      this.arLineAttributes[numLineItem]['amount'] = toFormattedNumber(totalAmount, {
        currency: true,
      });
      this.arLineAttributes[numLineItem]['supplier_invoice']['unit_cost'] = toFormattedNumber(unitCost, {
        currency: true,
        maxDecimalPlaces: 4,
      });
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectATaxcode(event, attr) {
    let strTaxCodeId = (event == undefined || event == null) ? null : event['id'];
    let strTaxName = (event == undefined || event == null) ? null : event['name'];
    let strTaxCode = (event == undefined || event == null) ? null : event['code'];
    let strTaxRate = (event == undefined || event == null) ? 0 : parseFloat(event['rate']) * 0.01;

    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));

      // If the item is found.
      if (numLineItem > -1) {
          // Set the values of the line item.
          this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code_id'] = strTaxCodeId;
          this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code'] = strTaxCode;
          this.arLineAttributes[numLineItem]['tax_rate'] = strTaxRate;
          this.setAmountDue();
      }
    this.markAsDirty();
  }
  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectAccountCode(event, attr) {
    let strAccountCodeId = (event == undefined || event == null) ? null : event['id'];
    let strAccountName = (event == undefined || event == null) ? null : event['name'];
    let strAccountCode = (event == undefined || event == null) ? null : event['code'];

    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));
    // If the item is found.
    if (numLineItem > -1) {
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code_id'] = strAccountCodeId;
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code'] = strAccountCode;
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectDepartment(event, attr) {
    let strDepartmentCodeId = (event == undefined || event == null) ? null : event['id'];
    let strDepartmentName = (event == undefined || event == null) ? null : event['department_name'];

    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));

    // If the item is found.
    if (numLineItem > -1) {
      // Set the values of the line item.
      this.arLineAttributes[numLineItem]['supplier_invoice']['department_id'] = strDepartmentCodeId;
      this.arLineAttributes[numLineItem]['supplier_invoice']['department_name'] = strDepartmentName;
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectProduct(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));
    let arDefaultItemAccounting;
    if (this.strViewType === 'edit') {
      arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.supplierInvoiceRecord['related_data'], 'default_item_accounting', []);
    } else {
      arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
    }

    let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';

    if (numLineItem > -1) {
      if (event != undefined && event != '' && event != null) {
        event['unit_cost'] = (event.unit_cost != undefined && event.unit_cost != '') ? event.unit_cost : 0;
        let arTaxCode: [] | {} = [];
        let arAccountCode: [] | {} = [];

        event['description'] = (event['description'] != "" && event['description'] != undefined) ? event['description'] : "";

        if (event['default_purchase_tax_code_id'] != null && event['default_purchase_tax_code_id'] != '') {
          arTaxCode = {
            'id' : event['tax_code_id'],
            'rate' : event['tax_rate'],
            'code' : event['tax_code'],
            'name' : event['tax_code_name'],
            'text' : event['tax_code_name']
          }
        } else {
          if (this.strViewType == 'edit') {
            if (this.supplierInvoiceRecord['related_data'] != undefined && this.supplierInvoiceRecord['related_data']['default_tax_code_purchase'] != undefined) {
              arTaxCode = this.supplierInvoiceRecord['related_data']['default_tax_code_purchase'];
            }
          } else {
            if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_tax_code_purchase'] != undefined) {
              arTaxCode = this.objModuleConfig['related_data']['default_tax_code_purchase'];
            }
          }
        }

        if (event['default_purchase_account_code_id'] != null && event['default_purchase_account_code_id'] != '') {
          arAccountCode = {
            'id' : event['account_code_id'],
            'code' : event['account_code'],
            'name' : event['account_code_name']
          }
        } else {
          if (this.strViewType == 'edit') {
            if (this.supplierInvoiceRecord['related_data'] != undefined && this.supplierInvoiceRecord['related_data']['default_account_code_purchase'] != undefined) {
              arAccountCode = this.supplierInvoiceRecord['related_data']['default_account_code_purchase'];
            }
          } else {
            if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_account_code_purchase'] != undefined) {
              arAccountCode = this.objModuleConfig['related_data']['default_account_code_purchase'];
            }
          }
        }
        // Set the values of the line item.
        let total_cost: number = (parseFloat(this.arLineAttributes[numLineItem]['supplier_invoice']['quantity']) * event['unit_cost']);

        this.arLineAttributes[numLineItem]['amount'] = this.numberService.roundToTwo(total_cost);
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_id'] = event['id'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_name'] = event['name'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_code'] = event['code'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['unit_cost'] = event['unit_cost'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['description'] = event['description'];
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_accounting_id'] = (event['accounting_id'] !== null && event['accounting_id'] !== '') ? event['accounting_id'] : strDefaultItemAccountingId;

        // Tax code relate data
        let strTaxRate = (arTaxCode && arTaxCode['rate'] != undefined && arTaxCode['rate'] != null && arTaxCode['rate'] != '') ? parseFloat(arTaxCode['rate']) * 0.01 : 0;
        this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code_id'] = (arTaxCode && arTaxCode['id'] != undefined && arTaxCode['id'] != null) ? arTaxCode['id'] : '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['tax_code'] = (arTaxCode && arTaxCode['code'] != undefined && arTaxCode['code'] != null) ? arTaxCode['code'] : '';
        this.arLineAttributes[numLineItem]['tax'] = (arTaxCode && arTaxCode['id'] != undefined) ? arTaxCode : null;
        this.arLineAttributes[numLineItem]['tax_rate'] = strTaxRate;
        // Account code relate data
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code_id'] = (arAccountCode && arAccountCode['id'] != undefined && arAccountCode['id'] != null) ? arAccountCode['id'] : '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['account_code'] = (arAccountCode && arAccountCode['code'] != undefined && arAccountCode['code'] != null) ? arAccountCode['code'] : '';
        this.arLineAttributes[numLineItem]['account_code'] = (arAccountCode && arAccountCode['id'] != undefined) ? arAccountCode : null;
        this.setAmountDue();
      } else {
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_id'] = '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_name'] = '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_code'] = '';
        this.arLineAttributes[numLineItem]['supplier_invoice']['item_accounting_id'] = strDefaultItemAccountingId;
      }
    }
    this.markAsDirty();
  }

  /**
   * Compute Total Invoice Line Tax
   */
  computeInvoiceLineTax() {
    let computedTax: number = 0;

    this.arLineAttributes.forEach( data => {
      let amount: number = (data.amount != undefined && data.amount != '' && data.amount != null && data.amount != 'NaN') ? parseFloat(data.amount) : 0;
      let tax_rate = (data.tax_rate != undefined && data.tax_rate != '' && data.tax_rate != null && data.amount != 'NaN') ? parseFloat(data.tax_rate) : 0;
      computedTax += amount * tax_rate;
    });

    return toFormattedNumber(computedTax, {
      currency: true,
    });
  }

  /**
   * Compute Total Excluded Tax (Invoice Line Items Layout)
   */
  computeInvoiceLineExcludedTax() {
    let computedExcludedTax: number = 0;

    this.arLineAttributes.forEach( data => {
      let amount: number = (data.amount != undefined && data.amount != '' && data.amount != null && data.amount != 'NaN') ? parseFloat(data.amount) : 0;
      computedExcludedTax += amount;
    });

    return toFormattedNumber(computedExcludedTax, {
      currency: true,
    });
  }

  /**
   * Compute Total Included Tax (Invoice Line Items Layout)
   */
  computeInvoiceLineIncludedTax() {
    let computedIncludedTax: number = this.computeInvoiceLineExcludedTax() + this.computeInvoiceLineTax();

    return toFormattedNumber(computedIncludedTax + toNumber(this.totalTaxAdjustment), {
      currency: true,
    });
  }
  /**
   * Get index of form
   * @param strField
   */
  getFormFieldIndex(strField) {

    return this.supplierInvoiceForm.findIndex(attr => (attr.groups.get(strField) != undefined && attr.groups.get(strField) != null));
  }
  /**
   * Get index of field
   * @param strField
   * @param form
   */
  getFieldIndex(strField, form) {
    if (form != undefined && form['fields'] != undefined) {
      return form['fields'].findIndex( attr => (attr['key'] == strField));
    } else {
      return -1;
    }
  }
  /**
   * Set value of field
   * @param strField
   * @param strValue
   */
  fieldPatchValue(strField, strValue) {
    let indexOfFormField = this.getFormFieldIndex(strField);
    if (indexOfFormField > -1) {
      this.supplierInvoiceForm[indexOfFormField]['groups'].patchValue({
        [strField] : strValue,
      }, {emitEvent: false, onlySelf: true});
    }
  }

  /**
   * Validate Request
   * @param objRequest
   */
  validateRequest(objRequest, bLineItem = true) {
    for (let key in objRequest) {
      if (this.arRequiredField.indexOf(key) > -1 && key != 'line_items') {
        if((objRequest[key] == undefined || objRequest[key] == '' || objRequest[key] == null || objRequest[key].length < 1)) {
          let indexOfFormField = this.getFormFieldIndex(key);
          if (indexOfFormField > -1) {
            this.supplierInvoiceForm[indexOfFormField]['groups']['controls'][key].markAsTouched();
            this.supplierInvoiceForm[indexOfFormField]['groups']['controls'][key].setErrors({'incorrect' : true});
          }
          this.hasError = true;
        }
      }
      switch(key) {
        case 'tax_code_id':
        case 'tax_code':
        case 'tax_code_name':
        case 'account_code_id':
        case 'account_code':
        case 'account_code_name':
          if(objRequest[key] == '' || objRequest[key] == null || objRequest[key] == undefined) {
            this.hasError = true;
          }
        break;
        case 'department_id':
        if (
          this.bDepartmentTracking &&
          (objRequest[key] == '' || objRequest[key] == null || objRequest[key] == undefined) &&
          bLineItem
        ) {
          this.hasError = true;
        }
        break;
      }

    }
  }

  /**
   * Validate Field
   * @param strValue
   */
  validateField(strValue) {
    if (strValue == '' || strValue == null || strValue == undefined) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Set line items
   */
  setLineItems() {
    if (this.strViewType == 'edit' && this.data.supplier_invoice != undefined) {
      if (this.data.supplier_invoice.line_items != undefined && this.data.supplier_invoice.line_items != null && this.data.supplier_invoice.line_items.length > 0) {
        this.data.supplier_invoice.line_items.forEach( data => {
          this.addLineAttribute(data, false);
        });
      } else {
        this.bInvoiceLineLoaded = true;
      }
    } else {
      this.bInvoiceLineLoaded = true;
    }
  }

  /**
   * Initialize relate fields
   * @param strAttributeName - object attribute
   * @param strModule - module for relate
   * @param strObservableName - observable that is set
   * @param objMainFilter - Main filter for record (onChange of the fields)
   * @param arRelateInitialValue - Initial relate value
   */
  initRelateRecords(strAttributeName, strModule, strObservableName, objMainFilter, arRelateInitialValue, curentIndex: number | string = '') {
    // Get the lastest index.
    let newIndex = (curentIndex === '') ? this[strAttributeName].length - 1 : curentIndex;
    let strObservable = strObservableName + '_obv';
    let strTypehead = strObservableName + '_typehead';
    let strLoader = strObservableName + '_loader';
    if (strModule == 'items') {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term => this.recordService.getProductRecordData(term, objMainFilter, null, false).pipe(
            tap(() => {
              this[strAttributeName][newIndex][strLoader] = false;
            })
          ))
        )
      )
    } else if(['tax_codes', 'account_codes', 'departments'].includes(strModule)) {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term =>
            this.recordService.getFilterData(term, arRelateInitialValue).pipe(
              tap(() => {
                this[strAttributeName][newIndex][strLoader] = false;
              })
            )
          )
        )
      );
    } else {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term => this.recordService.getRecordRelate(strModule, term, '', false, objMainFilter).pipe(
            tap(() => {
              this[strAttributeName][newIndex][strLoader] = false;
            })
          ))
        )
      )
    }
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if(this.checkFormGroupDirty()) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close({message: 'cancel'});
        });
    } else {
      this.dialogRef.close({message: 'cancel'});
    }
  }

  /**
   * Compile the data form including the relate values that we need to save.
   *
   * @return {object}
   */
  getRecordToSave(isPreview: boolean = false): object {
    // Get the raw value of the form.
    this.hasError = false;

    if (! isPreview) {
      this.bSubmitted = true;
      this.isTouched = true;
    }

    let objSupplierInvoice = [];
    this.arReadOnlyId = [];
    this.arRelateEmptyId = [];
    this.arRequiredField = [];
    let strRecordModuleId = RelateIds[this.strRecordModule];
    this.supplierInvoiceForm.map(
      form => {
        Object.keys(form['groups'].value).forEach(
          item => {
            let strFieldMetadata = form['fields'].find( x => x.key == item);
            if (strFieldMetadata != undefined) {
              if (strFieldMetadata.readonly) this.arReadOnlyId.push(item);
              if (strFieldMetadata.controlType == 'relate') this.arRelateEmptyId.push(item);
              if (strFieldMetadata.required) this.arRequiredField.push(item);
            }
        })
    });

    //Loops the values.
    this.supplierInvoiceForm.forEach(
      item => {
        //Merge all form group values into a single object.
        objSupplierInvoice = {...objSupplierInvoice, ...item['groups'].getRawValue()};
    });

    objSupplierInvoice['tax_adjustment_amount'] = this.totalTaxAdjustment;

    // Remove readonly fields
    this.arReadOnlyId.forEach( strId => {
      if (strId != 'accounting_sync_detail') {
        delete objSupplierInvoice[strId];
      }
    });

    let lineItems = [];

    if (! isPreview) {
      this.validateRequest(objSupplierInvoice, false);
    }

    // Validate each line itmes of time entry
    this.arLineAttributes.forEach( data => {
      data.supplier_invoice.total_price = data.amount;
      data.supplier_invoice.quantity = data.supplier_invoice.quantity;
      if (data['item'] && data['item'].text) {
        data.supplier_invoice.item = data['item'].text;
        data.supplier_invoice.item_name = data['item'].text;
      }

      this.validateRequest(data.supplier_invoice);
      lineItems.push(data.supplier_invoice);
    });

    if (this.strRecordModule === 'customers' && isEmpty(this.strRecordId) && !isEmpty(objSupplierInvoice['customer_id'])) {
      this.strRecordId = objSupplierInvoice['customer_id'];
    }

    if (this.strRecordModule === 'purchase_orders' && !isEmpty(objSupplierInvoice['purchase_order_id'])) {
      this.strRecordId = objSupplierInvoice['purchase_order_id'];
    }

    if (this.strRecordModule != undefined && this.strRecordModule != '') {
      if(this.strRecordId != null && this.strRecordId != undefined && this.strRecordId != '') {
        objSupplierInvoice[strRecordModuleId] = this.strRecordId;
      } else {
        objSupplierInvoice[strRecordModuleId] = null;
      }
    }

    if (lineItems.length > 0) {
      objSupplierInvoice['line_items'] = lineItems;
    } else {
      if (!isPreview) {
        this.bSubmitted = false;
        this.pdfPreview = false;
        this.hasError = true;

        this.notifService.sendNotification('not_allowed', 'invoice_line_items_required', 'warning');
      }
    }

    if (objSupplierInvoice['contact_id'] == '') {
      objSupplierInvoice['contact_id'] = null;
    }

    if (objSupplierInvoice['department_id'] == '') {
      objSupplierInvoice['department_id'] = null;
    }

    this.arRelateEmptyId.forEach( strField => {
      if (objSupplierInvoice[strField] == '') {
        objSupplierInvoice[strField] = null;
      }
    });

    return objSupplierInvoice;
  }

  /**
   * Save the supplier invoice
   *
   * @returns {void}
   */
  saveSupplierInvoice(): void {

    let objSupplierInvoice = this.getRecordToSave();

    const lines = get(objSupplierInvoice, 'line_items', []);

    if (isArray(lines)) {
      lines.forEach(lineItem => {
        if (isEmpty(get(lineItem, 'description'))) {
          this.hasError = true;
          return;
        }
      });
    }

    if (!this.hasError && objSupplierInvoice) {
      if (this.strViewType == 'edit' && this.data['supplier_invoice'] != undefined) {
        // Save customer invoice
        this.beforeSaveSupplierInvoice(objSupplierInvoice, 'edit', this.data['supplier_invoice']['id']);
      } else {
        // Status default to created
        objSupplierInvoice['status'] = 'created';
        this.beforeSaveSupplierInvoice(objSupplierInvoice, 'add', '');
      }
    } else {
      this.notifService.sendNotification('not_allowed', 'required_notice', 'danger');
      this.bSubmitted = false;
      this.isPreviewTemplate = false;
      this.whenInProgress$.next(false);
    }
  }

  beforeSaveSupplierInvoice(objSupplierInvoice, strViewType, requestRecordId) {
    // Initialize multiple relate data
    let strModule = 'purchase_orders|supplier_invoices';
    let strFilter = {
      'purchase_orders': { 'purchase_orders.id': objSupplierInvoice['purchase_order_id'] },
      'supplier_invoices': { purchase_order_id: objSupplierInvoice['purchase_order_id']}
    }

    this.whenInProgress$.next(true);

    /**
     * Get multiple module relate record
     * @param strModule - request module to get config
     * @param strRecordIds - request record id of main table
     * @param arFilter - request filter
     * @param arOrderClause - request order clause   Format: ['id' => 'column', 'sort' => 'desc']
     * @param intLimit - request limit
     * @param arWhereIn - request to find in array
     * @param arWhereNotIn - request to find in array
     */
    this.recordService.getMultipleModuleRelateRecord(strModule, false, strFilter, false, false).subscribe( res => {

      let arPurchaseOrderData = (res['purchase_orders'].length > 0) ? res['purchase_orders'][0] : [];
      let arSupplierInvoiceData = (res['supplier_invoices'].length > 0) ? res['supplier_invoices'] : [];
      let totalAccumulatedSupplierInvoice = 0;
      let totalPurchaseOrderAmount = 0;
      let totalRemainingAmount = 0;
      // Sum all current line items
      if (objSupplierInvoice['line_items'] != '' && objSupplierInvoice['line_items'] != null) {
        objSupplierInvoice['line_items'].forEach( line_item => {
          totalAccumulatedSupplierInvoice += parseFloat(line_item['total_price']);
        });
      }
      // Get all supplier invoice with relate purchase order id
      if (arSupplierInvoiceData) {
        arSupplierInvoiceData.forEach( data => {
          // Check if supplier invoice id is not the same to current supplier invoice then add the amount.
          if (data['id'] != requestRecordId) {
            totalAccumulatedSupplierInvoice += parseFloat(data['amount']);
          }
        });
      }

      if (arPurchaseOrderData) {
        totalPurchaseOrderAmount = parseFloat(arPurchaseOrderData['amount']);
      }

      totalRemainingAmount = totalPurchaseOrderAmount - totalAccumulatedSupplierInvoice;

      if (totalRemainingAmount < 0) {
        let strExceedMessage = this.customTranslate.getTranslation('exceed_supplier_invoice_amount');
        let strTotalPurchaseOrderMessage = this.customTranslate.getTranslation('total_purchase_order_amount');
        let strTotalSupplierInvoiceMessage = this.customTranslate.getTranslation('total_supplier_invoice');
        let strTotalRemainingAmountMessage = this.customTranslate.getTranslation('total_remaining_amount');
        let strConfirmationMessage = strExceedMessage + strTotalPurchaseOrderMessage + this.numberService.roundToTwo(totalPurchaseOrderAmount) + " " + strTotalSupplierInvoiceMessage + this.numberService.roundToTwo(totalAccumulatedSupplierInvoice) + " " + strTotalRemainingAmountMessage + this.numberService.roundToTwo(totalRemainingAmount);
        //We tell the app component to open the confirmation.
        this.notifService.sendConfirmation(strConfirmationMessage)
          .subscribe(confirmation => {
            // If the user confirm
            if (confirmation.answer) {
              // Save purchase order
              this.saveSupplierInvoiceRecordData(objSupplierInvoice, requestRecordId).pipe(
                finalize(() => this.whenInProgress$.next(false))
              ).subscribe(res => {

                if (strViewType == 'add') {
                  if (res.status === 202) {
                    if (res.body.error !== undefined) {
                      this.notifService.promptError(res.body.error);
                    } else {
                      this.notifService.notifyWarning('invalid_next_supplier_number');
                    }
                  } else if (res.body.id != undefined) {
                    this.displayTemplate(this.data['supplier_invoice_id']);
                    this.dialogRef.close({id: res.body.id, message: 'save'});
                  } else {
                    this.dialogRef.close({message: 'fail'});
                  }
                } else {
                  if (res.status == 200) {
                    this.displayTemplate(this.data['supplier_invoice_id']);
                    this.dialogRef.close({id: res.body.id, message: 'save'});
                  } else if (res.status == 202 && res.body.error !== undefined) {
                    this.notifService.promptError(res.body.error);
                  } else {
                    this.dialogRef.close({message: 'fail'});
                  }
                }
              });
            } else {
              this.bSubmitted = false;
              this.isPreviewTemplate = false;
              this.whenInProgress$.next(false);
            }
          });
      } else {
        // Save supplier invoice
        this.saveSupplierInvoiceRecordData(objSupplierInvoice, requestRecordId).pipe(
          finalize(() => this.whenInProgress$.next(false))
        ).subscribe(res => {

          if (strViewType == 'add') {
            if (res.status === 202) {
              if (res.body.error !== undefined) {
                this.notifService.promptError(res.body.error);
              } else {
                this.notifService.sendNotification('warning', 'invalid_next_supplier_number', 'warning', 6000);
              }
            } else if (res.body.id != undefined) {
              this.displayTemplate(res.body.id);
              this.dialogRef.close({id: res.body.id, message: 'save'});
            } else {
              this.dialogRef.close({message: 'fail'});
            }
          } else {
            if (res.status == 200) {
              this.displayTemplate(this.data['supplier_invoice_id']);
              this.dialogRef.close({id: this.data['supplier_invoice_id'], message: 'save'});
            } else if (res.status === 202 && res.body.error !== undefined) {
              this.notifService.promptError(res.body.error);
            } else {
                this.dialogRef.close({message: 'fail'});
            }
          }
        });
      }
    });
  }

  /**
   * Compare the old data to the requested data
   * @param objSupplierInvoice - Requested data
   * @param relateData = Related Data
   */
  compareOldData(objSupplierInvoice) {
    let objSupplierInvoiceFields = Object.keys(objSupplierInvoice);
    objSupplierInvoiceFields.forEach( strField => {
      if (strField != 'line_items') {
        let strOriginalDate = '';

        if (strField == 'invoice_date' || strField == 'invoice_due' || strField == 'po_date') {
          strOriginalDate = objSupplierInvoice[strField];
          this.data.supplier_invoice[strField] = this.formatDate(this.data.supplier_invoice[strField]);
          objSupplierInvoice[strField] = this.formatDate(objSupplierInvoice[strField]);
        }
        // Due to in objSupplierInvoice relate id set as null if its empty. We set the old data also as null
        if (this.arRelateEmptyId.indexOf(strField) > -1) {
          if (this.data.supplier_invoice[strField] == '') {
            this.data.supplier_invoice[strField] = null;
          }
        }

        if (strField == 'job_id' && this.data.supplier_invoice[strField] == '') {
          this.data.supplier_invoice[strField] = null;
        }

        if (this.data.supplier_invoice[strField] != objSupplierInvoice[strField]) {
          this.bHasChanged = true;
        }

        if (strField == 'invoice_date' || strField == 'invoice_due' || strField == 'po_date') {
          objSupplierInvoice[strField] = strOriginalDate;
        }
      } else {
        objSupplierInvoice[strField].forEach( (data, index) => {
          let objLineItemsField = Object.keys(data);
          objLineItemsField.forEach( strLineItemField => {
            // If data purchase order with index given is undefined. Set has changed to true.
            if (this.data.supplier_invoice[strField][index] == undefined) {
              this.bHasChanged = true;
            } else {
              if (this.data.supplier_invoice[strField][index][strLineItemField] != data[strLineItemField]) {
                this.bHasChanged = true;
              }
            }
          });
        });
      }
    });
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate) {

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }

  /**
   * Get the default tax or account code from the local storage setting.
   * @param strKey tax | account
   */
  getDefaultCodes(strKey) {
    let objCOnfig = this.clientStoreService.getActiveClient();

    let strResult: string | boolean = false;

    if (objCOnfig['default_' + strKey + '_code_purchase'] != null) {
      strResult = objCOnfig['default_' + strKey + '_code_purchase'];
    }

    return strResult;
  }

  /**
   * Forms the ng-select object if the default tax/account code exists.
   * Also retrieves the name from the list of tax/account lists.
   * @param strKey tax | account
   * @param anyDefaultValue
   */
  getDefaultObject(strKey, anyDefaultValue = {}) {

    let objResult: any = anyDefaultValue;
    let strId = this.getDefaultCodes(strKey);
    let arRelate = [];

    if (strKey == 'tax') {
      arRelate = this.initialRelateTaxCodeData;
    }

    if (strKey == 'account') {
      arRelate = this.initialRelateAccountCodeData;
    }

    if (strId) {
      let numIndex = arRelate.findIndex(element => (element['id'] == strId));
      if (numIndex > -1) {
        objResult = {
          id: strId,
          name: arRelate[numIndex]['name']
        }
      }
    }

    return objResult;
  }

  /**
   * set amount due with computation of included tax
   */
  setAmountDue() {
    let indexOfAmountDue = this.getFormFieldIndex('amount_due');
    let indexOfAmountPaid = this.getFormFieldIndex('amount_paid');
    let strAmountPaid: number = 0;

    if (indexOfAmountPaid > -1) {
      let strAmountPaidValue = this.supplierInvoiceForm[indexOfAmountDue]['groups'].controls.amount_paid.value;
      strAmountPaid = this.is.toFloat(strAmountPaidValue);
    }

    if (indexOfAmountDue > -1) {
      this.supplierInvoiceForm[indexOfAmountDue]['groups'].patchValue({
        amount_due: this.computeInvoiceLineIncludedTax() - strAmountPaid
      });
    }
  }

  /**
   * Get and store the relate text on change in other component
   *
   * @param {object} objField
   * @param {string} strValue
   *
   * @returns {void}
   */
  getRelateText(objField, strValue): void {
    if (objField.controlType == 'relate') {

      this.objRelateText[objField.key.replace('_id', '_text')] = strValue;
    }
  }

  /**
   * Trigger to openPreview
   *
   * @returns {void}
   */
  previewPDF(): void {

    this.pdfPreview = true;
    // Get the records to save, for realtime pdf preview
    let objSupplierInvoice = this.getRecordToSave();
    // Open pdf preview
    this.openPreview(objSupplierInvoice);
  }

  /**
   * Open pdf preview
   * Request for pdf record data using the selected id.
   *
   * @param objModuleRecord
   *
   * @returns {void}
   */
  openPreview(objRecord): void {

    objRecord = _cloneDeep(_merge(this.objRelateText, objRecord));
    let objCurrentRecord = (this.data['supplier_invoice']) ? _cloneDeep(this.data['supplier_invoice']) : {};
    let objCompiledRecord = (objCurrentRecord) ? _cloneDeep(_merge(objCurrentRecord, objRecord)) : objRecord;
    let strLabel = (objCompiledRecord.invoice_number && this.data['view_type'] == 'edit') ? objCompiledRecord.customer_text+ ' - ' +objCompiledRecord.invoice_number : 'preview_' +moment().format('YYYY_MM_DD_HHMMSS');

    objCompiledRecord['amount'] = this.computeInvoiceLineExcludedTax();
    objCompiledRecord['tax'] = this.computeInvoiceLineTax();
    objCompiledRecord['amount_inc_tax'] = this.computeInvoiceLineIncludedTax();

    let arLineItems: [][] = [];
    this.arLineAttributes.forEach( line_items => {
      arLineItems.push(line_items.supplier_invoice);
    })

    objCompiledRecord['line_items'] = arLineItems;
    if (Object.keys(this.objPurchaseOrderOnchange).length) {
      // If we got here, it means that the user changed the purchase order
      // We need to update these fields
      objCompiledRecord['required_by'] = this.objPurchaseOrderOnchange['required_by'];
      objCompiledRecord['delivery_location'] = this.objPurchaseOrderOnchange['delivery_location'];
      objCompiledRecord['delivery_notes'] = this.objPurchaseOrderOnchange['delivery_notes'];
    }
    let dialogConfig : {[k: string]: any} = {
      data: {
        module: 'supplier_invoices',
        label: strLabel,
        response: {
          file_name: strLabel,
          module: 'supplier_invoices',
          record: objCompiledRecord
        }
      },
      disableClose:true
    };

    // If mobile
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = '80%';
    }

    this.pdfPreview = false;
    this.bSubmitted = false;
    this.dialog.open(PdfComponent, dialogConfig);
  }

  /**
   * This is to increase the row
   * when textarea is focus
   */
  increaseRow(i) {
    let currentElem = document.querySelector('.invoice-desc-'+ i);
    currentElem.setAttribute("style", "height: 100px");
  }

  /**
   * This is to decrease the row
   * when textarea is focus out
   */
  decreaseRow(i) {
    let currentElem = document.querySelector('.invoice-desc-'+ i);
    currentElem.setAttribute("style", "height: 40px");
  }

  /**
   * Check if the form group is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    let bDirty = false;
    this.supplierInvoiceForm.forEach( objForm => {
      var formGroup = objForm.groups;
      if (formGroup.dirty === true) {
        bDirty = true;
      }
    });
    return bDirty || this.bFormDirty;
  }

  /**
   * Mark as dirty
   *
   * @returns {void}
   */
  markAsDirty(): void {
    this.bFormDirty = true;
  }

  /**
   * This will auto populate the related field
   * of purchase order to supplier invoice
   * @param purchaseOrderDetail
   */
  populateRelatedFieldFromPurchaseOrder(purchaseOrderDetail)
  {
    if (purchaseOrderDetail !== null) {
      let indexContactForm = this.getFormFieldIndex('contact_id');
      let indexContactField = this.getFieldIndex('contact_id', this.supplierInvoiceForm[indexContactForm]);
      let indexCustomerForm = this.getFormFieldIndex('customer_id');
      let indexCustomerField = this.getFieldIndex('customer_id', this.supplierInvoiceForm[indexCustomerForm]);

      // FC-4255: fix issue in supplier invoice form where changing purchase order value does not update the supplier value
      if (indexCustomerForm > -1 && indexCustomerField > -1) {
        const customerField = _cloneDeep(this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField])
        customerField['options'] = [new Select(purchaseOrderDetail['customer_id'], purchaseOrderDetail['customer_text'])];
        // if customer field is readonly, we temporarily set it to false to update the supplier value
        if (this.bIsCustomerFieldReadOnly) {
          customerField['readonly'] = false;
        }

        this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField] = customerField;
      }

      this.fieldPatchValue('contact_id', purchaseOrderDetail['contact_id']);
      this.fieldPatchValue('customer_id', purchaseOrderDetail['customer_id']);
      this.fieldPatchValue('amount_paid', purchaseOrderDetail['amount_paid']);

      if (this.strRecordModule !== 'jobs') {
        let indexJobForm = this.getFormFieldIndex('job_id');
        let indexJobField = this.getFieldIndex('job_id', this.supplierInvoiceForm[indexJobForm]);

        if (indexJobForm > -1 && indexJobField > -1) {
          const jobField = _cloneDeep(this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField])
          jobField['options'] = [new Select(purchaseOrderDetail['job_id'], purchaseOrderDetail['job_text'])];
          this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField] = jobField;

          this.fieldPatchValue('job_id', purchaseOrderDetail['job_id']);
          this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['default_value'] = purchaseOrderDetail['job_id'];
          this.supplierInvoiceForm[indexJobForm]['fields'][indexJobField]['default_text'] = purchaseOrderDetail['job_text'];
        }
      }

      if (indexContactForm > -1 && indexContactField > -1) {
        this.supplierInvoiceForm[indexContactForm]['fields'][indexContactField]['default_value'] = purchaseOrderDetail['contact_id'];
        this.supplierInvoiceForm[indexContactForm]['fields'][indexContactField]['default_text'] = purchaseOrderDetail['contact_text'];
      }

      if (indexCustomerForm > -1 && indexCustomerField > -1) {
        this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_value'] = purchaseOrderDetail['customer_id'];
        this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['default_text'] = purchaseOrderDetail['customer_text'];

        if (this.bIsCustomerFieldReadOnly) {
          this.supplierInvoiceForm[indexCustomerForm]['fields'][indexCustomerField]['readonly'] = true;
        }
      }
    }
  }

  /**
   * Set line items from purchase order
   *
   * @param strPurchaserOrderId
   */
  setLineItemFromPurchaseOrder(strPurchaserOrderId) {
    this.recordService.getRelatedModuleRecord('purchase_orders', strPurchaserOrderId, {
      has_related_data: true
    }).subscribe( result => {
      // Set data
      let purchaseOrderDetail = this.arrService.keyFallsBackTo(result, 'record_details');
      // We need to set this type to any. So it can use this in foreach
      // Note: It has condition for this variable so it sure that the value is an array.
      let lineItems: Record<string, any>[] = this.arrService.keyFallsBackTo(purchaseOrderDetail, 'line_items');

      if (lineItems.length > 0) {
        /// filter line items that where already invoiced
        lineItems = lineItems.filter((line) => {
          const invoiceQuantity = toFormattedNumber(get(line, 'invoiced_quantity', 0));
          const expectedQuantity = toFormattedNumber(get(line, 'quantity', 0));

          return expectedQuantity > invoiceQuantity;
        });
      }

      this.objPurchaseOrderOnchange = (purchaseOrderDetail !== null) ? purchaseOrderDetail : {};
      // This is to auto populate the related field from purchase order to supplier invoice.
      this.populateRelatedFieldFromPurchaseOrder(purchaseOrderDetail);

      if (lineItems) {
          this.arLineAttributes = [];
          lineItems.forEach( data => {
            this.addLineAttribute(data);
          });

          this.setAmountDue();
      } else {
        this.arLineAttributes = [];
        this.addLineAttribute();
        this.setAmountDue();
      }
    });
  }

  /**
   * triggers the save and preview
   */
  onSubmitAndPreview(): void {
    this.isPreviewTemplate = true;
    if (!this.bSubmitted) {
        this.saveSupplierInvoice();
    }
  }

  /**
   * trigger event to disaplay template
   *
   * @param id
   */
  displayTemplate(id: string): void {
    if (this.isPreviewTemplate && id) {
      this.previewTemplate.next({
        id: id,
        module: 'supplier_invoices',
        document_type: 'supplier_invoice_report'
      });
    }
    this.isPreviewTemplate = false;
  }

  onLineTotalChange(input: HTMLInputElement, opts: {
    attr: Record<string, any>,
    index: number,
  }): void {
    if (this.arLineAttributes.length < opts.index) {
      return;
    }

    const adjustment = toFormattedNumber(input.value, {
      currency: true,
    });

    const quantity = toFormattedNumber(get(opts.attr, 'supplier_invoice.quantity'));
    const cost = adjustment / quantity;

    this.arLineAttributes[opts.index]['supplier_invoice']['unit_cost'] = toFormattedNumber(cost, {
      currency: true,
      maxDecimalPlaces: 4,
    });

    this.setAmountDue();
    this.markAsDirty();
  }

  onTaxAdjustment(): void {
    this.setAmountDue();
    this.markAsDirty();
  }

  /**
   * for viewing the current record
   *
   * @returns void
   */
  onPreview(): void {
    let objSupplierInvoice = this.getRecordToSave(true);
    let arModule = [];
    let objFilter = {};
    let arRelateFields = [
      { id: 'customer_id', module: 'customers', key: 'supplier' },
      { id: 'contact_id', module: 'sites', key: 'contact' },
      { id: 'user_id', module: 'users', key: 'assigned_user' },
      { id: 'purchase_order_id', module: 'purchase_orders', key: 'purchase_order' },
      { id: 'job_id', module: 'jobs', key: 'job'},
    ];

    arRelateFields.forEach( relateField => {
      if (objSupplierInvoice[relateField.id]) {
        arModule.push(relateField.module);
        objFilter[relateField.module] = {
          id: objSupplierInvoice[relateField.id]
        };
      }
    });

    this.whenInProgress$.next(true);

    this.recordService.getMultipleModuleRelateRecord(arModule.join('|'), false, objFilter).subscribe( response => {
      arRelateFields.forEach( relateField => {
        if (response[relateField.module] && response[relateField.module][0]) {
          objSupplierInvoice[relateField.key] = response[relateField.module][0];
          if (objSupplierInvoice[relateField.key]['address']) {
            objSupplierInvoice[relateField.key]['address'] =
              this.readableAddressPipe.transform(objSupplierInvoice[relateField.key]['address'])
          }
        } else {
          objSupplierInvoice[relateField.key] = {}
        }
      });

      objSupplierInvoice = this.setDropdownValues(objSupplierInvoice);
      objSupplierInvoice['amount'] = this.computeInvoiceLineExcludedTax();
      objSupplierInvoice['tax'] = this.computeInvoiceLineTax();
      objSupplierInvoice['amount_inc_tax'] = this.totalTaxAdjustment;

      this.realTimePreviewTemplate.next({
        id: this.data['supplier_invoice_id'],
        module: 'supplier_invoices',
        document_type: 'supplier_invoice_report',
        data: objSupplierInvoice
      });

      this.bSubmitted = false;
      this.isPreviewTemplate = false;
    });
  }

  /*
   * NOTE: This is a method from Angular CDK itself and is not a custom one.
   *
   * Triggered when an item is being dropped.
   * This is used by the quote line editor.
   * @param event - the item being dragged.
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arLineAttributes, event.previousIndex, event.currentIndex);
  }

  setDropdownValues(purchaseOrder): LooseObject {
    let arDropdownKeys = Object.keys(this.objModuleConfig['used_fields']).filter(
      strKey => this.objModuleConfig['used_fields'][strKey].type === 'dropdown'
    );
    arDropdownKeys.forEach( dropdownKeys => {
      if (purchaseOrder[dropdownKeys]) {
        let dropdownValue = cloneDeep(purchaseOrder[dropdownKeys]);

        purchaseOrder[dropdownKeys+ '_raw'] = dropdownValue;
        purchaseOrder[dropdownKeys] = this.translateService.instant(dropdownValue)
      }
    });

    return purchaseOrder;
  }

  /**
   * Save supplier invoice record data
   *
   * @param {LooseObject} objData
   * @param {strRecordId} strRecordId
   *
   * @see {RecordService['saveRecord']}
   */
  protected saveSupplierInvoiceRecordData(objData: LooseObject, strRecordId: string = '') {
    // FC-4265: If we are updating the SI record, and we changed Purchase Order record id, we must update the
    // 'remaining_amount' of the previous purchase order data, otherwise, if PO 'remaining_amount' field remains 0,
    // it will not be visible in the options of the relate field of a purchase order in a form.
    // Refer to server/app/Models/PurchaseOrder.php getRecordsAdditionalFilter method.
    if (
      this.strViewType === 'edit' &&
      (this.objOriginalPurchaseOrderData.id !== objData.purchase_order_id)
    ) {
      objData['previous_po_to_update'] = this.objOriginalPurchaseOrderData;
    }

    return this.recordService.saveRecord('supplier_invoices', objData, strRecordId).pipe(
      finalize(() => {
        this.bSubmitted = false;
        this.isPreviewTemplate = false;
      })
    );
  }
}
