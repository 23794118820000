import { Prompt } from "./prompt";

export enum NotificationTheme {
  THEME_PRIMARY = 'primary',
  THEME_SECONDARY = 'secondary',
  THEME_INFO = 'info',
  THEME_ERROR = 'danger',
  THEME_SUCCESS = 'success',
  THEME_WARNING = 'warning',
  THEME_DARK = 'dark',
};

/**
 * The available themes for a notification.
 * It's obviously derived from Bootstrap's color themes.
 * The only one we didn't cater is the 'light' theme because
 * our font is white.
 */
export type NotificationThemes = 'success' | 'danger' | 'info' | 'primary' | 'warning' | 'secondary' | 'dark';

export enum NotificationTypes {
  TYPE_DEFAULT = 'default',
  TYPE_PERSISTENT = 'persistent',
  TYPE_PROMPT = 'prompt',
  TYPE_INTERCEPTOR = 'interceptor',
};

/**
 * The available types of a notification.
 *
 * 'default' - A default notification that doesn't appear in the headers.
 * 'persistent' - A notification that comes from pusher and is saved in the database.
 * 'prompt' - Errors that come from error validation from our server.
 * 'interceptor' - Notifications that come from the interceptor.
 */
export type NotificationType = 'default' | 'persistent' | 'prompt' | 'interceptor';

export interface NotificationInterface {

  /**
   * A randomnly generated id to keep tabs
   * of the notifications popping up.
   *
   * @var {number | string}
   */
  id?: number | string;

  /**
   * The notification header message. Usually a
   * short message that sums up the notification.
   *
   * @var {string}
   */
  header?: string;

  /**
   * The contents of the noticiation. This should either be
   * a string or any supportedd object so we can maintain the translations for the message.
   * It can also accept html if your submitting a string.
   *
   * @var {string|Prompt}
   */
  message: string | Prompt;

  /**
   * The type of notification. See notification type
   * for a list of notification.
   *
   * @var {string}
   */
  type?: NotificationType;

  /**
   * The theme of the notification, this can be the following:
   * succes, danger, info, primary, warning, secondary, dark.
   *
   * @var {NotificationThemes}
   */
  theme?: NotificationThemes;

  /**
   * The duration of the notification, meaning how long this notification
   * will appear. This should be in miliseconds. Set this to 0 if you dont
   * want the notification to disappear. (They can hit close to remove the notification.)
   *
   * @var {number}
   */
  duration?: number;

  /**
   * If the notification has a button inside that corresponds to
   * a certain action.
   *
   * @var {boolean}
   */
  has_button?: boolean;

  /**
   * The title of the button. This will only be used if the
   * has_button is true.
   *
   * @var {string}
   */
  button_title?: string;

  /**
   * Redirect link for the notification if
   * there should be any.
   *
   * @var {string}
   */
  link?: string

  /**
   * If the notifications has already been
   * read by the user.
   */
  is_read?: boolean;

  /**
   * If the notifications is from
   * push notification service.
   */
   is_push_notif?: boolean;
}

export class Notification implements NotificationInterface {

  public id: number | string;
  public header?: string;
  public message: string | Prompt;
  public type: NotificationType;
  public theme: NotificationThemes;
  public duration?: number;
  public has_button: boolean;
  public button_title?: string;
  public link?: string;
  public is_read?: boolean;
  public is_push_notif?: boolean;

  constructor(
    properties: NotificationInterface
  ){
    this.id = properties.id || Math.floor(Math.random() * Date.now());
    this.header = properties.header;
    this.message = properties.message;
    this.type = properties.type || 'default';
    this.theme = properties.theme || 'success';
    this.duration = properties.duration || 5000;
    this.has_button = properties.has_button || false;
    this.button_title = properties.button_title;
    this.link = properties.link;
    this.is_read = properties.is_read || false;
    this.is_push_notif = properties.is_push_notif || false;
  }

  /**
   * Marks the is_read property of this object
   * to be true.
   *
   * @returns {void}
   */
  markAsRead(): void {
    this.is_read = true;
  }

  /**
   * Checks if the ID of this notification is a temp one
   * (a temp id would be a randomnly generated number) or if
   * it's a string, which can be a UUID or custom string.
   *
   * @returns {boolean}
   */
  isTempId(): boolean {
    if (typeof this.id == 'number') {
      return true;
    } else {
      return false;
    }
  }

}

export class NotificationAction {

  /**
   * The ID of the notification where
   * the action came from.
   *
   * @var {number|string}
   */
  id: number|string;

  /**
   * If the action button is triggered.
   *
   * @var {boolean}
   */
  action: boolean;

  constructor(id: number | string, action: boolean) {
    this.id = id;
    this.action = action;
  }
}