<app-error-handler onlyFor="recurring_invoices:list" alignment="center" displayAs="widget">

  <!-- create form dialog -->
  <div class="d-flex justify-content-around create-form-dialog-box tabs-content">
    <ng-container *fcHasPermission="'recurring_invoices:create'; else createRecurringInvoicesDisabled">
      <span id="widgetRecurringInvoicesCreateButton" (click)="! bLoading && openDialogForm()" class="span-links">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ 'create_recurring_invoice' | translate }}
      </span>
    </ng-container>
    <ng-template #createRecurringInvoicesDisabled>
      <span id="widgetRecurringInvoicesCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
          <fa-icon [icon]="['fas', 'plus-circle']" class="text-default"></fa-icon>
          {{ 'create_recurring_invoice' | translate }}
      </span>
    </ng-template>
  </div>
  <!-- /end create form dialog -->


  <div class="recurring_invoices-body">
    <div class="container">
      <!-- filter form -->
      <div class="row">
        <div class="col-lg-12 pt-4 pb-4">

          <!-- filter:status -->
          <div class="form-inline mt-12">
            <div class="form-group flex-nowrap w-100">
              <div class="col-lg-4">
                <label class="jc-l">{{ 'filter_by_status' | translate }}</label>
              </div>
              <div class="col-lg-8">
                <ng-select
                  [items]="arStatusFilter"
                  [clearable]=true
                  class="value-text"
                  (change)="addFilter('is_active', $event, true)"
                  id="widgetRecurringInvoicesFilterStatus"
                  bindValue="id"
                >
                  <ng-template ng-label-tmp let-item="item">{{ item.text | translate }}</ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <span id="widgetActivitiesTimelineFilter{{ item.text }}">{{ item.text | translate }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <!-- /end filter:status -->

        </div>
      </div>
      <!-- /end filter form -->

      <!-- pagination and module title -->
      <div class="row border-top pt-2 pb-2">
        <!-- module title -->
        <div class="col-sm-6 pt-1">
          <span class="module-title">
              <fa-icon [icon]="['fa', 'file-invoice-dollar']" aria-hidden="true"></fa-icon>
              &nbsp;
              {{ 'recurring_invoices' | translate }}
          </span>
        </div>
        <!-- end module title -->

        <!-- pagination -->
        <div class="col-sm-6 pr-2">
          <nav class="pagination-section" class="float-right">
            <ul class="pagination">
              <refresh-pagination-btn id="widgetRecurringInvoicesRefreshButton" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
              <li [ngClass]="(getListingService().strFirstValue != getListingService().strCurrentValue && getListingService().strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                <a id="widgetRecurringInvoicesPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                  <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                </a>
              </li>
              <li [ngClass]="(getListingService().strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                <a id="widgetRecurringInvoicesNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                  <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- /end pagination -->
      </div>
      <!-- /end pagination and module title -->

      <!-- if initial listing is empty  -->
      <div *ngIf="arRecurringInvoices.length < 1">
        <!-- if bLoading === false -->
        <div class="row border-top" *ngIf="!bLoading">
          <span class="no-content">{{ 'no_recurring_invoices' | translate }}</span>
        </div>

        <!-- else if bLoading === true -->
        <div class="row border-top" *ngIf="bLoading">
          <span class="no-content">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            {{ 'loading' | translate }}
          </span>
        </div>
        <!-- /end if -->
      </div>
      <!-- end if -->

      <!-- listing -->
      <div class="row border-top pt-2 pb-2 pointer" *ngFor="let objRecurringInvoice of getRecurringInvoicesList()" [id]="'widgetRecurringInvoicesRecord' + objRecurringInvoice.id">
        <!-- status -->
        <div class="col-sm-1" id="widgetRecurringInvoicesHistory" (click)="showHistoricalInvoicesDialog(objRecurringInvoice.id)">
          <fa-icon class="float-left icon-size text-success text-status" *ngIf="objRecurringInvoice.is_active === true" matTooltip="{{ 'active' | translate }}" [icon]="['fas', 'circle']"></fa-icon>
          <fa-icon class="float-left icon-size text-danger text-status" *ngIf="objRecurringInvoice.is_active === false" matTooltip="{{ 'inactive' | translate }}" [icon]="['fas', 'circle']"></fa-icon>
        </div>
        <!-- /end status -->

        <div class="col-sm-3" (click)="showHistoricalInvoicesDialog(objRecurringInvoice.id)">
          <div class="text-activity-content truncate" matTooltip="{{ 'site' | translate }}: {{ objRecurringInvoice.site_text || '--' }}">
            {{ objRecurringInvoice.site_text || '--' }}
          </div>
        </div>

        <!-- summary & period -->
        <div class="col-sm-2" (click)="showHistoricalInvoicesDialog(objRecurringInvoice.id)">
          <!-- period -->
          <div class="row" matTooltip="{{ 'period' | translate }}: {{ objRecurringInvoice.period | translate }}">
            {{ objRecurringInvoice.period | translate }}
          </div>
          <!-- /end period -->

          <!-- invoice summary  -->
          <div class="row text-activity-content truncate" matTooltip="{{ 'invoice_summary' | translate }}: {{ objRecurringInvoice.invoice_summary || '--' }}">
            {{ objRecurringInvoice.invoice_summary || '--' }}
          </div>
          <!-- /end invoice summary -->
        </div>
        <!-- /end summary & period-->

        <!-- last invoice date and next invoice date -->
        <div class="col-sm-3" (click)="showHistoricalInvoicesDialog(objRecurringInvoice.id)">
          <!-- next invoice date  -->
          <div class="" matTooltip="{{ 'next_invoice_date' | translate }}: {{ formatDate(objRecurringInvoice.next_invoice_date) }}">
            {{ formatDate(objRecurringInvoice.next_invoice_date) }}
          </div>
          <!-- /end next invoice date -->

          <!-- last invoice date -->
          <div class="" matTooltip="{{ 'last_invoice_date' | translate }}: {{ objRecurringInvoice.last_invoice_date ? formatDate(objRecurringInvoice.last_invoice_date) : '--' }}">
            {{ objRecurringInvoice.last_invoice_date ? formatDate(objRecurringInvoice.last_invoice_date) : '--' }}
          </div>
          <!-- /end last invoice date -->
        </div>
        <!-- /end last invoice date and next invoice date-->

        <!-- amount (tax included and excluded tax) -->
        <div class="col-sm-2" (click)="showHistoricalInvoicesDialog(objRecurringInvoice.id)">
          <!-- ex tax -->
          <div class="row" matTooltip="{{ 'total_ex_tax' | translate }}: {{ objRecurringInvoice.amount_tax_excluded | currency }}">
            {{ objRecurringInvoice.amount_tax_excluded | currency: {decimal_places: 2} }}
          </div>
          <!-- /end ex tax -->

          <!-- ex tax -->
          <div class="row" matTooltip="{{ 'total_inc_tax' | translate }}: {{ objRecurringInvoice.amount_tax_included | currency }}">
            {{ objRecurringInvoice.amount_tax_included | currency: {decimal_places: 2} }}
          </div>
          <!-- /end ex tax -->
        </div>
        <!-- /end amount -->

        <!-- actions -->
        <div class="col-sm-1">
          <!-- edit -->
          <div class="row">
            <div class="col-12">
              <ng-container *fcHasPermission="'recurring_invoices:edit'; else disabledEditButton">
                <fa-icon id="widgetRecurringInvoicesEditButton" class="text-success pointer float-right" matTooltip="{{ 'edit' | translate }}" [icon]="['fas', 'pencil']" (click)="openDialogForm(objRecurringInvoice.id)"></fa-icon>
              </ng-container>
              <ng-template #disabledEditButton>
                <fa-icon id="widgetRecurringInvoicesEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
              </ng-template>
            </div>
          </div>
          <!-- /end edit -->

          <!-- delete -->
          <div class="row">
            <div class="col-12">
              <ng-container *fcHasPermission="'recurring_invoices:delete'; else disabledDeleteButton">
                <fa-icon id="widgetRecurringInvoicesDeleteButton" class="text-warning pointer float-right" matTooltip="{{ 'delete' | translate }}" [icon]="['fas', 'trash-alt']" (click)="deleteRecord(objRecurringInvoice.id)"></fa-icon>
              </ng-container>
              <ng-template #disabledDeleteButton>
                  <fa-icon id="widgetRecurringInvoicesDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
              </ng-template>
            </div>
          </div>
          <!-- /end delete -->
        </div>
        <!-- end actions -->
      </div>
      <!-- /end listing -->
    </div>
  </div>

</app-error-handler>
