<app-error-handler onlyFor="jobs:list" alignment="center" displayAs="widget">
<div class="d-flex justify-content-around contacts-option tabs-content">
    <ng-container *fcHasPermission="'jobs:create'; else createJobDisabled">
        <span id="widgetJobsCreateButton" (click)="(bPageLoaded) ? openCreateJobWizard() : ''" class="span-links">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
            {{ 'create_job' | translate }}
        </span>
    </ng-container>
    <ng-template #createJobDisabled>
        <span id="widgetJobsCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-default"></fa-icon>
            {{ 'create_job' | translate }}
        </span>
    </ng-template>
</div>
<div class="contact-roles-body">
    <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pb-4">
              <div class="form-inline mt-12">
                  <div class="form-group flex-nowrap w-100">
                      <div class="col-lg-4">
                          <label class="jc-l">{{ 'filter_by_status' | translate }}</label>
                      </div>
                      <div class="col-lg-8">
                          <ng-select
                              [items]="arStatusFilter"
                              [clearable]="true"
                              class="value-text"
                              (change)="filter()"
                              [(ngModel)]="strSelectStatus"
                              id="widgetJobsStatusFilter">
                              <ng-template ng-label-tmp let-item="item">{{ item | translate }}</ng-template>
                              <ng-template ng-option-tmp let-item="item">
                                <span id="widgetJobsStatusFilter{{ item }}">{{ item | translate }}</span>
                              </ng-template>
                          </ng-select>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="row border-top pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'wrench']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'jobs' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetJobsRefreshButton" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
                        <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetJobsPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev', (strSelectStatus != null && strSelectStatus != '') ? { status : strSelectStatus} : {})">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetJobsNextButton" class="page-link" aria-label="first" (click)="fetchList('next', (strSelectStatus != null && strSelectStatus != '') ? { status : strSelectStatus} : {})">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div *ngIf="arJobs.length < 1">
            <div class="row border-top" *ngIf="!bLoading">
                <span class="no-content">
                    {{ 'no_jobs' | translate }}
                </span>
            </div>
            <div class="row border-top" *ngIf="bLoading">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </div>

        <div class="row border-top pt-2 pb-2 pr-3"  *ngFor="let job of arJobs" [id]="'widgetJobsRecord_' + job.id">
            <div class="col-sm-1">
                    <fa-icon [ngClass]="arJobStatus[job.status]" class="text-status" *ngIf="job.status" matTooltip="{{ job.status | translate }}" [icon]="['fas', 'circle']"></fa-icon>
            </div>
            <div class="col-sm-2">
                <div matTooltip="{{ 'job_number' | translate }}: {{ job.job_number }}" class="text-activity-type">
                    <a id="widgetJobsLabel" href="/#/jobs/{{job.id}}">#{{ job.job_number }}</a>
                </div>

                <span matTooltip="{{ 'job_type' | translate }}: {{ job.type | translate }}" class="text-activity-content">
                    {{ job.type | translate }}
                </span>
            </div>
            <div class="col-sm-4 truncate">
              <span matTooltip="{{ 'job_summary' | translate }}: {{ job.job_summary }}">{{ job.job_summary }}</span><br/>
              <span *ngIf="strModule == 'customers'" matTooltip="{{ 'site' | translate }}: {{ job.site_text }}">{{ job.site_text | placeholdWithString }}</span><br/>
            </div>
            <div class="col-sm-4 truncate">
                <span class="word-wrap" matTooltip="{{ 'due_date' | translate }}: {{ job.due_date | humanReadableDate | placeholdWithString }}">{{ "due" | translate }}: {{ job.due_date | humanReadableDate | placeholdWithString }}</span>
                <br />
                <span class="word-wrap" matTooltip="{{ 'date_completed' | translate }}: {{ job.date_completed | humanReadableDate | placeholdWithString }}">{{ "completed" | translate }}: {{ job.date_completed | humanReadableDate | placeholdWithString }}</span>
                <!-- <span matTooltip="{{ 'date_completed' | translate }}: {{ job.date_completed }}">{{ job.date_completed | translate }}</span> -->
            </div>
            <div class="col-sm-1 d-table text-role less-padding">
                <div class="row" *ngIf="job.editable">
                    <div class="col-12">
                        <ng-container *fcHasPermission="'jobs:edit'; else disabledEditButton">
                            <fa-icon id="widgetJobsEditButton" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="recordDialog(job.id)"></fa-icon>
                        </ng-container>
                        <ng-template #disabledEditButton>
                            <fa-icon id="widgetJobsEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                        </ng-template>
                    </div>
                </div>
                <div class="row" *ngIf="job.deletable">
                    <div class="col-12">
                        <ng-container *fcHasPermission="'jobs:delete'; else disabledDeleteButton">
                            <fa-icon id="widgetJobsDeleteButton" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteJob(job.id)"></fa-icon>
                        </ng-container>
                        <ng-template #disabledDeleteButton>
                            <fa-icon id="widgetJobsDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</app-error-handler>