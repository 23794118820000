import { Component, Output, EventEmitter, OnDestroy, OnInit, Input } from "@angular/core";
import { WizardStepComponent, StepEventData, PROGRESS_NEXT, PROGRESS_FAILED, PROGRESS_INPROGRESS } from '../../../connect.component';
import { Subscription } from "rxjs";
import { AccountingSystemService, ProgressEvent } from "../../../../../services/accounting_system.service";
import { finalize } from "rxjs/operators";
import { DriverInterface } from "../../../../../entities/driver";

@Component({
  selector: 'wizard-step-import-tax-codes',
  templateUrl: './tax-codes.component.html'
})
export class ImportTaxCodesComponent implements WizardStepComponent, OnDestroy, OnInit {
  /**
   * Selected driver information
   *
   * @var {DriverInterface}
   */
  @Input('driver') driver: DriverInterface;

  /**
   * {@inheritDoc}
   */
  @Output('event-progress') eventProgress: EventEmitter<StepEventData> = new EventEmitter;

  /**
   * this tells if the current step is in progress
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * this tells if the current step is successful
   *
   * @var {boolean}
   */
  isSuccessful: boolean = false;

  /**
   * contains the error message
   *
   * @var {string}
   */
  error: string;

  /**
   * Current batch cursor
   *
   * @var {number}
   */
  cursor: number = 0;

  /**
   * INTERNAL: contains all the subscriptions for rxjs that should be cleaned up once
   * this component is unmounted
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * @param {AccountingSystemService} service
   */
  constructor(
    private service: AccountingSystemService
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.runImport();
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * handles the user interaction on retry action
   *
   * @returns {void}
   */
  retryHandler(): void {
    this.runImport();
  }

  /**
   * run import for tax codes
   *
   * @returns {void}
   */
  private runImport(): void {
    this.isInProgress = true;
    this.eventProgress.emit({ progress: PROGRESS_INPROGRESS });

    this.subscriptions.push(
      this.service.importTaxCodes$({
        cursor: this.cursor,
        progressId: 'connect_import_taxes'
      }).pipe(
        finalize(() => this.isInProgress = false)
      )
      .subscribe((result) => {
        if (result.isSuccessful) {
          this.isSuccessful = true;
          this.cursor = 0;
          this.eventProgress.emit({ progress: PROGRESS_NEXT });
        } else {
          this.error = result.errors.shift();
          this.isSuccessful = false;
          this.eventProgress.emit({ progress: PROGRESS_FAILED });
        }
      })
    );
  }

  /**
   * Handles progress event emitted
   *
   * @param {ProgressEvent} event
   *
   * @returns {void}
   */
  onProgressEvent(event: ProgressEvent): void {
    this.cursor = event.progress;
  }
}
