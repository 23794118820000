import { Form } from './form';

export class Relate extends Form<string> {
  controlType = 'relate';
  default_text: string;
  module: string;
  multiple: false;
  maxSelectedItems: number = 100;
  option_count: number = 0;
  options: any;
  filter: any;
  note: string;
  add_tag: boolean;
  is_hidden: boolean;

  /**
   * this contain the key in the data to be used as text in the dropdown options
   *
   * @var {string}
   */
  text: string = 'text';

  /**
   * Placeholder for display
   *
   * @var {string}
   */
  placeholder: string;

  constructor(options: {} = {}) {
    super(options);
    this.default_text = options['default_text'] || '';
    this.module = options['module'] || '';
    this.multiple = options['multiple'] || false;
    this.maxSelectedItems = options['maxSelectedItems'] || 100;
    this.option_count = options['option_count'] || 0;
    this.options = options['options'] || [];
    this.filter = options['filter'] || {};
    this.note = options['note'] || '';
    this.add_tag = options['add_tag'] || false;
    this.is_hidden = options['is_hidden'] || false;
    this.text = options['text'] || this.text;
    this.placeholder = options['placeholder'] || '';
  }
}
