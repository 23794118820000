<div class="container">
  <div class="row">
    <div class="col-lg-10 mr-auto ml-auto">
      <div class="card rounded-0 mt-4 mb-5 border-0">
        <div class="card-body p-md-5">
          <div class="row">

            <div class="col-md-6">
              <div class="text-center mb-4">
                <img *ngIf="!isInternetExplorer6to11" src="./assets/img/fieldmagic-logo.svg" class="img-fluid">
                <img *ngIf="isInternetExplorer6to11" src="../../assets/img/fmlogo.png" class="img-fluid">
              </div>
              <div class="text-center mb-4">
                <img src="../../assets/img/login-figure.png" class="img-fluid">
              </div>
            </div>

            <div class="col-md-6 align-self-center">

              <div *ngIf="strErrorType == 'unverified'; else defaultDisplay" class="form-group">

                <div class="text-center mb-4">
                  <h4 class="text-heading mb-3 font-weight-normal">{{ 'error_554_record' | translate }}</h4>
                  <h6 class="text-heading mb-0 font-weight-normal">{{ 'error_554_record_message' | translate }}</h6>
                </div>

                <div class="text-center mb-4">
                  <button id="btnResendEmail" (click)="resendEmail()" class="white-i btn btn-block btn-success">
                    <fa-icon [icon]="['fas','envelope']"></fa-icon>&nbsp;
                    {{ strErrorMessage | translate }}
                  </button>
                  <br>
                  <p>{{ 'or_refresh' | translate }}</p>
                  <button id="btnContinueToApp" (click)="refreshToken()" class="white-i btn btn-block btn-primary">{{ 'continue_to_app' | translate }}</button>
                </div>
              </div>

              <ng-template #defaultDisplay>
                <div class="text-center mb-4">
                  <h1 class="text-heading mb-3 font-weight-normal display-1">{{ strErrorCode | translate }}</h1>
                  <h4 class="text-heading mb-3 font-weight-normal">{{ strErrorMessage | translate }}</h4>
                  <h6 class="text-heading mb-3 font-weight-normal">{{ 'error_message_link' | translate }}</h6>
                </div>
                <div class="row">
                  <div class="col-sm-8 ml-auto mr-auto mb-4">
                    <div *ngFor="let link of arNavigateLink" class="text-center">
                      <a [routerLink]="link.source" class="inline_block">{{ link.name | translate }}</a>
                    </div>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>