<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title">
      <fa-icon *ngIf="icon" [icon]="icon" transform="shrink-3"></fa-icon>
      <span role="heading" *ngIf="title" class="pl-1">{{ title | translate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <dialog-button [buttons]="buttons" (action)="ngOnChangeButton($event)"></dialog-button>
    </div>
  </div>
</header>