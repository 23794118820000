import { Component, OnInit, HostListener, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { RecordService } from '../../../../../../services/record.service';
import { ListingService } from '../../../../../../services/listing.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  providers: [ ListingService ]
})
export class ViewComponent implements OnInit {

  /**
   * store the stock transfer data
   */
  objStockTransferData;

  /**
   * store the stock transfer quantity data
   */
  stockTransferQuantityData;

  /**
   * determine if component data is still loading
   */
  loading: boolean = false;

  /**
   * determine if the sock transfer quantity data is still loading
   */
  stockTransferQuantityLoading: boolean = false;

  /**
   * determine if the stock transfer is completed
   */
  bCompleted: boolean = false;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  /**
   * to determine if next page is enabled
   */
  get nextButton(): string {
    return (this.listingService.strNextValue != '') ? 'active' : 'disabled';
  }

  /**
   * to determine if previous page is enabled
   */
  get previousButton(): string {
    return (this.listingService.strFirstValue != this.listingService.strCurrentValue &&
      this.listingService.strCurrentValue != '')
      ? "active" : "disabled";
  }

  constructor(
    private recordService: RecordService,
    private listingService: ListingService,
    private dialogRef: MatDialogRef<ViewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.objStockTransferData = this.data;
    this.bCompleted = this.data.status == 'complete';
  }

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this.getStockTransferQuantities('default');
  }

  /**
   * cancel the dialog
   *
   * @returns {void}
   */
  cancelDialog(): void {
    this.dialogRef.close();
  }

  /**
   * get the stock transfer quantity list
   *
   * @param page
   * @param filter
   *
   * @returns {void}
   */
  getStockTransferQuantities(page: string, filter: object = {}): void {
    this.stockTransferQuantityLoading = true;
    let pagination = this.listingService.beforeFetching(page);
    filter = {...filter, ...this.getDefaultFilter()};
    this.listingService.fetchData(JSON.stringify(pagination['objPage']), 'stock_transfer_quantities', JSON.stringify(filter)).subscribe( response => {

      this.loading = false;
      this.stockTransferQuantityData = response['data'];
      this.stockTransferQuantityLoading = false;
      this.listingService.afterFetching(response, page);
    });
  }

  /**
   * get default filter
   *
   * @returns {object}
   */
  getDefaultFilter() {
    return {
      stock_transfer_id: this.objStockTransferData.id
    };
  }

  markAsComplete(): void {

    this.recordService.saveRecord('stock_transfers', {
      id: this.objStockTransferData.id,
      status: 'complete'
    }).subscribe( response => {
      if (response.status === 200) {

        // this.notificationService.notifySuccess('stock_transfer_created_success');
        // this.dialogRef.close('success');
      } else {

        // this.notificationService.notifyWarning(response.body.error[0]);
        // this.bSubmitted = false;
      }
    });
  }
}
