import { ValidatorFn, AbstractControl } from '@angular/forms';

import { LooseObject } from '../../objects/loose-object';

// Custom validator for address.
// This identifies if the address contains a street, city, state, country, and zip.
export function addressValidator(): ValidatorFn {
    return (control: AbstractControl): LooseObject | null => {
        if (control.value !== undefined && control.value['street'] !== undefined && control.value['street'] == "") {
          return {'address': true};
        } else if (control.value !== undefined && control.value['city'] !== undefined && control.value['city'] == "") {
          return {'address': true};
        } else if (control.value !== undefined && control.value['zip'] !== undefined && control.value['zip'] == "") {
          return {'address': true};
        } else if (control.value !== undefined && control.value['state'] !== undefined && control.value['state'] == "") {
          return {'address': true};
        } else if (control.value !== undefined && control.value['country'] !== undefined && control.value['country'] == "") {
          return {'address': true};
        } else {
          return null;
        }
      }
  }
