import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() label: string = null;
  @Input() icon: string = null;
  @Input() style: object = {};

  @Input() save: boolean = false;
  @Input() saveConfig: {
    label: string,
    icon: string,
    loading: boolean,
    disable: boolean
  } = {
    label: "save",
    icon: "save",
    loading: false,
    disable: true
  };
  @Input() cancel: boolean = false;

  @Output() actionResponse = new EventEmitter<{}>();

  constructor() { }

  ngOnInit() {
  }

  onChange(action: string): void {
    this.actionResponse.emit({
      action: action
    })
  }
}
