import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutDashboardService } from '../../../layout-dashboard.service';
import { Router } from '@angular/router';
import { AsideMeta } from '../../objects/aside-meta';
import { MatDialog } from '@angular/material';
import { AsideLink } from '../../objects/aside-link';
import { SubscriptionRestrictionService } from '../../../../../../services/subscription-restriction/subscription-restriction.service';
import { ADVANCED_PLAN, ENTERPRISE_PLAN } from '../../../../../../objects/subscription-plans';
import { NotificationService } from '../../../../../../services/notification.service';
import { first } from 'rxjs/operators';
import { FormPopup } from '../../../../../../objects/centralized-forms/form-popup';
import { EditformComponent } from '../../../../../components/editform/editform.component';
import { LooseObject } from '../../../../../../objects/loose-object';
import { FormComponent as StockFormsComponent } from '../../../../../../module/stock-level/widgets/stock-transfer/dialog/form/form.component';
import { EditComponent as EditJobTemplatesComponent } from '../../../../../../admin/job-templates/edit/edit.component';
import { EditRecurringJobsComponent } from '../../../../../components/widget/recurring-jobs/edit-recurring-jobs/edit-recurring-jobs.component';
import { EditInvoiceComponent } from '../../../../../../module/jobs/customer-invoices/edit-invoice/edit-invoice.component';
import { EditRecurringInvoiceFormComponent } from '../../../../../components/widget/recurring_invoices/form/edit-recurring_invoice-form.component';
import { EditSupplierInvoiceComponent } from '../../../../../../module/jobs/supplier-invoices/edit-supplier-invoice/edit-supplier-invoice.component';
import { EditPurchaseOrderComponent } from '../../../../../../module/jobs/purchase-orders/edit-purchase-order/edit-purchase-order.component';

@Component({
  selector: 'dashboard-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  /**
   * The icon used beside the label.
   *
   * @type {AsideMeta}
   */
  @Input() metadata: AsideMeta;

  /**
   * The icon used beside the label.
   *
   * @type {string[]}
   */
  @Input() icon: string[];

  /**
   * The subscriptions to destroy when leaving the
   * component, although this component is always on display
   * since this is the navigation. But just to be sure.
   *
   * @var {Subscription[]}
   */
  public arSubscriptions: Subscription[] = [];

  /**
   * If the screen is above the breaking point for the navigation.
   *
   * @var {boolean}
   */
  bAboveBreakPoint = true;

  /**
   * If the navigation on the mobile is ver is collapsed.
   *
   * @var {boolean}
   */
  bIsCollapsed = true;

  get showNavFont() {
    return !this.layoutDashboardService.minimizedAside || this.layoutDashboardService.showedAside
  }

  constructor(
    public layoutDashboardService: LayoutDashboardService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private subscription: SubscriptionRestrictionService,
    private notification: NotificationService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.arSubscriptions.forEach(subscriptions => {
      subscriptions.unsubscribe();
    });
  }

  ngOnInit() {

    [...this.metadata.panel1, ...this.metadata.panel2].forEach(link => {
      link.restrict = !(this.subscription.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(link.minimum_subscription))
    });

    this.arSubscriptions.push(
      this.breakpointObserver.observe([
        "(max-width: 991px)"
      ]).subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.bAboveBreakPoint = false;
        } else {
          this.bAboveBreakPoint = true;
        }
      })
    );
  }

  /**
   * Open the dialog component, make sure the component you will declare
   * is under the entry components of the AppModule.
   *
   * @param {AsideLink} objLink
   */
  openDialog(objLink: AsideLink, event: any = null): void {

    if (event) {
      event.preventDefault();
    }

    this.layoutDashboardService.strActiveParent = this.metadata.name;

    if (!this.bAboveBreakPoint) {
      this.layoutDashboardService.toggleAside();
    }

    if (objLink.restrict === true && objLink.minimum_subscription == ENTERPRISE_PLAN) {
      this.notification.notifyInfo('enterprise_only')
    } else if (objLink.restrict === true && objLink.minimum_subscription == ADVANCED_PLAN) {
      this.notification.notifyInfo('advanced_only')
    } else {
      if (objLink.dialog) {
        let dialog = this.dialog.open(objLink.dialog.component, objLink.dialog.config);

        if (objLink.dialog.direct === true) {
          this.arSubscriptions.push(dialog.afterClosed().pipe(first()).subscribe(objResult => {
            if (objResult) {

              if (objLink.dialog.component == EditformComponent) {
                this.getCentralizedDialogData(objLink, objResult);
              }

              if (
                objLink.dialog.component == EditRecurringJobsComponent ||
                objLink.dialog.component == EditJobTemplatesComponent ||
                objLink.dialog.component == StockFormsComponent ||
                objLink.dialog.component == EditInvoiceComponent ||
                objLink.dialog.component == EditRecurringInvoiceFormComponent ||
                objLink.dialog.component == EditSupplierInvoiceComponent ||
                objLink.dialog.component == EditPurchaseOrderComponent
              ) {
                this.getCustomDialogData(objLink, objResult);
              }
            }
          }));
        }
      } else {
        this.router.navigate(objLink.link, {queryParams: objLink.params});
      }
    }
  }

  /**
   * Get the centralized data for redirection.
   *
   * @param {AsideLink} objLink
   * @param {data: LooseObject} objResult
   *
   * @returns {void}
   */
  private getCentralizedDialogData(objLink: AsideLink, objResult: {data: LooseObject}): void {
    if (objResult.data) {

      let strRedirectModule: string = objLink.dialog.config.data['strModule'];

      if (['asset_types'].includes(strRedirectModule)) {
        strRedirectModule = `admin/${strRedirectModule}`;
      }

      if (['pricebooks'].includes(strRedirectModule)) {
        strRedirectModule = `admin/${strRedirectModule}/edit`;
      }

      this.goToView(strRedirectModule, objResult['data']['id'], objLink.params);

    }
  }

  /**
   * Get the custom data for redirection.
   *
   * @param {AsideLink} objLink
   * @param {response: {id: string}, id: string} objResult
   *
   * @returns {void}
   */
  private getCustomDialogData(objLink: AsideLink, objResult: {response: {id: string}, id: string}): void {

    let strId: string = null;

    if (objResult['id']) {
     strId = objResult['id'];
    } else if (objResult['response'] && objResult['response']['id']) {
      strId = objResult['response']['id'];
    }

    if (strId != null) {
      if (objLink.dialog.config.data['strModule'] != 'recurring_invoices') {
        this.notification.notifySuccess('header_notification.success_added');
      }
      this.goToView(objLink.dialog.config.data['strModule'], strId);
    }

  }

  /**
   * Go the module and the assigned record.
   *
   * @param {string} strModule
   * @param {string} strId
   * @param {LooseObject} objParams
   *
   * @returns {void}
   */
  private goToView(strModule: string, strId: string, objParams: LooseObject = null): void {
    this.router.navigate(
      [`/${strModule}/${strId}`],
      {...(objParams && {queryParams: objParams })}
    )
  }

}
