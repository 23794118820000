import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Address } from '../objects/address';
import { Location } from '../objects/location';
import { FormGroup } from '@angular/forms';
import { LocalStorageService } from './local-storage.service';

const strProtocol: string = 'https:';
/**
 * use latlang parameter to get the address by latitude and longitude
 * use address parameter to get the latitude and longitude of address text
 */
const strMapQuestUrl: string = strProtocol+ "//maps.googleapis.com/maps/api/geocode/json";
// use input parameter to get the places
const strMapQuestUrlAutoCompletePlace: string = strProtocol+ "//maps.googleapis.com/maps/api/place/autocomplete/json";
const strCountryUrl: string = strProtocol+ "//battuta.medunes.net/api/country/all/?key=93fc5e4b6d80dcee40f73e800e42985e";
const strListFilePath = './assets/api/states-countries.json';

//Initialize headers for external API.
let headers = new HttpHeaders({
  'Content-Type': 'text/plain',
});

//Initialize parameters for google map API params.
let params = new HttpParams()
  .set('key', 'AIzaSyDOMAL3H37-L_Mg5VjLSSFRbGNT5SvSSUk')

@Injectable()
export class AddressService {

  //Initialize httpoptions, will use this to combine the headers and params for later use.
  private httpOptions = {};
  private objCountryFullNames = {};

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    // Get the countries and translate to an indexed list
    this.http.get<Location[]>(strListFilePath)
      .subscribe(objFullList => {
        // Initialize
        this.objCountryFullNames = {};
        // Loop through each list
        for (var strKey in objFullList) {
          // Add to the index
          this.objCountryFullNames[objFullList[strKey].code] = objFullList[strKey].name;
        }
    });
  }

  /*
   * Get all the places based on query.
   */
  getPlaces(strQuery): Observable<Address[]>{

    /*
     * Place the searched query to the parameters.
     * We'll also set the maximum results to 7.
     */
    params = params
              .set('input', strQuery);

    // Add updated parameters to HttpOptions
    this.httpOptions =  {
      params,
      headers
    }

    //Properly map values to form an Observable array of Address.
    return this.http.get(strMapQuestUrlAutoCompletePlace, this.httpOptions)
      .map(res => {
        if (res['status'] == 'OK') {
          return res['results'].map(
            items => {
              return new Address(
                items.latitude,
                items.longitude,
                items.street, // Street
                "", // Unit
                "", // Level
                items.adminArea6, // Neighborhood | Building Name
                "", // Lot Number
                items.adminArea5, // City
                items.adminArea3, // State
                items.adminArea1, // Country
                items.postalCode // ZIP
              );
            }
          );
        } else {
          return [];
        }

      });
  }

  //Get a list of countries in the .json file at assets/api.
  getAllCountries(): Observable<Location[]> {
    return this.http.get<Location[]>(strListFilePath);
  }

  //Get a list of states/region depending on the given country code.
  getStatesByCountry(strCountryCode): Observable<Location[]> {
    return this.http.get<Location[]>(strListFilePath)
    .map(
      res => {
        // FC-816: Undefined property regions
        let objResult = res.find(item => (item.code == strCountryCode));
        if (objResult && objResult.hasOwnProperty('regions')) {
          return objResult['regions'];
        }

        // return empty array result
        return [];
      }
    );
  }

  //Get the country based on the longitude and latitude.
  getCountryViaLongLat(strLongLat): Observable<string> {

    let strCountryCode: string;
    //Set the long lat as location.
    params = params.set('address', strLongLat);
    //Combine the headers and parameters.
    this.httpOptions =  {
      params,
      headers
    }

    //Get the country of the first given location.
    return this.http.get<string>(strMapQuestUrl, this.httpOptions)
      .map(
        res => {
          //Get the first record's country code.
          strCountryCode = res['results'][0]['address_components'][0]['short_name'];
          return strCountryCode;
        }
      );
  }

  getCountryNameByCode(strCountryCode) {
    // Do we have a valid code?
    if (this.objCountryFullNames[strCountryCode] != undefined) {
      // Return the full name
      return this.objCountryFullNames[strCountryCode];
    }
    // No match
    return "";
  }

  /**
   *
   * @param addressData
   * @returns Address
   */
  setMissingStateAndCountryByClientDefault(addressData: Address): Address {
    let objCurrentClient = JSON.parse(this.localStorageService.getItem('current_client'));
    let currentClientAddress = JSON.parse(objCurrentClient['address']);

    addressData['country'] = addressData['country'] || currentClientAddress['country'];
    addressData['state'] = addressData['state'] || currentClientAddress['state'];

    return addressData;
  }
}
