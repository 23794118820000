import { LooseObject } from '../loose-object';
import { Relate } from "../relate";

export interface ItemReorderInterface {
  item_id: string;
  item_name: string;
  item_code: string;
  item_text: string;
  unit_cost: number;
  warehouse_id: string;
  warehouse_text: string;
  stock_level_quantity: number;
  customer_id: string;
  customer_text: string;
  min_stock_level: number;
  min_reorder_level: number;
  preferred_supplier: Relate<any>;
  is_included: boolean;
  is_supplier_new_pricing: boolean;
  is_unit_cost_changed: boolean;
  use_supplier_pricing: boolean;
  original_data: LooseObject;
  purchase_orders: LooseObject[];
  lead_time: number;
}
export class ItemReorder implements ItemReorderInterface {
  item_id: string;
  item_name: string;
  item_code: string;
  item_text: string;
  unit_cost: number;
  warehouse_id: string;
  warehouse_text: string;
  stock_level_quantity: number;
  customer_id: string;
  customer_text: string;
  min_stock_level: number;
  min_reorder_level: number;
  preferred_supplier: Relate<any>;
  is_included: boolean;
  is_supplier_new_pricing: boolean;
  is_unit_cost_changed: boolean;
  use_supplier_pricing: boolean;
  original_data: LooseObject;
  item_relate: Relate<any>;
  is_additional: boolean;
  warehouse_relate: Relate<any>;
  supplier_pricing_list: LooseObject[] = [];
  item_preferred_supplier: LooseObject[] = [];
  purchase_orders: LooseObject[] = [];
  lead_time: number;

  constructor(
    properties: ItemReorderInterface,
    supplierRelate: Relate<any>,
    is_included: boolean = false,
    itemRelate: Relate<any> = null,
    warehouseRelate: Relate<any> = null) {

    this.item_id = properties.item_id;
    this.item_name = properties.item_name;
    this.item_code = properties.item_code;
    this.item_text = properties.item_text;
    this.unit_cost = properties.unit_cost;
    this.warehouse_id = properties.warehouse_id;
    this.warehouse_text = properties.warehouse_text;
    this.stock_level_quantity = properties.stock_level_quantity;
    this.customer_id = properties.customer_id;
    this.customer_text = properties.customer_text;
    this.min_stock_level = properties.min_stock_level;
    this.min_reorder_level = properties.min_reorder_level || 0;
    this.preferred_supplier = supplierRelate;
    this.is_included = is_included;
    this.is_supplier_new_pricing = properties.is_supplier_new_pricing || false;
    this.is_unit_cost_changed = false;
    this.use_supplier_pricing = false;
    this.original_data = properties;
    this.item_relate = itemRelate;
    this.is_additional = itemRelate ? true : false;
    this.warehouse_relate = warehouseRelate;
    this.purchase_orders = properties.purchase_orders;
    this.lead_time = properties.lead_time || 0;
  }

}
