import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { FormService } from '../../../../../services/form.service';
import { NotificationService } from '../../../../../services/notification.service';
import { WizardQuoteData } from '../../../objects/wizard';

@Component({
  selector: 'quote-step',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  /**
   * The stepper of main parent wizard.
   *
   * @var {MatStepper}
   */
  @Input() stepper: MatStepper;

  /**
   * ID of the job, when creating a quote from the job view
   *
   * @type {string}
   */
  @Input() jobId: string;

  /**
   * Emitter to send the quote data to
   * the parent.
   *
   * @var {EventEmitter<WizardQuoteData>}
   */
  @Output() objQuoteData = new EventEmitter<WizardQuoteData>();

  /**
   * The form group of the quote.
   *
   * @var {FormGroup}
   */
  form: FormGroup;

  /**
   * Flag if the form was submitter.
   *
   * @var {boolean}
   */
  bSubmitted: boolean = false;

  /**
   * Used for the centralized forms to
   * set the mode.
   *
   * @var {string}
   */
  strMode: string = 'add';

  /**
   * Uesd for the centralized forms
   * to indicate the module.
   *
   * @var {string}
   */
  strModule = 'opportunities';

  /**
   * List of fields to be created.
   *
   * @var {any}
   */
  arFields: any = [
    {
      "required": true,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "opportunity_name",
      "label": "opportunity_name",
      "controlType": "text",
      "space": 12,
      "max_length": 64,
      "has_primary": false
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "summary",
      "label": "summary",
      "controlType": "textarea",
      "space": 6,
      "is_hidden": false,
      "max_length": 2056,
      "has_primary": false,
      "rows": 4
    },
    {
      "required": false,
      "readonly": false,
      "is_admin": false,
      "default_value": "",
      "key": "notes",
      "label": "notes",
      "controlType": "textarea",
      "space": 6,
      "is_hidden": false,
      "max_length": 2056,
      "has_primary": false,
      "rows": 4
    }
  ];

  constructor(
    private notifService: NotificationService,
    private formService: FormService,
  ) { }

  ngOnInit() {
    this.form = this.formService.toFormGroup(this.arFields);
  }

  /**
   * When the user hits next.
   *
   * @returns {void}
   */
  next() {
    this.form.markAsDirty();
    this.form.markAsTouched();
    this.bSubmitted = true;
    if (this.form.invalid) {
      this.notifService.notifyError('please_complete_the_form');
    } else {
      this.objQuoteData.emit({
        ...this.form.value,
        from_job_id: this.jobId
      });
    }
  }

}
