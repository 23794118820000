import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';

import {
  MatAutocompleteModule,
  MatSelectModule,
  MatDividerModule,
  MatToolbarModule,
  MatDialogModule,
  MatButtonModule,
  MatInputModule,
  MatChipsModule,
  MatIconModule,
  MatOptionModule,
  MatMenuModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatStepperModule,
  MatRadioModule
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    CommonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDividerModule,
    MatDialogModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatOptionModule,
    MatMenuModule,
    OverlayModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatStepperModule,
    MatRadioModule
  ],
  declarations: [],
})

export class MaterialModule {}
