<header >
  <div class="d-flex dialog-header">
      <div class="dialog-title">
        <span *ngIf="!editText" (click)="focusInput(true)">{{ strChecklistName | translate }} <fa-icon class="ml-2" [icon]="['fas', 'pencil']"></fa-icon></span>
        <fc-text-input
          *ngIf="editText"
          #checklistName
          (focusout)="focusInput(false)"
          [(ngModel)]="strChecklistName"
          (ngModelChange)="markAsEdited()"
          id="checklistName"
          [customClass]="'form-control input-height-40 inline-block mb-3'">
        </fc-text-input>
      </div>
      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary" [disabled]="bShowLoader">
              <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
              <span class="pl-1">{{ 'back' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <button type="button" (click)="deleteRecord()" class="btn ml-1 btn-warning text-white">
              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
              <span class="pl-1">{{ 'delete' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <button (click)="onSubmit()" [disabled]="bShowLoader || !strChecklistName" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader)? 'btn-spinner' : ''">
              <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
  </div>
  <hr />
</header>

<div class="row">
    <div class="col-4 rows">
        <span class="labels">{{ 'checklist_type' | translate }}</span>
        <ng-select
          disabled
          bindLabel="checklist_type"
          [bindValue]
          [(ngModel)]="strChecklistType"
          (ngModelChange)="markAsEdited()">
          <ng-option value="job_checklist">{{ 'job_checklist' | translate }}</ng-option>
          <ng-option value="quote_checklist">{{ 'quote_checklist' | translate }}</ng-option>
          <ng-option value="assets_checklist">{{ 'assets_checklist' | translate }}</ng-option>
        </ng-select>
    </div>
    <div class="col-4 rows" *ngIf="strChecklistType === 'assets_checklist'">
        <span class="labels">{{ 'asset_type' | translate }}</span>
        <ng-select
          disabled
          [items]="objAssetType['obv'] | async"
          [typeahead]="objAssetType['typehead']"
          [loading]="objAssetType['loader']"
          [hideSelected]="true"
          [clearable]="false"
          [ngModelOptions]="{standalone: true}"
          bindLabel="text"
          bindValue="id"
          [(ngModel)]="strAssetType"
          [id]="objAssetType['name']"
          [name]="objAssetType['name']"
          (ngModelChange)="markAsEdited()">

        </ng-select>
    </div>
    <div class="col-4 rows" *ngIf="strChecklistType === 'assets_checklist'">
        <span class="labels">{{ 'period' | translate }}</span>
        <ng-select
          [items] = arAvailablePeriod
          bindLabel="available_periods"
          [bindValue]
          [multiple]= true
          (change)="getPeriods($event)"
          [(ngModel)]="arPeriod"
          (ngModelChange)="markAsEdited()">

            <ng-template ng-option-tmp let-item="item" class="value-text">
                {{ item | translate }}
            </ng-template>

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                {{ item | translate }}
            </ng-template>
        </ng-select>
      </div>
</div>
<div class="row pl010">
    <div class="col-6 rows" >
      <div>
          <span class="labels">{{ 'is_checklist_enabled' | translate }}</span>
          <br/>
          <mat-slide-toggle color="primary"
              [(ngModel)]="isChecklistEnabled"
              class="value-text"
              name="isChecklistEnabled"
              (ngModelChange)="markAsEdited()">
          </mat-slide-toggle>
      </div>
    </div>
</div>

<div *ngFor="let objPrompt of arPrompts, index as indexPromptTypes" >
    <div class="row" *ngIf="(objPrompt['name'] != 'main_prompts' && strChecklistType == 'assets_checklist') || strChecklistType != 'assets_checklist'">
        <hr />
        <div class="col-md-6 mb-3 form-group">
            <span class="module-title">
              {{ objPrompt['name'] | translate }}
            </span>
        </div>
        <div class="col-md-6 mb-3 form-group">
            <div class="float-right mr-1">
                <span class="add_prompt mr-1" (click)="addPrompt(indexPromptTypes, objPrompt['prompts'])" matTooltip="{{ 'add_prompt' | translate }}" matTooltipPosition="above">
                    <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
                    {{ 'add_a_prompt' | translate }}
                </span>

                <span class="add_prompt" matTooltip="{{ 'add_repeating_group_tooltip' | translate }}" (click)="addGroup(indexPromptTypes)" matTooltipPosition="above">
                    <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
                    {{ 'add_repeating_group' | translate }}
                </span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="(objPrompt['name'] != 'main_prompts' && strChecklistType == 'assets_checklist') || strChecklistType != 'assets_checklist'">

        <div class="col-md-12 mb-5">
            <table class="table table-striped w100p">
                <thead>
                    <tr>
                        <th class="theader {{ objPromptColumnClasses.prompt_icons_class }}"></th>
                        <th class="theader {{ objPromptColumnClasses.prompt_name_class }}">{{ 'prompt' | translate }}</th>
                        <th class="theader {{ objPromptColumnClasses.prompt_type_class }}">{{ 'type' | translate }}</th>
                        <th class="theader {{ objPromptColumnClasses.prompt_value_class }}">{{ 'value' | translate }}</th>
                        <th class="theader {{ objPromptColumnClasses.prompt_sched_class }}">{{ 'schedule_type' | translate }}</th>
                        <th class="theader {{ objPromptColumnClasses.prompt_req_class }}"><fa-icon [icon]="['fas', 'asterisk']" aria-hidden="true" matTooltip="{{ 'tooltip_required' | translate }}"></fa-icon></th>
                        <th class="theader {{ objPromptColumnClasses.prompt_icons_class }}"><fa-icon [icon]="['fas', 'desktop']" aria-hidden="true" matTooltip="{{ 'tooltip_report_visibility' | translate }}"></fa-icon></th>
                        <th class="theader {{ objPromptColumnClasses.prompt_icons_class }}">
                            <!-- If checklist type is asset, adjust header spacing -->
                            {{ (strChecklistType === 'assets_checklist') ? '&nbsp;&nbsp;&nbsp;' : '' }}
                        </th>
                    </tr>
                </thead>
                <tbody
                    *ngIf="objPrompt['prompts'].length"
                    id="outer"
                    class="prompt-container"
                    [cdkDropListEnterPredicate]="dragAnimationFunc"
                    [cdkDropListConnectedTo]="repeatingGroups"
                    (cdkDropListDropped)="arrangePrompts($event, indexPromptTypes)"
                    cdkDropList>

                    <ng-container *ngFor="let prompt of objPrompt['prompts'];  index as indexPrompts">
                        <tr
                            app-checklist-prompt
                            *ngIf="!prompt['is_group']"
                            class="prompt-row"
                            [objPrompt]="prompt"
                            [arPromptTypes]="arPromptTypes"
                            [numIndex]="indexPrompts"
                            [arFieldEntries]="arFieldEntry"
                            [arAssetFieldEntries]="arFieldEntryTypes"
                            [objSearcher]="objAssetType"
                            [strChecklistType]="strChecklistType"
                            [strPromptCategory]="objPrompt['name']"
                            [objPromptColumnClasses]="objPromptColumnClasses"
                            [arPeriodList]="arPeriodList"
                            (rowIndexEmitter)="removePrompt(indexPromptTypes, $event)"
                            (promptEditedEmitter)="markAsEdited($event)"
                            [cdkDragData]="prompt"
                            cdkDrag>

                            <td class="text-center {{ objPromptColumnClasses.prompt_icons_class }}">
                                <fa-icon [icon]="['fas', 'grip-vertical']" class="grabbing fs-16" cdkDragHandle matTooltip="{{ 'drag_to_change_position' | translate }}"></fa-icon>
                            </td>
                        </tr>

                        <tr *ngIf="prompt['is_group']" class="prompt-row" [cdkDragData]="prompt" cdkDrag (mouseout)="setDragAnimationFlag(true)" (mouseover)="setDragAnimationFlag(false)" >
                            <td class="p-0" colspan="8">
                                <div>
                                    <table class="w-100 bg-light-blue rounded-top">
                                        <tbody
                                            [id]="'repeating-group-' + indexPrompts + '-header'"
                                            [cdkDropListConnectedTo]="getConnectedDropLists('repeating-group-' + indexPrompts)"
                                            (cdkDropListDropped)="arrangePrompts($event, indexPromptTypes, prompt)"
                                            cdkDropList>

                                            <tr class="group-name-row">
                                                <td class="text-center border-top-0 {{ objPromptColumnClasses.prompt_icons_class }}">
                                                    <fa-icon [icon]="['fas', 'grip-vertical']" class="grabbing fs-16 text-white" cdkDragHandle matTooltip="{{ 'drag_to_change_position' | translate }}"></fa-icon>
                                                </td>

                                                <td
                                                    class="text-center border-top-0 pl-0 pr-0 pb-4 pt-4"
                                                    [ngClass]="strChecklistType === 'assets_checklist' ? 'group-name-col-asset' : 'group-name-col'"
                                                    colspan="6">

                                                    <div class="d-flex align-items-center gap-5">
                                                        <input type="text" class="form-control text-size w-50" placeholder="{{ 'group_name' | translate }}" [(ngModel)]="prompt['group_name']">

                                                        <span class="fa-stack">
                                                            <fa-icon
                                                                [icon]="['fas', 'circle']"
                                                                class="fa-stack-1x text-white fs-18 ml-2">
                                                            </fa-icon>

                                                            <fa-icon
                                                                [icon]="['fas', 'plus-circle']"
                                                                class="text-success fa-stack-1x pointer fs-18 ml-2"
                                                                (click)="addPromptUnderGroup(indexPrompts, prompt)"
                                                                matTooltip="{{ 'add_prompt_under_group' | translate }}">
                                                            </fa-icon>
                                                        </span>
                                                      </div>
                                                </td>

                                                <td class="text-center border-top-0 {{ objPromptColumnClasses.prompt_icons_class }}">
                                                    <fa-icon [icon]="['fas', 'trash-alt']" class="text-warning pointer" (click)="removePrompt(indexPromptTypes, indexPrompts)"></fa-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table table-striped w100p mb-0 border-light-blue">
                                        <tbody
                                            [id]="'repeating-group-' + indexPrompts"
                                            class="prompt-container"
                                            [cdkDropListConnectedTo]="getConnectedDropLists('repeating-group-' + indexPrompts)"
                                            (cdkDropListDropped)="arrangePrompts($event, indexPromptTypes, prompt)"
                                            cdkDropList>

                                            <tr
                                                app-checklist-prompt
                                                cdkDrag
                                                class="prompt-row"
                                                *ngFor="let subprompt of prompt['prompts']; index as i"
                                                [objPrompt]="subprompt"
                                                [arPromptTypes]="arPromptTypes"
                                                [numIndex]="i"
                                                [arFieldEntries]="arFieldEntry"
                                                [arAssetFieldEntries]="arFieldEntryTypes"
                                                [objSearcher]="objAssetType"
                                                [strChecklistType]="strChecklistType"
                                                [strPromptCategory]="objPrompt['name']"
                                                [objPromptColumnClasses]="objPromptColumnClasses"
                                                [arPeriodList]="arPeriodList"
                                                [bIsUnderGroup]="true"
                                                (rowIndexEmitter)="onRemovePromptUnderGroup($event, prompt)"
                                                (promptEditedEmitter)="markAsEdited($event)"
                                                [cdkDragData]="subprompt">

                                                <td class="text-center {{ objPromptColumnClasses.prompt_drag_class }}">
                                                    <fa-icon [icon]="['fas', 'grip-vertical']" class="grabbing fs-16" cdkDragHandle matTooltip="{{ 'drag_to_change_position' | translate }}"></fa-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tbody *ngIf="objPrompt['prompts'].length <= 0" align="center">
                    <td [attr.colspan]="8">
                        <span *ngIf="objPrompt['name'] == 'main_prompts'"  class="text-center">
                            <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>{{ 'no_main_prompts' | translate }}
                        </span>
                        <span *ngIf="objPrompt['name'] == 'per_asset_prompts'" class="text-center">
                            <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>{{ 'no_assets_prompts' | translate }}
                        </span>
                    </td>
                </tbody>

            </table>
        </div>
    </div>
</div>
