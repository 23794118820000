<div class="row">
  <div class="col-12">
    <div class="row">
        <div class="col-12">
            <span class="header-title">
                <fa-icon [icon]="['far', 'file-pdf']" class="pr-1"></fa-icon>{{ 'annual_condition_report' | translate }}
            </span>
            <ul class="list-inline float-right mb-0">
              <li class="list-inline-item">
                <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
                  <fa-icon [icon]="['fas', 'times']"></fa-icon>
                  <span class="pl-1">{{ 'close' | translate }}</span>
                </button>
              </li>
              <li class="list-inline-item">
                <button (click)="onSubmit()" [disabled]="bShowLoader" class="btn ml-1 btn-primary">
                  <fa-icon [icon]="['fas', (bShowLoader) ? 'spinner' : 'file-pdf']" [spin]="(bShowLoader) ? true : false" aria-hidden="true"></fa-icon>
                  <span class="pl-1">{{ 'preview' | translate }}</span>
                </button>
              </li>
            </ul>
        </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <form *ngIf="objForm" [formGroup]="objForm" onBlur="test()">
          <div class="row">
            <div class="col-6">
                <app-edit-field [item]="objFormMetadata['start_date']" [form]="objForm" (strCurrentValue)="updateDate('start_date', $event)" [strMode]="'add'" [strModule]="'sites_annual_condition_report'" [strUpdateValue]="objForm.controls['start_date'].value"></app-edit-field>
            </div>
            <div class="col-6">
                <app-edit-field [item]="objFormMetadata['end_date']" [form]="objForm" (strCurrentValue)="updateDate('end_date', $event)" [strMode]="'add'" [strModule]="'sites_annual_condition_report'" [strUpdateValue]="objForm.controls['end_date'].value"></app-edit-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>