import * as _moment from 'moment';
import { Subject, of, concat, Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap, switchMap, finalize, map } from 'rxjs/operators';

import { Select } from '../../../../objects/select';
import { Department } from '../../../../objects/department';
import { RelateIds } from '../../../../lists/relate-ids';
import { ViewService } from '../../../../services/view.service';
import { FormService } from '../../../../services/form.service';
import { RecordService } from '../../../../services/record.service';
import { ClientStoreService } from '../../../../services/client-store.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { InputSanitizerService } from '../../../../services/input-sanitizer.service';
import { CustomTranslateService } from '../../../../services/custom-translate.service';
import { PdfComponent } from './../../../../shared/components/view/pdf/pdf.component';
import { NumberService } from '../../../../services/helpers/number.service';
import { ArrService } from '../../../../services/helpers/arr.service';
import { StrService } from '../../../../services/helpers/str.service';
import { SelectTemplate } from '../../../../objects/select-template';

import * as _ from 'lodash';
import { LooseObject } from '../../../../objects/loose-object';
import { RelatedProductsComponent } from '../../../../features/product-folders/static-folders/related-products/related-products.component';
import { Relate } from '../../../../objects/relate';
import { toFormattedNumber } from '../../../../shared/utils/numbers';
import { ReadableAddressPipe } from '../../../../pipes/readable-address.pipe';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { filled } from '../../../../shared/utils/common';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-edit-purchase-order',
  templateUrl: './edit-purchase-order.component.html',
  styleUrls: ['./edit-purchase-order.component.scss'],
  providers: [CustomTranslateService]
})
export class EditPurchaseOrderComponent implements OnInit {
  @ViewChild("invoiceLineDesc") textAreaInvoiceLineElement: ElementRef;

  public purchaseOrderForm: any = [];
  public strRecordId: string = (this.data.record_id != undefined && this.data.record_id != null && this.data.record_id != '') ? this.data.record_id : null;
  public strViewType: string = this.data.view_type;
  public strRecordModule: string = (this.data.module != undefined) ? this.data.module : '';
  public purchaseOrderRecord: {} = {};
  public objJobData: {} = {};
  public objCustomerData: {} = {};
  public objModuleConfig: {} = {};
  public arPurchaseOrderFieldKey: any = [];
  public objPurchaseOrderField = {};
  public bSubmitted: boolean = false;
  public bHasChanged: boolean = false;
  public bInvoiceLineLoaded: boolean = false;
  public hasError: boolean = false;
  public isTouched: boolean = false;
  public itemType: any = '';
  public arLineAttributes: any = [];
  public arTaxCodeFilter: any = { is_purchase: true };
  public arAccountCodeFilter: any = { is_purchase: true };
  public arItemFilter: any = { active: true, labor: false };
  public arReadOnlyId: any = [];
  public arRelateEmptyId: any = [];
  public arRequiredField: any = [];

  public initialRelateAccountCodeData: any = [];
  public initialRelateTaxCodeData: any = [];
  public initialRelateItemData: any = [];
  public initialRelateDepartmentData: any = [];
  public objRelateText: any = {};

  public arTranslateables = [
    'exceed_supplier_invoice_amount',
    'total_purchase_order_amount',
    'total_supplier_invoice',
    'total_remaining_amount',
  ];

  public objDefaultTaxCode = {};
  public objDefaultAccountCode = {};
  public objClientRecord: {} = {};
  public pdfPreview: boolean = false;
  public objCacheData: {} = {};
  public bFormDirty: boolean = false;
  public previewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();
  public isPreviewTemplate: boolean = false;
  public jobRelateField = new Relate<any>();
  public realTimePreviewTemplate: Subject<SelectTemplate> = new Subject<SelectTemplate>();

  public objItemDetails: LooseObject = {};

  /**
   * If department tracking is enabled.
   *
   * @var {boolean}
   */
  public bDepartmentTracking: boolean = false;

  totalTaxAdjustment: number = 0;

  readonly whenInProgress$ = new BehaviorSubject<boolean>(false);

  get invoiceLineIncludedTax(): number {
    return this.computeInvoiceLineIncludedTax();
  }

  set invoiceLineIncludedTax(value: number) {
    const adjustment = toFormattedNumber(value, {
      currency: true,
    });

    const totalPrice = this.computeInvoiceLineExcludedTax();
    const totalTax = this.computeInvoiceLineTax();

    this.totalTaxAdjustment = toFormattedNumber(adjustment - (totalPrice + totalTax), {
      currency: true,
    });

    this.setAmountDue();
  }

  /**
   * Get the supplier id.
   *
   * @returns {string}
   */
  get supplierId(): string | null {

    let strSupplierId: string | null = null;

    this.purchaseOrderForm.forEach(item => {
      if (item.groups && item.groups.get('customer_id')) {
        strSupplierId = item.groups.get('customer_id').value
      }
    });

    return strSupplierId;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    public clientStoreService: ClientStoreService,
    public dialogRef: MatDialogRef<EditPurchaseOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public formService: FormService,
    public recordService: RecordService,
    public notifService: NotificationService,
    public viewService: ViewService,
    public customTranslate: CustomTranslateService,
    public numberService: NumberService,
    public arrService: ArrService,
    public strService: StrService,
    protected is: InputSanitizerService,
    protected localStorageService: LocalStorageService,
    private readableAddressPipe: ReadableAddressPipe,
    private translateService: TranslateService
  ) {}

  /**
   * When a line item is dragged then dropped, we simply move the posiiton.
   *
   * @returns {void}
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arLineAttributes, event.previousIndex, event.currentIndex);
  }

   // This function is triggered inside the child component.
  doSomethingInParent(event) {
    // Check if field is job id
    if (event['field'] == 'job_id') {
      // Set job id
      let strJobId = (!_.isEmpty(event['value']) && this.strService.fallsBackTo(event['value']['id']) !== null) ? event['value']['id'] : null;
      // Check if val has value and strRecordModule is job then assigned it to strRecordId(job_id)
      if (this.strRecordModule == 'jobs') {
        this.strRecordId = strJobId;
      }

      this.objJobData = [];

      if (strJobId !== null) {
        // If has job id get the data
        this.recordService.getRecordRelateJoined('jobs', strJobId).subscribe( result => {
          // Set data
          this.objJobData = (result[0] != undefined && result[0] != null) ? result[0] : [];
        });
      }
    }

    if (event['field'] == 'customer_id') {
      this.recalculateLineItemBuyPrice(event['value']);
    }
  }

  initializeComponent() {
    this.customTranslate.initializeTransalateables(this.arTranslateables);

    this.initField();

    this._attachListenerForDeliveryFields();

    if (this.strViewType == 'edit' && this.data.purchase_order != undefined) {
      this.purchaseOrderForm.map( form => {
        let objField = Object.keys(form['groups']['controls']);
        objField.forEach( strField => {
          let indexField = this.getFieldIndex(strField, form);
          if (indexField > -1) {
            if (form['fields'][indexField]['controlType'] == 'relate') {
              let textField = strField.replace('_id', '_text');
              let strText = this.data.purchase_order[textField];
              form['fields'][indexField]['default_value'] = this.data.purchase_order[strField];
              form['fields'][indexField]['default_text'] = strText;
            }

            if (strField == 'customer_id' && this.strRecordModule == 'customers') {
              form['fields'][indexField]['readonly'] = true;
            }

            // Set default value for date and datetime
            if (form['fields'][indexField]['controlType'] == 'date' || form['fields'][indexField]['controlType'] == 'datetime') {
              form['fields'][indexField]['default_value'] = this.data.purchase_order[strField];
            }
          }

          if (this.data.purchase_order[strField] != undefined) {
            this.fieldPatchValue(strField, this.data.purchase_order[strField]);
          }

        });
      });
      // Get index of warehouse field
      let numWarehouseFormIndex = this.getFormFieldIndex('warehouse_id');
      let numWarehouseFieldIndex = this.getFieldIndex('warehouse_id', this.purchaseOrderForm[numWarehouseFormIndex]);
      if (numWarehouseFormIndex > -1 && numWarehouseFieldIndex > -1 && this.data.purchase_order['delivery_location'] == 'main_warehouse') {
        this.purchaseOrderForm[numWarehouseFormIndex]['fields'][numWarehouseFieldIndex]['is_hidden'] = false;
      }

      // Get index of site field
      let indexSiteForm = this.getFormFieldIndex('site_id');
      let indexSiteField = this.getFieldIndex('site_id', this.purchaseOrderForm[indexSiteForm]);
      // Get index of delivery notes field
      let indexOfDeliveryNotesForm = this.getFormFieldIndex('delivery_notes');
      let indexOfDeliveryNotesField = this.getFieldIndex('delivery_notes', this.purchaseOrderForm[indexOfDeliveryNotesForm]);
      // Check if site field is found and delivery location is site. remove hidden
      if (indexSiteForm > -1 && indexSiteField > -1 && this.data.purchase_order['delivery_location'] == 'site') {
        this.purchaseOrderForm[indexSiteForm]['fields'][indexSiteField]['is_hidden'] = false;
      }
      // Check if delivery notes field is found and delivery location is refer to delivery notes. remove hidden
      if (indexOfDeliveryNotesForm > -1 &&
          indexOfDeliveryNotesField > -1 &&
          this.data.purchase_order['delivery_location'] == 'refer_to_delivery_notes' ||
          this.data.purchase_order['delivery_location'] == 'main_warehouse'
        ) {
        this.purchaseOrderForm[indexOfDeliveryNotesForm]['fields'][indexOfDeliveryNotesField]['is_hidden'] = false;
      }

      if (numWarehouseFormIndex > -1 && numWarehouseFieldIndex > -1 && this.data.purchase_order['delivery_location'] == 'main_warehouse') {
        this.purchaseOrderForm[numWarehouseFormIndex]['fields'][numWarehouseFieldIndex]['is_hidden'] = false;
      }

      // Get index of accounting sync error and accounting sync detail
      let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
      let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
      let indexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.purchaseOrderForm[indexOfAccountingSyncDetail]);
      if (indexOfAccountingSyncError > -1 && indexOfAccountingSyncDetailField > -1) {
        // Check accountg sync error value
        let bAccountingSyncError = this.data.purchase_order['accounting_sync_error'];
        // If true show accounting sync detail field else hide it.
        if (bAccountingSyncError) {
          this.purchaseOrderForm[indexOfAccountingSyncDetail]['fields'][indexOfAccountingSyncDetailField]['is_hidden'] = false;
        } else {
          this.purchaseOrderForm[indexOfAccountingSyncDetail]['fields'][indexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
      }

    } else {
      let indexJobForm = this.getFormFieldIndex('job_id');
      let indexJobField = this.getFieldIndex('job_id', this.purchaseOrderForm[indexJobForm]);

      let indexCustomerForm = this.getFormFieldIndex('customer_id');
      let indexCustomerField = this.getFieldIndex('customer_id', this.purchaseOrderForm[indexCustomerForm]);
      // Set default customer data if exist
      if (this.objCustomerData['id'] != undefined) {
        if (indexCustomerForm > -1 && indexCustomerField > -1) {
          this.purchaseOrderForm[indexCustomerForm]['fields'][indexCustomerField]['default_value'] = this.objCustomerData['id'];
          this.purchaseOrderForm[indexCustomerForm]['fields'][indexCustomerField]['default_text'] = this.objCustomerData['customer_text'];
          this.purchaseOrderForm[indexCustomerForm]['fields'][indexCustomerField]['readonly'] = true;
        }
        this.fieldPatchValue('customer_id', (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '');
      }
      // Set default job data if exist
      if (this.objJobData['id'] != undefined) {
        if (indexJobForm > -1 && indexJobField > -1) {
          this.purchaseOrderForm[indexJobForm]['fields'][indexJobField]['default_value'] = this.objJobData['id'];
          this.purchaseOrderForm[indexJobForm]['fields'][indexJobField]['default_text'] = this.objJobData['text'];
          this.purchaseOrderForm[indexJobForm]['fields'][indexJobField]['readonly'] = true;
        }
        this.fieldPatchValue('job_id', (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '');

        if (_.trim(this.objJobData['site_id']).length > 0) {
          this.fieldPatchValue('delivery_location', 'site', {
            emitEvent: true,
          });

          let indexSiteForm = this.getFormFieldIndex('site_id');
          let indexSiteField = this.getFieldIndex('site_id', this.purchaseOrderForm[indexSiteForm]);

          if (indexSiteField > -1 && indexSiteForm > -1) {
            this.purchaseOrderForm[indexSiteForm]['fields'][indexSiteField]['default_value'] = this.objJobData['site_id'];
            this.purchaseOrderForm[indexSiteForm]['fields'][indexSiteField]['default_text'] = this.objJobData['site_text'];
            this.fieldPatchValue('site_id', this.objJobData['site_id']);
          }
        }
      }
      // Get index of accounting sync error and accounting sync detail
      let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
      let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
      let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.purchaseOrderForm[indexOfAccountingSyncDetail]);
      // If mode is create. Hide accounting sync detail as default
      if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
        this.purchaseOrderForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
      }
    }
    this.setLineItems();

  }

  ngOnInit() {
    this.objClientRecord = this.clientStoreService.getActiveClient();
    this.bDepartmentTracking = this.clientStoreService.isDepartmentTracking();

    // Declare tax code and account code variable
    let defaultTaxCodePurchase = [];
    let defaultAccountCodePurchase = [];

    if (this.strViewType == 'edit') {
      this.recordService.getRecordAndParentRecord(
        'purchase_orders', this.data['purchase_order_id'], !_.isEmpty(this.data.module) && !_.isEmpty(this.data.record_id)
      )
      .subscribe(([purchaseOrders, moduleRecord]) => {
        // Set Job Data if record view is job get the record in view service
        this.objJobData = (this.strRecordModule == 'jobs') ? moduleRecord['record_details'] : [];
        // Set Customer Data if record view is customer get the record in view service
        this.objCustomerData = (this.strRecordModule == 'customers') ? moduleRecord['record_details'] : [];
        // Set Purchase Order Record
        this.purchaseOrderRecord = purchaseOrders;
        // Set Record details of purchase order record
        this.data['purchase_order'] = purchaseOrders['record_details'];
        // Set config of purchase order
        this.objModuleConfig['record_details'] = this.purchaseOrderRecord['record_details'];
        this.objModuleConfig['record_view'] = this.purchaseOrderRecord['record_view'];
        this.objModuleConfig['used_fields'] = this.purchaseOrderRecord['used_fields'];

        this.totalTaxAdjustment = toFormattedNumber(_.get(this.purchaseOrderRecord, 'record_details.tax_adjustment_amount'), {
          currency: true,
        });

        // If purchase order record has related data
        if (this.purchaseOrderRecord['related_data'] != undefined) {
          // Set all related data to objCacheData
          this.objCacheData = this.purchaseOrderRecord['related_data'];

          // Check if default tax code exist
          if (this.purchaseOrderRecord['related_data']['default_tax_code_purchase'] != undefined) {
            // Set Default tax code
            defaultTaxCodePurchase = this.purchaseOrderRecord['related_data']['default_tax_code_purchase'];
          }

          // Check if default account code exist
          if (this.purchaseOrderRecord['related_data']['default_account_code_purchase'] != undefined) {
            // Set default account code
            defaultAccountCodePurchase = this.purchaseOrderRecord['related_data']['default_account_code_purchase'];
          }

          // If job data is empty. Check the related job data in purchase order record. What does this mean the dialog is not from widget.
          if (this.objJobData['id'] == undefined && this.purchaseOrderRecord['record_details']['job_id'] !== '' && this.purchaseOrderRecord['record_details']['job_id'] !== null) {
            this.objJobData = {
              id: this.purchaseOrderRecord['record_details']['job_id'],
              text: this.purchaseOrderRecord['record_details']['job_text']
            }
          }
        }
        // If the record view is customers. Set customer record id
        if (this.strRecordModule == 'customers') {
          this.strRecordId = (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '';
        // If the record view is customers. Set job record id
        } else if (this.strRecordModule == 'jobs') {
          this.strRecordId = (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '';
        }
        // Set default tax code
        this.objDefaultTaxCode = defaultTaxCodePurchase;
        // Set default account code
        this.objDefaultAccountCode = defaultAccountCodePurchase;
        this.initializeComponent();
        setTimeout( () => {
          this.onChanges();
        }, 2000);
      });

    } else {
      this.recordService.getConfigAndParentRecord(
        'purchase_orders', !_.isEmpty(this.data.module) && !_.isEmpty(this.data.record_id)
      )
      .subscribe(([purchaseOrders, moduleRecord]) => {
        // Set Job Data if record view is job get the record in view service
        this.objJobData = (this.strRecordModule == 'jobs') ? moduleRecord['record_details'] : [];
        // Set Customer Data if record view is customer get the record in view service
        this.objCustomerData = (this.strRecordModule == 'customers') ? moduleRecord['record_details'] : [];
        // Set purchase order config
        this.objModuleConfig = purchaseOrders;
        // Check if has related data
        if (this.objModuleConfig['related_data'] != undefined) {
          // Set all related data to objCacheData
          this.objCacheData = this.objModuleConfig['related_data'];
          // Check if default tax code exist
          if (this.objModuleConfig['related_data']['default_tax_code_purchase'] != undefined) {
            // Set default tax code
            defaultTaxCodePurchase = this.objModuleConfig['related_data']['default_tax_code_purchase'];
          }

          // Check if default account code exist
          if (this.objModuleConfig['related_data']['default_account_code_purchase'] != undefined) {
            // Set default account code
            defaultAccountCodePurchase = this.objModuleConfig['related_data']['default_account_code_purchase'];
          }
        }

        // If the record view is customers. Set customer record id
        if (this.strRecordModule == 'customers') {
          this.strRecordId = (this.objCustomerData['id'] != undefined) ? this.objCustomerData['id'] : '';
        // If the record view is jobs. Set job record id
        } else if (this.strRecordModule == 'jobs') {
          this.strRecordId = (this.objJobData['id'] != undefined) ? this.objJobData['id'] : '';
        }

        // Set default tax code
        this.objDefaultTaxCode = defaultTaxCodePurchase;
        // Set default account code
        this.objDefaultAccountCode = defaultAccountCodePurchase;
        this.initializeComponent();
        setTimeout( () => {
          this.onChanges();
        }, 2000);
      });
    }

    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancelDialog();
    });
  }

  /**
   * Initialize Field
   */
  initField() {
    // To set data of custom attributes as default value
    if (this.objModuleConfig['record_details']['custom_attributes'] != undefined && this.objModuleConfig['record_details']['custom_attributes'] != null && this.objModuleConfig['record_details']['custom_attributes'] != '') {
      let customAttributeKeys = Object.keys(this.objModuleConfig['record_details']['custom_attributes']);
      customAttributeKeys.forEach( strKey => {
        // Append each custom attributes to record details
        this.objModuleConfig['record_details'][strKey] = this.objModuleConfig['record_details']['custom_attributes'][strKey];
      });
    }

    // Add filter to customer relate
    if (this.objModuleConfig['used_fields']['customer_id'] !== undefined) {
      this.objModuleConfig['used_fields']['customer_id']['filter'] = {'is_supplier': true};
    }

    let arFormData = this.formService.formData(this.objModuleConfig['record_view'], this.objModuleConfig['used_fields'], this.objModuleConfig['record_details']);
    this.purchaseOrderForm  = arFormData.map(
      formItems => {
        formItems['id'] = formItems['label'].toLowerCase().replace(/ /g,'_');
        formItems['groups'] = this.formService.toFormGroup(formItems['fields'])
        return formItems;
      }
    );
  }

  /**
   * On changes of form
   */
  onChanges(): void {

    let indexOfSyncToAccounting = this.getFormFieldIndex('sync_to_accounting');
    let indexOfAccountingSyncError = this.getFormFieldIndex('accounting_sync_error');
    let indexOfAccountingSyncDetail = this.getFormFieldIndex('accounting_sync_detail');
    let indexindexOfAccountingSyncDetailField = this.getFieldIndex('accounting_sync_detail', this.purchaseOrderForm[indexOfAccountingSyncDetail]);

    let indexOfAmountPaid = this.getFormFieldIndex('amount_paid');
    let indexOfAmountDue = this.getFormFieldIndex('amount_due');

    this._attachListenerForDeliveryFields();

    // Onchange of accounting sync error. If true show accounting sync detail else hide it.
    if (indexOfAccountingSyncError > -1 && indexindexOfAccountingSyncDetailField > -1) {
      this.purchaseOrderForm[indexOfAccountingSyncError]['groups'].get('accounting_sync_error').valueChanges.subscribe(val => {
        if (val) {
          this.purchaseOrderForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = false;
        } else {
          this.purchaseOrderForm[indexOfAccountingSyncDetail]['fields'][indexindexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
      });
    }

    // Onchange of sync to accounting. Clear the value of accounting sync detail
    if (indexOfSyncToAccounting > -1) {
      this.purchaseOrderForm[indexOfSyncToAccounting]['groups'].get('sync_to_accounting').valueChanges.subscribe(val => {
        this.purchaseOrderForm[indexOfSyncToAccounting]['groups'].patchValue({
          accounting_sync_detail: ''
        });
      });
    }

    // If amount paid field exist
    if (indexOfAmountPaid > -1) {
      this.purchaseOrderForm[indexOfAmountPaid]['groups'].get('amount_paid').valueChanges.subscribe(val => {
        let totalIncludedTax: number = this.computeInvoiceLineIncludedTax();
        // Clear accounting sync detail when sync to accounting is change
        let strAmountDue = totalIncludedTax - parseFloat(val);
        this.purchaseOrderForm[indexOfAmountDue]['groups'].patchValue({
          amount_due: !isNaN(strAmountDue) ? _.toString(toFormattedNumber(strAmountDue, {
            currency: true,
          })) : '0.0000',
        });
      });
    }
  }

  /**
   * Removes the line item.
   * @param attr - the object to be removed.
   */
  removeAttribute(attr) {
    this.arLineAttributes.splice(this.arLineAttributes.indexOf(attr), 1);
    this.setAmountDue();
  }

  addLineAttribute(objData: any = [], bFromUi: boolean = false) {

    if (objData && bFromUi) {
      this.storeItemDetails(objData);
    }

    if (this.arLineAttributes.length === 1 && _.isEmpty(this.arLineAttributes[0]['purchase_order']['description'])) {
      this.removeAttribute(this.arLineAttributes[0]);
    }

    // Generate a unique name for the lineitem.
    let newId = 'nameField' + this.arLineAttributes.length + Math.floor((Math.random() * 10) + 1);
    // If the generated name is indeed unique (no existing item of a similar name found.).
    if (this.arLineAttributes.findIndex(attr => (attr['type'] == newId)) < 0) {

      if (Object.keys(objData).length > 0) {
        let strTaxCodeId = '';
        let strTaxCode = '';
        let strTaxRate: number = 0;
        let objTaxCode = null;

        let strAccountCodeId = '';
        let strAccountCode = '';
        let objAccountCode = null;

        if (objData['tax_code_id'] != undefined && objData['tax_code_id'] != null && objData['tax_code_id'] != '') {
          strTaxCodeId = objData['tax_code_id'];
          strTaxCode = objData['tax_code'];
          strTaxRate = parseFloat(objData['tax_rate']) * 0.01;
          objTaxCode = new Select(objData['tax_code_id'], objData['tax_code_name']);
        } else if (this.objDefaultTaxCode['id'] != undefined && this.objDefaultTaxCode['id'] != null && this.objDefaultTaxCode['id'] != '') {
          strTaxCodeId = this.objDefaultTaxCode['id'];
          strTaxCode = this.objDefaultTaxCode['code'];
          strTaxRate = parseFloat(this.objDefaultTaxCode['rate']) * 0.01;
          objTaxCode = new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']);
        }

        if (objData['account_code_id'] != undefined && objData['account_code_id'] != null && objData['account_code_id'] != '') {
          strAccountCodeId = objData['account_code_id'];
          strAccountCode = objData['account_code'];
          objAccountCode = { id: objData['account_code_id'], name: objData['account_code_name'] };
        } else if (this.objDefaultAccountCode['id'] != undefined && this.objDefaultAccountCode['id'] != null && this.objDefaultAccountCode['id'] != '') {
          strAccountCodeId = this.objDefaultAccountCode['id'];
          strAccountCode = this.objDefaultAccountCode['code'];
          objAccountCode = { id: this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name'] };
        }

        // Set value of relate field
        let strItemId = bFromUi ? objData['id'] : objData['item_id'];
        let strItemName = objData['name'] || objData['item_name'];
        let strItemcode = objData['code'] || objData['item_code'];
        let strDescription = objData['description'] || objData['name'];
        let strQuantity: number = objData['quantity'] || 1;
        let strUnitCost: number = toFormattedNumber(objData['unit_cost'], {
          currency: true,
          maxDecimalPlaces: 4,
        });
        let strDepartmentName = objData['department_name'];
        let strDepartmentId = objData['department_id'];
        let objDepartment = (strDepartmentId) ? new Select(strDepartmentId, strDepartmentName) : {};
        let objItem = (strItemId) ? new Select(strItemId, strItemName) : {};
        let strItemAccountingId = objData['accounting_id'];
        let lineItemJobId = objData['job_id'];
        let lineItemJobData = null;
        let defaultJobForRelateField = [];

        if (lineItemJobId) {
          lineItemJobData = {
            'job_number': objData['job_number'],
            'id': objData['id'],
          };
        }

        if ((!lineItemJobId && bFromUi) || (!lineItemJobId && _.get(this.data, 'module') == 'jobs')) {
          lineItemJobId = _.get(this.objJobData, 'id');
          let defaultJob = {
            id: this.objJobData['id'],
            job_number: this.objJobData['text'],
          };

          defaultJobForRelateField = _.get(this.objJobData, 'id') ? [defaultJob] : [];

          lineItemJobData = defaultJob;
        }

        let indexOfSupplierField = this.getFormFieldIndex('customer_id');

        if (indexOfSupplierField > -1 && _.get(objData, 'supplier_pricing')) {
          let customerId = this.purchaseOrderForm[indexOfSupplierField]['groups'].controls.customer_id.value;
          let itemSupplierIndex = objData['supplier_pricing'].findIndex(supplier => (supplier.customer_id == customerId));

          if (itemSupplierIndex > -1) {
            strUnitCost = objData['supplier_pricing'][itemSupplierIndex]['unit_cost'];
          }
        }

        this.arLineAttributes.push({
          id : "",
          purchase_order: {...{
            item_id: strItemId,
            item_code: strItemcode,
            account_code: strAccountCode,
            tax_code: strTaxCode,
            department_id: strDepartmentId,
            department_name: strDepartmentName,
            description: strDescription,
            quantity: strQuantity,
            unit_cost: strUnitCost,
            tax_code_id: strTaxCodeId,
            account_code_id: strAccountCodeId,
            item_accounting_id: strItemAccountingId,
            job_id: lineItemJobId
          }, ... (!bFromUi) ? {id: objData['id']} : {}},
          type: newId,
          item: objItem,
          tax: objTaxCode,
          account_code: objAccountCode,
          department: objDepartment,
          item_obv: new Observable<Select[]>(),
          item_typehead: new Subject<string>(),
          item_loader: false,
          taxcode_obv: new Observable<Select[]>(),
          taxcode_typehead: new Subject<string>(),
          taxcode_loader: false,
          account_code_obv: new Observable<Select[]>(),
          account_code_typehead: new Subject<string>(),
          account_code_loader: false,
          department_obv: new Observable<Select[]>(),
          department_typehead: new Subject<string>(),
          department_loader: false,
          job: this.buildJobRelateField(defaultJobForRelateField),
          job_data: lineItemJobData,
          amount: toFormattedNumber(strUnitCost * strQuantity, {
            currency: true,
          }),
          tax_rate: strTaxRate,
          current_stock_level: objData['current_stock_level'] || 0,
          supplier_info: objData['supplier_pricing'] || [],
          related_products: objData['related_products'] || [],
          supplier_pricing_note: objData['supplier_pricing_note'],
          show_related_job_field: false,
        });

      } else {
        let objItem = null;
        let strItemId = null;
        let strItemName = null;
        let strItemCode = null;
        let strDescription = null;
        let intQuantity = 1;
        let intUnitCost = 0.00;
        let intAmount = 0.00;

        let arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
        let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';
        let objDepartmentJob = new Department().getJobDepartment(this.objJobData);

        // FC-3928: Inherit the products on work order line items
        if (objData) {

          strItemId = this.strService.fallsBackTo(objData['item_id']);
          strItemName = this.strService.fallsBackTo(objData['item_name']);
          strItemCode = this.strService.fallsBackTo(objData['item_code']);
          strDescription = this.strService.fallsBackTo(objData['description'], objData['name']);
          intQuantity = Number(this.strService.fallsBackTo(objData['quantity'], 1));
          intUnitCost = Number(this.strService.fallsBackTo(objData['unit_cost'], '0.00'));
          intAmount = Number(this.strService.fallsBackTo(objData['line_item'], '0.00'));

          objItem = (strItemId && strItemName) ? new Select(strItemId, strItemName) : null;
        }

        let defaultJobForRelateField = _.get(this.objJobData, 'id') ? [{
          id: this.objJobData['id'],
          job_number: this.objJobData['text'],
        }] : [];

        this.arLineAttributes.push({
            id : "",
            purchase_order: {
              item_id: strItemId,
              item_code: strItemCode,
              account_code: (this.objDefaultAccountCode['code'] != undefined) ? this.objDefaultAccountCode['code'] : null,
              tax_code: (this.objDefaultTaxCode['code'] != undefined) ? this.objDefaultTaxCode['code'] : null,
              department_id: this.strService.fallsBackTo(objDepartmentJob.department_id),
              department_name: this.strService.fallsBackTo(objDepartmentJob.department_text),
              description: strDescription,
              quantity: intQuantity,
              unit_cost: intUnitCost,
              tax_code_id: (this.objDefaultTaxCode['id'] != undefined) ? this.objDefaultTaxCode['id'] : null,
              account_code_id: (this.objDefaultAccountCode['id'] != undefined) ? this.objDefaultTaxCode['id'] : null,
              item_accounting_id: strDefaultItemAccountingId,
              job_id: _.get(this.objJobData, 'id'),
            },
            type: newId,
            item: objItem,
            tax: (this.objDefaultTaxCode['id'] != undefined) ? new Select(this.objDefaultTaxCode['id'], this.objDefaultTaxCode['text']) : {},
            account_code: (this.objDefaultAccountCode['id'] != undefined) ? {id : this.objDefaultAccountCode['id'], name: this.objDefaultAccountCode['name']} : {},
            department: !_.isEmpty(objDepartmentJob.department) ? objDepartmentJob.department : {},
            item_obv: new Observable<Select[]>(),
            item_typehead: new Subject<string>(),
            item_loader: false,
            taxcode_obv: new Observable<Select[]>(),
            taxcode_typehead: new Subject<string>(),
            taxcode_loader: false,
            account_code_obv: new Observable<Select[]>(),
            account_code_typehead: new Subject<string>(),
            account_code_loader: false,
            department_obv: new Observable<Select[]>(),
            department_typehead: new Subject<string>(),
            department_loader: false,
            job: this.buildJobRelateField(defaultJobForRelateField),
            job_data: {
              'job_number': _.get(this.objJobData, 'job_number'),
              'id': _.get(this.objJobData, 'id'),
            },
            amount: (intQuantity * intUnitCost) || '0.00',
            tax_rate: (this.objDefaultTaxCode['id'] != undefined) ? parseFloat(this.objDefaultTaxCode['rate']) * 0.01 : 0,
            current_stock_level: 0,
            supplier_info: [],
            related_products: [],
            supplier_pricing_note: '',
            show_related_job_field: false,
        });
      }

      // For Tax Code Relate
      this.initRelateRecords('arLineAttributes', 'tax_codes', 'taxcode', this.arTaxCodeFilter, this.initialRelateTaxCodeData);
      // For Items Relate
      this.initRelateRecords('arLineAttributes', 'items', 'item', this.arItemFilter, this.initialRelateItemData);
      // For Account Code Relate
      this.initRelateRecords('arLineAttributes', 'account_codes', 'account_code', this.arAccountCodeFilter, this.initialRelateAccountCodeData);
      // For Department Relate
      this.initRelateRecords('arLineAttributes', 'departments', 'department', false, this.initialRelateDepartmentData);

      this.bInvoiceLineLoaded = true;
      if (Object.keys(objData).length < 1) {
        this.setAmountDue();
      }
    }
  }

  /**
   * Compute total cost, price and amount when quantity is change
   * @param event
   * @param attr
   * @param strModule
   */
  quantityChange(event, attr) {
    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));
    if (numLineItem > -1) {
      let quantity = (event.target.value != '' && event.target.value > 0) ? event.target.value : 1;
      let totalCost: number = parseFloat(this.arLineAttributes[numLineItem]['purchase_order']['unit_cost']) * parseFloat(quantity);
      this.arLineAttributes[numLineItem]['purchase_order']['quantity'] = quantity;
      this.arLineAttributes[numLineItem]['amount'] = toFormattedNumber(totalCost, {
        currency: true,
      });
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * Compute data when unit price is change
   * @param event
   * @param attr
   * @param strModule
   */
  unitCostChange(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));
    if (numLineItem > -1) {
      let unitCost: number = (event.target.value != '') ? event.target.value : 0;
      let totalAmount: number = (unitCost * this.arLineAttributes[numLineItem]['purchase_order']['quantity']);
      this.arLineAttributes[numLineItem]['amount'] = toFormattedNumber(totalAmount, {
        currency: true,
      });
      this.arLineAttributes[numLineItem]['purchase_order']['unit_cost'] = toFormattedNumber(unitCost, {
        currency: true,
        maxDecimalPlaces: 4,
      });
      this.setAmountDue();
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectATaxcode(event, attr) {
    let strTaxCodeId = (event == undefined || event == null) ? null : event['id'];
    let strTaxName = (event == undefined || event == null) ? null : event['name'];
    let strTaxCode = (event == undefined || event == null) ? null : event['code'];
    let strTaxRate = (event == undefined || event == null) ? 0 : parseFloat(event['rate']) * 0.01;

    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));

      // If the item is found.
      if (numLineItem > -1) {
          // Set the values of the line item.
          this.arLineAttributes[numLineItem]['purchase_order']['tax_code_id'] = strTaxCodeId;
          this.arLineAttributes[numLineItem]['purchase_order']['tax_code'] = strTaxCode;
          this.arLineAttributes[numLineItem]['tax_rate'] = strTaxRate;
          this.setAmountDue();
      }
      this.markAsDirty();
  }
  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectAccountCode(event, attr) {
    let strAccountCodeId = (event == undefined || event == null) ? null : event['id'];
    let strAccountName = (event == undefined || event == null) ? null : event['name'];
    let strAccountCode = (event == undefined || event == null) ? null : event['code'];

    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));
    // If the item is found.
    if (numLineItem > -1) {
        this.arLineAttributes[numLineItem]['purchase_order']['account_code_id'] = strAccountCodeId;
        this.arLineAttributes[numLineItem]['purchase_order']['account_code'] = strAccountCode;
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectDepartment(event, attr) {
    let strDepartmentCodeId = (event == undefined || event == null) ? null : event['id'];
    let strDepartmentName = (event == undefined || event == null) ? null : event['department_name'];

    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));

    // If the item is found.
    if (numLineItem > -1) {
      // Set the values of the line item.
      this.arLineAttributes[numLineItem]['purchase_order']['department_id'] = strDepartmentCodeId;
      this.arLineAttributes[numLineItem]['purchase_order']['department_name'] = strDepartmentName;
    }
    this.markAsDirty();
  }

  /**
   * When selecting a product from the dropdown of products/labor.
   * @param event - the productt/labor object.
   * @param attr - the line item object where we will put the product/labor object into.
   */
  selectProduct(event, attr) {
    // Find the index of the line item that was selected.
    let numLineItem = this.arLineAttributes.findIndex(line => (line.type == attr.type));
    if (numLineItem > -1) {
      let arDefaultItemAccounting;
      if (this.strViewType === 'edit') {
        arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.purchaseOrderRecord['related_data'], 'default_item_accounting', []);
      } else {
        arDefaultItemAccounting = this.arrService.keyFallsBackTo(this.objModuleConfig['related_data'], 'default_item_accounting', []);
      }

      let strDefaultItemAccountingId: string = (arDefaultItemAccounting) ? arDefaultItemAccounting['accounting_id'] : '';

      if (event != undefined && event != '' && event != null) {
        event['unit_cost'] = (event.unit_cost != undefined && event.unit_cost != '') ? parseFloat(event.unit_cost) : 0;
        this.setProduct(event, attr, numLineItem, strDefaultItemAccountingId);
      } else {
        this.arLineAttributes[numLineItem]['purchase_order']['item_id'] = '';
        this.arLineAttributes[numLineItem]['purchase_order']['item_code'] = '';
        // If we clear the product. We need to set the default product for accounting sync from client config to item accounting id
        this.arLineAttributes[numLineItem]['purchase_order']['item_accounting_id'] = strDefaultItemAccountingId;
      }
    }
    this.markAsDirty();
  }

  setProduct(event, attr, numLineItem, strDefaultItemAccountingId) {
      // Set the values of the line item.
      let total_cost: number = (parseFloat(this.arLineAttributes[numLineItem]['purchase_order']['quantity']) * event['unit_cost']);

      let arTaxCode: [] | {} = [];
      let arAccountCode: [] | {} = [];

      event['description'] = (event['description'] != "" && event['description'] != undefined) ? event['description'] : "";

      if (event['default_purchase_tax_code_id'] != null && event['default_purchase_tax_code_id'] != '') {
        arTaxCode = {
          'id' : event['tax_code_id'],
          'rate' : event['tax_rate'],
          'code' : event['tax_code'],
          'name' : event['tax_code_name'],
          'text' : event['tax_code_name']
        }
      } else {
        if (this.strViewType == 'edit') {
          if (this.purchaseOrderRecord['related_data'] != undefined && this.purchaseOrderRecord['related_data']['default_tax_code_purchase'] != undefined) {
            arTaxCode = this.purchaseOrderRecord['related_data']['default_tax_code_purchase'];
          }
        } else {
          if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_tax_code_purchase'] != undefined) {
            arTaxCode = this.objModuleConfig['related_data']['default_tax_code_purchase'];
          }
        }
      }

      if (event['default_purchase_account_code_id'] != null && event['default_purchase_account_code_id'] != '') {
        arAccountCode = {
          'id' : event['account_code_id'],
          'code' : event['account_code'],
          'name' : event['account_code_name']
        }
      } else {
        if (this.strViewType == 'edit') {
          if (this.purchaseOrderRecord['related_data'] != undefined && this.purchaseOrderRecord['related_data']['default_account_code_purchase'] != undefined) {
            arAccountCode = this.purchaseOrderRecord['related_data']['default_account_code_purchase'];
          }
        } else {
          if (this.objModuleConfig['related_data'] != undefined && this.objModuleConfig['related_data']['default_account_code_purchase'] != undefined) {
            arAccountCode = this.objModuleConfig['related_data']['default_account_code_purchase'];
          }
        }
      }

      this.arLineAttributes[numLineItem]['amount'] = toFormattedNumber(total_cost, {
        currency: true,
      });
      this.arLineAttributes[numLineItem]['purchase_order']['item_id'] = event['id'];
      this.arLineAttributes[numLineItem]['purchase_order']['item_code'] = event['code'];
      this.arLineAttributes[numLineItem]['purchase_order']['item_name'] = event['name'];
      this.arLineAttributes[numLineItem]['purchase_order']['item_accounting_id'] = (event['accounting_id'] !== null && event['accounting_id'] !== '') ? event['accounting_id'] : strDefaultItemAccountingId;
      this.arLineAttributes[numLineItem]['purchase_order']['unit_cost'] = event['unit_cost'];
      this.arLineAttributes[numLineItem]['purchase_order']['description'] = event['description'];

      // Tax code relate data
      let strTaxRate = (arTaxCode && arTaxCode['rate'] != undefined && arTaxCode['rate'] != null && arTaxCode['rate'] != '') ? parseFloat(arTaxCode['rate']) * 0.01 : 0;
      this.arLineAttributes[numLineItem]['purchase_order']['tax_code_id'] = (arTaxCode && arTaxCode['id'] != undefined && arTaxCode['id'] != null) ? arTaxCode['id'] : '';
      this.arLineAttributes[numLineItem]['purchase_order']['tax_code'] = (arTaxCode && arTaxCode['code'] != undefined && arTaxCode['code'] != null) ? arTaxCode['code'] : '';
      this.arLineAttributes[numLineItem]['tax'] = (arTaxCode && arTaxCode['id'] != undefined) ? arTaxCode : null;
      this.arLineAttributes[numLineItem]['tax_rate'] = strTaxRate;
      // Account code relate data
      this.arLineAttributes[numLineItem]['purchase_order']['account_code_id'] = (arAccountCode && arAccountCode['id'] != undefined && arAccountCode['id'] != null) ? arAccountCode['id'] : '';
      this.arLineAttributes[numLineItem]['purchase_order']['account_code'] = (arAccountCode && arAccountCode['code'] != undefined && arAccountCode['code'] != null) ? arAccountCode['code'] : '';
      this.arLineAttributes[numLineItem]['account_code'] = (arAccountCode && arAccountCode['id'] != undefined) ? arAccountCode : null;
      this.setAmountDue();
      this.markAsDirty();
    }

  /**
   * Compute Total Invoice Line Tax
   */
  computeInvoiceLineTax() {
    let computedTax = 0;

    this.arLineAttributes.forEach( data => {
      let amount = (data.amount != undefined && data.amount != '' && data.amount != null && data.amount != 'NaN') ? data.amount : 0;
      let tax_rate = (data.tax_rate != undefined && data.tax_rate != '' && data.tax_rate != null && data.amount != 'NaN') ? data.tax_rate : 0;
      computedTax += parseFloat(amount) * parseFloat(tax_rate);
    });

    const formatted = toFormattedNumber(computedTax, {
      currency: true,
    });

    return formatted;
  }

  /**
   * Compute Total Excluded Tax (Invoice Line Items Layout)
   */
  computeInvoiceLineExcludedTax() {
    let computedExcludedTax = 0;

    this.arLineAttributes.forEach( data => {
      let amount = (data.amount != undefined && data.amount != '' && data.amount != null && data.amount != 'NaN') ? data.amount : 0;
      computedExcludedTax += parseFloat(amount);
    });

    const formatted = toFormattedNumber(computedExcludedTax, {
      currency: true,
    });

    if (_.isNil(formatted)) {
      return 0;
    }

    return formatted;
  }

  /**
   * Compute Total Included Tax (Invoice Line Items Layout)
   */
  computeInvoiceLineIncludedTax() {
    let computedIncludedTax: number = this.computeInvoiceLineExcludedTax() + this.computeInvoiceLineTax();
    const formatted = toFormattedNumber(computedIncludedTax + _.toNumber(this.totalTaxAdjustment), {
      currency: true,
    });

    if (_.isNil(formatted)) {
      return 0;
    }

    return formatted;
  }

  /**
   * Get index of form
   * @param strField
   */
  getFormFieldIndex(strField) {
    return this.purchaseOrderForm.findIndex(attr => (attr.groups.get(strField) != undefined && attr.groups.get(strField) != null));
  }

  /**
   * Get index of field
   * @param strField
   * @param form
   */
  getFieldIndex(strField, form) {
    if (form != undefined && form['fields'] != undefined) {
      return form['fields'].findIndex( attr => (attr['key'] == strField));
    } else {
      return -1;
    }
  }

  /**
   * Set value of field
   * @param strField
   * @param strValue
   * @param options
   */
  fieldPatchValue(strField, strValue, options = {
    emitEvent: false,
  }) {
    let indexOfFormField = this.getFormFieldIndex(strField);
    if (indexOfFormField > -1) {
      this.purchaseOrderForm[indexOfFormField]['groups'].patchValue({
        [strField] : strValue,
      }, {emitEvent: options.emitEvent, onlySelf: true});
    }
  }

  /**
   * Validate Request
   * @param objRequest
   */
  validateRequest(objRequest, bLineItem = true) {
    for (let key in objRequest) {
      if (this.arRequiredField.indexOf(key) > -1 && key != 'line_items') {
        if((objRequest[key] == undefined || objRequest[key] == '' || objRequest[key] == null || objRequest[key].length < 1)) {
          let indexOfFormField = this.getFormFieldIndex(key);
          if (indexOfFormField > -1) {
            this.purchaseOrderForm[indexOfFormField]['groups']['controls'][key].markAsTouched();
            this.purchaseOrderForm[indexOfFormField]['groups']['controls'][key].setErrors({'incorrect' : true});
          }
          this.hasError = true;
        }
      }
      switch(key) {
        case 'tax_code_id':
        case 'account_code_id':
        case 'description':
          if(objRequest[key] == '' || objRequest[key] == null || objRequest[key] == undefined) {
            this.hasError = true;
          }
        break;
        case 'department_id':
          if (
            this.bDepartmentTracking &&
            (objRequest[key] == '' || objRequest[key] == null || objRequest[key] == undefined) &&
            bLineItem
          ) {
            this.hasError = true;
          }
        break;
      }
    }
  }

  /**
   * Validate Field
   * @param strValue
   */
  validateField(strValue) {
    if (strValue == '' || strValue == null || strValue == undefined) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get multiple relate record with joined
   * @param strModule - Multiple modules separated by |
   * @param strFilter - Filter by module
   */
  initMultipleRelateData(strModule, strFilter: any = []) {
    /**
     * Get multiple module relate record
     * @param strModule - request module to get config
     * @param strRecordIds - request record id of main table
     * @param arFilter - request filter
     * @param arOrderClause - request order clause   Format: ['id' => 'column', 'sort' => 'desc']
     * @param intLimit - request limit
     * @param arWhereIn - request to find in array
     * @param arWhereNotIn - request to find in array
     */


    this.recordService.getMultipleModuleRelateRecord(strModule, false, strFilter, false, 10).subscribe( res => {
      this.initialRelateTaxCodeData = res['tax_codes'];
      this.initialRelateItemData = res['items'];
      this.initialRelateAccountCodeData = res['account_codes'];
      this.initialRelateDepartmentData = res['departments'];
      this.setLineItems();
    });
  }

  /**
   * set line items
   */
  setLineItems() {
    if (this.strViewType == 'edit' && this.data.purchase_order != undefined) {
      if (this.data.purchase_order.line_items != undefined && this.data.purchase_order.line_items != null && this.data.purchase_order.line_items.length > 0) {
        this.data.purchase_order.line_items.map( lineItem => {
          // FC-4358: Use the item name as description if it is empty
          if (_.isEmpty(lineItem.description)&& !_.isEmpty(lineItem.item_name)) {
            lineItem.description = lineItem.item_name;
          }
          return lineItem;
        }).forEach( data => {
          this.addLineAttribute(data);
        });
      } else {
        this.bInvoiceLineLoaded = true;
      }

      this.getRelatedProducts();

    } else {
      this.bInvoiceLineLoaded = true;
      if (this.objJobData && this.objJobData['work_order_items']) {
        this.objJobData['work_order_items'].map( objWorkOrderItems => {
          // FC-4137: Make sure that we have null line item id when creating purchase order
          objWorkOrderItems.id = null;
          // FC-4358: Use the item name as description if it is empty
          if (_.isEmpty(objWorkOrderItems.description) && !_.isEmpty(objWorkOrderItems.item_name)) {
            objWorkOrderItems.description = objWorkOrderItems.item_name
          }
          this.addLineAttribute(objWorkOrderItems);
        });

        this.getRelatedProducts();
      } else {
        this.addLineAttribute();
      }
    }
  }

  /**
   * Initialize relate fields
   * @param strAttributeName - object attribute
   * @param strModule - module for relate
   * @param strObservableName - observable that is set
   * @param objMainFilter - Main filter for record (onChange of the fields)
   * @param arRelateInitialValue - Initial relate value
   */
  initRelateRecords(strAttributeName, strModule, strObservableName, objMainFilter, arRelateInitialValue, curentIndex: number | string = '') {
    // Get the lastest index.
    let newIndex = (curentIndex === '') ? this[strAttributeName].length - 1 : curentIndex;
    let strObservable = strObservableName + '_obv';
    let strTypehead = strObservableName + '_typehead';
    let strLoader = strObservableName + '_loader';

    if (strModule == 'items') {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term => this.recordService.getProductRecordData(term, objMainFilter, null, false).pipe(
            tap(() => {
              this[strAttributeName][newIndex][strLoader] = false;
            })
          ))
        )
      )
    } else if(['tax_codes', 'account_codes', 'departments'].includes(strModule)) {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term =>
            this.recordService.getFilterData(term, arRelateInitialValue).pipe(
              tap(() => {
                this[strAttributeName][newIndex][strLoader] = false;
              })
            )
          )
        )
      );
    } else {
      // Set a default dropdown value for this line item.
      this[strAttributeName][newIndex][strObservable] = concat(
        of(arRelateInitialValue),
        this[strAttributeName][newIndex][strTypehead].pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this[strAttributeName][newIndex][strLoader] = true),
          switchMap(term => this.recordService.getRecordRelate(strModule, term, '', false, objMainFilter).pipe(
            tap(() => {
              this[strAttributeName][newIndex][strLoader] = false;
            })
          ))
        )
      )
    }
  }

  /**
   * Initialize relate field
   * @param {string} strModule - module to get related data
   * @param strAttributeName  - attribute name i.e. arTimeEntryAttributes
   * @param strObservableName - Observable name i.e department
   * @param strIndex - Index of attribute
   * @param objFilter - Desired Filter
   */
  initRelateData(strModule, strAttributeName, strObservableName, strIndex, objFilter: any = false) {
    let strLoader = strObservableName + '_loader';
    if (strAttributeName != undefined && strAttributeName != '') this[strAttributeName][strIndex][strLoader] = true;
    if (strModule == 'items') {
      this.recordService.getProductRecordData('', objFilter, null, false).subscribe(result => {
        if (strAttributeName != undefined && strAttributeName != '') {
          this.initRelateRecords(strAttributeName, strModule, strObservableName, objFilter, result, strIndex);
          this[strAttributeName][strIndex][strLoader] = false;
        }
      });
    } else {
      this.recordService.getRecordRelate(strModule, '', false, false, objFilter).subscribe( result => {
        if (strAttributeName != undefined && strAttributeName != '') {
          this.initRelateRecords(strAttributeName, strModule, strObservableName, objFilter, result, strIndex);
          this[strAttributeName][strIndex][strLoader] = false;
        }
      });
    }
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    if(this.checkFormGroupDirty()) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Trigger to openPreview
   *
   * @returns {void}
   */
  previewPDF(): void {
    this.pdfPreview = true;
    this.savePurchaseOrder();
  }

  /**
   * Save PurchaseOrder
   */
  savePurchaseOrder() {
    // Get the raw value of the form.
    this.hasError = false;
    this.bSubmitted = true;
    this.isTouched = true;
    let objPurchaseOrder = this.getDataToSave();
    this.arReadOnlyId = [];
    this.arRelateEmptyId = [];
    this.arRequiredField = [];

    if (!this.hasError) {
      if (this.pdfPreview == true) {

        this.openPreview(objPurchaseOrder);
      } else if (this.strViewType == 'edit' && this.data['purchase_order'] != undefined) {
        // Initialize multiple relate data
        let strModule = 'supplier_invoices';
        let strFilter = {
          'supplier_invoices': { purchase_order_id: this.data['purchase_order']['id']}
        }

        this.whenInProgress$.next(true);

        /**
         * Get multiple module relate record
         * @param strModule - request module to get config
         * @param strRecordIds - request record id of main table
         * @param arFilter - request filter
         * @param arOrderClause - request order clause   Format: ['id' => 'column', 'sort' => 'desc']
         * @param intLimit - request limit
         * @param arWhereIn - request to find in array
         * @param arWhereNotIn - request to find in array
         */
        this.recordService.getMultipleModuleRelateRecord(strModule, false, strFilter, false, false).subscribe( res => {
          let arSupplierInvoiceData = (res['supplier_invoices'] != undefined && res['supplier_invoices'].length > 0) ? res['supplier_invoices'] : [];
          let totalAccumulatedSupplierInvoice = 0;
          let totalPurchaseOrderAmount = 0;
          let totalRemainingAmount = 0;
          if (objPurchaseOrder['line_items'].length > 0) {
            objPurchaseOrder['line_items'].forEach( data => {
              totalPurchaseOrderAmount += parseFloat(data['total_price']);
            });
          }
          if (arSupplierInvoiceData) {
            arSupplierInvoiceData.forEach( data => {
                totalAccumulatedSupplierInvoice += parseFloat(data['amount']);
            });
          }

          totalRemainingAmount = totalPurchaseOrderAmount - totalAccumulatedSupplierInvoice;
          // Notify user if total remaining amount fully consumed
          if (totalRemainingAmount < 0) {
            let strExceedMessage = this.customTranslate.getTranslation('exceed_supplier_invoice_amount');
            let strTotalPurchaseOrderMessage = this.customTranslate.getTranslation('total_purchase_order_amount');
            let strTotalSupplierInvoiceMessage = this.customTranslate.getTranslation('total_supplier_invoice');
            let strTotalRemainingAmountMessage = this.customTranslate.getTranslation('total_remaining_amount');
            let strConfirmationMessage = strExceedMessage + strTotalPurchaseOrderMessage + toFormattedNumber(totalPurchaseOrderAmount, { currency: true }) + " " + strTotalSupplierInvoiceMessage + toFormattedNumber(totalAccumulatedSupplierInvoice, { currency: true }) + " " + strTotalRemainingAmountMessage + toFormattedNumber(totalRemainingAmount, {
              currency: true,
            });
            //We tell the app component to open the confirmation.
            this.notifService.sendConfirmation(strConfirmationMessage).subscribe(confirmation => {
              // If the user confirm
              if (confirmation.answer) {
                // Save purchase order
                this.savePurchaseOrderData(objPurchaseOrder, _.get(this.data, 'purchase_order.id')).pipe(
                  finalize(() => {
                    this.whenInProgress$.next(false);
                    this.bSubmitted = false;
                    this.isPreviewTemplate = false;
                  })
                ).subscribe(res => {

                  if (res.status == 200) {
                    this.displayTemplate(this.data['purchase_order_id'])
                    this.dialogRef.close(res.body);
                  } else if (res.status == 202) {
                    this.notifService.promptError(res.body.error);
                  } else {
                    this.dialogRef.close('fail');
                  }

                });
              } else {
                this.whenInProgress$.next(false);
                this.bSubmitted = false;
                this.isPreviewTemplate = false;
              }
            });

          } else {
            // Save customer invoice
            this.savePurchaseOrderData(objPurchaseOrder, _.get(this.data, 'purchase_order.id')).pipe(
              finalize(() => {
                this.whenInProgress$.next(false);
                this.bSubmitted = false;
                this.isPreviewTemplate = false;
              }),
            ).subscribe( res => {

              if (res.status == 200) {
                this.dialogRef.close(res.body);
                this.displayTemplate(this.data['purchase_order_id'])
              } else if (res.status == 202 && res.body.error !== undefined) {
                this.notifService.promptError(res.body.error);
              } else {
                  this.dialogRef.close('fail');
              }

            });
          }
        });
      } else {
          // Save purchase order
          this.savePurchaseOrderData(objPurchaseOrder, _.get(this.data, 'purchase_order.id')).pipe(
            finalize(() => {
              this.whenInProgress$.next(false);
              this.bSubmitted = false;
              this.isPreviewTemplate = false;
            }),
          ).subscribe( res => {

            if (res.status === 202) {
              if (res.body.error !== undefined) {
                this.notifService.promptError(res.body.error);
              } else {
                this.notifService.sendNotification('warning', 'invalid_next_po_number', 'warning', 6000);
              }
            } else if (res.body.id != undefined) {
              this.dialogRef.close(res.body);
              this.displayTemplate(res.body.id)
            }
          });
      }
    } else {
      this.notifService.sendNotification('not_allowed', 'required_notice', 'danger');
      this.bSubmitted = false;
      this.pdfPreview = false;
      this.isPreviewTemplate = false;
    }
  }

  /**
   * Compare the old data to the requested data
   * @param objPurchaseOrder - Requested data
   * @param relateData = Related Data
   */
  compareOldData(objPurchaseOrder) {
    let objPurchaseOrderFields = Object.keys(objPurchaseOrder);
    objPurchaseOrderFields.forEach( strField => {
      if (strField != 'line_items') {
        let strOriginalDate = '';

        if (strField == 'invoice_date' || strField == 'invoice_due' || strField == 'po_date' || strField == 'estimated_delivery_date') {
          strOriginalDate = objPurchaseOrder[strField];
          this.data.purchase_order[strField] = this.formatDate(this.data.purchase_order[strField]);
          objPurchaseOrder[strField] = this.formatDate(objPurchaseOrder[strField]);
        }
        // Due to in objSupplierInvoice relate id set as null if its empty. We set the old data also as null
        if (this.arRelateEmptyId.indexOf(strField) > -1) {
          if (this.data.purchase_order[strField] == '') {
            this.data.purchase_order[strField] = null;
          }
        }

        if ((strField == 'job_id' || strField == 'delivery_location') && this.data.purchase_order[strField] == '') {
          this.data.purchase_order[strField] = null;
        }

        if (this.data.purchase_order[strField] != objPurchaseOrder[strField]) {
          this.bHasChanged = true;
        }

        if (strField == 'invoice_date' || strField == 'invoice_due' || strField == 'po_date' || strField == 'estimated_delivery_date') {
          objPurchaseOrder[strField] = strOriginalDate;
        }
      } else {
        objPurchaseOrder[strField].forEach( (data, index) => {
          let objLineItemsField = Object.keys(data);
          objLineItemsField.forEach( strLineItemField => {
            // If data purchase order with index given is undefined. Set has changed to true.
            if (this.data.purchase_order[strField][index] == undefined) {
              this.bHasChanged = true;
            } else {
              if (this.data.purchase_order[strField][index][strLineItemField] != data[strLineItemField]) {
                this.bHasChanged = true;
              }
            }
          });
        });
      }
    });
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate) {

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('ll');
  }

  /**
   * Get the default tax or account code from the local storage setting.
   * @param strKey
   */
  getDefaultCodes(strKey) {
    let objCOnfig = this.clientStoreService.getActiveClient();


    let strResult: string | boolean = false;

    if (objCOnfig['default_tax_code_purchase'] != null && strKey == 'tax') {
      strResult = objCOnfig['default_tax_code_purchase'];
    }

    if (objCOnfig['default_account_code_purchase'] != null && strKey == 'account') {
      strResult = objCOnfig['default_account_code_purchase'];
    }
    return strResult;
  }

  getDefaultCodeNames(strKey, strId) {

    let strResult: string | boolean = false;

    if (strKey == 'tax') {
      let numIndex = this.initialRelateTaxCodeData.findIndex(element => (element['id'] == strId));

      if (numIndex > -1) {
        strResult = this.initialRelateTaxCodeData[numIndex]['name'];
        return strResult;
      }
    }

    if (strKey == 'account') {
      let numIndex = this.initialRelateAccountCodeData.findIndex(element => (element['id'] == strId));

      if (numIndex > -1) {
        strResult = this.initialRelateAccountCodeData[numIndex]['name'];
        return strResult;
      }
    }

    return strResult;
  }

  /**
   * set amount due with computation of included tax
   */
  setAmountDue() {
    let indexOfAmountDue = this.getFormFieldIndex('amount_due');
    let indexOfAmountPaid = this.getFormFieldIndex('amount_paid');
    let strAmountPaid: number = 0;

    if (indexOfAmountPaid > -1) {
      let strAmountPaidValue = this.purchaseOrderForm[indexOfAmountDue]['groups'].controls.amount_paid.value;
      strAmountPaid = this.is.toFloat(strAmountPaidValue);
    }

    if (indexOfAmountDue > -1) {
      this.purchaseOrderForm[indexOfAmountDue]['groups'].patchValue({
        amount_due: this.computeInvoiceLineIncludedTax() - strAmountPaid
      });
    }
  }

  /**
   * Get and store the relate text on change
   *
   * @param {object} objField
   * @param {string} strValue
   *
   * @returns {void}
   */
  getRelateText(objField, strValue): void {
    if (objField.controlType == 'relate') {

      this.objRelateText[objField.key.replace('id', 'text')] = strValue;
    }
  }

  /**
   * Open pdf preview
   * Request for pdf record data using the selected id.
   *
   * @param objModuleRecord
   *
   * @returns {void}
   */
  openPreview(objRecord): void {

    objRecord = _.cloneDeep(_.merge(this.objRelateText, objRecord));
    let objCompiledRecord = (this.data['purchase_order']) ? _.cloneDeep(_.merge(this.data['purchase_order'], objRecord)) : objRecord;
    let strLabel = (objCompiledRecord.po_number && this.data['view_type'] == 'edit') ? objCompiledRecord.customer_text+ ' - ' +objCompiledRecord.po_number : 'preview_' +moment().format('YYYY_MM_DD_HHMMSS');

    objCompiledRecord['amount'] = this.computeInvoiceLineExcludedTax();
    objCompiledRecord['tax'] = this.computeInvoiceLineTax();
    objCompiledRecord['amount_inc_tax'] = this.computeInvoiceLineIncludedTax();

    let dialogConfig : {[k: string]: any} = {
      data: {
        module: 'purchase_orders',
        label: strLabel,
        response: {
          file_name: strLabel,
          module: 'purchase_orders',
          record: objCompiledRecord
        }
      },
      disableClose:true
    };

    // If mobile
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = '80%';
    }

    this.pdfPreview = false;
    this.bSubmitted = false;
    this.dialog.open(PdfComponent, dialogConfig);
  }

  /**
   * This is to increase the row
   * when textarea is focus
   */
  increaseRow(i) {
    let currentElem = document.querySelector('.po-task-desc-'+ i);
    currentElem.setAttribute("style", "height: 100px");
  }

  /**
   * This is to decrease the row
   * when textarea is focus out
   */
  decreaseRow(i) {
    let currentElem = document.querySelector('.po-task-desc-'+ i);
    currentElem.setAttribute("style", "height: 40px");
  }

  /**
   * Get notes of selected supplier.
   *
   * @param arSupplierInfo
   *
   * @returns {string | null}
   */
  getNotesOfSupplier(arSupplierInfo = [], objData: LooseObject): string | null{

    if (_.isArray(arSupplierInfo)
      && filled(arSupplierInfo)
      && this.supplierId) {
      let objSupplier = arSupplierInfo.find(item => {
        return item['customer_id'] == this.supplierId
      });

      if (objSupplier) {
        return objSupplier['notes'];
      }
    }

    if (objData['supplier_pricing_note']) {
      return objData['supplier_pricing_note'];
    }

    return null;
  }

  /**
   * Check if the form group is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    let bDirty = false;
    this.purchaseOrderForm.forEach( objForm => {
      var formGroup = objForm.groups;
      if (formGroup.dirty === true) {
        bDirty = true;
      }
    });
    return bDirty || this.bFormDirty;
  }

  /**
   * Mark as dirty
   *
   * @returns {void}
   */
  markAsDirty(): void {
    this.bFormDirty = true;
  }

  /**
   * triggers the save and preview
   */
  onSubmitAndPreview(): void {
    this.isPreviewTemplate = true;
    if (!this.bSubmitted) {
        this.savePurchaseOrder();
    }
  }

  /**
   * trigger event to disaplay template
   *
   * @param id
   */
  displayTemplate(id: string): void {
    if (this.isPreviewTemplate && id) {
      this.previewTemplate.next({
        id: id,
        module: 'purchase_orders',
        document_type: 'purchase_order_report'
      });
    }
    this.isPreviewTemplate = false;
  }

  /**
   * Save purchase order data
   *
   * @param {LooseObject} objData
   * @param {string} strRecordId
   *
   * @see {RecordService['saveRecord']}
   */
  protected savePurchaseOrderData(objData: LooseObject, strRecordId: string = '') {
    return this.recordService.saveRecord('purchase_orders', objData, strRecordId).pipe(
      finalize(() => {
        this.bSubmitted = false;
        this.isPreviewTemplate = false;
      })
    );
  }

  /**
   * Add the related product to the list of line items.
   *
   * @param {LooseObject} objRecord
   */
  public addRelated(objRecord: LooseObject) {

    this.dialog.open(RelatedProductsComponent, {
      width: '70%',
      data: objRecord,
    }).afterClosed().subscribe(response => {
      if (response && response.length > 0) {

        let strIds: string[] = [];

        response.forEach(item => {
          strIds.push(item['child_item_id'])
        });

        this.recordService.getProductRecordData(null, null, null, false, null, strIds).subscribe(response => {
          if (response && response.length > 0) {
            response.forEach(item => {
              this.addLineAttribute(item, true);
            });
          }
        });
      }
    });

  }

  /**
   * Get the related products and set the properties to
   * line items.
   */
  public getRelatedProducts() {

    let arItemIds: string[] = [];
    let arTaxCodeIds: string[] = [];

    this.arLineAttributes.forEach(item => {
      if (item['item']) {
        arItemIds.push(item['item']['id']);
      }
      if (item['tax']) {
        arTaxCodeIds.push(item['tax']['id']);
      }
    });

    if (this.strViewType === 'add') {
      forkJoin([
        this.recordService.getRecordRelateJoined('tax_codes', false, false, false, false, {id: arTaxCodeIds}),
        this.recordService.getProductRecordData(null, null, null, false, null, arItemIds)
      ]) .subscribe(([taxCodeResponse, itemResponse]) => {
        this.updateLineItemTaxCode(taxCodeResponse);
        this.updateLineItemDefault(itemResponse);
      });
    } else {
      this.recordService.getProductRecordData(null, null, null, false, null, arItemIds).subscribe(response => {
        this.updateLineItemDefault(response);
      });
    }
  }

  /**
   * update the tax code to null if the selected tax code has is_purchase false value
   *
   * @param arTaxCode
   */
  public updateLineItemTaxCode(arTaxCode: LooseObject[] = []): void {
    if (arTaxCode && arTaxCode.length > 0) {
      arTaxCode.forEach( taxCode => {
        this.arLineAttributes.forEach((line, index) => {
          if (line['tax'] && line['tax']['id'] == taxCode['id']) {
            if (taxCode['is_purchase'] == false) {
              this.arLineAttributes[index]['tax'] = null;
              this.arLineAttributes[index]['purchase_order']['tax_code'] = null;
              this.arLineAttributes[index]['purchase_order']['tax_code_id'] = null;
            }
          }
        });
      });
    }
  }

  /**
   * when tax code and account code is empty, use the item's purchase default tax_code and account_code
   *
   * @param arItem
   */
  public updateLineItemDefault(arItem: LooseObject[] = []): void {
    if (arItem && arItem.length > 0) {
      this.arLineAttributes.forEach((line, index) => {
        let objItem = arItem.find( data => data.id === line['item']['id']);
        if (objItem) {
          this.storeItemDetails(objItem);
          this.arLineAttributes[index]['supplier_info'] = _.isArray(objItem['supplier_pricing']) && filled(objItem['supplier_pricing']) ? objItem['supplier_pricing'] : [];
          this.arLineAttributes[index]['related_products'] = objItem['related_products'];
          // when account code is empty, use the items default purchase account code
          if(this.strViewType === 'add' || _.isNil(this.arLineAttributes[index]['account_code']) || _.isNil(this.arLineAttributes[index]['account_code']['id'])) {
            if (objItem['account_code_id'] && objItem['account_code_name']) {
              this.arLineAttributes[index]['account_code'] = {
                id: objItem['account_code_id'],
                name: objItem['account_code_name'],
              };
              this.arLineAttributes[index]['purchase_order']['account_code'] = objItem['account_code_name'];
              this.arLineAttributes[index]['purchase_order']['account_code_id'] = objItem['account_code_id'];
            } else {
              // if account code is empty and there is no default account code for item
              // use the client's default account code
              this.arLineAttributes[index]['account_code'] = this.objDefaultAccountCode;
              this.arLineAttributes[index]['purchase_order']['account_code'] = this.objDefaultAccountCode['code'];
              this.arLineAttributes[index]['purchase_order']['account_code_id'] = this.objDefaultAccountCode['id'];
            }
          }
          // when tax code is empty, use the items default tax account code
          if(_.isNil(this.arLineAttributes[index]['tax']) || _.isNil(this.arLineAttributes[index]['tax']['id'])) {
            if (objItem['tax_code_id'] && objItem['tax_code_name']) {
              this.arLineAttributes[index]['tax'] = {
                id: objItem['tax_code_id'],
                text: objItem['tax_code_name'],
              };
              this.arLineAttributes[index]['purchase_order']['tax_code'] = objItem['tax_code_name'];
              this.arLineAttributes[index]['purchase_order']['tax_code_id'] = objItem['tax_code_id'];
            } else {
              // if tax code is empty and there is no default tax code for item
              // use the client's default tax code
              this.arLineAttributes[index]['tax'] = this.objDefaultTaxCode;
              this.arLineAttributes[index]['purchase_order']['tax_code'] = this.objDefaultTaxCode['code'];
              this.arLineAttributes[index]['purchase_order']['tax_code_id'] = this.objDefaultTaxCode['id'];
            }
          }
        } else if (_.isEmpty(this.arLineAttributes[index]['tax'])) {
          // if tax_code is empty use the default client's tax code
          this.arLineAttributes[index]['tax'] = this.objDefaultTaxCode;
          this.arLineAttributes[index]['purchase_order']['tax_code'] = this.objDefaultTaxCode['code'];
          this.arLineAttributes[index]['purchase_order']['tax_code_id'] = this.objDefaultTaxCode['id'];
        }
      });
    }
  }

  /**
   * select a related job of current line item
   * @param event - the job object.
   * @param attr - the line item object where we will put the job object into.
   */
  selectJob(event, attr, i) {
    this.arLineAttributes[i]['purchase_order']['job_id'] = _.isUndefined(event) ? '' : event['id'];
  }

  /**
   * initialize job relate field
   */
  buildJobRelateField(defaultJob: Array<LooseObject>): Relate<any> {
    let jobRelate = new Relate<any>();

    return jobRelate.buildRelates(
      switchMap(term =>
        this.recordService.getRecordRelate(
          'jobs',
          term,
          '',
        ).pipe(
          map(result => {
            return result;
          })
        )
      ),
      defaultJob
    );
  }

  /**
   * trigger typehead manually
   */
  triggerSubject(objTypehead: Subject<string>, strValue: string = ''): void {
    objTypehead.next(strValue);
  }

  onLineTotalChange(input: HTMLInputElement, opts: {
    attr: Record<string, any>,
    index: number,
  }): void {
    if (this.arLineAttributes.length < opts.index) {
      return;
    }

    const adjustment = toFormattedNumber(input.value, {
      currency: true,
    });

    const quantity = toFormattedNumber(_.get(opts.attr, 'purchase_order.quantity'));
    const cost = adjustment / quantity;

    this.arLineAttributes[opts.index]['purchase_order']['unit_cost'] = toFormattedNumber(cost, {
      currency: true,
      maxDecimalPlaces: 4,
    });

    this.setAmountDue();
    this.markAsDirty();
  }

  onTaxAdjustment(): void {
    this.setAmountDue();
    this.markAsDirty();
  }

  getDataToSave(isPreview: boolean = false) {
    let objPurchaseOrder = {};
    let strRecordModuleId = RelateIds[this.strRecordModule];
    this.purchaseOrderForm.map(
      form => {
        Object.keys(form['groups'].value).forEach(
          item => {
            let strFieldMetadata = form['fields'].find( x => x.key == item);
            if (strFieldMetadata != undefined) {
              if (strFieldMetadata.readonly) this.arReadOnlyId.push(item);
              if (strFieldMetadata.controlType == 'relate') this.arRelateEmptyId.push(item);
              if (strFieldMetadata.required) this.arRequiredField.push(item);
            }
        })
    });

    //Loops the values.
    this.purchaseOrderForm.forEach(
      item => {
        //Merge all form group values into a single object.
        objPurchaseOrder = {...objPurchaseOrder, ...item['groups'].getRawValue()};
    });

    // Remove readonly fields
    this.arReadOnlyId.forEach( strId => {
      if (strId != 'accounting_sync_detail') {
        delete objPurchaseOrder[strId];
      }
    });

    objPurchaseOrder['tax_adjustment_amount'] = this.totalTaxAdjustment;

    let lineItems = [];

    this.validateRequest(objPurchaseOrder, false);

    // Validate each line itmes of time entry
    this.arLineAttributes.forEach( data => {
      data.purchase_order.total_price = data.amount;
      data.purchase_order.quantity = data.purchase_order.quantity;
      if (data['item'] && data['item'].text) {
        data.purchase_order.item = data['item'].text;
        data.purchase_order.item_name = data['item'].text;
      }
      this.validateRequest(data.purchase_order);
      lineItems.push(data.purchase_order);
    });

    // If module exist set record id of current module
    if (this.strRecordModule != undefined && this.strRecordModule != '') {
      if(this.strRecordId != null && this.strRecordId != undefined && this.strRecordId != '') {
        objPurchaseOrder[strRecordModuleId] = this.strRecordId;
      } else {
        objPurchaseOrder[strRecordModuleId] = null;
      }
    }

    if (lineItems.length > 0) {
      objPurchaseOrder['line_items'] = lineItems;
    } else {
      if (!isPreview) {
        this.bSubmitted = false;
        this.pdfPreview = false;
        this.isPreviewTemplate = false;
        this.notifService.sendNotification('not_allowed', 'invoice_line_items_required', 'warning');

        return false;
      }
    }

    if (objPurchaseOrder['contact_id'] == '') {
      objPurchaseOrder['contact_id'] = null;
    }
    if (objPurchaseOrder['delivery_location'] == '') {
      objPurchaseOrder['delivery_location'] = null;
    }
    // If delivery location is not a site. Set site_id as null
    if (objPurchaseOrder['delivery_location'] != 'site') {
      objPurchaseOrder['site_id'] = null;
    //If delivery location is not refer to delivery notes empty delivery notes
    } else if(objPurchaseOrder['delivery_location'] != 'refer_to_delivery_notes') {
      objPurchaseOrder['delivery_notes'] = '';
    } else if(objPurchaseOrder['delivery_location'] != 'main_warehouse') {
      objPurchaseOrder['warehouse_id'] = null;
    }

    this.arRelateEmptyId.forEach( strField => {
      if (objPurchaseOrder[strField] == '') {
        objPurchaseOrder[strField] = null;
      }
    });

    return objPurchaseOrder;
  }

  /**
   * for viewing the current record
   *
   * @returns void
   */
  onPreview(): void {
    let purchaseOrder = this.getDataToSave(true);
    let arModule = [];
    let objFilter = {};
    let arRelateFields = [
      { id: 'customer_id', module: 'customers', key: 'customer' },
      { id: 'site_id', module: 'sites', key: 'site' },
      { id: 'user_id', module: 'users', key: 'assigned_user' },
    ];

    arRelateFields.forEach( relateField => {
      if (purchaseOrder[relateField.id]) {
        arModule.push(relateField.module);
        objFilter[relateField.module] = {
          id: purchaseOrder[relateField.id]
        };
      }
    });

    this.whenInProgress$.next(true);

    this.recordService.getMultipleModuleRelateRecord(arModule.join('|'), false, objFilter).subscribe( response => {
      arRelateFields.forEach( relateField => {
        if (response[relateField.module] && response[relateField.module][0]) {
          purchaseOrder[relateField.key] = response[relateField.module][0];
          if (purchaseOrder[relateField.key]['address']) {
            purchaseOrder[relateField.key]['address'] =
              this.readableAddressPipe.transform(purchaseOrder[relateField.key]['address'])
          }
        } else {
          purchaseOrder[relateField.key] = {}
        }
      });

      purchaseOrder = this.setDropdownValues(purchaseOrder);
      purchaseOrder['amount'] = this.computeInvoiceLineExcludedTax();
      purchaseOrder['tax'] = this.computeInvoiceLineTax();
      purchaseOrder['amount_inc_tax'] = this.totalTaxAdjustment;

      this.realTimePreviewTemplate.next({
        id: this.data['purchase_order_id'] || null,
        module: 'purchase_orders',
        document_type: 'purchase_order_report',
        data: purchaseOrder
      });
    });
  }

  /**
   * update dropdown value and add field+ _raw for dropdown original value
   *
   * @param purchaseOrder
   * @returns
   */
  setDropdownValues(purchaseOrder): LooseObject {
    let arDropdownKeys = Object.keys(this.objModuleConfig['used_fields']).filter(
      strKey => this.objModuleConfig['used_fields'][strKey].type === 'dropdown'
    );
    arDropdownKeys.forEach( dropdownKeys => {
      if (purchaseOrder[dropdownKeys]) {
        let dropdownValue = _.cloneDeep(purchaseOrder[dropdownKeys]);

        purchaseOrder[dropdownKeys+ '_raw'] = dropdownValue;
        purchaseOrder[dropdownKeys] = this.translateService.instant(dropdownValue)
      }
    });

    return purchaseOrder;
  }

  /**
   * store the item record
   *
   * @param objItem
   */
  storeItemDetails(objItem: LooseObject ): void {
    this.objItemDetails[objItem.id] = objItem;
  }

  /**
   * recalculate the line item unit_cost based on supplier pricing
   *
   * @param objCustomer
   */
  recalculateLineItemBuyPrice(objCustomer): void {
    if (objCustomer) {

      this.arLineAttributes.forEach( lineItem => {
        if (lineItem.item && lineItem.item.id) {

          let objProduct = this.objItemDetails[lineItem.item.id];
          if (objProduct) {

            let supplierPricing = objProduct.supplier_pricing.find(
              supplierPricing => supplierPricing.customer_id == objCustomer.id
            );
            if (supplierPricing) {
              lineItem.purchase_order.unit_cost = supplierPricing.unit_cost;
            }
          }
        }
      })
    }
  }

  protected _attachListenerForDeliveryFields() {
    let indexOfWarehouseForm = this.getFormFieldIndex('warehouse_id');

    let indexOfDeliveryLocationForm = this.getFormFieldIndex('delivery_location');
    let indexOfDeliveryLocationField = this.getFieldIndex('delivery_location', this.purchaseOrderForm[indexOfDeliveryLocationForm]);
    let numWarehouseFormIndex = this.getFormFieldIndex('warehouse_id');
    let numWarehouseFieldIndex = this.getFieldIndex('warehouse_id', this.purchaseOrderForm[numWarehouseFormIndex]);
    let indexSiteForm = this.getFormFieldIndex('site_id');
    let indexSiteField = this.getFieldIndex('site_id', this.purchaseOrderForm[indexSiteForm]);
    let indexOfDeliveryNotesForm = this.getFormFieldIndex('delivery_notes');
    let indexOfDeliveryNotesField = this.getFieldIndex('delivery_notes', this.purchaseOrderForm[indexOfDeliveryNotesForm]);

    if (indexOfWarehouseForm > -1) {

      this.purchaseOrderForm[indexOfDeliveryLocationForm]['groups'].get('warehouse_id').valueChanges.subscribe(strWarehouseId => {

        let objWarehouse = null;
        this.purchaseOrderForm[indexOfDeliveryLocationForm]['fields'].forEach(objFieldElement => {
          if (objFieldElement['key'] == 'warehouse_id') {
            objFieldElement['options'].forEach(objWarehouseOptions => {
              if (objWarehouseOptions['id'] == strWarehouseId) {
                objWarehouse = objWarehouseOptions;
              }
            })
          }
        });

        if (objWarehouse != null) {

          let strInstructions: string;

          if (objWarehouse['delivery_instructions']) {
            strInstructions = objWarehouse['delivery_instructions']
          }

          if (objWarehouse['extras'] && objWarehouse['extras']['delivery_instructions']) {
            strInstructions = objWarehouse['extras']['delivery_instructions'];
          }

          if (indexOfDeliveryNotesForm > -1) {
            this.purchaseOrderForm[indexOfDeliveryLocationForm]['groups']
              .get('delivery_notes')
              .setValue(strInstructions || '');
          }
        }

      })
    }

    // If delivery location is found. Add on changes event to that field
    if (indexOfDeliveryLocationForm > -1 && indexOfDeliveryLocationField > -1) {
      this.purchaseOrderForm[indexOfDeliveryLocationForm]['groups'].get('delivery_location').valueChanges.subscribe(val => {
        // If site field is found
        if (indexSiteForm > -1 && indexSiteField > -1) {
          // As default hide the field
          this.purchaseOrderForm[indexSiteForm]['fields'][indexSiteField]['is_hidden'] = true;
          // If delivery location is site show site field
          if (val == 'site') {
            this.purchaseOrderForm[indexSiteForm]['fields'][indexSiteField]['is_hidden'] = false;
          }
        }
        // If delivery notes field is found
        if (indexOfDeliveryNotesForm > -1 && indexOfDeliveryNotesField > -1) {
          // As default hide the field
          this.purchaseOrderForm[indexOfDeliveryNotesForm]['fields'][indexOfDeliveryNotesField]['is_hidden'] = true;
          // If delivery location is refer to delivery notes show delivery notes field
          if (val == 'refer_to_delivery_notes' || val == 'main_warehouse') {
            this.purchaseOrderForm[indexOfDeliveryNotesForm]['fields'][indexOfDeliveryNotesField]['is_hidden'] = false;
          }
        }

        if (numWarehouseFormIndex > -1 && numWarehouseFieldIndex > -1) {
          this.purchaseOrderForm[numWarehouseFormIndex]['fields'][numWarehouseFieldIndex]['is_hidden'] = true;
          if (val == 'main_warehouse') {
            this.purchaseOrderForm[numWarehouseFormIndex]['fields'][numWarehouseFieldIndex]['is_hidden'] = false;
          }
        }
      });
    }
  }
}
