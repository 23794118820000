<app-error-handler onlyFor="assets:list" alignment="center" displayAs="widget">
    <div class="d-flex justify-content-around activities-option tabs-content">
    <div *ngIf="strModule == 'jobs'" [ngClass]="{ 'col-sm-4' : strModule == 'jobs' }" class="text-center">
      <span id="widgetAssetsSelectButton" class="pointer" (click)="selectAssets()" class="span-links">
        <fa-icon [icon]="['fas', 'wrench']" class="text-success"></fa-icon>
        {{ strCreateLabel | translate }}
      </span>
      <ng-template #createAssetDisabled>
        <span id="widgetAssetsSelectButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'wrench']" class="text-default"></fa-icon>
            {{ strCreateLabel | translate }}
        </span>
      </ng-template>
    </div>
    <div [ngClass]="strModule == 'sites' ? 'col-sm-12' : 'col-sm-4'" class="text-center">
      <ng-container *fcHasPermission="'assets:create'; else createAssetAndLinkDisabled">
        <span id="widgetAssetsCreateLinkButton" class="pointer" (click)="createAndLinkAsset()" class="span-links">
          <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
          {{ 'create_and_link_asset' | translate }}
        </span>
      </ng-container>
      <ng-template #createAssetAndLinkDisabled>
        <span id="widgetAssetsCreateLinkButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-default"></fa-icon>
            {{ 'create_and_link_asset' | translate }}
        </span>
      </ng-template>
    </div>
    <div *ngIf="strModule == 'jobs'" [ngClass]="{ 'col-sm-4' : strModule == 'jobs' }" class="text-center">
      <span id="widgetAssetsCreateQuoteVersionButton" class="pointer" (click)="(bProcessed) ? convertToQuote() : ''" class="span-links">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        {{ 'create_opportunity' | translate }}
      </span>
    </div>
  </div>

    <div class="assets-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pt-4 pb-4">
          <div class="form-inline">
            <div class="form-group flex-nowrap w-100">
              <div class="col-lg-4">
                <label class="jc-l">{{ strFilterLabel | translate }}</label>
              </div>
              <div *ngIf="objAssetForm" class="col-lg-8">
                <form [formGroup]="objAssetForm" (change)="getRecord()">
                  <app-edit-field [item]="objAssetField[strFilterId]" [form]="objAssetForm" [strMode]="add" [bSubmitted]="false" [strCustomClass]="'w-100'"
                    id="widgetAssetsSerialNumberFilter"></app-edit-field>
                </form>
              </div>
            </div>
          </div>

          <div *ngIf="objOption['status']" class="form-inline mt-12">
            <div class="form-group flex-nowrap w-100">
              <div class="col-lg-4">
                <label class="jc-l">{{ 'status' | translate }}</label>
              </div>
              <div class="col-lg-8">
                <ng-select
                [items]="objOption.status.config"
                class="value-text"
                (change)="getRecord()"
                [(ngModel)]="strFilterStatus"
                id="widgetAssetsStatusFilter">
                  <ng-template ng-label-tmp let-item="item">{{ item.text | translate }}</ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <span id="widgetAssetsStatusFilter{{ item.id }}">{{ item.text | translate }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row border-top pt-2 pb-2">
        <div class="col-sm-6 pt-1">
          <span class="module-title">
            <fa-icon [icon]="['fa', 'wrench']" aria-hidden="true"></fa-icon>
            &nbsp;
            {{ 'linked_assets' | translate }}
          </span>
        </div>
        <div class="col-sm-6 pr-2">
          <nav class="pagination-section">
            <ul class="pagination">
              <refresh-pagination-btn id="widgetAssetsRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bLoader"></refresh-pagination-btn>
              <li [ngClass]="(listingService.strFirstValue != listingService.strCurrentValue && listingService.strCurrentValue != '') ? 'active pointer' : 'disabled'" class="page-item">
              <a id="widgetAssetsPreviousButton" class="page-link" aria-label="prev" (click)="getRecord('prev')">
                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
              </a>
              </li>
              <li [ngClass]="(listingService.strNextValue != '') ? 'active pointer' : 'disabled'" class="page-item">
              <a id="widgetAssetsNextButton" class="page-link"  aria-label="next" (click)="getRecord('next')">
                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
              </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div *ngIf="arAssetList.length < 1">
        <div class="row border-top" *ngIf="bLoader">
          <span class="no-content">
            {{ 'no_assets_found' | translate }}
          </span>
        </div>
        <div class="row border-top" *ngIf="!bLoader">
          <span class="no-content">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            {{ 'loading' | translate }}
          </span>
        </div>
      </div>

      <div class="row border-top pt-2 pb-2 pr-2"  *ngFor="let asset of arAssetList" [id]="'widgetAssetsRecord' + asset.id">

        <div class="col-sm-1 circle-size">
          <fa-icon matTooltip="{{ asset.status | translate }}" [icon]="['fas', 'circle']" [ngClass]="[(objStatusClass[asset.status]) ? objStatusClass[asset.status] : '']"></fa-icon>
        </div>

        <div class="col-sm-4">
          <ng-container *ngIf="asset.is_owned_by_current_client; else noLinkAssetsLabel">
            <a id="widgetAssetsLabel" *ngIf="strModule == 'jobs'" class='word-wrap' class="pointer" [routerLink]="['/assets/' + asset.asset_id]">{{ (asset.serial_number) ? '#' + asset.serial_number : '--' }}</a>
            <a id="widgetAssetsLabel" *ngIf="strModule == 'sites'" class='word-wrap' class="pointer" [routerLink]="['/assets/' + asset.id]">{{ (asset.serial_number) ? '#' + asset.serial_number : '--' }}</a> <br/>
          </ng-container>
          <ng-template #noLinkAssetsLabel>
            <span class="word-wrap" id="widgetAssetsLabel">{{ (asset.serial_number) ? '#' + asset.serial_number : '--' }}</span><br/>
          </ng-template>

          <span class='word-wrap'>{{ (asset.asset_type_text) ? asset.asset_type_text : '--' }}</span> <br/>
          <span class='word-wrap' matTooltip="{{ 'walk_order' | translate }}">{{ (asset.walk_order) ? asset.walk_order : '--' }}</span>
        </div>

        <div class="col-sm-6 truncate" matTooltip="{{ (asset.notes && asset.notes.length >= 85) ? asset.notes : '' }}">
          {{ (asset.notes) ? asset.notes : '--' }}
        </div>

        <div class="col-sm-1 d-table text-role less-padding">
          <div class="row" *ngIf="asset.editable">
            <div class="col-12">
              <ng-container *fcHasPermission="'assets:edit'; else disabledEditButton">
                <fa-icon id="widgetAssetsEditButton" [icon]="['fas', 'pencil']" class="text-success pointer float-right" (click)="(asset.asset_id == undefined) ? saveAsset(asset.id) : saveAsset(asset.asset_id)"></fa-icon>
              </ng-container>
              <ng-template #disabledEditButton>
                <fa-icon id="widgetAssetsEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
              </ng-template>
            </div>
          </div>
          <div class="row" *ngIf="asset.deletable">
            <div class="col-12">
              <ng-container *fcHasPermission="'assets:delete'; else disabledDeleteButton">
                <fa-icon id="widgetAssetsDeleteButton" [icon]="['fas', 'trash-alt']" class="text-warning pointer float-right" (click)="deleteRecord(asset.id)"></fa-icon>
              </ng-container>
              <ng-template #disabledDeleteButton>
                <fa-icon id="widgetAssetsDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
              </ng-template>
            </div>
          </div>
          <div class="row" *ngIf="strModule === 'jobs'">
              <div class="col-12">
                <fa-icon id="widgetAssetsPromptsButton" [icon]="['fas', 'tasks']" class="text-primary pointer float-right" (click)="showPerAssetPrompts(asset)"></fa-icon>
              </div>
          </div>
          <div class="row" *ngIf="strModule === 'jobs'">
            <div class="col-12">
              <fa-icon id="widgetAssetJobsPhotosButton" [icon]="['fas', 'file-image']" class="text-danger pointer float-right" (click)="openAssetJobPhotosDialog(asset)"></fa-icon>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-12">
              <fa-icon [icon]="['fas', 'file-image']" class="text-primary pointer float-right" (click)="openFileForm(asset)"></fa-icon>
            </div>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</app-error-handler>