<div class="d-flex h-100 flex-column">
  <h1 mat-dialog-title class="title">
    <fa-icon [icon]="['fas', 'edit']" class="mr-1"></fa-icon>{{ 'manage_subscription' | translate }}
  </h1>
  <hr class="w-100 mt-0">
  <mat-dialog-content>

    <div *ngIf="!loading" class="form-inline">
      <div class="flex-nowrap w-100 d-flex align-items-start justify-content-center mb-1">
        <div class="d-flex col-lg-12 justify-content-start">
          <small><b>{{ 'current_plan' | translate }}</b></small>
        </div>
      </div>
      <div class="flex-nowrap w-100 d-flex align-items-start justify-content-center">
        <div class="d-flex col-lg-12 justify-content-start">
          <h5>
            {{ strCurrentCurrency }} | {{ strPlanName }} - {{ 'subscription_label_' + strBillingPeriod | translate }}
            <span [ngClass]="{'text-warning': strStatus == 'in_trial', 'text-success': strStatus == 'active'}">
              ({{ strStatus | translate }})
            </span>
          </h5>
        </div>
      </div>
      <div class="flex-nowrap w-100 d-flex align-items-start justify-content-center">
        <div class="d-flex col-lg-12 justify-content-start">
          <small><a href="" [routerLink]="" (click)="openPaymentMethodPage()">{{ 'change_payment_method' | translate }}</a></small>
        </div>
      </div>
      <div class="flex-nowrap w-100 d-flex align-items-start justify-content-center mb-4">
        <div class="d-flex col-lg-12 justify-content-start">
          <small><a id="billingHistory" href="" [routerLink]="" (click)="openBillingHistory()">{{ 'billing_history' | translate }}</a></small>
        </div>
      </div>
      <div class="flex-nowrap w-100 d-flex align-items-start justify-content-center mb-3">
        <div class="d-flex col-lg-5 justify-content-start">
          <strong>{{ 'choose_plan_duration' | translate }}:</strong>
        </div>
        <div class="col-lg-7 text-center">
          <div class="row">
            <div class="col-6">
              <input [(ngModel)]="periodUnit" class="form-check-input" type="radio" value="year" (change)="onChangePeriodUnit()"/>{{ 'annual_plan' | translate }}
            </div>
            <div class="col-6">
              <input [(ngModel)]="periodUnit" class="form-check-input" type="radio" value="month" (change)="onChangePeriodUnit()"/>{{ 'monthly_plan' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group flex-nowrap w-100 d-flex align-items-start justify-content-center">
        <div class="d-flex col-lg-5 justify-content-start">
          <label class="jc-l mt-2"><strong>{{ 'choose_your_plan' | translate }}:</strong></label>
        </div>
        <div class="col-lg-7">
          <ng-select appendTo="body" [searchable]="false" [clearable]="false" class="value-text" [(ngModel)]="selectedPlanId" (change)="setSelectedPlan(selectedPlanId)">
            <ng-option value="{{ plan.id }}" *ngFor="let plan of plansOption">{{ plan.title | translate }}</ng-option>
          </ng-select>
          <small class="text-muted" *ngIf="(selectedPlan$ | async) as selectedPlan"><strong> {{ selectedPlan.currency }}{{ selectedPlan.price }} / {{ selectedPlan.period_unit }}&nbsp;</strong> <i>{{ selectedPlan.description }}</i></small>
        </div>
      </div>
    </div>
    <div class="text-center">
      <inline-view-loader [isLoading]="loading" [hasText]="false"></inline-view-loader>
    </div>
    <div *ngIf="!loading" class="mt-4 mb-1">
      <div>
        <small><span class="text-muted">{{ 'for_more_information_click' | translate }} <a href="{{ env.website }}en/pricing/" target="_blank">{{ 'here' | translate }}</a></span></small>
        <br>
        <small><span class="text-muted">{{ 'for_cancel_info' | translate }} <a href = "mailto: support@crmonline.com.au">support@crmonline.com.au</a></span></small>
      </div>
    </div>
    <button class="btn btn-primary btn-lg float-right" (click)="subscribeToPlan(selectedPlanId)" *ngIf="!loading && showSaveButton === true">
      <fa-icon [icon]="['fas', 'save']" class="mr-1"></fa-icon>
      <span class="pl-1">{{ 'update' | translate }}</span>
    </button>
  </mat-dialog-content>
</div>
