import { Component, OnInit } from '@angular/core';
import { ClientStoreService } from '../../../../services/client-store.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { NotificationService } from '../../../../services/notification.service';
import { AccountService } from '../../../../services/account.service';
import { Router } from '@angular/router';
import { CustomTranslateService } from '../../../../services/custom-translate.service';
import { Notification } from '../../../../objects/notification';
import { filter } from 'rxjs/operators';
import { Pagination } from '../../../../objects/pagination';

@Component({
  selector: 'notification-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [CustomTranslateService]
})
export class HeaderComponent implements OnInit {

  constructor(
    public clientStoreService: ClientStoreService,
    public localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private accountService: AccountService,
    private customTranslations: CustomTranslateService,
    private router: Router,
  ) {
    this.customTranslations.initializeTransalateables(['new_chat_message']);
  }

  /**
   * Simply a list of persistent type notifications from pusher.
   *
   * @var {Notification}
   */
  public arNotifications: Notification[] = [];

  /**
   * A list of persistent notifications that should
   * always be at the top.
   *
   * @var {Notification}
   */
  public arStickyNotifications: Notification[] = [];

  /**
   * Saves the current page of the notification list.
   *
   * @var {Pagination}
   */
  public objNextToken: Pagination = null;

  /**
   * Number of unread notifications.
   *
   * @var {number}
   */
  public numUnreadNotification: number = 0;

  ngOnInit(): void {

    this.checkClientNotifications();
    this.checkLocalStorageNotifications();

    this.notificationService.notif$
      .pipe(
        filter(notification => (
          notification instanceof Notification &&
          notification.type === 'persistent'
        ))
      )
      .subscribe((notification: Notification) => {
        this.addNotification(notification);
      });

    this.notificationService.notifMarkAsRead$
    .pipe(
      filter(notification => (
        notification instanceof Notification
      ))
    )
    .subscribe((notification: Notification) => {
      this.markAsRead(notification);
    });

  }

  /**
   * Triggers an action based on the notification selected.
   *
   * @param {string} strTitle
   *
   * @return {void}
   */
  getActionButton(objNotification: Notification): void {
    switch (objNotification.id) {
      case 'demo_mode':
        this.removeDemoData(objNotification.id);
        break;
      default:
        this.notificationService.sendNotificationAction(objNotification.id, true);
        break;
    }
  }

  /**
   * Marks all notifications as read in the list
   * of notifications. Also sends a request
   * to mark the notification records in the
   * database as read.
   *
   * @returns {void}
   */
  markAllAsRead(): void {

    this.arNotifications.forEach(notification => {
      notification.markAsRead();
    });

    this.numUnreadNotification = 0;

    this.updateNotifications();
    this.checkLocalStorageNotifications();

    this.notificationService.markNotificationAsRead('all').subscribe();
  }

  /**
   * Marks a single notification as read.
   *
   * @param {Notification} objNotification
   *
   * @returns {void}
   */
  markAsRead(objNotification: Notification): void {
    if (!objNotification.is_read && !objNotification.isTempId()) {
      this.subtractOneToUnreadNotification();
      objNotification.markAsRead();
      this.updateNotifications();
      this.checkLocalStorageNotifications();
      this.notificationService.markNotificationAsRead([objNotification.id.toString()]).subscribe();
    }
    if (objNotification.link) {
      this.router.navigateByUrl(objNotification.link);
    }
  }

  /**
   * Retrieves previous notification messages.
   *
   * @returns {void}
   */
  getPreviousNotifications(): void {
    this.notificationService.getNotifications(this.objNextToken.toString()).subscribe(response => {
      response.data.forEach(notification => {
        this.arNotifications.push(notification);
      });
      this.objNextToken = response.token;
      this.updateNotifications();
    })
  }

  /**
   * Retrieves all notifications from the local storage
   *
   * @returns {void}
   */
  private checkLocalStorageNotifications(): void {

    let objNotificationConfig: {
      notifications: Notification[],
      page: Pagination
      unread: number
    } = this.localStorageService.getJsonItem('local_notifications') || null;

    if (objNotificationConfig === null) {
      this.notificationService.getNotifications().subscribe(response => {
        response.data.forEach(notification => {
          this.arNotifications.push(notification);
        });
        this.objNextToken = response.token;
        this.numUnreadNotification = response.unread;
        this.updateNotifications();
      });
    } else {
      this.arNotifications = objNotificationConfig.notifications.map(notification => new Notification(notification));
      this.objNextToken = objNotificationConfig.page;
      this.numUnreadNotification = objNotificationConfig.unread;
    }
  }

  /**
   * Checks the notifications from the client's table.
   *
   * @returns {void}
   */
  private checkClientNotifications(): void {
    let objClient = this.clientStoreService.getActiveClient();
    if (objClient && objClient.notifications && typeof objClient.notifications == 'object') {
      Object.keys(objClient.notifications).forEach(notification => {
        this.arStickyNotifications.push(new Notification({
          id: notification,
          header: notification,
          message: objClient.notifications[notification].message,
          has_button: objClient.notifications[notification].has_button,
          button_title: notification + '_button',
          type: 'persistent'
        }))
      });
    }
  }

  /**
   * Updates the local storage with the latest notification.
   *
   * @returns {void}
   */
  private updateNotifications(): void {

    let objLocalNotification = {
      notifications: this.arNotifications,
      page: this.objNextToken,
      unread: this.numUnreadNotification
    }

    this.localStorageService.setItem('local_notifications', objLocalNotification);
  }

  /**
   * Adds a new notifiction to the list
   * of notifications.
   *
   * @param {Notification} objNotification
   *
   * @returns {void}
   */
  private addNotification(objNotification: Notification): void {
    if (!objNotification.is_read) {
      this.addOneToUnreadNotification();
    }
    this.arNotifications.unshift(objNotification);
    this.updateNotifications();
  }

  /**
   * Removes the notification from the display.
   *
   * @param {void} filter
   */
  private removeNotification(filter: { header?: string, id?: string }): void {
    let numNotificationIndex = this.arNotifications.findIndex(notification => notification.header === filter.header || notification.id === filter.id);
    if (numNotificationIndex > -1) {
      this.arNotifications.splice(numNotificationIndex, 1);
    }
  }

  /**
   * Sends a request to remove all demo data.
   *
   * @param {string} strTitle
   *
   * @returns {void}
   */
  private removeDemoData(strTitle: string): void {
    this.notificationService.sendConfirmation('demo_data_removal', 'warning')
      .subscribe(confirmation => {
        if (confirmation.answer) {

          this.removeNotification({ header: strTitle });
          this.notificationService.removeNotification(strTitle);
          this.router.navigate(["/admin/users/invites"]);

          this.notificationService.sendNotification('please_wait', 'removing_demo', 'info');
          this.accountService.removeDemoData(strTitle).subscribe(
            data => {
              if (data) {
                this.removeNotification({ header: strTitle });
                this.notificationService.removeNotification(strTitle);
                this.notificationService.notifySuccess('demo_data_removed_successfully');
              }
              this.router.navigate(["/admin/users/invites"]);
            }
          );
        }
      });
  }

  /**
   * Adds one to unread notification count.
   *
   * @returns {void}
   */
  private addOneToUnreadNotification(): void {
    this.numUnreadNotification = this.numUnreadNotification + 1;
  }

  /**
   * Subtracts one to unread notification.
   */
  private subtractOneToUnreadNotification(): void {
    this.numUnreadNotification = this.numUnreadNotification - 1;
  }
}
