import { NgModule } from "@angular/core";
import { ButtonComponent as SWMSManageButtonComponent } from "./components/manage/button.component";
import { FormComponent as SWMSManageFormDialogComponent } from "./components/manage/form/form.component";
import { CommonModule } from "@angular/common";
import { SharedModule as AppSharedModule } from "../../../shared/modules/shared.module";
import { ViewService } from "../../../services/view.service";
import { SafetyManagementService } from "./services/safety-management.service";
import { NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule as FormTemplateSharedModule } from '../../form-templates/shared/shared.module';

@NgModule({
  declarations: [
    SWMSManageButtonComponent,
    SWMSManageFormDialogComponent,
  ],
  entryComponents: [
    SWMSManageButtonComponent,
    SWMSManageFormDialogComponent,
  ],
  exports: [
    SWMSManageButtonComponent,
  ],
  imports: [
    CommonModule,
    AppSharedModule.forRoot(),
    NgbTypeaheadModule,
    FormTemplateSharedModule,
  ],
  providers: [
    ViewService,
    SafetyManagementService
  ]
})
export class SharedModule {}
