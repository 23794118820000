import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

import { FormatPipe } from '../moment/format/format.pipe';

@Pipe({
  name: 'humanReadableDate'
})
export class HumanReadableDatePipe extends FormatPipe implements PipeTransform {

/**
 * Returns a human readable date from a given input.
 *
 * @param input
 * @param format
 *
 * @returns {String}
 */
  transform(input: moment.MomentInput, format: string = 'll'): string|null {
    return super.transform(input, format);
  }

}
