import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { kPermissionsDef, Role } from '../objects/role';
import { Injectable } from '@angular/core';
import { delay, filter, map } from 'rxjs/operators';
import { Client } from '../objects/client';
import { ClientStoreService, UserAccessLevel } from './client-store.service';
import { environment } from '../../environments/environment';
import { Select } from '../objects/select';

type RoleResponse = Role;
type SaveResult = string;
type RoleData = Pick<Role, 'name' | 'permissions'>;
type SelectableUser = {
  /**
   * Unique identifier of the user
   */
  id: string;
  /**
   * Display name of the user
   *
   * @type {string}
   */
  text: string;
};

type SearchResultResponse = {
  /**
   * List of users
   *
   * @type {SelectableUser[]}
   */
  users: SelectableUser[];
};

type ViewUsersResponse = {
  /**
   * List of users that are attached to the current role
   *
   * @type {SelectableUser[]}
   */
  users: SelectableUser[];
}

export type ClientInformation = Pick<Client, 'roles' | 'level'>;

type AssignedModel = {
  /**
   * Assigned user id
   *
   * @type {string}
   * @readonly
   */
  readonly user_id: string;
  /**
   * List of department ids assigned to the record
   *
   * @type {string[]}
   * @readonly
   */
  readonly department_ids: string[];
};

type HasPermissionOptions = {
  /**
   * Current client of the user to be use for role checking
   *
   * @type {ClientInformation}
   * @readonly
   */
  readonly client?: ClientInformation;
  /**
   * @todo
   */
  readonly model?: AssignedModel;
};

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  /**
   * Api Base URL
   *
   * @readonly
   * @type {string}
   */
  protected readonly baseURL: string = environment.url;

  /**
   * Create instance of the service
   *
   * @param {HttpClient} http
   * @param {ClientStoreService} clients
   */
  constructor(
    protected http: HttpClient,
    protected clients: ClientStoreService
  ) {}

  /**
   * Save current role information to the server
   *
   * @param   {RoleData} objRole
   * @param   {string|undefined} strId
   *
   * @returns {Observable<SaveResult>}
   */
  save(objRole: RoleData, strId?: string): Observable<SaveResult> {
    let strUrl = `${this.baseURL}/roles`;
    const strMethod = (strId) ? 'PUT' : 'POST';

    // append the id to the url if the current request will be
    // an update request
    if (strMethod === 'PUT') {
      strUrl += `/${strId}`;
    }

    return this.http.request<RoleResponse>(strMethod, strUrl, {
      body: new URLSearchParams({
        name: objRole.name,
        permissions: JSON.stringify(objRole.permissions),
      }),
      observe: 'response'
    }).pipe(
      filter((objResponse) => objResponse.status === 200 || objResponse.status === 201),
      map((objResponse) => objResponse.body.id)
    );
  }

  /**
   * Fetch current role information
   *
   * @param   {string} strId
   *
   * @returns {Observable<Role>}
   */
  get(strId: string): Observable<Role> {
    return this.http.post<RoleResponse>(`${this.baseURL}/roles/${strId}`, undefined, {
      observe: 'response'
    }).pipe(
      filter(({ status }) => status === 200),
      map(({ body }) => body as Role)
    );
  }

  /**
   * Check if the current user has an access to provided action
   *
   * @param   {string} strAction
   * @param   {HasPermissionOptions} objOptions
   *
   * @returns {boolean}
   */
  hasPermission(strAction: string, objOptions: HasPermissionOptions = {}): boolean {
    objOptions = Object.assign({
      client: this.clients.getActiveClient(),
      model: {}
    }, objOptions);

    // - user is admin for the current activated client
    // - user is non admin and does not have any roles to the current activated client
    // - user is non admin and of the role has an enabled access to the specified action/module
    if (
      objOptions.client.level === UserAccessLevel.admin
      || (! objOptions.client.roles || objOptions.client.roles.length === 0)
    ) {
      return true;
    }

    const [strModule] = strAction.split(':');
    const objDef = kPermissionsDef.findIndex((objDef) => objDef.action === strModule);

    // skip checking if current action/module is not part in any the defined modules/action
    if (objDef === -1) {
      return true;
    }

    let bIsAllowed: boolean = false;

    // check for every role if we have the action enabled for specified action/module
    for (let objRole of objOptions.client.roles) {
      bIsAllowed = !! objRole.permissions.find(
        ({ action, scope }) => action === strAction && (scope === 'all' || scope === 'enabled' || scope === 'owner')
      );

      // roles are additive, if one the roles contains an enabled action (it is either owner, all, or enabled scope)
      // we would allow the action/module to be seen or performed
      if (bIsAllowed) {
        return bIsAllowed;
      }
    }

    return bIsAllowed;
  }

  /**
   * Duplicate an existing role to a new role
   *
   * @param   {string} strId
   * @param   {string} strNewName
   *
   * @returns {Observable<string>}
   */
  duplicate(strId: string, strNewName: string): Observable<string> {
    return this.http.post<Role>(
      `${this.baseURL}/roles/duplicate`,
      new URLSearchParams({
        id: strId,
        name: strNewName,
      }),
      {observe: 'response'}
    ).pipe(
      filter((objResponse) => objResponse.status === 201),
      map((objResponse) => objResponse.body.id)
    );
  }

  /**
   * Search for users that can be assigned to a role
   *
   * @param   {string} strTerm
   *
   * @returns {Observable<Select[]>}
   */
  searchForUsers(strTerm?: string): Observable<Select[]> {
    return this.http.post<SearchResultResponse>(
      `${this.baseURL}/roles/search_for_users`,
      new URLSearchParams({
        search: strTerm
      }),
      {observe: 'response'}
    ).pipe(
      filter((objResponse) => objResponse.status === 200),
      map((objResponse) => objResponse.body.users.map((objUser) => new Select(objUser.id, objUser.text))),
    );
  }

  /**
   * Retrieved the list of users that are attached to the current role
   *
   * @param   {string} strRoleId
   *
   * @returns {Observable<Select[]>}
   */
  retrievedUsers(strRoleId: string): Observable<Select[]> {
    return this.http.post<ViewUsersResponse>(
      `${this.baseURL}/roles/view_users`,
      new URLSearchParams({
        role_id: strRoleId
      }),
      {observe: 'response'}
    ).pipe(
      filter((objResponse) => objResponse.status === 200),
      map((objResponse) => objResponse.body.users.map((objUser) => new Select(objUser.id, objUser.text))),
    );
  }

  /**
   * Update the current list of users attached to the role
   *
   * @param   {string}   strRoleId
   * @param   {string[]} arUserIds
   *
   * @returns {Observable<Select[]>}
   */
  updateUsers(strRoleId: string, arUserIds: string[] = []): Observable<Select[]> {
    return this.http.put<ViewUsersResponse>(
      `${this.baseURL}/roles/update_users`,
      new URLSearchParams({
        role_id: strRoleId,
        user_ids: JSON.stringify(arUserIds),
      }),
      {observe: 'response'}
    ).pipe(
      filter((objResponse) => objResponse.status === 200),
      map((objResponse) => objResponse.body.users.map((objUser) => new Select(objUser.id, objUser.text))),
    );
  }
}
