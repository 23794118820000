import { Injectable } from '@angular/core';
import { LooseObject } from '../../../objects/loose-object';

@Injectable()
export class SharedService {

  /**
   * The site data to share in between the wizard components.
   *
   * @var {WizardSharedSite}
   */
  private objSiteData?: WizardSharedSite;

  /**
   * Assign the site in the service.
   *
   * @param {LooseObject} objSite
   */
  setSite(objSite: LooseObject): void {
    this.objSiteData = new WizardSharedSite({
      site_id: objSite.id,
      primary_contact_id: objSite.primary_contact_id
    });
  }

  /**
   * Retrieve the site data in the service.
   *
   * @returns {WizardSharedSite | null}
   */
  getSite(): WizardSharedSite | null {
    return this.objSiteData || null;
  }

}

export class WizardSharedSite {
  site_id: string;
  primary_contact_id: string;

  constructor(objWizardSharedSite: WizardSharedSite) {
    this.site_id = objWizardSharedSite.site_id;
    this.primary_contact_id = objWizardSharedSite.primary_contact_id;
  }
}