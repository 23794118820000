<mat-dialog-content class="overflow-overlay mat-dialog-content">
  <div class="overflow-overlay" style="max-height: 500px;">
    <ng-container>
      <ng-container *ngIf="isLoading | async">
        <div class="d-flex justify-content-center pt-2 pb-2 loader-container">
          <mat-spinner></mat-spinner>
        </div>
      </ng-container>

      <span *ngIf="errorMessageOnExtract !== null" class="mapping-error-text">{{ errorMessageOnExtract }}</span>

        <div class="card" *ngIf="errorMessageOnExtract === null">
          <div id="importCsvFieldMapping" *ngIf="pairedData.length > 0">
            <div class="card-body border-0 pt-0">
              <table class="table table-stripe w100p table-layout-fixed mb-0">
                <tr>
                  <th class="w30p table-header">{{ 'csv_column' | translate }}</th>
                  <th class="w40p table-header">{{ 'first_row_value' | translate }}</th>
                  <th class="w30p table-header">{{ 'allowed_columns' | translate }}
                    <fa-icon
                      *ngIf="requiredFieldsList !== null"
                      [icon]="['fas', 'info-circle']"
                      class="change-pointer"
                      matTooltip="{{ requiredFieldsList }}"></fa-icon>
                  </th>
                </tr>
                <ng-container *ngFor="let objPairedField of pairedData; let intIndex = index;">
                  <tr>
                    <td class="w30p">
                      <label class="jc-l mt-2" for="import_header">
                        <strong>{{ objPairedField.import_header }}:</strong>
                      </label>
                    </td>
                    <td class="w40p">
                      <div class="position-relative text-truncate">
                        <span
                          for="first_row_value"
                          class="jc-l mt-2"
                          [matTooltip]="objPairedField.first_row_data"
                          [matTooltipPosition]="'below'">
                          {{ objPairedField.first_row_value | truncate | translate }}
                        </span>
                      </div>
                    </td>
                    <td class="w30p">
                      <ng-select
                        [clearable]="true"
                        [multiple]="false"
                        [(ngModel)]="objPairedField.metadata.label"
                        [bindValue]="label"
                        [bindLabel]="label"
                        (clear)="onClear(objPairedField.metadata.label, true)"
                        (change)="onSelectOnAllowed()">
                        <ng-option
                          value="{{ plan.label }}"
                          *ngFor="let plan of arAllowedColumns"
                          [disabled]="plan.disabled">{{ plan.label | translate }}
                        </ng-option>
                      </ng-select>
                    </td>

                  </tr>
                </ng-container>

                <!-- FOR ADDITIONAL FIELDS -->
                <ng-container *ngIf="additionalPairedData.length > 0">
                  <tr *ngFor="let objAdditionalPairedField of additionalPairedData; let intIndex = index;">
                    <td class="w30p">
                      <label class="jc-l mt-2">
                        {{ objAdditionalPairedField.import_header }}:
                      </label>
                    </td>
                    <td class="w40p">
                      <ng-container *ngIf="objAdditionalPairedField.metadata === [];
                        else definedTypeTemplate">--</ng-container>
                        <ng-template #definedTypeTemplate>
                          <ng-container [ngSwitch]="objAdditionalPairedField.metadata.type">
                            <!-- NUMBER -->
                            <input
                              *ngSwitchCase="'currency'"
                              class="value-text pointer"
                              type="number"
                              [(ngModel)]="objAdditionalPairedField.first_row_value"/>

                            <!-- TEXTAREA -->
                            <textarea
                              *ngSwitchCase="'textarea'"
                              class="form-control template-option"
                              [(ngModel)]="objAdditionalPairedField.first_row_value"
                              [maxlength]="objAdditionalPairedField.metadata.maxlength !== undefined ?
                                objAdditionalPairedField.metadata.maxlength : 256">
                              </textarea>

                            <!-- CHECKBOX (TRUE | FALSE) -->
                            <mat-slide-toggle
                              *ngSwitchCase="'checkbox'"
                              color="primary"
                              [(ngModel)]="objAdditionalPairedField.first_row_value">
                            </mat-slide-toggle>

                            <!-- DROPDOWN -->
                            <ng-select
                              *ngSwitchCase="'dropdown'"
                              [items]="objAdditionalPairedField.metadata.options"
                              bindLabel="text"
                              bindValue="id"
                              [(ngModel)]="objAdditionalPairedField.first_row_value">

                              <ng-template ng-option-tmp let-item="item" class="value-text">
                                {{ item.text | translate }}
                              </ng-template>

                              <ng-template ng-label-tmp let-item="item" class="template-option" let-clear="clear">
                                  {{ item.text | translate }}
                              </ng-template>
                            </ng-select>

                            <!-- DEFAULT -->
                            <input
                              *ngSwitchDefault
                              class="value-text pointer"
                              type="text"
                              [(ngModel)]="objAdditionalPairedField.first_row_value"
                              [maxlength]="objAdditionalPairedField.metadata.maxlength !== undefined ?
                              objAdditionalPairedField.metadata.maxlength : 256"/>
                          </ng-container>
                        </ng-template>
                    </td>
                    <td class="w30p">
                      <ng-select
                        [clearable]="true"
                        [multiple]="false"
                        [(ngModel)]="objAdditionalPairedField.metadata.label"
                        [bindValue]="label"
                        [bindLabel]="label"
                        (clear)="onClear(objAdditionalPairedField.metadata.label, false)"
                        (change)="onSelectOnAllowed()">
                        <ng-option
                          *ngFor="let plan of arAllowedColumns"
                          value="{{ plan.label }}"
                          [disabled]="plan !== undefined && plan.disabled">{{ plan.label | translate }}
                        </ng-option>
                      </ng-select>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
    </ng-container>
  </div>
</mat-dialog-content>
<footer *ngIf="errorMessageOnExtract === null">

  <div class="row">
    <div class="col-md-5 form-group align-items-start">
      <div class="btn-group dropup">
        <button type="button" class="btn btn-primary btn-sm dropdown-toggle" [disabled]="isLoading | async" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <button-icon-loader [defaultIcon]="['fas', 'file-pdf']" class="mr-1"></button-icon-loader>
          <span>{{ 'field_mapping_template' | translate }}</span>
        </button>
        <div class="dropdown-menu p-0">
          <ng-container *ngIf="objTemplates as templates">
            <ng-container *ngIf="templates.length; else noTemplate">
              <a *ngFor="let template of templates" (click)="onSelectTemplate(template)"
                class="dropdown-item template-option field-mapping-template-option pointer"
                [ngClass]="{ 'selected-template': strSelectedTemplateName === templates.name }">
              {{ template.name }} </a>
            <div class="dropdown-divider m-0"></div>
            </ng-container>
            <ng-template #noTemplate>
              <a class="dropdown-item template-option field-mapping-template-option cursor-default">{{ 'no_template' | translate }}</a>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-7 form-group">
      <ul class="list-inline pull-right d-flex m-0">
        <li class="list-inline-item align-self-end flex-grow align-items-end">
          <button type="button" class="btn btn-primary btn-lg" [disabled]="isLoading | async" (click)="addFields()">
            <button-icon-loader [defaultIcon]="['fas', 'plus']"></button-icon-loader>
            <span class="pl-1">{{ 'add_fields' | translate }}</span>
          </button>
        </li>
        <li *ngIf="! strSelectedTemplateName" class="list-inline-item align-self-end flex-grow align-items-end">
          <button type="button" class="btn btn-primary btn-lg" [disabled]="isLoading | async" (click)="openTemplateForm()">
            <button-icon-loader [defaultIcon]="['fas', 'plus']"></button-icon-loader>
            <span class="pl-1">{{ 'create_template' | translate }}</span>
          </button>
        </li>
        <li *ngIf="strSelectedTemplateName" class="list-inline-item align-self-end flex-grow align-items-end">
          <button type="button" class="btn btn-primary btn-lg" (click)="openTemplateForm(true)">
            <fa-icon [icon]="['fas', 'save']"></fa-icon><span class="pl-1">{{ 'edit_field_mapping_template' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item align-self-end flex-grow align-items-end">
          <button type="button" class="btn btn-primary btn-lg" [disabled]="isLoading | async" (click)="onSubmit()">
            <button-icon-loader [defaultIcon]="['far', 'file-import']" [isLoading]="bLoading"></button-icon-loader>
            {{ 'submit' | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</footer>