export class Sms {
  modules = [
    'leads',
    'customers',
    'sites',
    'contacts',
    'opportunities',
    'jobs',
    'assets',
    'customer_invoices',
    'purchase_orders',
    'supplier_invoices'
  ]
};
