import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { UserTypeModule } from "../lists/user-type-module";
import { Injectable } from '@angular/core';
import { Observable } from "../../../node_modules/rxjs";
import { Injector } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";

@Injectable()
export class UserLevelModule implements CanActivate {

    /**
     * Static instance of LocalStorageService. Within
     * canActivate, we'll set its value.
     */
    static localStorageService: LocalStorageService;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
        // Inject our LocalStorageService into this class. We can't do it via
        // constructor since this is just a normal class and Angular cannot
        // manipulate it.
        if (!UserLevelModule.localStorageService) {
            const injector: any = Injector.create([{ provide: LocalStorageService, useClass: LocalStorageService, deps: [] }]);
            UserLevelModule.localStorageService = injector.get(LocalStorageService);
        }

        // Check first if there is a selected client.
        if (UserLevelModule.localStorageService.getItem('current_client') != null) {
            // Store current path
            var strPath = route.routeConfig.path;
            // Store client level
            var strClientLevel = JSON.parse(UserLevelModule.localStorageService.getItem('current_client')).level;
            // Store client config
            var strClientConfig = JSON.parse(UserLevelModule.localStorageService.getItem('current_client')).config;
            // Store user type modules
            var userTypeList = new UserTypeModule();
            var arUserTypeList = userTypeList.moduleList;
            var arModuleList: Array<any> = arUserTypeList[strClientLevel] || [];
            // Check if path existing on the current user type
            if (arModuleList.length > 0 && arModuleList.indexOf(strPath) >= 0) {
                // Check if current path is in the config and true
                if (typeof strClientConfig[strPath] != 'undefined') {
                    if (strClientConfig[strPath]) {
                        // Valid path
                        return true;
                    }
                } else {
                    // Valid user
                    return true;
                }
            }
        }
        return false;
    }

}
