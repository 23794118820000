<div>
  <div class="d-flex justify-content-around activities-option tabs-content">
      <span id="widgetAssetsPhotosUploadButton" (click)="openFileForm()" class="span-links">
          <fa-icon [icon]="['fas', 'upload']" class="text-success"></fa-icon>
          {{ 'upload_new_photos' | translate }}
      </span>
  </div>

  <div class="files-body">
      <div class="container">
          <div class="row border-top pt-2 pb-2">
              <div class="col-sm-6 pt-1">
                  <span class="module-title">
                      <fa-icon [icon]="['fa', 'file-alt']" aria-hidden="true"></fa-icon>
                      {{ 'uploaded_photos' | translate }}
                  </span>
              </div>
          </div>

          <div *ngIf="arPhotoList.length < 1">
              <div class="row border-top" *ngIf="!bLoading">
                  <span class="no-content">
                      {{ 'no_photos_uploaded' | translate }}
                  </span>
              </div>
              <div class="row border-top" *ngIf="bLoading">
                  <span class="no-content">
                      <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                      {{ 'loading' | translate }}
                  </span>
              </div>
          </div>

          <div class="row border-top"  *ngFor="let file of arPhotoList, index as indexPhoto">
              <div class="col-sm-5 file-texts">
                  {{ file.file_name  }}
              </div>
              <div class="col-sm-3 file-texts">
                  <fa-icon [icon]="['fas', 'file-image']"></fa-icon>&nbsp;
                  {{ file.file_type }}
              </div>
              <div class="col-sm-3 file-texts">
                  <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>&nbsp;
                  {{ file.updated_at | format: 'll' }}
              </div>
              <div class="col-sm-1">
                <div class="row pt-0 pb-0 justify-content-center">
                    <fa-icon id="widgetAssetsPhotosPreviewButton" [icon]="['fas', 'eye']" class="file-icon-preview" matTooltip="{{ 'preview' | translate }}" (click)="previewPhoto(file)"></fa-icon>
                </div>
                <div class="row pt-0 pb-0 justify-content-center">
                    <fa-icon id="widgetAssetsPhotosDownloadButton" [icon]="['fas', 'download']" class="file-icon-download" matTooltip="{{ 'download_file' | translate }}" (click)="downloadFile(file.upload_name, file.file_type, file.file_name)" ></fa-icon>
                </div>
                <div class="row pt-0 pb-0 justify-content-center">
                    <fa-icon id="widgetAssetsPhotosDeleteButton" [icon]="['fas', 'trash-alt']" class="file-icon-trash" matTooltip="{{ 'delete' | translate }}" (click)="removePhoto(indexPhoto)" ></fa-icon>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
