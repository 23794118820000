<ng-container *ngIf="isInProgress">
  <p class="text-center mt-3 mb-3">
    <fa-icon [icon]="['fas','spinner']" class="custom-size text-secondary" [spin]="true"></fa-icon>
  </p>
  <p class="text-center">{{ 'authorizing_driver' | translate }}</p>
</ng-container>
<ng-container *ngIf="! isInProgress">
  <p *ngIf="! isSuccessful" class="text-center mt-3 mb-3" (click)="getAuthorizationUrl()">
    <fa-icon matTooltip="{{ 'retry_auth' | translate }}"
      matTooltipPosition="above"
      [icon]="['fas','sync-alt']"
      class="custom-size text-primary">
    </fa-icon>
  </p>
  <p class="text-center" id="authorization-message">{{ message | translate }}</p>
</ng-container>
