<div *ngIf="arStatusList" class="row" [ngClass]="{'mb-4': arStatusList.length != 0 }">
  <div class="col-md-12" align="center">
    <div
      *ngFor="let status of arStatusList"
      [ngClass]="{ 'active': status.id == strStatusSelected.id, 'complete': status.position < strStatusSelected.position }"
      [ngStyle]="{'width': arStatusCustomCSS.width}"
      (click)="updateStatus(status)"
      (myEvent)="updateStatus(status)"
      class="progress-nav btn align-middle pointer"
      [id]="'viewProgressButton_' + status.id">
      <span>{{ status.label | translate }}</span>
    </div>
  </div>
</div>