import { Component, OnInit, Input, Output, EventEmitter, NgZone, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { LooseObject } from '../../../../objects/loose-object';
import { concat, Observable, of, Subject } from 'rxjs';
import { Select } from '../../../../objects/select';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { RecordService } from '../../../../services/record.service';

@Component({
  selector: 'app-quickfilter',
  templateUrl: './quickfilter.component.html',
  styleUrls: ['./quickfilter.component.scss']
})
export class QuickfilterComponent implements OnInit {

  /**
   * Handles the element of google auto complete field
   *
   * @var ElementRef
   */
  @ViewChild('locationInput') locationElementRef: ElementRef;

  /**
   * Current module
   *
   * @var string
   */
  @Input() currentModule: string;

  /**
   * Handles the current filter
   *
   * @var object
   */
  @Input() currentFilter: object = {};

  /**
   * List of field types and
   * their metadata.
   *
   * @var LooseObject
   */
  @Input() fieldTypes: LooseObject = {};

  /**
   * Emit the current filter
   *
   * @var FormGroup
   */
  @Output() quickFilter = new EventEmitter<{}>();

  /**
   * Current module form group
   *
   * @var FormGroup
   */
  public objQuickSearchForm: FormGroup;

  /**
   * Handles the current metadata
   *
   * @var object
   */
  public moduleFilter: object = {};

  /**
   * List of metadata in every module
   *
   * @var object
   */
  public moduleFilterLists = {
    users: {
      first_name: {
        label: 'first_name',
        type: 'text',
        class: '',
        max_length: 32,
      },
      last_name: {
        label: 'last_name',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    leads: {
      first_name: {
        label: 'first_name',
        type: 'text',
        class: '',
        max_length: 32,
      },
      last_name: {
        label: 'last_name',
        type: 'text',
        class: '',
        max_length: 32,
      },
      company: {
        label: 'company',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    customers: {
      name: {
        label: 'name',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    sites: {
      site_number: {
        label: 'site_number',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    contacts: {
      first_name: {
        label: 'first_name',
        type: 'text',
        class: '',
        max_length: 32,
      },
      last_name: {
        label: 'last_name',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    customer_invoices: {
      invoice_number: {
        label: 'invoice_number',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    purchase_orders: {
      po_number: {
        label: 'po_number',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    supplier_invoices: {
      invoice_number: {
        label: 'invoice_number',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    items: {
      name: {
        label: 'name',
        type: 'text',
        class: '',
        max_length: 32,
      }
    },
    jobs: {
      po_number: {
        label: 'po_number',
        type: 'text',
        class: '',
        max_length: 32,
      },
      job_number: {
        label: 'job_number',
        type: 'text',
        class: '',
        max_length: 32,
      },
      status: {
        label: 'status',
        type: 'dropdown',
      },
      substatus: {
        label: 'substatus',
        type: 'dropdown',
        array: true
      }
    },
    opportunities: {
      opportunity_number: {
        label: 'opportunity_number',
        type: 'text',
        class: '',
        max_length: 32,
      },
      status: {
        label: 'status',
        type: 'dropdown',
      }
    },
    assets: {
      'asset_type_id': {
        label: 'asset_type',
        type: 'relate',
        module: 'asset_types',
        observable: new Observable<Select[]>(),
        typehead: new Subject<string>(),
        loader: false,
      }
    }
  };

  /**
   * List of field filters
   *
   * @var string[]
   */
  public fieldList: string[] = [];

  /**
   * Checks if the type list has values.
   *
   * @return {boolean}
   */
  get hasTypeList() {
    if (Object.keys(this.fieldTypes).length !== 0) {
      return true;
    }
    return false;
  };

  constructor(
    private recordService: RecordService,
  ) {

  }

  /**
   * Initialization
   *
   * @returns {void}
   */
  ngOnInit(): void {
    if (this.moduleFilterLists[this.currentModule]) {
      this.moduleFilter = this.moduleFilterLists[this.currentModule];
      Object.keys(this.moduleFilter).forEach( field => { this.fieldList.push(field)});
      this.generateForm(this.currentFilter);
    }
  }

  /**
   * Creates the quick filter form
   * If we have current filter, patch the existing filter on the form
   *
   * @param filter
   * @returns {void}
   */
  generateForm(filter = {}): void {
    let fieldFormControl: {} = {};
    // Create form control on each field
    this.fieldList.forEach( field => {
      fieldFormControl[field] = new FormControl(null)
    });
    // Create form group
    this.objQuickSearchForm = new FormGroup(fieldFormControl);
    // Do have filter?
    if (filter) {
      // Patch each existing field
      this.fieldList.forEach( field => {
        if (filter[field] !== undefined) {
          this.objQuickSearchForm.patchValue({
            [field]: filter[field] || null
          });
        } else if (filter['quick_search'] !== undefined && filter['quick_search'][field] !== undefined) {
          this.objQuickSearchForm.patchValue({
            [field]: filter[field] || null
          });
        }
      });
    }
  }

  /**
   * Creates the request data that we need to send on api
   *
   * @returns {void}
   */
  generateFilter(): void {
    let filterValues = this.objQuickSearchForm.getRawValue();
    Object.entries(filterValues).forEach(([key, value]) => {
      if (typeof value === 'number' && this.moduleFilterLists[this.currentModule][key]['array'] === true) {
        filterValues[key] = [value];
      }
      else if ((value && typeof value === 'object') || !value) {
        filterValues[key] = "";
      }
    });
    this.quickFilter.emit(filterValues);
  }

  /**
   * This will initialize the relate field
   * and get the record base on the value
   * of the form.
   */
  initializeRelateField() {
    Object.keys(this.moduleFilterLists[this.currentModule]).forEach(field => {
      let fieldValue = this.objQuickSearchForm.controls[field].value;
      if (this.moduleFilterLists[this.currentModule][field]['type'] === 'relate' && fieldValue) {
        let module = this.moduleFilterLists[this.currentModule][field]['module'];
        let moduleFilter = { [`${module}.id`] : fieldValue};
        this.getRelateData(field, module, moduleFilter);
      }
    });
  }

  /**
   * This will trigger every change in the parent variable.
   *
   * @return {void}
   */
  ngOnChanges(): void {
    if (this.objQuickSearchForm) {
      let updateForm = {};
      this.fieldList.forEach(field => {
        if (this.currentFilter['quick_search'] && this.currentFilter['quick_search'][field]) {
          updateForm[field] = this.currentFilter['quick_search'][field];
        } else if (this.currentFilter[field]) {
          updateForm[field] = this.currentFilter[field];
        }
      })
      this.objQuickSearchForm.patchValue(updateForm);
      this.initializeRelateField();
    }
  }

  /**
   * Reset the quick search form
   *
   * @returns {void}
   */
  resetForm(): void {
    // FC-2050: If quick search form is initialized
    if (this.objQuickSearchForm !== undefined) {
      this.objQuickSearchForm.reset();
    }
  }

  /**
   * This will get the data of related
   * field and initialize the relate
   * field of the form.
   * @param strField
   * @param strModule
   * @param moduleFilter
   */
  getRelateData(strField: string, strModule: string, moduleFilter: {} = {}) {
    this.moduleFilterLists[this.currentModule][strField]['loader'] = true;
    this.recordService.getRecordRelate(strModule, '', false, false, moduleFilter).subscribe( result => {
      this.initRelateField(strField, strModule, result);
      this.moduleFilterLists[this.currentModule][strField]['loader'] = false;
    });
  }

  /**
   * This will the observable of the
   * relate field
   * @param strField
   * @param strRelateModule
   * @param arRelateInitialValue
   */
  initRelateField(strField: string, strRelateModule: string, arRelateInitialValue: Array<Object> = []) {
    this.moduleFilterLists[this.currentModule][strField]['observable'] = concat(
      of(arRelateInitialValue),
      this.moduleFilterLists[this.currentModule][strField]['typehead'].pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.moduleFilterLists[this.currentModule][strField]['loader'] = true),
        switchMap(term => this.recordService.getRecordRelate(strRelateModule, term, '', false, false).pipe(
          tap(() => {
            this.moduleFilterLists[this.currentModule][strField]['loader'] = false;
          })
        ))
      )
    )
  }
}