import { Component, OnInit, Inject } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

import { DomSanitizer } from '@angular/platform-browser';
import { LambdaService } from '../../../../services/lambda.service';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { DOCUMENT } from '@angular/common';
import { EmailComponent } from '../../../../shared/components/widget/email/email.component';
import { RecordService } from '../../../../services/record.service';
import { ViewService } from '../../../../services/view.service';

@Component({
  selector: 'app-preview-quote',
  templateUrl: './preview-quote.component.html',
  styleUrls: ['./preview-quote.component.scss']
})
export class PreviewQuoteComponent implements OnInit {

  public showPreview = false;
  public showEmail = false;

  constructor(
    public dialogRef: MatDialogRef<PreviewQuoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document: any,
    private sanitizer: DomSanitizer,
    public widgetService: WidgetService,
    public recordService: RecordService,
    public viewService: ViewService,
    public lambdaService: LambdaService,
    private dialog: MatDialog,) { }
    public strUrl;

  ngOnInit() {
    this.lambdaService.getPreviewUrl(this.data['quote_preview']).subscribe(response => {
      // Pass the URL to the IFrame
      this.strUrl = response['url'];
    })
  }

  callBackFn(pdf: PDFDocumentProxy) {
    this.showPreview = true;
  }

  downloadPdf() {
    this.lambdaService.getDownloadUrl({ name: this.data['quote_preview']['temporary_name'] }).subscribe(response => {
      if(response) {
        // Redirect to that value
        this.document.location.href = response['url'];
      }
    })
  }

  sendQuote() {

    // Show loader on button.
    this.showEmail = true;

    // Get the contact id of the opportunity.
    let strContactId = this.viewService.getRecord()['record_details']['contact_id'];

    // If there is a contact id.
    if (strContactId != null && strContactId != '') {
      // Get the contact record. (To get the email of the connected contact.)
      this.recordService.getRecord('contacts', strContactId).subscribe(objContact => {

        // Initialize a variable for email list.
        let arEmail = [];

        // Loop through available emails.
        objContact['record_details']['email_address'].forEach(email => {

          // Create email dropdown
          arEmail.push({ text: objContact['record_details']['text'], 'email_address': email });

        });

        // Show the email pop up.
        this.showEmailDialog(arEmail);
      })
    } else {
      // Show email pop up if no contact conected.
      this.showEmailDialog();
    }

  }

  showEmailDialog(arEmail = null) {

    //Get the email attachment from the preview.
    this.lambdaService.getEmailAttachment({ name: this.data['quote_preview']['temporary_name'] }).subscribe(response => {
      if(response) {
        // Hide loader.
        this.showEmail = false;
        // Set the data we need for the email pop up.
        let objEmailData = {
          width: '66%',
          height: 'auto',
          // Data to be passed onto the dialog.
          data: {
            "module" : "opportunities",
            "record_id" : this.data['record_id'],
            "view_type" : "quote",
            "is_draft": false,
            "quote_data": {
              subject: this.data['quote_preview']['quote_summary'],
              attachment: response['data'],
              send_to: arEmail
            }
          },
          disableClose: true
        };

        // Close all previous opened dialogs.
        this.dialog.closeAll();

        // Open the dialog.
        let tabDialogRef = this.dialog.open(EmailComponent, objEmailData);
      }
    });
  }

  /**
	 * Close the current dialog.
	 */
	closeDialog() {
		this.dialogRef.close();
	}

}
