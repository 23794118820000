import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArrService {

  constructor() { }

  /**
   * Check if the given key is set and not empty
   *
   * @param objData
   * @param strKey
   * @param fallBack
   */
  keyFallsBackTo<T>(objData: Array<[]> | Object, strKey: string = '', fallBack: T = null): T {
    return (objData !== undefined && objData !== null && objData !== '' && objData[strKey] !== undefined && objData[strKey] !== null && objData[strKey] !== '') ? objData[strKey] : fallBack;
  }

  /**
   * This will compile all unique value in an array
   *
   * @param array
   * @param strKey
   * @returns
   */
  getUniqueValues(array: Array<object>, strKey: string) {
    var arResult: Array<string> = [];
    array.forEach(function(item) {
        if (item.hasOwnProperty(strKey) && !arResult.includes(item[strKey])) {
          arResult.push(item[strKey]);
        }
    });

    return arResult;
    }
}
