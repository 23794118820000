import { Form } from './form';
import {Select} from '../objects/select';

export class Datetime extends Form<string> {
  controlType = 'datetime';
  date: string;
  minute: string;
  hour: string;
  meridiem: string;

  constructor(options: {} = {}) {
    super(options);
    this.minute = options['minute'] || '';
    this.hour = options['hour'] || '';
    this.meridiem = options['meridiem'] || '';
    this.date = options['date'] || '';
  }
}