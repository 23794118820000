import { RelateData } from './relate-data';
import { TaskMetadata } from './task-metadata';

export interface JobMetadata {

  assigned_user: RelateData | null;

  department: RelateData | null;

  customer: RelateData | null;

  due_date: string

  full_address: string;

  id: string;

  job_number: string;

  tasks: Array<TaskMetadata> | null;

}

export class JobMetadataC {

  assigned_user: RelateData | null;

  department: RelateData | null;

  customer: RelateData | null;

  due_date: string

  full_address: string;

  id: string;

  job_number: string;

  tasks: Array<TaskMetadata> | null;

  metadata_type: string = 'job';

  constructor(properties: JobMetadata) {
    this.id = properties.id;
    this.job_number = properties.job_number;
    this.full_address = properties.full_address;
    this.due_date = properties.due_date;


    this.assigned_user = properties.assigned_user || null;
    this.department = properties.department || null;
    this.customer = properties.customer || null;
    this.tasks = properties.tasks || null;
  }
}