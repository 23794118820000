import { EventApi } from '@fullcalendar/core';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { LocalStorageService } from '../../../../services/local-storage.service';
import { NotificationService } from '../../../../services/notification.service';
import { CalendarService } from '../../services/calendar.service';
import { ClientStoreService } from '../../../../services/client-store.service';
import { CalendarApiService } from '../../services/calendar-api.service';
import { TaskMetadataC } from '../../full-calendar/classes/metadata/task-metadata';
import { LooseObject } from '../../../../objects/loose-object';

@Component({
  selector: 'app-task-unscheduling-confirmation-dialog',
  templateUrl: './task-unscheduling-confirmation-dialog.component.html',
  styleUrls: ['./task-unscheduling-confirmation-dialog.component.scss'],
  providers: [
    CalendarApiService
  ]
})
export class TaskUnschedulingConfirmationDialogComponent implements OnInit {
  /**
   * Store's the task details.
   *
   * @type {LooseObject}
   */
  public taskMetadata: LooseObject = {};

  /**
   * Store's the record's details.
   *
   * @type {LooseObject}
   */
  public recordDetails: LooseObject = {};

  /**
   * Flag to show loading icon when unscheduling a task
   *
   * @type {boolean}
   */
  public bShowLoader: boolean = false;

  constructor(
    public clientStoreService: ClientStoreService,
    protected dialogRef: MatDialogRef<TaskUnschedulingConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected notificationService: NotificationService,
    protected localStorageService: LocalStorageService,
    protected calendarService: CalendarService,
    protected calendarServiceApi: CalendarApiService
  ) {
    this.taskMetadata = this.calendarService.getTaskMetadata(this.data.fcEvent);
    this.recordDetails = this.data.fcEvent.record_details;
  }

  ngOnInit() {
  }

  /**
   * The user has decided to cancel the unscheduling of a task.
   * We'll now have to do nothing but to close the dialog.
   *
   * @returns {void}
   */
  cancelTaskUnscheduling(): void {
    this.dialogRef.close(null);
  }

  /**
   * The unschedule a tast and send back metadata to
   * dialofRef for updating of calendar and task list
   *
   * @returns {void}
   */
  unscheduleTask(): void {
    this.dialogRef.disableClose = true;
    this.bShowLoader = true;

    const options = {
      ... (this.data.on_behalf_of_client && {
        for_child_client_id: this.data.on_behalf_of_client,
      }),
    };

    this.calendarServiceApi.unscheduleTask(this.taskMetadata['id'], options).subscribe(task => {
      this.notificationService.notifySuccess('task_schedule_cancelled');

      // we would use the task data from the api response
      // when a parent client cancels its own task in the child client
      // system. This is necessary so we could append the task
      // to the list of task to be schedule just like the normal
      // unscheduling of task
      const data = (task['was_replaced']) ? task : this.getReturnData();

      this.dialogRef.close({
        action: 'unschedule',
        data: data,
      });
    }, (error: HttpErrorResponse) => {
      this.notificationService.notifyWarning('task_schedule_cancel_error');
      this.dialogRef.close(null);
    });
  }

  /**
   * Returns a formatted object of the unscheduled task for updating
   * of the calendar and the tasks for scheduling list.
   *
   * @returns {TaskMetadataC}
   */
  getReturnData(): TaskMetadataC {
    return {
      id: this.taskMetadata['id'],
      name: this.taskMetadata['activity_name'],
      status: "awaiting_scheduling",
      due_date: null,
      priority: this.taskMetadata['priority'],
      estimated_duration: 0,
      description: this.taskMetadata['notes'],
      job: this.data.fcEvent.module === 'jobs'
        ? this.calendarService.getTaskJobDetails(this.taskMetadata, this.recordDetails, this.data.fcEvent.tasks)
        : null,
      opportunity: this.data.fcEvent.module === 'opportunities'
        ? this.calendarService.getTaskOpportunityDetails(this.taskMetadata, this.recordDetails, this.data.fcEvent.tasks)
        : null,
      department: {
        id: this.taskMetadata['department_id'],
        name: this.taskMetadata['department_name'],
      },
      assigned_user: null,
      viewable: false,
      parent_id: this.taskMetadata['parent_id'],
      metadata_type: 'task',
      activity_date: null
    }
  }
}
