import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { merge } from 'rxjs';
import { DialogButtonAction } from './../../../../objects/dialog-button-action';
import { DialogButtonConfig } from './../../../../objects/dialog-button-config';

@Component({
  selector: 'dialog-button',
  templateUrl: './dialog-button.component.html',
  styleUrls: ['./dialog-button.component.scss']
})
export class DialogButtonComponent implements OnInit {
  _buttons: DialogButtonConfig[] = [];

  /**
   * list of button configuration
   *
   * Note: only sets the _buttons value
   */
  @Input('buttons')
  set buttons(arButtons: DialogButtonConfig[]) {
    this._buttons = arButtons.map((objButton) => ({
      ...objButton,
      disabled: (objButton.isProcessing && objButton.disabled)
        ? merge(objButton.disabled, objButton.isProcessing)
        : (objButton.disabled || objButton.isProcessing)
    }));
  }

  /**
   * to emit the data in parent component
   */
  @Output() action = new EventEmitter<DialogButtonAction>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * this will emit the data
   * when there is changes made
   *
   * @param event
   */
  ngOnChangeButton(event, callback: string): void {
    this.action.next({
      event: event,
      callback: callback,
    });
  }
}
