import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty, get } from 'lodash';
import * as _moment from 'moment';

import { Select } from '../../../../objects/select';
import { CalendarService } from '../../services/calendar.service';
import { CalendarApiService } from '../../services/calendar-api.service';
import { RecordService } from '../../../../services/record.service';
import { NotificationService } from '../../../../services/notification.service';
import { switchMap } from 'rxjs/operators';
import { LooseObject } from '../../../../objects/loose-object';
import { Relate } from '../../../../objects/relate';
import { TaskMetadata } from '../../full-calendar/classes/metadata/task-metadata';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-task-duplicate-dialog',
  templateUrl: './task-duplicate-dialog.component.html',
  styleUrls: ['./task-duplicate-dialog.component.scss'],
  providers: [
    CalendarApiService
  ]
})
export class TaskDuplicateDialog implements OnInit {

  /**
   * Store's the task details.
   *
   * @type {LooseObject}
   */
  public taskMetadata: LooseObject = {};

  /**
   * Task duplciate form group.
   *
   * @type {Object}
   */
  public taskDuplicateForm: FormGroup;

  /**
   * Loading flag.
   *
   * @type {Object}
   */
  public isLoading: boolean = false;

  /**
   * Stores the record's details.
   *
   * @type {LooseObject}
   */
  public recordDetails: LooseObject = {};

  /**
   * Duplicate metadata.
   *
   * @type {object}
   */
  public duplicateTaskMetadata: object = {
    number_of_copies: {
      key: 'number_of_copies',
      type: 'dropdown',
      label: 'number_of_copies_for_task',
      required: true,
      options: [
        { id: 1, text: '1' },
        { id: 2, text: '2' },
        { id: 3, text: '3' },
        { id: 4, text: '4' },
        { id: 5, text: '5' },
        { id: 6, text: '6' },
        { id: 7, text: '7' },
        { id: 8, text: '8' },
        { id: 9, text: '9' },
        { id: 10, text: '10' }
      ],
    },
    new_task_on: {
      key: 'new_task_on',
      type: 'dropdown',
      label: 'place_new_task_on',
      required: true,
      options: [
        { id: 'same_day', text: 'same_day' },
        { id: 'consecutive_days', text: 'consecutive_days' },
        { id: 'consecutive_weeks', text: 'consecutive_weeks' },
        { id: 'consecutive_months', text: 'consecutive_months' }
      ],
    },
    task_duration: {
      key: 'task_duration',
      type: 'number',
      label: 'task_duration',
      required: true,
      name: ''
    },
  };

  /**
   * Invalid data indicator for technician field.
   *
   * @type {object}
   */
  public isTechnicianValid: boolean = true;

  /**
   * Config for technician relate field
   *
   * @type {Object}
   */
  public objRelateConfig: {label: string, relate: Relate<Select> | null};

  /**
   * Relate options based on the view.
   *
   * @type {Object}
   */
  public objRelateOptions = {
    teams: {
      filter: null,
      label: 'team'
    },
    users: {
      filter: {'access_levels.enable_scheduling': true},
      label: 'technician'
    }
  };

  /**
   * Get the assigned id.
   *
   * @returns {string | null}
   */
  get assigned_id(): string | null {
    return isEmpty(this.objUserData) ? null : this.objUserData['id'];
  }

  /**
   * Get the field to update based
   * on the view.
   *
   * @returns {string}
   */
  get assigned_field() {
    return this.data.view == 'users' ? 'user_id' : 'team_id';
  }

  /**
   * Contains data for selected technician
   *
   * @type {Object}
   */
  public objUserData: object;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    protected recordService: RecordService,
    protected calendarService: CalendarService,
    protected calendarServiceApi: CalendarApiService,
    protected notificationService: NotificationService,
    protected dialogRef: MatDialogRef<TaskDuplicateDialog>
  ) {
    this.taskMetadata = this.calendarService.getTaskMetadata(this.data.fcEvent);
    this.recordDetails = this.data.fcEvent.record_details;

    this.taskDuplicateForm = new FormGroup({
      'number_of_copies': new FormControl(1, Validators.required),
      'new_task_on': new FormControl('same_day', Validators.required),
      'task_duration': new FormControl('1.000', Validators.required)
    });

    this.setRelate();
  }

  ngOnInit() {}

  /**
   * Create and update the tasks
   *
   * @returns {void}
   */
  saveTask(): void {
    this.isLoading = true;
    let taskList = this.compileTask();
    this.checkValidTechnician(this.objUserData);

    if (this.taskDuplicateForm.valid && taskList.length && this.objUserData) {
      this.createTask(taskList);
    } else {
      this.isLoading = false;
      this.notificationService.notifyError('please_complete_the_form');
    }
  }

  /**
   * Create task
   *
   * @param taskList
   *
   * @returns {void}
   */
  createTask(taskList): void {
    let numEstimatedDuration: number = taskList[0].estimated_duration;
    let strDueDates: string[] = taskList.map(task => task.due_date);

    this.calendarServiceApi.duplicateTask(
      this.taskMetadata['id'],
      numEstimatedDuration,
      strDueDates,
      this.assigned_field,
      this.assigned_id
    ).subscribe(arResponse => {
      let arDuplicatedEvents = [];
      arResponse['items'].forEach((objNewId, intIndex) => {
        let objDuplicatedTask = this.getReturnData(objNewId.id, numEstimatedDuration, strDueDates[intIndex], this.assigned_id);
        arDuplicatedEvents.push(objDuplicatedTask);
      });

      this.notificationService.notifySuccess('task_duplicated');
      this.closeDialog({
        action: 'duplicate',
        data: arDuplicatedEvents,
        user: { id: this.assigned_id },
      });
    }, (error: HttpErrorResponse) => {
      this.notificationService.notifyWarning('task_duplicate_error')
      this.dialogRef.disableClose = false;
      this.isLoading = false;
    });
  }

  /**
   * Create a set of task record to save
   *
   * @returns {Array<object>}
   */
  compileTask(): Array<object> {
    let numSubmittedDuration = Number(this.taskDuplicateForm.controls['task_duration'].value)
    if (numSubmittedDuration < 0) {
      this.taskDuplicateForm.patchValue({ task_duration: '1.000' });
    }

    let compileTask = [];
    if (this.taskDuplicateForm.valid) {
      for(let numberOfthisRecord = 1; numberOfthisRecord <= this.taskDuplicateForm.controls['number_of_copies'].value; numberOfthisRecord++) {

        var activityDueDate: string = this.addDate(this.taskMetadata['due_date'], numberOfthisRecord);
        var activityDate: string = this.addDate(this.taskMetadata['activity_date'], numberOfthisRecord);
        var estimatedDuration = parseFloat(this.taskDuplicateForm.controls['task_duration'].value);

        compileTask.push({
          activity_name: this.taskMetadata['activity_name'],
          estimated_duration: estimatedDuration,
          due_date: activityDueDate,
          activity_date: activityDate,
          priority: this.taskMetadata['task_priority'],
          notes: this.taskMetadata['notes'],
          task_progress: this.taskMetadata['task_progress'],
          site_id: this.taskMetadata['site_id'],
          department_id: this.taskMetadata['department_id'],
          [this.assigned_field]: this.assigned_id
        })
      }
    }
    return compileTask;
  }

  /**
   * Add days, weeks or months on given datetime
   *
   * @param {string} date
   * @param {number} additional
   *
   * @returns {string}
   */
  addDate(date: string, additional: number): string {
    switch (this.taskDuplicateForm.controls['new_task_on'].value) {
      case 'consecutive_days':
        return moment.utc(date).add(additional, 'days').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

      case 'consecutive_weeks':
        return moment.utc(date).add(additional, 'weeks').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

      case 'consecutive_months':
        return moment.utc(date).add(additional, 'months').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

      default:
        return moment.utc(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    }
  }

  /**
   * Add 3 decimal places on task duration field
   *
   * @returns {void}
   */
  formatTaskDuration(event): void {
    this.taskDuplicateForm.patchValue({
      task_duration: Number(event.target.value).toFixed(3)
    });
  }

  /**
   * Checks if technician is valid and displays indicator
   *
   * @returns {void}
   */
  checkValidTechnician(event): void {
    if (event) {
      this.isTechnicianValid = true;
    } else {
      this.isTechnicianValid = false;
    }
  }

  /**
   * Returns a formatted object of the duplicated task for updating
   * of the calendar and the tasks for scheduling list.
   *
   * @param {string} strDuplicatedTaskId
   * @param {string} numEstimatedDuration
   * @param {string} strDueDate
   * @param {string} strUserId
   *
   * @returns {TaskMetadata}
   */
  getReturnData(
    strDuplicatedTaskId: string,
    numEstimatedDuration: number,
    strDueDate: string,
    strUserId: string
  ): TaskMetadata {
    return {
      id: strDuplicatedTaskId,
      name: this.taskMetadata['activity_name'],
      status: "scheduled",
      due_date: strDueDate,
      priority: this.taskMetadata['priority'],
      estimated_duration: numEstimatedDuration,
      description: this.taskMetadata['notes'],
      job: this.data.fcEvent.module === "jobs"
        ? this.calendarService.getTaskJobDetails(this.taskMetadata, this.recordDetails, this.data.fcEvent.tasks)
        : null,
      opportunity: this.data.fcEvent.module === "opportunities"
        ? this.calendarService.getTaskOpportunityDetails(this.taskMetadata, this.recordDetails, this.data.fcEvent.tasks)
        : null,
      department: {
        id: this.taskMetadata['department_id'],
        name: this.taskMetadata['department_name'],
      },
      assigned_user: {
        id: strUserId,
        name: get(this.objUserData, 'text', '')
      },
      viewable: true,
      reschedulable: true,
      activity_date: strDueDate,
    }
  }

  /**
   * Close the dialog
   *
   * @returns {void}
   */
  closeDialog(task = null): void {
    this.dialogRef.close(task);
  }

  /**
   * Set the relate values of the
   * ng-select.
   *
   * @returns {void}
   */
  private setRelate(): void {

    this.objRelateConfig = {
      relate: new Relate<Select>().buildRelates(
        switchMap(term => this.recordService.getRecordRelate(
          this.data.view,
          term,
          '',
          false,
          this.objRelateOptions[this.data.view].filter
        ))
      ),
      label: this.objRelateOptions[this.data.view].label
    };

  }
}
