<header >
  <div class="d-flex dialog-header">
      <h3 class="dialog-title" translate>
          <fa-icon [icon]="['far', 'tasks']" transform="shrink-3" class="dialog-title"></fa-icon>
          <span class="pl-1">{{ 'available_checklists' | translate }}</span>
      </h3>
      <div class="ml-auto d-flex">
          <ul class="list-inline pull-right">
              <li class="list-inline-item">
                  <button type="button" (click)="exitDialog()" class="btn btn-primary">
                      <fa-icon [icon]="['fas', 'times']"></fa-icon>
                      <span class="pl-1">{{ 'close' | translate }}</span>
                  </button>
              </li>
              <li class="list-inline-item">
                <button type="button" (click)="attachSelectedChecklists()" class="btn btn-primary">
                    <button-icon-loader [defaultIcon]="['fas', 'paperclip']" [isLoading]="isFormSubmitted"></button-icon-loader>
                    <span class="pl-1">{{ 'attach_selected' | translate }}</span>
                </button>
            </li>
          </ul>
      </div>
  </div>
  <hr />
</header>
<mat-dialog-content class="content">
    <div>
      <div class="row">
        <div class="col-11 checklist-title"> {{ 'name' | translate}} </div>
        <div class="col-1">
          <span>
            <input type="checkbox" [checked]="true" (click)="selectAllAttachments($event)">
          </span>
        </div>
      </div>

      <div class="row" *ngFor="let assetTypechecklist of assetTypeChecklistResponses; index as i">
        <div class="col-11 default-value"> {{ assetTypechecklist['checklist_name'] }} - #{{ assetTypechecklist['serial_number'] }} </div>
        <div class="col-1">
          <input type="checkbox"
            [checked]="assetTypechecklist['selected']"
            (click)="selectChecklist($event, 'asset_type_checklist', i)">
        </div>
      </div>

      <div class="row" *ngFor="let checklist of jobChecklistResponses; index as i">
        <div class="col-11 default-value"> {{ checklist['name'] }} </div>
        <div class="col-1">
          <input
            type="checkbox"
            [checked]="checklist['selected']"
            (click)="selectChecklist($event, 'job_checklist', i)"/>
        </div>
      </div>
    </div>
</mat-dialog-content>