import { Select } from '../../../objects/select';

/**
 * Available ratings options for risk
 *
 * [0] => 'likelihood'
 * [1] => 'consequences'
 *
 * @var {Select[][]}
 */
export const RATINGS_OPTIONS  = [
  [
    new Select(1, 'rare'),
    new Select(2, 'unlikely'),
    new Select(3, 'possible'),
    new Select(4, 'likely'),
    new Select(5, 'almost_certain'),
  ],
  [
    new Select(1, 'insignificant'),
    new Select(2, 'minor'),
    new Select(3, 'moderate'),
    new Select(4, 'major'),
    new Select(5, 'catastrophic'),
  ]
];
