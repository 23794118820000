import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable()
export class MimeTypeValidator {
  /**
   * Assumes that the given control is a file then verifies that
   * it's type is set to "text/csv"
   *
   * @param {AbstractControl} control
   *
   * @returns {ValidationErrors | null}
   */
  csv = (control: AbstractControl): ValidationErrors | null => {
    let file = control.value;

    return this.isFile(file) && this.hasCsvMimeType(file) ? null : this.makeErrorResponse('csv', 'The input must be a valid CSV file.');
  }

  /**
   * Verifies whether the given subject is a file.
   *
   * @param {any} subject
   *
   * @returns {Boolean}
   */
  protected isFile = (subject): boolean => {
    return subject instanceof File;
  }

  /**
   * Checks if the given file is a valid CSV file.
   *
   * @see {@link https://stackoverflow.com/a/11834872/8449155} For a list of
   * acceptable "csv" file mime types.
   *
   * @param {File} file
   *
   * @returns {Boolean}
   */
  protected hasCsvMimeType = (file: File): boolean => {
    return [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ].indexOf(file.type) !== -1;
  }

  /**
   * Constructs a validation error object for the given
   * validator.
   *
   * @param validator
   * @param message
   *
   * @returns {Object}
   */
  protected makeErrorResponse = (validator: string, message: string) => {
    let response = {};

    response[validator] = {
      valid: false,
      message: message
    };

    return response;
  }
}
