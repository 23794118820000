import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FormService } from '../../../../../services/form.service';
import { MatDialog } from '@angular/material';
import { GoogleMapComponent } from './google-map/google-map.component';

type OnChangeHandler = (value?: string) => void;
type OnTouchedHandler = () => void;

@Component({
  selector: 'fc-geolocation-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeolocationComponent),
      multi: true,
    }
  ],
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss'],
})
export class GeolocationComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  // Need to have an event emitter for this to be able to manage the value of this
  @Output() childToParentEvent: EventEmitter<GeolocationData> = new EventEmitter();
  @ViewChild('searchInput') searchElementRef: ElementRef;

  constructor(
    public dialog: MatDialog,
    public formService: FormService,
  ) { }

  /**
   * Dictates if the field should be disabled
   */
  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Contains the value inputted
   */
  value: GeolocationData;

  /**
   * Contains geolocation latitude
   * to be use in google map
   */
  latitude: number;

  /**
   * Contains geolocation longitude
   * to be use in google map
   */
  longitude: number;

  /**
   * Contains address name
   */
  address_name: string;

  /**
   * Containts the geolocation data from parent component
   * most likely this will be supplied when you want to
   * have a default geolocation like for update data
   */
  @Input() geolocationData: GeolocationData;

  /**
   * This will enable the function of prompting dialog
   * to show the map to auto fill the location base
   * on your selected map marker
   */
  @Input('withShowMap') withShowMap: boolean = true;

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => {};

  /**
   * Callback when input value was changed
   */
  onChange: OnChangeHandler = () => {};

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.geolocationData) {
      this.value = this.geolocationData;
      this.latitude = this.geolocationData.latitude;
      this.longitude = this.geolocationData.longitude;
    }
  }

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    this.onChange = (value?: any) => {
      fn(
        this.value = value
      );
    };
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: any): void {
    this.value = value;
  }

  openMap() {
    let tabDialogRef = this.dialog.open(GoogleMapComponent, {
      width: '1100px',
      height: '900px',
      data: {
        address_name: null,
        latitude: this.latitude,
        longitude: this.longitude,
      },
      disableClose: true
    });

    tabDialogRef.afterClosed().first().subscribe(result => {
      if (result) {
        this.latitude = result.latitude;
        this.longitude = result.longitude;

        let googleMapData: GeolocationData = {
          address_name: result.address_name,
          latitude: result.latitude,
          longitude: result.longitude,
        };

        this.value = googleMapData;

        this.geolocationData = googleMapData;
        this.childToParentEvent.emit(googleMapData);
      }
    });
  }

  /**
   * When geolocation change it should send
   * to parent component to get the data.
   */
  geolocationChange() {
    let googleMapData: GeolocationData = {
      address_name: this.value.address_name,
      latitude: this.latitude,
      longitude: this.longitude,
    };

    this.childToParentEvent.emit(googleMapData);
  }
}

export interface GeolocationData {
  address_name: string,
  latitude: number,
  longitude: number,
}