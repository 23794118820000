import { Select } from '../objects/select';

export class DateSelectList {
  list: Select[] = [
    new Select('yesterday','date_presets.yesterday'),
    new Select('today','date_presets.today'),
    new Select('tomorrow','date_presets.tomorrow'),
    new Select('next_week','date_presets.next_week'),
    new Select('next_monday','date_presets.next_monday'),
    new Select('next_friday','date_presets.next_friday'),
    new Select('two_weeks','date_presets.two_weeks'),
    new Select('next_month','date_presets.next_month'),
    new Select('first_day_month','date_presets.first_day_month'),
    new Select('three_months','date_presets.three_months'),
    new Select('six_months','date_presets.six_months'),
    new Select('next_year','date_presets.next_year'),
  ];
}