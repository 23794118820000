import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddQuoteComponent } from './add-quote/add-quote.component';
import { ListingService } from '../../../services/listing.service';
import { cloneDeep } from 'lodash';
import { NotificationService } from '../../../services/notification.service';
import { RecordService } from '../../../services/record.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfComponent } from '../../../shared/components/view/pdf/pdf.component';
import * as _ from 'lodash';
import { ViewService } from '../../../services/view.service';
import { LooseObject } from '../../../objects/loose-object';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { get } from 'lodash';
import { toFormattedNumber } from '../../../shared/utils/numbers';
import { blank, filled } from '../../../shared/utils/common';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss'],
  providers: [ListingService]
})
export class QuotesComponent implements OnInit, OnChanges {

  @Input() strRecordId: string;
  @Input() strModule: string;

  public arQuotes = [];
  public arDropdown = {};
  public arPreviousPages = {};
  public bLoading = false;
  public bHasDialogOpened = false;

  /**
   * Subject to wait for any requests
   * to refresh the current quote view or
   * the record view.
   *
   * @var {Subject}
   */
  public refresh = new Subject<{list: boolean, view: boolean}>();

  constructor(
    private dialog: MatDialog,
    public listService: ListingService,
    private notificationService: NotificationService,
    private recordService: RecordService,
    private route: ActivatedRoute,
    private viewService: ViewService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(
      filter((params) => filled(get(params, 'id'))),
    ).subscribe((params) => {
      this.arQuotes = [];
      this.strRecordId = get(params, 'id');
      this.fetchList('default');
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const recordId = get(changes, 'strRecordId.currentValue', null);

    if (filled(recordId) && recordId !== get(changes, 'strRecordId.previousValue')) {
      this.bHasDialogOpened = false;
    }
  }

  /**
   * Option to duplicate quote.
   * @param quote
   */
  duplicateQuote(quote) {

    // Clone deep the quote so when we delete parts of the object...
    // the object in the list does not get deleted as well.
    let objQuote = cloneDeep(quote);

    // Ask first if they really want to duplicate the record.
    this.notificationService.sendConfirmation('duplicate_confirm', 'duplicate_confirm_header')
      .subscribe(confirmation => {
        // If the answer is yes.
        if (confirmation.answer) {
        // These will be autogenerated on creation, so let's remove this.
        let arRemove = [
          'created_type', 'modified_type', 'contact_text',
          'deleted_at', 'updated_at', 'created_at',
          'quote_number', 'text', 'id',
          'created_by', 'modified_by', 'external_id',
          'amount_tax_inc', 'user_text', 'created_by_name',
          'modified_by_name', 'is_primary'
        ];

        // Remove empty and autogenerated values.
        Object.keys(quote).forEach(element => {
          if (arRemove.indexOf(element) != -1 || objQuote[element] == '') {
            delete objQuote[element];
          }
        });

        this.saveQuote(objQuote).subscribe(response => {
          this.fetchList('default', {
            type: 'edit_dialog',
            quote_number: response['quote_number']
          });
        });

      }
    });

  }

  /**
   * Open the create quote dialog.
   *
   * @param strCase - If the create quote has additional case.
   */
  createQuote(opts: {
    case?: string,
    opportunity_id?: string,
  } = {}) {
    opts = Object.assign(opts, {
      opportunity_id: this.strRecordId,
      case: '',
    }, opts);

    if (blank(opts.opportunity_id)) {
      opts = Object.assign(opts, {
        opportunity_id: this.strRecordId,
      });
    }

    // Create the object to be passed inside the dialog.
    let objData = {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      padding: '1%',
      panelClass: 'quote-dialog',
      // The id of the opporunity, the quote's "parent".
      data: {
        record_id: opts.opportunity_id,
        default_config: [],
        options: this.arDropdown,
        view_type: 'add',
        case: opts.case,
      },
      disableClose: true
    };

    // Initialize the dialog.
    let tabDialogRef = this.dialog.open(AddQuoteComponent, objData);

    // When the dialog closes, reload the list.
    tabDialogRef.afterClosed().pipe(
      tap(() => this.bHasDialogOpened = false),
      tap(() => this.router.navigate([], {
        queryParams: {
          from: null,
        },
        queryParamsHandling: 'merge',
      })),
    ).subscribe(item => {
      if (item != undefined && item == 'save') {
        this.refresh.next({list: true, view: true});
      }
    })
  }

  /**
   * Opens the selected quote from the list.
   * @param objQuote - the quote data.
   */
  openQuote(objQuote) {
    // Object to be passed in the dialog.
    let objData = {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      padding: '1%',
      // Data to be passed on
      data: {
        // Put the opportunity_id and quote data to the dialog.
        record_id: this.strRecordId,
        options: this.arDropdown,
        default_config: [],
        quote: [],
        quote_id: objQuote['id'],
        view_type: 'edit',
        refresh: this.refresh
      },
      disableClose: true
    };

    // Instantiate dialog.
    let tabDialogRef = this.dialog.open(AddQuoteComponent, objData);

    // Reload quote list once the dialog is closed.
    tabDialogRef.afterClosed().pipe(
      tap(() => this.bHasDialogOpened = false),
      tap(() => this.router.navigate([], {
        queryParams: {
          open: null,
        },
        queryParamsHandling: 'merge',
      })),
    ).subscribe(item => {
      // Only when the user hits save will we reload the list.
      if (item != undefined && item == 'save') {
        this.refresh.next({list: true, view: true});
      }
    });
  }

  /**
   * Fetch the list of quote.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage, config: object = {}) {

    let objPagination = this.listService.beforeFetching(strPage);
    this.bLoading = true;

    // Get the list from API.
    this.listService.fetchData(JSON.stringify(objPagination['objPage']), 'quotes', JSON.stringify(
      {
        'opportunity_id': this.strRecordId,
        'is_template': false,
        'order_by': {
          id: 'created_at',
          sort: 'desc'
        },
      })).subscribe(response => {

      this.arQuotes = response['data'];
      this.arDropdown = response['option'];

      this.listService.afterFetching(response, strPage);
      this.bLoading = false;

      this.openQuoteDialogBasedOnRouteParams();

      if (config['type'] == 'edit_dialog') {
        let objCreatedQuote = this.arQuotes.find(data => data.quote_number == config['quote_number']);
        if (get(objCreatedQuote, 'id', null)) {
          this.openQuote(objCreatedQuote);
        }
      }
    });
  }

  addTax(price, tax) {

    if (price == null || price == '') {
      price = 0;
    }

    if (tax == null || tax == '') {
      tax = 0;
    }

    return toFormattedNumber((parseFloat(price) + parseFloat(tax)), {
      currency: true,
    });
  }

  /**
   * This will compute the profit of each line item
   *
   * @param lineItems
   * @returns number
   */
  computeProfit(lineItems: Array<object> = []): number {

    let totalProfit: number = 0;

    lineItems.forEach(line_item => {
      totalProfit += line_item['profit'];
    });

    return totalProfit;
  }

  /**
   * This will convert profit to percentage
   *
   * @param lineItems
   * @returns string|number
   */
  computeProfitPercentage(lineItems: Array<object> = []) {
    let totalProfit = this.computeProfit(lineItems);
    let totalCost: number = 0;

    lineItems.forEach(line_item => {
        totalCost += line_item['cost'];
    });

    let totalProfitPercentage = (totalProfit / totalCost) * 100;

    return !isNaN(totalProfitPercentage) && isFinite(totalProfitPercentage) ? totalProfitPercentage.toFixed(2) : 0;
  }

  /**
   * Preview PDF
   *
   * @param objQuote
   */
  openPDF(objQuote) {

    let objOppRecord = _.clone(this.viewService.getViewRecord());
    let objQuoteRecord = _.clone(objQuote);
    let dialogConfig: { [k: string]: any } = {
      data: {
        module: 'quotation',
        label: objQuote['text'],
        response: {
          file_name: objQuote['text'],
          module: 'quotation',
          record: _.merge(objQuoteRecord, objOppRecord)
        }
      },
      disableClose: true
    };

    if (window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.maxWidth = '100vw';
    } else {
      // display as pop up if not mobile
      dialogConfig.width = '80%';
      dialogConfig.height = '80%';
    }
    this.dialog.open(PdfComponent, dialogConfig);
  }

  /**
   * Opens the quote dialog depending on the params
   * passed.
   * - One is for opening a new quote upon converting a lead.
   * - The other is for when opening an existing quote. (Usually from a faulty asset.)
   *
   * This function also makes sure that auto opening of the quote dialog should be just once.
   */
  openQuoteDialogBasedOnRouteParams() {
    if (this.bHasDialogOpened) {
      return;
    }

    this.bHasDialogOpened = true;

    const { params, queries } = {
      params: this.route.snapshot.params,
      queries: this.route.snapshot.queryParams,
    };

    // Do we havefrom create_quote and no quotes?
    if (get(queries, 'from') == 'create_quote') {
      this.createQuote({
        case: 'from_convert',
        opportunity_id: get(params, 'id'),
      });
    }

    // Check if there is a quote to open.
    if (filled(get(queries, 'open'))) {
      this.openQuote({ id: get(queries, 'open') });
    }
  }

  /**
   * Sets the quote as primary.
   *
   * @param {LooseObject} objQuote
   *
   * @returns {void}
   */
  setAsPrimary(objQuote: LooseObject): void {
    this.notificationService.sendConfirmation('set_as_primary')
      .filter(confirmation => (confirmation.answer))
      .subscribe(() => {
        this.saveQuote({ is_primary: true }, objQuote.id).subscribe(() => {
          this.refresh.next({ list: true, view: true });
        });
      });
  }

  /**
   * Send the quote to the api for saving.
   *
   * @param {LooseObject} objQuote
   * @param {string} strQuoteId
   *
   * @returns {Observable<HttpResponse<LooseObject>>}
   */
  saveQuote(objQuote: LooseObject, strQuoteId: string = null): Observable<HttpResponse<LooseObject>> {
    return this.recordService.saveRecord('quotes', objQuote, strQuoteId).do(response => {
      if (response['status'] == 201 || response['status'] == 200) {
        if (response['status'] == 201) {
          this.notificationService.notifySuccess('record_duplicate_success');
        } else {
          this.notificationService.notifySuccess('successfully_set_as_primary');
        }
      } else {
        this.notificationService.notifyWarning('please_contact_admin_for_assistance');
      }
    });
  }

  /**
   * Deletes the selected quote.
   *
   * @param {LooseObject} quote
   * @returns {void}
   */
  deleteQuote(objQuote: LooseObject): void {
    this.notificationService.sendConfirmation('delete_quote_confirmation').pipe(
      take(1),
      filter(response => (response.answer)),
      switchMap(() => this.recordService.deleteRecord('quotes', objQuote.id)),
    ).subscribe(response => {
      if (response.status === 200) {
        this.notificationService.notifySuccess('record_delete_success');
        this.refresh.next({list: true, view: false});
      } else {
        this.notificationService.notifyError('record_delete_failed');
      }
    });
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.arQuotes = []; // clear list
    this.fetchList('default');
  }
}
