<header>
  <div class="d-flex dialog-header">
    <h3 class="task-title">
      <fa-icon [icon]="['fas', 'calendar']" class="mr-1"></fa-icon>
      <span>{{ strMode | translate }} {{ 'task' | translate }} - {{ taskMetadata['activity_name'] | truncate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button id="btnClose" type="button" class="btn btn-primary" (click)="closeDialog()" [disabled]="bSaving">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
              <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button id="btnSave" type="button" class="btn btn-primary" (click)="scheduleTask()" [disabled]="bSaving">
              <fa-icon *ngIf="!bSaving" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bSaving" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<div class="row">
  <div *ngFor="let data of fields" class="col-{{data.space}} mb-3">
    <ng-container>
      <app-edit-field [behalf-of-client-id]="onBehalfOfClientId" [item]="data" [form]="taskScheduleForm" [bSubmitted]="bSubmitted" [strMode]="strMode"
        [strModule]="data.module" (parentEvent)="setTechnicianText($event)"></app-edit-field>
    </ng-container>
  </div>
</div>