import moment from 'moment';

export class Stocktake implements StocktakeInterface {
    id: string;
    warehouse_id?: string;
    items: StocktakeItemInterface[]
    stocktake_date: string;

    get local_date() {
        return moment(moment.utc(this.stocktake_date).toDate()).local().format('YYYY-MM-DD HH:mm:ss');
    }

    constructor(properties?: StocktakeInterface) {
        this.id = properties.id;
        this.warehouse_id = properties.warehouse_id;
        this.items = properties.items;
        this.stocktake_date = properties.stocktake_date;
    }

}

export interface StocktakeInterface {
    id: string;
    warehouse_id?: string;
    items: StocktakeItemInterface[]
    stocktake_date: string;
}

export interface StocktakeItemInterface {
    name: string;
    item_id: string;
    quantity: number;
    code: string;
    location: string;
}