<ng-container *fcHasPermission="'contacts:create'; else createContactDisabled">
  <form [formGroup]="form">
    <div class="container-fluid">

      <div class="row">
        <div class="col-xs-6 col-md-6 form-group">
          <strong id="contactStepTitle">{{ 'create_or_search' | translate }} {{ 'contact' | translate }}</strong>
          <br />
          <small>{{ 'fill_in_contact_details' | translate }}</small>
        </div>
        <div class="col-xs-6 col-md-6 form-group">
          <a href="javascript:void(0)" id="btnSkipContact" class="text-primary float-right" (click)="skip()">
            <fa-icon [icon]="['fas', 'forward']"></fa-icon>&nbsp;{{ 'skip' | translate }}
          </a>
          <a *ngIf="objSelectedContact" id="btnResetContact" href="javascript:void(0)" class="text-danger float-right mr-3" (click)="clearForm()">
            <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>&nbsp;{{ 'reset' | translate }}
          </a>
        </div>
      </div>

      <div class="row">

        <div class="col-6 mb-3">
          <div>
            <label class="labels" for="first_name"> {{ 'first_name' | translate }}
              <required-tag [hasSpace]="false"></required-tag>
            </label>
          </div>

          <div class="contact-input">
            <input id="contactStepFirstName"
              formControlName="first_name"
              type="text"
              class="form-control font-size-11 input-height-40 input-padding"
              [ngClass]="{'is-invalid': form.controls['first_name'].invalid && form.dirty}"
              [matAutocomplete]="firstNameSearch"
              [matAutocompleteDisabled]="!firstNameSearch"/>
            <fa-icon [icon]="['fal', 'search']" class="contact-search-icon"></fa-icon>
            <fa-icon *ngIf="objContactSearch.loader"
              [icon]="['fal', 'spinner-third']"
              [spin]="true"
              class="contact-input-icon">
            </fa-icon>
          </div>
          <mat-autocomplete (onScrollEndEvent)="onEndScroll()" #firstNameSearch="matAutocomplete">
            <ng-container *ngIf="(objContactSearch.source | async) as searchResults">
              <mat-option *ngFor="let results of searchResults" class="search-class" [value]="results.name"
                (onSelectionChange)="prefillContactForm(results)">
                <div class="row">
                  <div class="col-12 result-name">{{
                    results.name }}</div>
                  <div class="col-6 result-module">{{ results.module | translate }}</div>
                  <div class="col-6 result-module"><span class="float-right">{{ 'match_score' | translate }}: {{
                      results.match_percentage | number:'1.0-0' }}%</span></div>
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>

        <div class="col-6 mb-3">
          <div>
            <label class="labels" for="last_name"> {{ 'last_name' | translate }}
              <required-tag [hasSpace]="false"></required-tag>
            </label>
          </div>
          <div class="contact-input">
            <input id="contactStepLastName"
              formControlName="last_name"
              type="text"
              class="form-control font-size-11 input-height-40"
              [ngClass]="{'is-invalid': form.controls['last_name'].invalid && form.dirty}" />
          </div>
        </div>

        <div *ngFor="let data of arFields" class="col-{{data.space}} mb-3">
          <ng-container
            *ngIf="(
              data.label != 'created_by' &&
              data.label != 'modified_by' &&
              data.label != 'updated_at' &&
              data.label != 'created_at'
            )">
            <app-edit-field [item]="data" [form]="form" [bSubmitted]="bSubmitted" [strMode]="strMode"
              [strModule]="strModule"></app-edit-field>
          </ng-container>
        </div>

        <!-- Special container for phone -->
        <div class="col-{{objPhoneField.space}} mb-3">
          <ng-container>
            <app-phone [form]="form" [value]="objPhoneFieldValue" [key]="objPhoneField.key" [label]="objPhoneField.label">
            </app-phone>
          </ng-container>
        </div>

      </div>

      <!-- Linked Customers -->
      <div class="row mt-2 mb-4" *ngIf="form.valid">
        <div class="col-xs-12 col-md-12  d-block">
          <div class="border">
            <div *ngIf="bIsLinked" class="row p-3">
              <div class="col-xs-12 col-md-12">
                <small>
                  <fa-icon [icon]="['fas', 'check-circle']" class="text-success mr-2"></fa-icon>
                  <i>{{ 'contact_linked' | translate }}</i>
                </small>
              </div>
            </div>
            <div *ngIf="!bIsLinked" class="row p-3">
              <div class="col-xs-12 col-md-12 mb-3">
                <small>
                  <fa-icon [icon]="['fas', 'times-circle']" class="text-danger mr-2"></fa-icon>
                  <i>{{ 'contact_link_to_customer' | translate }}</i>
                </small>
              </div>
              <div class="col-xs-12 col-md-12">
                <div class="row">
                  <div class="col-xs-12 col-md-12">
                    <ng-select [items]="arRoles"
                      formControlName='roles'
                      placeholder="{{ 'select_roles' | translate }}"
                      bindLabel="text"
                      [multiple]="true"
                      appendTo="body"
                      (change)="onRoleChange($event)">

                      <ng-template ng-label-tmp let-item="item" let-clear="clear" class="fs-12">
                        <span id="setAsPrimaryRole_{{ item.id }}{{ i }}" class="pl-1" [ngClass]="{'pointer': item.primary != true}" (click)="setPrimaryRole(item.id, objForm)" aria-hidden="true">{{ item.text | translate }}</span>
                        <span *ngIf="item.primary == true" id="primaryRole{{ i }}" class="ml-1 badge badge-success">{{ 'primary' | translate }}</span>
                        <span id="removeRole_{{ item.id }}{{ i }}" class="pl-1 pr-1 pointer" (click)="clear(item)" aria-hidden="true">×</span>
                      </ng-template>

                      <ng-template ng-option-tmp let-item="item" class="pointer">{{ item.text | translate }}</ng-template>

                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Stepper Next -->
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <button id="contactStepNext" type="button" class="btn btn-primary btn-block btn-lg p-2" (click)="next()"
            [disabled]="!form.valid">
            <strong>{{ 'next' | translate }}</strong>
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #createContactDisabled>
  <span id="widgetContactsCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
  </span>
</ng-template>