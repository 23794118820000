<header >
  <div class="d-flex dialog-header">
      <div class="dialog-title">
          {{ 'add_checklist' | translate }}
      </div>

      <div class="ml-auto d-flex">
        <ul class="list-inline pull-right">
          <li class="list-inline-item">
            <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary" [disabled]="bShowLoader">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
              <span class="pl-1">{{ 'cancel' | translate }}</span>
            </button>
          </li>
          <li class="list-inline-item">
            <button (click)="onSubmit()" [disabled]="bShowLoader" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader)? 'btn-spinner' : ''">
              <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'save']"></fa-icon>
              <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
              <span class="pl-1">{{ 'save' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
  </div>
  <hr />
</header>

<div>
    <div class="row">
        <div class="col-12 rows">
            <span class="labels">{{ 'checklist_name' | translate }}</span>
            <fc-text-input
              [(ngModel)]="checklistName"
              (ngModelChange)="markAsEdited()"
              id="checklistName"
              [customClass]="'form-control font-size-12 input-height-40'">
            </fc-text-input>
        </div>
    </div>
    <div class="row">
        <div class="col-12 rows">
            <span class="labels">{{ 'checklist_type' | translate }}</span>
            <ng-select
              appendTo="body"
              bindLabel="checklist_type"
              [bindValue]
              [(ngModel)]="checklistType"
              (ngModelChange)="markAsEdited()">
              <ng-option value="job_checklist">{{ 'job_checklist' | translate }}</ng-option>
              <ng-option value="quote_checklist">{{ 'quote_checklist' | translate }}</ng-option>
              <ng-option value="assets_checklist" *fcMinSubscriptionPlanRequired="'fieldmagic-advanced'">{{ 'assets_checklist' | translate }}</ng-option>
            </ng-select>
        </div>

        <div class="col-12 rows" *ngIf="checklistType === 'assets_checklist'">

            <span class="labels">{{ 'asset_type' | translate }}</span>
            <ng-select
              appendTo="body"
              [items]="objAssetType['obv'] | async"
              [typeahead]="objAssetType['typehead']"
              [loading]="objAssetType['loader']"
              [hideSelected]="true"
              [clearable]="false"
              [ngModelOptions]="{standalone: true}"
              bindLabel="text"
              bindValue="id"
              [(ngModel)]="assetType"
              (open)="triggerSubject(objAssetType['typehead'])"
              (ngModelChange)="markAsEdited()">

            </ng-select>
        </div>
    </div>
</div>
