import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'view-stock-levels',
  templateUrl: './view-stock-levels.component.html'
})
export class ViewStockLevelsComponent implements OnInit {

  constructor(
    private objDialogRef: MatDialogRef<ViewStockLevelsComponent>,
    @Inject(MAT_DIALOG_DATA) public objData: any
  ) {}

  ngOnInit() {}

  /**
   * Closes the current dialog.
   * 
   * @returns {void}
   */
  closeDialog(): void {
    this.objDialogRef.close();
  }

}
