import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RecordService } from '../../../../services/record.service';
import { NotificationService } from '../../../../services/notification.service';
import { Notification } from '../../../..//objects/notification';
import { isEmpty } from 'lodash';
import { StatusCode } from '../../../../lists/status-code';
@Component({
  selector: 'app-convert-opportunity-files',
  templateUrl: './convert-opportunity-files.component.html',
  styleUrls: ['./convert-opportunity-files.component.scss']
})
export class ConvertOpportunityFilesComponent implements OnInit {


  public bDisabled = false;
  public arSelectedOpportunityFilesId = [];
  public objOpportunityFiles = {};

  constructor(
    public recordService: RecordService,
    public notifService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public objRecords: any,
    public dialog: MatDialogRef<ConvertOpportunityFilesComponent>) {
      this.objOpportunityFiles = this.objRecords['opportunity_files'];
  }

  ngOnInit() {}

  /**
   * Arranges request data and updates selected file records to have the latest job_id
   *
   * @returns {void}
   */
  linkFilesToNewlyConvertedJobRecord(): void {

    this.bDisabled = true;

    if (this.objRecords['job_id'] && ! isEmpty(this.arSelectedOpportunityFilesId)) {
      let arRequestData = [];

      this.arSelectedOpportunityFilesId.forEach(strFileId => {
        arRequestData.push({id: strFileId, job_id: this.objRecords['job_id']});
      });

      this.recordService.saveMultipleRecord('files', arRequestData).subscribe( response => {
        this.bDisabled = false;

        if (response.status !== StatusCode.kResponseSuccess) {
          this.notifService.notifyWarning('link_unsuccessful');
        } else {
          this.notifService.notifySuccess('link_file_successful');
        }

        this.cancelDialog();
      });
    } else {
      this.bDisabled = false;
      this.notifService.notifyWarning('no_files_selected');
    }

  }

  /**
   * Triggered when the select all checkbox was clicked
   *
   * @param {event}
   *
   * @returns {void}
   */
  selectAllFiles(event): void {

    for (let intCounter = 0; intCounter < Object.keys(this.objOpportunityFiles).length; intCounter ++) {
      if ((event.target.checked)) {
        if (! this.arSelectedOpportunityFilesId.includes(this.objOpportunityFiles[intCounter]['id'])) {
          this.arSelectedOpportunityFilesId.push(this.objOpportunityFiles[intCounter]['id'])
          this.objOpportunityFiles[intCounter]['checked'] = true;
        }
      } else {
        this.objOpportunityFiles[intCounter]['checked'] = false;
      }
    }

    // If unchecked, reset the selected items array
    if (! (event.target.checked)) {
      this.arSelectedOpportunityFilesId = [];
    }

  }

  /**
   * Triggered when a file is selected or unselected
   * Adds or removes file ID of the selected row
   *
   * @param {event}
   * @param {strFileId}
   * @param {intFileIndex}
   *
   * @returns {void}
   */
  selectAFile(event, strFileId, intFileIndex): void {

    if ((event.target.checked)) {
      if (! this.arSelectedOpportunityFilesId.includes(strFileId)) {
        this.arSelectedOpportunityFilesId.push(strFileId);
        this.objOpportunityFiles[intFileIndex]['checked'] = true;
      }
    } else {
      let intAssetToBeRemoved = this.arSelectedOpportunityFilesId.indexOf(strFileId);
      if (intAssetToBeRemoved !== -1) {
        this.arSelectedOpportunityFilesId.splice(intAssetToBeRemoved, 1);
      }
      this.objOpportunityFiles[intFileIndex]['checked'] = false;
    }

  }

  /**
   * Closes opportunity files component
   *
   * @returns void
   */
  cancelDialog(): void {
    this.dialog.close();
  }

}
