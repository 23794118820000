<header>
  <div class="d-flex dialog-header">
    <h3 class="dialog-title" translate>
      <div>
        {{ 'set_reason_lost' | translate }}
      </div>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'close' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button type="button" (click)="cancelDialog(strChosenReason)" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'save']"></fa-icon>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <hr />
</header>
<div class="row">
  <div class="col-12">
      <ng-select [items]="arReasonOptions"
        bindLabel="text"
        bindValue="id"
        [(ngModel)]="strChosenReason"
        placeholder="{{ 'select_a_reason' | translate }}"
        [clearable]="false"
        class="dropdown-select-width">
          <ng-template ng-option-tmp let-item="item" class="value-text">
              {{item.text | translate}}
          </ng-template>

          <ng-template ng-label-tmp let-item="item" class="value-text">
              {{item.text | translate }}
          </ng-template>
      </ng-select>
  </div>
</div>