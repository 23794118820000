import { Component, Output, EventEmitter, OnDestroy, Input } from "@angular/core";
import { WizardStepComponent, StepEventData, PROGRESS_FINISHED } from '../../connect.component';
import { AccountingSystemService } from "../../../../services/accounting_system.service";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { DriverInterface } from "../../../../entities/driver";

@Component({
  selector: 'wizard-step-success-connection',
  templateUrl: 'success-connection.component.html'
})
export class SuccessWizardConnectionComponent implements WizardStepComponent, OnDestroy {
  /**
   * determines if the current step is in progress
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * Injected driver instance
   *
   * @var {DriverInterface}
   */
  @Input('driver') driver: DriverInterface;

  /**
   * {@inheritdoc}
   */
  @Output('event-progress') eventProgress: EventEmitter<StepEventData> = new EventEmitter<StepEventData>(true);

  /**
   * INTERNAL: contains the list of subscription to the observable and should be cleaned up when this component is unmounted
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * @param {AccountingSystemService} service
   */
  constructor(
    private service: AccountingSystemService
  ) {}

  /**
   * handles the clicked button continue event
   *
   * @returns {void}
   */
  continueHandler(): void {
    this.isInProgress = true;

    this.subscriptions.push(
      this.service.updateStep$('connection', 'completed')
        .pipe(
          finalize(() => this.isInProgress = false)
        )
        .subscribe(() => this.eventProgress.emit({ progress: PROGRESS_FINISHED }))
    );
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
