
import { OnInit, Component, OnDestroy, Input, Output } from "@angular/core";
import { DriverInterface } from "../../../../entities/driver";
import { Subscription, of } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";
import { AccountingSystemService } from "../../../../services/accounting_system.service";
import { EventEmitter } from "@angular/core";
import { WizardStepComponent, StepEventData, PROGRESS_NEXT, PROGRESS_FAILED } from '../../connect.component';
import { MatDialog, MatDialogRef } from "@angular/material";
import { SetupMetadataFieldsComponent } from './components/setup-metadata-fields.component';

@Component({
  selector: 'wizard-step-authorization',
  templateUrl: './authorization.component.html',
})
export class AuthorizationComponent implements OnInit, OnDestroy, WizardStepComponent {
  /**
   * {@inheritdoc}
   */
  @Output('event-progress') eventProgress: EventEmitter<StepEventData> = new EventEmitter<StepEventData>();

  /**
   * contains the selected driver to be authorize
   *
   * @var {DriverInterface}
   */
  @Input('driver') driver: DriverInterface;

  /**
   * this tells if the current step is in progress
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * determines if the current operation is successful
   *
   * @var {boolean}
   */
  isSuccessful: boolean = false;

  /**
   * contains the message of the current progress
   *
   * @var {string}
   */
  message: string;

  /**
   * INTERNAL: contains all the subscriptions for rxjs that should be cleaned up once
   * this component is unmounted
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   *
   * @param {AccountingSystemService} service
   */
  constructor(
    private service: AccountingSystemService,
    private dialog: MatDialog
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.getAuthorizationUrl();
  }

  /**
   * fetches and processes the authorization
   *
   * @returns {void}
   */
  getAuthorizationUrl(): void {
    this.isInProgress = true;
    if (this.driver) {
      this.subscriptions.push(
        this.service.getAuthorizationUrl$(this.driver)
          .pipe(
            switchMap((url: string) => {
              if (url) return this.service.authorize$(url);
              return of({
                isSuccessful: false,
                message: 'unable_get_authorization_url'
              });
            }),
            finalize(() => this.isInProgress = false)
          )
          .subscribe((result) => {
            if (result.isSuccessful) {
              this.isInProgress = true;
              this.message = 'checking_driver_requirements';

              if (this.driver.metadata.authentication.length > 0) {
                this.displayMetadataFieldsDialog();
              } else {
                this.emitSuccessfulProcess();
              }
            } else {
              this.message = result.message;
              this.eventProgress.emit({ progress: PROGRESS_FAILED });
            }
          })
      );
    } else {
      this.message = 'no_driver_selected';
      this.isInProgress = false;
    }
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * displays a dialog instance that will contain the custom fields that should be filled up by the user
   *
   * @return {void}
   */
  protected displayMetadataFieldsDialog(): void {
    let dialogReference: MatDialogRef<SetupMetadataFieldsComponent> = this.dialog.open(SetupMetadataFieldsComponent, {
      data: {
        fields: this.driver.metadata.authentication,
        driverId: this.driver.id,
      },
      minWidth: '30%',
      maxWidth: '100%',
      disableClose: true
    });

    this.subscriptions.push(
      dialogReference.afterClosed().subscribe((isSucessful: boolean) => {
        if (isSucessful) {
          this.emitSuccessfulProcess();
        }
      })
    );
  }

  /**
   * emits an event data that indicates a successful value
   *
   * @returns {void}
   */
  protected emitSuccessfulProcess(): void {
    this.message = 'authorization_successful';
    this.isSuccessful = true;
    this.eventProgress.emit({ progress: PROGRESS_NEXT });
  }
}
