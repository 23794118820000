<section>
  <div class="row">
    <div class="col-sm-12">
      <app-information strModule="import" [isPanel1Visible]="true" [isPanel2Visible]="true" [isPanel3Visible]="true">
      </app-information>
    </div>
    <div class="col-sm-12">
      <app-listing [newRecord]="showNewImportDialog" strModule="imports" [objTableHeader]="objTableHeaderData"></app-listing>
    </div>
  </div>
</section>
