import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { ViewService } from "../../../../../../services/view.service";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import * as _ from 'lodash';
import { RecordService } from "../../../../../../services/record.service";
import { finalize, filter, switchMap, tap, debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { SafetyManagementService } from "../../../services/safety-management.service";
import { NotificationService } from "../../../../../../services/notification.service";
import { Subscription, zip, Observable, of, defer, Subject } from "rxjs";
import { PdfComponent } from "../../../../../../shared/components/view/pdf/pdf.component";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { Select } from "../../../../../../objects/select";
import { RATINGS_OPTIONS } from "../../../constants";
import { SearchService } from "../../../../../../services/search.service";
import { ClientStoreService } from '../../../../../../services/client-store.service';
import { GlobalRecord } from "../../../../../../objects/global-record";
import { parse as parseJSON } from '../../../../../../shared/utils/json';
import { SelectTemplate } from '../../../../../../objects/select-template';

@Component({
  selector: 'safety-management-shared-manage-swms-form-dialog',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  /**
   * Form group instance
   *
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({
    compliance_officer: new FormControl(null, [Validators.required]),
    relevant_legislation: new FormControl(null, [Validators.required]),
    management_competency: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    staff_competencies: new FormControl(null, [Validators.maxLength(255)]),
    specific_ppe_required: new FormControl(null),
    emergency_planning: new FormControl(null),
    plant_and_equipment: new FormControl(null),
    hazardous_substances: new FormControl(null),
    tasks: new FormArray([]),
    deleted_sub_tasks: new FormArray([])
  });

  /**
   * Form input select options
   *
   * @var {object}
   */
  options = {
    users: [],
    tasks: [],
    ratings: RATINGS_OPTIONS,
    task_review_frequencies: [],
  };

  /**
   * Current viewed job record
   *
   * @var {Job}
   */
  objJob: Job = null;

  /**
   * Flag if the current form is being initialized
   *
   * @var {boolean}
   */
  isInitializingForm: boolean = true;

  /**
   * Flag if the current form is being processed
   *
   * @var {boolean}
   */
  isProcessing: boolean = false;

  /**
   * Flag if the current module is previewing pdf
   *
   * @var {boolean}
   */
  isPreviewing: boolean = false;

  /**
   * List of tasks form group for the current form
   *
   * @var {FormArray}
   */
  tasks: FormArray = this.form.get('tasks') as FormArray;

  /**
   * @type {Subject}
   */
  preview: Subject<SelectTemplate> = new Subject;

  /**
   * Checks if department tracking is enabled.
   *
   * @type {boolean}
   */
  bDepartmentTracking: boolean = false;

  /**
   * @type {isSaveAndPreview}
   */
  protected isSaveAndPreview: boolean = false;

  /**
   * List of subscriptions that has been used and will be cleaned up later on
   *
   * @var {Subscription}
   */
  protected subscriptions: Subscription[] = [];

  /**
   * Create instance of the component
   *
   * @param {ViewService} views
   * @param {DialogData} data
   */
  constructor(
    protected views: ViewService,
    protected records: RecordService,
    protected safety: SafetyManagementService,
    protected notifications: NotificationService,
    protected dialog: MatDialogRef<FormComponent>,
    protected dialogFactory: MatDialog,
    protected searcher: SearchService,
    private clientStoreService: ClientStoreService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.objJob = this.data;
    this.bDepartmentTracking = this.clientStoreService.isDepartmentTracking();
    this.initializeForm();
    this.attachDialogBackdropEvent();
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Retrieved associated risks and controls for the current task group
   *
   * @param {FormGroup} task
   *
   * @returns {FormArray}
   */
  getSubTasks(task: FormGroup): FormArray {
    return task.get('sub_tasks') as FormArray;
  }

  /**
   * Add/appends risk to the current task group
   *
   * @param {FormGroup} task
   * @param {FormGroup} risk
   *
   * @returns {void}
   */
  addSubTask(
    task: FormGroup = this.tasks.at(this.tasks.length - 1) as FormGroup,
    risk: FormGroup = this.makeSubTaskGroup()
  ): void {
    this.getSubTasks(task).push(risk);
  }

  /**
   * Removes a sub task to a current task group based on a selected row
   *
   * @param {FormGroup} task
   * @param {number} index
   *
   * @returns {void}
   */
  removeSubTask(task: FormGroup, index: number): void {
    const subTasks = this.getSubTasks(task);
    const currentSubTask = subTasks.at(index) as FormGroup;

    // we only queue sub task that are already saved and has a unique identifier
    if (currentSubTask.value.id) this.queueSubTaskForDelete(currentSubTask);

    // remove from stack
    subTasks.removeAt(index);
  }

  /**
   * Check if a given task group has a single risk and control defined
   *
   * @param {FormGroup} task
   *
   * @returns {boolean}
   */
  hasSingleSubTask(task: FormGroup): boolean {
    return this.getSubTasks(task).length === 1;
  }

  /**
   * Event called when save button is clicked
   *
   * @returns {void}
   */
  onSave(): void {
    if (!this.form.valid) {
      this.notifications.notifyWarning('fill_out_fields');
      return;
    }

    this.isProcessing = true;

    this.subscriptions.push(
      this.safety.saveJobSafety$(this.objJob.id, this.form.value).pipe(
        finalize(() => this.isProcessing = false)
      ).subscribe((data) => {
        if (_.isEmpty(data)) return this.notifications.notifyError('sms_saved_failed');

        const updatedTasks = data.tasks.map((task) => ({
          ...task,
          sub_tasks: task.sub_tasks.map((subTask) => ({
            ...subTask,
            responsible_person: new Select(subTask.responsible_person.id, subTask.responsible_person.name)
          }))
        }));

        this.form.patchValue({
          tasks: updatedTasks
        });

        // marked that form is now pristined
        this.form.markAsPristine();

        this.notifications.notifySuccess('swms_saved');

        if (this.isSaveAndPreview) {
          this.preview.next(new SelectTemplate(this.objJob.id, 'jobs', undefined, 'job_swms'));
        }

        this.dialog.close();
      })
    );
  }

  /**
   * Event called when cancel button is clicked
   *
   * @returns {void}
   */
  onCancel(): void {
    this.subscriptions.push(
      this.confirmDiscard().subscribe(() => this.dialog.close())
    );
  }

  /**
   * Event called when preview PDF button is called
   *
   * @returns {void}
   */
  onPdfPreview(): void {
    this.isPreviewing = true;

    this.safety.getPdf$(this.objJob.id)
      .pipe(
        filter((data) => !_.isEmpty(data)),
        switchMap((data) => {
          return this.dialogFactory.open(PdfComponent, {
            data: {
              module: 'job_swms',
              label: 'swms_pdf',
              response: data,
            },
            height: '80%',
            width: '80%',
            disableClose: true,
          }).afterClosed()
        })
      )
      .subscribe(() => this.isPreviewing = false);
  }

  /**
   * Get the task name or description from the current task group
   *
   * @param {FormGroup} task
   *
   * @returns {string}
   */
  getTaskName(task: FormGroup): string {
    return _.get(this.options.tasks.find((row) => row.id === task.get('id').value), 'text');
  }

  /**
   * Check if sub tasks are hidden for the current task group
   *
   * @param {FormGroup} task
   *
   * @returns {boolean}
   */
  isSubTasksHidden(task: FormGroup): boolean {
    return task.value.is_hidden;
  }

  /**
   * Toggles sub tasks for the current task group
   *
   * @param {FormGroup} task
   *
   * @returns {void}
   */
  toggleSubTasks(task: FormGroup): void {
    task.patchValue({
      is_hidden: !task.value.is_hidden
    }, {
      emitEvent: false,
    });
  }

  /**
   * Checks if the current sub task group score is a catastrophic risk
   *
   * @param {FormGroup} subTask
   *
   * @returns {boolean}
   */
  isRiskCatastrophic(subTask: FormGroup): boolean {
    return _.indexOf([20, 25, 16], this.getRiskScore(subTask)) !== -1;
  }

  /**
   * Checks if the current sub task group score is a high risk
   *
   * @param {FormGroup} subTask
   *
   * @returns {boolean}
   */
  isRiskHigh(subTask: FormGroup): boolean {
    return _.indexOf([10, 15, 12], this.getRiskScore(subTask)) !== -1;
  }

  /**
   * Checks if the current sub task group score is a moderate risk
   *
   * @param {FormGroup} subTask
   *
   * @returns {boolean}
   */
  isRiskModerate(subTask: FormGroup): boolean {
    return _.indexOf([4, 5, 6, 8, 9], this.getRiskScore(subTask)) !== -1;
  }

  /**
   * Checks if the current sub task group score is a low risk
   *
   * @param {FormGroup} subTask
   *
   * @returns {boolean}
   */
  isRiskLow(subTask: FormGroup): boolean {
    return _.indexOf([1, 2, 3], this.getRiskScore(subTask)) !== -1;
  }

  /**
   * Retrieved the sub task risk score translation
   *
   * @param {FormGroup} subTask
   *
   * @returns {string}
   */
  getRiskScoreTranslation(subTask: FormGroup): string {
    if (this.isRiskCatastrophic(subTask)) return 'catastrophic';
    if (this.isRiskHigh(subTask)) return 'high';
    if (this.isRiskModerate(subTask)) return 'moderate';

    return 'low';
  }

  /**
   * Handle typeahead search
   *
   * @param   {FormGroup} task
   *
   * @returns {function}
   */
  search(task: FormGroup) {
    return (term$: Observable<string>) => term$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(() => this.bDepartmentTracking ? !_.isEmpty(task.value.department.id) : true),
      switchMap((term) => this.records.getRecordRelate('job_safety_sub_task_templates', term, '', false).pipe(
        map((results: RiskTemplateRelateData[]) => results
          .filter((riskTemplate) => this.bDepartmentTracking
            ? riskTemplate.department_id === task.value.department.id
            : true
          )
          .map((riskTemplate: RiskTemplateRelateData) => ({
            name: riskTemplate.name,
            risk: riskTemplate.risk,
            risk_rating: riskTemplate.risk_rating,
            control_measure: riskTemplate.control_measure,
            residual_risk_rating: riskTemplate.residual_risk_rating,
            task_review_frequency: riskTemplate.task_review_frequency
          }))
        )
      ))
    );
  }

  /**
   * Handle search item select
   *
   * @param {NgbTypeaheadSelectItemEvent} $event
   * @param {FormGroup} subTask
   *
   * @returns {void}
   */
  onSearchSelect($event: NgbTypeaheadSelectItemEvent, subTask: FormGroup): void {
    $event.preventDefault();

    const { item } = $event;

    let riskRating: [number, number] | string = item.risk_rating;
    let residualRiskRating: [number, number] | string = item.residual_risk_rating;

    // when ratings received as encoded string (which is provided by the manual sync command of es)
    // we then parse it to native value

    if (riskRating && _.isString((riskRating))) {
      riskRating = parseJSON<[number, number]>(riskRating as string, [0, 0]);
    }

    if (residualRiskRating && _.isString((residualRiskRating))) {
      residualRiskRating = parseJSON<[number, number]>(residualRiskRating as string, [0, 0]);
    }

    subTask.patchValue({
      name: item.name,
      risk: item.risk,
      risk_rating: riskRating,
      control_measure: item.control_measure,
      residual_risk_rating: residualRiskRating,
      task_review_frequency: item.task_review_frequency,
    });
  }

  /**
   * Initiializes form requirements and its data
   *
   * @returns {void}
   */
  protected initializeForm(): void {
    this.subscriptions.push(
      zip(
        this.records.getMultipleModuleRelateRecord('activities|users', undefined, {
          activities: {
            job_id: this.objJob.id,
            activity_type: 'task',
            is_open: true,
          }
        }),
        this.safety.getJobSafety$(this.objJob.id),
        this.records.getDropdownRecord('job_safety_sub_task_templates')
      ).pipe(
        finalize(() => this.isInitializingForm = false),
        tap(([modules]) => this.options.tasks = _.map(modules['activities'], (activity) => ({
          id: activity.id,
          text: activity.text,
          department_id: activity.department_id
        }))),
        tap(([modules]) => this.options.users = _.map(modules['users'], (user) => ({
          id: user.id,
          text: user.text
        }))),
        tap(([, { data, config }]) => this.form.patchValue({
          ..._.omit(data, ['sub_tasks']),
          compliance_officer: (!_.isEmpty(data.compliance_officer)) ? new Select(data.compliance_officer.id, data.compliance_officer.name) : null,
          relevant_legislation: data.relevant_legislation || config.default_relevant_legislation,
          staff_competencies: data.staff_competencies || config.default_staff_competencies,
          management_competency: data.management_competency || config.default_management_competency,
          hazardous_substances: data.hazardous_substances || config.default_hazardous_substances,
          plant_and_equipment: data.plant_and_equipment || config.default_plant_and_equipment,
          specific_ppe_required: data.specific_ppe_required || config.default_specific_ppe_required,
          emergency_planning: data.emergency_planning || config.default_emergency_planning,
        }, {
          emitEvent: false,
        })),
        tap(([, , { body }]) => this.options.task_review_frequencies = body.task_review_frequency.config)
      ).subscribe(([, { data }]) => _.chain(this.options.tasks)
        .map((task) => Object.assign(
          {
            id: task.id,
            sub_tasks: [{
              id: null,
              name: null,
              risk: null,
              risk_rating: [],
              risk_score: 0,
              control_measure: null,
              residual_risk_rating: [],
              residual_risk_score: 0,
              task_review_frequency: null,
              responsible_person: null,
            }]
          },
          data.tasks.find((row) => row.id === task.id),
          {
            department: {
              id: task.department_id
            }
          }
        ))
        .each((data) => {
          // @ts-ignore
          // please fix my types
          data.sub_tasks = data.sub_tasks.map((subTask) => ({
            ...subTask,
            responsible_person: (!_.isEmpty(subTask.responsible_person)) ? new Select(subTask.responsible_person.id, subTask.responsible_person.name) : null
          }));

          this.tasks.push(this.makeTaskFormGroup(data));
        })
        .value()
      )
    );
  }

  /**
   * Attaches a listener to the current dialog back drop event
   *
   * @returns {void}
   */
  protected attachDialogBackdropEvent(): void {
    this.dialog.disableClose = true;

    this.subscriptions.push(
      this.dialog.backdropClick().pipe(
        switchMap(() => this.confirmDiscard())
      ).subscribe(() => this.dialog.close())
    )
  }

  /**
   * Displays a confirmation when discarding changes
   *
   * @returns {Observable<boolean>}
   */
  protected confirmDiscard(): Observable<boolean> {
    return defer(() => (!this.form.pristine) ? this.notifications.sendConfirmation('confirm_discard').map(item => { return item.answer }) : of(true))
      .pipe(
        filter((response) => response)
      );
  }

  /**
   * Get current sub task risk score
   *
   * @param {FormGroup} risk
   *
   * @returns {number}
   */
  protected getRiskScore(subTask: FormGroup): number {
    return subTask.value.residual_risk_score || subTask.value.risk_score;
  }

  /**
   * Makes a risk form group instance based on a given risk data
   *
   * @param {object} data
   *
   * @returns {FormGroup}
   */
  protected makeSubTaskGroup(data: Partial<SubTaskData> = {}): FormGroup {
    const group = new FormGroup({
      id: new FormControl(data.id),
      name: new FormControl(data.name, [Validators.maxLength(255)]),
      risk: new FormControl(data.risk, [Validators.required]),
      control_measure: new FormControl(data.control_measure, [Validators.required]),
      risk_rating: this.makeRatingControls(data.risk_rating),
      residual_risk_rating: this.makeRatingControls(data.residual_risk_rating),
      risk_score: new FormControl(data.risk_score || 0),
      residual_risk_score: new FormControl(data.residual_risk_score || 0),
      task_review_frequency: new FormControl(data.task_review_frequency, [Validators.required]),
      responsible_person: new FormControl(data.responsible_person || this.form.value.compliance_officer, [Validators.required]),
    });

    this.subscriptions.push(
      group.valueChanges.subscribe((value) => group.patchValue({
        // @ts-ignore
        // please fix my types
        risk_score: _.multiply(...value.risk_rating),
        // @ts-ignore
        // please fix my types
        residual_risk_score: _.multiply(...value.residual_risk_rating)
      }, {
        emitEvent: false
      }))
    );

    return group;
  }

  /**
   * Makes a risk rating controls from a given ratings
   *
   * @param {RiskRating} ratings
   *
   * @returns {FormGroup}
   */
  protected makeRatingControls(ratings: RiskRating = []): FormArray {
    const [likelihood, consequences] = ratings;

    return new FormArray([
      new FormControl(likelihood, [Validators.required]),
      new FormControl(consequences, [Validators.required])
    ]);
  }

  /**
   * Makes a task group based on a given task data
   *
   * @param {TaskData} data
   *
   * @returns {FormGroup}
   */
  protected makeTaskFormGroup(data: Partial<TaskData> = {}): FormGroup {
    return new FormGroup({
      id: new FormControl(data.id, [Validators.required]),
      is_hidden: new FormControl(true),
      department: new FormControl(data.department),
      sub_tasks: new FormArray(_.map((data.sub_tasks), (data) => this.makeSubTaskGroup(data))),
    });
  }

  /**
   * Pushes current deleted sub task (already saved) to queue
   *
   * @param {FormGroup} subTask
   *
   * @returns {void}
   */
  protected queueSubTaskForDelete(subTask: FormGroup): void {
    let queue: FormArray = this.form.get('deleted_sub_tasks') as FormArray;

    queue.push(new FormControl(subTask.value.id));
  }

  /**
   * Handle save and preview event
   *
   * @returns {void}
   */
  onSaveAndPreview(): void {
    this.isSaveAndPreview = true;
    this.onSave();
  }
}

interface Job {
  id: string;
  job_summary: string;
  site_text: string;
}

type RiskRating = [number?, number?];

type SubTaskData = {
  id: string,
  name: string,
  risk: string,
  risk_rating: RiskRating,
  risk_score: number,
  control_measure: string,
  residual_risk_rating: RiskRating,
  residual_risk_score: number,
  task_review_frequency: string,
  responsible_person: Select,
}

type TaskData = {
  id: string,
  is_hidden: boolean,
  department: Select,
  sub_tasks: SubTaskData[],
}

type RiskTemplateRelateData = {
  id: string,
  name: string,
  risk: string,
  risk_rating: RiskRating,
  control_measure: string,
  residual_risk_rating: RiskRating,
  task_review_frequency: string,
  department_id: string,
  department_text: string
}
