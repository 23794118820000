<div class="d-flex justify-content-around contacts-option tabs-content">
  <span id="widgetStockTransferCreateButton" (click)="openFormDialog()" class="span-links">
    <fa-icon [icon]="['fas', 'plus-circle']" class="text-success pr-1"></fa-icon>{{ 'create_stock_transfer' | translate }}
  </span>
</div>

<div class="pagination-body">
  <div class="container">
    <div class="row pt-2 pb-2">
      <div class="col-sm-6 pt-1">
        <span class="module-title">
          <fa-icon [icon]="['fa', 'exchange-alt']" aria-hidden="true" class="pr-2"></fa-icon>{{ 'stock-transfer' | translate }}
        </span>
      </div>
      <div class="col-sm-6 pr-2">
        <nav class="pagination-section">
          <ul class="pagination">
            <refresh-pagination-btn (refresh)="onRefresh()" [isDisabled]="bLoading" id="widgetStockTransferRefreshButton"></refresh-pagination-btn>
            <li id="widgetStockTransferPreviousButton" [ngClass]="previousButton" class="page-item">
              <a class="page-link" aria-label="first" (click)="getRecord('prev')">
                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
              </a>
            </li>
            <li id="widgetStockTransferNextButton" [ngClass]="nextButton" class="page-item">
              <a class="page-link" aria-label="first" (click)="getRecord('next')">
                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div *ngIf="!hasData">
      <div class="row border-top p-3" *ngIf="!bLoading">
        <div class="col-12 text-center"><span>{{ 'no_results' | translate }}</span></div>
      </div>
      <div class="row border-top p-3" *ngIf="bLoading">
        <div class="col-12 text-center"><span><inline-view-loader [isLoading]="true"></inline-view-loader></span></div>
      </div>
    </div>

    <div *ngIf="hasData">
      <div *ngFor="let stockTransfer of arRecordList; index as stockTransferIndex" class="row border-top p-2">
        <div class="col-sm-6 pl-1 pr-1">
          <div class="truncate">
            <span matTooltip="{{ 'warehouse_source' | translate }}: {{ stockTransfer.warehouse_source_text }}" class="pointer">
              <fa-icon [icon]="['far', 'arrow-left']" class="text-danger" aria-hidden="true"></fa-icon>
              {{ stockTransfer.warehouse_source_text }}
            </span>
          </div>
          <div class="truncate">
            <span matTooltip="{{ 'warehouse_destination' | translate }}: {{ stockTransfer.warehouse_destination_text }}" class="pointer">
              <fa-icon [icon]="['far', 'arrow-right']" class="text-success" aria-hidden="true"></fa-icon>
              {{ stockTransfer.warehouse_destination_text }}
            </span>
          </div>
        </div>
        <div class="col-sm-5 pl-1 pr-1">
          <div class="truncate">
            <a matTooltip="{{ stockTransfer.created_by_name }}">
              <span>{{ stockTransfer.created_by_name }}</span>
            </a>
          </div>
          <div class="truncate">
            <a matTooltip="{{ formatDate(stockTransfer.created_at) }}">
              <span>{{ formatDate(stockTransfer.created_at) }}</span>
            </a>
          </div>
        </div>
        <div class="col-sm-1 pl-1 pr-1">
          <div class="truncate">
            <fa-icon id="widgetStockTransferViewButton" class="text-primary pointer float-right" [icon]="['fas', 'eye']" (click)="openStockTranferDialog(stockTransfer)"></fa-icon>
          </div>
          <div class="truncate">
            <div *ngIf="stockTransfer.status == 'draft'; else completedStatus">
              <div *ngIf="objMarkAsCompleteList[stockTransfer.id]; else markAsCompleteStatus">
                <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true" class="float-right"></fa-icon>
              </div>
              <ng-template #markAsCompleteStatus>
                <input id="widgetStockTransferCompleteCheckbox" matTooltip="{{ 'mark_as_complete' | translate }}" type="checkbox" class="float-right pointer" (click)="markAsComplete(stockTransferIndex, stockTransfer.id)"/>
              </ng-template>
            </div>
            <ng-template #completedStatus>
              <fa-icon id="widgetStockTransferCompleted" matTooltip="{{ 'complete' | translate }}" class="text-success float-right" [icon]="['fas', 'check-circle']"></fa-icon>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
