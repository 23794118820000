
import { Component, EventEmitter, Output, OnDestroy, QueryList, ContentChildren, AfterViewInit, Input } from "@angular/core";
import { WizardStepComponent, StepEventData, PROGRESS_NEXT, PROGRESS_SKIP, PROGRESS_INPROGRESS } from '../../../connect.component';
import { Subscription, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wizard-step-import-multiple-modules',
  templateUrl: './multiple-modules.component.html'
})
export class ImportMultipleModulesComponent implements WizardStepComponent, AfterViewInit, OnDestroy {
  /**
   * {@inheritDoc}
   */
  @Output('event-progress') eventProgress: EventEmitter<StepEventData> = new EventEmitter(true);

  /**
   * contains the component children that implements the wizard component interface
   *
   * @var {QueryList<WizardStepComponent>}
   */
  @ContentChildren('steps') steps: QueryList<WizardStepComponent>;

  /// this flag is used to determine if the step can be skipped
  /// useful when this is part of a wizard
  @Input() canBeSkipped: boolean = false;

  readonly inProgress$ = new BehaviorSubject<boolean>(false);

  /**
   * contains the number of steps that are already successful
   *
   * @var {number}
   */
  private successfulSteps: number = 0;

  /**
   * INTERNAL: contains all the subscriptions for rxjs that should be cleaned up once
   * this component is unmounted
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * {@inheritdoc}
   */
  ngAfterViewInit(): void {
    this.steps.forEach((step: WizardStepComponent) => {
      this.subscriptions.push(
        step.eventProgress.subscribe((result: StepEventData) => {
          if (result.progress === PROGRESS_NEXT || result.progress === PROGRESS_SKIP) {
            this.successfulSteps++;
            if (this.successfulSteps === this.steps.length) {
              this.eventProgress.emit({ progress: PROGRESS_NEXT });
            }
          } else {
            this.eventProgress.emit({ progress: result.progress });
          }

          if (result.progress === PROGRESS_INPROGRESS) {
            this.inProgress$.next(true);
          } else {
            this.inProgress$.next(false);
          }
        })
      );
    });
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /// handles on skip step user action
  onSkipStep(): void {
    this.eventProgress.emit({
      progress: PROGRESS_SKIP,
    });
  }
}
