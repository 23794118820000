import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Common } from '../../../../objects/common';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-savefilterdialog',
  templateUrl: './savefilterdialog.component.html',
  styleUrls: ['./savefilterdialog.component.scss']
})
export class SavefilterdialogComponent implements OnInit {

  public arFilter: any = {};
  public arLabels: any = {
    'default_filter': 'def_fil' 
  };
  public arLabelsDisplay: any;
  public arCurrentLabel: any;
  public arFilterCommon: Common[] = [];
  public strFilterName: any;

  public arRelateText: any = [];
  public arFilterOption: any = [];
  public arSelectedFilter: boolean;

  public bUpdate: boolean = false;

  constructor(public dialogRef: MatDialogRef<SavefilterdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    // Store parameters
    this.arFilter = data['objFilterBody'];
    this.arRelateText = data['arRelate'];
    this.arFilterOption = data['arFilterOption'];
    // Check if id exist
    if (data['arSelectedFilter']['id']) {
      // Store the current name
      this.strFilterName = data['arSelectedFilter']['name'];
      // For display text in button
      this.bUpdate = true;
    }
    
    // Map the table header
    data['arLabels'].map(
      items => {
        // Create array with id and value
        let strId =  (typeof(items['id']) == 'string') ? items['id'] : items['id'][0];
        this.arLabels[strId] = items['label'];
      }
    );

    // Default parameter
    this.arLabels['order_by'] = this.arFilter['order_by'];
  }

  ngOnInit() {
    Object.keys(this.arFilter).forEach( item => {
      if (this.arFilter[item]) {
        switch (item) {
          case 'order_by':
            if (this.arFilter[item]['id']) {
              // Get current order by id
              let strId = this.arFilter[item]['id'];
              let arOrderByValues: any = {
                'id': strId,
                'sort': this.arFilter[item]['sort']
              }
              // Push to arFilterCommon
              this.arFilterCommon.push(new Common(item, arOrderByValues, ''));
            }
          break;
          case 'level':
            let arSelected;
            if (arSelected = this.arFilterOption[item].find(x => x.id == this.arFilter[item])) {
              this.arFilterCommon.push(new Common(this.arLabels[item], arSelected['value'], ''));
            }
          break;
          // case 'share':
          //   // Push to arFilterCommon
          //   this.arFilterCommon.push(new Common('share_filter_with_all_users', (this.arFilter[item]) ? 'true' : 'false', ''));
          // break;
          default:
            // If field id has value in relate, get the relate
            let strFilterValue = (this.arRelateText[item]) ? this.arRelateText[item] : this.arFilter[item];
            // Push to arFilterCommon
            this.arFilterCommon.push(new Common(this.arLabels[item], strFilterValue, ''));
          break;
        }
      } else {
        delete this.arFilter[item];
      }
    });
    
    let arCleanFilterCommon = [];
    // Cleanup arFilterCommon
    this.arFilterCommon.forEach( (data, index) => {
      if (data['id'] == undefined) {
        delete this.arFilterCommon[index];
      } else {
        arCleanFilterCommon.push(data);
      }
    });
    
    this.arFilterCommon = arCleanFilterCommon;
  }

  public checkType(anyValue) {
    
    if (Array.isArray(anyValue)) {
      return 'array';
    } 
    
    return 'string';
    
  }

  public dialogClose(){
    this.dialogRef.close();
  }

  public saveFilter(){
    if (this.strFilterName) {
      this.dialogRef.close({
        'filter_name': this.strFilterName,
        'filter_body': this.arFilter
      });
    }
  }

}
