<div *ngFor="let role of arSiteList" class="row border-top p-2" [id]="'widgetSiteRolesRecord' + role.id">
    <div class="col-sm-1 pl-1 pr-1">
        <fa-icon *ngIf="role.site_disable_maintenance" class="text-danger fs-22 position-absolute" matTooltip="{{ 'disabled_maintenance' | translate }}" [icon]="['fas', 'circle']"></fa-icon>
        <fa-icon *ngIf="!role.site_disable_maintenance" class="text-success fs-22 position-absolute" matTooltip="{{ 'enabled_maintenance' | translate }}" [icon]="['fas', 'circle']"></fa-icon>
    </div>
    <div class="col-sm-6 site-text pt-2 truncate">
        <a id="widgetSiteRolesLabel" matTooltip="{{ 'site' | translate }}: {{ role.module_text }}" class="pointer" href="/#/sites/{{role.module_id}}">
            {{ role.module_text }}
        </a>
        <fa-icon *ngIf="role.primary_contact_id == role.contact_id" class="text-success pl-1 primary-role" matTooltip="{{ 'primary' | translate }}" [icon]="['fas', 'check-circle']"></fa-icon>
    </div>
    <div class="col-sm-4 pl-1 pr-1">
        <div class="text-job-title">
            <span *ngIf="role.role" matTooltip="{{ translateRole(role.role) }}" class="fs-12 d-inherit word-break truncate">
                <fa-icon [icon]="['fas', 'user']" aria-hidden="true" class="pr-1"></fa-icon>{{ translateRole(role.role) }}
            </span>
            <span *ngIf="!role.role" matTooltip="{{ 'no_role_assigned' | translate }}" class="fs-12 d-inherit word-break truncate">
                {{ 'no_role_assigned' | translate }}
            </span>
        </div>
        <div class="text-job-title">
            <span matTooltip="{{ 'tenant' | translate }}: {{ role.site_tenant | placeholdWithString }}" class="fs-12 d-inherit word-break truncate">
                <fa-icon [icon]="['fal', 'user']"></fa-icon><span> {{ role.site_tenant | placeholdWithString }} </span>
            </span>
        </div>
    </div>
    <div class="col-sm-1 d-table fs-12 d-inherit word-break pl-1 pr-1">
        <div class="row">
            <div class="col-12">
                <fa-icon id="widgetSiteRolesEditButton" class="text-success pointer float-right edit-record" [icon]="['fas', 'pencil']" (click)="emitAction('edit', role)"></fa-icon>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <fa-icon id="widgetSiteRolesDeleteButton" class="text-warning pointer float-right delete-record" [icon]="['fas', 'trash-alt']" (click)="emitAction('delete', role)"></fa-icon>
            </div>
        </div>
    </div>
</div>