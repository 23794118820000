import { Observable } from 'rxjs/Rx';
import { throwError, BehaviorSubject, of } from 'rxjs';
import { switchMap, catchError, finalize, filter, take, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../auth/auth.service';
import { NotificationAction } from '../objects/notification';
import { environment } from '../../environments/environment';
import { ClientStoreService } from '../services/client-store.service';
import { NotificationService } from '../services/notification.service';
import { LocalStorageService } from '../services/local-storage.service';
import { Client } from '../objects/client';
import { Prompt } from '../objects/prompt';
import { isEmpty } from 'lodash';
import { CustomTranslateService } from '../services/custom-translate.service';
import { RecaptchaService } from '../services/recaptcha.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * this will determine if we already handling the refreshing of token, this will only
   * resets if the token is already been refreshed
   *
   * @param {boolean}
   */
  private isTokenBeingRefreshed: boolean = false;

  /**
   * this contain our resuable observer which holds the listener to refresh the token
   *
   * @param {BehaviorSubject<boolean>}
   */
  private tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private clientStoreService: ClientStoreService,
    private router: Router,
    private auth: AuthService,
    private localStorageService: LocalStorageService,
    private notifications: NotificationService,
    private translator: CustomTranslateService,
    private recaptcha: RecaptchaService,
  ) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*
     * Are we gonna call an external API?
     * If yes, we will not add the custom headers for serverdev.fieldmagic.co API.
     * We do this by checking if there is already a
     * Content Type header.
     */

    if (!request.headers.has('Content-Type')) {
      // lets attach the authentication information
      request = this._cloneRequestWithAuthenticationInformation(request);
    }

    return this.attachCaptcha(request).pipe(
      switchMap((request) => {
        return next.handle(request);
      }),
      catchError((error: HttpErrorResponse) => {
        switch(error.status) {
          case 0:
            return this._handleConnectionRequestError$(error);
          case 400:
            return this._handleBadRequestError$(error);
          case 401:
            return this._handleUnathorizedRequestError$(request, next);
          case 404:
            return this._handlePageNotFoundRequestError$(error);
          case 500:
            return this._handleInternalServerRequestError(error);
          case 403:
            return this._handleForbiddenRequestError$(error);
          case 422:
            return this._handleRequestValidationError$(error);
          case 409:
            return this._handleExistingRecordFoundRequest$(error);
          default:
            return this._handleGenericErrorRequest$(error);
        }
      }),
    );
  }

  /**
   * handles the unauthorizeed request error
   *
   * @param   {HttpRequest<any>}  request
   * @param   {HttpHandler}       next
   *
   * @returns {Observable<HttpEvent<any>>}
   */
  private _handleUnathorizedRequestError$(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isTokenBeingRefreshed) {
      return this.tokenSubject.pipe(
        filter((result) => result === true),
        take(1),
        switchMap(() => next.handle(this._cloneRequestWithAuthenticationInformation(request)))
      );
    } else {
      this.isTokenBeingRefreshed = true;
      this.tokenSubject.next(false);

      return this._renewToken().pipe(
        switchMap((isSuccess: boolean) => {
          this.tokenSubject.next(isSuccess);
          return next.handle(this._cloneRequestWithAuthenticationInformation(request));
        }),
        finalize(() => this.isTokenBeingRefreshed = false)
      );
    }
  }

  /**
   * handles email not verified request error
   *
   * @param   {HttpErrorResponse} error
   *
   * @returns {Observable<never>}
   */
  private _handleForbiddenRequestError$(error: HttpErrorResponse): Observable<never> {

    if (error.error === "Email Not Verified.") {
      this.router.navigate(['error/403/unverified']);
    }

    return throwError(error);
  }

  /**
   * Shows a generic error message for records
   * that are already existing and throws an error.
   *
   * @param error
   *
   * @returns {Observable<never>}
   */
  private _handleExistingRecordFoundRequest$(error: HttpErrorResponse): Observable<never> {
    this.notifications.notifyWarning('record_already_exist')
    return throwError(error);
  }

  /**
   * Throws an error related to API request validation.
   *
   * @param error
   *
   * @returns {Observable<never>}
   */
  private _handleRequestValidationError$(error: HttpErrorResponse): Observable<never> {
    return throwError(error);
  }

  /**
   * handles not found http request error
   *
   * @param   {HttpErrorResponse} error
   *
   * @returns {Observable<never>}
   */
  private _handlePageNotFoundRequestError$(error: HttpErrorResponse): Observable<never> {
    this._displayNotification(error);
    this.router.navigate(['error/404/record']);

    return throwError(error);
  }

  /**
   * handles internal server error requests
   *
   * @param   {HttpErrorResponse} error
   *
   * @returns {Observable<never>}
   */
  private _handleInternalServerRequestError(error: HttpErrorResponse): Observable<never> {
    this._displayNotification(error);
    this.router.navigate(['error/500/record']);

    return throwError(error);
  }

  /**
   * handles internal server error requests
   *
   * @param   {HttpErrorResponse} error
   *
   * @returns {Observable<never>}
   */
  private _handleConnectionRequestError$(error: HttpErrorResponse): Observable<never> {
    this.notifications.sendNotification('header_notification.generic_fail', 'connection_failed', 'warning');

    return throwError(error);
  }

  /**
   * handles generic error requests
   *
   * @param   {HttpErrorResponse} error
   *
   * @returns {Observable<never>}
   */
  private _handleGenericErrorRequest$(error: HttpErrorResponse): Observable<never> {
    this._displayNotification(error);
    return throwError(error);
  }

  /**
   * handle bad request error
   *
   * @param  {HttpErrorResponse} objResponse
   *
   * @returns {Observable<never>}
   */
  private _handleBadRequestError$(objResponse: HttpErrorResponse): Observable<never> {
    this._badRequestNotification(
      objResponse.error.errors || [objResponse.error.message]
    );

    return throwError(objResponse);
  }

  /**
   * Notification shown for bad request error
   * @param
   */
  private _badRequestNotification(arErrors: string[]): void {
    if (! isEmpty(arErrors)) {
      let arTranslatedErrors: Array<string> = [];

      arErrors.forEach(strError => {
        arTranslatedErrors.push(
          this.translate(strError)
        );
      });
      let strErrorMessage = arTranslatedErrors.join(' ,');
      // check if there are translated error before triggering the notify error
      if (strErrorMessage) {
        this.notifications.notifyError(strErrorMessage);
      }
    }
  }

  /**
   * renew our current access token
   *
   * @returns {Observable<any>}
   */
  private _renewToken(): Observable<any> {
    return Observable.from(this.auth.renewToken({
      redirect: false,
    }).catch(() => this.auth.login()));
  }

  /**
   * displays a notification a popup when error has been occured
   *
   * @param {HttpErrorResponse} error
   */
  private _displayNotification(error: HttpErrorResponse): void {
    const body = error.error || {};
    const content = body.message || 'content_report_error';
    const fieldName = body.field_name != null ? body.field_name : error.status.toString();
    const fieldValue = body.field_value != null ? body.field_value : error.statusText.toString();

    let objNotification = this.notifications.customNotification({
      header: 'error_occurred',
      message: new Prompt({
        message: content,
        field_name: fieldName,
        field_value: fieldValue
      }),
      theme: 'danger',
      has_button: true,
      button_title: 'send_report',
      type: 'interceptor'
    })

    this.notifications.notif$
      .filter(notification =>
        notification instanceof NotificationAction &&
        notification.action === true &&
        notification.id === objNotification.id
      )
      .subscribe(() => {
        let to: string = environment.admin_email;
        let subject: string = 'Fieldmagic - Bug Report';
        let body: string = `Error Encountered: ${error.name} - ${error.message}`;

        window.open(`mailto:${to}?subject=${subject}&body=${body}`, '_self');
      });
  }

  /**
   * clones a copy of a given HttpRequest instance with the attach authentication information
   *
   * @param   {HttpRequest<any>} request
   *
   * @returns {HttpRequest<any>}
   */
  private _cloneRequestWithAuthenticationInformation(request: HttpRequest<any>): HttpRequest<any> {

    let headers: HttpHeaders = request.headers;
    let authenticationToken: string|null = this.localStorageService.getItem('access_token') || null;
    let activeClient: Client|null = this.clientStoreService.getActiveClient();

    // Check if 'Authorization' exists on http headers
    if (headers.has('X-Lambda')) {
      // FC-3925: somehow aws lambda keeps making authorization invalid. to fix the issue we will
      // use a custom header for authentication verification
      headers = headers.set('X-Authorization', `Bearer ${authenticationToken}`);

      return request.clone({
        headers
      });
    } else {
      headers = headers.set('Authorization', `Bearer ${authenticationToken}`);

      let body: URLSearchParams | FormData;

      if (request.body instanceof FormData) {
        body = <FormData>request.body;
      } else {
        body = new URLSearchParams(request.body);
        body.set('Timezone-Offset', ((new Date().getTimezoneOffset() / 60) * -1).toString());
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
      }

      // body.set overrides a given value in the search params we would to do that in order
      // not to have multple instance of the form value

      // attach the authentication to request body
      if (body.has('Authorization')) {
        body.set('Authorization', `Bearer ${authenticationToken}`);
      } else {
        body.append('Authorization', `Bearer ${authenticationToken}`);
      }

      // do we have an active client?
      if (activeClient && activeClient['client_id']) {
        if (body.has('client_id')) {
          body.set('client_id', activeClient['client_id']);
        } else {
          body.append('client_id', activeClient['client_id']);
        }

        headers = headers.set('x-client-id', activeClient['client_id']);
      }

      let content: string | FormData;

      if (body instanceof URLSearchParams) {
        content = body.toString();
      } else {
        content = body;
      }

      return request.clone({
        headers: headers,
        ['body']: content
      });
    }
  }

  /**
   * translates a given value
   *
   * @param {string|undefined} value
   *
   * @returns {string|undefined}
   */
  protected translate(strValue?: string): string|undefined {
    if (! strValue) {
      return strValue
    }

    this.translator.initializeTransalateables([strValue]);

    return this.translator.getTranslation(strValue);
  }

  /**
   * Replaces the captcha header value with the token acquired from the captcha service
   */
  protected attachCaptcha(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    if (! request.headers.has('X-Captcha')) {
      return of(request);
    }

    return this.recaptcha.getCaptcha(request.headers.get('X-Captcha')).pipe(
      map((captcha) => {
        return request.clone({
          headers: request.headers.set('X-Captcha', captcha),
        });
      }),
    );
  }
}
