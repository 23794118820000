
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end pb-3">
      <ul class="pagination">
        <li class="page-item" [ngClass]="(hasPreviousPage) ? 'text-primary pointer' : 'disabled'">
          <a class="page-link" (click)="onChangePage('default')">
            <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="(hasPreviousPage) ? 'text-primary pointer' : 'disabled'">
          <a class="page-link" (click)="onChangePage('prev')">
            <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
          </a>
        </li>
        <li class="page-item" [ngClass]="(hasNextPage) ? 'text-primary pointer' : 'disabled'">
          <a class="page-link" (click)="onChangePage('next')">
            <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
  