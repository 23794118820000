import { Component, Input } from '@angular/core';

@Component({
  selector: 'fieldmagic-alert',
  template: `
    <div [ngClass]="className">
      <small>
          <fa-icon [icon]="['fas', iconName]" class="mr-2"></fa-icon>
          <ng-content></ng-content>
      </small>
    </div>
  `
})
export class AlertComponent {
  @Input('mode')
  mode : 'warning' | 'error' | 'info' = 'info';

  get className(): string {
    const baseClassName = 'alert';

    if (this.mode == 'error') {
      return `${baseClassName} alert-danger`;
    }

    if (this.mode == 'warning') {
      return `${baseClassName} alert-warning`;
    }

    return `${baseClassName} alert-info`;
  }

  get iconName(): string {
    if (this.mode == 'warning') {
      return 'exclamation-triangle';
    }

    if (this.mode == 'error') {
      return 'exclamation-circle';
    }

    return 'info-circle';
  }
}