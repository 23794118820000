<div *ngFor="let role of arOpportunityList" class="row border-top p-2" [id]="'widgetOpportunityRolesRecord' + role.id">
    <div class="col-sm-1 pl-1 pr-1">
        <fa-icon [ngClass]="arOpportunityStatus[role.opportunity_status]" class="fs-22 position-absolute" matTooltip="{{ role.opportunity_status | translate }}" [icon]="['fas', 'circle']"></fa-icon>
    </div>
    <div class="col-sm-6 opportunity-text pl-1 pr-1 truncate">
        <a id="widgetOpportunityRolesLabel" matTooltip="{{ 'opportunity_number' | translate }}: {{ '#' }}{{ formatOpportunityNumber(role.module_text) }}" class="pointer" href="/#/opportunities/{{role.module_id}}">
            <span>{{ '#' }}{{ formatOpportunityNumber(role.module_text) }}</span>
        </a>
        <fa-icon *ngIf="role.primary_contact_id == role.contact_id" class="text-success pl-1 primary-role" matTooltip="{{ 'primary' | translate }}" [icon]="['fas', 'check-circle']"></fa-icon>
        <div class="text-address truncate" matTooltip="{{ 'quote_summary' | translate }}: {{ role.opportunity_summary | placeholdWithString }}">
            <span>{{ role.opportunity_summary | placeholdWithString }}</span>
        </div>
    </div>
    <div class="col-sm-4 pl-1 pr-1">
        <div class="text-job-title">
            <span *ngIf="role.role" matTooltip="{{ translateRole(role.role) }}" class="fs-12 d-inherit word-break truncate">
                <fa-icon [icon]="['fas', 'user']" aria-hidden="true" class="pr-1"></fa-icon>{{ translateRole(role.role) }}
            </span>
            <span *ngIf="!role.role" matTooltip="{{ 'no_role_assigned' | translate }}" class="fs-12 d-inherit word-break truncate">
                {{ 'no_role_assigned' | translate }}
            </span>
        </div>
        <div class="text-job-title">
            <span matTooltip="{{ 'amount' | translate }}: {{ role.opportunity_amount | placeholdWithString }}" class="fs-12 d-inherit word-break truncate">
                <span> {{ role.opportunity_amount | currency | placeholdWithString }} </span>
            </span>
        </div>
        <span matTooltip="{{ 'forecast_close_date' | translate }}: {{ formatDate(role.opportunity_forecast_close_date) | placeholdWithString }}" class="fs-12 d-inherit word-break truncate">
            <fa-icon [icon]="['fas', 'calendar-alt']" class="pr-1"></fa-icon>{{ formatDate(role.opportunity_forecast_close_date) | placeholdWithString }}
        </span>
    </div>
    <div class="col-sm-1 d-table fs-12 d-inherit word-break pl-1 pr-1">
        <div class="row">
            <div class="col-12">
                <fa-icon id="widgetOpportunityRolesEditButton" class="text-success pointer float-right edit-record" [icon]="['fas', 'pencil']" (click)="emitAction('edit', role)"></fa-icon>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <fa-icon id="widgetOpportunityRolesDeleteButton" class="text-warning pointer float-right delete-record" [icon]="['fas', 'trash-alt']" (click)="emitAction('delete', role)"></fa-icon>
            </div>
        </div>
    </div>
</div>