import { Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ComposeComponent } from "./compose/compose.component";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: 'integrations-accounting-systems-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnDestroy {
  /**
   * Output an event where emits a boolean value when composing of enquiry is finished
   *
   * @var {EventEmitter<boolean>}
   */
  @Output('event-on-compose-finished') onComposeFinished: EventEmitter<boolean> = new EventEmitter;

  /**
   * Indicates if the current process is in-progress state
   *
   * @var {boolean}
   */
  isInProgress: boolean = false;

  /**
   * Internal: Contains all the rxjs subscriptions within the component that
   * would be cleaned up when the component is unmounted or destroyed within the DOM
   *
   * @var {Subscription[]}
   */
  protected subscriptions: Subscription[] = [];

  /**
   * Create instance of the component
   *
   * @param {MatDialog} dialogFactory
   */
  constructor(
    protected dialogFactory: MatDialog
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Handles event when compose enquiry button has been clicked
   *
   * @returns {void}
   */
  onClick(): void {
    this.isInProgress = true;

    let subscription = this.dialogFactory.open(ComposeComponent, {
        width: '40vw',
      })
      .afterClosed()
      .pipe(
        finalize(() => this.isInProgress = false)
      )
      .subscribe((composed: boolean) => this.onComposeFinished.emit(composed));

    this.subscriptions.push(subscription);
  }
}
