import { filter, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ImportRecord } from '../../../../../objects/import-record';
import { BrowserService } from '../../../../../services/browser/browser.service';
import { ImporterService } from '../../../../../services/importer/importer.service';
import { Widgetable } from '../../../../../objects/component-viewing-widget/widgetable';

@Component({
  selector: 'app-result-set-list',
  templateUrl: './result-set-list.component.html',
  styleUrls: ['./result-set-list.component.scss']
})
export class ResultSetListComponent implements OnInit, Widgetable {

  /**
   * The import record where the user is currently viewing this
   * component in.
   *
   * @type {BehaviorSubject<ImportRecord>}
   */
  importRecord: BehaviorSubject<ImportRecord> = new BehaviorSubject(null);
  importRecord$: Observable<ImportRecord> = this.importRecord.asObservable().pipe(
    filter(record => record !== undefined),
  );

  /**
   * Gets triggered when the import record is completed.
   *
   * @type {Observable<ImportRecord>}
   */
  whenImportRecordCompletes$: Observable<ImportRecord> = this.importRecord$.pipe(
    filter(record => record !== null && record.is_finished),
  );

  constructor(public importerService: ImporterService, public browserService: BrowserService) { }

  ngOnInit() { }

  /**
   * @inheritdoc
   */
  setTrickleDownData(data: ImportRecord): void {
    this.importRecord.next(data);
  }

  /**
   * @inheritdoc
   */
  getTrickleDownData(): any { }

  /**
   * Executes the downloading of a resultset file.
   *
   * @param import_id
   * @param kind
   *
   * @returns {void}
   */
  downloadResultSet(import_id: string, kind: 'success' | 'errors' | 'import'): void {
    this.importerService.downloadResultSet(import_id, kind).subscribe((response) => {
      this.browserService.forceDownload(response.body, `${kind}.csv`);
    });
  }

  /**
   * Returns the current value of importRecord
   *
   * @returns {ImportRecord}
   */
  getImportRecord(): ImportRecord {
    return this.importRecord.getValue();
  }

}
