import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

const kItemFoldersUrl = environment.url + '/item_folders/';

@Injectable({
  providedIn: 'root'
})
export class ItemFoldersService {

  /**
   * Holds the http client instance.
   */
  public http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * Enables us to add items to the folder.
   *
   * @param {string[]} arIds
   * @param {string} strFolderId
   *
   * @returns {Observable<object>}
   */
  addItemsToFolder(arIds: string[], strFolderId: string){
    return this.http.post(`${kItemFoldersUrl}add_items`, new URLSearchParams({
      ids: JSON.stringify(arIds),
      folder_id: strFolderId
    }).toString());
  }

  /**
   * Enables us to retrieve list of folders.
   *
   * @param {string} strToken
   * @param {string} strParentFolder
   * @param {string} strTerm
   *
   * @returns {Observable<object>}
   */
  getList(
    strToken: string = null,
    strParentFolder: string = null,
    arFolderIds: string[] = [],
    arItemIds: string[] = [],
    bLabor: boolean | null = null
  ): Observable<object>{

    let objDataParams = new URLSearchParams();

    if (strToken) {
      objDataParams.append('token', strToken)
    }

    if (strParentFolder) {
      objDataParams.append('parent_folder_id', strParentFolder)
    }

    if (arFolderIds.length > 0) {
      objDataParams.append('folder_ids', JSON.stringify(arFolderIds));
    }

    if (arItemIds.length > 0) {
      objDataParams.append('item_ids', JSON.stringify(arItemIds));
    }

    // Get both non-labor and labor if this field is null.
    if (bLabor != null) {
      objDataParams.append('labor', JSON.stringify(bLabor));
    }

    return this.http.post(`${kItemFoldersUrl}list`, objDataParams.toString());

  }

  /**
   * Enables us to retrieve items and folders in a single API
   * request. This displays all children under the searched parent.
   *
   * @param {string} strParentFolerId
   *
   * @returns {Observable<object>}
   */
  getChildrenList(strParentFolderId: string | null = null, bLabor: boolean | null = null, strToken: string = null): Observable<object>{

    let objParams = new URLSearchParams();

    if (strParentFolderId != null) {
      objParams.append('parent_folder_id', strParentFolderId);
    }

    if (strToken != null) {
      objParams.append('token', strToken);
    }

    if (bLabor != null) {
      objParams.append('labor', JSON.stringify(bLabor));
    }

    return this.http.post(`${kItemFoldersUrl}children_list`, objParams.toString());

  }

  /**
   * Get the items stats.
   *
   * @param strItemId
   *
   * @returns {Observable<object>}
   */
  getItemStocks(strItemId: string) {
    let objParams = new URLSearchParams();
    objParams.append('item_id', strItemId);
    return this.http.post(`${environment.url}/stock_management/stock_levels/item/statistics`, objParams.toString());
  }
}