import { PipeTransform, Pipe } from '@angular/core';
import { first, get, isMap, isObjectLike, isString, split } from 'lodash';
import { blank, fallback, whenBlank, whenFilled } from '../shared/utils/common';

@Pipe({
  name: 'data_get',
})
export class DataGetPipe implements PipeTransform {
  transform = <T extends Object, R = any>(value: T, args: DataGetPipeOptions<R> | string): R | undefined => {
    let opts: DataGetPipeOptions<R>;

    if (isString(args)) {
      opts =  {
        key: args,
      }
    } else {
      opts = args as DataGetPipeOptions<R>;
    }

    let keys: string[] = [];

    if (isString(opts.key)) {
      keys = split(opts.key, '.');
    } else {
      keys = opts.key;
    }

    if (blank(value)) {
      return opts.default_value;
    }

    if (isMap(value)) {
      value = value.get(first(keys)) || opts.default_value;
    } else {
      value = get(value, first(keys), opts.default_value);
    }

    keys = keys.slice(1);

    if (blank(keys)) {
      return fallback(value, {
        fallback: () =>  opts.default_value,
      });
    }

    return this.transform(value, {
      key: keys,
      default_value: opts.default_value,
    });
  }
}

type DataGetPipeOptions<R> = {
  key: string | string[],
  default_value?: R
}