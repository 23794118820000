import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Chat } from '../objects/chat';
import { Pagination } from '../objects/pagination';
import { HttpClient } from '@angular/common/http';
import { Relate } from '../objects/relate';
import { RecordService } from './record.service';
import { ViewService } from './view.service';
import { RelateIds } from '../lists/relate-ids';
import { switchMap } from 'rxjs/operators';
import { ChatAction } from '../objects/chat-action';
import { LooseObject } from '../objects/loose-object';

@Injectable()
export class ChatService {

  /**
   * Currently available chat actions depending
   * on which module chosen.
   *
   * @var {ChatAction[]}
   */
  public arChatActions: ChatAction[] = [];

  /**
   * The base url for all requests in this service.
   *
   * @returns {string}s
   */
  get strBaseUrl() {
    return environment.url + '/messages/';
  }

  constructor(
    private http: HttpClient,
    private recordService: RecordService,
    private viewService: ViewService
  ) {}

  /**
   * Set the actions for this module.
   *
   * @param {ChatAction} arActions
   *
   * @returns {void}
   */
  setActions(arActions: ChatAction[]): void {
    this.arChatActions = arActions;
  }

  /**
   * Get all actions.
   *
   * @returns {ChatAction[]}
   */
  getActions() {
    return this.arChatActions;
  }

  /**
   * Get the same record service that this
   * service uses.
   *
   * @returns {RecordService}
   */
  getRecordService(): RecordService {
    return this.recordService;
  }

  /**
   * Get the view record taken from the
   * view service.
   *
   * @returns {LooseObject}
   */
  getViewRecord(): LooseObject {
    return this.viewService.arRecord;
  }

  /**
   * Retrieves the latest ten chats.
   *
   * @param {string} strActivityId
   * @param {string} strToken
   *
   * @returns {Observable<{data: Chat[], token: Pagination}>}
   */
  getActivityMessages(strActivityId: string, strToken: string): Observable<{ data: Chat[], token: Pagination }> {
    let body = new URLSearchParams();
    body.append('activity_id', strActivityId);
    body.append('token', strToken);
    return this.http.post<Response>(this.strBaseUrl + "thread", body.toString()).map(response => {
      let data: Chat[] = response['data'].map(chat => {
        let objChat = new Chat(chat);
        objChat.setName(chat.first_name, chat.last_name);

        return objChat;
      });
      let token: Pagination = (response['next']) ? new Pagination(response['next']) : null;
      return { data, token };
    });
  }

  /**
   * Adds the default actions per module. This action is the
   * ability to send an attachment from the files widget. For now,
   * that's the only default action.
   *
   * @param {string} strModule
   * @param {string} strRecordId
   *
   * @returns {void}
   */
  addDefaultAction(strModule: string, strRecordId: string): void {
    if (this.arChatActions.findIndex(action => action.id == 'send_files') < 0) {
      let objFileRelate: Relate<any> = new Relate<any>();
      let strRelateId = RelateIds[strModule];

      objFileRelate.buildRelates(
        switchMap(term => this.getRecordService()
          .getRecordRelate('files', term, '', false, { [strRelateId]: strRecordId })
          .map(data => {
            let arFiles: any = data;
            return (arFiles) ? arFiles.map(file => {
              let objFile: any = file;
              objFile.text = file['file_name'];
              return objFile;
            }) : [];
          })
        ));

      this.arChatActions.unshift(new ChatAction({
        id: 'send_files',
        icon: 'file-alt',
        selection: true,
        relate: objFileRelate
      }));
    };
  }

  /**
   * Sends a new chat message to server for
   * saving to db and triggering a
   * pusher event.
   *
   * @param {string} strActivityId
   * @param {string} strMessage
   * @param {string} strType
   * @param {string} strName
   *
   * @returns {Observable<Response>}
   */
  sendMessage(
    strActivityId: string,
    strMessage: string,
    strType: string = null,
    strName: string = null,
    arMention: string[] = []
  ): Observable<Response> {
    let body = new URLSearchParams();
    body.append('activity_id', strActivityId);
    body.append('message', strMessage);
    if (strType) {
      body.append('type', strType);
    }
    if (strName) {
      body.append('file_name', strName);
    }
    if (arMention.length > 0) {
      body.append('mentions', JSON.stringify(arMention));
    }
    return this.http.post<Response>(this.strBaseUrl + "send", body.toString());
  }

  /**
   * Generated pdfs from the chat are only saved in the temp bucket,
   * so we do this request to copy the pdf from the temp bucket to the
   * permanent bucket so we'd able to get a longer presigned url.
   *
   * @param {string} strUploadName
   */
  copyMessageAttachment(strUploadName: string): Observable<string> {
    let body = new URLSearchParams();
    body.append('upload_name', strUploadName);
    return this.http.post<string>(this.strBaseUrl + "copy", body.toString());
  }

}