<input type="text"
            [(ngModel)]="filter.viewModel.value"
            (change)="filter.onChange()"
            (focus)="filter.focusInput$.next()"
            (keydown)="advancedSearchBox.keydown($event, filter.viewModel)" 
            autosize 
            [placeholder]="filter.viewModel.placeholder | translate"
            [mask]="filter.viewModel.mask.mask"
            [specialCharacters]="filter.viewModel.mask.specialCharacters"
            [patterns]="filter.viewModel.mask.patterns"
            [dropSpecialCharacters]="filter.viewModel.mask.dropSpecialCharacters"
            [clearIfNotMatch]="filter.viewModel.mask.clearIfNotMatch"
            #inputRef
            class="as-text-input"
            />