export enum RelateIds {
    'leads' = 'lead_id',
    'customers' = 'customer_id',
    'contacts' = 'contact_id',
    'opportunities' = 'opportunity_id',
    'jobs' = 'job_id',
    'customer_invoices' = 'customer_invoice_id',
    'purchase_orders' = 'purchase_order_id',
    'supplier_invoices' = 'supplier_invoice_id',
    'assets' = 'asset_id',
    'sites' = 'site_id'
};