import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ProfitabilityAnalysisDialogComponent, ProfitabilityAnalysisDialogData } from '../dialog/dialog.component';
import { LooseObject } from '../../../../objects/loose-object';
import { map } from 'rxjs/operators';
import { ProfitabilityAnalystService } from '../services/profitability-analyst.service';

@Component({
  selector: 'profitability-analysis-button',
  template: `<button
    *ngIf="isUserProfitabilityEnabled"
    id="txtActualProfit"
    class="btn mr-2 btn-success"
    (click)="showProfitabilityAnalysis()"
    [ngClass]="{
      'btn-success': objProfitMargin.margin > 0,
      'btn-danger': objProfitMargin.margin <= 0
    }"
    matTooltip="{{'profitability_analysis' | translate}}" >
     {{  ( objProfitMargin.profit | currency: {decimal_places: 2} ) }}
     ({{ objProfitMargin.margin }}%)
  </button>`
})

export class ProfitabilityAnalysisButtonComponent {
  @Input('job-record') jobRecord;

  @Output() updatedAmountToInvoiceEmitter = new EventEmitter<number>();

  public objProfitMargin: AnalysisDisplay = {
    profit: 0,
    margin: 0
  }

  /**
   * Flag for user's profitability analysis availability
   * It will be true if it returns the values
   */
  public isUserProfitabilityEnabled: boolean = false;

  /**
   * @param {MatDialog}
   */
  constructor(
    private dialog: MatDialog,
    protected analyst: ProfitabilityAnalystService
  ) {}

  ngOnInit() { }

  /**
   * Whenever there's change in job record (usually when the chevron left and right while browsing jobs on view)
   */
  ngOnChanges() {
    this.getProfitabilityAnalysis();
  }

  /**
   * Opens a job profitability dialog
   *
   * @param record
   *
   * @returns {void}
   */
  public showProfitabilityAnalysis(): void {
    this.dialog.open<ProfitabilityAnalysisDialogComponent, ProfitabilityAnalysisDialogData>(ProfitabilityAnalysisDialogComponent, {
      data: {
        jobId: this.jobRecord.id,
      },
      minWidth: '95vw',
      disableClose: true,
    });
  }

  /**
   * Pulls the api for computing the margin and the profit for display
   */
  private getProfitabilityAnalysis(): void {
    this.analyst.analyze(this.jobRecord.id).pipe(
      map((analysis) => {
        if (this.jobRecord.invoicing_type === 'time_and_materials') {
          this.updatedAmountToInvoiceEmitter.emit(analysis.total_actual_revenue);
        }

        this.isUserProfitabilityEnabled = true;
        return {
          profit: analysis.total_actual_profit,
          margin: analysis.total_actual_profit_margin
        };
      }),
    ).subscribe((analysis) => {
      this.objProfitMargin = analysis;
    });
  }
}

export interface AnalysisDisplay {
  margin: number;
  profit: number;
};
