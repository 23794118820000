<app-error-handler onlyFor="sites:list" alignment="center" displayAs="widget">
<div class="d-flex justify-content-around contacts-option tabs-content">
    <ng-container *fcHasPermission="'sites:create'; else createSiteDisabled">
        <span id="widgetSitesCreateButton" (click)="(bPageLoaded) ? recordDialog() : ''" class="span-links">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-success mr-1"></fa-icon>
            {{ 'create_site' | translate }}
        </span>
    </ng-container>
    <ng-template #createSiteDisabled>
        <span id="widgetSitesCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-default"></fa-icon>
            {{ 'create_site' | translate }}
        </span>
    </ng-template>
</div>
<div>
    <div class="sites-body">
        <div class="container">
            <div class="row pt-2 pb-2">
                <div class="col-sm-6 pt-1">
                    <span class="module-title">
                        <fa-icon [icon]="['fa', 'map-marker-alt']" aria-hidden="true"></fa-icon>
                        &nbsp;
                        {{ 'sites' | translate }}
                    </span>
                </div>
                <div class="col-sm-6 pr-2">
                    <nav class="pagination-section" class="float-right">
                        <ul class="pagination">
                            <refresh-pagination-btn id="widgetSitesRefreshButton" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
                            <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                                <a id="widgetSitesPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                                    <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                                </a>
                            </li>
                            <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                                <a id="widgetSitesNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                                    <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div *ngIf="arSites.length < 1">
                <div class="row border-top" *ngIf="!bLoading">
                    <span class="no-content">
                        {{ 'no_sites' | translate }}
                    </span>
                </div>
                <div class="row border-top" *ngIf="bLoading">
                    <span class="no-content">
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                        {{ 'loading' | translate }}
                    </span>
                </div>
            </div>

            <div class="row border-top pt-2 pb-2" *ngFor="let site of arSites" [id]="'widgetSitesRecord' + site.id">
                <div class="col-sm-1">
                    <fa-icon class="text-danger text-status" *ngIf="site.disable_maintenance" matTooltip="{{ 'disabled_maintenance' | translate }}" [icon]="['fas', 'circle']"></fa-icon>
                    <fa-icon class="text-success text-status" *ngIf="!site.disable_maintenance" matTooltip="{{ 'enabled_maintenance' | translate }}" [icon]="['fas', 'circle']"></fa-icon>
                </div>
                <div class="col-sm-6">
                    <div class="text-activity-type truncate">
                        <a id="widgetSitesLabel" matTooltip="{{ site.site_text }}" class="pointer" href="/#/sites/{{site.id}}">
                            <span>{{ site.site_text }}</span>
                        </a>
                    </div>

                    <span matTooltip="{{ site.tenant }}" class="text-activity-content truncate">
                        {{ site.tenant | translate }}
                    </span>
                </div>
                <div class="col-sm-4 truncate">
                    <table>
                        <tr *ngFor="let phone of site.phone; let i=index">
                            <ng-container *ngIf="i < 1">
                                <td class="customer-icon-phone"><fa-icon [icon]="['far', getPhoneIcon(phone.type)]"></fa-icon></td>
                                <td>
                                    <span matTooltip="{{ phone.type | translate }}" ngxClipboard [cbContent]="phone.type" (cbOnSuccess)="notifyCopy()" class="phone-text">
                                        {{ phone.code }} {{ (phone.number) ? (phone.number | phone) : '' }}
                                    </span>
                                </td>
                            </ng-container>
                        </tr>
                    </table>
                </div>
                <div class="col-sm-1 text-role less-padding">
                    <div class="row pl-2">
                        <div class="col-12">
                            <ng-container *fcHasPermission="'sites:edit'; else disabledEditButton">
                                <fa-icon id="widgetSitesEditButton" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="recordDialog(site.id)"></fa-icon>
                            </ng-container>
                            <ng-template #disabledEditButton>
                                <fa-icon id="widgetSitesEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row pl-2">
                        <div class="col-12">
                            <ng-container *fcHasPermission="'sites:delete'; else disabledDeleteButton">
                                <fa-icon id="widgetSitesDeleteButton" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteSite(site.id)"></fa-icon>
                            </ng-container>
                            <ng-template #disabledDeleteButton>
                                <fa-icon id="widgetSitesDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</app-error-handler>