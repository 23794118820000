import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../../services/account.service';
import { NotificationService } from '../../../../services/notification.service';
import { concatMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ConfirmationAnswer } from '../../../../objects/confirmation';
import { ChargebeeService } from '../../../../services/chargebee/chargebee.service';
import { ClientStoreService } from '../../../../services/client-store.service';
import { NotificationThemes } from '../../../../objects/notification';
import { MatDialogRef } from '@angular/material';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sms-packages',
  templateUrl: './sms-packages.component.html',
  styleUrls: ['./sms-packages.component.scss']
})
export class SmsPackagesComponent implements OnInit {

  /**
   * Placeholder to put the current SMS credits.
   *
   * @var {number}
   */
  public numCredits: number = null;

  /**
   * Flag to know if user/client wants to be informed
   * whenever their SMS Credits goes below a certain point.
   *
   * @var {boolean}
   */
  public bNotifyLowCredits: boolean = false;

  /**
   * The number of credits to trigger a notification.
   *
   * @var {number}
   */
  public numCreditThreshold: number = 100;

  /**
   * Flag to show user that purchase is currently loading.
   *
   * @var {boolean}
   */
  public bPurchaseLoader: boolean = false;


  /**
   * Flag to show user that saving the autocharge
   * setting is currently loading.
   *
   * @var {boolean}
   */
  public bAutochargeLoader: boolean = false;

  get strSubscriptionId() {
    return this.clientStoreService.getActiveClient().subscription_id
  }

  constructor(
    public selfDialog: MatDialogRef<SmsPackagesComponent>,
    public accountService: AccountService,
    public notifService: NotificationService,
    private chargebeeService: ChargebeeService,
    private clientStoreService: ClientStoreService,
    private route: Router
  ) { }

  ngOnInit() {
    this.accountService.getSmsBalance().subscribe(response => {
      this.numCredits = response.credits;
      if (response.config && response.config.sms_config) {
        let objSmsConfig: {autocharge?: boolean, threshold?: number} = response.config.sms_config;
        this.numCreditThreshold = objSmsConfig.threshold || this.numCreditThreshold;
        this.bNotifyLowCredits = objSmsConfig.autocharge || this.bNotifyLowCredits;
      }
    });
  }

  /**
   * Updates the sms settings in the client config.
   *
   * @returns {void}
   */
  updateSmsSettings(bNoConfirmation = true): void {

    if (this.bNotifyLowCredits && this.numCreditThreshold < 1) {
      this.notifService.notifyWarning('lowest_value_one');
    } else {

      this.bAutochargeLoader = true;

      if (!this.bNotifyLowCredits && this.numCreditThreshold < 1) {
        this.numCreditThreshold = 100;
      }

      if (this.bNotifyLowCredits && bNoConfirmation) {
        this.notifService.sendConfirmation('automatic_charge_confirm', 'automatic_charge').pipe(
          switchMap(confirmation => {
            if (confirmation.answer === true) {
              return this.accountService.updateClientConfig({
                sms_config: {
                  autocharge: this.bNotifyLowCredits,
                  threshold: this.numCreditThreshold
                }
              });
            } else {
              return Observable.of(confirmation);
            }
          })
        ).subscribe(response => {
          this.bAutochargeLoader = false;
          if (response instanceof ConfirmationAnswer) {
            this.bNotifyLowCredits = false;
          } else {
            if (response.message === 'success') {
              this.notifService.notifySuccess('updated_sms_settings');
            } else {
              this.notifService.notifyError('failed_to_save');
            }
          }
        });
      } else {
        this.accountService.updateClientConfig({
          sms_config: {
            autocharge: this.bNotifyLowCredits,
            threshold: this.numCreditThreshold
          }
        }).subscribe(response => {
          this.bAutochargeLoader = false;
          if (response.message === 'success') {
            this.notifService.notifySuccess('updated_sms_settings');
          } else {
            this.notifService.notifyError('failed_to_save');
          }
        })
      }
    }

  }

  /**
   * To purcahse SMS credits, we need first to confirm if the user
   * really wants to purchase. If  he/she selected yes, proceed to call
   * API for purchase.
   *
   * @returns {void}
   */
  purchaseAddOn(): void {
    this.bPurchaseLoader = true;
    this.notifService.sendConfirmation('sms_credits_purchase_confirm', 'sms_credits_purchase')
      .pipe(
        concatMap(confirmation => {
          if (confirmation.answer === true) {
            return this.chargebeeService.checkoutCharge(
              this.strSubscriptionId,
              'sms-credits-pu-500',
              1
            )
          } else {
            return of(null);
          }
        })
      ).subscribe(response => {
        this.bPurchaseLoader = false;
        if (response != null) {
          if (response.error_code) {
            this.notifService.sendConfirmation('purchase_failed', 'sms_credits_purchase')
              .filter(confirmation => confirmation.answer)
              .subscribe(() => {
                this.selfDialog.close('open_manage_subscription');
              });
          } else {
            // FC-3486: SMS Credit successful purchase notification now displays text
            // instead of [object Object]
            this.notifService.sendNotification('sms_credits_purchase', 'purchase_success', 'success');
            this.selfDialog.close();
          }
        }
      });
  }
}
