export enum ActivityType {
    event = 'event',
    note = 'note',
    task = 'task',
    email = 'email',
    message = 'message'
};

export enum Priority {
    high = 'high',
    normal = 'normal',
    low = 'low'
};

export enum ActivityIcon {
    event = 'calendar-alt',
    note = 'sticky-note',
    task = 'tasks',
    email = 'envelope',
    message = 'comments'
};
