import { first, tap, filter, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild, ContentChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { ListingService } from '../../../services/listing.service';
import { MatDialog } from '@angular/material';
import { Common } from '../../../objects/common';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import { SavefilterdialogComponent } from './savefilterdialog/savefilterdialog.component';
import { FilterdialogComponent } from './filterdialog/filterdialog.component';
import { RecordService } from '../../../services/record.service';
import { ActivitiesService } from '../../../services/activities.service';
import { StatusCode } from '../../../lists/status-code';
import { ModuleLogo } from '../../../lists/module-logo';
import { EditformComponent } from '../editform/editform.component';
import { ProjectTemplatesService } from '../../../services/project-templates.service';
import { UpdateDocumentDialogComponent } from '../../../admin/document-library/update-document-dialog/update-document-dialog.component';
import { UploadDocumentDialogComponent } from '../../../admin/document-library/upload-document-dialog/upload-document-dialog.component';
import { EditInvoiceComponent } from '../../../module/jobs/customer-invoices/edit-invoice/edit-invoice.component';
import { EditPurchaseOrderComponent } from '../../../module/jobs/purchase-orders/edit-purchase-order/edit-purchase-order.component';
import { EditSupplierInvoiceComponent } from '../../../module/jobs/supplier-invoices/edit-supplier-invoice/edit-supplier-invoice.component';
import { CreateChecklistComponent } from '../../../../app/admin/checklists/create-checklist/create-checklist.component';
import { LocalStorageService } from '../../../services/local-storage.service';
import { IDialogEvent } from './../../events/dialog.event';
import { QuickfilterComponent } from './quickfilter/quickfilter.component';
import { EditComponent as EditEmailTemplate } from "../../../admin/email-template/edit/edit.component";
import { SaveComponent } from '../../../../app/admin/sms-templates/save/save.component';
import { isEmpty, merge, isNil, cloneDeep, has, get, toNumber } from 'lodash';
import { EditRecurringInvoiceFormComponent } from '../widget/recurring_invoices/form/edit-recurring_invoice-form.component';
import { AsConfigService } from '../../../shared/external-libraries/ngx-advanced-searchbox/src/public_api';
import { SearchService } from '../../../services/search.service';
import { AdvanceSearchboxTemplate } from '../../../objects/advance-searchbox';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { FormGroup } from '@angular/forms';
import { LooseObject } from '../../../objects/loose-object';
import { Location } from '@angular/common';
import { forkJoin, NEVER, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchAfter } from '../../../objects/elasticsearch';
import { EditRecurringJobsComponent } from '../widget/recurring-jobs/edit-recurring-jobs/edit-recurring-jobs.component';
import { FormPopup } from '../../../objects/centralized-forms/form-popup';
import { FormComponent as StockTransferFormComponent } from '../../../module/stock-level/widgets/stock-transfer/dialog/form/form.component';
import { ArrService } from '../../../services/helpers/arr.service';
import { FormsComponent as ExportFormComponent } from '../../../../app/admin/export/forms/forms.component';
import { MergeRecordComponent } from './merge-dialog/merge-record/merge-record.component';
import { RowDeleteButtonDirective } from '../../directives/listing/row/delete-button.directive';
import { ListRowDirective } from '../../directives/listing/row.directive';
import { EditComponent as EditJobTemplatesComponent } from '../../../admin/job-templates/edit/edit.component';
import { FormComponent as WorkflowFormComponent } from '../../../admin/workflow/form/form.component';
import { ArchiveComponent } from '../notifications/archive/archive.component';
import { AsComponent } from '../../../shared/external-libraries/ngx-advanced-searchbox/src/public_api';
import { ClientStoreService } from '../../../services/client-store.service';
import { filled } from '../../utils/common';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
})

export class ListingComponent implements OnInit {

  @ContentChild('list_item_actions') list_item_actions: any;
  @ContentChild('list_actions') list_actions: any;
  /**
   * A template for additional row details
   *
   * @type {TemplateRef<any>}
   */
  @ContentChild(ListRowDirective, { read: TemplateRef }) rowTemplate: TemplateRef<any> = null;
  /**
   * A template for delete button per row
   *
   * @type {TemplateRef<any>}
   */
  @ContentChild(RowDeleteButtonDirective, { read: TemplateRef }) rowDeleteButtonTemplate: TemplateRef<any>;

  /**
   * Should receive a callback that will be then be called when
   * the user clicks on the "Add" button in the list view.
   *
   * @function
   */
  @Input() newRecord: ((objParams) => void);
  @Input() strModule: string;
  @Input() objTableHeader: LooseObject[];
  public objTableHeaderOption: any = [];

  public strIndexName: string;
  public arFilters: string[];
  public arPageNumber: string[] = [];
  public arRowDisplay: any = [];
  public arContent: any = [];
  public arSearchAfters: SearchAfter[];
  public arContentType: any = [];
  public arSavedFilters: any = [];
  public arModels: any = {};
  public arDropdownModels: any = {};
  public arDropdownOptions: any = {};
  public strComponent: any;
  public innerWidth: any;
  public strTooltipDisabled = '';

  public bLoading: boolean = true;
  public bNoResult: boolean = false;
  public bViewLoaded = false;

  public intTotalItems;
  public bShowPrev = false;
  public bShowNext = true;
  public numCurrentPage = 0;
  public numFirstNumber = 1;
  public numLastNumber = 10;
  public strPastValue = '';
  public objModuleConfig: any = {};
  // Icons in listview
  public arIcon: any;
  // List of modules that create is just a dialog form
  public arDialogModule = ['checklists', 'document_library', 'sms_templates', 'exports'];
  // List of modules that has no view
  public arNoViewModule = [
    'departments',
    'resources',
    'tax_codes',
    'account_codes',
    'document_library',
    'pricebooks',
    'project_templates',
    'job_skills',
    'checklists',
    'email_templates',
    'sms_templates',
    'job_safety_sub_task_templates',
    'stock_levels',
    'warehouses',
    'job_templates',
    'activity_log_types',
    'workflows',
    'subcontractors',
  ];
  public bViewLink: boolean;
  public bFilter: boolean = false;
  public arSortOption: any = [];
  public arPagination: any = [];
  public arFilterRelate: any = [];
  public bSaveButton: boolean = false;
  public bClearButton: boolean = false;
  public bEditButton: boolean = true;
  public bAddButton: boolean = true;
  public strPreviousPage: string = '';
  public arSelectedFilter: object;
  public arCurrentFilter: {} = {
    'id': '',
    'status': ''
  };
  public bAdd: boolean = true;
  public bNoAction: boolean = true;

  public arNoActionModule: Array<string> = ['activities', 'exports', 'stock_levels', 'stocktakes'];
  public arNoAddModule: Array<string> = ['activities', 'exports', 'stock_levels', 'stocktakes'];

  public downloadAction: Array<string> = ['exports'];
  public hasDownloadAction: boolean = false;

  // Testing
  public modelAs: object;
  public templateAs: Array<object>;
  public form: FormGroup;

  // Pagination
  public hasNextPage: boolean;
  public hasPreviousPage: boolean;

  // Page Details
  public totalRecords: number;
  public fromRecords: number;
  public toRecords: number;
  public arSelectedRecords: Array<object> = [];

  public bAllowedDelete: boolean = true;
  public arModulesDeleteNotAllowed: Array<string> = ['tax_codes', 'account_codes', 'users', 'subcontractors', 'stocktakes'];

  public lastPage: number;
  public isFilterChanged: boolean = false;

  public arRecordPerPageOption: number[] = [
    10, 15, 20, 25
  ];
  public arNavigationPage: number[] = [ 1 ];

  /**
   * event triggered when dialog actions occur
   *
   * @var {EventEmitter<IDialogEvent>}
   */
  @Output() evtListingDialog = new EventEmitter<IDialogEvent>();

  /**
   * quick filter component
   *
   * @var {QuickfilterComponent}
   */
  @ViewChild(QuickfilterComponent) quickFilterComponent: QuickfilterComponent;

  /**
   * AdvancedSearchbox component
   *
   * @var {AsComponent}
   */
  @ViewChild(AsComponent) advancedSearchboxComponent: AsComponent;

  /**
   * determine if we need to display the checkbox for merging record
   */
  get hasMergingOption(): boolean {
    let arHasNoMergingOption = [
      'items',
      'stocktakes'
    ];

    return !arHasNoMergingOption.includes(this.strModule);
  }

  /**
   * get the current page stored in listing service
   */
  get currentPage(): number {
    return this.listService.getCurrentPageNum();
  }

  /**
   * get the cached listing record per page config
   */
  get recordsPerPage(): number {
    return toNumber(get(this.clients.getActiveClient(), 'config.listing_records_per_page', 25));
  }

  constructor(
    public listService: ListingService,
    public router: Router,
    public route: ActivatedRoute,
    public asConfig: AsConfigService,
    public searchService: SearchService,
    public notificationService: NotificationService,
    public location: Location,
    public arrService: ArrService,
    protected localStorageService: LocalStorageService,
    private recordService: RecordService,
    private projectTemplateService: ProjectTemplatesService,
    private notifService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private clients: ClientStoreService,
  ) { }

  async setupAdvanceSearchbox(template: object[]): Promise<void> {
    // Setup Template
    this.modelAs = {};
    this.templateAs = template;

    // Get filter with remote related data
     this.templateAs.forEach((asTemplate: AdvanceSearchboxTemplate) => {
      if (asTemplate.domains && asTemplate.domains === 'remote') {
        this.asConfig.customDomainsAsyncFn[asTemplate.model] = (observable, viewModel, model) => {
          return observable.pipe(
            debounceTime(400),
          ).switchMap((term) => {
            let filter = (asTemplate.additional_filter) ? asTemplate.additional_filter : {};
            let termFilter = {
              global_search: { op: 'eq', value: term }
            };
            return this.listService.fetchDataAdvanceSearch({}, asTemplate.remoteModule, {...filter, ...termFilter})
              .map((response) => {
                return {
                  response: this.updateRecordData(response['data'], asTemplate.remoteModule),
                  term: term,
                };
              });
          });
        };
      }
    });
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  ngOnChanges() {
    this.refreshPage();
  }

  ngOnDestroy() {
    // Get stored filter
    let arListingStorage = (this.localStorageService.getItem('listing')) ? JSON.parse(this.localStorageService.getItem('listing')) : {};
    let hasGlobalSearch = !isEmpty(arListingStorage) && filled(get(arListingStorage, [this.strModule, 'filter', 'global_search']));

    if (hasGlobalSearch) {
      delete arListingStorage[this.strModule]['filter']['global_search'];
    }

    this.localStorageService.setJsonItem('listing', arListingStorage);
  }

  refreshPage() {
    // From individual module list components
    this.listService.setTableHeaderFields(this.objTableHeader);
    this.listService.setModule(this.strModule);

    // From Constructor
    // Check if module has view
    this.bViewLink = (this.arNoViewModule.indexOf(this.strModule) > -1) ? true : false;
    // Set Icon for listing title
    this.arIcon = ModuleLogo[this.strModule];
    // Store first column name
    this.strIndexName = this.objTableHeader[0]['label'];

    Object.keys(this.objTableHeader).forEach( item => {
      if (this.objTableHeader[item]['sortable'] == true) {
        this.arSortOption.push(new Common(this.objTableHeader[item]['label'], this.objTableHeader[item]['id'], ''));
      }
    });
    // Generate model for filter input box.
    this.generateModel();
    //Get the fields to filter in the list.
    this.arFilters = this.listService.getDefaultFilters();
    // Merge the module default filter to common default filter
    this.arFilters = this.arFilters.concat(this.listService.getModuleDefaultFilter());
    // Check if the record list has action buttons
    this.bNoAction = (this.arNoActionModule.indexOf(this.strModule) > -1) ? true : false;
    // Check if module is not allowed to add
    this.bAdd = (this.arNoAddModule.indexOf(this.strModule) > -1) ? false : true;
    // check if module should display download button
    this.hasDownloadAction = (this.downloadAction.indexOf(this.strModule) > -1) ? true : false;

    if (this.arModulesDeleteNotAllowed.includes(this.strModule)) {
      this.bAllowedDelete = false;
    }

    // From ngInit
    // Clear this to remove save and clear when transitioning to the new list view
    this.modelAs = {};
    this.listService.obvSearchItem$.subscribe(
      objSearch => {

        if (objSearch['strModule'] == this.strModule) {
          this.searchItemName(objSearch['strSearch']);
        }

      }
    );

    // Waiting to pass filter from information section (Metrics)
    this.listService.obvInformationFilter$.subscribe( objFilter => {
      if (typeof objFilter === 'object') {
        this.modelAs = objFilter;
        this.filterRecord('go', null, true);
      } else if (typeof objFilter === 'string') {
        this.setFilter(objFilter, 'saved_filter', true);
      }
    });

    this.form = new FormGroup({});

    // this.form.valueChanges.pipe(
    //   debounceTime(1000),
    //   distinctUntilChanged()
    // ).subscribe((res) => {
    //   this.getData('default');
    // });

    // From ngAfterViewInit
    // Setup advance searchbox params
    const metadata$ = this.listService.getAdvancedSearchboxTemplate();
    const filters$ = this.listService.getModuleFilters();
    forkJoin([metadata$, filters$]) .subscribe(([template, filters]) => {
      this.arSavedFilters = filters;
      this.setupAdvanceSearchbox(template);

      // Setup sortable
      this.arSortOption = [];
      // Sortable type
      const sortableType = [
        'smallint',
        'integer',
        'float',
        'double precision',
        'numeric',
        'date',
        'timestamp without time zone',
        'datetime',
        'number',
        'currency',
        'checkbox',
      ];
      template.forEach( field => {
        if (sortableType.includes(field.dataType)) {
          this.arSortOption.push(new Common(field.label, field.model, ''));
        }
      });
      this.route.queryParams.subscribe( params => {
        // Get saved filter from local storage
        const arListingStorage = (this.localStorageService.getItem('listing')) ? JSON.parse(this.localStorageService.getItem('listing')) : {};

        if (params['add_form'] == 'open') {
          this.goToAddLink(params);
        }

        if (params['filter'])  {
          this.modelAs = JSON.parse(params['filter']);
        }

        if (params['module_search']) {
          let searchValue = Array.isArray(params['module_search']) ? params['module_search'][0] : params['module_search'];
          this.modelAs['global_search'] = {
            'op': 'eq',
            'value': searchValue,
          }

          if (!isEmpty(this.advancedSearchboxComponent)) {
            this.advancedSearchboxComponent.searchBoxControl.setValue(
              {
                'op': 'eq',
                'label': searchValue,
                'value': searchValue,
              }
            );
          }
        }

        if (params['filter'] || params['module_search']) {

          this.setDefaultSort(arListingStorage);
          this.getData('default');
          return;
        }

        if (params.trigger !== undefined) {
          return;
        }
        // Check if page nnumber is set
        const pageNum = Math.abs(parseInt(params.page));
        if (Number.isNaN(pageNum) === false) {
          this.listService.setCurrentPageNum(pageNum);
        }

        this.setDefaultSort(arListingStorage);

        // Do we have existing saved filter?
        if (arListingStorage[this.strModule] && arListingStorage[this.strModule]['filter'] !== undefined) {
          // Set filter
          const filterName = arListingStorage[this.strModule]['filter_name'];
          if (filterName !== undefined && filterName !== null && filterName !== "") {
            // Filter from defined filters
            this.setFilter(filterName, 'saved_filter', false);
          } else {
            // Filter from storage
            this.modelAs = arListingStorage[this.strModule]['filter'];
            this.changeDetectorRef.detectChanges();
            this.getData(Number.isNaN(pageNum) ? 'default' : 'reload', null);
          }
        } else {
          this.getData(Number.isNaN(pageNum) ? 'default' : 'reload', null);
        }
      });
    });
    this.bViewLoaded = true;
  }

  generateModelOptions() {
    this.objTableHeader.forEach( objFieldData => {
      if (objFieldData['option'] != "") {
        //Initialize the models for the dropdowns.
        this.arDropdownModels[objFieldData['id']] = {};
        //Assign the dropdown options to an array.
        this.arDropdownOptions[objFieldData['id']] = this.getTableHeaderOption(objFieldData['id']).map(
          item => {
            //Assign the models value. (false since its a checkbox).
            this.arDropdownModels[objFieldData['id']][item.id] = false;

            //Return just to keep
            return item.id;
          }
        );

      }
    });
  }

  // Create model
  generateModel(strType = '') {
    // Loop table header
    this.objTableHeader.forEach( objFieldData => {

      // If id is object get the first id
      let strId = (typeof(objFieldData.id) == 'object') ? objFieldData.id[0] : objFieldData.id;
      // Reset current object
      this.arModels[strId] = '';
    });
    // Exclude order by when default or save filter on clear
    if (strType != 'default_filter' && strType != 'saved_filter') {
      // Add default order_by field
      this.arModels['order_by'] = this.listService.defaultOrderBy || {
        id: 'updated_at',
        sort: 'desc'
      };
    }
    // Set filter user share to false
    if (this.arModels['share'] != undefined) {
      this.arModels['share'] = false;
    }
    // Add addition filter id
    this.arModels['default_filter'] = '';
    this.arModels['hasFilter'] = false;
    this.arModels['filter_name'] = '';
  }

  /**
   * Builds the dropdown checkbox filters
   * in the filter object.
   */
  generateDropdownFilter() {
    //Loop through the models and find the checked checkboxes.
    Object.keys(this.arDropdownModels).forEach(strKey => {
      //Find the real id of the column instead of the option name.
      let strDropdownId = this.objTableHeader.find(item => (item.option == strKey))['id'];
      //Assign the dropdown checked settings in the armodel for API to check.
      this.arModels[strDropdownId] = Object.keys(this.arDropdownModels[strKey]).filter(
        item => {
          //If its checked (true), then proceed on giving to the arModel.
          if (this.arDropdownModels[strKey][item]) {
            return item;
          }
        }
      );
    });

    this.listService.setFilter(this.arModels);
    this.refreshList();
  }

  // Create model
  getModelId(strId) {
    // Check if id exist on the generated model
    return (typeof(strId) == 'object') ? strId[0] : strId;
  }

  getDropdownModelId(strId) {
    // Check if id exist on the generated model
    return (typeof(strId) == 'object') ? strId[0] : strId;
  }

  // Calls a dialog form for creating a record in the current module
  callDialogCreate(strCurrentModule: string, objParams: LooseObject = {}) {
      let arComponents = {
          'document_library' : UploadDocumentDialogComponent,
          'checklists' : CreateChecklistComponent,
          'sms_templates': SaveComponent,
          'exports': ExportFormComponent
      }

      // Popup for adding current module
      let tabDialogRef = this.dialog.open(arComponents[strCurrentModule], {
          width: '600px',
          height: 'auto',
          disableClose: true,
          data: objParams ? objParams : {}
      });

      tabDialogRef.afterClosed().subscribe(
          response => {
            this.refreshList();
          }
      );
  }

   /**
    * Checks if add will use dialog or link
    *
    * @todo Update logic so that instead of conditionally checking
    * the module to determine an action, we use the [newRecord] input
    * instead.
    *
    * @returns {void}
    */
  goToAddLink(objParams?: LooseObject): void {

      if (this.strModule == 'users') {
          this.router.navigate(["admin/users/invites"]);
      } else if (this.strModule == 'imports') {
        this.newRecord(objParams);
      } else if (this.strModule == 'project_templates') {
          // Set create mode
          this.projectTemplateService.setMode('create');
          // If module is project template, open a new page for adding a new job template
          this.router.navigate(['admin/project_templates/create']);

        } else if(this.strModule == 'customer_invoices') {
        // Create the object to be passed inside the dialog.
        let objData = {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            padding: '1%',
            disableClose: true,
            // The id of the jobs, the invoice's "parent".
            data: {
              record_id : '',
              view_type : 'add',
              ...objParams
            }
        };

        // Initialize the dialog.
        let dialogRef = this.dialog.open(EditInvoiceComponent, objData);

        dialogRef.afterClosed().first().subscribe(saveRecordData => {
            if (saveRecordData != undefined && saveRecordData.action == 'save') {
              this.notifService.notifySuccess('header_notification.success_added');
              if (saveRecordData['response'] && saveRecordData['response']['id']) {
                this.gotoRecordView(saveRecordData['response']['id']);
              }
            }
        });
      } else if(this.strModule == 'purchase_orders') {
        // Create the object to be passed inside the dialog.
        let objData = {
          maxWidth: '100%',
          width: '100%',
          height: 'auto',
          padding: '1%',
          disableClose: true,
          // The id of the jobs, the invoice's "parent".
          data: {
            record_id : '',
            view_type : 'add'
          }
        };
        // Initialize the dialog.
        let dialogRef = this.dialog.open(EditPurchaseOrderComponent, objData);

        dialogRef.afterClosed().first().subscribe(saveRecordData => {
            if (saveRecordData && saveRecordData != 'fail') {
              this.notifService.notifySuccess('header_notification.success_added');
              if (saveRecordData['id']) {
                this.gotoRecordView(saveRecordData['id']);
              }
            }
        });
      } else if(this.strModule == 'supplier_invoices') {
        // Create the object to be passed inside the dialog.
        let objData = {
          maxWidth: '100%',
          width: '100%',
          height: 'auto',
          padding: '1%',
          disableClose: true,
          // The id of the jobs, the invoice's "parent".
          data: {
            record_id : '',
            view_type : 'add'
          }
        };
        // Initialize the dialog.
        let dialogRef = this.dialog.open(EditSupplierInvoiceComponent, objData);

        dialogRef.afterClosed().first().subscribe(saveRecordData => {
          if (saveRecordData.message == 'save') {
            this.notifService.notifySuccess('header_notification.success_added');
            if (saveRecordData['id']) {
              this.gotoRecordView(saveRecordData['id']);
            }
          }
        });
      } else if (this.arDialogModule.includes(this.strModule)) {
          // Calls a pop up form for creating a record in some modules
          this.callDialogCreate(this.strModule, objParams);

      } else if (this.strModule == "email_templates") {
        let popupConfig : {[k: string]: any} = this.getDialogConfig();

        let dialogRef = this.dialog.open(EditEmailTemplate, popupConfig);
        dialogRef.afterClosed().first().subscribe( response => {
          if (response) {
            this.notifService.notifySuccess('header_notification.success_added');
            setTimeout(() => this.refreshList(), 3500);
          }
        });
      } else if (this.strModule === 'recurring_invoices') {
        this
          .dialog
          .open(EditRecurringInvoiceFormComponent, {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            disableClose: true,
            data: {
              isNew: true,
              moduleID: null,
              moduleName: this.strModule,
              recordID: null,
              ...objParams
            },
          })
          .afterClosed()
          .pipe(
            first(),
            filter(response => response === 'save'),
            tap(() => this.refreshList()),
          )
          .subscribe();
      } else if (this.strModule === 'recurring_jobs') {
        this
          .dialog
          .open(EditRecurringJobsComponent, {
            maxWidth: '100%',
            width: '80%',
            height: 'auto',
            disableClose: true,
            data: {
              record_id: null,
              module: null,
              recurring_job: {},
              view_type: 'add',
              ...objParams
            },
          })
          .afterClosed()
          .pipe(
            first(),
            filter(response => !isNil(response) && response !== false),
            tap(() => this.refreshList()),
            tap(() => this.notifService.notifySuccess('header_notification.success_added')),
          )
          .subscribe(response => {
            if (response != 'fail' && response['id']) {
              this.gotoRecordView(response['id']);
            }
          });

      } else if (this.strModule === 'job_templates') {
        this
          .dialog
          .open(EditJobTemplatesComponent, {
            panelClass: 'height-auto-mat-dialog',
            maxWidth: '98%',
            maxHeight: '98%',
            width: '98%',
            height: 'auto',
            disableClose: true,
            data: {
              record_id: null,
              module: null,
              job_template: {},
              view_type: 'add',
            },
          })
          .afterClosed()
          .pipe(
            first(),
            filter(response => response !== undefined && response !== false),
            tap(() => this.refreshList()),
            tap(() => this.notifService.notifySuccess('header_notification.success_added')),
          )
          .subscribe();

      } else if (this.strModule === 'roles') {
        this.router.navigate(['admin/roles/add']);
      } else if (this.strModule === 'workflows') {
        this.router.navigate(['admin/workflow/form']);
      } else {
          // Open a record dialog
          this.recordDialog('', objParams);
      }

  }

  /**
   * Triggers when delete button is clicked
   *
   * @param strItemId
   */
  deleteLink(strItemId: string): void {
    this.notifService.sendConfirmation("confirm_delete").subscribe(
      confirmation => {
        if (confirmation.answer) {
          if (this.strModule === 'warehouses') {
            this.listService.fetchData(null, 'stock_levels', JSON.stringify({ warehouse_id: strItemId }))
              .subscribe(response => {
                if (response['data'].length > 0) {
                  this.notifService.notifyError('cannot_delete_warehouse');
                } else {
                  this.deleteRecord(strItemId);
                }
            });
          } else {
            this.deleteRecord(strItemId);
          }
        }
      }
    );
  }

  /**
   * archive the selected item
   *
   * @param strItemId
   */
  archiveLink(strItemId: string): void {
    this.dialog.open(ArchiveComponent, {
      width: '35%',
      data: {
        module: this.strModule
      }
    }).afterClosed().subscribe(
      response => {
        if (response.confirmation) {
          this.recordService.archiveRecord(this.strModule, strItemId, response.module).first().subscribe( response => {
            if (response.status == StatusCode.kResponseSuccess) {
              // Show notification update success
              this.notifService.sendNotification('archived', response.body.toString(), 'success');
            }
            this.refreshList();
          });
        }
    });
  }

  /**
   * When deleting record in items module, also
   * deletes related record in stock levels module.
   *
   * @param strItemId
   * @returns {void}
   */
  deleteItemInStockLevels(strItemId: string): void {
    this.recordService.getRecordRelateJoined('stock_levels', false, { 'items.id': strItemId })
    .pipe(
      switchMap(objStockLevelData => {
        const objStockLevelItemToBeDeleted = objStockLevelData[0];

        if (objStockLevelItemToBeDeleted) {
          return this.recordService.deleteRecord('stock_levels', objStockLevelItemToBeDeleted.id);
        } else {
          return Observable.of(false);
        }
      })
    )
    .subscribe(() => {
      this.continueRecordDeletion(strItemId);
    }, error => {
      if (error.error.id) {
        this.notifService.sendNotification('not_allowed', error.error.id[0], 'warning');
      }
    });
  }

  /**
   * Calls api which deletes record
   *
   * @param strItemId
   * @returns {void}
   */
  continueRecordDeletion(strItemId: string): void {
    this.recordService.deleteRecord(this.strModule, strItemId).first().subscribe(
      data => {
        // Store response body
        let arResponse = data.body;
        // Check if status is 200
        if (data.status == StatusCode.kResponseSuccess) {
          // Show notification update success
          this.notifService.sendNotification('deleted', arResponse.toString(), 'success');
        } else {
          var warning_message = (typeof arResponse === 'object' && arResponse['message']) ? arResponse['message'] : arResponse.toString();
          //Notify user if the update is a success.
          this.notifService.sendNotification('warning', warning_message, 'warning');
        }
        this.refreshList();
      },
      error => {
        if (error.error.id) {
          this.notifService.sendNotification('not_allowed', error.error.id[0], 'warning');
        }
      }
    );
  }

  /**
   * Prepares deletion of record from current module.
   *
   * If current module is items, deletes related data
   * in stock levels first before proceeding to deletion.
   *
   * @param strItemId
   * @returns {void}
   */
  deleteRecord(strItemId: string): void {
    if (this.strModule === 'items') {
      this.deleteItemInStockLevels(strItemId);
    } else {
      this.continueRecordDeletion(strItemId);
    }
  }

  /**
   * We display an object's key and value.
   * @param obj
   */
  objConverter(obj){
    if (obj) {
        return Object.keys(obj);
    }
  }

  /**
   * We check what type of data it is.
   * @param anyDataType
   */
  typeCheck(anyDataType){
    if (typeof anyDataType == 'object') {

      if (Array.isArray(anyDataType) && anyDataType.length != 0) {

        let arType = anyDataType;

        if (typeof arType[0] == 'string') {
          return 'string_array';
        }

        if (typeof arType[0] == 'object') {
          return 'object_array';
        }
      } else {
        return 'object';
      }
    }
  }

  /**
   * Filter record list
   *
   * @param   {string}  action      Pagination action
   * @param   {string}  filter      Selected filter
   * @param   {boolean} reset       Reset to First Page
   *
   * @return  {void}                Refresh the list view
   */
  filterRecord(action: string, filter = null, reset = false): void {
    let pageNum = null;
    switch (action) {
      case 'default':
      case 'go':
        pageNum = 1;
        break;
      case 'prev':
        pageNum = this.listService.getCurrentPageNum() - 1;
        break;
      case 'next':
        pageNum = this.listService.getCurrentPageNum() + 1;
        break;
      case 'with_filter':
        pageNum = this.listService.getCurrentPageNum();
        break;
      default:
        pageNum = 1;
        break;
    }
    // If reset flag is set to true. Return to first page
    if (reset) {
      pageNum = 1;
    }

    // Navigation Extras (Add Page number to the url)
    const navExtras: NavigationExtras = {
      queryParams: { page: pageNum },
      replaceUrl: true,
    }

    // Update list view
    const url = this.router.serializeUrl(this.router.createUrlTree([], navExtras));
    if (action === 'with_filter' || action === 'sort') {
      // Update the list view with defined filter and pagination
      this.location.replaceState(url);
      this.getData(reset ? 'default' : 'reload', filter);
    } else if (action === 'go') {
      // Run the search from the go button
      this.location.replaceState(url);
      this.getData('default');
    } else {
      // Update the list view with filter and pagination
      this.router.navigate([], {
        queryParams: {trigger: false}
      }).then(() => {
        this.router.navigate([], navExtras);
      });
    }
  }

  /**
   * Go from list view to record view
   *
   * @param   {string}  recordId     The record id of the current module
   * @param   {number}  recordIndex  The current index of the record
   *
   * @return  {void}                 Add additional state when navigating from list to record view
   */
  gotoRecordView(recordId: string, recordIndex: number = -1): void {
    if (this.strModule === 'pricebooks') {
      this.router.navigate([`/admin/pricebooks/edit/${recordId}`]);
    }

    if (!this.arNoViewModule.includes(this.strModule)) {
      let objQuery = {};

      if (recordIndex < 0) {
        objQuery = {...(this.strModule == 'opportunities' && {from: 'create_quote'}) }
      }

      if (recordIndex > -1) {
        this.router.navigate([recordId], {
          state: {
            fromListView: true,
            page: this.listService.getCurrentPageNum(),
            searchAfter: this.arSearchAfters[recordIndex],
          },
          queryParams: objQuery,
          relativeTo: this.route
        });
      } else {
        this.router.navigate([recordId], {
          state: {
            fromListView: false
          },
          queryParams: objQuery,
          relativeTo: this.route
        });
      }

    }
  }

  /**
   * Creates a URL that goes from list view to record view
   *
   * @param   {string}  strRecordId     The record id of the current item
   * @param   {number}  numRecordIndex  The index of the current item
   * @param   {LooseObject} objData
   *
   * @return  {string}
   */
  generateRecordUrl(strRecordId: string, numRecordIndex: number = -1, objData: LooseObject = {}): string {
    const params: LooseObject = {};
    if (this.strModule === 'stock_levels') {
      params['item_id'] = objData['item_id']
    }

    return this.listService.getListRecordURL(
      this.strModule,
      strRecordId,
      numRecordIndex,
      this.listService.getCurrentPageNum(),
      cloneDeep(this.arNoViewModule),
      cloneDeep(this.arSearchAfters),
      this.route,
      params
    );
  }

  /**
   * Retrieves the data from api depending on the
   * page number and render it to the listing page
   * @param numPage
   */
  async getData(strPage, objFilter = null){
    // Pause a bit to run the blur event from the advance searchbox
    await new Promise(resolve => setTimeout(resolve, 100));

    const objPagination = this.listService.beforeFetching(strPage);
    if (objFilter != null && Object.keys(objFilter).length) {
      objPagination['objFilters'] = objFilter;
    }
    // Get Sort params
    const sortParams = this.listService.getOrderBy();

    // Get Filter Params
    const filterParams = this.modelAs;

    // use latest searchbox value with each search
    if (!isEmpty(this.advancedSearchboxComponent) && !isEmpty(this.advancedSearchboxComponent.searchBoxValue)) {
      filterParams['global_search'] = {
        op: 'eq',
        value: get(this.advancedSearchboxComponent.searchBoxValue, 'value', this.advancedSearchboxComponent.searchBoxValue)
      }
    }
    // Predefined Filter
    const predefinedFilterName = objFilter === null ?
      null :
      typeof(objPagination['objFilters']) === 'object' ?
      (objPagination['objFilters']['filter_name'] !== undefined ? objPagination['objFilters']['filter_name'] : null) :
      objPagination['objFilters'] as string;

    // Get record list
    this.listService.fetchDataAdvanceSearch(
      objPagination['objPage'],
      this.strModule,
      filterParams,
      sortParams,
      predefinedFilterName,
      this.recordsPerPage
    ).pipe(
      debounceTime(100)
    ).subscribe( data => {
      this.initializePages(data['last_page']);
      // Get default display of listing
      this.arRowDisplay = data['row_display'];
      // Get all resource fetched from api
      this.arContent = data['data'];
      this.arSearchAfters = data['search_after'];
      // Get type of fields.
      // FC-2079: Change Object.assign to merge, because Object.assign is replacing all meta object values using options
      // while the merge is merging the metadata object values and option
      this.arContentType = merge(data['metadata']);
      // Set available filters
      this.arSavedFilters = data['available_filters'];
      // Set the selected filter
      const previousFilter = this.arSelectedFilter;
      this.arSelectedFilter = data['applied_filter'];
      // Set the disabled tooltip.
      this.setDisabledTooltip();

      this.listService.afterFetching(data, strPage);
      // If there is selected data, Store in arModels variables
      if (this.arSelectedFilter['config'] != undefined) {
        const oldFilterName = this.arModels['filter_name'];
        const oldHasFilter = this.arModels['hasFilter'];
        this.arModels = data['applied_filter']['config'];
        this.arModels['default_filter'] = '';
        this.arModels['filter_name'] = oldFilterName;
        this.arModels['hasFilter'] = oldHasFilter;
      } else if(JSON.stringify(this.modelAs) === '{}') {
        this.arModels['filter_name'] = '';
      } else if(previousFilter !== undefined) {
        this.arSelectedFilter = previousFilter
        this.arModels['filter_name'] = previousFilter['name'];
      }
      // Show if no result found.
      this.bNoResult = this.arContent.length > 0 ? false : true;
      // Set the sort
      this.arModels['order_by'] = data['order_by'];
      // Stop loading if data is loaded
      this.bLoading = false;

      if (Object.keys(this.arDropdownModels).length === 0) {
        this.generateModelOptions();
      }

      this.storeModuleFilter({
        page: objPagination['objPage'],
        filter: filterParams,
        sort: sortParams,
        filter_name: predefinedFilterName
      });

      // Set pagination flags
      this.hasNextPage = data['hasNextToken'];
      this.hasPreviousPage = data['hasPreviousToken'];
      this.fromRecords = data['from_records'];
      this.toRecords = data['to_records'];
      this.totalRecords = data['total_records'];

      this.showSaveAndClear();
    });
  }

  displayObjectKey(objToDisplay){
    let strKey = Object.keys(objToDisplay)[0];
    return strKey;
  }

  displayObjectValue(objToDisplay){
    let strValue = Object.values(objToDisplay)[0];
    return strValue;
  }


  generateFilter(strId = null) {
    let sortValue = 'asc';
    if (this.arModels['order_by'] !== undefined && this.arModels['order_by']['id'] === strId) {
      // If same value. Toggle between asc and desc
      sortValue = this.arModels['order_by']['sort'] === 'asc' ? 'desc' : 'asc';
    }
    const sortParams = {
      id: strId,
      sort: sortValue,
    };
    this.listService.setOrderBy(sortParams);
    this.listService.setFilter('');
    this.arModels['order_by'] = sortParams;
    this.changeDetectorRef.detectChanges();
    this.filterRecord('sort', this.listService.getFilter(), true);
  }

  // Clear all filters based on module
  clearAllFilters(strType = '') {
    this.modelAs = {};
    // Clear filter
    this.listService.setFilter('clear');
    this.generateModel();
    // do we have a order by set? lets clear it also
    if (this.arModels['order_by']) {
      this.arModels['order_by'] = {
        id: 'updated_at',
        sort: 'desc'
      };
      this.listService.setOrderBy(this.arModels['order_by']);
    }

    this.filterRecord('go');
  }

  saveCurrentFilter() {
    // If filter is shared and it is not yours. Do not allow to
    const arSelectedFilter = this.arSelectedFilter;
    if (arSelectedFilter['is_removable'] === false) {
      arSelectedFilter['id'] = null;
    }
    // When saving current filter exclude the default filter
    this.arModels['default_filter'] = '';
    let tabDialogRef = this.dialog.open(SavefilterdialogComponent, {
      width: '500px',
      height: 'auto',
      // Data to be passed on
      data: {
        "objFilterBody": {...this.arModels},
        "arLabels": this.objTableHeader,
        "arRelate": this.arFilterRelate,
        "arFilterOption": this.objTableHeaderOption,
        "arSelectedFilter": arSelectedFilter
      }
    });

    tabDialogRef.afterClosed().subscribe(
      saveFilterResult => {
        // Check if it is not empty or null
        if (saveFilterResult) {
          if (saveFilterResult && saveFilterResult['filter_body']['filter_name'] != undefined) {
            saveFilterResult['filter_body']['filter_name'] = saveFilterResult['filter_name'];
          }
          // Check if user has current selected filter
          if (this.arSelectedFilter) {
            // Include the id on the request
            saveFilterResult['id'] = this.arSelectedFilter['id'];
          }
          // Save current filter specified on the advance searchbox
          saveFilterResult['filter_body']['filter_structure'] = this.modelAs;
          // filter name and filter body is in saveFilterResult.
          this.listService.saveCreatedFilter(this.strModule, JSON.stringify(saveFilterResult)).subscribe(
            data => {
              this.arModels['filter_name'] = data['filter_name'];
              this.listService.setFilter(data['filter_name']);
              this.setFilter(data['filter_name'], 'saved_filter', true);
              this.notifService.notifySuccess("filter_successfully_saved");
          }, (err: HttpErrorResponse) => {
            this.notifService.notifyError(err.error.message);
          });
        }
      }
    );

  }

  createRecordFilter() {
    let popupConfig : {[k: string]: any} = {
      //Here, we pass all the data we need inside the dialog.
      data: {
        "arFilterModel": this.arModels,
        "arFilterList": this.objTableHeader,
        "arFilterOption": this.objTableHeaderOption,
        "arSelectedFilter": this.arSelectedFilter,
        'strCurrentModule': this.strModule
      }
    };

    // IF MOBILE
    if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
      // Display the pop up in full screen (WHOLE PAGE)
      popupConfig.width = '100%';
      popupConfig.height = '100%';
      popupConfig.maxHeight = '100vh';
      popupConfig.maxWidth = '100vw';
    } else {
      // display as pop up
      popupConfig.width = '60%';
      popupConfig.height = 'auto';
    }

    let createFilterDialog = this.dialog.open(FilterdialogComponent, popupConfig);

    createFilterDialog.afterClosed().subscribe( saveFilterResult => {
      // Check if filter has value
      if (saveFilterResult) {
        if (saveFilterResult == 'refresh_delete') {
          this.clearAllFilters();
        } else {
          // When creating a custom filter exclude default filter
          this.arModels['default_filter'] = '';
          // Store relate values
          this.arFilterRelate = (saveFilterResult['relate']) ? saveFilterResult['relate'] : this.arFilterRelate;
          // Check if type is save or update
          if (saveFilterResult['type'] == 'save' || saveFilterResult['type'] == 'update') {
            this.saveCurrentFilter();
          } else {
            // Set created filter
            this.setFilter(saveFilterResult['filter'], 'saved_filter');
          }
        }
      }

      this.quickFilterComponent.resetForm();
    });
  }

  showSaveAndClear() {
    // Default value
    this.bSaveButton = false;
    this.bClearButton = false;
    // Check if there's filter
    Object.keys(this.arModels).forEach(
      item => {
        // is there any input for filter?
        if (this.arModels[item]) {
          if (Array.isArray(this.arModels[item])) {
            this.bSaveButton = true;
            this.bClearButton = true;
          } else if (typeof(this.arModels[item]) == 'object') {
            // if id has value return true
            this.bSaveButton = (this.arModels[item]['id']) ? true : this.bSaveButton;
            this.bClearButton = (this.arModels[item]['id']) ? true : this.bClearButton;
          } else {
            this.bSaveButton = true;
            this.bClearButton = true;
          }
        }
      }
    );

    // Check if there is selected filter
    if (this.arCurrentFilter['id']) {
      this.bClearButton = true;
    } else {
      // Check if there is a selected filter
      this.bSaveButton = (this.arSelectedFilter['id']) ? false : this.bClearButton
      // Check if there is a selected filter
      this.bClearButton = (this.arSelectedFilter['id']) ? true : this.bClearButton
    }
  }

  getAvailableFilters() {
    // Refresh list of available filters
    this.listService.fetchAvailableFilters(this.strModule).subscribe (
      availableFilters => {
        this.arSavedFilters = availableFilters;
      }
    );
  }

  searchItemName(strSearch) {
    // Get first column in the listing: can be string or object
    let columnId = this.objTableHeader[0]['id'];
    // Check if column id if string or object
    switch (typeof(columnId)) {
      case 'string':
        // Set the value based on column id
        this.arModels[columnId] = strSearch;
      break;
      case 'object':
        // Loop the column id
        columnId.forEach( strId => {
          // Set the value on each id
          this.arModels[strId] = strSearch;
        });
      break;
    }
    // Do we have contacts module?
    if (this.strModule == 'contacts') {
      this.arModels['email_address'] = strSearch;
    }
    // Set filter
    this.listService.setFilter(this.arModels);
    // Call API for getting data and set to page 1
    this.refreshList();
  }

  getTableHeaderOption(strOptionId): Common[] {
    // Check if id exist in list of dropdown
    return (this.objTableHeaderOption[strOptionId] != undefined) ? this.objTableHeaderOption[strOptionId] : [new Common('', 'no_available_filter', '')];
  }

  displaySort(objFieldData) {
    // Check if sortable is true
    if (objFieldData.sortable == true) {
      // Get current id
      let strId =  (typeof(objFieldData.id) == 'string') ? objFieldData.id : objFieldData.id[0];
      return (strId == this.arModels['order_by'].id) ? true : false;
    }
    // return false if not sortable
    return false;
  }

  getRowValue(key, value) {
    // Check if key is object
    if (typeof(key) == 'object') {
      // Where can we push each values
      let arVales: string[] = [];
      // Loop each id and get its value
      key.forEach(
        item => {
          arVales.push(value[item]);
        }
      );
      // Return string using join function with separator of 1 space
      return arVales.join(' ');
    } else {
      // Get current value
      let strValue = value[key];
      // Check if key is a dropdown type
      if (this.objTableHeaderOption[key]) {
        // Store drodpdown list
        let objDropdownList: any = this.objTableHeaderOption[key];
        // Get value from the dropdown list
        objDropdownList.forEach( item => { if (item.id == strValue) strValue = item.value;  });
      }

      // Return the value
      return strValue;
    }
  }

  /**
   * To tell if edit will use dialog or link.
   *
   * @param - the id of record if edit.
   */
  goToEdit(strId, arData = []) {
      if (this.strModule == 'pricebooks') {
        this.router.navigate(['admin/pricebooks/edit/' +strId]);
      } else if (this.strModule == 'teams') {
        this.router.navigate(['admin/teams/' + strId]);
      } else if (this.strModule == 'project_templates') {
        this.projectTemplateService.setMode('update');
        this.projectTemplateService.setProjectTemplate(arData);
        // If module is project template, open a new page for editing a job template
        this.router.navigate(['admin/project_templates/edit']);
      } else if (this.strModule == 'checklists') {
        this.router.navigate(['admin/checklists/edit/' + strId]);
      } else if(this.strModule == 'customer_invoices') {
        // Append record details to module config to get the latest data
        // Create the object to be passed inside the dialog.
        let objData = {
          maxWidth: '100%',
          width: '100%',
          height: 'auto',
          padding: '1%',
          disableClose: true,
          // The id of the jobs, the invoice's "parent".
          data: {
            record_id : '',
            invoice: [],
            customer_invoice_id: arData['id'],
            view_type : 'edit'
          }
        };
        // Initialize the dialog.
        let dialogRef = this.dialog.open(EditInvoiceComponent, objData);
        dialogRef.afterClosed().first().subscribe(saveRecordData => {
          if (saveRecordData != undefined && saveRecordData.action == 'save') {
            this.notifService.notifySuccess('header_notification.success_update');
            if (arData['id']) {
              this.getRecord(arData['id']);
            } else {
              this.refreshList();
            }
          }
        });
      } else if(this.strModule == 'purchase_orders') {
        // Create the object to be passed inside the dialog.
        let objData = {
          maxWidth: '100%',
          width: '100%',
          height: 'auto',
          padding: '1%',
          disableClose: true,
          // The id of the jobs, the invoice's "parent".
          data: {
            record_id : '',
            purchase_order: [],
            purchase_order_id: arData['id'],
            view_type : 'edit'
          }
        };
        // Initialize the dialog.
        let dialogRef = this.dialog.open(EditPurchaseOrderComponent, objData);
        dialogRef.afterClosed().first().subscribe(saveRecordData => {
          if (saveRecordData && saveRecordData != 'fail') {
            this.notifService.notifySuccess('header_notification.success_update');
            if (arData['id']) {
              this.getRecord(arData['id']);
            } else {
              this.refreshList();
            }
          }
        });
      } else if(this.strModule == 'supplier_invoices') {
            // Create the object to be passed inside the dialog.
            let objData = {
              maxWidth: '100%',
              width: '100%',
              height: 'auto',
              padding: '1%',
              disableClose: true,
              // The id of the jobs, the invoice's "parent".
              data: {
                record_id : '',
                supplier_invoice: [],
                supplier_invoice_id: arData['id'],
                view_type : 'edit'
              }
            };
            // Initialize the dialog.
            let dialogRef = this.dialog.open(EditSupplierInvoiceComponent, objData);
            dialogRef.afterClosed().first().subscribe(saveRecordData => {
              if (saveRecordData.message == 'save') {
                this.notifService.notifySuccess('header_notification.success_update');
                if (arData['id']) {
                  this.getRecord(arData['id']);
                } else {
                  this.refreshList();
                }
              }
            });
      } else if (this.strModule == "email_templates") {

        let popupConfig : {[k: string]: any} = this.getDialogConfig(arData);

        let dialogRef = this.dialog.open(EditEmailTemplate, popupConfig);
        dialogRef.afterClosed().first().subscribe( response => {
          if (response) {
            this.notifService.notifySuccess('header_notification.success_update');
            this.refreshList();
          }
        });
      } else if (this.strModule == 'sms_templates') {
        let dialogRef = this.dialog.open(SaveComponent, {
          width: '600px',
          disableClose: true,
          data: arData
        });

        dialogRef.afterClosed().first().subscribe(saveRecordData => {
          if (saveRecordData === 'save') {
            this.refreshList();
          }
        });
      } else if (this.strModule === 'recurring_invoices') {
        this
          .dialog
          .open(EditRecurringInvoiceFormComponent, {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            disableClose: true,
            data: {
              isNew: false,
              moduleID: arData['id'],
              moduleName: this.strModule,
              recordID: arData['id'],
            },
          })
          .afterClosed()
          .subscribe();
      } else if (this.strModule === 'recurring_jobs') {
        this
          .dialog
          .open(EditRecurringJobsComponent, {
            maxWidth: '100%',
            width: '80%',
            height: 'auto',
            disableClose: true,
            data: {
              record_id: null,
              module: null,
              recurring_job: arData,
              recurring_job_id: arData['id'],
              view_type: 'edit',
            },
          })
          .afterClosed()
          .pipe(
            first(),
            filter(response => !isNil(response) && response !== false),
            tap(() => this.refreshList()),
            tap(() => this.notifService.notifySuccess('header_notification.success_update')),
          )
          .subscribe();

      } else if (this.strModule === 'job_templates') {
        this
          .dialog
          .open(EditJobTemplatesComponent, {
            panelClass: 'height-auto-mat-dialog',
            maxWidth: '98%',
            maxHeight: '98%',
            width: '98%',
            height: 'auto',
            disableClose: true,
            data: {
              record_id: null,
              module: null,
              job_template: arData,
              job_template_id: arData['id'],
              view_type: 'edit',
            },
          })
          .afterClosed()
          .pipe(
            first(),
            filter(response => response !== undefined),
            tap(() => this.refreshList()),
            tap(() => this.notifService.notifySuccess('header_notification.success_update')),
          )
          .subscribe();

      } else if (this.strModule === 'stock_levels') {
        this.router.navigate(['stock_levels/' + strId], {queryParams: {item_id:arData['item_id']}});
      } else if (this.strModule === 'roles') {
        this.router.navigate(['admin/roles/', strId]);
      } else if (this.strModule === 'workflows') {
        this.router.navigate(['admin/workflow/form/' + strId]);
      } else {
        // Use dialog
        this.recordDialog(strId, arData);
      }
  }

  /**
   * Open dialog to edit record.
   * @param - the id of record if edit.
   */
  recordDialog(strId, arData = {}) {
    let objPopupConfig: FormPopup = new FormPopup(
      this.strModule,
      {},
      arData,
      strId,
      (strId) ? 'edit' : 'add'
    );

    // Set default form for dialog
    this.strComponent = EditformComponent;

    // If document library, set Update Document as Component for dialog
    if (this.strModule == 'document_library') {
      objPopupConfig.data = arData;
      objPopupConfig.width = '600px',
      this.strComponent = UpdateDocumentDialogComponent;
    } else if (this.strModule === 'sms_templates') {
      objPopupConfig.data = arData;
      objPopupConfig.width = '600px',
      this.strComponent = SaveComponent;
    }


    // This line initializes and opens dialog.
    let editRecordDialog = this.dialog.open(this.strComponent, objPopupConfig);

    editRecordDialog.afterClosed().first().subscribe(editRecordData => {
      if (editRecordData !== undefined && editRecordData.status == 'save') {
        if (arData['id']) {
          this.getRecord(arData['id']);
        } else {
          // trigger event dialog
          this.evtListingDialog.emit({
            mode: 'form',
            action: 'close',
            dataStatus: 'save'
          });

          if (editRecordData['data'] && editRecordData['data']['id']) {
            this.gotoRecordView(editRecordData['data']['id']);
          }

        }
      }
    });

  }

  /**
   * Set the filter based on the filter type
   *
   * @param {string | []}  - Selected filter or created filter can be string or array
   * @param {string}  - Filter type
   * @param {boolean} - Default is false, if true we need to reset the model before applying the new filter
   * @param {boolean} - If setting the filter should remove quick filter form contents.
   */
  setFilter(filter: string | [], strType: string, bReset: boolean = false, bResetQuickFilter: boolean = false) {

    // Declare variable
    let currentFilter: any = '';
    // Do we have filters?
    if (filter) {
      // Check filter type
      switch(strType) {
        case 'default_filter':
          if (strType == 'default_filter' && Object.keys(this.route.snapshot.queryParamMap['params']).length > 0) {

            // Set filter
            let objFilterFromLink = {...this.route.snapshot.queryParamMap['params']};
            let strLabel = objFilterFromLink['label'];
            delete objFilterFromLink['label'];

            // Set the default filter  in models.
            this.arModels['default_filter'] = '';
            // Clear filter name in default filter
            this.arModels['filter_name'] = strLabel;

            currentFilter = objFilterFromLink;
          } else {
            // Set the default filter  in models.
            this.arModels['default_filter'] = filter;
            // Clear filter name in default filter
            this.arModels['filter_name'] = '';
            // Set filter
            currentFilter = this.arModels;
          }
          break;
        case 'saved_filter':
          // Set filter
          currentFilter = filter;
          this.arModels['filter_name'] = filter;
          if (bReset) {
            this.listService.setOrderBy({});
          }
          // Get searchbox model from predefined filter
          let filterData = null;
          if (this.arSavedFilters.default[currentFilter] !== undefined) {
            filterData = this.arSavedFilters.default[currentFilter];
          } else if (this.arSavedFilters.saved[currentFilter] !== undefined) {
            filterData = this.arSavedFilters.saved[currentFilter];
          }
          if (filterData !== null) {
            this.modelAs = filterData.config.filter_structure;
          }
          break;

        case 'set_filter':
          Object.keys(filter).forEach( strFilterKey => {
            // Check if filter has value
            if (filter[strFilterKey]) {
              // Set the filter in
              this.arModels[strFilterKey] = filter[strFilterKey];
            }
          });
          // Clear default filter
          if (typeof(filter) != 'object') {
            this.arModels['default_filter'] = '';
          }
          // When sort is trigger check if has selected filter then set it to arModels
          if (this.arSelectedFilter['config'] != undefined) {
            Object.keys(this.arSelectedFilter['config']).forEach( index => {
              if (index != 'order_by') {
                this.arModels[index] = this.arSelectedFilter['config'][index];
              }
            });
          }

          currentFilter = this.arModels;
        break;

        default:
          this.arModels['filter_name'] = '';
          // Set filter
          currentFilter = filter;
        break;
      }
    }

    // If filter is object and filter key has value set hasFilter to true
    Object.keys(filter).forEach( strFilterKey => {
      // Check if filter has value
      if (filter[strFilterKey] && strFilterKey != 'order_by') {
        this.arModels['hasFilter'] = true;
      }
    });

    // Set filter
    this.listService.setFilter(currentFilter);
    // Call API for getting data with filter
    this.filterRecord('with_filter', filter, bReset);
  }

  /**
   * Save the last filter that user created or selected
   *
   * @param object - Filter data
   */
  storeModuleFilter(filterData: LooseObject) {
    // Get stored filter
    const arListingStorage = (this.localStorageService.getItem('listing')) ? JSON.parse(this.localStorageService.getItem('listing')) : {};
    // Update the filter
    arListingStorage[this.strModule] = filterData;
    this.localStorageService.setJsonItem('listing', arListingStorage);
  }

  /**
   * Check field for minimize the width
   * @param strField - Current field
   */
  checkStatusField(strField) {
    let arField = [
      'status',
      'sync_to_accounting',
      'on_hold',
      'disable_maintenance',
      'is_active',
      'is_productive',
    ];
    return (typeof strField[0] != 'undefined' && arField.includes(strField[0])) ? true : false;
  }
  // Event resize to get the current window size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  /**
	 * Get record module config field
	 */
  getModuleConfig() {
    this.recordService.getRecordConfig(this.strModule).first().subscribe(
      result => {
        this.objModuleConfig = result;
      }
    );
  }
  /**
   * Compiled all related data to be shown in tooltip
   * @param relatedData - related data to be shown
   */
  compiledRelatedData(relatedData) {
    let invoiceNumber = [];
    relatedData.forEach( data => {
      invoiceNumber.push(data['text']);
    });

    return invoiceNumber.join(", ");
  }

  /**
   * Set the tooltip depending on the module.
   */
  setDisabledTooltip() {
    // Set the tooltip for disabled records.
    if (this.strModule == 'leads') {
      this.strTooltipDisabled = 'disabled_edit_leads';
    }
  }

  /**
   * Simply refreshes the list page and go to page 1
   *
   * @return {void}
   */
  refreshList = (): void => {
    this.filterRecord('default');
  }

  /**
   * get record
   *
   * @returns {void}
   */
  getRecord(id: string): void {
    this.recordService.getRecord(this.strModule, id, true, {}, 'list').subscribe( response => {
      if (response['record_details']) {
        this.listService.setUpdatedRecord(response['record_details']);
        this.updateListingData(response['record_details']);
      }
    });
  }

  /**
   * update listing data after the record update
   *
   * @returns {void}
   */
  updateListingData(record: object): void {
    this.arContent.forEach( (data, index) => {
      if (data['id'] == record['id']) {
        // When record is updated. We need to remove updated record in arSelectedRecords.
        let selectedRecordIndex = this.arSelectedRecords.findIndex(selectedRecord => selectedRecord['id'] === record['id']);

        if (selectedRecordIndex > -1) {
          this.arSelectedRecords.splice(selectedRecordIndex, 1);
        }

        this.arContent[index] = record;
      }
    });
  }

 /**
  * returns the dialog config
  *
  * @param data
  */
 getDialogConfig(data: object = {}): {[k: string]: any} {
   let popupConfig : {[k: string]: any} = {
     disableClose: true,
     data: data
   };
   if(window.innerWidth <= 800 && window.innerHeight <= 1024) {
     popupConfig.width = '100%';
     popupConfig.height = '100%';
     popupConfig.maxHeight = '100vh';
     popupConfig.maxWidth = '100vw';
   } else {
     popupConfig.width = '40%';
     popupConfig.height = 'auto';
   }
   return popupConfig;
  }

  /**
   * Remove item.
   */
  deleteFilter(filter) {
    // Pop-up modal for confirmation
    this.notifService.sendConfirmation('filter_delete_confirm').subscribe(confirmation => {
      // If the user confirm to delete
      if (confirmation.answer) {
        this.recordService.deleteFilter(this.strModule, filter['id']).subscribe(response => {
          const strResponse: any = response['body'];
          this.notificationService.notifySuccess(strResponse);
          if (this.arModels['filter_name'] === filter['name']) {
            this.clearAllFilters();
          } else {
            delete this.arSavedFilters['saved'][filter['name']];
          }
        });
      }
    });
  }

  triggerFilter(event: Event) {
    this.isFilterChanged = true;
    this.filterRecord('go');
  }

  /**
   * This will add and remove the selected record data
   * @param event
   * @param recordData
   */
  selectRecordChange(event, recordData) {
    if (event.target.checked) {
      this.arSelectedRecords.push(recordData);
    } else {
      this.arSelectedRecords.splice(this.arSelectedRecords.findIndex(record => record['id'] === recordData['id']), 1);
    }
  }

  /**
   * It will open the merge dialog
   */
  openMergeDialog() {
    let bHasConvertedRecord = false;
    let bHasInvoice = false;
    let excludedModuleForMergingRecords: Array<string> = [
      'customer_invoices', 'supplier_invoices', 'purchase_orders',
      'users', 'asset_types', 'checklists', 'document_library',
      'project_templates', 'job_safety_sub_task_templates', 'items',
      'account_codes', 'tax_codes', 'pricebooks', 'recurring_invoices',
      'stock_levels', 'warehouses', 'job_templates',
    ];

    let arInvoicingType = this.arrService.getUniqueValues(this.arSelectedRecords, 'invoicing_type');

    this.arSelectedRecords.forEach( arRecord => {
      if (this.strModule === 'leads' && (arRecord['status'] === 'converted' || arRecord['status'] === 'disqualified')) {
        bHasConvertedRecord = true;
      }

      if (this.strModule === 'jobs' && (arRecord['has_invoice'])) {
        bHasInvoice = true;
      }
    });

    if (this.arSelectedRecords.length < 2 || this.arSelectedRecords.length > 3) {
      this.notifService.notifyWarning('merge_selected_record_warning');
    } else if(excludedModuleForMergingRecords.includes(this.strModule)) {
      this.notifService.notifyWarning('merging_record_not_allowed');
    } else if (bHasConvertedRecord) {
      this.notifService.notifyWarning('merging_lead_record_not_allowed');
    } else if(arInvoicingType.length > 1 && bHasInvoice) {
      this.notifService.notifyWarning('merging_job_record_not_allowed');
    } else {
      let popupConfig = {
        data: {
          records: this.arSelectedRecords,
          module: this.strModule,
        },
        disableClose: true,
        maxWidth: '100%',
        width: '99%',
        height: '99%',
        padding: '1%',
      };

      let dialogRef = this.dialog.open(MergeRecordComponent, popupConfig);
      dialogRef.afterClosed().pipe(
      first(),
      filter((response) => response.action === 'success')
      ).subscribe(() => {
          this.arSelectedRecords = [];
          this.notifService.notifySuccess('merge_success');
          this.refreshList();
      });
    }
  }

  isRecordSelected(arRecordData) {
    return this.arSelectedRecords.findIndex(selectedRecord => selectedRecord['id'] === arRecordData['id']) > -1;
  }

  /**
   * set default sorting
   *
   * @param arListingStorage
   */
  setDefaultSort(arListingStorage): void {
    // Do we have an existing sort
    if (arListingStorage[this.strModule] && arListingStorage[this.strModule]['sort'] !== undefined) {
      this.listService.setOrderBy(arListingStorage[this.strModule]['sort']);
      this.arModels['order_by'] = arListingStorage[this.strModule]['sort'];
    } else {
      // FC-4050: Fix the issue when there is no cached in selected module, it uses the previous module sort config
      // When there is no default sort order in the listing store, use the current model sort config
      this.listService.setOrderBy(this.arModels['order_by']);
    }
  }

  /**
   * generate a set of array that will be showed in listing pagination
   *
   * @param totalPage
   */
  initializePages(totalPage: number): void {
    let maxPageAllowed = 10000/this.recordsPerPage;
    let isMoreThanMaxRecords = maxPageAllowed <= totalPage;
    let maxTotalPage = isMoreThanMaxRecords ? maxPageAllowed : totalPage;
    this.arNavigationPage = [];
    this.lastPage = maxTotalPage;
    let pageList: number[] = [];
    let numberOfPage = 5
    for (let startPage = 1; startPage <= maxTotalPage; startPage++) {
      pageList.push(startPage);
    }

    let pageStart: number = (this.currentPage-3 > 0 && maxTotalPage > 5) ? this.currentPage-3 : 0;
    let pageEnd: number =  (this.currentPage+2 > numberOfPage) ? this.currentPage+2 : numberOfPage;
    if (this.lastPage - pageEnd < 0  && maxTotalPage > numberOfPage) {
      pageStart = pageStart + maxTotalPage - pageEnd;
    }

    this.arNavigationPage = pageList.slice(pageStart, pageEnd);

    if (this.isFilterChanged && isMoreThanMaxRecords) {
      this.notifService.notifyWarning('exceed_max_number_of_displayed_record')
      this.isFilterChanged = false;
    }
  }

  /**
   * set current page number
   *
   * @param page
   */
  setPageNumber(page: number): void {
    this.listService.setCurrentPageNum(page);
    this.getData('reload');
  }

  updateRecordData(record: LooseObject[], module: string): LooseObject[] {
    return record.map( data => {

      let name = data['text'] || '';
      if (module == 'departments') {
        name = data['department_name'];
      }

      let compactData = {
        id: data.id,
        name: name,
        label: name,
      };

      // additional data
      if (module == 'sites') {
        compactData['tenant'] = data['tenant'];
      }

      return compactData;
    });
  }
}
