import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {

  /**
   * A prefix that is applied to all data stored by FieldmagiC
   * Cloud in the browser. This will help us differentiate our
   * own data from other apps that  also use local storage.
   *
   * @type {string}
   */
  private _prefix: string = 'FMCloud:';

  constructor() { }

  /**
   * Sets data within the browser's local storage.
   *
   * @param {string}  key
   * @param {any}     value
   *
   * @returns {void}
   */
  setItem(key: string, value: any): void {
    value = (typeof value == 'object') ? JSON.stringify(value) : value;

    localStorage.setItem(this._createStorageKey(key), value);
  }

  /**
   * Similar to LocalStorageService::setItem but removes the given
   * item from local storage first.
   *
   * @param {string}  key
   * @param {any}     value
   *
   * @returns {void}
   */
  replaceItem(key: string, value: any): void {
    this.removeItem(key);
    this.setItem(key, value);
  }

  /**
   * Similar to setItem, but converts the given value
   * into a JSON string first, before storage.
   *
   * @param {string}  key
   * @param {object}  value
   *
   * @returns {void}
   */
  setJsonItem(key: string, value: object): void {
    localStorage.setItem(this._createStorageKey(key), JSON.stringify(value));
  }

  /**
   * Similar to LocalStorageService::setJsonItem but removes the given
   * item from local storage first.
   *
   * @param {string}  key
   * @param {object}  value
   *
   * @returns {void}
   */
  replaceJsonItem(key: string, value: object): void {
    this.removeItem(key);
    this.setJsonItem(key, value);
  }

  /**
   * Get an Fieldmagic data entry from the browser's
   * local storage.
   *
   * @param {string} key
   *
   * @returns {any}
   */
  getItem(key: string): any {
    return localStorage.getItem(this._createStorageKey(key));
  }

  /**
   * Similar to getItem, but parses the fetched data
   * and converts it into a JSON object.
   *
   * @param {string} key
   *
   * @returns {object|array}
   */
  getJsonItem(key: string): any {
    let jsonItem = localStorage.getItem(this._createStorageKey(key));
    return jsonItem !== null ? JSON.parse(jsonItem) : null;
  }

  /**
   * Removes a Fieldmagic data entry from the browser's
   * local storage.
   *
   * @param {string} key
   *
   * @returns {void}
   */
  removeItem(key: string): void {
    localStorage.removeItem(this._createStorageKey(key));
  }

  /**
   * Get an Fieldmagic data entry from the browser's
   * local storage.
   *
   * @param {string} key
   *
   * @returns {any}
   */
  hasItem(key: string): any {
    var item = localStorage.getItem(this._createStorageKey(key));

    return item !== undefined && item !== null;
  }

  /**
   * Removes Fieldmagic's data from the browser's local storage.
   *
   * @returns {void}
   */
  clear(): void {
    var _storage_keys = Object.keys(localStorage);

    _storage_keys.forEach((storage_key) => {
      if (storage_key.indexOf(this._prefix) !== -1) {
        localStorage.removeItem(storage_key);
      }
    });
  }

  /**
   * Creates the identification keys used for storing
   * items in local storage.
   *
   * @param {string} subject
   */
  private _createStorageKey(subject: string): string {
    return `${this._prefix}${subject}`;
  }

  /**
   * Replaces / Adds / Removes value of the current client in local storage
   *
   * @param arKey
   * @param arValue
   * @param arRemove
   *
   */
  public saveValueInCurrentClient(arKey: Array<string>, arValue: Array<string | object | boolean>, arRemove: Array<string> = []) {
    let objCurrentClient = JSON.parse(this.getItem('current_client'));

    if (objCurrentClient) {
      arKey.forEach((key, index)  => {
        objCurrentClient[key] = arValue[index];
      });

      arRemove.forEach((key)  => {
        delete objCurrentClient[key];
      });

      this.setItem('current_client', JSON.stringify(objCurrentClient))
    }
  }

}
