<header >
    <div class="d-flex dialog-header">
        <h3 class="dialog-title" translate>
            <fa-icon [icon]="['far', strLabelIcon]" transform="shrink-3" class="dialog-title"></fa-icon>
            <span class="pl-1">{{ strLabelText | translate }}</span>
        </h3>
        <div class="ml-auto d-flex">
            <ul class="list-inline pull-right">
                <li class="list-inline-item">
                    <button type="button" (click)="closeDialog()" class="btn ml-1 btn-primary">
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                        <span class="pl-1">{{ 'close' | translate }}</span>
                    </button>
                </li>
                <li *ngIf="arOption.length" class="list-inline-item">
                    <button type="button" (click)="closeDialog('preview')" class="btn ml-1 btn-primary">
                        <fa-icon [icon]="['fas', 'file-pdf']"></fa-icon>
                        <span class="pl-1">{{ 'preview' | translate }}</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <hr />
</header>
<mat-dialog-content #content>
  <fa-icon *ngIf="bLoading" [icon]="['fas', 'spinner']" [spin]="true" class="text-center d-inline-block w-100 pt-4 pb-4" size="2x"></fa-icon>
  <table *ngIf="!bLoading" class="table table-striped">
    <thead>
        <tr *ngIf="arOption.length">
            <td><b>{{ 'name' | translate }}</b></td>
            <td align="right">
                <mat-checkbox
                    [(ngModel)]="selectAll"
                    (change)="selectFilter($event, 'all')"
                    color="primary">
                </mat-checkbox>
            </td>
        </tr>
    </thead>
    <tbody>
      <tr *ngFor="let option of arOption">
        <td>{{ option.text }}</td>
        <td align="right">
            <mat-checkbox
                color="primary"
                [(ngModel)]="option.selected"
                (change)="selectFilter($event, option)">
            </mat-checkbox>
        </td>
      </tr>
      <tr *ngIf="!arOption.length">
        <td>{{ 'result_empty' | translate }}</td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>