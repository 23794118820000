import { Select } from "../../../../objects/select";

export const ACCOUNTING_DRIVER_STATUS_CONNECTED = 'connected';
export const ACCOUNTING_DRIVER_STATUS_NOT_CONNECTED = 'not_connected';

export interface DriverInterface {
  /**
   * this contains the unique identifier for the driver
   *
   * @var {string}
   */
  id: string;

  /**
   * contains the metadata fields of the driver
   *
   * @var {{authentication: MetadataFieldInterface[], configuration: MetadataFieldInterface[]}}
   */
  metadata: {
    authentication: MetadataFieldInterface[],
    configuration: MetadataFieldInterface[],
  };
}

export interface IntegratedDriverInterface extends DriverInterface {
  /**
   * The name of the tenant that has been selected
   *
   * @var {string}
   */
  tenantName: string;

  /**
   * contains the datetime when does the refresh token expires
   *
   * @var {string|undefined}
   */
  refreshTokenExpiration: string|undefined;
}

export class Driver implements DriverInterface {
  /**
   * {@inheritdoc}
   */
  id: string;

  /**
   * {@inheritdoc}
   */
  metadata: {
    authentication: MetadataFieldInterface[],
    configuration: MetadataFieldInterface[],
  } = {
    authentication: [],
    configuration: [],
  };

  constructor(id: string, metadata: {authentication: MetadataFieldInterface[], configuration: MetadataFieldInterface[]}) {
    this.id = id;
    this.metadata = {
      authentication: metadata.authentication || [],
      configuration: metadata.configuration || [],
    };
  }
}

export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_PASSWORD = 'password';
export const FIELD_TYPE_DROPDOWN = 'dropdown';

export interface MetadataFieldInterface {
  /**
   * unique identifier of the field
   *
   * @var {string}
   */
  id: string;

  /**
   * label that should be displayed in the driver
   *
   * @var {string}
   */
  label: string;

  /**
   * type of field that should be render it could be a text, password etc
   *
   * @var {'text'|'password'|'dropdown'}
   */
  type: 'text'|'password'|'dropdown';

  /**
   * contains the value of the field
   *
   * @var {any}
   */
  value: any;

  /**
   * options of field that should be dropdown
   *
   * @var {Select[]}
   */
  options?: Select[];

  /**
   * determines if the current field is required.
   *
   * @var {boolean}
   */
  required?: boolean;

  /**
   * contains the help information for this current field
   *
   * @var {string}
   */
  help?: string;
}
