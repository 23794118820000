<div class="d-flex border rounded mb-2">
  <div class="rounded-left p-2 flex-shrink-1 bg-primary text-light pl-4 pr-4 pt-3 pb-3">
    <div class="d-flex align-items-center h-100">
      <fa-icon [icon]="['fas', 'info-circle']" class="fa-lg" aria-hidden="true"></fa-icon>
    </div>
  </div>
  <div class="w-100 p-2 pl-4">
    <div class="d-flex align-items-center h-100">
      <div class="row">
        <div class="col-sx-12 col-md-12">
          <div id="sidePanelInformationDetails" class="info-size" [innerHTML]="information_content | translate"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="arModuleLegend.length > 0" class="container-fluid mb-3">
  <div class="row border rounded p-2">
    <div class="col-2" *ngFor="let legend of arModuleLegend">
      <fa-icon [icon]="['fas', 'circle']" class="{{ legend.class }} legend-cirle"></fa-icon>
      <span class="info-size mb-4">&nbsp;{{ legend.value | translate }}</span>
    </div>
  </div>
</div>
