import { Directive, OnInit, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';

import { Client } from '../../../objects/client';
import { ClientStoreService } from '../../../services/client-store.service';
import { SubscriptionRestrictionService } from '../../../services/subscription-restriction/subscription-restriction.service';
import { SubscriptionPlanTypes as SPT, ENTERPRISE_PLAN, ADVANCED_PLAN, STARTER_PLAN } from '../../../objects/subscription-plans';

/**
 * Allowed subscription ids.
 *
 * @type {String}
 */
export type SubscriptionID = SPT['ENTERPRISE_PLAN'] | SPT['ADVANCED_PLAN'] | SPT['STARTER_PLAN'];

@Directive({
  selector: '[fcMinSubscriptionPlanRequired]'
})
export class MinimumSubscriptionPlanRequiredDirective implements OnInit {

  /**
   * The least allowed subscription level. Enterprise means that users need
   * to have an enterprise subscription and so on..
   *
   * @type {String}
   */
  private minimumSubscriptionRequired: BehaviorSubject<SubscriptionID> = new BehaviorSubject<SubscriptionID>(undefined);

  /**
   * An observable that we'll use to detect changes to the given subscription.
   *
   * @type {Observable<SubscriptionID>
   */
  protected minimumSubscriptionRequired$: Observable<SubscriptionID> = this.minimumSubscriptionRequired.asObservable();

  /**
   * This element's view state! The toggling of display for the element with this
   * directive shall be determined by the active client's subscription plan and
   * this element's minimum required subscription.
   *
   * @type {Observable<[Client, SubscriptionID]>}
   */
  protected viewDisplayState: Observable<[Client, SubscriptionID]> = combineLatest([
    this.clientStoreService.getWhenClientIsSetEvent(),
    this.minimumSubscriptionRequired$
  ]);

  constructor(
    protected subscriptionRestrictionService: SubscriptionRestrictionService,
    protected clientStoreService: ClientStoreService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  /**
   * Initialize a listener to our client object. This will let us "know"
   * whenever the current client changes. When that happens, we then
   * determine whether a given element should be displayed or not
   * based on their subscription level.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.viewDisplayState.subscribe(([activeClient, minimumSubscriptionRequired]) => {
      this.toggleViewBasedOnMinimumSubscriptioRequired(activeClient, minimumSubscriptionRequired)
    });
  }

  @Input()
  set fcMinSubscriptionPlanRequired(plan_id: SubscriptionID) {
    this.minimumSubscriptionRequired.next(plan_id);
  }

  /**
   * Displays or hides a given element based on the plan id of the current client
   * that they are using.
   *
   * @param {Client} activeClient
   * @param {SubscriptionID} minimumSubscriptionRequired
   *
   * @returns {void}
   */
  private toggleViewBasedOnMinimumSubscriptioRequired(activeClient: Client, minimumSubscriptionRequired: SubscriptionID): void {
    if (typeof minimumSubscriptionRequired !== 'string') {
      console.error('The minimum subscription ID required must be string. Did you wrap it in quotes?');
      return;
    }

    this.subscriptionRestrictionService.subscriptionPlanIsGreaterThanOrEqualTo(activeClient, minimumSubscriptionRequired) === true ? this.showView() : this.hideView();
  }

  /**
   * Shows the element to the user but only if isn't in the DOM yet.
   *
   * @returns {void}
   */
  private showView(): void {
    if (this.viewContainer.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  /**
   * Hides the element from the user.
   *
   * @returns {void}
   */
  private hideView(): void {
    this.viewContainer.clear();
  }
}
