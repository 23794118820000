import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatStepper, MatDialog } from '@angular/material';
import { WizardContactData, WizardCustomerData, WizardJobData } from '../../objects/wizard';
import { Observable, of, forkJoin, defer, from } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import { NotificationService } from '../../../../services/notification.service';
import { WizardService } from '../../services/wizard.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { get, isEmpty } from 'lodash';
import { WizardComponentInterface } from '../wizard/wizard.component.interface';
import { WizardStep, WizardStepper } from '../../objects/wizard-stepper';
import { CustomerComponent } from '../steps/customer/customer.component';
import { ChecklistResponse } from '../../../../objects/checklis-response';
import { ChecklistsService } from '../../../../services/checklist.service';
import { RecordService } from '../../../../services/record.service';
import { AssetService } from '../../../../services/asset.service';
import { LayoutDashboardService } from '../../../../shared/layouts/layout-dashboard/layout-dashboard.service';

@Component({
  selector: 'app-create-job-wizard',
  templateUrl: './create-job-wizard.component.html',
  styleUrls: ['./create-job-wizard.component.scss'],
  providers: [AssetService]
})
export class CreateJobWizardComponent implements OnInit, WizardComponentInterface {

  /**
   * The stepper initial settings. You can also update the
   * stepper through here but do note that it will have a delay as
   * change detection of child components take a while to reflect.
   *
   * @var {WizardStepper}
   */
  public wizardStepper: WizardStepper = new WizardStepper(
    new WizardStep({title: 'customer', icon: 'users', optional: false, editable: true, completed: false}),
    new WizardStep({title: 'contact', icon: 'address-book', optional: true, editable: true, completed: false}),
    new WizardStep({title: 'job', icon: 'wrench', optional: false, editable: true, completed: false}),
    new WizardStep({title: 'summary', icon: 'book', optional: false, editable: false, completed: false})
  );

  @ViewChild(CustomerComponent) customer: CustomerComponent;

  constructor(
    public dialogRef: MatDialogRef<CreateJobWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialog: MatDialog,
    private notifService: NotificationService,
    public wizardService: WizardService,
    private recordService: RecordService,
    private checklistsService: ChecklistsService,
    private assetService: AssetService,
    private layoutDashboardService: LayoutDashboardService,
    private router: Router
  ) {
    this.dialogData = this.wizardService.checkPreselectedData(layoutDashboardService, dialogData);

  }

  /**
   * Loading indicator.
   *
   * @var {boolean}
   */
  public bLoading: boolean = false;

  /**
   * The id of the customer if it already exists.
   *
   * @var {string}
   */
  public strCustomerId?: string;

  /**
   * The id of the site if it already exists.
   *
   * @var {string}
   */
  public strSiteId?: string;

  /**
   * Holds the data for this wizard.
   *
   * @var {CustomerWizardData}
   */
  public objWizard: {
    contact: WizardContactData | null,
    customer: WizardCustomerData | null,
    job: WizardJobData | null
  } = {
    contact: null,
    customer: null,
    job: null
  };

  /**
   * Checks if job has a customer.
   *
   * @var {boolean}
   */
  bHasNoCustomer: boolean = false;

  /**
   * Checks if job is at a custom location.
   *
   * @var {boolean}
   */
  bIsCustomLocation: boolean = false;

  /**
   * Identify which redirect to go to after creation.
   *
   * @var {string}
   */
  strRedirect?: 'create' | 'schedule' | 'work_order';

  ngOnInit() {
    if (!this.dialogData.customer_id && !this.dialogData.opened_from_mega_menu) {
      this.changeCustomerStepName(true);
    }
  }

  /**
   * Get the updates from the contact form
   * and save it to this component.
   *
   * @param {WizardContactData} objContactData
   *
   * @returns {void}
   */
  captureContactData(objContactData: WizardContactData, stepper: MatStepper): void{
    this.objWizard.contact = objContactData;

    if (objContactData != null) {
      stepper.steps.find(item => item.state == 'step2').completed = true;
      stepper.next();
    }
  }

  /**
   * Captures the customer data
   *
   * @param {WizardCustomerData} objCustomerData
   * @param {MatStepper} objStepper
   *
   * @returns {void}
   */
  captureCustomerData(objCustomerData: any, stepper: MatStepper): void {
    this.objWizard.customer = objCustomerData;
    this.strCustomerId = null;
    this.strSiteId = null;

    if (get(objCustomerData, 'customer_id')) {
      this.strCustomerId = objCustomerData['customer_id'];
    }

    if (get(objCustomerData, 'site_id')) {
      this.strSiteId = objCustomerData['site_id'];
    }

    if (objCustomerData != null) {
      stepper.steps.first.completed = true;
      stepper.next();
    }
  }

  /**
   * Captures the job data
   *
   * @param {WizardJobData} objJobData
   * @param {MatStepper} objStepper
   *
   * @returns {void}
   */
  captureJobData(objJobData: WizardJobData, objStepper: MatStepper): void {
    this.objWizard.job = objJobData;

    if (objJobData != null) {
      objStepper.steps.find(item => item.state == 'step3').completed = true;
      objStepper.next();
    }

  }

  /**
   * Actions to perform if the customer step is skipped.
   *
   * @param {boolean} bFlag
   * @param {MatStepper} stepper
   *
   * @returns {void}
   */
  skipCustomerStep(bFlag: boolean, stepper: MatStepper): void {
    if (bFlag) {
      stepper.steps.first.completed = true;
      stepper.next();
    }
  }

  /**
   * Actions to perform if the contact step is skipped.
   *
   * @param {boolean} bFlag
   * @param {MatStepper} stepper
   *
   * @returns {void}
   */
  skipContactStep(bFlag: boolean, stepper: MatStepper): void {
    if (bFlag) {
      stepper.steps.find(item => item.state == 'step2').completed = true;
      stepper.next();
    }
  }

  /**
   * Schedule the job details.
   *
   * @returns {void}
   */
  save(): void{
    this.bLoading = true;

    this.wizardService.saveJobDetails(this.objWizard).pipe(
      switchMap(jobDetailsRes =>
        forkJoin([
          defer(() => from(this.generateLinkedChecklistResponseData(jobDetailsRes.body.record_id))),
          of(jobDetailsRes),
        ])
      ),
      switchMap(([chklistRes, jobDetailsRes]) =>
        forkJoin([
          this.getLinkedChecklistsObservable(chklistRes),
          this.getLinkedAssetObservable(jobDetailsRes.body.record_id),
          of(jobDetailsRes),
        ])
      ),
      switchMap(([chklistRes, assetsRes, jobDetailsRes]) => {
        let obs: Observable<{ answer: boolean }> = of({ answer: true });

        return forkJoin([
          obs,
          of(jobDetailsRes)
        ]);
      }))
      .subscribe(([dialogRes, jobDetailsRes]) => {
        this.bLoading = false;
        this.notifService.notifySuccess('job_successfully_created');
        this.dialogRef.close('save');
        if (get(dialogRes, 'answer') === true) {

          if (this.strRedirect == 'create') {
            this.layoutDashboardService.strActiveParent = 'jobs';
            this.router.navigate([`/jobs/${jobDetailsRes.body.record_id}`]);
          }

          if (this.strRedirect == 'work_order') {
            this.layoutDashboardService.strActiveParent = 'jobs';
            this.router.navigate([`/jobs/${jobDetailsRes.body.record_id}`], {queryParams: {work_order: 'open'}});
          }

          if (this.strRedirect == 'schedule') {
            this.router.navigate([`/jobs/${jobDetailsRes.body.record_id}`], {queryParams: {scheduler: 'open'}});
          }

        }
      }, (error: HttpErrorResponse) => {
        let errorIds = Object.keys(error.error);
        if (errorIds.length && errorIds[0]) {
          this.notifService.notifyWarning(error.error[errorIds[0]][0]);
        } else {
          this.notifService.notifyWarning('record_invalid_parameters');
        }
        this.bLoading = false;
      }, () => {
        this.bLoading = false;
      });
  }

  /**
   * Generates the checklist response data for the checklists to be linked
   * to the newly created job.
   *
   * @param {string} strJobId
   *
   * @returns {Promise<ChecklistResponse[]> }
   */
  generateLinkedChecklistResponseData(strJobId: string): Promise<ChecklistResponse[]> {
    return Promise.all(this.objWizard.job.checklists.map(objChecklist => {
      let arSelectedInspectionPeriods: string[] = objChecklist['selected_inspection_periods'] || [];

      return this.checklistsService.generateChecklistResponse(objChecklist, arSelectedInspectionPeriods, strJobId, 'jobs')
        .then(_ => this.checklistsService.getChecklistResponseData());
    }));
  }

  /**
   * Gets the observable to link the checklists to the newly created job
   *
   * @param {ChecklistResponse[]} arChklistResponses
   *
   * @returns {Observable<any> }
   */
  getLinkedChecklistsObservable(arChklistResponses: ChecklistResponse[]): Observable<any> {
    if (!isEmpty(arChklistResponses)) {
      return this.recordService.saveMultipleRecord('checklist_responses', arChklistResponses);
    }

    return of([]);
  }

  /**
   * Gets the observable to link the asset to the newly created job
   *
   * @param {string} strJobId
   *
   * @returns {Observable<any> }
   */
  getLinkedAssetObservable(strJobId: string): Observable<any> {
    const strAssetId: string = get(this.dialogData, 'asset_data.asset_id');

    return strAssetId
      ? this.assetService.linkAssets([strAssetId], strJobId)
      : of([]);
  }

  /**
   * Checks if this stepper is dirty.
   *
   * @returns {boolean}
   */
  isDirty(): boolean {
    return this.customer.form.dirty
  }

  /**
   * Only visible in create job wizard. Changes the name of the step from 'Customer' to 'Location'
   * if the 'No customer' checkbox is checked.
   *
   * @param {boolean} bFlag
   *
   * @returns {void}
   */
  changeCustomerStepName(bFlag: boolean): void {
    this.bHasNoCustomer = bFlag;

    if (bFlag) {
      this.wizardStepper.step1 = new WizardStep({title: 'location', icon: 'map-marked-alt', optional: true, editable: true, completed: false});
    } else {
      this.wizardStepper.step1 = new WizardStep({title: 'customer', icon: 'users', optional: false, editable: true, completed: false});
    }
  }

}