<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'users']" ></fa-icon>&nbsp;
        <span *ngIf="strViewType == 'edit'" >{{ 'edit_event'| translate }} </span>
        <span *ngIf="strViewType == 'add'" >{{ 'create_event'| translate }} </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button (click)="onSubmit()" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader)? 'btn-spinner' : ''">
            <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'save']"></fa-icon>
            <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
            <span class="pl-1">{{ 'save' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr class="separator"/>
<div class="row">
  <div *ngIf="objEventName && objEventField && eventForm && bPageLoaded" class="col-sm-12">
    <form [formGroup]="eventForm">
      <div *ngFor="let strName of objEventName" class="form-group">
          <app-edit-field [item]="objEventField[strName]" [ngClass]="{'is-invalid': !eventForm.valid && bSubmitted}" [form]="eventForm" strMode="add" [bSubmitted]="bSubmitted" [strCustomClass]="objEventField[strName]['custom_class']"></app-edit-field>
      </div>
    </form>
  </div>
  <ng-container *ngIf="!bPageLoaded">
    <div class="col-sm-12" align="center">
      <h4><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></h4>
    </div>
  </ng-container>
</div>