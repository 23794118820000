import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

import { SharedModule } from '../../shared/modules/shared.module';
import { ChargebeeComponent } from './chargebee.component';
import { SmsPackagesComponent } from './dialogs/sms-packages/sms-packages.component';
import { ManageSubscriptionComponent } from './dialogs/manage-subscription/manage-subscription.component';
import { RouterModule } from '@angular/router';
import { ChangePaymentComponent } from './dialogs/change-payment/change-payment.component';
import { BillingHistoryComponent } from './dialogs/billing-history/billing-history.component';

@NgModule({
  declarations: [
    ChargebeeComponent,
    SmsPackagesComponent,
    ManageSubscriptionComponent,
    ChangePaymentComponent,
    BillingHistoryComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    SharedModule.forRoot(),
    RouterModule
  ],
  exports: [
    ChargebeeComponent
  ],
  entryComponents: [
    SmsPackagesComponent,
    ManageSubscriptionComponent,
    ChangePaymentComponent,
    BillingHistoryComponent
  ]
})
export class ChargebeeModule { }
