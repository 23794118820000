import { Component, Inject, ViewChild, EventEmitter, Output, OnInit, OnDestroy, HostListener } from "@angular/core";
import { MAT_DIALOG_DATA, MatStepper, MatDialogRef } from "@angular/material";
import { DriverInterface } from "../../entities/driver";
import { StepperSelectionEvent, CdkStep } from "@angular/cdk/stepper";
import { AccountingSystemService } from "../../services/accounting_system.service";
import { Subscription } from "rxjs";
import { filter, finalize, map } from "rxjs/operators";
import { NotificationService } from "../../../../../services/notification.service";

@Component({
  selector: 'integrations-accounting-systems-connect',
  styleUrls: ['./connect.component.scss'],
  templateUrl: './connect.component.html',
})
export class ConnectComponent implements OnInit, OnDestroy {
  /**
   * contains the element referece material stepper component
   *
   * @var {MatStepper}
   */
  @ViewChild('wizard') wizard: MatStepper;

  /**
   * event emitted for each progress that is happening during the integration connection process
   *
   * @var {EventEmitter<IntegrationProgressEventData>}
   */
  @Output('integration-progress-event') eventIntegrationProgress: EventEmitter<IntegrationProgressEventData> = new EventEmitter;

  /**
   * determines the current step that is viewing by the
   *
   * @var {string}
   */
  currentStep: string = 'step_authorization';

  /**
   * contains the current index of the step
   *
   * @var {number}
   */
  currentStepIndex: number = 0;

  /**
   * determines if the current step is in progress
   *
   * @var {boolean}
   */
  isInProgress: boolean = true;

  /**
   * determines if the current component is currently checking the client saved state
   *
   * @var {boolean}
   */
  isCheckingState: boolean = false;

  /**
   * determines if the next button should be displayed
   *
   * @var {boolean}
   */
  shouldDisplayNextButton: boolean = false;

  /**
   * determines if the current step should display a skip button
   *
   * @var {boolean}
   */
  shouldDisplaySkipButton: boolean = false;

  /**
   * the callback function when the next button is called
   *
   * @var {Function}
   */
  nextButtonCallback: Function = () => {};

  /**
   * contains the injected driver instance
   *
   * @var {DriverInterface}
   */
  driver: DriverInterface;

  /**
   * contains the list of step
   *
   * @var {string[]}
   */
  stepIndexes = [
    'step_authorization',
    'step_import_tax_and_account_codes',
    'step_import_items',
    'step_import_customers_suppliers_and_contacts',
    'step_setup_system_defaults',
    'step_connect_success',
  ];

  /**
   * INTERNAL: contains all the subscription observable actions that should be cleaned up later
   *
   * @var {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * @param {ConnectComponentMetadata} data
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConnectComponentMetadata,
    private currentDialog: MatDialogRef<ConnectComponent>,
    private accounting: AccountingSystemService
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.isCheckingState = true;
    this.driver = this.data.driver;

    // append driver configuration step after import of suppliers and contacts
    if (this.hasConfigurableMetadata()) this.stepIndexes.splice((this.stepIndexes.length - 2), 0, 'step_setup_driver_configuration');

    this.subscriptions.push(
      this.accounting.getCurrentStep$('connection')
        .pipe(
          finalize(() => this.isCheckingState = false),
          map((storedStep) => (storedStep || '').split(':')),
          filter(([, driverId]) => this.driver.id === driverId)
        )
        .subscribe(([currentStep]) => {
          if (currentStep === 'completed') currentStep = 'step_connect_success';

          if (currentStep && currentStep !== 'step_connect_success') {
            let i = this.stepIndexes.findIndex((step) => step === currentStep);
            currentStep = this.stepIndexes[i + 1]; // as we would need him to continue to the next step of the previously saved step
          }

          this.currentStep = currentStep || this.currentStep;
          this.currentStepIndex = this.stepIndexes.findIndex((step) => this.currentStep === step);
        })
    );
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * handles the incoming event progress that is triggered or emmited by each steps component
   *
   * @param   {StepEventData} event
   *
   * @returns {void}
   */
  handleEventProgressEmitted(event: StepEventData): void {
    // output event
    this.eventIntegrationProgress.emit({
      progress: event.progress,
      currentStep: this.currentStep
    });

    if (event.progress === PROGRESS_NEXT || event.progress === PROGRESS_SKIP) {
      this.proceedToNext();
    } else if (event.progress === PROGRESS_INPROGRESS) {
      this.isInProgress = true;
    } else if (event.progress === PROGRESS_FAILED) {
      this.isInProgress = false;
    } else if (event.progress === PROGRESS_FINISHED) {
      this.currentDialog.close(this.driver);
    }
  }

  /**
   * handles the emitted event next button of the child components
   *
   * @param   {DisplayNextButtonEventData}
   *
   * @returns {void}
   */
  handleEventNextButton(event: DisplayNextButtonEventData): void {
    if (event.shouldDisplayButton === true) {
      this.shouldDisplayNextButton = true;
      this.nextButtonCallback = event.callback;
    }
  }

  /**
   * handles the next button click event
   *
   * @returns {void}
   */
  handleNextButtonClick(): void {
    this.nextButtonCallback();
  }

  /**
   * handles the skip button event
   *
   * @returns {void}
   */
  handleSkipButtonClick(): void {
    this.proceedToNext();
  }

  /**
   * handle the selection change of wizard component
   *
   * @param   {StepperSelectionEvent} event
   *
   * @returns {void}
   */
  handleSelectionChange(event: StepperSelectionEvent): void {
    let selected: CdkStep = event.selectedStep;
    let nextStep: string = selected.ariaLabel;

    // if it is a new step reset actions
    if (nextStep !== this.currentStep) {
      this.reset();
    }

    this.currentStep = nextStep;
    this.currentStepIndex = this.stepIndexes.findIndex((step) => this.currentStep === step);
    this.shouldDisplaySkipButton = selected.optional || false;
  }

  /**
   * Check if the current step is already completed
   *
   * @param   {string} step
   *
   * @returns {boolean}
   */
  isStepCompleted(step: string): boolean {
    return this.currentStepIndex > this.stepIndexes.indexOf(step)
  }

  /**
   * resets the component properties that can be used by each step
   *
   * @returns {void}
   */
  protected reset(): void {
    this.shouldDisplayNextButton = false;
    this.shouldDisplaySkipButton = false;
    this.isInProgress = false;
    this.nextButtonCallback = () => {};
  }

  /**
   * proceeds to the next step
   *
   * @returns {void}
   */
  protected proceedToNext(): void {
    this.subscriptions.push(
      this.accounting.updateStep$('connection', `${this.currentStep}:${this.driver.id}`).subscribe(() => {
        this.wizard.next();
      })
    );
  }

  /**
   * Check if the current driver has a configurable metadata
   *
   * @returns {boolean}
   */
  hasConfigurableMetadata(): boolean {
    return this.driver.metadata.configuration.length > 0;
  }
}

export interface ConnectComponentMetadata {
  /**
   * the current driver to connect to
   *
   * @var {DriverInterface}
   */
  driver: DriverInterface;
}

export interface WizardStepComponent {
  /**
   * contains the event that should be emitted by steps component
   *
   * @var {EventEmitter<StepEventData>}
   */
  eventProgress: EventEmitter<StepEventData>;

  /**
   * contains the event that should be emitted when the step requires a next button action
   *
   * @var {EventEmitter<DisplayNextButtonEventData>}
   */
  eventNextButton?: EventEmitter<DisplayNextButtonEventData>;
}

export interface StepEventData {
  /**
   * contains what would be the next action
   *
   * @var {string}
   */
  progress: string;
}

export interface IntegrationProgressEventData extends StepEventData {
  /**
   * contains the current step where the user is at
   *
   * @var {string}
   */
  currentStep: string;
}

export interface DisplayNextButtonEventData {
  /**
   * determines if the current event should display the next button
   *
   * @var {boolean}
   */
  shouldDisplayButton: boolean;

  /**
   * contains the callback that should be called when button is clicked
   */
  callback: Function;
}

/**
 * possible values of progress property in the event data
 *
 * @const
 */
export const PROGRESS_NEXT = 'next';
export const PROGRESS_INPROGRESS = 'in_progress';
export const PROGRESS_FAILED = 'failed';
export const PROGRESS_SKIP = 'skip';
export const PROGRESS_FINISHED = 'finished';
