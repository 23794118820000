
    <div class="input-icon">
      <input
        type="number"
        [attr.min]="min"
        [attr.max]="max"
        [attr.step]="step"
        class="{{ customClass }}"
        [placeholder]="placeholder"
        [disabled]="isDisabled$ | async"
        [(ngModel)]="value"
        (change)="onChange($event.target.value)"
        (focus)="onTouched()"
        (keypress)="onKeyPress($event)"
        [readonly]="isReadonly"
        [class.with-controls]="withControls"
      />
      <i>{{ strCurrencySymbol }}</i>
    </div>
  