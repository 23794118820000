<header>
  <div class="d-flex">
    <h3 class="title">
        <fa-icon [icon]="['fas', 'wrench']" ></fa-icon> &nbsp;
        <span>{{ 'search_and_select_asset' | translate }}</span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="closeDialog()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
      </ul>

    </div>
  </div>
</header>
<hr class="separator"/>
  <div class="form-row">
      <div class="col-lg-2 search-labels">
        <span class="">{{ 'site' | translate }}</span>
      </div>
      <div class="input-group col-lg-10">
          <ng-select
            class="input-select"
            [items]="objSelections['sites']['obv'] | async"
            [typeahead]="objSelections['sites']['typehead']"
            [loading]="objSelections['sites']['loader']"
            [hideSelected]="true"
            [clearable]="true"
            [ngModelOptions]="{standalone: true}"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="strSite"
            (open)="triggerSubject(objSelections['sites']['typehead'])">
            <!-- DISPLAY IN SELECTED -->
            <ng-template ng-label-tmp let-item="item">
              <span class="ng-value-label">{{ item.name | translate }}</span>
            </ng-template>
            <!-- DISPLAY IN OPTION -->
            <ng-template  ng-option-tmp let-item="item">
              <span class="value-text"> {{ item.name | translate }} </span>
            </ng-template>
          </ng-select>
      </div>
  </div>
  <div class="form-row">
    <div class="col-lg-2 search-labels">
      <span class="">{{ 'asset_type' | translate }}</span>
    </div>
    <div class="input-group col-lg-10">
        <ng-select
            class="input-select"
            [items]="objSelections['asset_types']['obv'] | async"
            [typeahead]="objSelections['asset_types']['typehead']"
            [loading]="objSelections['asset_types']['loader']"
            [hideSelected]="true"
            [clearable]="true"
            [ngModelOptions]="{standalone: true}"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="strAssetType"
            (open)="triggerSubject(objSelections['asset_types']['typehead'])">
          <!-- DISPLAY IN SELECTED -->
          <ng-template ng-label-tmp let-item="item">
            <span class="ng-value-label">{{ item.name | translate }}</span>
          </ng-template>
          <!-- DISPLAY IN OPTION -->
          <ng-template  ng-option-tmp let-item="item">
            <span class="value-text"> {{ item.name | translate }} </span>
          </ng-template>
        </ng-select>
    </div>
  </div>
  <div class="form-row">
    <div class="col-lg-2 search-labels">
      <span class="">{{ 'asset_group' | translate }}</span>
    </div>
    <div class="input-group col-lg-10">
      <ng-select
          class="input-select"
          [items]="objSelections['asset_groups']['obv'] | async"
          [typeahead]="objSelections['asset_groups']['typehead']"
          [loading]="objSelections['asset_groups']['loader']"
          [hideSelected]="true"
          [clearable]="true"
          [ngModelOptions]="{standalone: true}"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="strAssetGroups"
          (open)="triggerSubject(objSelections['asset_groups']['typehead'])">
        <!-- DISPLAY IN SELECTED -->
        <ng-template ng-label-tmp let-item="item">
          <span class="ng-value-label">{{ item.name | translate }}</span>
        </ng-template>
        <!-- DISPLAY IN OPTION -->
        <ng-template  ng-option-tmp let-item="item">
          <span class="value-text"> {{ item.name | translate }} </span>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="form-row">
    <div class="col-lg-2 search-labels">
      <span class="">{{ 'serial_number' | translate }}</span>
    </div>
    <div class="input-group col-lg-10">
        <input type="text" class="input-search" [(ngModel)]="strSerialNumber">
        <button class="btn-search">
            <fa-icon [icon]="['fal', 'search']"></fa-icon>
        </button>
    </div>
  </div>

  <div class="form-row">
    <div class="col-lg-12 right-aligned-container">
      <button (click)="linkAllRelatedAsset()" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader)? 'btn-spinner' : ''">
        <fa-icon *ngIf="!bShowLoader" [icon]="['fal', 'link']"></fa-icon>
        <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
        <span class="pl-1">{{ 'search_and_link_assets' | translate }}</span>
      </button>
      <button (click)="linkSelectedAssets()" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader)? 'btn-spinner' : ''">
        <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'link']"></fa-icon>
        <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
        <span class="pl-1">{{ 'link_selected_assets' | translate }}</span>
      </button>
      <button (click)="searchAsset()" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader)? 'btn-spinner' : ''">
        <fa-icon *ngIf="!bShowLoader" [icon]="['fas', 'search']"></fa-icon>
        <fa-icon *ngIf="bShowLoader" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
        <span class="pl-1">{{ 'search' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="form-row" *ngIf="!isResultEmpty">
    <div class="col-lg-12 right-aligned-container">
        <nav class="pagination-section mb-0" align="left">
          <ul class="pagination mb-0">
            <li [ngClass]="(arPagination.initial_value != arPagination.current_page && arPagination.current_page != '') ? 'active pointer' : 'disabled'" class="page-item">
              <a class="page-link" aria-label="first" (click)="getItem('default')">
                <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
              </a>
            </li>
            <li [ngClass]="(arPagination.initial_value != arPagination.current_page && arPagination.current_page != '') ? 'active pointer' : 'disabled'" class="page-item">
              <a class="page-link" aria-label="first" (click)="getItem('prev')">
                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
              </a>
            </li>
            <li [ngClass]="(arPagination.next_page != '') ? 'active pointer' : 'disabled'" class="page-item">
              <a class="page-link" aria-label="first" (click)="getItem('next')">
                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
              </a>
            </li>
          </ul>
        </nav>
    </div>
  </div>

  <div class="row">
      <div class="col-md-12">
        <table class="table table-striped w100p">
          <thead>
            <tr>
              <th class="header-serial">{{ 'serial_number' | translate }}</th>
              <th class="header-asset-type">{{ 'asset_type' | translate }}</th>
              <th class="header-site">{{ 'site' | translate }}</th>
              <th class="header-checkbox"><input type="checkbox" (click)="selectAllItem($event)"/></th>
            </tr>
          </thead>
          <tbody *ngIf="arAssets.length">
            <tr *ngFor="let item of arAssets, index as assetIndex">
              <td class="td-description text-center">{{ item.serial_number }}</td>
              <td class="td-description text-center">{{ item.asset_type_text }}</td>
              <td class="td-description text-center">{{ item.site_text }}</td>
              <td class="td-description text-center">

                <span *ngIf="item.is_linked; else notLinked">
                  <fa-icon [icon]="['fas', 'check-circle']" class="text-success"></fa-icon>
                </span>
                <ng-template #notLinked>
                  <input type="checkbox" [checked]="item.checked" (click)="selectItem($event, item.id, assetIndex)"/>
                </ng-template>

              </td>
            </tr>
          </tbody>
          <tbody>
            <tr *ngIf="bLoading" align="center">
              <td [attr.colspan]="5">
                <fa-icon [icon]="['fas', 'spinner']" class="loading-spinner" [spin]="true"></fa-icon>
              </td>
            </tr>
            <tr *ngIf="!bLoading && isResultEmpty" align="center">
              <td [attr.colspan]="5">
                <span class="text-center">
                  <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>{{ 'empty_asset' | translate }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>