import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Response } from '@angular/http';
import { environment } from '../../../../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../../../services/notification.service';
import { Config } from 'protractor';

@Component({
  selector: 'app-participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: ['./participants-list.component.scss']
})
export class ParticipantsListComponent implements OnInit {
  participants: Array<{
    emailAddress:string,
    isSubscribed: boolean
  }> = [];
  form: FormGroup;
  isProcessing: boolean = false;

  constructor(
    @Inject(MatDialogRef) protected currentDialog: any,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private formBuilder: FormBuilder,
    private client: HttpClient,
    private notifier: NotificationService
  ) { }

  ngOnInit() {
    // list the subscribed
    this.data.participants.subscribed.forEach((emailAddress) => {
      this.participants.push({
        emailAddress,
        isSubscribed: true
      });
    });

    // list the unsubscribed
    this.data.participants.unsubscribed.forEach((emailAddress) => {
      this.participants.push({
        emailAddress,
        isSubscribed: false
      });
    });

    this.form = this.formBuilder.group({
      participants: this.formBuilder.array(this.createParticipants())
    })
  }

  cancelDialog(isUpdated: boolean = false): void {
    this.currentDialog.close(isUpdated);
  }

  save(): void {
    let participants = this.form.value.participants;
    let body = new URLSearchParams({
      'thread_id': this.data.threadId
    });

    // append each participants into the proper request body
    participants.forEach((participant, index) => {
      body.append(`participants[${index}][participant][name]`, participant.emailAddress.name);
      body.append(`participants[${index}][participant][email]`, participant.emailAddress.email);
      body.append(`participants[${index}][is_subscribed]`, (participant.isSubscribed) ? '1' : '0');
    });

    this.isProcessing = true;

    let http$ = this.client.post<Response>(`${environment.url}/mails/update-participants`, body.toString(), { observe: 'response' });

    http$
      .pipe(
        finalize(() => this.isProcessing = false)
      )
      .subscribe((response: HttpResponse<Config>) => {
        if (response.status === 200) {
          this.notifier.notifySuccess('response.body.message');
          this.cancelDialog(true);
        }
      });
  }

  get participantsControls() {
    return this.form.get('participants') as FormArray;
  }

  protected createParticipants(): Array<FormGroup> {
    let controls: Array<FormGroup> = [];

    this.participants.forEach((participant) => {
      controls.push(this.formBuilder.group({
        emailAddress: participant.emailAddress,
        isSubscribed: participant.isSubscribed
      }))
    });

    return controls;
  }
}
