import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  /**
   * Create instance of the recaptcha service
   */
  constructor(
    private recaptcha: ReCaptchaV3Service,
  ) {}

  /**
   * Retrieve the captcha for the provided action
   */
  getCaptcha(action: string): Observable<string> {
    return this.recaptcha.execute(action);
  }
}