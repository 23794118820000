import { PopupConfig } from '../../../../../objects/dialogs/popup-config';
import { LooseObject } from '../../../../../objects/loose-object';
import { ENTERPRISE_PLAN, ADVANCED_PLAN, STARTER_PLAN, SubscriptionId } from '../../../../../objects/subscription-plans';

export class AsideLink {

  /**
   * The label of the navigation.
   *
   * @var {string}
   */
  label: string;

  /**
   * Holds the direct value to either a link or dialog.
   *
   * @var {string[] | PopupConfig}
   */
  direct: string[] | PopupConfig;

  /**
   * The icon of the navigation.
   *
   * @var {string[]}
   */
  icon: string[] = [];

  /**
   * The query params that comes along with the navigation.
   *
   * @var {LooseObject}
   */
  params: LooseObject;

  /**
   * What is the minimum subscription for this.
   *
   * @var {SubscriptionId}
   */
  minimum_subscription?: SubscriptionId;

  /**
   * Restric this link?
   *
   * @var {boolean}
   */
  restrict: boolean = false;

  get link(): string[] {
    if (!(this.direct instanceof PopupConfig)) {
      return this.direct;
    }

    return null;
  }

  get dialog(): PopupConfig {
    if (this.direct instanceof PopupConfig) {
      return this.direct;
    }

    return null;
  }

  constructor(
    label: string,
    direct: string[] | PopupConfig,
    params: LooseObject = null,
    icon: string[] = ['fas', 'plus-circle'],
    minimum_subscription?: SubscriptionId
  ) {
    this.label = label;
    this.direct = direct;
    this.icon = icon;
    this.params = params;
    this.minimum_subscription = minimum_subscription;
  }

}