export enum ModuleRelate {
    'lead_id' = 'leads',
    'customer_id' = 'customers',
    'site_id' = 'sites',
    'contact_id' = 'contacts',
    'opportunity_id' = 'opportunities',
    'quote_id' = 'quotes',
    'job_id' = 'jobs',
    'asset_id' = 'assets',
    'customer_invoice_id' = 'customer_invoices',
    'purchase_order_id' = 'purchase_orders',
    'supplier_invoice_id' = 'supplier_invoices',
    'user_id' = 'users',
    'item_id' = 'items',
    'tax_code_id' = 'tax_codes',
    'pricebook_id' = 'pricebooks'
  };
