<span id="archiveConfirmation" class="confirmation-header">{{ 'confirmation' | translate }}</span>
<hr>
<mat-dialog-content>
    <div id="confirmationMessage" class="confirmation-body">{{ 'confirm_archive' | translate  }}</div>
    <div id="confirmationMessageSub" class="confirmation-body-sub">{{ 'confirm_archive_module' | translate  }}</div>
    <ng-select id="selectedModule" class="module-selection" [(ngModel)]="arSelectedModule" multiple="true" width="80%" appendTo="body">
        <ng-option *ngFor="let option of arModuleSelection" [value]="option.module">{{ option.module | translate }}</ng-option>
     </ng-select>
    <hr>
    <div class="form-group text-center mb-0">
        <button id="archiveConfirmTrue" tabindex="-1" mat-button (click)="closeDialog(true)" >{{ 'yes' | translate }}</button>
        <button id="archiveConfirmFalse" tabindex="-1" mat-button (click)="closeDialog(false)">{{ 'no' | translate }}</button>
    </div>
</mat-dialog-content>
