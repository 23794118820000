import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

import { RecordService } from '../../../../../services/record.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Notification } from '../../../../../objects/notification';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent implements OnInit {

  public arOption: any = [];
  public strModule: string = '';
  public objFilter: any = {};
  public objParams: any = {};
  public bLoading: boolean = true;
  public strLabelIcon: string = '';
  public strLabelText: string = ''
  public selectAll: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<OptionComponent>,
    private recordService: RecordService,
    private notificationService: NotificationService
  ) {

    this.strModule = data.module;
    switch (data.module) {

      case 'assets':
        this.strLabelIcon = (data.label_config) ? data.label_config.icon : '';
        this.strLabelText = (data.label_config) ? data.label_config.label : '';
        this.objParams = data.params;
        this.recordService.getAssetUniqueAssetTypes(this.objParams['module'], this.objParams['filter']).subscribe( response => {

          if (response.body.length) {
            this.arOption = response.body;
          }
          this.bLoading = false;
        });
      break;

      default:
        this.closeDialog();
      break;
    }
  }

  ngOnInit() {
  }

  /**
   * Select option and store in filter
   *
   * @param strOptionId
   */
  selectFilter(event, objRecord) {
    if (event.checked) {
      if (this.selectAll && objRecord == 'all') {
        this.arOption.forEach( option => {
          option.selected = this.selectAll;
          this.objFilter[option.id] = option;
        });
      } else {
        this.objFilter[objRecord.id] = objRecord;
      }
    } else {
      delete this.objFilter[objRecord.id];
      this.selectAll = false;
    }
  }

  /**
   * Close the preview component dialog
   *
   * @param {string} strPreview - Flag if we already selected a filter
   *
   * @returns {void}
   */
  closeDialog(strPreview = null) : void {
    if (strPreview) {

      var arResponse: any = [];
      var intFilter = Object.keys(this.objFilter).length;
      // Do we have selected filter?
      if (intFilter) {

        let arWhereInIds = [];
        // Get all id's
        Object.keys(this.objFilter).forEach( strRecordId => {
          arResponse.push(this.objFilter[strRecordId])
          arWhereInIds.push(strRecordId)
        })
        // Get record based on request
        this.objParams['filter']['asset_type_id'] = arWhereInIds;
        this.recordService.getPDFRecordData(this.objParams['module'], null, this.objParams['filter']).subscribe( response => {
          this.dialogRef.close(response.body);
        });
      } else {
        this.notificationService.notifyWarning('please_select_asset_type');
      }
    } else {
      // Return with no data
      this.dialogRef.close();
    }
  }
}
