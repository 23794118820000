import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotifyViaPushService } from '../features/task-calendar/components/services/notify-via-push-service';
import { LooseObject } from '../objects/loose-object';

const kBaseUrl: string = environment.url + "/activities/";

@Injectable()
export class ActivitiesService {

  constructor (
    private http: HttpClient,
    private notifyPush: NotifyViaPushService
  ) {}

  /**
   * Fetches activity records
   * @param module
   * @param record_id
   * @param filter_clause
   */
  getActivities(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "get_activities", body.toString());
  }

  /**
   * Inserts a record in activities table
   */
  createActivity(jsonData) {
    let body = new URLSearchParams({
      data: jsonData,
    });

    body = this.sendPushNotifOnDueDate(JSON.parse(jsonData), body);

    return this.http.post<Response>(kBaseUrl + "create_activity", body, {
      headers: {
        'X-Captcha': 'create_activity',
      },
    });
  }

  updateActivity(jsonData) {
    let body = new URLSearchParams();

    body = this.sendPushNotifOnDueDate(JSON.parse(jsonData), body);

    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "update_activity", body.toString());
  }

  getEmailRecord(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + 'get_email_record', body.toString());
  }

  deleteActivity(jsonData) {
    let body = new URLSearchParams();
    body.append('data', jsonData);
    return this.http.post<Response>(kBaseUrl + "delete_activity", body.toString());
  }

  /**
   * Checks if there is due date present to send push notification.
   *
   * @param arRequestData
   * @param objURLParams
   *
   * @returns
   */
  private sendPushNotifOnDueDate(arRequestData: LooseObject, objURLParams: URLSearchParams) {

    if (arRequestData['due_date']) {
      objURLParams.append('notify_via_push', this.notifyPush.shouldNotify(arRequestData['due_date']) ? '1' : '0');

      if (arRequestData['id']) {
        objURLParams.append('push_notification_body', this.notifyPush.makePushNotificationBody({[arRequestData['id']]: {due_date: arRequestData['due_date']}}));
      } else {
        objURLParams.append('push_notification_body', this.notifyPush.getNotificationBodyMessage(arRequestData['due_date']));
      }

    }

    return objURLParams;
  }

}
