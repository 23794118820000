
    <div class="row">
      <ng-select
        class="col-lg-12 col-md-12 col-xs-12"
        [(ngModel)]="values"
        [addTag]="onTagCreation"
        [isOpen]="false"
        [multiple]="true"
        (change)=onChanged($event)
        (focus)="onTouched()"
        bindLabel="0"
        (blur)="onBlur($event.target)"
        (keyup.enter)="onEnter($event.target)"
      >
      </ng-select>
    </div>
