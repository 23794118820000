import { NgModule } from '@angular/core';
import { ErrorHandlerComponent } from '../components/error-handler/error-handler.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ErrorHandlerComponent
  ],
  exports: [
    ErrorHandlerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
  ],
})
export class ErrorHandlerModule {}