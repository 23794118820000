// Interface for custom errors for Fieldmagic.
export class FMErrorInterface {
    detail: string;
    status: number;
    title: string;
}

// Object for custom errors for Fieldmagic.
export class FMError {
    detail: string;
    status: number;
    title: string;

    constructor(properties: FMErrorInterface) {
        this.detail = properties.detail;
        this.status = properties.status;
        this.title = properties.title;
    }
}