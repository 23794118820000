
<div class="file-upload-wrapper">
  <input #fileUploadInput class="file-input form-control" type="file"
  (click)="clearFileInput()"
  [accept]="accept"
  [multiple]="multiple"/>

  <div *ngIf="(filenames$ | async).length === 0; else fileNames" class="file-input-overlay">
    {{ 'Choose file to upload' | translate }}
  </div>

  <ng-template #fileNames>
    <p *ngIf="(filenames$ | async) as filename" class="file-input-overlay-uploaded text-break">
      {{ filename.join(", ") }}
    </p>
  </ng-template>
</div>
