export enum FieldType {
    Text = 'text',
    Address = 'address',
    Checkbox = 'checkbox',
    Number = 'number',
    Currency = 'currency',
    Dropdown = 'dropdown',
    Multiselect = 'multiselect',
    TextArea = 'textarea',
    Date = 'date',
    DateTime = 'datetime',
    Url = 'url'
};

export class EnumeratedTypeFields {
    jobs = {
        'type'  : ['simple_job', 'project'],
        'invoicing_type' : ['time_and_materials', 'fixed_price_invoices', 'flexible_invoicing', 'milestone_invoicing'],
        'priority' : ['low', 'normal', 'high', 'urgent'],
        'status' : ['awaiting_scheduling', 'scheduled', 'pending', 'awaiting_invoicing', 'completed', 'cancelled']
    };

    assets_jobs = {
        'status' : ['awaiting_completion', 'pass', 'fail']
    };
};

export enum FieldsWithCharacterLimit {
    'text' = 64,
    'textarea' = 2056,
    'url' = 2000
};