
  <div [formGroup]="parentForm" class="row">
    <div class="col-12 mb-2 text-right">
      <button
        id="reorder_config"
        type="button"
        class="btn btn-link"
        (click)="addItem()">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        <span class="pl-1">{{ 'add_item' | translate }}</span>
      </button>
    </div>
    <div class="col-12">
      <table
        [formArrayName]="field.key"
        class="table table-bordered">
        <thead>
          <tr>
            <th colspan="4" class="text-center fmc-input-label">{{ 'reorder_levels_table' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-template #emptyItemCodesMessage>
            <tr>
              <td class="text-center text-muted" colspan="4">{{ 'no_reorder_levels' | translate }}</td>
            </tr>
          </ng-template>
          <ng-container *ngIf="items.controls.length > 0; else emptyItemCodesMessage">
            <tr *ngFor="let control of items.controls; let i = index;" [formGroupName]="i">
              <td class="form-group w-50">
                <label class="font-weight-bold" for="code">
                  <required-tag>{{ 'warehouse' | translate }}</required-tag>
                </label>
                <ng-select
                  formControlName="warehouse_id"
                  placeholder="{{ 'select_warehouse' | translate }}"
                  matTooltip="{{ warehouseRelate[i].value?.text }}"
                  [items]="warehouseRelate[i].source | async"
                  [typeahead]="warehouseRelate[i].typehead"
                  [loading]="warehouseRelate[i].loader"
                  (change)="onChangeWarehouse(i, $event)"
                  (open)="warehouseRelate[i].loadDataOnClick()"
                  [clearable]="false"
                  bindLabel="text"
                  bindValue="id"
                  appendTo="body"
                  [ngClass]="{
                    'is-invalid': control.invalid && (control.touched || control.parent.touched)
                  }"
                >
                </ng-select>
              </td>
              <td class="form-group w-20">
                <label class="font-weight-bold" for="supplier">{{ 'reorder_quantity' | translate }}</label>
                <input
                  formControlName="min_reorder_level"
                  id="additional_code_min_reorder_level_{{ i }}"
                  type="number"
                  min=0
                  step="0.1"
                  class="form-control font-size-11"
                  (keypress)="negateNegative($event)"
                />
              </td>
              <td class="form-group w-20">
                <label class="font-weight-bold" for="unit_cost">{{ 'min_stock_level' | translate }}</label>
                <input
                  formControlName="min_stock_level"
                  id="additional_code_min_stock_level_{{ i }}"
                  type="number"
                  min=0
                  step="0.1"
                  class="form-control font-size-11"
                  (keypress)="negateNegative($event)"
                />
              </td>
              <td class="w-10 text-center">
                <button
                  id="add_additional_code_{{ i }}"
                  type="button"
                  class="btn btn-link text-success"
                  (click)="addItem()"
                  matTooltip="{{ 'add_item' | translate }}">
                  <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
                </button>
                <button
                  id="remove_additional_code_{{ i }}"
                  type="button"
                  class="btn btn-link text-danger"
                  (click)="removeItem(i)"
                  matTooltip="{{ 'remove' | translate }}">
                  <fa-icon [icon]="['fal', 'minus-circle']"></fa-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  