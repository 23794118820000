<ul class="list-inline float-right ml-2">
  <li *ngIf="record['erroneous_rows'] > 0" class="list-inline-item">
    <button type="button" class="btn btn-primary btn-danger" (click)="downloadResultSet('errors')">
      <fa-icon [icon]="['fas', 'download']"></fa-icon>
      <span class="pl-1">{{ 'rows_with_errors' | translate }}</span>
    </button>
  </li>
  <li *ngIf="record['imported_rows'] > 0" class="list-inline-item">
    <button type="button" class="btn btn-primary btn-success" (click)="downloadResultSet('success')">
      <fa-icon [icon]="['fas', 'download']"></fa-icon>
      <span class="pl-1">{{ 'imported_files' | translate }}</span>
    </button>
  </li>
</ul>