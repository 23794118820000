
    <ng-select
        appendTo="body"
        [bindLabel]="filter.viewModel.bindLabel"
        [bindValue]="filter.viewModel.bindValue"
        (focus)="filter.focusInput$.next()"
        (keydown)="onKeydown($event)"
        #inputRef
        [placeholder]="filter.viewModel.placeholder | translate"
        [typeahead]="domainTypeahead"
        [items]="itemsDomain"
        (change)="onChange($event)"
        (clear)="onClear()"
        (remove)="onRemove($event)"
        [multiple]="true"
        [hideSelected]="true"
        [clearable]="false"
        [(ngModel)]="filter.viewModel.value.value">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{ (item.label || item.name) | translate }}</span>
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <span class="ng-value-label" [title]="getToolTipContent(item)">{{ (item.label || item.name) | translate }}</span>
        </ng-template>
    </ng-select>
    <input autosize #inputAutosize type="text" [(ngModel)]="filterValue" [hidden]="true" />