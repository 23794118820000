import { Component, OnInit, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Notification } from '../../../../objects/notification';
import { NotificationService } from '../../../../services/notification.service';
import { Confirmation } from '../../../../objects/confirmation';
import { distinct, filter, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  selector: 'popups',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class PopupComponent implements OnInit {

  /**
   * List of notifications being shown.
   * Everything put here is temporary, so it will be removed
   * once their duration is over.
   *
   * @var {Notification[]}
   */
  public arPopupNotifications: Notification[] = [];

  public observer: MutationObserver;

  constructor(
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.listenToNotifications();
    this.listenToConfirmations();
  }

  /**
   * Sends out an event that an action in the
   * popup notification button has been triggered.
   *
   * @param {Notification} objNotification
   */
  buttonAction(objNotification: Notification): void {
    this.notificationService.sendNotificationAction(objNotification.id, true);
  }

  /**
   * Listens to the confirmation stream for any
   * events, and displays the modal dialog
   * if there are confirmation events triggered
   * from anywhere in our application.
   *
   * @returns {void}
   */
  private listenToConfirmations(): void {
    this.notificationService.confirm$
      .pipe(
        filter(confirmation => confirmation instanceof Confirmation),
        switchMap((confirmation) => this.dialog.open(ConfirmationComponent, {
          width: '500px',
          height: 'auto',
          data: confirmation
        }).afterClosed())
      ).subscribe(
        results => {
          this.notificationService.sendConfirmationAnswer(results.result, results.confirmation.type);
        }
      )
  }

  /**
   * Listens to the notification stream
   * for any notifications we have to show.
   * If there are notifications to show, we put them in
   * the list until their duration expires, then
   * we remove them from the list.
   *
   * @returns {void}
   */
  private listenToNotifications(): void {
    this.notificationService.notif$
      .pipe(
        filter(notification => notification instanceof Notification),
        distinct((notification) => notification.id)
      )
      .subscribe((notification: Notification) => {
        this.arPopupNotifications.push(notification);
        this.processNotification(notification);
      });
  }

  /**
   * The method that processess the removal
   * of the notification based on its duration
   * property. If the duration is set to 0,
   * the notification wont be automatically removed,
   * the user will need to hit the notification's close
   * icon manually.
   *
   * @param {Notification} objNotification
   *
   * @returns {void}
   */
  private processNotification(objNotification: Notification): void{
    if (objNotification.duration > 0) {
      setTimeout(() => {
        this.removeNotification(objNotification.id);
      }, objNotification.duration);
    }
  }

  /**
   * Removes the notification from the list
   * based from the given ID. We find the index
   * of the notification based from the ID and remove
   * it from the list using the index.
   *
   * @param {string|number} strNotificationId
   */
  private removeNotification(strNotificationId: string|number) {
    let numNotificationIndex: number = this.arPopupNotifications.findIndex(notification =>
      notification.id == strNotificationId
    );
    if (numNotificationIndex > -1) {
      this.arPopupNotifications.splice(numNotificationIndex, 1);
    }
  }

  /**
   * @inheritdoc
   */
  ngAfterViewInit() {
    // detect the changes in template
    const observer = new MutationObserver(list => {
      this.arPopupNotifications.forEach( data => {
        let notificationMessageElement = this.elementRef.nativeElement
          .querySelector(`.N-${data.id}`);
        if (notificationMessageElement) {
          notificationMessageElement = notificationMessageElement.querySelector('.delete-link');
        }
        // add remove notification in delete link
        // so that after the user click the download
        // the notification will be automatically close
        if (notificationMessageElement) {
          notificationMessageElement.addEventListener('click', () => {
            this.removeNotification(data.id);
          });
          notificationMessageElement.classList.remove('delete-link');
        }
      });
    });
    var config = { attributes: true, childList: true, subtree: true };
    observer.observe(this.elementRef.nativeElement, config);
  }

  /**
   * @inheritdoc
   */
  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
