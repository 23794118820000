
    <fieldmagic-dialog>
      <div class="dialog-header-content">
        <fieldmagic-dialog-title
          icon="envelope"
          label="{{ 'Message' | translate }}"
        >
        </fieldmagic-dialog-title>
      </div>
      <div class="dialog-header-buttons">
        <button class="btn btn-primary"
          (click)="onSave()"
        >
          {{ 'save' | translate }}</button>
        <button
          class="btn btn-danger"
          (click)="onClose()"
        >
          {{ 'cancel' | translate }}
        </button>
      </div>
      <div class="dialog-content" [formGroup]="form">
        <fieldmagic-textarea-input [max]="200" formControlName="message"></fieldmagic-textarea-input>
      </div>
    </fieldmagic-dialog>
  