import { FileService } from './../../../../../services/file/file.service';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpEvent } from '@angular/common/http/http';
import { WidgetService } from '../../../../../services/widget.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Notification } from '../../../../../objects/notification';
import { ViewService } from './../../../../../services/view.service';

interface AssetPhotoInterface {
  file_name: string,
  size: number,
  file_type: string,
  upload_name: string,
}
@Component({
  selector: "app-asset-photo-dialog",
  templateUrl: "./asset-photo-dialog.component.html",
  styleUrls: ["./asset-photo-dialog.component.scss"],
})

export class AssetPhotoDialogComponent implements OnInit {
  public bShowLoader: any = {
    submit: false,
  };

  public bSubmitted: boolean = false;
  public objPhotoUploaded: AssetPhotoInterface;
  public arRecordDetails: any = [];
  public isFileDropped: boolean = false;
  public isFileUploaded: boolean = false;
  public strDescription: string = "";
  public arPhotosToSave = [];
  public bEdited: boolean = false;

  @HostListener("window:keyup.esc") onKeyUp() {
    this.cancelDialog();
  }

  constructor(
    public dialogRef: MatDialogRef<AssetPhotoDialogComponent>,
    private fileService: FileService,
    public widgetService: WidgetService,
    private notifService: NotificationService,
    private viewService: ViewService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Store record details
    this.arRecordDetails = data;
  }

  ngOnInit() {
    if (this.arRecordDetails.photos) {
      this.arPhotosToSave = this.arRecordDetails.photos;
    }

    this.dialogRef.backdropClick().subscribe((_) => {
      this.cancelDialog();
    });
  }

  onFileChange(objData) {
    let reader = new FileReader();
    // If file is valid
    if (objData.target.files && objData.target.files.length) {
      this.isFileDropped = true;
      const [file] = objData.target.files;
      reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileService.uploadImage(file, file.type, file.size)
            .subscribe((response) => {

              this.isFileDropped = false;
              this.isFileUploaded = true;

              this.objPhotoUploaded = {
                file_name: file.name,
                size: file.size / 1024,
                file_type: file.type,
                upload_name: response["filename"],
              };

            },error => {
              this.isFileDropped = false;
            });
          this.bEdited = true;
        };

    }
  }

  /**
   * For Cancel button
   */
  cancelDialog(): void {
    if (this.bEdited) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation("confirm_cancel");
      // We wait for the confirmation response.
      this.notifService.confirm$.first().subscribe((confirmStream) => {
        if (confirmStream) {
          this.dialogRef.close("cancel");
        }
      });
    } else {
      this.dialogRef.close("cancel");
    }
  }

  /*
   * Submit the form's data to the Activities table
   * and send the email id not draft
   */
  onSubmit() {
    // Set submitted to true
    this.bSubmitted = true;
    // Check if file is set and is uploaded
    if (this.isFileUploaded == false) {
      this.notifService.sendNotification("upload_failed", "file_invalid", "warning");
      // Check character count of description
    } else if (this.strDescription.length > 1024) {
      this.notifService.sendNotification("upload_failed", "max_character", "warning");
    } else {
      // Setup request data
      this.arPhotosToSave.push(this.objPhotoUploaded);
      var requestData = {
        site_id: this.arRecordDetails.assets.site_id,
        asset_type_id: this.arRecordDetails.assets.asset_type_id,
        serial_number: this.arRecordDetails.assets.serial_number,
        walk_order: this.arRecordDetails.assets.walk_order,
        photos: this.arPhotosToSave
      };

      this.bSubmitted = false;
      this.dialogRef.close({ data: requestData });
    }
  }

  /**
   * Mark as edited
   */
  markAsEdited(): void {
    this.bEdited = true;
  }
}
