import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material';

type OnChangeHandler = (value?: boolean) => void;
type OnTouchedHandler = () => void;

@Component({
  selector: 'fieldmagic-checkbox-input',
  template: `
    <mat-slide-toggle
      [id]="id"
      [disabled]="isDisabled$ | async"
      class="font-size-11"
      (change)="onChange($event)"
      (blur)="onTouched()"
      [checked]="value"
    >
      <ng-content></ng-content>
    </mat-slide-toggle>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputComponent),
      multi: true,
    },
  ],
})
export class CheckboxInputComponent implements ControlValueAccessor {
  @Input() id: string;

  @Output('change') $onChange = new EventEmitter<boolean>();

  readonly isDisabled$ = new BehaviorSubject<boolean>(false);

  value: boolean;

  /**
   * Callback when input is touched
   */
  onTouched: OnTouchedHandler = () => { };

  private _onChange: OnChangeHandler;

  /**
   * @inheritdoc
   */
  registerOnChange(fn: OnChangeHandler): void {
    /// since we have a custom onChanged handler this
    /// will be an internal thing provided to update form accessor
    /// which will be notified in our onChange method
    this._onChange = fn;
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: OnTouchedHandler): void {
    this.onTouched = fn;
  }

  /**
   * {@inheritdoc}
   */
  setDisabledState(disabled: boolean): void {
    this.isDisabled$.next(disabled);
  }

  /**
   * @inheritdoc
   */
  writeValue(value?: boolean): void {
    this.value = value || false;
  }

  onChange(event: MatSlideToggleChange): void {
    this.$onChange.emit(event.checked);
    this._onChange(event.checked);
  }
}
