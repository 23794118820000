<input type="text"
            [(ngModel)]="filter.viewModel.value.value"
            (change)="_onChange(this.filter.viewModel.value);filter.onChange()"
            (focus)="filter.focusInput$.next()"
            (keydown)="advancedSearchBox.keydown($event,filter.viewModel)" 
            autosize 
            [placeholder]="filter.viewModel.placeholder | translate"
            [mask]="filter.viewModel.mask.mask"
            [specialCharacters]="filter.viewModel.mask.specialCharacters"
            [patterns]="filter.viewModel.mask.patterns"
            [dropSpecialCharacters]="filter.viewModel.mask.dropSpecialCharacters"
            [clearIfNotMatch]="filter.viewModel.mask.clearIfNotMatch"
            [allowNegativeNumbers]="filter.viewModel.mask.allowNegativeNumbers"
            [thousandSeparator]="filter.viewModel.mask.thousandSeparator"
            [maxLength]='maxLength'
            #inputRef
            class="as-text-input"
            />