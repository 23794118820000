
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {

  constructor() {}

  transform(numPhoneNumber: number) {

    let strPhoneNumber: string = numPhoneNumber.toString();
    let arRegexMatch = [];

    if (strPhoneNumber.length === 8) {
      arRegexMatch = strPhoneNumber.match(/.{1,4}/g);
    } else if (strPhoneNumber.length === 10) {
      arRegexMatch = [strPhoneNumber.slice(0, 3), strPhoneNumber.slice(3, 6), strPhoneNumber.slice(-4)];
    } else {
      arRegexMatch = strPhoneNumber.match(/.{1,3}/g);
    }

    return arRegexMatch.join(' ');
  }
} 