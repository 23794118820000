<app-error-handler onlyFor="customer_invoices:list" alignment="center" displayAs="widget">
<div class="d-flex justify-content-around contacts-option tabs-content">
    <ng-container *fcHasPermission="'customer_invoices:create'; else createCustomerInvoiceDisabled">
        <span id="widgetCustomerInvoicesCreateButton" (click)="(bPageLoaded && bDialogLoaded) ? createInvoice() : ''" class="span-links">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
            {{ 'create_job_invoice' | translate }}
        </span>
    </ng-container>
    <ng-template #createCustomerInvoiceDisabled>
        <span id="widgetCustomerInvoicesCreateButton" class="span-links disabled" matTooltip="{{ 'forbidden_action_error' | translate }}">
            <fa-icon [icon]="['fas', 'plus-circle']" class="text-default"></fa-icon>
            {{ 'create_job_invoice' | translate }}
        </span>
    </ng-template>
</div>
<div class="contact-roles-body">
    <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pb-4">
            <div class="form-inline mt-12">
                <div class="form-group flex-nowrap w-100">
                    <div class="col-xs-4 col-md-4 col-lg-4">
                        <label class="jc-l">{{ 'filter_by_status' | translate }}</label>
                    </div>
                    <div class="col-xs-4 col-md-4 col-lg-8">
                        <ng-select
                            [items]="arStatusFilter"
                            [clearable]="true"
                            class="value-text"
                            (change)="filter()"
                            [(ngModel)]="strSelectStatus"
                            id="widgetCustomerInvoicesStatusFilter">
                            <ng-template ng-label-tmp let-item="item">{{ item | translate }}</ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              <span id="widgetCustomerInvoicesStatusFilter{{ item }}">{{ item | translate }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="row border-top pt-2 pb-2">
            <div class="col-sm-6 pt-1">
                <span class="module-title">
                    <fa-icon [icon]="['fa', 'dollar-sign']" aria-hidden="true"></fa-icon>
                    &nbsp;
                    {{ 'customer_invoices' | translate }}
                </span>
            </div>
            <div class="col-sm-6 pr-2">
                <nav class="pagination-section">
                    <ul class="pagination">
                        <refresh-pagination-btn id="widgetCustomerInvoicesRefreshButton" (refresh)="onRefresh()" [isDisabled]="! bPageLoaded"></refresh-pagination-btn>
                        <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetCustomerInvoicesPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev', (strSelectStatus != null && strSelectStatus != '') ? { status : strSelectStatus} : {})">
                                <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                        <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                            <a id="widgetCustomerInvoicesNextButton" class="page-link" aria-label="first" (click)="fetchList('next', (strSelectStatus != null && strSelectStatus != '') ? { status : strSelectStatus} : {})">
                                <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <ng-container *ngIf="bPageLoaded">
          <div *ngIf="arInvoices.length < 1">
              <div class="row border-top">
                  <span class="no-content">
                      {{ 'no_invoices' | translate }}
                  </span>
              </div>
          </div>
          <div class="row border-top pt-2 pb-2 pr-2"  *ngFor="let invoice of arInvoices" [id]="'widgetCustomerInvoicesRecord' + invoice.id">
              <div class="col-sm-1">
                  <fa-icon matTooltip="{{ invoice.status | translate }}" class="icon-size float-left {{objColors['customer_invoices'][invoice.status]}}" [icon]="['fas', 'circle']"></fa-icon>
              </div>
              <div class="col-sm-3">
                  <div class="row" matTooltip="{{ 'invoice_number' | translate }}: {{ invoice.text }}">
                      <div class="col-12 truncate">
                        <a id="widgetCustomerInvoicesLabel" [routerLink]="['/customer_invoices/'+invoice.id]">
                            <span> #{{ invoice.text }}</span>
                        </a>
                      </div>
                  </div>
                  <div class="row" matTooltip="{{ 'invoice_summary' | translate }}: {{ invoice.invoice_summary }}">
                      <div class="col-12 truncate">
                          <span> {{ invoice.invoice_summary }} </span>
                      </div>
                  </div>
              </div>
              <div class="col-sm-3">
                  <div class="row" matTooltip="{{ 'date_invoice' | translate }}: {{ formatDate(invoice.date_invoice) }}">
                      <div class="col-12 truncate">
                          <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                          {{ formatDate(invoice.date_invoice) }}
                      </div>
                  </div>
                  <div class="row" matTooltip="{{ 'date_due' | translate }}: {{ formatDate(invoice.date_due) }}">
                      <div class="col-12 truncate">
                          <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                          {{ formatDate(invoice.date_due) }}
                      </div>
                  </div>
              </div>
              <div class="col-sm-2">
                  <div class="row" matTooltip="{{ 'amount_tax_ex' | translate }}: {{ invoice.amount_tax_ex | currency }}">
                      <div class="col-12 truncate">
                        <span *ngIf="invoice.amount_tax_ex">{{ invoice.amount_tax_ex | currency: {decimal_places: 2} }}</span>
                      </div>
                  </div>
                  <div class="row" matTooltip="{{ 'amount_tax_inc' | translate }}: {{ invoice.amount_tax_inc | currency }}">
                      <div class="col-12 truncate">
                        <span *ngIf="invoice.amount_tax_inc">{{ invoice.amount_tax_inc | currency: {decimal_places: 2} }}</span>
                      </div>
                  </div>
              </div>
              <div class="col-sm-2">
                  <div class="row" matTooltip="{{ 'amount_due' | translate }}: {{ invoice.amount_due | currency }}">
                      <div class="col-12 truncate">
                        <span *ngIf="invoice.amount_due">{{ invoice.amount_due | currency: {decimal_places: 2} }}</span>
                      </div>
                  </div>
              </div>
              <div class="col-sm-1 d-table text-role less-padding">
                  <div class="row">
                      <div class="col-12">
                            <form-templates-preview-default-template-btn
                                [as-span]="true"
                                icon-class="text-danger"
                                btn-class=""
                                [hide-text]="true"
                                class="pointer float-right ml-2"
                                [module-id]="invoice.id"
                                document-type="customer_invoice_report"
                                module-name="customer_invoices"
                                id="widgetCustomerInvoicesPreviewButton">
                            </form-templates-preview-default-template-btn>
                            <ng-container *fcHasPermission="'customer_invoices:edit' else disabledEditButton">
                                <fa-icon id="widgetCustomerInvoicesEditButton" matTooltip="{{ 'edit' | translate }}" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="(bDialogLoaded) ? openInvoice(invoice) : ''"></fa-icon>
                            </ng-container>
                            <ng-template #disabledEditButton>
                                <fa-icon id="widgetCustomerInvoicesEditButton" class="disabled float-right" [icon]="['fas', 'pencil']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                            </ng-template>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12">
                            <ng-container *fcHasPermission="'customer_invoices:delete' else disabledDeleteButton">
                                <fa-icon id="widgetCustomerInvoicesDeleteButton" matTooltip="{{ 'delete' | translate }}" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteInvoice(invoice.id)"></fa-icon>
                            </ng-container>
                            <ng-template #disabledDeleteButton>
                                <fa-icon id="widgetCustomerInvoicesDeleteButton" class="disabled float-right" [icon]="['fas', 'trash-alt']" matTooltip="{{ 'forbidden_action_error' | translate }}"></fa-icon>
                            </ng-template>
                      </div>
                  </div>
              </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!bPageLoaded">
            <div class="row border-top">
                <span class="no-content">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                    {{ 'loading' | translate }}
                </span>
            </div>
        </ng-container>
    </div>
</div>
</app-error-handler>
