import { Injectable } from '@angular/core';
import { round } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NumberService {

  constructor() {}

  /**
   * Rounded in to two decimal places
   * @param value
   * @return number
   */
  roundToTwo(value: number): number {
    if (isNaN(value) || value == null) {
      return 0;
    } else {
      return round(value, 2);
    }
  }
}
