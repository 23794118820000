
    <div [formGroup]="form">
        <ng-template #rt let-r="result" let-t="term">
            {{ translateLabel(r) }}
        </ng-template>
        <ng-container *ngFor="let filter of viewModel; trackBy: trackByFn;" >
            <ng-container [ngSwitch]="filter.type" >
                <as-input [id]="'as-filter-input-'+filter.model" *ngSwitchCase="'INPUT'" [viewModel]="filter" class="as-filter" [formControl]="form.get([filter.model+'_'+filter.uuid])" ></as-input>
                <as-input-operators [id]="'as-filter-input-'+filter.model" *ngSwitchCase="'OPERATORS'" [viewModel]="filter" class="as-filter" [formControl]="form.get([filter.model+'_'+filter.uuid])" ></as-input-operators>
                <ng-container *ngTemplateOutlet="externalTemplate; context: {$implicit: filter}"></ng-container>
            </ng-container>
        </ng-container>
        <span class="searchBox">
            <input
              type="text"
              id="searchBootstrap"
              class="form-control form-control-sm"
              #searchbox
              autosize
              (keydown)="keydown($event)"
              (focus)="focusInput$.next()"
              placeholder="{{placeholder | translate}}"
              aria-label="Number"
              [formControl]="searchBoxControl"
              [matAutocomplete]="auto">
            <span *ngIf="searchBoxValue"><fa-icon [icon]="['fas', 'times']" (click)="onClearSearchBox()"></fa-icon></span>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addFilter($event)" [displayWith]="displayAutocompleteFn" panelWidth="500">
                <mat-option *ngFor="let option of searchBoxFunc | async" [value]="option" [id]="'as-filter-dropdown-'+option.model" class="as-option">
                    {{ translateLabel(option) }}
                </mat-option>
            </mat-autocomplete>
        </span>
    </div>
    