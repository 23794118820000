import { LooseObject } from './loose-object';

export class Address {
  constructor(
    public latitude?: number,
    public longitude?: number,
    public street?: string,
    public unit?: string,
    public level?: string,
    public building_name?: string,
    public lot_number?: string,
    public city?: string,
    public state?: string,
    public country?: string,
    public zip?: string
  ) { }

  /**
   * Didn't want to break anything for now
   * so I'm creating a function to set the addres
   * without needing to assign each field in their
   * respective orders.
   *
   * @param properties - address properties
   * @return Address
   */
  setProperties(properties: {
    latitude?: number,
    longitude?: number,
    street?: string,
    unit?: string,
    level?: string,
    building_name?: string,
    lot_number?: string,
    city?: string,
    state?: string,
    country?: string,
    zip?: string
  } = {}): Address {
    this.latitude = properties.latitude;
    this.longitude = properties.longitude;
    this.street = properties.street;
    this.unit = properties.unit;
    this.level = properties.level;
    this.building_name = properties.building_name;
    this.lot_number = properties.lot_number;
    this.city = properties.city;
    this.state = properties.state;
    this.country = properties.country;
    this.zip = properties.zip;
    return this;
  }

  /**
   * Set the address properties but instead of undefined
   * for not set properties, make it null.
   *
   * @param {Address} properties
   *
   * @returns {Address}
   */
  setPropertiesToNull(properties: {
    latitude?: number,
    longitude?: number,
    street?: string,
    unit?: string,
    level?: string,
    building_name?: string,
    lot_number?: string,
    city?: string,
    state?: string,
    country?: string,
    zip?: string
  } = {}): Address {
    this.latitude = properties.latitude || null;
    this.longitude = properties.longitude || null;
    this.street = properties.street || null;
    this.unit = properties.unit || null;
    this.level = properties.level || null;
    this.building_name = properties.building_name || null;
    this.lot_number = properties.lot_number || null;
    this.city = properties.city  || null;
    this.state = properties.state || null;
    this.country = properties.country || null;
    this.zip = properties.zip || null;
    return Object.assign({}, this);
  }

  /**
   * Checks if the current address is valid.
   *
   * @returns {boolean}
   */
  public isValidAddress(): boolean {
    return (this.city != null && this.city != '') && (this.street != null && this.street != '') && (this.zip != null && this.zip != '');
  }

}
