import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonPaginationComponent } from './pagination/common-pagination.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  declarations: [
    CommonPaginationComponent,
  ],
  entryComponents: [
    CommonPaginationComponent
  ],
  exports: [
    CommonPaginationComponent
  ],
})
export class CommonPaginationModule { }
