import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { AccountingSystemService, DebugSyncResult } from '../../../services/accounting_system.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { filter, finalize, tap } from 'rxjs/operators';
import { iif, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { AccountingSyncRequestLogComponent, AccountingSyncRequestLogComponentProps } from './request-log-dialog/request-log-dialog.component';

@Component({
  selector: 'integrations-accounting-system-sync-module',
  templateUrl: './sync.component.html',
  styleUrls: [
    './sync.component.scss',
  ]
})
export class AccountingSyncButtonComponent implements OnDestroy, OnInit {
  /**
   * contains the current module to be synced
   *
   * @var {string}
   */
  @Input('module-name') moduleName: string;

  /**
   * contains the current module unique id to be synced
   *
   * @var {string}
   */
  @Input('module-id') moduleId: string;

  /**
   * @type {EventEmitter<AccountingActions>}
   */
  @Output('onSuccessfulAction') onSuccessfulAction: EventEmitter<AccountingSyncAction> = new EventEmitter;

  /**
   * @type {boolean}
   */
  isPullingAnAmountDueUpdate: boolean = false;

  /**
   * @type {boolean}
   */
  isSyncing: boolean = false;

  /**
   * @type {boolean}
   */
  debugging: boolean = false;

  /**
   * determines if the current button should be displayed
   *
   * @var {boolean}
   */
  shouldDisplay: boolean = false;

  /**
   * INTERNAL USE: contains all the subscription instance that is currently used by the component and later be cleanup
   *
   * @var {Subscription[]}
   */
   protected subscriptions: Subscription[] = [];

  /**
   * @param {AccountingSystemService} service
   * @param {NotificationService} notification
   */
  constructor(
    protected service: AccountingSystemService,
    protected notification: NotificationService,
    protected dialogFactory: MatDialog
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.service.hasIntegratedDriver$().subscribe((result) => this.shouldDisplay = result)
    );
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * handles click event when user wants to sync the current module
   *
   * @param {Event} objEvent
   * @param {boolean} debug
   *
   * @returns {boolean}
   */
  sync(objEvent: Event, bDebug: boolean = false): boolean {
    // disables the click event to be propagated back to the parent which will disables the menu
    // selection to be closed
    objEvent.stopPropagation();

    this.toggleSyncFlag(bDebug);

    this.subscriptions.push(
      iif(
        () => bDebug,
        this.service.syncDebug$(this.moduleId, this.moduleName),
        this.service.scheduleSync$(this.moduleId, this.moduleName)
      )
      .pipe(
        finalize(() => this.toggleSyncFlag(bDebug)),
        tap((objResult: DebugSyncResult) => {
          if (bDebug && objResult.requestLogId.length > 0) {
            this.dialogFactory.open<AccountingSyncRequestLogComponent, AccountingSyncRequestLogComponentProps>(
              AccountingSyncRequestLogComponent, {
                minWidth: '70%',
                minHeight: '90%',
                data: {
                  logId: objResult.requestLogId,
                }
              }
            );
          }
        }),
        tap((objResult) => objResult.isSuccessful && this.notification.notifySuccess(
          (bDebug) ? 'accounting_sync_successfully' : 'scheduled_sync_successful'
        )),
        filter((objResult) => objResult.isSuccessful),
        tap({
          next: () => this.onSuccessfulAction.emit(
            (bDebug) ? AccountingSyncAction.ACTION_DEBUG_SYNC : AccountingSyncAction.ACTION_SYNC_IN_BACKGROUND
          )
        })
      )
      .subscribe()
    );

    return false;
  }

  /**
   * Handles update amount due
   *
   * @param {Event} event
   *
   * @returns {boolean}
   */
  updateAmountDue(event: Event): boolean {
    // disables the click event to be propagated back to the parent which will disables the menu
    // selection to be closed
    event.stopPropagation();

    this.isPullingAnAmountDueUpdate = true;

    this.subscriptions.push(
      this.service.updateAmountDue$(this.moduleId, this.moduleName)
        .pipe(
          finalize(() => this.isPullingAnAmountDueUpdate = false),
          tap({
            next: () => this.notification.notifySuccess('amount_due_updated_successfully'),
          }),
          tap(() => this.onSuccessfulAction.emit(AccountingSyncAction.ACTION_UPDATE_AMOUNT_DUE))
        )
        .subscribe()
    );

    return false;
  }

  /**
   * @type {boolean}
   */
  get isInvoiceModule(): boolean {
    return this.moduleName === 'customer_invoices'
      || this.moduleName === 'supplier_invoices';
  }

  /**
   * Toggles the sync flags
   *
   * @param {boolean} debug
   */
  protected toggleSyncFlag(debug: boolean = false): void {
    if (debug) {
      this.debugging = ! this.debugging;
    } else {
      this.isSyncing = ! this.isSyncing;
    }
  }
}

export enum AccountingSyncAction {
  ACTION_SYNC_IN_BACKGROUND,
  ACTION_UPDATE_AMOUNT_DUE,
  ACTION_DEBUG_SYNC,
};