<div class="row">
  <div class="col-md-12 d-flex justify-content-end pb-3">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link" (click)="onChangePage('default')">
          <fa-icon [icon]="['far', 'sync-alt']" aria-hidden="true"></fa-icon>
        </a>
      </li>
      <li class="page-item" [ngClass]="(hasPrevPage) ? 'text-primary pointer' : 'disabled'">
        <a class="page-link" (click)="onChangePage('default')">
          <fa-icon [icon]="['far', 'chevron-double-left']" aria-hidden="true"></fa-icon>
        </a>
      </li>
      <li class="page-item" [ngClass]="(hasPrevPage) ? 'text-primary pointer' : 'disabled'">
        <a class="page-link" (click)="onChangePage('prev')">
          <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
        </a>
      </li>
      <ng-container *ngIf="hasNavigation">
        <ng-container *ngFor="let numPage of navigation">
          <li class="page-item" [ngClass]="{'disabled': currentPage == numPage}">
            <a id="nagvigateToPage_{{numPage}}" class="page-link pr-1 pl-1" (click)="onChangePage(numPage)" aria-label="first">
              {{ numPage }}
            </a>
          </li>
        </ng-container>
      </ng-container>
      <li class="page-item" [ngClass]="(hasNextPage) ? 'text-primary pointer' : 'disabled'">
        <a class="page-link" (click)="onChangePage('next')">
          <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
        </a>
      </li>
    </ul>
  </div>
</div>