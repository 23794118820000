<td *ngIf="bIsUnderGroup" class="text-center {{ objPromptColumnClasses.prompt_drag_class }}">
  <div class="bg-indent"></div>
</td>

<!-- For REARRANGING prompts -->
<ng-content></ng-content>

<td class="text-center {{ objPromptColumnClasses.prompt_name_class }} pl-0">
  <div class="d-flex align-items-center gap-10">
    <textarea
      id="promptName-{{ numIndex }}"
      cdkTextareaAutosize
      #promptName="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="3"
      ngDefaultControl
      [(ngModel)]="objPrompt['prompt']"
      class="form-control text-size"
      [value]=""
      (focus)="setTextareaHeight($event, promptName, 3)"
      (focusout)="setTextareaHeight($event, promptName, 2)"
      (ngModelChange)="emitAsEdited()">
    </textarea>
  </div>
</td>

<td class="text-center {{ objPromptColumnClasses.prompt_type_class }}">
  <ng-select
    id="promptType-{{ numIndex }}"
    class="select-input"
    ngDefaultControl
    [items]="arPromptTypes"
    bindLabel="checklist_type"
    [bindValue]
    (change)="checkPromptType($event)"
    [ngModelOptions]="{standalone: true}"
    [(ngModel)]="objPrompt['type']"
    (ngModelChange)="emitAsEdited()">

    <ng-template ng-option-tmp let-item="item" class="value-text">
        {{ item | translate }}
    </ng-template>

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        {{ item | translate }}
    </ng-template>

  </ng-select>
</td>

<td class="text-center {{ objPromptColumnClasses.prompt_value_class }}">
  <!-- For NULL, SIGNATURE, IMAGE, DATE, NUMBER, TEXT Types -->
  <span *ngIf="objPrompt['type'] == null || objPrompt['type'] == 'signature' || objPrompt['type'] == 'image' || objPrompt['type'] == 'text' || objPrompt['type'] == 'date' || objPrompt['type'] == 'number'">--</span>

  <!-- For PASS or FAIL Prompt Type -->
  <div *ngIf="objPrompt['type'] == 'pass/fail'">
    <mat-form-field class="dd-chip-pass" id="passValues-{{ numIndex }}">
      <mat-chip-list #chipListPassValue>
        <mat-chip *ngFor="let passValue of objPrompt['value']['pass']"
          class="chip-background-pass"
          [selectable]="false"
          [removable]="true"
          (removed)="removeChip(passValue, 'pass')">
          {{ passValue }}
          <mat-icon class="chip-remove d-flex justify-content-center" matChipRemove>
            &times;
          </mat-icon>
        </mat-chip>
        <input
          class="chip-input"
          placeholder="{{ 'pass_values' | translate }}"
          [matChipInputFor]="chipListPassValue"
          [matChipInputSeparatorKeyCodes]="arSeparatorKeysCodes"
          [matChipInputAddOnBlur]="bAddOnBlur"
          (matChipInputTokenEnd)="addChip($event, 'pass')">
      </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="dd-chip-fail" id="failValues-{{ numIndex }}">
      <mat-chip-list #chipListFailValue aria-label="fail_values">
        <mat-chip *ngFor="let failValue of objPrompt['value']['fail']"
          class="chip-background-fail"
          [selectable]="false"
          [removable]="true"
          (removed)="removeChip(failValue, 'fail')">
          {{ failValue }}
          <mat-icon class="chip-remove d-flex justify-content-center" matChipRemove>
            &times;
          </mat-icon>
        </mat-chip>
        <input
          class="chip-input"
          placeholder="{{ 'fail_values' | translate }}"
          [matChipInputFor]="chipListFailValue"
          [matChipInputSeparatorKeyCodes]="arSeparatorKeysCodes"
          [matChipInputAddOnBlur]="bAddOnBlur"
          (matChipInputTokenEnd)="addChip($event, 'fail')">
      </mat-chip-list>
    </mat-form-field>
  </div>

  <!-- PROMPT TYPES THAT USES JOBS/OPPORTUNITIES AND SITES FIELDS AS FIELD ENTRIES (field_entry and instruction) -->
  <ng-container *ngIf="strPromptCategory === 'main_prompts'">
    <!-- For INSTRUCTIONS Prompt Type -->
    <div *ngIf="objPrompt['type'] === 'instructions' && arFieldEntries.length > 0">
      <div class="container-instruction" >
        <ng-select
          id="instructionsField-{{ numIndex }}"
          class="select-input"
          [items]="arFieldEntries"
          [typeahead]="objSearcher['typehead']"
          [loading]="objSearcher['loader']"
          [hideSelected]="true"
          groupBy="{{ 'field_type' | translate }}"
          [clearable]="false"
          [ngModelOptions]="{standalone: true}"
          bindLabel="display"
          bindValue="id"
          class="dd-instruction select-input"
          [(ngModel)]="objPrompt['value']['instructions']">
          <ng-template ng-option-tmp let-item="item" class="value-text">
            {{ item.display | translate }}
          </ng-template>

          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            {{ item.display | translate }}
          </ng-template>

          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.field_type | translate }}
          </ng-template>

        </ng-select>

        <fa-icon
          [icon]="['fas', 'plus-circle']"
          (click)="addInstructionVariable(objPrompt['value']['instructions'])"
          matTooltip="{{ 'tooltip_add_instruction' | translate }}"
          class="instruction-add-button">
        </fa-icon>
      </div>
      <div class="text-instruction">
        <textarea
          id="instructionsText-{{ numIndex }}"
          ngDefaultControl
          cdkTextareaAutosize
          #instructionsText="cdkTextareaAutosize"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="3"
          (focus)="setTextareaHeight($event, instructionsText, 3)"
          (focusout)="setTextareaHeight($event, instructionsText, 2)"
          name="objPrompt['value']['instructions_text']"
          [(ngModel)]="objPrompt['value']['instructions_text']"
          class="form-control text-size">
        </textarea>
      </div>
    </div>

    <!-- For FIELD ENTRY Prompt Type -->
    <div *ngIf="objPrompt['type'] == 'field_entry' && arFieldEntries.length > 0">
      <ng-select
        id="fieldEntry-{{ numIndex }}"
        class="select-input"
        [items]="arFieldEntries"
        [typeahead]="objSearcher['typehead']"
        [loading]="objSearcher['loader']"
        [hideSelected]="true"
        groupBy="{{ 'field_type' | translate }}"
        [clearable]="false"
        [ngModelOptions]="{standalone: true}"
        bindLabel="display"
        bindValue="id"
        [(ngModel)]="objPrompt['value']['field_entry']">

        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          {{ item.display | translate }}
        </ng-template>

        <ng-template ng-optgroup-tmp let-item="item">
          {{ item.field_type | translate }}
        </ng-template>

        <ng-template  ng-option-tmp let-item="item">
          {{ item.display | translate }}
        </ng-template>

      </ng-select>
    </div>
  </ng-container>

    <!-- PROMPT TYPES THAT USES ASSET TYPES AS FIELD ENTRIES (field_entry and instruction) -->
    <ng-container *ngIf="strPromptCategory !== 'main_prompts'">
      <!-- For INSTRUCTIONS Prompt Type -->
      <div *ngIf="objPrompt['type'] === 'instructions' && arAssetFieldEntries.length > 0">
        <div class="container-instruction" >
          <ng-select
            id="assetInstructionsField-{{ numIndex }}"
            [items]="arAssetFieldEntries"
            [typeahead]="objSearcher['typehead']"
            [loading]="objSearcher['loader']"
            [hideSelected]="true"
            groupBy="{{ 'field_type' | translate }}"
            [clearable]="false"
            [ngModelOptions]="{standalone: true}"
            bindLabel="field"
            bindValue="id"
            class="dd-instruction select-input"
            [(ngModel)]="objPrompt['value']['instructions']">
            <ng-template ng-option-tmp let-item="item" class="value-text">
              {{ item.display | translate }}
            </ng-template>

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              {{ item.display | translate }}
            </ng-template>

            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.field_type | translate }}
            </ng-template>
          </ng-select>

          <fa-icon
            [icon]="['fas', 'plus-circle']"
            (click)="addInstructionVariable(objPrompt['value']['instructions'])"
            matTooltip="{{ 'tooltip_add_instruction' | translate }}"
            class="instruction-add-button">
          </fa-icon>
        </div>
        <div class="text-instruction">
          <textarea
            id="assetInstructionsText-{{ numIndex }}"
            ngDefaultControl
            cdkTextareaAutosize
            #assetInstructionsText="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="3"
            (focus)="setTextareaHeight($event, assetInstructionsText, 3)"
            (focusout)="setTextareaHeight($event, assetInstructionsText, 2)"
            name="objPrompt['value']['instructions_text']"
            [(ngModel)]="objPrompt['value']['instructions_text']"
            class="form-control text-size">
          </textarea>
        </div>
      </div>

    <!-- For FIELD ENTRY Prompt Type -->
    <div *ngIf="objPrompt['type'] == 'field_entry' && arAssetFieldEntries.length > 0">
      <ng-select
        id="assetFieldEntry-{{ numIndex }}"
        class="select-input"
        [items]="arAssetFieldEntries"
        [typeahead]="objSearcher['typehead']"
        [loading]="objSearcher['loader']"
        [hideSelected]="true"
        groupBy="{{ 'field_type' | translate }}"
        [clearable]="false"
        [ngModelOptions]="{standalone: true}"
        bindLabel="display"
        bindValue="id"
        [(ngModel)]="objPrompt['value']['field_entry']">

        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          {{ item.display | translate }}
        </ng-template>

        <ng-template ng-optgroup-tmp let-item="item">
          {{ item.field_type | translate }}
        </ng-template>

        <ng-template  ng-option-tmp let-item="item">
          {{ item.display | translate }}
        </ng-template>
      </ng-select>
    </div>

  </ng-container>

  <!-- For DROPDOWN Prompt Type -->
  <div *ngIf="objPrompt['type'] == 'dropdown'">
    <mat-form-field class="dd-chip" id="dropdownField-{{ numIndex }}">
      <mat-chip-list #chipListDropdown aria-label="dropdown_values">
        <mat-chip *ngFor="let ddValue of objPrompt['value']['dropdown']"
          class="chip-background-dropdown"
          [selectable]="false"
          [removable]="true"
          (removed)="removeChip(ddValue, 'dropdown')">
          {{ ddValue | translate }}
          <mat-icon class="chip-remove d-flex justify-content-center" matChipRemove>
            &times;
          </mat-icon>
        </mat-chip>
        <input
          class="chip-input"
          placeholder="{{ 'dropdown_values' | translate }}"
          [matChipInputFor]="chipListDropdown"
          [matChipInputSeparatorKeyCodes]="arSeparatorKeysCodes"
          [matChipInputAddOnBlur]="bAddOnBlur"
          (matChipInputTokenEnd)="addChip($event, 'dropdown')">
      </mat-chip-list>
    </mat-form-field>
  </div>
</td>

<!-- For SCHEDULE type -->
<td class="{{ objPromptColumnClasses.prompt_sched_class }}">
  <div *ngFor="let item of arPeriodList; index as i" class="checkbox-period">
    <span>
      <input ngDefaultControl id="prompt-{{ numIndex }}-ScheduleType-{{ i }}" type="checkbox" name="objPrompt['schedule_type'][item]" [(ngModel)]="objPrompt['schedule_type'][item]" (ngModelChange)="emitAsEdited()"> {{ item | translate }}
    </span>
  </div>
</td>

<!-- For REQUIRED checkbox -->
<td class="text-center {{ objPromptColumnClasses.prompt_req_class }}">
  <div class="d-flex align-items-end justify-content-center">
    <input ngDefaultControl id="chkboxRequired-{{ numIndex }}" type="checkbox" name="isRequired" [disabled]="objPrompt['type'] == 'instructions'" class="checkbox-objPrompt" [(ngModel)]="objPrompt['is_required']" (ngModelChange)="emitAsEdited()">
  </div>
</td>

<!-- For VISIBLE IN REPORTS checkbox -->
<td class="text-center {{ objPromptColumnClasses.prompt_icons_class }}">
  <div class="d-flex align-items-end justify-content-center">
    <input ngDefaultControl id="chkboxVisible-{{ numIndex }}" type="checkbox" name="isVisibleInReports" class="checkbox-objPrompt" [(ngModel)]="objPrompt['is_visible_in_reports']" (ngModelChange)="emitAsEdited()">
  </div>
</td>

<!-- For REMOVAL OF PROMPT checkbox -->
<td class="text-center {{ objPromptColumnClasses.prompt_icons_class }}">
  <fa-icon id="removePrompt-{{ numIndex }}" [icon]="['fas', 'trash-alt']" class="text-warning pointer" (click)="emitIndex()"></fa-icon>
</td>