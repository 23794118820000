<mat-dialog-content>
  <mat-horizontal-stepper *ngIf="! isCheckingState" (selectionChange)="handleSelectionChange($event)" [selectedIndex]="currentStepIndex" #wizard>
    <!-- Step: Authorization -->
    <mat-step label="{{ 'step_authorization' | translate }}" [editable]="false" aria-label="step_authorization" [completed]="isStepCompleted('step_authorization')">
      <wizard-step-authorization [driver]="driver" (event-progress)="handleEventProgressEmitted($event)" *ngIf="currentStep === 'step_authorization'"></wizard-step-authorization>
    </mat-step>

    <!-- Step: Import tax and account codes -->
    <mat-step label="{{ 'step_import_tax_and_account_codes' | translate }}" [editable]="false" aria-label="step_import_tax_and_account_codes" [completed]="isStepCompleted('step_import_tax_and_account_codes')">
      <wizard-step-import-multiple-modules (event-progress)="handleEventProgressEmitted($event)" *ngIf="currentStep === 'step_import_tax_and_account_codes'">
        <!-- Sub Step: Import Tax Codes -->
        <wizard-step-import-tax-codes [driver]="driver" #steps></wizard-step-import-tax-codes>
        <!-- Sub Step: Import Account Codes -->
        <wizard-step-import-account-codes [driver]="driver" #steps></wizard-step-import-account-codes>
      </wizard-step-import-multiple-modules>

      <!-- TODO: this would cause bloated code in the future, will think how to reimplement this tips -->
      <div *ngIf="driver.id === 'quickbooks_desktop'" class="row mt-3">
        <div class="col-2"></div>
        <div class="col-8 text-secondary text-center">
          <small>
            <b>{{ 'tip' | translate }}: </b>
            {{ 'qbdekstop_tip' | translate }}
          </small>
        </div>
        <div class="col-2"></div>
      </div>

    </mat-step>
    <!-- Step: Import Items -->
    <mat-step label="{{ 'step_import_items' | translate }}" [editable]="false" aria-label="step_import_items" [completed]="isStepCompleted('step_import_items')">
      <wizard-step-import-items [driver]="driver" (event-progress)="handleEventProgressEmitted($event)" *ngIf="currentStep === 'step_import_items'"></wizard-step-import-items>
    </mat-step>

    <!-- Step: Import customers, suppliers and contacts -->
    <mat-step label="{{ 'step_import_customers_suppliers_and_contacts' | translate }}" [editable]="false" aria-label="step_import_customers_suppliers_and_contacts" [completed]="isStepCompleted('step_import_customers_suppliers_and_contacts')">
      <wizard-step-import-multiple-modules (event-progress)="handleEventProgressEmitted($event)" *ngIf="currentStep === 'step_import_customers_suppliers_and_contacts'" [canBeSkipped]="true">
        <!-- Sub Step: Import Customers and Suppliers -->
        <wizard-step-schedule-import-customers-and-suppliers [driver]="driver" #steps></wizard-step-schedule-import-customers-and-suppliers>
        <!-- Sub Step: Import Contacts -->
        <wizard-step-schedule-import-contacts [driver]="driver" #steps></wizard-step-schedule-import-contacts>
      </wizard-step-import-multiple-modules>
    </mat-step>

    <!-- Configure driver -->
    <mat-step id="step_setup_driver_configuration" label="{{ 'step_setup_driver_configuration' | translate }}" [editable]="false" aria-label="step_setup_driver_configuration"  [completed]="isStepCompleted('step_setup_driver_configuration')" *ngIf="hasConfigurableMetadata()">
      <wizard-step-configure-driver [driver]="driver" (event-progress)="handleEventProgressEmitted($event)" *ngIf="currentStep === 'step_setup_driver_configuration'"></wizard-step-configure-driver>
    </mat-step>

    <!-- Configure defaults -->
    <mat-step label="{{ 'step_setup_system_defaults' | translate }}" [editable]="false" aria-label="step_setup_system_defaults"  [completed]="isStepCompleted('step_setup_system_defaults')">
      <wizard-step-configure-system-defaults (event-progress)="handleEventProgressEmitted($event)" *ngIf="currentStep === 'step_setup_system_defaults'" [driver]="driver"></wizard-step-configure-system-defaults>
    </mat-step>

    <!-- Step: [FINAL] Connection is Successful -->
    <mat-step label="{{ 'step_connect_success' | translate }}"  [editable]="false" aria-label="step_connect_success">
      <wizard-step-success-connection [driver]="driver" (event-progress)="handleEventProgressEmitted($event)" *ngIf="currentStep === 'step_connect_success'"></wizard-step-success-connection>
    </mat-step>
  </mat-horizontal-stepper>

  <div class="col-12 text-center" *ngIf="isCheckingState" style="padding: 10px">
    <span class="mr-1" style="font-size: 12px;">{{ 'checking_last_progress' | translate }}</span>
    <fa-icon [icon]="['fas','spinner']" [spin]="true" aria-hidden="true"size="xs"></fa-icon>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="! isCheckingState">
  <button mat-button id="next-btn" *ngIf="shouldDisplayNextButton" type="button" [disabled]="isInProgress" (click)="handleNextButtonClick()">{{ 'next' | translate }}</button>
  <button mat-button id="skip-btn" *ngIf="shouldDisplaySkipButton" type="button" [disabled]="isInProgress" (click)="handleSkipButtonClick()">{{ 'skip' | translate }}</button>
</mat-dialog-actions>
