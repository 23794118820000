import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-account',
  templateUrl: './layout-account.component.html',
  styleUrls: ['./layout-account.component.scss'],
})
export class LayoutAccountComponent {
  constructor() {}
}
