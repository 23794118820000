import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, switchMap, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../../../../services/notification.service';
import { RecordService } from '../../../../../../services/record.service';
import { Relate } from '../../../../../../objects/relate';
import { Checklist } from '../../../../../../objects/checklist';
import { get, isEmpty, isNull } from 'lodash';
import { ChecklistsService } from '../../../../../../services/checklist.service';

@Component({
  selector: 'calendar-link-checklist',
  templateUrl: './link-checklist.component.html',
})
export class LinkChecklistComponent implements OnInit {

  @Input() strJobId: string;
  @Input() strOpportunityId: string;
  @Input() strModule: string;
  @Output() parentEventEmitter = new EventEmitter<boolean>();

  bSubmit: boolean = false
  checklistModel: string = null;
  checklistPeriodModel: string[] = [];

  objChecklistRelate = new Relate<any>();
  objChecklist: Checklist = {};

  constructor(
    private notifService: NotificationService,
    private recordService: RecordService,
    private checklistService: ChecklistsService
  ) {
    this.initChecklist();
  }

  ngOnInit() {

  }

  /**
   * initialize checklist relate field
   */
  initChecklist(): void {
    this.objChecklistRelate.buildRelates(
      switchMap(term =>
        this.recordService.getRecordRelate(
          'checklists',
          term,
          '',
          false,
          {
            is_checklist_enabled : true,
            // FC-3476: If module is opportunities (quote), we set the filter here to only get quote checklists
            ... (this.strModule === 'opportunities' && {
              type: 'opportunity',
            }),
          }
        ).pipe(
          // FC-3476: If module is jobs, we need to filter the result here because we need to get both job and asset checklists
          map(result => {
            if (this.strModule === 'jobs') {
              return result.filter(objChecklist => objChecklist['type'] !== 'opportunity');
            }

            return result;
          })
        )
      ),
      null
    );
  }

  /**
   * when the selected checklist is asset, we need to parse the available_periods
   * so we can use it to select the user's desire period
   */
  onChangeChecklist(event: Checklist | null ): void {
    this.objChecklist = event || {};
    if (this.objChecklist.type === 'asset') {

      this.objChecklist.available_periods = get(this.objChecklist, 'available_periods', null);
      if (!isNull(this.objChecklist.available_periods)) {

        this.objChecklist.available_periods = JSON.parse(this.objChecklist.available_periods);
      } else {

        this.notifService.notifyWarning('no_inspection_period');
      }
    }
  }

  /**
   * trigger typehead manually
   */
  triggerSubject(objTypehead: Subject<string>, strValue: string = ''): void {
    objTypehead.next(strValue);
  }

  /**
   * check if user has valid data
   */
  isValid() {
    return !isEmpty(this.checklistModel)
      && ((this.objChecklist.type === 'asset' && !isEmpty(this.checklistPeriodModel)) || this.objChecklist.type === 'job' || this.objChecklist.type === 'opportunity');
  }

  /**
   * link the selected checklist
   */
  async onSave() {
    this.bSubmit = true
    if (this.isValid()) {
      try {
        let strRecordId: string = (this.strModule === 'jobs') ? this.strJobId : this.strOpportunityId;

        if (await this.checklistService.generateChecklistResponse(this.objChecklist, this.checklistPeriodModel, strRecordId, this.strModule)) {
          this.recordService.saveRecord('checklist_responses', this.checklistService.getChecklistResponseData())
          .pipe(
            finalize(() => this.bSubmit = false)
          )
          .subscribe( response  => {
            this.notifService.sendNotification('created', 'checklist_created_success', 'success');
            this.parentEventEmitter.emit(true);
          }, (error: HttpErrorResponse) => {
            if (error.status == 400 || error.status == 422) {
              this.notifService.notifyWarning('record_invalid_parameters');
            }
          });
        }
      } catch (e) {
        this.notifService.notifyWarning(this.checklistService.getChecklistResponseError());
        this.bSubmit = false;
      }
    } else {
      this.notifService.notifyWarning('invalid_requested_parameters');
      this.bSubmit = false;
    }
  }

  /**
   * cancel the link checklist form
   */
  onCancel(): void {
    this.parentEventEmitter.emit(false);
  }
}