<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="input-group" >
        <div class="input-group-prepend">
          <span class="input-group-text" style="background-color: transparent;">
            <fa-icon [icon]="['fas', 'search']" aria-hidden="true"></fa-icon>
          </span>
        </div>
        <input type="text" [formControl]="objSearchFolder" class="form-control search-folder-input" placeholder="{{ 'search_item_or_folder' | translate }}">
      </div>
    </div>
  </div>
</div>
<div class="p-3">

  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    cdkDropList
    [cdkDropListData]="dataSource.data"
    (cdkDropListDropped)="drop($event)">

    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      cdkDrag
      [matTreeNodePadding]="node['level']"
      [cdkDragData]="node"
      [cdkDragDisabled]="treeControl.isExpanded(node) || dataSource.arSpecialFolders.includes(node.id)"
      (cdkDragStarted)="drag(node)"
      [hidden]="node['metadata']['name'] == 'unallocated' && dataSource.bHideUnallocated">


      <ng-container *ngIf="node.id != 'next_page'">
        <span matTreeNodeToggle>
          <fa-icon *ngIf="treeControl.isExpanded(node)" [icon]="['fas', 'minus-square']" class="mr-2 text-primary"></fa-icon>
          <fa-icon *ngIf="!treeControl.isExpanded(node)" [icon]="['fas', 'plus-square']" class="mr-2 text-primary"></fa-icon>
        </span>

        <span
          [matTooltip]="treeControl.isExpanded(node) ? ('collapse_first' | translate) : null"
          matTooltipPosition="above"
          class="mr-2"
          matTreeNodeToggle
          [attr.aria-label]="'toggle ' + node.name">
          <a href="javascript:;">
            <span *ngIf="!dataSource.bHideUnallocated">{{ node['metadata']['name'] | translate }}</span>
            <span *ngIf="dataSource.bHideUnallocated">{{ node['metadata']['full_path'] | translate }}</span>
          </a>
        </span>
      </ng-container>

      <ng-container *ngIf="node.id == 'next_page'">
        <span>
          <fa-icon [icon]="['far', 'chevron-double-right']" class="mr-2 text-primary"></fa-icon>
        </span>
        <span
          matTooltip="{{ 'load_more_items' | translate }}"
          matTooltipPosition="above"
          class="mr-2"
          [attr.aria-label]="'toggle ' + node.name">
          <a href="javascript:;" (click)="dataSource.loadNodePages(node)">{{ node['metadata']['name'] | translate }}</a>
        </span>
      </ng-container>


      <ng-container *ngIf="!dataSource.arSpecialFolders.includes(node.id)">

        <span class="mr-1">
          <a [id]="'iconEditRecord' + node['metadata']['id']"
            class="text-success pointer icon-size"
            (click)="addProducts(node)">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
          </a>
        </span>

        <span class="mr-2">
          <list-actions
            (actionsEvent)="refreshList()"
            [rowData]="node['metadata']"
            [bAllowedDelete]="true"
            [bLogoEditOnly]="true"
            strModule="item_folders">
          </list-actions>
        </span>

      </ng-container>

      <ng-container *ngIf="node.is_loading">
        <fa-icon [icon]="['fas','spinner']" [spin]="true"></fa-icon>
      </ng-container>

    </mat-tree-node>

    <mat-tree-node
      *matTreeNodeDef="let node"
      cdkDrag
      [cdkDragData]="node"
      [matTreeNodePadding]="node['level']">

      <span class="pointer">
        <fa-icon [icon]="['fal', 'box-open']" class="mr-2"></fa-icon>
      </span>

      <span class="mr-2" style="cursor: move;">
        [{{ node['metadata']['code'] }}] {{ node['metadata']['name'] }}
      </span>

      <span class="mr-2">
        <list-actions
          (actionsEvent)="refreshItem(node)"
          [rowData]="node['metadata']"
          [bAllowedDelete]="true"
          [bLogoEditOnly]="true"
          [bShowView]="true"
          strModule="items">
        </list-actions>
      </span>

    </mat-tree-node>
  </mat-tree>

  <div *ngIf="!dataSource.bLoading && dataSource.data.length == 1 && dataSource.bHideUnallocated" class="text-center">
    <span class="no-results-text text-secondary">
      <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>{{ 'no_results_found' | translate }}
    </span>
  </div>

  <div *ngIf="dataSource.bLoading" class="text-center">
    <span>
      <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="fa-2x text-secondary"></fa-icon>
    </span>
  </div>
</div>

<div *ngIf="dataSource.bHasNextPage">
  <div class="text-center pb-3">
    <span class="span-link" (click)="dataSource.loadPages()">{{ 'load_more_items' | translate }}</span>
  </div>
</div>

