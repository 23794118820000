import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { RecordService } from '../../../services/record.service';
import { FormService } from '../../../services/form.service';
import { NotificationService } from '../../../services/notification.service';

import { StatusCode } from '../../../lists/status-code';
import { Notification } from '../../../objects/notification';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-forceupdate',
  templateUrl: './forceupdate.component.html',
  styleUrls: ['./forceupdate.component.scss'],
  providers: [ RecordService, FormService ]
})
export class ForceupdateComponent {

  public strModule: string;
  public bStatus: boolean = false;
  public updateForm: FormGroup;
  public bSubmitted: boolean = false;
  public objRecordFields = {
    users: ['first_name', 'last_name']
  };

  public objMetadata = {
    users: {
      last_name: {
        key: 'last_name',
        label: 'last_name',
        controlType: 'text',
        required: true,
        default_value: ''
      },
      first_name: {
        key: 'first_name',
        label: 'first_name',
        controlType: 'text',
        required: true,
        default_value: ''
      }
    }
  };

  constructor(
    public recordService: RecordService,
    public formService: FormService,
    private notifService: NotificationService,
    public dialogRef: MatDialogRef<ForceupdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected localStorageService: LocalStorageService
  ) {
    // Get metadata
    if (data['module'] && data['module'] == 'users') {

      // Get module
      this.strModule = data['module'];
      // Create form control
      let arFormControl: any = {};
      // Loop each field
      this.objRecordFields[this.strModule].forEach( item => {

        // Get metadata
        let objFieldConfig = this.objMetadata[this.strModule][item];
        let strFieldKey = objFieldConfig['key'];
        // Push to create form control
        arFormControl[strFieldKey] = new FormControl(objFieldConfig['default_value'], Validators.required);
      });
      // Do we have created form control?
      if (arFormControl) this.updateForm = new FormGroup(arFormControl);
    }
  }

  ngOnInit() {
  }

  onSubmit() {

    // Set submitted to true
    this.bSubmitted = true;
    // Get current user id
    let strUserId = this.localStorageService.getItem('user_id');
    // Do we have valid form?
    if (this.updateForm.valid && strUserId) {

      // Set up request data
      let arRequestData: any = {};
      // Get all values from form control
      Object.keys(this.updateForm.controls).forEach( item => {
        arRequestData[item] = this.updateForm.controls[item].value;
      });

      // Update record
      this.recordService.saveRecord(this.strModule, arRequestData, strUserId).subscribe( data => {

        // Store response body
        let arResponse = data.body;
        // Check if status is success 200 or created 201
        if (data.status == StatusCode.kResponseSuccess || StatusCode.kResponseCreated) {

          // Show notification save success
          this.notifService.sendNotification('updated', arResponse.toString(), 'success');

          // Do we have users module? Set first and last name
          if (this.strModule === 'users') this.localStorageService.setItem('user_name', arRequestData['first_name']+ ' ' +arRequestData['last_name']);

          // Redirect to listing.
          this.dialogRef.close();
        }
      });
    }
  }
}
