<header>
  <div class="d-flex header-container header-label">
    <span class="title">
      <fa-icon [icon]="['fas', 'edit']" class="mr-1"></fa-icon>{{ 'save_template_name' | translate }}
    </span>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button (click)="cancel()" class="btn btn-primary float-right mb-1">{{ 'cancel' | translate }}</button>
        </li>
        <li class="list-inline-item">
          <button (click)="saveTemplate()" class="btn btn-primary float-right mb-1">{{ 'save' | translate }}</button>
        </li>
      </ul>
    </div>
  </div>
  <hr class="separator"/>
</header>
<div class="wrapper">
  <label class="template-name-label">
    <fa-icon class="label-danger" [icon]="['fas', 'asterisk']" matTooltip="{{ 'tooltip_required' | translate }}"></fa-icon> {{ 'template_name' | translate }}:
  </label>
  <input class="form-control" id="inputTemplateName" [(ngModel)]="strTemplateName"/>
</div>