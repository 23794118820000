<header>
    <div class="d-flex dialog-header">
        <h3 class="dialog-title">
            <fa-icon [icon]="['fas', 'file-pdf']" transform="shrink-3" class="pr-2 "></fa-icon>{{ objConfig['file_name'] }}
        </h3>
        <div class="ml-auto d-flex">
            <ul class="list-inline pull-right">
                <li class="list-inline-item">
                    <button type="button" (click)="closeDialog()" class="btn ml-1 btn-primary">
                        <fa-icon [icon]="['fas', 'times']"></fa-icon><span class="pl-1">{{ 'close' | translate }}</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <hr />
</header>
<div class="body">
    <pdf-viewer
        id="preview"
        data-testid="preview"
        [src]="objConfig['path']"
        [render-text]="true"
        [external-link-target]="'blank'"
        [show-all]="true"
    ></pdf-viewer>
</div>