import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LooseObject } from '../../../../../../objects/loose-object';

@Component({
  selector: 'chat-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {

  /**
   * Basically holds any record. Can be a quote record,
   * a file, a job, etc.
   *
   * @var {LooseObject}
   */
  public objSelectedRecord: LooseObject = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public objAction: any,
    public selfDialog: MatDialogRef<ActionComponent>
  ) {}

  ngOnInit() {}

  /**
   * Sends the record back to the main component
   * for further processing.
   *
   * @returns {void}
   */
  doAction(): void {
    if (this.objSelectedRecord) {
      this.selfDialog.close({
        record: this.objSelectedRecord,
        action: this.objAction
      });
    } else {
      this.selfDialog.close();
    }
  }

}
