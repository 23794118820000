import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ViewService } from '../../../services/view.service';
import { RecordService } from '../../../services/record.service';
import { ListingService } from '../../../services/listing.service';
import { JobsComponent } from '../../opportunities/jobs/jobs.component';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'jobs-from-recurring-jobs',
  templateUrl: './jobs-from-recurring-jobs.component.html',
  styleUrls: ['./jobs-from-recurring-jobs.component.scss']
})
export class JobsFromRecurringJobsComponent extends JobsComponent implements OnInit {

  // For checking current recordId
  strCurrentId: string;

  // Widget Jobs Status Filter Items
  arWidgetJobsStatusFilterItems = [
    {id: 'awaiting_scheduling', name: 'awaiting_scheduling'},
    {id: 'scheduled', name: 'scheduled'},
    {id: 'pending', name: 'pending'},
    {id: 'awaiting_invoicing', name: 'awaiting_invoicing'},
    {id: 'completed', name: 'completed'},
    {id: 'cancelled', name: 'cancelled'}
  ];

  constructor(
    listService: ListingService,
    viewService: ViewService,
    dialog: MatDialog,
    notifService: NotificationService,
    recordService: RecordService,
    protected route: ActivatedRoute,
  ) {
    super(listService, viewService, dialog, notifService, recordService);
  }

  ngOnInit() {
    this
    .route
    .params
    .pipe(
      tap(({id}) => {
        this.strRecordId = id;
      }),
    )
    .subscribe();

    super.ngOnInit();
  }

  /**
   * @inheritDoc
   */
  fetchList(strPage, objFilter: {} = {}) {
    const objPagination = this.listService.beforeFetching(strPage);

    objFilter['recurring_job_id'] = this.strRecordId;

    this.bLoading = true;

    this
      .listService
      .fetchDataAdvanceSearch(
        objPagination['objPage'],
        'jobs',
        objFilter,
        { 'id': 'created_at', 'sort': 'desc' },
      )
      .pipe(
        tap(objResponse => this.arJobs = objResponse['data']),
        tap(objResponse => this.listService.afterFetching(objResponse, strPage)),
        tap(() => this.bLoading = false),
      )
      .subscribe();
  }

  //Check if strRecordId changes
  ngDoCheck(): void {
    if(this.strCurrentId !== this.strRecordId){
      this.ngOnChanges();
      this.strCurrentId = this.strRecordId;
    }
  }

  ngOnChanges(): void {
    this.onRefresh();
  }

}
