<div class="d-flex h-100 flex-column">
  <h1 class="title">
    <fa-icon [icon]="['fal', 'map-signs']" class="mr-1"></fa-icon>{{ 'save_as' | translate }}
  </h1>
  <hr class="w-100 mt-0">
  <mat-dialog-content class="mt-3">
    <form class="form-inline flex-nowrap w-100 d-flex align-items-start justify-content-center">
      <div class="form-group">
        <div class="col-lg-6">
          <label class="jc-l mt-2"><strong>{{ 'field_mapping_name' | translate }}:</strong></label>
        </div>
        <div class="col-lg-6">
          <input type="text" [(ngModel)]="fieldMappingName" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-end mt-5">
    <button type="button" class="btn btn-danger btn-lg m-1" (click)="cancel()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
      <span class="pl-1">{{ 'cancel' | translate }}</span>
    </button>
    <button type="button" class="btn btn-primary btn-lg" (click)="save()">
      <fa-icon [icon]="['fas', 'save']"></fa-icon>
      <span class="pl-1">{{ 'save' | translate }}</span>
    </button>
  </mat-dialog-actions>
</div>
