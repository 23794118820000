import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'inline-view-loader',
  templateUrl: './inline-view-loader.component.html',
  styleUrls: ['./inline-view-loader.component.scss']
})
export class InlineViewLoaderComponent implements OnInit {

  /**
   * Show the loader to the user if the consumer of this component
   * is still loading.
   *
   * @type {Boolean}
   */
  @Input('isLoading') isLoading: boolean = false;

  /**
   * Show the loading text to the user if the consumer of this component
   * is still loading.
   *
   * @type {Boolean}
   */
  @Input('hasText') hasText: boolean = true;

  /**
   * Font Awesome icon defined as an array. Value should be similar
   * to how we use the [icon] attribute here in FM Cloud.\
   *
   * @type {Array}
   */
  @Input('loaderIcon') loaderIcon: string[] = ['fas', 'spinner'];

  /**
   * Customizable classes that will be added to the loader
   *
   * @type {string | string[] | Set<string> | {[className: string]: any}}
   */
  @Input('className') className: string | string[] | Set<string> | {[className: string]: any} = [];

  constructor() { }

  ngOnInit() {
  }

}
