import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';
import { CustomEditFieldComponent, Form, FormMode } from '../../../base/form';
import { Select } from '../../../objects/select';
import { Relate } from '../../../objects/relate';
import { map, switchMap } from 'rxjs/operators';
import { RecordService } from '../../../services/record.service';
import { ItemReorder } from '../../../objects/stock-management/item-reorder';
import { LooseObject } from '../../../objects/loose-object';

@Component({
  selector: 'fc-edit-reorder-config',
  template: `
  <div [formGroup]="parentForm" class="row">
    <div class="col-12 mb-2 text-right">
      <button
        id="reorder_config"
        type="button"
        class="btn btn-link"
        (click)="addItem()">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
        <span class="pl-1">{{ 'add_item' | translate }}</span>
      </button>
    </div>
    <div class="col-12">
      <table
        [formArrayName]="field.key"
        class="table table-bordered">
        <thead>
          <tr>
            <th colspan="4" class="text-center fmc-input-label">{{ 'reorder_levels_table' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-template #emptyItemCodesMessage>
            <tr>
              <td class="text-center text-muted" colspan="4">{{ 'no_reorder_levels' | translate }}</td>
            </tr>
          </ng-template>
          <ng-container *ngIf="items.controls.length > 0; else emptyItemCodesMessage">
            <tr *ngFor="let control of items.controls; let i = index;" [formGroupName]="i">
              <td class="form-group w-50">
                <label class="font-weight-bold" for="code">
                  <required-tag>{{ 'warehouse' | translate }}</required-tag>
                </label>
                <ng-select
                  formControlName="warehouse_id"
                  placeholder="{{ 'select_warehouse' | translate }}"
                  matTooltip="{{ warehouseRelate[i].value?.text }}"
                  [items]="warehouseRelate[i].source | async"
                  [typeahead]="warehouseRelate[i].typehead"
                  [loading]="warehouseRelate[i].loader"
                  (change)="onChangeWarehouse(i, $event)"
                  (open)="warehouseRelate[i].loadDataOnClick()"
                  [clearable]="false"
                  bindLabel="text"
                  bindValue="id"
                  appendTo="body"
                  [ngClass]="{
                    'is-invalid': control.invalid && (control.touched || control.parent.touched)
                  }"
                >
                </ng-select>
              </td>
              <td class="form-group w-20">
                <label class="font-weight-bold" for="supplier">{{ 'reorder_quantity' | translate }}</label>
                <input
                  formControlName="min_reorder_level"
                  id="additional_code_min_reorder_level_{{ i }}"
                  type="number"
                  min=0
                  step="0.1"
                  class="form-control font-size-11"
                  (keypress)="negateNegative($event)"
                />
              </td>
              <td class="form-group w-20">
                <label class="font-weight-bold" for="unit_cost">{{ 'min_stock_level' | translate }}</label>
                <input
                  formControlName="min_stock_level"
                  id="additional_code_min_stock_level_{{ i }}"
                  type="number"
                  min=0
                  step="0.1"
                  class="form-control font-size-11"
                  (keypress)="negateNegative($event)"
                />
              </td>
              <td class="w-10 text-center">
                <button
                  id="add_additional_code_{{ i }}"
                  type="button"
                  class="btn btn-link text-success"
                  (click)="addItem()"
                  matTooltip="{{ 'add_item' | translate }}">
                  <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
                </button>
                <button
                  id="remove_additional_code_{{ i }}"
                  type="button"
                  class="btn btn-link text-danger"
                  (click)="removeItem(i)"
                  matTooltip="{{ 'remove' | translate }}">
                  <fa-icon [icon]="['fal', 'minus-circle']"></fa-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  `
})

export class EditReorderConfigComponent implements CustomEditFieldComponent, OnInit {
  /**
   * The parent form group where the field belongs to
   */
  parentForm: FormGroup;

  /**
   * The current field which represents by this custom field component
   */
  field: Form<ReorderConfig[]>;

  warehouseRelate: Relate<any>[] = [];

  arWarehouseList: string[] = [];

  /**
   * Contains the item codes
   */
  get items(): FormArray {
    return this.parentForm.get(this.field.key) as FormArray;
  }

  constructor(
    private recordService: RecordService
  ) {}

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.field.default_value.forEach((objItem: any) => {
      this.addItem(objItem);
    });
  }

  /**
   * @inheritdoc
   */
  setFormMode(formMode: FormMode): void {
    // no need at the moment
  }

  /**
   * @inheritdoc
   */
  setParentForm(parentForm: FormGroup): void {
    this.parentForm = parentForm;
  }

  /**
   * @inheritdoc
   */
  setField(field: Form<any>): void {
    this.field = field;
  }

  /**
   * Add a new item to the list
   */
  addItem(objItem: ItemReorder = null): void {
    let objFormGroup = new FormGroup({
      warehouse_id: new FormControl(null, [Validators.required]),
      min_reorder_level: new FormControl(0),
      min_stock_level: new FormControl(0),
    });
    if (!isEmpty(objItem)) {
      objFormGroup.patchValue({
        warehouse_id: objItem.warehouse_id,
        min_reorder_level: objItem.min_reorder_level,
        min_stock_level: objItem.min_stock_level,
      })
    }

    this.items.push(objFormGroup);

    this.warehouseRelate.push(
      this.setUpWarehouseRelate(objItem)
    )
  }

  /**
   * Remove a certain item
   */
  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  /**
   * reinitialize relate field
   *
   * @param intIndex
   * @param event
   */
  onChangeWarehouse(intIndex: number, event): void {
    // Reinitialize all fields to fix issue where selected warehouse still appears in other line item's options
    for (let i = 0; i < this.warehouseRelate.length; i++) {
      this.warehouseRelate[i].destroyTypehead();
      this.warehouseRelate[i] = (i !== intIndex) ? this.setUpWarehouseRelate() : this.setUpWarehouseRelate(event);
    }
  }

  /**
   * set up relate field
   *
   * @param objData
   * @returns
   */
  setUpWarehouseRelate(objData: LooseObject = null): Relate<any> {
    let objDefaultValue = [];
    if (objData) {
      objDefaultValue = [ new Select(
        objData.warehouse_id || objData.id,
        objData.warehouse_text || objData.name
      ) ]
    }
    return new Relate<any>().buildRelates(
      switchMap(strTerm => this.recordService.getRecordRelate('warehouses', strTerm, false)
        .pipe(
          map(response => this.filterWarehouseRelate(response))
        )
      ),
      objDefaultValue,
      !isEmpty(objDefaultValue)
    )
  }

  /**
   * filter warehouse relate, should not include selected warehouses
   *
   * @param arWarehouse
   *
   * @returns
   */
  filterWarehouseRelate(arWarehouse): LooseObject[] {
    let arFormSelectedIds = this.items.controls.map( objForm => {
      return objForm.value.warehouse_id;
    }).filter( strWarehouseId => strWarehouseId);

    return arWarehouse.filter(
      objWarehouse => !arFormSelectedIds.includes(objWarehouse.id)
    );
  }

  /**
   * disable negative values when inputing on number field
   *
   * @param event
   */
  negateNegative(event: KeyboardEvent) {
    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }
}

export type ReorderConfig = {
  warehouse_id: Relate<any>;
  min_reorder_level?: number;
  min_stock_level?: number;
}