import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { filter, isEmpty, isNil, toLower, toString } from 'lodash';
import { Form, FormMode } from '../../../base/form';
import { Select } from '../../../objects/select';
import { LooseObject } from '../../../objects/loose-object';
import { Relate } from '../../../objects/relate';
import { RecordService } from '../../../services/record.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'fc-editform-item-codes-field',
  template: `
    <div [formGroup]="parentForm" class="row">
      <div class="col-12 mb-2 text-right">
        <button
          id="add_additional_code"
          type="button"
          class="btn btn-link"
          (click)="addItem()">
          <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
          <span class="pl-1">{{ 'add_item' | translate }}</span>
        </button>
      </div>
      <div class="col-12">
        <table
          [formArrayName]="field.key"
          class="table table-bordered">
          <thead>
            <tr>
              <th colspan="5" class="text-center fmc-input-label">{{ 'supplier_pricing_table' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-template #emptyItemCodesMessage>
              <tr>
                <td class="text-center text-muted" colspan="4">{{ 'no_item_codes_defined' | translate }}</td>
              </tr>
            </ng-template>
            <ng-container *ngIf="items.controls.length > 0; else emptyItemCodesMessage">
              <tr *ngFor="let control of items.controls; let i = index;" [formGroupName]="i">
                <td class="form-group" style="width: 20%;">
                  <label class="font-weight-bold" for="code">
                    <required-tag>{{ 'supplier_code' | translate }}</required-tag>
                  </label>
                  <input
                    id="additional_code_{{ i }}"
                    class="form-control font-size-11"
                    type="text"
                    formControlName="code"
                    maxLength="128"
                    [ngClass]="{
                      'is-invalid': control.get('code').errors && control.get('customer_id').touched
                    }"
                    required
                    #ng
                  />
                  <small class="text-danger" *ngIf="control.get('code').errors && control.get('code').errors.unique">
                    {{ 'unique_supplier_code_error' | translate }}
                  </small>
                </td>
                <td class="form-group" style="width: 20%;">
                  <label class="font-weight-bold" for="supplier">
                    <required-tag>{{ 'supplier_name' | translate }}</required-tag>
                  </label>
                  <ng-select
                    id="additional_code_supplier_{{ i }}"
                    formControlName="customer_id"
                    placeholder="{{ 'supplier_name' | translate }}"
                    matTooltip="{{ customerRelateField[control.value.id].value?.text }}"
                    bindLabel="text"
                    bindValue="id"
                    appendTo="body"
                    [items]="customerRelateField[control.value.id].source | async"
                    [typeahead]="customerRelateField[control.value.id].typehead"
                    [loading]="customerRelateField[control.value.id].loader"
                    [clearable]="false"
                    [ngClass]="{
                      'is-invalid': control.get('customer_id').errors && control.get('customer_id').touched
                    }">
                  </ng-select>
                </td>
                <td class="form-group" style="width: 15%;">
                  <label class="font-weight-bold" for="unit_cost">{{ 'default_unit_cost' | translate }}</label>
                  <fc-currency-input
                    [places]="4"
                    id="additional_code_unit_cost_{{ i }}"
                    formControlName="unit_cost"
                    nonNegative="true">
                  </fc-currency-input>
                </td>
                <td class="form-group w-15" style="width: 15%;">
                  <label class="font-weight-bold" for="lead_time">{{ 'lead_time' | translate }} ({{ 'days' | translate }})</label>
                  <input
                    formControlName="lead_time"
                    id="additional_code_lead_time_{{ i }}"
                    type="number"
                    class="form-control font-size-11"
                    (keypress)="negateNegative($event)">
                </td>
                <td class="form-group" style="width: 30%;">
                  <label class="font-weight-bold" for="lead_time">{{ 'notes' | translate }}</label>
                  <textarea
                    formControlName="notes"
                    id="additional_code_notes_{{ i }}"
                    type="number"
                    (focus)="increaseRow(i)"
                    (focusout)="decreaseRow(i)"
                    class="form-control font-size-11 additional_code_notes_{{ i }}">
                  </textarea>
                </td>
                <td class="w-5 text-center">
                  <button
                    id="add_additional_code_{{ i }}"
                    type="button"
                    class="btn btn-link text-success"
                    (click)="addItem()"
                    matTooltip="{{ 'add_item' | translate }}">
                    <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
                  </button>
                  <button
                    id="remove_additional_code_{{ i }}"
                    type="button"
                    class="btn btn-link text-danger"
                    (click)="removeItem(i)"
                    matTooltip="{{ 'remove' | translate }}">
                    <fa-icon [icon]="['fal', 'minus-circle']"></fa-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  `
})
export class EditItemCodesComponent implements OnInit {
  /**
   * The parent form group where the field belongs to
   */
  parentForm: FormGroup;

  /**
   * The current field which represents by this custom field component
   */
  field: Form<ItemCode[]>;

  /**
   * customer relate field
   */
  customerRelateField: LooseObject = {};

  /**
   * Contains the item codes
   */
  get items(): FormArray {
    return this.parentForm.get(this.field.key) as FormArray;
  }

  constructor(
    private recordService: RecordService,
  ) { }

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    filter(this.field.default_value, (row) => {
      return !row.is_primary;
    }).forEach((row) => {
      this.addItem(row);
    });
  }

  /**
   * @inheritdoc
   */
  setFormMode(formMode: FormMode): void {
    // no need at the moment
  }

  /**
   * @inheritdoc
   */
  setParentForm(parentForm: FormGroup): void {
    this.parentForm = parentForm;
  }

  /**
   * @inheritdoc
   */
  setField(field: Form<any>): void {
    this.field = field;
  }

  /**
   * Add a new item to the list
   */
  addItem(item: ItemCode = null): void {
    let recordId = item ? item.id : this.generateRandomId();
    let formGroup = new FormGroup({
      id: new FormControl(recordId),
      code: new FormControl(null, [
        Validators.required,
        Validators.maxLength(128),
        (control: AbstractControl): ValidationErrors | null => {
          const code: string | null = !isNil(control.value) ? toString(control.value) : null;

          if (isNil(code)) {
            return null;
          }

          for (const sibling of this.items.controls.filter((sibling) => sibling != control.parent)) {
            const siblingValue: { code: string } = sibling.value;

            if (toLower(siblingValue.code) == toLower(code)) {
              return {
                unique: true,
              };
            }
          }

          return null;
        }
      ]),
      customer_id: new FormControl(null, [Validators.required]),
      unit_cost: new FormControl(0),
      lead_time: new FormControl(0),
      notes: new FormControl(null),
    });

    if (item) {
      formGroup.patchValue(item)
    }

    this.items.push(formGroup);
    this.customerRelateField[recordId] = this.setupCustomerRelate(item);
  }

  /**
   * Remove a certain item
   */
  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  /**
   * set up relate field
   *
   * @param objData
   * @returns
   */
  setupCustomerRelate(item: LooseObject = null): Relate<any> {
    let objDefaultValue = [];
    if (item) {
      objDefaultValue = [new Select(item.customer_id, item.customer_text)];
    }
    return new Relate<any>().buildRelates(
      switchMap(strTerm => this.recordService.getRecordRelate('customers', strTerm, false, false, { is_supplier: true })),
      objDefaultValue,
      !isEmpty(objDefaultValue)
    )
  }

  /**
   * disable negative values when inputing on number field
   *
   * @param event
   */
  negateNegative(event: KeyboardEvent) {
    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }

  /**
   * Generate a 7 character random id for the custom field mapping
   *
   * @returns { string }
   */
  protected generateRandomId(): string {
    var text = '';
    var availableCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 6; i++) {
      text += availableCharacters.charAt(Math.floor(Math.random() * availableCharacters.length));
    }
    return text;
  }

  /**
   * This is to increase the row
   * when textarea is focus
   */
  increaseRow(i) {
    let currentElem = document.querySelector('.additional_code_notes_' + i);
    currentElem.setAttribute("style", "height: 100px");
  }

  /**
   * This is to decrease the row
   * when textarea is focus out
   */
  decreaseRow(i) {
    let currentElem = document.querySelector('.additional_code_notes_' + i);
    currentElem.setAttribute("style", "height: 40px");
  }
}

export type ItemCode = {
  /**
   * The code associated to the item
   */
  code: string;
  /**
   * Indicates the current item code is a primary item code. This is initially from the
   * the code field that already exists in the items record
   */
  is_primary: boolean;
  /**
   * The supplier details associated with the current code
   */
  supplier?: Pick<Select, 'id' | 'text'>;
  /**
   * The unit cost associated with the item code
   */
  unit_cost?: number;
  /**
   * The lead time associated with current supplier pricing
   */
  lead_time?: number;
  /**
   * record id
   */
  id?: string;
  /**
   * customer id
   */
  customer_id?: Relate<any>;
}
