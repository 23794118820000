import { Component, Inject, OnInit } from '@angular/core';
import { ImageViewComponent } from '../../../image-view/image-view.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { RecordService } from '../../../../../services/record.service';
import { FileService } from '../../../../../services/file/file.service';
import { NotificationService } from '../../../../../services/notification.service';
import { throwError } from 'rxjs';
import { catchError, concatMap, finalize } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { cloneDeep } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-asset-job-photos',
  templateUrl: './asset-job-photos.component.html',
  styleUrls: ['./asset-job-photos.component.scss']
})

export class AssetJobPhotosComponent implements OnInit {

  public isFileDropped: boolean = false;
  public isFileUploaded: boolean = false;
  public photos: Array<AssetPhotoInterface> = [];
  public photoUploaded: AssetPhotoInterface;

  constructor(
    public dialogRef: MatDialogRef<AssetJobPhotosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifService: NotificationService,
    private fileService: FileService,
    private recordService: RecordService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.photos = this.data['photos'] ? this.data['photos'] : [];
  }

  onFileChange(data) {
    let reader = new FileReader();

    if (data.target.files && data.target.files.length) {
      this.isFileDropped = true;
      let [file] = data.target.files;

      reader.readAsDataURL(file);

      if (file['type'].split('/')[0] === 'image') {
        if (file.size / 1024 / 1024 < 30) {
          reader.onload = () => {

            this.fileService.upload(file, environment.client_bucket, null, {
              hasErrorHandler: true,
            }).pipe(
              catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status == 422) {
                  this.notifService.notifyError('invalid_file_type');
                }

                return throwError(err);
              }),
              concatMap(tempfile => {
                return this.fileService.getObjectSignedUrl(tempfile.filename, environment.client_bucket);
              }),
              finalize(() => {
                this.isFileUploaded = true;
                this.isFileDropped = false;
              }),
            ).subscribe(response => {
                let file = this.fileService.objFile;
                this.photoUploaded = {
                  name: file.name,
                  size: file.size / 1024,
                  type: file.type,
                  upload_name: response["filename"],
                  url: response['url']
                };

                this.photos.push(this.photoUploaded);
              });
          };
        } else {
          this.notifService.notifyWarning('invalid_file_size');
          this.isFileDropped = false;
        }
      } else {
        this.notifService.notifyWarning('invalid_file_type');
        this.isFileDropped = false;
      }
    }
  }

  viewImage(imageData) {
    let bucket = imageData['url'].includes('fieldmagic-tmp') ? environment.temp_bucket : environment.client_bucket;

    // If upload name and type were not passed (This is from signature that has different format)
    if (imageData['upload_name'] == undefined) {
      imageData['upload_name'] = imageData['signature'];
    }
    if (imageData['type'] == undefined) {
      imageData['type'] = "image/png";
    }

    this.fileService.getObjectSignedUrl(imageData['upload_name'], bucket).subscribe(object => {
      let data = {
        width: '1200px',
        height: 'auto',
        data: {
          image_url: object['url']
        }
      };
      this.dialog.open(ImageViewComponent, data);
    });
  }

  /**
  * Remove an image from one of the prompts
  *
  * @param imageValues - Current list of images uploaded
  * @param imageIndex - Index of the image to be deleted in Current list
  *
  */
  removeAttachement(imageValues, imageIndex) {
    imageValues.splice(imageIndex, 1);
  }

  dialogClose() {
    this.dialogRef.close('cancel');
  }

  onSubmit() {
    // We need to do this to avoid deleting the
    // url key in the this.photos variable
    let clonePhoto = cloneDeep(this.photos);

    let filterPhotos = clonePhoto.filter(function (photo) {
      delete photo['url'];

      return photo;
    });

    let payload = {
      photos: filterPhotos
    };

    this.recordService.saveRecord('assets_jobs', payload, this.data['id']).subscribe(response => {
      this.notifService.notifySuccess('successfully_save');
      this.dialogRef.close('saved');
    });
  }
}

interface AssetPhotoInterface {
  name: string,
  size: number,
  type: string,
  upload_name: string,
  url: string,
}
