import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../../../../services/notification.service';
import { SharingService } from '../../../services/sharing.service';

@Component({
  selector: 'ds-share-btn',
  templateUrl: './share.button.component.html'
})
export class ShareButtonComponent {
  /**
   * Name of the module to be shared
   *
   * @type {string}
   */
  @Input('module-name') moduleName: string;

  /**
   * Module unique id to be shared
   *
   * @type {string}
   */
  @Input('module-id') moduleId: string;

  /**
   * An output event that is triggered after a successful action
   *
   * @type {EventEmitter}
   */
  @Output('on-shared') onSharedEvent = new EventEmitter<string>();

  /**
   * A subject flag that indicates that the action is in progress
   */
  readonly isInProgress$ = new BehaviorSubject(false);

  /**
   * Create instance of the component
   *
   * @param {NotificationService} notifications
   */
  constructor(
    protected readonly notifications: NotificationService,
    protected readonly sharing: SharingService,
  ) {}

  /**
   * Handles the share request
   */
  doShare(): void {
    this.notifications.sendConfirmation('confirm_share_message').pipe(
      filter((answer) => answer.answer),
      tap(() => this.isInProgress$.next(true)),
      finalize(() => this.isInProgress$.next(false)),
      switchMap(() => this.sharing.shareRecord([this.moduleId], this.moduleName)),
      tap(() => this.onSharedEvent.emit(this.moduleId)),
    ).subscribe(() => {
      this.notifications.notifySuccess('record_successfully_shared_message');
    });
  }
}