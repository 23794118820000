import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { isEmpty, isNil } from 'lodash';
import moment, { Moment } from 'moment';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { EnumeratedTypeFields } from '../../../../../../lists/field-type';
import { Field } from '../../../../../../objects/field';
import { FileUploadInterface } from '../../../../../../objects/file-upload';
import { LooseObject } from '../../../../../../objects/loose-object';
import { FileService } from '../../../../../../services/file/file.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { ImageViewComponent } from '../../../../image-view/image-view.component';


@Component({
  selector: 'app-checklist-response-prompt',
  templateUrl: './checklist-response-prompt.component.html',
  styleUrls: ['../checklist-response.component.scss']
})
export class ChecklistResponsePromptComponent implements OnInit {
  /**
   * Contains the prompt data.
   *
   * @type {LooseObject} objPrompt
   */
  @Input() objPrompt: LooseObject;

  /**
   * Index of row this instance of the checklist prompt is in.
   *
   * @type {number} numIndex
   */
  @Input() numIndex: number;

  /**
   * Counts the number of text and text area fields which exeed the maximum character limit.
   *
   * @type {string[]} arNumberOfErroneousText
   */
  @Input() arNumberOfErroneousText: string[];

  /**
   * Contains the metadata of the fields used in the checklist (jobs/assets/opportunities/sites).
   *
   * @type {LooseObject} objMetadata
   */
  @Input() objMetadata: LooseObject;

  /**
   * Flag that records if the prompt is under a prompt group or not.
   *
   * @type {boolean} bIsUnderGroup
   */
  @Input() bIsUnderGroup: boolean = false;

  /**
   * Emits a flag that checks if the form is dirty.
   *
   * @type {EventEmitter<boolean>} formDirtyEmitter
   */
  @Output() formDirtyEmitter: EventEmitter<boolean> = new EventEmitter();

  /**
   * For pass/fail type prompt only, emits whenever the value is changed.
   *
   * @type {EventEmitter<void>} passFailPromptEmitter
   */
  @Output() passFailPromptEmitter: EventEmitter<void> = new EventEmitter();

  /**
   * Flag that checks if files to be uploaded have been dropped (using drag and drop).
   *
   * @type {boolean} bIsFileDropped
   */
  public bIsFileDropped: boolean = false;

  /**
   * Maximum text length.
   *
   * @type {number} numDefaultTextMaxLength
   */
  public numDefaultTextMaxLength : number = 2056;

  /**
   * Contains values for dropdowns.
   *
   * @type {EnumeratedTypeFields} objEnumFields
   */
  public objEnumFields = new EnumeratedTypeFields;

  /**
   * Flag that checks if user is currently focusing on a textarea.
   *
   * @type {boolean} bFocusingOnTextarea
   */
  public bFocusingOnTextarea: boolean = false;


  constructor(
    private notifService: NotificationService,
    private fileService: FileService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void { }

  /**
   * When uploading image is triggered.
   *
   * @param {any} objData
   *
   * @returns {void}
   */
  onFileChange(objData: any): void {
    let reader = new FileReader();

    // If file is valid
    if (objData.target.files && objData.target.files.length) {
      this.bIsFileDropped = true;
      let [file] = objData.target.files;

      reader.readAsDataURL(file);

      // Is file type image?
      if (file['type'].split('/')[0] === 'image') {
          // if file size is less than 30mb
          if (file.size/1024/1024 < 30) {
            reader.onload = () => {
              this.fileService.upload(file).pipe(
                concatMap(tempfile => {
                  if (tempfile.url === null || tempfile.filename === null) {
                    return throwError('An error occured while uploading the file. Please try again');
                  }

                  return this.fileService.getObjectSignedUrl(tempfile.filename);
                })
              )
              .subscribe(response => {
                this.bIsFileDropped = false;
                let objFile = this.fileService.objFile;
                this.objPrompt['value']['image'].push({
                    'name': objFile.name,
                    'size': objFile.size / 1024,
                    'type': objFile.type,
                    'upload_name': response['filename'],
                    'image_path': response['url']
                });
              });
            };
          } else {
            // FILE SIZE IS TOO LARGE (must be less than 30mb)
            this.notifService.notifyWarning('invalid_file_size');
            this.bIsFileDropped = false;
          }
      } else {
        // FILE TYPE IS NOT AN IMAGE
        this.notifService.notifyWarning('invalid_file_type');
        this.bIsFileDropped = false;
      }
    }
  }

  /**
   * Call a dialog to display a larger image of the thumbnail
   *
   * @param {UploadedImage} objImageData
   *
   * @returns {void}
   */
  viewImage(objImageData: UploadedImage): void {
    // Check if image is not yet in the current client bucket
    let strBucket = objImageData.image_path.includes('fieldmagic-tmp') ? environment.temp_bucket : environment.client_bucket;

    // If upload name and type were not passed (This is from signature that has different format)
    if (isNil(objImageData.upload_name)) {
      objImageData.upload_name = objImageData.signature;
    }
    if (isNil(objImageData.type)) {
      objImageData.type = "image/png";
    }

    this.fileService.getObjectSignedUrl(objImageData.upload_name, strBucket).subscribe(object => {
      let objChecklist: object = {
        width: '1200px',
        height: 'auto',
        data: {
            image_url : object['url']
        }
      };
      this.dialog.open(ImageViewComponent, objChecklist);
    });
  }

  /**
   * Counts the number of text and text area fields which exeed the maximum character limit
   *
   * @param indexPromptTypes
   * @param indexPrompts
   */
  onChangeTexts(numMaxLength: number = this.numDefaultTextMaxLength): void  {
      this.formDirtyEmitter.emit(true);

      let arFieldEntryValue = this.objPrompt.value['field_entry_value'] || [];
      let strFieldEntryId: string = this.objPrompt.id;
      let numErroneousTextIndex: number = this.arNumberOfErroneousText.findIndex(id => id === strFieldEntryId);

      if (arFieldEntryValue.length > numMaxLength) {
          this.arNumberOfErroneousText.push(strFieldEntryId);
      } else if(numErroneousTextIndex !== -1) {
          this.arNumberOfErroneousText.splice(numErroneousTextIndex, 1);
      }
  }

  /**
   * Count the length of a given string
   *
   * @param {string} strValue
   *
   * @returns {number}
   */
  countFieldValue(strValue: string): number {
    if (!isEmpty(strValue)) {
        return strValue.length;
    }

    return 0;
  }

  /**
   * Gets the date and formats it to be in a year-month-date form
   * This is specifically for prompt type date
   *
   * @param {DateTimeChange} event
   *
   * @returns {void}
   */
  dateChange(event: DateTimeChange): void {
    let dateInputData = '';
    if (!isEmpty(event.value)) {
        dateInputData = moment(event.value).format('YYYY-MM-DD HH:mm:ss');
    }

    this.objPrompt['value']['date'] = dateInputData;
    // FC-1278: Fix converting dates to utc, add .format() to get the date without converting utc()
    this.objPrompt['value']['field_entry_value'] = dateInputData;
    this.formDirtyEmitter.emit(true);
  }

  /**
   * Get the index of the current selected option and negate it's current value
   * (eg. Add checked status if not yet selected)
   *
   * @param {string} strSelectedId
   * @param {boolean} bIsChecked
   * @param {LooseObject[]} options
   *
   * @returns {void}
   */
  changeSelection(strSelectedId: string, bIsChecked: boolean, options: LooseObject[]): void {
    let numSelectedIndex: number = options.findIndex( option => strSelectedId === option['id']);
    options[numSelectedIndex]['checked'] = !bIsChecked;
    this.objPrompt['value']['options'] = options;
  }

  /**
   * Get the set max length of field
   *
   * @param {MaxLengthField} objField
   *
   * @returns {number}
   */
  getFieldMaxLength(objField: MaxLengthField): number {
    if (!isNil(objField.max_length)) {
      return objField.max_length;
    }

    return this.numDefaultTextMaxLength;
  }

  /**
   * If pass or fail value is changed
   * Specifically for changing class when passed (Green) and failed (red)
   *
   * @param {string} event
   * @param {string[]} arPassValues
   *
   * @returns {void}
   */
  onPassFailChange(strValue: string, arPassValues: string[]): void {
    this.objPrompt['value']['is_passed'] = arPassValues.includes(strValue) ? true : false;

    // If pass / fail prompt's value was erased
    if (isNil(strValue)) {
        this.objPrompt['value']['is_passed'] = 'default';
    }

    this.formDirtyEmitter.emit(true);
    this.passFailPromptEmitter.emit();
  }

  /**
   * Adjusts the height of the textarea whenever it is selected or out of focus.
   *
   * @param {FocusEvent} event
   * @param {number} numRows
   *
   * @returns {void}
   */
  setTextareaHeight(event: FocusEvent, numRows: number): void {
    event.target['rows'] = numRows;
  }

  /**
   * Increases the height of the textarea whenever it is selected.
   * Also sets the focusing on textarea flag to true
   *
   * @param {FocusEvent} event
   * @param {number} numRows
   *
   * @returns {void}
   */
  enlargeTextareaHeight(event: FocusEvent, numRows: number): void {
    this.bFocusingOnTextarea = true;
    this.setTextareaHeight(event, numRows);
  }

  /**
   * Decreases the height of the textarea whenever it is out of focus.
   * Also sets the focusing on textarea flag to false
   *
   * @param {FocusEvent} event
   * @param {number} numRows
   *
   * @returns {void}
   */
  decreaseTextareaHeight(event: FocusEvent, numRows: number): void {
    this.bFocusingOnTextarea = false;
    this.setTextareaHeight(event, numRows);
  }

  /**
   * Remove an image from one of the prompts
   *
   * @param {UploadedImage[]} arImageValues - Current list of images uploaded
   * @param {number} numImageIndex - Index of the image to be deleted in Current list
   *
   * @returns {void}
   *
   */
  removeAttachement(arImageValues: UploadedImage[], numImageIndex: number): void {
    arImageValues.splice(numImageIndex, 1);
  }
}

interface UploadedImage extends FileUploadInterface {
  image_path: string;
  signature?: string;
}

interface MaxLengthField extends Field {
  max_length: number;
}

interface DateTimeChange {
  input: HTMLInputElement;
  source: OwlDateTimeInputDirective<any>;
  value: Moment;
}