<ng-container *fcHasPermission="'stock_management'; else notPermitted">
<section>
  <header>
    <div class="title2">
      <div class="row">
        <div class="col-xs-6 col-md-6 d-flex">
          <h5 class="font-weight-light pull-left text-primary">
            <span>
              <fa-icon [icon]="['fas', 'people-carry']" class="pr-2"></fa-icon>{{ 'create_stocktake' | translate }}
            </span>
          </h5>
        </div>
        <div class="col-xs-6 col-md-6">

          <button id="btnStocktakeDialogSaveFinal"
            class="btn mr-1 btn-primary float-right"
            [disabled]="objPagination.list.length < 1 || bDisabledForms"
            (click)="save('final')">
            <button-icon-loader [defaultIcon]="['fas', 'clipboard-check']" [isLoading]="bSaveLoading"></button-icon-loader>
            <span class="pl-1">{{ 'save_as_complete' | translate }}</span>
          </button>

          <button id="btnStocktakeDialogSaveDraft"
            class="btn mr-1 btn-primary float-right"
            [disabled]="objPagination.list.length < 1 || bDisabledForms"
            (click)="save('draft')">
            <button-icon-loader [defaultIcon]="['fas', 'clipboard']" [isLoading]="bSaveLoading"></button-icon-loader>
            <span class="pl-1">{{ 'save_as_draft' | translate }}</span>
          </button>

          <button [routerLink]="['/stock_levels']"
            type="button"
            class="btn btn-link float-right">
              <fa-icon [icon]="['fas', 'arrow-left']" class="pr-1"></fa-icon>{{ 'back_to_list' | translate }}
          </button>

        </div>
      </div>
    </div>
  </header>

  <div class="row pt-2">

    <div class="col-xs-12 col-md-12 mb-3">
      <app-information
        strType="listing"
        strModule="stock_take"
        [isPanel1Visible]= "true"
        [isPanel2Visible]= "true"
        [isPanel3Visible]= "true" >
      </app-information>
    </div>

    <div class="col-xs-9 col-md-9">

    <table class="table table-bordered w100p">
      <thead>
        <tr>
          <td colspan="6">
            <pagination-buttons [objPagination]="objPagination"></pagination-buttons>
          </td>
        </tr>
        <tr *ngIf="!isStocktakeComplete">
          <th class="w30p fs12">{{ 'name' | translate }}</th>
          <th class="w15p fs12">{{ 'code' | translate }}</th>
          <th class="w18p fs12">{{ 'location' | translate }}</th>
          <th class="w15p fs12">{{ 'new_quantity' | translate }}</th>
          <th class="w12p fs12">{{ 'existing_quantity' | translate }}</th>
          <th class="w10p fs12">
            <div class="float-right">
              <fa-icon id="iconStocktakeAddLine"
                *ngIf="objPagination.list.length > 0"
                (click)="addStockLevelField()"
                class="text-success pointer fs16 pr-2"
                [icon]="['fas', 'plus-circle']"
                matTooltip="{{ 'add_item' | translate }}" >
              </fa-icon>
              <fa-icon id="iconStocktakeClearAll"
                *ngIf="objPagination.list.length > 0"
                (click)="clearAll()"
                class="text-danger pointer fs16 pr-2"
                [icon]="['fas', 'trash-alt']"
                matTooltip="{{ 'clear_all' | translate }}" >
              </fa-icon>
            </div>

          </th>
        </tr>

        <tr *ngIf="isStocktakeComplete">
          <th class="w40p fs12">{{ 'name' | translate }}</th>
          <th class="w20p fs12">{{ 'code' | translate }}</th>
          <th class="w15p fs12">{{ 'adjusted_quantity' | translate }}</th>
        </tr>

      </thead>
      <tbody>
        <ng-container *ngFor="let config of objPagination.list | slice:objPagination.start_index:objPagination.end_index; let i = index;">
          <tr >
            <td colspan="6" class="p-0 w45p">
              <table class="table table-bordered w100p m-0">
                <tr>
                  <td class="w30p">
                    <ng-container>
                      <ng-select
                        [disabled]="bDisabledForms"
                        id="ngSelectStockLevelProduct{{i}}"
                        bindLabel="product_name"
                        placeholder="{{ 'select_item' | translate }}"
                        [items]="config.source | async"
                        [typeahead]="config.typehead"
                        [loading]="config.loader"
                        [(ngModel)]="config.value"
                        matTooltip="{{ config.value?.product_name }}"
                        (open)="config.loadDataOnClick()"
                        (change)="onStockLevelChanged()">

                        <ng-template ng-option-tmp let-item="item">
                          <div title="{{item.product_name}}">{{item.product_name}}</div>
                        </ng-template>

                      </ng-select>
                    </ng-container>

                  </td>

                  <td id="tdStockLevelProductCode{{i}}" class="w15p">
                    <ng-container *ngIf="config.value">{{ config.value.product_code }}</ng-container>
                  </td>

                  <td id="tdLocation{{i}}" class="w18p">
                    <input
                      *ngIf="config.value"
                      [(ngModel)]="config.value.location"
                      class="form-control font-size-11 input-height-40"
                      [disabled]="bDisabledForms"
                      maxlength="32"/>
                  </td>

                  <td id="tdStockLevelQuantity{{i}}" class="w15p">
                    <input *ngIf="config.value"
                      type="number"
                      onkeyup="if(this.value<0){this.value= this.value * -1}"
                      [(ngModel)]="config.value.quantity"
                      class="form-control font-size-11 input-height-40"
                      [disabled]="bDisabledForms"
                      [class.is-invalid]="bSaveClicked && config.value.quantity == config.value.original_quantity"/>
                  </td>

                  <td *ngIf="!isStocktakeComplete" id="tdStockLevelOriginalQuantity{{i}}" class="w12p">
                    <ng-container *ngIf="config.value">{{ config.value.original_quantity }}</ng-container>
                  </td>

                  <td *ngIf="!isStocktakeComplete" class="w10p">
                    <fa-icon id="iconRemoveStockLevel"
                      *ngIf="objPagination.list.length > 1"
                      class="text-danger pointer fs16 pr-2 float-right"
                      (click)="removeStockLevelField(i)"
                      [icon]="['fal', 'minus-circle']"></fa-icon>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </ng-container>

        <tr *ngIf="objPagination.list.length < 1" class="text-center">
          <td colspan="6">{{ 'select_warehouse' | translate }}</td>
        </tr>

        <tr>
          <td colspan="6">
            <pagination-buttons [objPagination]="objPagination"></pagination-buttons>
          </td>
        </tr>

      </tbody>
    </table>

  </div>

  <div class="col-md-3">
    <div class="card mb-16">
      <div class="card-header bg-success text-white">
        {{ 'select_warehouse_label' | translate }}
        <fa-icon class="float-right" [icon]="['fas', 'life-ring']" aria-hidden="true"></fa-icon>
      </div>
      <div class="card-body">
        <ng-select
          id="ngSelectStocktakeWarehouse"
          bindLabel="name"
          placeholder="{{ 'select_warehouse' | translate }}"
          [items]="objWarehouse.source | async"
          [typeahead]="objWarehouse.typehead"
          [loading]="objWarehouse.loader"
          [(ngModel)]="objWarehouse.model"
          [clearable]="false"
          (change)="onWarehouseSelected()">
        </ng-select>
      </div>
    </div>
  </div>

</div>

</section>
</ng-container>

<ng-template #notPermitted>
  <span>{{ 'forbidden_error' | translate }}</span>
</ng-template>