import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'local'
})
export class LocalPipe implements PipeTransform {

  /**
   * A pipe wrapper for MomentJS's Local.
   *
   * @see {@link https://momentjs.com/docs/#/manipulating/local/}
   *
   * @param input
   * @param args
   *
   * @returns {moment.Moment}
   */
  transform(input: moment.MomentInput): moment.Moment {
    return moment.utc(input).local();
  }

}
