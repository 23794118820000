<div>
    <div class="d-flex justify-content-around activities-option tabs-content">
        <span id="widgetTimeEntriesCreateButton" (click)="createTimeEntry()" class="span-links">
        <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
            {{ 'create_time_entry' | translate }}
        </span>
    </div>
    <div class="assets-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 pt-4 pb-4">
                    <div class="form-inline">
                        <div class="form-group flex-nowrap w-100">
                            <div class="col-lg-4">
                                <label class="jc-l">{{ 'filter_by_user' | translate }}</label>
                            </div>
                            <div class="col-lg-8">
                                <ng-select
                                    [items]="arNgSelectFields['assigned_user']['obv'] | async"
                                    [typeahead]="arNgSelectFields['assigned_user']['typehead']"
                                    [loading]="arNgSelectFields['assigned_user']['loader']"
                                    [clearable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    bindLabel="text"
                                    bindValue="id"
                                    [(ngModel)]="arNgSelectFields['assigned_user']['value']"
                                    (change)="filterData($event,'user')"
                                    (click)="getRelateField('assigned_user')"
                                    id="widgetTimeEntriesUsersFilter"
                                    >
                                    <ng-option value=''></ng-option>
                                    <ng-template ng-option-tmp let-item="item">
                                      <span id="widgetTimeEntriesUsersFilter{{ item.id }}">{{ item.text }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="form-inline mt-12">
                        <div class="form-group flex-nowrap w-100">
                            <div class="col-lg-4">
                                <label class="jc-l">{{ 'filter_by_category' | translate }}</label>
                            </div>
                            <div class="col-lg-8">
                                <ng-select
                                    [items]="arNgSelectFields['items']['obv'] | async"
                                    [typeahead]="arNgSelectFields['items']['typehead']"
                                    [loading]="arNgSelectFields['items']['loader']"
                                    [clearable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    bindLabel="text"
                                    bindValue="id"
                                    [(ngModel)]="arNgSelectFields['items']['value']"
                                    (change)="filterData($event,'item')"
                                    (click)="getRelateField('items')"
                                    id="widgetTimeEntriesCategoryFilter">
                                    <ng-template ng-option-tmp let-item="item">
                                      <span id="widgetTimeEntriesCategoryFilter{{ item.id }}">{{ item.text }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border-top pt-2 pb-2">
                <div class="col-sm-6 pt-1">
                    <span class="module-title">
                        <fa-icon [icon]="['fa', 'clock']" aria-hidden="true"></fa-icon>
                        &nbsp;
                        {{ 'time_entries' | translate }}
                    </span>
                </div>
                <div class="col-sm-6 pr-2">
                    <nav class="pagination-section">
                        <ul class="pagination">
                            <refresh-pagination-btn id="widgetTimeEntriesFefreshButton" (refresh)="onRefresh()" [isDisabled]="bLoading"></refresh-pagination-btn>
                            <li [ngClass]="(listService.strFirstValue != listService.strCurrentValue && listService.strCurrentValue != '') ? 'active' : 'disabled'" class="page-item">
                                <a id="widgetTimeEntriesPreviousButton" class="page-link" aria-label="first" (click)="fetchList('prev')">
                                    <fa-icon [icon]="['far', 'chevron-left']" aria-hidden="true"></fa-icon>
                                </a>
                            </li>
                            <li [ngClass]="(listService.strNextValue != '') ? 'active' : 'disabled'" class="page-item">
                                <a id="widgetTimeEntriesNextButton" class="page-link" aria-label="first" (click)="fetchList('next')">
                                    <fa-icon [icon]="['far', 'chevron-right']" aria-hidden="true"></fa-icon>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div *ngIf="arTimeEntryList.length < 1">
                <div class="row border-top" *ngIf="!bLoading">
                    <span class="no-content">
                        {{ 'no_time_entry' | translate }}
                    </span>
                </div>
                <div class="row border-top" *ngIf="bLoading">
                    <span class="no-content">
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
                        {{ 'loading' | translate }}
                    </span>
                </div>
            </div>

            <div class="row border-top pt-2 pb-2 pr-2"  *ngFor="let time_entry of arTimeEntryList" [id]="'widgetTimeEntriesRecord' + time_entry.id" >
                <div class="col-sm-4 truncate">
                    <div class="row" matTooltip="{{ 'start_time' | translate }}: {{ formatDate(time_entry.start_time) }}">
                        <div class="col-12">
                            <fa-icon [icon]="['fas', 'hourglass-start']"></fa-icon>
                            {{ formatDate(time_entry.start_time) }}
                        </div>
                    </div>
                    <div class="row" matTooltip="{{ 'actual_duration' | translate }}: {{ time_entry.actual_duration }}">
                        <div class="col-12">
                            <fa-icon [icon]="['fas', 'clock']"></fa-icon>
                            {{ time_entry.actual_duration  }} Hours
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 truncate">
                    <div class="row" matTooltip="{{ 'category' | translate }}: {{ time_entry.name }}">
                        <div class="col-12">
                            <fa-icon [icon]="['fas', 'grip-horizontal']"></fa-icon>
                            {{ time_entry.name  }}
                        </div>
                    </div>
                    <div class="row" matTooltip="{{ 'user' | translate }}: {{ time_entry.user_text }}">
                        <div class="col-12">
                            <fa-icon [icon]="['fas', 'user']"></fa-icon>
                            {{ time_entry.user_text  }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 truncate" (click)="openTimeEntry(time_entry)">
                    <span matTooltip="{{ 'description' | translate }}: {{  time_entry.description }}" *ngIf="time_entry.description != ''">{{ time_entry.description  }}</span>
                    <span matTooltip="--" *ngIf="time_entry.description == ''">--</span>
                </div>
                <div class="col-sm-1 d-table text-role less-padding">
                    <div class="row">
                        <div class="col-12">
                            <fa-icon id="widgetActivitiesEditButton" *ngIf="time_entry.customer_invoice_id == '' || time_entry.customer_invoice_id == null" class="text-success pointer float-right" [icon]="['fas', 'pencil']" (click)="openTimeEntry(time_entry)"></fa-icon>
                            <a *ngIf="time_entry.customer_invoice_id != '' && time_entry.customer_invoice_id != null" href="" [routerLink]="">
                                <fa-icon id="widgetActivitiesEditButton" class="text-secondary pointer float-right" [icon]="['fas', 'pencil']" (click)="openCI(time_entry)" matTooltip="{{ 'time_entry_linked' | translate }}{{ time_entry.invoice_number }} {{ 'cannot_be_updated' | translate }}"></fa-icon>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                          <fa-icon id="widgetActivitiesDeleteButton" *ngIf="time_entry.customer_invoice_id == '' || time_entry.customer_invoice_id == null" class="text-warning pointer float-right" [icon]="['fas', 'trash-alt']" (click)="deleteTimeEntry(time_entry.id)"></fa-icon>
                          <a *ngIf="time_entry.customer_invoice_id != '' && time_entry.customer_invoice_id != null" href="" [routerLink]="">
                            <fa-icon id="widgetActivitiesDeleteButton" class="text-secondary pointer float-right" [icon]="['fas', 'trash-alt']" (click)="openCI(time_entry)" matTooltip="{{ 'time_entry_linked' | translate }}{{ time_entry.invoice_number }} {{ 'cannot_be_deleted' | translate }}"></fa-icon>
                          </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
