import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'fieldmagic-multitext-input',
  template: `
    <div class="row">
      <ng-select
        class="col-lg-12 col-md-12 col-xs-12"
        [(ngModel)]="values"
        [addTag]="onTagCreation"
        [isOpen]="false"
        [multiple]="true"
        (change)=onChanged($event)
        (focus)="onTouched()"
        bindLabel="0"
        (blur)="onBlur($event.target)"
        (keyup.enter)="onEnter($event.target)"
      >
      </ng-select>
    </div>
`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultitextInputComponent),
      multi: true,
    },
  ],
})
export class MultitextInputComponent implements ControlValueAccessor {
  values: string[] = [];

  isDisabled: boolean = false;

  // Callback when input is touched
  onTouched = () => { };

  // Callback when input value was changed
  onChanged = (values: string[]) => { };

  // called tag is added/created
  onTagCreation = (tag: string) => {
    if (_.toString(tag).trim().length < 1) {
      return;
    }

    this._pushTag(tag);

    /// _pushTag will work on the onChange and trigger the write value for it
    /// no need to tell ng-select about the tag
    return;
  };

  onBlur = (target: HTMLInputElement) => {
    this._pushTag(target.value);

    target.value = '';
  };

  onEnter = (target: HTMLInputElement) => target.value = '';

  /// calls to register onChanged handler
  registerOnChange = (fn: (values: string[]) => void) => this.onChanged = (values: string[]) => fn(this._parseValues(values));

  /// calls to register onTouched handler
  registerOnTouched = (fn: () => void) => this.onTouched = fn;

  setDisabledState = (state: boolean) => this.isDisabled = state;

  /// called when value is being written
  writeValue = (values: string[]) => this.values = this._parseValues(values);

  private _parseValues(values: string[]): string[] {
    const out: string[] = [];

    for (let value of values) {
      value = _.toString(value);

      if (_.trim(value).length < 1) {
        continue;
      }

      out.push(value); 
    }

    return out;
  }

  private _pushTag(tag: string): void {
    const values = [... this.values, tag];

    this.writeValue(values);
    this.onChanged(values);
  }
}
