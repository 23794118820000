
    <div [formGroup]="parentForm" class="row">
      <div class="col-12 mb-2 text-right">
        <button
          id="add_additional_code"
          type="button"
          class="btn btn-link"
          (click)="addItem()">
          <fa-icon [icon]="['fas', 'plus-circle']" class="text-success"></fa-icon>
          <span class="pl-1">{{ 'add_item' | translate }}</span>
        </button>
      </div>
      <div class="col-12">
        <table
          [formArrayName]="field.key"
          class="table table-bordered">
          <thead>
            <tr>
              <th colspan="5" class="text-center fmc-input-label">{{ 'supplier_pricing_table' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-template #emptyItemCodesMessage>
              <tr>
                <td class="text-center text-muted" colspan="4">{{ 'no_item_codes_defined' | translate }}</td>
              </tr>
            </ng-template>
            <ng-container *ngIf="items.controls.length > 0; else emptyItemCodesMessage">
              <tr *ngFor="let control of items.controls; let i = index;" [formGroupName]="i">
                <td class="form-group" style="width: 20%;">
                  <label class="font-weight-bold" for="code">
                    <required-tag>{{ 'supplier_code' | translate }}</required-tag>
                  </label>
                  <input
                    id="additional_code_{{ i }}"
                    class="form-control font-size-11"
                    type="text"
                    formControlName="code"
                    maxLength="128"
                    [ngClass]="{
                      'is-invalid': control.get('code').errors && control.get('customer_id').touched
                    }"
                    required
                    #ng
                  />
                  <small class="text-danger" *ngIf="control.get('code').errors && control.get('code').errors.unique">
                    {{ 'unique_supplier_code_error' | translate }}
                  </small>
                </td>
                <td class="form-group" style="width: 20%;">
                  <label class="font-weight-bold" for="supplier">
                    <required-tag>{{ 'supplier_name' | translate }}</required-tag>
                  </label>
                  <ng-select
                    id="additional_code_supplier_{{ i }}"
                    formControlName="customer_id"
                    placeholder="{{ 'supplier_name' | translate }}"
                    matTooltip="{{ customerRelateField[control.value.id].value?.text }}"
                    bindLabel="text"
                    bindValue="id"
                    appendTo="body"
                    [items]="customerRelateField[control.value.id].source | async"
                    [typeahead]="customerRelateField[control.value.id].typehead"
                    [loading]="customerRelateField[control.value.id].loader"
                    [clearable]="false"
                    [ngClass]="{
                      'is-invalid': control.get('customer_id').errors && control.get('customer_id').touched
                    }">
                  </ng-select>
                </td>
                <td class="form-group" style="width: 15%;">
                  <label class="font-weight-bold" for="unit_cost">{{ 'default_unit_cost' | translate }}</label>
                  <fc-currency-input
                    [places]="4"
                    id="additional_code_unit_cost_{{ i }}"
                    formControlName="unit_cost"
                    nonNegative="true">
                  </fc-currency-input>
                </td>
                <td class="form-group w-15" style="width: 15%;">
                  <label class="font-weight-bold" for="lead_time">{{ 'lead_time' | translate }} ({{ 'days' | translate }})</label>
                  <input
                    formControlName="lead_time"
                    id="additional_code_lead_time_{{ i }}"
                    type="number"
                    class="form-control font-size-11"
                    (keypress)="negateNegative($event)">
                </td>
                <td class="form-group" style="width: 30%;">
                  <label class="font-weight-bold" for="lead_time">{{ 'notes' | translate }}</label>
                  <textarea
                    formControlName="notes"
                    id="additional_code_notes_{{ i }}"
                    type="number"
                    (focus)="increaseRow(i)"
                    (focusout)="decreaseRow(i)"
                    class="form-control font-size-11 additional_code_notes_{{ i }}">
                  </textarea>
                </td>
                <td class="w-5 text-center">
                  <button
                    id="add_additional_code_{{ i }}"
                    type="button"
                    class="btn btn-link text-success"
                    (click)="addItem()"
                    matTooltip="{{ 'add_item' | translate }}">
                    <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
                  </button>
                  <button
                    id="remove_additional_code_{{ i }}"
                    type="button"
                    class="btn btn-link text-danger"
                    (click)="removeItem(i)"
                    matTooltip="{{ 'remove' | translate }}">
                    <fa-icon [icon]="['fal', 'minus-circle']"></fa-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  