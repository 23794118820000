import { NgZone, Component, DoCheck, Inject, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Validators, FormGroup, AbstractControl } from '@angular/forms';
import { FormService } from '../../../services/form.service';
import { NotificationService } from '../../../services/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { RecordService } from '../../../services/record.service';
import { StatusCode } from '../../../lists/status-code';
import { isEmpty, cloneDeep, isArray, get, find, has, split } from 'lodash';
import { environment } from '../../../../environments/environment';
import { EditComponent as EditRolesComponent } from '../widget/roles/edit/edit.component';
import * as _moment from 'moment';
import { CustomTranslateService } from '../../../services/custom-translate.service';
import { MapsAPILoader } from '@agm/core';
import { Relate } from '../../../base/relate';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ViewService } from '../../../services/view.service';

import { SubscriptionRestrictionService } from '../../../services/subscription-restriction/subscription-restriction.service';
import { ADVANCED_PLAN, ENTERPRISE_PLAN } from '../../../objects/subscription-plans';
import { LooseObject } from '../../../objects/loose-object';
import { AvailabilityAction, Form } from '../../../base/form';
import { Select } from '../../../objects/select';
import { ArrService } from '../../../services/helpers/arr.service';
import { finalize } from 'rxjs/operators';
import { DateService } from '../../../services/helpers/date.service';
import { ReassignFutureTasksDialog, Action } from '../../../module/user/dialog/reassign-future-tasks/reassign-future-tasks.dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { blank, filled } from '../../utils/common';
import { toFormattedNumber } from '../../utils/numbers';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
@Component({
  selector: 'app-edit-form',
  templateUrl: './editform.component.html',
  styleUrls: ['./editform.component.scss'],
  providers: [CustomTranslateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditformComponent implements DoCheck, OnDestroy {

  public items: any = [];
  public strId: string;
  public strMode: string;
  public strModule: string;
  public bSubmitted: boolean = false;
  public bShowLoader: boolean = false;
  public bIsRoleForm: boolean = false;
  public bFound: any = [];
  public strInvalidDropdownField: any = [];
  public dropdownFieldCount = 0;
  public arReadOnlyId: any = [];
  public arResult: any = {};
  // List of modules that has custom edit view
  public arCustomEditModule = ['pricebooks'];
  public bCustomEditView: boolean;
  // List of modules that has no view
  public arNoViewModule = ['departments', 'resources', 'tax_codes', 'account_codes', 'document_library', 'job_skills', 'subcontractors'];
  public arModuleWithRelatedData: string[] = ['jobs', 'opportunities'];
  public bViewLink: boolean;
  public addToFinalRecord = {};
  public geoCoder: any;
  public arTranslateables = [
    'dropdown_default_value_error',
    'dropdown_option_error',
    'here', 'record_create_success',
  ];
  public arRelatedData = [];

  // List of fields that should only be visible in Enterprise plans
  public arEnterpriseFields = ['attributes', 'portal_active'];
  public parentValue: ParentValue;
  public isInitialLoad: boolean = true;
  public readonlyFieldsCanProcess: Array<string> = ['latitude', 'longitude'];

  /**
   * Have an active tab holder.
   *
   * @var {string}
   */
  public strActiveTab: string;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancelDialog();
  }

  private _subscriptions: Subscription[] = [];

  constructor(
    protected ngZone: NgZone,
    public subscriptionRestrictionService: SubscriptionRestrictionService,
    private formService: FormService,
    private recordService: RecordService,
    private notifService: NotificationService,
    public customTranslate: CustomTranslateService,
    private mapsAPILoader: MapsAPILoader,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    public dialogRef: MatDialogRef<EditformComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private viewService: ViewService,
    private arrService: ArrService,
    private dateService: DateService,
    private router: Router,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
      });
      this.customTranslate.initializeTransalateables(this.arTranslateables);

      // Store config
      this.strMode = data['strMode'];
      this.strModule = data['strModule'];
      this.bIsRoleForm = data['bIsRoleForm'] ? data['bIsRoleForm'] : false;
      this.formService.setModule(this.strModule);
      // Check if module has custom edit view
      this.bCustomEditView = (this.arCustomEditModule.indexOf(this.strModule) > -1) ? true : false;
      // Check if module has view
      this.bViewLink = (this.arNoViewModule.indexOf(this.strModule) > -1) ? true : false;
      // Do we have 'edit' mode?
      if (this.strMode == 'edit') {

        // Store current id
        this.strId = data['strId'];

        // Check if there's an id and module
        if (this.strModule != undefined && this.strId != undefined) {
          //We combine both request, and wait until we ge the data.
          recordService.getRecord(
            this.strModule, this.strId, (this.arModuleWithRelatedData.indexOf(this.strModule) > -1), {}, 'edit_form'
          ).pipe(
            finalize(() => this.bShowLoader === false)
          ).subscribe(
            results => {
              // Lets save the original form.
              this.arResult = cloneDeep(results);
              // Set related data
              this.arRelatedData = (results['related_data'] != undefined) ? results['related_data'] : [];
              // If module is jobs or opportunities
              if (this.strModule == 'jobs' || this.strModule == 'opportunities') {
                // Check the related customer data
                if (this.arRelatedData['customers'] != undefined && this.arRelatedData['customers'][0] != undefined) {
                  // Set customer address that needed when job has no site selected and is_custom_location is false
                  let customerData = {
                    type: 'customer_id',
                    data: { address: this.arRelatedData['customers'][0]['address'] }
                  }
                  this.doSomethingInParent(customerData);
                }
                // Check the related site data
                if (this.arRelatedData['sites'] != undefined && this.arRelatedData['sites'][0] != undefined) {
                  // Set site address that needed when job is_custom_location false
                  let siteData = {
                    type: 'site_id',
                    data: { address: this.arRelatedData['sites'][0]['address'] }
                  }
                  this.doSomethingInParent(siteData);
                }
              }

              this.removeFlexibleInvoicingWhenOnStarterPlan(results);
              this.removeProjectFromJobTypesWhenOnStarterPlan(results);
              this.removeFieldsFromEditFormUnlessOnEnterprisePlan(results);

              // Get config from local storage
              let objCurrentClient = JSON.parse(this.localStorageService.getItem('current_client'));

              results['used_fields'] = this.formatMetadataDefaultValue(results['used_fields']);
              // Set address field to true if is_custom_location is true
              if (results['used_fields']['is_custom_location'] != undefined &&
              results['used_fields']['is_custom_location']['default_value'] != undefined &&
              results['used_fields']['is_custom_location']['default_value']) {
                results['used_fields']['address']['required'] = true;
              }
              // Check if the form has a labor field.
              if (
                results['used_fields']['labor'] != undefined &&
                results['used_fields']['labor']['default_value'] != undefined
              ) {
                // Use the rearrange form to get the proper appearance.
                this.rearrangeForm({
                  data: results['used_fields']['labor']['default_value'],
                  mode: this.strMode,
                  type: "labor"
                });
              }

              // Check if the form has the 'is_custom_location' key and that the value is false.
              else if (
                results['used_fields']['is_custom_location'] != undefined &&
                results['used_fields']['is_custom_location']['default_value'] != undefined &&
                !results['used_fields']['is_custom_location']['default_value']
              ) {

                // Use the rearrange form to get the proper appearance.
                this.rearrangeForm({
                  data: results['used_fields']['is_custom_location']['default_value'],
                  mode: this.strMode,
                  type: "is_custom_location"
                });

              } else if (this.strModule == 'users' && results['record_details']['is_department_restricted'] === true && objCurrentClient.config.department_tracking) {
                this.rearrangeForm({
                  data: true,
                  mode: this.strMode,
                  type: "is_department_restricted"
                });
              } else {
                var objUsedFields = this.formatMetadataDefaultValue(results['used_fields']);
                //We set the data for the centralized edit to handle.
                this.arrangeData(this.formService.formData(results['record_view'], objUsedFields, results['record_details']));
              }

              //Set the old data for comparison when changes are made.
              this.formService.setOldData(this.items[this.strModule]);
              this.setTab();
              this._initializeFieldsAvailability();
              // We need to call markForCheck. To be able to reflect the changes made.
              this.changeDetectorRef.markForCheck();
            }
          );
        } else {
          this.notifService.sendNotification('not_allowed', 'invalid_module_or_id', 'danger');
        }

      } else {
        recordService.getRecordConfig(this.strModule, (!isEmpty(data.objDefault)) ? data.objDefault : null).first().subscribe(
          result => {
            // Set address field to false if is_custom_location is false
            if (result['used_fields']['is_custom_location'] != undefined &&
            result['used_fields']['is_custom_location']['default_value'] != undefined &&
            !result['used_fields']['is_custom_location']['default_value']) {
              result['used_fields']['address']['required'] = false;
            }

            this.removeFlexibleInvoicingWhenOnStarterPlan(result);
            this.removeProjectFromJobTypesWhenOnStarterPlan(result);
            this.removeFieldsFromEditFormUnlessOnEnterprisePlan(result);

            // Special case for adding of assets (This happens in jobs asset widget)
            if (this.data['arData'] !== undefined) {
              if (this.strModule === 'assets') {
                // Set value for site field
                if (has(result, 'used_fields.site_id')) {
                  result['record_details']['site_text'] = this.data['arData']['site_text'];
                  result['used_fields']['site_id']['default_value'] = this.data['arData']['site_id'];
                }
                // Set value for customer field
                if (has(result, 'used_fields.customer_id')) {
                  result['used_fields']['customer_id']['default_value'] = this.data['arData']['customer_id'];
                  result['record_details']['customer_text'] = this.data['arData']['customer_text'];
                }

                // If adding of asset was opened from assset job widget,
                // We should always set asset to active
                if (this.data['arData']['is_active'] != undefined && this.data['arData']['is_active'] === true && result['used_fields']['is_active'] !== undefined) {
                  result['record_details']['is_active'] = true;
                  result['used_fields']['is_active']['default_value'] = true;
                  result['used_fields']['is_active']['readonly'] = true;
                }

              }


              if (this.strModule === 'jobs' || this.strModule === 'opportunities' || this.strModule === 'sites') {
                if (this.checkUsedFieldExist(result['used_fields'], 'site_id') !== false && this.data['arData']['site_id'] && this.data['arData']['site_text']) {
                  result['record_details']['site_text'] = this.data['arData']['site_text'];
                  result['used_fields']['site_id']['default_value'] = this.data['arData']['site_id'];
                }

                if (this.checkUsedFieldExist(result['used_fields'], 'customer_id') !== false && this.data['arData']['customer_id'] && this.data['arData']['customer_text']) {
                  result['record_details']['customer_text'] = this.data['arData']['customer_text'];
                  result['used_fields']['customer_id']['default_value'] = this.data['arData']['customer_id'];
                }

                if (this.checkUsedFieldExist(result['used_fields'], 'contact_id') !== false && this.data['arData']['contact_id'] && this.data['arData']['contact_text']) {
                  result['record_details']['contact_text'] = this.data['arData']['contact_text'];
                  result['used_fields']['contact_id']['default_value'] = this.data['arData']['contact_id'];
                }

                if (this.checkUsedFieldExist(result['used_fields'], 'from_job_id') !== false && this.data['arData']['from_job_id'] && this.data['arData']['from_job_text']) {
                  result['record_details']['from_job_text'] = this.data['arData']['from_job_text'];
                  result['used_fields']['from_job_id']['default_value'] = this.data['arData']['from_job_id'];
                  result['used_fields']['from_job_id']['readonly'] = true;
                }
              }

              if (this.checkUsedFieldExist(result['used_fields'], 'purchase_order_id') !== false && this.strModule === 'supplier_invoices') {
                result['record_details']['purchase_order_text'] = this.data['arData']['purchase_order_text'];
                result['used_fields']['purchase_order_id']['default_value'] = this.data['arData']['purchase_order_id'];
              }

              if (this.checkUsedFieldExist(result['used_fields'], 'phone') !== false && this.strModule === 'contacts' && this.data['arData']['phone'] !== undefined) {
                result['used_fields']['phone']['default_value'] = this.data['arData']['phone'];
              }
            }

            // Lets save the original form.
            this.arResult = result;

            // If the form is a job or opportunities form, make sure to show the right form.
            if (this.strModule === 'jobs' || this.strModule === 'opportunities') {
              // Use the rearrange form to get the proper appearance.
              this.rearrangeForm({
                data: false,
                mode: this.strMode,
                type: "is_custom_location"
              });

            } else if (this.strModule === 'items') {
              // Use the rearrange form to get the proper appearance for items module
              this.rearrangeForm({
                data: false,
                mode: this.strMode,
                type: "labor"
              });
            } else {

              //Set the form data.
              this.arrangeData(this.formService.formData(result['record_view'], result['used_fields'], result['record_details']));
            }

            //Set the old data for comparison when changes are made.
            this.formService.setOldData(this.items[this.strModule]);
            this.setTab();
            this._initializeFieldsAvailability();
            // We need to call markForCheck. To be able to reflect the changes made.
            this.changeDetectorRef.markForCheck();
          }
        );
      }

      dialogRef.backdropClick().subscribe(_ => {
        this.cancelDialog();
      });
  }

  /**
   * After initializing of the component.
   * We need to call markForCheck to
   * be able to reflect all changes.
   */
  ngDoCheck() {
    this.changeDetectorRef.markForCheck();
  }

  // @see OnDestory::ngOnDestroy
  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Can be used by any module to add their custom touches to the forms.
   */
  public customFunctions() {

    // If the module is jobs.
    if(this.strModule == 'jobs') {

      // Initialize variables.
      let numIndexOfTypeForm = -1;
      let numIndexOfinvoicingTypeForm = -1;
      let numIndexOfinvoicingTypeField = -1;
      let numIndexOfAmountToInvoiceField = -1;
      let numIndexOfAmountToInvoiceForm = -1;
      let numIndexOfBillableForm = -1;
      let numIndexOfBillableField = -1;
      let numIndexOfProjectTemplateGroup = -1;
      let numIndexOfProjectTemplateField = -1;
      let numIndexOfJobTemplateForm = -1;
      let numIndexOfJobTemplateField = -1;

      // Loop throught the list of forms/tabs.
      this.items[this.strModule].forEach((form, index) => {

        // Find the index of the form control named 'type'. (Job Type)
        if (form['groups']['controls']['type'] != undefined) {
          numIndexOfTypeForm = index;
        }

        // Find the index of the form control named 'amount_to_invoice'
        if (form['groups']['controls']['amount_to_invoice'] != undefined) {
          numIndexOfAmountToInvoiceForm = index;
        }

        // Find the index of the form control named 'billable'
        if (form['groups']['controls']['billable'] != undefined) {
          numIndexOfBillableForm = index;
        }

        // Find the index of the form control named 'billable'
        if (form['groups']['controls']['job_template_id'] != undefined) {
          numIndexOfJobTemplateForm = index;
        }

        // Find the form object of the invoicing_type from the array of field objects.
        let hasInvoiceType = form['fields'].findIndex(objField => (objField['key'] == 'invoicing_type'));
        let hasAmountToInvoice = form['fields'].findIndex(objField => (objField['key'] == 'amount_to_invoice'));
        let hasBillable = form['fields'].findIndex(objField => (objField['key'] == 'billable'));
        let numIndexProjectTemplate: number = form['fields'].findIndex(objField => (objField['key'] == 'project_template_id'));
        let numIndexOfJobTemplate: number = form['fields'].findIndex(objField => (objField['key'] == 'job_template_id'));

        if (hasInvoiceType > -1) {
          numIndexOfinvoicingTypeField = hasInvoiceType;
          // Save the index if there is an index found.
          numIndexOfinvoicingTypeForm = index;
        }
        if (hasAmountToInvoice > -1) {
          numIndexOfAmountToInvoiceField = hasAmountToInvoice;
        }
        if (hasBillable > -1) {
          numIndexOfBillableField = hasBillable;
        }

        if (numIndexProjectTemplate !== -1) {
          numIndexOfProjectTemplateGroup = index;
          numIndexOfProjectTemplateField = numIndexProjectTemplate;
        }

        if (numIndexOfJobTemplate !== -1) {
          numIndexOfJobTemplateField = numIndexOfJobTemplate;
        }

      });

      // If index of billable are found proceed
      if (numIndexOfBillableForm > -1 && numIndexOfBillableField > -1 && numIndexOfinvoicingTypeField != -1 && numIndexOfAmountToInvoiceField != -1) {
        if (this.items[this.strModule][numIndexOfBillableForm]['groups']['value']['billable']) {
          this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].setValidators([Validators.required]);
          this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].updateValueAndValidity();
          this.items[this.strModule][numIndexOfinvoicingTypeForm]['fields'][numIndexOfinvoicingTypeField]['is_hidden'] = false;
          if (
            this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['value']['invoicing_type'] == 'fixed_price_invoices' ||
            this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['value']['invoicing_type'] == 'time_and_materials'
          ) {
            this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['is_hidden'] = false;
          }
        } else {
          this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].clearValidators();
          this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].updateValueAndValidity();
          this.items[this.strModule][numIndexOfinvoicingTypeForm]['fields'][numIndexOfinvoicingTypeField]['is_hidden'] = true;
          this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['is_hidden'] = true;
        }
      }

      // If indexes for both type and invoicing type are found, proceed.
      if (numIndexOfTypeForm != -1 && numIndexOfinvoicingTypeForm != -1) {
        // Set readonly if jobs has customer invoices created
        if (this.arRelatedData['customer_invoices'] != undefined && this.arRelatedData['customer_invoices'] != null && this.arRelatedData['customer_invoices'].length > 0) {
          this.items[this.strModule][numIndexOfinvoicingTypeForm]['fields'][numIndexOfinvoicingTypeField]['readonly'] = true;
          this.items[this.strModule][numIndexOfBillableForm]['fields'][numIndexOfBillableField]['readonly'] = true;
          this.items[this.strModule][numIndexOfBillableForm]['groups']['controls']['billable'].disable();
        } else {
          this.items[this.strModule][numIndexOfinvoicingTypeForm]['fields'][numIndexOfinvoicingTypeField]['readonly'] = false;
          this.items[this.strModule][numIndexOfBillableForm]['fields'][numIndexOfBillableField]['readonly'] = false;
          this.items[this.strModule][numIndexOfBillableForm]['groups']['controls']['billable'].enable();

        }

        // Check the type and set the proper dropdown values.
        this.changeDropdownValue(this.items[this.strModule][numIndexOfTypeForm]['groups']['controls']['type']['value'], numIndexOfinvoicingTypeForm, numIndexOfinvoicingTypeField);
        // Check if amount to invoice field is exist
        if (numIndexOfAmountToInvoiceField != -1) {
          // Check if invoicing type is fixed_price_invoices
          if (this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type']['value'] != undefined &&
            this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type']['value'] != 'fixed_price_invoices') {
            // If not set amount to invoice as readonly
            this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['readonly'] = true;
            this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['is_hidden'] = true;
            this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['default_value'] = '0.00';
          } else {
            // If yes set amount to invoice as editable and set the current amount to invoice
            let strAmountToInvoice = '0.00';
            if (this.arResult['record_details']['amount_to_invoice'] != undefined) {
              strAmountToInvoice = this.arResult['record_details']['amount_to_invoice'];
            }
            this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['readonly'] = false;
            this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['is_hidden'] = false;
            this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['default_value'] = strAmountToInvoice;
          }
        }
        // If the type dropdown changes.
        this.items[this.strModule][numIndexOfTypeForm]['groups']['controls']['type'].valueChanges.subscribe(
          change => {

            // If the selected type is simple job but the invoicing type selected is milestone_invoicing. Milestone billing is only allowed
            // if the job type is a project.
            if (change == 'simple_job' && this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type']['value'] == 'milestone_invoicing') {
              this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups'].patchValue({
                invoicing_type: "time_and_materials"
              });
            }
            // Alter the dropdown list of the invoicing type.
            this.changeDropdownValue(change, numIndexOfinvoicingTypeForm, numIndexOfinvoicingTypeField);

            // FC-1203: display project templates field if we have a job type = 'project' and currently creating a job
            if (numIndexOfProjectTemplateGroup !== -1 && numIndexOfProjectTemplateField !== -1 && this.strMode == 'add') {
              let objProjectTemplateField: Relate = this.items[this.strModule][numIndexOfProjectTemplateGroup]['fields'][numIndexOfProjectTemplateField];
              objProjectTemplateField.is_hidden = ! (change === 'project');
            }
            // We need to call markForCheck. To be able to reflect the changes made.
            this.changeDetectorRef.markForCheck();
          }
        );

        // If the invoicing type dropdown changes.
        this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].valueChanges.subscribe(
          change => {
            // If invoicing type exists
            if (numIndexOfAmountToInvoiceField != -1) {
              // If on change of invoicing type is fixed_price_invoices
              // set amount to invoice as editable then set the current amount to invoice
              // FC-4299: also display amount to invoice on time and materials job
              if (change == 'fixed_price_invoices' || change == 'flexible_invoicing' || change === 'time_and_materials') {
                let strAmountToInvoice = '0.00';
                if (this.arResult['record_details']['amount_to_invoice'] != undefined) {
                  strAmountToInvoice = this.arResult['record_details']['amount_to_invoice'];
                }
                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['readonly'] = true;
                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['is_hidden'] = false;
                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['default_value'] = isEmpty(strAmountToInvoice) ? 0 : strAmountToInvoice;
              } else {
                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['readonly'] = true;
                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['is_hidden'] = true;
                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['default_value'] = 0;
              }
              // We need to call markForCheck. To be able to reflect the changes made.
              this.changeDetectorRef.markForCheck();
            }
          }
        );
      }

      // If index of billable are found proceed
      if (numIndexOfBillableForm > -1 && numIndexOfBillableField > -1) {
        // If billable change.
        this.items[this.strModule][numIndexOfBillableForm]['groups']['controls']['billable'].valueChanges.subscribe(
          change => {
            // If invoicing type exists
            if (numIndexOfinvoicingTypeField != -1 && numIndexOfAmountToInvoiceField != -1) {
              if (change) {
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups'].patchValue({
                  invoicing_type: ''
                });

                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['groups'].patchValue({
                  amount_to_invoice: '0.00'
                });

                this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].setValidators([Validators.required]);
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].updateValueAndValidity();
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type']['value'] = '';
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['fields'][numIndexOfinvoicingTypeField]['is_hidden'] = false;
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['fields'][numIndexOfinvoicingTypeField]['default_value'] = '';


                if (this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['value']['invoicing_type'] == 'fixed_price_invoices') {
                  this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['amount_to_invoice']['value'] = '0.00';
                  this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['is_hidden'] = false;
                  this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['default_value'] = '0.00';
                }
              } else {
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups'].patchValue({
                  invoicing_type: ''
                });

                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['groups'].patchValue({
                  amount_to_invoice: '0.00'
                });

                this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].clearValidators();
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type'].updateValueAndValidity();
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['groups']['controls']['invoicing_type']['value'] = null;
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['fields'][numIndexOfinvoicingTypeField]['is_hidden'] = true;
                this.items[this.strModule][numIndexOfinvoicingTypeForm]['fields'][numIndexOfinvoicingTypeField]['default_value'] = null;

                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['groups']['controls']['amount_to_invoice']['value'] = '0.00';
                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['is_hidden'] = true;
                this.items[this.strModule][numIndexOfAmountToInvoiceForm]['fields'][numIndexOfAmountToInvoiceField]['default_value'] = '0.00';
              }
              // We need to call markForCheck. To be able to reflect the changes made.
              this.changeDetectorRef.markForCheck();
            }
          }
        );
      }

      if (numIndexOfJobTemplateForm > -1 && numIndexOfJobTemplateField > -1) {
        let objJobTemplateIdGroup = this.items[this.strModule][numIndexOfJobTemplateForm]['groups'];
        let objJobTemplateIdField = this.items[this.strModule][numIndexOfJobTemplateForm]['fields'][numIndexOfJobTemplateField];

        this.jobTemplateChange(objJobTemplateIdGroup, objJobTemplateIdField);
      }

    } else if (this.strModule == 'assets') {
      // Initialize variables.
      let numIndexOfAssetTypeForm = -1;
      let numIndexOfAttributesForm = -1;
      let numIndexOfIsRentalForm = -1;
      let numIndexOfExpectedDateReturnForm = -1;
      let numIndexOfSiteForm = -1;
      let numIndexOfCustomerForm = -1;

      // Loop throught the list of forms/tabs.
      this.items[this.strModule].forEach((form, index) => {

        // Find the index of the form control named 'type'. (Job Type)
        if (form['groups']['controls']['asset_type_id'] != undefined) {
          numIndexOfAssetTypeForm = index;
        }

        // Find the index of the form control named 'type'. (Job Type)
        if (form['groups']['controls']['attributes'] != undefined) {
          numIndexOfAttributesForm = index;
        }

        if (form['groups']['controls']['is_rental'] != undefined) {
          numIndexOfIsRentalForm = index;
        }

        if (form['groups']['controls']['expected_date_return'] != undefined) {
          numIndexOfExpectedDateReturnForm = index;
        }

        if (form['groups']['controls']['site_id'] != undefined) {
          numIndexOfSiteForm = index;
        }

        if (form['groups']['controls']['customer_id'] != undefined) {
          numIndexOfCustomerForm = index;
        }

        let hasAttributes = form['fields'].findIndex(objField => (objField['key'] == 'attributes'));

        // If indexes for both asset type and attributes are found, proceed.
        if (numIndexOfAssetTypeForm != -1 && numIndexOfAttributesForm != -1 && hasAttributes != -1) {
          if (this.strMode == 'edit') {
            this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['is_loading'] = true;
            this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['is_loading'] = false;
            let currentAttributes = this.items[this.strModule][numIndexOfAssetTypeForm]['groups']['controls']['attributes']['value'];
            let arResult = (this.arResult['attributes'] != null && this.arResult['attributes'] != '') ? this.arResult['attribtues'] : [];
            let accumulatedDefaultValue = [];
            if (currentAttributes) {
              currentAttributes.forEach( (data) => {
                let key = Object.keys(data);
                let attributeExist = arResult.findIndex(objData => (objData['key'] == data[key[0]]));
                let arDefaultValue = (attributeExist < 0) ? false : {id: key[0], value: arResult[attributeExist]['key']};
                if (arDefaultValue) {
                  accumulatedDefaultValue.push(arDefaultValue);
                }
              });
            }

            this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['asset_type_value'] = arResult;
            this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['default_value'] = accumulatedDefaultValue;

          } else {
            this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['is_loading'] = false;
            this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['asset_type_value'] = [];
            this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['default_value'] = [];
          }

          // If the type dropdown changes.
          this.items[this.strModule][numIndexOfAssetTypeForm]['groups']['controls']['asset_type_id'].valueChanges.subscribe(
            change => {
              this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['is_loading'] = true;
              let arFilter: any = { 'asset_types.id': change };
              if (change != '' && change != null) {
                this.recordService.getRecordRelate('asset_types', '', '', false, arFilter, 10, false).subscribe( results => {

                  let arValue = (results[0] != undefined) ? results[0]['attributes'] : [];

                  this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['is_loading'] = false;
                  this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['asset_type_value'] = arValue;
                  this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['default_value'] = arValue;
                  this.items[this.strModule][numIndexOfAttributesForm]['groups']['controls']['attributes']['value'] = arValue;
                });
              } else {
                this.items[this.strModule][numIndexOfAttributesForm]['fields'][hasAttributes]['is_loading'] = false;
              }
              // We need to call markForCheck. To be able to reflect the changes made.
              this.changeDetectorRef.markForCheck();
            }
          );
        }
      });

      if (numIndexOfIsRentalForm != -1) {

        let expectedDateReturnField = this.items[this.strModule][numIndexOfExpectedDateReturnForm].fields.find((field) => field.key === 'expected_date_return');

        this.items[this.strModule][numIndexOfIsRentalForm]['groups']['controls']['is_rental'].valueChanges.subscribe(
          change => {
            if (expectedDateReturnField !== undefined) {
              if (change) {
                expectedDateReturnField.is_hidden = false;
              } else {
                this.items[this.strModule][numIndexOfExpectedDateReturnForm]['groups']['controls']['expected_date_return']['value'] = null;
                expectedDateReturnField.is_hidden = true;
              }
            }
            // We need to call markForCheck. To be able to reflect the changes made.
            this.changeDetectorRef.markForCheck();
          }
        );
      }

      if (numIndexOfSiteForm != -1) {
        this.items[this.strModule][numIndexOfSiteForm]['groups']['controls']['site_id'].valueChanges.subscribe(
          change => {
              if (change && !this.isInitialLoad) {
                this.recordService.getRecord('sites', change, true).subscribe(data => {
                  this.items[this.strModule][numIndexOfCustomerForm]['groups'].patchValue({
                    customer_id: data['record_details']['customer_id']
                  });

                  this.parentValue = {
                    value: [new Select(data['record_details']['customer_id'], data['record_details']['customer_text'])],
                    control_type: 'relate',
                    field_name: 'customer_id',
                  }

                  // Need to setTimeout as we need to
                  // throw the data in parent component.
                  setTimeout(() => {
                    this.parentValue = {
                      value: {
                        'address_name': null,
                        'latitude': get(data, 'record_details.address.latitude', null),
                        'longitude': get(data, 'record_details.address.longitude', null),
                      },
                      control_type: 'geolocation',
                      field_name: 'geolocation',
                    }
                  }, 200);
                });
              }

            this.isInitialLoad = false;
            // We need to call markForCheck. To be able to reflect the changes made.
            this.changeDetectorRef.markForCheck();
          });
      }
    } else if (this.strModule == 'customers' || this.strModule == 'items') {
      // Initialize variables.
      let numIndexOfAccountingSyncErrorForm = -1;
      let numIndexOfAccountingSyncErrorField = -1;

      let numIndexOfAccountingSyncDetailForm = -1;
      let numIndexOfAccountingSyncDetailField = -1;

      let groupIndexOfTypeField = -1;
      let firstNameGroupIndex,
          lastNameGroupIndex,
          fullNameGroupIndex;

      // Loop throught the list of forms/tabs.
      this.items[this.strModule].forEach((form, index) => {

        // Find the index of the form control named 'accounting_sync_error'
        if (form['groups']['controls']['accounting_sync_error'] != undefined) {
          numIndexOfAccountingSyncErrorForm = index;
        }
        // Find the index of the form control named 'accounting_sync_detail'
        if (form['groups']['controls']['accounting_sync_detail'] != undefined) {
          numIndexOfAccountingSyncDetailForm = index;
        }
        // Find the form object of the accounting_sync_error and accounting_sync_detail from the array of field objects.
        let hasAccountingSyncErrorField = form['fields'].findIndex(objField => (objField['key'] == 'accounting_sync_error'));
        let hasAccountingSyncDetailField = form['fields'].findIndex(objField => (objField['key'] == 'accounting_sync_detail'));

        if (hasAccountingSyncErrorField > -1) {
          numIndexOfAccountingSyncErrorField = hasAccountingSyncErrorField;
        }
        if (hasAccountingSyncDetailField > -1) {
          numIndexOfAccountingSyncDetailField = hasAccountingSyncDetailField;
        }

        if (form['groups']['controls']['type'] !== undefined) {
          groupIndexOfTypeField = index;
        }

        if (form['groups']['controls']['first_name'] !== undefined) {
          firstNameGroupIndex = index;
        }

        if (form['groups']['controls']['last_name'] !== undefined) {
          lastNameGroupIndex = index;
        }

        if (form['groups']['controls']['name'] !== undefined) {
          fullNameGroupIndex = index;
        }
      });

      if (numIndexOfAccountingSyncErrorForm > -1 && numIndexOfAccountingSyncDetailForm > -1) {
        // Onload of form check strMode
        if (this.strMode == 'edit') {
          // If edit check accounting_sync_error to check if accounting sync detail is_hidden
          if (this.arResult['record_details']['accounting_sync_error'] === true) {
            this.items[this.strModule][numIndexOfAccountingSyncDetailForm]['fields'][numIndexOfAccountingSyncDetailField]['is_hidden'] = false;
          } else {
            this.items[this.strModule][numIndexOfAccountingSyncDetailForm]['fields'][numIndexOfAccountingSyncDetailField]['is_hidden'] = true;
          }
        } else {
          // If create default of accounting sync detail is_hidden  to true
          this.items[this.strModule][numIndexOfAccountingSyncDetailForm]['fields'][numIndexOfAccountingSyncDetailField]['is_hidden'] = true;
        }
        // If the accounting sync error changes.
        this.items[this.strModule][numIndexOfAccountingSyncErrorForm]['groups']['controls']['accounting_sync_error'].valueChanges.subscribe(
          change => {
            // Upon onchange of accounting sync error empty accounting sync detail
            this.items[this.strModule][numIndexOfAccountingSyncDetailForm]['groups'].patchValue({
              accounting_sync_detail: ''
            });
            if (change === true) {
              this.items[this.strModule][numIndexOfAccountingSyncDetailForm]['fields'][numIndexOfAccountingSyncDetailField]['is_hidden'] = false;
            } else {
              this.items[this.strModule][numIndexOfAccountingSyncDetailForm]['fields'][numIndexOfAccountingSyncDetailField]['is_hidden'] = true;
            }
            // We need to call markForCheck. To be able to reflect the changes made.
            this.changeDetectorRef.markForCheck();
          }
        );
      }

      if (groupIndexOfTypeField >= 0) {
        let group: FormGroup = this.items['customers'][groupIndexOfTypeField].groups;

        group.controls.type.valueChanges.subscribe((change) => {
          let isOrganization: boolean = change === 'organization';
          let firstNameField = this.items['customers'][firstNameGroupIndex].fields.find((field) => field.key === 'first_name');
          let lastNameField = this.items['customers'][lastNameGroupIndex].fields.find((field) => field.key === 'last_name');
          let fullNameField = this.items['customers'][fullNameGroupIndex].fields.find((field) => field.key === 'name');

          if (firstNameField !== undefined) {
            firstNameField.required = (! isOrganization);
            firstNameField.is_hidden = (isOrganization);

            this.setControlValidator(firstNameField, this.items['customers'][firstNameGroupIndex].groups.controls.first_name);
          }

          if (lastNameField !== undefined) {
            lastNameField.required = (! isOrganization);
            lastNameField.is_hidden = (isOrganization);

            this.setControlValidator(lastNameField, this.items['customers'][lastNameGroupIndex].groups.controls.last_name);
          }

          if (fullNameField !== undefined) {
            fullNameField.required = (isOrganization);
            fullNameField.readonly = (! isOrganization);

            this.setControlValidator(fullNameField, this.items['customers'][fullNameGroupIndex].groups.controls.name);
          }
        });
      }
    } else if (this.strModule === 'warehouses') {
      let numIndexType = -1;
      let numIndexAddress = -1;

      this.items[this.strModule].forEach((form, index) => {
        if (form['groups']['controls']['type'] != undefined) {
          numIndexType = index;
        }
        if (form['groups']['controls']['address'] !== undefined) {
          numIndexAddress = index;
        }

        if (numIndexType >= 0) {
          let group: FormGroup = this.items['warehouses'][numIndexType].groups;
          let addressField = this.items['warehouses'][numIndexAddress].fields.find((field) => field.key === 'address');

          if (get(group, ['controls', 'type', 'value'], null) === 'vehicle') {
            addressField.required = false;
            addressField.is_hidden = true;
          }

          group.controls.type.valueChanges.subscribe((newValue) => {
            let isVehicle : boolean = newValue === 'vehicle';

            if (addressField !== undefined) {
              addressField.required = (! isVehicle);
              addressField.is_hidden = (isVehicle);

              this.setControlValidator(addressField, this.items['warehouses'][numIndexAddress].groups.controls.address);
            }
          });
        }
      });
    }

    if (this.strModule == 'jobs' || this.strModule == 'opportunities' || this.strModule == 'sites') {
      let numIndexOfCustomerForm = -1;
      let numIndexOfCustomerField = -1;
      let numIndexOfSiteForm = -1;
      let numIndexOfSiteField = -1;

      // Loop throught the list of forms/tabs.
      this.items[this.strModule].forEach((form, index) => {

        // Find the index of the form control named 'customer_id'
        if (form['groups']['controls']['customer_id'] != undefined) {
          numIndexOfCustomerForm = index;
        }

        // Find the index of the form control named 'site_id'
        if (form['groups']['controls']['site_id'] != undefined) {
          numIndexOfSiteForm = index;
        }

        let hasCustomer = form['fields'].findIndex(objField => (objField['key'] == 'customer_id'));
        let hasSite = form['fields'].findIndex(objField => (objField['key'] == 'site_id'));

        if (hasCustomer > -1) {
          numIndexOfCustomerField = hasCustomer;
        }
        if (hasSite > -1) {
          numIndexOfSiteField = hasSite;
        }
      });

      // If index of customer are found proceed
      if (numIndexOfCustomerForm > -1 && numIndexOfCustomerField > -1) {
        // If widget data module is customer. Make customer field as readonly
        if (this.data['arData'] != undefined && this.data['arData']['module'] != undefined && this.data['arData']['module'] == 'customers') {
          this.items[this.strModule][numIndexOfCustomerForm]['fields'][numIndexOfCustomerField]['readonly'] = true;
          if (numIndexOfSiteForm > -1 && numIndexOfSiteField > -1) {
            this.items[this.strModule][numIndexOfSiteForm]['fields'][numIndexOfSiteField]['filter'] = { customer_id: this.data['arData']['customer_id'] };
            this.items[this.strModule][numIndexOfSiteForm]['fields'][numIndexOfSiteField]['filter_tap'] = false;
          }

          let arAddress = (this.data['arData']['address'] != undefined) ? this.data['arData']['address'] : [];
          let parentData = {
            type: 'customer_id',
            data: { address: arAddress }
          }
          this.doSomethingInParent(parentData);
        }
      }
      // If index of site are found proceed.
      if (numIndexOfSiteForm > -1 && numIndexOfSiteField > -1) {
        // If widget data module is site. Make site field as readonly
        if (this.data['arData'] != undefined && this.data['arData']['module'] != undefined && this.data['arData']['module'] == 'sites') {
          this.items[this.strModule][numIndexOfSiteForm]['fields'][numIndexOfSiteField]['readonly'] = true;
          let arAddress = (this.data['arData']['address'] != undefined) ? this.data['arData']['address'] : [];
          let parentData = {
            type: 'site_id',
            data: { address: arAddress }
          }
          this.doSomethingInParent(parentData);
        }
      }
    }

    if (this.strModule == 'items') {
      let numIndexOfLaborForm = -1;
      let numIndexOfLaborField = -1;

      // Loop throught the list of forms/tabs.
      this.items[this.strModule].forEach((form, index) => {
        // Find the index of the form control named 'labor'
        if (form['groups']['controls']['labor'] != undefined) {
          numIndexOfLaborForm = index;
        }

        // Find the form object of the field from the array of field objects.
        let hasLaborField = form['fields'].findIndex(objField => (objField['key'] == 'labor'));

        if (hasLaborField > -1) {
          numIndexOfLaborField = hasLaborField;
        }
      });

      this.items[this.strModule][numIndexOfLaborForm]['groups']['controls']['labor'].valueChanges.subscribe(
        change => {
          // We need to set a timeout as the form is rebuilding when labor is changed
          setTimeout(() => {
            let numIndexOfUnitPriceForm = -1;
            let numIndexOfUnitPriceField = -1;

            let numIndexOfHourlyCostForm = -1;
            let numIndexOfHourlyCostField = -1;

            let numIndexOfUnitCostForm = -1;
            let numIndexOfUnitCostField = -1;

            let numIndexOfMarkupForm = -1;
            let numIndexOfMarkupField = -1;

            let numIndexOfPricingMethodForm = -1;
            let numIndexOfPricingMethodField = -1;

            // Loop throught the list of forms/tabs.
            this.items[this.strModule].forEach((form, index) => {

              // Find the index of the form control named 'labor'
              if (form['groups']['controls']['labor'] != undefined) {
                numIndexOfLaborForm = index;
              }

              // Find the index of the form control named 'unit_price'
              if (form['groups']['controls']['unit_price'] != undefined) {
                numIndexOfUnitPriceForm = index;
              }

              // Find the index of the form control named 'hourly_cost'
              if (form['groups']['controls']['hourly_cost'] != undefined) {
                numIndexOfHourlyCostForm = index;
              }

              // Find the index of the form control named 'unit_cost'
              if (form['groups']['controls']['unit_cost'] != undefined) {
                numIndexOfUnitCostForm = index;
              }

              // Find the index of the form control named 'markup'
              if (form['groups']['controls']['markup'] != undefined) {
                numIndexOfMarkupForm = index;
              }

              // Find the index of the form control named 'pricing_method'
              if (form['groups']['controls']['pricing_method'] != undefined) {
                numIndexOfPricingMethodForm = index;
              }

              // Find the form object of the field from the array of field objects.
              let hasLaborField = form['fields'].findIndex(objField => (objField['key'] == 'labor'));
              let hasUnitPriceField = form['fields'].findIndex(objField => (objField['key'] == 'unit_price'));
              let hasHourlyCostField = form['fields'].findIndex(objField => (objField['key'] == 'hourly_cost'));
              let hasUnitCostField = form['fields'].findIndex(objField => (objField['key'] == 'unit_cost'));
              let hasMarkupField = form['fields'].findIndex(objField => (objField['key'] == 'markup'));
              let hasPricingMethodField = form['fields'].findIndex(objField => (objField['key'] == 'pricing_method'));

              if (hasLaborField > -1) {
                numIndexOfLaborField = hasLaborField;
              }

              if (hasUnitPriceField > -1) {
                numIndexOfUnitPriceField = hasUnitPriceField;
              }

              if (hasHourlyCostField > -1) {
                numIndexOfHourlyCostField = hasHourlyCostField;
              }

              if (hasUnitCostField > -1) {
                numIndexOfUnitCostField = hasUnitCostField;
              }

              if (hasUnitCostField > -1) {
                numIndexOfUnitCostField = hasUnitCostField;
              }

              if (hasMarkupField > -1) {
                numIndexOfMarkupField = hasMarkupField;
              }

              if (hasPricingMethodField > -1) {
                numIndexOfPricingMethodField = hasPricingMethodField;
              }
            });

            let unitCost = numIndexOfUnitCostField > -1 ? parseFloat(this.items[this.strModule][numIndexOfUnitCostForm]['groups']['value']['unit_cost']) : 0;
            let hourlyCost = numIndexOfHourlyCostField > -1 ? parseFloat(this.items[this.strModule][numIndexOfHourlyCostForm]['groups']['value']['hourly_cost']) : 0;
            let unitPrice = numIndexOfUnitPriceField > -1 ? parseFloat(this.items[this.strModule][numIndexOfUnitPriceForm]['groups']['value']['unit_price']) : 0;
            let markup = numIndexOfMarkupField > -1 ? parseFloat(this.items[this.strModule][numIndexOfMarkupForm]['groups']['controls']['markup']['value']) : 0;
            let pricingMethod = numIndexOfPricingMethodField > -1 ? this.items[this.strModule][numIndexOfPricingMethodForm]['groups']['value']['pricing_method'] : '';

            if (pricingMethod == 'fixed_sell_price') {
              let computeMarkup = this.computeMarkup(unitPrice, change ? hourlyCost : unitCost);

              this.items[this.strModule][numIndexOfMarkupForm]['groups'].patchValue({
                markup: computeMarkup
              });

            } else {
              let computeUnitPrice = this.computeUnitPrice(markup, change ? hourlyCost : unitCost);

              this.items[this.strModule][numIndexOfUnitPriceForm]['groups'].patchValue({
                unit_price: computeUnitPrice
              });
            }


            // We need to call markForCheck. To be able to reflect the changes made.
            this.changeDetectorRef.markForCheck();
          }, 200);
        }
      );
    }
  }

  /**
   * Changes the dropdown values of the invoicing type.
   * @param strBaseValue - the value that causes the change of dropdown
   * @param numIndex1 - index of the field in the tab list.
   * @param numIndex2 - index of the field in the field list
   */
  changeDropdownValue(strBaseValue, numIndex1, numIndex2) {

    // If the module is jobs.
    if(this.strModule == 'jobs') {

      // Clone the list of options in the invoicing type.
      let oldOptions = cloneDeep(this.items[this.strModule][numIndex1]['fields'][numIndex2]['options']);

      // If the type is 'project', add the milestone billing.
      // If the type is not 'project', remove the milestone billing.
      if (strBaseValue == 'project') {
        if (oldOptions.findIndex(items => items.id == 'milestone_invoicing') == -1) {
          oldOptions.push({id: "milestone_invoicing", text: "milestone_invoicing"});
        }
      } else {
        oldOptions.splice(3, 1);
      }

      // Place the new and updated drodpdown list.
      this.items[this.strModule][numIndex1]['fields'][numIndex2]['options'] = oldOptions;
    }
  }

  arrangeData(objData){
    if (objData) {

      let arData: any = objData;

      this.preDataArrangement(arData);

      //Modify the form groups to a reactive form group.
      this.items[this.strModule] = arData.map(
        formItems => {
          formItems['id'] = formItems['label']
            .toLowerCase()
            // FC-2579: This Regex will replace characters that do not match the
            // lowercase and uppercase characters from A to Z, 0 to 9, and an
            // underscore.
            .replace(/[^a-zA-Z0-9_]+/g, '_');
          formItems['groups'] = this.formService.toFormGroup(formItems['fields']);
          // Get all control field key
          let objControlField = Object.keys(formItems['groups']['controls']);
          // Loop field
          objControlField.forEach( strField => {
            // Get index of field
            let numIndex = formItems['fields'].findIndex( field => (field['key'] == strField));
            if (numIndex > -1) {
              let field = formItems['fields'][numIndex];
              let control = formItems.groups.controls[strField];

              // set validator
              this.setControlValidator(field, control);
            }
          });
          return formItems;
        }
      );
    }

    // This will trigger custom functions in the form.
    this.customFunctions();
  }

  /**
   * Rebuilds the form with the current values.
   * @param newForm The new form to be built.
   * @param excludeField The only field where we wont touch.
   */
  maintainCurrentValues(newForm, excludeField = []) {
    // Make sure the items has values to loop.
    if (this.items[this.strModule] != undefined) {
      // Set the values using current form control values.
      Object.keys(newForm['used_fields']).forEach(field => {
        this.items[this.strModule].forEach(tabs => {
          // Get all values of the form.
          let arValues = tabs['groups'].getRawValue();
          // Assign the values to the field.
          if (arValues[field] != undefined && !excludeField.includes(field)) {
            // If not relate, simply set the value.
            if (newForm['used_fields'][field]['type'] != 'relate') {
              newForm['used_fields'][field]['default_value'] = arValues[field];
            } else {
              // If a relate field, we need to set both value and options.
              let fields = tabs['fields'] || [];
              fields.forEach(element => {
                if (element['key'] == field) {
                  newForm['used_fields'][field]['default_value'] = arValues[field];
                  newForm['used_fields'][field]['options'] = element['options'];
                }
              });
            }
          }
        })
      });
    }
    // Return the modified object.
    return newForm;
  }

  // This function rearranges the add/edit form when the labor toggle is ticked.
  rearrangeForm(data) {

    // Get the original result.
    let newForm: any = cloneDeep(this.arResult);
    newForm['used_fields'] = this.formatMetadataDefaultValue(newForm['used_fields']);

    // If the field is is_custom_location.
    if (data['type'] == 'is_custom_location' || data['type'] == 'stage') {

      if (data['type'] == 'is_custom_location') {
        // Make sure the labor toggle gets the new value.
        newForm['used_fields']['is_custom_location']['default_value'] = data['data'];

        // Reapply values to new form.
        newForm = this.maintainCurrentValues(newForm, ['is_custom_location']);
      }

      // Trigger this only if stage is updated.
      if (data['type'] == 'stage') {
        // Make sure the stage gets the new value.
        newForm['used_fields']['stage']['default_value'] = data['data'];

        // Reapply values to new form.
        newForm = this.maintainCurrentValues(newForm, ['stage']);
      }

      // Loop through the record view metadata and remove address if is_custom_location is false;
      if (data['type'] == 'is_custom_location' && data['data'] === false || (data['type'] == 'stage' && newForm['used_fields']['is_custom_location']['default_value'] == false)) {
        // Set address required to false if is_custom_location is false
        newForm['used_fields']['address']['required'] = false;
        newForm['record_view'].forEach((tabs, index1) => {
          let fields = tabs['fields'] || [];
          fields.forEach((fields, index2) => {
              if (fields.indexOf('address') > -1) {
                if (fields.length == 1) {
                  newForm['record_view'][index1]['fields'].splice(index2, 1);
                } else {
                  newForm['record_view'][index1]['fields'][index2].splice(fields.indexOf('address'), 1)
                }
              }
          });
        });
      }

      if (data['type'] == 'is_custom_location' && data['data'] === true || (data['type'] == 'stage' && newForm['used_fields']['is_custom_location']['default_value'] == true)) {
        // Set address required to true if is_custom_location is true
        newForm['used_fields']['address']['required'] = true;
      }

      // Make sure we set the reason for lost along with the is_custom_location form changing.
      if ((this.strModule == 'opportunities' && (data['type'] == 'is_custom_location' || data['type'] == 'stage'))) {
        let numReasonForLostIndex = {
          formIndex: -1,
          fieldIndex: -1,
          fieldArrayIndex: -1,
        }

        // Get the indexes of the reason_for_lost on the record view.
        newForm['record_view'].forEach((element1, index1) => {
          let fields = element1['fields'] || [];
          fields.forEach((element2, index2) => {
            let fieldArrayIndex: number = element2.indexOf('reason_for_lost');
            if (fieldArrayIndex > -1) {
              numReasonForLostIndex['formIndex'] = index1;
              numReasonForLostIndex['fieldIndex'] = index2;
              numReasonForLostIndex['fieldArrayIndex'] = fieldArrayIndex;
            }
          });
        });

        // If there are indexes.
        if (numReasonForLostIndex['formIndex'] != -1 && numReasonForLostIndex['fieldIndex'] != -1) {
          // If the stage is not closed_lost, remove the reason for lost.
          if ((data['type'] == 'stage' && data['data'] != 'closed_lost') || (data['type'] == 'is_custom_location' && newForm['used_fields']['stage']['default_value'] != 'closed_lost')) {
            if (newForm['record_view'][numReasonForLostIndex['formIndex']]['fields'][numReasonForLostIndex['fieldIndex']].length > 1) {
              newForm['record_view'][numReasonForLostIndex['formIndex']]['fields'][numReasonForLostIndex['fieldIndex']].splice(numReasonForLostIndex['fieldArrayIndex'], 1);
            } else {
              newForm['record_view'][numReasonForLostIndex['formIndex']]['fields'].splice(numReasonForLostIndex['fieldIndex'], 1)
            }
          }
          // If the stage is  closed_lost, add the reason for lost.
          if ((data['type'] == 'stage' && data['data'] == 'closed_lost') || (data['type'] == 'is_custom_location' && newForm['used_fields']['stage']['default_value'] == 'closed_lost')) {
            // Else leave it there but make it required.
            newForm['used_fields']['reason_for_lost']['required'] = true;
            if (newForm['record_view'][numReasonForLostIndex['formIndex']]['fields'][numReasonForLostIndex['fieldIndex']].length > 1) {
              newForm['record_view'][numReasonForLostIndex['formIndex']]['fields'][numReasonForLostIndex['fieldIndex']][numReasonForLostIndex['fieldArrayIndex']] = 'reason_for_lost';
            } else {
              newForm['record_view'][numReasonForLostIndex['formIndex']]['fields'][numReasonForLostIndex['fieldIndex']] = ['reason_for_lost'];
            }
          }
        }
      }
    }

    // If the field is labor.
    if (data['type'] == 'labor') {

      // Make sure the labor toggle gets the new value.
      newForm['used_fields']['labor']['default_value'] = data['data'];

      // Reapply values to new form.
      newForm = this.maintainCurrentValues(newForm, ['labor']);

      // If the labor is true,
      // FC-2375: refactored removing unit_cost and current_stock_level when it is a labor
      let EXCLUDED_ITEM_FIELDS_IN_LABOR_TYPE = [];
      if (data['data']) {
        EXCLUDED_ITEM_FIELDS_IN_LABOR_TYPE = ['current_stock_level', 'unit_cost', 'preferred_supplier', 'unit', 'is_inventoried'];
      } else {
        EXCLUDED_ITEM_FIELDS_IN_LABOR_TYPE = ['hourly_cost'];
      }

      newForm['record_view'] = newForm['record_view'].map(({label, fields}) => ({
        label,
        fields: fields.map((group) => group.filter((field) =>
          ! EXCLUDED_ITEM_FIELDS_IN_LABOR_TYPE.includes(field)
        ))
      }));
    }

    if (data['type'] == 'is_department_restricted') {
      if (data['data'] === true) {
        newForm['used_fields']['department_id']['required'] = true;
      } else {
        newForm['used_fields']['department_id']['required'] = false;
      }
      newForm = this.maintainCurrentValues(newForm);
    }

    // Arrange the data.
    this.arrangeData(this.formService.formData(newForm['record_view'], newForm['used_fields'], newForm['record_details']));
  }

  // This function is triggered inside the child component.
  doSomethingInParent(event) {

    if (event) {
      // Added a switch event as this function can be useful
      // in the future.
      switch(event['type']) {
        case 'labor':
        case 'is_custom_location':
        case 'is_department_restricted':
          this.rearrangeForm(event);
          break;
        case 'stage':
          let allFormValues = {};

          //Get's all the fields.
          this.items['opportunities'].forEach(element => {
            allFormValues = {...allFormValues, ...element['groups'].getRawValue()}
          });

          // Only change form if either the new value is closed_lost and we dont have a reason for lost field.
          // Or if it's not closed_lost but there is a reason for lost field.
          if ((event['data'] != 'closed_lost' && allFormValues['reason_for_lost'] != undefined) ||
              (event['data'] == 'closed_lost' && allFormValues['reason_for_lost'] == undefined)) {
              this.rearrangeForm(event);
          }
          break;
        case 'site_id':
        case 'customer_id':
          if (event['data']['address'] != undefined) {
            this.appendToListOfAddress(event['data']['address'], event);
          } else if (event['data'] instanceof Select && event['data'].extras) {
            this.appendToListOfAddress(event['data'].extras, event);
          }
          break;
        case 'is_supplier':
        case 'is_customer':
          // If module is 'customers', and 'is_customer' or 'is_supplier' is set to true,
          // show the create job/create supplier invoice email address, which are used
          // to create jobs or supplier invoices from emails.
          //
          // Only available to enterprise customers.
          let infoGroup = this.items[this.strModule].find(group => group.id === 'information');
          let arItemFields = get(infoGroup, 'fields');
          let field: Form<any> = find(arItemFields, field => field.key === event['key']);

          if (field && this.strMode === 'edit') {
            field.is_hidden = event['is_hidden'];
          }

          if (field && this.strMode === 'edit' && event['is_hidden'] === false) {
            if (this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN)) {
              // Set default value to allow copying to clipboard
              field.default_value = this.generateCreateJobOrSupplierInvoiceEmail(event['type']);
              this.fieldPatchValue(field.key, this.generateCreateJobOrSupplierInvoiceEmail(event['type']));
            } else {
              // If client is not on enterprise plan just display blank
              field.default_value = '';
              this.fieldPatchValue(field.key, '');
            }
          }
          break;
        case 'redirect_to_manage_subscription':
          // FC-3930: Called from customers module, if client decides to upgrade subscription, redirect to manage subscription in admin.
          this.dialogClose('cancel');
          this.router.navigate(['/admin'], { queryParams: { open: 'manage_subscription' } });
          break;
      }

    }
  }

  /**
   * Generate a 'create job' or 'create supplier' invoice address
   *
   * @param {'is_customer' | 'is_supplier'} strKey
   *
   * @returns {string}
   */
  generateCreateJobOrSupplierInvoiceEmail(strKey: 'is_customer' | 'is_supplier'): string {
    let objCurrentClient = JSON.parse(this.localStorageService.getItem('current_client'));
    let strModule: string = strKey === 'is_customer' ? 'createjob' : 'createsupplierinvoice';

    return `${this.strId}.${strModule}.${objCurrentClient.alias}@${environment.archive_domain_name}`;
  }

  /**
   * Adds the current address to the list of addresses
   * that will be used depending on certain conditions.
   *
   * If a site was selected, use the site address.
   * If a site is not selected but a customer has address, use the customer address.
   * If the custom address was enabled, use the custom address.
   *
   * @param {any} objAdress //TODO: Typecast to address object.
   * @param {any} event //TODO: Typecast to a custom object.
   */
  appendToListOfAddress(objAdress: any, event: any) {

    let bMissing = false;

    // Make sure address has the required fields.
    Object.keys(objAdress).forEach(address => {
      if ((
        address == 'street' ||
        address == 'city' ||
        address == 'zip' ||
        address == 'country' ||
        address == 'state') && objAdress[address] == "") {
          bMissing = true;
      }
    });

    // If address does meet the required fields.
    if (!bMissing) {
      // Save to a placeholder.
      this.addToFinalRecord[event['type']] = objAdress;
    } else {
      // If address has missing delete existing data of address
      if(this.addToFinalRecord[event['type']] != undefined) {
        delete this.addToFinalRecord[event['type']];
      }
    }
  }


  /**
   * Closes the dialog.
   *
   * @see {@link https://github.com/angular/components/issues/9676} Fixes a bug where
   * the dialog box isn't closing immediately (and will take about 20 seconds to
   * close) even after calling dialogRef.close
   */
  dialogClose(strStatus, arData = null): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ status: strStatus, data: arData });
    });
  }

  /**
   * For Cancel button
   */
  cancelDialog(): void {
    if (this.checkFormGroupDirty()) {
      // Pop-up modal for confirmation
      this.notifService.sendConfirmation('confirm_cancel')
        .filter(confirmation => confirmation.answer === true)
        .subscribe(() => {
          this.dialogClose('cancel');
          // FC-1422 We need to call markForCheck. To be able to reflect the changes made.
          this.changeDetectorRef.markForCheck();
        });
    } else {
      this.dialogClose('cancel');
      // FC-1422 We need to call markForCheck. To be able to reflect the changes made.
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Check if the form group is changed
   *
   * @returns {boolean}
   */
  checkFormGroupDirty(): boolean {
    let bDirty = false;
    if (this.items[this.strModule]) {
      this.items[this.strModule].forEach( objForm => {
        var formGroup: FormGroup = objForm.groups;
        if (formGroup.dirty === true) {
          bDirty = true;
        }
      });
    }
    return bDirty;
  }

  /**
   * When a user hits save.
   */
  onSubmit() {

    this.bSubmitted = true;
    this.bShowLoader = true;
    let bValid = true;
    this.formService.setModule(this.strModule);

    //Loop throught the form groups.
    this.items[this.strModule] = this.items[this.strModule].map(
      form => {
        Object.keys(form['groups'].value).forEach(
          item => {
            let bReadonly = form['fields'].find( x => x.key == item);

            if (bReadonly != undefined) {
              if (bReadonly.readonly && !this.readonlyFieldsCanProcess.includes(bReadonly.key)) this.arReadOnlyId.push(item);
            }

            //Trim all data to make sure no unecessary whitespaces are inputted.
            let objFormGroupItem = form['groups'].get(item).value;

            // Mark all form controls to touch on submit
            form['groups'].controls[item].markAsTouched();

            /**
             * Special case if you have a validation to your attributes field
             */
            if (item == 'attributes' && this.strModule == 'assets') {
              if (objFormGroupItem != null && objFormGroupItem != '' && objFormGroupItem != undefined) {
                objFormGroupItem.forEach( data => {
                    if(data['required'] && data['default_value'] == '') {
                      bValid = false;
                      this.bSubmitted = false;
                    }
                });
              }
            } else if(item == 'attributes' && this.strModule == 'asset_types') {
              this.strInvalidDropdownField = [];
              this.bFound = [];
              this.dropdownFieldCount = 0;
              if (objFormGroupItem != null && objFormGroupItem != '' && objFormGroupItem != undefined) {
                objFormGroupItem.forEach( data => {
                  if(data['type'] == 'dropdown' && data['option'] != '' && data['option'] != null && data['option'] != undefined) {
                    this.dropdownFieldCount++;
                    let bIsFound = false;
                    // Loop dropdown options and check the default value to its data
                    data['option'].forEach( option => {
                      if (data['default_value'] == '' || option['id'] == data['default_value']) {
                        bIsFound = true;
                      }
                    });
                    // If found push to bFound to compare the count of dropdown field exist in form
                    if (bIsFound) {
                      this.bFound.push(true);
                    }
                    // If current dropdown option is less than 1 force to found
                    if (data['option'].length  < 1) {
                      this.bFound.push(true);
                      bIsFound = true;
                    }
                    // This is for message purposes to check what label has an invalid default value in options
                    if (!bIsFound) {
                      this.strInvalidDropdownField.push(data['label']);
                    }
                  }
                });
              }
              // Compare all bFound to dropdown field count
              if (this.bFound.length != this.dropdownFieldCount) {
                bValid = false;
              }
            } else if(item == 'address') {
              // Get field address
              let numIndex = form['fields'].findIndex( field => (field['key'] == 'address'));
              // Check if exist and field is required
              if (numIndex > -1 && form['fields'][numIndex]['required']) {
                // If address is required check each address has missing.
                let objAdress = objFormGroupItem;
                let bMissing = false;
                // Make sure address has the required fields.
                Object.keys(objAdress).forEach(address => {
                  if ((
                    address == 'street' ||
                    address == 'city' ||
                    address == 'zip' ||
                    address == 'country' ||
                    address == 'state') && isEmpty(objAdress[address])) {
                      bMissing = true;
                  }
                });
                // If address does meet the required fields.
                if (bMissing) {
                  bValid = false;
                }
              }
            }
          }
        );

        //Check if there are still invalid fields.
        if (!form['groups'].valid) {
          bValid = false;
          this.bSubmitted = false;
        }

        return form;
      }
    );



    if (bValid) {
      // Set mode in service.
      this.formService.setMode(this.strMode);
      if (this.formService.setSaveData(this.items[this.strModule])) {
        if (this.formService.objSaveData) {
          switch(this.strModule) {
            // Special case need to check upon saving of assets if asset group is not yet created.
            case 'assets':
              let indexOfAssetGroupForm = -1;
              let indexOfAssetGroupField = -1;

              this.items[this.strModule].forEach((form, index) => {
                // Find the index of the asset group field
                if (form['groups']['controls']['asset_group_id'] != undefined) {
                  indexOfAssetGroupForm = index;
                  indexOfAssetGroupField = form['fields'].findIndex(objField => (objField['key'] == 'asset_group_id'));
                }
              });

              if (indexOfAssetGroupForm > -1 && indexOfAssetGroupField > -1) {
                let strGroupName = this.formService.objSaveData['asset_group_id'];
                // Patter to check if a valid uuid
                let strPattern =  "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";
                let isMatched = (strGroupName != undefined) ? strGroupName.match(strPattern) : "";
                // Check if valid uuid if not create record
                if (strGroupName != undefined && strGroupName != '' && strGroupName != null && isMatched == null) {
                    let objData = { name: strGroupName };
                    this.recordService.saveRecord('asset_groups', objData).subscribe( result => {
                      if (result['body'] != undefined && typeof(result['body']) != 'string') {
                        this.formService.objSaveData['asset_group_id'] = result['body']['id'];
                        this.saveData(this.formService.objSaveData);
                      } else {
                        this.saveData(this.formService.objSaveData);
                      }
                    });
                } else {
                  this.saveData(this.formService.objSaveData);
                }
              } else {
                this.saveData(this.formService.objSaveData);
              }
            break;
            case 'jobs':
            case 'opportunities':
              // Placeholder for the rules with their values.
              let objRules = {};

              // We need to get the values in 2 different places,
              // that's why we have 2 sets of rules.
              let arListOfRules = {
                set1: [
                  {label: 'objAddress', key: 'address'},
                  {label: 'isCustomLocation', key: 'is_custom_location'},
                  {label: 'isSiteLocation', key: 'site_id'},
                  {label: 'isCustomerLocation', key: 'customer_id'}
                ],
                set2: [
                  {label: 'objSiteAddress', key: 'site_id'},
                  {label: 'objCustomerAddress', key: 'customer_id'},
                ]
              };

              // First is we find the current values in the form.
              this.items[this.strModule].forEach(tabs => {
                let objFormValues = tabs['groups'].getRawValue();
                arListOfRules.set1.forEach(element => {
                  if (
                    objFormValues[element['key']] != undefined ||
                    (objFormValues[element['key']] != undefined && objFormValues[element['key']] != '')
                  ) {
                    objRules[element['label']] = objFormValues[element['key']];
                  }
                });
              });

              // Next we find the addresses we got when selecting a site/customer id.
              arListOfRules.set2.forEach(element => {
                if (this.addToFinalRecord[element['key']] != undefined) {
                  objRules[element['label']] = this.addToFinalRecord[element['key']];
                }
              });

              // If the user enabled custom location, automatically get the values from the address field.
              if (objRules['isCustomLocation']) {
                this.saveData(Object.assign(this.formService.objSaveData, {address: objRules['objAddress']}));
              }
              // If they selected site location, and we have a site address from the placeholder.
              else if (objRules['isSiteLocation'] != '' && objRules['objSiteAddress'] != undefined) {
                // set complete address text
                let strCompleteAddress = this.setCompleteAddress(objRules['objSiteAddress']);
                // Set Longitude and Latitude based on site address
                this.geoCoder.geocode({
                  'address': strCompleteAddress
                }, (results, status) => {
                  let strLatitude = '';
                  let strLongitude = '';
                  if (status == google.maps.GeocoderStatus.OK) {
                    if (results.length > 0) {
                      //set latitude, longitude
                      strLatitude = results[0].geometry.location.lat();
                      strLongitude = results[0].geometry.location.lng();
                    }
                  }
                  objRules['objSiteAddress']['latitude'] = strLatitude;
                  objRules['objSiteAddress']['longitude'] = strLongitude;
                  this.saveData(Object.assign(this.formService.objSaveData, {address: objRules['objSiteAddress']}));
                });
              }
              // If they didn't select a site, but has a customer and that customer has address.
              else if (objRules['isSiteLocation'] == '' && objRules['isCustomerLocation'] != '' && objRules['objCustomerAddress'] != undefined) {
                // set complete address text
                let strCompleteAddress = this.setCompleteAddress(objRules['objCustomerAddress']);
                // Set Longitude and Latitude based on site address
                this.geoCoder.geocode({
                  'address': strCompleteAddress
                }, (results, status) => {
                  let strLatitude = '';
                  let strLongitude = '';
                  if (status == google.maps.GeocoderStatus.OK) {
                    if (results.length > 0) {
                      //set latitude, longitude
                      strLatitude = results[0].geometry.location.lat();
                      strLongitude = results[0].geometry.location.lng();
                    }
                  }
                  objRules['objCustomerAddress']['latitude'] = strLatitude;
                  objRules['objCustomerAddress']['longitude'] = strLongitude;
                  this.saveData(Object.assign(this.formService.objSaveData, {address: objRules['objCustomerAddress']}));
                });
              }
              // If custom location is disabled and a site selected, just stay with the current address.
              else if (!objRules['isCustomLocation'] && objRules['isSiteLocation'] != '') {
                // FC-3519: add address when creating quote from job
                const objViewData = this.viewService.getViewRecord();
                this.saveData(Object.assign(this.formService.objSaveData, { address: objViewData.address }));
              } else if (
                // FC-4014: allow jobs without customer and site and custom location set to false to be saved
                this.strModule === 'jobs' && !objRules['isCustomLocation'] &&
                isEmpty(this.formService.objSaveData['customer_id']) &&
                isEmpty(this.formService.objSaveData['site_id'])
              ) {
                this.saveData(this.formService.objSaveData);
              } else {
                // Anythine else should trigger this error.
                this.notifService.sendNotification('address_required', 'select_site_or_custom', 'danger', 5000);
                this.bShowLoader = false;
                this.bSubmitted = false;
              }

            break;
            case 'users' :
              // If the user changed his own name and locale, also change his name in the localstorage
              if (this.localStorageService.getItem('user_id') != undefined && this.localStorageService.getItem('user_id') == this.strId) {

                let objBasicUserInfo = this.items['users'][0]['groups'].getRawValue();

                if (this.formService.objSaveData['locale'] != undefined) {
                  this.localStorageService.setItem('locale', objBasicUserInfo['locale']);
                }

                if (this.formService.objSaveData['first_name'] != undefined || this.formService.objSaveData['last_name'] != undefined) {
                  this.localStorageService.setItem('user_name', objBasicUserInfo['first_name'] + ' ' + objBasicUserInfo['last_name']);
                }
              }
              this.saveData(this.formService.objSaveData);
            break;
            case 'warehouses' :
              if (this.formService.objSaveData['type'] === 'vehicle') {
                this.formService.objSaveData['address'] = null;
              }
              this.saveData(this.formService.objSaveData);
            break;
            case 'activity_logs':
              this.formService.objSaveData['start_datetime'] = this.formService.objSaveData['log_date']['start_date'];
              this.formService.objSaveData['end_datetime'] = this.formService.objSaveData['log_date']['end_date'];
              delete this.formService.objSaveData['log_date'];
              this.saveData(this.formService.objSaveData);
            break;
            default:
              this.saveData(this.formService.objSaveData);
            break;
          }
        }
      } else {
        this.dialogClose('cancel')
      }

    } else {
      // This is validation for asset type dropdown field
      if (this.bFound.length != this.dropdownFieldCount) {
        let errorDefaultValueMessage = this.customTranslate.getTranslation('dropdown_default_value_error');
        let errorOptionMessage = this.customTranslate.getTranslation('dropdown_option_error');
        let strDropdownFieldError = this.strInvalidDropdownField.join(', ');
        let errorMessage = errorDefaultValueMessage + strDropdownFieldError + " " + errorOptionMessage;
        this.notifService.sendNotification('not_allowed', errorMessage , 'danger');
        this.bShowLoader = false;
        this.bSubmitted = false;
      } else {
        this.notifService.sendNotification('not_allowed', 'required_notice', 'danger');
        this.bShowLoader = false;
        this.bSubmitted = false;
      }
    }

  }

  /**
   * Checks if the given string is a valid email.
   * @param strEmail
   */
  isEmail(strEmail)
  {
      // Initialize variable.
      let isEmail;

      // Create regex object. This checks the format of the string.
      let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      // Use the regex and test the string.
      isEmail = emailRegex.test(strEmail);

      // Returns true if string is email, false if not.
      return isEmail;
  }

  saveData(objToSave) {
    // Remove readonly fields
    this.arReadOnlyId.forEach( strId => {
      // accounting sync details is readonly but need to update in server side. No need to delete
      if (strId != 'accounting_sync_detail') {
        delete objToSave[strId];
      }
    });

    // Initialize boolean for checking email validity.
    let bInvalidEmail = false;

    // If the field is an email that is JSON object type. (not string)
    if (typeof objToSave['email_address'] == 'object' && objToSave['email_address'].length > 0) {
      // Loop through the emails.
      objToSave['email_address'].forEach(email => {
        // Check the email if it's a valid email.
        if (!this.isEmail(email.email)) {
          // Mark the boolean to identify that one of the email address is invalid.
          bInvalidEmail = true;
        }
      });
    }

    // When opportunities is from widget in job record
    // we need to add this data as to link the job to opp
    let fromJobId = this.arrService.keyFallsBackTo(this.data.arData, 'from_job_id');
    if (this.strModule === 'opportunities' && fromJobId) {
      this.formService.objSaveData['from_job_id'] = fromJobId;
    }

    // If the email is invalid.
    if (bInvalidEmail) {
      this.bShowLoader = false;
      // Show a error informing user that one of the email address is invalid.
      this.notifService.sendNotification('oops', 'email_format_incorrect', 'warning');
    } else {
      // Show the loader to inform user that the saving is in progress.
      this.bShowLoader = true;

      if (objToSave['address'] != undefined && (objToSave['address']['latitude'] == '' || objToSave['address']['longitude'] == '') && this.strModule !== 'warehouses') {
        // Pop-up modal for confirmation
        this.notifService.sendConfirmation('confirm_save_data_with_geolocation')
          .subscribe( confirmation => {
            // If the user confirm to delete
            if (confirmation.answer) {
              // Save the changes in API.
              this.recordService.saveRecord(this.strModule, objToSave, this.strId)
              .pipe(
                finalize(() => this.bShowLoader = false)
              )
              .subscribe(
                data => {
                  // Store response body
                  let arResponse = data.body;

                  // Check if status is success 200 or created 201
                  if (data.status === StatusCode.kResponseSuccess || data.status === StatusCode.kResponseCreated) {
                    // Special case for contacts to link one or more customer/site.
                    if (this.strModule == 'contacts' && this.strMode != 'edit') {
                      // Data config for linking customer
                      let customerDataConfig = {
                        width: '1000px',
                        height: 'auto',
                        data: {
                          module: this.strModule,
                          module_id: data.body.id,
                          record: data.body,
                          relate_module: 'customers',
                          view_type: 'manage'
                        },
                        id: 'roleDialog'
                      }
                      // Data config for linking site
                      let siteDataConfig = {
                        width: '1000px',
                        height: 'auto',
                        data: {
                          module: this.strModule,
                          module_id: data.body.id,
                          record: data.body,
                          relate_module: 'sites',
                          view_type: 'manage'
                        },
                        disableClose: true,
                        panelClass: 'custom-dialog-container',
                        id: 'roleDialog'
                      }

                      //We tell the app component to open the confirmation.
                      this.notifService.sendConfirmation("link_contact_to_customer")
                        .subscribe(
                          confirmation => {
                            //If the user confirmed, open role dialog for customer
                            if (confirmation.answer) {
                              // Dialog to manage customer roles
                              let editFormDialog = this.dialog.open(EditRolesComponent, customerDataConfig);
                              editFormDialog.afterClosed().subscribe(
                                dialogResult => {
                                  // If the dialog is close. Send confirmation for linking site.
                                  this.notifService.sendConfirmation("link_contact_to_site")
                                    .subscribe(
                                      confirmation => {
                                        //If the user confirmed, open role dialog for site
                                        if (confirmation.answer) {
                                          // Dialog to manage site roles
                                          let editFormDialog = this.dialog.open(EditRolesComponent, siteDataConfig);
                                          editFormDialog.afterClosed().subscribe(
                                            dialogResult => {
                                              // If the dialog is close response the success of creating a contact.
                                              this.successNotification(arResponse,data.body);
                                          });
                                        } else {
                                          // If the user not confirmed, response the success of creating a contact.
                                          this.successNotification(arResponse,data.body);
                                        }
                                    });
                                });
                            } else {
                              // If the user not confirmed for linking the customer. Ask confirmation for linking the site.
                              this.notifService.sendConfirmation("link_contact_to_site")
                                .subscribe(
                                  confirmation => {
                                      //If the user confirmed, open role dialog for site
                                    if (confirmation.answer) {
                                      // Dialog to manage site roles
                                      let editFormDialog = this.dialog.open(EditRolesComponent, siteDataConfig);
                                      editFormDialog.afterClosed().subscribe(
                                        dialogResult => {
                                          // If the dialog is close response the success of creating a contact
                                          this.successNotification(arResponse,data.body);
                                        });
                                      } else {
                                        // If the user not confirmed, response the success of creating a contact.
                                        this.successNotification(arResponse,data.body);
                                      }
                                  });
                            }
                          });
                    } else {
                      // Notification for create success.
                      this.successNotification(arResponse,data.body);
                    }
                  } else {
                    this.bShowLoader = false;
                    if (data.status === StatusCode.kResponseAccepted) {
                      this.notifService.promptError(arResponse.error);
                    } else {
                      let strDefaultWarningMessage = (arResponse['message'] != undefined) ? arResponse['message'] : 'something_went_wrong';
                      this.customTranslate.initializeTransalateables([strDefaultWarningMessage]);
                      let strWarningMessage = this.customTranslate.getTranslation(strDefaultWarningMessage);

                      let arResponseMessage = (arResponse['error'] != undefined) ? arResponse['error'] : '';

                      if (arResponseMessage.length > 0) {
                        this.customTranslate.initializeTransalateables(arResponseMessage);

                        arResponseMessage.forEach( data => {
                          strWarningMessage += '<br>' + this.customTranslate.getTranslation(data);
                        });
                      }
                      // Notify user if the update is a success.
                      this.notifService.sendNotification('warning', strWarningMessage, 'warning', 6000);
                    }
                  }
                }
              );
            } else {
              this.bShowLoader = false;
              return false;
            }
        });
      } else {
        // FC-3386: Display confirmation prompt when cancelling a job.
        // In cases where the condition doesn't apply, proceed to saving of data.
        if (this.strModule === 'jobs' && objToSave['status'] === 'cancelled' && this.strMode === 'edit') {
          this.notifService.sendConfirmation('confirm_cancel_job').subscribe(confirmation => {
            if (confirmation.answer) {
              this.saveDataFinal(objToSave);
            } else {
              this.bShowLoader = false;
            }
          });
        } else if (
          this.strModule == 'users'
          && (this.formService.wasChanged(['status']) && objToSave['status'] === 'inactive')
          && this.strMode === 'edit'
        ) {
          let dialogRef = this.dialog.open(ReassignFutureTasksDialog, {
            disableClose: true,
            panelClass: ['p-2', 'fmc-dialog-md'],
            data: {
              exclude_user_id: this.strId
            }
          });

          dialogRef.afterClosed()
          .subscribe(result => {
            if (result.action !== Action.CANCELLED) {
              objToSave['reassign_user_id'] = result.action === Action.REASSIGN ? result.user_id : null;
              this.saveDataFinal(objToSave);
            } else {
              this.bShowLoader = false;
            }
          });
        } else {
          this.saveDataFinal(objToSave);
        }
      }
    }
  }


  /**
   * Proceed to saving data after completing confirmation prompts
   * @param objToSave
   */
  saveDataFinal(objToSave: object): void {
    // Save the changes in API.
    this.recordService.saveRecord(this.strModule, objToSave, this.strId)
    .pipe(
      finalize(() => this.bShowLoader = false)
    )
    .subscribe(
      data => {
        // Store response body
        let arResponse = data.body;

        // Check if status is success 200 or created 201
        if (data.status === StatusCode.kResponseSuccess || data.status === StatusCode.kResponseCreated) {
          // Special case for contacts to link one or more customer/site.
          if (this.strModule === 'contacts' && this.strMode !== 'edit' && this.bIsRoleForm === false) {
            // Data config for linking customer
            let customerDataConfig = {
              width: '700px',
              height: 'auto',
              data: {
                module: this.strModule,
                module_id: data.body.id,
                record: data.body,
                relate_module: 'customers',
                view_type: 'manage'
              },
              disableClose: true,
              id: 'roleDialog'
            }
            // Data config for linking site
            let siteDataConfig = {
              width: '700px',
              height: 'auto',
              data: {
                module: this.strModule,
                module_id: data.body.id,
                record: data.body,
                relate_module: 'sites',
                view_type: 'manage'
              },
              disableClose: true,
              id: 'roleDialog'
            }

            //We tell the app component to open the confirmation.
            this.notifService.sendConfirmation("link_contact_to_customer")
              .subscribe(
                confirmation => {
                  //If the user confirmed, open role dialog for customer
                  if (confirmation.answer) {
                    // Dialog to manage customer roles
                    let editFormDialog = this.dialog.open(EditRolesComponent, customerDataConfig);
                    editFormDialog.afterClosed().subscribe(
                      dialogResult => {
                        // If the dialog is close. Send confirmation for linking site.
                        this.notifService.sendConfirmation("link_contact_to_site")
                          .subscribe(
                            confirmation => {
                              //If the user confirmed, open role dialog for site
                              if (confirmation.answer) {
                                // Dialog to manage site roles
                                let editFormDialog = this.dialog.open(EditRolesComponent, siteDataConfig);
                                editFormDialog.afterClosed().subscribe(
                                  dialogResult => {
                                    // If the dialog is close response the success of creating a contact.
                                    this.successNotification(arResponse,data.body);
                                });
                              } else {
                                // If the user not confirmed, response the success of creating a contact.
                                this.successNotification(arResponse,data.body);
                              }
                          });
                      });
                  } else {
                    // If the user not confirmed for linking the customer. Ask confirmation for linking the site.
                    this.notifService.sendConfirmation("link_contact_to_site")
                      .subscribe(
                        confirmation => {
                            //If the user confirmed, open role dialog for site
                          if (confirmation.answer) {
                            // Dialog to manage site roles
                            let editFormDialog = this.dialog.open(EditRolesComponent, siteDataConfig);
                            editFormDialog.afterClosed().subscribe(
                              dialogResult => {
                                // If the dialog is close response the success of creating a contact
                                this.successNotification(arResponse,data.body);
                              });
                            } else {
                              // If the user not confirmed, response the success of creating a contact.
                              this.successNotification(arResponse,data.body);
                            }
                        }
                      );
                  }
                });
          } else {
            // Notification for create success.
            this.successNotification(arResponse,data.body);
          }
        } else {
          this.bShowLoader = false;
          if (data.status === StatusCode.kResponseAccepted) {
            this.notifService.promptError(arResponse.error);
          } else {
            let strDefaultWarningMessage = (arResponse['message'] != undefined) ? arResponse['message'] : 'something_went_wrong';
            this.customTranslate.initializeTransalateables([strDefaultWarningMessage]);
            let strWarningMessage = this.customTranslate.getTranslation(strDefaultWarningMessage);
            let arResponseMessage = (arResponse['error'] != undefined) ? arResponse['error'] : '';

            if (arResponseMessage.length > 0) {
                this.customTranslate.initializeTransalateables(arResponseMessage);

                arResponseMessage.forEach( data => {
                  strWarningMessage += '<br>' + this.customTranslate.getTranslation(data);
              });
            }

            // Notify user if the update is a success.
            this.notifService.sendNotification('warning', strWarningMessage, 'warning', 6000);
          }
        }
      },
      err => {
        // Turn off the loader on error.
        this.bShowLoader = false;
      }
    );
  }

  successNotification(arResponse, data) {
    let createLink = '';
    if (this.strMode != 'edit') {

      let strCurrentUrl = window.location.href;
      let strAdmin = (!isEmpty(strCurrentUrl.match('#\/admin\/'))) ? 'admin/' : '';
      let strStyle = 'class="notification-link"';

      //Creates the link with the right url and translations.
      if (this.bCustomEditView) {
        createLink = this.customTranslate.getTranslation('record_create_success') + '&nbsp;' + this.translate.instant('to_view') + ',<a ' + strStyle + ' href="' + environment.app_url + '/#/' + strAdmin + this.strModule + "/edit/" + arResponse['id'] +'">&nbsp;' + this.translate.instant('click_here') + '</a>'
      } else {
        createLink = this.customTranslate.getTranslation('record_create_success');
      }

      // If module has no view
      if (this.bViewLink) {
        // Remove link
        createLink = this.customTranslate.getTranslation('record_create_success');
      }
    }

    // Show notification save success
    if (this.strModule === 'pricebooks') {
      this.notifService.notifySuccess('record_create_success');
    } else {
      this.notifService.sendNotification(
        (this.strMode == 'edit') ? 'updated' : 'created',
        (this.strMode == 'edit') ? arResponse.toString() : createLink,
        'success',
        (this.strMode == 'edit') ? 2000 : 5000
      );
    }

    // Redirect to listing.
    this.dialogClose("save", data);
  }
  /**
   * To set complete address to string
   * @param objAddress - Data to complete address as string
   */
  setCompleteAddress(objAddress) {
    let strCompleteAddress = '';
    if (objAddress['street'] != '') {
      strCompleteAddress += objAddress['street'] + " ";
    }
    if (objAddress['city'] != '') {
      strCompleteAddress += objAddress['city'] + " ";
    }
    if (objAddress['zip'] != '') {
      strCompleteAddress += objAddress['zip'] + " ";
    }
    if (objAddress['state'] != '') {
      strCompleteAddress += objAddress['state'] + " ";
    }
    if (objAddress['country'] != '') {
      strCompleteAddress += objAddress['country'] + " ";
    }

    return strCompleteAddress;
  }

  /**
   * Triggered when job template id is changed.
   * Logic for auto populate value of job
   * based on the job template
   *
   * @param objJobTemplateIdFormGroup
   * @param objJobTemplateIdFormField
   */
  jobTemplateChange(objJobTemplateIdFormGroup: FormGroup, objJobTemplateIdFormField: LooseObject) {
    objJobTemplateIdFormGroup['controls']['job_template_id'].valueChanges.subscribe(
      strJobTemplateId => {
        objJobTemplateIdFormField['is_loading'] = true;

        let arFilter: any = { 'job_templates.id': strJobTemplateId };

        if (!isEmpty(strJobTemplateId)) {
          this.recordService.getRecordRelate('job_templates', '', '', false, arFilter, 10, false).subscribe(arResult => {
            let arValue = (arResult[0] != undefined) ? arResult[0] : [];
            let dueDate = this.dateService.convertPeriodToDate(arValue['due_date']);

            this.doSomethingInParent(dueDate);

            let intJobTypeIndexForm = this.getFormFieldIndex('type');
            let intBillableIndexForm = this.getFormFieldIndex('billable');
            let intInvoicingTypeIndexForm = this.getFormFieldIndex('invoicing_type');

            this.fieldPatchValue('due_date', dueDate);
            this.fieldPatchValue('priority', arValue['priority']);
            this.fieldPatchValue('job_summary', arValue['job_summary']);
            this.fieldPatchValue('internal_notes', arValue['internal_notes']);

            this.fieldPatchValue('type', arValue['type']);
            this.items[this.strModule][intJobTypeIndexForm]['groups']['controls']['type'].updateValueAndValidity();

            this.fieldPatchValue('billable', arValue['billable']);
            this.items[this.strModule][intBillableIndexForm]['groups']['controls']['billable'].updateValueAndValidity();

            this.fieldPatchValue('invoicing_type', arValue['invoicing_type']);
            this.items[this.strModule][intInvoicingTypeIndexForm]['groups']['controls']['invoicing_type'].updateValueAndValidity();

            this.parentValue = {
              value: dueDate,
              control_type: 'date',
              field_name: 'due_date',
            }

            objJobTemplateIdFormField['is_loading'] = false;
          });
        } else {
          objJobTemplateIdFormField['is_loading'] = false;
        }
        // We need to call markForCheck. To be able to reflect the changes made.
        this.changeDetectorRef.markForCheck();
    });
  }

  /**
   * Get index of form
   * @param strField
   */
   getFormFieldIndex(strField: string) {
    return this.items[this.strModule].findIndex(attr => (attr.groups.get(strField) != undefined && attr.groups.get(strField) != null));
  }

  /**
   * Get index of field
   * @param strField
   * @param form
   */
  getFieldIndex(strField: string, objForm: LooseObject) {
    if (objForm != undefined && objForm['fields'] != undefined) {
      return objForm['fields'].findIndex( attr => (attr['key'] == strField));
    } else {
      return -1;
    }
  }

  /**
   * Set value of field
   * @param strField
   * @param strValue
   */
  fieldPatchValue(strField: string, strValue: string) {
    let intIndexOfFormField = this.getFormFieldIndex(strField);

    if (intIndexOfFormField > -1) {
      this.items[this.strModule][intIndexOfFormField]['groups'].patchValue({
        [strField] : strValue,
      }, {emitEvent: false, onlySelf: true});
    }
  }

  /**
   * This will set a value for parent to pass
   * to the child component
   * @param value
   */
   setValueFromParent(value: any) {
    // We need to clear the last value of parent.
    this.parentValue = value;
  }

  /**
   * Format the metadata's default value
   *
   * @param objUsedFields
   */
  formatMetadataDefaultValue(objUsedFields) {
    if (this.strModule === "assets") {
      Object.keys(objUsedFields).forEach( field_key => {
        if (field_key === 'attributes') {
          var currentDefaultValue = cloneDeep(this.arResult['record_details'][field_key]);
          var assetTypeAttributes = this.arResult["record_details"]["asset_type_attributes"];
          if (assetTypeAttributes) {
            var assetAttributes = assetTypeAttributes.map( item => {
              item.default_value = currentDefaultValue[item.key];
              return item;
            })
            objUsedFields[field_key].default_value = assetAttributes;
          }
        }
      });
    }
    return objUsedFields;
  }

  /**
   * Removes the "Flexible Invoicing" option under "Billing Type" if
   * the user's client is under the starter plan.
   *
   * @todo Need to refactor this part from here, but will
   * leave as to do for now since it seems virtually impossible.
   *
   * @param results
   *
   * @returns {void}
   */
  protected removeFlexibleInvoicingWhenOnStarterPlan(results: LooseObject): void {
    let invoicingType = results['used_fields']['invoicing_type'];

    if (invoicingType !== undefined &&
      invoicingType['options'] !== undefined &&
      isArray(invoicingType['options']) &&
      this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ADVANCED_PLAN) === false
    ) {
      results['used_fields']['invoicing_type']['options'] = invoicingType['options'].filter(option => option.id !== 'flexible_invoicing');
    }
  }

  /**
   * Removes the "Project" option under "Job Type" if the user's client
   * is under the starter plan.
   *
   * @todo Need to refactor this part from here, but will
   * leave as to do for now since it seems virtually impossible.
   *
   * @param results
   *
   * @returns {void}
   */
  protected removeProjectFromJobTypesWhenOnStarterPlan(results: LooseObject): void {
    let jobType = results['used_fields']['type'];

    if (jobType !== undefined &&
      jobType['options'] !== undefined &&
      isArray(jobType['options']) &&
      this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ADVANCED_PLAN) === false
    ) {
      results['used_fields']['type']['options'] = jobType['options'].filter(option => option.id !== 'project');
    }
  }

  /**
   * Removes certain fields from the edit form if the user's client
   * is not under the enterprise plan.
   *
   * @todo Need to refactor this part from here, but will
   * leave as to do for now since it seems virtually impossible.
   *
   * @param results
   *
   * @returns {void}
   */
  protected removeFieldsFromEditFormUnlessOnEnterprisePlan(results: LooseObject): void {
    this.arEnterpriseFields.forEach(strEnterpriseField => {
      let objField = results['used_fields'][strEnterpriseField];

      if (objField !== undefined && this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN) === false) {
        delete results['used_fields'][strEnterpriseField];

        results['record_view'].forEach((objRecordView, strIndex)=> {
          results['record_view'][strIndex]['fields'].forEach((strFields, strFieldIndex) => {
            let strEnterpriseFieldIndex = results['record_view'][strIndex]['fields'][strFieldIndex].indexOf(strEnterpriseField);

            if (strEnterpriseFieldIndex !== -1) {
              results['record_view'][strIndex]['fields'][strFieldIndex].splice(strEnterpriseFieldIndex, 1);
            }
          });
        });
      }
    });
  }

  /**
   * method called the arrange form data and fields pre hook
   *
   * @param {ModuleFormTab[]} tabs
   *
   * @returns {void}
   */
  protected preDataArrangement(tabs: ModuleFormTab[]): void {
    if (this.strModule === 'customers') {
      tabs.forEach((tab: ModuleFormTab) => {
        let typeField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'type');

        if (typeField !== undefined) {
          let isOrganization: boolean = (typeField.default_value === 'organization');
          let firstNameField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'first_name');
          let lastNameField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'last_name');
          let fullNameField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'name');
          let isCustomerField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'is_customer');
          let isSupplierField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'is_supplier')
          let createJobEmailAddressField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'create_job_email_address');
          let createSupplierInvoiceEmailAddressField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'create_supplier_invoice_email_address')

          if (firstNameField !== undefined) {
            firstNameField.required = (! isOrganization);
            firstNameField.is_hidden = (isOrganization);
          }

          if (lastNameField !== undefined) {
            lastNameField.required = (! isOrganization);
            lastNameField.is_hidden = (isOrganization);
          }

          if (fullNameField !== undefined) {
            fullNameField.readonly = (! isOrganization);
            fullNameField.required = (isOrganization);
          }

          if (isCustomerField && isCustomerField['default_value'] && createJobEmailAddressField) {
            createJobEmailAddressField.is_hidden = false;

            if (
              isEmpty(createJobEmailAddressField['default_value']) &&
              this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN)
            ) {
              createJobEmailAddressField.default_value = this.generateCreateJobOrSupplierInvoiceEmail('is_customer');
            }

            // If client plan is not enterprise, display blank 'create_job_email_address'_value
            if (!this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN)) {
              createJobEmailAddressField.default_value = '';
            }
          }

          if (isSupplierField && isSupplierField['default_value'] && createSupplierInvoiceEmailAddressField) {
            createSupplierInvoiceEmailAddressField.is_hidden = false;

            if (
              isEmpty(createSupplierInvoiceEmailAddressField['default_value']) &&
              this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN)
            ) {
              createSupplierInvoiceEmailAddressField.default_value = this.generateCreateJobOrSupplierInvoiceEmail('is_supplier');
            }

            // If client plan is not enterprise, display blank 'create_supplier_invoice_email_address'_value
            if (!this.subscriptionRestrictionService.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ENTERPRISE_PLAN)) {
              createSupplierInvoiceEmailAddressField.default_value = '';
            }
          }
        }
      });
    } else if (this.strModule === 'assets') {
      tabs.forEach((tab: ModuleFormTab) => {
        let isRentalField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'is_rental');

        if (isRentalField !== undefined) {
          let isRental: boolean = isRentalField.default_value;
          let expectedDateReturnField: Form<any> = tab.fields.find((input: Form<any>) => input.key === 'expected_date_return');

          if (expectedDateReturnField !== undefined) {
            expectedDateReturnField.is_hidden = (! isRental);
          }
        }
      });
    }
  }

  /**
   * sets the current form control rules
   *
   * @param   {Form<any>} field
   * @param   {AbstractControl} control
   *
   * @returns {void}
   */
  protected setControlValidator(field: Form<any>, control: AbstractControl): void {
    let rules = [];

    if (field.required) {
      rules.push(Validators.required);
    }

    control.setValidators(rules);
    control.updateValueAndValidity();
  }

  /**
   * This will check if the given field
   * is exist in used fields
   * @param usedFields
   * @param field
   * @returns
   */
  protected checkUsedFieldExist(usedFields: [] | {}, field: string) {
    return this.arrService.keyFallsBackTo(usedFields, field, false);
  }

  protected computeUnitPrice(markup: number, unitCost: number)
  {
    let unitPrice = unitCost + ((markup / 100) * unitCost);

    return !isNaN(unitPrice) && isFinite(unitPrice) ? unitPrice : 0;
  }

  protected computeMarkup(unitPrice: number, unitCost: number)
  {
    let markup = ((unitPrice - unitCost) / unitCost) * 100;

    return !isNaN(markup) && isFinite(markup) ? markup : 0;
  }

  /**
   * Set the currently active tab.
   *
   * @param {string} tab
   */
  private setTab(strTab?: string): void {
    if (!this.strActiveTab) {
      this.strActiveTab = this.items[this.strModule][0]['label'];
    } else {
      this.strActiveTab = strTab;
    }
  }

  private _initializeFieldsAvailability(): void {
    const tabs = get(this.items, this.strModule, []);

    if (blank(tabs)) {
      return;
    }

    const forms: FormGroup[] = tabs.map(tab => tab.groups);

    for (const tab of tabs) {
      const form: FormGroup = get(tab, "groups");

      this._subscriptions.push(
        form.valueChanges.subscribe(() => {
          this._toggleFieldAvailability({
            form: form,
            groups: forms,
            fields: tab.fields,
          });
        })
      );

      /// initialize
      this._toggleFieldAvailability({
        form: form,
        groups: forms,
        fields: tab.fields,
      });
    }
  }

  private _toggleFieldAvailability(opts: {
    form: FormGroup,
    groups: FormGroup[],
    fields: Form<any>[],
  }) {
    const { fields, groups } = opts;
    const values: Record<string, any> = groups.reduce(
      (acc, group) => Object.assign(acc, group.value),
      {}
    );

    for (const field of fields) {
      const condition = field.depends_on;

      if (blank(condition)) {
        continue;
      }

      /// condition is in the format of `principal_field:operator:expected_value[:action]`
      /// where `action` can be `hide` or `readonly`
      const [principalAttributeName, operator, expectedPrincipalValue, action = 'hide'] = split(
        condition,
        ':'
      );

      const principalField = fields.find((field) => field.key == principalAttributeName);

      /// ignore if the principal field where not part of all the fields for this form
      /// this is necessary to identify how we will parse the expected value
      /// for example if the value is a boolean we will turn the 1 to true and 0 to false
      if (blank(principalField)) {
        continue;
      }

      /// this will contain the parsed principal value
      let parsedExpectedPrincipalValue: any = expectedPrincipalValue;

      switch (principalField.controlType) {
        case 'checkbox':
          parsedExpectedPrincipalValue = !!parseInt(expectedPrincipalValue);
          break;
      }

      /// now compare if the principal value is equal to the expected value
      const currentPrincipalValue = values[principalAttributeName];

      /// toggle the dependent field visibility
      /// based on the condition and the current value of the principal field
      if (operator == 'neq') {
        field.toggleFieldAvailability(currentPrincipalValue == parsedExpectedPrincipalValue, {
          action: action as AvailabilityAction,
        });
      } else if (operator == 'eq') {
        field.toggleFieldAvailability(currentPrincipalValue != parsedExpectedPrincipalValue, {
          action: action as AvailabilityAction,
        });
      } else if (operator == 'lt' || operator == 'lte') {
        field.toggleFieldAvailability(toFormattedNumber(currentPrincipalValue) > toFormattedNumber(parsedExpectedPrincipalValue), {
          action: action as AvailabilityAction,
        });
      } else if (operator == 'gt' || operator == 'gte') {
        field.toggleFieldAvailability(toFormattedNumber(currentPrincipalValue) < toFormattedNumber(parsedExpectedPrincipalValue), {
          action: action as AvailabilityAction,
        });
      }
    }
  }
}

export interface ModuleFormTab {
  label: string,
  fields: Form<any>[],
  id: string
}

export interface ParentValue {
  value: any,
  control_type: string,
  field_name: string,
}
