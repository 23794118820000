import { AuthorizedClientData } from './api-response-body';

export class AuthorizedClient {
  /**
   * @see {AuthorizedClientData['id']}
   */
  readonly id = this.data.id;

  /**
   * @see {AuthorizedClientData['client_id']}
   */
  readonly client_id = this.data.client_id;

  /**
   * @see {AuthorizedClientData['name']}
   */
  readonly name = this.data.name;

  /**
   * @see {AuthorizedClientData['permissions']}
   */
  readonly permissions = this.data.permissions;

  /**
   * Instance of the authorized data
   *
   * @param {AuthorizedClientData} data
   */
  constructor(
    protected readonly data: AuthorizedClientData
  ) {}
}