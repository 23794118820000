<div>
  <div class="row">
    <div class="col-9">
      <h5>{{ 'quote_details' | translate }}</h5>
    </div>
    <div class="col-3 text-right p-0">
      <button id="editQuoteBtn" class="btn btn-outline-primary btn-block" (click)="emitEditModeStatus()">
        {{ 'edit' | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'opportunity_number' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          <ng-container
            *ngTemplateOutlet="quoteDetails && quoteDetails.id ? quoteLink : noLinkRelate; context: {
              id: quoteDetails.id,
              text: quoteDetails.opportunity_number
            }">
          </ng-container>
          <ng-template #quoteLink let-id="id" let-text="text">
            <a class="pointer dialog-link" id="quoteNumber" href="{{ createUrl('opportunities', id) }}">{{ text | placeholdWithString }}</a>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'opportunity_name' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts h-100">
          <span>
            {{ quoteDetails.opportunity_name | placeholdWithString }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'customer' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts h-100">
          <ng-container
            *ngTemplateOutlet="quoteDetails && quoteDetails.customer_id ? customerLink : noLinkRelate; context: {
              id: quoteDetails.customer_id,
              text: quoteDetails.customer_text
            }"
          ></ng-container>
          <ng-template #customerLink let-id="id" let-text="text">
            <a class="pointer dialog-link" id="quoteCustomer" href="{{ createUrl('customers', id) }}">{{ text | placeholdWithString }}</a>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'site_address' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text h-100">
          <ng-container
            *ngTemplateOutlet="quoteDetails && quoteDetails.site_id ? siteLink : noLinkRelate; context: {
              id: quoteDetails.site_id,
              text: quoteDetails.site_text
            }"
          ></ng-container>
          <ng-template #siteLink let-id="id" let-text="text">
            <a class="pointer dialog-link" id="quoteSite" href=" {{ createUrl('sites', id) }}">{{ text | placeholdWithString }}</a>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'contact' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts long-text h-100">
          <ng-container
            *ngTemplateOutlet="quoteDetails && quoteDetails.contact_id ? contactLink : noLinkRelate; context: {
              id: quoteDetails.contact_id,
              text: quoteDetails.contact_text
            }"
          ></ng-container>
          <ng-template #contactLink let-id="id" let-text="text">
            <a class="pointer dialog-link" id="quoteContact" href=" {{ createUrl('contacts', id) }}">{{ text | placeholdWithString }}</a>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'amount' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          <span>
            {{ quoteDetails.amount | currency | placeholdWithString }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'forecast_close_date' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          <span>
            {{ quoteDetails.forecast_close_date | date:'mediumDate' | placeholdWithString }}
          </span>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'date_follow_up' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          <span>
            {{ quoteDetails.date_follow_up | date:'medium' | placeholdWithString }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="record-labels">{{ 'stage' | translate }}</label>
        <div class="form-control-plaintext border-bottom form-control-sm pl-2 record-texts">
          <span>
            {{ quoteDetails.stage | placeholdWithString | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noLinkRelate let-text="text">
  <span>{{ text | placeholdWithString }}</span>
</ng-template>