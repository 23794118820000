import { Component, Inject, OnInit } from '@angular/core';
import { RecordService } from '../../../../services/record.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LooseObject } from '../../../../objects/loose-object';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit {

  /**
   * Contains list of all related products.
   *
   * @var {LooseObject}
   */
  arRelatedProducts: LooseObject = [];

  /**
   * Flag if there are items that have
   * already been selected.
   *
   * @returns {boolean}
   */
  get bHasSelectedItems() {
    return this.arRelatedProducts.filter(item => {
      return item.selected
    }).length > 0;
  }

  constructor(
    public dialogRef: MatDialogRef<RelatedProductsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.arRelatedProducts = data['related_products'].map(item => {
      return {...item, ...{selected: false, expanded: false}}
    });
  }

  ngOnInit() {
  }

  /**
   * Passes the selected products to the dialog.
   *
   * @returns {void}
   */
  addProducts(): void {
    this.dialogRef.close(this.arRelatedProducts.filter(item => item.selected === true).map(item => {
      return item;
    }));
  }

}
