
    <div class="row">
      <div class="col-12 mb-2 text-right">
      <browse-products
        [bSearchable]="false"
        [bAllowCustom]="false"
        [bHideQuantity]="true"
        (objProductSelected)="addItem($event)">
      </browse-products>
      </div>
      <div class="col-12">
        <table
          class="table table-bordered">
          <thead>
            <tr>
              <th colspan="5" class="text-center fmc-input-label">{{ 'related_products' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-template #emptyItemCodesMessage>
              <tr>
                <td class="text-center text-muted" colspan="4">{{ 'no_related_products' | translate }}</td>
              </tr>
            </ng-template>
            <ng-container *ngIf="arItems.length > 0; else emptyItemCodesMessage">
              <tr *ngFor="let item of arItems; let i = index;">
                <td class="form-group" width="20%">
                  <div><b>{{ 'item_code' | translate }}</b></div>
                  <div>{{ item['item_code'] | translate }}</div>
                </td>
                <td class="form-group" width="20%">
                  <div><b>{{ 'item_name' | translate }}</b></div>
                  <div>{{ item['item_name'] | translate }}</div>
                </td>
                <td class="form-group">
                  <div><b>{{ 'description' | translate }}</b></div>
                  <div>{{ item['description'] | translate | slice:0:128 }}</div>
                </td>
                <td class="form-group">
                  <button
                    id="remove_additional_code_{{ i }}"
                    type="button"
                    class="btn btn-link text-danger"
                    (click)="removeItem(i)"
                    matTooltip="{{ 'remove' | translate }}">
                    <fa-icon [icon]="['fal', 'minus-circle']"></fa-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  