
import { Component, OnInit  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from './auth/auth.service';
import { ClientStoreService } from './services/client-store.service';
import { ChargebeeService } from './services/chargebee/chargebee.service';
import { LocalStorageService } from './services/local-storage.service';
import { CustomTranslateService } from './services/custom-translate.service';
import { RecordService } from './services/record.service';

import { environment } from '../environments/environment';
import { MessagingService } from './services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    TranslateService,
    CustomTranslateService
  ],
})
export class AppComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public translate: TranslateService,
    public clientStoreService: ClientStoreService,
    public chargebeeService: ChargebeeService,
    protected localStorageService: LocalStorageService,
    private customTranslate: CustomTranslateService,
    private router: Router,
    private titleService: Title,
    private messagingService: MessagingService,
    private recordService: RecordService,
  ) {
    // Adds supported languages of the app
    translate.addLangs(["en", "es", "fil"]);

    // Sets supported languages to array
    let arAvailableLanguage = translate.getLangs();
    // Get current language from database (eg. when language is changed in mobile, reflect changes in desktop)
    this.recordService.getRecord('users', this.localStorageService.getItem('user_id')).subscribe(data => {
      let strLanguage = data['record_details'].locale;

      // Checks if language is supported by the app
      if (!arAvailableLanguage.includes(strLanguage)) {
        // Set default language to english
        strLanguage = 'en';
      }

      // Update user locale in local storage
      this.localStorageService.setItem('user_locale', strLanguage);
      // Sets app current language
      translate.use(strLanguage);
    });

    // Check first if the callback passed has invalid format
    this.reconstructRedirectUrl(window.location.href);
  }

  ngOnInit() {
    window['loading_screen'].finish();

    // Handle authentication redirect if it exists
    this.auth.handleAuthentication();
    this.chargebeeService.initSubscriptionInfoWhenAdmin();
    window['loading_screen'].finish();

    // Updates the page title (of main modules) based on route changes
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event : NavigationEnd) => {
          if (event.url !== undefined) {
            // URL Without the query param
            const url = this.router.serializeUrl(this.router.createUrlTree([]));
            return this.titleService.setTitle(this.translatePageTitle(this.getTitlePage(url)))
          }
        }
      );

    this.messagingService.initializePushNotification();
  }

  /**
   * Checks if the response redirect url from AUTH0 service has invalid format
   * Eg. http://localhost:4200/#access_token=blabla...
   *
   * @param responseRedirectUrl
   * @returns void
   */
  reconstructRedirectUrl(responseRedirectUrl) : void {

    // The response url (which invalidated the format)
    let currentRedirectUrl = environment.app_url + '/#access_token';

    // If the redirect url contains the invalid format,
    // get the correct angular redirect route and append the original data
    if (responseRedirectUrl.includes(currentRedirectUrl)) {

      let correctAngularRedirectRoute = '/account/callback?access_token';
      let reconstructedRedirectUrl = correctAngularRedirectRoute + responseRedirectUrl.slice(currentRedirectUrl.length);

      this.router.navigateByUrl(reconstructedRedirectUrl)
    }
  }

   /*
   * Translates the current page title based on the user's locale and add 'Fieldmagic -' at the start of each title.
   * If page title is not set, default it to 'Fieldmagic'
   *
   * @param strPageTitle
   * @returns string
   */
  translatePageTitle(strPageTitle): string {

    // FC-2549: Move the translatables from ngOnInit to this method
    // to initialize the it everytime this method is called
    this.ngOnInitTranslatables();
    if (strPageTitle !== undefined) {
      return 'Fieldmagic - ' + this.customTranslate.getTranslation(strPageTitle)
    }
    return this.customTranslate.getTranslation('Fieldmagic')
  }

  /**
   * Gets the current url of the page and strip it to return the current module name
   *
   * @param strUri
   * @returns string
   */
  getTitlePage(strUri): string {
    if (strUri !== undefined) {
      let arOfSplittedUrl = strUri.split('/');
      return arOfSplittedUrl[1]
    }
  }

  /**
   * Initialize translatables
   */
  ngOnInitTranslatables(): void {
    this.customTranslate.initializeTransalateables([
      'admin',
      'leads',
      'customers',
      'sites',
      'contacts',
      'jobs',
      'assets',
      'opportunities',
      'customer_invoices',
      'purchase_orders',
      'supplier_invoices',
      'dashboard',
      'calendar',
      'schedule_recurring_jobs',
      'recurring_invoices',
      'site_service_summary_report',
      'activities',
      'error',
      'account',
      'shared_location',
      'recurring_jobs',
      'recurring-invoices',
      'stock_levels',
      'support',
      'items',
      'timesheets'
    ]);
  }
}
