<app-error-handler onlyFor="stock_levels:list">
  <section>
    <div class="row">
      <div class="col-sm-12">
        <app-information strModule="stock_levels"
          [isPanel1Visible]= "true"
          [isPanel2Visible]= "false"
          [isPanel3Visible]= "false" >
        </app-information>
      </div>
      <div class="col-sm-12">
        <app-listing strModule="stock_levels" [objTableHeader]="objTableHeaderData">

          <ng-template #list_actions>

            <div class="dropdown-item pl-1">
              <p class="mb-0">
                <a id="hrefDialog" matTooltip="{{ 'reorder_info' | translate }}" class="pointer" [routerLink]="['reorder']">
                  <fa-icon [icon]="['fas', 'box-open']"></fa-icon>  {{'reorder' | translate }}
                </a>
              </p>
            </div>

            <div class="dropdown-item pl-1">
              <p class="mb-0">
                <a id="aStocktakeOpen" matTooltip="{{ 'stocktake_info' | translate }}" class="pointer" [routerLink]="['stocktake']">
                  <fa-icon [icon]="['fas', 'people-carry']"></fa-icon> {{ 'create_stocktake' | translate }}
                </a>
              </p>
            </div>

          </ng-template>

        </app-listing>
      </div>
    </div>
  </section>
</app-error-handler>
