import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ListingService } from '../../../services/listing.service';
import { RecordService } from '../../../services/record.service';
import { ViewService } from '../../../services/view.service';
import { NotificationService } from '../../../services/notification.service';
import { AddTimeEntryComponent } from './add-time-entry/add-time-entry.component';
import * as moment from 'moment';
import { concat } from 'rxjs/observable/concat';
import { Observable } from 'rxjs/Observable';
import { Select } from '../../../objects/select';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { LooseObject } from '../../../objects/loose-object';
import { EditInvoiceComponent } from '../customer-invoices/edit-invoice/edit-invoice.component';

@Component({
  selector: 'app-time-entry',
  templateUrl: './time-entry.component.html',
  styleUrls: ['./time-entry.component.scss'],
  providers: [ListingService]
})
export class TimeEntryComponent implements OnInit {

  @Input() strRecordId : string;
  @Input() strModule: string;

  public selectDate: string;
  public selectType: string;
  public selectSort: string;

  public arTimeEntryList = [];
  public arPreviousPages = {};
  public bLoading = false;
  public strFilterUser: string;
  public strFilterLabor: string;
  public arNgSelectFieldKey: any = [];
  public arNgSelectFields =
    { items : {
      obv: new Observable<Select[]>(),
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'select_item',
      name: 'item_id',
      module: 'items',
      value: null,
      filter: {
        labor: true
      },
    },
    assigned_user: {
      obv: new Observable<Select[]>(),
      typehead: new Subject<string>(),
      loader: false,
      placeholder: 'select_user',
      name: 'user_id',
      module: 'users',
      value: null,
    }
  };

  constructor(
    private dialog: MatDialog,
    public listService: ListingService,
    public recordService: RecordService,
    public notificationService: NotificationService,
    public viewService: ViewService,
  ) {
    // Initialize form control fields.
    this.arNgSelectFieldKey = Object.keys(this.arNgSelectFields);
    // this.getRelateField();
  }

  ngOnInit() {
    // Get the list on page initialization.
    this.fetchList('default');
  }

  /**
   * Open the create quote dialog.
   */
  createTimeEntry() {

    // Create the object to be passed inside the dialog.
    let objData = {
      width: '1200px',
      height: 'auto',
      // The id of the opporunity, the quote's "parent".
      data: {
        record_id : this.strRecordId,
        view_type : 'add',
        set_default_task: true,
      },
      disableClose: true
    };

    // Initialize the dialog.
    let tabDialogRef = this.dialog.open(AddTimeEntryComponent, objData);

    // When the dialog closes, reload the list.
    tabDialogRef.afterClosed().subscribe(item => {
      if (item != undefined && item == 'save') {
        // this.getRelateField();
        // Show notification that activity has been created.
        this.notificationService.notifySuccess('header_notification.success_added');
        this.filterData(null, null);
        this.viewService.reloadRecordView(item);
      }
    })
  }

  /**
   * Opens the selected quote from the list.
   * @param objTimeEntry - the quote data.
   */
  openTimeEntry(objTimeEntry) {
      // Object to be passed in the dialog.
      let objData = {
        width: '1200px',
        height: 'auto',
        // Data to be passed on
        data: {
          // Put the opportunity_id and quote data to the dialog.
          record_id : this.strRecordId,
          time_entry: [],
          time_entry_id: objTimeEntry['id'],
          view_type : 'edit'
        },
        disableClose: true
      };
      // Instantiate dialog.
      let tabDialogRef = this.dialog.open(AddTimeEntryComponent, objData);

      // Reload quote list once the dialog is closed.
      tabDialogRef.afterClosed().subscribe(item => {
        // Only when the user hits save will we reload the list.
        if (item != undefined && item == 'save') {
          // this.getRelateField();
          // Show notification that activity has been created.
          this.notificationService.notifySuccess('header_notification.success_update');
          this.filterData(null, null);
          this.viewService.reloadRecordView(item);
        }
      });
  }

  /**
   * Fetch the list of quote.
   * @param strPage - what page is currently to be viewed.
   */
  fetchList(strPage) {

    let objPagination = this.listService.beforeFetching(strPage);
    let arWhereClause = {
      'job_id' : this.strRecordId
      // 'order_by': {id: 'start_time', sort: 'desc'}
    }
    this.bLoading = true;
    // Get the list from API.
    this.listService.fetchData(JSON.stringify(objPagination['objPage']), 'time_entries', JSON.stringify(arWhereClause)).subscribe(response => {

      this.arTimeEntryList = response['data'];

      this.listService.afterFetching(response, strPage);

      this.bLoading = false;

    });
  }

  /**
   * Let's format the datetime value.
   * @param date
   */
  formatDate(strDate, arCustomConfig = []) {

    // Convert datetime to utc
    let utcTime = moment.utc(strDate).toDate();
    // Convert to local time zone and display
    return moment(utcTime).local().format('lll');
  }

  // Search through time Entry
  filterData(event, currentFilter) {

    let arWhereClause = {
      'job_id' : this.strRecordId,
      'order_by': {id: 'start_time', sort: 'desc'}
    }

    if (event != undefined) {
      if (currentFilter == 'user') {
        this.strFilterUser = event.id
      }

      if (currentFilter == 'item') {
        this.strFilterLabor = event.id;
      }
      arWhereClause['user_id'] = this.strFilterUser;
      arWhereClause['item_id'] = this.strFilterLabor;

    } else {

      if (currentFilter == 'user') {
        this.strFilterUser = '';
      }

      if (currentFilter == 'item') {
        this.strFilterLabor = '';
      }

      if (this.strFilterUser != '') {
        arWhereClause['user_id'] = this.strFilterUser;
      }

      if (this.strFilterLabor != '') {
        arWhereClause['item_id'] = this.strFilterLabor;
      }
    }


    let objPagination = this.listService.beforeFetching('default');

    this.bLoading = true;
    this.arTimeEntryList = [];

    // Get the list from API.
    this.listService.fetchData(JSON.stringify(objPagination['objPage']), 'time_entries', JSON.stringify(arWhereClause)).subscribe(response => {

      this.arTimeEntryList = response['data'];

      this.listService.afterFetching(response, 'default');

      this.bLoading = false;

    });
  }

  deleteTimeEntry(strId) {
     //We tell the app component to open the confirmation.
     this.notificationService.sendConfirmation()
       .subscribe(
         confirmation => {
           //If the user confirmed, delete the record by field
           if (confirmation.answer) {
             this.recordService.deleteRecord('time_entries', strId).first().subscribe(
               data => {
                 if (data) {
                   // Get the list on page initialization.
                   this.fetchList('reload');
                   this.viewService.reloadRecordView(data);
                   this.notificationService.notifySuccess('record_delete_success');
                 } else {
                   this.notificationService.notifyError('record_delete_failed');
                 }
               },
               error => {
                 if (error.error.id) {
                    this.notificationService.notifyWarning(error.error.id[0]);
                 }
               });
           }
         }
       );
    }

  getRelateField(strKey) {
    // Loop through the custom ng-select fields.
    // this.arNgSelectFieldKey.forEach( key => {
      // Initialize a variable for filters.
      let arFilter: any = false;

      // If the field has filter, place them in the arFilter.
      if (this.arNgSelectFields[strKey]['filter'] != undefined) {
        arFilter = this.arNgSelectFields[strKey]['filter'];
      }

      // this.arNgSelectFields[strKey]['value'] = null;
      // Show the loader.
      this.arNgSelectFields[strKey]['loader'] = true;

      // Get the initial value if there are any, if none, just get the first 10 from API.
      this.recordService.getRecordRelate(this.arNgSelectFields[strKey]['module'], '', false, false, arFilter).subscribe(response => {
        // Hide the laoder.
        this.arNgSelectFields[strKey]['loader'] = false;

        // Initialize the list observable.
        this.arNgSelectFields[strKey]['obv'] = concat(
          of(response),
          this.arNgSelectFields[strKey]['typehead'].pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => this.arNgSelectFields[strKey]['loader'] = true),
            switchMap(term => this.recordService.getRecordRelate(this.arNgSelectFields[strKey]['module'], term, '', false, arFilter).pipe(
            tap(() => {
              this.arNgSelectFields[strKey]['loader'] = false;
            })
            ))
          )
        )
      });
    // });
  }

  /**
   * Handles refresh list event
   *
   * @returns {void}
   */
  onRefresh(): void {
    this.filterData(null, null);
  }

  /**
   * Open customer invoice so they can edit materials.
   *
   * @param objMaterial
   */
  openCI(objTimeEntry: LooseObject) {

    if (objTimeEntry['customer_invoice_id'] && objTimeEntry['customer_invoice_id'] != null) {
      let objData = {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        padding: '1%',
        disableClose: true,
        data: {
          record_id : this.strRecordId,
          invoice: [],
          module: 'customer_invoices',
          view_type : 'edit',
          customer_invoice_id: objTimeEntry['customer_invoice_id']
        }
      };

      let tabDialogRef = this.dialog.open(EditInvoiceComponent, objData);

      tabDialogRef.afterClosed().first().subscribe(item => {
        if (item != undefined && item.action == 'save') {
          this.onRefresh();
        }
      });
    }

  }
}
