import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ListingService } from '../../services/listing.service';
import { LooseObject } from '../../objects/loose-object';
import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { debounceTime, delay, finalize, switchMap, tap } from 'rxjs/operators';
import { SearchService } from '../../services/search.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NotificationService } from '../../services/notification.service';
import { CalendarApiService } from '../../module/calendar/services/calendar-api.service';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { RecordService } from '../../services/record.service';
import { CalendarService } from '../../module/calendar/services/calendar.service';
import { TaskDetailsNoActivities } from '../../module/calendar/dialogs/task-details-dialog/components/task-details-no-activities.component';
import { CustomNotificationMessageDialog } from './components/dialog/custom-notification-message.component';
import { DialogService } from '../../services/dialog.service';
import { isEmpty, toString } from 'lodash';
import { NotifyViaPushService } from './components/services/notify-via-push-service';

@Component({
  selector: 'app-task-calendar',
  templateUrl: './task-calendar.component.html',
  styleUrls: ['./task-calendar.component.scss'],
  providers: [],
})
export class TaskCalendarComponent implements OnInit, AfterViewInit {

  /**
   * The calendar in the html, we do this so we can access
   * its api.
   *
   * @var {FullCalendarComponent}
   */
  @ViewChild('fullcalendar') calendarComponent: FullCalendarComponent;

  /**
   * The calendar options.
   *
   * @var {CalendarOptions}
   */
  options: CalendarOptions = {

    businessHours: {
      startTime: '08:00',
      endTime: '17:00',
    },

    plugins: [
      timeGridPlugin,
      dayGridPlugin,
      interactionPlugin,
      bootstrapPlugin
    ],

    titleFormat: {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      weekday: 'long'
    },

    headerToolbar: {
      start: 'dayGridMonth,timeGridWeek,timeGridDay',
      center: 'title',
      end: 'today prev,next'
    },

    bootstrapFontAwesome: {
      prev: 'icon fc-icon fc-icon-chevron-left',
      next: 'icon fc-icon fc-icon-chevron-right',
      prevYear: 'left-double-arrow',
      nextYear: 'right-double-arrow'
    },

    schedulerLicenseKey: '0258041683-fcs-1609118915',
    lazyFetching: true,
    initialView: 'timeGridWeek',
    themeSystem: 'bootstrap4',
    editable: true,
    aspectRatio: 1.8,
    slotDuration: '00:30:00',
    defaultTimedEventDuration: '01:00:00',

    dateClick: this.handleDateClick.bind(this),
    eventDrop: this.taskChange.bind(this),
    eventResize: this.taskChange.bind(this),
    datesSet: this.changeEvents.bind(this),
    eventClick: this.onEventClick.bind(this),
    eventDidMount: this.handleTooltip.bind(this),
  };

  /**
   * Simple holder for the users paging.
   *
   * @var {object}
   */
  public objPage = {
    previous: false,
    next: false,
    current: 1
  }

  /**
   * The selected user.
   *
   * If task is already scheduled, this is the
   * user that was assigned to the task.
   *
   * If task is not yet scheduled, this is the
   * user whose calendar icon was clicked.
   *
   * @var {TaskCalendarUser}
   */
  public objSelectUser: TaskCalendarUser = null;

  /**
   * The form control for searching the user.
   *
   * @var {FormControl}
   */
  public objSearchUser = new FormControl(null);

  /**
   * List of calendar user, will only show when
   * a task is not yet scheduled.
   *
   * @var {TaskCalendarUser}
   */
  public arUsers: TaskCalendarUser[] = [];

  /**
   * If the task associated is already scheduled.
   *
   * @var {boolean}
   */
  public bIsScheduled: boolean = false;

  /**
   * If there were tasks set on the calendar, we need
   * to refresh the task list once the dialog is closed.
   *
   * @var {boolean}
   */
  public bShouldRefreshActivities: boolean = false;

  /**
   * The current page being viewed by the user.
   *
   * @var {string | null}
   */
  public strUserPage: string | null = null;

  /**
   * The current task's id.
   *
   * @var {string}
   */
  public strTaskId: string = null;

  /**
   * Flag once events are loaded
   *
   * @var {boolean}
   */
  public bLoadedEventsOnce: boolean = false;

  /**
  * Indicator when user search is loading.
  *
  * @var {boolean}
  */
  public bUserSearchLoader: boolean = false;

  /**
   * Collection of tasks to save.
   *
   * @var {LooseObject}
   */
  public objToSave: LooseObject = {};

  /**
   * Save loading indicator.
   *
   * @var {boolean}
   */
  public bSave: boolean = false;

  /**
   * If paging is ready.
   *
   * @var {boolean}
   */
  public bPaginationReady: boolean = false;

  /**
   * If list is subcontractors or users.
   *
   * @var {string}
   */
  public strSubcontractor: 'users' | 'subcontractors' = 'users';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public list: ListingService,
    public calendar: CalendarApiService,
    public translate: TranslateService,
    public search: SearchService,
    public notifyPush: NotifyViaPushService,
    private record: RecordService,
    private notif: NotificationService,
    private matDialog: MatDialog,
    private calendarService: CalendarService,
    private dialog: DialogService,
  ) {

    if (this.data['task_progress'] == 'scheduled' && this.data['user_id']) {
      this.bIsScheduled = true;
      this.objSelectUser = new TaskCalendarUser(this.data);
    }

    this.strTaskId = this.data['id'];

    this.options.allDayText = this.translate.instant('all_day');
  }

  ngAfterViewInit(): void {

    if (this.data['task_progress'] == 'scheduled' && this.data['user_id']) {

      this.calendar.objStartDate = moment(this.data['activity_date']).startOf('week');
      this.calendar.objEndDate = moment(moment(this.data['activity_date'])).endOf('week');

      this.getEvents();

      this.record.getRecord('users', this.objSelectUser.id).subscribe(response => {
        if (response && response['record_details']) {
          this.selectUser(response['record_details'], false);
        }
      })


    }

    this.fetchUsers();

    this.objSearchUser.valueChanges
      .pipe(
        tap(() => {
          this.bUserSearchLoader = true;
        }),
        debounceTime(400),
        switchMap(terms => {
          if (terms) {
            return this.list.fetchDataAdvanceSearch(null, 'users', {
              global_search: { op: 'eq', value: terms || '' }
            })
          } else {
            return Observable.of(null);
          }
        }),
      )
      .subscribe(result => {
        if (result != null) {
          let ids = result['data'].map(search => {
            return { id: search['id'] };
          });
          if (ids.length > 0) {
            this.fetchUsers(ids);
          } else {
            this.bUserSearchLoader = false;
            this.arUsers = [];
          }
        } else {
          this.fetchUsers();
        }
      });
  }

  ngOnInit() {
    this.options.buttonText = {
      today: this.translate.instant('today'),
      month: this.translate.instant('month'),
      day: this.translate.instant('day'),
      list: this.translate.instant('list'),
      twoWeeks: this.translate.instant('two_weeks'),
      week: this.translate.instant('week'),
    };
  }

  /**
   * Handle event tooltip.
   *
   * @param info
   */
  handleTooltip(objInfo: LooseObject) {

    if (this.calendarService && objInfo.event.extendedProps) {

      let objTooltipData = { ...objInfo.event.extendedProps };
      objTooltipData['metadata_type'] = 'task';

      objInfo.el.title = this
        .calendarService
        .formatTaskTooltip(objTooltipData, null);
    }

  }

  /**
   * Call new events when the calendar view changes.
   *
   * @param {LooseObject} objEvent
   */
  changeEvents(objEvent: LooseObject) {
    if (this.bLoadedEventsOnce) {
      this.calendar.objStartDate = moment(objEvent['startStr']);
      this.calendar.objEndDate = moment(objEvent['endStr']);
      this.getEvents();
    }
  }

  /**
   * Retrieve the events of this calendar.
   *
   * @returns {void}
   */
  getEvents() {
    this.calendar.getEvents([this.objSelectUser.id], { view: 'users' })
      .pipe(
        finalize(() => { this.bLoadedEventsOnce = true }),
        delay(100) // Delay necessary to load calendar properly.
      )
      .subscribe(response => {
        if (response && response['tasks']) {
          this.options.events = response['tasks']
            .filter(item => item.job)
            .map(item => {
              let objStart = moment.utc(item['due_date']).local();
              let objEnd = moment.utc(item['due_date']).local().add(item['estimated_duration'], 'hours');
              return {
                id: item['id'],
                title: `#${item['job']['job_number']} - ${item['name']}`,
                start: objStart.toISOString(),
                end: objEnd.toISOString(),
                extendedProps: item
              }
            });
        }
      });
  }

  /**
   * Fetch the users.
   *
   * @param {LooseObject} objFilter
   */
  fetchUsers(arIds: string[] = [], strDirection: string = 'default'): void {

    let objFilter = {
      enable_scheduling: true,
      status: 'active',
      ...(arIds.length > 0) ? { id: arIds } : {}
    };

    if (this.strSubcontractor == 'subcontractors') {
      objFilter['level'] = 'subcontractor';
    } else {
      objFilter['level'] = 'non_subcontractor';
    }

    let pagination = this.list.beforeFetching(strDirection);
    this.list.fetchData(JSON.stringify(pagination['objPage']), 'users', JSON.stringify(objFilter)).pipe(
      finalize(() => {
        this.bPaginationReady = true;
      })
    ).subscribe(response => {
      this.arUsers = response['data'].map(user => {
        return new TaskCalendarUser(user);
      });
      this.list.afterFetching(response, strDirection);
      this.bUserSearchLoader = false;
    });
  }

  /**
   * Move page of the user list.
   *
   * @param {'next'|'previous'} strDirection
   */
  movePage(strDirection: 'next' | 'prev'): void {
    this.fetchUsers([], strDirection);
  }

  /**
   * When the task has changed either by resizing or by dragging.
   *
   * @param {LooseObject} objEvent
   */
  taskChange(objEvent: LooseObject): void {

    let objStart = moment(objEvent['event'].startStr);
    let objEnd = moment(objEvent['event'].endStr);

    this.setTask(
      objStart,
      objEnd,
      objEnd.diff(objStart, 'hours'),
      objEvent['event']['id'],
      objEvent['event']['title'],
      objEvent['event']['extendedProps']
    );

  }

  /**
   * Set the task in the api.
   *
   * @param {Moment} strStart
   * @param {Moment} strEnd
   * @param {number} numDuration
   */
  setTask(
    strStart: Moment,
    strEnd: Moment,
    numDuration: number = 1,
    strId: string,
    strText: string,
    objExtendedProps: LooseObject = {}
  ): void {

    let arListEvents = [...(this.options.events as any)].filter(items => {
      return items['id'] != strId;
    });

    arListEvents.push({
      id: strId,
      title: strText,
      start: strStart.toISOString(),
      end: strEnd.toISOString(),
      extendedProps: objExtendedProps
    });

    this.options.events = arListEvents;

    this.objToSave[strId] = {
      id: strId,
      user_id: this.objSelectUser.id,
      due_date: strStart.utc().format('YYYY-MM-DD HH:mm:ss'),
      estimated_duration: numDuration
    };

    const duration = moment().add(24, 'hours').diff(strStart, 'hours');

    this.notifyPush.notifyViaPush = duration > 0 && duration < 24;
  }

  /**
   * Saves the changes made in the calendar.
   *
   * @returns {void}
   */
  save() {
    if (isEmpty(this.objToSave)) {
      return;
    }

    this.bSave = true;
    this.record.saveMultipleRecord(
      'activities',
      Object.keys(this.objToSave).map(item => { return this.objToSave[item] }),
      {
        additional_data: {
          notify_via_push: (this.notifyPush.notifyViaPush) ? '1' : '0',
          ... (toString(this.notifyPush._customNotificationMessage).trim().length > 0 && {
            notification_note: this.notifyPush._customNotificationMessage,
          }),
          push_notification_body: this.notifyPush.makePushNotificationBody(this.objToSave),
        },
      }
    ).pipe(
      finalize(() => {
        this.bSave = false;
        this.onClose();
      })
    ).subscribe(() => {
      this.notif.notifySuccess('success');
      this.bShouldRefreshActivities = true;
    });
  }

  /**
   * Handles the date click, this usually sets the current
   * selected task in the calendar.
   *
   * @param {LooseObject} objEvent
   */
  handleDateClick(objEvent: LooseObject): void {

    let objStart = moment(objEvent.dateStr);
    let objEnd = moment(objEvent.dateStr);
    let numEstimatedDuration: number = 1;
    if (this.objSelectUser) {

      let objDay = this.objSelectUser.getAvailabilityByIndex(objStart.day());

      if (objEvent['allDay']) {

        if (objDay.has_time) {
          objStart.set("hour", objDay.hour_start);
          objEnd.set("hour", objDay.hour_end);
          numEstimatedDuration = (objDay.hour_end - objDay.hour_start) || 1;
        } else {
          objStart.set("hour", 8);
          objEnd.set("hour", 17);
          numEstimatedDuration = 9;
        }

      } else {
        objEnd.add(1, 'hour');
      }

      this.setTask(
        objStart,
        objEnd,
        numEstimatedDuration,
        this.data['id'],
        `#${this.data['job_text']} - ${this.data['activity_name']}`,
        this.formatExtendedProps(numEstimatedDuration)
      );
    }
  }

  /**
   * Select the user and assign it to this component.
   *
   * @param {LooseObject} objUser
   * @param {boolean} bWithFetch
   */
  selectUser(objUser: LooseObject, bWithFetch: boolean = true) {

    this.objSelectUser = new TaskCalendarUser(objUser);
    this.options.events = [];

    if (this.objSelectUser.is_subcontractor) {
      this.notifyPush.notifyViaPush = false;
    }

    if (this.objSelectUser.has_availability) {
      this.options.businessHours = Object.keys(this.objSelectUser.availability).map((schedule, index) => {
        return {
          daysOfWeek: [index + 1],
          startTime: this.objSelectUser.availability[schedule].start_time || '00:00',
          endTime: this.objSelectUser.availability[schedule].end_time || '00:00'
        };
      });
    } else {
      this.options.businessHours = {
        startTime: '08:00',
        endTime: '17:00',
      };
    }

    if (bWithFetch) {

      if (!this.calendar.objStartDate) {
        this.calendar.objStartDate = moment().startOf('week');
        this.calendar.objEndDate = moment().endOf('week');
      }

      this.getEvents();
    }

  }

  /**
   * Ignores the default sorting for keyvalue
   * iterations in html
   *
   * @returns {number}
   */
  ignoreSort(): number {
    return 0;
  }

  /**
   * When the task in the scheduler is clicked.
   *
   * @return {void}
   */
  onEventClick(objEvent) {

    this.matDialog.open(TaskDetailsNoActivities, {
      maxWidth: '84vw',
      width: '1280px',
      data: {
        ...this.calendarService.formatTaskForDialogDisplay(objEvent.event.extendedProps),
        scheduled_task: false,
        view: 'users',
        metadata_type: 'task'
      }
    })
      .afterClosed()
      .subscribe(objDialogResult => {
        // Update the activities listing if we unschedule a task
        if (objDialogResult.action === 'unschedule') {
          this.bShouldRefreshActivities = true;
        }
        if (objDialogResult.action) {
          this.getEvents();
        }

      });
  }

  onClose(): void {
    this.dialog.close({
      instance: this,
    });
  }

  /**
   * Formats the properties for tooltip purposes.
   *
   * @param {number} numEstimatedDuration
   *
   * @returns {LooseObject}
   */
  formatExtendedProps(numEstimatedDuration: number): LooseObject {

    let objProps = {
      name: this.data['activity_name'],
      job: this.data['job'] || {},
      assigned_user: {
        id: this.objSelectUser.id
      },
      description: this.data['notes'],
      department: null,
      due_date: null,
      estimated_duration: numEstimatedDuration,
      id: this.data['id'],
      metadata_type: 'task',
      opportunity: null,
      parent_id: null,
      priority: 'normal',
      status: '',
      viewable: true
    }

    if (this.data['department_id']) {
      objProps.department = {
        id: this.data['department_id'],
        name: this.data['department_text']
      }
    }

    if (this.data['customer_id']) {
      objProps.job['customer'] = {
        id: this.data['customer_id'],
        name: this.data['customer_text']
      }
    }

    if (this.data['job']['address_text']) {
      objProps.job['full_address'] = this.data['job']['address_text'];
    }

    return objProps;
  }

  /**
   * When the subcontractor filter is toggled.
   *
   * @param bSubcontractorToggle
   */
  public onSubcontractorToggle(): void {

    if (this.strSubcontractor == 'subcontractors') {
      this.notifyPush.notifyViaPush = false;
    }

    this.fetchUsers();
  }
}

export class TaskCalendarAvailability {

  start_time: string;
  end_time: string;

  get has_time() {
    return this.start_time != null && this.end_time != null;
  }

  get hour_start() {
    return this.start_time != null ? parseInt(this.start_time.split(':')[0]) : 8;
  }

  get hour_end() {
    return this.end_time != null ? parseInt(this.end_time.split(':')[0]) : 8;
  }

  get display_start() {
    if (this.start_time) {
      return this.getDisplay(this.start_time.split(':'));
    }
  }

  get display_end() {
    if (this.end_time) {
      return this.getDisplay(this.end_time.split(':'));
    }
  }

  get duration() {
    if (this.start_time && this.end_time) {
      let start: string[] = this.start_time.split(':');
      let end: string[] = this.end_time.split(':');
      return parseInt(end[0]) - parseInt(start[0]);
    } else {
      return 0;
    }
  }

  constructor(properties: LooseObject) {
    this.start_time = properties.start_time || null;
    this.end_time = properties.end_time || null;
  }

  /**
   * Get display of the time.
   *
   * @param {string[]} strTime
   *
   * @returns {string}
   */
  getDisplay(strTime: string[]): string {
    let num = parseInt(strTime[0]);
    let mins = strTime[1];
    return ((num > 11) ? ((num - 12) || 12).toString().padStart(2, '0') :
      (num || 12).toString().padStart(2, '0')) + `:${(mins)} ${(num > 11) ?
        'PM' : 'AM'}`
  }

}


export class TaskCalendarUser {
  id: string;
  text: string;
  show_availablity: boolean;
  status: string;
  is_subcontractor: boolean = false;
  availability?: {
    monday: TaskCalendarAvailability,
    tuesday: TaskCalendarAvailability,
    wednesday: TaskCalendarAvailability,
    thursday: TaskCalendarAvailability,
    friday: TaskCalendarAvailability,
    saturday: TaskCalendarAvailability,
    sunday: TaskCalendarAvailability,
  }

  get has_availability() {
    let bHasAvailability: boolean = false;

    Object.keys(this.availability).forEach(schedule => {
      if (this.availability[schedule].start_time != null) {
        bHasAvailability = true;
      }
      if (this.availability[schedule].end_time != null) {
        bHasAvailability = true;
      }
    });

    return bHasAvailability;
  }

  getAvailabilityByIndex(numIndex: number): TaskCalendarAvailability {
    return this.availability[{
      0: 'sunday',
      1: 'monday',
      2: 'tuesday',
      3: 'wednesday',
      4: 'thursday',
      5: 'friday',
      6: 'saturday',
    }[numIndex]];
  }

  constructor(properties: LooseObject) {
    this.text = properties.user_text || properties.text;
    this.id = properties.user_id || properties.id;
    this.status = properties.status;
    this.show_availablity = false;

    this.is_subcontractor = properties.is_subcontractor || (properties.level && properties.level == 'subcontractor');

    if (properties.availability) {
      this.availability = {
        monday: new TaskCalendarAvailability(properties.availability['monday']),
        tuesday: new TaskCalendarAvailability(properties.availability['tuesday']),
        wednesday: new TaskCalendarAvailability(properties.availability['wednesday']),
        thursday: new TaskCalendarAvailability(properties.availability['thursday']),
        friday: new TaskCalendarAvailability(properties.availability['friday']),
        saturday: new TaskCalendarAvailability(properties.availability['saturday']),
        sunday: new TaskCalendarAvailability(properties.availability['sunday']),
      }
    }
  }
}
