import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { ClientStoreService } from '../../services/client-store.service';
import { filter } from 'rxjs/operators';
import { RoleService, ClientInformation } from '../../services/role.service';

@Directive({
  selector: '[fcHasPermission]',
})
export class HasPermissionDirective implements OnInit {
  /**
   * @type {BehaviorSubject<string>}
   * @readonly
   */
  protected readonly actionSubject = new BehaviorSubject<string>(undefined);

  /**
   * @type {Obseravable<[ClientInformation, string]}
   * @readonly
   */
  protected readonly state: Observable<[ClientInformation, string]> = combineLatest([
    this.clients.getWhenClientIsSetEvent(),
    this.actionSubject
  ]);

  /**
   * Handles directive checking
   *
   * @param {string} strAction
   */
  @Input('fcHasPermission')
  set hasPermission(strAction: string) {
    this.actionSubject.next(strAction);
  }

  /**
   * A template reference that will be used as fallback
   *
   * @type {TemplateRef<unknown>}
   */
  @Input('fcHasPermissionElse') else?: TemplateRef<unknown>;

  /**
   * Create instance of the directive
   *
   * @param {ViewContainerRef} view
   * @param {TemplateRef<unknown>} template
   * @param {ClientStoreService} clients
   * @param {RoleService} roles
   */
  constructor(
    protected view: ViewContainerRef,
    protected template: TemplateRef<unknown>,
    protected clients: ClientStoreService,
    protected roles: RoleService
  ) {}

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.state.pipe(
      filter(([ objClient ]) => !! objClient)
    ).subscribe(([objClient, strAction]) => {
      this.view.clear();

      if (this.roles.hasPermission(strAction, { client: objClient })) {
        this.show();
      } else {
        this.hide();
      }
    });
  }

  /**
   * Shows the current template
   *
   * @returns {void}
   */
  protected show(): void {
    this.view.createEmbeddedView(this.template);
  }

  /**
   * Clears the current view context
   *
   * @returns {void}
   */
  protected hide(): void {
    if (this.else !== undefined) {
      this.view.createEmbeddedView(this.else);
    }
  }
}