import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private readonly http: HttpClient,
  ) { }

  callPrivate$<R = unknown>(props: ApiCallProps<R>): Observable<R | null> {
    const endpoint = _.join([
      _.trimEnd(environment.base_api_url, '/'),
      'private',
      _.trim(props.path, '/'),
    ], '/');

    return this.http.request<Record<string, any>>(props.action, endpoint, {
      ... (!_.isEmpty(props.queryParams) && {
        params: this._transformQuery(props.queryParams),
      }),
      ... (!_.isEmpty(props.body) && {
        body: this._transformBody(props.body),
      }),
      observe: 'response',
    }).pipe(
      tap({
        error: (response) => {
          const callback = props.onErrorResponse;

          if (response instanceof HttpErrorResponse && !_.isNil(callback)) {
            const data = new Map<string, any>()

            let error = response.error;

            if (_.isString(error)) {
              error = {
                message: error,
              };
            }

            for (const [key, value] of Object.entries(response.error)) {
              data.set(key, value);
            }

            return callback(response.status, data);
          }
        }
      }),
      map((response) => {
        if (response.status >= 200 && response.status <= 299) {
          const data = new Map<string, any>();
          const content = (props.expectsNewFormat === true)
            ? response.body['item']
            : response.body;

          if (_.isEmpty(content)) {
            return null;
          }

          for (const [key, value] of Object.entries(content)) {
            data.set(key, value);
          }

          return props.onSuccessResponse(data);
        }

        return null;
      }),
    );
  }

  _transformQuery(query: ApiQueryParams): ApiQueryParams {
    let params = {};

    for (let [key, value] of _.entries(query)) {
      if (_.isNil(value)) {
        value = '';
      }

      params[key] = value;
    }

    return params;
  }

  _transformBody(body: Map<string, any>): URLSearchParams {
    let params = new URLSearchParams();

    for (let [key, value] of _.entries(body)) {
      if (_.isMap(value) || _.isObject(value)) {
        value = JSON.stringify(value);
      } else if (!_.isString(value)) {
        value = _.toString(value);
      }

      params.set(key, value);
    }

    return params;
  }
}

type ApiCallProps<R> = {
  path: string;
  action: 'post' | 'get' | 'delete' | 'patch' | 'put' | 'head';
  queryParams?: ApiQueryParams;
  body?: Map<string, any>;
  headers?: {
    [key: string]: string[],
  };
  onSuccessResponse: (response: Map<string, any>) => R | null;
  expectsNewFormat?: boolean;
  onErrorResponse?: (status: number, data: Map<string, any>) => void;
}

type ApiQueryParams = Record<string, string | string[]>;
