import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Phone } from '../../../../objects/phone';
import { ClientStoreService } from '../../../../services/client-store.service';
import { dial_codes } from '../../../../../assets/api/dial_codes.json';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

  @Input() value: any;
  @Input() label: string;
  @Input() form: FormGroup;
  @Input() key: string;

  /// list of selectable phone codes
  readonly codes = dial_codes;
 
  /// list of available phone types
  readonly types = [
    'mobile',
    'home',
    'others',
    'work',
  ];

  get isTouched() { return this.form.controls[this.key].touched; }

  constructor(private clientStore: ClientStoreService) { }

  ngOnInit() {}

  /**
   * Deletes the current phone.
   *
   * @param objPhone - we use this to identify it's index and then splice.
   */
  deletePhone(objPhone: Phone) {
    this.value.splice(this.value.indexOf(objPhone), 1);
    // Update the form control value.
    this.form.controls[this.key].setValue(this.value);
  }

  /**
   * Add the new phone instance to our list of phones.
   *
   * @returns {void}
   */
  addPhone() {
    if (typeof this.value == 'string') {
      this.value = [];
    }
    var activeClient = this.clientStore.getActiveClient();
    // FC-2681: If we don't have default phone config use the clients country address
    var defaultCountry = (activeClient.config['phone']) ? activeClient.config['phone']['code'] : activeClient.country;
    this.value.push(new Phone().setCurrentCountry(defaultCountry));
    // Update the form control value.
    this.form.controls[this.key].setValue(this.value);
  }

  /**
   * Simply updates the form control if the number field
   * changes.
   *
   * @returns {void}
   */
  updateFormControlOnModelChange() {
    this.form.controls[this.key].updateValueAndValidity();
    this.form.controls[this.key].markAsDirty();
  }

  /**
   * number's should only contain numbers
   *
   * @param event
   */
  validateInput(event) {
    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
        event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent, index): void {
    let pastedText = event.clipboardData.getData('text');
    let sanitizedValue = parseInt(pastedText.replace(/[^0-9]/g, ''));
    if (this.value[index]) {

      this.value[index] = new Phone({
        type: this.value[index].type,
        code: this.value[index].code,
        number: sanitizedValue
      });

      this.form.controls[this.key].setValue(
        this.value.map( data => {
          data.number = data.number.toString()
          return data;
        })
      );
    }
  }
}
