<div class="row">
  <div class="col-sm-12">
    <span class="title" translate>
      <fa-icon [icon]="['fas', 'file-upload']" ></fa-icon>
      &nbsp; {{ 'upload_file'| translate }}
    </span>

    <span class="buttons">
      <button type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
        <span class="pl-1">{{ 'cancel' | translate }}</span>
      </button>

      <button (click)="onSubmit()" class="btn ml-1 btn-primary" [ngClass]="(bShowLoader['submit'])? 'btn-spinner' : ''">
        <fa-icon *ngIf="!bShowLoader['submit']" [icon]="['fas', 'file-upload']"></fa-icon>
        <fa-icon *ngIf="bShowLoader['submit']" [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon>
        <span class="pl-1">{{ 'upload' | translate }}</span>
      </button>

    </span>
  </div>
</div>

<hr class="separator"/>

<span class="label"> {{ 'file' | translate }} </span>
<div class="file-drop-container">
    <input class="file-input" type="file" (change)="onFileChange($event)" />

    <div *ngIf="!isFileDropped && arFiles.name == undefined" class="file-input-overlay">
        {{ 'insert_file' | translate }}
    </div>

    <div *ngIf="isFileDropped" class="file-input-overlay-uploading">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" aria-hidden="true"></fa-icon> {{ 'uploading' | translate }}
    </div>

    <div *ngIf="!isFileDropped && arFiles.name !== undefined" class="file-input-overlay-uploaded">
        {{ arFiles.name }}
    </div>

</div>

<span class="label"> {{ 'description' | translate }} </span>
<div class="description-containter">
  <textarea [required] class="form-control texts"  rows="3" [(ngModel)]="strDescription" (ngModelChange)="markAsEdited()"></textarea>
</div>
