<ng-container *ngIf="! hasError">
  <ng-content></ng-content>
</ng-container>
<ng-container *ngIf="hasError">
  <div [align]="alignment">
    <span
      data-testid="error_forbidden"
      *ngIf="isForbiddenHttpError"
      [class.for-widget]="displayAs === 'widget'"
    >
      {{ (isAction ? 'forbidden_action_error' : 'forbidden_error') | translate }}
    </span>
  </div>
</ng-container>