import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormService } from '../../services/form.service';
import { ViewModule } from '../../shared/modules/view.module';
import { ImportRoutingModule } from './import-routing.module';
import { RecordService } from '../../services/record.service';
import { PipesModule } from '../../shared/modules/pipes.module';
import { SharedModule } from '../../shared/modules/shared.module';
import { ListingModule } from '../../shared/modules/listing.module';
import { ActivityModule } from '../../shared/modules/activity.module';
import { DirectivesModule } from '../../shared/modules/directives.module';
import { InformationModule } from '../../shared/modules/information.module';
import { ImportRecordComponent } from './import-record/import-record.component';
import { FieldMappingsComponent } from './forms/field-mappings/field-mappings.component';
import { ImportRecordsListComponent } from './import-records-list/import-records-list.component';
import { SaveFieldMappingComponent } from './forms/save-field-mapping/save-field-mapping.component';
import { DynamicComponentDisplayModule } from '../../shared/modules/dynamic-component-display.module';
import { MimeTypeValidator } from '../../shared/validators/files/mime-type-validator/mime-type-validator';
import { NewImportQueueFormComponent } from './forms/new-import-queue-form/new-import-queue-form.component';
import { ResultSetListComponent } from './import-record/widget-components/result-set-list/result-set-list.component';
import { ImportedRecordsComponent } from './import-record/widget-components/imported-records/imported-records.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DialogSaveTemplateComponent } from './forms/field-mappings/dialog-save-template/dialog-save-template.component';
import { MaterialModule } from '../../shared/modules/material.module';
import { EditModule } from '../../shared/modules/edit.module';
@NgModule({
  declarations: [
    ImportRecordsListComponent,
    NewImportQueueFormComponent,
    ImportRecordComponent,
    ImportedRecordsComponent,
    ResultSetListComponent,
    FieldMappingsComponent,
    SaveFieldMappingComponent,
    DialogSaveTemplateComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    ImportRoutingModule,
    InformationModule,
    ListingModule,
    SharedModule.forRoot(),
    ViewModule,
    ActivityModule,
    DirectivesModule,
    DynamicComponentDisplayModule,
    PipesModule,
    MatExpansionModule,
    MaterialModule,
    FormsModule,
    EditModule,
  ],
  providers: [
    RecordService,
    FormService,
    MimeTypeValidator
  ],
  entryComponents: [
    NewImportQueueFormComponent,
    FieldMappingsComponent,
    SaveFieldMappingComponent,
    DialogSaveTemplateComponent
  ]
})
export class ImportModule { }
