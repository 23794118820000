<header>
  <div class="d-flex dialog-header">
    <h3 class="title">
      <span>
        <fa-icon [icon]="['fas', 'plus-circle']" class="mr-1 text-success"></fa-icon>{{ 'Add Products to ' + data['name'] }}
      </span>
    </h3>
    <div class="ml-auto d-flex">
      <ul class="list-inline pull-right">
        <li class="list-inline-item">
          <button type="button" (click)="dialogRef.close()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
            <span class="pl-1">{{ 'cancel' | translate }}</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button type="button" (click)="addProducts()" class="btn ml-1 btn-primary">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
            <span class="pl-1">{{ 'add' | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>
<hr>
<div>
  <mat-dialog-content>
    <div style="width: 600px;">
      <ng-select [items]="objProducts.source | async"
        multiple="true"
        bindLabel="name"
        bindValue="id"
        appendTo="body"
        (open)="objProducts.loadDataOnClick()"
        [typeahead]="objProducts.typehead"
        [loading]="objProducts.loader"
        [(ngModel)]="objProducts.value">
      </ng-select>
    </div>
  </mat-dialog-content>
</div>
