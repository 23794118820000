<div class="pt-3 mb-3">
  <div *ngIf="!bHasNoCustomer" class="row">
    <div class="col-12">
      <table class="table table-bordered" aria-describedby="customer-summary">
        <thead>
          <tr class="bg-light">
            <th class="fs-13 text-center" id="customer-summary" colspan="3">{{ 'customer' | translate }}</th>
          </tr>
          <tr class="bg-light">
            <th class="fs-13" width="33%">{{ 'name' | translate }}</th>
            <th class="fs-13" width="33%">{{ 'type' | translate }}</th>
            <th class="fs-13" width="33%">{{ 'address' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="jobSummaryCustomerName">{{ customerNameSummaryText | placeholdWithString }}</td>
            <td id="jobSummaryCustomerType">{{ customerTypeSummaryText | translate | placeholdWithString }}</td>
            <td id="jobSummaryCustomerAddress">{{ summary.customer?.address | readableAddress | placeholdWithString }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="bHasNoCustomer" class="row">
    <div class="col-12">
      <table class="table table-bordered" aria-describedby="location-summary">
        <thead>
          <tr class="bg-light">
            <th class="fs-13 text-center" id="location-summary" colspan="3">{{ 'location' | translate }}</th>
          </tr>
          <tr class="bg-light">
            <th class="fs-13" width="50%">{{ 'address' | translate }}</th>
            <th class="fs-13" width="25%">{{ 'latitude' | translate }}</th>
            <th class="fs-13" width="25%">{{ 'longitude' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="jobSummaryLocationAddress">{{ summary.customer?.address | readableAddress | placeholdWithString }}</td>
            <td id="jobSummaryGPSLatitude">{{ summary.customer?.address.latitude | placeholdWithString }}</td>
            <td id="jobSummaryGPSLongitude">{{ summary.customer?.address.longitude | placeholdWithString }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row pt-4">
    <div class="col-12">
      <table class="table table-bordered" aria-describedby="contact-summary">
        <thead>
          <tr class="bg-light">
            <th class="fs-13 text-center" id="contact-summary" colspan="4">{{ 'contact' | translate }}</th>
          </tr>
          <tr class="bg-light">
            <th class="fs-13" width="25%">{{ 'first_name' | translate }}</th>
            <th class="fs-13" width="25%">{{ 'last_name' | translate }}</th>
            <th class="fs-13" width="25%">{{ 'email_address' | translate }}</th>
            <th class="fs-13" width="25%">{{ 'phone' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="jobSummaryContactFirstName">{{ summary.contact?.first_name | placeholdWithString }}</td>
            <td id="jobSummaryContactLastName">{{ summary.contact?.last_name | placeholdWithString }}</td>
            <td id="jobSummaryContactEmailAddress">
              <div *ngIf="summary.contact?.email_address; else noData" >
                <div *ngFor="let item of summary.contact?.email_address">
                  <div class="icon-container">
                    {{ item.email }} <span *ngIf="item.primary == '1'" class="badge badge-success">Primary</span>
                  </div>
                </div>
              </div>
            </td>
            <td id="jobSummaryContactPhome">
              <div *ngIf="summary.contact?.phone.length > 0; else noData">
                <div *ngFor="let item of summary.contact?.phone">
                  <div class="icon-container">
                    <fa-icon [fixedWidth]="true" [icon]="['far', item.getPhoneIcon]" class="icon-style"></fa-icon>
                    <span *ngIf="item.number; else illegalNumber">{{ item.code }} {{ (item.number) ? (item.number | phone) : '' }}</span>
                  </div>
                  <ng-template #illegalNumber><span>{{ 'fix_number' | translate }}</span></ng-template>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row pt-4">
    <div class="col-12">
      <table class="table table-bordered" aria-describedby="job-summary">
        <thead>
          <tr class="bg-light">
            <th class="fs-13 text-center" id="job-summary" colspan="4">{{ 'job' | translate }}</th>
          </tr>
          <tr class="bg-light">
            <th class="fs-13" width="25%">{{ 'job_template' | translate }}</th>
            <th class="fs-13" width="25%">{{ 'priority' | translate }}</th>
            <th class="fs-13" width="25%">{{ 'job_type' | translate }}</th>
            <th class="fs-13" width="25%">{{ 'project_template' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="jobSummaryJobTemplate">{{ summary.job?.field_text.job_template_text | placeholdWithString }}</td>
            <td id="jobSummaryPriority">{{ summary.job?.priority | translate }}</td>
            <td id="jobSummaryJobType">{{ summary.job?.type | translate | placeholdWithString }}</td>
            <td id="jobSummaryProjectTemplate">{{ summary.job?.field_text.project_template_text | placeholdWithString }}</td>
          </tr>

          <tr class="bg-light">
            <th class="fs-13">{{ 'internal_notes' | translate }}</th>
            <th class="fs-13">{{ 'billable' | translate }}</th>
            <th *ngIf="bDepartmentTracking" class="fs-13">{{ 'department' | translate }}</th>
            <th [attr.colspan]="bDepartmentTracking ? 1 : 2" class="fs-13">{{ 'due_date' | translate }}</th>
          </tr>

          <tr>
            <td id="jobSummaryInternalNotes">{{ summary.job?.internal_notes | placeholdWithString }}</td>
            <td id="jobSummaryBillable">{{ (summary.job?.billable ? 'yes' : 'no') | translate }}</td>
            <td *ngIf="bDepartmentTracking" id="jobSummaryDepartment">{{ summary.job?.field_text.department_text | placeholdWithString }}</td>
            <td id="jobSummaryDueDate" [attr.colspan]="bDepartmentTracking ? 1 : 2">{{ summary.job?.due_date | humanReadableDate | placeholdWithString }}</td>
          </tr>

          <tr class="bg-light">
            <th class="fs-13" colspan="2">{{ 'linked_assets' | translate }}</th>
            <th class="fs-13" colspan="2">{{ 'linked_checklists' | translate }}</th>
          </tr>

          <tr>
            <td id="jobSummaryAssets" colspan="2">{{ assetSummaryText }}</td>
            <td id="jobSummaryChecklists" colspan="2">{{ checklistsSummaryText }}</td>
          </tr>

          <tr class="bg-light">
            <th *ngIf="summary.job?.billable" colspan="2" class="fs-13">{{ 'invoicing_type' | translate }}</th>
            <th class="fs-13" [ngClass]="{'text-center': !summary.job?.billable}" [attr.colspan]="summary.job?.billable ? '2' : '4'">{{ 'job_summary' | translate }}</th>
          </tr>

          <tr>
            <td *ngIf="summary.job?.billable" colspan="2" id="jobSummaryInvoicingType">
              {{ summary.job?.invoicing_type | translate | placeholdWithString }}
            </td>
            <td
              id="jobSummary"
              [ngClass]="{'text-center': !summary.job?.billable}"
              [attr.colspan]="summary.job?.billable ? '2' : '4'">
                {{ summary.job?.job_summary | placeholdWithString }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #noData>
  <span>--</span>
</ng-template>