import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Relate } from '../../../../objects/relate';
import { switchMap, tap } from 'rxjs/operators';
import { RecordService } from '../../../../services/record.service';
import { MatDialog } from '@angular/material';
import { StaticFoldersComponent } from '../static-folders.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { LooseObject } from '../../../../objects/loose-object';
import { filled } from '../../../../shared/utils/common';
import { get } from 'lodash';
import { ListingService } from '../../../../services/listing.service';

@Component({
  selector: 'browse-products',
  templateUrl: './browse-products.component.html',
  styleUrls: ['./browse-products.component.scss']
})
export class BrowseProductsComponent implements OnInit, OnChanges {

  /**
   * This will emit the selected product.
   *
   * @var EventEmitter
   */
  @Output() objProductSelected = new EventEmitter<any>();

  /**
   * This will emit the selected group.
   *
   * @var EventEmitter
   */
  @Output() objGroupSelected = new EventEmitter<any>();

  /**
   * If the product to search are only labor true products.
   *
   * @var {boolean}
   */
  @Input() bLabor: boolean = null;

  /**
   * If we should allow searchable products.
   *
   * @var {boolean}
   */
  @Input() bSearchable: boolean = true;

  /**
   * If we should allow searchable products.
   *
   * @var {boolean}
   */
  @Input() bAllowCustom: boolean = true;

  /**
   * If the product has a pricebook filter.
   *
   * @var {boolean}
   */
  @Input() strPricebookId: string = null;

  /**
   * If the product to search are only sales true products.
   *
   * @var {boolean}
   */
  @Input() bSales: boolean = true;

  /// apply customer settings to searched items
  @Input('customerId') strCustomerId: string;

  /// override unit price with discount price
  @Input('overrideUnitPriceWithDiscountPrice') bOverrideUnitPriceWithDiscountPrice: boolean = true;

  /**
   * Hides the quantity input field in folder view.
   * We have this option because not all line items
   * have quantity.
   *
   * @var {boolean}
   */
  @Input() bHideQuantity: boolean = false;

  /**
   * Instead of the former per line product relate, we reverted into
   * a single product search which they can add to the list.
   *
   * @var {Relate<any>}
   */
  public objProductRelate = new Relate<any>();

  constructor(
    public recordService: RecordService,
    private dialog: MatDialog,
    public list: ListingService,
  ) { }

  ngOnChanges(objChanges: SimpleChanges): void {
    if (objChanges && (filled(get(objChanges, 'strCustomerId')) || filled(get(objChanges, 'strPricebookId')))) {
      this.resetSearch();
    }
  }

  ngOnInit() {
    this.resetSearch();
  }

  /**
   * Reset the relates to a new value.
   *
   * @returns {void}
   */
  resetSearch(): void {

    let objFilters: LooseObject = {active: true};

    if (this.bSearchable) {
      objFilters.is_searchable = this.bSearchable
    }

    if (this.bLabor == null) {
      this.objProductRelate.buildRelates(
        switchMap(term => this.getRecords(term, objFilters, true, this.strPricebookId))
      );
    } else if (this.bLabor === true) {

      objFilters.labor = true;
      this.objProductRelate.buildRelates(
        switchMap(term => this.getRecords(term, objFilters, false, this.strPricebookId))
      );
    } else {

      objFilters.labor = false;
      this.objProductRelate.buildRelates(
        switchMap(term => this.getRecords(term, objFilters, this.bSales, this.strPricebookId))
      );
    }
  }

  /**
   * Fetch the item using global ES instead of list filters.
   *
   * @param strTerm
   * @param objFilters
   * @param strIds
   * @param isSale
   * @param strPricebook
   *
   * @returns {Observable<any[]>}
   */
  getRecords(strTerm: string, objFilters: any, isSale: boolean, strPricebook: string) {
    return this.recordService.getProductRecordData(
      strTerm, objFilters, this.strCustomerId, isSale, strPricebook, [], false, this.bOverrideUnitPriceWithDiscountPrice
    );
  }

  /**
   * This is triggered when creating a customized line item
   * and not getting from the list of items.
   *
   * @param name - name of the custom product.
   */
  addTag(name) {
    return { description: name };
  }

  /**
   * When an item is added, it triggers an event that informs parent.
   *
   * @param element
   * @param event
   */
  addAttribute(element: NgSelectComponent = null, event: any = null) {
    if (element) {
      element.handleClearClick();
    }

    if ((element && event) || (!element && event) || (!element && !event)) {
      this.objProductSelected.next(event);
    }
  }

  /**
   * Let's open the static folder dialog so they
   * can select products using a folder like structure.
   *
   * TODO: Add type cast so we identify if label or group via a flag
   * instead of checking if the returned data has a "code" property.
   *
   * @returns {void}
   */
  openProductFolders(): void {

    let objStaticFolders = this.dialog.open(StaticFoldersComponent, {height: '70%', width: '70%', data: {
      labor: this.bLabor,
      hideQuantity: this.bHideQuantity
    }});

    let objFolderSub = objStaticFolders.componentInstance.objProductSelected.subscribe(response => {
      this.objProductSelected.next(response);
    });

    objStaticFolders
      .afterClosed()
      .pipe(tap(() => {
        objFolderSub.unsubscribe();
      }))
      .subscribe();

  }

}
