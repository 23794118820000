export class QuoteLineGroup {

  /**
   * Name of the group.
   */
  label: string;

  /**
   * Just a boolean to check if this indeed a group.
   */
  is_group: boolean;

  constructor(properties: {
    label?: string
    is_group?: boolean
  } = {}){
    this.label = properties.label || '';
    this.is_group = properties.is_group || true;
  }

}