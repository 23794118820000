<div>
  <div class="input-group">
    <div class="col-sm-6 pl-0">
      <input
        type="text"
        placeholder="{{ 'latitude' | translate }}"
        [(ngModel)]="latitude"
        (ngModelChange)="geolocationChange()"
        name="latitude"
        id="latitude"
        class="form-control value-text input-height-36"
      />
    </div>

    <div class="col-sm-6 pr-0">
      <input
        type="text"
        placeholder="{{ 'longitude' | translate }}"
        [(ngModel)]="longitude"
        (ngModelChange)="geolocationChange()"
        name="longitude"
        id="longitude"
        class="form-control value-text input-height-36"
      />

      <span *ngIf="withShowMap" class="input-group-addon right-addon pointer text-primary">
        <fa-icon [icon]="['fas', 'map-marker-alt']" (click)="openMap()"></fa-icon>
      </span>
    </div>
  </div>
</div>
