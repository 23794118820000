import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RecordService } from '../../../../../services/record.service';
import { FormService } from '../../../../../services/form.service';
import { NotificationService } from '../../../../../services/notification.service';
import { AddressComponent } from '../../../../../shared/components/address/address.component';
import { FormControl, FormGroup } from '@angular/forms';
import { sprintf } from 'sprintf-js';
import { Address } from '../../../../../objects/address';
import { Select } from '../../../../../objects/select';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { StrService } from '../../../../../services/helpers/str.service';
import { cloneDeep, get, isArray } from 'lodash';
import * as _moment from 'moment';
import { filled } from '../../../../utils/common';
import { CustomTranslateService } from '../../../../../services/custom-translate.service';
import { spf } from '../../../../utils/str';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-merge-record',
  templateUrl: './merge-record.component.html',
  styleUrls: ['./merge-record.component.scss']
})

export class MergeRecordComponent implements AfterViewInit {
  @ViewChild(AddressComponent)
  private addressComponent: AddressComponent;
  public relateFields: Array<RelateField> = [];
  public fieldForm: Array<object> = [];
  public addressValue$: BehaviorSubject<Address>;
  public bSubmitted: boolean = false;
  public addressFormGroup: FormGroup;
  public usedFields: object = {};
  public recordView: object = {};
  public strModule: string = '';
  public parentValue: ParentValue;
  public selectedRecords: Array<object>;
  private recordDetails: Array<object>;
  private excludedFields: Array<string> = [
    'amount_to_invoice', 'amount_actually_invoiced',
    'customer_number', 'supplier_number',
    'created_by', 'updated_by',
    'created_by_name', 'modified_by_name',
    'created_at', 'updated_at',
    'accounting_sync_error', 'accounting_sync_detail',
    'line_items', 'create_job_email_address',
  ];
  private oldFormValues: Array<object> = [];

  readonly error$ = new BehaviorSubject<string|undefined>(undefined);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected dialogRef: MatDialogRef<MergeRecordComponent>,
    protected recordService: RecordService,
    protected formService: FormService,
    protected notificationService: NotificationService,
    private stringService: StrService,
    private readonly _translations: CustomTranslateService,
  ) {
    this.strModule = data.module;
    this.selectedRecords = data.records;
  }


  ngAfterViewInit() {
    this.recordService.getRecordConfig(this.strModule).first().subscribe(
      result => {
        this.usedFields = result['used_fields'];
        this.recordView = result['record_view'];
        this.recordDetails = result['record_details'];
        this.initField();

        this.fieldForm.map( (form, index) => {
          let objFields = Object.keys(form['groups']['controls']);
          form['radio_model'] = {};

          objFields.forEach( strField => {
            let strRecordField = strField.replace('-'+index, '');

            if (strField.includes('attributes') && this.selectedRecords[index][strRecordField]) {
              this.selectedRecords[index][strRecordField].forEach(attr_field => {
                form['radio_model'][attr_field['key']] = index === 0;
              });
            } else {
              form['radio_model'][strField] = index === 0;
            }


            let indexField = this.getFieldIndex(strField, form);
            if (indexField > -1) {
              if (form['fields'][indexField]['controlType'] == 'relate') {
                let textField = strRecordField.replace('_id', '_text');
                if (this.strModule === 'supplier_invoices' && textField == 'customer_text') textField = 'supplier_text';
                let strText = this.selectedRecords[index][textField];
                form['fields'][indexField]['default_value'] = this.selectedRecords[index][strRecordField];
                form['fields'][indexField]['default_text'] = strText;

                this.relateFields[strField] = {
                  obv: new Observable<Select[]>(),
                  typehead: new Subject<string>(),
                  loader: false,
                  label: strRecordField,
                  name: strRecordField,
                  module: form['fields'][indexField]['module'],
                  initial_value: [new Select(this.selectedRecords[index][strRecordField], strText)],
                  value: new Select(this.selectedRecords[index][strRecordField], strText),
                };
              }

              if (strField.includes('customer_id') && this.strModule == 'customers') {
                form['fields'][indexField]['readonly'] = true;
              }

              let bHasInvoice = this.selectedRecords[index]['has_invoice'];
              if (bHasInvoice && (strField.includes('invoicing_type') || strField.includes('billable')) && this.strModule == 'jobs') {
                form['fields'][indexField]['readonly'] = true;
              }

              // Set options for email address
              if (this.selectedRecords[index][strRecordField] != undefined) {
                let recordValue = this.selectedRecords[index][strRecordField];

                if (strField.includes('phone')) {
                  recordValue = (recordValue) ? recordValue : [];
                }

                form['fields'][indexField]['default_value'] = recordValue;

                // Set default value for date and datetime
                if (form['fields'][indexField]['controlType'] == 'date' || form['fields'][indexField]['controlType'] == 'datetime') {
                  let strFormValue = '';
                  let toNotUtc = '';
                  let dateValue = this.selectedRecords[index][strRecordField];

                  if (dateValue) {
                    if (form['fields'][indexField]['controlType'] == 'date') {
                      strFormValue = moment(dateValue).format('YYYY-MM-DD');
                      toNotUtc = moment(dateValue).format('YYYY-MM-DD');
                    } else {
                      let toUtc = moment.utc(dateValue);
                      toNotUtc = moment(dateValue).format('YYYY-MM-DD HH:mm:ss');
                      let toUtcString = toUtc._d;
                      strFormValue = moment(toUtcString).format('YYYY-MM-DD HH:mm:ss');
                    }
                  }

                  form['fields'][indexField]['default_value'] = strFormValue;
                }

                if (strField == 'email_address-'+index && form['fields'][indexField]['controlType'] === 'multiselect') {
                  let options = this.selectedRecords[index][strRecordField].map(email_address => {
                    let emailAddress: EmailAddress = {
                      id: email_address['email'],
                      text: email_address['email'],
                      primary: email_address['primary'],
                      email: email_address['email'],
                    }

                    return emailAddress;
                  });

                  form['fields'][indexField]['options'] = options;
                  form['fields'][indexField]['default_value'] = options;
                  this.fieldPatchValue(strField, options, index);
                } else {
                  this.fieldPatchValue(strField, recordValue, index);
                }

                if (this.isAddressField(form['fields'][indexField]['controlType'])) {
                  form['address_group'][strField].patchValue({
                    latitude: recordValue.latitude,
                    longitude: recordValue.longitude,
                    level: this.stringService.fallsBackTo(recordValue.level, null),
                    lot_number: this.stringService.fallsBackTo(recordValue.lot_number, null),
                    unit: this.stringService.fallsBackTo(recordValue.unit, null),
                    building_name: this.stringService.fallsBackTo(recordValue.building_name, null),
                    city: this.stringService.fallsBackTo(recordValue.city, null),
                    zip: this.stringService.fallsBackTo(recordValue.zip, null),
                    state: this.stringService.fallsBackTo(recordValue.state, null),
                    country: this.stringService.fallsBackTo(recordValue.country, null),
                    street: this.stringService.fallsBackTo(recordValue.street, null)
                  });
                }
              }
            }
          });

          this.oldFormValues[index] = cloneDeep(form['groups'].getRawValue());
        });

        // Loop through the custom ng-select fields.
        Object.keys(this.relateFields).forEach( name => {
          this.initializeSelectRelateField(name);
        });
    });
  }

  /**
   * Initialize Main Field
   */
  initField() {
    let formPerRecords = [];
    if (this.selectedRecords) {

      this.selectedRecords.forEach((record, index) => {
        let arFormData = this.formService.formData(this.recordView, this.usedFields, record);
        let arFormFields = [];
        arFormData.forEach(formData => {
          formData['fields'] = formData['fields'].filter(field => {
            if (!this.excludedFields.includes(field['key']) && !get(field, 'readonly', false)) {
              return field
            }
          });

          formData['fields'].map(field => {
            field['key'] = sprintf('%s-%d', field['key'], index);
            return field;
          });
          arFormFields = [...arFormFields, ...formData['fields']];
        });


        let finalFormPerRecord = {
          is_primary: index === 0,
          fields: arFormFields,
          groups: this.formService.toFormGroup(arFormFields),
          address_group: {},
        }

        arFormFields.forEach(arFormField => {
          if (this.isAddressField(arFormField['key'])) {
            finalFormPerRecord['address_group'][arFormField['key']] = new FormGroup({
              latitude: new FormControl,
              longitude: new FormControl,
              street: new FormControl,
              unit: new FormControl,
              level: new FormControl,
              building_name: new FormControl,
              lot_number: new FormControl,
              city: new FormControl,
              state: new FormControl,
              country: new FormControl,
              zip: new FormControl
            });
          }
        });

        formPerRecords.push(finalFormPerRecord);
      });

      this.fieldForm = formPerRecords;
    }
  }

  /**
   * Get index of field
   * @param strField
   * @param form
   */
  getFieldIndex(strField, form) {
    if (form != undefined && form['fields'] != undefined) {
      return form['fields'].findIndex( attr => (attr['key'] == strField));
    } else {
      return -1;
    }
  }

  /**
   * Set value of field
   * @param strField
   * @param strValue
   */
  fieldPatchValue(strField, strValue, formIndex) {
    this.fieldForm[formIndex]['groups'].patchValue({
      [strField] : strValue,
    }, {emitEvent: true, onlySelf: true});
  }

  /**
   * Set form as primary
   * @param index
   */
  setPrimaryRecord(index) {
    this.fieldForm.map((form, i) => {
      if (form['radio_model']) {
        Object.keys(form['radio_model']).forEach((field) => {
          if (i === index) {
            form['radio_model'][field] = true;
          } else {
            form['radio_model'][field] = false;
          }
        });
      }

      return form['is_primary'] = false;
    });

    this.fieldForm[index]['is_primary'] = true;
  }

  /**
   * This will remove appended index in field
   * @param string
   * @returns
   */
  removeAppendedIndex(string: string): string {
    return string.replace(/-(.*)/,"");
  }

  /**
   * When an ng-select is clicked, trigger a fake search
   * to load the ng-select with default options.
   */
  public triggerSubject(typehead: Subject<string>): void {
    typehead.next("");
  }

  /**
   * Close the current dialog.
   */
  cancelDialog() {
    let bHasChanged = false;

    // This logic is for checking if the form has changed.
    // so we can send a confirmation if they want to discard changes
    this.oldFormValues.forEach((objForm, intFormIndex) => {
      let currentFormValue = this.fieldForm[intFormIndex]['groups'].getRawValue();
      for (let [strField, formValue] of Object.entries(objForm)) {
        if (bHasChanged) {
          continue;
        }

        if (typeof formValue === 'object') {
          if (this.isAddressField(strField)) {
            let objAddressValue = this.fieldForm[intFormIndex]['address_group'][strField].getRawValue();
            bHasChanged = this.compareAddressValue(objAddressValue, formValue);
          } else {
            bHasChanged = JSON.stringify(formValue) !== JSON.stringify(currentFormValue[strField]);
          }
        } else {
          bHasChanged = formValue !== currentFormValue[strField];
        }
      }
    });

    if (bHasChanged) {
      this.notificationService.sendConfirmation('confirm_discard', 'confirm_discard_header', 'default')
      .filter(confirmation => confirmation.answer == true)
      .subscribe(() => {
        this.dialogRef.close({action: 'cancel'});
      });
    } else {
      this.dialogRef.close({action: 'cancel'});
    }
  }

  /**
   * This will copy the value of the form to primary form
   * @param formGroup
   * @param fieldKey
   * @param fieldType
   * @param formIndex
   * @param attrIndex
   */
  copyToPrimary(formGroup: FormGroup, fieldKey: string, fieldType: string, formIndex: number, attrIndex: number) {

    this.fieldForm.forEach((form, index) => {
      if (form['is_primary']) {
        let originalField = this.removeAppendedIndex(fieldKey);
        let fieldOfPrimaryRecord = sprintf('%s-%s', originalField, index);
        let valueToCopy = formGroup.controls[fieldKey].value;
        let attrValueToCopy = (typeof valueToCopy === 'object' && valueToCopy) ? valueToCopy[attrIndex] : [];
        let isValid = true;

        if (this.strModule === 'assets') {
          let primaryAssetType = sprintf('asset_type_id-%s', index);
          let secondaryAssetType = sprintf('asset_type_id-%s', formIndex);

          let primaryAssetTypeValue = (this.relateFields[primaryAssetType]['value']) ? this.relateFields[primaryAssetType]['value']['id'] : null;
          let secondaryAssetTypeValue = (this.relateFields[secondaryAssetType]['value']) ? this.relateFields[secondaryAssetType]['value']['id'] : null;

          if (fieldKey.includes('attributes') && primaryAssetTypeValue !== secondaryAssetTypeValue) {
            this.notificationService.notifyWarning('copy_attributes_not_allowed');
            isValid = false;
          }

          if (isValid) {
            let primaryFormValue = this.fieldForm[index]['groups'].controls[fieldOfPrimaryRecord].value;
            if (fieldKey.includes('asset_type_id')) {
              this.assetAttributeChange(secondaryAssetTypeValue, index);
            } else if (fieldKey.includes('attributes') && typeof primaryFormValue !== 'string') {
              primaryFormValue[attrIndex] = attrValueToCopy;
              valueToCopy = primaryFormValue;
            }
          }
        }

        let bHasInvoice = this.selectedRecords[index]['has_invoice'];

        if (this.strModule === 'jobs' && bHasInvoice && (fieldKey.includes('billable') || fieldKey.includes('invoicing_type'))) {
          this.notificationService.notifyWarning('cannot_copy_with_invoice');
          isValid = false;
        }

        if (isValid) {

          let indexField = this.getFieldIndex(fieldOfPrimaryRecord, this.fieldForm[index]);

          if (indexField > -1) {
            this.fieldForm[index]['fields'][indexField]['default_value'] = valueToCopy;
            this.fieldForm[index]['groups'].controls[fieldOfPrimaryRecord].setValue(valueToCopy);
          }

          if (fieldType === 'relate') {
            let selectedRelateField = this.relateFields[fieldKey];
            this.relateFields[fieldOfPrimaryRecord]['value'] = selectedRelateField['value']
          }

          if (this.isAddressField(fieldType)) {
            let addressValueToCopy = this.fieldForm[formIndex]['address_group'][fieldKey].value;
            this.fieldForm[index]['address_group'][fieldOfPrimaryRecord].patchValue({
              latitude: addressValueToCopy.latitude,
              longitude: addressValueToCopy.longitude,
              level: this.stringService.fallsBackTo(addressValueToCopy.level, null),
              lot_number: this.stringService.fallsBackTo(addressValueToCopy.lot_number, null),
              unit: this.stringService.fallsBackTo(addressValueToCopy.unit, null),
              building_name: this.stringService.fallsBackTo(addressValueToCopy.building_name, null),
              city: this.stringService.fallsBackTo(addressValueToCopy.city, null),
              zip: this.stringService.fallsBackTo(addressValueToCopy.zip, null),
              state: this.stringService.fallsBackTo(addressValueToCopy.state, null),
              country: this.stringService.fallsBackTo(addressValueToCopy.country, null),
              street: this.stringService.fallsBackTo(addressValueToCopy.street, null)
            });
            this.addressComponent.setCountriesToAddressForm();
          }

          if (this.fieldForm[index]['fields'][indexField]['controlType'] == 'date' || this.fieldForm[index]['fields'][indexField]['controlType'] == 'datetime') {
            this.parentValue = {
              value: valueToCopy,
              control_type: this.fieldForm[index]['fields'][indexField]['controlType'],
              field_name: fieldOfPrimaryRecord,
            }
          }


          this.fieldPatchValue(fieldOfPrimaryRecord, valueToCopy, index);
        }
      }
    });
  }

  /**
   * This will process the merging record
   */
  mergeRecord() {
    this.bSubmitted = true;
    let strPrimaryRecordId: string = null;
    let objDataToMerge: object = {}; // can't assigned an interface due to key and value are dynamic data.
    let dataToDelete: Array<string> = [];
    let bIsPrimaryInvoicingTypeNotAllowed: boolean = false;
    let arInvoicingTypeWithInvoice: Array<string> = [];

    if (this.strModule === 'jobs') {
      this.selectedRecords.forEach((arRecord, intIndex) => {
        if (arRecord['has_invoice']) {
          let strInvoicingType: string = this.fieldForm[intIndex]['groups']['controls']['invoicing_type-'+intIndex]['value'];
          arInvoicingTypeWithInvoice.push(strInvoicingType);
        }
      });

      this.fieldForm.forEach((objForm, intIndex) => {
        if (objForm['is_primary']) {
          let strInvoicingType: string = objForm['groups']['controls']['invoicing_type-'+intIndex]['value'];

          if (arInvoicingTypeWithInvoice.length > 0 && !arInvoicingTypeWithInvoice.includes(strInvoicingType)) {
            bIsPrimaryInvoicingTypeNotAllowed = true;
          }
        }
      });
    }

    if(bIsPrimaryInvoicingTypeNotAllowed) {
      this.notificationService.notifyWarning('merging_job_record_not_allowed');
      this.bSubmitted = false;
    } else {
      this.notificationService.sendConfirmation('merge_record_warning', 'merge_record_header')
      .subscribe(confirmation => {
        if (confirmation.answer) {
          this.fieldForm.map((form, index) => {
            if (form['is_primary']) {
              let arRawData: Array<[]> = form['groups'].getRawValue();
              let objNewData = {};
              let arFields = Object.keys(arRawData);

              arFields.map(field => {
                let strRelatedData = (this.relateFields[field]) ? this.relateFields[field]['value']['id'] : null;
                let strField = this.removeAppendedIndex(field);

                if (this.isAddressField(strField)) {
                  objNewData[strField] = this.fieldForm[index]['address_group'][field].getRawValue();
                } else {
                  objNewData[strField] = (strRelatedData) ? strRelatedData : arRawData[field];
                }

                if (strField.includes('email_address') && isArray(arRawData[field])) {
                  let arEmail: Array<EmailAddress> = [];

                  arRawData[field].map(objEmail => {
                    arEmail.push({
                      id: objEmail['id'],
                      text: objEmail['text'],
                      primary: objEmail['primary'],
                      email: objEmail['text']
                    });
                  });

                  objNewData[strField] = arEmail;
                }
              });

              objDataToMerge = objNewData;
              strPrimaryRecordId = this.selectedRecords[index]['id'];

            } else {
              dataToDelete.push(this.selectedRecords[index]['id']);
            }
          });

          this.recordService.mergeRecord(this.strModule, strPrimaryRecordId, objDataToMerge, dataToDelete).subscribe(response => {
            if (response.status === 200) {
              this.dialogRef.close({
                action: 'success',
                response: response.body
              });
            } else if (response.status == 202) {
              this.bSubmitted = false;

              const parts = [];
              const fieldName = get(response.body, 'error.field_name');

              if (filled(fieldName)) {
                this._translations.initializeTransalateables([fieldName]);

                parts.push(spf('%s:', {
                    args: [this._translations.getTranslation(fieldName)]
                }));
              }

              const description = get(response.body, 'error.message', 'Unknown validation error. Please contact support.');

              this._translations.initializeTransalateables([description]);

              parts.push(this._translations.getTranslation(description));

              this.error$.next(parts.join(' ').trim());
            } else {
              this.notificationService.sendNotification('not_allowed', 'fields_marked_are_required', 'danger');
              this.bSubmitted = false;
            }
          });
        } else {
          this.bSubmitted = false;
        }
      });
    }
  }

  /**
   * This is for asset module when asset type is change
   * is should update the attribute field
   * @param assetTypeId
   * @param formIndex
   */
  assetAttributeChange(assetTypeId: string, formIndex: number) {
    let attributeField = 'attributes-'+formIndex;
    let hasAttributes = this.fieldForm[formIndex]['fields'].findIndex(objField => (objField['key'] == attributeField));

    // If indexes for attributes is found, proceed.
    if (hasAttributes > -1) {
      this.fieldForm[formIndex]['fields'][hasAttributes]['asset_type_value'] = [];
      this.fieldForm[formIndex]['fields'][hasAttributes]['default_value'] = [];
      this.fieldForm[formIndex]['groups']['controls'][attributeField]['value'] = [];
      this.fieldForm[formIndex]['fields'][hasAttributes]['is_loading'] = true;

      let arFilter: any = { 'asset_types.id': assetTypeId };

      if (assetTypeId) {
        this.recordService.getRecordRelate('asset_types', '', '', false, arFilter, 10, false).subscribe( results => {
          let arValue = (results[0] != undefined) ? results[0]['attributes'] : [];

          this.fieldForm[formIndex]['fields'][hasAttributes]['is_loading'] = false;
          this.fieldForm[formIndex]['fields'][hasAttributes]['asset_type_value'] = arValue;
          this.fieldForm[formIndex]['fields'][hasAttributes]['default_value'] = arValue;
          this.fieldForm[formIndex]['groups']['controls'][attributeField]['value'] = arValue;
          arValue.forEach(attr_field => {
            this.fieldForm[formIndex]['radio_model'][attr_field['key']] = false;
          });
        });
      } else {
        this.fieldForm[formIndex]['fields'][hasAttributes]['is_loading'] = false;
        this.fieldForm[formIndex]['fields'][hasAttributes]['asset_type_value'] = [];
        this.fieldForm[formIndex]['fields'][hasAttributes]['default_value'] = [];
        this.fieldForm[formIndex]['groups']['controls'][attributeField]['value'] = [];
      }

    }
  }

  /**
   *
   * @param arSiteData
   * @param strSiteId
   * @param strFieldName
   * @param intFormIndex
   */
  siteFieldChange(arSiteData, intFormIndex: number) {
    if (this.strModule === 'jobs') {
      let objAddressValue = arSiteData['address'];
      this.fieldForm[intFormIndex]['address_group']['address-'+intFormIndex].patchValue({
        latitude: objAddressValue.latitude,
        longitude: objAddressValue.longitude,
        level: this.stringService.fallsBackTo(objAddressValue.level, null),
        lot_number: this.stringService.fallsBackTo(objAddressValue.lot_number, null),
        unit: this.stringService.fallsBackTo(objAddressValue.unit, null),
        building_name: this.stringService.fallsBackTo(objAddressValue.building_name, null),
        city: this.stringService.fallsBackTo(objAddressValue.city, null),
        zip: this.stringService.fallsBackTo(objAddressValue.zip, null),
        state: this.stringService.fallsBackTo(objAddressValue.state, null),
        country: this.stringService.fallsBackTo(objAddressValue.country, null),
        street: this.stringService.fallsBackTo(objAddressValue.street, null)
      });
      this.addressComponent.setCountriesToAddressForm();
    }
  }

  /**
   * When asset type relate field is change. We should
   * call the process of asset attribute change
   * @param event
   * @param strFieldName
   * @param intFormIndex
   */
  relateFieldModelChange(event, strFieldName: string, intFormIndex: number) {
    let strId = event ? event['id'] : null;

    if (strFieldName.includes('asset_type_id')) {
      this.assetAttributeChange(strId, intFormIndex);
    }

    if (strFieldName.includes('site_id')) {
      this.siteFieldChange(event, intFormIndex);
    }
  }

  /**
   * This will rename the key of the given object
   * @param object
   * @param key
   * @param newKey
   * @returns
   */
  renameKey = (object, key, newKey) => {
    const clone = (obj) => Object.assign({}, obj);
    const clonedObj = clone(object);
    const targetKey = clonedObj[key];

    delete clonedObj[key];

    clonedObj[newKey] = targetKey;

    return clonedObj;

  };

  /**
   * Check if the input number is valid
   * @param event
   */
  validateInputNumber(event) {
    if (event.which != 8 && event.which != 0 && event.which !== 46 && event.which < 48 || event.which > 57) {
        event.preventDefault();
    }
  }

  /**
   * Initialize the ng select field
   * Field should be listed in this.arNgSelectFields
   *
   * @param field
   */
   initializeSelectRelateField(field: string) {
    let bHasAdditionalFilter: boolean = this.strModule !== 'asset_types';
    // Initialize a variable for filters.
    let objFilter: object = {};
    // If the field hasf filter, place them in the arFilter.
    if (this.relateFields[field]['filter'] != undefined) {
      objFilter = this.relateFields[field]['filter'];
    }
    let initialValue = this.relateFields[field]['initial_value'];
    // Hide the laoder.
    this.relateFields[field].loader = false;
    // Initialize the list observable.
    this.relateFields[field].obv = concat(
      of(initialValue),
      this.relateFields[field].typehead.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.relateFields[field].loader = true),
        switchMap(term => this.recordService.getRecordRelate(this.relateFields[field]['module'], term, '', false, objFilter, 10, bHasAdditionalFilter).pipe(
          tap(() => {
            this.relateFields[field].loader = false;
          })
        ))
      )
    );
  }

  /**
   * Check if the field is address
   * @param field
   * @returns
   */
  isAddressField(field: string) {
    return field.includes('address') && !field.includes('email_address');
  }

  /**
   * This will set a value for parent to pass
   * to the child component
   * @param value
   */
  setValueFromParent(value: any) {
    // We need to clear the last value of parent.
    this.parentValue = value;
  }

  /**
   * This will compare if address1 to address 2
   * has different value
   * @param objAddress1
   * @param objAddress2
   * @returns
   */
  compareAddressValue(objAddress1: Address, objAddress2: Address) {
    // We need to create an object so the order of field is the same.
    let objAddressOne = {
      building_name: this.stringService.fallsBackTo(objAddress1.building_name, null),
      city: this.stringService.fallsBackTo(objAddress1.city, null),
      country: this.stringService.fallsBackTo(objAddress1.country, null),
      latitude: objAddress1.latitude,
      longitude: objAddress1.longitude,
      level: this.stringService.fallsBackTo(objAddress1.level, null),
      lot_number: this.stringService.fallsBackTo(objAddress1.lot_number, null),
      state: this.stringService.fallsBackTo(objAddress1.state, null),
      street: this.stringService.fallsBackTo(objAddress1.street, null),
      unit: this.stringService.fallsBackTo(objAddress1.unit, null),
      zip: this.stringService.fallsBackTo(objAddress1.zip, null),
    };

    let objAddressTwo = {
      building_name: this.stringService.fallsBackTo(objAddress2.building_name, null),
      city: this.stringService.fallsBackTo(objAddress2.city, null),
      country: this.stringService.fallsBackTo(objAddress2.country, null),
      latitude: objAddress2.latitude,
      longitude: objAddress2.longitude,
      level: this.stringService.fallsBackTo(objAddress2.level, null),
      lot_number: this.stringService.fallsBackTo(objAddress2.lot_number, null),
      state: this.stringService.fallsBackTo(objAddress2.state, null),
      street: this.stringService.fallsBackTo(objAddress2.street, null),
      unit: this.stringService.fallsBackTo(objAddress2.unit, null),
      zip: this.stringService.fallsBackTo(objAddress2.zip, null),
    }

    if (JSON.stringify(objAddressOne) !== JSON.stringify(objAddressTwo)) {
      return true;
    }

    return false;
  }

}

export interface DialogData {
  records : Array<object>;
  module: string;
}

export interface ModuleConfig {
  record_details?: Array<object>;
  used_fields?: Array<object>;
  record_view?: Array<object>;
}


export interface MergeRecordData {
  record_id : string;
  data?: object;
}

export interface RelateField {
  obv: Observable<Select[]>,
  typehead: Subject<string>,
  loader: boolean,
  placeholder: string,
  name: string,
  module: string,
  initial_value: Select[],
  value: any,
}

export interface EmailAddress {
  id: string,
  text: string,
  primary: boolean,
  email: string,
}

export interface ParentValue {
  value: any,
  control_type: string,
  field_name: string,
}
