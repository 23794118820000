<!-- sub steps -->
<br />
<ng-content></ng-content>
<div class="d-flex justify-content-center mt-4">
  <button
    *ngIf="canBeSkipped"
    class="btn btn-primary"
    [disabled]="inProgress$ | async"
    id="btn-skip-step"
    (click)="onSkipStep()"
  >
    {{ "skip_this_step" | translate }}
  </button>
</div>
