export class Json<T>{
  default_value: T;
  key: string;
  label: string;
  controlType: string;
  space: number;
  required: boolean = false;
  readonly: boolean;
  validator: boolean;
  max_length: number;
  min_length: number;
  format: object;
  note: string;

  constructor(options: {
      default_value?: T,
      key?: string,
      label?: string,
      controlType?: string,
      space?: number,
      required?: boolean,
      readonly?: boolean,
      validator?: boolean,
      max_length?: number,
      min_length?: number,
      format?: object,
      note?: string
    } = {}) {
      this.default_value = options.default_value,
      this.key = options.key,
      this.label = options.label,
      this.controlType = options.controlType,
      this.space = (options.space == 2) ? 12 : 6,
      this.required = options.required || this.required,
      this.readonly = options.readonly,
      this.validator = options.validator,
      this.max_length = options.max_length,
      this.min_length = options.min_length,
      this.format = options.format,
      this.note = options.note;
  }
}