<header>
	<div class="d-flex dialog-header">
		<h3 class="dialog-title" translate>
			<fa-icon [icon]="['fas', 'eye']" transform="shrink-3" class="dialog-title"></fa-icon>
			{{ 'quote_preview' | translate }}
		</h3>
		<div class="ml-auto d-flex">
			<ul class="list-inline pull-right">

				<li class="list-inline-item">
					<button (click)="downloadPdf()" class="btn ml-1 btn-primary" [disabled]="!showPreview">
						<fa-icon [icon]="['fas', 'download']" class="fa-lg"></fa-icon>
					</button>
				</li>

				<li class="list-inline-item">
					<button (click)="sendQuote()" class="btn ml-1 btn-success" [disabled]="!showPreview">
						<fa-icon *ngIf="!showEmail" [icon]="['fas', 'envelope']" class="fa-lg"></fa-icon>
						<fa-icon *ngIf="showEmail" [icon]="['fas','spinner']" [spin]="true" aria-hidden="true" class="fa-lg"></fa-icon>
					</button>
        </li>

        <li class="list-inline-item">
            <button (click)="closeDialog()"  type="button" class="btn ml-1 btn-danger">
              &nbsp;<fa-icon [icon]="['fas', 'times']" class="fa-lg"></fa-icon>&nbsp;
            </button>
          </li>
        
			</ul>
		</div>
	</div>
	<hr />
</header>

<div class="resp-container">

  <span [hidden]="showPreview" class="center text-secondary">
    <fa-icon [icon]="['fas','spinner']" [spin]="true" aria-hidden="true"></fa-icon>
    {{ 'loading_pdf' | translate }}
  </span>

  <div [hidden]="!showPreview" class="bg-secondary p-2">
		<iframe [src]="strUrl"></iframe>
  </div>

</div>
  


