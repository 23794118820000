<form [formGroup]="form">
  <div class="container-fluid">
    <div class="row" >
      <div class="col-xs-6 col-md-6 form-group">
        <strong id="quoteStepTitle">{{ 'create_opportunity' | translate }}</strong>
        <br />
        <small>{{ 'fill_in_details' | translate }}</small>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let data of arFields" class="col-{{data.space}} mb-3">
        <ng-container
          *ngIf="(( data.label != 'created_by' && data.label != 'modified_by' && data.label != 'updated_at' && data.label != 'created_at') && strMode == 'add') || strMode == 'edit'">
          <app-edit-field [item]="data" [form]="form" [bSubmitted]="bSubmitted" [strMode]="strMode"
            [strModule]="strModule"></app-edit-field>
        </ng-container>
      </div>
    </div>
    <!-- Stepper Next -->
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <button id="quoteStepNext" type="button" class="btn btn-primary btn-block btn-lg p-2" (click)="next()">
          <strong>{{ 'next' | translate }}</strong>
        </button>
      </div>
    </div>
  </div>
</form>