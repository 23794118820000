import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { AdvanceSearchboxTemplate } from '../objects/advance-searchbox';

@Injectable()
export class AdvanceSearchboxService {

  advanceSearchboxUrl = environment.url + '/advanced_searchbox';
  filterUrl = environment.url + '/list/filters';

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Get the searchbox metadata for the specified module
   *
   * @param   {string}      module
   *
   * @return  {Observable<AdvanceSearchboxTemplate>[]}
   */
  getMetadata(module: string): Observable<AdvanceSearchboxTemplate[]> {
    const body = new URLSearchParams();

    const metadataUrl = this.advanceSearchboxUrl + '/metadata/' + module;
    return this.http.post<AdvanceSearchboxTemplate[]>(metadataUrl, body.toString());
  }

  /**
   * Get the searchbox filters for the specified module
   *
   * @param   {string}      module
   *
   * @return  {Observable<object>[]}
   */
   getFilters(module: string): Observable<object[]> {
    const body = new URLSearchParams();

    const metadataUrl = this.filterUrl + '/' + module;
    return this.http.post<object[]>(metadataUrl, body.toString());
  }
}
