import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingNewComponent } from './listing-new.component';
import { FontAwesomeModule } from '../../modules/font-awesome.module';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DirectivesModule } from '../../modules/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../modules/material.module';
import { AddComponent } from './components/add/add.component';
import { SortComponent } from './components/sort/sort.component';
import { FilterComponent } from './components/filter/filter.component';
import { ListModule } from '../listing/list/list.module';
import { AsBoxModule } from '../../external-libraries/ngx-advanced-searchbox/src/public_api';
import { SharedModule } from '../../modules/shared.module';
import { RouterModule } from '@angular/router';
import { ActionsComponent } from './components/actions/actions.component';
import { SharedInputModule } from '../../modules/shared-input.module';

@NgModule({
  declarations: [
    ListingNewComponent,
    PaginationComponent,
    AddComponent,
    SortComponent,
    FilterComponent,
    ActionsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    DirectivesModule,
    TranslateModule,
    MaterialModule,
    ListModule,
    AsBoxModule.forRoot(),
    RouterModule
  ],
  exports: [
    ListingNewComponent,
    ActionsComponent
  ]
})
export class ListingNewModule { }
