import { filter, tap, first, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Client } from '../../../../objects/client';
import { environment } from '../../../../../environments/environment';
import { NotificationService } from '../../../../services/notification.service';
import { ChargebeeService } from '../../../../services/chargebee/chargebee.service';
import { ENTERPRISE_PLAN, ADVANCED_PLAN, STARTER_PLAN } from '../../../../objects/subscription-plans';
import { ClientsListStoreService } from '../../../../services/clients-list-store/clients-list-store.service';
import { currency_codes } from "../../../../../assets/api/currency_codes.json";
import { ClientStoreService } from '../../../../services/client-store.service';
import { ChangePaymentComponent } from '../change-payment/change-payment.component';
import { BillingHistoryComponent } from '../billing-history/billing-history.component';

export type SubscriptionPlan = {

  /**
   * ID of the subscription.
   *
   * @type {String}
   */
  id: string;

  /**
   * Display title for the given subscription.
   *
   * @type {String}
   */
  title: string;

  /**
   * Brief summary about this subscription. May contain its inclusions,
   * whatsoever.
   *
   * @type {String}
   */
  description: string;

  /**
   * Monthly price of this subscription.
   *
   * @type {Number}
   */
  price: string;

  /**
   * In the subscription plan selection page, should this plan
   * be selected immediately?
   *
   * @type {Boolean}
   */
  default: boolean;

  /**
   * Subscription plan duration
   *
   * @type {String}
   */
  period_unit?: string;

  /**
   * plan currency
   *
   * @type {String}
   */
  currency?: string;

  /**
   * plan type
   *
   * @type {String}
   */
  item_id?: string;
};

@Component({
  selector: 'manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss']
})
export class ManageSubscriptionComponent implements OnInit {

  /**
   * Environment variables for Fieldmagic Cloud
   *
   * @type {Object}
   */
  env = environment;

  /**
   * These are the subscription plans that Fieldmagic Cloud offer.
   *
   * @type {ArrayBufferLike}
   */
  plans: SubscriptionPlan[] = [];

  /**
   * These are the subscription plans that Fieldmagic Cloud offer filtered by period unit.
   *
   * @type {ArrayBufferLike}
   */
  plansOption: SubscriptionPlan[] = [];

  descriptions = {
    starter: this.translateService.instant('subscription_starter_plan_description'),
    advanced: this.translateService.instant('subscription_advanced_plan_description'),
    enterprise: this.translateService.instant('subscription_enterprise_plan_description'),
  }

  planListArrangement = [
    'fieldmagic-starter',
    'fieldmagic-advanced',
    'fieldmagic-enterprise'
  ]

  /**
   * A subscription that is manually triggered whenever the
   * selected subscription id is changed.
   *
   * @type {BehaviorSubject<string>}
   */
  selectedPlan$: BehaviorSubject<SubscriptionPlan> = new BehaviorSubject(null);

  /**
   * Determines whether the Chargebee Hosted Page checkout succeeds.
   *
   * @type {Subject<boolean>}
   */
  checkoutSucceeded: Subject<boolean> = new Subject();

  /**
   * Emits when the Chargebee Hosted Page checkout succeeds.
   *
   * @type {Observable<boolean>}
   */
  checkoutSucceeded$: Observable<boolean> = this
    .checkoutSucceeded
    .asObservable()
    .pipe(
      filter(checkoutSucceeded => checkoutSucceeded === true),
      first()
    );

  /**
   * The selected subscription plan id.
   *
   * @type {String}
   */
  selectedPlanId: string;

  /**
   * Should the save button be displayed?
   *
   * @type {Boolean}
   */
  showSaveButton: boolean;

  periodUnit: string = 'year';

  loading: boolean = true;

  /**
   * Holds the current currency we took from the
   * details API.
   *
   * @var {string}
   */
  strCurrentCurrency: string;

  /**
   * The external name of the plan.
   *
   * @var {string}
   */
  strPlanName: string;

  /**
   * The status of the plan.
   *
   * @var {string}
   */
  strStatus: string;

  /**
   * The billing period of the plan. (Monthly or Annual)
   *
   * @var {string}
   */
  strBillingPeriod: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public client: Client,
    protected dialogRef: MatDialogRef<ManageSubscriptionComponent>,
    protected chargebeeService: ChargebeeService,
    protected translateService: TranslateService,
    protected notificationService: NotificationService,
    protected clientsListStoreService: ClientsListStoreService,
    protected clientStoreService: ClientStoreService,
    protected dialog: MatDialog
  ) { }

  get planList(): SubscriptionPlan[] {
    return this.plans.filter( plan => plan.period_unit === this.periodUnit);
  }

  ngOnInit() {
    this.getPlanList();

    this.selectedPlan$.subscribe((selectedPlan) => {
      this.showSaveButton = selectedPlan !== null;
    });

    // When the checkout succeeds and this component is closed, how a notification to
    // the user informing them that their checkout was successful.
    forkJoin([this.checkoutSucceeded$, this.dialogRef.beforeClosed()])
      .pipe(
        tap(
          () => this
            .notificationService
            .notifySuccess('subscription_plan_update_successful')
        )
      )
      .subscribe();

    // As of writing, the advanced plan should be selected by default.
    let defaultPlans = this.plans.filter((plan) => plan.default === true);
    if (defaultPlans.length) {
      this.selectedPlanId = defaultPlans[0].id;
      this.setSelectedPlan(this.selectedPlanId);
    }
  }

  /**
   * Lets open the payment method screen.
   *
   * @returns {void}
   */
  openPaymentMethodPage(): void {
    this.dialog.open(ChangePaymentComponent, {
      height: '825px',
      width: '455px',
      data: this.client
    });
  }

  /**
   * Purchases a new subscription for the client. This will open up a little
   * window where the user can fill in the billing address, credit card info and
   * etc.
   *
   * @param {String} selectedPlanId
   *
   * @returns {void}
   */
  subscribeToPlan(selectedPlanId: string): void {
    let chargebeeInstance = this.chargebeeService.getInstance();
    let clientId = this.client.client_id;

    this.chargebeeService.getSubscriptionInfoRequest(clientId).subscribe((response) => {
      chargebeeInstance.openCheckout({
        hostedPage: () => {
          return new Promise((resolve, reject) => {
            this.chargebeeService.checkout(clientId, selectedPlanId).subscribe((checkoutResponse) => {
              resolve(checkoutResponse.body);
            });
          });
        },
        success: (hostedPageId) => this.checkoutSuccessCallback(),
        close: (hostedPageId) => this.chargebeePortalCheckoutClose()
      });
    });
  }

  /**
   * Emits a plan change which in turn updates the selected
   * plan object for this class.
   *
   * @param {String} planId
   */
  setSelectedPlan(planId: string): void {
    this.selectedPlan$.next(this.plans.filter(plan => plan.id === planId).shift());
  }

  /**
   * get the plan list
   *
   * @returns {void}
   */
  getPlanList(): void {
    this.chargebeeService.getPlanList(this.client.client_id)
    .subscribe( response => {

      response['plans'].forEach( item => {
        let strPlanType = item.item_id.split('-')[1];
        this.plans.push({
          id: item.id,
          title: item.external_name,
          description: this.descriptions[strPlanType] || '',
          price: (item.price/100).toString(),
          default: false,
          period_unit: item.period_unit,
          currency: this.getCurrencySymbol(item.currency_code),
          item_id: item.item_id
        })
      });

      this.setPlanData(response['details']);
      this.onChangePeriodUnit();
      this.loading = false
    });
  }

  /**
   * reset's the selected plan and update the plan option
   *
   * @returns {void}
   */
  onChangePeriodUnit(): void {
    this.selectedPlanId = null;
    this.selectedPlan$.next(null);
    this.plansOption = [];
    let planList = this.plans.filter( plan => plan.period_unit === this.periodUnit);
    this.planListArrangement.forEach( planType => {

      var planData = planList.find( plan => plan.item_id.includes(planType));
      if (planData) {

        this.plansOption.push(planData);
      }
    });
  }

  /**
   * open billing history dialog
   *
   * @returns {void}
   */
  openBillingHistory(): void {
    this.dialog.open(BillingHistoryComponent, {
      height: '425px',
      width: '535px',
      data: this.client
    });
  }

  /**
   * Called when Chargebee's checkout portal is closed. As of writing,
   * all this does is close the plan selection dialog box, since the
   * user is done interacting with the portal, there's no more use
   * in displaying the selection component to the user.
   *
   * @returns {void}
   */
  protected chargebeePortalCheckoutClose(): void {
    this.dialogRef.close();
  }

  /**
   * Gets called when the chargebee checkout is successful
   *
   * @returns {void}
   */
  protected checkoutSuccessCallback(): void {
    this.chargebeeService.syncSubscriptionInfoToClient(this.client);
    this.checkoutSucceeded.next(true);
  }

  private getCurrencySymbol(currency: string): string {
    let currencyConfig = currency_codes.find(({ code }) => code === currency);

    return currencyConfig ? currencyConfig.symbol : '$'
  }

  /**
   * Set the plan data.
   *
   * @param {currency: string, status: string, plan_id: string, period: string} arPlanData
   *
   * @return {void}
   */
  private setPlanData(arPlanData: {currency: string, status: string, plan_id: string, period: string}): void {

    this.strCurrentCurrency = arPlanData['currency'];
    this.strStatus = arPlanData['status'];
    this.strBillingPeriod = arPlanData['period']

    let objPlan = this.plans.find(item => (item.id === arPlanData['plan_id']));
    if (objPlan) {
      this.strPlanName = objPlan.title;
    } else {
      this.strPlanName = this.translateService.instant('invalid_plan');
    }

  }
}
