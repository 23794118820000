import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from "@angular/core";
import { AccountingSystemService, ProgressEvent } from "../../../services/accounting_system.service";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import { finalize, filter } from "rxjs/operators";

@Component({
  selector: 'accounting-systems-common-import-progress',
  templateUrl: './import-progress.component.html'
})
export class ImportProgressComponent implements OnInit, OnDestroy {
  /**
   * Component event
   *
   * @var {EventEmitter<ProgressEvent>}
   */
  @Output('event-progress') onProgress: EventEmitter<ProgressEvent> = new EventEmitter;

  /**
   * Progress unique id by the caller
   *
   * @var {any}
   */
  @Input('progress-id') id: any;

  /**
   * Current progress count
   *
   * @var {number}
   */
  progress: number = 0;

  /**
   * Progress total
   *
   * @var {null | number}
   */
  total: null | number;

  /**
   * Flag if the current action is batched
   *
   * @var {boolean}
   */
  isBatch: boolean = false;

  /**
   * Has total
   *
   * @var {boolean}
   */
  hasTotal: boolean = false;

  /**
   * List of subs to be cleaned up later
   *
   * @var {Subscription[]}
   */
  protected subscriptions: Subscription[] = [];

  /**
   * create instance
   *
   * @param {AccountingSystemService} accounting
   */
  constructor(
    protected accounting: AccountingSystemService
  ) {}

  /**
   * {@inheritdoc}
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.accounting.getProgressEvent$()
      .pipe(
        finalize(() => this.isBatch = false),
        filter(({ id }) => this.id === id),
      )
      .subscribe(({ progress, total, id }) => {

        if (total !== null) {
          this.hasTotal = true; 
        }

        this.progress = progress;
        this.total = total;
        this.isBatch = true;

        this.onProgress.emit({
          progress, total, id,
        });
      })
    );
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Retrieved translation parameter values
   *
   * @returns {object}
   */
  getImportingTranslateParameters() {

    if (this.hasTotal === false) {
      return {
        progress: this.progress
      }
    } else {
      return {
        progress: this.progress,
        total: this.total,
        percentage: _.round((this.progress / this.total) * 100, 2)
      };
    }


  }
}
