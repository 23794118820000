import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { PromptColumnClasses } from '../shared/classes';
import { MatChipInputEvent } from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { isNil } from 'lodash';
import { LooseObject } from '../../../../objects/loose-object';
import { NotificationService } from '../../../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'tr[app-checklist-prompt]',
  templateUrl: './checklist-prompt.component.html',
  styleUrls: ['../update-checklist.component.scss']
})
export class ChecklistPromptComponent implements OnInit {
  /**
   * The prompt name textarea.
   *
   * @type {CdkTextareaAutosize} promptName
   */
  @ViewChild('promptName') promptName: CdkTextareaAutosize;

  /**
   * The prompt instructions type textarea.
   *
   * @type {CdkTextareaAutosize} instructionsText
   */
  @ViewChild('instructionsText') instructionsText: CdkTextareaAutosize;

  /**
   * The prompt asset instructions type textarea.
   *
   * @type {CdkTextareaAutosize} assetInstructionsText
   */
  @ViewChild('assetInstructionsText') assetInstructionsText: CdkTextareaAutosize;

  /**
   * Contains the prompt data.
   *
   * @type {LooseObject} objPrompt
   */
  @Input() objPrompt: LooseObject;

  /**
   * The types of prompts (pass or fail, text, number, etc.).
   *
   * @type {string[]} arPromptTypes
   */
  @Input() arPromptTypes: string[];

  /**
   * Index of row this instance of the checklist prompt is in.
   *
   * @type {number} numIndex
   */
  @Input() numIndex: number;

  /**
   * Dropdown items for 'Instructions' prompt type.
   *
   * @type {FieldEntry[]} arFieldEntries
   */
  @Input() arFieldEntries: FieldEntry[];

  /**
   * Dropdown items for 'Instructions' prompt type if checklist type is 'asset'.
   *
   * @type {FieldEntry[]} arAssetFieldEntries
   */
  @Input() arAssetFieldEntries: FieldEntry[];

  /**
   * Dropdown items for 'Instructions' prompt type if checklist type is 'asset'.
   *
   * @type {object} objSearcher
   */
  @Input() objSearcher: object;

  /**
   * Type of checklist currently opened.
   *
   * @type {'job_checklist' | 'quote_checklist' | 'assets_checklist'} strChecklistType
   */
  @Input() strChecklistType: 'job_checklist' | 'quote_checklist' | 'assets_checklist';

  /**
   * Category under which this prompt belongs.
   *
   * @type {'main_prompts' | 'per_asset_prompts'} strPromptCategory
   */
  @Input() strPromptCategory: 'main_prompts' | 'per_asset_prompts'

  /**
   * Contains the class names used for the width of the prompt columns.
   *
   * @type {PromptColumnClasses} objPromptColumnClasses
   */
  @Input() objPromptColumnClasses: PromptColumnClasses;

  /**
   * Inspection periods for asset checklists (Annual, Monthly, etc.).
   *
   * @type {string[]} arPeriodList
   */
  @Input() arPeriodList: string[];

  /**
   * Flag that records if the prompt is under a prompt group or not.
   *
   * @type {boolean} bIsUnderGroup
   */
  @Input() bIsUnderGroup: boolean = false;

  /**
   * Emits the row index.
   *
   * @type {EventEmitter<number>} rowIndexEmitter
   */
  @Output() rowIndexEmitter: EventEmitter<number> = new EventEmitter();

  /**
   * Emits a boolean value stating that the prompt as having been edited.
   *
   * @type {EventEmitter<boolean>} promptEditedEmitter
   */
  @Output() promptEditedEmitter: EventEmitter<boolean> = new EventEmitter();

  // For chips
  bAddOnBlur: boolean = true;
  arSeparatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private notifService: NotificationService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void { }

  /**
   * Emits the row index.
   *
   * @returns {void}
   */
  emitIndex(): void {
    this.rowIndexEmitter.emit(this.numIndex);
  }

  /**
   * Emits a boolean value stating that the prompt as having been edited.
   *
   * @returns {void}
   */
  emitAsEdited(): void {
    this.promptEditedEmitter.emit(true);
  }

  /**
   * Function that adds a chip value from array and in the input field.
   *
   * @param {MatChipInputEvent} event = triggers when chip input has been added, contains inputted value and chip input content
   * @param {'pass' | 'fail' | 'dropdown'} chipType = indication of what chip type was used (pass, fail, dropdown)
   *
   * @returns {void}
   */
  addChip(event: MatChipInputEvent, chipType: 'pass' | 'fail' | 'dropdown'): void {
    const input = event.input;
    const value = event.value;

    // If any chip type prompt value is undefined, Create it with an empty array
    if (this.objPrompt['value'][chipType] == undefined) {
       this.objPrompt['value'][chipType] = [];
    }


    // Add value to the current chip input
    if ((value || '').trim()) {
        this.objPrompt['value'][chipType].push(value.trim());
    }

    // Reset the current chip input
    if (input) {
        input.value = '';
    }
  }

  /**
   * Function that removes a chip value from array and in the input field.
   *
   * @param {MatChipInputEvent} event = triggers when chip input has been added, contains inputted value and chip input content
   * @param {'pass' | 'fail' | 'dropdown'} chipType = indication of what chip type was used (pass, fail, dropdown)
   *
   * @returns {void}
  */
  removeChip(event: MatChipInputEvent, chipType: 'pass' | 'fail' | 'dropdown'): void {
    let numChipIndex: number = this.objPrompt['value'][chipType].indexOf(event);

    if (numChipIndex >= 0) {
      this.objPrompt['value'][chipType].splice(numChipIndex, 1);
    }
  }

  /**
   * Identify the prompt type and reset when switched.
   *
   * @param {string} strPromptType = the type of prompt.
   *
   * @returns {void}
   */
  checkPromptType(strPromptType: string): void {
    // Reset prompt value
    this.objPrompt['value'] = {};
    // If prompt type is pass/fail, automatically set 'pass' and 'fail' as selected values
    if (strPromptType === "pass/fail") {
      this.objPrompt['value'] = {
        fail: [this.translateService.instant('fail')],
        pass: [this.translateService.instant('pass')]
      };
    } else {
      // If prompt type is field entry of instruction, set default to empty string else to an empty array
      if (strPromptType === 'dropdown' || strPromptType === 'image') {
        this.objPrompt['value'][strPromptType] = [];
      } else {
        this.objPrompt['value'][strPromptType] = "";
      }
    }

    // Uncheck required if instruction type
    if (strPromptType === "instructions") {
      this.objPrompt['is_required'] = false;
    } else {
      this.objPrompt['is_required'] = true;
    }
  }

  /**
   * Adds variable to current string for instruction type.
   *
   * @param {string} strInstructionVariable
   *
   * @returns {void}
   */
  addInstructionVariable(strInstructionVariable: string): void {
    // Check if there's already available field type
    if (strInstructionVariable) {
      // if instructions text is not yet set, initialize it to have the variable
      if (isNil(this.objPrompt['value']['instructions_text'])) {
        this.objPrompt['value']['instructions_text'] = "{$" + strInstructionVariable + "}";
      } else {
        // just append the variable to the current instructions text
        this.objPrompt['value']['instructions_text'] += " {$" + strInstructionVariable + "}";
      }
    } else {
      this.notifService.notifyWarning('invalid_variable_value');
    }
  }

  /**
   * Adjusts the height of the textarea whenever it is selected.
   *
   * @param {FocusEvent} event
   * @param {CdkTextareaAutosize} objTextarea
   * @param {number} numRows
   *
   * @returns {void}
   */
  setTextareaHeight(event: FocusEvent, objTextarea: CdkTextareaAutosize, numRows: number): void {
    event.target['rows'] = numRows;
    objTextarea.resizeToFitContent(true);
  }
}

interface FieldEntry {
  id: string;
  field_type: string;
  field: string;
  display: string;
}
