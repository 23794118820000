import { Component, Input, OnDestroy, OnInit, Injector } from '@angular/core';
import { FormTemplateService } from '../../services/form-template.service';
import { defer, Observable, Subscription, BehaviorSubject } from 'rxjs';
import FormTemplate from '../../entities/form-template.entity';
import { finalize } from 'rxjs/operators/finalize';
import { take } from 'rxjs/operators/take';
import { MatDialog } from '@angular/material';
import { PreviewComponent, PreviewPdfData } from './components/preview/preview.component';
import { CustomFieldsComponent } from './components/custom-fields/custom-fields.component';
import { LooseCustomField } from '../../contracts/loose-custom-field';
import { RecordService } from './../../../../../services/record.service';
import { ViewService } from './../../../../../services/view.service';
import { CUSTOM_MODULE_TEMPLATES } from '../../constants';
import * as _ from 'lodash';
import { CustomTemplate } from '../../contracts/custom-template';
import { filter, map, switchMap } from 'rxjs/operators';
import { SubscriptionRestrictionService } from './../../../../../services/subscription-restriction/subscription-restriction.service';
import { ADVANCED_PLAN } from './../../../../../objects/subscription-plans';
import { AnnualConditionReportComponent } from './dialog/annual-condition-report/annual-condition-report.component';
import { AnnualAssetReportComponent } from './dialog/annual-asset-report/annual-asset-report.component';
import { LooseObject } from './../../../../../objects/loose-object';
import { LooseModuleData } from '../../contracts/loose-module-data';

@Component({
  selector: 'form-templates-generator',
  templateUrl: './generate-pdf.component.html',
  styleUrls: ['./generate-pdf.component.scss']
})
export class GeneratePDFComponent implements OnDestroy, OnInit {
  /**
   * element attribute that will determine the current module being used
   *
   * @type {string}
   */
  @Input('module-name') moduleName: string;

  /**
   * Current module identifier
   *
   * @type {string}
   */
  @Input('module-id') moduleId: string;

  /**
   * This contains the possible filters for document data fetching etc
   *
   * @type {LooseObject}
   */
  @Input('filter') filter: LooseObject;

  /**
   * this contains all the form templates  (active) for the current module
   *
   * @type {FormTemplate[]}
   */
  templates: FormTemplate[] = [];

  /**
   * determines if the current module templates are being fetched
   *
   * @type {boolean}
   */
  isLoadingTemplates: boolean = false;

  /**
   * list of component based on document type
   *
   * @type {object}
   */
  documentTypeComponent: object = {
    site_asset_summary: AnnualAssetReportComponent,
    site_annual_condition: AnnualConditionReportComponent
  };

  /**
   * document type that will be using
   *
   * @type {string}
   */
  @Input('document-type') documentType: string | string[];

  /**
   * data from get_data request
   *
   * @type {LooseModuleData}
   */
  templateData: LooseModuleData;

  /**
   * Flagged to determined if the current feature should be displayed
   *
   * @type {boolean}
   */
  get shouldDisplay(): boolean {
    return this.isAdvancedOrEnterprise || this.customTemplates.length > 0;
  };

  /**
   * List of custom templates
   *
   * @type {CustomTemplate[]}
   */
  customTemplates: CustomTemplate[];

  /**
   * custom module data for pdf
   *
   * @type {LooseObject}
   */
  customModuleData: LooseObject = {};

  /**
   * Flag if the current plan is enterprise
   *
   * @type {boolean}
   */
  get isAdvancedOrEnterprise(): boolean {
    return this.plans.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(ADVANCED_PLAN);
  };

  /**
   * Get the total length of default templates
   *
   * @type {number}
   */
  get totalDefaultTemplates(): number {
    return this.templates.filter(objTemplate => objTemplate.documentType).length;
  };

  /**
   * Get the total length of custom templates
   *
   * @type {number}
   */
  get totalCustomTemplates(): number {
    return this.templates.filter(objTemplate => !objTemplate.documentType).length;
  };

  /**
   * INTERNAL: this contains the all subscriptions from observable that will be cleaned up after
   * this component is being destroyed
   */
  private subscriptions: Subscription[] = [];

  isInProgress$ = new BehaviorSubject<boolean>(false);

  /**
   * This indicates that all default templates should be excluded when listing the available templates
   *
   * @type {boolean}
   */
  @Input('exclude-defaults') excludeDefaultTemplates: boolean = false;

  /**
   * @type {boolean}
   */
  @Input('allow-custom-template-generate') allowCustomTemplateGenerate: boolean = false;
  @Input('previous-preview-data') previousPreviewData: PreviewPdfData;

  /**
   * @type {string}
   */
  @Input('preview-header-label') previewHeaderLabel: string;

  /**
   * @param {FormTemplateService} service
   * @param {MatDialog}           dialogFactory
   */
  constructor(
    protected service: FormTemplateService,
    protected dialogFactory: MatDialog,
    protected records: RecordService,
    protected views: ViewService,
    protected ctx: Injector,
    protected plans: SubscriptionRestrictionService
  ) { }

  /**
   * {@inheritdoc}
   */
  ngOnInit() {
    this.templates = [];
    this.customTemplates = (CUSTOM_MODULE_TEMPLATES[this.moduleName] || []).filter((template) => this.shouldDisplayCustomTemplate(template));
  }

  /**
   * retrieves all the module template
   *
   * @returns {boolean}
   */
  getTemplates(): boolean {
    // this action should only be applicable to entprise subscribers
    if (this.templates.length < 1) {
      this.loadTemplates();
    }

    return false;
  }

  /**
   * callback when a form template was clicked and will generate a PDF preview
   *
   * @param   {FormTemplate|undefined} template
   *
   * @returns {boolean}
   */
  previewTemplate(template?: FormTemplate): boolean {
    // if already in progress
    if (this.isInProgress$.getValue()) {
      return false;
    }

    if (_.isEmpty(this.customModuleData)) {
      // Close all previous dialogs
      this.dialogFactory.closeAll();
    }

    // if we had a previous preview data given from the preview component
    // we would regenerate a new preview with the selected template
    if (this.previousPreviewData !== undefined) {
      this.openPreviewComponent(template, this.previousPreviewData.moduleData, {
        custom: this.previousPreviewData.custom,
        filename: this.previousPreviewData.filename,
      });
    } else {
      this.isInProgress$.next(true);

      defer(() => {
        // This might not work when template was not provided since additional component
        // are based on the document type of a given template. Improve me...
        let documentTypeComponent = this.documentTypeComponent[_.get(template, ['documentType'])] || null;

        if (!!documentTypeComponent) {
          return this.dialogFactory.open(documentTypeComponent, {
            minWidth: '30vw',
            maxWidth: '100%',
            height: 'auto',
            data: {
              id: this.moduleId,
              has_custom_field: _.get(template, ['history', 'metadata', 'fields'], []).length > 0,
            },
          })
            .afterClosed()
            .pipe(
              filter((filter) => !!filter)
            );
        }

        return Observable.of(undefined);
      })
        .pipe(
          switchMap((filter) => {
            if (this.moduleId) {
              return this.getModuleMetadata$({
                dontIncludeTemplate: !!template,
                documentType: _.get(template, 'documentType'),
                filter: _.assign({}, filter, this.filter),
                data: this.customModuleData,
              });
            } else {
              return this.getModuleTemplate$({
                dontIncludeTemplate: !!template,
                documentType: _.get(template, 'documentType'),
                filter: _.assign({}, filter, this.filter),
                data: this.customModuleData,
              });
            }
          }),
          finalize(() => this.isInProgress$.next(false)),
          map((result) => ({
            ...result,
            template: template || result.template,
          })),
          map((result) => ({
            ...result,
            template: {
              ...result.template,
              history: {
                ...result.template.history,
                // merges the additional paths to the current template paths
                path: [
                  ... (_.isString(result.template.history.path) ? [result.template.history.path] : result.template.history.path) as string[],
                  ...result.additional_template_paths
                ]
              }
            }
          })),
          switchMap((result) => {
            if (result.template.history.metadata.fields.length > 0) {
              return this.dialogFactory.open(CustomFieldsComponent, {
                minWidth: '30vw',
                maxWidth: '100%',
                height: 'auto',
                data: {
                  moduleData: result.data,
                  fields: template.history.metadata.fields,
                  id: this.moduleId,
                  module: this.moduleName,
                },
              })
                .afterClosed()
                .pipe(
                  filter((value) => !!value),
                  map((custom) => ({
                    ...result,
                    custom,
                  }))
                );
            }

            return Observable.of(result);
          })
        )
        .subscribe((result) => {
          let option = {
            filename: result.config.filename,
            custom: _.get(result, 'custom')
          };
          if (result['additional_document']) {
            if (_.isUndefined(option.custom)) {
              option.custom = {
                additional_document: result['additional_document']
              };
            } else if (option.custom) {
              option.custom.additional_document = result['additional_document']
            }
          }
          this.openPreviewComponent(result.template, result.data, option)
        });
    }

    return false;
  }

  setCustomModuleData(moduleData: LooseObject): void {
    this.customModuleData = moduleData;
  }

  /**
   * {@inheritdoc}
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * opens a preview component for previewing the template
   *
   * @param   {FormTemplate}               template
   * @param   {LooseModuleData}            moduleData
   * @param   {OpenPreviewComponentOption} option
   *
   * @returns {void}
   */
  protected openPreviewComponent(template: FormTemplate, moduleData: LooseModuleData, option: OpenPreviewComponentOption): void {
    this.dialogFactory.open(PreviewComponent, {
      minWidth: '50%',
      maxWidth: '100%',
      height: 'auto',
      data: {
        moduleData: moduleData,
        filename: option.filename,
        module: this.moduleName,
        moduleId: this.moduleId,
        template: template,
        ...(option.custom && { custom: option.custom }),
        allowCustomTemplateGeneration: this.allowCustomTemplateGenerate,
        previewHeaderLabel: this.previewHeaderLabel,
      }
    });
  }

  /**
   * Do some additional checks to determine if the custom template should be displayed
   *
   * @param {CustomTemplate} customTemplate
   *
   * @returns {boolean}
   */
  protected shouldDisplayCustomTemplate(customTemplate: CustomTemplate): boolean {
    if (customTemplate.minimum_subscription_plan !== undefined) {
      return this.plans.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(customTemplate.minimum_subscription_plan);
    }

    return true;
  }

  /**
   * Load all available templates
   *
   * @returns {void}
   */
  protected loadTemplates(): void {
    this.isLoadingTemplates = true;

    let subscription = this.service.getModuleTemplates$(this.moduleName, this.excludeDefaultTemplates)
      .pipe(
        finalize(() => this.isLoadingTemplates = false),
        take(1)
      )
      .subscribe((templates: FormTemplate[]) => this.templates = templates.filter((template) => {
        // only filter when provided
        if (template.minSubscription) {
          return this.plans.subscriptionPlanOfActiveClientIsGreaterThanOrEqualTo(template.minSubscription)
        }

        return true;
      }));

    this.subscriptions.push(subscription);
  }

  /**
   * Wraps fetching of module metadata information
   *
   * @param {GetModuleMetadataOptions} options
   */
  protected getModuleMetadata$(options: GetModuleMetadataOptions = {}) {
    return this.service.getModuleDocumentData$(this.moduleId, this.moduleName, {
      dontIncludeTemplate: options.dontIncludeTemplate || false,
      filter: options.filter,
      document_type: options.documentType || this.documentType,
      data: options.data,
    });
  }

  protected getModuleTemplate$(options: GetModuleMetadataOptions = {}) {
    return this.service.getModuleDocumentTemplate$(this.moduleName, {
      dontIncludeTemplate: options.dontIncludeTemplate || false,
      filter: options.filter,
      document_type: options.documentType || this.documentType,
      data: options.data,
    });
  }
}

interface GetModuleMetadataOptions {
  dontIncludeTemplate?: boolean;
  filter?: LooseObject;
  documentType?: string | string[];
  data?: Record<string, any>;
}

interface OpenPreviewComponentOption {
  filename: string;
  custom?: LooseCustomField;
}
