import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SubscriptionId } from '../../objects/subscription-plans';
import { ClientStoreService } from '../../services/client-store.service';
import { SubscriptionRestrictionService } from '../../services/subscription-restriction/subscription-restriction.service';

@Injectable({
  providedIn: 'root'
})
export class MinimumSubscriptionRequiredGuard implements CanActivate {

  constructor(private subscriptionRestrictionService: SubscriptionRestrictionService, private clientStoreService: ClientStoreService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (typeof route.data === 'undefined' || typeof route.data.minimumSubscriptionRequired === 'undefined') { return false; }

    let minimumSubscriptionRequired: SubscriptionId = route.data.minimumSubscriptionRequired;

    return this.subscriptionRestrictionService.subscriptionPlanIsGreaterThanOrEqualTo(this.clientStoreService.getActiveClient(), minimumSubscriptionRequired);
  }
}
