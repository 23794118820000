<div>
    <div class="row">
        <div class="col-sm-12">
            <span class="title">
                <fa-icon [icon]="['fas', 'hashtag']" ></fa-icon>
                {{ pad(strJobOrOpportunityNumber, 6) }}
            </span>
            <span class="title" *ngIf="strResponseType === 'main_prompts'">&nbsp;-&nbsp;{{ arChecklist['name'] }}
                <span *ngIf="strResponseType === 'main_prompts' && arChecklist['type'] !== 'asset' && strReviewStatus === 'review_complete'"
                    class="review-completed-by">
                   ( {{ 'reviewed_completed_by' | translate }} {{ arChecklist.review_status_completed_by }} on
                    {{ arChecklist.review_status_completed_date | humanReadableDate | placeholdWithString }} )
                </span>
            </span>

            <span class="buttons">

            <button
                *ngIf="strResponseType === 'main_prompts' && arChecklist['type'] !== 'asset' && strReviewStatus === 'review_pending'"
                class="btn ml-1 btn-success"
                (click)="changeReviewStatusToComplete()">
                <fa-icon [icon]="['fas', 'book-reader']"></fa-icon>
                <span class="pl-1">{{ 'mark_completed' | translate }}</span>
            </button>

            <button id="btnCancel" type="button" (click)="cancelDialog()" class="btn ml-1 btn-primary">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
                <span class="pl-1">{{ 'cancel' | translate }}</span>
            </button>

            <button
                id="btnSave"
                (click)="onSubmit()"
                class="btn ml-1 btn-primary" >
                <fa-icon [icon]="['fas', 'save']"></fa-icon>
                <span class="pl-1">{{ 'save' | translate }}</span>
            </button>

            </span>
        </div>
    </div>

    <hr class="separator"/>
    <mat-dialog-content>
        <div class="containers">
            <div *ngFor="let objPrompt of arPrompts, index as indexPromptTypes">

                <!-- ONLY SHOW PROMPTS THAT ARE RELATIVE TO THE ONE WHO CALLED THIS DIALOG -->
                <!-- (per_asset_prompts if was called in asset widget / main_prompts if called in checklist widget ) -->
                <ng-container *ngIf="objPrompt !== null && objPrompt['name'] === strResponseType && objPrompt['prompts'].length > 0">
                    <ng-container *ngIf="objPrompt['prompts'].length">
                        <ng-container *ngFor="let prompt of objPrompt['prompts'] index as indexPrompts">
                            <div *ngIf="prompt['is_group']" class="row body-container pt-1 pb-2">
                                <div class="col-12 border-light-blue prompt-rows p-0 rounded-top">
                                    <div class="bg-light-blue d-flex justify-content-between p-2">
                                        <div class="d-flex align-items-center">
                                            <span class="fs-14 font-weight-bold text-white">
                                                {{ indexPrompts + 1 }}. {{ prompt['group_name'] | translate }}
                                            </span>
                                        </div>

                                        <div class="d-flex gap-10 align-items-center">
                                            <span class="fa-stack">
                                                <fa-icon
                                                    [icon]="['fas', 'circle']"
                                                    class="fa-stack-1x text-white fs-16 ml-1">
                                                </fa-icon>

                                                <fa-icon
                                                    [icon]="['fas', 'plus-circle']"
                                                    class="text-success fa-stack-1x pointer fs-16 ml-1"
                                                    (click)="repeatGroup(prompt, indexPrompts)"
                                                    matTooltip="{{ 'repeat_group' | translate }}">
                                                </fa-icon>
                                            </span>

                                            <fa-icon
                                                *ngIf="hasRepeatingGroup(prompt, indexPrompts)"
                                                [icon]="['fas', 'trash-alt']"
                                                class="text-warning pointer fs-16"
                                                (click)="removeGroup(indexPrompts)"
                                                matTooltip="{{ 'remove_group' | translate }}">
                                            </fa-icon>
                                        </div>
                                    </div>

                                    <div>
                                        <app-checklist-response-prompt
                                            *ngFor="let subprompt of prompt['prompts']; index as i"
                                            class="row body-container w-100 m-0"
                                            [objPrompt]="subprompt"
                                            [numIndex]="i"
                                            [arNumberOfErroneousText]="arNumberOfErroneousText"
                                            [objMetadata]="arMetadata"
                                            [bIsUnderGroup]="true"
                                            (formDirtyEmitter)="markAsDirty($event)"
                                            (passFailPromptEmitter)="checkForFailedPassFailPrompts()">
                                        </app-checklist-response-prompt>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!prompt['is_group']" class="prompts-container">
                                <app-checklist-response-prompt
                                    class="row w-100 m-0"
                                    [objPrompt]="prompt"
                                    [numIndex]="indexPrompts"
                                    [arNumberOfErroneousText]="arNumberOfErroneousText"
                                    [objMetadata]="arMetadata"
                                    (formDirtyEmitter)="markAsDirty($event)"
                                    (passFailPromptEmitter)="checkForFailedPassFailPrompts()">
                                </app-checklist-response-prompt>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

            </div>

            <div *ngIf="bHasFailedPrompts">
                <div class="row card faults-container" >
                    <div class="card-header text-center faults-header" >
                        <span class="prompt-required">{{ 'required' | translate }}</span>
                        {{ 'fault_details' | translate }}
                    </div>
                    <div class="card-body fault-body">
                        <textarea id="textareaFaultDetails" class="form-control fault-details" [(ngModel)]="strFaultDetails" rows="3" (ngModelChange)="markAsDirty()">{{ strFaultDetails }}</textarea>
                    </div>
                </div>

                <div class="row card products-container" *ngIf="strResponseType === 'per_asset_prompts'" >
                    <div class="card-header text-center faults-header" >
                        {{ 'products_required_to_fix' | translate }}
                    </div>
                    <div class="card-body fault-body">

                        <ng-select
                            id="ngSelectProductsRequiredToFix"
                            [items]= "objProducts['obv'] | async"
                            [typeahead]= "objProducts['typehead']"
                            [loading]= "objProducts['loader']"
                            [hideSelected]= "true"
                            [clearable]= "true"
                            [multiple] = "true"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]= "arProducts"
                            bindLabel= "name"
                            bindValue= "id"
                            (open)="triggerSubject(objProducts['typehead'])"
                            appendTo="body"
                            (ngModelChange)="markAsDirty()">
                            <!-- DISPLAY IN OPTION -->
                            <ng-template  ng-option-tmp let-item="item" let-index="index">
                                <span id="productsRequiredOption{{index}}" class="value-text"> {{ item.name | translate }} </span>
                            </ng-template>
                        </ng-select>

                    </div>
                </div>
            </div>

            <div class="row asset-prompts-notice pt-0" *ngIf="shouldShowDateCompleted === true">
                <div class="col-6 p-2 completed_by">{{ 'date_completed' | translate }}</div>
                <div class="col-6 p-2">
                    <div class="input-group">
                        <input
                            [(ngModel)] = "strDateCompleted"
                            [owlDateTime]="dateCompletedInput"
                            id="dateCompletedInput"
                            type="text"
                            class="form-control date-input prompt-input"
                            (ngModelChange)="markAsDirty()">

                        <button class="calendar-button" [owlDateTimeTrigger]="dateCompletedInput">
                            <fa-icon [icon]="['fal', 'calendar']" style="color:#fff;" ></fa-icon>
                        </button>
                    </div>
                    <div>
                        <owl-date-time  #dateCompletedInput [pickerType]="'calendar'"></owl-date-time>
                    </div>
                </div>
            </div>

            <div class="row asset-prompts-notice pt-0" *ngIf="strResponseType === 'main_prompts' && arChecklist['type'] === 'asset'">
                <div class="col-6 p-2 completed_by">{{ 'completed_by' | translate }}</div>
                <div class="col-6 p-2">
                    <ng-select
                        id="ngSelectCompletedBy"
                        [items]="arRelateValues$ | async"
                        [loading]="bRelateLoading"
                        [typeahead]="arRelateValuesInput$"
                        class="w-100"
                        name="user_id"
                        bindLabel="text"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="objCompletedBy"
                        (ngModelChange)="markAsDirty()"
                    >
                    </ng-select>
                </div>
                <div class="col-12 prompt-rows">{{ 'complete_asset_prompts' | translate }}</div>
            </div>

        </div>
    </mat-dialog-content>
</div>
