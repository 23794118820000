
    <div class="input-group input-group-sm">
        <span class="input-group-prepend">
            <span class="btn btn-outline-primary notClickable">{{ advancedSearchBox.translateLabel(viewModel) }}</span>
        </span>
        <ng-container [ngSwitch]="inputType">
            <div [ngStyle]="{'display': viewModel.removeInputField ? 'none' : 'inherit' }">
                <as-simple-input *ngSwitchCase="'SIMPLE'" #inputComponent ></as-simple-input>
                <as-simple-input-mask *ngSwitchCase="'SIMPLE_MASK'" #inputComponent ></as-simple-input-mask>
                <as-suggestions-input *ngSwitchCase="'SUGGESTIONS'" #inputComponent ></as-suggestions-input>
                <as-domains-input *ngSwitchCase="'DOMAINS'" #inputComponent (change)="onChange()"></as-domains-input>
            </div>
        </ng-container>
        <span class="input-group-append">
            <button class="btn btn-outline-primary" type="button" (click)="remove()">X</button>
        </span>
    </div>
    