import { ADVANCED_PLAN, STARTER_PLAN, SubscriptionId } from './subscription-plans';

export type PermissionDef = {
  /**
   * Action that requires permission can be a module or feature
   *
   * @type {string}
   */
  action: string;
  /**
   * A flag used to determine whether the current action has a CRUD operation which
   * allows us to add list,view,edit,create and delete sub actions. i.e `leads` action
   * requires to have crud sub actions hence this action will sub actions appeneded to it
   *
   * @type {boolean}
   */
  is_crud?: boolean;
  /**
   * A flag used to determine if the current action has minimal subscription required to be
   * shown to clients. i.e assets requires fieldmagic-advanced subscription in order to view. this same goes when
   * setting up the permission for the assets module
   *
   * @type {SubscriptionId}
   */
  min_subscription?: SubscriptionId;

  /**
   * a flag to remove the owner value in actions dropdown option
   * added disabling of owner option for modules that don't have user_id field
   *
   * @type {boolean}
   */
  disable_owner?: boolean;
}

export type SubAction = 'list' | 'create' | 'edit' | 'view' | 'delete' | 'exports' | 'approve';
export type Scope = 'all' | 'owner' | 'disabled' | 'enabled';

export type Permission = {
  /**
   * The action requiring a permission
   *
   * @type {string}
   */
  action: string;
  /**
   * Scope of the action
   *
   * @type {Scope}
   */
  scope: Scope;
};

export interface Role {
  /**
   * Unique id of the role
   *
   * @type {string}
   */
  id: string;
  /**
   * Name of the role
   *
   * @type {string}
   */
  name: string;
  /**
   * List of permissions for this role
   *
   * @type {Permision[]}
   */
  permissions: Permission[];
};

/**
 * Permission Definitions
 *
 * @type {PermissionDef}
 */
export const kPermissionsDef: PermissionDef[] = [
  {
    action: 'leads',
    is_crud: true,
  },
  {
    action: 'customers',
    is_crud: true,
  },
  {
    action: 'sites',
    is_crud: true,
  },
  {
    action: 'contacts',
    is_crud: true,
  },
  {
    action: 'opportunities', // labeled as quotes
    is_crud: true,
  },
  {
    action: 'jobs',
    is_crud: true,
  },
  {
    action: 'recurring_jobs',
    is_crud: true,
    min_subscription: ADVANCED_PLAN,
  },
  {
    action: 'assets',
    is_crud: true,
    min_subscription: ADVANCED_PLAN,
  },
  {
    action: 'customer_invoices',
    is_crud: true,
  },
  {
    action: 'recurring_invoices',
    is_crud: true,
  },
  {
    action: 'purchase_orders',
    is_crud: true,
  },
  {
    action: 'supplier_invoices',
    is_crud: true,
  },
  {
    action: 'generate_scheduled_jobs',
    min_subscription: ADVANCED_PLAN,
  },
  {
    action: 'list_scheduled_recurring_jobs',
    min_subscription: ADVANCED_PLAN,
  },
  {
    action: 'site_service_summary_report',
  },
  {
    action: 'dashboard_and_reports',
  },
  {
    action: 'generate_scheduled_invoice',
  },
  {
    action: 'list_scheduled_recurring_invoices',
  },
  {
    action: 'technician_live_map',
  },
  {
    action: 'job_scheduler',
  },
  {
    action: 'stock_management',
  },
  {
    action: 'warehouses',
    is_crud: true,
    disable_owner: true,
  },
  {
    action: 'timesheets',
    is_crud: true,
  }
].map((objDef) => ({
  ...objDef,
  is_crud: objDef.is_crud || false,
  min_subscription: (objDef.min_subscription || STARTER_PLAN) as SubscriptionId, // default to min starter plan
}));